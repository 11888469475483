import { Dropdown } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFilterListPropertyThunk } from "../../../thunks/DashboardThunks";
import { debounce } from "lodash";
import {
  filterListPropertySelectedSelector,
  filterListPropertySelector,
} from "../../../selectors/DashboardSelectors";
import { filterListPropertySelectedAction } from "../../../actions/DashboardActions";
import DropdownWithCheckboxMenuWithSearchInput from "../../../../../components/DropdownWithCheckboxMenuWithSearchInput";
import { userCompanyChooseSelector } from "../../../../User/selectors/UserSelectors";

const FilterListProperty = () => {
  const dispatch = useDispatch();
  const filterListProperty = useSelector(filterListPropertySelector);
  const filterListPropertySelected = useSelector(
    filterListPropertySelectedSelector
  );

  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const userCompanyChoose = useSelector(userCompanyChooseSelector);

  useEffect(() => {
    _fetchListProperty();
  }, [userCompanyChoose]);

  const _fetchListProperty = async () => {
    setLoading(true);
    try {
      await dispatch<any>(getFilterListPropertyThunk(keyword));
    } catch (error: any) {
      console.log("error: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DropdownWithCheckboxMenuWithSearchInput
      loading={loading}
      onSearchChange={debounce(async (event: any) => {
        setKeyword(event.target.value);
      }, 300)}
      label={"Filter by Property"}
      listCheckBox={filterListProperty
        .filter((property: any) => {
          if (keyword.length > 0) {
            if (
              property?.text
                ?.trim()
                ?.toLowerCase()
                ?.includes(keyword.trim().toLowerCase())
            ) {
              return property;
            }
            return null;
          }
          return property;
        })
        .map((property: any) => {
          return {
            id: property?.id,
            name: property?.text,
          };
        })}
      values={filterListPropertySelected}
      onClear={() => {
        dispatch(filterListPropertySelectedAction([]));
      }}
      onCheckAll={() => {
        dispatch(
          filterListPropertySelectedAction(
            filterListProperty.map((property: any) => property.id)
          )
        );
      }}
      onChange={(e: any, data: any) => {
        if (data.checked) {
          dispatch(
            filterListPropertySelectedAction([
              ...filterListPropertySelected,
              data.value,
            ])
          );
        } else {
          dispatch(
            filterListPropertySelectedAction(
              filterListPropertySelected.filter(
                (property: any) => property !== data.value
              )
            )
          );
        }
      }}
    />
  );
};

export default FilterListProperty;
