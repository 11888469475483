import React from "react";
import MasterTemplate from "../../../App/views/MasterTemplate";
import "../../styles/dashboard.scss";
import ChartSummary from "./ChartSummary";
import UsageList from "./UsageList";
import Filter from "./Filter";
import UserGuideDashboard from "../UserGuide";
import { ROLE_BUSINESS_ADMIN_ID } from "../../../User/configs/Constants";
import ModalBusinessProfileIncomplete from "../ModalBusinessProfileIncomplete";
import ModalStartUserGuide from "../UserGuide/Components/ModalStartUserGuide";
import { PRODUCT_TYPE_BUSINESS } from "../../configs/Constants";

const Dashboard = () => {
  return (
    <MasterTemplate roleAccept={[ROLE_BUSINESS_ADMIN_ID]}>
      <ModalStartUserGuide />
      <UserGuideDashboard />
      <Filter />
      <ChartSummary productType={PRODUCT_TYPE_BUSINESS} />
      <UsageList />
      <ModalBusinessProfileIncomplete />
    </MasterTemplate>
  );
};

export default Dashboard;
