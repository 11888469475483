import React from "react";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import Label from "../../../../../../components/Label";
import {
  CONTRACT_DAILY_TYPE,
  CONTRACT_MONTHLY_TYPE,
  CONTRACT_ONETIME_TYPE,
} from "../../../../configs/Constants";

type Props = {
  value: string;
  onChange: (value: any) => void;
  disabled: boolean;
};

const DropdownContractType = ({ value, onChange, disabled }: Props) => {
  const options = [
    {
      key: CONTRACT_DAILY_TYPE,
      text: "Daily",
      value: CONTRACT_DAILY_TYPE,
    },
    {
      key: CONTRACT_MONTHLY_TYPE,
      text: "Monthly",
      value: CONTRACT_MONTHLY_TYPE,
    },
    {
      key: CONTRACT_ONETIME_TYPE,
      text: "One time",
      value: CONTRACT_ONETIME_TYPE,
    },
  ];

  return (
    <>
      <Label label={"Lease Term"} />
      <DropdownCustom
        disabled={disabled}
        options={options}
        selectUI
        value={value}
        onChange={(event, { value }) => onChange(value)}
      />
    </>
  );
};

export default DropdownContractType;
