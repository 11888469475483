import {
  MODAL_PAID_TRIAL_PACKAGE_ACTION,
  MODAL_TOP_PROPERTIES_ACTION,
  PACKAGE_TRIAL_INFO_ACTION,
  TOP_PROPERTIES_INFO_ACTION,
} from "../configs/Constants";

export const modalPaidTrialPackageAction = (data: boolean) => {
  return {
    type: MODAL_PAID_TRIAL_PACKAGE_ACTION,
    payload: data,
  };
};

export const modalTopPropertiesAction = (data: boolean) => {
  return {
    type: MODAL_TOP_PROPERTIES_ACTION,
    payload: data,
  };
};
export const packageTrialInfoAction = (data: object) => {
  return {
    type: PACKAGE_TRIAL_INFO_ACTION,
    payload: data,
  };
};
export const topPropertiesInfoAction = (data: object[]) => {
  return {
    type: TOP_PROPERTIES_INFO_ACTION,
    payload: data,
  };
};
