import React, { SyntheticEvent, useContext, useState } from "react";
import DropdownWithCheckboxMenu from "../../../../../components/DropdownWithCheckboxMenu";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import { LocalizationContext } from "../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import { dashboardLeaseFilterSelector } from "../../../selectors/DashboardSelectors";
import { dashboardLeaseFilterAction } from "../../../actions/DashboardActions";
import { PAYMENT_FAIL, PAYMENT_SUCCESS } from "../../../configs/Constants";

const FilterPrivateOffice = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const dashboardLeaseFilter = useSelector(dashboardLeaseFilterSelector);

  const [deskUsageStatus, setDeskUsageStatus] = useState<
    { id: string; name: string }[]
  >([
    { id: PAYMENT_FAIL, name: "Pending" },
    { id: PAYMENT_SUCCESS, name: "Paid" },
  ]);

  const _onChangeFilter = (e: SyntheticEvent, data: any) => {
    if (data.checked) {
      const newValue = [...dashboardLeaseFilter.status, data.value];
      dispatch(dashboardLeaseFilterAction({ key: "status", value: newValue }));
    } else {
      const newValue = dashboardLeaseFilter.status.filter(
        (status: number) => status !== data.value
      );
      dispatch(dashboardLeaseFilterAction({ key: "status", value: newValue }));
    }
  };

  return (
    <div className={"dropdown-filter"}>
      <DropdownWithCheckboxMenu
        onChange={_onChangeFilter}
        listCheckBox={deskUsageStatus.map((filter: any) => {
          if (dashboardLeaseFilter.status.includes(filter.id)) {
            filter.checked = true;
          } else {
            filter.checked = false;
          }
          return filter;
        })}
        label={translations.filters}
        icon={
          <img
            alt={"Deskimo"}
            src={PUBLIC_URL + "/assets/icons/icon-filter.svg"}
          />
        }
      />
    </div>
  );
};

export default FilterPrivateOffice;
