import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalRightSideBarTemplate from "../ModalRightSideBarTemplate";
import { isModalNavbarMobileSelector } from "../../selectors/AppSelectors";
import { modalNavbarMobileAction } from "../../actions/AppActions";
import {
  DASHBOARD_DESKIMO_LINK,
  DASHBOARD_PARTNER_LINK,
  DESKIMO_USERS_LINK,
  INVOICE_PARTNER_LINK,
  PARTNER_INTEGRATION_LINK,
} from "../../configs/ListRoutes";
import { PUBLIC_URL } from "../../configs/Constants";
import { Link, useNavigate } from "react-router-dom";
import ButtonCustom from "../../../../components/Button";
import "./style.scss";
import { LocalizationContext } from "../../../../locales/Translation";
import { isModalAddTeamMemberAction } from "../../../User/actions/PartnerTeamActions";

const NavBarPartnerMobile = () => {
  const { translations } = useContext(LocalizationContext);

  const dispatch = useDispatch();
  const isModalNavbarMobile = useSelector(isModalNavbarMobileSelector);
  const navigate = useNavigate();

  const _onClick = (pathName: string) => {
    navigate(pathName, { replace: true });
    dispatch(modalNavbarMobileAction(false));
  };

  return (
    <ModalRightSideBarTemplate
      trigger={null}
      onClose={() => {
        dispatch(modalNavbarMobileAction(false));
      }}
      modalClassName={"modal-edit-employee"}
      open={isModalNavbarMobile}
    >
      <div className={"navbar-mobile__list"}>
        <div className={"d-flex justify-content-between"}>
          <Link to={DESKIMO_USERS_LINK} className={"navbar__logo"}>
            <img src={PUBLIC_URL + "/assets/logo.svg"} alt="deskimo-logo" />
          </Link>
          <ButtonCustom
            onClick={() => dispatch(modalNavbarMobileAction(false))}
            className={"m-0 p-0 none-bg close-modal"}
          >
            <>
              {translations.close}
              <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
            </>
          </ButtonCustom>
        </div>

        <div className={"navbar-mobile__links"}>
          <div
            className={"navbar-mobile__link"}
            onClick={() => {
              _onClick(DASHBOARD_PARTNER_LINK);
            }}
          >
            <i className="uil uil-chart-line" />
            Overview
          </div>
          <div
            className={"navbar-mobile__link"}
            onClick={() => {
              _onClick(DESKIMO_USERS_LINK);
            }}
          >
            <i className="uil uil-users-alt" />
            Team
          </div>
          <div
            className={"navbar-mobile__link"}
            onClick={() => {
              _onClick(INVOICE_PARTNER_LINK);
            }}
          >
            <i className="uil uil-bill" />
            Billing
          </div>
          <div
            className={"navbar-mobile__link"}
            onClick={() => {
              _onClick(PARTNER_INTEGRATION_LINK);
            }}
          >
            <i className="uil uil-setting" />
            Integration
          </div>
          <ButtonCustom
            height={"36px"}
            className={"primary"}
            onClick={() => dispatch(isModalAddTeamMemberAction(true))}
          >
            <>
              <i className="uil uil-user-plus mr-8px"></i>Add Team Members
            </>
          </ButtonCustom>
        </div>
      </div>
    </ModalRightSideBarTemplate>
  );
};

export default NavBarPartnerMobile;
