import "./styles.scss";
import React, { useEffect, useState } from "react";
import RewardTable from "./Components/RewardTable";
import ButtonCustom from "../../../../../../components/Button";
import ModalCreateReward from "./Components/ModalCreateReward";
import { useDispatch, useSelector } from "react-redux";
import { modalRewardAction } from "../../../../actions/PropertyActions";
import { rewardListThunk } from "../../../../thunks/PropertyThunks";
import { useNavigate, useParams } from "react-router-dom";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../../../App/configs/Constants";
import { MyProfileType } from "../../../../../User/configs/DeclareTypes";
import { myProfileSelector } from "../../../../../App/selectors/AppSelectors";
import { ROLE_PROPERTY_STAFF_ID } from "../../../../../User/configs/Constants";

const RewardsTab = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { id: propertyId } = useParams();
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState({
    page: 1,
    property_id: propertyId,
    statuses: [0, 1],
    keyword: "",
    sort_type: "",
    sort_by: "",
  });
  const myProfile: MyProfileType = useSelector(myProfileSelector);
  const navigation = useNavigate();

  useEffect(() => {
    if (myProfile.role_id == ROLE_PROPERTY_STAFF_ID) {
      navigation("/dashboard-property");
    }
  }, []);

  useEffect(() => {
    fetchListReward();
  }, [filters, refresh]);

  const fetchListReward = async () => {
    setLoading(true);
    try {
      await dispatch<any>(rewardListThunk(filters));
    } catch (error: any) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const _onChangeSortCol = (col: any) => {
    if (filters.sort_by !== col.key) {
      setFilters({ ...filters, sort_by: col.key, sort_type: ORDER_BY_ASC });
    } else {
      if (filters.sort_type === ORDER_BY_NONE) {
        setFilters({ ...filters, sort_by: col.key, sort_type: ORDER_BY_ASC });
      }
      if (filters.sort_type === ORDER_BY_DESC) {
        setFilters({ ...filters, sort_by: col.key, sort_type: ORDER_BY_NONE });
      }
      if (filters.sort_type === ORDER_BY_ASC) {
        setFilters({ ...filters, sort_by: col.key, sort_type: ORDER_BY_DESC });
      }
    }
  };

  const _onChangePagination = (e: any, { activePage }: any) => {
    setFilters({ ...filters, page: activePage });
  };

  return (
    <div className={"deskimo-tab-content"}>
      <>
        <div className={"d-flex justify-content-end"}>
          <ButtonCustom
            onClick={() =>
              dispatch(modalRewardAction({ open: true, data: null }))
            }
            height={"36px"}
            className={"mr-0 primary"}
          >
            Add New Rewards +
          </ButtonCustom>
        </div>
        <RewardTable
          refresh={refresh}
          setRefresh={setRefresh}
          onChangeSortCol={_onChangeSortCol}
          onChangePagination={_onChangePagination}
          loading={loading}
          filters={filters}
        />
        <ModalCreateReward refresh={refresh} setRefresh={setRefresh} />
      </>
    </div>
  );
};

export default RewardsTab;
