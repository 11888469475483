const BookIcon = ({ strokeColor = "#21252A" }: { strokeColor?: string }) => {
  return (
    <svg
      id="book-icon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0415 3.33317H3.95817C3.61299 3.33317 3.33317 3.61299 3.33317 3.95817V16.0415C3.33317 16.3867 3.61299 16.6665 3.95817 16.6665H16.0415C16.3867 16.6665 16.6665 16.3867 16.6665 16.0415V3.95817C16.6665 3.61299 16.3867 3.33317 16.0415 3.33317ZM3.95817 1.6665C2.69252 1.6665 1.6665 2.69252 1.6665 3.95817V16.0415C1.6665 17.3072 2.69252 18.3332 3.95817 18.3332H16.0415C17.3072 18.3332 18.3332 17.3072 18.3332 16.0415V3.95817C18.3332 2.69252 17.3072 1.6665 16.0415 1.6665H3.95817Z"
        fill={strokeColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 7.49984C5 7.0396 5.3731 6.6665 5.83333 6.6665H8.33333C8.79357 6.6665 9.16667 7.0396 9.16667 7.49984C9.16667 7.96007 8.79357 8.33317 8.33333 8.33317H5.83333C5.3731 8.33317 5 7.96007 5 7.49984Z"
        fill={strokeColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 12.9168C5 12.4566 5.3731 12.0835 5.83333 12.0835H8.33333C8.79357 12.0835 9.16667 12.4566 9.16667 12.9168C9.16667 13.3771 8.79357 13.7502 8.33333 13.7502H5.83333C5.3731 13.7502 5 13.3771 5 12.9168Z"
        fill={strokeColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7547 5.65927C15.0809 5.98398 15.082 6.51162 14.7573 6.83778L12.6833 8.92111C12.4032 9.20236 11.9638 9.24713 11.6328 9.02811L10.3735 8.19478C9.98973 7.94079 9.88449 7.42375 10.1385 7.03995C10.3925 6.65614 10.9095 6.5509 11.2933 6.80489L11.9835 7.26166L13.5762 5.6619C13.9009 5.33573 14.4285 5.33456 14.7547 5.65927Z"
        fill={strokeColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7547 11.0763C15.0809 11.401 15.082 11.9286 14.7573 12.2548L12.6833 14.3381C12.4032 14.6194 11.9638 14.6641 11.6328 14.4451L10.3735 13.6118C9.98973 13.3578 9.88449 12.8407 10.1385 12.4569C10.3925 12.0731 10.9095 11.9679 11.2933 12.2219L11.9835 12.6787L13.5762 11.0789C13.9009 10.7527 14.4285 10.7516 14.7547 11.0763Z"
        fill={strokeColor}
      />
    </svg>
  );
};

export default BookIcon;
