import React from "react";
import { PUBLIC_URL } from "../../../../App/configs/Constants";

const RatingCell = ({ stars }: any) => {
  const starsHTML = [];
  if (stars > 0) {
    for (let i = 1; i <= stars; i++) {
      starsHTML.push(
        <img src={PUBLIC_URL + "/assets/icons/icon-star-black.svg"} />
      );
    }
  }
  return <div className={"user-rating"}>{starsHTML}</div>;
};

export default RatingCell;
