import React from "react";
import { Facebook } from "react-content-loader";

const CardPlaceholder = () => {
  return (
    <>
      <div className={"book-card"}>
        <div className="book-card__info">
          <Facebook />
        </div>
      </div>
      <div className={"book-card"}>
        <div className="book-card__info">
          <Facebook />
        </div>
      </div>
    </>
  );
};

export default CardPlaceholder;
