import React from "react";
import "./style.scss";
import { DatePicker } from "antd";
import type { Moment } from "moment";
import momentGenerateConfig from "rc-picker/lib/generate/moment";

const DatePickerWithMomentJS =
  DatePicker.generatePicker<Moment>(momentGenerateConfig);
const { RangePicker } = DatePickerWithMomentJS;

const DateRangePickerV2 = ({ ...props }: any) => {
  return <RangePicker {...props} />;
};

export default DateRangePickerV2;
