import React from "react";
import SizeBox from "../../../../../components/SizeBox";

type Props = {
  title: string;
  color: string;
  percent: string;
};

const UtilProgress = ({ title, color, percent }: Props) => {
  if (!Number(percent)) {
    return (
      <div>
        <div className={"dashboard_chart_sub_title"}>{title}</div>
        <SizeBox height={"13px"} />
        <div className={"dashboard_chart_progress"} />
      </div>
    );
  }

  return (
    <div>
      <div className={"dashboard_chart_sub_title"}>{title}</div>
      <SizeBox height={"13px"} />
      <div className={"dashboard_chart_progress"}>
        <div
          className={"dashboard_chart_progress_inner"}
          style={{ backgroundColor: color, width: `${percent}%` }}
        />
      </div>
    </div>
  );
};

export default UtilProgress;
