import ButtonCustom from "../../../../../../../../components/Button";
import { getLogoBrand } from "../../../../../../services/Common";
import { useDispatch } from "react-redux";
import { modalAddCardAction } from "../../../../../../actions/BiilingActions";
import { useContext } from "react";
import { LocalizationContext } from "../../../../../../../../locales/Translation";
import Label from "../../../../../../../../components/Label";

type Props = {
  openPaymentMethod?: any;
  paymentMethod?: any;
};

const PaymentInfo = ({ openPaymentMethod, paymentMethod }: Props) => {
  const dispatch = useDispatch();
  let primaryCard: any;
  const { translations } = useContext(LocalizationContext);

  if (paymentMethod && paymentMethod.length > 0) {
    primaryCard = paymentMethod.filter((card: any) => card.is_primary);
    return (
      <div className="payment-info ">
        <div className={"billing-section-header"}>
          <Label label={translations.settings.paymentMethod} />
          <ButtonCustom
            onClick={openPaymentMethod}
            className={"link edit-link p-0"}
          >
            <span>{translations.settings.edit}</span>
            <i className="uil uil-edit" />
          </ButtonCustom>
        </div>
        <div className={"card-number"}>{primaryCard[0]?.card_number}</div>
        <p className={"card-name"}>{primaryCard[0]?.card_holder_name}</p>
        <p className="card-expired">{primaryCard[0]?.expiry_date}</p>
        <img
          src={getLogoBrand(primaryCard[0]?.brand_link)}
          className="card-logo"
        />
      </div>
    );
  }

  return (
    <div className="payment-info ">
      <div className={"d-flex justify-content-between mb-19px"}>
        <h4>{translations.settings.paymentMethod}</h4>
        <ButtonCustom
          onClick={openPaymentMethod}
          className={"link edit-link p-0"}
        >
          <i className="uil uil-edit mr-2" />
          <span>{translations.settings.edit}</span>
        </ButtonCustom>
      </div>
      <div className={"w-100"}>
        <ButtonCustom
          height={"36px"}
          className={"outline-primary w-100"}
          onClick={() => dispatch(modalAddCardAction(true))}
        >
          {translations.settings.addCard}
        </ButtonCustom>
      </div>
    </div>
  );
};

export default PaymentInfo;
