export const CAMPAIGN_FILTER_ACTION = "CAMPAIGN_FILTER_ACTION";
export const CAMPAIGN_LIST_ACTION = "CAMPAIGN_LIST_ACTION";
export const CAMPAIGN_LIST_REMOVE_ITEM_ACTION =
  "CAMPAIGN_LIST_REMOVE_ITEM_ACTION";
export const CAMPAIGN_LIST_UPDATE_STATUS_ACTION =
  "CAMPAIGN_LIST_UPDATE_STATUS_ACTION";
export const CAMPAIGN_DETAIL_ACTION = "CAMPAIGN_DETAIL_ACTION";

// VOUCHER TYPE
export const VOUCHER_REDEEM_FIXED_TYPE = "FIXED";
export const VOUCHER_REDEEM_PERCENTAGE_TYPE = "PERCENTAGE";
export const VOUCHER_REDEEM_CREDIT_TYPE = "CREDIT";
export const VOUCHER_REDEEM_ON_BOARDING_TYPE = "ONBOARD";
export const VOUCHER_REDEEM_PERCENTAGE_PERIOD_DISCOUNT_TYPE = "PERIOD_DIS";
export const VOUCHER_REDEEM_PERCENTAGE_CREDIT_PACKAGE_DISCOUNT_TYPE =
  "PACKAG_DIS";

// VOUCHER RULES
export const NOT_ALLOW_TO_STACK_SAME_PROMOTION_RULE =
  "NOT_ALLOW_TO_STACK_SAME_PROMOTION_RULE";
export const ALLOW_TO_FIRST_VISIT_ONLY_PROMOTION_RULE =
  "ALLOW_TO_FIRST_VISIT_ONLY_PROMOTION_RULE";
export const MATCH_WITH_CUSTOM_VOUCHER_PROMOTION_RULE =
  "MATCH_WITH_CUSTOM_VOUCHER_PROMOTION_RULE";
export const MATCH_WITH_SPECIFIC_CITIES_RULE =
  "MATCH_WITH_SPECIFIC_CITIES_RULE";
export const MATCH_WITH_SPECIFIC_USERS_RULE = "MATCH_WITH_SPECIFIC_USERS_RULE";
export const MATCH_WITH_ONLY_ON_BOARDING_BUSINESS_RULE =
  "MATCH_WITH_ONLY_ON_BOARDING_BUSINESS_RULE";
export const MATCH_WITH_SPECIFIC_PROPERTIES_RULE =
  "MATCH_WITH_SPECIFIC_PROPERTIES_RULE";
export const MATCH_WITH_SPECIFIC_COMPANIES_RULE =
  "MATCH_WITH_SPECIFIC_COMPANIES_RULE";
export const MATCH_WITH_ONLY_BUSINESS_PAYMENT_RULE =
  "MATCH_WITH_ONLY_BUSINESS_PAYMENT_RULE";
export const ALLOW_ONLY_FIRST_TIME_PER_COMPANY_RULE =
  "ALLOW_ONLY_FIRST_TIME_PER_COMPANY_RULE";
export const ALLOW_ONLY_MEETING_ROOM_RULE = "ALLOW_ONLY_MEETING_ROOM_RULE";
export const ALLOW_ONLY_VISIT_RULE = "ALLOW_ONLY_VISIT_RULE";
export const ALLOW_FOR_BUSINESS_COMPANY_DISCOUNT_PERIOD_RULE =
  "ALLOW_FOR_BUSINESS_COMPANY_DISCOUNT_PERIOD_RULE";

export const ALLOW_TO_NEW_COMPANY_ONLY_RULE = "ALLOW_TO_NEW_COMPANY_ONLY_RULE";

export const CAMPAIGN_RULES_DEFAULT = [
  {
    type: ALLOW_TO_FIRST_VISIT_ONLY_PROMOTION_RULE,
    value: true,
  },
  {
    type: MATCH_WITH_SPECIFIC_CITIES_RULE,
    value: null,
  },
  {
    type: MATCH_WITH_SPECIFIC_USERS_RULE,
    value: null,
  },
  {
    type: MATCH_WITH_SPECIFIC_PROPERTIES_RULE,
    value: null,
  },
  {
    type: MATCH_WITH_SPECIFIC_COMPANIES_RULE,
    value: null,
  },
];

export const CAMPAIGN_RULES_BUSINESS_ONLY = [
  {
    type: ALLOW_TO_NEW_COMPANY_ONLY_RULE,
    value: false,
  },
  {
    type: MATCH_WITH_SPECIFIC_CITIES_RULE,
    value: null,
  },
  {
    type: MATCH_WITH_SPECIFIC_COMPANIES_RULE,
    value: null,
  },
];

export const CAMPAIGN_RULES_EMPTY = [
  {
    type: ALLOW_TO_FIRST_VISIT_ONLY_PROMOTION_RULE,
    value: false,
  },
  {
    type: MATCH_WITH_SPECIFIC_CITIES_RULE,
    value: null,
  },
  {
    type: MATCH_WITH_SPECIFIC_USERS_RULE,
    value: null,
  },
  {
    type: MATCH_WITH_SPECIFIC_PROPERTIES_RULE,
    value: null,
  },
  {
    type: MATCH_WITH_SPECIFIC_COMPANIES_RULE,
    value: null,
  },
];

export const DISTRIBUTION_TYPE_DESKIMO = "DESKIMO";
export const DISTRIBUTION_TYPE_PROPERTY = "PROPERTY";

export const REWARD_STATUS_UTIL = {
  UNUSE: "UNUSE",
  REDEEMED: "REDEEMED",
  EXPIRED: "EXPIRED",
};
