import {
  getBusinessCompanyListService,
  businessCompanyPostPayService,
  businessCompanyDeleteService,
  businessCompanyDetailService,
  businessCompanyStaffService,
  businessCompanyInvoiceService,
  businessCompanyStaffDeleteService,
  businessCompanyStaffEditService,
  businessCompanyCustomPackageService,
  businessCompanyPendingInvitationDeskimoAdminService,
  businessCompanyCanBuyCreditService,
} from "../services/BusinessServices";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import {
  businessCompanyCanBuyCreditAction,
  businessCompanyCustomPackageAction,
  businessCompanyDeleteAction,
  businessCompanyDetailAction,
  businessCompanyInvoiceAction,
  businessCompanyListAction,
  businessCompanyPayPostAction,
  businessCompanyPendingInvitationAction,
  businessCompanyStaffAction,
  businessCompanyStaffDeleteAction,
  businessCompanyStaffEditAction,
} from "../actions/BusinessActions";

export const getBusinessCompanyListThunk = (payload: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await getBusinessCompanyListService(payload);
    dispatch<any>(businessCompanyListAction(Array.isArray(data) ? null : data));
  };
};

export const businessCompanyPostPayThunk = (companyId: number) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    await businessCompanyPostPayService(companyId);
    dispatch<any>(businessCompanyPayPostAction(companyId));
  };
};

export const businessCompanyCanBuyCreditThunk = (
  companyId: number,
  data: object
) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    await businessCompanyCanBuyCreditService(companyId, data);
    dispatch<any>(businessCompanyCanBuyCreditAction(companyId));
  };
};

export const businessCompanyDeleteThunk = (
  companyId: number,
  data?: object
) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    await businessCompanyDeleteService(companyId, data);
    dispatch<any>(businessCompanyDeleteAction(companyId));
  };
};

export const businessCompanyDetailThunk = (companyId: string) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await businessCompanyDetailService(companyId);
    dispatch<any>(businessCompanyDetailAction(data));
  };
};

export const businessCompanyStaffThunk = (payload: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await businessCompanyStaffService(payload);
    dispatch<any>(
      businessCompanyStaffAction(Array.isArray(data) ? null : data)
    );
  };
};

export const businessCompanyPendingInvitationThunk = (payload: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await businessCompanyPendingInvitationDeskimoAdminService(
      payload
    );
    console.log("data: ", data);
    dispatch<any>(
      businessCompanyPendingInvitationAction(Array.isArray(data) ? null : data)
    );
  };
};

export const businessCompanyEditStaffThunk = (
  payload: any,
  companyName: any
) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await businessCompanyStaffEditService(payload);
    const processedData = { ...data, company_name: companyName };
    dispatch<any>(businessCompanyStaffEditAction(processedData));
  };
};

export const businessCompanyDeleteStaffThunk = (invitationId: number) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    await businessCompanyStaffDeleteService(invitationId);
    dispatch<any>(businessCompanyStaffDeleteAction(invitationId));
  };
};

export const businessCompanyInvoiceThunk = (payload: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await businessCompanyInvoiceService(payload);
    dispatch<any>(
      businessCompanyInvoiceAction(Array.isArray(data) ? null : data)
    );
  };
};

export const businessCompanyCustomPackageThunk = (payload: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await businessCompanyCustomPackageService(payload);
    dispatch<any>(
      businessCompanyCustomPackageAction(Array.isArray(data) ? null : data)
    );
  };
};

export const businessCompanyDeleteInvitationThunk = (invitationId: number) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    await businessCompanyStaffDeleteService(invitationId);
    dispatch<any>(businessCompanyStaffDeleteAction(invitationId));
  };
};
