import ButtonCustom from "../../../../../../../../components/Button";
import SizeBox from "../../../../../../../../components/SizeBox";
import { getLogoBrand } from "../../../../../../services/Common";
import React, { useContext, useState } from "react";
import { LocalizationContext } from "../../../../../../../../locales/Translation";
import { Loader } from "semantic-ui-react";

type Props = {
  card: any;
  onChangePrimaryCard?: any;
  removeCreditCard?: any;
  loading?: any;
};

const CardItem = ({
  card,
  onChangePrimaryCard,
  removeCreditCard,
  loading,
}: Props) => {
  const { translations } = useContext(LocalizationContext);
  const [removeLoading, setRemoveLoading] = useState<boolean>(false);

  return (
    <div className="card">
      <ButtonCustom
        disabled={removeLoading}
        onClick={async () => {
          setRemoveLoading(true);
          await removeCreditCard(card?.id);
          setRemoveLoading(false);
        }}
        className="none-bg m-0 p-0 card-delete-cta"
      >
        <>
          {removeLoading ? (
            <Loader active inline inverted size={"small"} />
          ) : (
            <i className="uil uil-trash-alt" />
          )}
        </>
      </ButtonCustom>
      <div className={"card-number"}>{card?.card_number}</div>
      <SizeBox height={"4px"} />
      <div className={"card-name"}>{card?.card_holder_name}</div>
      <SizeBox height={"4px"} />
      <div className={"card-expiry"}>{card?.expiry_date}</div>
      <SizeBox height={"24px"} />
      <div className={"card-logo"}>
        <img src={getLogoBrand(card?.brand_link)} />
      </div>
      <SizeBox height={"16px"} />
      <div className={"card-primary"}>
        {card?.is_primary ? (
          <ButtonCustom height={"36px"} className={"primary-alt"}>
            {translations.settings.primaryCard}
          </ButtonCustom>
        ) : (
          <ButtonCustom
            loading={loading}
            onClick={() => onChangePrimaryCard(card?.id)}
            height={"36px"}
            className={"outline-primary"}
          >
            {translations.settings.makePrimary}
          </ButtonCustom>
        )}
      </div>
    </div>
  );
};

export default CardItem;
