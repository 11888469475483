import { ROLE_DESKIMO_ADMIN_ID } from "../../User/configs/Constants";
import {
  ACCOUNTING_LINK,
  CONFIG_MAIN_LINK,
  CONFIG_PREPAID_CREDIT_PACKAGES_CONFIGURATION,
  CONFIG_ROLE_PERMISSION_LINK,
  CONFIG_TAGS,
} from "../../App/configs/ListRoutes";
import MasterTemplate from "../../App/views/MasterTemplate";
import React from "react";
import { Tab } from "semantic-ui-react";
import { Link, Route } from "react-router-dom";
import MarketList from "./MarketList";
import Tags from "./Tags";
import PrepaidCreditPackages from "./PrepaidCreditPackages";
import RolePermission from "./RolePermission";
import { myProfileSelector } from "../../App/selectors/AppSelectors";
import { useSelector } from "react-redux";
import { findTabIndexByName } from "../../../utils/commons";
import Accounting from "./Accounting";

const Configuration = ({ tabName }: any) => {
  const myProfile = useSelector(myProfileSelector);
  const panes: any = [
    {
      name: CONFIG_MAIN_LINK,
      menuItem: {
        as: Link,
        id: "tab1",
        to: CONFIG_MAIN_LINK,
        content: "Markets",
        exact: true,
      },
      pane: <Route path={CONFIG_MAIN_LINK} />,
      render: () => <MarketList />,
    },
  ];

  if (myProfile?.is_show_permission) {
    panes.push({
      name: CONFIG_ROLE_PERMISSION_LINK,
      menuItem: {
        as: Link,
        id: "tab1b",
        to: CONFIG_ROLE_PERMISSION_LINK,
        content: "Roles & Permissions",
        exact: true,
      },
      pane: <Route path={CONFIG_ROLE_PERMISSION_LINK} />,
      render: () => <RolePermission />,
    });
  }

  panes.push(
    {
      name: CONFIG_TAGS,
      menuItem: {
        as: Link,
        id: "tab2",
        to: CONFIG_TAGS,
        content: "Tags",
        exact: true,
      },
      render: () => <Tags />,
    },
    {
      name: CONFIG_PREPAID_CREDIT_PACKAGES_CONFIGURATION,
      menuItem: {
        as: Link,
        id: "tab3",
        to: CONFIG_PREPAID_CREDIT_PACKAGES_CONFIGURATION,
        content: "Prepaid credit packages configuration",
        exact: true,
      },
      render: () => <PrepaidCreditPackages />,
    },
    {
      name: ACCOUNTING_LINK,
      menuItem: {
        as: Link,
        id: "tab4",
        to: ACCOUNTING_LINK,
        content: "Accounting",
        exact: true,
      },
      render: () => <Accounting />,
    }
  );

  const defaultIndex = findTabIndexByName(panes, tabName);

  return (
    <MasterTemplate roleAccept={[ROLE_DESKIMO_ADMIN_ID]}>
      <Tab
        menu={{ text: true }}
        activeIndex={defaultIndex}
        panes={panes}
        className={"tab-custom"}
      />
    </MasterTemplate>
  );
};
export default Configuration;
