import { useDropzone } from "react-dropzone";
import React, { useContext, useState } from "react";
import { Button } from "semantic-ui-react";
import {
  DROPZONE_MAX_FILE_3MB,
  DROPZONE_MAX_FILE_3MB_TEXT,
} from "../../../../../../App/configs/DropzoneConfig";
import { uploadImageService } from "../../../../../../App/services/AppServices";
import ButtonCustom from "../../../../../../../components/Button";
import { toastError } from "../../../../../../../components/Toast";
import { LocalizationContext } from "../../../../../../../locales/Translation";
import { v4 as uuidv4 } from "uuid";
import { GridContextProvider, GridDropZone, GridItem } from "react-grid-dnd";
import SizeBox from "../../../../../../../components/SizeBox";

type Props = {
  value: any;
  setOfficeInfoModal?: any;
  isRoomUpload?: any;
  setPhotos?: any;
  deletePhotos: (id: string) => void;
  isDragDropChangeOrder?: boolean;
  onDragEnd?: any;
  containerClass?: string;
  boxClass?: string;
};

const DropzoneComponent = ({
  value,
  setOfficeInfoModal,
  isRoomUpload,
  deletePhotos,
  setPhotos,
  isDragDropChangeOrder,
  onDragEnd,
  containerClass = "",
  boxClass = "",
}: Props) => {
  const { translations } = useContext(LocalizationContext);

  const [isDragOver, setIsDragOver] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    maxSize: DROPZONE_MAX_FILE_3MB,
    useFsAccessApi: false,
    onDrop: async (acceptedFiles) => {
      if (!acceptedFiles?.length) {
        return;
      }

      try {
        const func: any = [];

        acceptedFiles.forEach((item) => {
          const formData = new FormData();
          formData.append("avatar", item);
          func.push(uploadImageService(formData));
        });
        const response = await Promise.all(func);

        const data: any = [];

        response.forEach((res) => {
          data.push({
            id: res?.data?.id || uuidv4(),
            file_key: res?.data?.avatar_uri,
            uri: res?.data?.uri,
          });
        });

        setPhotos(data);
      } catch (error) {
        console.log("Error: ", error);
      } finally {
        setIsDragOver(false);
      }
    },

    onDropRejected: (fileRejections) => {
      let isFileTooLarge;
      fileRejections.forEach((file) => {
        isFileTooLarge =
          file.errors.filter((error) => error.code === "file-too-large")
            .length > 0;
        if (isFileTooLarge) {
          toastError(
            `File ${file.file.name} is larger than ${DROPZONE_MAX_FILE_3MB_TEXT}`
          );
        }
      });
    },

    onDragOver: () => {
      setIsDragOver(true);
    },

    onDragLeave: () => {
      setIsDragOver(false);
    },
  });

  // const _onClickPrimaryMeetingRoom = (id: any) => {
  //   setOfficeInfoModal((prevState: any) => {
  //     let images = [...prevState.images];
  //     images = images.map((item) => {
  //       if (item.id === id) {
  //         return { ...item, is_primary: true };
  //       } else {
  //         return { ...item, is_primary: false };
  //       }
  //     });
  //     return { ...prevState, images };
  //   });
  // };

  const thumbs = value.map((file: any) => {
    if (file) {
      return (
        <div className={"dropzone-preview__thumb-wrapper "} key={file?.fileKey}>
          <div
            className={
              "dropzone-preview__thumb-box" + (file?.isPrimary ? " active" : "")
            }
          >
            <ButtonCustom
              type={"button"}
              onClick={() => deletePhotos(file?.id)}
              className={"dropzone-preview__delete-cta"}
            >
              <i className="uil uil-trash" />
            </ButtonCustom>
            <Button
              className={
                "dropzone-preview__set-primary-cta " +
                (file?.isPrimary ? "active" : "")
              }
            >
              <i className="uil uil-check" />
            </Button>
            <img
              onClick={() => deletePhotos(file?.id)}
              className={"dropzone-preview__thumb"}
              src={file?.uri}
              alt={"Deskimo"}
            />
          </div>
        </div>
      );
    }
    return null;
  });

  return (
    <section className={"mt-3 " + containerClass}>
      {isDragDropChangeOrder ? (
        <>
          <GridContextProvider onChange={onDragEnd}>
            <GridDropZone
              id="items"
              boxesPerRow={4}
              rowHeight={156}
              style={{ height: 156 * Math.ceil(value.length / 4) + "px" }}
            >
              {value.map((item: any) => (
                <GridItem
                  key={item.id}
                  className={
                    "react-dnd-grid-item dropzone-preview__thumb-wrapper"
                  }
                >
                  <div
                    className={
                      "dropzone-preview__thumb-box" +
                      (item?.isPrimary ? " active" : "")
                    }
                  >
                    <ButtonCustom
                      type={"button"}
                      onClick={() => deletePhotos(item?.id)}
                      className={"dropzone-preview__delete-cta"}
                    >
                      <i className="uil uil-trash" />
                    </ButtonCustom>
                    <Button
                      className={
                        "dropzone-preview__set-primary-cta " +
                        (item?.isPrimary ? "active" : "")
                      }
                    >
                      <i className="uil uil-check" />
                    </Button>
                    <img
                      className={"dropzone-preview__thumb"}
                      src={item?.uri}
                      alt={"Deskimo"}
                    />
                  </div>
                </GridItem>
              ))}
            </GridDropZone>
          </GridContextProvider>
        </>
      ) : (
        <>
          <SizeBox height={"24px"} />
          <aside>{thumbs}</aside>
          <SizeBox height={"24px"} />
        </>
      )}

      <div
        {...getRootProps({
          className:
            "dropzone dropzone-box " +
            (isDragOver ? " drag-over " : "") +
            boxClass,
        })}
      >
        <input {...getInputProps()} />
        <div className={"dropzone-guide-title"}>
          {translations.dropYourImageFilesHereOr}
        </div>
        <div className={"dropzone-guide-subtitle"}>
          JPG, GIF & PNG. {translations.maxFileSizeOf} 3MB
        </div>
        <div className={"dropzone-guide-subtitle mb-0"}>
          {translations.maximum10Images}
        </div>
      </div>
    </section>
  );
};

export default DropzoneComponent;
