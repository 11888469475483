import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardPropertyChooseSelector,
  dashboardPropertyPendingBookingsSelector,
} from "../../../selectors/DashboardSelectors";
import { dashboardPropertyPendingBookingsThunk } from "../../../thunks/DashboardThunks";
import TableCustom from "../../../../../components/Table";
import SizeBox from "../../../../../components/SizeBox";
import BookingAction from "./BookingAction";

const PendingBooking = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const dashboardPropertyPendingBookings = useSelector(
    dashboardPropertyPendingBookingsSelector
  );

  const dashboardPropertyChoose = useSelector(dashboardPropertyChooseSelector);

  useEffect(() => {
    const interval = setInterval(_init, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    (async () => {
      await _init();
    })();
  }, [dashboardPropertyChoose]);

  const _init = async () => {
    try {
      if (!dashboardPropertyChoose?.property_id) {
        return;
      }
      setLoading(true);
      await dispatch<any>(dashboardPropertyPendingBookingsThunk());
    } catch (e) {
      console.log("e", e);
    } finally {
      setLoading(false);
    }
  };

  const _formatInputData = () => {
    return dashboardPropertyPendingBookings.map(
      ({ booker, asset, start_time, duration, note, id, type }: any) => {
        return {
          booker,
          asset,
          start_time,
          duration: duration,
          note: note || "-",
          action: <BookingAction bookingId={id} type={type} />,
        };
      }
    );
  };

  const columns = [
    translations.userName,
    translations.asset,
    translations.startTime,
    translations.duration,
    translations.note,
    translations.action,
  ];

  return (
    <div>
      <h6 className={"dashboard_pending_booking_text"}>
        {translations.pendingBookings}
      </h6>
      <SizeBox height={"14px"} />
      <TableCustom
        tableData={_formatInputData()}
        columns={columns}
        loading={loading}
        tableClass={"dashboard_pending_bookings"}
        showTotal={false}
      />
    </div>
  );
};

export default PendingBooking;
