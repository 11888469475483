import React, { useContext, useState } from "react";
import ButtonCustom from "../../../../../components/Button";
import { LocalizationContext } from "../../../../../locales/Translation";
import TableDeskUsage from "./TableDeskUsage";
import TableRoomBookings from "./TableRoomBookings";
import classNames from "classnames";
import Search from "./Search";
import FilterDeskUsage from "./FilterDeskUsage";
import FilterRoomBooking from "./FilterRoomBooking";
import {
  PRODUCT_TYPE_BUSINESS,
  TAB_DESK_BOOKINGS_INDEX,
  TAB_DESK_USAGE_INDEX,
  TAB_PRIVATE_OFFICES_INDEX,
  TAB_ROOM_BOOKINGS_INDEX,
} from "../../../configs/Constants";
import FilterDeskBooking from "./FilterDeskBooking";
import TableDeskBookings from "./TableDeskBookings";
import OfficeLeases from "../OfficeLeases";
import FilterPrivateOffice from "../OfficeLeases/Filter";

const UsageList = () => {
  const { translations } = useContext(LocalizationContext);
  const [tabIndex, setTabIndex] = useState(TAB_DESK_USAGE_INDEX);
  const _onChangeTab = (value: number) => {
    setTabIndex(value);
  };

  return (
    <>
      <div className={"filter-section"}>
        <Search />
        <div className={"button-group yellow"}>
          <ButtonCustom
            height={"36px"}
            className={classNames({
              active: tabIndex === TAB_DESK_USAGE_INDEX,
            })}
            onClick={() => _onChangeTab(TAB_DESK_USAGE_INDEX)}
          >
            {translations.deskWalkIn}
          </ButtonCustom>
          <ButtonCustom
            height={"36px"}
            className={classNames({
              active: tabIndex === TAB_DESK_BOOKINGS_INDEX,
            })}
            onClick={() => _onChangeTab(TAB_DESK_BOOKINGS_INDEX)}
          >
            {translations.deskBookings}
          </ButtonCustom>
          <ButtonCustom
            height={"36px"}
            className={classNames({
              active: tabIndex === TAB_ROOM_BOOKINGS_INDEX,
            })}
            onClick={() => _onChangeTab(TAB_ROOM_BOOKINGS_INDEX)}
          >
            {translations.roomBookings}
          </ButtonCustom>
          <ButtonCustom
            height={"36px"}
            className={classNames({
              active: tabIndex === TAB_PRIVATE_OFFICES_INDEX,
            })}
            onClick={() => _onChangeTab(TAB_PRIVATE_OFFICES_INDEX)}
          >
            Private Offices
          </ButtonCustom>
        </div>

        {tabIndex === TAB_DESK_USAGE_INDEX ? (
          <FilterDeskUsage productType={PRODUCT_TYPE_BUSINESS} />
        ) : tabIndex === TAB_DESK_BOOKINGS_INDEX ? (
          <FilterDeskBooking productType={PRODUCT_TYPE_BUSINESS} />
        ) : tabIndex === TAB_ROOM_BOOKINGS_INDEX ? (
          <FilterRoomBooking productType={PRODUCT_TYPE_BUSINESS} />
        ) : (
          <FilterPrivateOffice />
        )}
      </div>

      <div className={"dashboard_table"}>
        {tabIndex === TAB_DESK_USAGE_INDEX ? (
          <TableDeskUsage />
        ) : tabIndex === TAB_DESK_BOOKINGS_INDEX ? (
          <TableDeskBookings />
        ) : tabIndex === TAB_ROOM_BOOKINGS_INDEX ? (
          <TableRoomBookings />
        ) : (
          <OfficeLeases />
        )}
      </div>
    </>
  );
};

export default UsageList;
