import { Dimmer, Loader } from "semantic-ui-react";
import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { Formik, FormikHelpers, FormikValues } from "formik";
import ButtonCustom from "../../../../../components/Button";
import Label from "../../../../../components/Label";
import TableCustom from "../../../../../components/Table";
import { configMarketLandmarkListSelector } from "../../../selectors/ConfigSelectors";
import { useDispatch, useSelector } from "react-redux";
import { configMarketLandmarkListThunk } from "../../../thunks/ConfigThunks";
import { useParams } from "react-router-dom";
import { ConfigMarketLandmarkDataType } from "../../../configs/DeclareTypes";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import { SWAL_DELETE_OPTION } from "../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../components/SweetAlert";
import {
  configCreateMarketLandmarkService,
  configDeleteMarketLandmarkService,
} from "../../../services/ConfigServices";
import { deleteMarketLandmarkAction } from "../../../actions/ConfigActions";
import { toastError, toastSuccess } from "../../../../../components/Toast";
import InputCustom from "../../../../../components/Input";
import MapSection from "./Components/MapSection";
import SizeBox from "../../../../../components/SizeBox";
import UploadImageSection from "./Components/UploadImageSection";
import "./styles.scss";
import * as yup from "yup";
import { LocalizationContext } from "../../../../../locales/Translation";

const LandmarkManagementTab = () => {
  const { translations } = useContext(LocalizationContext);
  const { id } = useParams();
  const [imageUploaded, setImageUploaded] = useState<any>(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const DEFAULT_PAYLOAD = {
    market_id: String(id),
    page: 1,
  };
  const [payload, setPayload] = useState(DEFAULT_PAYLOAD);
  const columns = ["Name", "Address", "Image", "Action"];
  const landmarkList = useSelector(configMarketLandmarkListSelector);
  const [clearImg, setClearImg] = useState(false);

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    setPayload((prevState: any) => {
      return { ...prevState, page: activePage };
    });
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await dispatch<any>(configMarketLandmarkListThunk(payload));
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [payload]);

  const _onDeleteLandmark = async (id: number) => {
    try {
      const iconHtml = (
        <img
          src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
          className={"swal2-icon__error"}
          alt="error_icon"
        />
      );
      const title = "Are you sure you want to delete this landmark?";
      const confirmText = "Delete";
      const cancelText = "Cancel";
      const confirmClass = "danger";
      const options = SWAL_DELETE_OPTION({
        title,
        iconHtml,
        confirmText,
        confirmClass,
        cancelText,
      });
      SweetAlertCommon({
        options,
        confirmCallback: async () => {
          await configDeleteMarketLandmarkService(String(id));
          dispatch(deleteMarketLandmarkAction(id));
        },
        cancelCallback: () => null,
      });
    } catch (e: any) {
      toastError(e?.status?.message);
    }
  };

  const _onSubmit = async (
    values: FormikValues,
    { resetForm }: FormikHelpers<FormikValues>
  ) => {
    try {
      const payload = { market_id: id, ...values };
      await configCreateMarketLandmarkService(payload);
      await dispatch<any>(configMarketLandmarkListThunk(DEFAULT_PAYLOAD));
      resetForm();
      toastSuccess("Created Landmark Successfully");
      setClearImg(true);
    } catch (e: any) {
      toastSuccess(e?.status?.message);
    }
  };

  const _formatData = () => {
    if (!landmarkList.item) {
      return [];
    }
    return landmarkList?.item?.map(
      ({ id, name, address, image }: ConfigMarketLandmarkDataType) => {
        return {
          name,
          address,
          image: (
            <img
              src={image.replaceAll(
                "https://dn6r1hkhtrr0f.cloudfront.net/media/https://dn6r1hkhtrr0f.cloudfront.net/media/",
                "https://dn6r1hkhtrr0f.cloudfront.net/media/"
              )}
              width={108}
              height={87}
              className="landmark-image-view"
              alt="landmark_thumbnail"
            />
          ),
          action: (
            <ButtonCustom
              className={"button-delete-public-holidays"}
              fontSize={"22px"}
              padding={"12px"}
              onClick={() => _onDeleteLandmark(id)}
            >
              <i className="uil uil-trash" />
            </ButtonCustom>
          ),
        };
      }
    );
  };

  return (
    <>
      {loading ? (
        <Dimmer active>
          <Loader />
        </Dimmer>
      ) : (
        <div className={"section-white-box"}>
          <Label
            label={"Edit Landmarks"}
            fontSize={"24px"}
            className={"section-title"}
          />
          <Formik
            enableReinitialize
            initialValues={{
              name: "",
              location: "",
              lat: "",
              lng: "",
              image: "",
            }}
            validationSchema={yup.object().shape({
              name: yup.string().required().label("Name"),
              location: yup.string().required().label("Location"),
              image: yup.string().required().label("Picture"),
            })}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onSubmit={_onSubmit}
          >
            {({
              values,
              handleSubmit,
              resetForm,
              isSubmitting,
              setFieldValue,
              errors,
              touched,
              handleChange,
            }) => (
              <div>
                <div className={"input-landmarks"}>
                  <div className={"left-section-input-landmarks"}>
                    <InputCustom
                      label="Name"
                      labelRequired
                      value={values.name}
                      name={"name"}
                      error={errors.name}
                      touched={touched.name}
                      onChange={handleChange}
                    />
                    <MapSection
                      values={values}
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <div className={"right-section-input-landmarks"}>
                    <Label label="Pictures" fontSize="12px" required />
                    <UploadImageSection
                      setFieldValue={setFieldValue}
                      imgSrc={values.image}
                      values
                      imageUploaded={imageUploaded}
                      setImageUploaded={setImageUploaded}
                      clearImg={clearImg}
                      setClearImg={setClearImg}
                    />
                  </div>
                </div>
                <SizeBox height={"24px"} />
                <div>
                  <Label label="Landmarks" fontSize="16px" />
                  <TableCustom
                    loading={loading}
                    columns={columns}
                    tableData={landmarkList ? _formatData() : []}
                    totalItems={landmarkList?.pagination?.total}
                    currentPage={landmarkList?.pagination?.page}
                    showTotal={false}
                    onChangePagination={_onChangePagination}
                  />
                </div>
                <div
                  className={
                    "section-white-box-actions action-section-public-holiday"
                  }
                >
                  <ButtonCustom
                    className={"cancel mr-10px"}
                    onClick={resetForm}
                  >
                    {translations.cancel}
                  </ButtonCustom>
                  <ButtonCustom
                    className={"primary mr-0"}
                    onClick={handleSubmit}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    {translations.save}
                  </ButtonCustom>
                </div>
              </div>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default LandmarkManagementTab;
