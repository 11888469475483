import React, { useContext, useEffect, useState } from "react";
import { InfoWindow } from "@react-google-maps/api";
import { PUBLIC_URL } from "../../../App/configs/Constants";
import ButtonCustom from "../../../../components/Button";
import { LocalizationContext } from "../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import {
  infoWindowDataSelector,
  meetingRoomFiltersSelector,
  meetingRoomListSelector,
  propertyFilterSelector,
  propertyListSelector,
} from "../../selectors/BookSelectors";
import {
  infoWindowDataAction,
  pinHighlightIdAction,
} from "../../actions/BookActions";
import {
  BookInfoWindowType,
  BookMeetingRoomType,
  BookPropertyType,
} from "../../configs/DeclareType";
import {
  handleBookDeskService,
  handleBookRoomService,
} from "../../services/BookServices";
import { DESK_TYPE } from "../../configs/Constants";
import { Link } from "react-router-dom";
import {
  BOOK_DESK_DETAIL_LINK,
  BOOK_DESK_DETAIL_WITH_DEFAULT_DATE_LINK,
  BOOK_ROOM_DETAIL_LINK,
  BOOK_ROOM_DETAIL_WITH_DEFAULT_DATE_LINK,
} from "../../../App/configs/ListRoutes";
import {
  propertyFavoriteThunk,
  roomFavoriteThunk,
} from "../../thunks/BookThunks";
import classNames from "classnames";
import moment from "moment/moment";

const InfoWindowCustom = () => {
  const { translations } = useContext(LocalizationContext);
  const [favoriteLocal, setFavoriteLocal] = useState<0 | 1>(0);

  const dispatch = useDispatch();
  const infoWindowData: BookInfoWindowType = useSelector(
    infoWindowDataSelector
  );

  const propertyMap: BookPropertyType[] =
    useSelector(propertyListSelector)?.results;

  const meetingRoomList: BookMeetingRoomType[] = useSelector(
    meetingRoomListSelector
  );
  const filtersRoom = useSelector(meetingRoomFiltersSelector);
  const filtersProperty = useSelector(propertyFilterSelector);

  useEffect(() => {
    if (!infoWindowData) return;

    setFavoriteLocal(infoWindowData?.isFavorite);
  }, [infoWindowData]);

  if (!infoWindowData) {
    return null;
  }

  const {
    lat,
    lng,
    id,
    name,
    address,
    capacity,
    office,
    openTime,
    thumbnailUrl,
    unit,
    price,
    type,
    isClosed,
  } = infoWindowData;

  const _handleBookRoom = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (isClosed) {
      return;
    }

    if (type === DESK_TYPE) {
      const item = propertyMap.find(({ id }) => id === infoWindowData.id);

      if (!item) {
        return;
      }

      handleBookDeskService(item);
      return;
    }

    const item = meetingRoomList.find(
      ({ meeting_room_id }) => meeting_room_id === id
    );

    if (!item) {
      return;
    }

    handleBookRoomService(item);
  };

  const _getDetailLink = () => {
    return type === DESK_TYPE
      ? filtersProperty?.defaultDate
        ? BOOK_DESK_DETAIL_WITH_DEFAULT_DATE_LINK.replaceAll(
            ":id",
            String(id)
          ).replaceAll(
            ":defaultDate",
            String(moment(filtersProperty?.defaultDate).unix())
          )
        : BOOK_DESK_DETAIL_LINK.replaceAll(":id", String(id))
      : filtersRoom?.isSetDefaultValue
      ? BOOK_ROOM_DETAIL_WITH_DEFAULT_DATE_LINK.replaceAll(":id", String(id))
          .replaceAll(":defaultDate", filtersRoom?.defaultDate)
          .replaceAll(":defaultStartTime", filtersRoom?.defaultStartTime)
          .replaceAll(":defaultDuration", filtersRoom?.defaultDuration)
      : BOOK_ROOM_DETAIL_LINK.replaceAll(":id", String(id));
  };

  const _onClickFavorite = () => {
    setFavoriteLocal(favoriteLocal === 1 ? 0 : 1);

    if (type === DESK_TYPE) {
      dispatch<any>(
        propertyFavoriteThunk({
          id,
          isFavorite: favoriteLocal,
        })
      );
      return;
    }
    dispatch<any>(
      roomFavoriteThunk({
        isFavorite: favoriteLocal,
        id,
      })
    );
  };

  return (
    <InfoWindow
      options={{
        pixelOffset: new window.google.maps.Size(0, -50),
      }}
      position={{
        lat,
        lng,
      }}
      onCloseClick={() => {
        dispatch(infoWindowDataAction(null));
        dispatch(pinHighlightIdAction(null));
      }}
    >
      <Link
        to={_getDetailLink()}
        target={"_blank"}
        className={classNames("info-window-book__box", {
          "info-window-book__box-closed": isClosed,
        })}
      >
        <div className={"info-window-book__thumbnail"}>
          <img
            className={"info-window-book__thumbnail-img"}
            src={thumbnailUrl || PUBLIC_URL + "/assets/images/logo-text.png"}
            alt={name}
          />
          <div
            className={"info-window-book__thumbnail-favorite"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              _onClickFavorite();
            }}
          >
            {favoriteLocal == 1 ? (
              <img
                alt={name}
                src={PUBLIC_URL + "/assets/icons/icon-heart-full.svg"}
              />
            ) : (
              <img
                alt={name}
                src={PUBLIC_URL + "/assets/icons/icon-heart-empty.svg"}
              />
            )}
          </div>
        </div>
        <div className={"info-window-book__info"}>
          <div className={"info-window-book__info-name"}>{name}</div>
          <div className={"info-window-book__info-price"}>
            {price}
            <span className={"book-card__info-bottom__unit"}> / {unit}</span>
          </div>
          <div className={"info-window-book__info-item"}>
            <i className="uil uil-building" />
            {office}
          </div>
          <div className={"info-window-book__info-item"}>
            <i className="uil uil-map" />
            {address}
          </div>
          {openTime ? (
            <div className={"info-window-book__info-item"}>
              <i className="uil uil-clock" />
              {openTime}
            </div>
          ) : null}
          {capacity ? (
            <div className={"info-window-book__info-item"}>
              <i className="uil uil-users-alt" />
              {capacity} {capacity > 1 ? "People" : "Person"}
            </div>
          ) : null}
          <ButtonCustom
            className={"info-window-book__info-book w-100"}
            height={"32px"}
            onClick={_handleBookRoom}
          >
            {isClosed ? translations.closed : translations.book}
          </ButtonCustom>
        </div>
      </Link>
    </InfoWindow>
  );
};

export default InfoWindowCustom;
