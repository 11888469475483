import clientRequestService from "../../../services/ClientRequestService";
import {
  DELETE_DESKIMO_PROPERTY_AMENITY_LIST_API,
  GET_DESKIMO_PROPERTY_AMENITY_DETAIL_API,
  POST_ADD_PROPERTY_AMENITY_API,
  POST_DESKIMO_PROPERTY_AMENITY_LIST_API,
  POST_UPLOAD_ICON_IMAGE_API,
} from "../configs/Endpoints";

export const getPropertyAmenityListService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: POST_DESKIMO_PROPERTY_AMENITY_LIST_API,
    data,
  });
};

export const getPropertyAmenityDetailService = async (id: string) => {
  return await clientRequestService({
    method: "get",
    url: GET_DESKIMO_PROPERTY_AMENITY_DETAIL_API.replaceAll(":amenityId", id),
  });
};

export const deletePropertyAmenityService = async (id: string) => {
  return await clientRequestService({
    method: "delete",
    url: DELETE_DESKIMO_PROPERTY_AMENITY_LIST_API.replaceAll(":amenityId", id),
  });
};

export const uploadIconImageService = async (payload?: any) => {
  return await clientRequestService({
    method: "post",
    url: POST_UPLOAD_ICON_IMAGE_API,
    data: payload,
    isFile: true,
  });
};

export const propertyAmenityAddEditService = async (payload?: any) => {
  return await clientRequestService({
    method: "post",
    url: POST_ADD_PROPERTY_AMENITY_API,
    data: payload,
  });
};
