export const dashboardFilterDateTypeSelector = (state: any) =>
  state.DashboardReducer.filterDateType;

export const dashboardFilterDateRangeSelector = (state: any) =>
  state.DashboardReducer.filterDateRange;

export const dashboardStatisticSelector = (state: any) =>
  state.DashboardReducer.dashboardStatistic;

export const dashboardStatisticDeskimoSelector = (state: any) =>
  state.DashboardReducer.dashboardStatisticDeskimo;

export const dashboardStatisticPropertySelector = (state: any) =>
  state.DashboardReducer.dashboardStatisticProperty;

export const dashboardStatisticBookingPropertySelector = (state: any) =>
  state.DashboardReducer.dashboardStatisticBookingProperty;

export const dashboardStatisticOfficePropertySelector = (state: any) =>
  state.DashboardReducer.dashboardStatisticOfficeProperty;

export const dashboardDeskUsageSelector = (state: any) =>
  state.DashboardReducer.dashboardDeskUsage;

export const dashboardDeskBookingSelector = (state: any) =>
  state.DashboardReducer.dashboardDeskBooking;

export const dashboardRoomBookingSelector = (state: any) =>
  state.DashboardReducer.dashboardRoomBooking;

export const dashboardDeskUsageFilterSelector = (state: any) =>
  state.DashboardReducer.dashboardDeskUsageFilter;

export const dashboardDeskBookingFilterSelector = (state: any) =>
  state.DashboardReducer.dashboardDeskBookingFilter;

export const dashboardRoomBookingFilterSelector = (state: any) =>
  state.DashboardReducer.dashboardRoomBookingFilter;
export const dashboardLeaseFilterSelector = (state: any) =>
  state.DashboardReducer.dashboardLeaseFilter;

export const dashboardPropertyListSelector = (state: any) =>
  state.DashboardReducer.dashboardPropertyList;

export const dashboardPropertyChooseSelector = (state: any) =>
  state.DashboardReducer.dashboardPropertyChoose;

export const dashboardPropertyPendingBookingsSelector = (state: any) =>
  state.DashboardReducer.dashboardPropertyPendingBookings;

export const dashboardActivityPropertySelector = (state: any) =>
  state.DashboardReducer.dashboardActivityProperty;

export const declineReasonsSelector = (state: any) =>
  state.DashboardReducer.declineReasons;

export const modalDeclineReasonsSelector = (state: any) =>
  state.DashboardReducer.modalSelectDeclineReason;

export const modalCheckInManualSelector = (state: any) =>
  state.DashboardReducer.modalCheckInManual;

export const modalBookingDeskSelector = (state: any) =>
  state.DashboardReducer.modalBookingDesk;

export const modalBookingRoomSelector = (state: any) =>
  state.DashboardReducer.modalBookingRoom;

export const ModalCreateWalkInsSelector = (state: any) =>
  state.DashboardReducer.modalCreateWalkIns;

export const dashboardStatisticFilterSelector = (state: any) =>
  state.DashboardReducer.dashboardStatisticFilter;

export const freeCreditSelector = (state: any) =>
  state.DashboardReducer.freeCredit;

export const modalBusinessCompanyProfileIncompleteSelector = (state: any) =>
  state.DashboardReducer.modalBusinessCompanyProfileIncomplete;

export const dashboardDeskimoDeskUsageFilterSelector = (state: any) =>
  state.DashboardReducer.dashboardDeskimoDeskUsageFilter;

export const dashboardDeskimoDeskBookingFilterSelector = (state: any) =>
  state.DashboardReducer.dashboardDeskimoDeskBookingFilter;

export const dashboardDeskimoRoomBookingFilterSelector = (state: any) =>
  state.DashboardReducer.dashboardDeskimoRoomBookingFilter;

export const transactionInfoSelector = (state: any) =>
  state.DashboardReducer.transactionInfo;

export const ModalPartnerCreateWalkInsSelector = (state: any) =>
  state.DashboardReducer.modalPartnerCreateWalkIns;

export const filterListPropertySelector = (state: any) =>
  state.DashboardReducer.filterListProperty;

export const filterListPropertySelectedSelector = (state: any) =>
  state.DashboardReducer.filterListPropertySelected;
