import { Routes, Route } from "react-router-dom";
import {
  DESK_BOOKING_LINK,
  ROOM_BOOKING_LINK,
} from "../../App/configs/ListRoutes";
import BookingTabs from "../views/Tabs";

const BookRoutes = () => {
  return (
    <Routes>
      <Route path={DESK_BOOKING_LINK} element={<BookingTabs tabIndex={0} />} />
      <Route path={ROOM_BOOKING_LINK} element={<BookingTabs tabIndex={1} />} />
    </Routes>
  );
};

export default BookRoutes;
