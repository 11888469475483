import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalRightSideBarTemplate from "../ModalRightSideBarTemplate";
import { isModalNavbarMobileSelector } from "../../selectors/AppSelectors";
import { modalNavbarMobileAction } from "../../actions/AppActions";
import {
  DASHBOARD_PROPERTY_LINK,
  EMPLOYEES_PROPERTY_LINK,
  INVOICE_PROPERTY_LINK,
  PROPERTY_LIST_LINK,
} from "../../configs/ListRoutes";
import { PROPERTY_USER_GUIDE, PUBLIC_URL } from "../../configs/Constants";
import { Link, NavLink, useNavigate } from "react-router-dom";
import ButtonCustom from "../../../../components/Button";
import "./style.scss";
import { LocalizationContext } from "../../../../locales/Translation";
import { usePermissionCheck } from "../../../../utils/custom-hooks";
import {
  COMPANY_PROPERTY_USER_PER,
  INVOICE_MANAGE_PER,
  UI_PROPERTY_MANAGE_PER,
} from "../../../../utils/PermissionConstant";

const NavBarPropertyMobile = () => {
  const { translations } = useContext(LocalizationContext);

  const dispatch = useDispatch();
  const isModalNavbarMobile = useSelector(isModalNavbarMobileSelector);
  const navigate = useNavigate();

  const _onClick = (pathName: string) => {
    navigate(pathName, { replace: true });
    dispatch(modalNavbarMobileAction(false));
  };

  return (
    <ModalRightSideBarTemplate
      trigger={null}
      onClose={() => {
        dispatch(modalNavbarMobileAction(false));
      }}
      modalClassName={"modal-edit-employee"}
      open={isModalNavbarMobile}
    >
      <div className={"navbar-mobile__list"}>
        <div className={"d-flex justify-content-between"}>
          <Link to={DASHBOARD_PROPERTY_LINK} className={"navbar__logo"}>
            <img src={PUBLIC_URL + "/assets/logo.svg"} alt="deskimo-logo" />
          </Link>
          <ButtonCustom
            onClick={() => dispatch(modalNavbarMobileAction(false))}
            className={"m-0 p-0 none-bg close-modal"}
          >
            <>
              {translations.close}
              <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
            </>
          </ButtonCustom>
        </div>

        <div className={"navbar-mobile__links"}>
          <div
            className={"navbar-mobile__link"}
            onClick={() => {
              _onClick(DASHBOARD_PROPERTY_LINK);
            }}
          >
            <i className="uil uil-chart" />
            {translations.navBar.dashboard}
          </div>
          {usePermissionCheck(UI_PROPERTY_MANAGE_PER) && (
            <div
              className={"navbar-mobile__link"}
              onClick={() => {
                _onClick(PROPERTY_LIST_LINK);
              }}
            >
              <i className="uil uil-building" />
              {translations.properties}
            </div>
          )}
          {usePermissionCheck(COMPANY_PROPERTY_USER_PER) && (
            <div
              className={"navbar-mobile__link"}
              onClick={() => {
                _onClick(EMPLOYEES_PROPERTY_LINK);
              }}
            >
              <i className="uil uil-users-alt" />
              {translations.users}
            </div>
          )}
          {usePermissionCheck(INVOICE_MANAGE_PER) && (
            <div
              className={"navbar-mobile__link"}
              onClick={() => {
                _onClick(INVOICE_PROPERTY_LINK);
              }}
            >
              <i className="uil uil-setting" />
              {translations.invoices}
            </div>
          )}
          <a
            className={"navbar-mobile__link"}
            href={PROPERTY_USER_GUIDE}
            target={"_blank"}
            rel="noreferrer"
          >
            <i className="bx bx-help-circle" />
            {translations.help}
          </a>
        </div>
      </div>
    </ModalRightSideBarTemplate>
  );
};

export default NavBarPropertyMobile;
