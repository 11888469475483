// GET OTP TYPE
export const GET_OTP_SMS = 1;
export const GET_OTP_CALL = 2;
export const GET_OTP_WHATSAPP = 3;
export const TIME_SHOW_RESEND = 25; // Second
export const TYPE_ACCEPT = "TYPE_ACCEPT";
export const TYPE_REJECT = "TYPE_REJECT";

export const MODAL_PAID_TRIAL_PACKAGE_ACTION =
  "MODAL_PAID_TRIAL_PACKAGE_ACTION";

export const MODAL_TOP_PROPERTIES_ACTION = "MODAL_TOP_PROPERTIES_ACTION";
export const PACKAGE_TRIAL_INFO_ACTION = "PACKAGE_TRIAL_INFO_ACTION";
export const TOP_PROPERTIES_INFO_ACTION = "TOP_PROPERTIES_INFO_ACTION";
