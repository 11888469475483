import { sha256 } from "js-sha256";

export const getValidTimeStamp = (timestamp?: number) => {
  if (!timestamp) {
    return;
  }

  if (timestamp.toString().length > 10) {
    return timestamp;
  }
  return timestamp * 1000;
};

export function fileValidation(files: FileList | null) {
  if (files && files.length > 0) {
    for (let i = 0; i <= files.length - 1; i++) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const fsize = files.item(i).size;
      const file = Math.round(fsize / 1024);
      // The size of the file.
      if (file >= 2049) {
        return false;
      } else {
        return true;
      }
    }
  }
}

export function dataURItoBlob(dataURI: string) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString });
  return blob;
}

export const chunksOf = (string: string, size: number) => {
  let i, j;
  const arr = [];
  for (i = 0, j = string.length; i < j; i += size) {
    arr.push(string.substring(i, i + size));
  }
  return arr;
};

export const _checkValue = (str: string, max: number) => {
  if (str.charAt(0) !== "0" || str === "00") {
    let num = parseInt(str);
    if (isNaN(num) || num <= 0 || num > max) {
      num = 1;
    }
    str =
      num > parseInt(max.toString().charAt(0)) && num.toString().length === 1
        ? "0" + num
        : num.toString();
  }
  return str;
};

export function isMobile() {
  return window.innerWidth < 992;
}

export function containWords(text: string, needles: string[]) {
  if (text === undefined) {
    return false;
  }
  return needles.some((needle: string) => text.includes(needle));
}

export function isNumeric(str: string) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export function encryptSHA256(text: string) {
  return sha256(text);
}

export function isValidUrl(url: string) {
  const urlPattern =
    /^(?:https?:\/\/)?(?:www\.)?[\w.-]+\.[a-zA-Z]{2,}(?:\/[\w-]*)*\/?$/;
  return urlPattern.test(url);
}

export function getUrlParameter(sParam: any) {
  const sPageURL = window.location.search.substring(1);
  const sURLVariables = sPageURL.split("&");
  let sParameterName;

  for (let i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }
  return false;
}

export function areObjectsEqual(
  obj1: Record<string, any>,
  obj2: Record<string, any>
): boolean {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

export const isObject = (obj: any) => {
  return obj != null && obj.constructor.name === "Object";
};

export const generateGoogleMapsLink = (address: string) => {
  // Encode the address to make it URL-safe
  const encodedAddress = encodeURIComponent(address);

  // Construct the Google Maps URL with the encoded address
  return `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
};

export const findTabIndexByName = (panes: any, name: string) => {
  if (panes && panes?.length && panes?.length > 0)
    return panes.findIndex((pane: any) => {
      return pane.name === name;
    });
};
