import React, { useState } from "react";
import { Modal } from "semantic-ui-react";
import "./styles.scss";
import { PUBLIC_URL } from "../../../App/configs/Constants";
import SizeBox from "../../../../components/SizeBox";
import ButtonCustom from "../../../../components/Button";
import {
  modalPaidTrailPackageSelector,
  packageTrialInfoSelector,
} from "../../selectors/OnboardSelectors";
import { useDispatch, useSelector } from "react-redux";
import {
  modalPaidTrialPackageAction,
  modalTopPropertiesAction,
} from "../../actions/OnboardActions";
import { SweetAlertCommon } from "../../../../components/SweetAlert";
import { SWAL_DELETE_OPTION } from "../../../../components/SweetAlert/configs/SwalConfigs";
import { UserCompanyType } from "../../../User/configs/DeclareTypes";
import { userCompanyChooseSelector } from "../../../User/selectors/UserSelectors";
import {
  getCreditRemainingThunk,
  getMyProfileThunk,
  onboardPromotionInfoThunk,
} from "../../../App/thunks/AppThunk";
import { toastError } from "../../../../components/Toast";
import { getTopRatingPropertiesThunk } from "../../thunks/OnboardThunks";
import { buyTrialPackageService } from "../../../App/services/AppServices";

const ModalPackageTrial = () => {
  const dispatch = useDispatch<any>();
  const modalPaidTrailPackage = useSelector(modalPaidTrailPackageSelector);
  const { whats_app_link }: UserCompanyType = useSelector(
    userCompanyChooseSelector
  );

  const packageTrialInfo = useSelector(packageTrialInfoSelector);
  const userCompanyChoose = useSelector(userCompanyChooseSelector);
  const [loading, setLoading] = useState(false);

  const _onClose = () => {
    dispatch(modalPaidTrialPackageAction(false));
  };

  const _onBuy = async () => {
    try {
      setLoading(true);
      await buyTrialPackageService(userCompanyChoose?.company_id);
      await dispatch(getTopRatingPropertiesThunk());

      dispatch(onboardPromotionInfoThunk());
      dispatch(getCreditRemainingThunk());
      dispatch(getMyProfileThunk(userCompanyChoose?.company_id));

      _onClose();
      dispatch(modalTopPropertiesAction(true));
    } catch (e: any) {
      toastError(e?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const _onCancel = () => {
    _onClose();
    const options = SWAL_DELETE_OPTION({
      title: "Not ready now? No worries!",
      text: `Your trial offer is valid for ${packageTrialInfo?.trial_day_left} more days. Get in touch if you need help choosing the best solution for your team.`,
      confirmText: "Contact Us",
      cancelText: "Close",
      confirmClass: "primary",
    });

    SweetAlertCommon({
      options,
      cancelCallback: () => null,
      confirmCallback: () => window.open(whats_app_link, "blank"),
    });
  };

  return (
    <Modal
      onClose={_onClose}
      open={modalPaidTrailPackage}
      className={"modal-paid-trial-package"}
    >
      <div className="box">
        <div className={"box_content"}>
          <div className={"box_content_left"}>
            <div className={"title"}>
              Start your {packageTrialInfo?.total_trial_day}-day trial now
            </div>
            <div className={"discount-box"}>
              You save{" "}
              <span className={"percent"}>
                {packageTrialInfo?.discount_percent}
              </span>
            </div>
            <div className={"origin-price"}>
              {packageTrialInfo?.credit_amount} credits
            </div>
            <div className={"price"}>
              <span>for</span> {packageTrialInfo?.price}
            </div>
          </div>

          <SizeBox width={"16px"} />

          <img
            alt={"Deskimo"}
            src={PUBLIC_URL + "/assets/images/paidTrialPackage.svg"}
            className={"thumb"}
          />
        </div>

        <SizeBox height={"16px"} />

        <div className={"description"}>
          Your balance is topped up with the same amount upon expiry or when
          your balance falls below {packageTrialInfo?.trigger_amount}.<br />
          {packageTrialInfo?.roll_over
            ? `The new credits can roll over up to ${packageTrialInfo?.roll_over} cycles (30 days per cycle) and are
          topped up monthly, or if your balance falls below
          ${packageTrialInfo?.trigger_amount}.`
            : null}
        </div>
      </div>

      <SizeBox height={"16px"} />

      <ButtonCustom
        onClick={_onBuy}
        height={"36px"}
        width={"100%"}
        className={"primary"}
        loading={loading}
        disabled={loading}
      >
        Start your trial
      </ButtonCustom>

      <SizeBox height={"8px"} />

      <a className={"btn-cancel"} onClick={_onCancel}>
        No, I don’t want to save {packageTrialInfo?.saving_amount}
      </a>

      <div className="break-line" />

      {packageTrialInfo?.saas_fee ? (
        <div className={"description"}>
          If you don’t start your trial within the next{" "}
          {packageTrialInfo?.trial_day_left} days, a monthly nominal fee of{" "}
          {packageTrialInfo?.saas_fee} will be applicable per company to access
          the workspaces.
        </div>
      ) : null}
    </Modal>
  );
};

export default ModalPackageTrial;
