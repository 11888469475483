import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import * as yup from "yup";
import ButtonCustom from "../../../../../components/Button";
import InputCustom from "../../../../../components/Input";
import { Formik, FormikValues } from "formik";
import SizeBox from "../../../../../components/SizeBox";

type Props = {
  handleSetNewPassword: (data: any) => void;
};

const CreateNewPassword = ({ handleSetNewPassword }: Props) => {
  const { translations } = useContext(LocalizationContext);

  const _onSubmit = async (values: FormikValues) => {
    await handleSetNewPassword(values);
  };

  return (
    <div className="business-onboard">
      <div className="title">{translations.buOnboard.createNewPassword}</div>
      <div className="description">
        {translations.buOnboard.createNewPasswordDescription}
      </div>
      <Formik
        initialValues={{
          password: "",
          repeat_password: "",
        }}
        validationSchema={yup.object().shape({
          password: yup.string().required().label(translations.newPassword),
          repeat_password: yup
            .string()
            .oneOf([yup.ref("password"), null], "Password don't match!")
            .required()
            .label(translations.repeatNewPassword),
        })}
        onSubmit={_onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          isSubmitting,
          handleSubmit,
        }) => (
          <>
            <InputCustom
              label={translations.newPassword}
              value={values.password}
              name={"password"}
              onChange={handleChange}
              touched={touched.password}
              error={errors.password}
              placeholder={"Password"}
              passwordShow
              height={"38px"}
            />
            <SizeBox height={"7px"} />
            <InputCustom
              label={translations.reEnterYourNewPassword}
              value={values.repeat_password}
              name={"repeat_password"}
              onChange={handleChange}
              touched={touched.repeat_password}
              error={errors.repeat_password}
              placeholder={"Password"}
              passwordShow
              height={"38px"}
            />
            <SizeBox height={"35px"} />
            <ButtonCustom
              className={"primary"}
              height={"47px"}
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={
                isSubmitting ||
                !values.password.length ||
                !values.repeat_password.length
              }
              type={"button"}
            >
              {translations.submit}
            </ButtonCustom>
          </>
        )}
      </Formik>
    </div>
  );
};

export default CreateNewPassword;
