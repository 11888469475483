import { Dimmer, Loader, Modal, Segment } from "semantic-ui-react";
import React, { useState } from "react";
import "./style.scss";

type Props = {
  children: JSX.Element | JSX.Element[];
  trigger?: JSX.Element | JSX.Element[] | null;
  open: boolean;
  onClose: any;
  modalClassName?: string;
  size?: string;
  loading?: boolean;
};

const ModalRightSideBarTemplate = ({
  children,
  trigger = null,
  open,
  onClose,
  modalClassName = "",
  size,
  loading = false,
}: Props) => {
  const [hide, setHide] = useState(false);

  const _onCloseWithTransition = () => {
    setHide(true);
    setTimeout(() => {
      onClose();
      setHide(false);
    }, 300);
  };

  return (
    <Modal
      onClose={_onCloseWithTransition}
      open={open}
      trigger={trigger}
      className={
        "modal-right-sidebar-template " +
        modalClassName +
        " " +
        size +
        (hide ? " hide " : "")
      }
    >
      {children}
      {loading ? (
        <Segment className={"modal-right-sidebar__loading"}>
          <Dimmer active>
            <Loader />
          </Dimmer>
        </Segment>
      ) : null}
    </Modal>
  );
};

export default ModalRightSideBarTemplate;
