import React, { useEffect } from "react";
import {
  changeBookingStatusService,
  handleCheckBookingStatusService,
} from "../../services/AuthenticationServices";
import { toastError } from "../../../../components/Toast";
import { DASHBOARD_DESKIMO_LINK } from "../../../App/configs/ListRoutes";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "../../../../utils/custom-hooks";
import LoaderCustom from "../../../../components/Loader";
import { SWAL_CONFIRM_OPTION } from "../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../components/SweetAlert";
import { PUBLIC_URL } from "../../../App/configs/Constants";

const BookingApproval = () => {
  const { token } = useParams();
  const queryParams = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await handleCheckBookingStatusService(
        queryParams.get("type"),
        token,
        navigate,
        _handleSave
      );
    })();
  }, []);

  const _handleSave = async () => {
    try {
      const type = queryParams.get("type");

      if (!type || !token) {
        return;
      }

      await changeBookingStatusService(type, token);

      const iconHtml = (
        <img src={PUBLIC_URL + "/assets/icons/icon-check-green.svg"} />
      );

      const options = SWAL_CONFIRM_OPTION({
        iconHtml,
        title: "Approved Successfully!",
        confirmText: "OK",
      });
      SweetAlertCommon({
        options: { ...options, allowOutsideClick: false },
        confirmCallback: () => navigate(DASHBOARD_DESKIMO_LINK),
      });
    } catch (e: any) {
      toastError(e?.status?.message);
    }
  };

  return <LoaderCustom />;
};

export default BookingApproval;
