export const employeesSelector = (state: any) =>
  state.EmployeeReducer.employees;

export const employeesFilterSelector = (state: any) =>
  state.EmployeeReducer.employeesFilter;

export const usersSelector = (state: any) => state.EmployeeReducer.users;

export const usersFilterSelector = (state: any) =>
  state.EmployeeReducer.usersFilter;

export const isModalEditEmployeeSelector = (state: any) =>
  state.EmployeeReducer.isModalEditEmployee;

export const employeeDetailSelector = (state: any) =>
  state.EmployeeReducer.employeeDetail;

export const isModalDetailEmployeeSelector = (state: any) =>
  state.EmployeeReducer.isModalDetailEmployee;

export const employeeGoogleSelector = (state: any) =>
  state.EmployeeReducer.employeeGoogle;

export const isModalImportEmployeeGoogleSelector = (state: any) =>
  state.EmployeeReducer.isModalImportEmployeeGoogle;

export const employeeGoogleChooseSelector = (state: any) =>
  state.EmployeeReducer.employeeGoogleChoose;

export const modalCreateDeskimoAdminSelector = (state: any) =>
  state.EmployeeReducer.modalCreateDeskimoAdmin;
