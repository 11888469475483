import React, { useContext } from "react";
import { PUBLIC_URL } from "../../../App/configs/Constants";
import { LocalizationContext } from "../../../../locales/Translation";
import ButtonCustom from "../../../../components/Button";
import SizeBox from "../../../../components/SizeBox";
import { useDispatch, useSelector } from "react-redux";
import { modalPrivateOfficeAction } from "../../actions/BookActions";
import { marketHasPrivateOfficesSelector } from "../../selectors/BookSelectors";

const FormRight = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const marketHasPrivateOffices = useSelector(marketHasPrivateOfficesSelector);

  return (
    <div className={"book-inquire_right"}>
      <div className={"content"}>
        <div className={"top-title"}>
          <img
            alt={"Deskimo"}
            src={PUBLIC_URL + "/assets/images/inquire/building-square-blue.svg"}
          />
          {translations.DeskimoFlexiOffice}
        </div>
        <div className={"title"}>
          {translations.inquireTitle1}
          <br />
          {translations.inquireTitle2}
        </div>
        <div className={"description"}>{translations.inquireDescription1}</div>

        <div className={"list-info"}>
          <img
            alt={"Deskimo"}
            src={
              PUBLIC_URL + "/assets/images/inquire/icon-check-circle-blue.svg"
            }
          />
          {translations.inquireSub1}
        </div>

        <div className={"list-info"}>
          <img
            alt={"Deskimo"}
            src={
              PUBLIC_URL + "/assets/images/inquire/icon-check-circle-blue.svg"
            }
          />
          {translations.inquireSub2}
        </div>

        <div className={"list-info"}>
          <img
            alt={"Deskimo"}
            src={
              PUBLIC_URL + "/assets/images/inquire/icon-check-circle-blue.svg"
            }
          />
          {translations.inquireSub3}
        </div>

        <SizeBox height={"16px"} />
        {marketHasPrivateOffices ? (
          <div className={"inquire__right-actions-group"}>
            <div className={"button-groups"}>
              <ButtonCustom
                fontSize={"16px"}
                width={"100%"}
                className={"primary mr-0"}
                onClick={() =>
                  dispatch(modalPrivateOfficeAction({ isOpen: true }))
                }
              >
                {translations.InquireNow}
              </ButtonCustom>
              <SizeBox width={"8px"} />
              <ButtonCustom
                fontSize={"16px"}
                width={"100%"}
                className={"yellow mr-0"}
                onClick={() =>
                  window.open(
                    "https://www.deskimo.com/flexi-private-offices/",
                    "_blank"
                  )
                }
              >
                {translations.LearnMore}
              </ButtonCustom>
            </div>
            <div>
              <a
                className={"button-custom link"}
                href="https://www.deskimo.com/flexioffice/#savings"
                target={"_blank"}
                rel="noreferrer"
              >
                <i className="uil uil-calculator"></i>
                Calculate Savings
              </a>
            </div>
          </div>
        ) : null}
      </div>
      <img
        alt={"Deskimo"}
        className={"symbol"}
        src={PUBLIC_URL + "/assets/images/inquire/inquire-symbol.png"}
      />
    </div>
  );
};

export default FormRight;
