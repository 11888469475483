import "./styles.scss";
import React from "react";
import LoginTemplate from "../../../App/views/LoginTemplate";
import SizeBox from "../../../../components/SizeBox";
import { Link } from "react-router-dom";
import { PUBLIC_URL } from "../../../App/configs/Constants";
import { ACCOUNT_CREATE_INDIVIDUAL_LINK } from "../../../App/configs/ListRoutes";

const AccountCreateOption = () => {
  return (
    <LoginTemplate className={"account-create"}>
      <h1 className={"account-create__title"}>Welcome,</h1>
      <p className={"account-create__des"}>
        Please let us know what is your preference
      </p>
      <SizeBox height={32} />
      <div className={"account-create__wrapper"}>
        <div className={"account-create__box"}>
          <img
            className={"thumb"}
            src={PUBLIC_URL + "/assets/images/individual-option.svg"}
          />
          <p className={"title"}>Individual Account</p>
          <p className={"des"}>
            You work independently, without the need to manage a team and
            budgets.
          </p>
          <SizeBox height={20} />
          <p className={"des"}>
            Pay for Deskimo using your personal credit/debit card or mobile
            digital payments.
          </p>
          <SizeBox height={16} />
          <div style={{ flex: 1 }} />
          <Link
            to={ACCOUNT_CREATE_INDIVIDUAL_LINK}
            className="ui button button-custom primary"
            style={{ height: 47, width: "100%" }}
          >
            I need an Individual Account
          </Link>
        </div>
        <div className={"account-create__box"}>
          <img
            className={"thumb"}
            src={PUBLIC_URL + "/assets/images/business-option.svg"}
          />
          <p className={"title"}>Business Account</p>
          <p className={"des"}>
            You work with a team, overseeing budget allocation and coordinating
            workspace logistics for multiple employees.
          </p>
          <SizeBox height={20} />
          <p className={"des"}>
            You require streamlined invoicing for company claims, payment
            consolidation options and budget management tools.
          </p>
          <SizeBox height={16} />
          <a
            href={process.env.REACT_APP_ADMIN_URL + "/business/onboard"}
            className="ui button button-custom primary"
            style={{ height: 47, width: "100%" }}
          >
            I need a Business Account
          </a>
        </div>
      </div>
    </LoginTemplate>
  );
};

export default AccountCreateOption;
