import React, { useContext, useState, useEffect } from "react";
import { modalBookingSuccessAction } from "../../../actions/BookActions";
import classNames from "classnames";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../components/Button";
import ModalCenterTemplate from "../../../../App/views/ModalCenterTemplate";
import { useDispatch, useSelector } from "react-redux";
import { modalBookingSuccessSelector } from "../../../selectors/BookSelectors";
import { LocalizationContext } from "../../../../../locales/Translation";
import { bookingRoomDetailService } from "../../../services/BookServices";
import moment from "moment-timezone";
import {
  FORMAT_DATE_TIME_VARIANT_3,
  FORMAT_DATE_TIME_VARIANT_6,
  FORMAT_TIME_VARIANT_1,
} from "../../../../App/configs/DateTimeConfigs";
import { toastError } from "../../../../../components/Toast";
import { useNavigate } from "react-router-dom";
import { ROOM_BOOKING_LINK } from "../../../../App/configs/ListRoutes";
import Divide from "../../../../../components/Divide";
import ParkingRequest from "../ModalBookingSubmittedDesk/ParkingRequest";
import { ROOM_BOOKING } from "../../../../Dashboard/configs/Constants";

const ModalBookingSubmitted = () => {
  const dispatch = useDispatch();
  const modalBookingSuccess = useSelector(modalBookingSuccessSelector);
  const { translations } = useContext(LocalizationContext);
  const bookingData = modalBookingSuccess?.data;
  const [bookingDetail, setBookingDetail] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchBookingDetailInfo();
  }, [modalBookingSuccess]);

  const fetchBookingDetailInfo = async () => {
    try {
      if (bookingData?.booking_id) {
        const response = await bookingRoomDetailService(
          bookingData?.booking_id
        );
        setBookingDetail(response?.data);
      }
    } catch (error: any) {
      toastError(error?.status?.message);
    }
  };

  const _onClose = () => {
    setBookingDetail(null);
    dispatch(modalBookingSuccessAction({ open: false, data: null }));
  };

  return (
    <ModalCenterTemplate
      trigger={null}
      onClose={_onClose}
      modalClassName={classNames("modal-center-v2 modal-booking-success")}
      open={modalBookingSuccess?.open}
    >
      <Modal.Header>
        <i className="uil uil-check-circle" />
        Booking Submitted
      </Modal.Header>
      <Modal.Content>
        <div>
          <div className={"modal-booking-success__thumbnail"}>
            <img src={bookingDetail?.thumbnail} alt="" />
          </div>
          <div className={"d-flex flex-column gap-20"} style={{ padding: 24 }}>
            <div className={"modal-booking-success__thankyou"}>
              <div className="title">Thank you for booking with us</div>
              <div className="content">
                Your booking has been successfully submitted. Workspaces
                normally confirm reservations within 60 min during working
                hours. We will notify you via email and in the app once your
                reservation has been confirmed.
              </div>
            </div>
            <div className={"modal-booking-success__info"}>
              <div className={"title"}>
                <span className={"title-content"}>
                  {bookingDetail?.meeting_room_name}
                </span>
                <div className={"status-wrapper"}>
                  <span className={"status"}>
                    {translations.roomBookingStatus[bookingDetail?.status_id]}
                  </span>
                </div>
              </div>
              <div className={"item"}>
                <i className="uil uil-building" />
                {bookingDetail?.property_name +
                  " • " +
                  bookingDetail?.brand_name}
              </div>
              <div className={"item"}>
                <i className="uil uil-map" /> {bookingDetail?.address}
              </div>
              <div className={"item"}>
                <i className="uil uil-users-alt" />
                {bookingDetail?.meeting_room_capacity}{" "}
                {bookingDetail?.meeting_room_capacity > 1
                  ? translations.people
                  : translations.person}
              </div>
            </div>

            {bookingDetail?.allow_parking_request ? (
              <>
                <Divide height={"1px"} type={"horizontal"} margin={"0"} />
                <ParkingRequest
                  parkingRequest={bookingDetail?.parking_request}
                  onRefresh={fetchBookingDetailInfo}
                  bookingId={bookingData?.booking_id}
                  bookingType={ROOM_BOOKING}
                />
              </>
            ) : null}

            <Divide height={"1px"} type={"horizontal"} margin={"0"} />
            <div className={"modal-booking-success__detail p-0"}>
              <div className={"title"}>Booking Details</div>
              <div className={"select-item"}>
                <div className={"text"}>Booking Reference</div>
                <div className={"value"}>
                  {bookingDetail?.booking_reference
                    ? bookingDetail?.booking_reference
                    : ""}
                </div>
              </div>
              <div className={"select-item"}>
                <div className={"text"}>Date</div>
                <div className={"value"}>
                  {bookingDetail?.booking_start_time
                    ? moment
                        .tz(
                          bookingDetail?.booking_start_time * 1000,
                          bookingDetail?.timezone
                        )
                        .format(FORMAT_DATE_TIME_VARIANT_3)
                    : ""}
                </div>
              </div>
              <div className={"select-item"}>
                <div className={"text"}>Booking Time</div>
                <div className={"value"}>
                  {bookingDetail?.booking_start_time
                    ? moment
                        .tz(
                          bookingDetail?.booking_start_time * 1000,
                          bookingDetail?.timezone
                        )
                        .format(FORMAT_TIME_VARIANT_1) +
                      " - " +
                      moment
                        .tz(
                          bookingDetail?.booking_end_time * 1000,
                          bookingDetail?.timezone
                        )
                        .format(FORMAT_TIME_VARIANT_1)
                    : ""}
                </div>
              </div>
              <div className={"select-item"}>
                <div className={"text"}>Duration</div>
                <div className={"value"}>{bookingDetail?.duration}</div>
              </div>
              <div className={"select-item"}>
                <div className={"text"}>Hourly Rate</div>
                <div className={"value"}>
                  {bookingDetail?.currency} {bookingDetail?.rate_per_hour}
                </div>
              </div>
            </div>
            <Divide height={"1px"} type={"horizontal"} margin={"0"} />
            <div className={"modal-booking-success__detail p-0"}>
              <div className={"title"}>Payment</div>
              <div className={"select-item"}>
                <div className={"text"}>{bookingDetail?.payment_type}</div>
                <div className={"value"}>{bookingDetail?.payment}</div>
              </div>
              <div className={"select-item"}>
                <div className={"text"}>Time</div>
                <div className={"value"}>
                  {bookingDetail?.booking_start_time
                    ? moment
                        .tz(bookingDetail?.time * 1000, bookingDetail?.timezone)
                        .format(FORMAT_DATE_TIME_VARIANT_6)
                    : ""}
                </div>
              </div>
            </div>

            {bookingDetail?.vouchers[0]?.code ? (
              <>
                <Divide height={"1px"} type={"horizontal"} margin={"0"} />
                <div
                  className={
                    "modal-booking-success__detail d-flex flex-column gap-24 p-0"
                  }
                >
                  {/*<div className={"title"}></div>*/}
                  {bookingDetail?.vouchers[0]?.code ? (
                    <div className={"select-item mb-0"}>
                      <div className={"text title mb-0"}>Applied Voucher</div>
                      <div className={"value"}>
                        <div className={"voucher-chip"}>
                          {bookingDetail?.vouchers[0]?.code}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className={"select-item mb-0"}>
                    <div className={"text title mb-0"}>Subtotal</div>
                    <div className={"value"} style={{ fontSize: 20 }}>
                      {bookingDetail?.currency}{" "}
                      {bookingDetail?.price_before_discount}
                    </div>
                  </div>
                  <div className={"select-item mb-0"}>
                    <div className={"text title mb-0"}>Discount</div>
                    <div className={"value"} style={{ fontSize: 20 }}>
                      - {bookingDetail?.currency} {bookingDetail?.discount}
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            <Divide height={"1px"} type={"horizontal"} margin={"4px 0"} />
            <div className={"modal-booking-success__total"}>
              <div className={"title"}>Total Amount Paid</div>
              <div className={"value"}>
                {bookingDetail?.currency} {bookingDetail?.total_final_price}
              </div>
            </div>

            <div className={"modal-booking-success__actions"}>
              <ButtonCustom
                fontSize={"16px"}
                className={"primary"}
                height={"60px"}
                onClick={() => {
                  _onClose();
                  navigate(ROOM_BOOKING_LINK);
                }}
              >
                View my bookings
              </ButtonCustom>
            </div>
          </div>
        </div>
      </Modal.Content>
    </ModalCenterTemplate>
  );
};

export default ModalBookingSubmitted;
