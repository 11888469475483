import React, { useContext } from "react";
import InputCustom from "../../../../../../components/Input";
import { getIn } from "formik";
import VoucherCurrency from "./VoucherCurrency";
import { CampaignCreateEditContext } from "../../../../reducers/CampaignCreateEditContext";

const VoucherCreditPackage = () => {
  const { errors, touched, isSubmitting, handleChange, setFieldValue, values } =
    useContext(CampaignCreateEditContext);

  return (
    <div className={"campaign-create-type"}>
      <InputCustom
        label={"Percentage Value (%)"}
        labelRequired
        value={values.voucher_reward.value}
        name={"voucher_reward.value"}
        onChange={handleChange}
        error={getIn(errors, "voucher_reward.value")}
        touched={getIn(touched, "voucher_reward.value")}
        disabled={isSubmitting}
      />
      <VoucherCurrency />

      <InputCustom
        label={`Promotion Limit (${values.voucher_reward.currency})`}
        labelGuide={
          "Apply promotion to credit packages below or equal a specified credit amount."
        }
        value={values.voucher_reward.percentage_max_redeem_value}
        name={"voucher_reward.percentage_max_redeem_value"}
        onChange={handleChange}
        error={getIn(errors, "voucher_reward.percentage_max_redeem_value")}
        touched={getIn(touched, "voucher_reward.percentage_max_redeem_value")}
        disabled={isSubmitting}
      />

      <InputCustom
        label={`Credit amount (${values.voucher_reward.currency})`}
        value={values.voucher_reward.credit_amount}
        name={"voucher_reward.credit_amount"}
        onChange={handleChange}
        error={getIn(errors, "voucher_reward.credit_amount")}
        touched={getIn(touched, "voucher_reward.credit_amount")}
        disabled={isSubmitting}
        removeLetter
      />

      <InputCustom
        label={"Duration"}
        value={values.voucher_reward.duration}
        name={"voucher_reward.duration"}
        onChange={handleChange}
        error={getIn(errors, "voucher_reward.duration")}
        touched={getIn(touched, "voucher_reward.duration")}
        disabled={isSubmitting}
        removeLetter
      />

      <InputCustom
        label={"Roll over"}
        value={values.voucher_reward.roll_over}
        name={"voucher_reward.roll_over"}
        onChange={handleChange}
        error={getIn(errors, "voucher_reward.roll_over")}
        touched={getIn(touched, "voucher_reward.roll_over")}
        disabled={isSubmitting}
        removeLetter
      />
    </div>
  );
};

export default VoucherCreditPackage;
