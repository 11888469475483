import React, { useContext, useEffect, useState } from "react";
import Label from "../../../../../components/Label";
import InputCustom from "../../../../../components/Input";
import { LocalizationContext } from "../../../../../locales/Translation";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import SizeBox from "../../../../../components/SizeBox";
import DropdownCustom from "../../../../../components/DropdownCustom";
import { tagsListService } from "../../../../Configuration/services/ConfigServices";

type Props = {
  values: FormikValues;
  setFieldValue: any;
};

const Tags = ({ values, setFieldValue }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const [tagOptions, setTagOptions] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await tagsListService({ page: 1 });

        const tags = data?.items?.map(
          ({ id, name }: { id: number; name: string }) => ({
            key: id,
            text: name,
            value: id,
          })
        );
        setTagOptions(tags);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <>
      <Label
        label={"Company can access to workspaces with the following tags"}
        fontSize={"24px"}
        className={"section-title"}
      />
      <div className={"detail_divide"}>
        <div className={"left_section"}>
          <DropdownCustom
            selectUI
            options={tagOptions}
            value={values.tag_ids}
            multiple
            className={"w-100"}
            onChange={(event, { value }) => {
              setFieldValue("tag_ids", value);
            }}
          />
        </div>
      </div>

      <SizeBox height={"24px"} />
      <div className={"property_company_detail_line"} />
    </>
  );
};

export default Tags;
