import { createReducer } from "@reduxjs/toolkit";
import {
  USER_COMPANY_ACTION,
  USER_COMPANY_CHOOSE_ACTION,
  USER_COMPANY_CHOOSE_UPDATE_CREDIT_ACTION,
} from "../configs/Constants";

const initialState = {
  userCompany: [],
  userCompanyChoose: null,
};
const UserReducer = createReducer(initialState, {
  [USER_COMPANY_ACTION]: (state, action) => {
    state.userCompany = action.payload;
  },
  [USER_COMPANY_CHOOSE_ACTION]: (state, action) => {
    state.userCompanyChoose = action.payload;
  },
  [USER_COMPANY_CHOOSE_UPDATE_CREDIT_ACTION]: (state, action) => {
    if (!state.userCompany.length) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.userCompany = state.userCompany.map((item: any) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (item?.company_id === state.userCompanyChoose?.company_id) {
        return { ...item, ...{ has_credit_card: true } };
      }

      return item;
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.userCompanyChoose.has_credit_card = true;
  },
});

export default UserReducer;
