import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import {
  GET_OTP_CALL,
  GET_OTP_SMS,
  GET_OTP_WHATSAPP,
  TIME_SHOW_RESEND,
} from "../../../configs/Constants";
import SizeBox from "../../../../../components/SizeBox";

type Props = { resendOTP: ({ type }: { type: number }) => void };

const VerifyOTPResend = ({ resendOTP }: Props) => {
  const { translations } = useContext(LocalizationContext);

  const [isResendAvailable, setIsResendAvailable] = useState<boolean>(false);
  const [countDown, setCountDown] = useState<number>(TIME_SHOW_RESEND);

  useEffect(() => {
    if (isResendAvailable) {
      return;
    }
    setCountDown(TIME_SHOW_RESEND);
    const timeOut = setInterval(async () => {
      setCountDown((preState) => {
        if (preState < 2) {
          clearInterval(timeOut);
          setIsResendAvailable(true);
        }
        return preState - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timeOut);
    };
  }, [isResendAvailable]);

  const _onPressSMS = async () => {
    setIsResendAvailable(false);
    await resendOTP({ type: GET_OTP_SMS });
  };

  const _onPressCall = async () => {
    setIsResendAvailable(false);
    await resendOTP({ type: GET_OTP_CALL });
  };

  const _onPressWhatsApp = async () => {
    setIsResendAvailable(false);
    await resendOTP({ type: GET_OTP_WHATSAPP });
  };

  if (!isResendAvailable) {
    return (
      <div className={"sub-label"}>
        {translations.buOnboard.didNotReceiveVerificationOTP}{" "}
        {translations.buOnboard.resendIn} {countDown}{" "}
        {translations.buOnboard.seconds}
      </div>
    );
  }

  return (
    <>
      <div className={"sub-label"}>
        {translations.buOnboard.didNotReceiveVerificationOTP}&nbsp;
        <button onClick={_onPressSMS}>
          {translations.buOnboard.resendAgain}
        </button>
      </div>
      <SizeBox height={"12px"} />

      <div className={"sub-label"}>Try another methods</div>
      <SizeBox height={"8px"} />
      <div className={"sub-label"}>
        <button onClick={_onPressCall}>
          <i className="uil uil-phone" />
          Receive a Call
        </button>
        &nbsp;or&nbsp;
        <button onClick={_onPressWhatsApp}>
          <i className="uil uil-whatsapp" />
          WhatsApp message
        </button>
      </div>
    </>
  );
};

export default VerifyOTPResend;
