import React, { useContext } from "react";
import CheckboxCustom from "../../../../../../../components/Checkbox";
import { LocalizationContext } from "../../../../../../../locales/Translation";
import SizeBox from "../../../../../../../components/SizeBox";

type Props = {
  closeOnHoliday?: boolean;
  setCloseOnHoliday: any;
};

const ScheduleHoliday = ({ closeOnHoliday, setCloseOnHoliday }: Props) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <>
      <SizeBox height={"16px"} />
      <div className={"schedule_holiday"}>
        <CheckboxCustom
          label={translations.closedOnPublicHolidays}
          checked={closeOnHoliday}
          onChange={() => {
            setCloseOnHoliday((prevState: boolean) => !prevState);
          }}
        />
      </div>
    </>
  );
};

export default ScheduleHoliday;
