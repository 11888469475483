import React from "react";
import {
  modalStartUserGuideAction,
  userGuideAction,
} from "../../../../App/actions/AppActions";
import { Modal } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import "../styles.scss";
import SizeBox from "../../../../../components/SizeBox";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import { modalStartUserGuideSelector } from "../../../../App/selectors/AppSelectors";
import { updateNewUser } from "../../../../User/services/UserServices";

const ModalStartUserGuide = () => {
  const dispatch = useDispatch();

  const modalStartUserGuide = useSelector(modalStartUserGuideSelector);

  const _onClose = () => {
    dispatch(modalStartUserGuideAction(false));
    updateNewUser(); // Call async;
  };

  const _openGuide = () => {
    _onClose();

    dispatch(userGuideAction({ key: "run", value: true }));
    dispatch(userGuideAction({ key: "stepIndex", value: 0 }));
  };

  return (
    <Modal
      onClose={_onClose}
      open={modalStartUserGuide}
      className={"modal-start-user-guide"}
    >
      <h1 className={"header-title"}>Hey there!</h1>
      <h4 className={"header-description"}>
        Would you like us to give you a tour?
      </h4>

      <div className={"box"}>
        <div className={"box-item"} onClick={_openGuide}>
          <img src={PUBLIC_URL + "/assets/images/user-guide-information.svg"} />
          <p>Have a tutorial</p>
        </div>
        <SizeBox width={"16px"} />
        <div className={"box-item"} onClick={_onClose}>
          <img src={PUBLIC_URL + "/assets/images/on-my-way.svg"} />
          <p>Explore yourself</p>
        </div>
      </div>
    </Modal>
  );
};

export default ModalStartUserGuide;
