import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import DropdownWithCheckboxMenuWithTotal from "../../../../../../components/DropdownWithCheckboxMenuWithTotal";

const FilterStatus = ({ onChangeFilter, filters }: any) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <div className={"dropdown-filter"}>
      <DropdownWithCheckboxMenuWithTotal
        values={filters}
        onChange={onChangeFilter}
        listCheckBox={[
          { id: 0, name: "Unpublished" },
          { id: 1, name: "Published" },
        ]}
        label={translations.filters}
        icon={
          <img
            alt={"Deskimo"}
            src={PUBLIC_URL + "/assets/icons/icon-filter.svg"}
          />
        }
      />
    </div>
  );
};

export default FilterStatus;
