import React from "react";
import Label from "../../../../../../components/Label";
import SizeBox from "../../../../../../components/SizeBox";

type Props = {
  name: string;
  description: string;
  iconUrl: string;
};

const IntegrationName = ({ name, iconUrl, description }: Props) => {
  return (
    <div className={"integration_name"}>
      <div className={"icon"}>
        <img src={iconUrl} alt={name} />
      </div>
      <div>
        <Label label={name} />
        <SizeBox height={"4px"} />
        <Label label={description} fontSize={"12px"} />
      </div>
    </div>
  );
};

export default IntegrationName;
