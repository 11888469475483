import React from "react";
import { Modal } from "semantic-ui-react";
import "./styles.scss";
import Slider from "react-slick";
import SizeBox from "../../../../components/SizeBox";
import ButtonCustom from "../../../../components/Button";
import { Link, useNavigate } from "react-router-dom";
import {
  BOOK_DESK_DETAIL_LINK,
  BOOK_DESK_LINK,
} from "../../../App/configs/ListRoutes";
import { useDispatch, useSelector } from "react-redux";
import {
  modalTopPropertiesSelector,
  topPropertiesInfoSelector,
} from "../../selectors/OnboardSelectors";
import { modalTopPropertiesAction } from "../../actions/OnboardActions";

const ModalTopProperties = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const topPropertiesInfo = useSelector(topPropertiesInfoSelector);

  const modalTopProperties = useSelector(modalTopPropertiesSelector);

  const _onClose = () => {
    dispatch(modalTopPropertiesAction(false));
  };

  return (
    <Modal
      onClose={_onClose}
      open={modalTopProperties}
      className={"modal-top-properties"}
    >
      <div className={"title"}>Thank you for choosing us!</div>
      <div className={"description"}>
        Your trial credits are successfully issued to your account. Here are
        some of our best workspaces to get you started:
      </div>
      <Slider
        slidesToShow={window.screen.width < 768 ? 2 : 3}
        slidesToScroll={window.screen.width < 768 ? 2 : 3}
        arrows={false}
        infinite={false}
        className={"slick-package-list"}
        dots
        swipe
      >
        {topPropertiesInfo.map(
          ({ id, name, image_url, address }: any, index: number) => (
            <Link
              to={BOOK_DESK_DETAIL_LINK.replaceAll(":id", id)}
              key={index}
              className={"card"}
              onClick={_onClose}
            >
              <img src={image_url} alt={"Deskimo"} />
              <div className={"content"}>
                <div className={"name"} title={name}>
                  {name}
                </div>
                <div className={"address"} title={address}>
                  <i className={"uil uil-location-point"} />
                  {address}
                </div>
              </div>
            </Link>
          )
        )}
      </Slider>
      <SizeBox height={"16px"} />

      <div className={"btn-group"}>
        <ButtonCustom
          height={"28px"}
          className={"cancel"}
          fontSize={"12px"}
          onClick={_onClose}
        >
          Explore on my own
        </ButtonCustom>
        <SizeBox width={"16px"} />
        <ButtonCustom
          onClick={() => {
            _onClose();
            navigate(BOOK_DESK_LINK);
          }}
          height={"28px"}
          className={"primary"}
          fontSize={"12px"}
        >
          Book now
        </ButtonCustom>
      </div>
    </Modal>
  );
};

export default ModalTopProperties;
