import clientRequestService from "../../../services/ClientRequestService";
import {
  ADD_VOUCHER_FOR_BOOKING_API,
  API_SETTING,
  BUY_TRIAL_PACKAGE_API,
  COMPANY_VOUCHER_DELETE_API,
  DELETE_AUTO_TOP_UP_API,
  GET_COMPANY_VOUCHER_API,
  GET_CREDIT_REMAINING_API,
  GET_LIST_VOUCHER_FOR_BOOKING_API,
  GET_MY_PROFILE_API,
  GET_PACKAGE_API,
  MARKET_SETTINGS_API,
  POST_ADD_VOUCHER_API,
  POST_BUY_PACKAGE_API,
  POST_CHANGE_PASSWORD_API,
  POST_INVITE_FILE_API,
  POST_PENDING_BOOKING_NOTIFICATION_API,
  POST_UPDATE_MY_PROFILE_API,
  POST_UPLOAD_FILE,
  POST_UPLOAD_IMAGE,
  POST_VALIDATE_FILE_API,
  REDEEM_VOUCHER_FOR_BOOKING_API,
  REMOVE_VOUCHER_FOR_BOOKING_API,
  UPLOAD_CONTRACT_FILE_API,
} from "../configs/Endpoints";
import {
  CURRENT_COMPANY_ID,
  FAKING_CODE_CACHE,
  PARTNER_MODE_CACHE,
} from "../../../configs/Constants";
import { UserCompanyType } from "../../User/configs/DeclareTypes";
import { useCompanyChooseAction } from "../../User/actions/UserActions";
import { store } from "../configs/Store";

export const getCreditRemainingService = async () => {
  return await clientRequestService({
    isCompany: true,
    method: "get",
    url: GET_CREDIT_REMAINING_API,
  });
};

export const stopAutoTopUpService = async () => {
  return await clientRequestService({
    isCompany: true,
    method: "delete",
    url: DELETE_AUTO_TOP_UP_API,
  });
};

export const getPackageInfoService = async () => {
  return await clientRequestService({
    isCompany: true,
    method: "get",
    url: GET_PACKAGE_API,
  });
};

export const getCompanyVoucherService = async () => {
  return await clientRequestService({
    isCompany: true,
    method: "get",
    url: GET_COMPANY_VOUCHER_API,
  });
};

export const companyVoucherDeleteService = async (
  companyId: string,
  voucherId: string
) => {
  return await clientRequestService({
    method: "DELETE",
    url: COMPANY_VOUCHER_DELETE_API.replaceAll("%companyId%", companyId),
    data: { company_voucher_id: voucherId },
  });
};

export const buyPackageService = async (payload?: any) => {
  return await clientRequestService({
    method: "post",
    url: POST_BUY_PACKAGE_API,
    isCompany: true,
    data: payload,
  });
};

export const buyTrialPackageService = async (companyId: string) => {
  return await clientRequestService({
    method: "post",
    url: BUY_TRIAL_PACKAGE_API.replaceAll("%companyId%", companyId),
  });
};

export const uploadEmployeeFileService = async (payload?: any) => {
  const companyId: any = localStorage.getItem(CURRENT_COMPANY_ID);

  if (!companyId) {
    return;
  }

  return await clientRequestService({
    method: "post",
    url: POST_VALIDATE_FILE_API.replaceAll("%companyId%", companyId),
    data: payload,
    isFile: true,
  });
};

export const inviteEmployeeService = async (payload?: any) => {
  const companyId: any = localStorage.getItem(CURRENT_COMPANY_ID);

  if (!companyId) {
    return;
  }

  return await clientRequestService({
    method: "post",
    url: POST_INVITE_FILE_API.replaceAll("%companyId%", companyId),
    data: payload,
  });
};

export const addVoucherService = async (payload?: any) => {
  return await clientRequestService({
    method: "post",
    url: POST_ADD_VOUCHER_API,
    data: payload,
    isCompany: true,
  });
};

export const changePasswordService = async (payload?: any) => {
  return await clientRequestService({
    method: "post",
    url: POST_CHANGE_PASSWORD_API,
    data: payload,
    isCompany: true,
  });
};

export const getMyProfileService = async (companyId?: any) => {
  const company = companyId ? `/${companyId}` : "";

  const fakingCode = localStorage.getItem(FAKING_CODE_CACHE);
  const subFix = fakingCode ? `?faking_code=${fakingCode}` : "";
  const url = GET_MY_PROFILE_API + company + subFix;
  return await clientRequestService({
    method: "get",
    url,
  });
};

export const updateMyProfileService = async (payload?: any) => {
  return await clientRequestService({
    isCompany: true,
    method: "post",
    url: POST_UPDATE_MY_PROFILE_API,
    data: payload,
  });
};

//Upload image
export const uploadImageService = async (payload?: any) => {
  return await clientRequestService({
    method: "post",
    url: POST_UPLOAD_IMAGE,
    data: payload,
    isFile: true,
  });
};

export const uploadFileService = async (payload?: any) => {
  return await clientRequestService({
    method: "post",
    url: POST_UPLOAD_FILE,
    data: payload,
    isFile: true,
  });
};

export const uploadContractFileService = async (payload?: any) => {
  return await clientRequestService({
    method: "post",
    url: UPLOAD_CONTRACT_FILE_API,
    data: payload,
    isFile: true,
  });
};

export const getSettingService = async () => {
  return await clientRequestService({
    url: API_SETTING,
  });
};

export const getSettingsForMarketService = async () => {
  return await clientRequestService({
    url: MARKET_SETTINGS_API,
  });
};

export const onChooseCompany = (item: UserCompanyType) => {
  const dispatch = store.dispatch;
  dispatch(useCompanyChooseAction(item));
  localStorage.setItem(CURRENT_COMPANY_ID, String(item.company_id));

  if (item?.is_partner) {
    localStorage.setItem(PARTNER_MODE_CACHE, "1");
  } else {
    localStorage.removeItem(PARTNER_MODE_CACHE);
  }
};

export const getPendingBookingNotificationService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: POST_PENDING_BOOKING_NOTIFICATION_API,
    data,
  });
};

export const getVoucherForBookingService = async () => {
  return await clientRequestService({
    method: "post",
    url: GET_LIST_VOUCHER_FOR_BOOKING_API,
    data: {
      position: "CHECKIN",
    },
    isAuth: true,
  });
};

export const addVoucherForBookingService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: ADD_VOUCHER_FOR_BOOKING_API,
    data,
    isAuth: true,
  });
};

export const redeemVoucherForBookingService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: REDEEM_VOUCHER_FOR_BOOKING_API,
    data,
    isAuth: true,
  });
};

export const removeVoucherForBookingService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: REMOVE_VOUCHER_FOR_BOOKING_API,
    data,
    isAuth: true,
  });
};
