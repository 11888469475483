import { toast, ToastContent, ToastOptions } from "react-toastify";
import { PUBLIC_URL } from "../../modules/App/configs/Constants";

export const CloseButtonToast = () => {
  return (
    <button
      className="Toastify__close-button Toastify__close-button--light"
      type="button"
      aria-label="close"
    >
      <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
    </button>
  );
};

export const toastSuccess = (content: ToastContent, options?: ToastOptions) => {
  toast.success(content, {
    hideProgressBar: true,
    ...options,
    icon: () => (
      <img src={PUBLIC_URL + "/assets/icons/icon-check-green-round.svg"} />
    ),
    closeButton: <CloseButtonToast />,
  });
};

export const toastWarning = (content: ToastContent, options?: ToastOptions) => {
  toast.warning(content, {
    hideProgressBar: true,
    ...options,
    icon: () => <img src={PUBLIC_URL + "/assets/icons/icon-warning.svg"} />,
    closeButton: <CloseButtonToast />,
  });
};

export const toastError = (content: ToastContent, options?: ToastOptions) => {
  toast.error(content, {
    hideProgressBar: true,
    ...options,
    closeButton: <CloseButtonToast />,
  });
};
