import clientRequestService from "../../../services/ClientRequestService";
import {
  API_DESKIMO_USER_DELETE,
  API_DESKIMO_USER_DETAIL,
  API_DESKIMO_USER_DETAIL_REVIEW,
  API_DESKIMO_USER_UPDATE,
  API_DESKIMO_USER_USAGE_HISTORY,
  API_DESKIMO_USER_VOUCHER_USAGE_HISTORY,
  API_DESKIMO_USERS,
  API_DESKIMO_USERS_BLACK_LIST,
  API_DESKIMO_USERS_STATUS,
  API_EMPLOYEE_DEACTIVE,
  API_EMPLOYEE_DELETE,
  API_EMPLOYEE_EDIT_INLINE,
  API_EMPLOYEE_IMPORT,
  API_EMPLOYEE_PROFILE,
  API_EMPLOYEE_PROFILE_PROPERTY,
  API_EMPLOYEE_PROPERTY,
  API_EMPLOYEE_PROPERTY_ACTIVE,
  API_EMPLOYEE_PROPERTY_DEACTIVE,
  API_EMPLOYEE_PROPERTY_INVITE,
  API_EMPLOYEE_PROPERTY_REINVITE,
  API_EMPLOYEE_PROPERTY_UPDATE,
  API_EMPLOYEE_REINVITE,
  API_EMPLOYEE_UPDATE,
  API_EMPLOYEE_UPLOAD_AVATAR,
  API_EMPLOYEES,
  API_EMPLOYEES_PROPERTY,
  API_EXTEND_CREDIT,
  API_GET_ALL_COMPANIES,
  API_GET_EMPLOYEE_BY_GOOGLE,
  API_INVITE_EMPLOYEE_BY_GOOGLE,
  API_INVITE_WMS_USER,
  API_RESET_OTP_LIMIT,
  API_SEARCH_PROPERTY,
  API_SEND_OTP_TO_SLACK,
} from "../configs/Endpoints";
import {
  CURRENT_COMPANY_ID,
  METHOD_DELETE,
  METHOD_GET,
  METHOD_POST,
} from "../../../configs/Constants";
import { employeeByGoogleThunk } from "../thunks/EmployeeThunks";
import { modalImportEmployeeGoogleAction } from "../actions/EmployeeActions";
import { CloseButtonToast, toastError } from "../../../components/Toast";
import { store } from "../../App/configs/Store";
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { toast } from "react-toastify";
import { PUBLIC_URL } from "../../App/configs/Constants";

export const getEmployeesService = async (data: object) => {
  return await clientRequestService({
    method: METHOD_POST,
    url: API_EMPLOYEES,
    isCompany: true,
    data,
  });
};

export const getDeskimoUsersService = async (data?: object) => {
  return await clientRequestService({
    method: METHOD_POST,
    url: API_DESKIMO_USERS,
    data,
  });
};

export const deskimoUserStatusService = async (data: {
  status: number;
  id: number;
}) => {
  return await clientRequestService({
    method: "put",
    url: API_DESKIMO_USERS_STATUS,
    data,
  });
};

export const getDeskimoUserUsageService = async (
  data: object,
  userId: string
) => {
  return await clientRequestService({
    method: METHOD_POST,
    url: API_DESKIMO_USER_USAGE_HISTORY.replaceAll("%userId%", userId),
    data,
  });
};

export const getDeskimoUserVoucherUsageService = async (
  data: object,
  userId: string
) => {
  return await clientRequestService({
    method: METHOD_POST,
    url: API_DESKIMO_USER_VOUCHER_USAGE_HISTORY.replaceAll("%userId%", userId),
    data,
  });
};

export const getEmployeesPropertyService = async (data: object) => {
  return await clientRequestService({
    method: METHOD_POST,
    url: API_EMPLOYEES_PROPERTY,
    isCompany: true,
    data,
  });
};

export const getEmployeeDetailService = async (data: object) => {
  return await clientRequestService({
    method: METHOD_POST,
    url: API_EMPLOYEE_PROFILE,
    data,
  });
};

export const getDeskimoUserDetailService = async (userId: string) => {
  return await clientRequestService({
    url: API_DESKIMO_USER_DETAIL.replaceAll("%userId%", userId),
  });
};

export const getDeskimoUserDetailReviewService = async (
  userId: string,
  data: object
) => {
  return await clientRequestService({
    method: "post",
    url: API_DESKIMO_USER_DETAIL_REVIEW.replaceAll("%userId%", userId),
    data,
  });
};

export const updateDeskimoUserDetailService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: API_DESKIMO_USER_UPDATE,
    data,
  });
};

export const getEmployeeDetailPropertyService = async (
  companyId: number,
  userId: number
) => {
  return await clientRequestService({
    url: API_EMPLOYEE_PROFILE_PROPERTY.replaceAll(
      "%companyId%",
      String(companyId)
    ).replaceAll("%userId%", String(userId)),
  });
};

export const employeeImportService = async (
  data: object,
  isSingleInvite = 0
) => {
  const companyId = localStorage.getItem(CURRENT_COMPANY_ID);
  if (!companyId) {
    return;
  }
  return await clientRequestService({
    method: METHOD_POST,
    url:
      API_EMPLOYEE_IMPORT.replaceAll("%companyId%", companyId) +
      "?single_invite=" +
      isSingleInvite,
    data,
  });
};

export const employeePropertyInviteService = async (data: object) => {
  return await clientRequestService({
    method: METHOD_POST,
    url: API_EMPLOYEE_PROPERTY_INVITE,
    data,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    isCompany: !data?.company_id,
  });
};

export const employeeUploadAvatarService = async (data: object) => {
  return await clientRequestService({
    headers: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      "Content-Type": `multipart/form-data`,
    },
    method: METHOD_POST,
    url: API_EMPLOYEE_UPLOAD_AVATAR,
    data,
  });
};

export const employeeUpdateService = async (data: object) => {
  return await clientRequestService({
    method: METHOD_POST,
    url: API_EMPLOYEE_UPDATE,
    data,
  });
};

export const employeePropertyUpdateService = async (data: object) => {
  return await clientRequestService({
    method: METHOD_POST,
    url: API_EMPLOYEE_PROPERTY_UPDATE,
    data,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    isCompany: !data?.company_id,
  });
};

export const employeeEditInlineService = async (data: object) => {
  return await clientRequestService({
    method: METHOD_POST,
    url: API_EMPLOYEE_EDIT_INLINE,
    isCompany: true,
    data,
  });
};

export const employeeReInviteService = async (invitationId: string) => {
  return await clientRequestService({
    method: METHOD_GET,
    url: API_EMPLOYEE_REINVITE.replaceAll("%invitationId%", invitationId),
  });
};

export const employeePropertyReInviteService = async (data: object) => {
  return await clientRequestService({
    method: METHOD_POST,
    url: API_EMPLOYEE_PROPERTY_REINVITE,
    data,
    isCompany: true,
  });
};

export const employeeDeActiveService = async (invitationId: string) => {
  return await clientRequestService({
    method: METHOD_GET,
    url: API_EMPLOYEE_DEACTIVE.replaceAll("%invitationId%", invitationId),
  });
};

export const employeePropertyDeActiveService = async (userId: string) => {
  return await clientRequestService({
    method: METHOD_GET,
    url: API_EMPLOYEE_PROPERTY_DEACTIVE.replaceAll("%userId%", userId),
    isCompany: true,
  });
};

export const employeePropertyActiveService = async (userId: string) => {
  return await clientRequestService({
    method: METHOD_GET,
    url: API_EMPLOYEE_PROPERTY_ACTIVE.replaceAll("%userId%", userId),
    isCompany: true,
  });
};

export const employeeDeleteService = async (invitationId: string) => {
  return await clientRequestService({
    method: METHOD_GET,
    url: API_EMPLOYEE_DELETE.replaceAll("%invitationId%", invitationId),
  });
};

export const employeePropertyDeleteService = async (data: object) => {
  return await clientRequestService({
    method: METHOD_DELETE,
    url: API_EMPLOYEE_PROPERTY,
    data,
    isCompany: true,
  });
};

export const getEmployeeByGoogleService = async (data: object) => {
  const companyId = localStorage.getItem(CURRENT_COMPANY_ID);
  if (!companyId) {
    return;
  }
  return await clientRequestService({
    method: "post",
    url: API_GET_EMPLOYEE_BY_GOOGLE.replaceAll("%companyId%", companyId),
    data,
  });
};

export const inviteEmployeeByGoogleService = async (data: object) => {
  const companyId = localStorage.getItem(CURRENT_COMPANY_ID);
  if (!companyId) {
    return;
  }
  return await clientRequestService({
    method: "post",
    url: API_INVITE_EMPLOYEE_BY_GOOGLE.replaceAll("%companyId%", companyId),
    data,
  });
};

export const inviteEmployeeAfterLoginGoogle = async ({
  translations,
  response,
}: {
  translations: any;
  response: GoogleLoginResponse | GoogleLoginResponseOffline;
}) => {
  const dispatch = store.dispatch;
  try {
    if (!response?.code) {
      toastError("An error occurred. Contact support@deskimo.com for support.");
      return;
    }
    await dispatch<any>(employeeByGoogleThunk(response?.code));
    dispatch(modalImportEmployeeGoogleAction(true));
  } catch (e: any) {
    switch (e?.status?.code) {
      case 1005:
        toastGoogleInviteError({ translations, email: e?.data?.email });
        break;
      default:
        toastError(
          "An error occurred. Contact support@deskimo.com for support."
        );
    }
  }
};

export const toastGoogleInviteError = ({
  translations,
  email,
}: {
  translations: any;
  email: string;
}) => {
  const Content = () => {
    return (
      <div className={"toast_google_invite_body"}>
        <div className={"sub-title"}>{translations.ggLoginError_1}</div>
        <div className={"sub-description"}>{translations.ggLoginError_2}</div>
        <div className={"sub-description"}>
          1. {translations.ggLoginError_3}
        </div>
        <div className={"sub-description"}>
          2. {translations.ggLoginError_4}
        </div>
        <div className={"sub-description"}>
          3. {translations.ggLoginError_5} &#34;{email}&#34;{" "}
          {translations.ggLoginError_6}
        </div>
        <div className={"sub-description"}>{translations.ggLoginError_7}</div>
      </div>
    );
  };

  toast(<Content />, {
    className: "toast_google_invite toast_google_invite_error",
    autoClose: 20000,
    icon: () => null,
    closeButton: <CloseButtonToast />,
  });
};

export const toastGoogleInviteSuccess = ({
  badgeText,
  title,
  description,
}: {
  badgeText: string;
  title: string;
  description: string;
}) => {
  const Content = () => {
    return (
      <>
        <img
          width={"100%"}
          height={"auto"}
          src={PUBLIC_URL + "/assets/images/gg-invite-success.svg"}
        />
        <div className={"toast_google_invite_body"}>
          <div className={"badge"}>{badgeText}</div>
          <div className={"title"}>{title}</div>
          <div className={"description"}>{description}</div>
        </div>
      </>
    );
  };

  toast(<Content />, {
    className: "toast_google_invite",
    autoClose: 20000,
    icon: () => null,
    closeButton: <CloseButtonToast />,
  });
};

export const searchPropertyService = async ({
  keyword = "",
  companyId,
}: {
  keyword?: string;
  companyId?: string;
}) => {
  let url = API_SEARCH_PROPERTY.replaceAll("%search%", keyword);
  if (companyId) {
    url = url.replace("%companyId%", companyId);
  }
  return await clientRequestService({
    method: "get",
    url,
    isCompany: !companyId,
  });
};

export const deskimoDeleteUserService = async (id: number) => {
  return await clientRequestService({
    method: "post",
    url: API_DESKIMO_USER_DELETE.replaceAll("%id%", String(id)),
  });
};

export const getAllCompaniesService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: API_GET_ALL_COMPANIES,
    data,
  });
};

export const inviteWMSUserService = async (
  data: object,
  companyId: number | string
) => {
  return await clientRequestService({
    method: "post",
    url: API_INVITE_WMS_USER.replaceAll("%companyId%", companyId.toString()),
    data,
  });
};

export const extendCreditService = async (
  data: object,
  userId: number | string,
  companyId: number | string
) => {
  return await clientRequestService({
    method: "put",
    url: API_EXTEND_CREDIT.replaceAll("%userId%", userId.toString()).replaceAll(
      "%companyId%",
      companyId.toString()
    ),
    data,
  });
};

export const sendOTPToSlackService = async (userId: number) => {
  return await clientRequestService({
    method: "post",
    url: API_SEND_OTP_TO_SLACK.replaceAll("%userId%", String(userId)),
  });
};

export const resetOTPLimitService = async (userId: number) => {
  return await clientRequestService({
    method: "post",
    url: API_RESET_OTP_LIMIT.replaceAll("%userId%", String(userId)),
  });
};

export const deskimoUserBlackListService = async (
  id: number,
  action: string
) => {
  return await clientRequestService({
    method: "put",
    url: API_DESKIMO_USERS_BLACK_LIST.replaceAll(
      "%id%",
      id.toString()
    ).replaceAll("%action%", action),
  });
};
