import { Modal } from "semantic-ui-react";
import { PUBLIC_URL } from "../../configs/Constants";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isModalDownloadAppSelector } from "../../selectors/AppSelectors";
import {
  modalDownloadAppAction,
  modalUpdatePasswordAction,
} from "../../actions/AppActions";
import ButtonCustom from "../../../../components/Button";
import "./style.scss";
import SizeBox from "../../../../components/SizeBox";
import { LocalizationContext } from "../../../../locales/Translation";

const ModalDownloadApp = () => {
  const { translations } = useContext(LocalizationContext);
  const modalDownloadAppSelector = useSelector(isModalDownloadAppSelector);
  const dispatch = useDispatch();
  return (
    <Modal
      onClose={() => dispatch(modalDownloadAppAction(false))}
      open={modalDownloadAppSelector}
      className={"modal-center-template"}
    >
      <div className={"modal-download-wrapper"}>
        <ButtonCustom
          onClick={() => dispatch(modalDownloadAppAction(false))}
          className={"m-0 p-0 none-bg close-modal"}
        >
          <>
            {translations.close}
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
        <div className={"modal-download-main"}>
          <div className={"modal-download__title"}>
            Download the Deskimo App
          </div>
          <div className={"modal-download__text"}>
            With Deskimo, you can access a network of hundreds of independent
            workspaces across the world without the need for a subscription or
            membership. Explore available options in your location on the go,
            and get to work where you feel most productive. Get the free app
            now.
          </div>
          <div className={"modal-download__download-wrapper"}>
            <div className={"modal-download__download"}>
              <a
                href="https://deskimo.me/now"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={PUBLIC_URL + "/assets/images/download-apple.svg"} />
              </a>
              <SizeBox height={"21px"} />
              <a
                href="https://deskimo.me/now"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={PUBLIC_URL + "/assets/images/download-google.svg"} />
              </a>
            </div>
            <div className={"modal-download__qrcode"}>
              <a
                href="https://deskimo.me/now"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={PUBLIC_URL + "/assets/images/download-qrcode.svg"} />
              </a>
            </div>
          </div>
        </div>
        <div className={"modal-download-image"}>
          <img src={PUBLIC_URL + "/assets/images/modal-download-bg.png"} />
        </div>
      </div>
    </Modal>
  );
};

export default ModalDownloadApp;
