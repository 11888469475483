import { Dimmer, Loader, Message, Segment } from "semantic-ui-react";
import "./style.scss";
import React from "react";

const ForceLogin = ({ forceLoginError }: any) => {
  return (
    <>
      {forceLoginError ? (
        <Message negative>
          <Message.Header>An error occurred.</Message.Header>
          <p>Contact support@deskimo.com for support.</p>
        </Message>
      ) : (
        <Segment className={"login-loading"}>
          <Dimmer active>
            <Loader />
          </Dimmer>
        </Segment>
      )}
    </>
  );
};

export default ForceLogin;
