import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import DropdownWithCheckboxMenu from "../../../../../components/DropdownWithCheckboxMenu";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import { useDispatch, useSelector } from "react-redux";
import { dashboardDeskUsageFilterAction } from "../../../actions/DashboardActions";
import {
  dashboardDeskUsageFilterSelector,
  dashboardFilterDateRangeSelector,
} from "../../../selectors/DashboardSelectors";
import { createDeskUsageStatusFilterService } from "../../../services/DashboardServices";
import { LocalizationContext } from "../../../../../locales/Translation";
import { BUSINESS } from "../../../configs/Constants";

type Props = {
  productType?: number;
};

const FilterDeskUsage = ({ productType }: Props) => {
  const { translations } = useContext(LocalizationContext);

  const dispatch = useDispatch();
  const dashboardDeskUsageFilter = useSelector(
    dashboardDeskUsageFilterSelector
  );
  const dashboardFilterDateRange = useSelector(
    dashboardFilterDateRangeSelector
  );

  const [deskUsageStatus, setDeskUsageStatus] = useState<
    { id: number; name: string }[]
  >([]);

  useEffect(() => {
    (async () => {
      try {
        if (dashboardFilterDateRange === null) {
          return;
        }
        await createDeskUsageStatusFilterService({
          translations,
          setStatus: setDeskUsageStatus,
          type: BUSINESS,
          productType,
        });
      } catch (e) {
        console.log("e", e);
      }
    })();
  }, [dashboardDeskUsageFilter?.keyword, dashboardFilterDateRange]);

  const _onChangeFilter = (e: SyntheticEvent, data: any) => {
    if (data.checked) {
      const newValue = [...dashboardDeskUsageFilter.filter, data.value];
      dispatch(
        dashboardDeskUsageFilterAction({ key: "filter", value: newValue })
      );
    } else {
      const newValue = dashboardDeskUsageFilter.filter.filter(
        (status: number) => status !== data.value
      );
      dispatch(
        dashboardDeskUsageFilterAction({ key: "filter", value: newValue })
      );
    }
  };

  return (
    <div className={"dropdown-filter"}>
      <DropdownWithCheckboxMenu
        onChange={_onChangeFilter}
        listCheckBox={deskUsageStatus.map((filter: any) => {
          if (dashboardDeskUsageFilter.filter.includes(filter.id)) {
            filter.checked = true;
          } else {
            filter.checked = false;
          }
          return filter;
        })}
        label={translations.filters}
        icon={
          <img
            alt={"Deskimo"}
            src={PUBLIC_URL + "/assets/icons/icon-filter.svg"}
          />
        }
      />
    </div>
  );
};

export default FilterDeskUsage;
