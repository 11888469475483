import "./style.scss";
import BillingInfo from "./Components/BillingInfo";
import PaymentInfo from "./Components/PaymentInfo";
import BillingContact from "./Components/BillingContact";
import { useDispatch, useSelector } from "react-redux";
import {
  billingContactSelector,
  billingInfoSelector,
  paymentMethodSelector,
} from "../../../../selectors/BillingSelector";
import {
  modalBillingContactAction,
  modalBillingInformationAction,
  modalUpdatePaymentMethodAction,
} from "../../../../actions/BiilingActions";
import { useContext } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";

const BillingLeftSide = () => {
  const paymentMethod = useSelector(paymentMethodSelector);
  const billingContact = useSelector(billingContactSelector);
  const billingInfo = useSelector(billingInfoSelector);
  const dispatch = useDispatch();
  const { translations } = useContext(LocalizationContext);

  const _openBillingInformation = () => {
    dispatch(modalBillingInformationAction(true));
  };

  const _openPaymentMethod = () => {
    dispatch(modalUpdatePaymentMethodAction(true));
  };

  const _openBillingContact = () => {
    dispatch(modalBillingContactAction(true));
  };

  return (
    <div className={"billing-tab__left"}>
      <BillingInfo
        companyInfo={billingInfo}
        openBillingInformation={_openBillingInformation}
      />
      <PaymentInfo
        paymentMethod={paymentMethod}
        openPaymentMethod={_openPaymentMethod}
      />
      <BillingContact
        billingContact={billingContact}
        openBillingContact={_openBillingContact}
      />
    </div>
  );
};

export default BillingLeftSide;
