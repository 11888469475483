import React, { useContext, useState } from "react";
import ButtonCustom from "../../../../../components/Button";
import { LocalizationContext } from "../../../../../locales/Translation";
import TableDeskUsage from "./TableDeskUsage";
import TableRoomBookings from "./TableRoomBookings";
import classNames from "classnames";
import Search from "../../Dashboard/UsageList/Search";
import FilterDeskUsage from "./FilterDeskUsage";
import FilterRoomBooking from "./FilterRoomBooking";
import {
  FILTER_DATE_CUSTOM,
  TAB_DESK_BOOKINGS_INDEX,
  TAB_DESK_USAGE_INDEX,
  TAB_PRIVATE_OFFICES_INDEX,
  TAB_ROOM_BOOKINGS_INDEX,
} from "../../../configs/Constants";
import FilterDeskBooking from "./FilterDeskBooking";
import TableDeskBookings from "./TableDeskBookings";
import Filter from "../../Dashboard/Filter";
import {
  usePermissionCheck,
  useScreenWidth,
} from "../../../../../utils/custom-hooks";
import { UI_ACCESS_STATISTIC_PROPERTY_PER } from "../../../../../utils/PermissionConstant";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { dashboardFilterDateTypeSelector } from "../../../selectors/DashboardSelectors";
import OfficeLeases from "../OfficeLeases";
import FilterPrivateOffice from "../OfficeLeases/Filter";
import { permissionListSelector } from "../../../../Authentication/selectors/AuthenticationSelectors";
import FilterListProperty from "../../Components/FilterListProperty";

const UsageList = () => {
  const { translations } = useContext(LocalizationContext);
  const [tabIndex, setTabIndex] = useState(TAB_DESK_USAGE_INDEX);
  const _onChangeTab = (value: number) => {
    setTabIndex(value);
  };
  const dashboardFilterDateType = useSelector(dashboardFilterDateTypeSelector);

  const permissionList = useSelector(permissionListSelector);

  const screenWidth = useScreenWidth();

  return (
    <>
      {!usePermissionCheck(UI_ACCESS_STATISTIC_PROPERTY_PER) &&
      permissionList.length &&
      screenWidth < 1200 ? (
        <div className={"filter_date_show_mobile"}>
          <Filter />
        </div>
      ) : null}
      <div
        className={classnames("filter-section", {
          "filter-section_search_small":
            !usePermissionCheck(UI_ACCESS_STATISTIC_PROPERTY_PER) &&
            dashboardFilterDateType === FILTER_DATE_CUSTOM,
        })}
      >
        <Search />
        {/*<FilterListProperty />*/}
        <div className={"button-group yellow"}>
          <ButtonCustom
            height={"36px"}
            className={classNames({
              active: tabIndex === TAB_DESK_USAGE_INDEX,
            })}
            onClick={() => _onChangeTab(TAB_DESK_USAGE_INDEX)}
          >
            {translations.deskWalkIn}
          </ButtonCustom>
          <ButtonCustom
            height={"36px"}
            className={classNames({
              active: tabIndex === TAB_DESK_BOOKINGS_INDEX,
            })}
            onClick={() => _onChangeTab(TAB_DESK_BOOKINGS_INDEX)}
          >
            {translations.deskBookings}
          </ButtonCustom>
          <ButtonCustom
            height={"36px"}
            className={classNames({
              active: tabIndex === TAB_ROOM_BOOKINGS_INDEX,
            })}
            onClick={() => _onChangeTab(TAB_ROOM_BOOKINGS_INDEX)}
          >
            {translations.roomBookings}
          </ButtonCustom>
          <ButtonCustom
            height={"36px"}
            className={classNames({
              active: tabIndex === TAB_PRIVATE_OFFICES_INDEX,
            })}
            onClick={() => _onChangeTab(TAB_PRIVATE_OFFICES_INDEX)}
          >
            Private Offices
          </ButtonCustom>
        </div>
        {!usePermissionCheck(UI_ACCESS_STATISTIC_PROPERTY_PER) &&
        permissionList.length &&
        screenWidth >= 1200 ? (
          <Filter />
        ) : null}

        {tabIndex === TAB_DESK_USAGE_INDEX ? (
          <FilterDeskUsage />
        ) : tabIndex === TAB_DESK_BOOKINGS_INDEX ? (
          <FilterDeskBooking />
        ) : tabIndex === TAB_ROOM_BOOKINGS_INDEX ? (
          <FilterRoomBooking />
        ) : (
          <FilterPrivateOffice />
        )}
      </div>

      <div className={"dashboard_table"}>
        {tabIndex === TAB_DESK_USAGE_INDEX ? (
          <TableDeskUsage />
        ) : tabIndex === TAB_DESK_BOOKINGS_INDEX ? (
          <TableDeskBookings />
        ) : tabIndex === TAB_ROOM_BOOKINGS_INDEX ? (
          <TableRoomBookings />
        ) : (
          <OfficeLeases />
        )}
      </div>
    </>
  );
};

export default UsageList;
