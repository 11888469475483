import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import TableCustom from "../../../../../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import { integrationListThunk } from "../../../../thunks/IntegrationThunk";
import { integrationListSelector } from "../../../../selectors/IntegrationSelector";
import { Tab } from "semantic-ui-react";
import { employeesFilterAction } from "../../../../../User/actions/EmployeeActions";
import IntegrationName from "./IntegrationName";
import CircleStatus from "../../../../../../components/CircleStatus";
import IntegrationAction from "./IntegrationAction";
import ModalInviteEmployeeByGoogle from "../../../../../User/views/EmployeeBusiness/Components/ModalInviteEmployeeByGoogle";

const IntegrationTab = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const integrationList = useSelector(integrationListSelector);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch<any>(integrationListThunk());
      setLoading(false);
    })();
  }, []);

  const _formatInputData = () => {
    return integrationList?.settings.map((field: any) => {
      return {
        col1: (
          <IntegrationName
            name={field.name}
            description={field.description}
            iconUrl={field.icon}
          />
        ),
        col2: (
          <div className={"d-flex align-items-center"}>
            <CircleStatus color={field?.status ? "green" : "red"} />
            {field?.status ? translations.active : translations.inActive}
          </div>
        ),
        col3: (
          <IntegrationAction
            status={field?.status}
            id={field?.id}
            autoInvite={field?.auto_invite}
          />
        ),
      };
    });
  };

  return (
    <>
      <Tab.Pane attached={false} className={"billing-tab"}>
        <TableCustom
          columns={["Integration", translations.status, translations.action]}
          sortTable={false}
          loading={loading}
          showTotal={false}
          tableData={integrationList?.settings ? _formatInputData() : []}
          currentPage={integrationList?.pagination?.page}
          totalItems={integrationList?.pagination?.total}
          // onChangePagination={(e: any, { activePage }: any) => {
          //   dispatch(employeesFilterAction({ key: "page", value: activePage }));
          // }}
        />
      </Tab.Pane>
      <ModalInviteEmployeeByGoogle />
    </>
  );
};

export default IntegrationTab;
