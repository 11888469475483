import moment from "moment-timezone";
import { Easing, Tween, update } from "@tweenjs/tween.js";
import { numberCommaFormat } from "../../../services/FormatTextService";
import {
  bookDeskConfirmPayService,
  confirmAndPayRoomService,
} from "./BookServices";
import {
  modalBookingSuccessAction,
  modalBookingSuccessDeskOpenAction,
} from "../actions/BookActions";
import { store } from "../../App/configs/Store";
import clientRequestService from "../../../services/ClientRequestService";
import {
  TINY_API_DOMAIN,
  TINY_DOMAIN,
  TINY_URL_KEY,
} from "../configs/Endpoints";
import { BUFFER_DURATION, DEFAULT_DURATION } from "../configs/Constants";
import { computeDistanceBetween, computeOffset } from "spherical-geometry-js";
import { getHeading } from "./MapServices";

//Date: moment object
//Time: string - Ex: "00:30"
//Duration: number minutes - Ex: 480
//utc: boolean

export const calculateBookingTime = (
  date: any,
  time: any,
  duration: any,
  timezone: string,
  isBuffer?: boolean
) => {
  if (!timezone) {
    return 0;
  }

  //End time
  let result;
  if (duration && duration !== 0) {
    if (isBuffer) {
      result = moment(date)
        .set({
          hours: time.split(":")[0],
          minutes: time.split(":")[1],
          second: 0,
        })
        .add(duration + BUFFER_DURATION, "m");
    } else {
      result = moment(date)
        .set({
          hours: time.split(":")[0],
          minutes: time.split(":")[1],
          second: 0,
        })
        .add(duration, "m");
    }
  } else {
    //Start time
    if (isBuffer) {
      result = moment(date)
        .set({
          hours: time.split(":")[0],
          minutes: time.split(":")[1],
          second: 0,
        })
        .subtract(BUFFER_DURATION, "m");
    } else {
      result = moment(date).set({
        hours: time.split(":")[0],
        minutes: time.split(":")[1],
        second: 0,
      });
    }
  }
  result = moment(result).tz(timezone, true).utc().unix();
  return result;
};

export const calculateBookingTimeTillSecond = (
  date: any,
  time: any,
  duration: any,
  timezone: string,
  isBuffer?: boolean
) => {
  if (!timezone) {
    return 0;
  }

  //End time
  let result;
  if (duration && duration !== 0) {
    if (isBuffer) {
      result = moment(date)
        .set({
          hours: time.split(":")[0],
          minutes: time.split(":")[1],
          second: time.split(":")[2],
        })
        .add(duration + BUFFER_DURATION * 60, "s");
    } else {
      result = moment(date)
        .set({
          hours: time.split(":")[0],
          minutes: time.split(":")[1],
          second: time.split(":")[2],
        })
        .add(duration, "s");
    }
  } else {
    //Start time
    if (isBuffer) {
      result = moment(date)
        .set({
          hours: time.split(":")[0],
          minutes: time.split(":")[1],
          second: time.split(":")[2],
        })
        .subtract(BUFFER_DURATION * 60, "s");
    } else {
      result = moment(date).set({
        hours: time.split(":")[0],
        minutes: time.split(":")[1],
        second: time.split(":")[2],
      });
    }
  }
  result = moment(result).tz(timezone, true).utc().unix();
  return result;
};

export const convertDate2StatEndTime = (timezone: string, date: Date) => {
  const time = moment.tz(timezone).set({
    date: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
  });
  const startTime = time.clone().startOf("day").utc().unix();
  const endTime = time.clone().endOf("day").utc().unix();
  return { startTime, endTime };
};

export const convertDate2StatEndTimeUsingMoment = (
  timezone: string,
  date: any
) => {
  const time = moment.tz(timezone).set({
    date: moment.isMoment(date) ? date.date() : moment().date(),
    month: moment.isMoment(date) ? date.month() : moment().month(),
    year: moment.isMoment(date) ? date.year() : moment().year(),
  });
  const startTime = time.clone().startOf("day").utc().unix();
  const endTime = time.clone().endOf("day").utc().unix();
  return { startTime, endTime };
};

interface CameraOptionsType extends google.maps.CameraOptions {
  center: {
    lat: number;
    lng: number;
  };
}

type MapMoveAnimateType = {
  map: google.maps.Map;
  cameraOptions: CameraOptionsType;
  duration?: number;
};

export const mapMoveAnimate = ({ map, cameraOptions }: MapMoveAnimateType) => {
  if (!map) {
    return;
  }

  const startPosition = {
    lat: map.getCenter()?.lat() || 0,
    lng: map.getCenter()?.lng() || 0,
  };

  const distanceBetween = computeDistanceBetween(startPosition, {
    lat: cameraOptions.center.lat,
    lng: cameraOptions.center.lng,
  });

  const degree = getHeading(startPosition, {
    lat: cameraOptions.center.lat,
    lng: cameraOptions.center.lng,
  });

  new Tween(startPosition) // Create a new tween that modifies 'cameraOptions'.
    .to({}, 500)
    .easing(Easing.Quadratic.InOut) // Use an easing function to make the animation smooth.
    .onUpdate((object, elapsed) => {
      const newDistance = distanceBetween * elapsed;

      const { latitude, longitude } = computeOffset(
        startPosition,
        newDistance,
        degree
      );

      const newOptions = {
        center: {
          lat: latitude,
          lng: longitude,
        },
      };
      map.moveCamera(newOptions);
    })
    .start(); // Start the tween immediately.

  // Set up the animation loop.
  function animate(time: number) {
    requestAnimationFrame(animate);
    update(time);
  }

  requestAnimationFrame(animate);
};

export const calculateTotalDeskPrice = (bookingNo: number, price: string) => {
  try {
    if (bookingNo < 1) {
      return price;
    }
    const priceWithoutFormat = String(price).replaceAll(",", "");
    return numberCommaFormat(parseFloat(priceWithoutFormat) * bookingNo);
  } catch (e) {
    return price;
  }
};

type MakeBookingDeskType = {
  timezone: string;
  dateChoose: any;
  paymentCompanyId: number;
  propertyId: number;
  bookingNo: number;
};

export const makeBookingDeskService = async ({
  propertyId,
  dateChoose,
  paymentCompanyId,
  timezone,
  bookingNo,
}: MakeBookingDeskType) => {
  const dispatch = store.dispatch;
  const { startTime, endTime } = convertDate2StatEndTimeUsingMoment(
    timezone,
    dateChoose
  );
  const payload = {
    company_id: paymentCompanyId,
    property_id: propertyId,
    total_slot: bookingNo,
    start_time: startTime,
    end_time: endTime,
  };
  const { data } = await bookDeskConfirmPayService(payload);
  dispatch(modalBookingSuccessDeskOpenAction({ id: data?.id, bookingNo }));
};

type MakeBookingRoomType = {
  timezone: string;
  dateChoose: Date | any;
  paymentCompanyId: number;
  roomId: number;
  startTime: string;
  duration: number;
  isBuffer?: boolean;
};

export const makeBookingRoomService = async ({
  timezone,
  paymentCompanyId,
  roomId,
  dateChoose,
  startTime,
  duration,
  isBuffer,
}: MakeBookingRoomType) => {
  const dispatch = store.dispatch;

  const payload = {
    company_id: paymentCompanyId,
    meeting_room_id: roomId,
    start_time: calculateBookingTime(
      dateChoose,
      startTime,
      undefined,
      timezone,
      isBuffer
    ),
    end_time: calculateBookingTime(
      dateChoose,
      startTime,
      duration,
      timezone,
      isBuffer
    ),
  };
  const response = await confirmAndPayRoomService(payload);
  dispatch(modalBookingSuccessAction({ open: true, data: response?.data }));
};

export const getUrlShortenFree = async (fullUrl: string) => {
  return clientRequestService({
    url: TINY_DOMAIN + `?url=${fullUrl}`,
  });
};

export const getUrlShortenCustomDomain = async (fullUrl: string) => {
  const data = {
    url: fullUrl,
    domain: "deskimo.me",
  };

  const res = await clientRequestService({
    isAuth: false,
    method: "post",
    url: TINY_API_DOMAIN + `/create?api_token=${TINY_URL_KEY}`,
    data,
  });

  return res?.data?.tiny_url;
};

const randomShareSentences = (translations: any, url: string) => {
  const sentence = [
    `${translations.messageShareFirst_1} ${url} ${translations.messageShareFirst_2}`,
    `${translations.messageShareSecond_1} ${url} ${translations.messageShareSecond_2}`,
    `${translations.messageShareThird_1} ${url} ${translations.messageShareThird_2}`,
  ];
  const randomIndex = Math.floor(Math.random() * 3);
  return sentence[randomIndex];
};

export const sharePropertyMessage = async (
  translations: object,
  id: number,
  webUrl: string
) => {
  const fullUrl =
    "https://deskimo.page.link/?link=https://www.deskimo.com?" +
    `params={"source":"PropertyDetail","id":"${id}"}&apn=com.deskimo` +
    `&isi=1564621411&ibi=com.deskimo&ofl=${webUrl}`;

  let shortUrl = "";
  if (process.env.REACT_APP_ENVIRONMENT !== "production") {
    shortUrl = await getUrlShortenFree(fullUrl);
  } else {
    shortUrl = await getUrlShortenCustomDomain(fullUrl);
  }
  return randomShareSentences(translations, shortUrl);
};

export const shareMeetingRoomMessage = async (
  translations: object,
  id: number,
  webUrl: string
) => {
  const fullUrl =
    "https://deskimo.page.link/?link=https://www.deskimo.com?" +
    `params={"source":"MeetingRoomDetail","id":"${id}"}&apn=com.deskimo` +
    `&isi=1564621411&ibi=com.deskimo&ofl=${webUrl}`;

  let shortUrl = "";
  if (process.env.REACT_APP_ENVIRONMENT !== "production") {
    shortUrl = await getUrlShortenFree(fullUrl);
  } else {
    shortUrl = await getUrlShortenCustomDomain(fullUrl);
  }
  return randomShareSentences(translations, shortUrl);
};

export const sharePrivateOfficeMessage = async (
  translations: object,
  webUrl: string
) => {
  const fullUrl =
    "https://deskimo.page.link/?link=https://www.deskimo.com?" +
    `apn=com.deskimo&isi=1564621411&ibi=com.deskimo&ofl=${webUrl}`;

  let shortUrl = "";
  if (process.env.REACT_APP_ENVIRONMENT !== "production") {
    shortUrl = await getUrlShortenFree(fullUrl);
  } else {
    shortUrl = await getUrlShortenCustomDomain(fullUrl);
  }
  return randomShareSentences(translations, shortUrl);
};

export const clipboardCopyWithAsyncFunction = (func: any) => {
  if (typeof ClipboardItem && navigator.clipboard.write) {
    // NOTE: Safari locks down the clipboard API to only work when triggered
    //   by a direct user interaction. You can't use it async in a promise.
    //   But! You can wrap the promise in a ClipboardItem, and give that to
    //   the clipboard API.
    //   Found this on https://developer.apple.com/forums/thread/691873

    const text = new ClipboardItem({
      "text/plain": func()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .then((text) => new Blob([text], { type: "text/plain" })),
    });
    navigator.clipboard.write([text]);
  } else {
    // NOTE: Firefox has support for ClipboardItem and navigator.clipboard.write,
    //   but those are behind `dom.events.asyncClipboard.clipboardItem` preference.
    //   Good news is that other than Safari, Firefox does not care about
    //   Clipboard API being used async in a Promise.

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    func().then((text) => navigator.clipboard.writeText(text));
  }
};

export const getLocalDateFromAnotherTimezone = (
  timestamp: number,
  timezone: string,
  getNextDay?: boolean
) => {
  if (!timestamp || !timezone) {
    if (getNextDay) {
      return moment().day() === 5
        ? moment().add(3, "days").toDate()
        : moment().day() === 6
        ? moment().add(2, "days").toDate()
        : moment().add(1, "days").toDate();
    }

    return moment().toDate();
  }

  const temp = moment.unix(timestamp).tz(timezone).startOf("day");

  if (getNextDay) {
    return temp.day() === 5
      ? temp.add(3, "days").toDate()
      : temp.day() === 6
      ? temp.add(2, "days").toDate()
      : temp.add(1, "days").toDate();
  }

  return temp.toDate();
};

export const getLocalDateFromAnotherTimezoneMoment = (
  timestamp: number,
  timezone: string,
  getNextDay?: boolean
) => {
  if (!timestamp || !timezone) {
    if (getNextDay) {
      return moment().day() === 5
        ? moment().add(3, "day")
        : moment().day() === 6
        ? moment().add(2, "day")
        : moment().add(1, "day");
    }

    return moment();
  }

  const temp = moment.unix(timestamp).tz(timezone).startOf("day");

  if (getNextDay) {
    return temp.day() === 5
      ? temp.add(3, "day")
      : temp.day() === 6
      ? temp.add(2, "day")
      : temp.add(1, "day");
  }

  return temp;
};

export const getLocalTimeAndDurationFromAnotherTimezone = (
  startTime: number,
  endTime: number,
  timezone: string
) => {
  if (!startTime || !endTime || !timezone) {
    return { startTime: "08:00", duration: DEFAULT_DURATION };
  }

  const startTimeTemp = moment.unix(startTime).tz(timezone);
  const endTimeTemp = moment.unix(endTime).tz(timezone);

  const duration = endTimeTemp.diff(startTimeTemp);

  return {
    startTime: startTimeTemp.format("HH:mm"),
    duration: duration / 60000,
  };
};

export const isHoliday = (listHoliday: any, date: any) => {
  let isHoliday = false;
  listHoliday.forEach((holiday: any) => {
    if (moment(date).isSame(holiday)) {
      isHoliday = true;
    }
  });
  return isHoliday;
};
