import React, { useEffect, useState } from "react";
import DropdownCustom from "../DropdownCustom";
import { useSelector } from "react-redux";
import { globalSettingSelector } from "../../modules/App/selectors/AppSelectors";

type Props = {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  [x: string]: any;
};

const FilterMarket = ({ onChange, value, className, ...props }: Props) => {
  const [marketOptions, setMarketOptions] = useState<[]>([]);

  const globalSetting = useSelector(globalSettingSelector);

  useEffect(() => {
    let marketList = globalSetting?.location.map(
      ({ city_code, city_name }: { city_code: string; city_name: string }) => {
        return {
          key: city_code,
          text: city_name,
          value: city_code,
        };
      }
    );

    marketList = [
      {
        key: "All",
        text: "All Markets",
        value: "0",
      },
      ...marketList,
    ];

    setMarketOptions(marketList);
  }, [globalSetting]);

  return (
    <DropdownCustom
      squareUI
      scrolling
      options={marketOptions}
      value={value}
      onChange={(e, { value }: any) => {
        onChange(value);
      }}
      placeholder={"Select market"}
      className={className}
      {...props}
    />
  );
};
export default FilterMarket;
