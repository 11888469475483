import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import ModalCenterTemplate from "../../../../../App/views/ModalCenterTemplate";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { DateObject } from "react-multi-date-picker";
import DatePickerMultiple from "../../../../../../components/DatePickerMultiple";
import {
  getPropertyFutureClosuresService,
  propertyFutureClosuresService,
} from "../../../../services/DashboardServices";
import { useSelector } from "react-redux";
import { dashboardPropertyChooseSelector } from "../../../../selectors/DashboardSelectors";
import moment from "moment";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const format = "MM/DD/YYYY";

const ModalCloseSchedule = ({ open, setOpen }: Props) => {
  const { translations } = useContext(LocalizationContext);

  const [loading, setLoading] = useState(false);

  const [dates, setDates] = useState<DateObject | DateObject[] | null>([]);

  useEffect(() => {
    (async () => {
      try {
        setDates([]);
        if (!open) {
          return;
        }
        const { data } = await getPropertyFutureClosuresService(
          dashboardPropertyChoose?.property_id
        );
        setDates(
          data?.closure_dates.map((timestamp: number) => {
            return new DateObject(timestamp * 1000);
          })
        );
      } catch (e) {
        console.log(e);
      }
    })();
  }, [open]);

  const dashboardPropertyChoose = useSelector(dashboardPropertyChooseSelector);

  const _handleSave = async () => {
    setLoading(true);
    try {
      if (!Array.isArray(dates)) {
        return;
      }

      const payload = {
        property_id: dashboardPropertyChoose?.property_id,
        closure_dates: dates.map((date) => {
          const dateConvert = new DateObject(date).format();
          return moment.utc(dateConvert, format).unix();
        }),
      };
      await propertyFutureClosuresService(payload);
      setOpen(false);
      toastSuccess("Future closures saved successfully");
    } catch (error: any) {
      toastError(error?.status?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={() => setOpen(false)}
      modalClassName={"modal-add-future-close"}
      open={open}
    >
      <Modal.Header>
        <span>{translations.chooseWhenYourSpaceIsClosed}</span>
        <ButtonCustom
          onClick={() => setOpen(false)}
          className={"modal-close-cta"}
        >
          <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
        </ButtonCustom>
      </Modal.Header>
      <Modal.Content>
        <div className={"dashboard_close_schedule_box"}>
          {Array.isArray(dates) && dates?.length ? (
            <div className={"dashboard_close_schedule_row"}>
              <span>
                {translations.selectedDays}: {dates?.length}
              </span>
              <ButtonCustom
                onClick={() => {
                  setDates([]);
                }}
              >
                {translations.cancel}
              </ButtonCustom>
            </div>
          ) : null}
          <DatePickerMultiple
            minDate={new Date()}
            value={dates}
            onChange={setDates}
            shadow={false}
            format={format}
          />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <ButtonCustom height={"47px"} onClick={() => setOpen(false)}>
          {translations.cancel}
        </ButtonCustom>
        <ButtonCustom
          loading={loading}
          className={"primary"}
          height={"47px"}
          onClick={_handleSave}
        >
          {translations.save}
        </ButtonCustom>
      </Modal.Actions>
    </ModalCenterTemplate>
  );
};

export default ModalCloseSchedule;
