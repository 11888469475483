import "./styles.scss";
import { PUBLIC_URL } from "../../../App/configs/Constants";
import SizeBox from "../../../../components/SizeBox";
import ButtonCustom from "../../../../components/Button";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  redeemRewardService,
  rewardDetailService,
} from "../../services/MarketingServices";
import { useQuery } from "../../../../utils/custom-hooks";
import LoaderCustom from "../../../../components/Loader";
import { toastError, toastSuccess } from "../../../../components/Toast";
import moment from "moment";
import { FORMAT_DATE_TIME_VARIANT_8 } from "../../../App/configs/DateTimeConfigs";
import { REWARD_STATUS_UTIL } from "../../configs/Constants";

const RewardRedeem = () => {
  const navigate = useNavigate();
  const query = useQuery();

  const [data, setData] = useState<any>(null);
  const [requestToken, setRequestToken] = useState<string | null>(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [loading, setLoading] = useState(false);

  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const token = query.get("token");

        if (!token) {
          throw Error();
        }

        const strDetect = token.match(/-(.*?)-/);

        const requestToken = strDetect ? strDetect[1] : null;

        if (!requestToken) {
          throw Error();
        }

        setRequestToken(requestToken);

        const { data } = await rewardDetailService(requestToken);

        setData(data);
      } catch (e: any) {
        toastError(e?.status?.message);
        navigate("/", { replace: true });
      }
    })();
  }, [refreshing]);

  const _onProcess = async () => {
    try {
      if (!requestToken) {
        throw Error("Missing token");
      }

      setLoading(true);
      await redeemRewardService(requestToken);
      setRefreshing((prevState) => !prevState);

      toastSuccess("Redeemed successfully!");

      setTimeout(() => {
        setLoading(false);
        setIsVerifying(false);
      }, 1000);
    } catch (e: any) {
      toastError(e?.status?.message || e);
      setLoading(false);
    }
  };

  if (!data) {
    return <LoaderCustom fullScreen />;
  }

  return (
    <div className={"redeem"}>
      <div className={"redeem-box"}>
        <div className={"redeem-header"}>
          <img src={PUBLIC_URL + "/assets/icons/logoWithText.svg"} />
          <img
            className={"line-1"}
            src={PUBLIC_URL + "/assets/icons/redeem-line-1.svg"}
          />
          <img
            className={"line-2"}
            src={PUBLIC_URL + "/assets/icons/redeem-line-2.svg"}
          />
        </div>
        <div className={"redeem-content"}>
          <div className={"title"}>
            {data?.reward?.status === REWARD_STATUS_UTIL.UNUSE
              ? "Please confirm this reward"
              : data?.reward?.status === REWARD_STATUS_UTIL.REDEEMED
              ? "Reward Redeemed"
              : "Reward Expired"}
          </div>

          {isVerifying ? (
            <div className="box">
              <div className="name">
                Are you sure you want to confirm this reward?
              </div>
              <div className="value text-center">
                This action cannot be undone
              </div>
              <SizeBox height={16} />
              <div className={"btn-group"}>
                <ButtonCustom
                  className={"btn"}
                  onClick={() => setIsVerifying(false)}
                >
                  Cancel
                </ButtonCustom>
                <ButtonCustom
                  loading={loading}
                  disabled={loading}
                  className={"btn yellow"}
                  onClick={_onProcess}
                >
                  Process
                </ButtonCustom>
              </div>
            </div>
          ) : (
            <div className="box">
              <div className="name">{data?.reward?.name}</div>
              <div className="label">Issued by:</div>
              <div className="value">{data?.reward?.property?.name}</div>
              <div className="value">
                {data?.reward?.property?.company?.name}
              </div>
              <SizeBox height={8} />
              <div className="label">
                {data?.reward?.usage_date ? "Redeemed on:" : "Expires on:"}
              </div>
              <div className="value">
                {data?.reward?.usage_date
                  ? moment
                      .unix(data?.reward?.usage_date)
                      .format(FORMAT_DATE_TIME_VARIANT_8)
                  : moment
                      .unix(data?.reward?.expired_date)
                      .format(FORMAT_DATE_TIME_VARIANT_8)}
              </div>
              <SizeBox height={16} />
              {data?.reward?.status === REWARD_STATUS_UTIL.UNUSE ? (
                <ButtonCustom
                  className={"yellow btn"}
                  onClick={() => setIsVerifying(true)}
                >
                  Use
                </ButtonCustom>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RewardRedeem;
