type Props = {
  title?: string;
  text?: string | any;
  icon?: any;
  iconHtml?: any;
  html?: any;
  confirmText?: any;
  cancelText?: string;
  confirmClass?: string;
  cancelClass?: string;
  containerSize?: string;
  showCloseButton?: any;
  showCancelButton?: any;
  inputOptions?: any;
  input?: any;
  [x: string]: any;
};

export const SWAL_CONFIRM_OPTION = ({
  title,
  text,
  icon,
  iconHtml,
  html,
  confirmText,
  cancelText,
  showCancelButton,
  confirmClass,
}: Props) => {
  return {
    iconHtml: iconHtml ? iconHtml : undefined,
    icon: icon ? icon : undefined,
    title: title,
    text: text,
    html,
    confirmButtonText: confirmText ? confirmText : "Confirm",
    cancelButtonText: cancelText ? cancelText : "Cancel",
    reverseButtons: true,
    showCancelButton,
    customClass: {
      popup: "swal-saas-popup",
      title: "swal-saas-header",
      container: "swal-saas-content",
      actions: "swal-saas-actions w-100",
      confirmButton: `ui button primary ${confirmClass} button-custom h-34px flex-1 mr-0`,
      cancelButton: "ui button default button-custom h-34px flex-1 mr-8px",
      denyButton: "d-none",
    },
  };
};

export const SWAL_DELETE_OPTION = ({
  title,
  text,
  icon,
  confirmText,
  cancelText,
  iconHtml,
  confirmClass = "danger",
  cancelClass = "default",
  containerSize = "sm",
  showCloseButton = false,
  html,
}: Props) => {
  return {
    iconHtml: iconHtml ? iconHtml : undefined,
    icon: icon ? icon : undefined,
    title: title,
    text: text,
    html: html ? html : undefined,
    confirmButtonText: confirmText ? confirmText : "Confirm",
    cancelButtonText: cancelText ? cancelText : "Cancel",
    reverseButtons: true,
    showCloseButton,
    customClass: {
      popup: "swal-saas-popup " + containerSize,
      title: "swal-saas-header",
      container: "swal-saas-content",
      actions: "swal-saas-actions w-100 d-flex mt-3",
      confirmButton: `ui button ${confirmClass} flex-1 w-50 button-custom h-34px`,
      cancelButton: `ui button ${cancelClass} flex-1 w-50 button-custom h-34px`,
      denyButton: "d-none",
    },
  };
};

type SwalCustomContent = {
  content: any;
  confirmButtonClass?: string;
  cancelButtonClass?: string;
  denyButtonText?: string;
  wrapperClass?: string;
  denyButtonClass?: string;
  cancelButtonText?: any;
  confirmButtonText?: any;
  reverseButtons?: any;
  showDenyButton?: any;
  showCancelButton?: any;
  showCloseButton?: any;
};

export const SWAL_CUSTOM_CONTENT = ({
  content,
  confirmButtonClass,
  cancelButtonClass,
  denyButtonClass,
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
  denyButtonText = "No",
  wrapperClass,
  reverseButtons = true,
  showDenyButton = false,
  showCancelButton = true,
  showCloseButton = false,
}: SwalCustomContent) => {
  return {
    icon: null,
    iconHtml: null,
    title: null,
    html: content,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    denyButtonText: denyButtonText,
    reverseButtons: reverseButtons,
    showDenyButton: showDenyButton,
    showCancelButton: showCancelButton,
    showCloseButton: showCloseButton,
    customClass: {
      title: "swal-custom-title",
      htmlContainer: "swal-custom-html-container ",
      popup: "swal-custom-popup " + wrapperClass,
      header: "swal-custom-header",
      content: "swal-custom-content",
      actions: "swal-custom-actions",
      confirmButton: confirmButtonClass,
      cancelButton: cancelButtonClass,
      denyButton: denyButtonClass,
    },
  };
};

export const SWAL_CONFIRM_OPTION_V2 = ({
  title,
  text,
  icon,
  iconHtml,
  html,
  confirmText,
  cancelText,
  showCancelButton,
  inputOptions,
  input,
}: Props) => {
  return {
    iconHtml: iconHtml ? iconHtml : undefined,
    icon: icon ? icon : undefined,
    title: title,
    text: text,
    html,
    confirmButtonText: confirmText ? confirmText : "Confirm",
    cancelButtonText: cancelText ? cancelText : "Cancel",
    reverseButtons: true,
    showCancelButton,
    customClass: {
      popup: "swal-saas-popup",
      title: "swal-saas-header",
      container: "swal-saas-content",
      actions: "swal-saas-actions w-100",
      confirmButton: "ui button primary button-custom h-34px flex-1 mr-0",
      cancelButton: "ui button default button-custom h-34px flex-1 mr-8px",
      denyButton: "d-none",
    },
    input,
    inputOptions,
  };
};
