import React, { useContext, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { BookingActionType } from "../../../../configs/DeclareTypes";
import { DESK_BOOKING_CONFIRMED_STATUS } from "../../../../configs/Constants";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import BookingActionButton from "./BookingActionButton";

type Props = {
  bookingId: number;
  actions: BookingActionType[] | undefined;
  statusId: number;
  onCallback: (status: number) => void;
  type?: string;
  refresh?: boolean;
  setRefresh?: any;
  refundType?: any;
  priceAfterDiscount?: any;
  priceAfterDiscountAmount?: any;
  freeCreditAmount?: any;
  currency?: any;
};

const BookingAction = ({
  actions,
  bookingId,
  statusId,
  onCallback,
  type,
  refresh,
  setRefresh,
  refundType,
  priceAfterDiscount,
  priceAfterDiscountAmount,
  freeCreditAmount,
  currency,
}: Props) => {
  const { translations } = useContext(LocalizationContext);
  const [disabled, setDisabled] = useState(false);

  if (actions?.length === undefined) {
    return null;
  }

  if (statusId === DESK_BOOKING_CONFIRMED_STATUS) {
    return (
      <div className={"dashboard_approve_status"}>
        <img
          src={PUBLIC_URL + "/assets/icons/icon-check-green-round.svg"}
          width={14}
          height={14}
        />
        <span>{translations.approved}</span>
      </div>
    );
  }

  return (
    <div className={"dashboard_approve_btn_wrapper "}>
      {actions.map((item: BookingActionType) => {
        return (
          <BookingActionButton
            disabled={disabled}
            setDisabled={setDisabled}
            refresh={refresh}
            setRefresh={setRefresh}
            type={type}
            key={item.name + bookingId}
            item={item}
            bookingId={bookingId}
            onCallback={onCallback}
            refundType={refundType}
            priceAfterDiscount={priceAfterDiscount}
            priceAfterDiscountAmount={priceAfterDiscountAmount}
            freeCreditAmount={freeCreditAmount}
            currency={currency}
          />
        );
      })}
    </div>
  );
};

export default BookingAction;
