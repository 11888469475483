import React, { useEffect, useState } from "react";
import DropdownCustom from "../../../../components/DropdownCustom";
import moment from "moment";

type Props = {
  value: number;
  onChange: (value: number) => void;
};

const FilterYear = ({ value, onChange }: Props) => {
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    const currentYear = moment().get("year");
    const temp = [];
    for (let i = currentYear; i >= 2021; i--) {
      temp.push({ key: i, text: i, value: i });
    }
    setOptions(temp);
  }, []);

  return (
    <DropdownCustom
      squareUI
      selection
      options={options}
      search
      value={value}
      onChange={(e, { value }) => onChange(Number(value))}
    />
  );
};

export default FilterYear;
