import React, { useContext, useEffect, useState } from "react";
import LoginTemplate from "../../../App/views/LoginTemplate";
import { LocalizationContext } from "../../../../locales/Translation";
import { TextArea } from "semantic-ui-react";
import ButtonCustom from "../../../../components/Button";
import DropdownCustom from "../../../../components/DropdownCustom";
import { Formik } from "formik";
import { useQuery } from "../../../../utils/custom-hooks";
import { getDeclineReasonsService } from "../../../Dashboard/services/DashboardServices";
import {
  ROOM_BOOKING,
  TYPE_DESK,
  TYPE_ROOM,
} from "../../../Dashboard/configs/Constants";
import { useNavigate, useParams } from "react-router-dom";
import {
  changeBookingStatusService,
  handleCheckBookingStatusService,
} from "../../services/AuthenticationServices";
import { DASHBOARD_DESKIMO_LINK } from "../../../App/configs/ListRoutes";
import { toastError } from "../../../../components/Toast";
import { PUBLIC_URL } from "../../../App/configs/Constants";
import { SWAL_CONFIRM_OPTION } from "../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../components/SweetAlert";

const BookingDeclineReason = () => {
  const { translations } = useContext(LocalizationContext);
  const { token } = useParams();
  const queryParams = useQuery();
  const navigate = useNavigate();

  const [declineReasons, setDeclineReasons] = useState<any>([]);

  useEffect(() => {
    (async () => {
      await handleCheckBookingStatusService(
        queryParams.get("type"),
        token,
        navigate,
        _getDeclineReason
      );
    })();
  }, []);

  const _getDeclineReason = async () => {
    const response = await getDeclineReasonsService(
      queryParams.get("type") === ROOM_BOOKING ? TYPE_ROOM : TYPE_DESK
    );
    if (response?.data) {
      setDeclineReasons(response?.data);
    }
  };

  const isDescription = (reasonId: any) => {
    return declineReasons.filter((reason: any) => reason.id === reasonId)[0]
      ?.description;
  };

  const _handleSave = async (values: any) => {
    try {
      const type = queryParams.get("type");

      if (!type || !token) {
        return;
      }

      await changeBookingStatusService(type, token, values);

      const iconHtml = (
        <img src={PUBLIC_URL + "/assets/icons/icon-check-green.svg"} />
      );

      const options = SWAL_CONFIRM_OPTION({
        iconHtml,
        title: "Rejected Successfully!",
        confirmText: "OK",
      });
      SweetAlertCommon({
        options: { ...options, allowOutsideClick: false },
        confirmCallback: () => navigate(DASHBOARD_DESKIMO_LINK),
      });
    } catch (e: any) {
      toastError(e?.status?.message);
    }
  };

  return (
    <LoginTemplate>
      <div className={"login__title"}>{translations.rejectBooking}</div>
      <Formik
        enableReinitialize
        initialValues={{
          reason_id: declineReasons[0]?.id,
          description: "",
        }}
        onSubmit={_handleSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          isSubmitting,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            <div>
              <form className={"form-layout"}>
                <div className={"form-decline-text"}>
                  {translations.rejectBookingSelectReason}
                </div>
                <DropdownCustom
                  scrolling
                  selectUI
                  onChange={(e, { value }) => {
                    setFieldValue("reason_id", value);
                    setFieldValue("description", "");
                  }}
                  value={values.reason_id}
                  className={"w-100 form-decline-dropdown"}
                  options={declineReasons.map((reason: any) => {
                    return {
                      key: reason.id,
                      value: reason.id,
                      text: reason.text,
                    };
                  })}
                />
                {isDescription(values.reason_id) ? (
                  <TextArea
                    placeholder={"Description"}
                    className={"text-area form-decline-textarea"}
                    rows={4}
                    value={values.description}
                    error={errors.description}
                    touched={touched.description}
                    name={"description"}
                    onChange={handleChange}
                    disabled={isSubmitting}
                  />
                ) : null}
              </form>
              <hr className={"form-decline-hr"} />
            </div>
            <div>
              <ButtonCustom
                loading={isSubmitting}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
                width={"100%"}
              >
                {translations.submitCancelBooking}
              </ButtonCustom>
            </div>
          </>
        )}
      </Formik>
    </LoginTemplate>
  );
};

export default BookingDeclineReason;
