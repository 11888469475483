import { Modal } from "semantic-ui-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import ButtonCustom from "../../../../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { modalEditEmployeeAction } from "../../../../actions/EmployeeActions";
import {
  employeeDetailSelector,
  isModalEditEmployeeSelector,
} from "../../../../selectors/EmployeeSelectors";
import ModalRightSideBarTemplate from "../../../../../App/views/ModalRightSideBarTemplate";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { Formik } from "formik";
import * as yup from "yup";
import Label from "../../../../../../components/Label";
import SizeBox from "../../../../../../components/SizeBox";
import InputCustom from "../../../../../../components/Input";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { DeskimoUserDetailType } from "../../../../configs/DeclareTypes";
import { updateDeskimoUserDetailService } from "../../../../services/EmployeeServices";
import {
  deskimoUserDetailThunk,
  deskimoUsersThunk,
} from "../../../../thunks/EmployeeThunks";
import { fileValidation } from "../../../../../../utils/commons";
import ModalCropImage from "../../../../../App/views/ModalCropImage";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useLocation, useParams } from "react-router-dom";
import { DESKIMO_USER_DETAIL_LINK } from "../../../../../App/configs/ListRoutes";
import InputPhoneFlag from "../../../../../../components/InputPhoneFlag";
import { DIALING_CODE } from "../../../../../App/configs/PhonePrefix";
import Company from "./Company";

const ModalEditEmployee = () => {
  const { translations } = useContext(LocalizationContext);
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const isModalEditEmployee = useSelector(isModalEditEmployeeSelector);
  const formRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const uploadFileRef = useRef<any>();
  const [previewImage, setPreviewImage] = useState(null);
  const [openModalCrop, setOpenModalCrop] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const [entitlements, setEntitlements] = useState<any[]>([]);

  const employeeDetail: DeskimoUserDetailType = useSelector(
    employeeDetailSelector
  );

  useEffect(() => {
    if (!employeeDetail) {
      return;
    }
    setEntitlements(employeeDetail.user_types);
  }, [employeeDetail]);

  const _handleSave = async (values: any) => {
    setLoading(true);
    try {
      const payload = { ...values };
      payload.monthly_budget = parseInt(payload.monthly_budget);
      payload.entitlements = entitlements;
      await updateDeskimoUserDetailService(payload);
      dispatch(modalEditEmployeeAction(false));

      if (
        location.pathname ===
          DESKIMO_USER_DETAIL_LINK.replaceAll(":id", String(id)) &&
        id
      ) {
        await dispatch<any>(deskimoUserDetailThunk(id));
      } else {
        await dispatch<any>(deskimoUsersThunk());
      }

      toastSuccess("Updated successfully");
    } catch (e: any) {
      toastError(e?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const PreviewImage = ({ uri }: any) => {
    return <img src={uri} />;
  };

  const _onCloseModal = () => {
    setPreviewImage(null);
    dispatch(modalEditEmployeeAction(false));
  };

  const handleUploadFile = async (e: any) => {
    const reader: any = new FileReader();
    reader.addEventListener("load", () =>
      setImgSrc(reader.result.toString() || "")
    );
    reader.readAsDataURL(e.target.files[0]);
    setOpenModalCrop(true);
  };

  const selectedFlag = (dialing_code: any) => {
    if (!dialing_code) {
      return "";
    }
    const result = DIALING_CODE.filter((number: any) => {
      return number.value.replaceAll("+", "") === dialing_code;
    });
    if (result.length > 0 && result[0]?.key) {
      return result[0].key.toUpperCase();
    } else {
      return "";
    }
  };

  const onSelectFlag = (code: any, setFieldValue: any) => {
    const result = DIALING_CODE.filter((number: any) => {
      return number.key === code.toLowerCase();
    });
    if (result.length > 0 && result[0]?.value) {
      const dialingCode = DIALING_CODE.filter((number: any) => {
        return number.key === code.toLowerCase();
      })[0].value.replaceAll("+", "");
      setFieldValue("dialing_code", dialingCode);
    } else {
      setFieldValue("dialing_code", "");
    }
  };

  return (
    <ModalRightSideBarTemplate
      trigger={null}
      onClose={_onCloseModal}
      modalClassName={"modal-edit-employee"}
      open={isModalEditEmployee}
    >
      <Modal.Header>
        {translations.editUser}
        <ButtonCustom
          onClick={_onCloseModal}
          className={"m-0 p-0 none-bg close-modal"}
        >
          <>
            {translations.close}
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Modal.Content className={"scroll"}>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={{
            avatar_url: employeeDetail?.avatar || "",
            user_id: employeeDetail?.user_id,
            first_name: employeeDetail?.first_name || "",
            last_name: employeeDetail?.last_name || "",
            email: employeeDetail?.email || "",
            phone: employeeDetail?.phone || "",
            dialing_code: employeeDetail?.dialing_code || "",
          }}
          validationSchema={yup.object().shape({
            first_name: yup.string().required().label("First Name"),
            email: yup.string().email().required().label("Email"),
          })}
          onSubmit={_handleSave}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => (
            <form className={"form-layout"}>
              <div>
                <Label
                  className={"sm"}
                  label={translations.profilePicture}
                  labelGuide={translations.uploadImageFormat}
                />

                <div className={"d-flex align-items-center mb-48px"}>
                  <div className={"my-profile__avatar"}>
                    {previewImage ? (
                      <PreviewImage uri={previewImage} />
                    ) : (
                      <img
                        src={
                          values?.avatar_url
                            ? values?.avatar_url
                            : PUBLIC_URL + "/assets/default-add-avatar.svg"
                        }
                      />
                    )}

                    <input
                      className={"d-none"}
                      ref={uploadFileRef}
                      type="file"
                      accept="image/*"
                      onChange={async (event: any) => {
                        const isFileValid = fileValidation(event.target.files);

                        if (isFileValid) {
                          const _URL = window.URL || window.webkitURL;
                          const img = new Image();
                          const objectUrl = _URL.createObjectURL(
                            event.target.files[0]
                          );

                          img.src = objectUrl;
                          img.onload = async () => {
                            _URL.revokeObjectURL(objectUrl);
                            await handleUploadFile(event);
                            event.target.value = null;
                          };
                          setOpenModalCrop(true);
                        } else {
                          toastError("The file must be smaller than 2MB");
                        }
                      }}
                    />
                  </div>

                  <SizeBox width={"32px"} />

                  <ButtonCustom
                    onClick={(e: any) => {
                      e.preventDefault();
                      uploadFileRef.current.click();
                    }}
                    className={"primary-alt"}
                    height={"36px"}
                  >
                    <>
                      {translations.uploadImage}
                      <SizeBox width={"14px"} />
                      <i className="uil uil-upload-alt" />
                    </>
                  </ButtonCustom>
                </div>
              </div>
              <SizeBox height={"36px"} />
              <InputCustom
                labelRequired
                label={"First Name"}
                value={values.first_name}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.first_name}
                error={errors.first_name}
                name={"first_name"}
              />
              <InputCustom
                label={translations.lastName}
                value={values.last_name}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.last_name}
                error={errors.last_name}
                name={"last_name"}
              />
              <InputCustom
                labelRequired
                label={translations.emailAddress}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.email}
                error={errors.email}
                name={"email"}
              />
              <div className={"mb-16px"} />
              <InputPhoneFlag
                label={translations.mobilePhoneNumber}
                labelGuide={translations.addMobilePhoneLabelGuide}
                selectedFlag={selectedFlag(values.dialing_code)}
                onSelectFlag={(code) => onSelectFlag(code, setFieldValue)}
                error={errors.phone}
                touched={touched.phone}
                phoneChange={(value) => setFieldValue("phone", value)}
                phoneValue={values.phone}
              />
              <ModalCropImage
                imgSrc={imgSrc}
                open={openModalCrop}
                setOpen={setOpenModalCrop}
                setFieldValue={setFieldValue}
                fieldKey={"avatar_url"}
                setPreviewImage={setPreviewImage}
              />
              <SizeBox height={"16px"} />
              <Label label={translations.userType} className={"fw-600"} />
              <SizeBox height={"16px"} />
              <div className={"deskimo-user__company"}>
                <b className={"name"}>Company name</b>
                <b className={"make-admin"}>Make admin</b>
                <b className={"delete"}>Delete</b>
              </div>
              {entitlements.map((item, index) => (
                <Company
                  item={item}
                  key={index}
                  setEntitlements={setEntitlements}
                />
              ))}
            </form>
          )}
        </Formik>
      </Modal.Content>
      <Modal.Actions>
        <ButtonCustom height={"47px"} onClick={_onCloseModal}>
          {translations.discard}
        </ButtonCustom>
        <ButtonCustom
          type={"submit"}
          loading={loading}
          className={"primary"}
          height={"47px"}
          onClick={() => {
            formRef?.current?.handleSubmit();
          }}
        >
          {translations.save}
        </ButtonCustom>
      </Modal.Actions>
    </ModalRightSideBarTemplate>
  );
};

export default ModalEditEmployee;
