import axios, { AxiosRequestConfig } from "axios";
import {
  ACCESS_TOKEN_CACHE,
  CURRENT_COMPANY_ID,
  INTERNAL_SERVER_ERROR,
  METHOD_GET,
  UNAUTHORIZED,
} from "../configs/Constants";
import { API_DOMAIN } from "../configs/Endpoints";
import { LOGOUT_LINK } from "../modules/App/configs/ListRoutes";
import { isObject } from "../utils/commons";
import { toastError } from "../components/Toast";

interface clientRequestServiceProps extends AxiosRequestConfig {
  isAuth?: boolean;
  isCompany?: boolean;
  url: string;
  isFile?: boolean;
}

const clientRequestService = async ({
  isAuth = true,
  isCompany,
  data,
  method = METHOD_GET,
  url,
  isFile,
  ...params
}: clientRequestServiceProps) => {
  const headers: any = {};
  let payload = { ...data };
  let URL = url;

  if (isAuth) {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_CACHE);

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken?.replace(/^"|"$/g, "")}`;
    }
  }

  if (isCompany) {
    let companyId = localStorage.getItem(CURRENT_COMPANY_ID);

    if (!companyId) {
      companyId = "0";
    }

    if (URL.includes("%companyId%")) {
      URL = URL.replaceAll("%companyId%", companyId);
    }

    if (method !== METHOD_GET) {
      payload = { ...payload, company_id: companyId };
    }
  }

  headers["X-User-Agent"] = navigator.userAgent;

  const config = {
    method,
    baseURL: API_DOMAIN,
    headers,
    data: isFile ? data : payload,
    url: URL,
    ...params,
  };

  const { data: dataResponse } = await axios.request(config);
  if (
    dataResponse?.status?.code !== undefined &&
    dataResponse?.status?.code !== 200
  ) {
    throw dataResponse;
  }
  return dataResponse;
};

export const handleClientRequestException = () => {
  axios.interceptors.request.use(
    (config) => {
      try {
        const { method, data } = config;

        // Trim space on payload
        if (
          !method ||
          method.toLowerCase().includes("get") ||
          !isObject(data)
        ) {
          return config;
        }

        const newData: object = { ...data };

        Object.keys(newData).forEach((key) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (typeof newData[key] !== "string") {
            return;
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          newData[key] = newData[key].trim();
        });

        return { ...config, data: newData };
      } catch (e) {
        return config;
      }
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error?.message?.includes("timeout")) {
        return Promise.reject();
      }

      const response = error?.response;
      switch (response?.status) {
        case UNAUTHORIZED:
          localStorage.removeItem(ACCESS_TOKEN_CACHE);
          sessionStorage.setItem("redirect_url", window.location.pathname);
          window.location.href = LOGOUT_LINK;
          return Promise.reject();
        case INTERNAL_SERVER_ERROR:
          toastError(
            "An error occurred. Contact support@deskimo.com for support."
          );
          return Promise.reject();
      }

      return Promise.reject(error);
    }
  );
};

export default clientRequestService;
