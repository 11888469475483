import { Accordion, Dropdown, Modal } from "semantic-ui-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import ButtonCustom from "../../../../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import ModalRightSideBarTemplate from "../../../../../App/views/ModalRightSideBarTemplate";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { Formik } from "formik";
import { DIALING_CODE } from "../../../../../App/configs/PhonePrefix";
import * as yup from "yup";
import Label from "../../../../../../components/Label";
import SizeBox from "../../../../../../components/SizeBox";
import InputCustom from "../../../../../../components/Input";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { fileValidation } from "../../../../../../utils/commons";
import ModalCropImage from "../../../../../App/views/ModalCropImage";
import { LocalizationContext } from "../../../../../../locales/Translation";
import {
  deskimoPropertyStaffListFilterSelector,
  modalDeskimoWorkspaceStaffListSelector,
} from "../../../../selectors/PropertySelector";
import { modalDeskimoWorkspaceStaffListAction } from "../../../../actions/PropertyActions";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import InputPhoneFlag from "../../../../../../components/InputPhoneFlag";
import BoxToggleSlider from "../../../../../../components/BoxToggleSlider";
import MakeAdminLabelGuide from "../../../../../App/views/ModalAddEmployee/MakeAdminLableGuide";
import "./style.scss";
import {
  employeePropertyInviteService,
  employeePropertyUpdateService,
  getEmployeeDetailPropertyService,
  searchPropertyService,
} from "../../../../../User/services/EmployeeServices";
import { getListMarketAndCompanyService } from "../../../../services/PropertyCompanyServices";
import {
  ROLE_DESKIMO_ADMIN_ID,
  ROLE_PROPERTY_ADMIN_ID,
  ROLE_PROPERTY_STAFF_ID,
} from "../../../../../User/configs/Constants";
import { getDeskimoPropertyStaffThunk } from "../../../../thunks/PropertyThunks";

const ModalAddEditStaff = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const modalDeskimoWorkspaceStaffList = useSelector(
    modalDeskimoWorkspaceStaffListSelector
  );

  const uploadFileRef = useRef<any>();
  const [previewImage, setPreviewImage] = useState(null);
  const [openModalCrop, setOpenModalCrop] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const [activeIndex, setActiveIndex] = useState([0, 1]);
  const [listPropertyAssigned, setListPropertyAssigned] = useState<any>([]);
  const [listPropertySearch, setListPropertySearch] = useState([]);
  const [propertyWillAdd, setPropertyWillAdd] = useState<any>({});
  const [tmpProperty, setTmpProperty] = useState("");

  const [staffDetail, setStaffDetail] = useState<any>(null);

  const [propertyCompanyOptions, setPropertyCompanyOptions] = useState([]);
  const propertyStaffListFilter = useSelector(
    deskimoPropertyStaffListFilterSelector
  );

  useEffect(() => {
    (async () => {
      if (!modalDeskimoWorkspaceStaffList.open) {
        return;
      }

      if (modalDeskimoWorkspaceStaffList.id) {
        const { data } = await getEmployeeDetailPropertyService(
          modalDeskimoWorkspaceStaffList.companyId,
          modalDeskimoWorkspaceStaffList.id
        );
        setStaffDetail(data);
        const properties = data.properties.map(
          ({ id, name }: { id: number; name: string }) => ({
            key: id,
            text: name,
            value: id,
          })
        );
        setListPropertyAssigned(properties);
      } else {
        setStaffDetail(null);
        setListPropertyAssigned([]);
        await _getCompanyList();
      }
    })();
  }, [modalDeskimoWorkspaceStaffList]);

  const _fetchPropertyList = async ({
    keyword,
    companyId,
  }: {
    keyword?: string;
    companyId: string;
  }) => {
    try {
      const response = await searchPropertyService({ keyword, companyId });
      if (!response?.data) {
        return;
      }

      setListPropertySearch(
        response?.data.map((item: any) => {
          return {
            key: item?.id,
            text: `${item?.text} (${
              item?.is_published ? "Published" : "Unpublished"
            })`,
            value: item?.id,
          };
        })
      );
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const _getCompanyList = async () => {
    try {
      const { data } = await getListMarketAndCompanyService({
        getOnlyActive: 1,
      });
      const companies = data?.companies;
      if (!companies) {
        return;
      }

      const temp = companies.map(
        ({
          id,
          name,
          country_code,
        }: {
          id: number;
          name: string;
          country_code: string;
        }) => ({
          key: id,
          text: name,
          value: id,
          dialingCode: country_code,
        })
      );
      setPropertyCompanyOptions(temp);
    } catch (e: any) {
      console.log(e);
    }
  };

  const _handleSave = async (values: any) => {
    try {
      const payload = { ...values };
      payload.properties = listPropertyAssigned.map(
        (property: any) => property.key
      );

      if (modalDeskimoWorkspaceStaffList.id) {
        await employeePropertyUpdateService(payload);
        toastSuccess(translations.editSuccessPrompt);
      } else {
        await employeePropertyInviteService(payload);
        toastSuccess(translations.createSuccessPrompt);
      }
      await dispatch<any>(
        getDeskimoPropertyStaffThunk(propertyStaffListFilter)
      );

      dispatch(modalDeskimoWorkspaceStaffListAction({ open: false, id: null }));
    } catch (e) {
      if (modalDeskimoWorkspaceStaffList.id) {
        toastError(translations.editFailPrompt);
      } else {
        toastError(translations.createFailPrompt);
      }
    }
  };

  const _onCloseModal = () => {
    setPreviewImage(null);
    dispatch(modalDeskimoWorkspaceStaffListAction({ open: false, id: null }));
  };

  const handleUploadFile = async (e: any) => {
    const reader: any = new FileReader();
    reader.addEventListener("load", () =>
      setImgSrc(reader.result.toString() || "")
    );
    reader.readAsDataURL(e.target.files[0]);
    setOpenModalCrop(true);
  };

  const handleClick = (e: any, title: any) => {
    const index = title.index;
    if (activeIndex.includes(index)) {
      setActiveIndex(activeIndex.filter((item) => item !== index));
    } else {
      setActiveIndex([...activeIndex, index]);
    }
  };

  return (
    <ModalRightSideBarTemplate
      trigger={null}
      onClose={_onCloseModal}
      modalClassName={"modal-right-sidebar-v2"}
      open={modalDeskimoWorkspaceStaffList.open}
    >
      <Formik
        enableReinitialize
        initialValues={{
          avatar: staffDetail?.avatar || "",
          user_id: staffDetail?.user_id || "",
          first_name: staffDetail?.first_name || "",
          last_name: staffDetail?.last_name || "",
          email: staffDetail?.email || "",
          phone_number: staffDetail?.phone_number || "",
          phone_prefix: staffDetail?.phone_prefix || "65",
          make_admin: staffDetail?.role_id !== ROLE_PROPERTY_STAFF_ID,
          company_id: modalDeskimoWorkspaceStaffList?.companyId || "",
          job_title: staffDetail?.job_title || "",
        }}
        validationSchema={yup.object().shape({
          first_name: yup.string().required().label("First Name"),
          email: yup.string().email().required().label("Email"),
          company_id: yup.string().required(),
        })}
        onSubmit={_handleSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting,
          handleSubmit,
        }) => (
          <>
            <Modal.Header>
              {modalDeskimoWorkspaceStaffList.id
                ? translations.editUser
                : translations.addUser}
              <ButtonCustom
                onClick={_onCloseModal}
                className={"m-0 p-0 none-bg close-modal"}
              >
                <>
                  {translations.close}
                  <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
                </>
              </ButtonCustom>
            </Modal.Header>
            <Modal.Content className={"scroll"}>
              <form className={"form-layout"}>
                <Accordion
                  className={"accordion-wrapper"}
                  defaultActiveIndex={activeIndex}
                  exclusive={false}
                  fluid
                >
                  <Accordion.Title
                    className={"accordion-title"}
                    active={activeIndex.includes(0)}
                    index={0}
                    onClick={handleClick}
                  >
                    Basic Information
                    <img
                      className="dropdown icon"
                      src={PUBLIC_URL + "/assets/icons/icon-caret-down.svg"}
                      alt={""}
                    />
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex.includes(0)}>
                    <Label
                      className={"sm"}
                      fontSize="12px"
                      label={translations.profilePicture}
                      labelGuide={translations.uploadImageFormat}
                    />
                    <SizeBox height="16px" />
                    <div className={"d-flex align-items-center mb-48px"}>
                      <div className={"my-profile__avatar"}>
                        {previewImage ? (
                          <img src={previewImage} />
                        ) : (
                          <img
                            src={
                              values?.avatar
                                ? values?.avatar
                                : PUBLIC_URL + "/assets/default-add-avatar.svg"
                            }
                          />
                        )}

                        <input
                          className={"d-none"}
                          ref={uploadFileRef}
                          type="file"
                          accept="image/*"
                          onChange={async (event: any) => {
                            const isFileValid = fileValidation(
                              event.target.files
                            );

                            if (isFileValid) {
                              const _URL = window.URL || window.webkitURL;
                              const img = new Image();
                              const objectUrl = _URL.createObjectURL(
                                event.target.files[0]
                              );

                              img.src = objectUrl;
                              img.onload = async () => {
                                _URL.revokeObjectURL(objectUrl);
                                await handleUploadFile(event);
                                event.target.value = null;
                              };
                              setOpenModalCrop(true);
                            } else {
                              toastError("The file must be smaller than 2MB");
                            }
                          }}
                        />
                      </div>
                      <div>
                        <div className={"d-flex align-items-center mb-48px"}>
                          <SizeBox width={"32px"} />
                          <ButtonCustom
                            onClick={(e: any) => {
                              e.preventDefault();
                              uploadFileRef.current.click();
                            }}
                            className={"primary-alt"}
                            height={"36px"}
                          >
                            <>
                              {translations.uploadImage}
                              <SizeBox width={"14px"} />
                              <i className="uil uil-upload-alt" />
                            </>
                          </ButtonCustom>
                        </div>
                      </div>
                    </div>
                    <SizeBox height="36px" />
                    <div className="d-flex">
                      <InputCustom
                        labelRequired
                        containerClass="w-100"
                        label="First Name"
                        value={values.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.first_name}
                        error={errors.first_name}
                        name="first_name"
                      />
                      <InputCustom
                        label={translations.lastName}
                        value={values.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.last_name}
                        error={errors.last_name}
                        containerClass="w-100 ml-16px"
                        name="last_name"
                      />
                    </div>
                    <InputCustom
                      label={"Job Title"}
                      value={values.job_title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched.job_title}
                      error={errors.job_title}
                      name={"job_title"}
                    />
                    <InputCustom
                      labelRequired
                      label={translations.emailAddress}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched.email}
                      error={errors.email}
                      name={"email"}
                    />
                    <div className={"mb-16px"} />
                    <InputPhoneFlag
                      label={translations.mobilePhoneNumber}
                      labelGuide={translations.addMobilePhoneLabelGuide}
                      selectedFlag={DIALING_CODE.filter(
                        (country) =>
                          country?.value?.replaceAll("+", "") ===
                          values.phone_prefix
                      )[0]?.key.toUpperCase()}
                      onSelectFlag={(code) => {
                        setFieldValue(
                          "phone_prefix",
                          DIALING_CODE.filter(
                            (country) => country?.flag.toUpperCase() === code
                          )[0]?.value.replaceAll("+", "")
                        );
                      }}
                      error={errors.phone_number}
                      touched={touched.phone_number}
                      phoneChange={(value) =>
                        setFieldValue("phone_number", value)
                      }
                      phoneValue={values.phone_number}
                    />
                  </Accordion.Content>
                  <SizeBox height="16px" />
                  <BoxToggleSlider
                    value={values.make_admin}
                    onChange={(e: any, data: any) =>
                      setFieldValue("make_admin", data.checked)
                    }
                    content={
                      <Label
                        fontSize={"14px"}
                        size={"16px"}
                        label={translations.makeAdmin}
                        labelGuide={<MakeAdminLabelGuide />}
                      />
                    }
                  />
                  <SizeBox height={"16px"} />
                  <Accordion.Title
                    className={"accordion-title"}
                    active={activeIndex.includes(1)}
                    index={1}
                    onClick={handleClick}
                  >
                    Property
                    <img
                      className="dropdown icon"
                      src={PUBLIC_URL + "/assets/icons/icon-caret-down.svg"}
                      alt={""}
                    />
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex.includes(1)}>
                    <SizeBox height={"24px"} />
                    <div>
                      <Label
                        label={"Property Company"}
                        className={"sm"}
                        fontSize={"12px"}
                      />
                      <DropdownCustom
                        search
                        disabled={modalDeskimoWorkspaceStaffList.id}
                        scrolling
                        selectUI={true}
                        placeholder={"Please select a property company"}
                        style={{
                          height: "44px",
                          width: "100%",
                          borderRadius: "4px",
                        }}
                        options={propertyCompanyOptions}
                        value={values.company_id}
                        onChange={(event, { value }) => {
                          setFieldValue("company_id", value);
                          _fetchPropertyList({ companyId: String(value) });
                          if (!values.phone_number) {
                            const companyInfo = propertyCompanyOptions.find(
                              ({ value: objValue }) => objValue === value
                            );

                            if (!companyInfo) {
                              return;
                            }

                            setFieldValue(
                              "phone_prefix",
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              companyInfo.dialingCode
                            );
                          }
                        }}
                        error={Boolean(touched.company_id && errors.company_id)}
                      />
                    </div>
                    <SizeBox height={"24px"} />
                    <div
                      className={"d-flex w-100"}
                      style={{ marginBottom: 16 }}
                    >
                      <Dropdown
                        disabled={!values.company_id}
                        onSearchChange={(event, { searchQuery }) =>
                          _fetchPropertyList({
                            keyword: searchQuery,
                            companyId: values.company_id,
                          })
                        }
                        onChange={(event, { value }) => {
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          setTmpProperty(value);
                          const isAssigned =
                            listPropertyAssigned.filter(
                              (property: any) => property?.value === value
                            ).length > 0;
                          if (!isAssigned) {
                            setPropertyWillAdd(
                              listPropertySearch.filter(
                                (property: any) => property?.value === value
                              )[0]
                            );
                          }
                        }}
                        value={tmpProperty}
                        className={"dropdown-search-property"}
                        placeholder="Search property name..."
                        search
                        icon={"search"}
                        selection
                        options={listPropertySearch}
                      />
                      <SizeBox width={"16px"} />
                      <ButtonCustom
                        type={"button"}
                        onClick={() => {
                          setTmpProperty("");
                          if (propertyWillAdd) {
                            setListPropertyAssigned([
                              ...listPropertyAssigned,
                              propertyWillAdd,
                            ]);
                            setPropertyWillAdd(null);
                          }
                        }}
                        className={"primary text-nowrap add-property-cta"}
                      >
                        Add Property
                      </ButtonCustom>
                    </div>
                    <div>
                      {listPropertyAssigned.length > 0
                        ? listPropertyAssigned.map(
                            (property: any, index: number) => {
                              if (!property?.text) {
                                return null;
                              }
                              return (
                                <div
                                  key={index}
                                  className={"property-assigned"}
                                >
                                  {property?.text}{" "}
                                  <ButtonCustom
                                    type={"button"}
                                    onClick={() => {
                                      setListPropertyAssigned(
                                        (prevState: any) => {
                                          let newState = JSON.parse(
                                            JSON.stringify(prevState)
                                          );
                                          newState = newState.filter(
                                            (item: any) => {
                                              return item.key != property.key;
                                            }
                                          );
                                          return newState;
                                        }
                                      );
                                    }}
                                    className={"none-bg"}
                                  >
                                    <i className="uil uil-times" />
                                  </ButtonCustom>
                                </div>
                              );
                            }
                          )
                        : null}
                    </div>
                  </Accordion.Content>
                </Accordion>
                <ModalCropImage
                  imgSrc={imgSrc}
                  open={openModalCrop}
                  setOpen={setOpenModalCrop}
                  setFieldValue={setFieldValue}
                  fieldKey={"avatar"}
                  setPreviewImage={setPreviewImage}
                />
              </form>
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom height={"47px"} onClick={_onCloseModal}>
                {translations.discard}
              </ButtonCustom>
              <ButtonCustom
                type={"submit"}
                loading={isSubmitting}
                disabled={isSubmitting}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {translations.save}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalRightSideBarTemplate>
  );
};

export default ModalAddEditStaff;
