import { useDispatch } from "react-redux";
import React, { Dispatch, useContext, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { SWAL_DELETE_OPTION } from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import ButtonCustom from "../../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import { businessCompanyPackageCancelService } from "../../../../services/BusinessServices";
import { businessCompanyCustomPackageThunk } from "../../../../thunks/BusinessThunks";
import { modalBusinessCompanyCustomPackageCreateEditAction } from "../../../../actions/BusinessActions";

type Props = {
  packageId: number;
  packageType: number;
  cancelStatus: boolean;
  marketOptions: any;
  setEditId: any;
};

const STATUS_IS_CANCELED = true;
const STATUS_IS_NOT_CANCELED = false;

const Action = ({
  packageId,
  packageType,
  cancelStatus,
  marketOptions,
  setEditId,
}: Props) => {
  const dispatch = useDispatch();
  const { translations } = useContext(LocalizationContext);
  const DEFAULT_PAYLOAD: {
    package_type: number;
    page: number;
    keyword: string;
    market_code: any;
  } = {
    package_type: packageType,
    page: 1,
    keyword: "",
    market_code: marketOptions.map(({ id }: { id: string }) => id),
  };
  const [disabled, setDisabled] = useState(false);

  const _onEditPackage = async () => {
    try {
      setEditId(packageId);
      dispatch(modalBusinessCompanyCustomPackageCreateEditAction(true));
    } catch (e) {
      console.error(e);
    }
  };

  const _onCancelPackage = async () => {
    const iconHtml = (
      <img
        alt="error_icon"
        src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
        className={"swal2-icon__error"}
      />
    );
    const title = translations.customPackages.cancelCustomPackageAskMessage;
    const confirmText = translations.delete;
    const cancelText = translations.cancel;
    const options = SWAL_DELETE_OPTION({
      title,
      iconHtml,
      confirmText,
      cancelText,
    });
    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setDisabled(true);
        try {
          await businessCompanyPackageCancelService(packageType, packageId);
          await dispatch<any>(
            businessCompanyCustomPackageThunk(DEFAULT_PAYLOAD)
          );
          toastSuccess(translations.customPackages.cancelSuccessfulMessage);
        } catch (e: any) {
          toastError(e?.status?.message);
        } finally {
          setDisabled(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  return (
    <DropdownCustom
      disabled={disabled}
      className={"table-action-dropdown"}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={_onEditPackage}
          text={"Edit"}
          icon={
            <i className="uil uil-edit icon" style={{ fontSize: "16px" }} />
          }
        />
        <Dropdown.Item
          onClick={_onCancelPackage}
          text={translations.delete}
          icon={
            <i
              className="uil uil-trash-alt icon"
              style={{ fontSize: "16px" }}
            />
          }
        />
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default Action;
