import {
  CAMPAIGN_DETAIL_ACTION,
  CAMPAIGN_FILTER_ACTION,
  CAMPAIGN_LIST_ACTION,
  CAMPAIGN_LIST_REMOVE_ITEM_ACTION,
  CAMPAIGN_LIST_UPDATE_STATUS_ACTION,
} from "../configs/Constants";

export const campaignFilterAction = (data: object) => {
  return {
    type: CAMPAIGN_FILTER_ACTION,
    payload: data,
  };
};

export const campaignListAction = (data: object) => {
  return {
    type: CAMPAIGN_LIST_ACTION,
    payload: data,
  };
};

export const campaignListRemoveItemAction = (campaignId: number) => {
  return {
    type: CAMPAIGN_LIST_REMOVE_ITEM_ACTION,
    payload: campaignId,
  };
};

export const campaignListUpdateStatusAction = (campaignId: number) => {
  return {
    type: CAMPAIGN_LIST_UPDATE_STATUS_ACTION,
    payload: campaignId,
  };
};

export const campaignDetailAction = (data: object) => {
  return {
    type: CAMPAIGN_DETAIL_ACTION,
    payload: data,
  };
};
