import React from "react";
import "./styles.scss";
import classNames from "classnames";

type Props = {
  label: string;
  value: string | string[] | number | null;
  breakLine?: boolean;
  containerClass?: string;
  valueContainerClass?: string;
};

const SectionWhiteBoxItem = ({
  label,
  value,
  breakLine,
  valueContainerClass,
  containerClass,
}: Props) => {
  if (value === undefined || value === null) {
    return null;
  }

  return (
    <div
      className={classNames("section-white-box-item", containerClass, {
        "break-line": breakLine,
      })}
    >
      <span className={"label"}>{label}:</span>
      <div className={classNames(valueContainerClass)}>
        {Array.isArray(value) ? (
          value.map((text: string, index: number) => (
            <div key={text + index} className={"value"}>
              {text}
            </div>
          ))
        ) : (
          <div className={"value"}>{value}</div>
        )}
      </div>
    </div>
  );
};

export default SectionWhiteBoxItem;
