import React, { useContext } from "react";
import SizeBox from "../../../../../components/SizeBox";
import { LocalizationContext } from "../../../../../locales/Translation";
import { FormikValues } from "formik";
import InputCustom from "../../../../../components/Input";
import InputPhoneFlag from "../../../../../components/InputPhoneFlag";
import { DIALING_CODE } from "../../../../App/configs/PhonePrefix";

type Props = {
  values: FormikValues;
  errors: any;
  touched: any;
  isSubmitting: boolean;
  handleChange: any;
  setFieldValue: any;
};
const Contact = ({
  isSubmitting,
  setFieldValue,
  values,
  errors,
  touched,
  handleChange,
}: Props) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <div className={"section"}>
      <SizeBox height={"16px"} />
      <div className={"section-title"}>
        {translations.propertyContactDetails}
      </div>
      <SizeBox height={"16px"} />
      <InputCustom
        label={translations.name}
        value={values.contact_details.name}
        onChange={handleChange}
        touched={touched?.contact_details?.name}
        error={errors?.contact_details?.name}
        name={"contact_details.name"}
        labelRequired
        disabled={isSubmitting}
      />
      <InputCustom
        label={translations.email}
        value={values.contact_details.email}
        onChange={handleChange}
        touched={touched?.contact_details?.email}
        error={errors?.contact_details?.email}
        name={"contact_details.email"}
        labelRequired
        disabled={isSubmitting}
      />
      <InputPhoneFlag
        label={translations.phoneNo}
        required
        selectedFlag={DIALING_CODE.filter((country) => {
          return (
            country?.value?.replaceAll("+", "") ===
            values.contact_details.phone_prefix
          );
        })[0]?.key.toUpperCase()}
        onSelectFlag={(code) =>
          setFieldValue(
            "contact_details.phone_prefix",
            DIALING_CODE.filter(
              (country) => country?.flag.toUpperCase() === code
            )[0]?.value.replaceAll("+", "")
          )
        }
        touched={touched?.contact_details?.phone}
        error={errors?.contact_details?.phone}
        phoneChange={(value) => setFieldValue("contact_details.phone", value)}
        phoneValue={values.contact_details.phone}
        disabled={isSubmitting}
      />
      {/*<InputCustom*/}
      {/*  label={translations.phoneNo}*/}
      {/*  value={values?.contact_details?.phone}*/}
      {/*  onChange={handleChange}*/}
      {/*  touched={touched?.contact_details?.phone}*/}
      {/*  error={errors?.contact_details?.phone}*/}
      {/*  name={"contact_details.phone"}*/}
      {/*  labelRequired*/}
      {/*  disabled={isSubmitting}*/}
      {/*/>*/}
      <SizeBox height={"16px"} />
    </div>
  );
};

export default Contact;
