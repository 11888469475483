import React from "react";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

type Props = {
  labels: string[];
  data: string[];
  color: string[];
  labelWithoutPercent?: boolean;
  showAllLabel?: boolean;
};

const UtilDoughnutChart = ({
  data,
  labels,
  color,
  labelWithoutPercent,
  showAllLabel,
}: Props) => {
  const checkNull = data.every(
    (value: string | number) => value === "0" || value === 0
  );

  const optionsEmpty = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        enabled: false, // <-- this option disables tooltips
      },
    },
    hover: { mode: null },
    cutout: "70%",
    layout: {
      padding: 50,
    },
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function ({ dataIndex, label, dataset }: any) {
            return " " + label + ": " + dataset.data[dataIndex] + "%";
          },
        },
      },
      datalabels: {
        color,
        font: {
          weight: 500,
          size: "10px",
        },
        anchor: "start",
        align: "end",
        offset: 25,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        formatter: (value, context) => {
          const data = context.dataset.data[context.dataIndex];
          if (data < 10 && !showAllLabel) {
            return null;
          }

          if (data < 8 && context.dataset.data[context.dataIndex - 1] < 8) {
            return null;
          }

          if (labelWithoutPercent) {
            return [context.chart.data.labels[context.dataIndex]];
          }

          return [data + "%", context.chart.data.labels[context.dataIndex]];
        },
      },
    },
    cutout: "70%",
    layout: {
      padding: 50,
    },
  };

  const dataConfigEmpty = {
    labels: ["Empty"],
    datasets: [
      {
        data: [100],
        backgroundColor: ["#E9ECEE"],
      },
    ],
  };

  const dataConfig = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: color,
      },
    ],
  };
  return (
    <Doughnut
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      options={checkNull ? optionsEmpty : options}
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      data={checkNull ? dataConfigEmpty : dataConfig}
      plugins={[ChartDataLabels]}
    />
  );
};

export default UtilDoughnutChart;
