import React, { Dispatch, SetStateAction, useContext } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import {
  SWAL_CONFIRM_OPTION_V2,
  SWAL_DELETE_OPTION,
} from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import ButtonCustom from "../../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import { getURlDownloadFileService } from "../../../../../Setting/services/InvoiceServices";
import {
  businessInvoiceRepayService,
  deleteBusinessInvoiceService,
  postPaidBusinessInvoiceService,
  resendBusinessInvoiceService,
} from "../../../../services/BusinessServices";
import { BUSINESS_COMPANY_INVOICE_DETAIL_LINK } from "../../../../../App/configs/ListRoutes";
import { onPayWithCredit } from "../../../../../Dashboard/services/DashboardServices";
import { BUSINESS_INVOICE } from "../../../../../Dashboard/configs/Constants";

type Props = {
  id: number;
  token: string;
  invoicePdfUrl: string;
  invoiceXlsxUrl: string;
  status: number;
  setRefreshList: Dispatch<SetStateAction<boolean>>;
  companyId: number;
  setLoading: any;
  loading: any;
  canPayNow: boolean;
  resendAble: boolean;
};

const Action = ({
  id,
  token,
  invoiceXlsxUrl,
  invoicePdfUrl,
  status,
  setRefreshList,
  companyId,
  setLoading,
  loading,
  canPayNow,
  resendAble,
}: Props) => {
  const { translations } = useContext(LocalizationContext);

  const _onRefresh = () => {
    setRefreshList((prevState) => !prevState);
  };

  const _onDetail = () => {
    window.open(
      BUSINESS_COMPANY_INVOICE_DETAIL_LINK.replaceAll(
        ":id",
        String(companyId)
      ).replaceAll(":token", token),
      "_blank"
    );
  };

  const handleDownloadFile = async (url: string) => {
    if (!url) {
      return;
    }
    try {
      const response = await getURlDownloadFileService(url);
      window.open(response?.data?.url, "_blank");
    } catch (error) {
      console.log(Error);
    }
  };

  const deleteInvoice = async (token: any) => {
    const iconHtml = (
      <img
        src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
        className={"swal2-icon__error"}
      />
    );
    const title = translations.companyInvoices.askDeleteInvoiceTitle;
    const confirmText = (
      <div
        className={"d-flex"}
        style={{ justifyItems: "center", justifyContent: "center" }}
      >
        <div style={{ marginLeft: 5 }}>Delete</div>
      </div>
    );
    const confirmClass = "danger";
    const options = SWAL_DELETE_OPTION({
      title,
      iconHtml,
      confirmText,
      confirmClass,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          setLoading(true);
          await deleteBusinessInvoiceService(token);
          toastSuccess(translations.companyInvoices.deleteInvoiceSuccessPrompt);
          _onRefresh();
        } catch (error: any) {
          toastError(error?.status?.message);
        } finally {
          setLoading(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  const changeStatusInvoice = async (token: string, status: number) => {
    const title =
      status === 0
        ? translations.companyInvoices.changeInvoiceToPostTitle
        : translations.companyInvoices.changeInvoiceToPaidTitle;

    const options = SWAL_DELETE_OPTION({
      title,
      confirmClass: "primary",
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          setLoading(true);
          const payload = { status: status === 0 ? 1 : 2 };
          await postPaidBusinessInvoiceService(token, payload);
          toastSuccess(translations.companyInvoices.updateSuccessPrompt);
          _onRefresh();
        } catch (error: any) {
          toastError(error?.status?.message);
        } finally {
          setLoading(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  const _payNow = async () => {
    try {
      await businessInvoiceRepayService(token);
      toastSuccess(translations.rePaySuccess);
      _onRefresh();
    } catch (e: any) {
      toastError(e?.status?.message);
    }
  };

  const resendInvoice = async (token: any) => {
    const title = "Are you sure want to resend this invoice to company?";
    const options = SWAL_CONFIRM_OPTION_V2({
      title,
      confirmText: "Send",
      showCancelButton: true,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          setLoading(true);
          await resendBusinessInvoiceService(token);
          toastSuccess("Resent successfully!");
        } catch (error: any) {
          toastError(error?.status?.message);
        } finally {
          setLoading(false);
        }
      },
    });
  };

  return (
    <DropdownCustom
      disabled={loading}
      className={"table-action-dropdown"}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={_onDetail}
          text={translations.propertyPage.details}
          icon={<img src={PUBLIC_URL + "/assets/icons/icon-message.svg"} />}
        />
        <Dropdown.Item
          onClick={() => handleDownloadFile(invoicePdfUrl)}
          text={translations.settings.downloadInvoice}
          icon={
            <img
              className={"icon"}
              src={PUBLIC_URL + "/assets/icons/icon-download-black.svg"}
              alt=""
            />
          }
        />
        <Dropdown.Item
          onClick={() => handleDownloadFile(invoiceXlsxUrl)}
          text={translations.settings.downloadXLSXFile}
          icon={
            <img
              className={"icon"}
              src={PUBLIC_URL + "/assets/icons/icon-xlsx-file-outline.svg"}
              alt=""
            />
          }
        />
        {resendAble ? (
          <Dropdown.Item
            onClick={() => resendInvoice(token)}
            text={"Resend"}
            icon={
              <i
                className="uil uil-message icon"
                style={{ fontSize: "16px" }}
              />
            }
          />
        ) : null}
        {status === 0 || status === 1 ? (
          <Dropdown.Item
            onClick={async () => changeStatusInvoice(token, status)}
            text={
              status === 0
                ? translations.companyInvoices.moveToPostTitle
                : translations.companyInvoices.moveToPaidTitle
            }
            icon={<i className="uil uil-exchange icon" />}
          />
        ) : null}
        {canPayNow ? (
          <Dropdown.Item
            onClick={_payNow}
            text={"Pay with CC"}
            icon={<i className="uil uil-history icon" />}
          />
        ) : null}
        {status === 1 ? (
          <Dropdown.Item
            onClick={() => onPayWithCredit(BUSINESS_INVOICE, id, _onRefresh)}
            text={"Pay with credit"}
            icon={<i className="uil icon uil-bill" />}
          />
        ) : null}
        {status === 0 || status === 1 ? (
          <Dropdown.Item
            onClick={async () => deleteInvoice(token)}
            text={translations.companyInvoices.deleteInvoiceTitle}
            icon={<i className="uil uil-trash-alt icon" />}
          />
        ) : null}
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default Action;
