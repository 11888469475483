import APIItem from "./APIItem";
import { useSelector } from "react-redux";
import { propertyIntegrationsAPIListSelector } from "../../../../../selectors/PropertySelector";
import MiddleDot from "../../../../../../../components/MiddleDot";
import { Link } from "react-router-dom";
import { API_DOCUMENT_LINK } from "../../../../../configs/Constants";

const APISection = () => {
  const apiData = useSelector(propertyIntegrationsAPIListSelector);

  return (
    <div className={"deskimo-tab-section"}>
      <div className="deskimo-tab-title deskimo-tab-title-link">
        <span>API</span>
        <MiddleDot size={"4px"} />
        <a target={"_blank"} href={API_DOCUMENT_LINK} rel="noreferrer">
          Check the documentation
        </a>
      </div>
      <div className={"api-section-wrapper"}>
        <APIItem
          text={apiData?.app_client}
          label={"Client App"}
          allowHide={false}
        />
        <APIItem text={apiData?.app_secret} label={"Client Secret"} />
      </div>
    </div>
  );
};

export default APISection;
