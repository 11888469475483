import React, { useContext, useState, useEffect } from "react";
import "./styles.scss";
import UploadImageSection from "./UploadImageSection";
import { ConfigMarketCreateEditContext } from "../../../reducers/ConfigMarketCreateEditContext";
import InputCustom from "../../../../../components/Input";
import DropdownCustom from "../../../../../components/DropdownCustom";
import Label from "../../../../../components/Label";
import ToggleSlider from "../../../../../components/ToggleSlider";
import SizeBox from "../../../../../components/SizeBox";
import { LocalizationContext } from "../../../../../locales/Translation";
import { useSelector } from "react-redux";
import { marketToggleListSelector } from "../../../selectors/ConfigSelectors";
import { validateNumber } from "../../../../../services/FormatTextService";

const Information = ({ marketId }: any) => {
  const [imageUploaded, setImageUploaded] = useState<any>(null);
  const { translations } = useContext(LocalizationContext);
  const {
    errors,
    touched,
    isSubmitting,
    handleChange,
    setFieldValue,
    values,
    timezoneOptions,
    currencyOptions,
    phonePrefixOptions,
  } = useContext(ConfigMarketCreateEditContext);

  const marketToggleList = useSelector(marketToggleListSelector);

  const _onChangeTimezone = (
    newTimezone:
      | boolean
      | number
      | string
      | (boolean | number | string)[]
      | undefined
  ) => {
    if (typeof newTimezone !== "string") {
      return;
    }
    setFieldValue("timezone", newTimezone);
  };

  const _onChangePhonePrefix = (
    newPhonePrefix:
      | boolean
      | number
      | string
      | (boolean | number | string)[]
      | undefined
  ) => {
    if (typeof newPhonePrefix !== "string") {
      return;
    }
    setFieldValue("phone_prefix", newPhonePrefix);
  };

  const _onChangeCurrency = (
    newCurrency:
      | boolean
      | number
      | string
      | (boolean | number | string)[]
      | undefined
  ) => {
    if (typeof newCurrency !== "string") {
      return;
    }
    setFieldValue("currency", newCurrency);
  };

  const handleOnChangeToggle = (
    setFieldValue: any,
    key: any,
    isChecked: any
  ) => {
    const newToggleConfigArr = values.toggle_config.map((toggle: any) => {
      if (typeof toggle[key] === "boolean") {
        toggle[key] = isChecked;
      }
      return toggle;
    });
    setFieldValue("toggle_config", newToggleConfigArr);
  };

  return (
    <>
      {marketId ? (
        <Label
          label={translations.editMarket.editMarketTitle}
          fontSize={"24px"}
          className={"section-title"}
        />
      ) : (
        <Label
          label={translations.editMarket.createMarketTitle}
          fontSize={"24px"}
          className={"section-title"}
        />
      )}
      <div className="detail_divide market_create_edit_edition">
        <div className={"left_section"}>
          <InputCustom
            label={translations.editMarket.marketCodeLabelTitle}
            labelRequired
            labelGuide={translations.editMarket.marketCodeLabelTitle}
            value={values.market_code}
            error={errors.market_code}
            touched={touched.market_code}
            containerClass="market-input-style"
            name={"market_code"}
            onChange={handleChange}
            disabled={isSubmitting}
          />

          <InputCustom
            label={translations.editMarket.marketNameLabelTitle}
            labelRequired
            value={values.market_name}
            error={errors.market_name}
            touched={touched.market_name}
            name={"market_name"}
            containerClass="market-input-style"
            onChange={handleChange}
            disabled={isSubmitting}
            labelGuide={translations.editMarket.marketNameLabelGuide}
          />
          <Label
            label={translations.editMarket.marketTimezoneLabelTitle}
            required
            fontSize={"12px"}
            labelRequired
            labelGuide={translations.editMarket.marketTimezoneLabelGuide}
          />
          <DropdownCustom
            selection
            selectUI
            options={timezoneOptions}
            value={values.timezone}
            className={"input-component"}
            onChange={(e, { value }) => _onChangeTimezone(value)}
            disabled={isSubmitting}
          />
          <div className="image-input-section">
            <Label
              label={translations.editMarket.marketCoverImageLabelTitle}
              required
              className={"form-input-label"}
              fontSize={"12px"}
            />
            <UploadImageSection
              touched={touched.cover_image}
              error={errors.cover_image}
              setFieldValue={setFieldValue}
              imgSrc={values.cover_image}
              values
              imageUploaded={imageUploaded}
              setImageUploaded={setImageUploaded}
            />
          </div>
          <div className="split-input-section">
            <InputCustom
              label={translations.editMarket.faqLabelTitle}
              labelRequired
              value={values.faq}
              error={errors.faq}
              touched={touched.faq}
              containerClass="market-input-style"
              name={"faq"}
              onChange={handleChange}
              disabled={isSubmitting}
              labelGuide={translations.editMarket.faqLabelGuide}
            />
            <InputCustom
              label={translations.editMarket.termsLabelTitle}
              labelRequired
              value={values.terms}
              error={errors.terms}
              touched={touched.terms}
              containerClass="market-input-style"
              name={"terms"}
              onChange={handleChange}
              disabled={isSubmitting}
              labelGuide={translations.editMarket.termsLabelGuide}
            />
          </div>
          <div className="split-input-section">
            <InputCustom
              label={translations.editMarket.policyLabelTitle}
              labelRequired
              value={values.policy}
              error={errors.policy}
              touched={touched.policy}
              containerClass="market-input-style"
              name={"policy"}
              onChange={handleChange}
              disabled={isSubmitting}
              labelGuide={translations.editMarket.policyLabelGuide}
            />
            <InputCustom
              label={translations.editMarket.userGuideLabelTitle}
              labelRequired
              value={values.user_guide}
              error={errors.user_guide}
              touched={touched.user_guide}
              containerClass="market-input-style"
              name={"user_guide"}
              onChange={handleChange}
              disabled={isSubmitting}
              labelGuide={translations.editMarket.userGuideLabelGuide}
            />
          </div>
          <div className="split-input-section">
            <InputCustom
              label={translations.editMarket.customerSupportLabelTitle}
              labelRequired
              value={values.customer_support}
              error={errors.customer_support}
              touched={touched.customer_support}
              containerClass="market-input-style"
              name={"customer_support"}
              onChange={handleChange}
              disabled={isSubmitting}
              labelGuide={translations.editMarket.customerSupportLabelGuide}
            />
            <InputCustom
              label={translations.editMarket.businessRegistrationLabelTitle}
              labelRequired
              value={values.business_registration}
              error={errors.business_registration}
              touched={touched.business_registration}
              containerClass="market-input-style"
              name={"business_registration"}
              onChange={handleChange}
              disabled={isSubmitting}
              labelGuide={
                translations.editMarket.businessRegistrationLabelGuide
              }
            />
          </div>
          <div className="split-input-section">
            <InputCustom
              label={translations.editMarket.howItWorksLabelTitle}
              labelRequired
              value={values.how_it_work}
              error={errors.how_it_work}
              touched={touched.how_it_work}
              containerClass="market-input-style"
              name={"how_it_work"}
              onChange={handleChange}
              disabled={isSubmitting}
              labelGuide={translations.editMarket.howItWorksLabelGuide}
            />
            <InputCustom
              label={translations.editMarket.inviteTermsLabelTitle}
              labelRequired
              value={values.invite_term}
              error={errors.invite_term}
              touched={touched.invite_term}
              containerClass="market-input-style"
              name={"invite_term"}
              onChange={handleChange}
              disabled={isSubmitting}
              labelGuide={translations.editMarket.inviteTermsLabelGuide}
            />
          </div>

          <div className="split-input-section">
            <InputCustom
              label={translations.editMarket.senderVoucherValue}
              labelRequired
              value={values.sender_voucher_value}
              error={errors.sender_voucher_value}
              touched={touched.sender_voucher_value}
              containerClass="market-input-style"
              name={"sender_voucher_value"}
              onChange={handleChange}
              disabled={isSubmitting}
              removeLetter
              inputRightIcon={values.currency}
            />
            <div className="input-component no-label-guide-input-section">
              <InputCustom
                label={translations.editMarket.recipientVoucherValue}
                labelRequired
                value={values.recipient_voucher_value}
                error={errors.recipient_voucher_value}
                touched={touched.recipient_voucher_value}
                containerClass="market-input-style mr-0"
                name={"recipient_voucher_value"}
                onChange={handleChange}
                disabled={isSubmitting}
                removeLetter
                inputRightIcon={values.currency}
              />
            </div>
          </div>

          <div className="split-input-section">
            <InputCustom
              label={translations.editMarket.maxCreditLimitLabelTitle}
              labelRequired
              value={values.maximum_credit_limit}
              error={errors.maximum_credit_limit}
              touched={touched.maximum_credit_limit}
              containerClass="market-input-style"
              name={"maximum_credit_limit"}
              onChange={handleChange}
              disabled={isSubmitting}
              removeLetter
            />
            <div className="input-component no-label-guide-input-section">
              <InputCustom
                label={translations.editMarket.defaultDisplayCreditLabelTitle}
                labelRequired
                value={values.default_display_credit}
                error={errors.default_display_credit}
                touched={touched.default_display_credit}
                containerClass="market-input-style mr-0"
                name={"default_display_credit"}
                onChange={handleChange}
                disabled={isSubmitting}
              />
            </div>
          </div>
          <div className="single-input-section">
            <InputCustom
              label={translations.editMarket.rechargeWhenCreditFallsLabelTitle}
              labelRequired
              value={values.trigger_percent}
              error={errors.trigger_percent}
              touched={touched.trigger_percent}
              containerClass="market-input-style"
              name={"trigger_percent"}
              onChange={handleChange}
              disabled={isSubmitting}
              inputRightIcon={values.currency}
              removeLetter
            />
            {/*<InputCustom*/}
            {/*  label={"Hot Desk Booking Delay Time (minute)"}*/}
            {/*  value={values.hot_desk_booking_delay_time}*/}
            {/*  error={errors.hot_desk_booking_delay_time}*/}
            {/*  touched={touched.hot_desk_booking_delay_time}*/}
            {/*  name={"hot_desk_booking_delay_time"}*/}
            {/*  onChange={handleChange}*/}
            {/*  disabled={isSubmitting}*/}
            {/*  labelGuide={*/}
            {/*    "Once the number of vouchers available has been redeemed by users, the voucher code cannot be redeemed by any more users"*/}
            {/*  }*/}
            {/*/>*/}
          </div>
          <div className="single-input-section">
            <InputCustom
              label={translations.editMarket.triggerRemindTopUpCredit}
              labelRequired
              value={values.trigger_remind_top_up_credit}
              error={errors.trigger_remind_top_up_credit}
              touched={touched.trigger_remind_top_up_credit}
              containerClass="market-input-style"
              name={"trigger_remind_top_up_credit"}
              onChange={handleChange}
              disabled={isSubmitting}
              inputRightIcon={values.currency}
              removeLetter
            />
          </div>
          <div className="split-input-section">
            <InputCustom
              label={translations.editMarket.accManagerNameLabelTitle}
              value={values.account_manager_name}
              error={errors.account_manager_name}
              touched={touched.account_manager_name}
              name={"account_manager_name"}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            <InputCustom
              label={translations.editMarket.accManagerPhoneLabelTitle}
              value={values.account_manager_phone}
              error={errors.account_manager_phone}
              touched={touched.account_manager_phone}
              name={"account_manager_phone"}
              onChange={handleChange}
              disabled={isSubmitting}
            />
          </div>
          <div className="split-input-section">
            <InputCustom
              label={translations.editMarket.accManagerEmailLabelTitle}
              value={values.account_manager_email}
              error={errors.account_manager_email}
              touched={touched.account_manager_email}
              name={"account_manager_email"}
              onChange={handleChange}
              disabled={isSubmitting}
            />
            <InputCustom
              label={translations.editMarket.saasCostLabelTitle}
              value={values.saas_cost_per_user}
              error={errors.saas_cost_per_user}
              touched={touched.saas_cost_per_user}
              name={"saas_cost_per_user"}
              onChange={handleChange}
              inputRightIcon={"$"}
              disabled={isSubmitting}
              removeLetter
            />
          </div>
          <div className="split-input-section">
            <ToggleSlider
              className={"flex-1"}
              label={translations.editMarket.showMobileAppToggleTitle}
              value={values.is_show_on_mobile}
              onChange={(e: any, data: any) => {
                setFieldValue("is_show_on_mobile", data.checked);
              }}
            />
            <ToggleSlider
              className={"flex-1"}
              label={"Force show top up"}
              value={values.force_show_top_up}
              onChange={(e: any, data: any) => {
                setFieldValue("force_show_top_up", data.checked ? 1 : 0);
              }}
            />
          </div>
          <SizeBox height={"16px"} />
          <div className="split-input-section">
            <ToggleSlider
              className={"flex-1"}
              label={"Charge the monthly SaaS fee"}
              value={values.has_saas_fee}
              onChange={(e: any, data: any) => {
                setFieldValue("has_saas_fee", data.checked);
              }}
            />
          </div>

          {values?.has_saas_fee ? (
            <>
              <SizeBox height={"16px"} />
              <div className="split-input-section">
                <InputCustom
                  containerClass={"mx-0 mb-0 flex-1"}
                  value={values.total_saas_fee}
                  error={errors.total_saas_fee}
                  touched={touched.total_saas_fee}
                  name={"total_saas_fee"}
                  onChange={handleChange}
                  disabled={isSubmitting}
                  inputLeftIcon={values?.currency}
                  inputLeftIconBorder
                />
                <div className={"flex-1"} />
              </div>
            </>
          ) : null}
          <SizeBox height={"16px"} />

          <div className={"market-toggle-list"}>
            {marketToggleList?.length > 0
              ? marketToggleList.map((toggle: any, index: number) => {
                  if (values?.toggle_config[index]) {
                    return (
                      <ToggleSlider
                        key={index}
                        label={toggle?.label}
                        value={values?.toggle_config[index][toggle?.key]}
                        onChange={(e: any, data: any) => {
                          handleOnChangeToggle(
                            setFieldValue,
                            toggle?.key,
                            data.checked
                          );
                        }}
                      />
                    );
                  }
                })
              : null}
          </div>
        </div>
        <div className="right_section">
          <Label
            label={translations.editMarket.phonePrefixLabelTitle}
            required
            fontSize={"12px"}
            labelGuide={translations.editMarket.phonePrefixLabelGuide}
          />
          <DropdownCustom
            selection
            selectUI
            options={phonePrefixOptions}
            value={values.phone_prefix}
            className={"input-component"}
            onChange={(e, { value }) => {
              _onChangePhonePrefix(value);
            }}
            disabled={isSubmitting}
          />
          <Label
            label={translations.editMarket.currencyCodeLabelTitle}
            required
            fontSize={"12px"}
            labelGuide={"TBA"}
          />
          <DropdownCustom
            selection
            selectUI
            options={currencyOptions}
            value={values.currency}
            className={"input-component"}
            onChange={(e, { value }) => _onChangeCurrency(value)}
            disabled={isSubmitting}
          />
          <InputCustom
            label={translations.editMarket.taxNameLabelTitle}
            labelRequired
            value={values.tax_name}
            error={errors.tax_name}
            touched={touched.tax_name}
            containerClass="market-input-style"
            name={"tax_name"}
            onChange={handleChange}
            disabled={isSubmitting}
            labelGuide={translations.editMarket.taxNameLabelGuide}
          />
          <InputCustom
            label={translations.editMarket.taxValueLabelTitle}
            labelRequired
            value={values.tax_value}
            error={errors.tax_value}
            touched={touched.tax_value}
            containerClass="market-input-style"
            name={"tax_value"}
            onChange={handleChange}
            disabled={isSubmitting}
            labelGuide={translations.editMarket.taxValueLabelGuide}
            removeLetter
          />
          <InputCustom
            labelRequired
            label={translations.editMarket.dkmMinChargeLabelTitle}
            value={values.dkm_minimum_charge}
            error={errors.dkm_minimum_charge}
            touched={touched.dkm_minimum_charge}
            containerClass="market-input-style"
            name={"dkm_minimum_charge"}
            onChange={handleChange}
            disabled={isSubmitting}
            labelGuide={translations.editMarket.dkmMinChargeLabelGuide}
            removeLetter
          />
          <InputCustom
            labelRequired
            label={translations.editMarket.stripeMinChargeLabelTitle}
            value={values.stripe_minimum_charge}
            error={errors.stripe_minimum_charge}
            touched={touched.stripe_minimum_charge}
            containerClass="market-input-style"
            name={"stripe_minimum_charge"}
            onChange={handleChange}
            disabled={isSubmitting}
            labelGuide={translations.editMarket.stripeMinChargeLabelGuide}
            removeLetter
          />
          <InputCustom
            labelRequired
            label={translations.editMarket.stripeSecretKeyLabelTitle}
            value={values.stripe_secret_key}
            error={errors.stripe_secret_key}
            touched={touched.stripe_secret_key}
            containerClass="market-input-style"
            name={"stripe_secret_key"}
            onChange={handleChange}
            disabled={isSubmitting}
            labelGuide={translations.editMarket.stripeSecretKeyLabelGuide}
          />
          <div className="split-input-section">
            <InputCustom
              labelRequired
              label={translations.editMarket.bankNameLabelTitle}
              value={values.bank_name}
              error={errors.bank_name}
              touched={touched.bank_name}
              containerClass="market-input-style"
              name={"bank_name"}
              onChange={handleChange}
              disabled={isSubmitting}
              labelGuide={translations.editMarket.bankNameLabelGuide}
            />
            <InputCustom
              labelRequired
              label={translations.editMarket.bankAccLabelTitle}
              value={values.bank_account}
              error={errors.bank_account}
              touched={touched.bank_account}
              containerClass="market-input-style"
              name={"bank_account"}
              onChange={handleChange}
              disabled={isSubmitting}
              labelGuide={translations.editMarket.bankAccLabelGuide}
            />
          </div>
          <div className="split-input-section">
            <InputCustom
              labelRequired
              label={translations.editMarket.bankAccHolderLabelTitle}
              value={values.bank_account_holder}
              error={errors.bank_account_holder}
              touched={touched.bank_account_holder}
              containerClass="market-input-style"
              name={"bank_account_holder"}
              onChange={handleChange}
              disabled={isSubmitting}
              labelGuide={translations.editMarket.bankAccHolderLabelGuide}
            />
            <InputCustom
              labelRequired
              label={translations.editMarket.entityNameLabelTitle}
              value={values.entity_name}
              error={errors.entity_name}
              touched={touched.entity_name}
              containerClass="market-input-style"
              name={"entity_name"}
              onChange={handleChange}
              disabled={isSubmitting}
              labelGuide={translations.editMarket.entityNameLabelGuide}
            />
          </div>
          <div className="split-input-section">
            <InputCustom
              labelRequired
              label={translations.editMarket.entityEmailLabelTitle}
              value={values.entity_email}
              error={errors.entity_email}
              touched={touched.entity_email}
              containerClass="market-input-style"
              name={"entity_email"}
              onChange={handleChange}
              disabled={isSubmitting}
              labelGuide={translations.editMarket.entityEmailLabelGuide}
            />
            <InputCustom
              labelRequired
              label={translations.editMarket.entityPhoneLabelTitle}
              value={values.entity_phone}
              error={errors.entity_phone}
              touched={touched.entity_phone}
              containerClass="market-input-style"
              name={"entity_phone"}
              onChange={handleChange}
              disabled={isSubmitting}
              labelGuide={translations.editMarket.entityPhoneLabelGuide}
            />
          </div>
          <InputCustom
            labelRequired
            label={translations.editMarket.entityAddrLabelTitle}
            value={values.entity_address}
            error={errors.entity_address}
            touched={touched.entity_address}
            containerClass="market-input-style"
            name={"entity_address"}
            onChange={handleChange}
            disabled={isSubmitting}
            labelGuide={translations.editMarket.entityAddrLabelGuide}
          />
          <InputCustom
            labelRequired
            label={translations.editMarket.corpUserImportTemplateLabelTitle}
            value={values.user_import_template}
            error={errors.user_import_template}
            touched={touched.user_import_template}
            containerClass="market-input-style"
            name={"user_import_template"}
            onChange={handleChange}
            disabled={isSubmitting}
            // labelGuide={
            //   "Once the number of vouchers available has been redeemed by users, the voucher code cannot be redeemed by any more users"
            // }
          />
          <InputCustom
            inputRightIcon={"Minutes"}
            label={translations.editMarket.hotDeskBookDelayTimeLabelTitle}
            value={values.hot_desk_booking_delay_time}
            error={errors.hot_desk_booking_delay_time}
            touched={touched.hot_desk_booking_delay_time}
            containerClass="market-input-style"
            name={"hot_desk_booking_delay_time"}
            onChange={handleChange}
            disabled={isSubmitting}
            removeLetter
          />
          <InputCustom
            inputRightIcon={"Minutes"}
            label={translations.editMarket.roomBookDelayTimeLabelTitle}
            value={values.meeting_room_booking_delay_time}
            error={errors.meeting_room_booking_delay_time}
            touched={touched.meeting_room_booking_delay_time}
            containerClass="market-input-style"
            name={"meeting_room_booking_delay_time"}
            onChange={handleChange}
            disabled={isSubmitting}
            removeLetter
          />

          <InputCustom
            label={"Limit Payment Retry"}
            value={values.payment_repay_time}
            error={errors.payment_repay_time}
            touched={touched.payment_repay_time}
            containerClass="market-input-style"
            name={"payment_repay_time"}
            onChange={(e: any, { value }: any) => {
              const text = validateNumber(value);
              setFieldValue("payment_repay_time", text);
            }}
            disabled={isSubmitting}
          />

          <InputCustom
            label={"Whatsapp link"}
            value={values.whatsapp_link}
            error={errors.whatsapp_link}
            touched={touched.whatsapp_link}
            name={"whatsapp_link"}
            onChange={handleChange}
            disabled={isSubmitting}
          />
        </div>
      </div>
      <div className={"section-white-box_line"} />
    </>
  );
};

export default Information;
