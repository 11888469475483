import React, { useContext } from "react";
import ButtonCustom from "../../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useDispatch } from "react-redux";
import { modalMeetingRoomUpdateAction } from "../../../../actions/PropertyActions";
import { getMeetingRoomDetailThunk } from "../../../../thunks/PropertyThunks";
import { SWAL_DELETE_OPTION } from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import {
  deleteMeetingRoomService,
  handlePublishRoomService,
  handlePublishService,
} from "../../../../services/PropertyServices";
import { toastError, toastSuccess } from "../../../../../../components/Toast";

const Action = ({
  id,
  meetingRoom,
  setRefreshList,
  refreshList,
  ...props
}: any) => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const onClickEditMeetingRoom = async (id: string) => {
    await dispatch<any>(getMeetingRoomDetailThunk(id ? id : ""));
  };

  const _handlePublishMeetingRoom = (id: any, isPublish: number) => {
    const icon = null;
    const title =
      isPublish > 0
        ? translations.propertyPage.publishRoomMsg
        : translations.propertyPage.unPublishRoomMsg;
    const confirmText =
      isPublish > 0
        ? translations.propertyPage.publish
        : translations.propertyPage.unPublish;
    const confirmClass = "primary";
    const options = SWAL_DELETE_OPTION({
      title,
      icon,
      confirmText,
      confirmClass,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          const payload = {
            meeting_room_id: id,
            status: isPublish,
          };
          await handlePublishRoomService(payload);
          toastSuccess(
            isPublish > 0
              ? translations.propertyPage.publishRoomSuccessMsg
              : translations.propertyPage.unPublishRoomSuccessMsg
          );
          setRefreshList(!refreshList);
        } catch (error: any) {
          if (error?.status?.message) {
            toastError(error?.status?.message);
          }
        }
      },
      cancelCallback: () => null,
    });
  };

  const _handleDeleteMeetingRoom = (id: any) => {
    const icon = null;
    const iconHtml = (
      <img
        src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
        className={"swal2-icon__error"}
      />
    );
    const title = translations.propertyPage.deleteRoomMsg;
    const confirmText = translations.delete;
    const confirmClass = "danger";
    const options = SWAL_DELETE_OPTION({
      title,
      iconHtml,
      icon,
      confirmText,
      confirmClass,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          const payload = {
            meeting_room_id: id,
          };
          await deleteMeetingRoomService(payload);
          toastSuccess(translations.propertyPage.deleteRoomSuccessMsg);
          setRefreshList(!refreshList);
        } catch (error: any) {
          if (error?.status?.message) {
            toastError(error?.status?.message);
          }
        }
      },
      cancelCallback: () => null,
    });
  };

  return (
    <DropdownCustom
      {...props}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => {
            onClickEditMeetingRoom(id);
            dispatch(modalMeetingRoomUpdateAction(true));
          }}
          text={"Edit Meeting Room"}
          icon={
            <i className="uil uil-edit icon" style={{ fontSize: "16px" }} />
          }
        />
        {meetingRoom?.status > 0 ? (
          <Dropdown.Item
            onClick={() => _handlePublishMeetingRoom(meetingRoom?.id, 0)}
            text={"Un-publish"}
            icon={
              <img src={PUBLIC_URL + "/assets/icons/icon-toggle-slider.svg"} />
            }
          />
        ) : (
          <Dropdown.Item
            onClick={() => _handlePublishMeetingRoom(meetingRoom?.id, 1)}
            text={"Publish"}
            icon={
              <img src={PUBLIC_URL + "/assets/icons/icon-toggle-slider.svg"} />
            }
          />
        )}

        <Dropdown.Item
          onClick={() => _handleDeleteMeetingRoom(meetingRoom?.id)}
          text={"Delete Room"}
          icon={
            <i
              className="uil uil-trash-alt icon"
              style={{ fontSize: "16px" }}
            />
          }
        />
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default Action;
