import React from "react";
import { BusinessCompanyDetailType } from "../../../configs/DeclareTypes";
import { useSelector } from "react-redux";
import { businessCompanyDetailSelector } from "../../../selectors/BusinessSelectors";

const Contract = () => {
  const businessCompanyDetail: BusinessCompanyDetailType = useSelector(
    businessCompanyDetailSelector
  );

  return (
    <>
      <div className={"company-detail__title"}>
        <div className="section-white-box_title">Contract</div>
      </div>
      <embed
        src={businessCompanyDetail.contract_file}
        width="100%"
        height="476px"
      />
    </>
  );
};

export default Contract;
