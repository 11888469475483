import React from "react";

const ProfileIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6654 6.00065C10.6654 6.7079 10.3844 7.38617 9.88432 7.88627C9.38422 8.38637 8.70594 8.66732 7.9987 8.66732C7.29145 8.66732 6.61318 8.38637 6.11308 7.88627C5.61298 7.38617 5.33203 6.7079 5.33203 6.00065C5.33203 5.29341 5.61298 4.61513 6.11308 4.11503C6.61318 3.61494 7.29145 3.33398 7.9987 3.33398C8.70594 3.33398 9.38422 3.61494 9.88432 4.11503C10.3844 4.61513 10.6654 5.29341 10.6654 6.00065ZM9.33203 6.00065C9.33203 6.35427 9.19156 6.69341 8.94151 6.94346C8.69146 7.19351 8.35232 7.33398 7.9987 7.33398C7.64508 7.33398 7.30594 7.19351 7.05589 6.94346C6.80584 6.69341 6.66536 6.35427 6.66536 6.00065C6.66536 5.64703 6.80584 5.30789 7.05589 5.05784C7.30594 4.80779 7.64508 4.66732 7.9987 4.66732C8.35232 4.66732 8.69146 4.80779 8.94151 5.05784C9.19156 5.30789 9.33203 5.64703 9.33203 6.00065Z"
        fill="#495057"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM1.99935 8.00033C1.99935 9.39366 2.47468 10.6763 3.27135 11.695C3.83084 10.9603 4.55262 10.3648 5.3803 9.9552C6.20799 9.54558 7.11918 9.33286 8.04268 9.33366C8.95423 9.33279 9.85396 9.54001 10.6733 9.93951C11.4926 10.339 12.21 10.9203 12.7707 11.639C13.3483 10.8814 13.7372 9.99714 13.9053 9.05938C14.0733 8.12163 14.0156 7.15735 13.737 6.24631C13.4584 5.33528 12.9668 4.50369 12.303 3.82035C11.6392 3.13701 10.8222 2.62157 9.9196 2.31666C9.01702 2.01176 8.05481 1.92617 7.11259 2.06697C6.17036 2.20777 5.27521 2.57092 4.50119 3.12636C3.72718 3.6818 3.09656 4.41357 2.6615 5.26112C2.22645 6.10867 1.99947 7.04764 1.99935 8.00033ZM7.99935 14.0003C6.62198 14.0024 5.28619 13.5286 4.21802 12.659C4.64797 12.0435 5.22024 11.5409 5.88614 11.1941C6.55205 10.8473 7.29187 10.6665 8.04268 10.667C8.78412 10.6664 9.51501 10.8427 10.1746 11.1813C10.8343 11.5198 11.4036 12.0109 11.8353 12.6137C10.7589 13.5115 9.40107 14.0023 7.99935 14.0003Z"
        fill="#495057"
      />
    </svg>
  );
};

export default ProfileIcon;
