import React, { useEffect, useState } from "react";
import SizeBox from "../../../../../../components/SizeBox";
import "./style.scss";
import { Progress } from "semantic-ui-react";
import { toastError } from "../../../../../../components/Toast";
import { regenerateGBVCheckService } from "../../../../services/ConfigServices";

const SwalRegenerate = ({ reference }: any) => {
  useEffect(() => {
    const interval = setInterval(async () => {
      checkRegenerateReport();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    checkRegenerateReport();
  }, []);

  const checkRegenerateReport = async () => {
    try {
      const response = await regenerateGBVCheckService(reference);
      if (response?.data?.download_link) {
        window.open(response?.data?.download_link, "blank");
      }
    } catch (error: any) {
      toastError(error?.status?.message);
    }
  };

  return (
    <div className={"swal-inviting"}>
      <i style={{ fontSize: 48 }} className="uil uil-file-export" />
      <SizeBox height={"22px"} />
      <div className={"swal-inviting__title"}>
        Regenerating the GBV report
        <SizeBox height={"16px"} />
        <div className={"w-100"}>
          <Progress active percent={100} indicating />
        </div>
        <SizeBox height={"8px"} />
        <div className={"swal-inviting__progress-text"}>
          Your report is currently being generated. This process may take a few
          minutes. Please wait while we complete the export.
        </div>
        <SizeBox height={"8px"} />
      </div>
    </div>
  );
};

export default SwalRegenerate;
