import "./style.scss";

type Props = {
  size?: string;
  color?: string;
  marginRight?: string;
};

const CircleStatus = ({
  size = "8px",
  color = "green",
  marginRight = "8px",
}: Props) => {
  return (
    <div
      className={"circle " + color}
      style={{ height: size, width: size, marginRight }}
    />
  );
};

export default CircleStatus;
