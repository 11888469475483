import ButtonCustom from "../../../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../../../App/configs/Constants";
import { useContext } from "react";
import { LocalizationContext } from "../../../../../../../../locales/Translation";
import Label from "../../../../../../../../components/Label";

type Props = {
  openBillingInformation?: any;
  companyInfo?: any;
};

const BillingInfo = ({ openBillingInformation, companyInfo }: Props) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <div className="billing-info">
      <div className={"billing-section-header"}>
        <Label label={translations.settings.billingInformation} />
        <ButtonCustom
          onClick={openBillingInformation}
          className={"link edit-link p-0"}
        >
          <span>{translations.settings.edit}</span>
          <i className="uil uil-edit" />
        </ButtonCustom>
      </div>
      <div className={"payment__company-logo"}>
        {companyInfo?.logo ? (
          <img src={companyInfo?.logo} />
        ) : (
          <img
            className={"image-default"}
            src={PUBLIC_URL + "/assets/logo.svg"}
          />
        )}
      </div>
      <div className={"payment__company-name"}>{companyInfo?.name}</div>

      {companyInfo?.address ? (
        <div className={"payment__company-meta"}>
          <i className="uil uil-location-pin-alt" />
          <span className="billing__address">{companyInfo?.address}</span>
        </div>
      ) : null}

      {companyInfo?.email ? (
        <div className={"payment__company-meta"}>
          <i className="uil uil-envelope" />
          <span className="billing__email">{companyInfo?.email}</span>
        </div>
      ) : null}

      {companyInfo?.full_phone_number ? (
        <div className={"payment__company-meta"}>
          <i className="uil uil-phone-alt" />
          <span className="billing__phone">
            {companyInfo?.full_phone_number}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default BillingInfo;
