import MasterTemplate from "../../../App/views/MasterTemplate";
import {
  ROLE_BUSINESS_ADMIN_ID,
  ROLE_PARTNER_ID,
} from "../../configs/Constants";
import SearchUser from "../UsersDeskimo/Components/EmployeeTable/SearchUser";
import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import PageTitle from "../../../../components/PageTitle";
import TableCustom from "../../../../components/Table";
import { LocalizationContext } from "../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../App/configs/Constants";
import {
  teamMemberFilterSelector,
  teamMemberListSelector,
} from "../../selectors/PartnerTeamMemberSelectors";
import { teamMemberListThunk } from "../../thunks/PartnerTeamThunks";
import { teamMemberFilterAction } from "../../actions/PartnerTeamActions";
import Action from "./Action";
import FilterStatus from "./FilterStatus";
import { UI_ACCESS_PARTNER_DASHBOARD } from "../../../../utils/PermissionConstant";

const PartnerTeams = () => {
  const dispatch = useDispatch<any>();
  const { translations } = useContext(LocalizationContext);
  const [loading, setLoading] = useState(false);

  const teamMemberList = useSelector(teamMemberListSelector);
  const teamMemberFilter = useSelector(teamMemberFilterSelector);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await dispatch(teamMemberListThunk());
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [teamMemberFilter]);

  const columns = [
    { key: "name", col: translations.employeeName },
    { key: "email", col: translations.email },
    {
      key: "phone_number",
      col: translations.phoneNumber,
    },
    { key: null, col: translations.accountStatus },
    { key: null, col: translations.action },
  ];

  const _formatInputData = () => {
    return teamMemberList?.members?.map(
      ({ id, full_name, status_name, status, email, contact_phone }: any) => {
        return {
          col1: <div>{full_name}</div>,
          col2: <div style={{ wordBreak: "break-word" }}>{email}</div>,
          col3: contact_phone,
          col8: status_name,
          col9: <Action userId={id} statusId={status} />,
        };
      }
    );
  };
  const _onSearch = (keyword: string) => {
    dispatch(teamMemberFilterAction({ keyword }));
  };

  const _onChangeSortCol = (col: any) => {
    if (teamMemberFilter.sort_by !== col.key) {
      dispatch(
        teamMemberFilterAction({ sort_by: col.key, sort_type: ORDER_BY_ASC })
      );
    } else {
      if (teamMemberFilter.sort_type === ORDER_BY_NONE) {
        dispatch(
          teamMemberFilterAction({ sort_by: col.key, sort_type: ORDER_BY_ASC })
        );
      }
      if (teamMemberFilter.sort_type === ORDER_BY_ASC) {
        dispatch(
          teamMemberFilterAction({ sort_by: col.key, sort_type: ORDER_BY_DESC })
        );
      }
      if (teamMemberFilter.sort_type === ORDER_BY_DESC) {
        dispatch(
          teamMemberFilterAction({ sort_by: col.key, sort_type: ORDER_BY_NONE })
        );
      }
    }
  };

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    dispatch(teamMemberFilterAction({ page: activePage }));
  };

  return (
    <MasterTemplate
      roleAccept={[ROLE_BUSINESS_ADMIN_ID, ROLE_PARTNER_ID]}
      permission={UI_ACCESS_PARTNER_DASHBOARD}
    >
      <PageTitle
        content={"Team members with access to your partner account on Deskimo"}
      />
      <div className={"filter-section filter-section-employee"}>
        <div
          className={
            "d-flex flex-wrap w-100 align-items-center justify-content-between"
          }
        >
          <SearchUser
            placeholder={"Search by Name, Email"}
            onSearch={_onSearch}
          />
          <FilterStatus />
        </div>
      </div>
      <div
        className={"employee-table"}
        style={
          teamMemberList?.members?.length < 3 ? { paddingBottom: 100 } : {}
        }
      >
        <TableCustom
          onChangeSortCol={_onChangeSortCol}
          sortTable={true}
          loading={loading}
          showTotal
          tableData={teamMemberList ? _formatInputData() : []}
          columns={columns}
          orderBy={teamMemberFilter.order_by}
          sortBy={teamMemberFilter.sort_by}
          currentPage={teamMemberFilter.page}
          totalItems={teamMemberList?.pagination?.total}
          onChangePagination={_onChangePagination}
        />
      </div>
    </MasterTemplate>
  );
};

export default PartnerTeams;
