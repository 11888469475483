import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { debounce } from "lodash";
import SearchBar4Table from "../../../../../../components/SearchBar4Table";

type Props = {
  onSearch: any;
};

const Search = ({ onSearch }: Props) => {
  const { translations } = useContext(LocalizationContext);

  const debounce_onChange = debounce((e, { value }) => {
    onSearch(value);
  }, 500);

  return (
    <div className={"w-200"}>
      <SearchBar4Table
        placeholder={
          translations.customPackages.searchByNameAndCompanyPlaceholder
        }
        onChange={debounce_onChange}
      />
    </div>
  );
};

export default Search;
