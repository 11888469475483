import clientRequestService from "../../../services/ClientRequestService";
import {
  GET_CONFIG_COMMISSION_SHARE_API,
  POST_CONFIG_MARKET_EXCHANGE_RATE_API,
  GET_CONFIG_MARKET_GENERAL_INFORMATION_API,
  POST_CONFIG_MARKET_LANDMARK_API,
  POST_CONFIG_MARKET_LIST_API,
  POST_CONFIG_MARKET_PUBLIC_HOLIDAYS_API,
  POST_CREATE_CONFIG_MARKET_GENERAL_INFORMATION_API,
  DELETE_MARKET_PUBLIC_HOLIDAYS_API,
  POST_UPDATE_CONFIG_COMMISSION_SHARE_API,
  POST_UPDATE_CONFIG_MARKET_COVER_IMAGE_API,
  POST_UPDATE_CONFIG_MARKET_GENERAL_INFORMATION_API,
  POST_UPDATE_CONFIG_MARKET_STATUS_API,
  DELETE_MARKET_LANDMARK_API,
  POST_CREATE_MARKET_PUBLIC_HOLIDAY_API,
  POST_CREATE_MARKET_LANDMARK_API,
  GET_ONBOARD_PROMOTION_DATA_API,
  POST_UPDATE_ONBOARD_PROMOTION_DATA_API,
  TAGS_LIST_API,
  CREATE_TAG_API,
  REMOVE_TAG_API,
  GET_MARKET_TOGGLE_API,
  POST_CONFIG_PREPAID_CREDIT_PACKAGE_LIST,
  GET_CONFIG_PREPAID_CREDIT_PACKAGE_DETAIL,
  PUT_CONFIG_PREPAID_CREDIT_PACKAGE_UPDATE_STATUS,
  DELETE_CONFIG_PREPAID_CREDIT_PACKAGE,
  POST_CREATE_CONFIG_PREPAID_CREDIT_PACKAGE,
  GET_ONBOARD_PROMOTION_PAID_TRIAL_API,
  UPDATE_ONBOARD_PROMOTION_PAID_TRIAL_API,
  PARTNER_CREDENTIAL_INFO_API,
  PARTNER_WEBHOOK_LIST_API,
  PARTNER_WEBHOOK_UPDATE_STATUS_API,
  PARTNER_WEBHOOK_SEND_TEST_API,
  PARTNER_WEBHOOK_UPDATE_URL_API,
  PARTNER_LIMITATION_API,
  GET_ROLE_PERMISSION_API,
  SAVE_ROLE_PERMISSION_API,
  GET_ACCOUNTING_API,
  REGENERATE_GBV_API,
  CHECK_REGENERATE_GBV_API,
} from "../configs/Endpoints";

export const configMarketListService = (data: object) => {
  return clientRequestService({
    method: "POST",
    url: POST_CONFIG_MARKET_LIST_API,
    data,
  });
};

export const configMarketExchangeRateListService = (data: any) => {
  return clientRequestService({
    method: "POST",
    url: POST_CONFIG_MARKET_EXCHANGE_RATE_API,
    data,
  });
};

export const configMarketCommissionShareService = (data: any) => {
  return clientRequestService({
    method: "GET",
    url: GET_CONFIG_COMMISSION_SHARE_API,
    data,
  });
};

export const configUpdateCommissionShareService = (data: object) => {
  return clientRequestService({
    method: "POST",
    url: POST_UPDATE_CONFIG_COMMISSION_SHARE_API,
    data,
  });
};

export const configUpdateMarketStatusService = (id: string) => {
  return clientRequestService({
    method: "POST",
    url: POST_UPDATE_CONFIG_MARKET_STATUS_API.replaceAll(":id", id),
    data: id,
  });
};

export const configMarketGeneralInformationService = (id: string) => {
  return clientRequestService({
    method: "GET",
    url: GET_CONFIG_MARKET_GENERAL_INFORMATION_API.replaceAll(":id", id),
  });
};

export const configUpdateCoverImageService = async (payload?: any) => {
  return await clientRequestService({
    method: "POST",
    url: POST_UPDATE_CONFIG_MARKET_COVER_IMAGE_API,
    data: payload,
    isFile: true,
  });
};

export const configEditMarketGeneralInformationService = async (
  payload?: any
) => {
  return await clientRequestService({
    method: "POST",
    url: POST_UPDATE_CONFIG_MARKET_GENERAL_INFORMATION_API,
    data: payload,
  });
};

export const configCreateMarketGeneralInformationService = async (
  payload?: any
) => {
  return await clientRequestService({
    method: "POST",
    url: POST_CREATE_CONFIG_MARKET_GENERAL_INFORMATION_API,
    data: payload,
  });
};

export const configMarketPublicHolidaysListService = async (data: object) => {
  return await clientRequestService({
    method: "POST",
    url: POST_CONFIG_MARKET_PUBLIC_HOLIDAYS_API,
    data,
  });
};

export const configCreateMarketPublicHolidayService = async (data: object) => {
  return await clientRequestService({
    method: "POST",
    url: POST_CREATE_MARKET_PUBLIC_HOLIDAY_API,
    data,
  });
};

export const configDeleteMarketPublicHolidaysService = async (id?: any) => {
  return await clientRequestService({
    method: "DELETE",
    url: DELETE_MARKET_PUBLIC_HOLIDAYS_API.replaceAll(":id", id),
  });
};

export const configMarketLandmarkListService = async (data: object) => {
  return await clientRequestService({
    method: "POST",
    url: POST_CONFIG_MARKET_LANDMARK_API,
    data,
  });
};

export const configCreateMarketLandmarkService = async (data: object) => {
  return await clientRequestService({
    method: "POST",
    url: POST_CREATE_MARKET_LANDMARK_API,
    data,
  });
};

export const configDeleteMarketLandmarkService = async (id?: any) => {
  return await clientRequestService({
    method: "DELETE",
    url: DELETE_MARKET_LANDMARK_API.replaceAll(":id", id),
  });
};

export const getOnboardPromotionDataService = async (marketId: any) => {
  return await clientRequestService({
    method: "GET",
    url: GET_ONBOARD_PROMOTION_DATA_API.replaceAll("%marketId%", marketId),
  });
};

export const getOnboardPromotionPaidTrialService = async (marketId: any) => {
  return await clientRequestService({
    method: "GET",
    url: GET_ONBOARD_PROMOTION_PAID_TRIAL_API.replaceAll(
      "%marketId%",
      marketId
    ),
  });
};

export const updateOnboardPromotionDataService = async (
  marketId: any,
  data: object
) => {
  return await clientRequestService({
    method: "POST",
    url: POST_UPDATE_ONBOARD_PROMOTION_DATA_API.replaceAll(
      "%marketId%",
      marketId
    ),
    data,
  });
};

export const updateOnboardPromotionPaidTrialService = async (
  marketId: any,
  data: object
) => {
  return await clientRequestService({
    method: "POST",
    url: UPDATE_ONBOARD_PROMOTION_PAID_TRIAL_API.replaceAll(
      "%marketId%",
      marketId
    ),
    data,
  });
};

export const tagsListService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: TAGS_LIST_API,
    data,
  });
};

export const createTagService = async (data: object) => {
  return await clientRequestService({
    method: "POST",
    url: CREATE_TAG_API,
    data,
  });
};

export const removeTagService = async (id: string) => {
  return await clientRequestService({
    method: "POST",
    url: REMOVE_TAG_API.replaceAll("%id%", id),
  });
};

export const getMarketToggleService = async () => {
  return clientRequestService({
    method: "GET",
    url: GET_MARKET_TOGGLE_API,
  });
};

export const getConfigPrepaidCreditPackageListService = async (
  data: object
) => {
  return await clientRequestService({
    method: "POST",
    url: POST_CONFIG_PREPAID_CREDIT_PACKAGE_LIST,
    data,
  });
};

export const getConfigPrepaidCreditPackageDetailService = async (
  id: string
) => {
  return await clientRequestService({
    method: "GET",
    url: GET_CONFIG_PREPAID_CREDIT_PACKAGE_DETAIL.replaceAll("%id%", id),
  });
};

export const createConfigPrepaidCreditPackageService = async (data: object) => {
  return await clientRequestService({
    method: "POST",
    url: POST_CREATE_CONFIG_PREPAID_CREDIT_PACKAGE,
    data,
  });
};

export const updateConfigPrepaidCreditPackageService = async (data: object) => {
  return await clientRequestService({
    method: "PUT",
    url: POST_CREATE_CONFIG_PREPAID_CREDIT_PACKAGE,
    data,
  });
};

export const updateStatusConfigPrepaidCreditPackageService = async (
  id: string,
  action: string
) => {
  return await clientRequestService({
    method: "PUT",
    url: PUT_CONFIG_PREPAID_CREDIT_PACKAGE_UPDATE_STATUS.replaceAll(
      "%id%",
      id
    ).replaceAll("%action%", action),
  });
};

export const deleteConfigPrepaidCreditPackageService = async (id: string) => {
  return await clientRequestService({
    method: "DELETE",
    url: DELETE_CONFIG_PREPAID_CREDIT_PACKAGE.replaceAll("%id%", id),
  });
};

//Partner
export const getPartnerCredentialInfoService = async (companyId: string) => {
  return await clientRequestService({
    method: "GET",
    url: PARTNER_CREDENTIAL_INFO_API.replaceAll("%companyId%", companyId),
  });
};

export const getPartnerWebhookListService = async (companyId: string) => {
  return await clientRequestService({
    method: "GET",
    url: PARTNER_WEBHOOK_LIST_API.replaceAll("%companyId%", companyId),
  });
};

export const updatePartnerWebhookStatusService = async (
  companyId: string,
  webhookId: string,
  data: object
) => {
  return await clientRequestService({
    method: "PUT",
    url: PARTNER_WEBHOOK_UPDATE_STATUS_API.replaceAll(
      "%companyId%",
      companyId
    ).replaceAll("%webhookId%", webhookId),
    data,
  });
};

export const updatePartnerWebhookUrlService = async (
  companyId: string,
  webhookId: string,
  data: object
) => {
  return await clientRequestService({
    method: "PUT",
    url: PARTNER_WEBHOOK_UPDATE_URL_API.replaceAll(
      "%companyId%",
      companyId
    ).replaceAll("%webhookId%", webhookId),
    data,
  });
};

export const sendTestPartnerWebhookService = async (
  companyId: string,
  webhookId: string
) => {
  return await clientRequestService({
    method: "POST",
    url: PARTNER_WEBHOOK_SEND_TEST_API.replaceAll(
      "%companyId%",
      companyId
    ).replaceAll("%webhookId%", webhookId),
  });
};

export const partnerLimitationService = async (companyId: string) => {
  return await clientRequestService({
    method: "GET",
    url: PARTNER_LIMITATION_API.replaceAll("%companyId%", companyId),
  });
};

export const getRolePermissionService = async () => {
  return await clientRequestService({
    method: "GET",
    url: GET_ROLE_PERMISSION_API,
  });
};

export const saveRolePermissionService = async (data: object) => {
  return await clientRequestService({
    method: "POST",
    data,
    url: SAVE_ROLE_PERMISSION_API,
  });
};

export const getAccountingListService = async (data: object) => {
  return await clientRequestService({
    method: "POST",
    data,
    url: GET_ACCOUNTING_API,
  });
};

export const regenerateGBVService = async (reference: string) => {
  return await clientRequestService({
    method: "PUT",
    url: REGENERATE_GBV_API.replaceAll("%reference%", reference),
  });
};

export const regenerateGBVCheckService = async (reference: string) => {
  return await clientRequestService({
    method: "GET",
    url: CHECK_REGENERATE_GBV_API.replaceAll("%reference%", reference),
  });
};

export const exportExcelService = async (reference: string) => {
  return await clientRequestService({
    method: "GET",
    url: REGENERATE_GBV_API.replaceAll("%reference%", reference),
  });
};
