import React, { useContext, useEffect } from "react";
import { LocalizationContext } from "../../../../locales/Translation";
import { Link, Route, useLocation, useParams } from "react-router-dom";
import { ROLE_DESKIMO_ADMIN_ID } from "../../../User/configs/Constants";
import {
  DESKIMO_METTING_ROOM,
  DESKIMO_PRIVATE_OFFICES_LINK,
  DESKIMO_PROPERTY_AMENITIES_LINK,
  DESKIMO_PROPERTY_COMPANY_LINK,
  DESKIMO_PROPERTY_INVOICES_LINK,
  DESKIMO_PROPERTY_LINK,
  DESKIMO_PROPERTY_SCANNER_CREATE_LINK,
  DESKIMO_PROPERTY_SCANNER_EDIT_LINK,
  DESKIMO_PROPERTY_SCANNERS_LINK,
  DESKIMO_PROPERTY_STAFF_LINK,
  DESKIMO_USER_REVIEW,
} from "../../../App/configs/ListRoutes";
import DeskimoProperties from "../DeskimoProperties";
import DeskimoPropertiesCompany from "../DeskimoPropertiesCompany";
import DeskimoPropertiesStaff from "../DeskimoPropertiesStaff";

import MasterTemplate from "../../../App/views/MasterTemplate";
import { Tab } from "semantic-ui-react";
import DeskimoPropertiesInvoices from "../DeskimoPropertiesInvoices";
import DeskimoPropertiesAmenities from "../DeskimoPropertiesAmenities";
import { DeskimoPropertiesScanners } from "../DeskimoPropertiesScanners";
import PropertiesScannerCreateEdit from "../DeskimoPropertiesScanners/Components/PropertiesScannerCreateEdit";
import ButtonGoBack from "../../../../components/ButtonGoBack";
import DeskimoPropertiesUserReviews from "../DeskimoPropertiesUserReviews";
import DeskimoPropertiesPrivateOfficeList from "./../DeskimoPropertiesPrivateOffice";

type Props = {
  tabIndex?: number;
};

const DeskimoPropertyInfo = ({ tabIndex }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const { token } = useParams();
  const location = useLocation();

  const panes = [
    location.pathname.includes(DESKIMO_PROPERTY_SCANNER_CREATE_LINK) ||
    location.pathname.includes(
      DESKIMO_PROPERTY_SCANNER_EDIT_LINK.replaceAll(":token", token as string)
    )
      ? {
          menuItem: (
            <ButtonGoBack
              goBack
              route={DESKIMO_PROPERTY_SCANNERS_LINK}
              className="mb-0"
            />
          ),
        }
      : { menuItem: null },
    {
      menuItem: {
        as: Link,
        id: "tab1",
        to: DESKIMO_PROPERTY_LINK,
        content: translations.workspaces,
        exact: true,
      },
      pane: <Route path={DESKIMO_PROPERTY_LINK} />,
      render: () => <DeskimoProperties />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab2",
        to: DESKIMO_PROPERTY_COMPANY_LINK,
        content: translations.propertyCompanies,
        exact: true,
      },
      pane: <Route path={DESKIMO_PROPERTY_COMPANY_LINK} />,
      render: () => <DeskimoPropertiesCompany />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab3",
        to: DESKIMO_PROPERTY_STAFF_LINK,
        content: translations.workspaceStaff,
        exact: true,
      },
      pane: <Route path={DESKIMO_PROPERTY_STAFF_LINK} />,
      render: () => <DeskimoPropertiesStaff />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab3b",
        to: DESKIMO_PRIVATE_OFFICES_LINK,
        content: translations.PrivateOffices,
        exact: true,
      },
      pane: <Route path={DESKIMO_PRIVATE_OFFICES_LINK} />,
      render: () => <DeskimoPropertiesPrivateOfficeList />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab4",
        to: DESKIMO_PROPERTY_INVOICES_LINK,
        content: translations.workspaceInvoices,
        exact: true,
      },
      pane: <Route path={DESKIMO_PROPERTY_INVOICES_LINK} />,
      render: () => <DeskimoPropertiesInvoices />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab5",
        to: DESKIMO_PROPERTY_AMENITIES_LINK,
        content: translations.propertyAmenities.amenities,
        exact: true,
      },
      pane: <Route path={DESKIMO_PROPERTY_AMENITIES_LINK} />,
      render: () => <DeskimoPropertiesAmenities />,
    },
    location.pathname.includes(DESKIMO_PROPERTY_SCANNER_CREATE_LINK)
      ? {
          menuItem: {
            as: Link,
            id: "tab6",
            to: DESKIMO_PROPERTY_SCANNER_CREATE_LINK,
            content: translations.propertyScanners.scanners,
            exact: true,
          },
          pane: <Route path={DESKIMO_PROPERTY_SCANNER_CREATE_LINK} />,
          render: () => <PropertiesScannerCreateEdit />,
        }
      : location.pathname.includes(
          DESKIMO_PROPERTY_SCANNER_EDIT_LINK.replaceAll(
            ":token",
            token as string
          )
        )
      ? {
          menuItem: {
            as: Link,
            id: "tab6",
            to: DESKIMO_PROPERTY_SCANNER_EDIT_LINK.replaceAll(
              ":token",
              token as string
            ),
            content: translations.propertyScanners.scanners,
            exact: true,
          },
          pane: <Route path={DESKIMO_PROPERTY_SCANNER_EDIT_LINK} />,
          render: () => <PropertiesScannerCreateEdit />,
        }
      : {
          menuItem: {
            as: Link,
            id: "tab6",
            to: DESKIMO_PROPERTY_SCANNERS_LINK,
            content: translations.propertyScanners.scanners,
            exact: true,
          },
          pane: <Route path={DESKIMO_PROPERTY_SCANNERS_LINK} />,
          render: () => <DeskimoPropertiesScanners />,
        },
    {
      menuItem: {
        as: Link,
        id: "tab7",
        to: DESKIMO_USER_REVIEW,
        content: translations.propertyPage.userReviews,
        exact: true,
      },
      render: () => <DeskimoPropertiesUserReviews />,
    },
  ];

  useEffect(() => {
    const activeNavItemRef = document.querySelector(
      ".tab-custom .menu .item.active"
    );
    if (activeNavItemRef) {
      activeNavItemRef?.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
    }
  }, []);

  return (
    <MasterTemplate roleAccept={[ROLE_DESKIMO_ADMIN_ID]}>
      <Tab
        menu={{ text: true }}
        activeIndex={tabIndex}
        panes={panes}
        className={"tab-custom"}
      />
    </MasterTemplate>
  );
};

export default DeskimoPropertyInfo;
