import MasterTemplate from "../../../App/views/MasterTemplate";
import { Tab } from "semantic-ui-react";
import SettingTab from "./Components/SettingTab";
import PrepaidCreditTab from "./Components/PrepaidCreditTab";
import BillingTab from "./Components/BillingTab";
import IntegrationTab from "./Components/IntegrationTab";
import "./style.scss";
import { Link, Route } from "react-router-dom";
import {
  AFFILIATE_LINK,
  BILLING_LINK,
  INTEGRATIONS_LINK,
  PRE_PAID_LINK,
  SETTING_LINK,
} from "../../../App/configs/ListRoutes";
import React, { useContext } from "react";
import { LocalizationContext } from "../../../../locales/Translation";
import { ROLE_BUSINESS_ADMIN_ID } from "../../../User/configs/Constants";
import AffiliateTab from "./Components/AffiliateTab";
import { useSelector } from "react-redux";
import { userCompanyChooseSelector } from "../../../User/selectors/UserSelectors";

type Props = {
  tabIndex?: number;
};

const Setting = ({ tabIndex }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const currentCompany = useSelector(userCompanyChooseSelector);

  const panes = [
    {
      menuItem: {
        as: Link,
        id: "tab1",
        to: SETTING_LINK,
        content: translations.allowances,
        exact: true,
      },
      pane: <Route path={SETTING_LINK} />,
      render: () => <SettingTab />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab2",
        to: PRE_PAID_LINK,
        content: translations.settings.prepaidCredit,
        exact: true,
      },
      pane: <Route path={PRE_PAID_LINK} />,
      render: () => <PrepaidCreditTab />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab3",
        to: BILLING_LINK,
        content: translations.settings.billing,
        exact: true,
      },
      pane: <Route path={PRE_PAID_LINK} />,
      render: () => <BillingTab />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab4",
        to: INTEGRATIONS_LINK,
        content: translations.settings.settings,
        exact: true,
      },
      pane: <Route path={PRE_PAID_LINK} />,
      render: () => <IntegrationTab />,
    },
  ];

  if (currentCompany?.is_affiliate) {
    panes.push({
      menuItem: {
        as: Link,
        id: "tab5",
        to: AFFILIATE_LINK,
        content: translations.settings.affiliate,
        exact: true,
      },
      pane: <Route path={AFFILIATE_LINK} />,
      render: () => <AffiliateTab />,
    });
  }

  return (
    <MasterTemplate roleAccept={[ROLE_BUSINESS_ADMIN_ID]}>
      <Tab
        menu={{ text: true }}
        activeIndex={tabIndex}
        panes={panes}
        className={"tab-custom"}
      />
    </MasterTemplate>
  );
};

export default Setting;
