import ToggleSlider from "../../../../../components/ToggleSlider";
import React from "react";

const WebhookAction = ({ status, onChangeStatus, item }: any) => {
  return (
    <ToggleSlider
      disabled={!item?.webhook_url || item?.webhook_url?.length < 1}
      value={status > 0}
      onChange={(e: any, data: any) => {
        onChangeStatus(data.checked, item?.id);
      }}
    />
  );
};

export default WebhookAction;
