import React, { useState, useContext } from "react";
import ButtonCustom from "../../../../../components/Button";
import {
  bookingPropertyActionService,
  getDeclineReasonsService,
} from "../../../services/DashboardServices";
import { useDispatch } from "react-redux";
import { toastSuccess } from "../../../../../components/Toast";
import { SWAL_DELETE_OPTION } from "../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../components/SweetAlert";
import { LocalizationContext } from "../../../../../locales/Translation";
import {
  dashboardPropertyPendingBookingRemoveAction,
  getDeclineReasonsAction,
  modalDeclineReasonsAction,
} from "../../../actions/DashboardActions";
import {
  DESK_BOOKING,
  ROOM_BOOKING,
  TYPE_DESK,
  TYPE_ROOM,
} from "../../../configs/Constants";

const BookingAction = ({
  bookingId,
  type,
}: {
  bookingId: number;
  type: typeof ROOM_BOOKING | typeof DESK_BOOKING;
}) => {
  const dispatch = useDispatch();
  const [approveLoading, setApproveLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);
  const { translations } = useContext(LocalizationContext);

  const _onClickApprove = async () => {
    try {
      setApproveLoading(true);
      const data = { booking_id: bookingId };
      const url =
        type === ROOM_BOOKING
          ? "/api/v1/property-com/meeting-room/booking/status/Approve"
          : "/api/v1/property-com/desk-booking/update-status/confirm";
      await bookingPropertyActionService({ url, data });
      dispatch<any>(dashboardPropertyPendingBookingRemoveAction(bookingId));
      toastSuccess("Approved successfully!");
    } catch (e) {
      console.log(e);
    } finally {
      setApproveLoading(false);
    }
  };

  const getDeclineReason = async (type: string) => {
    try {
      const response = await getDeclineReasonsService(
        type === ROOM_BOOKING ? TYPE_ROOM : TYPE_DESK
      );
      if (response?.data) {
        dispatch(getDeclineReasonsAction(response?.data));
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const _onClickDecline = async () => {
    const icon = null;
    const title = translations.declineBookingMsg;
    const confirmText = translations.decline;
    const confirmClass = "danger";
    const options = SWAL_DELETE_OPTION({
      title,
      icon,
      confirmText,
      confirmClass,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          const url =
            type === ROOM_BOOKING
              ? "/api/v1/property-com/meeting-room/booking/status/Decline"
              : "/api/v1/property-com/desk-booking/update-status/decline";
          await getDeclineReason(type);
          dispatch(
            modalDeclineReasonsAction({
              open: true,
              data: {
                booking_id: bookingId,
                url,
              },
            })
          );
        } catch (e) {
          console.log(e);
        } finally {
          setDeclineLoading(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  return (
    <div className={"dashboard_approve_btn_wrapper"}>
      <ButtonCustom
        className={"dashboard_approve_btn"}
        fontSize={"12px"}
        onClick={_onClickApprove}
        loading={approveLoading}
        disabled={approveLoading}
      >
        {translations.approve}
      </ButtonCustom>
      <ButtonCustom
        className={"dashboard_decline_btn"}
        fontSize={"12px"}
        onClick={_onClickDecline}
        loading={declineLoading}
        disabled={declineLoading}
      >
        {translations.decline}
      </ButtonCustom>
    </div>
  );
};

export default BookingAction;
