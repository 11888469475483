import React, { useContext, useEffect } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import SearchBar4Table from "../../../../../../components/SearchBar4Table";
import { employeesFilterAction } from "../../../../actions/EmployeeActions";

const SearchEmployee = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(employeesFilterAction({ key: "keyword", value: "" }));
    };
  }, []);

  const debounce_onChange = debounce(function (value) {
    dispatch(employeesFilterAction({ key: "keyword", value }));
  }, 500);

  return (
    <SearchBar4Table
      placeholder={translations.searchByName}
      onChange={(event, { value }) => debounce_onChange(value)}
    />
  );
};

export default SearchEmployee;
