import React from "react";
import CircleStatus from "../../../../../../components/CircleStatus";
import TableCustom from "../../../../../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  deskBookingListSelector,
  deskBookingPayloadSelector,
} from "../../../../selectors/BookingSelectors";
import { listDeskBookingPayloadAction } from "../../../../actions/BookingActions";
import {
  DESK_STATUS_CANCELLED,
  DESK_STATUS_UPCOMING,
} from "../../../../configs/Constants";
import moment from "moment-timezone";
import {
  FORMAT_DATE_VARIANT_3,
  FORMAT_TIME_VARIANT_1,
} from "../../../../../App/configs/DateTimeConfigs";
import Action from "./Action";
import SizeBox from "../../../../../../components/SizeBox";

const BookingTable = ({ columns, loading, setLoading }: any) => {
  const listDeskBooking = useSelector(deskBookingListSelector);
  const deskBookingPayload = useSelector(deskBookingPayloadSelector);
  const dispatch = useDispatch();

  const renderStatusCircle = (statusId: any) => {
    switch (statusId) {
      case 0:
        return "yellow";
      case 5:
        return "yellow";
      case 1:
        return "green";
      default:
        return "blue";
    }
  };

  const decoratedData = () => {
    if (listDeskBooking?.hotdesk_bookings?.length > 0) {
      return listDeskBooking?.hotdesk_bookings.map((desk: any) => {
        return {
          code: desk?.code,
          col1: (
            <div className={"table-booking__room-name table-booking__cell"}>
              {desk?.thumbnail ? <img src={desk?.thumbnail} alt="" /> : null}
              <span>{desk?.property_name}</span>
            </div>
          ),
          col2: (
            <div
              className={"table-booking__property-details table-booking__cell"}
            >
              <div className={"table-booking__property-details-item"}>
                <i className="uil uil-building" />
                <span>{desk?.company_name}</span>
              </div>
              <div className={"table-booking__property-details-item"}>
                <i className="uil uil-map" />
                <span>{desk?.property_address}</span>
              </div>
            </div>
          ),
          col3: <div className={"table-booking__cell"}>{desk?.duration}</div>,
          col4: (
            <div className={"table-booking__cell"}>
              {desk?.booking_date} <br />
              {desk?.start_time} - {desk?.end_time}
            </div>
          ),
          col5: (
            <div className={"table-booking__cell"}>
              {desk?.currency} {desk?.total_paid}
            </div>
          ),
          col6: (
            <div className={"table-booking__cell"}>
              {deskBookingPayload?.status === DESK_STATUS_UPCOMING ? (
                <div className={"table-booking__status"}>
                  <CircleStatus color={renderStatusCircle(desk?.status_id)} />
                  <span>{desk?.status_name}</span>
                </div>
              ) : deskBookingPayload?.status === DESK_STATUS_CANCELLED ? (
                <>
                  {moment
                    .tz(desk?.updated_at * 1000, desk?.timezone)
                    .format(FORMAT_DATE_VARIANT_3)}
                  <br />
                  {moment
                    .tz(desk?.updated_at * 1000, desk?.timezone)
                    .format(FORMAT_TIME_VARIANT_1)}
                </>
              ) : null}

              {desk?.parking_request ? (
                <>
                  <SizeBox height={16} />
                  <div>
                    <b>Parking:</b> {desk?.parking_request?.status?.text}
                  </div>
                </>
              ) : null}
            </div>
          ),
          col7: (
            <Action
              bookingId={desk?.id}
              loading={loading}
              setLoading={setLoading}
              cancellationPolicy={desk?.cancellation_policy}
              isRequestCancel={desk?.is_request_cancel}
              propertyId={desk?.property_id}
              status={deskBookingPayload?.status}
            />
          ),
        };
      });
    }
    return [];
  };

  return (
    <TableCustom
      showTotal={false}
      columns={columns}
      currentPage={deskBookingPayload?.page}
      loading={loading}
      tableClass={"table-booking"}
      tableData={decoratedData()}
      itemPerPage={listDeskBooking?.pagination?.limit}
      totalItems={listDeskBooking?.pagination?.total}
      onChangePagination={(e: any, { activePage }: any) => {
        dispatch(
          listDeskBookingPayloadAction({
            page: activePage,
          })
        );
      }}
    />
  );
};

export default BookingTable;
