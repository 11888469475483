export const API_GET_ADDRESS_BY_LAT_LNG = ({ lat, lng }: any) => {
  return `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GG_KEY}`;
};

export const POST_PROPERTY_LIST_API = "/api/v1/property-com/properties";
export const GET_PROPERTY_LIST_FILTERS_API =
  "/api/v1/property-com/%companyId%/properties/count-by-status";
export const POST_MEETING_ROOM_LIST_API = "/api/v1/property-com/meeting-room";
export const GET_PROPERTY_DETAIL_API =
  "/api/v1/property-com/%companyId%/property/%id%";
export const GET_MEETING_ROOM_DETAIL_API =
  "/api/v1/property-com/%companyId%/meeting-room/%id%";
export const PUT_UPDATE_PROPERTY_API = "/api/v1/property-com/property";

export const POST_DESKIMO_PROPERTY_LIST_API = "/api/v1/des-admin/workspaces";
export const POST_DESKIMO_PROPERTY_COMPANY_LIST_API =
  "/api/v1/des-admin/workspace-companies";
export const POST_DESKIMO_PROPERTY_STAFF_LIST_API =
  "/api/v1/des-admin/property-users";
export const POST_DESKIMO_PROPERTY_AMENITY_LIST_API =
  "/api/v1/des-admin/amenities";
export const POST_DESKIMO_PRIVATE_OFFICE_LIST_API =
  "/api/v1/property-com/private-offices";

export const PUT_UPDATE_DESKIMO_PROPERTY_STAFF_API = "/api/v1/des-admin/users";
export const POST_DESKIMO_PROPERTY_STAFF_DETAILS_API =
  "/api/v1/des-admin/users/{users}";

export const GET_DESKIMO_PROPERTY_AMENITY_DETAIL_API =
  "/api/v1/des-admin/amenity/:amenityId";
export const DELETE_DESKIMO_PROPERTY_AMENITY_LIST_API =
  "/api/v1/des-admin/amenity/:amenityId";

export const PUT_UPDATE_MEETING_ROOM_API = "/api/v1/property-com/meeting-room";
export const POST_USER_REVIEW_API = "/api/v1/property-com/review";
export const API_CHANGE_REVIEW_STATUS = "/api/v1/property-com/review-status";
export const GET_STAFF_API =
  "/api/v1/property-com/%companyId%/property-staff/%propertyId%";
export const PUT_PUBLISH_PROPERTY_API = "/api/v1/property-com/property/publish";
export const PUT_PUBLISH_ROOM_API = "/api/v1/property-com/meeting-room/publish";
export const DELETE_ROOM_API = "/api/v1/property-com/meeting-room";

export const POST_MANAGE_PMS_ROOM_API = "/api/v1/meeting-rooms/external";
export const POST_MANAGE_PMS_ADD_CALENDAR_API =
  "/api/v1/meeting-rooms/external/create";
export const POST_MANAGE_PMS_ASSIGN_CALENDAR_API =
  "/api/v1/meeting-rooms/external/assign";
export const DELETE_MANAGE_PMS_ROOM_API = "/api/v1/meeting-rooms/external";
export const DELETE_DISCONNECT_PMS_ROOM_API = "/api/v1/meeting-rooms/external";
export const PUT_UPDATE_MANAGE_PMS_AUTO_CONFIRM_API =
  "/api/v1/meeting-rooms/external";

export const PROPERTY_COMPANY_API = "/api/v1/des-admin/workspace-company";
export const GET_PROPERTY_COMPANY_API =
  "/api/v1/des-admin/workspace-company/%companyId%";
export const DELETE_PROPERTY_COMPANY_API =
  "/api/v1/des-admin/workspace-company/%companyId%/delete";

export const DESKIMO_COMPANY_PUBLISH_API =
  "/api/v1/des-admin/handle-publish-company";

export const POST_GET_LIST_UNUSED_PMS_ROOM_API =
  "/api/v1/meeting-rooms/external/unused";

export const POST_FETCH_LIST_PROPERTY_INVOICE_API =
  "/api/v1/property-com/company-invoices";

export const POST_GENERATE_PROPERTY_INVOICE_API =
  "/api/v1/property-com/company-invoices/generate-manually";

export const GET_PROPERTY_INVOICE_DETAIL_API =
  "/api/v1/property-com/%companyId%/company-invoices/invoice/%token%";

export const GET_LIST_MARKET_COMPANY_API =
  "/api/v1/des-admin/market/property-company";

export const POST_DELETE_PROPERTY_INVOICE_API =
  "/api/v1/property-com/company-invoice/delete/%token%";

export const RESEND_PROPERTY_INVOICE_API =
  "api/v1/property-com/company-invoice/%token%/resend";

export const POST_ADD_CUSTOM_FEE_API =
  "/api/v1/property-com/company-invoices/invoice/%token%/item";

export const PROPERTY_INVOICE_POST_PAID_API =
  "/api/v1/property-com/company-invoice/update-invoice-status/%token%";

export const GET_PROPERTY_STAFF_DEACTIVATE_API =
  "/api/v1/property-com/%companyId%/employee/%userId%/deactivate";
export const GET_PROPERTY_STAFF_ACTIVATE_API =
  "/api/v1/property-com/%companyId%/employee/%userId%/activate";

export const DELETE_PROPERTY_STAFF_DELETE_API = "/api/v1/property-com/employee";

export const POST_UPLOAD_ICON_IMAGE_API = "/api/v1/des-admin/amenity/icon";

export const POST_ADD_PROPERTY_AMENITY_API = "/api/v1/des-admin/amenity";

export const POST_PROPERTY_SCANNER_LIST_API = "/api/v1/scanners";

export const GET_PROPERTY_SCANNER_DETAILS_API =
  "/api/v1/scanner/get-detail/%token%";

export const POST_ADD_PROPERTY_SCANNER_API = "/api/v1/scanner/add";
export const POST_EDIT_PROPERTY_SCANNER_API = "/api/v1/scanner/edit/%token%";

export const GET_CHANGE_STATUS_PROPERTY_SCANNER_API =
  "/api/v1/scanners/change-status/%token%";

export const GET_RETRIEVE_PROPERTY_LIST_API =
  "/api/v1/des-admin/all-properties-list";
export const GET_PROPERTY_NOTES_API = "/api/v1/get-property-note";
export const PROPERTY_NOTE_API = "/api/v1/property-note";
export const DELETE_PROPERTY_NOTE_API = "/api/v1/property-note/%id%";
export const PROPERTY_NOTE_VERSIONING_API =
  "/api/v1/get-property-note-versioning/%id%";
export const GET_PROPERTY_CATEGORIES_API = "/api/v1/categories";
export const CREATE_PROPERTY_CATEGORY_API = "/api/v1/create-category";
export const DELETE_PROPERTY_CATEGORY_API = "/api/v1/delete-category/%id%";
export const GET_PROPERTY_HOLIDAYS_API =
  "/api/v3/property-holidays/%companyId%";

//Integration
export const PROPERTY_INTEGRATION_CHECKING =
  "/api/v3/workspace/%propertyId%/integration-checking";
export const GET_PROPERTY_INTEGRATION_QRCODE =
  "/api/v3/workspace/%propertyId%/qrcodes";
export const CREATE_PROPERTY_INTEGRATION_QRCODE =
  "/api/v3/workspace/%propertyId%/qrcodes";
export const UPDATE_PROPERTY_INTEGRATION_QRCODE =
  "/api/v3/workspace/%propertyId%/qrcodes/%qrcodeId%";
export const CHANGE_STATUS_PROPERTY_INTEGRATION_QRCODE =
  "/api/v3/workspace/%propertyId%/qrcodes/%qrcodeId%/status";
export const DELETE_PROPERTY_INTEGRATION_QRCODE =
  "/api/v3/workspace/%propertyId%/qrcodes/%qrcodeId%";
export const GET_PROPERTY_INTEGRATION_API =
  "/api/v3/workspace/%propertyId%/integration-credentials";
export const GET_PROPERTY_INTEGRATION_WEBHOOKS =
  "/api/v3/workspace/%propertyId%/webhooks";
export const UPDATE_PROPERTY_INTEGRATION_WEBHOOKS =
  "/api/v3/workspace/%propertyId%/webhooks/%webhookId%";
export const CHANGE_STATUS_PROPERTY_INTEGRATION_WEBHOOK =
  "/api/v3/workspace/%propertyId%/webhooks/%webhookId%/status";
export const SEND_TEST_PROPERTY_INTEGRATION_WEBHOOK =
  "/api/v3/workspace/%propertyId%/webhooks/%webhookId%/send-test";
export const PARTNER_LOG_API = "api/v3/business-partner/%companyId%/api-log";
// export const PARTNER_LOG_API =
//   "https://run.mocky.io/v3/20c27e31-01a0-47b1-bf3f-0a3ed772c18e";

export const PRIVATE_OFFICE_API = "/api/v1/property-com/private-office";
export const EDIT_PRIVATE_OFFICE_API =
  "/api/v1/property-com/private-office/%id%";

export const PRIVATE_OFFICE_MANAGE_LIST_API =
  "/api/v1/property-com/private-offices";

export const PRIVATE_OFFICE_MANAGE_STATUS_API =
  "/api/v1/property-com/private-office/publish";

export const PRIVATE_OFFICE_MANAGE_VISIBILITY_API =
  "/api/v1/property-com/private-office/%privateOfficeId%/visibility";

export const PRIVATE_OFFICE_MANAGE_DELETE_API =
  "api/v1/property-com/private-office/%privateOfficeId%";

export const PRIVATE_OFFICE_MANAGE_DETAIL_API =
  "/api/v1/property-com/%companyId%/private-office/%privateOfficeId%";

export const PRIVATE_OFFICE_DETAIL_API = "/api/v1/private-office/%slug%";

export const DESKIMO_USER_REVIEWS_API = "/api/v1/property-com/review";
export const DESKIMO_USER_REVIEWS_FEEDBACK_API =
  "/api/v3/property-com/review-services/%propertyId%";

export const MAKE_PROPERTY_ADMIN =
  "/api/v1/des-admin/mark-as-admin/%companyUserId%";

export const PROPERTY_REWARD_LIST_API = "/api/v1/property-com/rewards";
export const PROPERTY_REWARD_DETAIL_API =
  "/api/v1/property-com/%propertyId%/reward/%rewardId%";
export const CREATE_REWARD_API = "/api/v1/property-com/%propertyId%/reward";
export const DELETE_REWARD_API =
  "/api/v1/property-com/%propertyId%/reward/%rewardId%";
export const UPDATE_REWARD_API =
  "/api/v1/property-com/%propertyId%/reward/%rewardId%";
