import { Formik } from "formik";
import * as yup from "yup";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../../components/Button";
import InputCustom from "../../../../../../components/Input";
import SizeBox from "../../../../../../components/SizeBox";
import BoxToggleSlider from "../../../../../../components/BoxToggleSlider";
import Label from "../../../../../../components/Label";
import ModalRightSideBarTemplate from "../../../../../App/views/ModalRightSideBarTemplate";
import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import { modalUpdateAutoTopUpSelector } from "../../../../selectors/BusinessSelectors";
import { modalUpdateAutoTopUpAction } from "../../../../actions/BusinessActions";
import {
  businessAutoTopUpInfoService,
  creditPackageAmountInfoService,
  updateBusinessAutoTopUpInfoService,
} from "../../../../services/BusinessServices";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { validateNumber } from "../../../../../../services/FormatTextService";
import { debounce } from "lodash";

const ModalUpdateAutoTopUp = () => {
  const dispatch = useDispatch();
  const modalUpdateAutoTopUp = useSelector(modalUpdateAutoTopUpSelector);
  const { translations } = useContext(LocalizationContext);
  const [formData, setFormData] = useState<any>(null);
  const [creditAmountInfo, setCreditAmountInfo] = useState<any>(null);

  useEffect(() => {
    if (!modalUpdateAutoTopUp.open) {
      return;
    }
    _fetchAutoTopUpInfo();
  }, [modalUpdateAutoTopUp.open]);

  const _fetchAutoTopUpInfo = async () => {
    if (!modalUpdateAutoTopUp.data) {
      onClose();
      return;
    }

    const { data } = await businessAutoTopUpInfoService(
      modalUpdateAutoTopUp.data
    );
    setFormData(data);
    _getCreditAmountInfo(data?.recharge_amount);
  };

  const _getCreditAmountInfo = debounce(async (amount: string) => {
    const { data } = await creditPackageAmountInfoService({
      param: "amount",
      value: Number(amount) || 0,
      company_id: modalUpdateAutoTopUp.data,
    });
    setCreditAmountInfo(data);
  }, 300);

  const _handleSubmit = async (values: any) => {
    try {
      await updateBusinessAutoTopUpInfoService(
        modalUpdateAutoTopUp.data,
        values
      );
      toastSuccess("Updated Successfully!");
      onClose();
    } catch (e: any) {
      toastError(e?.status?.message);
    }
  };

  const onClose = () => {
    dispatch(modalUpdateAutoTopUpAction({ open: false, data: null }));
    setFormData(null);
  };

  const _renderPercent = (
    amount: string | number,
    percent: string | number
  ) => {
    return (Number(amount) * ((100 - Number(percent)) / 100)).toFixed(2);
  };

  return (
    <ModalRightSideBarTemplate
      open={modalUpdateAutoTopUp.open}
      modalClassName={"office-lease-modal"}
      onClose={onClose}
    >
      <Formik
        enableReinitialize
        initialValues={{
          is_auto_topup: formData?.is_auto_topup || false,
          discount_percentage: formData?.discount_percentage,
          expired_duration: formData?.expired_duration,
          trigger_amount: formData?.trigger_amount,
          recharge_amount: Number(formData?.recharge_amount) || 0,
          apply_discount_from_market: Boolean(
            formData?.apply_discount_from_market
          ),
          auto_extend_expired: formData?.auto_extend_expired || false,
          number_extend_time: formData?.number_extend_time,
        }}
        validationSchema={yup.object().shape({
          recharge_amount: yup
            .number()
            .min(1)
            .required()
            .label("Credit amount"),
        })}
        onSubmit={_handleSubmit}
      >
        {({
          values,
          isSubmitting,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => (
          <>
            <Modal.Header>
              Change auto top-up information
              <ButtonCustom
                onClick={onClose}
                className="m-0 p-0 none-bg close-modal"
              >
                <>
                  {translations.close}
                  <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
                </>
              </ButtonCustom>
            </Modal.Header>
            <Modal.Content className={"scroll"}>
              <BoxToggleSlider
                disabled={isSubmitting}
                value={values.is_auto_topup}
                onChange={(e: any, { checked }: any) => {
                  setFieldValue("is_auto_topup", checked);
                }}
                content={
                  <Label fontSize={"14px"} label={"Enable auto top-up"} />
                }
              />
              <SizeBox height={"16px"} />
              {values.is_auto_topup ? (
                <>
                  <div className="d-flex">
                    <InputCustom
                      labelRequired
                      containerClass={"flex-1"}
                      label={"Credit amount"}
                      name={"recharge_amount"}
                      value={values.recharge_amount}
                      onChange={(e: any, { value }: any) => {
                        const text = validateNumber(value);
                        setFieldValue("recharge_amount", text);
                        _getCreditAmountInfo(text);
                      }}
                      touched={touched.recharge_amount}
                      error={errors.recharge_amount}
                      inputLeftIcon={formData?.currency}
                      inputLeftIconBorder
                      placeholder={"0.00"}
                    />
                    <SizeBox width={"16px"} />
                    <InputCustom
                      containerClass={"flex-1"}
                      label={"Discount"}
                      name={"discount_percentage"}
                      value={values.discount_percentage}
                      onChange={(e: any, { value }: any) => {
                        let text = validateNumber(value);
                        if (Number(text) > 100) {
                          text = "100";
                        }
                        setFieldValue(
                          "discount_percentage",
                          text === "" ? null : text
                        );
                      }}
                      touched={touched.discount_percentage}
                      error={errors.discount_percentage}
                      inputLeftIcon={"%"}
                      inputLeftIconBorder
                      placeholder={creditAmountInfo?.discount || "0"}
                    />
                  </div>
                  <InputCustom
                    containerClass={"flex-1"}
                    disabled
                    label={"Price"}
                    value={_renderPercent(
                      values.recharge_amount,
                      values.discount_percentage || creditAmountInfo?.discount
                    )}
                    inputLeftIcon={formData?.currency}
                    inputLeftIconBorder
                    placeholder={"0.00"}
                  />
                  <div className={"d-flex"}>
                    <InputCustom
                      containerClass={"flex-1"}
                      label={"Expiry period"}
                      name={"expired_duration"}
                      onChange={(e: any, { value }: any) => {
                        let text = validateNumber(value);
                        if (text === "0") {
                          text = "1";
                        }
                        setFieldValue(
                          "expired_duration",
                          text === "" ? null : text
                        );
                      }}
                      value={values.expired_duration}
                      touched={touched.expired_duration}
                      error={errors.expired_duration}
                      inputLeftIcon={"Day(s)"}
                      inputLeftIconBorder
                      placeholder={creditAmountInfo?.duration || "0"}
                    />
                    <SizeBox width={"16px"} />
                    <InputCustom
                      containerClass={"flex-1"}
                      label={"No. of times to rollover unused credits"}
                      name={"number_extend_time"}
                      value={values.number_extend_time}
                      touched={touched.number_extend_time}
                      error={errors.number_extend_time}
                      onChange={(e: any, { value }: any) => {
                        const text = validateNumber(value);
                        setFieldValue(
                          "number_extend_time",
                          text === "" ? null : text
                        );
                      }}
                      placeholder={creditAmountInfo?.extend_time || "0"}
                    />
                  </div>
                  <InputCustom
                    label={"Top-up when the credit balance falls below"}
                    name={"trigger_amount"}
                    value={values.trigger_amount}
                    inputLeftIcon={formData?.currency}
                    touched={touched.trigger_amount}
                    error={errors.trigger_amount}
                    inputLeftIconBorder
                    onChange={(e: any, { value }: any) => {
                      const text = validateNumber(value);
                      setFieldValue(
                        "trigger_amount",
                        text === "" ? null : text
                      );
                    }}
                    placeholder={creditAmountInfo?.trigger_amount || "0"}
                  />
                </>
              ) : null}
              <SizeBox height={"60px"} />
            </Modal.Content>
            <Modal.Actions className={"credit-modal-actions"}>
              <div className={"d-flex"}>
                <ButtonCustom
                  disabled={isSubmitting}
                  height={"48px"}
                  onClick={onClose}
                >
                  {translations.cancel}
                </ButtonCustom>
                <ButtonCustom
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  className={"primary"}
                  height={"48px"}
                  onClick={handleSubmit}
                >
                  Save
                </ButtonCustom>
              </div>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalRightSideBarTemplate>
  );
};

export default ModalUpdateAutoTopUp;
