import React, { useContext, useState } from "react";
import { manualCheckOutService } from "../../../../services/DashboardServices";
import ButtonCustom from "../../../../../../components/Button";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { dashboardDeskUsageThunk } from "../../../../thunks/DashboardThunks";
import {
  DESK_USAGE_ON_GOING_STATUS,
  PROPERTY,
} from "../../../../configs/Constants";
import { useDispatch } from "react-redux";
import { SWAL_DELETE_OPTION } from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";

type Props = {
  statusId: number;
  visitId: number;
};

const DeskUsageAction = ({ statusId, visitId }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const _onClickCheckout = async () => {
    const payload = {
      visit_id: visitId,
    };

    const icon = null;
    const title = "Are you sure you want to \n check-out?";
    const confirmText = "No";
    const cancelText = "Yes";
    const cancelClass = "primary";
    const options = SWAL_DELETE_OPTION({
      title,
      icon,
      confirmText,
      cancelText,
      cancelClass,
      confirmClass: "secondary",
    });

    SweetAlertCommon({
      options,
      cancelCallback: async () => {
        try {
          setLoading(true);
          await manualCheckOutService(payload);
          await dispatch<any>(dashboardDeskUsageThunk(PROPERTY, undefined));
          toastSuccess("Check-out successful!");
        } catch (e: any) {
          toastError(e?.status?.message);
        } finally {
          setLoading(false);
        }
      },
      confirmCallback: () => null,
    });
  };

  if (statusId === DESK_USAGE_ON_GOING_STATUS) {
    return (
      <ButtonCustom
        className={"dashboard_checkout_btn"}
        fontSize={"12px"}
        onClick={_onClickCheckout}
        loading={loading}
        disabled={loading}
      >
        {translations.checkout}
      </ButtonCustom>
    );
  }

  return null;
};

export default DeskUsageAction;
