import React from "react";

const LogoutIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.666 8.00033L11.3327 5.33366L11.3327 7.33366L5.33268 7.33366L5.33268 8.66699L11.3327 8.66699L11.3327 10.667L14.666 8.00033Z"
        fill="#495057"
      />
      <path
        d="M7.3335 14.001C8.12182 14.0031 8.90273 13.8489 9.63101 13.5471C10.3593 13.2453 11.0204 12.8021 11.5762 12.243L10.6335 11.3003C9.75217 12.1816 8.58017 12.6676 7.3335 12.6676C6.08684 12.6676 4.91484 12.1816 4.0335 11.3003C3.15217 10.419 2.66617 9.24695 2.66617 8.00029C2.66617 6.75362 3.15217 5.58162 4.0335 4.70029C4.91484 3.81895 6.08684 3.33295 7.33351 3.33295C8.58017 3.33296 9.75217 3.81895 10.6335 4.70029L11.5762 3.75762C10.4435 2.62429 8.93684 1.99962 7.33351 1.99962C5.73017 1.99962 4.22351 2.62429 3.09084 3.75762C1.95751 4.89029 1.33284 6.39695 1.33284 8.00029C1.33284 9.60362 1.9575 11.1103 3.09084 12.243C3.64657 12.8021 4.30773 13.2453 5.036 13.5471C5.76428 13.8489 6.54519 14.0031 7.3335 14.001Z"
        fill="#495057"
      />
    </svg>
  );
};

export default LogoutIcon;
