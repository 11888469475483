import {
  PREPAID_CREDIT_TRANSACTION_ACTION,
  PREPAID_CREDIT_USAGE_ACTION,
} from "../configs/Actions";

export const getPaidCreditTransactionAction = (value: any) => {
  return {
    type: PREPAID_CREDIT_TRANSACTION_ACTION,
    payload: value,
  };
};

export const getPaidCreditUsageAction = (value: any) => {
  return {
    type: PREPAID_CREDIT_USAGE_ACTION,
    payload: value,
  };
};
