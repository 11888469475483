import React from "react";

type Props = {
  imgUrl: string;
  capName: string;
};
const Avatar = ({ imgUrl, capName }: Props) => {
  if (imgUrl) {
    return (
      <img alt={capName} className={"property-note-avatar"} src={imgUrl} />
    );
  }
  return <div className={"property-note-avatar-text"}>{capName}</div>;
};

export default Avatar;
