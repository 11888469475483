import React from "react";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import "./styles.scss";

interface Props extends DropdownProps {
  children: JSX.Element | JSX.Element[];
}

const DropdownMultiple = ({ children, className, ...props }: Props) => {
  return (
    <Dropdown
      {...props}
      className={`dropdown-multiple-level ${className ? className : ""}`}
      icon={<i className="uil uil-angle-down" />}
    >
      {children}
    </Dropdown>
  );
};

export default DropdownMultiple;
