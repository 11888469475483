import MasterTemplate from "../../../App/views/MasterTemplate";
import EmployeeTable from "./Components/EmployeeTable";
import React, { useContext, useEffect } from "react";
import ButtonCustom from "../../../../components/Button";
import { PUBLIC_URL } from "../../../App/configs/Constants";
import ModalEditEmployee from "./Components/ModalEditEmployee";
import FilterEmployee from "./Components/EmployeeTable/FilterEmployee";
import SearchEmployee from "./Components/EmployeeTable/SearchEmployee";
import "../../styles/employee.scss";
import ModalDetailEmployee from "./Components/ModalDetailEmployee";
import { useDispatch } from "react-redux";
import { modalDownloadAppAction } from "../../../App/actions/AppActions";
import { LocalizationContext } from "../../../../locales/Translation";
import { ROLE_BUSINESS_ADMIN_ID } from "../../configs/Constants";
import ModalInviteEmployeeByGoogle from "./Components/ModalInviteEmployeeByGoogle";
import ButtonInviteEmployeeByGoogle from "./Components/ButtonInviteEmployeeByGoogle";
import { removeDropdown } from "../../../../components/DropdownCustom/service";

const EmployeeList = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  useEffect(() => {
    const tableScroll = document.querySelector(
      ".table-custom.scroll-inside tbody"
    );

    if (tableScroll) {
      tableScroll.addEventListener("scroll", removeDropdown);
    }

    document.addEventListener("scroll", removeDropdown);

    return () => {
      document.removeEventListener("scroll", removeDropdown);

      if (tableScroll) {
        tableScroll.removeEventListener("scroll", removeDropdown);
      }
    };
  }, []);

  return (
    <MasterTemplate roleAccept={[ROLE_BUSINESS_ADMIN_ID]}>
      <div className={"filter-section filter-section-employee"}>
        <SearchEmployee />
        <>
          <div
            className={"dropdown-filter d-flex"}
            style={{ alignItems: "center" }}
          >
            <ButtonInviteEmployeeByGoogle />
            <ButtonCustom
              onClick={() => dispatch(modalDownloadAppAction(true))}
              height={"36px"}
              fontSize={"12px"}
              className={"primary-alt text-nowrap mr-8px"}
            >
              <>
                <img
                  alt={"Deskimo"}
                  className={"mr-10px"}
                  src={PUBLIC_URL + "/assets/icons/icon-download-blue.svg"}
                />
                {translations.downloadTheDeskimoApp}
              </>
            </ButtonCustom>
            <FilterEmployee />
          </div>
        </>
      </div>
      <EmployeeTable />
      <ModalEditEmployee />
      <ModalDetailEmployee />
      <ModalInviteEmployeeByGoogle />
    </MasterTemplate>
  );
};

export default EmployeeList;
