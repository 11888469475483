import MasterTemplate from "../../../App/views/MasterTemplate";
import CampaignTable from "./Components/CampaignTable";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Search from "./Components/Search";
import ButtonCustom from "../../../../components/Button";
import { ROLE_DESKIMO_ADMIN_ID } from "../../../User/configs/Constants";
import { campaignListThunk } from "../../thunks/MarketingThunks";
import { useNavigate } from "react-router-dom";
import { MARKETING_CAMPAIGN_CRATE_LINK } from "../../../App/configs/ListRoutes";
import FilterTime from "./Components/FilterTime";
import "./styles.scss";
import { campaignFilterSelector } from "../../selectors/MarketingSelectors";
import { campaignFilterAction } from "../../actions/MarketingActions";
import { useGetAllQuery, useSetQuery } from "../../../../utils/custom-hooks";
import { deskimoPropertyScannerFilterAction } from "../../../Property/actions/PropertyActions";
import { globalSettingSelector } from "../../../App/selectors/AppSelectors";

const CampaignList = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const campaignFilter = useSelector(campaignFilterSelector);
  const globalSetting = useSelector(globalSettingSelector);

  const [addParamsAlready, setAddParamsAlready] = useState<boolean>(false);
  const getAllQuery = useGetAllQuery();
  const setQuery = useSetQuery();

  useEffect(() => {
    try {
      if (!campaignFilter?.market_codes?.length) {
        const temp = globalSetting?.location.map(
          ({ city_code }: { city_code: string }) => city_code
        );

        dispatch(campaignFilterAction({ market_codes: temp }));
      }

      if (!Object.keys(getAllQuery).length) {
        return;
      }

      dispatch(campaignFilterAction(getAllQuery));
    } finally {
      setAddParamsAlready(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!campaignFilter?.market_codes?.length || !addParamsAlready) {
          return;
        }
        setLoading(true);
        setQuery(campaignFilter);
        await dispatch<any>(campaignListThunk(campaignFilter));
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [addParamsAlready, campaignFilter]);

  const _onSearch = (value: string) => {
    dispatch(campaignFilterAction({ keyword: value }));
  };

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    dispatch(campaignFilterAction({ page: activePage }));
  };

  return (
    <MasterTemplate roleAccept={[ROLE_DESKIMO_ADMIN_ID]}>
      <div className={"filter-section filter-campaign-list"}>
        <div className={"filter-left"}>
          <Search onSearch={_onSearch} />
          <FilterTime />
        </div>
        <ButtonCustom
          onClick={() => navigate(MARKETING_CAMPAIGN_CRATE_LINK)}
          height={"36px"}
          fontSize={"12px"}
          className={"primary text-nowrap mr-0"}
        >
          <>Add Promotion +</>
        </ButtonCustom>
      </div>

      <CampaignTable
        loading={loading}
        onChangePagination={_onChangePagination}
      />
    </MasterTemplate>
  );
};

export default CampaignList;
