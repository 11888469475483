import React, { useEffect } from "react";
import { Calendar, CalendarProps, DateObject } from "react-multi-date-picker";
import "./styles.scss";

type Props = CalendarProps;

const DatePickerMultiple = ({ onMonthChange, ...props }: Props) => {
  const weekDays = ["Su", "Mo", "Tu", "We", "Th", "Fri", "Sa"];

  useEffect(() => {
    setTimeout(() => {
      const headerEl = document.querySelector(".rmdp-header-values span");
      console.log(headerEl);
      if (!headerEl) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      headerEl.innerText = headerEl.innerText.replaceAll(",", "");
    }, 100);
  }, []);

  const _onChangeMonthTitle = (date: DateObject) => {
    const headerEl = document.querySelector(".rmdp-header-values span");
    if (!headerEl) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    headerEl.innerText = new DateObject(date).month.name;
  };

  return (
    <Calendar
      weekDays={weekDays}
      {...props}
      multiple
      weekStartDayIndex={1}
      onMonthChange={(date) => {
        _onChangeMonthTitle(date);
        onMonthChange && onMonthChange(date);
      }}
    />
  );
};

export default DatePickerMultiple;
