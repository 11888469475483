import { Routes, Route } from "react-router-dom";
import {
  ACCOUNT_CREATE_INDIVIDUAL_LINK,
  ACCOUNT_CREATE_OPTION_LINK,
  BOOKING_APPROVAL_LINK,
  BOOKING_DECLINE_LINK,
  FORCE_LOGIN_LINK,
  FORGOT_PASSWORD_LINK,
  LOGIN_LINK,
  LOGIN_LINKED_IN_LINK,
  LOGOUT_LINK,
  PARKING_REQUEST_CALLBACK_LINK,
  REMINDER_CALLBACK_LINK,
  RESET_PASSWORD_LINK,
  SET_PASSWORD_LINK,
} from "../../App/configs/ListRoutes";
import Login from "../views/Login";
import ForgotPassword from "../views/ForgotPassword";
import ResetPassword from "../views/ResetPassword";
import SetPassword from "../views/SetPassword";
import BookingDeclineReason from "../views/BookingDeclineReason";
import BookingApproval from "../views/BookingApproval";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import Logout from "../views/Logout";
import AccountCreateOption from "../views/AccountCreateOption";
import AccountCreateIndividual from "../views/AccountCreateIndividual";
import ReminderCallback from "../views/ReminderCallback";
import ParkingRequestCallback from "../views/ParkingRequestCallback";

const AuthenticationRoutes = () => {
  return (
    <Routes>
      <Route path={FORCE_LOGIN_LINK} element={<Login isForce={true} />} />
      <Route path={LOGIN_LINK} element={<Login />} />
      <Route path={LOGOUT_LINK} element={<Logout />} />
      <Route path={LOGIN_LINKED_IN_LINK} element={<LinkedInCallback />} />
      <Route path={FORGOT_PASSWORD_LINK} element={<ForgotPassword />} />
      <Route path={SET_PASSWORD_LINK} element={<SetPassword />} />
      <Route path={RESET_PASSWORD_LINK} element={<ResetPassword />} />
      <Route path={BOOKING_DECLINE_LINK} element={<BookingDeclineReason />} />
      <Route path={BOOKING_APPROVAL_LINK} element={<BookingApproval />} />
      <Route
        path={ACCOUNT_CREATE_OPTION_LINK}
        element={<AccountCreateOption />}
      />{" "}
      <Route
        path={ACCOUNT_CREATE_INDIVIDUAL_LINK}
        element={<AccountCreateIndividual />}
      />
      <Route path={REMINDER_CALLBACK_LINK} element={<ReminderCallback />} />
      <Route
        path={PARKING_REQUEST_CALLBACK_LINK}
        element={<ParkingRequestCallback />}
      />
    </Routes>
  );
};

export default AuthenticationRoutes;
