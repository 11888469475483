export const LIST_ROOM_BOOKING_ACTION = "LIST_ROOM_BOOKING_ACTION";
export const LIST_ROOM_BOOKING_PAYLOAD_ACTION =
  "LIST_ROOM_BOOKING_PAYLOAD_ACTION";
export const REFRESH_LIST_ROOM_BOOKING_ACTION =
  "REFRESH_LIST_ROOM_BOOKING_ACTION";
export const MODAL_ROOM_BOOKING_DETAIL_ACTION =
  "MODAL_ROOM_BOOKING_DETAIL_ACTION";
export const ONGOING_DATA_ACTION = "ONGOING_DATA_ACTION";
export const LIST_BOOKING_COMPLETED_ACTION = "LIST_BOOKING_COMPLETED_ACTION";
export const LIST_BOOKING_COMPLETED_PAYLOAD_ACTION =
  "LIST_BOOKING_COMPLETED_PAYLOAD_ACTION";

export const LIST_DESK_BOOKING_ACTION = "LIST_DESK_BOOKING_ACTION";
export const LIST_DESK_BOOKING_PAYLOAD_ACTION =
  "LIST_DESK_BOOKING_PAYLOAD_ACTION";
export const REFRESH_LIST_DESK_BOOKING_ACTION =
  "REFRESH_LIST_DESK_BOOKING_ACTION";
export const MODAL_DESK_BOOKING_DETAIL = "MODAL_DESK_BOOKING_DETAIL";

export const TYPE_ROOM = "TYPE_ROOM";
export const TYPE_DESK = "TYPE_DESK";

export const ROOM_STATUS_UPCOMING = 0;
export const ROOM_STATUS_COMPLETED = 1;
export const ROOM_STATUS_CANCELLED = 2;
export const DESK_STATUS_UPCOMING = 1;
export const DESK_STATUS_COMPLETED = 2;
export const DESK_STATUS_CANCELLED = 3;
