import { useDispatch } from "react-redux";
import React, { useContext, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import {
  SWAL_CONFIRM_OPTION,
  SWAL_CUSTOM_CONTENT,
  SWAL_DELETE_OPTION,
} from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import ButtonCustom from "../../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import {
  businessCompanyDeleteStaffThunk,
  businessCompanyEditStaffThunk,
} from "../../../../thunks/BusinessThunks";
import {
  businessCompanyStaffDeactivateAction,
  businessCompanyStaffMakeAdminAction,
  modalBusinessCompanyStaffEditAction,
} from "../../../../actions/BusinessActions";
import {
  businessCompanyStaffDeactivateService,
  businessCompanyStaffMakeAdminService,
  businessCompanyStaffReinviteService,
} from "../../../../services/BusinessServices";
import SwalSuccess from "../../../../../../components/SweetAlert/Templates/SwalSuccess";
import {
  onLogInAsAccount,
  onLoginAsWMSUser,
} from "../../../../../User/services/UserServices";
import { BUSINESS_COMPANY_STAFF_LINK } from "../../../../../App/configs/ListRoutes";

type Props = {
  companyName: string | undefined;
  companyId: number | undefined;
  statusCode: number | undefined;
  invitationId: number;
  isAdmin: boolean;
  loginOnBehalf: boolean;
  loginAsWMS: string;
  userId: number;
};

const Action = ({
  companyName,
  companyId,
  statusCode,
  invitationId,
  isAdmin,
  loginOnBehalf,
  loginAsWMS,
  userId,
}: Props) => {
  const dispatch = useDispatch();
  const { translations } = useContext(LocalizationContext);
  const [disabled, setDisabled] = useState(false);

  const STATUS_PENDING = 0;
  const STATUS_ACCEPTED = 1;
  const STATUS_SUSPENDED = 2;

  const _onReinviteUser = async () => {
    setDisabled(true);
    try {
      await businessCompanyStaffReinviteService(invitationId);
      SweetAlertCommon({
        options: SWAL_CUSTOM_CONTENT({
          content: <SwalSuccess title={translations.inviteResent} />,
          cancelButtonClass: "d-none",
          denyButtonClass: "d-none",
          confirmButtonText: (
            <ButtonCustom className={"primary w-100"} height={"36px"}>
              {translations.done}
            </ButtonCustom>
          ),
        }),
        confirmCallback: () => null,
      });
    } catch (e) {
      toastError("Error occurred when reinvite this user");
      console.error(e);
    } finally {
      setDisabled(false);
    }
  };
  const _onEditUser = async () => {
    setDisabled(true);
    const payload = { invitation_id: invitationId };
    try {
      dispatch(modalBusinessCompanyStaffEditAction(true));
      await dispatch<any>(businessCompanyEditStaffThunk(payload, companyName));
    } catch (e) {
      console.error(e);
    } finally {
      setDisabled(false);
    }
  };

  const _onDeactivateUser = async () => {
    try {
      const title = "Are you sure you want to deactivate this user?";
      const confirmText = "Deactivate";
      const confirmClass = "danger";
      const options = SWAL_DELETE_OPTION({
        title,
        confirmText,
        confirmClass,
      });
      SweetAlertCommon({
        options,
        confirmCallback: async () => {
          await businessCompanyStaffDeactivateService(invitationId);
          dispatch(businessCompanyStaffDeactivateAction(invitationId));
          toastSuccess("User deactivated successfully");
        },
        cancelCallback: () => null,
      });
    } catch (e: any) {
      toastError(e?.status?.message);
    }
  };

  const _onActivateUser = async () => {
    try {
      const title = "Are you sure you want to activate this user?";
      const confirmText = "Activate";
      const confirmClass = "primary";
      const options = SWAL_CONFIRM_OPTION({
        title,
        confirmText,
        confirmClass,
      });
      SweetAlertCommon({
        options,
        confirmCallback: async () => {
          setDisabled(true);
          await businessCompanyStaffDeactivateService(invitationId);
          dispatch(businessCompanyStaffDeactivateAction(invitationId));
          toastSuccess("User activated successfully");
          setDisabled(true);
        },
        cancelCallback: () => null,
      });
    } catch (e: any) {
      toastError(e?.status?.message);
    }
  };

  const _onMakeAdmin = async () => {
    const payload = {
      company_id: companyId,
      employee_id: invitationId,
      monthly_budget: "-1.000",
      used_company_budget: false,
      make_admin: true,
    };
    const title = "Are you sure you want to make this user a business admin?";
    const confirmText = "Make Admin";
    const cancelText = translations.cancel;
    const options = SWAL_CONFIRM_OPTION({
      title,
      confirmText,
      cancelText,
    });
    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setDisabled(true);
        try {
          await businessCompanyStaffMakeAdminService(payload);
          dispatch(businessCompanyStaffMakeAdminAction(invitationId));
          toastSuccess("Make admin successfully");
        } catch (e: any) {
          toastError(e?.status?.message);
        } finally {
          setDisabled(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  const _onDelete = async () => {
    const iconHtml = (
      <img
        alt="error_icon"
        src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
        className={"swal2-icon__error"}
      />
    );
    const title = "Are you sure want to delete this staff?";
    const confirmText = translations.delete;
    const cancelText = translations.cancel;
    const options = SWAL_DELETE_OPTION({
      title,
      iconHtml,
      confirmText,
      cancelText,
    });
    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setDisabled(true);
        try {
          await dispatch<any>(businessCompanyDeleteStaffThunk(invitationId));
          toastSuccess("Deleted Successfully");
        } catch (e: any) {
          toastError(e?.status?.message);
        } finally {
          setDisabled(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  return (
    <DropdownCustom
      disabled={disabled}
      className={"table-action-dropdown"}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={_onEditUser}
          text={translations.editUser}
          icon={
            <i className="uil uil-edit icon" style={{ fontSize: "16px" }} />
          }
        />
        <Dropdown.Item
          onClick={_onReinviteUser}
          text={"Re-invite User"}
          icon={
            <i
              className="uil uil-plus-circle icon"
              style={{ fontSize: "16px" }}
            />
          }
        />
        {statusCode === STATUS_ACCEPTED ? null : statusCode === // /> //   icon={<i className="uil uil-toggle-off icon" />} //   text={"Deactivate User"} //   onClick={_onDeactivateUser} // <Dropdown.Item
          STATUS_SUSPENDED ? (
          <Dropdown.Item
            onClick={_onActivateUser}
            text={"Activate User"}
            icon={<i className="uil uil-toggle-on icon" />}
          />
        ) : null}
        {isAdmin || statusCode === STATUS_PENDING ? null : (
          <Dropdown.Item
            onClick={_onMakeAdmin}
            text={"Make Business Admin"}
            icon={
              <img
                alt="make_admin"
                src={PUBLIC_URL + "/assets/icons/icon-message.svg"}
              />
            }
          />
        )}
        <Dropdown.Item
          onClick={_onDelete}
          text={"Delete User"}
          icon={
            <i
              className="uil uil-trash-alt icon"
              style={{ fontSize: "16px" }}
            />
          }
        />
        {loginOnBehalf ? (
          <Dropdown.Item
            onClick={() =>
              onLogInAsAccount(userId, BUSINESS_COMPANY_STAFF_LINK, companyId)
            }
            text={translations.loginUserAccount}
            icon={
              <img src={PUBLIC_URL + "/assets/icons/icon-log-out-circle.svg"} />
            }
          />
        ) : null}

        {loginAsWMS && loginAsWMS?.length > 0 ? (
          <Dropdown.Item
            onClick={() => onLoginAsWMSUser(loginAsWMS)}
            text={translations.loginWMSAccount}
            icon={
              <img src={PUBLIC_URL + "/assets/icons/icon-log-out-circle.svg"} />
            }
          />
        ) : null}
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default Action;
