import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OnboardTemplate from "../../../App/views/OnboardTemplate";
import VerifyOTP from "../BusinessUserOnboard/Components/VerifyOTP";
import { GET_OTP_SMS, TYPE_ACCEPT } from "../../configs/Constants";
import ButtonCustom from "../../../../components/Button";
import "./style.scss";
import {
  getPendingInviteOTPService,
  getUserPhoneWithoutAuthService,
  handleOTPCaptchaService,
  submitPendingInviteOTPService,
} from "../../services/OnboardServices";
import { rejectPendingInviteService } from "../../../Dashboard/services/DashboardServices";
import { toastError } from "../../../../components/Toast";
import LoaderCustom from "../../../../components/Loader";
import SizeBox from "../../../../components/SizeBox";
import ReCAPTCHA from "react-google-recaptcha";

type Props = {
  type: string;
};

const BusinessUserPendingInvite = ({ type }: Props) => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [userPhone, setUserPhone] = useState({
    phone: null,
    country_code: null,
  });
  const [loading, setLoading] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);

  useEffect(() => {
    if (type === TYPE_ACCEPT) {
      acceptPendingInvite();
    } else {
      rejectPendingInvite();
    }
  }, []);

  const rejectPendingInvite = async () => {
    setLoading(true);
    try {
      const hashToken = token ? token : "";
      await rejectPendingInviteService(hashToken);
    } catch (error: any) {
      if (error?.status?.code === 1000) {
        toastError("The invitation has been rejected");
        navigate("/");
        return;
      } else {
        toastError(error?.status?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const acceptPendingInvite = async () => {
    try {
      const hashToken = token ? token : "";
      const payload = {
        hash_token: hashToken,
      };

      const response = await getUserPhoneWithoutAuthService(payload);
      if (response?.data?.phone && response?.data?.country_code) {
        setUserPhone(response?.data);
        await getOTP(
          response?.data?.phone,
          response?.data?.country_code,
          hashToken
        );
      }
    } catch (error: any) {
      if (error?.status?.code === 113) {
        handleOTPCaptchaService(error, setShowCaptcha);
      } else {
        toastError(error?.status?.message);
        navigate("/");
      }
    }
  };

  const getOTP = async (phone: any, countryCode: any, hashToken: any) => {
    const payload = {
      phone_number: phone,
      country_code: countryCode,
      resend: 0,
      channel: GET_OTP_SMS,
    };
    await getPendingInviteOTPService(payload, hashToken);
  };

  const _resendOTP = async (data: any) => {
    try {
      const hashToken = token ? token : "";
      const payload = {
        phone_number: userPhone.phone,
        country_code: userPhone.country_code,
        resend: 1,
        channel: data?.type || GET_OTP_SMS,
      };

      if (data?.captcha_token) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        payload.captcha_token = data?.captcha_token;
      }

      return await getPendingInviteOTPService(payload, hashToken);
    } catch (error: any) {
      handleOTPCaptchaService(error, setShowCaptcha);
    }
  };

  const _submitOTP = async (data: any) => {
    try {
      const hashToken = token ? token : "";
      const payload = {
        code: data,
        phone_number: userPhone.phone,
        country_code: userPhone.country_code,
      };
      await submitPendingInviteOTPService(payload, hashToken);
      navigate("/");
    } catch (error: any) {
      handleOTPCaptchaService(error, setShowCaptcha);
    }
  };

  const _onChangeCaptcha = async (value: string | null) => {
    try {
      if (!value) {
        return;
      }

      await _resendOTP({ captcha_token: value });
      setShowCaptcha(false);
    } catch (e) {
      console.log(e);
    }
  };

  if (type === TYPE_ACCEPT) {
    return (
      <OnboardTemplate
        title={<>Pay for workspaces only when you actually use them</>}
        subtitle={
          <>
            With Deskimo, you maximize your value by paying for workspaces by
            the minute. That means you never pay for unused time.
          </>
        }
      >
        <>
          {userPhone?.phone && userPhone?.country_code ? (
            <VerifyOTP
              userData={{
                has_phone_number: true,
                phone: userPhone?.phone,
                dialing_code: userPhone?.country_code,
              }}
              resendOTP={_resendOTP}
              loginWithOTP={_submitOTP}
            />
          ) : null}
          {showCaptcha && process.env.REACT_APP_RECAPTCHA_KEY ? (
            <>
              <SizeBox height={"20px"} />
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={_onChangeCaptcha}
              />
            </>
          ) : null}
        </>
      </OnboardTemplate>
    );
  }

  return (
    <OnboardTemplate
      verticalCenter
      title={<>Pay for workspaces only when you actually use them</>}
      subtitle={
        <>
          With Deskimo, you maximize your value by paying for workspaces by the
          minute. That means you never pay for unused time.
        </>
      }
    >
      <div className={"pending-invite-box"}>
        {loading ? <LoaderCustom /> : null}
        <div className={"pending-invite-title"}>Cancellation Completed</div>
        <div className={"pending-invite-subtitle"}>
          We will inform your admin that you’ve declined their invite. If you
          wish to activate your account, you may ask your admin.
        </div>
        <div className={"d-flex w-100"}>
          <ButtonCustom
            onClick={() => {
              navigate("/");
            }}
            className={"flex-1 primary"}
          >
            Close window
          </ButtonCustom>
        </div>
      </div>
    </OnboardTemplate>
  );
};

export default BusinessUserPendingInvite;
