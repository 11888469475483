import React, { useContext, useState } from "react";
import { Modal } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import { isModalAddCreditCardSelector } from "../../../../selectors/BillingSelector";
import { modalAddCardAction } from "../../../../actions/BiilingActions";
import ButtonCustom from "../../../../../../components/Button";
import ModalCenterTemplate from "../../../../../App/views/ModalCenterTemplate";
import InputCustom from "../../../../../../components/Input";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { Formik } from "formik";
import * as yup from "yup";
import { _checkValue } from "../../../../../../utils/commons";
import { createPaymentMethodService } from "../../../../services/SettingServices";
import { getPaymentMethodThunk } from "../../../../thunks/BillingThunk";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useCompanyChooseUpdateCreditAction } from "../../../../../User/actions/UserActions";
import InputCardNumber from "../../../../../../components/Input/InputCardNumber";

const ModalAddCreditCard = () => {
  const dispatch = useDispatch();
  const isModalAddCreditCard = useSelector(isModalAddCreditCardSelector);
  const [loading, setLoading] = useState(false);
  const { translations } = useContext(LocalizationContext);

  const _onClickSave = async (values: any) => {
    setLoading(true);
    try {
      await createPaymentMethodService(values);
      dispatch(useCompanyChooseUpdateCreditAction());
      toastSuccess(translations.settings.addCreditCardMsg);
      await dispatch<any>(getPaymentMethodThunk());
      dispatch(modalAddCardAction(false));
    } catch (response: any) {
      toastError(response?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={() => dispatch(modalAddCardAction(false))}
      modalClassName={"modal-general modal-vertical-centered"}
      open={isModalAddCreditCard}
    >
      <Formik
        initialValues={{
          credit_number: "",
          card_holder_name: "",
          expired_date: "",
          cvc_number: "",
        }}
        validationSchema={yup.object().shape({
          credit_number: yup
            .string()
            .required(translations.settings.requiredField),
          card_holder_name: yup
            .string()
            .required(translations.settings.requiredField),
          expired_date: yup
            .string()
            .required(translations.settings.requiredField),
          cvc_number: yup
            .number()
            .typeError(translations.settings.inputTypeNumberOnly)
            .required(translations.settings.requiredField),
        })}
        onSubmit={_onClickSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <>
            <Modal.Header>
              {translations.settings.addCard}
              <ButtonCustom
                onClick={() => dispatch(modalAddCardAction(false))}
                className={"modal-close-cta"}
              >
                <>
                  <span>{translations.settings.close}</span>
                  <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
                </>
              </ButtonCustom>
            </Modal.Header>
            <Modal.Content>
              <InputCustom
                label={translations.settings.cardFullName}
                value={values.card_holder_name}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.card_holder_name}
                error={errors.card_holder_name}
                name={"card_holder_name"}
              />
              <div className={"mb-16px"} />
              <InputCardNumber
                label={translations.settings.cardNumber}
                value={values.credit_number}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.credit_number}
                error={errors.credit_number}
                name={"credit_number"}
              />
              <div className={"mb-16px"} />
              <div className={"d-flex"}>
                <div style={{ flex: 1 }}>
                  <InputCustom
                    placeholder={"MM/YY"}
                    label={translations.settings.expirationDate}
                    value={values.expired_date}
                    onChange={(e: any) => {
                      let input = e.target.value;
                      if (/\D\/$/.test(input)) {
                        input = input.substr(0, input.length - 3);
                      }
                      const values = input.split("/").map(function (v: any) {
                        return v.replace(/\D/g, "");
                      });
                      if (values[0]) {
                        values[0] = _checkValue(values[0], 12);
                      }
                      const output = values.map(function (v: any, i: any) {
                        return v.length === 2 && i < 2 ? v + " / " : v;
                      });

                      e.target.value = output.join("").substr(0, 7);

                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    touched={touched.expired_date}
                    error={errors.expired_date}
                    name={"expired_date"}
                  />
                </div>
                <div style={{ margin: "0 8px" }} />
                <div style={{ flex: 1 }}>
                  <InputCustom
                    label={"CVC"}
                    value={values.cvc_number}
                    onChange={(e: any) => {
                      e.target.value = e.target.value.replace(/\D/g, "");
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    touched={touched.cvc_number}
                    error={errors.cvc_number}
                    name={"cvc_number"}
                  />
                </div>
              </div>
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom
                className={"default"}
                height={"47px"}
                onClick={() => dispatch(modalAddCardAction(false))}
              >
                {translations.settings.cancel}
              </ButtonCustom>
              <ButtonCustom
                loading={loading}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {translations.settings.addCard}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalCenterTemplate>
  );
};

export default ModalAddCreditCard;
