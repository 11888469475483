import React, { useContext } from "react";
import Label from "../../../../../components/Label";
import InputCustom from "../../../../../components/Input";
import { LocalizationContext } from "../../../../../locales/Translation";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import SizeBox from "../../../../../components/SizeBox";
import moment from "moment";
import DatePickerSingle from "../../../../../components/DatePickerSingle";
import ToggleSlider from "../../../../../components/ToggleSlider";

type Props = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
  handleChange: any;
  setFieldValue: any;
};

const OnboardingPromotion = ({
  values,
  errors,
  touched,
  isSubmitting,
  handleChange,
  setFieldValue,
}: Props) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <>
      <Label
        label={"Onboarding promotion"}
        fontSize={"24px"}
        className={"section-title"}
      />
      <div className={"detail_divide"}>
        <div className={"left_section"}>
          <InputCustom
            label={"Free credit for new users"}
            value={values.free_credit}
            error={errors.free_credit}
            touched={touched.free_credit}
            name={"free_credit"}
            onChange={handleChange}
            disabled={isSubmitting}
            inputLeftIcon={values?.currency}
            inputLeftIconBorder
            removeLetter
          />

          <InputCustom
            label={"Free credit valid for"}
            value={values.free_credit_duration}
            error={errors.free_credit_duration}
            touched={touched.free_credit_duration}
            name={"free_credit_duration"}
            onChange={handleChange}
            disabled={isSubmitting}
            inputLeftIcon={"Days"}
            inputLeftIconBorder
            removeLetter
          />

          <ToggleSlider
            className={"flex-1"}
            label={translations?.showTopUpNotification}
            value={values.force_credit_popup}
            onChange={(e: any, data: any) => {
              setFieldValue("force_credit_popup", data.checked);
            }}
          />
        </div>

        <div className={"right_section"}>
          <Label
            label={"Onboard campaign starting"}
            fontSize={"12px"}
            className={"label"}
          />

          <DatePickerSingle
            value={
              values.onboard_campaign_start_time
                ? new Date(
                    moment.unix(values.onboard_campaign_start_time).get("year"),
                    moment
                      .unix(values.onboard_campaign_start_time)
                      .get("month"),
                    moment.unix(values.onboard_campaign_start_time).get("date")
                  )
                : null
            }
            inputStyle
            oneTap
            onChange={(date: any) => {
              setFieldValue("onboard_campaign_start_time", moment(date).unix());
            }}
          />

          <Label
            label={"Onboard campaign ending"}
            fontSize={"12px"}
            className={"label"}
          />
          <DatePickerSingle
            value={
              values.onboard_campaign_end_time
                ? new Date(
                    moment.unix(values.onboard_campaign_end_time).get("year"),
                    moment.unix(values.onboard_campaign_end_time).get("month"),
                    moment.unix(values.onboard_campaign_end_time).get("date")
                  )
                : null
            }
            inputStyle
            oneTap
            onChange={(date: any) => {
              setFieldValue("onboard_campaign_end_time", moment(date).unix());
            }}
          />
        </div>
      </div>

      <SizeBox height={"24px"} />
      <div className={"property_company_detail_line"} />
    </>
  );
};

export default OnboardingPromotion;
