import { Modal } from "semantic-ui-react";
import React, { useContext, useState } from "react";
import ButtonCustom from "../../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import ModalRightSideBarTemplate from "../ModalRightSideBarTemplate";
import { PUBLIC_URL } from "../../configs/Constants";
import { Formik } from "formik";
import * as yup from "yup";
import { isModalUpdatePasswordSelector } from "../../selectors/AppSelectors";
import { modalUpdatePasswordAction } from "../../actions/AppActions";
import InputCustom from "../../../../components/Input";
import { toastError, toastSuccess } from "../../../../components/Toast";
import { changePasswordService } from "../../services/AppServices";
import { LocalizationContext } from "../../../../locales/Translation";

const ModalUpdatePassword = () => {
  const { translations } = useContext(LocalizationContext);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isModalUpdatePassword = useSelector(isModalUpdatePasswordSelector);

  const _handleSave = async (values: any) => {
    setLoading(true);
    try {
      await changePasswordService(values);
      toastSuccess(translations.updatePasswordSuccessfully);
      dispatch(modalUpdatePasswordAction(false));
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalRightSideBarTemplate
      trigger={null}
      onClose={() => dispatch(modalUpdatePasswordAction(false))}
      modalClassName={"modal-edit-employee"}
      open={isModalUpdatePassword}
    >
      <Formik
        initialValues={{
          current_password: "",
          new_password: "",
          repeat_new_password: "",
        }}
        validationSchema={yup.object().shape({
          current_password: yup.string().required().label("Current Password"),
          new_password: yup.string().required().label("New Password"),
          repeat_new_password: yup
            .string()
            .oneOf([yup.ref("new_password"), null], "Passwords must match")
            .required()
            .label("Repeat New Password"),
        })}
        onSubmit={_handleSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <>
            <Modal.Header>
              <div>{translations.updatePassword}</div>
              <ButtonCustom
                onClick={() => dispatch(modalUpdatePasswordAction(false))}
                className={"m-0 p-0 none-bg close-modal"}
              >
                <>
                  {translations.close}
                  <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
                </>
              </ButtonCustom>
            </Modal.Header>
            <Modal.Content className={"scroll"}>
              <form className={"form-layout"}>
                <InputCustom
                  type={"password"}
                  labelRequired={true}
                  label={translations.currentPassword}
                  value={values.current_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.current_password}
                  error={errors.current_password}
                  name={"current_password"}
                />
                <InputCustom
                  type={"password"}
                  labelRequired={true}
                  label={translations.newPassword}
                  value={values.new_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.new_password}
                  error={errors.new_password}
                  name={"new_password"}
                />
                <InputCustom
                  type={"password"}
                  labelRequired={true}
                  label={translations.repeatNewPassword}
                  value={values.repeat_new_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.repeat_new_password}
                  error={errors.repeat_new_password}
                  name={"repeat_new_password"}
                />
                <div className={"mb-16px"} />
              </form>
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom
                height={"47px"}
                onClick={() => dispatch(modalUpdatePasswordAction(false))}
              >
                {translations.discard}
              </ButtonCustom>
              <ButtonCustom
                loading={loading}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {translations.saveChanges}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalRightSideBarTemplate>
  );
};

export default ModalUpdatePassword;
