import React, { useEffect, useState } from "react";
import Table from "../../../../../components/Table";
import { getDeskimoUserVoucherUsageService } from "../../../services/EmployeeServices";
import { useParams } from "react-router-dom";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../../App/configs/Constants";

const VoucherUsage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);

  const [usageHistory, setUsageHistory] = useState<null | {
    items: [];
    pagination: any;
  }>(null);

  const [usageHistoryFilter, setUsageHistoryFilter] = useState({
    page: 1,
    sort_by: "",
    sort_type: ORDER_BY_NONE,
  });

  useEffect(() => {
    (async () => {
      try {
        if (!id) {
          return;
        }
        setLoading(true);
        const { data } = await getDeskimoUserVoucherUsageService(
          usageHistoryFilter,
          id
        );
        if (Array.isArray(data)) {
          setUsageHistory(null);
          return;
        }
        setUsageHistory(data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [usageHistoryFilter]);

  const _formatInputData = () => {
    if (!usageHistory) {
      return [];
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return usageHistory.items.map(
      ({
        voucher_code,
        added_date,
        redeemed_date,
        transaction_id,
        type,
      }: any) => {
        return {
          voucher_code,
          added_date,
          redeemed_date,
          transaction_id,
          type,
        };
      }
    );
  };

  const columns = [
    { key: "voucher_code", col: "Voucher Code" },
    { key: "added_date", col: "Date Added" },
    { key: "redeemed_date", col: "Date Redeemed" },
    { key: "transaction_id", col: "Transaction ID" },
    { key: "type", col: "Type" },
  ];

  const _onChangeSortCol = (col: any) => {
    if (usageHistoryFilter.sort_by !== col.key) {
      setUsageHistoryFilter((prevState) => {
        return { ...prevState, sort_by: col.key, sort_type: ORDER_BY_ASC };
      });
    } else {
      if (usageHistoryFilter.sort_type === ORDER_BY_NONE) {
        setUsageHistoryFilter((prevState) => {
          return { ...prevState, sort_by: col.key, sort_type: ORDER_BY_ASC };
        });
      }
      if (usageHistoryFilter.sort_type === ORDER_BY_DESC) {
        setUsageHistoryFilter((prevState) => {
          return { ...prevState, sort_by: col.key, sort_type: ORDER_BY_NONE };
        });
      }
      if (usageHistoryFilter.sort_type === ORDER_BY_ASC) {
        setUsageHistoryFilter((prevState) => {
          return { ...prevState, sort_by: col.key, sort_type: ORDER_BY_DESC };
        });
      }
    }
  };

  return (
    <>
      <h2 className={"section-white-box_title"}>Voucher Usage</h2>
      <div className={"user_detail_table_wrapper"}>
        <Table
          loading={loading}
          tableClass={"user_detail_table"}
          sortTable
          showTotal={false}
          columns={columns}
          tableData={usageHistory ? _formatInputData() : []}
          currentPage={usageHistory?.pagination?.page}
          itemPerPage={usageHistory?.pagination?.limit}
          totalItems={usageHistory?.pagination?.total}
          onChangePagination={(e: any, { activePage }: any) =>
            setUsageHistoryFilter((prevState) => {
              return { ...prevState, page: activePage };
            })
          }
          onChangeSortCol={_onChangeSortCol}
          sortBy={usageHistoryFilter.sort_by}
          orderBy={usageHistoryFilter.sort_type}
        />
      </div>
      <div className={"section-white-box_line"}></div>
    </>
  );
};

export default VoucherUsage;
