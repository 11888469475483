import { createReducer } from "@reduxjs/toolkit";
import {
  DASHBOARD_ACTIVITY_LOG_ACTION,
  DASHBOARD_DESK_BOOKING_ACTION,
  DASHBOARD_DESK_BOOKING_FILTER_ACTION,
  DASHBOARD_DESK_USAGE_ACTION,
  DASHBOARD_DESK_USAGE_FILTER_ACTION,
  DASHBOARD_FILTER_DATE_ACTION,
  DASHBOARD_FILTER_DATE_RANGE_ACTION,
  DASHBOARD_PROPERTY_CHOOSE_ACTION,
  DASHBOARD_PROPERTY_LIST_ACTION,
  DASHBOARD_PROPERTY_PENDING_BOOKINGS_ACTION,
  DASHBOARD_PROPERTY_PENDING_BOOKING_REMOVE_ACTION,
  DASHBOARD_PROPERTY_STATUS_ACTION,
  DASHBOARD_ROOM_BOOKING_ACTION,
  DASHBOARD_ROOM_BOOKING_FILTER_ACTION,
  DASHBOARD_STATISTIC_ACTION,
  DASHBOARD_STATISTIC_BOOKING_PROPERTY_ACTION,
  DASHBOARD_STATISTIC_PROPERTY_ACTION,
  DESK_BOOKING_DECLINED_STATUS,
  DESK_USAGE_COMPLETE_STATUS,
  DESK_USAGE_ON_GOING_STATUS,
  ROOM_BOOKING_ALL_STATUS,
  ROOM_BOOKING_COMPLETED_STATUS,
  ROOM_BOOKING_CONFIRMED_STATUS,
  ROOM_BOOKING_NOT_REFUNDED_STATUS,
  ROOM_BOOKING_PENDING_PAYMENT_STATUS,
  ROOM_BOOKING_PENDING_REFUNDED_STATUS,
  ROOM_BOOKING_PENDING_STATUS,
  ROOM_BOOKING_REFUNDED_STATUS,
  DASHBOARD_DESK_BOOKING_CHANGE_STATUS_ACTION,
  DASHBOARD_ROOM_BOOKING_CHANGE_STATUS_ACTION,
  ROOM_BOOKING_DECLINED_STATUS,
  DASHBOARD_ACTIVITY_LOG_LOAD_MORE_ACTION,
  ROOM_BOOKING_CANCELLED_STATUS,
  DASHBOARD_STATISTIC_DESKIMO_ACTION,
  GET_DECLINE_REASONS_ACTION,
  MODAL_DECLINE_REASONS_ACTION,
  MODAL_BOOKING_DESK_MANUAL_ACTION,
  MODAL_BOOKING_ROOM_MANUAL_ACTION,
  DASHBOARD_LEASE_FILTER_ACTION,
  DASHBOARD_STATISTIC_FILTER_ACTION,
  GET_FREE_CREDIT_ACTION,
  DESK_USAGE_AWAITING_PAYMENT_STATUS,
  DASHBOARD_DESKIMO_DESK_USAGE_FILTER_ACTION,
  DASHBOARD_DESKIMO_DESK_BOOKING_FILTER_ACTION,
  DASHBOARD_DESKIMO_ROOM_BOOKING_FILTER_ACTION,
  MODAL_CREATE_WALK_INS_MANUAL_ACTION,
  PAYMENT_FAIL,
  PAYMENT_SUCCESS,
  PAYMENT_SCHEDULED,
  DASHBOARD_STATISTIC_OFFICE_PROPERTY_ACTION,
  TRANSACTION_INFO_ACTION,
  MODAL_CHECK_IN_MANUAL_ACTION,
  MODAL_PARTNER_CREATE_WALKINS_ACTIONS,
  LIST_PROPERTY_FILTER_ACTION,
  LIST_PROPERTY_SELECTED_FILTER_ACTION,
} from "../configs/Constants";
import { ORDER_BY_DESC } from "../../App/configs/Constants";

const initializeState = {
  filterDateType: null,
  filterDateRange: null,
  dashboardStatistic: {
    currency: "",
    total_visits: "0",
    total_visit_per: "0",
    current_user: ["0", "0", "0", "0"],
    total_bookings: 0,
    total_booking_per: 0,
    total_day_passes: 0,
    total_day_pass_per: 0,
    total_cost: "0",
    cost_per_user: "0",
    visit_cost: "0",
    booking_cost: "0",
    day_pass_cost: "0",
    cost_statistic: {
      visit_cost: 0,
      booking_cost: 0,
      day_pass_cost: 0,
    },
  },
  dashboardStatisticDeskimo: {
    currency: "",
    GBV: 0,
    net_revenue: 0,
    total_visit_and_booking: 0,
    total_visit: 0,
    total_room_booking: 0,
    market_breakdown: [],
    deskimo_account_type: [],
  },
  dashboardStatisticProperty: null,
  dashboardActivityProperty: [],
  dashboardStatisticBookingProperty: null,
  dashboardStatisticOfficeProperty: null,
  dashboardDeskUsage: null,
  dashboardDeskUsageFilter: {
    keyword: "",
    start_time: null,
    end_time: null,
    filter: [DESK_USAGE_ON_GOING_STATUS, DESK_USAGE_COMPLETE_STATUS],
    sort_by: null,
    order_by: null,
    page: 1,
  },
  dashboardDeskBooking: null,
  dashboardDeskBookingFilter: {
    keyword: "",
    start_time: null,
    end_time: null,
    status: [
      ROOM_BOOKING_ALL_STATUS,
      ROOM_BOOKING_CONFIRMED_STATUS,
      ROOM_BOOKING_PENDING_STATUS,
      ROOM_BOOKING_DECLINED_STATUS,
      ROOM_BOOKING_CANCELLED_STATUS,
      ROOM_BOOKING_COMPLETED_STATUS,
    ],
    sort_by: null,
    order_by: null,
    page: 1,
  },
  dashboardRoomBooking: null,
  dashboardRoomBookingFilter: {
    keyword: "",
    start_time: null,
    end_time: null,
    filter_status: [
      ROOM_BOOKING_ALL_STATUS,
      ROOM_BOOKING_CONFIRMED_STATUS,
      ROOM_BOOKING_PENDING_STATUS,
      ROOM_BOOKING_DECLINED_STATUS,
      ROOM_BOOKING_CANCELLED_STATUS,
      ROOM_BOOKING_COMPLETED_STATUS,
    ],
    sort_by: null,
    order_by: null,
    page: 1,
  },
  dashboardLeaseFilter: {
    keyword: "",
    status: [PAYMENT_FAIL, PAYMENT_SUCCESS, PAYMENT_SCHEDULED],
    sort_type: ORDER_BY_DESC,
    sort_by: "time_period",
    page: 1,
  },
  dashboardPropertyList: [],
  dashboardPropertyChoose: null,
  dashboardPropertyPendingBookings: [],
  declineReasons: [],
  modalSelectDeclineReason: {
    open: false,
  },
  modalCheckInManual: {
    open: false,
  },
  modalBookingDesk: {
    open: false,
  },
  modalBookingRoom: {
    open: false,
  },
  modalCreateWalkIns: {
    open: false,
  },
  dashboardStatisticFilter: {
    market: "0",
  },
  freeCredit: null,
  dashboardDeskimoDeskUsageFilter: {
    keyword: "",
    start_time: null,
    end_time: null,
    filter: [
      DESK_USAGE_ON_GOING_STATUS,
      DESK_USAGE_AWAITING_PAYMENT_STATUS,
      DESK_USAGE_COMPLETE_STATUS,
    ],
    sort_by: null,
    order_by: null,
    page: 1,
  },
  dashboardDeskimoDeskBookingFilter: {
    keyword: "",
    start_time: null,
    end_time: null,
    status: [
      ROOM_BOOKING_ALL_STATUS,
      ROOM_BOOKING_CONFIRMED_STATUS,
      ROOM_BOOKING_PENDING_STATUS,
      ROOM_BOOKING_DECLINED_STATUS,
      ROOM_BOOKING_CANCELLED_STATUS,
      ROOM_BOOKING_COMPLETED_STATUS,
      ROOM_BOOKING_PENDING_PAYMENT_STATUS,
    ],
    refund_status: [
      ROOM_BOOKING_PENDING_REFUNDED_STATUS,
      ROOM_BOOKING_REFUNDED_STATUS,
      ROOM_BOOKING_NOT_REFUNDED_STATUS,
    ],
    sort_by: null,
    order_by: null,
    page: 1,
  },
  dashboardDeskimoRoomBookingFilter: {
    keyword: "",
    start_time: null,
    end_time: null,
    filter_status: [
      ROOM_BOOKING_ALL_STATUS,
      ROOM_BOOKING_CONFIRMED_STATUS,
      ROOM_BOOKING_PENDING_STATUS,
      ROOM_BOOKING_DECLINED_STATUS,
      ROOM_BOOKING_CANCELLED_STATUS,
      ROOM_BOOKING_COMPLETED_STATUS,
      ROOM_BOOKING_PENDING_PAYMENT_STATUS,
    ],
    filter_refund_status: [
      ROOM_BOOKING_PENDING_REFUNDED_STATUS,
      ROOM_BOOKING_REFUNDED_STATUS,
      ROOM_BOOKING_NOT_REFUNDED_STATUS,
    ],
    sort_by: null,
    order_by: null,
    page: 1,
  },
  transactionInfo: [],
  modalPartnerCreateWalkIns: {},
  filterListProperty: [],
  filterListPropertySelected: [],
};

const DashboardReducer = createReducer(initializeState, {
  [DASHBOARD_FILTER_DATE_ACTION]: (state, action) => {
    state.filterDateType = action.payload;
  },

  [DASHBOARD_FILTER_DATE_RANGE_ACTION]: (state, action) => {
    state.filterDateRange = action.payload;
  },

  [DASHBOARD_STATISTIC_ACTION]: (state, action) => {
    state.dashboardStatistic = action.payload;
  },

  [DASHBOARD_STATISTIC_DESKIMO_ACTION]: (state, action) => {
    state.dashboardStatisticDeskimo = action.payload;
  },

  [DASHBOARD_STATISTIC_PROPERTY_ACTION]: (state, action) => {
    state.dashboardStatisticProperty = action.payload;
  },

  [DASHBOARD_STATISTIC_BOOKING_PROPERTY_ACTION]: (state, action) => {
    state.dashboardStatisticBookingProperty = action.payload;
  },

  [DASHBOARD_STATISTIC_OFFICE_PROPERTY_ACTION]: (state, action) => {
    state.dashboardStatisticOfficeProperty = action.payload;
  },

  [DASHBOARD_ACTIVITY_LOG_ACTION]: (state, action) => {
    state.dashboardActivityProperty = action.payload;
  },

  [DASHBOARD_ACTIVITY_LOG_LOAD_MORE_ACTION]: (state, action) => {
    if (Array.isArray(state.dashboardActivityProperty)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.dashboardActivityProperty = [
        ...state.dashboardActivityProperty,
        ...action.payload,
      ];
    }
  },

  [DASHBOARD_DESK_USAGE_ACTION]: (state, action) => {
    state.dashboardDeskUsage = action.payload;
  },

  [DASHBOARD_DESK_BOOKING_ACTION]: (state, action) => {
    state.dashboardDeskBooking = action.payload;
  },

  [DASHBOARD_DESK_BOOKING_CHANGE_STATUS_ACTION]: (state, action) => {
    if (!state.dashboardDeskBooking) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.dashboardDeskBooking.items = state.dashboardDeskBooking.items.map(
      (item: any) => {
        if (item?.id !== action.payload.id) {
          return item;
        }

        let actions = item.actions;

        if (action.payload.statusId === DESK_BOOKING_DECLINED_STATUS) {
          actions = item.actions.filter(({ name }: { name: string }) => {
            return String(name).toLowerCase() !== "decline";
          });
        }

        return { ...item, status_id: action.payload.statusId, actions };
      }
    );
  },

  [DASHBOARD_ROOM_BOOKING_ACTION]: (state, action) => {
    state.dashboardRoomBooking = action.payload;
  },

  [DASHBOARD_ROOM_BOOKING_CHANGE_STATUS_ACTION]: (state, action) => {
    if (!state.dashboardRoomBooking) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.dashboardRoomBooking.items = state.dashboardRoomBooking.items.map(
      (item: any) => {
        if (item?.id !== action.payload.id) {
          return item;
        }

        let actions = item.actions;

        if (action.payload.statusId === DESK_BOOKING_DECLINED_STATUS) {
          actions = item.actions.filter(({ name }: { name: string }) => {
            return String(name).toLowerCase() !== "decline";
          });
        }

        return { ...item, status_id: action.payload.statusId, actions };
      }
    );
  },

  [DASHBOARD_DESK_USAGE_FILTER_ACTION]: (state, action) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (action.payload.key === "page") {
      state.dashboardDeskUsageFilter["page"] = action.payload.value;
      return;
    }
    state.dashboardDeskUsageFilter = {
      ...state.dashboardDeskUsageFilter,
      ...{ [action.payload.key]: action.payload.value, page: 1 },
    };
  },

  [DASHBOARD_DESKIMO_DESK_USAGE_FILTER_ACTION]: (state, action) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (action.payload.key === "page") {
      state.dashboardDeskimoDeskUsageFilter["page"] = action.payload.value;
      return;
    }
    state.dashboardDeskimoDeskUsageFilter = {
      ...state.dashboardDeskimoDeskUsageFilter,
      ...{ [action.payload.key]: action.payload.value, page: 1 },
    };
  },
  [DASHBOARD_DESK_BOOKING_FILTER_ACTION]: (state, action) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (action.payload.key === "page") {
      state.dashboardDeskBookingFilter["page"] = action.payload.value;
      return;
    }
    state.dashboardDeskBookingFilter = {
      ...state.dashboardDeskBookingFilter,
      ...{ [action.payload.key]: action.payload.value, page: 1 },
    };
  },

  [DASHBOARD_DESKIMO_DESK_BOOKING_FILTER_ACTION]: (state, action) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (action.payload.key === "page") {
      state.dashboardDeskimoDeskBookingFilter["page"] = action.payload.value;
      return;
    }
    state.dashboardDeskimoDeskBookingFilter = {
      ...state.dashboardDeskimoDeskBookingFilter,
      ...{ [action.payload.key]: action.payload.value, page: 1 },
    };
  },
  [DASHBOARD_ROOM_BOOKING_FILTER_ACTION]: (state, action) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (action.payload.key === "page") {
      state.dashboardRoomBookingFilter["page"] = action.payload.value;
      return;
    }
    state.dashboardRoomBookingFilter = {
      ...state.dashboardRoomBookingFilter,
      ...{ [action.payload.key]: action.payload.value, page: 1 },
    };
  },
  [DASHBOARD_DESKIMO_ROOM_BOOKING_FILTER_ACTION]: (state, action) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (action.payload.key === "page") {
      state.dashboardDeskimoRoomBookingFilter["page"] = action.payload.value;
      return;
    }
    state.dashboardDeskimoRoomBookingFilter = {
      ...state.dashboardDeskimoRoomBookingFilter,
      ...{ [action.payload.key]: action.payload.value, page: 1 },
    };
  },
  [DASHBOARD_LEASE_FILTER_ACTION]: (state, action) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (action.payload.key === "page") {
      state.dashboardLeaseFilter["page"] = action.payload.value;
      return;
    }
    state.dashboardLeaseFilter = {
      ...state.dashboardLeaseFilter,
      ...{ [action.payload.key]: action.payload.value, page: 1 },
    };
  },

  [DASHBOARD_PROPERTY_LIST_ACTION]: (state, action) => {
    state.dashboardPropertyList = action.payload;
  },

  [DASHBOARD_PROPERTY_CHOOSE_ACTION]: (state, action) => {
    state.dashboardPropertyChoose = action.payload;
  },

  [DASHBOARD_PROPERTY_PENDING_BOOKINGS_ACTION]: (state, action) => {
    state.dashboardPropertyPendingBookings = action.payload;
  },

  [DASHBOARD_PROPERTY_PENDING_BOOKING_REMOVE_ACTION]: (state, action) => {
    state.dashboardPropertyPendingBookings =
      state.dashboardPropertyPendingBookings.filter(
        ({ id }) => id !== action.payload
      );
  },

  [DASHBOARD_PROPERTY_STATUS_ACTION]: (state, action) => {
    if (!state.dashboardPropertyChoose) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const openStatus = state.dashboardPropertyChoose?.is_open ? 0 : 1;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.dashboardPropertyChoose.is_open = openStatus;
    const index = state.dashboardPropertyList.findIndex(
      ({ property_id }) => property_id === action.payload
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.dashboardPropertyList[index].is_open = openStatus;
  },

  [GET_DECLINE_REASONS_ACTION]: (state, action) => {
    state.declineReasons = action.payload;
  },

  [MODAL_DECLINE_REASONS_ACTION]: (state, action) => {
    state.modalSelectDeclineReason = action.payload;
  },

  [MODAL_CHECK_IN_MANUAL_ACTION]: (state, action) => {
    state.modalCheckInManual = action.payload;
  },

  [MODAL_BOOKING_DESK_MANUAL_ACTION]: (state, action) => {
    state.modalBookingDesk = action.payload;
  },

  [MODAL_BOOKING_ROOM_MANUAL_ACTION]: (state, action) => {
    state.modalBookingRoom = action.payload;
  },

  [MODAL_CREATE_WALK_INS_MANUAL_ACTION]: (state, action) => {
    state.modalCreateWalkIns = action.payload;
  },

  [DASHBOARD_STATISTIC_FILTER_ACTION]: (state, action) => {
    state.dashboardStatisticFilter = action.payload;
  },

  [GET_FREE_CREDIT_ACTION]: (state, action) => {
    state.freeCredit = action.payload;
  },

  [TRANSACTION_INFO_ACTION]: (state, action) => {
    state.transactionInfo = action.payload;
  },

  [MODAL_PARTNER_CREATE_WALKINS_ACTIONS]: (state, action) => {
    state.modalPartnerCreateWalkIns = action.payload;
  },

  [LIST_PROPERTY_FILTER_ACTION]: (state, action) => {
    state.filterListProperty = action.payload;
  },

  [LIST_PROPERTY_SELECTED_FILTER_ACTION]: (state, action) => {
    state.filterListPropertySelected = action.payload;
  },
});

export default DashboardReducer;
