import React, { useContext } from "react";
import { Modal } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { modalAddVoucherAction } from "../../actions/AppActions";
import { isModalAddVoucherSelector } from "../../selectors/AppSelectors";
import "./styles.scss";
import ButtonCustom from "../../../../components/Button";
import ModalCenterTemplate from "../ModalCenterTemplate";
import { PUBLIC_URL } from "../../configs/Constants";
import SizeBox from "../../../../components/SizeBox";
import InputCustom from "../../../../components/Input";
import * as yup from "yup";
import { Formik } from "formik";
import { addVoucherService } from "../../services/AppServices";
import { toastError, toastSuccess } from "../../../../components/Toast";
import { LocalizationContext } from "../../../../locales/Translation";

const ModalAddVoucher = () => {
  const { translations } = useContext(LocalizationContext);

  const dispatch = useDispatch();
  const isModalAddVoucher = useSelector(isModalAddVoucherSelector);

  const _handleSave = async (values: any) => {
    try {
      await addVoucherService(values);
      toastSuccess(translations.addVoucherSuccessfully);
      await dispatch(modalAddVoucherAction(false));
    } catch (error: any) {
      toastError(error?.status?.message);
    }
  };

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={() => dispatch(modalAddVoucherAction(false))}
      modalClassName={"modal-general"}
      open={isModalAddVoucher}
    >
      <Formik
        initialValues={{
          voucher_code: "",
        }}
        validationSchema={yup.object().shape({
          voucher_code: yup.string().required().label("Voucher"),
        })}
        onSubmit={_handleSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <>
            <Modal.Header>
              {translations.addVoucher}
              <ButtonCustom
                onClick={() => dispatch(modalAddVoucherAction(false))}
                className={"modal-close-cta"}
              >
                <>
                  <span>{translations.close}</span>
                  <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
                </>
              </ButtonCustom>
            </Modal.Header>
            <Modal.Content>
              <form className={"form-layout"}>
                <div>{translations.addVoucherDes}</div>
                <SizeBox height={"22px"} />
                <InputCustom
                  value={values?.voucher_code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.voucher_code}
                  error={errors.voucher_code}
                  name={"voucher_code"}
                />
              </form>
            </Modal.Content>
            <Modal.Actions className={"border-0"}>
              <ButtonCustom
                disabled={isSubmitting}
                className={"default"}
                height={"47px"}
                onClick={() => dispatch(modalAddVoucherAction(false))}
              >
                {translations.cancel}
              </ButtonCustom>
              <ButtonCustom
                disabled={isSubmitting}
                loading={isSubmitting}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {translations.redeem}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalCenterTemplate>
  );
};

export default ModalAddVoucher;
