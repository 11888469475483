import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import Label from "../../../../../../components/Label";
import RuleAllowNewCompanyOnly from "./RuleAllowNewCompanyOnly";
import RuleItem from "./RuleItem";
import {
  MATCH_WITH_SPECIFIC_CITIES_RULE,
  MATCH_WITH_SPECIFIC_COMPANIES_RULE,
} from "../../../../configs/Constants";
import {
  autocompleteCompaniesService,
  autocompleteMarketsService,
} from "../../../../services/MarketingServices";

const RulesForBusinessOnly = () => {
  const { translations } = useContext(LocalizationContext);

  return (
    <>
      <Label
        label={translations.advancedRules}
        fontSize={"24px"}
        className={"section-title"}
      />
      <div className={"campaign-create-rules"}>
        <RuleItem
          ruleType={MATCH_WITH_SPECIFIC_CITIES_RULE}
          funcGetValue={autocompleteMarketsService}
          selectLabel={"Markets"}
        />
        <RuleItem
          ruleType={MATCH_WITH_SPECIFIC_COMPANIES_RULE}
          funcGetValue={autocompleteCompaniesService}
          selectLabel={"Companies"}
        />
        <RuleAllowNewCompanyOnly />
      </div>
    </>
  );
};

export default RulesForBusinessOnly;
