const ConfigurationIcon = ({
  strokeColor = "#21252A",
  isActive = false,
}: {
  strokeColor?: string;
  isActive?: boolean;
}) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 3.13281H3.33333C2.8731 3.13281 2.5 3.50591 2.5 3.96615V8.13281C2.5 8.59305 2.8731 8.96615 3.33333 8.96615H7.5C7.96024 8.96615 8.33333 8.59305 8.33333 8.13281V3.96615C8.33333 3.50591 7.96024 3.13281 7.5 3.13281Z"
        stroke={isActive ? "#003DBC" : strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 12.3008H3.33333C2.8731 12.3008 2.5 12.6739 2.5 13.1341V17.3008C2.5 17.761 2.8731 18.1341 3.33333 18.1341H7.5C7.96024 18.1341 8.33333 17.761 8.33333 17.3008V13.1341C8.33333 12.6739 7.96024 12.3008 7.5 12.3008Z"
        stroke={isActive ? "#003DBC" : strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M14.5827 8.96615C14.9657 8.96615 15.345 8.8907 15.6988 8.74413C16.0527 8.59755 16.3742 8.38271 16.6451 8.11187C16.9159 7.84104 17.1308 7.51951 17.2773 7.16564C17.4239 6.81177 17.4993 6.4325 17.4993 6.04948C17.4993 5.66646 17.4239 5.28719 17.2773 4.93332C17.1308 4.57945 16.9159 4.25792 16.6451 3.98708C16.3742 3.71625 16.0527 3.50141 15.6988 3.35483C15.345 3.20825 14.9657 3.13281 14.5827 3.13281C13.8091 3.13281 13.0673 3.4401 12.5203 3.98708C11.9733 4.53407 11.666 5.27593 11.666 6.04948C11.666 6.82303 11.9733 7.56489 12.5203 8.11187C13.0673 8.65886 13.8091 8.96615 14.5827 8.96615Z"
        stroke={isActive ? "#003DBC" : strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M16.666 12.3008H12.4993C12.0391 12.3008 11.666 12.6739 11.666 13.1341V17.3008C11.666 17.761 12.0391 18.1341 12.4993 18.1341H16.666C17.1263 18.1341 17.4993 17.761 17.4993 17.3008V13.1341C17.4993 12.6739 17.1263 12.3008 16.666 12.3008Z"
        stroke={isActive ? "#003DBC" : strokeColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ConfigurationIcon;
