import QRCodeSection from "./Components/QRCodeSection";
import APISection from "./Components/APISection";
import WebHookSection from "./Components/WebHookSection";
import "./styles.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPropertyDetailThunk,
  getPropertyIntegrationsAPIListThunk,
  getPropertyIntegrationsQRCodeListThunk,
  getPropertyIntegrationsWebhookListThunk,
} from "../../../../thunks/PropertyThunks";
import { useNavigate, useParams } from "react-router-dom";
import { propertyDetailSelector } from "../../../../selectors/PropertySelector";
import { propertyIntegrationsCheckingService } from "../../../../services/PropertyIntegrationServices";
import { DESKIMO_PROPERTY_DETAIL_LINK } from "../../../../../App/configs/ListRoutes";
import { Dimmer } from "semantic-ui-react";
import Loader from "../../../../../../components/Loader";

const IntegrationsTab = () => {
  const dispatch = useDispatch();
  const { id, companyId } = useParams();
  const propertyDetail = useSelector(propertyDetailSelector);
  const [firstLoad, setFirstLoad] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    _initIntegrationTab();
  }, []);

  const _initIntegrationTab = async () => {
    await dispatch<any>(getPropertyDetailThunk(id ? id : ""));
  };

  useEffect(() => {
    if (propertyDetail) {
      _checkingPropertyIntegrations();
    }
  }, [propertyDetail]);

  useEffect(() => {
    if (firstLoad) {
      _fetchQRCodeSection();
      _fetchAPISection();
      _fetchWebhookSection();
      setLoading(false);
    }
  }, [firstLoad]);

  const _checkingPropertyIntegrations = async () => {
    if (!id || !companyId) return;
    try {
      const response = await propertyIntegrationsCheckingService(id);
      setFirstLoad(true);
    } catch (error: any) {
      console.log("Error: ", error);
      navigate(
        DESKIMO_PROPERTY_DETAIL_LINK.replaceAll(
          ":companyId",
          companyId
        ).replaceAll(":id", id)
      );
    }
  };

  const _fetchQRCodeSection = async () => {
    if (!id) return;
    await dispatch<any>(getPropertyIntegrationsQRCodeListThunk(id));
  };

  const _fetchAPISection = async () => {
    if (!id) return;
    await dispatch<any>(getPropertyIntegrationsAPIListThunk(id));
  };

  const _fetchWebhookSection = async () => {
    if (!id) return;
    await dispatch<any>(getPropertyIntegrationsWebhookListThunk(id));
  };

  return (
    <div className={"deskimo-tab-content column"}>
      {loading ? (
        <Dimmer active>
          <Loader />
        </Dimmer>
      ) : (
        <>
          <QRCodeSection />
          <APISection />
          <WebHookSection />
        </>
      )}
    </div>
  );
};

export default IntegrationsTab;
