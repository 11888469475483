import {
  AFFILIATE_LIST_ACTION,
  BILLING_CONTACT_ACTION,
  BILLING_INFO_ACTION,
  CREATE_PAYMENT_METHOD_ACTION,
  GET_PROPERTIES_ACTION,
  GET_SETTINGS_ACTION,
  INVOICE_DETAIL_ACTION,
  MODAL_ADD_BILLING_CONTACT_ACTION,
  MODAL_ADD_CARD_ACTION,
  MODAL_BILLING_CONTACT_ACTION,
  MODAL_BILLING_INFORMATION_ACTION,
  MODAL_UPDATE_PAYMENT_METHOD_ACTION,
  MONTHLY_USAGE_ACTION,
  PAYMENT_HISTORY_ACTION,
  PAYMENT_HISTORY_FILTERS_ACTION,
  PAYMENT_METHOD_ACTION,
} from "../configs/Actions";

export const getPaymentHistoryAction = (value: any) => {
  return {
    type: PAYMENT_HISTORY_ACTION,
    payload: value,
  };
};

export const getInvoiceDetailAction = (value: any) => {
  return {
    type: INVOICE_DETAIL_ACTION,
    payload: value,
  };
};

export const getMonthlyUsageAction = (value: any) => {
  return {
    type: MONTHLY_USAGE_ACTION,
    payload: value,
  };
};

export const getPaymentMethodAction = (value: any) => {
  return {
    type: PAYMENT_METHOD_ACTION,
    payload: value,
  };
};

export const getBillingContactAction = (value: any) => {
  return {
    type: BILLING_CONTACT_ACTION,
    payload: value,
  };
};

export const getBillingInfoAction = (value: any) => {
  return {
    type: BILLING_INFO_ACTION,
    payload: value,
  };
};

export const modalUpdatePaymentMethodAction = (value: any) => {
  return {
    type: MODAL_UPDATE_PAYMENT_METHOD_ACTION,
    payload: value,
  };
};

export const modalAddCardAction = (value: any) => {
  return {
    type: MODAL_ADD_CARD_ACTION,
    payload: value,
  };
};

export const modalBillingContactAction = (value: any) => {
  return {
    type: MODAL_BILLING_CONTACT_ACTION,
    payload: value,
  };
};

export const modalAddBillingContactAction = (value: any) => {
  return {
    type: MODAL_ADD_BILLING_CONTACT_ACTION,
    payload: value,
  };
};

//Payment Method
export const createPaymentMethodAction = (value: any) => {
  return {
    type: CREATE_PAYMENT_METHOD_ACTION,
    payload: value,
  };
};

//Billing Information
export const modalBillingInformationAction = (value: any) => {
  return {
    type: MODAL_BILLING_INFORMATION_ACTION,
    payload: value,
  };
};

//Settings
export const getSettingAction = (value: any) => {
  return {
    type: GET_SETTINGS_ACTION,
    payload: value,
  };
};

export const getPropertiesAction = (values: any) => {
  return {
    type: GET_PROPERTIES_ACTION,
    payload: values,
  };
};

export const paymentHistoryFilterAction = (values: any) => {
  return {
    type: PAYMENT_HISTORY_FILTERS_ACTION,
    payload: values,
  };
};

export const affiliateListAction = (values: any) => {
  return {
    type: AFFILIATE_LIST_ACTION,
    payload: values,
  };
};
