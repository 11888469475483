import React, { useContext, useEffect, useState } from "react";
import ScheduleList from "../../PropertyDetail/Components/DetailsTab/Components/ScheduleList";
import SizeBox from "../../../../../components/SizeBox";
import { LocalizationContext } from "../../../../../locales/Translation";
import { FormikValues } from "formik";
import { onInheritScheduleAction } from "../../../services/PropertyServices";

type Props = {
  operationHour: any;
  setOperationHour: any;
  values: FormikValues;
  setFieldValue: any;
};

const Schedule = ({
  setOperationHour,
  operationHour,
  setFieldValue,
  values,
}: Props) => {
  const { translations } = useContext(LocalizationContext);
  const [closeOnHoliday, setCloseOnHoliday] = useState(false);

  useEffect(() => {
    setFieldValue("schedule.close_on_holiday", closeOnHoliday);
  }, [closeOnHoliday]);

  const _onChangeSchedule = (value: any) => {
    if (
      (values.schedule.aircon_inherit && value === "aircon") ||
      (values.schedule.community_inherit && value === "community")
    ) {
      return;
    }

    setOperationHour((prevState: any) => {
      //DeepClone
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.forEach((schedule: any) => {
        if (schedule.type === value.type) {
          schedule.week_days.forEach((day: any) => {
            if (day.week_day === value.week_day) {
              if (value.open_type) {
                day.open_type = parseInt(value.open_type);
              }
              if (value.from_time) {
                day.from_time = value.from_time;
              }

              if (value.to_time) {
                day.to_time = value.to_time;
              }
            }
          });
        }
      });

      return newState;
    });

    if (values.schedule.aircon_inherit) {
      onInheritScheduleAction("aircon", setOperationHour);
    }

    if (values.schedule.community_inherit) {
      onInheritScheduleAction("community", setOperationHour);
    }
  };

  return (
    <>
      <div className={"section"}>
        <div className={"section-title"} style={{ lineHeight: "37px" }}>
          {translations.schedule}
        </div>
        <div>
          <ScheduleList
            key={"operation-area1"}
            onChangeSchedule={_onChangeSchedule}
            hourGroupData={operationHour[0]}
            isShowHoliday
            closeOnHoliday={values.schedule.close_on_holiday}
            setCloseOnHoliday={setCloseOnHoliday}
          />
          <ScheduleList
            key={"operation-area2"}
            onChangeSchedule={_onChangeSchedule}
            hourGroupData={operationHour[1]}
            isInherit={values.schedule.community_inherit}
            isShowInherit
            onChangeInherit={(value: boolean) => {
              setFieldValue("schedule.community_inherit", value);
              if (value) {
                onInheritScheduleAction("community", setOperationHour);
              }
            }}
          />
          <ScheduleList
            key={"operation-area3"}
            onChangeSchedule={_onChangeSchedule}
            hourGroupData={operationHour[2]}
            isInherit={values.schedule.aircon_inherit}
            isShowInherit
            onChangeInherit={(value: boolean) => {
              setFieldValue("schedule.aircon_inherit", value);
              if (value) {
                onInheritScheduleAction("aircon", setOperationHour);
              }
            }}
          />
        </div>
      </div>
      <SizeBox height={"32px"} />
    </>
  );
};

export default Schedule;
