import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../components/Button";
import ModalCenterTemplate from "../../../App/views/ModalCenterTemplate";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PUBLIC_URL } from "../../../App/configs/Constants";
import "./style.scss";
import { BILLING_LINK } from "../../../App/configs/ListRoutes";
import { useNavigate } from "react-router-dom";
import { LOGIN_TIMES } from "../../../../configs/Constants";
import {
  modalStartUserGuideSelector,
  userGuideSelector,
} from "../../../App/selectors/AppSelectors";
import { userCompanyChooseSelector } from "../../../User/selectors/UserSelectors";
import { modalBillingInformationAction } from "../../../Setting/actions/BiilingActions";

const ModalBusinessProfileIncomplete = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginTimes = localStorage.getItem(LOGIN_TIMES);
  const userGuide = useSelector(userGuideSelector);
  const [open, setOpen] = useState(false);
  const userCompany = useSelector(userCompanyChooseSelector);
  const modalStartUserGuide = useSelector(modalStartUserGuideSelector);

  useEffect(() => {
    if (userGuide.run || modalStartUserGuide) {
      return;
    }

    if (!userCompany?.has_completed_profile) {
      if (Number(loginTimes) == 0 || Number(loginTimes) % 3 == 0) {
        setOpen(true);
      }
    }
  }, [userGuide.run, userCompany, modalStartUserGuide]);

  return (
    <ModalCenterTemplate
      trigger={null}
      onClose={() => {
        setOpen(false);
        localStorage.setItem(LOGIN_TIMES, String(1));
      }}
      modalClassName={"modal-business-company-profile-incomplete"}
      open={open}
    >
      <Modal.Content>
        <div className={"modal-image"}>
          <img
            src={PUBLIC_URL + "/assets/images/business-admin-incomplete.png"}
            alt=""
          />
        </div>
        <div className={"modal-title"}>Your Business Profile is incomplete</div>
        <div className={"modal-subtitle"}>
          Enhance your user experience and unlock the full potential of our
          platform by updating your business profile now.
        </div>
        <div className={"modal-actions"}>
          <ButtonCustom
            className={"default"}
            height={"47px"}
            onClick={() => {
              setOpen(false);
              localStorage.setItem(LOGIN_TIMES, String(1));
            }}
          >
            Remind me later
          </ButtonCustom>
          <ButtonCustom
            className={"primary"}
            height={"47px"}
            onClick={async () => {
              dispatch(modalBillingInformationAction(true));
              navigate(BILLING_LINK, { replace: true });
            }}
          >
            Complete business details
          </ButtonCustom>
        </div>
      </Modal.Content>
    </ModalCenterTemplate>
  );
};

export default ModalBusinessProfileIncomplete;
