import React from "react";
import CheckboxCustom from "../../../../../../components/Checkbox";
import Avatar from "../../../../../../components/Avatar";
import { EmployeeGoogleType } from "../../../../configs/DeclareTypes";
import { useDispatch, useSelector } from "react-redux";
import { employeeGoogleChooseSelector } from "../../../../selectors/EmployeeSelectors";
import classnames from "classnames";
import { employeeGoogleChooseAction } from "../../../../actions/EmployeeActions";

type Props = {
  item: EmployeeGoogleType;
};

const EmployeeItem = ({ item }: Props) => {
  const employeeGoogleChoose = useSelector(employeeGoogleChooseSelector);
  const dispatch = useDispatch();

  const employeeEmail = item.email;
  const isChecked = employeeGoogleChoose.some(
    ({ email }: EmployeeGoogleType) => email === employeeEmail
  );

  const _onCheck = () => {
    if (isChecked) {
      const temp = [...employeeGoogleChoose].filter(
        ({ email }: EmployeeGoogleType) => email !== employeeEmail
      );
      dispatch<any>(employeeGoogleChooseAction(temp));
      return;
    }

    dispatch<any>(employeeGoogleChooseAction([...employeeGoogleChoose, item]));
  };

  return (
    <div
      className={classnames("employee_google_user", {
        invited: item.is_invited,
      })}
    >
      <CheckboxCustom
        disabled={item.is_invited}
        checked={isChecked}
        onChange={_onCheck}
      />
      <Avatar capName={item.cap_name} imgUrl={item.avatar} noTooltip />
      <span className={"name"}>
        {item.first_name} {item.last_name}
      </span>
      <span className={"email"}>{employeeEmail}</span>
      {item.is_invited && <span className={"sub_text"}>Already Invited</span>}
    </div>
  );
};

export default EmployeeItem;
