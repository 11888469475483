import React, { useState } from "react";
import "./style.scss";
import ButtonCustom from "../Button";
import CheckboxCustom from "../Checkbox";
import classNames from "classnames";
import { Dimmer, Loader } from "semantic-ui-react";

type CheckBoxType = { id: number | string; name: string };

type Props = {
  label?: string;
  icon?: JSX.Element;
  listCheckBox: CheckBoxType[];
  onChange: any;
  values: string[] | number[];
  disabled?: boolean;
  onSearchChange?: any;
  loading?: any;
  onClear?: any;
  onCheckAll?: any;
};

const DropdownWithCheckboxMenuWithSearchInput = ({
  label,
  icon = <></>,
  listCheckBox,
  onChange,
  values,
  disabled,
  onSearchChange,
  loading,
  onClear,
  onCheckAll,
}: Props) => {
  const [open, setOpen] = useState(false);
  const menu =
    listCheckBox?.length > 0
      ? listCheckBox.map((checkbox: CheckBoxType, index: number) => {
          return (
            <div key={index} className={"dropdown-with-checkbox__row"}>
              <CheckboxCustom
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                checked={values.includes(checkbox?.id)}
                value={checkbox?.id}
                onChange={onChange}
                reverseText={true}
                label={checkbox?.name}
                disabled={disabled}
              />
            </div>
          );
        })
      : null;

  return (
    <div className={"dropdown-with-checkbox dropdown-with-checkbox-search "}>
      <ButtonCustom
        height={"44px"}
        className={classNames("filter", {
          show: open,
        })}
        onClick={() => setOpen(!open)}
      >
        <span className={"mr-10px"}>{label}</span>
        {icon}
      </ButtonCustom>
      {open ? (
        <>
          <div
            className={"dropdown-with-checkbox__overlay"}
            onClick={() => setOpen(false)}
          />

          <div
            className={
              "dropdown-with-checkbox__menu " + (loading ? "loading" : "")
            }
          >
            <div className={"dropdown-with-checkbox__search-input"}>
              <input
                onChange={onSearchChange}
                type="text"
                className={"mb-8px"}
              />
              <div className={"d-flex justify-content-end gap-12"}>
                <ButtonCustom
                  onClick={onCheckAll}
                  height={"24px"}
                  className={"link text-nowrap mr-0 p-0"}
                >
                  Select all
                </ButtonCustom>
                <ButtonCustom
                  onClick={onClear}
                  height={"24px"}
                  className={"grey-link text-nowrap mr-0 p-0"}
                >
                  Clear
                </ButtonCustom>
              </div>
            </div>

            {loading ? (
              <Dimmer active={true}>
                <Loader />
              </Dimmer>
            ) : null}

            {menu}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default DropdownWithCheckboxMenuWithSearchInput;
