import { PUBLIC_URL } from "../../../../../../App/configs/Constants";
import SizeBox from "../../../../../../../components/SizeBox";
import React, { useContext, useState } from "react";
import EditActions from "./EditActions";
import PropertyHowToGetThereEdit from "./PropertyHowToGetThereEdit";
import { useSelector } from "react-redux";
import { propertyDetailSelector } from "../../../../../selectors/PropertySelector";
import { LocalizationContext } from "../../../../../../../locales/Translation";

const PropertyHowToGetThere = ({ loading, setLoading }: any) => {
  const { translations } = useContext(LocalizationContext);
  const [editMode, setEditMode] = useState(false);
  const [showGuide, setShowGuide] = useState(false);
  const propertyDetail = useSelector(propertyDetailSelector);

  return (
    <div className={"section"}>
      <SizeBox height={"16px"} />
      <div className={"d-flex justify-content-between " + (editMode ? "" : "")}>
        <div className={"pr-70 w-100"}>
          <div className={"section-title"}>
            {translations.howToGetThere}
            {propertyDetail?.location_guide?.length > 0 && !editMode ? (
              <>
                <span className={"dot-inline"}>•</span>
                <span
                  onClick={() => setShowGuide((prevState) => !prevState)}
                  className={"text-link"}
                >
                  {showGuide
                    ? "Hide step-by-step guide"
                    : "Show step-by-step guide"}
                </span>
              </>
            ) : null}{" "}
          </div>
          {showGuide ? (
            <div className={"guide-list"}>
              {propertyDetail?.location_guide.map(
                (guide: any, index: number) => {
                  return (
                    <div className={"guide-item"} key={index}>
                      <div className={"guide-item-left"}>
                        <span>{index + 1}</span>
                        <div className={"guide-item-left-line"}></div>
                      </div>
                      <div className={"guide-item-right"}>
                        <div className={"guide-item-title"}>{guide.title}</div>
                        <div className={"guide-item-description"}>
                          {guide.description}
                        </div>
                        <img
                          className={"guide-item-image"}
                          src={guide?.picture}
                          alt="picture"
                        />
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          ) : null}
          <SizeBox height={"23px"} />
          {editMode ? (
            <PropertyHowToGetThereEdit
              setEditMode={setEditMode}
              setLoading={setLoading}
              loading={loading}
            />
          ) : (
            <div className={"d-flex how-to-get-there__list"}>
              <div className={"how-to-get-there__item"}>
                <img src={PUBLIC_URL + "/assets/icons/icon-car.svg"} />
                <div>
                  <div className={"how-to-get-there__title"}>
                    {translations.carDropOffPoint}
                  </div>
                  <div className={"how-to-get-there__subtitle"}>
                    {propertyDetail?.how_to_get_there?.car_drop_off_point
                      ? propertyDetail?.how_to_get_there?.car_drop_off_point
                      : translations.notAvailable}
                  </div>
                </div>
              </div>
              <div className={"how-to-get-there__item"}>
                <img src={PUBLIC_URL + "/assets/icons/icon-mrt.svg"} />
                <div>
                  <div className={"how-to-get-there__title"}>
                    {translations.nearestMRTStation}
                  </div>
                  <div className={"how-to-get-there__subtitle"}>
                    {propertyDetail?.how_to_get_there?.nearest_mrt_station
                      ? propertyDetail?.how_to_get_there?.nearest_mrt_station
                      : translations.notAvailable}
                  </div>
                </div>
              </div>
              <div className={"how-to-get-there__item"}>
                <img src={PUBLIC_URL + "/assets/icons/icon-building.svg"} />
                <div>
                  <div className={"how-to-get-there__title"}>
                    {translations.frontEntranceTheSpace}
                  </div>
                  <div className={"how-to-get-there__subtitle"}>
                    {propertyDetail?.how_to_get_there
                      ?.front_endtrance_of_the_space
                      ? propertyDetail?.how_to_get_there
                          ?.front_endtrance_of_the_space
                      : translations.notAvailable}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {!editMode && (
          <EditActions
            editMode={editMode}
            setEditMode={setEditMode}
            setShowGuide={setShowGuide}
          />
        )}
      </div>
      <SizeBox height={"53px"} />
    </div>
  );
};

export default PropertyHowToGetThere;
