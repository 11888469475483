import ButtonCustom from "../../../../../../../components/Button";
import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../../../locales/Translation";

type Props = {
  editMode: boolean;
  setEditMode: any;
  showSave?: boolean;
  submit?: any;
  loading?: any;
  setShowGuide?: any;
};

const EditActions = ({
  editMode,
  setEditMode,
  showSave = true,
  submit,
  setShowGuide,
  loading = false,
}: Props) => {
  const { translations } = useContext(LocalizationContext);

  if (editMode && showSave) {
    return (
      <div className={"edit-cta-wrapper"}>
        <ButtonCustom
          onClick={() => setEditMode(false)}
          className={"outline-primary"}
          fontSize={"12px"}
          height={"37px"}
        >
          <>{translations.cancel}</>
        </ButtonCustom>
        <ButtonCustom
          loading={loading}
          onClick={submit}
          className={"primary-alt"}
          fontSize={"12px"}
          height={"37px"}
        >
          <>{translations.save}</>
        </ButtonCustom>
      </div>
    );
  } else {
    return (
      <ButtonCustom
        onClick={() => {
          setEditMode(true);
          if (setShowGuide) {
            setShowGuide(false);
          }
        }}
        className={"edit-link reverse"}
        padding={"0"}
        fontSize={"16px"}
        height={"37px"}
      >
        <>
          {translations.edit} <i className="uil uil-edit" />
        </>
      </ButtonCustom>
    );
  }
};

export default EditActions;
