import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../components/Button";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import SizeBox from "../../../../../components/SizeBox";
import ModalCenterTemplate from "../../../../App/views/ModalCenterTemplate";
import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import Label from "../../../../../components/Label";
import DropdownCustom from "../../../../../components/DropdownCustom";
import { LIST_MONTH, LIST_YEAR } from "../../../../App/configs/DateTimeConfigs";
import moment from "moment";
import { getListMarketAndCompanyService } from "../../../services/PropertyCompanyServices";
import CheckboxCustom from "../../../../../components/Checkbox";

const ModalGeneratePropertyCompanyInvoice = ({
  modalGenerate,
  setModalGenerate,
  generateInvoice,
  generateLoading,
}: any) => {
  const { translations } = useContext(LocalizationContext);
  const [calendar, setCalendar] = useState<any>({
    month: 1,
    year: 1,
  });
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [listCompanyRaw, setListCompanyRaw] = useState<any>([]);

  const [isBlankInvoice, setIsBlankInvoice] = useState<any>(false);

  useEffect(() => {
    const currentTime = moment();
    setCalendar({
      month: currentTime.month() + 1,
      year: currentTime.year(),
    });
  }, []);

  useEffect(() => {
    if (!modalGenerate) {
      return;
    }

    setIsBlankInvoice(false);

    if (listCompanyRaw.length) {
      return;
    }

    _fetchListAllCompany();
  }, [modalGenerate]);

  const _fetchListAllCompany = async () => {
    try {
      const payload = { getOnlyActive: 0 };
      const response = await getListMarketAndCompanyService(payload);
      const companies = response?.data?.companies;
      setListCompanyRaw(companies);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const decorateListCompany = () => {
    if (listCompanyRaw.length > 0) {
      const newListCompany = listCompanyRaw.map((company: any) => {
        return {
          key: company?.id,
          text: company?.name,
          value: company?.id,
        };
      });
      newListCompany.unshift({
        key: 0,
        text: "All Companies",
        value: 0,
      });
      return newListCompany;
    } else {
      return [];
    }
  };

  return (
    <ModalCenterTemplate
      trigger={null}
      onClose={() => setModalGenerate(false)}
      open={modalGenerate}
      size={"md"}
      modalClassName={"modal-center-v2"}
    >
      <Modal.Header>
        Generate Invoices
        <ButtonCustom
          height={"auto"}
          onClick={() => setModalGenerate(false)}
          className={"m-0 p-0 none-bg close-modal"}
        >
          <>
            {translations.settings.close}
            <img
              src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"}
              alt={""}
            />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Modal.Content>
        <div className={"d-flex w-100"}>
          <div className={"flex-1"}>
            <Label className={"sm mb-4px"} fontSize={"12px"} label={"Month"} />
            <DropdownCustom
              scrolling
              style={{
                border: "1px solid #DEE2E6",
                borderRadius: "4px",
              }}
              className={
                "dropdown-select-country w-100 justify-content-between"
              }
              selectUI={true}
              options={LIST_MONTH}
              value={String(calendar.month)}
              onChange={(event, data) =>
                setCalendar({ ...calendar, month: data.value })
              }
            />
          </div>
          <SizeBox width={"16px"} />
          <div className={"flex-1"}>
            <Label className={"sm mb-4px"} fontSize={"12px"} label={"Year"} />
            <DropdownCustom
              scrolling
              style={{
                border: "1px solid #DEE2E6",
                borderRadius: "4px",
              }}
              className={
                "dropdown-select-country w-100 justify-content-between"
              }
              selectUI={true}
              options={LIST_YEAR(2022, 2025)}
              value={String(calendar.year)}
              onChange={(event, data) =>
                setCalendar({ ...calendar, year: data.value })
              }
            />
          </div>
        </div>
        <SizeBox height={"16px"} />
        <div
          style={{
            fontWeight: 400,
            fontSize: 12,
            lineHeight: "14px",
            color: "#21252A",
          }}
        >
          Allow to generate invoice only for one company
        </div>
        <SizeBox height={"30px"} />
        <Label
          className={"sm mb-4px"}
          fontSize={"12px"}
          label={"Generate invoice for"}
        />
        <DropdownCustom
          style={{
            border: "1px solid #DEE2E6",
            borderRadius: "4px",
          }}
          className={"dropdown-select-country w-100 justify-content-between"}
          search
          onChange={(event: any, data: any) => {
            setSelectedCompany(data?.value);
          }}
          selectUI={true}
          options={decorateListCompany()}
          value={selectedCompany}
        />
        <SizeBox height={"16px"} />
        {selectedCompany ? (
          <div className={"grid-column-2"}>
            <CheckboxCustom
              className={"custom-checkbox checkbox-label-md"}
              label={"Generate blank invoice"}
              checked={isBlankInvoice}
              onChange={(event, data) => {
                setIsBlankInvoice(data.checked);
              }}
            />{" "}
          </div>
        ) : null}
      </Modal.Content>
      <Modal.Actions className={"d-flex justify-content-between"}>
        <ButtonCustom
          className={"flex-1 m-0"}
          height={"47px"}
          onClick={() => setModalGenerate(false)}
        >
          {translations.settings.cancel}
        </ButtonCustom>
        <SizeBox width={"16px"} />
        <ButtonCustom
          loading={generateLoading}
          type={"submit"}
          className={"primary flex-1 m-0"}
          height={"47px"}
          onClick={() =>
            generateInvoice(calendar, selectedCompany, isBlankInvoice)
          }
        >
          Generate
        </ButtonCustom>
      </Modal.Actions>
    </ModalCenterTemplate>
  );
};

export default ModalGeneratePropertyCompanyInvoice;
