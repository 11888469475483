import React, { useContext, useState } from "react";
import ButtonCustom from "../../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import { useDispatch } from "react-redux";

import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import { SWAL_DELETE_OPTION } from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { LocalizationContext } from "../../../../../../locales/Translation";
import {
  MARKETING_CAMPAIGN_DETAIL_LINK,
  MARKETING_CAMPAIGN_EDIT_LINK,
} from "../../../../../App/configs/ListRoutes";
import {
  deleteCampaignService,
  updateStatusCampaignService,
} from "../../../../services/MarketingServices";
import {
  campaignListRemoveItemAction,
  campaignListUpdateStatusAction,
} from "../../../../actions/MarketingActions";

type Props = {
  id: number;
  status: any;
};

const Action = ({ id, status }: Props) => {
  const dispatch = useDispatch();
  const { translations } = useContext(LocalizationContext);
  const [disabled, setDisabled] = useState(false);

  const _onEditUser = async () => {
    window.open(
      MARKETING_CAMPAIGN_EDIT_LINK.replaceAll(":id", String(id)),
      "_blank"
    );
  };

  const _onDetail = () => {
    window.open(
      MARKETING_CAMPAIGN_DETAIL_LINK.replaceAll(":id", String(id)),
      "_blank"
    );
  };

  const _onUnPublish = async () => {
    try {
      const icon = null;
      const title = isUnPublish()
        ? "Are you sure want to publish this campaign?"
        : "Are you sure want to un-publish this campaign?";
      const confirmText = isUnPublish() ? "Publish" : "Un-publish";
      const cancelText = translations.cancel;
      const confirmClass = isUnPublish() ? "primary" : "danger";
      const options = SWAL_DELETE_OPTION({
        title,
        icon,
        confirmText,
        confirmClass,
        cancelText,
      });
      SweetAlertCommon({
        options,
        confirmCallback: async () => {
          setDisabled(true);
          try {
            if (isUnPublish()) {
              await updateStatusCampaignService(id, { status: 2 });
              toastSuccess("Publish successfully");
            } else {
              await updateStatusCampaignService(id, { status: 0 });
              toastSuccess("Un-publish successfully");
            }
            dispatch(campaignListUpdateStatusAction(id));
          } catch (response: any) {
            toastError(response?.status?.message);
          } finally {
            setDisabled(false);
          }
        },
        cancelCallback: () => null,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const _onDelete = async () => {
    const iconHtml = (
      <img
        src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
        className={"swal2-icon__error"}
      />
    );
    const title = "Are you sure want to delete this campaign?";
    const confirmText = translations.delete;
    const cancelText = translations.cancel;
    const options = SWAL_DELETE_OPTION({
      title,
      iconHtml,
      confirmText,
      cancelText,
    });
    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setDisabled(true);
        try {
          await deleteCampaignService(id);
          dispatch(campaignListRemoveItemAction(id));
          toastSuccess("Delete successfully!");
        } catch (response: any) {
          toastError(response?.status?.message);
        } finally {
          setDisabled(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  const isUnPublish = () => {
    return parseInt(status) === 0;
  };

  return (
    <DropdownCustom
      disabled={disabled}
      className={"table-action-dropdown"}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={_onDetail}
          text={translations.viewDetails}
          icon={<img src={PUBLIC_URL + "/assets/icons/icon-message.svg"} />}
        />
        <Dropdown.Item
          onClick={_onEditUser}
          text={"Edit Campaign"}
          icon={
            <i className="uil uil-edit icon" style={{ fontSize: "16px" }} />
          }
        />
        <Dropdown.Item
          onClick={_onUnPublish}
          text={isUnPublish() ? "Publish Campaign" : "Un-publish Campaign"}
          icon={
            <img src={PUBLIC_URL + "/assets/icons/icon-toggle-slider.svg"} />
          }
        />
        <Dropdown.Item
          onClick={_onDelete}
          text={"Delete Campaign"}
          icon={
            <i
              className="uil uil-trash-alt icon"
              style={{ fontSize: "16px" }}
            />
          }
        />
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default Action;
