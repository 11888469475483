import React, { SyntheticEvent, useContext } from "react";
import { LocalizationContext } from "../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import {
  EMPLOYEE_ACTIVE_STATUS,
  EMPLOYEE_DELETE_STATUS,
  EMPLOYEE_PENDING_STATUS,
} from "../../configs/Constants";
import DropdownWithCheckboxMenu from "../../../../components/DropdownWithCheckboxMenu";
import { PUBLIC_URL } from "../../../App/configs/Constants";
import { teamMemberFilterSelector } from "../../selectors/PartnerTeamMemberSelectors";
import { teamMemberFilterAction } from "../../actions/PartnerTeamActions";

const FilterStatus = () => {
  const { translations } = useContext(LocalizationContext);

  const dispatch = useDispatch();
  const teamMemberFilter = useSelector(teamMemberFilterSelector);

  const employeeFilterTemp = [
    {
      id: EMPLOYEE_ACTIVE_STATUS,
      name:
        translations.accountStatusList[EMPLOYEE_ACTIVE_STATUS] +
        " " +
        translations.accounts,
    },
    {
      id: EMPLOYEE_DELETE_STATUS,
      name:
        translations.accountStatusList[EMPLOYEE_DELETE_STATUS] +
        " " +
        translations.accounts,
    },
    {
      id: EMPLOYEE_PENDING_STATUS,
      name:
        translations.accountStatusList[EMPLOYEE_PENDING_STATUS] +
        " " +
        translations.accounts,
    },
  ];

  const _onChangeStatus = (e: SyntheticEvent, { value }: any) => {
    const statuses = [...teamMemberFilter.statuses];
    const isExist = statuses.includes(value);

    if (isExist) {
      dispatch(
        teamMemberFilterAction({
          statuses: statuses.filter((id: number) => id !== value),
        })
      );
      return;
    }

    dispatch(
      teamMemberFilterAction({
        statuses: [...statuses, value],
      })
    );
  };

  return (
    <div className={"dropdown-filter"}>
      <DropdownWithCheckboxMenu
        onChange={_onChangeStatus}
        listCheckBox={employeeFilterTemp.map((filter: any) => {
          if (teamMemberFilter.statuses.includes(filter.id)) {
            filter.checked = true;
          } else {
            filter.checked = false;
          }
          return filter;
        })}
        label={translations.filters}
        icon={
          <img
            alt={"Deskimo"}
            src={PUBLIC_URL + "/assets/icons/icon-filter.svg"}
          />
        }
      />
    </div>
  );
};

export default FilterStatus;
