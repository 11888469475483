import TableCustom from "../../../../../../components/Table";
import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useSelector } from "react-redux";
// import Action from "./Action.tsx";
import { configMarketListSelector } from "../../../../selectors/ConfigSelectors";
import { ConfigMarketDataType } from "../../../../configs/DeclareTypes";
import Action from "./Action";

type Props = {
  loading: boolean;
  onChangePagination: any;
};

const MarketTable = ({ loading, onChangePagination }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const marketList = useSelector(configMarketListSelector);

  const columns = [
    "Market Code",
    "Market Name",
    "Market Time Zone",
    "Country Code",
    "Currency",
    "VAT/GST Rate",
    translations.status,
    translations.action,
  ];

  const _formatInputData = () => {
    if (!marketList?.item) {
      return [];
    }
    return marketList?.item?.map(
      ({
        market_id,
        tax_name,
        status_id,
        tax_value,
        market_code,
        market_name,
        currency,
        // status_name,
        timezone,
        country_code,
      }: ConfigMarketDataType) => {
        const tax_details = `${tax_name} (${tax_value}%)`;
        return {
          market_code,
          market_name,
          timezone,
          country_code,
          currency,
          tax_details,
          status: status_id ? translations.active : translations.inActive,
          action: <Action status={status_id} id={market_id} />,
        };
      }
    );
  };

  return (
    <div
      className={"employee-table"}
      style={marketList?.item?.length < 3 ? { paddingBottom: 100 } : {}}
    >
      <TableCustom
        loading={loading}
        showTotal
        tableData={marketList ? _formatInputData() : []}
        columns={columns}
        currentPage={marketList?.pagination?.page}
        totalItems={marketList?.pagination?.total}
        unit={""}
        onChangePagination={onChangePagination}
      />
    </div>
  );
};

export default MarketTable;
