import classNames from "classnames";

type Props = {
  height?: string | number;
  width?: string | number;
  extraClass?: string;
  [x: string]: any;
};

const SizeBox = ({ height, width = "100%", extraClass, ...props }: Props) => {
  return (
    <div
      className={classNames(extraClass)}
      style={{
        height,
        width,
        display: "flex",
        minWidth: width,
        minHeight: height,
      }}
      {...props}
    />
  );
};

export default SizeBox;
