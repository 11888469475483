import React, { useContext } from "react";
import ButtonCustom from "../../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { modalEditEmployeeAction } from "../../../../actions/EmployeeActions";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import { useDispatch } from "react-redux";
import {
  deskimoUserDetailThunk,
  deskimoUsersThunk,
} from "../../../../thunks/EmployeeThunks";
import {
  deskimoUserStatusService,
  deskimoDeleteUserService,
  sendOTPToSlackService,
  resetOTPLimitService,
  deskimoUserBlackListService,
} from "../../../../services/EmployeeServices";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import {
  SWAL_CONFIRM_OPTION_V2,
  SWAL_DELETE_OPTION,
} from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useNavigate } from "react-router-dom";
import {
  DESKIMO_USER_DETAIL_LINK,
  DESKIMO_USERS_LINK,
} from "../../../../../App/configs/ListRoutes";
import {
  onLogInAsAccount,
  onLoginAsWMSUser,
} from "../../../../services/UserServices";
import { isGlobalIndicatorAction } from "../../../../../App/actions/AppActions";
import {
  MOVE_TO_BLACK_LIST,
  MOVE_TO_WHITE_LIST,
} from "../../../../configs/Constants";

type Props = {
  userId: number;
  setLoading?: any;
  statusId: number;
  loginOnBehalf: boolean;
  loginAsWMS: string;
  loading: boolean;
  isBlackList?: boolean;
};

const Action = ({
  userId,
  setLoading,
  statusId,
  loginOnBehalf,
  loginAsWMS,
  loading,
  isBlackList,
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { translations } = useContext(LocalizationContext);

  const _onEditUser = async () => {
    try {
      await dispatch<any>(deskimoUserDetailThunk(String(userId)));
      dispatch(modalEditEmployeeAction(true));
    } catch (e: any) {
      toastError(e?.status?.message);
      console.log(e);
    }
  };

  const _reload = async () => {
    try {
      setLoading(true);
      await dispatch<any>(deskimoUsersThunk());
      setLoading(false);
    } catch (error: any) {
      toastError(error?.status?.message);
      console.log(error);
    }
  };

  const _onDetail = () => {
    navigate(DESKIMO_USER_DETAIL_LINK.replaceAll(":id", String(userId)));
  };

  const _onDeactive = async () => {
    try {
      const icon = null;
      const title = isDeactiveUser()
        ? translations.areYouSureYouWantToActivateThisUser
        : translations.areYouSureYouWantToDeactivateThisUser;
      const confirmText = isDeactiveUser()
        ? translations.activate
        : translations.deactivate;
      const cancelText = translations.cancel;
      const confirmClass = isDeactiveUser() ? "primary" : "danger";
      const options = SWAL_DELETE_OPTION({
        title,
        icon,
        confirmText,
        confirmClass,
        cancelText,
      });
      SweetAlertCommon({
        options,
        confirmCallback: async () => {
          setLoading(true);
          try {
            const payload = {
              id: userId,
              status: 0,
            };
            if (isDeactiveUser()) {
              payload.status = 1;
            }
            await deskimoUserStatusService(payload);
            await _reload();
          } catch (response: any) {
            toastError(response?.status?.message);
          } finally {
            setLoading(false);
          }
        },
        cancelCallback: () => null,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const _onBlackList = async () => {
    try {
      const icon = null;
      const title = isBlackList
        ? translations.areYouSureYouWantToMoveThisUserToWhiteList
        : translations.areYouSureYouWantToMoveThisUserToBlackList;
      const confirmText = translations.confirm;
      const cancelText = translations.cancel;
      const confirmClass = isBlackList ? "primary" : "danger";
      const options = SWAL_DELETE_OPTION({
        title,
        icon,
        confirmText,
        confirmClass,
        cancelText,
      });
      SweetAlertCommon({
        options,
        confirmCallback: async () => {
          setLoading(true);
          try {
            await deskimoUserBlackListService(
              userId,
              isBlackList ? MOVE_TO_WHITE_LIST : MOVE_TO_BLACK_LIST
            );
            toastSuccess(
              `The user has been successfully moved to the ${
                isBlackList ? "whitelist" : "blacklist"
              }`
            );
            await _reload();
          } catch (response: any) {
            toastError(response?.status?.message);
          } finally {
            setLoading(false);
          }
        },
        cancelCallback: () => null,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const _onDelete = async () => {
    const iconHtml = (
      <img
        src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
        className={"swal2-icon__error"}
      />
    );
    const title = translations.areYouSureWantToDeleteThisAccount;
    const confirmText = translations.delete;
    const cancelText = translations.cancel;
    const options = SWAL_DELETE_OPTION({
      title,
      iconHtml,
      confirmText,
      cancelText,
    });
    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          setLoading(true);
          await deskimoDeleteUserService(userId);
          toastSuccess("Deleted Successfully!");
          await _reload();
        } catch (response: any) {
          toastError(response?.status?.message);
        } finally {
          setLoading(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  const isDeactiveUser = () => {
    return statusId === 0;
  };

  const _onSendOTPToSlack = () => {
    const options = SWAL_CONFIRM_OPTION_V2({
      title: "Are you sure want to\nsend OTP to Slack?",
      confirmText: "Send",
      cancelText: "Cancel",
      showCancelButton: true,
    });
    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          dispatch(isGlobalIndicatorAction(true));
          await sendOTPToSlackService(userId);
          toastSuccess("Sent Successfully!");
        } catch (response: any) {
          toastError(response?.status?.message);
        } finally {
          dispatch(isGlobalIndicatorAction(false));
        }
      },
      cancelCallback: () => null,
    });
  };

  const _onResetOTPLimitation = () => {
    const options = SWAL_CONFIRM_OPTION_V2({
      title: "Are you sure you want to\nreset the OTP limitation?",
      confirmText: "Reset",
      cancelText: "Cancel",
      showCancelButton: true,
    });
    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setLoading(true);
        try {
          dispatch(isGlobalIndicatorAction(true));
          await resetOTPLimitService(userId);
          toastSuccess("Reset Successfully!");
        } catch (response: any) {
          toastError(response?.status?.message);
        } finally {
          dispatch(isGlobalIndicatorAction(false));
          setLoading(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  return (
    <DropdownCustom
      disabled={loading}
      className={"table-action-dropdown"}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={_onDetail}
          text={translations.viewDetails}
          icon={<img src={PUBLIC_URL + "/assets/icons/icon-message.svg"} />}
        />
        <Dropdown.Item
          onClick={_onEditUser}
          text={translations.editUser}
          icon={
            <i className="uil uil-edit icon" style={{ fontSize: "16px" }} />
          }
        />

        <Dropdown.Item
          onClick={_onSendOTPToSlack}
          text={"Send OTP to Slack"}
          icon={<i className="uil uil-message icon" />}
        />

        <Dropdown.Item
          onClick={_onResetOTPLimitation}
          text={"Reset OTP limit"}
          icon={<i className="uil uil-refresh icon" />}
        />
        {/*<Dropdown.Item*/}
        {/*  onClick={() => console.log("Reinvite")}*/}
        {/*  text={translations.reInviteUser}*/}
        {/*  icon={*/}
        {/*    <i*/}
        {/*      className="uil uil-plus-circle icon"*/}
        {/*      style={{ fontSize: "16px" }}*/}
        {/*    />*/}
        {/*  }*/}
        {/*/>*/}
        <Dropdown.Item
          onClick={_onDeactive}
          text={
            isDeactiveUser()
              ? translations.activateUser
              : translations.deactivateUser
          }
          icon={
            isDeactiveUser() ? (
              <i
                className="uil uil-toggle-on icon"
                style={{ fontSize: "16px" }}
              />
            ) : (
              <i
                className="uil uil-toggle-off icon"
                style={{ fontSize: "16px" }}
              />
            )
          }
        />

        <Dropdown.Item
          onClick={_onBlackList}
          text={
            isBlackList
              ? translations.moveToWhiteList
              : translations.moveToBlackList
          }
          icon={
            isBlackList ? (
              <i
                className="uil uil-check-circle icon"
                style={{ fontSize: "16px" }}
              />
            ) : (
              <i
                className="uil uil-times-circle icon"
                style={{ fontSize: "16px" }}
              />
            )
          }
        />

        <Dropdown.Item
          onClick={_onDelete}
          text={translations.deleteUser}
          icon={
            <i
              className="uil uil-trash-alt icon"
              style={{ fontSize: "16px" }}
            />
          }
        />
        {loginOnBehalf ? (
          <Dropdown.Item
            onClick={() => onLogInAsAccount(userId, DESKIMO_USERS_LINK)}
            text={translations.loginUserAccount}
            icon={
              <img src={PUBLIC_URL + "/assets/icons/icon-log-out-circle.svg"} />
            }
          />
        ) : null}

        {loginAsWMS && loginAsWMS?.length > 0 ? (
          <Dropdown.Item
            onClick={() => onLoginAsWMSUser(loginAsWMS)}
            text={translations.loginWMSAccount}
            icon={
              <img src={PUBLIC_URL + "/assets/icons/icon-log-out-circle.svg"} />
            }
          />
        ) : null}
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default Action;
