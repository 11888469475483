import ButtonCustom from "../../../../../../../components/Button";
import React, { useState, useContext, useEffect } from "react";
import { LocalizationContext } from "../../../../../../../locales/Translation";
import SizeBox from "../../../../../../../components/SizeBox";
import EditActions from "./EditActions";
import { useDispatch, useSelector } from "react-redux";
import { propertyDetailSelector } from "../../../../../selectors/PropertySelector";
import ScheduleTable from "./ScheduleTable";
import ScheduleList from "./ScheduleList";
import { OPERATION_HOUR_INIT } from "../../../../../../App/configs/DateTimeConfigs";
import {
  onInheritScheduleAction,
  updatePropertyService,
} from "../../../../../services/PropertyServices";
import { getPropertyDetailThunk } from "../../../../../thunks/PropertyThunks";
import { toastError } from "../../../../../../../components/Toast";
import { useParams } from "react-router-dom";

const PropertySchedule = ({ setLoading, loading }: any) => {
  const [tabIndex, setTabIndex] = useState(1);
  const { translations } = useContext(LocalizationContext);
  const [editMode, setEditMode] = useState(false);
  const propertyDetail = useSelector(propertyDetailSelector);
  const [operationHour, setOperationHour] = useState<any>(OPERATION_HOUR_INIT);
  const [closeOnHoliday, setCloseOnHoliday] = useState<boolean>(false);
  const [communityInherit, setCommunityInherit] = useState<boolean>(false);
  const [airConInherit, setAirConInherit] = useState<boolean>(false);
  const { id } = useParams();
  const dispatch = useDispatch();

  const _onChangeSchedule = (value: any) => {
    if (
      (airConInherit && value === "aircon") ||
      (communityInherit && value === "community")
    ) {
      return;
    }

    setOperationHour((prevState: any) => {
      //DeepClone
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.forEach((schedule: any) => {
        if (schedule.type === value.type) {
          schedule.week_days.forEach((day: any) => {
            if (day.week_day === value.week_day) {
              if (value.open_type) {
                day.open_type = parseInt(value.open_type);
              }
              if (value.from_time) {
                day.from_time = value.from_time;
              }

              if (value.to_time) {
                day.to_time = value.to_time;
              }
            }
          });
        }
      });

      return newState;
    });

    if (airConInherit) {
      onInheritScheduleAction("aircon", setOperationHour);
    }

    if (communityInherit) {
      onInheritScheduleAction("community", setOperationHour);
    }
  };

  const _handleSave = async () => {
    const payload = {
      schedule: {
        operation_hour: operationHour,
        close_on_holiday: closeOnHoliday,
        community_inherit: communityInherit,
        aircon_inherit: airConInherit,
      },
      property_id: id,
    };
    setLoading(true);
    try {
      await updatePropertyService(payload);
      await dispatch<any>(getPropertyDetailThunk(id ? id : ""));
      setEditMode(false);
    } catch (response: any) {
      console.log(response);
      toastError(response?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const schedule = propertyDetail?.schedule;

    if (!schedule) {
      setOperationHour(OPERATION_HOUR_INIT);
      setCloseOnHoliday(false);
      setAirConInherit(false);
      setCommunityInherit(false);
      return;
    }

    setOperationHour(schedule.operation_hour);
    setCloseOnHoliday(schedule.close_on_holiday);
    setAirConInherit(schedule.aircon_inherit);
    setCommunityInherit(schedule.community_inherit);
  }, [propertyDetail]);

  return (
    <>
      <div
        className={"flex-1 pr-38 border-right-1 property-schedule__wrapper"}
        style={{ marginTop: 17, marginBottom: 36 }}
      >
        <div
          className={
            "d-flex justify-content-between flex-1 " +
            (editMode ? "" : "align-items-center")
          }
        >
          <div className={"section-title"} style={{ lineHeight: "37px" }}>
            {translations.schedule}
          </div>

          <EditActions
            editMode={editMode}
            setEditMode={setEditMode}
            submit={_handleSave}
            loading={loading}
          />
        </div>
        <SizeBox height={"25px"} className={"d-sm-none"} />

        {editMode ? (
          <>
            <ScheduleList
              key={"operation-area1"}
              onChangeSchedule={_onChangeSchedule}
              hourGroupData={operationHour[0]}
              isShowHoliday
              closeOnHoliday={closeOnHoliday}
              setCloseOnHoliday={setCloseOnHoliday}
            />
            <ScheduleList
              key={"operation-area2"}
              onChangeSchedule={_onChangeSchedule}
              hourGroupData={operationHour[1]}
              isShowInherit
              isInherit={communityInherit}
              onChangeInherit={(value: boolean) => {
                setCommunityInherit(value);
                if (value) {
                  onInheritScheduleAction("community", setOperationHour);
                }
              }}
            />
            <ScheduleList
              key={"operation-area3"}
              onChangeSchedule={_onChangeSchedule}
              hourGroupData={operationHour[2]}
              isShowInherit
              isInherit={airConInherit}
              onChangeInherit={(value: boolean) => {
                setAirConInherit(value);
                if (value) {
                  onInheritScheduleAction("aircon", setOperationHour);
                }
              }}
            />
          </>
        ) : (
          <div>
            <div className={"button-group yellow"}>
              <ButtonCustom
                onClick={() => setTabIndex(1)}
                height={"56px"}
                className={tabIndex === 1 ? "active" : ""}
              >
                {translations.propertyPage.operatingHours}
              </ButtonCustom>
              <ButtonCustom
                onClick={() => setTabIndex(2)}
                height={"56px"}
                className={tabIndex === 2 ? "active" : ""}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      translations.propertyPage.communityTeamOperatingHours,
                  }}
                />
              </ButtonCustom>
              <ButtonCustom
                onClick={() => setTabIndex(3)}
                height={"56px"}
                className={tabIndex === 3 ? "active" : ""}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: translations.propertyPage.airconOperatingHours,
                  }}
                />
              </ButtonCustom>
            </div>
            <div>
              <SizeBox height={"23px"} />
              {tabIndex === 1 ? (
                <ScheduleTable
                  schedule={
                    propertyDetail?.schedule?.operation_hour.filter(
                      (item: any) => item.type === "openHour"
                    )[0]
                  }
                />
              ) : null}
              {tabIndex === 2 ? (
                <ScheduleTable
                  schedule={
                    propertyDetail?.schedule?.operation_hour.filter(
                      (item: any) => item.type === "community"
                    )[0]
                  }
                />
              ) : null}
              {tabIndex === 3 ? (
                <ScheduleTable
                  schedule={
                    propertyDetail?.schedule?.operation_hour.filter(
                      (item: any) => item.type === "aircon"
                    )[0]
                  }
                />
              ) : null}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PropertySchedule;
