import { Pagination } from "semantic-ui-react";
import React from "react";
import "./style.scss";
import { PUBLIC_URL } from "../../modules/App/configs/Constants";

type Props = {
  defaultPage?: string | number;
  totalPage?: string | number;
  onChangePagination: any;
};

const PaginationCustom = ({
  defaultPage = 1,
  totalPage = 1,
  onChangePagination,
}: Props) => {
  return (
    <Pagination
      onPageChange={onChangePagination}
      activePage={defaultPage}
      ellipsisItem={undefined}
      firstItem={null}
      lastItem={null}
      prevItem={{
        content: (
          <img src={PUBLIC_URL + "/assets/icons/icon-chevron-left.svg"} />
        ),
        icon: true,
      }}
      nextItem={{
        content: (
          <img src={PUBLIC_URL + "/assets/icons/icon-chevron-right.svg"} />
        ),
        icon: true,
      }}
      totalPages={totalPage}
    />
  );
};

export default PaginationCustom;
