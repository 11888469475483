import JSONPretty from "react-json-pretty";
import React, { useEffect, useRef, useState } from "react";

const JSONCell = ({ title, content, setModalJSON, maxHeight = 84 }: any) => {
  const jsonRef = useRef(null);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const div: any = jsonRef.current;
    if (div) {
      const height = div?.offsetHeight;
      if (height >= maxHeight) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    }
  }, [jsonRef]);

  return (
    <div
      ref={jsonRef}
      className={"json-cell " + (collapsed ? "json-collapsed" : "")}
    >
      <JSONPretty
        style={{ maxHeight: maxHeight, overflow: "hidden" }}
        onClick={() => {
          setModalJSON({
            open: true,
            data: {
              title: title,
              content: content ? content : "",
            },
          });
        }}
        json={content ? content : ""}
      />

      <div
        onClick={() => {
          setModalJSON({
            open: true,
            data: {
              title: title,
              content: content ? content : "",
            },
          });
        }}
        className={"json-truncate-dots"}
      >
        <span>View More</span>
        <i className="uil uil-plus-circle"></i>
      </div>
    </div>
  );
};

export default JSONCell;
