import React, { useContext } from "react";
import { AmenityType } from "../../../configs/DeclareType";
import { LocalizationContext } from "../../../../../locales/Translation";

type Props = {
  amenities: AmenityType[];
};

const Amenities = ({ amenities }: Props) => {
  const { translations } = useContext(LocalizationContext);

  if (!amenities?.length) {
    return null;
  }

  return (
    <div className={"book-detail__section"}>
      <div className={"book-detail__section-title"}>
        {translations.propertyAmenities.amenities}
      </div>
      <div className={"book-detail__amenities"}>
        {amenities.map(({ name, icon_url, is_free }) => {
          return (
            <div key={name} className={"book-detail__amenity"}>
              <div className="box">
                <img src={icon_url} alt={name} />
              </div>
              {is_free ? null : (
                <div className="badge">
                  <i className="uil uil-dollar-sign-alt" />
                </div>
              )}
              <div className="text">{name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Amenities;
