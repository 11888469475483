import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useSelector } from "react-redux";
import { deskimoPropertyAmenityListSelector } from "../../../../selectors/PropertySelector";
import TableCustom from "../../../../../../components/Table";
import Action from "./Action";
import moment from "moment";
import { FORMAT_DATE_TIME_VARIANT_3 } from "../../../../../App/configs/DateTimeConfigs";
import {
  AMENITY_DESK_TYPE,
  AMENITY_ROOM_TYPE,
} from "../../../../configs/Constants";

type Props = {
  loading: boolean;
  onChangeSortCol: any;
  onChangePagination: any;
  filter: any;
};

const DeskimoPropertiesAmenityListTable = ({
  loading,
  onChangeSortCol,
  onChangePagination,
  filter,
}: Props) => {
  const { translations } = useContext(LocalizationContext);
  const amenityList = useSelector(deskimoPropertyAmenityListSelector);
  const [amenityTable, setAmenityTable] = useState([]);

  const columns = [
    {
      key: "name",
      col: translations.propertyAmenities.amenityNameColumnHeader,
    },
    {
      key: "updated_at",
      col: translations.propertyAmenities.updatedDateColumnHeader,
    },
    { key: "icon", col: translations.propertyAmenities.iconColumnHeader },
    { key: "type", col: translations.typeOfAmenity },
    { key: null, col: translations.action },
  ];

  useEffect(() => {
    if (amenityList && amenityList.amenities) {
      const data = amenityList.amenities.map((amenity: any) => {
        return {
          col1: amenity?.name,
          col2: moment
            .unix(amenity?.updated_at)
            .utc()
            .format(FORMAT_DATE_TIME_VARIANT_3),
          col3: (
            <img
              alt="amenity_icon"
              width={18}
              height={18}
              src={amenity?.image_url}
            />
          ),
          col4:
            amenity?.type.length === 2
              ? translations.both
              : amenity?.type.includes(AMENITY_DESK_TYPE)
              ? translations.hotDesks
              : amenity?.type.includes(AMENITY_ROOM_TYPE)
              ? translations.propertyPage.meetingRooms
              : "",
          col5: (
            <Action
              amenityId={amenity?.id}
              loading
              roomAmenities={amenity?.room_amenities}
              deskAmenities={amenity?.desk_amenities}
            />
          ),
        };
      });
      setAmenityTable(data as never);
    } else {
      setAmenityTable([]);
    }
  }, [amenityList]);

  return (
    <div>
      <TableCustom
        sortTable
        noHeader={false}
        loading={loading}
        showTotal
        tableData={amenityTable}
        onChangeSortCol={onChangeSortCol}
        columns={columns}
        currentPage={filter?.page}
        itemPerPage={amenityList?.pagination?.limit}
        totalItems={amenityList?.pagination?.total}
        sortBy={filter.sort_by}
        orderBy={filter.order_by}
        unit={"amenities"}
        onChangePagination={onChangePagination}
      />
    </div>
  );
};

export default DeskimoPropertiesAmenityListTable;
