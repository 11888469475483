import React, { useState, useEffect, useContext } from "react";
import { modalAssignBookingSubmittedAction } from "../../../actions/BookActions";
import classNames from "classnames";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../components/Button";
import ModalCenterTemplate from "../../../../App/views/ModalCenterTemplate";
import { useDispatch, useSelector } from "react-redux";
import {
  assignBookingIdSelector,
  modalAssignBookingSubmittedSelector,
} from "../../../selectors/BookSelectors";
import { assignBookingDetailService } from "../../../services/BookServices";
import moment from "moment-timezone";
import { FORMAT_DATE_TIME_VARIANT_6 } from "../../../../App/configs/DateTimeConfigs";
import { toastError } from "../../../../../components/Toast";
import { useNavigate } from "react-router-dom";
import { DESK_BOOKING_LINK } from "../../../../App/configs/ListRoutes";
import { PAYMENT_METHOD_PERSONAL_TYPE } from "../../../configs/Constants";
import { LocalizationContext } from "../../../../../locales/Translation";
import SizeBox from "../../../../../components/SizeBox";
import Divide from "../../../../../components/Divide";
import ParkingRequest from "../../Components/ModalBookingSubmittedDesk/ParkingRequest";
import { DESK_BOOKING } from "../../../../Dashboard/configs/Constants";

const ModalAssignBookingSubmitted = () => {
  const { translations } = useContext(LocalizationContext);

  const dispatch = useDispatch();
  const modalAssignBooking = useSelector(modalAssignBookingSubmittedSelector);
  const assignBookingId = useSelector(assignBookingIdSelector);
  const [bookingDetail, setBookingDetail] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!assignBookingId) {
        return;
      }
      await fetchBookingDetailInfo();
    })();
  }, [assignBookingId]);

  const fetchBookingDetailInfo = async () => {
    try {
      const { data } = await assignBookingDetailService(assignBookingId);
      setBookingDetail(data);
    } catch (error: any) {
      toastError(error?.status?.message);
    }
  };

  const _onClose = () => {
    dispatch(modalAssignBookingSubmittedAction({ open: false }));
    setBookingDetail(null);
  };

  return (
    <ModalCenterTemplate
      trigger={null}
      onClose={_onClose}
      modalClassName={classNames("modal-center-v2 modal-booking-success")}
      open={modalAssignBooking?.open}
    >
      <Modal.Header>
        <i className="uil uil-check-circle" />
        Booking Submitted
      </Modal.Header>
      <Modal.Content>
        <div>
          <div className={"modal-booking-success__thumbnail"}>
            <img
              src={bookingDetail?.detail_booking[0]?.property_image_url}
              alt=""
            />
          </div>
          <div className={"d-flex flex-column gap-20"} style={{ padding: 24 }}>
            <div className={"modal-booking-success__thankyou"}>
              <div className="title">Thank you for booking with us 1</div>
              <div className="content">
                Your booking has been successfully submitted. Workspaces
                normally confirm reservations within 60 min during working
                hours. We will notify you via email and in the app once your
                reservation has been confirmed.
              </div>
            </div>
            <div className={"modal-booking-success__info"}>
              <div className={"title"}>
                <span className={"title-content"}>Hot Desk</span>
                <div className={"status-wrapper"}>
                  <span className={"status"}>
                    {
                      translations.roomBookingStatus[
                        bookingDetail?.detail_booking[0]?.status_id
                      ]
                    }
                  </span>
                </div>
              </div>
              <div className={"item"}>
                <i className="uil uil-building" />
                {bookingDetail?.detail_booking[0]?.company_name}
              </div>
              <div className={"item"}>
                <i className="uil uil-map" />
                {bookingDetail?.detail_booking[0]?.property_address}
              </div>
              <div className={"item"}>
                <i className="uil uil-clock" />
                {
                  bookingDetail?.detail_booking[0]
                    ?.formatted_booking_start_end_time
                }
              </div>
            </div>

            {bookingDetail?.detail_booking[0]?.allow_parking_request ? (
              <>
                <Divide height={"1px"} type={"horizontal"} margin={"0"} />
                <ParkingRequest
                  parkingRequest={
                    bookingDetail?.detail_booking[0]?.parking_request
                  }
                  onRefresh={fetchBookingDetailInfo}
                  bookingId={bookingDetail?.detail_booking[0]?.id}
                  bookingType={DESK_BOOKING}
                />
              </>
            ) : null}

            <Divide height={"1px"} type={"horizontal"} margin={"0"} />
            <div className={"modal-booking-success__detail p-0"}>
              <div className={"title"}>Booking Details</div>
              <div className={"select-item"}>
                <div className={"text"}>Booking Reference</div>
                <div className={"value"}>
                  {bookingDetail?.detail_booking[0]?.booking_code}
                </div>
              </div>
              <div className={"select-item"}>
                <div className={"text"}>No. of Desks</div>
                <div className={"value"}>{assignBookingId?.bookingNo}</div>
              </div>
              <div className={"select-item"}>
                <div className={"text"}>Date</div>
                <div className={"value"}>
                  {bookingDetail?.detail_booking[0]?.formatted_booking_date}
                </div>
              </div>
              <div className={"select-item"}>
                <div className={"text"}>Duration</div>
                <div className={"value"}>
                  {bookingDetail?.detail_booking[0]?.duration}
                </div>
              </div>
            </div>
            <Divide height={"1px"} type={"horizontal"} margin={"0"} />
            <div className={"modal-booking-success__detail p-0"}>
              <div className={"title"}>Payment</div>
              <div className={"select-item"}>
                <div className={"text"}>
                  {bookingDetail?.detail_booking[0]?.payment_method_type ===
                  PAYMENT_METHOD_PERSONAL_TYPE
                    ? "Personal"
                    : "Business"}
                </div>
                <div className={"value"}>
                  {bookingDetail?.detail_booking[0]?.payment_method}
                </div>
              </div>
              {bookingDetail?.detail_booking[0]?.payment_date ? (
                <div className={"select-item"}>
                  <div className={"text"}>Time</div>
                  <div className={"value"}>
                    {moment
                      .unix(bookingDetail?.detail_booking[0]?.payment_date)
                      .format(FORMAT_DATE_TIME_VARIANT_6)}
                  </div>
                </div>
              ) : null}
            </div>

            {bookingDetail?.detail_booking[0]?.vouchers &&
            bookingDetail?.detail_booking[0]?.vouchers[0] ? (
              <>
                <Divide height={"1px"} type={"horizontal"} margin={"0"} />

                <div
                  className={
                    "modal-booking-success__detail d-flex flex-column gap-24 p-0"
                  }
                >
                  {bookingDetail?.detail_booking[0]?.vouchers[0]?.code ? (
                    <div className={"select-item mb-0"}>
                      <div className={"text title mb-0"}>Applied Voucher</div>
                      <div className={"value"}>
                        <div className={"voucher-chip"}>
                          {bookingDetail?.detail_booking[0]?.vouchers[0]?.code}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className={"select-item mb-0"}>
                    <div className={"text title mb-0"}>Subtotal</div>
                    <div className={"value"} style={{ fontSize: 20 }}>
                      {bookingDetail?.detail_booking[0]?.currency}{" "}
                      {bookingDetail?.detail_booking[0]?.price_before_discount}
                    </div>
                  </div>
                  <div className={"select-item mb-0"}>
                    <div className={"text title mb-0"}>Discount</div>
                    <div className={"value"} style={{ fontSize: 20 }}>
                      - {bookingDetail?.detail_booking[0]?.currency}{" "}
                      {bookingDetail?.detail_booking[0]?.discount}
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            {assignBookingId?.bookingNo > 1 ? (
              <SizeBox height={"47px"} />
            ) : (
              <>
                <Divide height={"1px"} type={"horizontal"} margin={"4px 0"} />

                <div className={"modal-booking-success__total"}>
                  <div className={"title"}>Total Amount Paid</div>
                  <div className={"value"}>
                    {bookingDetail?.detail_booking[0]?.currency}{" "}
                    {bookingDetail?.detail_booking[0]?.total_paid}
                  </div>
                </div>
              </>
            )}
            <div className={"modal-booking-success__actions"}>
              <ButtonCustom
                className={"primary"}
                height={"60px"}
                onClick={() => {
                  _onClose();
                  navigate(DESK_BOOKING_LINK);
                }}
              >
                View my bookings
              </ButtonCustom>
            </div>
          </div>
        </div>
      </Modal.Content>
    </ModalCenterTemplate>
  );
};

export default ModalAssignBookingSubmitted;
