import React, { useContext } from "react";
import "./styles.scss";
import { LANGUAGES, PUBLIC_URL } from "../../configs/Constants";
import DropdownCustom from "../../../../components/DropdownCustom";
import { LocalizationContext } from "../../../../locales/Translation";

type Props = {
  children: JSX.Element | JSX.Element[];
  title?: JSX.Element | JSX.Element[];
  subtitle?: JSX.Element | JSX.Element[];
  verticalCenter?: boolean;
};

const OnboardTemplate = ({
  children,
  title,
  subtitle,
  verticalCenter = false,
}: Props) => {
  const { translations, appLanguage, setAppLanguage } =
    useContext(LocalizationContext);

  return (
    <div className={"onboard-template"}>
      <div className={"onboard-template__left"}>
        <div className={"title"}>
          {title ? title : translations.buOnboard.title}
        </div>
        <div className={"description"}>
          {subtitle ? subtitle : translations.buOnboard.description}
        </div>
      </div>
      <div
        className={
          "onboard-template__content " +
          (verticalCenter ? "vertical-center" : "")
        }
      >
        <div className={"onboard-template__content-header"}>
          <img
            className={"logo"}
            src={PUBLIC_URL + "/assets/logo-with-business.svg"}
          />
          <DropdownCustom
            value={appLanguage}
            options={LANGUAGES}
            onChange={(e, { value }) => {
              if (!value) {
                return;
              }
              setAppLanguage(String(value));
            }}
            pointing={"top right"}
          />
        </div>
        <div className={"onboard-template__content-body"}>{children}</div>
      </div>
    </div>
  );
};

export default OnboardTemplate;
