const LoginBackAsAdminIcon = ({ className, strokeColor }: any) => {
  return (
    <div className={className}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.99967 15.3334C4.29501 14.5101 1.33301 11.0147 1.33301 7.33342V3.33341L7.99967 0.666748L14.6663 3.33341V7.33342C14.6663 11.0161 11.7043 14.5101 7.99967 15.3334ZM2.66634 4.00008V7.33342C2.70444 8.87483 3.24658 10.3613 4.20979 11.5653C5.173 12.7693 6.50421 13.6246 7.99967 14.0001C9.49513 13.6246 10.8263 12.7693 11.7896 11.5653C12.7528 10.3613 13.2949 8.87483 13.333 7.33342V4.00008L7.99967 2.00008L2.66634 4.00008Z"
          fill={strokeColor}
        />
        <path
          d="M7.99967 7.33333C8.92015 7.33333 9.66634 6.58714 9.66634 5.66667C9.66634 4.74619 8.92015 4 7.99967 4C7.0792 4 6.33301 4.74619 6.33301 5.66667C6.33301 6.58714 7.0792 7.33333 7.99967 7.33333Z"
          fill={strokeColor}
        />
        <path
          d="M4.66699 10C4.99558 10.5988 5.47739 11.0995 6.06308 11.451C6.64878 11.8024 7.31734 11.9919 8.00033 12C8.68331 11.9919 9.35187 11.8024 9.93757 11.451C10.5233 11.0995 11.0051 10.5988 11.3337 10C11.317 8.736 9.10566 8 8.00033 8C6.88899 8 4.68366 8.736 4.66699 10Z"
          fill={strokeColor}
        />
      </svg>
    </div>
  );
};

export default LoginBackAsAdminIcon;
