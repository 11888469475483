import {
  FILTER_MARKET_ACTION,
  INFO_WINDOW_DATA_ACTION,
  LIST_MEETING_ROOM_ACTION,
  LIST_MEETING_ROOM_FILTER_ACTION,
  MEETING_ROOM_DATA_TMP_ACTION,
  MODAL_CONFIRM_BOOKING_ACTION,
  PROPERTY_FILTER_ACTION,
  PROPERTY_FILTER_KEYWORD_ACTION,
  BOOK_PROPERTY_LIST_ACTION,
  REFRESH_LIST_MEETING_ROOM_ACTION,
  MODAL_CONFIRM_BOOKING_DESK_ACTION,
  MODAL_CONFIRM_BOOKING_DESK_DATA_ACTION,
  MODAL_BOOKING_SUCCESS_ACTION,
  BOOKING_SUCCESS_DESK_ID_ACTION,
  MAP_STATE_ACTION,
  PROPERTY_FAVORITE_ACTION,
  PROPERTY_DETAIL_ACTION,
  ROOM_DETAIL_ACTION,
  ROOM_FAVORITE_ACTION,
  PROPERTY_MARKET_COUNT_ACTION,
  ROOM_MARKET_COUNT_ACTION,
  FILTER_MARKET_OPTIONS_ACTION,
  MODAL_ASSIGN_USER_ACTION,
  GET_USER_LIST_ACTION,
  ASSIGN_USER_LIST_ACTION,
  ASSIGN_BOOKING_ID_ACTION,
  MODAL_ASSIGN_BOOKING_SUBMITTED_ACTION,
  APPEND_USER_LIST_ACTION,
  ROOM_AMENITIES_ACTION,
  DESK_AMENITIES_ACTION,
  PIN_HIGHLIGHT_ACTION,
  CIRCLE_INFO_ACTION,
  MODAL_PRIVATE_OFFICE_ACTION,
  PRIVATE_OFFICE_LIST_ACTION,
  PRIVATE_OFFICE_FILTER_ACTION,
  BOOK_PROPERTY_MAP_ACTION,
  MODAL_CHECKIN_ACTION,
  MODAL_REWARDS_ACTION,
  REWARD_LIST_ACTION,
} from "../configs/Constants";
import { BookInfoWindowType } from "../configs/DeclareType";

export const mapStateAction = (data: any) => {
  return {
    type: MAP_STATE_ACTION,
    payload: data,
  };
};

export const listMeetingRoomAction = (data: any) => {
  return {
    type: LIST_MEETING_ROOM_ACTION,
    payload: data,
  };
};

export const listMeetingRoomFilterAction = (data: any) => {
  return {
    type: LIST_MEETING_ROOM_FILTER_ACTION,
    payload: data,
  };
};

export const refreshListMeetingRoomAction = () => {
  return {
    type: REFRESH_LIST_MEETING_ROOM_ACTION,
  };
};

export const modalConfirmBookingAction = (data: any) => {
  return {
    type: MODAL_CONFIRM_BOOKING_ACTION,
    payload: data,
  };
};

export const modalConfirmBookingDeskAction = (data: boolean) => {
  return {
    type: MODAL_CONFIRM_BOOKING_DESK_ACTION,
    payload: data,
  };
};

export const modalBookingSuccessAction = (data: any) => {
  return {
    type: MODAL_BOOKING_SUCCESS_ACTION,
    payload: data,
  };
};

export const modalConfirmBookingDeskDataAction = (data: object) => {
  return {
    type: MODAL_CONFIRM_BOOKING_DESK_DATA_ACTION,
    payload: data,
  };
};

export const meetingRoomDataTmpAction = (data: any) => {
  return {
    type: MEETING_ROOM_DATA_TMP_ACTION,
    payload: data,
  };
};

export const propertyListAction = (data: any) => {
  return {
    type: BOOK_PROPERTY_LIST_ACTION,
    payload: data,
  };
};

export const propertyMapAction = (data: any) => {
  return {
    type: BOOK_PROPERTY_MAP_ACTION,
    payload: data,
  };
};

export const propertyFilterAction = (data: object) => {
  return {
    type: PROPERTY_FILTER_ACTION,
    payload: data,
  };
};

export const filterMarketAction = (data: object) => {
  return {
    type: FILTER_MARKET_ACTION,
    payload: data,
  };
};

export const propertyFilterKeywordAction = (data: string) => {
  return {
    type: PROPERTY_FILTER_KEYWORD_ACTION,
    payload: data,
  };
};

export const infoWindowDataAction = (data: BookInfoWindowType | null) => {
  return {
    type: INFO_WINDOW_DATA_ACTION,
    payload: data,
  };
};

export const modalBookingSuccessDeskOpenAction = (
  data: { id: number; bookingNo: number } | null
) => {
  return {
    type: BOOKING_SUCCESS_DESK_ID_ACTION,
    payload: data,
  };
};

export const propertyFavoriteAction = (id: number) => {
  return {
    type: PROPERTY_FAVORITE_ACTION,
    payload: id,
  };
};

export const roomFavoriteAction = (id: number) => {
  return {
    type: ROOM_FAVORITE_ACTION,
    payload: id,
  };
};

export const propertyDetailAction = (data: object) => {
  return {
    type: PROPERTY_DETAIL_ACTION,
    payload: data,
  };
};

export const roomDetailAction = (data: object) => {
  return {
    type: ROOM_DETAIL_ACTION,
    payload: data,
  };
};

export const propertyMarketCountAction = (data: object) => {
  return {
    type: PROPERTY_MARKET_COUNT_ACTION,
    payload: data,
  };
};

export const roomMarketCountAction = (data: object) => {
  return {
    type: ROOM_MARKET_COUNT_ACTION,
    payload: data,
  };
};

export const filterMarketOptionsAction = (data: any) => {
  return {
    type: FILTER_MARKET_OPTIONS_ACTION,
    payload: data,
  };
};

export const modalAssignUserAction = (data: object) => {
  return {
    type: MODAL_ASSIGN_USER_ACTION,
    payload: data,
  };
};

export const getUserListAction = (data: object) => {
  return {
    type: GET_USER_LIST_ACTION,
    payload: data,
  };
};

export const appendUserListAction = (data: object) => {
  return {
    type: APPEND_USER_LIST_ACTION,
    payload: data,
  };
};

export const assignUserListAction = (data: any) => {
  return {
    type: ASSIGN_USER_LIST_ACTION,
    payload: data,
  };
};

export const assignBookingIdAction = (data: any) => {
  return {
    type: ASSIGN_BOOKING_ID_ACTION,
    payload: data,
  };
};

export const modalAssignBookingSubmittedAction = (data: any) => {
  return {
    type: MODAL_ASSIGN_BOOKING_SUBMITTED_ACTION,
    payload: data,
  };
};

export const deskAmenitiesAction = (data: any) => {
  return {
    type: DESK_AMENITIES_ACTION,
    payload: data,
  };
};

export const roomAmenitiesAction = (data: any) => {
  return {
    type: ROOM_AMENITIES_ACTION,
    payload: data,
  };
};

export const pinHighlightIdAction = (data: number | null) => {
  return {
    type: PIN_HIGHLIGHT_ACTION,
    payload: data,
  };
};

export const circleInfoAction = (
  data: { lat: number; lng: number; radius: number } | null
) => {
  return {
    type: CIRCLE_INFO_ACTION,
    payload: data,
  };
};

export const modalPrivateOfficeAction = (data: {
  isOpen: boolean;
  data?: {
    privateOfficeId?: number;
    currency: string;
    timezone: string;
    capacity?: number;
  };
}) => {
  return {
    type: MODAL_PRIVATE_OFFICE_ACTION,
    payload: data,
  };
};

export const privateOfficeListAction = (data: object) => {
  return {
    type: PRIVATE_OFFICE_LIST_ACTION,
    payload: data,
  };
};

export const privateOfficeFilterAction = (data: object) => {
  return {
    type: PRIVATE_OFFICE_FILTER_ACTION,
    payload: data,
  };
};

export const modalCheckinAction = (data: object) => {
  return {
    type: MODAL_CHECKIN_ACTION,
    payload: data,
  };
};

export const modalRewardsAction = (data: object) => {
  return {
    type: MODAL_REWARDS_ACTION,
    payload: data,
  };
};

export const rewardListAction = (data: object) => {
  return {
    type: REWARD_LIST_ACTION,
    payload: data,
  };
};
