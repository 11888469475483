import React, { useContext } from "react";
import {
  privateOfficeFilterAction,
  propertyFilterKeywordAction,
} from "../../../actions/BookActions";
import Label from "../../../../../components/Label";
import InputCustom from "../../../../../components/Input";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/Button";
import SizeBox from "../../../../../components/SizeBox";
import SelectCapacity from "../../InquirePrivateOffice/InquireOfficeModal/SelectCapacity";
import { LocalizationContext } from "../../../../../locales/Translation";
import { privateOfficeFilterSelector } from "../../../selectors/BookSelectors";

const FilterPrivateOffice = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const privateOfficeFilter = useSelector(privateOfficeFilterSelector);

  const debounceOnChangeKeyWord = debounce(function (
    data: any,
    { value }: any
  ) {
    dispatch(propertyFilterKeywordAction(value));
  },
  500);

  return (
    <div className={"book-filter-wrapper"}>
      <div className="book-filter-wrapper__top book-filter-wrapper__top-room">
        <div className="book-filter-wrapper__top-inside">
          <div className={"filter-item filter-item-date"}>
            <SelectCapacity
              isAnyValue
              value={privateOfficeFilter?.capacity_select}
              onChange={(value) => {
                dispatch(privateOfficeFilterAction({ capacity_select: value }));
              }}
            />
          </div>
          <SizeBox width={"16px"} />
          <div className={"filter-item"}>
            <Label label={translations.NearWhere} fontSize={"12px"} />
            <InputCustom
              onChange={debounceOnChangeKeyWord}
              placeholder={translations.Search}
              inputRightIcon={<i className="uil uil-search" />}
            />
          </div>
          <div className={"filter-button"}>
            <ButtonCustom
              height={"44px"}
              className={"primary w-100"}
              // onClick={_onChangeStartEndTime}
            >
              {translations.show}
            </ButtonCustom>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterPrivateOffice;
