import { PARTNER_PREFIX_URL } from "./Constants";

export const LOGIN_LINK = "/login";
export const LOGOUT_LINK = "/logout";
export const LOGIN_LINKED_IN_LINK = "/redirect/client/callback/linkedin";
export const FORCE_LOGIN_LINK = "/force-login";
export const FORGOT_PASSWORD_LINK = "/forgot-password";
export const SET_PASSWORD_LINK = "/set-password";
export const RESET_PASSWORD_LINK = "/reset-password";
export const BOOKING_DECLINE_LINK = "/booking-decline/:token";
export const BOOKING_APPROVAL_LINK = "/booking-approval/:token";
export const ACCOUNT_CREATE_OPTION_LINK = "/create-account-option";
export const ACCOUNT_CREATE_INDIVIDUAL_LINK = "/create-account-individual";
export const REMINDER_CALLBACK_LINK = "/reminder-callback/:token";
export const PARKING_REQUEST_CALLBACK_LINK =
  "/parking-request-callback/:action/:bookingType/:bookingId/:reference/:token";
export const DASHBOARD_BUSINESS_LINK = "/dashboard-business";
export const DASHBOARD_PROPERTY_LINK = "/dashboard-property";
export const DASHBOARD_DESKIMO_LINK = "/";
export const DASHBOARD_PARTNER_LINK = `${PARTNER_PREFIX_URL}/dashboard`;
export const EMPLOYEES_BUSINESS_LINK = "/employees-business";
export const EMPLOYEES_PROPERTY_LINK = "/employees-property";
export const DESKIMO_USERS_LINK = "/deskimo-users";
export const DESKIMO_USER_DETAIL_LINK = "/deskimo-user/:id";
export const SETTING_LINK = "/settings/allowances";
export const PRE_PAID_LINK = "/settings/pre-paid";
export const BILLING_LINK = "/settings/billing";
export const INTEGRATIONS_LINK = "/settings/settings";
export const AFFILIATE_LINK = "/settings/affiliate";

export const INVOICE_PROPERTY_LINK = "/property-invoices";
export const INVOICE_PARTNER_LINK = `${PARTNER_PREFIX_URL}/invoices`;
export const PARTNER_TEAM_LINK = `${PARTNER_PREFIX_URL}/teams`;

//INVOICE DETAIL
export const INVOICE_DETAIL_LINK = "/company-invoice/:token";
export const PARTNER_INVOICE_DETAIL_LINK = `${PARTNER_PREFIX_URL}/invoice/:token`;
export const INVOICE_DETAIL_DESKIMO_ADMIN_LINK =
  "/company-invoice/:companyId/:token";
export const INVOICE_DETAIL_PROPERTY_LINK = "/property-invoice/:token";
export const DOWNLOAD_INVOICE_LINK = "/invoice/download/:token";

//PROPERTY
export const PROPERTY_LIST_LINK = "/properties";
export const PROPERTY_DETAIL_LINK = "/property/:id/";
export const PROPERTY_EDIT_LINK = "/property/:id/edit";
export const PROPERTY_CREATE_LINK = "/property/create/";
export const PROPERTY_DETAIL_USER_REVIEW_LINK = "/property/:id/user-review";
export const PROPERTY_DETAIL_MEETING_ROOM_LINK = "/property/:id/meeting-rooms";
export const PROPERTY_DETAIL_PRIVATE_OFFICES_LINK =
  "/property/:companyId/:id/private-offices";
export const PROPERTY_DETAIL_INTEGRATIONS_LINK =
  "/property/:companyId/:id/integrations";
export const PROPERTY_DETAIL_REWARDS_LINK = "/property/:companyId/:id/rewards";

// PROPERTY DESKIMO
export const DESKIMO_PROPERTY_LINK = "/deskimo-properties";
export const DESKIMO_PROPERTY_COMPANY_LINK = "/property-companies";
export const DESKIMO_PROPERTY_COMPANY_CREATE_LINK = "/property-company/create";
export const DESKIMO_PROPERTY_COMPANY_DETAIL_LINK = "/property-company/:id";
export const DESKIMO_PROPERTY_COMPANY_INVOICE_DETAIL_LINK =
  "/property-company/:companyId/company-invoices/invoice/:token";
export const DESKIMO_PROPERTY_STAFF_LINK = "/property-staff";
export const DESKIMO_PRIVATE_OFFICES_LINK = "/private-offices";
export const DESKIMO_PROPERTY_INVOICES_LINK = "/property-company/invoices";
export const DESKIMO_PROPERTY_AMENITIES_LINK = "/property-company/amenities";
export const DESKIMO_PROPERTY_SCANNERS_LINK = "/property-company/scanners";
export const DESKIMO_PROPERTY_SCANNER_CREATE_LINK =
  "/property-company/scanners/create";
export const DESKIMO_PROPERTY_SCANNER_EDIT_LINK =
  "/property-company/scanners/edit/:token";
export const DESKIMO_METTING_ROOM = "/deskimo-meeting-rooms";
export const DESKIMO_USER_REVIEW = "/deskimo-user-reviews";
export const DESKIMO_PROPERTY_DETAIL_LINK = "/deskimo-property/:companyId/:id";
export const DESKIMO_PROPERTY_DETAIL_USER_REVIEW_LINK =
  "/deskimo-property/:companyId/:id/user-review";
export const DESKIMO_PROPERTY_DETAIL_MEETING_ROOM_LINK =
  "/deskimo-property/:companyId/:id/meeting-rooms";

export const DESKIMO_PROPERTY_DETAIL_PRIVATE_OFFICES_LINK =
  "/deskimo-property/:companyId/:id/private-offices";

export const DESKIMO_PROPERTY_DETAIL_NOTES_LINK =
  "/deskimo-property/:companyId/:id/notes";
export const DESKIMO_PROPERTY_DETAIL_INTEGRATIONS_LINK =
  "/deskimo-property/:companyId/:id/integrations";

export const DESKIMO_PROPERTY_DETAIL_REWARD_LINK =
  "/deskimo-property/:companyId/:id/rewards";

// MARKETING
export const MARKETING_CAMPAIGNS_LINK = "/marketing/campaigns";
export const MARKETING_CAMPAIGN_DETAIL_LINK = "/marketing/campaign/:id";
export const MARKETING_CAMPAIGN_CRATE_LINK = "/marketing/campaign/create";
export const MARKETING_CAMPAIGN_EDIT_LINK = "/marketing/campaign/edit/:id";
export const REWARD_REDEEM_LINK = "/reward/redeem";

// CONFIGURATION
export const CONFIG_MAIN_LINK = "/configuration";
export const CONFIG_TAGS = "/configuration/tags";
export const CONFIG_ROLE_PERMISSION_LINK = "/configuration/role-permission";
export const CONFIG_PREPAID_CREDIT_PACKAGES_CONFIGURATION =
  "/configuration/prepaid-credit-packages-configuration";
export const ACCOUNTING_LINK = "/configuration/accounting";
export const CONFIG_MARKET_EDIT_GENERAL_INFORMATION_LINK =
  "/configuration/markets/edit/:id";
export const CONFIG_MARKET_CREATE_LINK = "/configuration/markets/create";
export const PARTNER_INTEGRATION_LINK = `${PARTNER_PREFIX_URL}/integration`;
export const PARTNER_OPERATION_LOG_LINK = `${PARTNER_PREFIX_URL}/operation-log`;
export const PARTNER_LIMITATION_LINK = `${PARTNER_PREFIX_URL}/limitation`;
export const CONFIG_MARKET_EDIT_PUBLIC_HOLIDAYS_LINK =
  "/configuration/markets/edit/:id/public-holidays";
export const CONFIG_MARKET_EDIT_LANDMARK_LINK =
  "/configuration/markets/edit/:id/landmark";
export const CONFIG_MARKET_EDIT_ONBOARD_PROMOTION =
  "/configuration/markets/edit/:id/onboard-promotion";

// BUSINESS COMPANY
export const BUSINESS_COMPANY_LIST_LINK = "/business-company";
export const BUSINESS_COMPANY_DETAIL_LINK = "/business-company/:id";
export const BUSINESS_COMPANY_EDIT_LINK = "/business-company/:id/edit";
export const BUSINESS_COMPANY_CREATE_LINK = "/business-company/create";
export const BUSINESS_COMPANY_STAFF_LINK = "/business-company/staff";
export const BUSINESS_COMPANY_INVOICES_LINK = "/business-company/invoices";
export const BUSINESS_COMPANY_PURCHASE_HISTORY_LINK =
  "/business-company/purchase-history";

export const BUSINESS_COMPANY_PREPAID_CREDIT_LINK =
  "/business-company/prepaid-credit";
export const BUSINESS_COMPANY_OFFICE_LEASE_LINK =
  "/business-company/office-lease";
export const BUSINESS_COMPANY_INVOICE_DETAIL_LINK =
  "/business-company/invoices/:id/details/:token";
export const BUSINESS_CUSTOM_PACKAGES_LINK =
  "/business-company/custom-packages";

// BOOK
export const BOOK_DESK_LINK = "/book/desk";
export const BOOK_ROOM_LINK = "/book/room";
export const BOOK_INQUIRE_OFFICE_LINK = "/book/inquire-office";
export const BOOK_INQUIRE_OFFICE_DETAIL_LINK = "/book/inquire-office/:slug";
export const BOOK_DESK_DETAIL_LINK = "/book/desk/:id";
export const BOOK_DESK_DETAIL_WITH_DEFAULT_DATE_LINK =
  "/book/desk/:id/:defaultDate";
export const BOOK_ROOM_DETAIL_LINK = "/book/room/:id";
export const BOOK_ROOM_DETAIL_WITH_DEFAULT_DATE_LINK =
  "/book/room/:id?default-date=:defaultDate&default-start-time=:defaultStartTime&default-duration=:defaultDuration";

// BOOKING
export const DESK_BOOKING_LINK = "/booking/desk";
export const ROOM_BOOKING_LINK = "/booking/room";

// Business User Onboard
export const BU_ONBOARD_LINK = "/business-user/onboard/:token";
export const PENDING_INVITE_ACCEPT_LINK =
  "/business-user/pending-invite/accept/:token";
export const PENDING_INVITE_REJECT_LINK =
  "/business-user/pending-invite/reject/:token";

// Media File
export const MEDIA_FILE_LINK = "/media-files";

export const TITLES: { [index: string]: any } = {
  [LOGIN_LINK]: "Login",
  [FORCE_LOGIN_LINK]: "Force Login",
  [FORGOT_PASSWORD_LINK]: "Forgot Password",
  [SET_PASSWORD_LINK]: "Set Password",
  [RESET_PASSWORD_LINK]: "Reset Password",
  [DASHBOARD_BUSINESS_LINK]: "Dashboard",
  [DASHBOARD_PROPERTY_LINK]: "Dashboard",
  [DASHBOARD_DESKIMO_LINK]: "Dashboard",
  [EMPLOYEES_BUSINESS_LINK]: "Employees",
  [DESKIMO_USERS_LINK]: "Users",
  [DESKIMO_USER_DETAIL_LINK]: "User Detail",
  [SETTING_LINK]: "Allowances",
  [PRE_PAID_LINK]: "Credits",
  [BILLING_LINK]: "Billing",
  [INTEGRATIONS_LINK]: "Settings",
  [INVOICE_PROPERTY_LINK]: "Invoices",
  [PROPERTY_LIST_LINK]: "Properties",
  [EMPLOYEES_PROPERTY_LINK]: "Users",
  [PROPERTY_DETAIL_LINK]: "Property Details",
  [PROPERTY_EDIT_LINK]: "Edit Property",
  [PROPERTY_CREATE_LINK]: "Create Property",
  [PROPERTY_DETAIL_USER_REVIEW_LINK]: "User Reviews",
  [PROPERTY_DETAIL_MEETING_ROOM_LINK]: "Meeting Rooms",
  [INVOICE_DETAIL_LINK]: "Invoice",
  [INVOICE_DETAIL_PROPERTY_LINK]: "Invoice",
  [MARKETING_CAMPAIGNS_LINK]: "Campaigns",
  [MARKETING_CAMPAIGN_DETAIL_LINK]: "Campaign Detail",
  [MARKETING_CAMPAIGN_CRATE_LINK]: "Create Campaign",
  [MARKETING_CAMPAIGN_EDIT_LINK]: "Edit Campaign",
  [DESKIMO_PROPERTY_INVOICES_LINK]: "Invoices",
  [DESKIMO_PROPERTY_COMPANY_INVOICE_DETAIL_LINK]: "Invoice Detail",
  [BUSINESS_COMPANY_LIST_LINK]: "Business Company",
  [BUSINESS_COMPANY_DETAIL_LINK]: "Business Company",
  [BUSINESS_COMPANY_STAFF_LINK]: "Business Staff",
  [BUSINESS_COMPANY_INVOICES_LINK]: "Business Invoices",
  [BUSINESS_COMPANY_INVOICE_DETAIL_LINK]: "Business Invoice Details",
  [BUSINESS_CUSTOM_PACKAGES_LINK]: "Business Custom Packages",
  [BOOK_DESK_LINK]: "Book Desk",
  [BOOK_ROOM_LINK]: "Book Meeting Room",
  [BOOK_DESK_DETAIL_LINK]: "Book Desk",
  [BOOK_ROOM_DETAIL_LINK]: "Book Meeting Room",
  [DESK_BOOKING_LINK]: "Desk Booking",
  [ROOM_BOOKING_LINK]: "Meeting Room Booking",
  [BU_ONBOARD_LINK]: "Reset password",

  [BUSINESS_COMPANY_EDIT_LINK]: "Company Edit",
  [BUSINESS_COMPANY_CREATE_LINK]: "Company Create",
  [DESKIMO_METTING_ROOM]: "Meeting Rooms",
  [DESKIMO_USER_REVIEW]: "User Reviews",
  [CONFIG_TAGS]: "Tags",
  [DESKIMO_PROPERTY_LINK]: "Properties",
  [DESKIMO_PROPERTY_COMPANY_CREATE_LINK]: "Create Property",
  [DESKIMO_PROPERTY_COMPANY_DETAIL_LINK]: "Property Detail",
  [DESKIMO_PROPERTY_STAFF_LINK]: "Staff",
  [DESKIMO_PROPERTY_AMENITIES_LINK]: "Amenities",
  [DESKIMO_PROPERTY_SCANNERS_LINK]: "Scanners",
  [DESKIMO_PROPERTY_SCANNER_CREATE_LINK]: "Create Scanner",
  [DESKIMO_PROPERTY_SCANNER_EDIT_LINK]: "Edit Scanner",
  [DESKIMO_PROPERTY_DETAIL_LINK]: "Property Detail",
  [DESKIMO_PROPERTY_DETAIL_USER_REVIEW_LINK]: "User Reviews",
  [DESKIMO_PROPERTY_DETAIL_MEETING_ROOM_LINK]: "Meeting Rooms",
  [CONFIG_MAIN_LINK]: "Configuration",
  [CONFIG_MARKET_EDIT_GENERAL_INFORMATION_LINK]: "Edit Market",
  [CONFIG_MARKET_CREATE_LINK]: "Create Market",
  [CONFIG_MARKET_EDIT_PUBLIC_HOLIDAYS_LINK]: "Holidays",
  [CONFIG_MARKET_EDIT_LANDMARK_LINK]: "Landmarks",
  [CONFIG_MARKET_EDIT_ONBOARD_PROMOTION]: "Promotion",

  [PARTNER_TEAM_LINK]: "Partner Teams",
};
