import React, { useEffect, useState } from "react";
import FilterDesk from "../Components/FilterDesk";
import { useDispatch, useSelector } from "react-redux";
import {
  getPropertyListThunk,
  getPropertyMapThunk,
  propertyMarketCountThunk,
} from "../../thunks/BookThunks";
import DeskList from "./DeskList";
import SizeBox from "../../../../components/SizeBox";
import {
  filterMarketSelector,
  propertyFilterSelector,
} from "../../selectors/BookSelectors";
import ModalConfirmBookingDesk from "../Components/ModalConfirmBookingDesk";
import ModalBookingSubmittedDesk from "../Components/ModalBookingSubmittedDesk";
import ModalCheckin from "../Components/ModalCheckin";
import { propertyFilterAction } from "../../actions/BookActions";
import { convertDate2StatEndTimeUsingMoment } from "../../services/Common";
import moment from "moment-timezone";

const Desk = () => {
  const dispatch = useDispatch();
  const propertyFilter = useSelector(propertyFilterSelector);
  const { end_time, city_code, amenities, page } = propertyFilter;
  const [loading, setLoading] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [populateFromSessionStorage, setPopulateFromSessionStorage] =
    useState(false);
  const filterMarket = useSelector(filterMarketSelector);

  useEffect(() => {
    const defaultDate = sessionStorage.getItem("DEFAULT_DATE");
    const defaultLocation = sessionStorage.getItem("DEFAULT_LOCATION");
    const defaultAmenity = sessionStorage.getItem("DEFAULT_AMENITIES");
    const defaultSort = Number(sessionStorage.getItem("DEFAULT_SORTBY"));

    let startTime = undefined;
    let endTime = undefined;

    if (defaultDate && filterMarket?.timezone) {
      const defaultDateMomentObj = moment(defaultDate);
      const time = convertDate2StatEndTimeUsingMoment(
        filterMarket?.timezone,
        defaultDateMomentObj
      );
      startTime = time.startTime;
      endTime = time.endTime;
    }

    const defaultFilters = {
      ...propertyFilter,
      sort_by: defaultSort ? defaultSort : propertyFilter?.sort_by,
      amenities: defaultAmenity
        ? defaultAmenity.split(",").map(Number)
        : propertyFilter?.amenities,
      lat: defaultLocation ? JSON.parse(defaultLocation).lat : null,
      lng: defaultLocation ? JSON.parse(defaultLocation).lng : null,
      start_time: startTime,
      end_time: endTime,
    };

    dispatch(propertyFilterAction(defaultFilters));
    setPopulateFromSessionStorage(true);
  }, [populateFromSessionStorage]);

  useEffect(() => {
    if (!populateFromSessionStorage) return;
    (async () => {
      try {
        if (!city_code) {
          return;
        }

        if (page === 1) {
          setLoading(true);
        } else {
          setLoadMoreLoading(true);
        }
        await dispatch<any>(getPropertyListThunk(propertyFilter));
      } finally {
        if (page === 1) {
          setLoading(false);
        } else {
          setLoadMoreLoading(false);
        }
      }
    })();
  }, [propertyFilter]);

  useEffect(() => {
    if (!city_code) {
      return;
    }

    if (!populateFromSessionStorage) return;
    dispatch<any>(getPropertyMapThunk(propertyFilter));
  }, [end_time, city_code, amenities]);

  return (
    <>
      <FilterDesk />
      <SizeBox height={"16px"} />
      <DeskList loading={loading} loadMoreLoading={loadMoreLoading} />
      <ModalConfirmBookingDesk />
      <ModalBookingSubmittedDesk />
      <ModalCheckin />
    </>
  );
};

export default Desk;
