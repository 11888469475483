import React, { useContext, useState, useEffect } from "react";
import classNames from "classnames";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../components/Button";
import ModalCenterTemplate from "../../../../App/views/ModalCenterTemplate";
import { LocalizationContext } from "../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import {
  filterMarketSelector,
  modalConfirmDeskDataSelector,
  modalConfirmDeskOpenSelector,
  propertyFilterSelector,
} from "../../../selectors/BookSelectors";
import {
  modalConfirmBookingDeskAction,
  propertyFilterKeywordAction,
} from "../../../actions/BookActions";
import moment from "moment-timezone";
import SizeBox from "../../../../../components/SizeBox";
import { checkAvailableDeskService } from "../../../services/BookServices";
import {
  calculateTotalDeskPrice,
  convertDate2StatEndTimeUsingMoment,
  makeBookingDeskService,
} from "../../../services/Common";
import { toastError } from "../../../../../components/Toast";
import { BookPropertyType } from "../../../configs/DeclareType";
import { FILTER_START_TIME_KEY } from "../../../configs/Constants";
import InputCustom from "../../../../../components/Input";
import { userCompanyChooseSelector } from "../../../../User/selectors/UserSelectors";
import NumberBox from "../../../../../components/NumberBox";
import DatePickerSingleV2 from "../../../../../components/DatePickerSingleV2";
import { getCreditRemainingThunk } from "../../../../App/thunks/AppThunk";
import BoxAddVoucherForBooking from "../../../../App/views/BoxAddVoucherForBooking";
import Divide from "../../../../../components/Divide";

const ModalConfirmBookingDesk = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const modalConfirmDeskOpen = useSelector(modalConfirmDeskOpenSelector);
  const [loading, setLoading] = useState(false);
  const modalConfirmDeskData: BookPropertyType = useSelector(
    modalConfirmDeskDataSelector
  );
  const propertyFilter = useSelector(propertyFilterSelector);
  const filterMarket = useSelector(filterMarketSelector);
  const userCompanyChoose = useSelector(userCompanyChooseSelector);

  const [dateChoose, setDateChoose] = useState<any>(moment());

  const [bookingInfo, setBookingInfo] = useState<any>();
  const [bookingNo, setBookingNo] = useState<number>(1);
  const [isRefresh, setIsRefresh] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(propertyFilterKeywordAction(""));
    };
  }, []);

  useEffect(() => {
    if (!modalConfirmDeskOpen) {
      if (bookingNo !== 1) {
        setBookingNo(1);
      }
      return;
    }

    if (propertyFilter[FILTER_START_TIME_KEY]) {
      const convertTime = moment
        .unix(propertyFilter[FILTER_START_TIME_KEY])
        .tz(filterMarket?.timezone);

      setDateChoose(convertTime.startOf("day"));
      checkAvailableRoom(convertTime.startOf("day"));
    } else {
      const convertTime = moment().tz(filterMarket?.timezone).startOf("day");
      const newDateChoose = _getNextWorkingDate(convertTime);
      setDateChoose(newDateChoose);
      checkAvailableRoom(newDateChoose);
    }
  }, [modalConfirmDeskOpen, isRefresh]);

  const _getNextWorkingDate = (date: any) => {
    if (!date) return;
    const targetDate = moment(date); // Get the current date

    if (targetDate.day() === 5) {
      // Friday
      targetDate.add({ d: 3 }); // Move to Monday
    } else if (targetDate.day() === 6) {
      // Saturday
      targetDate.add({ d: 2 }); // Move to Monday
    } else {
      targetDate.add({ d: 1 });
    }

    return targetDate;
  };

  const checkAvailableRoom = async (newDateChoose: any) => {
    try {
      setLoading(true);
      const { startTime, endTime } = convertDate2StatEndTimeUsingMoment(
        filterMarket.timezone,
        newDateChoose
      );

      const payload = {
        id: String(modalConfirmDeskData?.id),
        start_time: startTime,
        end_time: endTime,
      };
      const { data } = await checkAvailableDeskService(payload);
      setBookingInfo(data);
    } catch (error: any) {
      toastError(error?.status?.message);
      setBookingInfo(null);
      dispatch(modalConfirmBookingDeskAction(false));
    } finally {
      setLoading(false);
    }
  };

  const makeBooking = async () => {
    try {
      setLoading(true);
      await makeBookingDeskService({
        propertyId: modalConfirmDeskData?.id,
        paymentCompanyId: userCompanyChoose?.company_id,
        bookingNo,
        dateChoose,
        timezone: filterMarket.timezone,
      });
      dispatch<any>(getCreditRemainingThunk());
      dispatch(modalConfirmBookingDeskAction(false));
    } catch (error: any) {
      switch (error?.status?.code) {
        case 1002:
        case 1012:
        case 1014:
          toastError(error?.status?.message);
          break;
        case 1004:
          toastError(translations.errorMessage.paymentInValid);
          break;
        case 1007:
          toastError(translations.errorMessage.paymentInValid);
          break;
        case 1250:
          toastError(translations.errorMessage.hotDeskIsNotAvailable);
          break;
        case 2000:
          toastError(translations.errorMessage.paymentBusinessRestricted);
          break;
        default:
          toastError(error?.status?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalCenterTemplate
      trigger={null}
      onClose={() => dispatch(modalConfirmBookingDeskAction(false))}
      modalClassName={classNames("modal-center-v2 modal-confirm-booking")}
      open={modalConfirmDeskOpen}
    >
      <Modal.Header>Confirm your booking</Modal.Header>
      <Modal.Content>
        <div className={"w-100"}>
          <div className={"modal-confirm-booking__thumbnail"}>
            <img
              src={modalConfirmDeskData?.thumbnail}
              alt={modalConfirmDeskData?.name}
            />
          </div>
          <div
            className={"d-flex flex-column"}
            style={{
              padding: 24,
              gap: 32,
              borderBottom: "1px solid #E9ECEE",
            }}
          >
            <div className={"modal-confirm-booking__info"}>
              <div className={"title"}>{modalConfirmDeskData?.name}</div>
              <div className={"item"}>
                <i className="uil uil-building" />
                {modalConfirmDeskData?.type}
              </div>
              <div className={"item"}>
                <i className="uil uil-map" />
                {modalConfirmDeskData?.address}
              </div>
              <div className={"item mb-0"}>
                <i className="uil uil-clock" />
                {modalConfirmDeskData?.open_time}
              </div>
            </div>
            <div className={"modal-confirm-booking__detail"}>
              <div style={{ marginBottom: 20 }}>
                Confirm the selected date and the number of desks
              </div>
              <div className={"title"}>Booking Details</div>

              <div className={"select-item"}>
                <div className={"text"}>No. of Desks</div>
                <NumberBox
                  value={bookingNo}
                  onMinusChange={() =>
                    setBookingNo((prevState) => prevState - 1)
                  }
                  onPlusChange={() =>
                    setBookingNo((prevState) => prevState + 1)
                  }
                />
              </div>

              <div className={"select-item"}>
                <div className={"text"}>Date</div>
                <div>
                  <DatePickerSingleV2
                    format={"DD/MM/YYYY"}
                    allowClear={false}
                    value={dateChoose}
                    disabledDate={(date: any) => {
                      return moment(date).isBefore(moment().subtract({ d: 1 }));
                    }}
                    showToday={false}
                    className={"w-100"}
                    onChange={(date: any) => {
                      setDateChoose(date);
                    }}
                  />
                </div>
              </div>
              <div className={"select-item mb-0"}>
                <div className={"text"}>Duration</div>
                <div>
                  <InputCustom value={"Full Day"} disabled />
                </div>
              </div>
            </div>
          </div>
          <div style={{ padding: 24 }}>
            <BoxAddVoucherForBooking
              isLabelTitle={true}
              isRefresh={isRefresh}
              setIsRefresh={setIsRefresh}
            />

            {bookingInfo?.discount && bookingInfo?.discount > 0 ? (
              <>
                <SizeBox height={"24px"} />
                <div className="d-flex justify-content-between">
                  <div
                    className={"label-small fw-500"}
                    style={{ fontSize: 20 }}
                  >
                    Subtotal
                  </div>
                  <div
                    className={"label-small text-right"}
                    style={{ fontSize: 20 }}
                  >
                    {bookingInfo?.currency} {bookingInfo?.price_before_discount}
                  </div>
                </div>
                <SizeBox height={"24px"} />
              </>
            ) : (
              <SizeBox height={"12px"} />
            )}

            {bookingInfo?.discount && bookingInfo?.discount > 0 ? (
              <>
                <div className="d-flex justify-content-between">
                  <div
                    className={"label-small fw-500"}
                    style={{ fontSize: 20 }}
                  >
                    Discount
                  </div>
                  <div
                    className={"label-small text-right"}
                    style={{ fontSize: 20 }}
                  >
                    - {bookingInfo?.currency} {bookingInfo.discount}
                  </div>
                </div>
                <Divide height={"1px"} type={"horizontal"} margin={"24px 0"} />
              </>
            ) : null}

            <div className={"modal-confirm-booking__total"}>
              <div className={"title"}>Total</div>
              <div className={"value"}>
                {bookingInfo?.currency}{" "}
                {calculateTotalDeskPrice(
                  bookingNo,
                  bookingInfo?.total_final_price
                )}
              </div>
            </div>
            <SizeBox height={"24px"} />
            <div className={"modal-confirm-booking__actions"}>
              <ButtonCustom
                height={"47px"}
                disabled={loading}
                onClick={() => dispatch(modalConfirmBookingDeskAction(false))}
              >
                {translations.cancel}
              </ButtonCustom>
              <SizeBox width={"16px"} />
              <ButtonCustom
                disabled={loading}
                loading={loading}
                className={"primary"}
                height={"47px"}
                onClick={makeBooking}
              >
                Book now
              </ButtonCustom>
            </div>
          </div>
        </div>
      </Modal.Content>
    </ModalCenterTemplate>
  );
};

export default ModalConfirmBookingDesk;
