import React, { useContext, useState } from "react";
import { Modal } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import { isModalAddBillingContactSelector } from "../../../../selectors/BillingSelector";
import { modalAddBillingContactAction } from "../../../../actions/BiilingActions";
import ButtonCustom from "../../../../../../components/Button";
import ModalCenterTemplate from "../../../../../App/views/ModalCenterTemplate";
import InputCustom from "../../../../../../components/Input";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { Formik } from "formik";
import * as yup from "yup";
import { addBillingContactService } from "../../../../services/SettingServices";
import { getBillingContactThunk } from "../../../../thunks/BillingThunk";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { LocalizationContext } from "../../../../../../locales/Translation";

const ModalAddBillingContact = () => {
  const dispatch = useDispatch();
  const isModalAddBillingContact = useSelector(
    isModalAddBillingContactSelector
  );
  const [loading, setLoading] = useState(false);
  const { translations } = useContext(LocalizationContext);

  const _onClickSave = async (values: any) => {
    console.log("Save data: ", values);
    setLoading(true);
    try {
      await addBillingContactService(values);
      toastSuccess(translations.settings.addBillingContactMsg);
      await dispatch<any>(getBillingContactThunk());
      dispatch(modalAddBillingContactAction(false));
    } catch (response: any) {
      toastError(response?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={() => dispatch(modalAddBillingContactAction(false))}
      modalClassName={"modal-general modal-vertical-centered"}
      open={isModalAddBillingContact}
    >
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
        }}
        validationSchema={yup.object().shape({
          first_name: yup
            .string()
            .required(translations.settings.requiredField),
          last_name: yup.string().required(translations.settings.requiredField),
          email: yup
            .string()
            .email()
            .required(translations.settings.requiredField),
        })}
        onSubmit={_onClickSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <>
            <Modal.Header>
              {translations.settings.addBillingContact}
              <ButtonCustom
                onClick={() => dispatch(modalAddBillingContactAction(false))}
                className={"modal-close-cta"}
              >
                <>
                  <span>{translations.settings.close}</span>
                  <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
                </>
              </ButtonCustom>
            </Modal.Header>
            <Modal.Content>
              <div className="form-row">
                <InputCustom
                  label={translations.settings.firstName}
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.first_name}
                  error={errors.first_name}
                  name={"first_name"}
                />
                <InputCustom
                  label={translations.settings.lastName}
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.last_name}
                  error={errors.last_name}
                  name={"last_name"}
                />
              </div>

              <div className={"mb-16px"} />
              <InputCustom
                label={translations.settings.emailAddress}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.email}
                error={errors.email}
                name={"email"}
              />
              <div className={"mb-16px"} />
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom
                className={"default"}
                height={"47px"}
                onClick={() => dispatch(modalAddBillingContactAction(false))}
              >
                {translations.settings.cancel}
              </ButtonCustom>
              <ButtonCustom
                loading={loading}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {translations.settings.addContact}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalCenterTemplate>
  );
};

export default ModalAddBillingContact;
