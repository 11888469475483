import React, { useContext, useEffect, useState } from "react";
import MasterTemplate from "../../../App/views/MasterTemplate";
import SectionWhiteBox from "../../../../components/SectionWhiteBox";
import "./styles.scss";
import Thumbnail from "./Component/Thumbnail";
import Amenities from "./Component/Amenities";
import Copy from "./Component/Copy";
import Rating from "./Component/Rating";
import Address from "./Component/Address";
import RoomSuggestion from "./Component/RoomSuggestion";
import { useNavigate, useParams } from "react-router-dom";
import {
  modalRewardsSelector,
  propertyDetailViewSelector,
  rewardListSelector,
} from "../../selectors/BookSelectors";
import { useDispatch, useSelector } from "react-redux";
import { PropertyDetailType } from "../../configs/DeclareType";
import { propertyDetailThunk, rewardListThunk } from "../../thunks/BookThunks";
import { Dimmer, Loader } from "semantic-ui-react";
import { BOOK_DESK_LINK } from "../../../App/configs/ListRoutes";
import BookingBox from "./Component/BookingBox";
import ModalBookingSubmittedDesk from "../Components/ModalBookingSubmittedDesk";
import {
  clipboardCopyWithAsyncFunction,
  sharePropertyMessage,
} from "../../services/Common";
import { LocalizationContext } from "../../../../locales/Translation";
import { toastError, toastSuccess } from "../../../../components/Toast";
import {
  ROLE_BUSINESS_ADMIN_ID,
  ROLE_BUSINESS_USER_ID,
} from "../../../User/configs/Constants";
import ModalAssignUser from "./Component/ModalAssignUser";
import ModalAssignBookingSubmitted from "./Component/ModalAssignBookingSubmitted";
import Schedule from "./Component/Schedule";
import { readMoreRatingService } from "../../services/BookServices";
import {
  modalRewardsAction,
  propertyDetailAction,
} from "../../actions/BookActions";
import { RATING_ITEM_PER_PAGE } from "../../configs/Constants";
import ModalInquireOffice from "../InquirePrivateOffice/InquireOfficeModal/ModalInquireOffice";
import PropertyOutstanding from "../../../Property/views/PropertyDetail/Components/DetailsTab/Components/PropertyOutstanding";
import ModalRewards from "./Component/ModalRewards";
import ButtonCustom from "../../../../components/Button";

const BookProperty = () => {
  const { translations } = useContext(LocalizationContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [readMoreLoading, setReadMoreLoading] = useState<boolean>(false);
  const [isReadMore, setIsReadMore] = useState(true);

  const [inquireModal, setInquireModal] = useState<boolean>(false);

  const propertyDetail: PropertyDetailType = useSelector(
    propertyDetailViewSelector
  );
  const rewardList = useSelector(rewardListSelector);

  useEffect(() => {
    fetchModalReward();
  }, [propertyDetail]);

  const fetchModalReward = async () => {
    try {
      if (!propertyDetail?.id) return;
      await dispatch<any>(rewardListThunk(propertyDetail?.id?.toString()));
    } catch (error: any) {
      toastError(error?.status?.message);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (!id) {
          return;
        }

        const body = document.querySelector("body");
        if (body) {
          body.classList.remove("prevent-scroll");
        }
        setLoading(true);
        await dispatch<any>(propertyDetailThunk(id));
      } catch (e) {
        navigate(BOOK_DESK_LINK);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (propertyDetail?.rating?.user_review?.length < 3) {
      setIsReadMore(false);
    }
  }, [propertyDetail]);

  const _share = () =>
    sharePropertyMessage(
      translations,
      propertyDetail.id,
      propertyDetail.web_url
    );

  const _handleReadMore = async () => {
    setReadMoreLoading(true);
    try {
      const payload = {
        last_id: propertyDetail?.rating?.user_review.slice(-1)[0]?.id,
        property_id: propertyDetail?.id,
      };
      const response = await readMoreRatingService(payload);

      if (
        response?.data?.length === 0 ||
        response?.data?.length < RATING_ITEM_PER_PAGE
      ) {
        setIsReadMore(false);
      }

      const newPropertyDetailData = JSON.parse(JSON.stringify(propertyDetail));
      if (newPropertyDetailData && response?.data?.length > 0) {
        newPropertyDetailData.rating.user_review = [
          ...newPropertyDetailData.rating.user_review,
          ...response.data,
        ];
      }

      dispatch(propertyDetailAction(newPropertyDetailData));
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setReadMoreLoading(false);
    }
  };

  return (
    <MasterTemplate
      roleAccept={[ROLE_BUSINESS_USER_ID, ROLE_BUSINESS_ADMIN_ID]}
    >
      {loading ? (
        <Dimmer active>
          <Loader />
        </Dimmer>
      ) : (
        <>
          <SectionWhiteBox isShowBack backRoute={BOOK_DESK_LINK}>
            <div className={"book-detail__name-wrapper"}>
              <h2 className={"book-detail__name"}>{propertyDetail?.name}</h2>
              <button
                onClick={() => setInquireModal(true)}
                className={"book-detail__inquire"}
              >
                {translations.inquireForPrivateOffice}
              </button>
            </div>
            <div className={"book-detail__description-wrapper"}>
              <div className={"book-detail__description"}>
                <span>
                  <i className="uil uil-star" />
                  {propertyDetail?.rating?.star} ({propertyDetail?.rating.total}{" "}
                  {propertyDetail?.rating?.total < 2 ? "review" : "reviews"})
                </span>
                <span className={"dot"}>•</span>
                <span>
                  <i className="uil uil-building" />
                  {propertyDetail?.type}
                </span>
                <span className={"dot"}>•</span>
                <span>
                  <i className="uil uil-map" />
                  {propertyDetail?.address}
                </span>
              </div>
              {propertyDetail?.short_url ? (
                <button
                  className={
                    "book-detail__description book-detail__description-share"
                  }
                  onClick={() => {
                    navigator.clipboard.writeText(
                      propertyDetail?.short_url || ""
                    );
                    toastSuccess(translations.copyToClipboard);
                  }}
                >
                  <i className="uil uil-upload"></i>
                  &nbsp;&nbsp;Share
                </button>
              ) : null}
            </div>
            <Thumbnail thumbnail={propertyDetail?.images} />
            <div className={"book-detail__book-section"}>
              <div>
                <Amenities amenities={propertyDetail?.amenities} />
                <PropertyOutstanding propertyId={id || ""} bookPropertyDetail />
                <Schedule propertyDetail={propertyDetail} />
                <Copy title={"About"} content={propertyDetail?.about} />
                <Copy
                  title={"House Rules"}
                  content={propertyDetail?.house_rule}
                />
                <Copy
                  title={"Cancellation Policy"}
                  content={propertyDetail?.cancellation_policy}
                />
                <Rating
                  rating={propertyDetail?.rating}
                  handleReadMore={_handleReadMore}
                  isReadMore={isReadMore}
                  readMoreLoading={readMoreLoading}
                />
              </div>
              <div>
                <BookingBox />
              </div>
            </div>
            <Address lat={propertyDetail?.lat} lng={propertyDetail?.lng} />
            <RoomSuggestion rooms={propertyDetail?.meeting_rooms} />
          </SectionWhiteBox>
          {rewardList && rewardList?.length > 0 && (
            <ButtonCustom
              style={{ position: "fixed", bottom: 48, right: 48 }}
              className={"link"}
              onClick={() => dispatch(modalRewardsAction({ open: true }))}
            >
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="48" height="48" rx="24" fill="#FFBE00" />
                <rect x="3" y="3" width="42" height="42" rx="21" fill="white" />
                <path
                  d="M31.6562 20.1097H15.0312C14.7992 20.1097 14.5766 20.2019 14.4125 20.366C14.2484 20.5301 14.1562 20.7527 14.1562 20.9847V23.6097C14.1562 23.7246 14.1789 23.8384 14.2229 23.9446C14.2668 24.0507 14.3313 24.1472 14.4125 24.2285C14.4938 24.3097 14.5902 24.3742 14.6964 24.4181C14.8026 24.4621 14.9163 24.4847 15.0312 24.4847H31.6562C31.8883 24.4847 32.1109 24.3926 32.275 24.2285C32.4391 24.0644 32.5312 23.8418 32.5312 23.6097V20.9847C32.5312 20.7527 32.4391 20.5301 32.275 20.366C32.1109 20.2019 31.8883 20.1097 31.6562 20.1097Z"
                  fill="#FFCC5C"
                />
                <path
                  d="M31.6562 20.1097H29.9062C30.1383 20.1097 30.3609 20.2019 30.525 20.366C30.6891 20.5301 30.7812 20.7527 30.7812 20.9847V23.6097C30.7812 23.7246 30.7586 23.8384 30.7146 23.9446C30.6707 24.0507 30.6062 24.1472 30.525 24.2285C30.4437 24.3097 30.3473 24.3742 30.2411 24.4181C30.1349 24.4621 30.0212 24.4847 29.9062 24.4847H31.6562C31.8883 24.4847 32.1109 24.3926 32.275 24.2285C32.4391 24.0644 32.5312 23.8418 32.5312 23.6097V20.9847C32.5312 20.7527 32.4391 20.5301 32.275 20.366C32.1109 20.2019 31.8883 20.1097 31.6562 20.1097Z"
                  fill="#F7B546"
                />
                <path
                  d="M24.2188 24.4847H30.7812V34.1097C30.7812 34.3418 30.6891 34.5644 30.525 34.7285C30.3609 34.8926 30.1383 34.9847 29.9062 34.9847H16.7812C16.5492 34.9847 16.3266 34.8926 16.1625 34.7285C15.9984 34.5644 15.9062 34.3418 15.9062 34.1097V24.4847H22.4688"
                  fill="#FFCC5C"
                />
                <path
                  d="M29.0312 24.4847H15.9062V26.2347H25.5312C26.4595 26.2347 27.3497 26.6035 28.0061 27.2599C28.6625 27.9162 29.0312 28.8065 29.0312 29.7347V34.9847H29.9062C30.1383 34.9847 30.3609 34.8926 30.525 34.7285C30.6891 34.5644 30.7812 34.3418 30.7812 34.1097V24.4847H29.0312Z"
                  fill="#F7B546"
                />
                <path
                  d="M21.1562 15.7347C20.869 15.7347 20.5845 15.7913 20.3191 15.9013C20.0537 16.0112 19.8126 16.1723 19.6095 16.3754C19.4063 16.5786 19.2452 16.8197 19.1353 17.0851C19.0253 17.3505 18.9688 17.635 18.9688 17.9222C18.9688 18.2095 19.0253 18.494 19.1353 18.7594C19.2452 19.0248 19.4063 19.2659 19.6095 19.469C19.8126 19.6722 20.0537 19.8333 20.3191 19.9432C20.5845 20.0532 20.869 20.1097 21.1562 20.1097H23.3438V17.9222C23.3438 17.3421 23.1133 16.7857 22.703 16.3754C22.2928 15.9652 21.7364 15.7347 21.1562 15.7347ZM22.4688 19.2347H21.1562C20.8967 19.2347 20.6429 19.1578 20.4271 19.0135C20.2112 18.8693 20.043 18.6643 19.9437 18.4245C19.8443 18.1847 19.8183 17.9208 19.869 17.6662C19.9196 17.4116 20.0446 17.1777 20.2282 16.9942C20.4117 16.8106 20.6456 16.6856 20.9002 16.635C21.1548 16.5843 21.4187 16.6103 21.6585 16.7096C21.8984 16.809 22.1033 16.9772 22.2476 17.1931C22.3918 17.4089 22.4688 17.6627 22.4688 17.9222V19.2347ZM25.5312 15.7347C24.9511 15.7347 24.3947 15.9652 23.9845 16.3754C23.5742 16.7857 23.3438 17.3421 23.3438 17.9222V20.1097H25.5312C25.8185 20.1097 26.103 20.0532 26.3684 19.9432C26.6338 19.8333 26.8749 19.6722 27.078 19.469C27.2812 19.2659 27.4423 19.0248 27.5522 18.7594C27.6622 18.494 27.7188 18.2095 27.7188 17.9222C27.7188 17.635 27.6622 17.3505 27.5522 17.0851C27.4423 16.8197 27.2812 16.5786 27.078 16.3754C26.8749 16.1723 26.6338 16.0112 26.3684 15.9013C26.103 15.7913 25.8185 15.7347 25.5312 15.7347ZM25.5312 19.2347H24.2188V17.9222C24.2188 17.6627 24.2957 17.4089 24.4399 17.1931C24.5842 16.9772 24.7892 16.809 25.029 16.7096C25.2688 16.6103 25.5327 16.5843 25.7873 16.635C26.0419 16.6856 26.2758 16.8106 26.4593 16.9942C26.6429 17.1777 26.7679 17.4116 26.8185 17.6662C26.8692 17.9208 26.8432 18.1847 26.7438 18.4245C26.6445 18.6643 26.4763 18.8693 26.2604 19.0135C26.0446 19.1578 25.7908 19.2347 25.5312 19.2347Z"
                  fill="#F04C4D"
                />
                <path
                  d="M25.5312 19.2348H25.9688C26.3168 19.2348 26.6507 19.0965 26.8968 18.8503C27.143 18.6042 27.2812 18.2704 27.2812 17.9223C27.2812 17.5742 27.143 17.2403 26.8968 16.9942C26.6507 16.748 26.3168 16.6098 25.9688 16.6098C25.8935 16.6098 25.8218 16.6194 25.75 16.6316C26.0761 16.684 26.3704 16.8577 26.574 17.1178C26.7775 17.378 26.8752 17.7055 26.8475 18.0346C26.8199 18.3638 26.6689 18.6703 26.4248 18.8929C26.1807 19.1154 25.8616 19.2375 25.5312 19.2348ZM21.1562 16.6098H20.7188C20.3707 16.6098 20.0368 16.748 19.7907 16.9942C19.5445 17.2403 19.4062 17.5742 19.4062 17.9223C19.4062 18.2704 19.5445 18.6042 19.7907 18.8503C20.0368 19.0965 20.3707 19.2348 20.7188 19.2348C20.794 19.2348 20.8657 19.2251 20.9375 19.2129C20.6114 19.1605 20.3171 18.9868 20.1135 18.7267C19.91 18.4665 19.8123 18.1391 19.84 17.8099C19.8676 17.4807 20.0186 17.1742 20.2627 16.9516C20.5068 16.7291 20.8259 16.607 21.1562 16.6098Z"
                  fill="#E13340"
                />
                <path
                  d="M22.4688 20.1097H24.2188V34.9847H22.4688V20.1097Z"
                  fill="#F04C4D"
                />
                <path
                  d="M29.8187 14.7722L31.2188 14.4222L29.8187 14.0722L29.4688 12.6722L29.1188 14.0722L27.7188 14.4222L29.1188 14.7722L29.4688 16.1722L29.8187 14.7722ZM31.3938 17.2222L32.0938 17.0472L31.3938 16.8722L31.2188 16.1722L31.0437 16.8722L30.3438 17.0472L31.0437 17.2222L31.2188 17.9222L31.3938 17.2222ZM32.2688 13.7222L32.9688 13.5472L32.2688 13.3722L32.0938 12.6722L31.9187 13.3722L31.2188 13.5472L31.9187 13.7222L32.0938 14.4222L32.2688 13.7222Z"
                  fill="#FFCC5C"
                />
              </svg>
            </ButtonCustom>
          )}

          <ModalBookingSubmittedDesk />
          <ModalAssignUser />
          <ModalAssignBookingSubmitted />
          <ModalRewards />
          <ModalInquireOffice
            open={inquireModal}
            onClose={() => setInquireModal(false)}
            moreInfo={{
              timezone: propertyDetail?.timezone,
              currency: propertyDetail.currency_code,
            }}
          />
        </>
      )}
    </MasterTemplate>
  );
};

export default BookProperty;
