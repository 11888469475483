import SizeBox from "../../../SizeBox";
import "./style.scss";
import { PUBLIC_URL } from "../../../../modules/App/configs/Constants";

type Props = {
  children: JSX.Element | JSX.Element[];
  title?: string;
  text?: string;
};

const SwalError = ({ children, title, text }: Props) => {
  return (
    <div className={"swal-error"}>
      <div className={"swal-icon"}>
        <img src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"} />
      </div>
      <SizeBox height={"8px"} />
      {title ? (
        <>
          <div className={"swal-error__title"}>{title}</div>
          <SizeBox height={"8px"} />
        </>
      ) : null}

      {text ? <div className={"swal-error__text"}>{text}</div> : null}

      {children}
    </div>
  );
};

export default SwalError;
