import {
  IS_MODAL_ADD_TEAM_MEMBER_ACTION,
  TEAM_MEMBER_FILTER_ACTION,
  TEAM_MEMBER_LIST_ACTION,
} from "../configs/Constants";

export const isModalAddTeamMemberAction = (data: boolean) => {
  return {
    type: IS_MODAL_ADD_TEAM_MEMBER_ACTION,
    payload: data,
  };
};

export const teamMemberListAction = (data: any) => {
  return {
    type: TEAM_MEMBER_LIST_ACTION,
    payload: data,
  };
};

export const teamMemberFilterAction = (data: object) => {
  return {
    type: TEAM_MEMBER_FILTER_ACTION,
    payload: data,
  };
};
