import React, { useContext, useEffect, useState } from "react";
import Table from "../../../../../components/Table";
import { LocalizationContext } from "../../../../../locales/Translation";
import Rating from "./Rating";
import { useParams } from "react-router-dom";
import { getDeskimoUserDetailReviewService } from "../../../services/EmployeeServices";
import InterestingService from "../../../../Property/views/PropertyDetail/Components/UserReviewTab/InterestingService";

const UserDetailReviews = () => {
  const { id } = useParams();
  const { translations } = useContext(LocalizationContext);

  const [userReview, setUserReview] = useState({
    average_score: 0,
    reviews: [],
    pagination: {
      page: 1,
      limit: 25,
      total: 0,
      last_page: 1,
      next_page: null,
      previous_page: null,
    },
  });
  const [reviewPagination, setReviewPagination] = useState(1);

  useEffect(() => {
    (async () => {
      if (!id) {
        return;
      }
      const { data } = await getDeskimoUserDetailReviewService(id, {
        page: reviewPagination,
      });
      if (data?.average_score !== undefined) {
        setUserReview(data);
      }
    })();
  }, [reviewPagination]);

  const _formatInputData = () => {
    return userReview.reviews.map(
      ({
        property_name,
        created_date,
        star,
        comment,
        extra_comment,
        good_services,
      }) => {
        return {
          property_name,
          created_date,
          rating: <Rating count={Number(star)} />,
          col4: (
            <InterestingService
              comment={extra_comment}
              services={good_services}
            />
          ),
          comment,
        };
      }
    );
  };

  const columns = [
    translations.property,
    translations.date,
    translations.rating,
    "Outstanding Service",
    { col: translations.comments, width: 6 },
  ];

  return (
    <>
      <h2 className={"section-white-box_title"}>
        {translations.reviewsLowercase}
      </h2>
      <p className={"user-review-average"}>
        {translations.averageRatingScore}: {userReview.average_score}
      </p>
      <Table
        tableClass={"user_detail_table user_detail_table_review"}
        columns={columns}
        tableData={_formatInputData()}
        showTotal={false}
        currentPage={userReview.pagination.page}
        itemPerPage={userReview.pagination.limit}
        totalItems={userReview.pagination.total}
        onChangePagination={(e: any, { activePage }: any) =>
          setReviewPagination(activePage)
        }
      />
    </>
  );
};

export default UserDetailReviews;
