import React, { useContext, useEffect, useState } from "react";
import ModalCenterTemplate from "../../../../../../../App/views/ModalCenterTemplate";
import { Message, Modal } from "semantic-ui-react";
import {
  modalManagePMSAction,
  modalMeetingRoomAssignCalendarAction,
} from "../../../../../../actions/PropertyActions";
import { useDispatch, useSelector } from "react-redux";
import { isModalMeetingRoomAssignSelector } from "../../../../../../selectors/PropertySelector";
import ButtonCustom from "../../../../../../../../components/Button";
import { LocalizationContext } from "../../../../../../../../locales/Translation";
import { PUBLIC_URL } from "../../../../../../../App/configs/Constants";
import DropdownCustom from "../../../../../../../../components/DropdownCustom";
import "./style.scss";
import Label from "../../../../../../../../components/Label";
import SizeBox from "../../../../../../../../components/SizeBox";
import {
  getListUnusedPMSRoomService,
  managePMSAssignCalendarService,
} from "../../../../../../services/ManagePMSServices";
import { managePMSRoomListSelector } from "../../../../../../selectors/PropertySelector";
import { useParams } from "react-router-dom";
import { getManagePMSRoomThunk } from "../../../../../../thunks/PropertyThunks";
import {
  toastError,
  toastSuccess,
} from "../../../../../../../../components/Toast";

const ModalAssignMeetingRoom = ({
  roomSelected,
  setRoomSelected,
  refreshList,
  setRefreshList,
}: any) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { translations } = useContext(LocalizationContext);
  const isModalMeetingRoomAssignCalendar = useSelector(
    isModalMeetingRoomAssignSelector
  );
  const [listPMSRooms, setListPMSRooms] = useState({
    meeting_rooms: [],
  });
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const [pmsRoomSelected, setPmsRoomSelected] = useState<any>(null);

  useEffect(() => {
    if (isModalMeetingRoomAssignCalendar) {
      _getManagePMSRoomList();
    }
  }, [isModalMeetingRoomAssignCalendar]);

  const _getManagePMSRoomList = async () => {
    try {
      setLoading(true);
      const payload = { property_id: id };
      const response = await getListUnusedPMSRoomService(payload);
      if (response?.status?.code !== 200) {
        toastError(response?.status?.message);
        return;
      }
      setListPMSRooms({ meeting_rooms: response?.data });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (listPMSRooms.meeting_rooms?.length > 0) {
      const decoratedOptions = listPMSRooms.meeting_rooms.map(
        (room: any, index: number) => {
          return {
            key: String(index),
            text: room?.name,
            value: room?.token,
          };
        }
      );

      setOptions(decoratedOptions);
    } else {
      setOptions([]);
    }
  }, [listPMSRooms]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const payload = {
        external_room_token: pmsRoomSelected,
        room_id: roomSelected?.id,
        property_id: id,
      };
      dispatch(modalMeetingRoomAssignCalendarAction(false));
      await managePMSAssignCalendarService(payload);
      toastSuccess("Connect successfully!");
      setRefreshList(!refreshList);
    } catch (error: any) {
      toastError(error?.status?.message);
      console.log("error: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalCenterTemplate
      trigger={null}
      onClose={() => dispatch(modalMeetingRoomAssignCalendarAction(false))}
      open={isModalMeetingRoomAssignCalendar}
      size={"md"}
      modalClassName={"modal-center-v2"}
    >
      <Modal.Header>
        Assign PMS Room
        <ButtonCustom
          onClick={() => dispatch(modalMeetingRoomAssignCalendarAction(false))}
          className={"m-0 p-0 none-bg close-modal"}
        >
          <>
            {translations.settings.close}
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Modal.Content>
        <Label
          label={"Choose PMS Room"}
          size={"12px"}
          fontSize={"12px"}
          className={"sm"}
        />
        {options?.length > 0 ? (
          <DropdownCustom
            scrolling
            selectUI={true}
            placeholder={"Please choose a meeting room"}
            className={"w-100 justify-content-between"}
            style={{ borderRadius: 4 }}
            options={options}
            onChange={(e, { value }) => {
              setPmsRoomSelected(value);
            }}
          />
        ) : (
          <Message warning style={{ padding: "8px 16px" }}>
            <Message.Header className={"justify-content-center"}>
              <div style={{ fontSize: 14 }}>No external room existed</div>
            </Message.Header>
          </Message>
        )}

        <SizeBox height={"21px"} />
      </Modal.Content>
      <Modal.Actions>
        <ButtonCustom
          className={"flex-1 m-0"}
          height={"47px"}
          onClick={() => dispatch(modalMeetingRoomAssignCalendarAction(false))}
        >
          {translations.settings.cancel}
        </ButtonCustom>
        <SizeBox width={"16px"} />
        <ButtonCustom
          disabled={options?.length === 0 || !pmsRoomSelected}
          loading={loading}
          type={"submit"}
          height={"47px"}
          className={"primary flex-1 m-0"}
          onClick={handleSubmit}
        >
          {translations.confirm}
        </ButtonCustom>
      </Modal.Actions>
    </ModalCenterTemplate>
  );
};

export default ModalAssignMeetingRoom;
