import React, { SyntheticEvent, useContext } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import DropdownWithCheckboxMenu from "../../../../../../components/DropdownWithCheckboxMenu";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { employeesFilterSelector } from "../../../../selectors/EmployeeSelectors";
import { employeesFilterAction } from "../../../../actions/EmployeeActions";
import {
  EMPLOYEE_ACTIVE_STATUS,
  EMPLOYEE_DELETE_STATUS,
  EMPLOYEE_PENDING_STATUS,
} from "../../../../configs/Constants";

const FilterEmployee = () => {
  const { translations } = useContext(LocalizationContext);

  const dispatch = useDispatch();
  const employeesFilter = useSelector(employeesFilterSelector);

  const employeeFilterTemp = [
    {
      id: EMPLOYEE_ACTIVE_STATUS,
      name:
        translations.accountStatusList[EMPLOYEE_ACTIVE_STATUS] +
        " " +
        translations.accounts,
    },
    {
      id: EMPLOYEE_DELETE_STATUS,
      name:
        translations.accountStatusList[EMPLOYEE_DELETE_STATUS] +
        " " +
        translations.accounts,
    },
    {
      id: EMPLOYEE_PENDING_STATUS,
      name:
        translations.accountStatusList[EMPLOYEE_PENDING_STATUS] +
        " " +
        translations.accounts,
    },
  ];

  const _onChangeFilter = (e: SyntheticEvent, data: any) => {
    if (data.checked) {
      const newValue = [...employeesFilter.statuses, data.value];
      dispatch(employeesFilterAction({ key: "statuses", value: newValue }));
    } else {
      const newValue = employeesFilter.statuses.filter(
        (status: number) => status !== data.value
      );
      dispatch(employeesFilterAction({ key: "statuses", value: newValue }));
    }
  };

  return (
    <div className={"dropdown-filter"}>
      <DropdownWithCheckboxMenu
        onChange={_onChangeFilter}
        listCheckBox={employeeFilterTemp.map((filter: any) => {
          if (employeesFilter.statuses.includes(filter.id)) {
            filter.checked = true;
          } else {
            filter.checked = false;
          }
          return filter;
        })}
        label={translations.filters}
        icon={
          <img
            alt={"Deskimo"}
            src={PUBLIC_URL + "/assets/icons/icon-filter.svg"}
          />
        }
      />
    </div>
  );
};

export default FilterEmployee;
