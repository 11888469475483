import { createReducer } from "@reduxjs/toolkit";
import {
  CAMPAIGN_DETAIL_ACTION,
  CAMPAIGN_FILTER_ACTION,
  CAMPAIGN_LIST_ACTION,
  CAMPAIGN_LIST_REMOVE_ITEM_ACTION,
  CAMPAIGN_LIST_UPDATE_STATUS_ACTION,
} from "../configs/Constants";
import { CampaignItemType } from "../configs/DeclareTypes";
import { ORDER_BY_NONE } from "../../App/configs/Constants";

const initialState = {
  campaignFilter: {
    keyword: "",
    page: 1,
    order_by: ORDER_BY_NONE,
    sort_by: null,
    filters: {
      start_time: null,
      end_time: null,
      status: null,
    },
    market_codes: [],
  },
  campaignList: null,
  campaignDetail: null,
};
const MarketingReducer = createReducer(initialState, {
  [CAMPAIGN_FILTER_ACTION]: (state, action) => {
    state.campaignFilter = { ...state.campaignFilter, ...action.payload };
  },
  [CAMPAIGN_LIST_ACTION]: (state, action) => {
    state.campaignList = action.payload;
  },

  [CAMPAIGN_LIST_REMOVE_ITEM_ACTION]: (state, action) => {
    if (!state.campaignList) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.campaignList.items = state.campaignList.items.filter(
      ({ id }: CampaignItemType) => id !== action.payload
    );
  },

  [CAMPAIGN_LIST_UPDATE_STATUS_ACTION]: (state, action) => {
    if (!state.campaignList) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.campaignList.items = state.campaignList.items.map(
      (item: CampaignItemType) => {
        if (item.id !== action.payload) {
          return item;
        }

        return {
          ...item,
          status: item.status ? 0 : 2,
          status_name: item.status ? "Unpublished" : "Published",
        };
      }
    );
  },

  [CAMPAIGN_DETAIL_ACTION]: (state, action) => {
    state.campaignDetail = action.payload;
  },
});

export default MarketingReducer;
