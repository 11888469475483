import React from "react";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import "./styles.scss";
import { PUBLIC_URL } from "../../modules/App/configs/Constants";
import classNames from "classnames";

interface Props extends DropdownProps {
  children?: JSX.Element | JSX.Element[];
  icon?: any;
  selectUI?: boolean;
  squareUI?: boolean;
}

const DropdownCustom = ({
  children,
  className,
  selectUI = false,
  squareUI,
  icon = (
    <img
      className={"dropdown icon"}
      src={PUBLIC_URL + "/assets/icons/icon-caret-down.svg"}
    />
  ),
  loading,
  ...props
}: Props) => {
  return (
    <Dropdown
      icon={loading ? undefined : icon}
      pointing={"top"}
      loading={loading}
      {...props}
      className={classNames("dropdown-custom", className, {
        "select-ui": selectUI,
        "square-ui": squareUI,
      })}
    >
      {children}
    </Dropdown>
  );
};

export default DropdownCustom;
