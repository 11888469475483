import React, { useState } from "react";
import { Dropdown, Menu, Space } from "antd";
import "./style.scss";
import ButtonCustom from "../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import SelectMarket from "./SelectMarket";
import SelectPricingRate from "./SelectPricingRate";
import SelectCapacity from "./SelectCapacity";
import SelectArea from "./SelectArea";
import Status from "./Status";
import SelectPropertyCompany from "./SelectPropertyCompany";
import SelectWorkspace from "./SelectWorkspace";
import {
  deskimoPrivateOfficeFiltersSelector,
  deskimoPrivateOfficeFiltersTmpSelector,
} from "../../../../selectors/PropertySelector";
import { useDispatch, useSelector } from "react-redux";
import {
  deskimoPrivateOfficeFilterAction,
  deskimoPrivateOfficeFilterTmpAction,
} from "../../../../actions/PropertyActions";
import { globalSettingSelector } from "../../../../../App/selectors/AppSelectors";

const FilterPrivateOffice = () => {
  const [open, setOpen] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const initFilterObj = {
    markets: [0],
    pricing_rates: {
      type: "",
      from: "",
      to: "",
    },
    capacity: "all",
    area: "all",
    companies: [],
    workspaces: [],
    status: [0, 1],
  };
  const filtersTmp = useSelector(deskimoPrivateOfficeFiltersTmpSelector);
  const dispatch = useDispatch();
  const globalSetting = useSelector(globalSettingSelector);

  const handleMenuClick = (e: any) => {
    if (e.key === "3") {
      setOpen(false);
    }
  };

  const onApplyFilter = () => {
    dispatch(deskimoPrivateOfficeFilterAction(filtersTmp));
    setOpen(false);
    setIsFiltered(true);
  };

  const onResetFilter = () => {
    setOpen(false);
    setIsFiltered(false);
    if (globalSetting?.location?.length > 0) {
      const checkAllArr = globalSetting?.location.map(
        (item: any) => item.city_code
      );
      dispatch(
        deskimoPrivateOfficeFilterTmpAction({
          ...filtersTmp,
          filter: {
            ...initFilterObj,
            markets: [...checkAllArr, "0"],
          },
        })
      );
      dispatch(
        deskimoPrivateOfficeFilterAction({
          ...filtersTmp,
          filter: {
            ...initFilterObj,
            markets: [...checkAllArr, "0"],
          },
        })
      );
    }
  };

  const items = [
    {
      key: "sub1",
      label: "Markets",
      expandIcon: (
        <img
          alt={"Deskimo"}
          src={PUBLIC_URL + "/assets/icons/icon-caret-down.svg"}
        />
      ),
      children: [
        {
          key: "1",
          label: <SelectMarket />,
        },
      ],
    },
    {
      key: "sub2",
      label: "Pricing Rates (USD)",
      expandIcon: (
        <img
          alt={"Deskimo"}
          src={PUBLIC_URL + "/assets/icons/icon-caret-down.svg"}
        />
      ),
      children: [
        {
          key: "5",
          label: <SelectPricingRate />,
        },
      ],
    },
    {
      key: "sub4",
      label: "Capacity",
      expandIcon: (
        <img
          alt={"Deskimo"}
          src={PUBLIC_URL + "/assets/icons/icon-caret-down.svg"}
        />
      ),
      children: [
        {
          key: "9",
          label: <SelectCapacity />,
        },
      ],
    },
    {
      key: "sub5",
      label: "Area",
      expandIcon: (
        <img
          alt={"Deskimo"}
          src={PUBLIC_URL + "/assets/icons/icon-caret-down.svg"}
        />
      ),
      children: [
        {
          key: "9",
          label: <SelectArea />,
        },
      ],
    },
    {
      key: "sub6",
      label: "Property Company",
      expandIcon: (
        <img
          alt={"Deskimo"}
          src={PUBLIC_URL + "/assets/icons/icon-caret-down.svg"}
        />
      ),
      children: [
        {
          key: "9",
          label: <SelectPropertyCompany />,
        },
      ],
    },
    {
      key: "sub7",
      label: "Workspace",
      expandIcon: (
        <img
          alt={"Deskimo"}
          src={PUBLIC_URL + "/assets/icons/icon-caret-down.svg"}
        />
      ),
      children: [
        {
          key: "9",
          label: <SelectWorkspace />,
        },
      ],
    },
    {
      key: "sub8",
      label: "Status",
      expandIcon: (
        <img
          alt={"Deskimo"}
          src={PUBLIC_URL + "/assets/icons/icon-caret-down.svg"}
        />
      ),
      children: [
        {
          key: "9",
          label: <Status />,
        },
      ],
    },
  ];

  const dropdownItems = [
    {
      key: "1",
      label: (
        <Menu
          style={{
            width: 256,
          }}
          defaultOpenKeys={[]}
          mode="inline"
          items={items}
        />
      ),
    },
    {
      key: "2",
      label: (
        <div className={"filter-actions"}>
          <ButtonCustom
            onClick={onResetFilter}
            className={"flex-1 outline-primary"}
          >
            Reset filter
          </ButtonCustom>
          <ButtonCustom onClick={onApplyFilter} className={"flex-1 primary"}>
            Apply filter
          </ButtonCustom>
        </div>
      ),
    },
  ];

  return (
    <Dropdown
      rootClassName={"filter-advance-dropdown"}
      menu={{
        items: dropdownItems,
        onClick: handleMenuClick,
      }}
      trigger={["click"]}
      open={open}
    >
      <>
        <ButtonCustom
          height={"44px"}
          className={"filter filter-cta " + (open || isFiltered ? "show" : "")}
          onClick={() => setOpen(!open)}
        >
          <span className={"mr-10px text"}>Filters</span>
          <img
            alt={"Deskimo"}
            src={PUBLIC_URL + "/assets/icons/icon-caret-down.svg"}
          />
        </ButtonCustom>
        <ButtonCustom
          style={{ textTransform: "uppercase" }}
          className={"link"}
          onClick={onResetFilter}
        >
          Clear Filters
        </ButtonCustom>
      </>
    </Dropdown>
  );
};

export default FilterPrivateOffice;
