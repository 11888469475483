import LoginTemplate from "../../../App/views/LoginTemplate";
import { useNavigate, Navigate } from "react-router-dom";
import {
  loginGeneralService,
  loginService,
} from "../../services/AuthenticationServices";
import { useDispatch, useSelector } from "react-redux";
import { isAuthAction } from "../../actions/AuthenticationActions";
import { isAuthSelector } from "../../selectors/AuthenticationSelectors";
import { ACCESS_TOKEN_CACHE, LOGIN_TIMES } from "../../../../configs/Constants";
import { useContext } from "react";
import { LocalizationContext } from "../../../../locales/Translation";
import LoginForm from "./Components/LoginForm";
import ForceLogin from "./Components/ForceLogin";
import { useQuery } from "../../../../utils/custom-hooks";
import React, { useEffect, useState } from "react";

type Props = {
  isForce?: boolean;
};

const Login = ({ isForce = false }: Props) => {
  const query = useQuery();
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuth = useSelector(isAuthSelector);
  const [forceLoginError, setForceLoginError] = useState(false);

  useEffect(() => {
    if (isForce && !isAuth) {
      _autoLogin();
    }
  }, []);

  const _autoLogin = async () => {
    try {
      const values: any = {
        token: query.get("token"),
      };
      const { access_token } = await loginService(values);
      localStorage.setItem(ACCESS_TOKEN_CACHE, access_token);
      dispatch(isAuthAction(true));
    } catch (error) {
      setForceLoginError(true);
    }
  };

  const _onLogin = async (values: object) => {
    const currentLoginTimes = localStorage.getItem(LOGIN_TIMES);
    if (currentLoginTimes) {
      localStorage.setItem(LOGIN_TIMES, String(Number(currentLoginTimes) + 1));
    } else {
      localStorage.setItem(LOGIN_TIMES, String(0));
    }

    try {
      await loginGeneralService(values, translations);
      const redirectUrl = sessionStorage.getItem("redirect_url");

      if (redirectUrl) {
        sessionStorage.removeItem("redirect_url");
        window.location.href = redirectUrl;
      }

      navigate("/", { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  if (isAuth) {
    return <Navigate to={"/"} />;
  }

  return (
    <LoginTemplate>
      <div className={"login__title"}>{translations.loginToYourAccount}</div>
      {isForce ? (
        <ForceLogin forceLoginError={forceLoginError} />
      ) : (
        <LoginForm onLogin={_onLogin} />
      )}
    </LoginTemplate>
  );
};

export default Login;
