import { Link, NavLink, useLocation } from "react-router-dom";
import {
  PUBLIC_URL,
  SHOW_TOPUP_NONE,
  SHOW_TOPUP_POPUP,
  SHOW_TOPUP_TRIAL_END_BY_EXPIRED_DATE,
  SHOW_TOPUP_TRIAL_END_BY_OUT_OF_CREDIT,
} from "../../../configs/Constants";
import ButtonCustom from "../../../../../components/Button";
import Divide from "../../../../../components/Divide";
import ProfileDropdown from "../ProfileDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  headerPopupToppingUpAction,
  modalNavbarMobileAction,
  modalPackageTopUpAction,
  userGuideAction,
} from "../../../actions/AppActions";
import AddEmployeeModal from "../../ModalAddEmployee";
import ImportEmployeeModal from "../../ModalImportEmployee";
import ModalAddVoucher from "../../ModalAddVoucher";
import {
  BOOK_DESK_DETAIL_LINK,
  BOOK_DESK_LINK,
  BOOK_ROOM_DETAIL_LINK,
  BOOK_ROOM_LINK,
  DASHBOARD_BUSINESS_LINK,
  DESK_BOOKING_LINK,
  ROOM_BOOKING_LINK,
} from "../../../configs/ListRoutes";
import React, { useContext, useEffect, useState } from "react";
import { myProfileSelector } from "../../../selectors/AppSelectors";
import ModalTopUp from "../../ModalTopUp";
import { SWAL_CUSTOM_CONTENT } from "../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../components/SweetAlert";
import { isMobile } from "../../../../../utils/commons";
import ModalNavbarMobile from "../../ModalNavBarMobile";
import { LocalizationContext } from "../../../../../locales/Translation";
import BookIcon from "../Components/BookIcon";
import PendingInvitePopup from "../../../../Dashboard/views/Components/PendingInvitePopup";
import CreditTopUp from "./CreditTopUp";
import AddEmployee from "./AddEmployee";
import Configuration from "./Configuration";
import { EXPIRE_DATE_TOPUP_POPUP } from "../../../../../configs/Constants";
import moment from "moment";
import { useQuery } from "../../../../../utils/custom-hooks";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const dispatch = useDispatch();
  const { translations } = useContext(LocalizationContext);
  const [bookNavActive, setBookNavActive] = useState<boolean>(false);
  const [bookingNavActive, setBookingNavActive] = useState<boolean>(false);
  const { pathname } = useLocation();
  const userProfile = useSelector(myProfileSelector);
  const query = useQuery();
  const navigate = useNavigate();

  const showPopupToppingUp = () => {
    const expiredDate = localStorage.getItem(EXPIRE_DATE_TOPUP_POPUP);
    if (expiredDate) {
      if (moment(parseInt(expiredDate)).isBefore(moment(), "day")) {
        localStorage.setItem(EXPIRE_DATE_TOPUP_POPUP, Date.now() + "");
        dispatch(headerPopupToppingUpAction(true));
      }
    } else {
      localStorage.setItem(EXPIRE_DATE_TOPUP_POPUP, Date.now() + "");
      dispatch(headerPopupToppingUpAction(true));
    }
  };

  useEffect(() => {
    const isShowTopUpPackage = query.get("topup");
    if (isShowTopUpPackage === "true") {
      dispatch(modalPackageTopUpAction(true));
      query.delete("topup");
      navigate(query.toString());
    } else {
      switch (userProfile?.force_show_topup) {
        case SHOW_TOPUP_NONE:
          break;
        case SHOW_TOPUP_POPUP:
          showPopupToppingUp();
          break;
        case SHOW_TOPUP_TRIAL_END_BY_EXPIRED_DATE:
          SweetAlertCommon({
            options: SWAL_CUSTOM_CONTENT({
              content: (
                <div className={"topup-expire"}>
                  <div className={"topup-expire__icon"}>
                    <img
                      src={PUBLIC_URL + "/assets/icons/icon-trial-expired.png"}
                      alt="topup"
                    />
                  </div>
                  <div className={"topup-expire__title"}>
                    {translations?.endTrialTitle}
                  </div>
                  <div className={"topup-expire__subtitle"}>
                    {translations?.endTrialSubtitle}
                  </div>
                </div>
              ),
              cancelButtonClass: "ui button default",
              denyButtonClass: "",
              confirmButtonClass: "ui button primary",
              wrapperClass: "topup-expire__wrapper",
              confirmButtonText: translations?.topUpAccount,
              denyButtonText: translations?.topUpLater,
              cancelButtonText: translations?.topUpLater,
            }),
            confirmCallback() {
              dispatch(modalPackageTopUpAction(true));
            },
            cancelCallback() {
              dispatch(headerPopupToppingUpAction(true));
            },
          });
          break;
        case SHOW_TOPUP_TRIAL_END_BY_OUT_OF_CREDIT:
          SweetAlertCommon({
            options: SWAL_CUSTOM_CONTENT({
              content: (
                <div className={"topup-expire"}>
                  <div className={"topup-expire__icon"}>
                    <img
                      src={PUBLIC_URL + "/assets/icons/icon-trial-expired.png"}
                      alt="topup"
                    />
                  </div>
                  <div className={"topup-expire__title"}>
                    {translations?.endCreditTitle}
                  </div>
                  <div className={"topup-expire__subtitle"}>
                    {translations?.endCreditSubtitle}
                  </div>
                </div>
              ),
              cancelButtonClass: "ui button default",
              denyButtonClass: "",
              confirmButtonClass: "ui button primary",
              wrapperClass: "topup-expire__wrapper",
              confirmButtonText: translations?.topUpAccount,
              denyButtonText: translations?.topUpLater,
              cancelButtonText: translations?.topUpLater,
            }),
            confirmCallback() {
              dispatch(modalPackageTopUpAction(true));
            },
            cancelCallback() {
              dispatch(headerPopupToppingUpAction(true));
            },
          });
          break;
        default:
          return;
      }
    }
  }, [userProfile]);

  useEffect(() => {
    if (
      pathname === BOOK_ROOM_LINK ||
      pathname.includes(BOOK_DESK_DETAIL_LINK.replaceAll(":id", "")) ||
      pathname.includes(BOOK_ROOM_DETAIL_LINK.replaceAll(":id", ""))
    ) {
      setBookNavActive(true);
    } else {
      setBookNavActive(false);
    }

    if (pathname === ROOM_BOOKING_LINK) {
      setBookingNavActive(true);
    } else {
      setBookingNavActive(false);
    }
  }, [location?.href]);

  return (
    <div className={"navbar"}>
      {isMobile() ? (
        <ButtonCustom
          onClick={() => dispatch(modalNavbarMobileAction(true))}
          className={"primary-alt navbar__toggle"}
          height={"37px"}
        >
          <i className="uil uil-bars" />
        </ButtonCustom>
      ) : (
        <Link to={DASHBOARD_BUSINESS_LINK} className={"navbar__logo"}>
          <img src={PUBLIC_URL + "/assets/logo.svg"} alt="deskimo-logo" />
        </Link>
      )}

      <div className={"navbar__left"}>
        <NavLink
          className={({ isActive }) =>
            bookNavActive || isActive ? "active guide-book" : "guide-book"
          }
          to={BOOK_DESK_LINK}
        >
          <BookIcon />
          {translations.book}
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            bookingNavActive || isActive ? "active" : ""
          }
          to={DESK_BOOKING_LINK}
        >
          <i className="uil uil-list-ul" />
          {translations.myActivities}
        </NavLink>

        <ButtonCustom
          className={"none-bg"}
          onClick={() => {
            navigate(DASHBOARD_BUSINESS_LINK);
            dispatch(userGuideAction({ key: "run", value: true }));
            dispatch(userGuideAction({ key: "stepIndex", value: 0 }));
          }}
        >
          <i
            className="uil uil-2x uil-info-circle"
            style={{ color: "black" }}
          />
        </ButtonCustom>
      </div>
      <div className={"navbar__right"}>
        <CreditTopUp />
        <Divide margin={"0 26px"} />

        <AddEmployee />

        <Configuration />

        <ProfileDropdown />
        <ModalTopUp />
        <AddEmployeeModal />
        <ImportEmployeeModal />
        <ModalAddVoucher />
        <PendingInvitePopup />

        {isMobile() ? <ModalNavbarMobile /> : null}
      </div>
    </div>
  );
};

export default NavBar;
