import React from "react";
import { usersFilterAction } from "../../../../actions/EmployeeActions";
import { useDispatch, useSelector } from "react-redux";
import { usersFilterSelector } from "../../../../selectors/EmployeeSelectors";
import { USERS_FILTER_MARKET } from "../../../../../../configs/Constants";
import FilterMarket from "../../../../../../components/FilterMarket";

const Market = () => {
  const dispatch = useDispatch();
  const usersFilter = useSelector(usersFilterSelector);

  return (
    <FilterMarket
      value={usersFilter.market_code}
      onChange={(value) => {
        dispatch(usersFilterAction({ ...usersFilter, market_code: value }));
      }}
    />
  );
};
export default Market;
