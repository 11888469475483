import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import {
  configMarketCommissionShareService,
  configMarketExchangeRateListService,
  configMarketGeneralInformationService,
  configMarketLandmarkListService,
  configMarketListService,
  configMarketPublicHolidaysListService,
  getAccountingListService,
  getMarketToggleService,
  getPartnerCredentialInfoService,
  getPartnerWebhookListService,
  getRolePermissionService,
  partnerLimitationService,
  tagsListService,
} from "../services/ConfigServices";
import {
  configMarketCommissionShareAction,
  configMarketExchangeRateListAction,
  configMarketGeneralInformationAction,
  configMarketLandmarkListAction,
  configMarketListAction,
  configMarketPublicHolidaysListAction,
  configTagsListAction,
  getAccountingAction,
  getRolePermissionAction,
  marketToggleListAction,
  partnerCredentialInfoAction,
  partnerLimitationAction,
  partnerWebhookListAction,
} from "../actions/ConfigActions";

export const configMarketListThunk = (data: any) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await configMarketListService(data);
    dispatch<any>(configMarketListAction(response.data));
  };
};

export const configMarketExchangeRateListThunk = (data: any) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await configMarketExchangeRateListService(data);
    dispatch<any>(
      configMarketExchangeRateListAction(
        Array.isArray(response.data) ? null : response.data
      )
    );
  };
};

export const configMarketCommissionShareThunk = (data: any) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await configMarketCommissionShareService(data);
    dispatch<any>(configMarketCommissionShareAction(response.data));
  };
};

export const configMarketGeneralInformationThunk = (id: any) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await configMarketGeneralInformationService(id);
    dispatch<any>(configMarketGeneralInformationAction(response.data));
  };
};

export const configMarketPublicHolidaysListThunk = (data: any) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await configMarketPublicHolidaysListService(data);
    dispatch<any>(configMarketPublicHolidaysListAction(response.data));
  };
};

export const configMarketLandmarkListThunk = (data: any) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await configMarketLandmarkListService(data);
    dispatch<any>(configMarketLandmarkListAction(response.data));
  };
};

export const configTagsListThunk = (payload: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await tagsListService(payload);
    dispatch<any>(configTagsListAction(response.data));
  };
};

export const marketToggleListThunk = () => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await getMarketToggleService();
    dispatch<any>(marketToggleListAction(response.data));
  };
};

export const partnerCredentialInfoThunk = (companyId: string) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await getPartnerCredentialInfoService(companyId);
    dispatch<any>(partnerCredentialInfoAction(response.data));
  };
};

export const partnerWebhookListThunk = (companyId: string) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await getPartnerWebhookListService(companyId);
    dispatch<any>(partnerWebhookListAction(response.data));
  };
};

export const partnerLimitationThunk = (companyId: string) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await partnerLimitationService(companyId);
    dispatch<any>(partnerLimitationAction(response.data));
  };
};

export const getRolePermissionThunk = () => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await getRolePermissionService();
    dispatch<any>(getRolePermissionAction(response.data));
  };
};

export const getAccountingThunk = (data: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await getAccountingListService(data);
    dispatch<any>(getAccountingAction(response.data));
  };
};
