import React, { useContext, useEffect, useState } from "react";
import TableCustom from "../../../../../components/Table";
import { LocalizationContext } from "../../../../../locales/Translation";
import SizeBox from "../../../../../components/SizeBox";
import {
  dashboardFilterDateRangeSelector,
  dashboardPropertyChooseSelector,
  dashboardRoomBookingFilterSelector,
  dashboardRoomBookingSelector,
  filterListPropertySelectedSelector,
} from "../../../selectors/DashboardSelectors";
import { useDispatch, useSelector } from "react-redux";
import { RoomBookingType } from "../../../configs/DeclareTypes";
import {
  dashboardRoomBookingChangeStatusAction,
  dashboardRoomBookingFilterAction,
} from "../../../actions/DashboardActions";
import { dashboardRoomBookingPropertyThunk } from "../../../thunks/DashboardThunks";
import { userCompanyChooseSelector } from "../../../../User/selectors/UserSelectors";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../../App/configs/Constants";
import BookingAction from "./components/BookingAction";
import { usePermissionCheck } from "../../../../../utils/custom-hooks";
import { UI_ACCESS_TRANSACTION_BY_FILTER_PER } from "../../../../../utils/PermissionConstant";
import { ROOM_BOOKING } from "../../../configs/Constants";
import ReadMore from "../../../../../components/ReadMore";

const TableRoomBookings = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const dashboardRoomBooking = useSelector(dashboardRoomBookingSelector);
  const dashboardRoomBookingFilter = useSelector(
    dashboardRoomBookingFilterSelector
  );
  const userCompanyChoose = useSelector(userCompanyChooseSelector);
  const dashboardFilterDateRange = useSelector(
    dashboardFilterDateRangeSelector
  );
  const filterListPropertySelected = useSelector(
    filterListPropertySelectedSelector
  );
  const [refresh, setRefresh] = useState(false);

  const dashboardPropertyChoose = useSelector(dashboardPropertyChooseSelector);
  const propertyContext = usePermissionCheck(
    UI_ACCESS_TRANSACTION_BY_FILTER_PER
  );

  if (propertyContext) {
    useEffect(() => {
      (async () => {
        await _init();
      })();
    }, [
      dashboardRoomBookingFilter,
      userCompanyChoose,
      dashboardFilterDateRange,
      dashboardPropertyChoose,
      refresh,
      filterListPropertySelected,
    ]);
  } else {
    useEffect(() => {
      (async () => {
        await _init();
      })();
    }, [
      dashboardRoomBookingFilter,
      userCompanyChoose,
      dashboardFilterDateRange,
      refresh,
      filterListPropertySelected,
    ]);
  }

  const _init = async () => {
    try {
      if (dashboardFilterDateRange === null) {
        return;
      }
      setLoading(true);
      await dispatch<any>(dashboardRoomBookingPropertyThunk(propertyContext));
    } catch (e) {
      console.log("e", e);
    } finally {
      setLoading(false);
    }
  };

  const meetingRoomField = ({
    meeting_room_name,
    property_name,
    booking_note,
  }: {
    meeting_room_name: string;
    property_name: string;
    booking_note?: string;
  }) => (
    <>
      <div className={"table_title"}>{translations.meetingRoom}:</div>
      <div>{meeting_room_name}</div>
      <SizeBox height={"10px"} />
      <div className={"table_title"}>{translations.property}:</div>
      <div>{property_name}</div>
      {booking_note ? (
        <>
          <SizeBox height={"10px"} />
          <div className={"table_title"}>{translations.note}:</div>
          <ReadMore>{booking_note}</ReadMore>
        </>
      ) : null}
    </>
  );

  const dateTimeField = ({
    start_time,
    duration,
    created_time,
  }: {
    start_time: number;
    duration: string;
    created_time: string;
  }) => (
    <>
      <div className={"table_title"}>{translations.createdTime}:</div>
      <div>{created_time}</div>
      <SizeBox height={"10px"} />
      <div className={"table_title"}>{translations.startTime}:</div>
      <div>{start_time}</div>
      <SizeBox height={"10px"} />
      <div className={"table_title"}>{translations.duration}:</div>
      <div>{duration}</div>
    </>
  );

  const costField = ({
    hourly_rate,
    total_price,
  }: {
    hourly_rate: string;
    total_price: string;
  }) => (
    <>
      <div className={"table_title"}>{translations.hourlyRate}:</div>
      <div>{hourly_rate}</div>
      <SizeBox height={"10px"} />
      <div className={"table_title"}>{translations.totalPrice}:</div>
      <div>{total_price}</div>
    </>
  );

  const statusField = ({
    status_id,
    refund_amount,
    refund_status_id,
  }: {
    status_id: number;
    refund_amount: string | null;
    refund_status_id: number | null;
  }) => (
    <>
      <div>{translations.roomBookingStatus[status_id]}</div>
      {refund_status_id ? (
        <div>
          ({translations.roomBookingRefundStatus[refund_status_id]}
          {refund_amount ? ` - ${refund_amount}` : ""})
        </div>
      ) : null}
    </>
  );

  const _formatInputData = () => {
    return dashboardRoomBooking.items.map(
      ({
        transaction_id,
        employee_name,
        meeting_room_name,
        property_name,
        start_time,
        duration,
        hourly_rate,
        total_price,
        refund_amount,
        refund_status_id,
        // note,
        status_id,
        actions,
        id,
        created_time,
        booking_note,
      }: RoomBookingType) => {
        return {
          transaction_id,
          employee_name,
          meetingRoomField: meetingRoomField({
            property_name,
            meeting_room_name,
            booking_note,
          }),
          dateTimeField: dateTimeField({ created_time, start_time, duration }),
          costField: costField({ hourly_rate, total_price }),
          // note: note || "-",
          statusField: statusField({
            status_id,
            refund_amount,
            refund_status_id,
          }),
          action: (
            <BookingAction
              refresh={refresh}
              setRefresh={setRefresh}
              type={ROOM_BOOKING}
              statusId={status_id}
              bookingId={id}
              actions={actions}
              onCallback={(status) =>
                dispatch(
                  dashboardRoomBookingChangeStatusAction({
                    id,
                    statusId: status,
                  })
                )
              }
            />
          ),
        };
      }
    );
  };

  const columns = [
    { key: "meeting_room_by_transaction_id", col: translations.transactionID },
    { key: "meeting_room_by_employee_name", col: translations.userName },
    { key: "meeting_room_by_meeting_room", col: translations.meetingRoom },
    { key: "meeting_room_by_start_time", col: translations.dateTime },
    { key: "meeting_room_by_cost", col: translations.revenue },
    // { key: "meeting_room_by_note", col: translations.note },
    { key: "meeting_room_by_status", col: translations.status },
    { key: "meeting_room_by_action", col: translations.action },
  ];

  const _onChangeSortCol = (col: any) => {
    if (dashboardRoomBookingFilter.sort_by !== col.key) {
      dispatch(
        dashboardRoomBookingFilterAction({ key: "sort_by", value: col.key })
      );
      dispatch(
        dashboardRoomBookingFilterAction({
          key: "order_by",
          value: ORDER_BY_ASC,
        })
      );
    } else {
      dispatch(
        dashboardRoomBookingFilterAction({ key: "sort_by", value: col.key })
      );
      if (dashboardRoomBookingFilter.order_by === ORDER_BY_NONE) {
        dispatch(
          dashboardRoomBookingFilterAction({
            key: "order_by",
            value: ORDER_BY_ASC,
          })
        );
      }
      if (dashboardRoomBookingFilter.order_by === ORDER_BY_ASC) {
        dispatch(
          dashboardRoomBookingFilterAction({
            key: "order_by",
            value: ORDER_BY_DESC,
          })
        );
      }
      if (dashboardRoomBookingFilter.order_by === ORDER_BY_DESC) {
        dispatch(
          dashboardRoomBookingFilterAction({
            key: "order_by",
            value: ORDER_BY_NONE,
          })
        );
      }
    }
  };

  return (
    <TableCustom
      orderBy={dashboardRoomBookingFilter.order_by}
      sortBy={dashboardRoomBookingFilter.sort_by}
      sortTable={true}
      onChangeSortCol={_onChangeSortCol}
      tableData={dashboardRoomBooking ? _formatInputData() : []}
      columns={columns}
      loading={loading}
      totalItems={dashboardRoomBooking?.pagination?.total}
      currentPage={dashboardRoomBooking?.pagination?.page}
      showTotal
      unit={translations.transactions}
      itemPerPage={dashboardRoomBooking?.pagination?.limit}
      onChangePagination={(e: any, { activePage }: any) => {
        dispatch(
          dashboardRoomBookingFilterAction({ key: "page", value: activePage })
        );
      }}
    />
  );
};

export default TableRoomBookings;
