export const paymentHistorySelector = (state: any) => {
  return state.SettingReducer.paymentHistory;
};

export const invoiceDetailSelector = (state: any) => {
  return state.SettingReducer.invoiceDetail;
};

export const monthlyUsageSelector = (state: any) => {
  return state.SettingReducer.monthlyUsage;
};

export const paymentMethodSelector = (state: any) => {
  return state.SettingReducer.paymentMethod;
};

export const billingContactSelector = (state: any) => {
  return state.SettingReducer.billingContact;
};

export const billingInfoSelector = (state: any) => {
  return state.SettingReducer.billingInfo;
};

export const isModalUpdatePaymentMethodSelector = (state: any) => {
  return state.SettingReducer.isModalUpdatePaymentMethod;
};

export const isModalAddCreditCardSelector = (state: any) => {
  return state.SettingReducer.isModalAddCreditCard;
};

export const isModalBillingContactSelector = (state: any) => {
  return state.SettingReducer.isModalBillingContact;
};

export const isModalAddBillingContactSelector = (state: any) => {
  return state.SettingReducer.isModalAddBillingContact;
};

export const isModalBillingInformationSelector = (state: any) => {
  return state.SettingReducer.isModalBillingInformation;
};

export const settingSelector = (state: any) => {
  return state.SettingReducer.settings;
};

export const propertiesSelector = (state: any) => {
  return state.SettingReducer.properties;
};

export const paymentHistoryFilterSelector = (state: any) => {
  return state.SettingReducer.paymentHistoryFilters;
};

export const affiliateListSelector = (state: any) => {
  return state.SettingReducer.affiliateList;
};
