import React from "react";
import Label from "../../../../../components/Label";
import { FormikValues } from "formik";
import SizeBox from "../../../../../components/SizeBox";
import DropzoneContract from "../../../../Property/views/PropertyCompanyDetail/Components/DropzoneContract";

type Props = {
  values: FormikValues;
  setFieldValue: any;
};

const Contract = ({ values, setFieldValue }: Props) => {
  return (
    <>
      <Label label={"Contract"} fontSize={"24px"} className={"section-title"} />
      <DropzoneContract
        onChangeUri={(uri: string) => setFieldValue("contract_file", uri)}
        contractFile={values.contract_file}
      />

      <SizeBox height={"24px"} />
      <div className={"property_company_detail_line"} />
    </>
  );
};

export default Contract;
