import React, { useContext } from "react";
import { Modal } from "semantic-ui-react";
import ModalCenterTemplate from "../../../../../../App/views/ModalCenterTemplate";
import { Formik, FormikValues } from "formik";
import * as yup from "yup";
import ButtonCustom from "../../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../../App/configs/Constants";
import InputCustom from "../../../../../../../components/Input";
import SizeBox from "../../../../../../../components/SizeBox";
import { propertyNotesService } from "../../../../../services/PropertyServices";
import { toastSuccess } from "../../../../../../../components/Toast";
import { LocalizationContext } from "../../../../../../../locales/Translation";
import { useParams } from "react-router-dom";
import TextAreaCustom from "../../../../../../../components/Input/TextArea";

type Props = {
  data: any;
  onClose: () => void;
  onRefresh: () => void;
};
const ModalAddEditNote = ({ onClose, data, onRefresh }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const { id } = useParams();

  const _onSubmit = async (values: FormikValues) => {
    try {
      const payload: any = {
        ...values,
        property_id: id,
      };

      if (data?.id) {
        payload.note_id = data?.id;
      }

      await propertyNotesService(payload);
      if (data?.id) {
        toastSuccess(translations.editSuccessPrompt);
      } else {
        toastSuccess(translations.createSuccessPrompt);
      }
      onRefresh();
      onClose();
    } catch (e) {
      if (data?.id) {
        toastSuccess(translations.editFailPrompt);
      } else {
        toastSuccess(translations.createFailPrompt);
      }
    }
  };

  return (
    <ModalCenterTemplate
      size={"md"}
      open={data.open}
      onClose={onClose}
      modalClassName={"property-note-modal"}
    >
      <Formik
        enableReinitialize
        initialValues={{
          title: data?.title || "",
          comment: data?.comment || "",
        }}
        validationSchema={yup.object().shape({
          title: yup.string().required().label(translations.title),
          comment: yup.string().required().label(translations.comments),
        })}
        onSubmit={_onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => (
          <>
            <Modal.Header className={"ui"}>
              <span>
                {data?.id ? translations.editNote : translations.newNote}
              </span>
              <ButtonCustom
                height={"28px"}
                onClick={onClose}
                className={"modal-close-cta"}
              >
                <>
                  {translations.close}
                  <SizeBox width={"8px"} />
                  <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
                </>
              </ButtonCustom>
            </Modal.Header>
            <Modal.Content>
              <form className={"form-layout"}>
                <InputCustom
                  label={translations.title}
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.title}
                  error={errors.title}
                  name={"title"}
                />
                <TextAreaCustom
                  label={translations.comments}
                  value={values.comment}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.comment}
                  error={errors.comment}
                  name={"comment"}
                />
              </form>
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom
                height={"47px"}
                onClick={() => {
                  resetForm();
                  onClose();
                }}
              >
                {translations.close}
              </ButtonCustom>
              <SizeBox width={"16px"} />
              <ButtonCustom
                loading={isSubmitting}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {data?.id ? translations.saveChanges : translations.createNote}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalCenterTemplate>
  );
};

export default ModalAddEditNote;
