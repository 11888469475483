export const PROPERTY_LIST_ACTION = "PROPERTY_LIST_ACTION";

export const DESKIMO_PROPERTY_LIST_ACTION = "DESKIMO_PROPERTY_LIST_ACTION";
export const DESKIMO_PROPERTY_COMPANY_LIST_ACTION =
  "DESKIMO_PROPERTY_COMPANY_LIST_ACTION";
export const DESKIMO_PROPERTY_STAFF_LIST_ACTION =
  "DESKIMO_PROPERTY_STAFF_LIST_ACTION";
export const DESKIMO_PROPERTY_REWARD_LIST_ACTION =
  "DESKIMO_PROPERTY_REWARD_LIST_ACTION";

export const DESKIMO_PROPERTY_COMPANY_DELETE_ACTION =
  "DESKIMO_PROPERTY_COMPANY_DELETE_ACTION";

export const DESKIMO_PROPERTY_LIST_FILTER_ACTION =
  "DESKIMO_PROPERTY_LIST_FILTER_ACTION";

export const DESKIMO_PROPERTY_LIST_FILTER_APPLY_ALL_ACTION =
  "DESKIMO_PROPERTY_LIST_FILTER_APPLY_ALL_ACTION";

export const DESKIMO_PROPERTY_LIST_FILTER_RESET_ACTION =
  "DESKIMO_PROPERTY_LIST_FILTER_RESET_ACTION";
export const DESKIMO_PROPERTY_COMPANY_LIST_FILTER_ACTION =
  "DESKIMO_PROPERTY_COMPANY_LIST_FILTER_ACTION";

export const DESKIMO_PROPERTY_COMPANY_LIST_FILTER_APPLY_ALL_ACTION =
  "DESKIMO_PROPERTY_COMPANY_LIST_FILTER_APPLY_ALL_ACTION";
export const DESKIMO_PROPERTY_COMPANY_LIST_FILTER_RESET_ACTION =
  "DESKIMO_PROPERTY_COMPANY_LIST_FILTER_RESET_ACTION";
export const DESKIMO_PROPERTY_STAFF_LIST_FILTER_ACTION =
  "DESKIMO_PROPERTY_STAFF_LIST_FILTER_ACTION";

export const DESKIMO_PROPERTY_STAFF_LIST_FILTER_APPLY_ALL_ACTION =
  "DESKIMO_PROPERTY_STAFF_LIST_FILTER_APPLY_ALL_ACTION";
export const DESKIMO_PROPERTY_STAFF_LIST_FILTER_RESET_ACTION =
  "DESKIMO_PROPERTY_STAFF_LIST_FILTER_RESET_ACTION";
export const DESKIMO_PROPERTY_STAFF_DELETE_ACTION =
  "DESKIMO_PROPERTY_STAFF_DELETE_ACTION";
export const DESKIMO_PROPERTY_STAFF_CHANGE_STATUS_ACTION =
  "DESKIMO_PROPERTY_STAFF_CHANGE_STATUS_ACTION";

export const DESKIMO_PROPERTY_AMENITY_LIST_ACTION =
  "DESKIMO_PROPERTY_AMENITY_LIST_ACTION";
export const DESKIMO_PROPERTY_AMENITY_DETAIL_ACTION =
  "DESKIMO_PROPERTY_AMENITY_DETAIL_ACTION";
export const MODAL_ADD_EDIT_AMENITY_ACTION = "MODAL_ADD_EDIT_AMENITY_ACTION";
export const DESKIMO_PROPERTY_AMENITY_DELETE_ACTION =
  "DESKIMO_PROPERTY_AMENITY_DELETE_ACTION";

export const DESKIMO_PROPERTY_SCANNER_FILTER_ACTION =
  "DESKIMO_PROPERTY_SCANNER_FILTER_ACTION";

export const DESKIMO_PROPERTY_SCANNER_LIST_ACTION =
  "DESKIMO_PROPERTY_SCANNER_LIST_ACTION";
export const MODAL_PROPERTY_SCANNER_QR_CODE_ACTION =
  "MODAL_PROPERTY_SCANNER_QR_CODE_ACTION";
export const DESKIMO_PROPERTY_SCANNER_QR_CODE_ASSIGN_ACTION =
  "DESKIMO_PROPERTY_SCANNER_QR_CODE_ASSIGN_ACTION";
export const DESKIMO_PROPERTY_SCANNER_TOKEN_ASSIGN_ACTION =
  "DESKIMO_PROPERTY_SCANNER_TOKEN_ASSIGN_ACTION";
export const DESKIMO_PROPERTY_SCANNER_DETAIL_ACTION =
  "DESKIMO_PROPERTY_SCANNER_DETAIL_ACTION";
export const DESKIMO_PROPERTY_SCANNER_CHANGE_STATUS_ACTION =
  "DESKIMO_PROPERTY_SCANNER_CHANGE_STATUS_ACTION";

export const MEETING_ROOM_LIST_ACTION = "MEETING_ROOM_LIST_ACTION";
export const PROPERTY_DETAIL_ACTION = "PROPERTY_DETAIL_ACTION";
export const MEETING_ROOM_DETAIL_ACTION = "MEETING_ROOM_DETAIL_ACTION";
export const UPDATE_MEETING_ROOM_ACTION = "UPDATE_MEETING_ROOM_ACTION";
export const USER_REVIEW_ACTION = "USER_REVIEW_ACTION";
export const MEETING_ROOM_ASSIGN_ACTION = "MEETING_ROOM_CREATE_ACTION";

export const MANAGE_PMS_ROOM_LIST_ACTION = "MANAGE_PMS_ROOM_LIST_ACTION";
export const MANAGE_PMS_ADD_CALENDAR_ACTION = "MANAGE_PMS_ADD_CALENDAR_ACTION";
export const MANAGE_PMS_DELETE_ROOM_ACTION = "MANAGE_PMS_DELETE_ROOM_ACTION";

export const MODAL_MANAGE_PMS_ACTION = "MODAL_MANAGE_PMS_ACTION";
export const MODAL_MANAGE_PMS_ADD_ROOM_ACTION =
  "MODAL_MANAGE_PMS_ADD_ROOM_ACTION";
export const MODAL_MANAGE_PMS_ADD_CALENDAR_ACTION =
  "MODAL_MANAGE_PMS_ADD_CALENDAR_ACTION";
export const UPDATE_MANAGE_PMS_AUTO_CONFIRM_ACTION =
  "UPDATE_MANAGE_PMS_AUTO_CONFIRM_ACTION";
export const MODAL_MEETING_ROOM_UPDATE_ACTION =
  "MODAL_MEETING_ROOM_UPDATE_ACTION";
export const MODAL_MEETING_ROOM_CREATE_ACTION =
  "MODAL_MEETING_ROOM_CREATE_ACTION";
export const MODAL_MEETING_ROOM_ASSIGN_ACTION =
  "MODAL_MEETING_ROOM_ASSIGN_ACTION";

export const MODAL_ADD_STAFF_ACTION = "MODAL_ADD_STAFF_ACTION";
export const MODAL_CREATE_STAFF_ACTION = "MODAL_CREATE_STAFF_ACTION";
export const MODAL_DESKIMO_WORKSPACE_STAFF_LIST =
  "MODAL_DESKIMO_WORKSPACE_STAFF_LIST";

export const STAFF_DETAIL_ACTION = "STAFF_DETAIL_ACTION";

export const GET_PROPERTY_CATEGORIES_ACTION = "GET_PROPERTY_CATEGORIES_ACTION";
export const GET_DESKIMO_USER_REVIEWS_ACTION =
  "GET_DESKIMO_USER_REVIEWS_ACTION";

export const GET_PRIVATE_OFFICE_LIST_ACTION = "GET_PRIVATE_OFFICE_LIST_ACTION";
export const FILTER_PRIVATE_OFFICE_LIST_ACTION =
  "FILTER_PRIVATE_OFFICE_LIST_ACTION";
export const FILTER_TMP_PRIVATE_OFFICE_LIST_ACTION =
  "FILTER_TMP_PRIVATE_OFFICE_LIST_ACTION";
export const EXPORT_PRIVATE_OFFICE_LIST_ACTION =
  "EXPORT_PRIVATE_OFFICE_LIST_ACTION";

//Tab Integrations
export const GET_QRCODE_LIST_ACTION = "GET_QRCODE_LIST_ACTION";
export const CREATE_QRCODE_ACTION = "CREATE_QRCODE_ACTION";
export const UPDATE_QRCODE_ACTION = "UPDATE_QRCODE_ACTION";
export const CHANGE_STATUS_QRCODE_ACTION = "CHANGE_STATUS_QRCODE_ACTION";
export const REMOVE_QRCODE_ACTION = "REMOVE_QRCODE_ACTION";
export const GET_API_LIST_ACTION = "GET_API_LIST_ACTION";
export const GET_WEBHOOK_LIST_ACTION = "GET_WEBHOOK_LIST_ACTION";
export const DESKIMO_PRIVATE_OFFICE_MODAL_ACTION =
  "DESKIMO_PRIVATE_OFFICE_MODAL_ACTION";
export const GET_PARTNER_LOG_ACTION = "GET_PARTNER_LOG_ACTION";

export const MODAL_REWARD_ACTION = "MODAL_REWARD_ACTION";
export const REWARD_LIST_ACTION = "REWARD_LIST_ACTION";
