import Map from "../../../../../../../components/Map";
import { usePlacesWidget } from "react-google-autocomplete";
import axios from "axios";
import { GET_ADDRESS_BY_GOOGLE_MAPS_API } from "../../../../../configs/Endpoints";
import React from "react";
import Label from "../../../../../../../components/Label";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import { FormikHandleChangeType } from "../../../../../configs/DeclareTypes";
import SizeBox from "../../../../../../../components/SizeBox";

type Props = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  setFieldValue: any;
};

const MapSection = ({ values, errors, touched, setFieldValue }: Props) => {
  const { ref }: any = usePlacesWidget({
    onPlaceSelected: (place) => {
      onPlaceChanged(place);
    },
    options: {
      types: ["establishment"],
      fields: ["place_id", "geometry", "name"],
    },
  });

  const onPlaceChanged = async (place: any) => {
    if (!place.geometry) {
      return;
    }
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    setFieldValue("lat", lat);
    setFieldValue("lng", lng);
    setFieldValue("location", ref?.current?.value);
  };

  const _markerOnDragEnd = async (e: any) => {
    try {
      const lat = e.latLng.lat();
      const long = e.latLng.lng();
      axios
        .get(GET_ADDRESS_BY_GOOGLE_MAPS_API({ lat, long }))
        .then((response) => {
          const results = response?.data?.results;
          const address = results[0].formatted_address;
          setFieldValue("location", address);
          setFieldValue("lat", lat);
          setFieldValue("lng", long);
        });
    } catch (e) {
      console.error("error: ", e);
    }
  };

  return (
    <>
      <div>
        <div className={"ui input w-100"}>
          <div className="input-component mb-0 w-100">
            <Label label="Location" fontSize="12px" required />
            <div className="input-component">
              <div className={"input-wrapper"} style={{ height: 44 }}>
                <div className={"ui input"} style={{ height: "100%" }}>
                  <input
                    ref={ref}
                    value={values.location}
                    onChange={({ target }) => {
                      setFieldValue("location", target.value);
                    }}
                  />
                </div>
              </div>
              {touched.location && errors.location ? (
                <>
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/*@ts-ignore*/}
                  <span className={"error"}>{errors.location}</span>
                  <SizeBox height={"10px"} />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Map
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        lat={values.lat}
        lng={values.lng}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div className={"gg-map"} />}
        mapElement={<div style={{ height: `100%` }} />}
        draggable
        onDragEnd={_markerOnDragEnd}
      />
    </>
  );
};

export default MapSection;
