import React, { useCallback, useEffect, useState } from "react";
import SearchBar4Table from "./index";
import { debounce } from "lodash";

type Props = {
  placeholder?: string;
  initialValue?: string;
  onChange: (value: string) => void;
};
const SearchWithDebounce = ({ placeholder, initialValue, onChange }: Props) => {
  const [value, setValue] = useState(initialValue || "");

  useEffect(() => {
    debounce_onChange(value);
  }, [value]);

  const debounce_onChange = useCallback(
    debounce((value: string) => {
      onChange(value);
    }, 500),
    []
  );

  return (
    <SearchBar4Table
      value={value}
      placeholder={placeholder}
      onChange={(e, { value }) => setValue(value)}
    />
  );
};

export default SearchWithDebounce;
