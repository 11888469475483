import clientRequestService from "../../../services/ClientRequestService";
import {
  API_CREATE_MEDIA_FILE,
  API_DELETE_MEDIA_FILE,
  API_LIST_MEDIA_FILES,
  API_UPDATE_MEDIA_FILE,
} from "../configs/Endpoints";

export const getListMediaFileService = async (data: object) => {
  return clientRequestService({
    method: "post",
    url: API_LIST_MEDIA_FILES,
    data,
  });
};

export const uploadMediaFileService = async (data: object) => {
  return clientRequestService({
    method: "post",
    url: API_CREATE_MEDIA_FILE,
    data,
  });
};

export const deleteMediaFileService = async (ref: string) => {
  return clientRequestService({
    method: "delete",
    url: API_DELETE_MEDIA_FILE.replaceAll("%reference%", ref),
  });
};

export const updateMediaFileService = async (ref: string, data: object) => {
  return clientRequestService({
    method: "put",
    url: API_UPDATE_MEDIA_FILE.replaceAll("%reference%", ref),
    data,
  });
};
