import ButtonCustom from "../../../../../../components/Button";
import React, { useContext, useEffect, useState } from "react";
import { toastError } from "../../../../../../components/Toast";
import { useDropzone } from "react-dropzone";
import "./style.scss";
import { PUBLIC_URL } from "../../../../configs/Constants";
import { Modal } from "semantic-ui-react";
import {
  modalImportEmployeeAction,
  userGuideAction,
} from "../../../../actions/AppActions";
import { useDispatch, useSelector } from "react-redux";
import { EMPLOYEE_INVITE_FILE_URL } from "../../../../configs/Endpoints";
import {
  isModalImportEmployeeSelector,
  userGuideSelector,
  userGuideSendBulkInviteSelector,
} from "../../../../selectors/AppSelectors";
import { LocalizationContext } from "../../../../../../locales/Translation";

const UploadFileSection = ({
  setTabIndex,
  fileUploaded,
  setFileUploaded,
  goToUserGuideNextStep,
}: any) => {
  const dispatch = useDispatch();
  const [isDragOver, setIsDragOver] = useState(false);
  const isModalImportEmployee = useSelector(isModalImportEmployeeSelector);
  const userGuideSendBulkInvite = useSelector(userGuideSendBulkInviteSelector);
  const userGuide = useSelector(userGuideSelector);
  const { translations } = useContext(LocalizationContext);

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    accept: [".xlsx", ".xls"],
    maxSize: 5000000,
    useFsAccessApi: false,
    onDrop: async (acceptedFiles: any) => {
      setFileUploaded(acceptedFiles[0]);
      setIsDragOver(false);
    },

    onDropRejected: (fileRejections: any) => {
      let isFileTooLarge;
      fileRejections.forEach((file: any) => {
        isFileTooLarge =
          file.errors.filter((error: any) => error.code === "file-too-large")
            .length > 0;
        if (isFileTooLarge) {
          toastError(`File ${file.file.name} is larger than 5MB`);
        }
      });
    },

    onDragOver: () => {
      setIsDragOver(true);
    },

    onDragLeave: () => {
      setIsDragOver(false);
    },
  });

  useEffect(() => {
    if (isModalImportEmployee && userGuideSendBulkInvite) {
      inputRef.current?.click();
    }
  }, [userGuideSendBulkInvite]);

  return (
    <>
      <Modal.Content>
        <div>
          <div className={"download-sample"}>
            <a href={EMPLOYEE_INVITE_FILE_URL} target="_blank" rel="noreferrer">
              <i className="uil uil-download-alt" />
              {translations.navBar.downloadSample}
            </a>
          </div>
          <div
            {...getRootProps({
              className: "dropzone-box" + (isDragOver ? " drag-over" : ""),
            })}
          >
            <input {...getInputProps()} />
            <i className="uil uil-cloud-upload" />
            <div className={"title"}>{translations.dragAndDropFilesHereOr}</div>
            <div className={"cta"}>{translations.browseFiles}</div>
          </div>
          {fileUploaded && (
            <div className={"file-uploaded"}>
              <img
                className={"icon"}
                src={PUBLIC_URL + "/assets/icons/icon-xlsx-file.svg"}
              />
              <div className={"right"}>
                <div className={"file-name"}>
                  <span>{fileUploaded?.name}</span>
                  <ButtonCustom
                    onClick={() => setFileUploaded(null)}
                    className={"close"}
                  >
                    <i className="uil uil-times" />
                  </ButtonCustom>
                </div>
                <div className={"process-bar"}>
                  <div className={"process"} style={{ width: "100%" }} />
                </div>
                <div className={"percent"}>100%</div>
              </div>
            </div>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <ButtonCustom
          height={"47px"}
          onClick={() => {
            dispatch(modalImportEmployeeAction(false));
            goToUserGuideNextStep();
          }}
        >
          {translations.cancel}
        </ButtonCustom>
        <ButtonCustom
          disabled={!fileUploaded}
          className={"primary"}
          height={"47px"}
          onClick={() => {
            setTabIndex(2);
            if (userGuide?.run && userGuide?.stepIndex === 4) {
              dispatch(userGuideAction({ key: "run", value: false }));
            }
          }}
        >
          {`${translations.import} XLSX`}
        </ButtonCustom>
      </Modal.Actions>
    </>
  );
};

export default UploadFileSection;
