import React from "react";
import "./styles.scss";
import { FORMAT_DATE_FOR_DATE_PICKER_SINGLE } from "../../modules/App/configs/DateTimeConfigs";
import classNames from "classnames";
import { PUBLIC_URL } from "../../modules/App/configs/Constants";
import { DatePicker } from "rsuite";

interface Props {
  className?: string;
  inputStyle?: boolean;
  touched?: boolean;
  error?: any;
  icon?: JSX.Element;
  disabledDate?: any;
  squareUI?: boolean;
  [x: string]: any;
}

const DatePickerSingle = ({
  className,
  inputStyle,
  touched,
  error,
  icon,
  squareUI,
  ...props
}: Props) => {
  const CaretIcon = () => {
    if (icon) {
      return icon;
    }
    return (
      <img
        width={20}
        height={20}
        className={"date-range-picker-custom-caret-icon"}
        src={PUBLIC_URL + "/assets/icons/calendar-alt.svg"}
        alt={"Deskimo"}
      />
    );
  };

  return (
    <>
      <DatePicker
        placement={"auto"}
        className={classNames("date-range-picker-custom", className, {
          "date-range-picker-custom-input": inputStyle,
          "square-ui": squareUI,
        })}
        format={FORMAT_DATE_FOR_DATE_PICKER_SINGLE}
        caretAs={CaretIcon}
        ranges={[]}
        isoWeek
        {...props}
      />
      {touched && error ? (
        <span className={"date-range-picker-custom-error"}>{error}</span>
      ) : null}
    </>
  );
};

export default DatePickerSingle;
