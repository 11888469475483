import React, { useContext } from "react";
import { LocalizationContext } from "../../../../locales/Translation";
import DropdownCustom from "../../../../components/DropdownCustom";
import ButtonCustom from "../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import { PUBLIC_URL } from "../../../App/configs/Constants";
import { useNavigate } from "react-router-dom";
import { INVOICE_DETAIL_PROPERTY_LINK } from "../../../App/configs/ListRoutes";
import { getURlDownloadFileService } from "../../services/InvoiceServices";

type Props = { token: string; pdfUrl: string; xlxsUrl: string };

const Action = ({ token, xlxsUrl, pdfUrl }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const navigate = useNavigate();

  const handleDownloadFile = async (url: string) => {
    if (!url) {
      return;
    }
    try {
      const response = await getURlDownloadFileService(url);
      window.open(response?.data?.url, "_blank");
    } catch (error) {
      console.log(Error);
    }
  };

  return (
    <DropdownCustom
      className={"table-action-dropdown"}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => {
            navigate(INVOICE_DETAIL_PROPERTY_LINK.replaceAll(":token", token));
          }}
          text={translations.details}
          icon={<img src={PUBLIC_URL + "/assets/icons/icon-detail.svg"} />}
        />
        <Dropdown.Item
          onClick={() => handleDownloadFile(pdfUrl)}
          text={translations.settings.downloadInvoice}
          icon={
            <img src={PUBLIC_URL + "/assets/icons/icon-download-black.svg"} />
          }
        />
        <Dropdown.Item
          onClick={() => handleDownloadFile(xlxsUrl)}
          text={translations.settings.downloadXLSXFile}
          icon={
            <img
              src={PUBLIC_URL + "/assets/icons/icon-xlsx-file-outline.svg"}
            />
          }
        />
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default Action;
