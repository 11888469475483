import { createReducer } from "@reduxjs/toolkit";
import {
  EMPLOYEE_ACTIVE_STATUS,
  EMPLOYEE_DELETE_STATUS,
  EMPLOYEE_PENDING_STATUS,
  EMPLOYEES_ACTION,
  EMPLOYEES_FILTERS_ACTION,
  MODAL_EDIT_EMPLOYEE_ACTION,
  EMPLOYEE_DETAIL_ACTION,
  MODAL_DETAIL_EMPLOYEE_ACTION,
  EMPLOYEE_EDIT_INLINE_ACTION,
  EMPLOYEE_GOOGLE_ACTION,
  MODAL_IMPORT_EMPLOYEE_GOOGLE_ACTION,
  EMPLOYEE_GOOGLE_CHOOSE_ACTION,
  USERS_ACTION,
  USERS_FILTERS_ACTION,
  USERS_FILTERS_RESET_ACTION,
  MODAL_CREATE_DESKIMO_ADMIN_ACTION,
} from "../configs/Constants";
import { ORDER_BY_NONE } from "../../App/configs/Constants";
import produce from "immer";
import {
  USERS_FILTER_COMPANY,
  USERS_FILTER_MARKET,
} from "../../../configs/Constants";

const userFilterDefault = {
  keyword: "",
  sort_by: "",
  order_by: ORDER_BY_NONE,
  page: 1,
  market_code: localStorage.getItem(USERS_FILTER_MARKET) || "0",
  company_id: "0",
};

const initialState = {
  employees: null,
  employeesFilter: {
    keyword: "",
    statuses: [
      EMPLOYEE_PENDING_STATUS,
      EMPLOYEE_ACTIVE_STATUS,
      EMPLOYEE_DELETE_STATUS,
    ],
    sort_by: "",
    order_by: ORDER_BY_NONE,
    page: 1,
  },

  users: null,
  usersFilter: userFilterDefault,

  isModalEditEmployee: false,
  employeeDetail: null,
  isModalDetailEmployee: false,
  employeeGoogle: [],
  employeeGoogleChoose: [],
  isModalImportEmployeeGoogle: false,
  modalCreateDeskimoAdmin: {
    open: false,
    data: {},
  },
};

const EmployeeReducer = createReducer(initialState, {
  [EMPLOYEES_ACTION]: (state, action) => {
    state.employees = action.payload;
  },

  [EMPLOYEES_FILTERS_ACTION]: (state, action) => {
    if (action.payload.key === "page") {
      state.employeesFilter["page"] = action.payload.value;
      return;
    }

    state.employeesFilter = {
      ...state.employeesFilter,
      ...{ [action.payload.key]: action.payload.value, page: 1 },
    };
  },

  [USERS_ACTION]: (state, action) => {
    state.users = action.payload;
  },

  [USERS_FILTERS_ACTION]: (state, action) => {
    state.usersFilter = action.payload;
  },

  [USERS_FILTERS_RESET_ACTION]: (state) => {
    state.usersFilter = userFilterDefault;
  },

  [MODAL_EDIT_EMPLOYEE_ACTION]: (state, action) => {
    state.isModalEditEmployee = action.payload;
  },

  [EMPLOYEE_DETAIL_ACTION]: (state, action) => {
    state.employeeDetail = action.payload;
  },

  [MODAL_DETAIL_EMPLOYEE_ACTION]: (state, action) => {
    state.isModalDetailEmployee = action.payload;
  },

  [EMPLOYEE_EDIT_INLINE_ACTION]: (state, action) => {
    state.employees = produce(state.employees, (draft: any) => {
      const index = draft.data.findIndex(
        (employee: any) => employee.id === action.payload.employee_id
      );

      if (index !== -1) {
        if (action.payload["used_company_budget"] !== undefined) {
          draft.data[index].used_company_budget =
            action.payload.used_company_budget;
        }

        if (action.payload["monthly_budget"] !== undefined) {
          draft.data[index].budget_name =
            parseInt(action.payload.monthly_budget) < 0
              ? "Uncapped"
              : parseInt(action.payload.monthly_budget);
          draft.data[index].monthly_budget = action.payload.monthly_budget;
        }
      }
    });
  },

  [EMPLOYEE_GOOGLE_ACTION]: (state, action) => {
    state.employeeGoogle = action.payload;
  },

  [EMPLOYEE_GOOGLE_CHOOSE_ACTION]: (state, action) => {
    state.employeeGoogleChoose = action.payload;
  },

  [MODAL_IMPORT_EMPLOYEE_GOOGLE_ACTION]: (state, action) => {
    state.isModalImportEmployeeGoogle = action.payload;
  },

  [MODAL_CREATE_DESKIMO_ADMIN_ACTION]: (state, action) => {
    state.modalCreateDeskimoAdmin = action.payload;
  },
});

export default EmployeeReducer;
