import React from "react";
import BookingTable from "../BookingTable";

const CancelledTab = ({ loading, setLoading }: any) => {
  const columns = [
    "Transaction ID",
    "Name",
    "Property Details",
    "Duration",
    "Booking Date",
    "Total Fee",
    "Cancelled On",
    "Action",
  ];

  return (
    <BookingTable columns={columns} loading={loading} setLoading={setLoading} />
  );
};

export default CancelledTab;
