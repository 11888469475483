import React, { useContext, useEffect, useState } from "react";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import Label from "../../../../../../components/Label";
import { LocalizationContext } from "../../../../../../locales/Translation";

type Props = {
  isAnyValue?: boolean;
  value: string;
  onChange: (value: any) => void;
};
const SelectCapacity = ({ value, onChange, isAnyValue }: Props) => {
  const { translations } = useContext(LocalizationContext);

  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    if (isAnyValue) {
      setOptions([
        { key: "0", text: "Any", value: "0" },
        { key: "1", text: `1 ${translations.person}`, value: "1" },
        { key: "2", text: `2-4 ${translations.people}`, value: "2_4" },
        { key: "3", text: `5-7 ${translations.people}`, value: "5_7" },
        { key: "4", text: `8-10 ${translations.people}`, value: "8_10" },
        { key: "5", text: `10+ ${translations.people}`, value: "10_" },
      ]);
    } else {
      setOptions([
        { key: "1", text: `1 ${translations.person}`, value: "1" },
        { key: "2", text: `2-4 ${translations.people}`, value: "2_4" },
        { key: "3", text: `5-7 ${translations.people}`, value: "5_7" },
        { key: "4", text: `8-10 ${translations.people}`, value: "8_10" },
        { key: "5", text: `10+ ${translations.people}`, value: "10_" },
      ]);
    }
  }, [isAnyValue]);

  return (
    <div>
      <Label label={translations.capacity} />
      <DropdownCustom
        style={{ height: 46 }}
        selectUI
        options={options}
        value={value === null ? "0" : value}
        onChange={(event, { value }) => onChange(value === "0" ? null : value)}
      />
    </div>
  );
};

export default SelectCapacity;
