import React, { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { businessCompanyStaffThunk } from "../../thunks/BusinessThunks";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../App/configs/Constants";
import ModalCompanyStaffEdit from "./Components/ModalCompanyStaffEdit";
import CompanyStaffTable from "./Components/CompanyStaffTable";
import { globalSettingSelector } from "../../../App/selectors/AppSelectors";
import CompanyFilter from "../../../../components/CompanyFilter";
import { businessCompanyStaffFilterSelector } from "../../selectors/BusinessSelectors";
import { businessCompanyStaffFilterAction } from "../../actions/BusinessActions";
import SearchWithDebounce from "../../../../components/SearchBar4Table/SearchWithDebounce";
import { useGetAllQuery, useSetQuery } from "../../../../utils/custom-hooks";

const CompanyStaff = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const businessCompanyStaffFilter = useSelector(
    businessCompanyStaffFilterSelector
  );

  const globalSetting = useSelector(globalSettingSelector);
  const [addParamsAlready, setAddParamsAlready] = useState<boolean>(false);
  const getAllQuery = useGetAllQuery();
  const setQuery = useSetQuery();

  useEffect(() => {
    try {
      if (!businessCompanyStaffFilter.market_code.length) {
        dispatch(
          businessCompanyStaffFilterAction({
            market_code: globalSetting?.location.map(
              ({ city_code }: { city_code: string }) => city_code
            ),
          })
        );
      }

      if (!Object.keys(getAllQuery).length) {
        return;
      }
      dispatch(businessCompanyStaffFilterAction(getAllQuery));
    } finally {
      setAddParamsAlready(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!addParamsAlready) {
          return;
        }

        setLoading(true);
        if (businessCompanyStaffFilter.market_code.length === 0) {
          return;
        }
        setQuery(businessCompanyStaffFilter);
        await dispatch<any>(
          businessCompanyStaffThunk(businessCompanyStaffFilter)
        );
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [businessCompanyStaffFilter]);

  const _onChangeSortCol = (col: any) => {
    if (businessCompanyStaffFilter.sort_by !== col.key) {
      dispatch(
        businessCompanyStaffFilterAction({
          sort_by: col.key,
          order_by: ORDER_BY_ASC,
        })
      );
    } else {
      if (businessCompanyStaffFilter.order_by === ORDER_BY_NONE) {
        dispatch(
          businessCompanyStaffFilterAction({
            sort_by: col.key,
            order_by: ORDER_BY_ASC,
          })
        );
      }
      if (businessCompanyStaffFilter.order_by === ORDER_BY_DESC) {
        dispatch(
          businessCompanyStaffFilterAction({
            sort_by: col.key,
            order_by: ORDER_BY_NONE,
          })
        );
      }
      if (businessCompanyStaffFilter.order_by === ORDER_BY_ASC) {
        dispatch(
          businessCompanyStaffFilterAction({
            sort_by: col.key,
            order_by: ORDER_BY_DESC,
          })
        );
      }
    }
  };

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    dispatch(businessCompanyStaffFilterAction({ page: activePage }));
  };

  const _onSearch = (value: string) => {
    dispatch(businessCompanyStaffFilterAction({ page: 1, keyword: value }));
  };

  const _onChangeMarketFilter = (values: string[]) => {
    dispatch(
      businessCompanyStaffFilterAction({ market_code: values, page: 1 })
    );
  };

  const _onChangeCompany = (companyId: number | string) => {
    dispatch(
      businessCompanyStaffFilterAction({ company_id: companyId, page: 1 })
    );
  };

  if (!addParamsAlready) {
    return null;
  }

  return (
    <>
      <div className={"filter-section"}>
        <div className={"filter-section-left"}>
          <SearchWithDebounce
            initialValue={businessCompanyStaffFilter?.keyword}
            placeholder={"Search by user name, phone, email"}
            onChange={_onSearch}
          />
          <CompanyFilter
            companyId={businessCompanyStaffFilter.company_id || 0}
            onChange={_onChangeCompany}
            marketCodes={businessCompanyStaffFilter.market_code}
            type={2}
          />
        </div>
      </div>
      <CompanyStaffTable
        onChangeSortCol={_onChangeSortCol}
        onChangePagination={_onChangePagination}
        loading={loading}
        filter={businessCompanyStaffFilter}
        onChangeMarketFilter={_onChangeMarketFilter}
      />
      <ModalCompanyStaffEdit filter={businessCompanyStaffFilter} />
    </>
  );
};

export default CompanyStaff;
