import clientRequestService from "../../../services/ClientRequestService";
import { METHOD_GET, METHOD_POST } from "../../../configs/Constants";
import {
  API_PROPERTY_AVAILABLE_HOUR_BY_DATE,
  ASSIGN_BOOKING_DETAIL_API,
  ASSIGN_BOOKING_LIST,
  ASSIGN_BOOKING_REVIEW,
  DESK_AMENITIES_API,
  FAVORITE_ADD_API,
  FAVORITE_REMOVE_API,
  GET_CURRENT_USER_LOCATION,
  INQUIRE_OFFICE_API,
  POST_BOOKING_ROOM_DETAIL_API,
  POST_CHECK_ROOM_AVAILABLE_API,
  POST_CONFIRM_AND_PAY_ROOM_API,
  POST_MEETING_ROOM_LIST_API,
  POST_PROPERTY_LIST_API,
  PRIVATE_OFFICE_LIST_API,
  PROPERTY_AVAILABLE_API,
  PROPERTY_BOOK_CONFIRM_PAY_API,
  PROPERTY_BOOK_REVIEW_API,
  PROPERTY_BOOKING_DETAIL_API,
  PROPERTY_DETAIL_VIEW_API,
  PROPERTY_MARKET_COUNT_API,
  READ_MORE_RATING_API,
  REPORT_PROPERTY_STATUS_API,
  REQUEST_PARKING_API,
  REQUEST_PARKING_STATUS_API,
  REWARD_LIST_API,
  ROOM_AMENITIES_API,
  ROOM_DETAIL_VIEW_API,
  ROOM_MARKET_COUNT_API,
  USER_ASSIGN_LIST,
} from "../configs/Endpoints";
import {
  pinHighlightIdAction,
  meetingRoomDataTmpAction,
  modalConfirmBookingAction,
  modalConfirmBookingDeskAction,
  modalConfirmBookingDeskDataAction,
} from "../actions/BookActions";
import { store } from "../../App/configs/Store";
import {
  BookInfoWindowType,
  BookMeetingRoomType,
  BookPropertyType,
  FavoriteServicePayloadType,
} from "../configs/DeclareType";
import {
  BO_SOURCE,
  CAPACITY_FILTER_ARR,
  FAVORITE_MEETING_ROOM,
  FAVORITE_PROPERTY,
  FAVORITE_REMOVE,
  FILTER_ITEMS_PER_PAGE_KEY,
  FILTER_LATITUDE_KEY,
  FILTER_LONGITUDE_KEY,
  FILTER_PAGE_KEY,
  FILTER_POSITION_KEY,
} from "../configs/Constants";
import { mapMoveAnimate } from "./Common";

export const getMeetingRoomListService = async (payload: any) => {
  let currentCoords;

  try {
    if (navigator.geolocation) {
      const { coords }: any = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      currentCoords = {
        [FILTER_LATITUDE_KEY]: coords.latitude,
        [FILTER_LONGITUDE_KEY]: coords.longitude,
      };
    }
  } catch (e) {
    currentCoords = {
      [FILTER_LATITUDE_KEY]: null,
      [FILTER_LONGITUDE_KEY]: null,
    };
  }

  if (payload?.lat && payload?.lng) {
    currentCoords = {
      [FILTER_LATITUDE_KEY]: payload.lat,
      [FILTER_LONGITUDE_KEY]: payload.lng,
    };
  }

  return clientRequestService({
    method: METHOD_POST,
    url: POST_MEETING_ROOM_LIST_API,
    data: {
      ...payload,
      ...currentCoords,
      items_per_page: 1000,
      page: 1,
      position: "list",
    },
  });
};

export const checkAvailableRoomService = async (data: any) => {
  return clientRequestService({
    method: METHOD_POST,
    url: POST_CHECK_ROOM_AVAILABLE_API,
    data,
  });
};

export const confirmAndPayRoomService = async (payload: any) => {
  return clientRequestService({
    method: METHOD_POST,
    url: POST_CONFIRM_AND_PAY_ROOM_API,
    data: { ...payload, from_source: BO_SOURCE },
  });
};

export const bookingRoomDetailService = async (bookingId: any) => {
  return clientRequestService({
    method: METHOD_GET,
    url: POST_BOOKING_ROOM_DETAIL_API.replaceAll("%bookingId%", bookingId),
  });
};

export const getPropertyListService = async (data: any) => {
  const payload: any = { ...data };
  let currentCoords;

  try {
    if (navigator.geolocation) {
      const { coords }: any = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      currentCoords = {
        [FILTER_LATITUDE_KEY]: coords.latitude,
        [FILTER_LONGITUDE_KEY]: coords.longitude,
      };
    }
  } catch (e) {
    currentCoords = {
      [FILTER_LATITUDE_KEY]: null,
      [FILTER_LONGITUDE_KEY]: null,
    };
  }

  if (payload?.lat && payload?.lng) {
    currentCoords = {
      [FILTER_LATITUDE_KEY]: payload?.lat,
      [FILTER_LONGITUDE_KEY]: payload?.lng,
    };
  }

  payload.get_full_day_price = true;

  if (payload?.start_time && payload?.end_time) {
    payload.is_update_timezone = 1;
  }

  return clientRequestService({
    method: METHOD_POST,
    url: POST_PROPERTY_LIST_API,
    data: { ...payload, ...currentCoords },
  });
};

export const getPropertyMapService = async (data: object) => {
  const payload: any = {
    ...data,
    [FILTER_LATITUDE_KEY]: null,
    [FILTER_LONGITUDE_KEY]: null,
    [FILTER_ITEMS_PER_PAGE_KEY]: 1000,
    [FILTER_POSITION_KEY]: "map",
    [FILTER_PAGE_KEY]: 1,
    get_full_day_price: true,
  };

  if (payload?.start_time && payload?.end_time) {
    payload.is_update_timezone = 1;
  }

  return clientRequestService({
    method: METHOD_POST,
    url: POST_PROPERTY_LIST_API,
    data: payload,
  });
};

export const handleBookRoomService = (room: BookMeetingRoomType) => {
  const dispatch = store.dispatch;
  dispatch(meetingRoomDataTmpAction(room));
  dispatch(modalConfirmBookingAction({ open: true }));
};

export const handleBookDeskService = (desk: BookPropertyType) => {
  const dispatch = store.dispatch;
  dispatch(modalConfirmBookingDeskDataAction(desk));
  dispatch(modalConfirmBookingDeskAction(true));
};

export const checkAvailableDeskService = async (data: {
  id: string;
  end_time: number;
  start_time: number;
}) => {
  return clientRequestService({
    method: METHOD_POST,
    url: PROPERTY_BOOK_REVIEW_API.replaceAll("%id%", data.id),
    data,
  });
};

export const bookDeskConfirmPayService = async (data: object) => {
  return clientRequestService({
    method: METHOD_POST,
    url: PROPERTY_BOOK_CONFIRM_PAY_API,
    data: { ...data, from_source: "BO_APP" },
  });
};

export const bookingDeskDetailService = async (bookingId: string) => {
  return clientRequestService({
    url: PROPERTY_BOOKING_DETAIL_API.replaceAll("%id%", bookingId),
  });
};

export const assignBookingDetailService = async (groupBookingId: any) => {
  return clientRequestService({
    url: ASSIGN_BOOKING_DETAIL_API.replaceAll(
      "%groupBookingId%",
      groupBookingId
    ),
  });
};

export const handleClickCardShowMarker = (item: BookInfoWindowType) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const mapState: google.maps.Map = store.getState().BookReducer.mapState;
  const dispatch = store.dispatch;

  if (!mapState) {
    return;
  }

  const { lat, lng, id } = item;

  dispatch(pinHighlightIdAction(id));

  const markerInViewPort = mapState.getBounds()?.contains({ lat, lng });

  if (markerInViewPort) {
    return;
  }

  mapMoveAnimate({
    map: mapState,
    cameraOptions: {
      center: { lat: item.lat, lng: item.lng },
    },
  });

  // dispatch(
  //   infoWindowDataAction({
  //     ...item,
  //   })
  // );
};

export const handleHoverLeaveCard = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const mapState: google.maps.Map = store.getState().BookReducer.mapState;
  const dispatch = store.dispatch;

  dispatch(pinHighlightIdAction(null));

  // Trigger cluster calculate
  const center = {
    lat: mapState.getCenter()?.lat() || 0,
    lng: mapState.getCenter()?.lng() || 0,
  };

  mapState.panTo(center);
};

export const handleHoverCard = (item: {
  lat: number;
  lng: number;
  id: number;
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const mapState: google.maps.Map = store.getState().BookReducer.mapState;
  const dispatch = store.dispatch;

  if (!mapState) {
    return;
  }

  const { lat, lng, id } = item;

  dispatch(pinHighlightIdAction(id));

  const markerInViewPort = mapState.getBounds()?.contains({ lat, lng });
  if (markerInViewPort) {
    // Trigger cluster calculate
    const center = {
      lat: mapState.getCenter()?.lat() || 0,
      lng: mapState.getCenter()?.lng() || 0,
    };

    mapState.panTo(center);
    return;
  }

  mapMoveAnimate({
    map: mapState,
    cameraOptions: {
      center: { lat, lng },
    },
  });
};

export const handleClickPin = (item: {
  lat: number;
  lng: number;
  id: number;
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const mapState: google.maps.Map = store.getState().BookReducer.mapState;
  const dispatch = store.dispatch;

  if (!mapState) {
    return;
  }

  const { lat, lng, id } = item;

  dispatch(pinHighlightIdAction(id));

  mapMoveAnimate({
    map: mapState,
    cameraOptions: {
      center: { lat, lng },
    },
  });
};

export const handleClickFavoriteItem = async ({
  isFavorite,
  itemType,
  itemId,
}: {
  isFavorite: number;
  itemType: typeof FAVORITE_PROPERTY | typeof FAVORITE_MEETING_ROOM;
  itemId: number;
}) => {
  const nextFavorite = isFavorite ? 0 : 1;
  if (nextFavorite === FAVORITE_REMOVE) {
    await removeFavoriteItem({ item_id: itemId, item_type: itemType });
  } else {
    await addFavoriteItem({ item_id: itemId, item_type: itemType });
  }
};

export const addFavoriteItem = async (data: FavoriteServicePayloadType) => {
  return clientRequestService({
    method: "POST",
    url: FAVORITE_ADD_API,
    data,
  });
};

export const removeFavoriteItem = async (data: FavoriteServicePayloadType) => {
  return clientRequestService({
    method: "POST",
    url: FAVORITE_REMOVE_API,
    data,
  });
};

export const propertyDetailViewService = (id: string, data?: object) => {
  return clientRequestService({
    method: "POST",
    url: PROPERTY_DETAIL_VIEW_API.replaceAll("%id%", id),
    data,
  });
};

export const roomDetailViewService = (id: string, data?: object) => {
  return clientRequestService({
    url: ROOM_DETAIL_VIEW_API.replaceAll("%id%", id),
    data,
  });
};

export const getPropertiesMarketCount = (data: object) => {
  return clientRequestService({
    method: "post",
    url: PROPERTY_MARKET_COUNT_API,
    data,
  });
};

export const getRoomMarketCount = (data: object) => {
  return clientRequestService({
    method: "post",
    url: ROOM_MARKET_COUNT_API,
    data,
  });
};

export const getUserAssignService = (data: object) => {
  return clientRequestService({
    method: "post",
    url: USER_ASSIGN_LIST,
    data,
    isCompany: true,
  });
};

export const assignBookingService = (data: object) => {
  return clientRequestService({
    method: "post",
    url: ASSIGN_BOOKING_LIST,
    data,
    isCompany: true,
  });
};

export const assignBookingReviewService = (data: object) => {
  return clientRequestService({
    method: "post",
    url: ASSIGN_BOOKING_REVIEW,
    data,
    isCompany: true,
  });
};

export const getPropertyAvailableTimeService = (data: object) => {
  return clientRequestService({
    method: "post",
    url: PROPERTY_AVAILABLE_API,
    data,
  });
};

export const getPropertyAvailableHourByDateService = (data: object) => {
  return clientRequestService({
    method: "post",
    url: API_PROPERTY_AVAILABLE_HOUR_BY_DATE,
    data,
  });
};

export const readMoreRatingService = (data: object) => {
  return clientRequestService({
    method: "post",
    url: READ_MORE_RATING_API,
    data,
  });
};

export const getCurrentUserLocationService = () => {
  return clientRequestService({
    method: "get",
    url: GET_CURRENT_USER_LOCATION,
  });
};

export const getDeskAmenitiesService = () => {
  return clientRequestService({
    url: DESK_AMENITIES_API,
  });
};

export const getRoomAmenitiesService = () => {
  return clientRequestService({
    url: ROOM_AMENITIES_API,
  });
};

export const inquireOfficeService = (data: object) => {
  return clientRequestService({
    method: "post",
    url: INQUIRE_OFFICE_API,
    data,
  });
};

export const privateOfficeListService = (data: object) => {
  return clientRequestService({
    method: "post",
    url: PRIVATE_OFFICE_LIST_API,
    data,
  });
};

export const getSelectValueOfPrivateOfficeCapacity = (capacity: number) => {
  const value = CAPACITY_FILTER_ARR[capacity];

  return value || "10_";
};

export const submitReportPropertyStatusService = (data: object) => {
  return clientRequestService({
    method: "post",
    url: REPORT_PROPERTY_STATUS_API,
    data,
    isAuth: true,
  });
};

export const requestParkingService = (bookingId: number, data: object) => {
  return clientRequestService({
    method: "post",
    url: REQUEST_PARKING_API.replaceAll("%bookingId%", bookingId.toString()),
    data,
    isAuth: true,
  });
};

export const requestParkingChangeStatusService = (
  bookingId: number,
  requestRef: string,
  data: object
) => {
  return clientRequestService({
    method: "put",
    url: REQUEST_PARKING_STATUS_API.replaceAll(
      "%bookingId%",
      bookingId.toString()
    ).replaceAll("%vehicleRef%", requestRef.toString()),
    data,
    isAuth: true,
  });
};

export const rewardListService = (propertyId: string) => {
  return clientRequestService({
    method: "get",
    url: REWARD_LIST_API.replaceAll("%propertyId%", propertyId),
    isAuth: true,
  });
};
