import React, { useState } from "react";
import "./style.scss";
import ButtonCustom from "../Button";
import Radio from "../Radio";

type Props = {
  label?: string;
  icon?: JSX.Element;
  listCheckBox: any;
  onChange: any;
  rowClassName?: string;
  menuTitle?: string;
};

const DropdownWithRadioMenu = ({
  label,
  icon = <></>,
  listCheckBox,
  onChange,
  rowClassName = "",
  menuTitle,
}: Props) => {
  const [open, setOpen] = useState(false);

  const menu = listCheckBox.map((checkbox: any, index: number) => {
    return (
      <div
        key={index}
        className={"dropdown-with-checkbox__row " + rowClassName}
      >
        <Radio
          checked={checkbox?.checked}
          value={checkbox?.id}
          onChange={onChange}
          label={checkbox?.name}
        />
      </div>
    );
  });

  return (
    <div className={"dropdown-with-checkbox"}>
      <ButtonCustom
        height={"36px"}
        className={"filter " + (open ? "show" : "")}
        onClick={() => setOpen(!open)}
      >
        <span className={"mr-10px text"}>{label}</span>
        {icon}
      </ButtonCustom>
      {open ? (
        <>
          <div
            className={"dropdown-with-checkbox__overlay"}
            onClick={() => setOpen(false)}
          />
          <div className={"dropdown-with-checkbox__menu"}>
            {menuTitle ? (
              <div className={"dropdown-with-checkbox__title"}>{menuTitle}</div>
            ) : null}

            {menu}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default DropdownWithRadioMenu;
