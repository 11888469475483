import { combineReducers, configureStore } from "@reduxjs/toolkit";
import AppReducer from "../../App/reducers/AppReducer";
import AuthenticationReducer from "../../Authentication/reducers/AuthenticationReducer";
import DashboardReducer from "../../Dashboard/reducers/DashboardReducer";
import SettingReducer from "../../Setting/reducers/SettingReducer";
import UserReducer from "../../User/reducers/UserReducer";
import EmployeeReducer from "../../User/reducers/EmployeeReducer";
import thunk from "redux-thunk";
import PropertyReducer from "../../Property/reducers/PropertyReducer";
import MarketingReducer from "../../Marketing/reducers/MarketingReducer";
import ConfigReducer from "../../Configuration/reducers/ConfigReducer";
import BusinessReducer from "../../Business/reducers/BusinessReducer";
import BookReducer from "../../Book/reducers/BookReducer";
import BookingReducer from "../../Booking/reducers/BookingReducer";
import OnboardReducer from "../../Onboard/reducers/OnboardReducer";
import PartnerTeamReducer from "../../User/reducers/PartnerTeamReducer";
import MediaFileReducer from "../../MediaFile/reducers/MediaFileReducer";

const reducers = combineReducers({
  AppReducer,
  AuthenticationReducer,
  DashboardReducer,
  SettingReducer,
  UserReducer,
  EmployeeReducer,
  PropertyReducer,
  MarketingReducer,
  ConfigReducer,
  BusinessReducer,
  BookReducer,
  BookingReducer,
  OnboardReducer,
  PartnerTeamReducer,
  MediaFileReducer,
});

export const store = configureStore({
  reducer: reducers,
  middleware: [thunk],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
