import { Routes, Route } from "react-router-dom";
import {
  PROPERTY_LIST_LINK,
  PROPERTY_DETAIL_LINK,
  PROPERTY_DETAIL_USER_REVIEW_LINK,
  PROPERTY_DETAIL_MEETING_ROOM_LINK,
  PROPERTY_EDIT_LINK,
  DESKIMO_PROPERTY_LINK,
  DESKIMO_PROPERTY_COMPANY_LINK,
  DESKIMO_PROPERTY_STAFF_LINK,
  PROPERTY_CREATE_LINK,
  DESKIMO_PROPERTY_COMPANY_CREATE_LINK,
  DESKIMO_PROPERTY_COMPANY_DETAIL_LINK,
  DESKIMO_PROPERTY_DETAIL_LINK,
  DESKIMO_PROPERTY_DETAIL_USER_REVIEW_LINK,
  DESKIMO_PROPERTY_DETAIL_MEETING_ROOM_LINK,
  DESKIMO_PROPERTY_INVOICES_LINK,
  DESKIMO_PROPERTY_COMPANY_INVOICE_DETAIL_LINK,
  DESKIMO_PROPERTY_AMENITIES_LINK,
  DESKIMO_PROPERTY_SCANNERS_LINK,
  DESKIMO_PROPERTY_SCANNER_CREATE_LINK,
  DESKIMO_PROPERTY_SCANNER_EDIT_LINK,
  DESKIMO_PROPERTY_DETAIL_NOTES_LINK,
  DESKIMO_PROPERTY_DETAIL_INTEGRATIONS_LINK,
  PROPERTY_DETAIL_INTEGRATIONS_LINK,
  DESKIMO_PROPERTY_DETAIL_PRIVATE_OFFICES_LINK,
  DESKIMO_USER_REVIEW,
  PROPERTY_DETAIL_PRIVATE_OFFICES_LINK,
  DESKIMO_PRIVATE_OFFICES_LINK,
  DESKIMO_PROPERTY_DETAIL_REWARD_LINK,
  PROPERTY_DETAIL_REWARDS_LINK,
} from "../../App/configs/ListRoutes";
import PropertyList from "../views/PropertyList";
import PropertyDetail from "../views/PropertyDetail";
import DeskimoPropertyDetail from "../views/DeskimoPropertyDetail";
import DeskimoPropertyInfo from "../views/DeskimoPropertyInfo";
import CreateProperty from "../views/CreateProperty";
import PropertyCompanyDetail from "../views/PropertyCompanyDetail";
import DeskimoPropertyInvoiceDetail from "../views/DeskimoPropertyInvoiceDetail";

const PropertyRoutes = () => {
  return (
    <Routes>
      <Route path={PROPERTY_LIST_LINK} element={<PropertyList />} />
      <Route path={PROPERTY_CREATE_LINK} element={<CreateProperty />} />
      <Route
        path={PROPERTY_DETAIL_LINK}
        element={<PropertyDetail tabName={PROPERTY_DETAIL_LINK} />}
      />
      <Route
        path={DESKIMO_PROPERTY_DETAIL_LINK}
        element={
          <DeskimoPropertyDetail tabName={DESKIMO_PROPERTY_DETAIL_LINK} />
        }
      />
      <Route
        path={PROPERTY_DETAIL_USER_REVIEW_LINK}
        element={<PropertyDetail tabName={PROPERTY_DETAIL_USER_REVIEW_LINK} />}
      />
      <Route
        path={DESKIMO_PROPERTY_DETAIL_USER_REVIEW_LINK}
        element={
          <DeskimoPropertyDetail
            tabName={DESKIMO_PROPERTY_DETAIL_USER_REVIEW_LINK}
          />
        }
      />
      <Route
        path={PROPERTY_DETAIL_MEETING_ROOM_LINK}
        element={<PropertyDetail tabName={PROPERTY_DETAIL_MEETING_ROOM_LINK} />}
      />
      <Route
        path={PROPERTY_DETAIL_PRIVATE_OFFICES_LINK}
        element={
          <PropertyDetail tabName={PROPERTY_DETAIL_PRIVATE_OFFICES_LINK} />
        }
      />
      <Route
        path={PROPERTY_DETAIL_INTEGRATIONS_LINK}
        element={<PropertyDetail tabName={PROPERTY_DETAIL_INTEGRATIONS_LINK} />}
      />
      <Route
        path={PROPERTY_DETAIL_REWARDS_LINK}
        element={<PropertyDetail tabName={PROPERTY_DETAIL_REWARDS_LINK} />}
      />
      <Route
        path={DESKIMO_PROPERTY_DETAIL_MEETING_ROOM_LINK}
        element={
          <DeskimoPropertyDetail
            tabName={DESKIMO_PROPERTY_DETAIL_MEETING_ROOM_LINK}
          />
        }
      />
      <Route
        path={DESKIMO_PROPERTY_DETAIL_PRIVATE_OFFICES_LINK}
        element={
          <DeskimoPropertyDetail
            tabName={DESKIMO_PROPERTY_DETAIL_PRIVATE_OFFICES_LINK}
          />
        }
      />
      <Route
        path={DESKIMO_PROPERTY_DETAIL_NOTES_LINK}
        element={
          <DeskimoPropertyDetail tabName={DESKIMO_PROPERTY_DETAIL_NOTES_LINK} />
        }
      />
      <Route
        path={DESKIMO_PROPERTY_DETAIL_REWARD_LINK}
        element={
          <DeskimoPropertyDetail
            tabName={DESKIMO_PROPERTY_DETAIL_REWARD_LINK}
          />
        }
      />
      <Route
        path={DESKIMO_PROPERTY_DETAIL_INTEGRATIONS_LINK}
        element={
          <DeskimoPropertyDetail
            tabName={DESKIMO_PROPERTY_DETAIL_INTEGRATIONS_LINK}
          />
        }
      />
      <Route
        path={PROPERTY_EDIT_LINK}
        element={<PropertyDetail tabName={PROPERTY_EDIT_LINK} />}
      />
      <Route
        path={DESKIMO_PROPERTY_LINK}
        element={<DeskimoPropertyInfo tabIndex={1} key={1} />}
      />
      <Route
        path={DESKIMO_PROPERTY_COMPANY_LINK}
        element={<DeskimoPropertyInfo tabIndex={2} key={2} />}
      />
      <Route
        path={DESKIMO_PROPERTY_STAFF_LINK}
        element={<DeskimoPropertyInfo tabIndex={3} key={3} />}
      />
      <Route
        path={DESKIMO_PRIVATE_OFFICES_LINK}
        element={<DeskimoPropertyInfo tabIndex={4} key={4} />}
      />
      <Route
        path={DESKIMO_PROPERTY_INVOICES_LINK}
        element={<DeskimoPropertyInfo tabIndex={5} key={5} />}
      />
      <Route
        path={DESKIMO_PROPERTY_AMENITIES_LINK}
        element={<DeskimoPropertyInfo tabIndex={6} key={6} />}
      />
      <Route
        path={DESKIMO_PROPERTY_SCANNERS_LINK}
        element={<DeskimoPropertyInfo tabIndex={7} key={7} />}
      />
      <Route
        path={DESKIMO_USER_REVIEW}
        element={<DeskimoPropertyInfo tabIndex={8} key={8} />}
      />
      <Route
        path={DESKIMO_PROPERTY_COMPANY_CREATE_LINK}
        element={<PropertyCompanyDetail />}
      />
      <Route
        path={DESKIMO_PROPERTY_SCANNER_CREATE_LINK}
        element={<DeskimoPropertyInfo tabIndex={7} key={7} />}
      />
      <Route
        path={DESKIMO_PROPERTY_SCANNER_EDIT_LINK}
        element={<DeskimoPropertyInfo tabIndex={7} key={7} />}
      />
      {/*<Route*/}
      {/*  path={DESKIMO_METTING_ROOM}*/}
      {/*  element={<DeskimoPropertyInfo tabIndex={7} />}*/}
      {/*/>*/}
      <Route
        path={DESKIMO_PROPERTY_COMPANY_DETAIL_LINK}
        element={<PropertyCompanyDetail />}
      />
      <Route
        path={DESKIMO_PROPERTY_COMPANY_INVOICE_DETAIL_LINK}
        element={<DeskimoPropertyInvoiceDetail />}
      />
    </Routes>
  );
};

export default PropertyRoutes;
