import {
  COMPANY_CUSTOM_PACKAGES_ACTION,
  COMPANY_DETAIL_ACTION,
  COMPANY_FILTER_ACTION,
  COMPANY_INVOICE_ACTION,
  COMPANY_INVOICE_FILTER_ACTION,
  COMPANY_LIST_ACTION,
  COMPANY_LIST_CAN_BUY_CREDIT_ACTION,
  COMPANY_LIST_DELETE_ACTION,
  COMPANY_LIST_PAY_POST_ACTION,
  COMPANY_PENDING_INVITATION_ACTION,
  COMPANY_STAFF_ACTION,
  COMPANY_STAFF_DEACTIVATE_ACTION,
  COMPANY_STAFF_DELETE_ACTION,
  COMPANY_STAFF_EDIT_ACTION,
  COMPANY_STAFF_FILTER_ACTION,
  COMPANY_STAFF_MAKE_ADMIN_ACTION,
  COMPANY_STAFF_REINVITE_ACTION,
  MODAL_COMPANY_CUSTOM_PACKAGE_ACTION,
  MODAL_COMPANY_STAFF_EDIT_ACTION,
  MODAL_UPDATE_AUTO_TOPUP_ACTION,
  PREPAID_CREDIT_FILTERS_ACTION,
  PURCHASE_HISTORY_FILTERS_ACTION,
} from "../configs/Constants";
import { BusinessCompanyDetailType } from "../configs/DeclareTypes";
import { DESKIMO_PROPERTY_LIST_FILTER_RESET_ACTION } from "../../Property/configs/Actions";

export const businessCompanyFilterAction = (data: object) => {
  return { type: COMPANY_FILTER_ACTION, payload: data };
};

export const businessCompanyListAction = (data: object) => {
  return { type: COMPANY_LIST_ACTION, payload: data };
};

export const businessCompanyPayPostAction = (companyId: number) => {
  return { type: COMPANY_LIST_PAY_POST_ACTION, payload: companyId };
};

export const businessCompanyCanBuyCreditAction = (companyId: number) => {
  return { type: COMPANY_LIST_CAN_BUY_CREDIT_ACTION, payload: companyId };
};

export const businessCompanyDeleteAction = (companyId: number) => {
  return { type: COMPANY_LIST_DELETE_ACTION, payload: companyId };
};

export const businessCompanyDetailAction = (
  data: BusinessCompanyDetailType
) => {
  return { type: COMPANY_DETAIL_ACTION, payload: data };
};

export const businessCompanyStaffAction = (data: object) => {
  return { type: COMPANY_STAFF_ACTION, payload: data };
};

export const businessCompanyStaffFilterAction = (data: object) => {
  return { type: COMPANY_STAFF_FILTER_ACTION, payload: data };
};

export const businessCompanyPendingInvitationAction = (data: object) => {
  return { type: COMPANY_PENDING_INVITATION_ACTION, payload: data };
};

export const modalBusinessCompanyStaffEditAction = (data: any) => {
  return { type: MODAL_COMPANY_STAFF_EDIT_ACTION, payload: data };
};

export const businessCompanyStaffDeleteAction = (invitationId: number) => {
  return { type: COMPANY_STAFF_DELETE_ACTION, payload: invitationId };
};

export const businessCompanyStaffMakeAdminAction = (invitationId: number) => {
  return { type: COMPANY_STAFF_MAKE_ADMIN_ACTION, payload: invitationId };
};

export const businessCompanyStaffEditAction = (data: object) => {
  return { type: COMPANY_STAFF_EDIT_ACTION, payload: data };
};

export const businessCompanyStaffReinviteAction = (invitationId: number) => {
  return { type: COMPANY_STAFF_REINVITE_ACTION, payload: invitationId };
};

export const businessCompanyStaffDeactivateAction = (invitationId: number) => {
  return { type: COMPANY_STAFF_DEACTIVATE_ACTION, payload: invitationId };
};

export const businessCompanyInvoiceAction = (data: object) => {
  return { type: COMPANY_INVOICE_ACTION, payload: data };
};
export const businessCompanyInvoiceFilterAction = (data: object) => {
  return { type: COMPANY_INVOICE_FILTER_ACTION, payload: data };
};

export const businessCompanyCustomPackageAction = (data: object) => {
  return { type: COMPANY_CUSTOM_PACKAGES_ACTION, payload: data };
};

export const modalBusinessCompanyCustomPackageCreateEditAction = (
  data: any
) => {
  return { type: MODAL_COMPANY_CUSTOM_PACKAGE_ACTION, payload: data };
};

export const deskimoPropertyListFilterResetAction = () => {
  return {
    type: DESKIMO_PROPERTY_LIST_FILTER_RESET_ACTION,
  };
};

export const modalUpdateAutoTopUpAction = (data: any) => {
  return { type: MODAL_UPDATE_AUTO_TOPUP_ACTION, payload: data };
};

export const purchaseHistoryFiltersAction = (data: object) => {
  return { type: PURCHASE_HISTORY_FILTERS_ACTION, payload: data };
};

export const prepaidCreditFiltersAction = (data: any) => {
  return { type: PREPAID_CREDIT_FILTERS_ACTION, payload: data };
};
