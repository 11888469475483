import React, { useContext, useState, useEffect } from "react";
import classNames from "classnames";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../components/Button";
import ModalCenterTemplate from "../../../../App/views/ModalCenterTemplate";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationContext } from "../../../../../locales/Translation";
import {
  bookingDeskDetailService,
  bookingRoomDetailService,
} from "../../../../Book/services/BookServices";
import moment from "moment-timezone";
import {
  FORMAT_DATE_TIME_VARIANT_3,
  FORMAT_DATE_TIME_VARIANT_6,
  FORMAT_TIME_VARIANT_1,
} from "../../../../App/configs/DateTimeConfigs";
import { toastError } from "../../../../../components/Toast";
import {
  deskBookingPayloadSelector,
  listBookingCompletedPayloadSelector,
  modalRoomBookingDetailSelector,
  roomBookingPayloadSelector,
} from "../../../selectors/BookingSelectors";
import {
  listBookingCompletedAction,
  modalRoomBookingDetailAction,
} from "../../../actions/BookingActions";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import LoaderCustom from "../../../../../components/Loader";
import SizeBox from "../../../../../components/SizeBox";
import { TYPE_DESK, TYPE_ROOM } from "../../../configs/Constants";
import Divide from "../../../../../components/Divide";
import ParkingRequest from "../../../../Book/views/Components/ModalBookingSubmittedDesk/ParkingRequest";
import {
  DESK_BOOKING,
  ROOM_BOOKING,
} from "../../../../Dashboard/configs/Constants";
import {
  getDeskBookingListThunk,
  getRoomBookingListThunk,
} from "../../../thunks/BookingThunks";

const ModalBookingDetail = () => {
  const dispatch = useDispatch();
  const modalRoomBookingDetail = useSelector(modalRoomBookingDetailSelector);
  const { translations } = useContext(LocalizationContext);
  const [loading, setLoading] = useState(false);
  const [bookingDetail, setBookingDetail] = useState<any>(null);

  const deskBookingPayload = useSelector(deskBookingPayloadSelector);
  const roomBookingPayload = useSelector(roomBookingPayloadSelector);

  useEffect(() => {
    if (modalRoomBookingDetail?.open) {
      fetchBookingDetailInfo();
    }
  }, [modalRoomBookingDetail]);

  const fetchBookingDetailInfo = async () => {
    try {
      setLoading(true);
      await _onFetch();
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const _onFetch = async () => {
    if (
      modalRoomBookingDetail?.booking_id &&
      modalRoomBookingDetail?.type === TYPE_ROOM
    ) {
      const response = await bookingRoomDetailService(
        modalRoomBookingDetail?.booking_id
      );
      setBookingDetail(response?.data);
    } else {
      const response = await bookingDeskDetailService(
        modalRoomBookingDetail?.booking_id
      );
      setBookingDetail(response?.data);
    }
  };

  const _onRefresh = async () => {
    if (modalRoomBookingDetail?.type === TYPE_ROOM) {
      const payload = {
        status: roomBookingPayload.status + 1,
        page: roomBookingPayload.page,
      };
      await Promise.all([
        _onFetch(),
        dispatch<any>(getRoomBookingListThunk(payload)),
      ]);
      return;
    }

    const payload = {
      status: deskBookingPayload?.status,
      page: deskBookingPayload?.page,
    };

    await Promise.all([
      _onFetch(),
      dispatch<any>(getDeskBookingListThunk(payload)),
    ]);
  };

  const _onClose = () => {
    setBookingDetail(null);
    dispatch(modalRoomBookingDetailAction({ open: false }));
  };

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={_onClose}
      modalClassName={classNames(
        "modal-center-v2 modal-booking-success modal-booking-detail"
      )}
      open={modalRoomBookingDetail?.open}
    >
      <Modal.Header>
        <span>Details</span>
        <ButtonCustom
          onClick={() => _onClose()}
          className={"m-0 p-0 none-bg close-modal"}
        >
          <>
            {translations.close}
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Modal.Content>
        {loading ? (
          <div>
            <LoaderCustom />
            <SizeBox height={"50vh"} />
          </div>
        ) : (
          <div className={"w-100"}>
            <div className={"modal-booking-success__thumbnail"}>
              <img
                src={
                  modalRoomBookingDetail?.type === TYPE_ROOM
                    ? bookingDetail?.thumbnail
                    : bookingDetail?.property_image_url
                }
                alt=""
              />
            </div>
            <div
              className={"d-flex flex-column gap-20"}
              style={{ padding: 24 }}
            >
              <div className={"modal-booking-success__info"}>
                <div className={"title"}>
                  <span className={"title-content"}>
                    {modalRoomBookingDetail?.type === TYPE_ROOM
                      ? bookingDetail?.meeting_room_name
                      : bookingDetail?.property_name}
                  </span>
                  <div className={"status-wrapper"}>
                    <span className={"status"}>
                      {modalRoomBookingDetail?.type === TYPE_ROOM
                        ? bookingDetail?.status
                        : bookingDetail?.status_name}
                    </span>
                  </div>
                </div>
                <div className={"item"}>
                  <i className="uil uil-building" />
                  {modalRoomBookingDetail?.type === TYPE_ROOM
                    ? `${bookingDetail?.property_name} • ${bookingDetail?.brand_name}`
                    : `${bookingDetail?.company_name}`}
                </div>
                <div className={"item"}>
                  <i className="uil uil-map" />{" "}
                  {modalRoomBookingDetail?.type === TYPE_ROOM
                    ? bookingDetail?.address
                    : bookingDetail?.property_address}
                </div>
                <div className={"item"}>
                  {modalRoomBookingDetail?.type === TYPE_ROOM ? (
                    <>
                      <i className="uil uil-users-alt" />{" "}
                      {bookingDetail?.meeting_room_capacity}{" "}
                      {bookingDetail?.meeting_room_capacity > 1
                        ? translations.people
                        : translations.person}
                    </>
                  ) : (
                    <>
                      <i className="uil uil-clock" />
                      {bookingDetail?.ops_start_time} -{" "}
                      {bookingDetail?.ops_end_time}
                    </>
                  )}
                </div>
              </div>

              {bookingDetail?.allow_parking_request ? (
                <>
                  <Divide height={"1px"} type={"horizontal"} margin={"0"} />
                  <ParkingRequest
                    parkingRequest={bookingDetail.parking_request}
                    onRefresh={_onRefresh}
                    bookingId={modalRoomBookingDetail?.booking_id}
                    bookingType={
                      modalRoomBookingDetail?.type === TYPE_DESK
                        ? DESK_BOOKING
                        : ROOM_BOOKING
                    }
                  />
                </>
              ) : null}

              <Divide height={"1px"} type={"horizontal"} margin={"0"} />
              <div className={"modal-booking-success__detail p-0"}>
                <div className={"title"}>Booking Details</div>
                <div className={"select-item"}>
                  <div className={"text"}>Booking Reference</div>
                  <div className={"value"}>
                    {modalRoomBookingDetail?.type === TYPE_ROOM
                      ? bookingDetail?.booking_reference
                      : bookingDetail?.booking_code}
                  </div>
                </div>
                <div className={"select-item"}>
                  <div className={"text"}>Date</div>
                  <div className={"value"}>
                    {modalRoomBookingDetail?.type === TYPE_ROOM
                      ? moment
                          .tz(
                            bookingDetail?.booking_start_time * 1000,
                            bookingDetail?.timezone
                          )
                          .format(FORMAT_DATE_TIME_VARIANT_3)
                      : bookingDetail?.formatted_booking_date}
                  </div>
                </div>
                {modalRoomBookingDetail?.type === TYPE_ROOM ? (
                  <div className={"select-item"}>
                    <div className={"text"}>Booking Time</div>
                    <div className={"value"}>
                      {modalRoomBookingDetail?.type === TYPE_ROOM
                        ? moment
                            .tz(
                              bookingDetail?.booking_start_time * 1000,
                              bookingDetail?.timezone
                            )
                            .format(FORMAT_TIME_VARIANT_1) +
                          " - " +
                          moment
                            .tz(
                              bookingDetail?.booking_end_time * 1000,
                              bookingDetail?.timezone
                            )
                            .format(FORMAT_TIME_VARIANT_1)
                        : null}
                    </div>
                  </div>
                ) : null}
                <div className={"select-item"}>
                  <div className={"text"}>Duration</div>
                  <div className={"value"}>{bookingDetail?.duration}</div>
                </div>

                {modalRoomBookingDetail?.type === TYPE_ROOM ? (
                  <div className={"select-item"}>
                    <div className={"text"}>Hourly Rate</div>
                    <div className={"value"}>
                      {bookingDetail?.currency} {bookingDetail?.rate_per_hour}
                    </div>
                  </div>
                ) : null}
              </div>
              <Divide height={"1px"} type={"horizontal"} margin={"0"} />
              <div className={"modal-booking-success__detail p-0"}>
                <div className={"title"}>Payment</div>
                <div className={"select-item"}>
                  <div className={"text"}>
                    {modalRoomBookingDetail?.type === TYPE_ROOM
                      ? bookingDetail?.payment_type
                      : bookingDetail?.payment_method_type === 1
                      ? "Business"
                      : "Personal"}
                  </div>
                  <div className={"value"}>
                    {modalRoomBookingDetail?.type === TYPE_ROOM
                      ? bookingDetail?.payment
                      : bookingDetail?.payment_method}
                  </div>
                </div>
                <div className={"select-item"}>
                  <div className={"text"}>Time</div>
                  <div className={"value"}>
                    {modalRoomBookingDetail?.type === TYPE_ROOM
                      ? moment
                          .tz(
                            bookingDetail?.time * 1000,
                            bookingDetail?.timezone
                          )
                          .format(FORMAT_DATE_TIME_VARIANT_6)
                      : moment(bookingDetail?.payment_date * 1000).format(
                          FORMAT_DATE_TIME_VARIANT_6
                        )}
                  </div>
                </div>
              </div>
              {bookingDetail?.vouchers &&
              bookingDetail?.vouchers?.length > 0 &&
              bookingDetail?.vouchers[0]?.code ? (
                <>
                  <Divide height={"1px"} type={"horizontal"} margin={"0"} />
                  <div
                    className={
                      "modal-booking-success__detail d-flex flex-column gap-24 p-0"
                    }
                  >
                    {/*<div className={"title"}></div>*/}
                    {bookingDetail?.vouchers[0]?.code ? (
                      <div className={"select-item mb-0"}>
                        <div className={"text title mb-0"}>Applied Voucher</div>
                        <div className={"value"}>
                          <div className={"voucher-chip"}>
                            {bookingDetail?.vouchers[0]?.code}
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className={"select-item mb-0"}>
                      <div className={"text title mb-0"}>Subtotal</div>
                      <div className={"value"} style={{ fontSize: 20 }}>
                        {bookingDetail?.currency}{" "}
                        {bookingDetail?.price_before_discount}
                      </div>
                    </div>
                    <div className={"select-item mb-0"}>
                      <div className={"text title mb-0"}>Discount</div>
                      <div className={"value"} style={{ fontSize: 20 }}>
                        - {bookingDetail?.currency} {bookingDetail?.discount}
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              <Divide height={"1px"} type={"horizontal"} margin={"4px 0"} />
              <div className={"modal-booking-success__total"}>
                <div className={"title"}>Total Amount Paid</div>
                <div className={"value"}>
                  {bookingDetail?.currency}{" "}
                  {bookingDetail?.total_final_price ||
                    bookingDetail?.total_paid}
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Content>
    </ModalCenterTemplate>
  );
};

export default ModalBookingDetail;
