import React, { useContext } from "react";
import SizeBox from "../../../../../../components/SizeBox";
import { LocalizationContext } from "../../../../../../locales/Translation";

type Props = {
  data: any;
  type: "desk" | "meeting-room" | "private-offices";
};

const Detail = ({ data, type }: Props) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <div className={"dashboard_chart_box_wrapper_3"}>
      <div className={"dashboard_chart_box"}>
        <div className={"dashboard_chart_line"}>
          <h2 className={"dashboard_chart_sub_title"}>
            {type === "desk"
              ? translations.deskVisits
              : type === "meeting-room"
              ? translations.roomBookings
              : "Office Visits"}{" "}
          </h2>
          <SizeBox height={"18px"} />
          <span className={"dashboard_revenue"}>{data?.total}</span>
        </div>
        {type === "private-offices" ? null : (
          <div className={"dashboard_chart_line"}>
            <h2 className={"dashboard_chart_sub_title"}>
              {translations.averageDuration}
            </h2>
            <SizeBox height={"18px"} />
            <span className={"dashboard_revenue"}>
              {data?.average_duration}
            </span>
          </div>
        )}
        <div className={"dashboard_chart_line"}>
          <h2 className={"dashboard_chart_sub_title"}>
            {translations.averageValue}
          </h2>
          <SizeBox height={"18px"} />
          <span className={"dashboard_revenue"}>{data?.average_value}</span>
        </div>
      </div>
    </div>
  );
};

export default Detail;
