import React, { useContext, useEffect } from "react";
import SearchBar4Table from "../../../../../components/SearchBar4Table";
import { LocalizationContext } from "../../../../../locales/Translation";
import { useDispatch } from "react-redux";
import {
  dashboardDeskBookingFilterAction,
  dashboardDeskUsageFilterAction,
  dashboardLeaseFilterAction,
  dashboardRoomBookingFilterAction,
  dashboardDeskimoDeskUsageFilterAction,
  dashboardDeskimoDeskBookingFilterAction,
  dashboardDeskimoRoomBookingFilterAction,
} from "../../../actions/DashboardActions";
import { debounce } from "lodash";

type Props = {
  isDeskimoAdmin?: boolean;
};

const Search = ({ isDeskimoAdmin }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      _handleChangeKeyword("");
    };
  }, []);

  const debounce_onChange = debounce(function (value) {
    _handleChangeKeyword(value);
  }, 500);

  const _handleChangeKeyword = (value: string) => {
    if (isDeskimoAdmin) {
      dispatch(
        dashboardDeskimoDeskUsageFilterAction({ key: "keyword", value })
      );
      dispatch(
        dashboardDeskimoDeskBookingFilterAction({ key: "keyword", value })
      );
      dispatch(
        dashboardDeskimoRoomBookingFilterAction({ key: "keyword", value })
      );
    } else {
      dispatch(dashboardDeskUsageFilterAction({ key: "keyword", value }));
      dispatch(dashboardDeskBookingFilterAction({ key: "keyword", value }));
      dispatch(dashboardRoomBookingFilterAction({ key: "keyword", value }));
    }
    dispatch(dashboardLeaseFilterAction({ key: "keyword", value }));
  };

  return (
    <SearchBar4Table
      placeholder={translations.searchByNameTransactionId}
      onChange={(event, { value }) => debounce_onChange(value)}
    />
  );
};

export default Search;
