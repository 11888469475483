import React, { SyntheticEvent, useContext, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import { dashboardDeskimoDeskUsageFilterSelector } from "../../../../selectors/DashboardSelectors";
import { dashboardDeskimoDeskUsageFilterAction } from "../../../../actions/DashboardActions";
import DropdownWithCheckboxMenu from "../../../../../../components/DropdownWithCheckboxMenu";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";

const FilterDeskUsage = () => {
  const { translations } = useContext(LocalizationContext);

  const dispatch = useDispatch();
  const dashboardDeskUsageFilter = useSelector(
    dashboardDeskimoDeskUsageFilterSelector
  );

  const [deskUsageStatus, setDeskUsageStatus] = useState<
    { id: number; name: string }[]
  >([
    {
      id: 0,
      name: "Ongoing",
    },
    {
      id: 2,
      name: "Completed",
    },
    {
      id: 1,
      name: "Awaiting Payment",
    },
  ]);

  const _onChangeFilter = (e: SyntheticEvent, data: any) => {
    if (data.checked) {
      const newValue = [...dashboardDeskUsageFilter.filter, data.value];
      dispatch(
        dashboardDeskimoDeskUsageFilterAction({
          key: "filter",
          value: newValue,
        })
      );
    } else {
      const newValue = dashboardDeskUsageFilter.filter.filter(
        (status: number) => status !== data.value
      );
      dispatch(
        dashboardDeskimoDeskUsageFilterAction({
          key: "filter",
          value: newValue,
        })
      );
    }
  };

  return (
    <div className={"dropdown-filter"}>
      <DropdownWithCheckboxMenu
        onChange={_onChangeFilter}
        listCheckBox={deskUsageStatus.map((filter: any) => {
          if (dashboardDeskUsageFilter.filter.includes(filter.id)) {
            filter.checked = true;
          } else {
            filter.checked = false;
          }
          return filter;
        })}
        label={translations.filters}
        icon={
          <img
            alt={"Deskimo"}
            src={PUBLIC_URL + "/assets/icons/icon-filter.svg"}
          />
        }
      />
    </div>
  );
};

export default FilterDeskUsage;
