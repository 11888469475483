import React from "react";
import FlagDialingCode from "../FlagDialingCode";
import "./styles.scss";

type Props = {
  dialingCode: string;
  phoneNumber: string;
};

const PhoneWithFlag = ({ phoneNumber, dialingCode }: Props) => {
  if (!phoneNumber) {
    return null;
  }
  return (
    <div className={"phone-with-flag"}>
      <FlagDialingCode dialingCode={dialingCode} />
      <p className={"text"}>
        +{dialingCode} {phoneNumber}
      </p>
    </div>
  );
};

export default PhoneWithFlag;
