import TableCustom from "../../../../../../../../components/Table";
import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import ButtonCustom from "../../../../../../../../components/Button";
import ToggleSlider from "../../../../../../../../components/ToggleSlider";
import { useDispatch, useSelector } from "react-redux";
import { managePMSRoomListSelector } from "../../../../../../selectors/PropertySelector";
import {
  deleteManagePMSRoomService,
  updateManagePMSAutoConfirmService,
} from "../../../../../../services/ManagePMSServices";
import { getManagePMSRoomThunk } from "../../../../../../thunks/PropertyThunks";
import { useParams } from "react-router-dom";
import {
  deleteManagePMSRoomAction,
  modalManagePMSAddCalendarAction,
  updateManagePMSAutoConfirmAction,
} from "../../../../../../actions/PropertyActions";
import { PUBLIC_URL } from "../../../../../../../App/configs/Constants";
import { SWAL_DELETE_OPTION } from "../../../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../../../components/SweetAlert";

const ManagePMSTable = ({ refreshList, setRefreshList }: any) => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [pmsTable, setPMSTable] = useState([]);
  const managePMSRoomList = useSelector(managePMSRoomListSelector);

  const GOOGLE_CALENDAR = "Google Calendar";

  useEffect(() => {
    _getManagePMSRoomList();
  }, []);

  const _getManagePMSRoomList = async () => {
    try {
      setLoading(true);
      const payload = { unused: 0, property_id: id };
      await dispatch<any>(getManagePMSRoomThunk(payload));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const _deletePMSRoom = async (room: any) => {
    const iconHtml = (
      <img
        src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
        className={"swal2-icon__error"}
      />
    );
    const title = "Are you sure you want to delete this room?";
    const confirmText = (
      <div
        className={"d-flex"}
        style={{ justifyItems: "center", justifyContent: "center" }}
      >
        <div style={{ marginLeft: 5 }}>Delete</div>
      </div>
    );
    const confirmClass = "danger-outline";
    const options = SWAL_DELETE_OPTION({
      title,
      iconHtml,
      confirmText,
      confirmClass,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        const response = await deleteManagePMSRoomService({
          external_room_token: room.token,
          remove_room: true,
        });
        if (response?.status?.code !== 200) {
          return;
        } else {
          dispatch<any>(deleteManagePMSRoomAction(room.id));
          setRefreshList(!refreshList);
        }
      },
      cancelCallback: () => {
        console.log("cancel");
      },
    });
  };

  useEffect(() => {
    console.log(managePMSRoomList);
    if (managePMSRoomList && managePMSRoomList.meeting_rooms) {
      const roomData = managePMSRoomList.meeting_rooms.map((room: any) => {
        return {
          col1: <div>{room.name}</div>,
          col2: (
            <div>
              {room.integration === GOOGLE_CALENDAR ? (
                <div className={"d-flex"}>
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Google_Calendar_icon_%282020%29.svg"
                    alt="gcal_logo"
                    width={12}
                    style={{ marginRight: 8 }}
                  />
                  <div>Google Calendar</div>
                </div>
              ) : (
                "Undefined"
              )}
            </div>
          ),
          col3: (
            <div>
              <ToggleSlider
                value={room.auto_confirm}
                onChange={async () => {
                  await updateManagePMSAutoConfirmService({
                    external_room_token: room.token,
                  }).then(
                    dispatch<any>(updateManagePMSAutoConfirmAction(room.id))
                  );
                }}
              />
            </div>
          ),
          col4: (
            <ButtonCustom
              onClick={() => _deletePMSRoom(room)}
              className={"m-0 p-0 none-bg close-modal"}
            >
              <i className="uil uil-times close-button" />
            </ButtonCustom>
          ),
        };
      });
      setPMSTable(roomData);
    } else {
      setPMSTable([]);
    }
  }, [managePMSRoomList]);

  const columns = [
    <div key={"Connecting Rooms List"}>List of connected rooms</div>,
    <div key={"Integration"}>Integration</div>,
    <div key={"Auto-confirm"}>Auto-confirm</div>,
    <div key={"Action"}>Action</div>,
  ];

  return (
    <div>
      <TableCustom
        showTotal={false}
        tableClass={"manage_pms_table"}
        tableHeaderClass={"manage_pms_table"}
        loading={loading}
        tableData={pmsTable}
        noHeader={false}
        columns={columns}
      />
    </div>
  );
};

export default ManagePMSTable;
