import { Modal } from "semantic-ui-react";
import React, { useContext, useState } from "react";
import ButtonCustom from "../../../../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  employeeDetailAction,
  modalDetailEmployeeAction,
  modalEditEmployeeAction,
} from "../../../../actions/EmployeeActions";
import {
  employeeDetailSelector,
  isModalDetailEmployeeSelector,
} from "../../../../selectors/EmployeeSelectors";
import ModalRightSideBarTemplate from "../../../../../App/views/ModalRightSideBarTemplate";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { EmployeePropertyDetailType } from "../../../../configs/DeclareTypes";
import Label from "../../../../../../components/Label";
import BoxToggleSlider from "../../../../../../components/BoxToggleSlider";
import SizeBox from "../../../../../../components/SizeBox";
import "./style.scss";
import {
  ROLE_PROPERTY_ADMIN_ID,
  ROLE_PROPERTY_STAFF_ID,
} from "../../../../configs/Constants";
import { employeePropertyUpdateService } from "../../../../services/EmployeeServices";
import { SWAL_DELETE_OPTION } from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import { toastError } from "../../../../../../components/Toast";
import { employeesThunk } from "../../../../thunks/EmployeeThunks";
import { LocalizationContext } from "../../../../../../locales/Translation";
import MakeAdminLabelGuide from "../../../../../App/views/ModalAddEmployee/MakeAdminLableGuide";
import moment from "moment";
import { FORMAT_DATE_TIME_VARIANT_2 } from "../../../../../App/configs/DateTimeConfigs";
import { PROPERTY } from "../../../../../Dashboard/configs/Constants";

const ModalDetailEmployee = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const isModalDetailEmployee = useSelector(isModalDetailEmployeeSelector);
  const [loading, setLoading] = useState(false);

  const employeeDetail: EmployeePropertyDetailType = useSelector(
    employeeDetailSelector
  );

  const _onChangeAdmin = async (e: any, data: any) => {
    if (data.checked) {
      const icon = null;
      const title = translations.areYouSureYouWantToMakeThisUserAdmin;
      const confirmText = translations.makeAdmin;
      const confirmClass = "primary";
      const options = SWAL_DELETE_OPTION({
        title,
        icon,
        confirmText,
        confirmClass,
      });
      SweetAlertCommon({
        options,
        confirmCallback: async () => {
          changeEmployeeRole(data);
        },
        cancelCallback: () => null,
      });
    } else {
      changeEmployeeRole(data);
    }
  };

  const changeEmployeeRole = async (data: any) => {
    setLoading(true);
    try {
      const payload = {
        ...employeeDetail,
        make_admin: data.checked,
        role_id: data.checked ? ROLE_PROPERTY_ADMIN_ID : ROLE_PROPERTY_STAFF_ID,
      };
      await employeePropertyUpdateService(payload);
      dispatch(employeeDetailAction(payload));
      await dispatch<any>(employeesThunk(PROPERTY));
    } catch (response: any) {
      toastError(response?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const _openEditModal = async () => {
    dispatch(modalDetailEmployeeAction(false));
    dispatch(modalEditEmployeeAction(true));
  };

  return (
    <ModalRightSideBarTemplate
      loading={loading}
      trigger={null}
      onClose={() => dispatch(modalDetailEmployeeAction(false))}
      modalClassName={"modal-edit-employee"}
      open={isModalDetailEmployee}
    >
      <Modal.Header>
        {translations.userDetails}
        <ButtonCustom
          onClick={() => dispatch(modalDetailEmployeeAction(false))}
          className={"m-0 p-0 none-bg close-modal"}
        >
          <>
            {translations.close}
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Modal.Content className={"scroll"}>
        <SizeBox height={"28px"} />
        <div className={"employee-meta__row"}>
          <div className={"fw-500"}>{translations.name}:</div>{" "}
          {employeeDetail?.first_name + " " + employeeDetail?.last_name}
        </div>
        <SizeBox height={"26px"} />
        <div className={"employee-meta__row"}>
          <div className={"fw-500"}>{translations.email}:</div>{" "}
          {employeeDetail?.email}
        </div>
        {employeeDetail?.phone ? (
          <>
            <SizeBox height={"26px"} />
            <div className={"employee-meta__row"}>
              <div className={"fw-500"}>{translations.phoneNo}:</div>
              {employeeDetail?.phone}
            </div>
          </>
        ) : null}

        <SizeBox height={"26px"} />
        <div className={"employee-meta__row"}>
          <div className={"fw-500"}>{translations.accountStatus}:</div>
          {translations.accountStatusList[employeeDetail?.status]}
        </div>
        <SizeBox height={"26px"} />
        <div className={"employee-meta__row"}>
          <div className={"fw-500"}>{translations.firstAdded}:</div>
          {moment
            .unix(employeeDetail?.created_at)
            .format(FORMAT_DATE_TIME_VARIANT_2)}
        </div>
        <SizeBox height={"26px"} />
        <div className={"employee-meta__row"}>
          <div className={"fw-500"}>Properties:</div>
          <ul>
            {employeeDetail?.properties?.length > 0
              ? employeeDetail?.properties.map((property: any) => (
                  <li style={{ marginBottom: 4 }} key={property?.id}>
                    {property?.name}
                  </li>
                ))
              : null}
          </ul>
        </div>
        <BoxToggleSlider
          value={employeeDetail?.role_id === ROLE_PROPERTY_ADMIN_ID}
          onChange={_onChangeAdmin}
          content={
            <Label
              fontSize={"14px"}
              size={"16px"}
              label={translations.adminRights}
              labelGuide={<MakeAdminLabelGuide />}
            />
          }
        />

        <SizeBox height={"22px"} />

        <ButtonCustom
          onClick={_openEditModal}
          className={"primary-alt"}
          height={"36px"}
        >
          <>{translations.editUser}</>
          <SizeBox width={"6px"} />
          <i className="uil uil-edit" />
        </ButtonCustom>
      </Modal.Content>
    </ModalRightSideBarTemplate>
  );
};

export default ModalDetailEmployee;
