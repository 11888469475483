import { createReducer } from "@reduxjs/toolkit";
import {
  MODAL_PAID_TRIAL_PACKAGE_ACTION,
  MODAL_TOP_PROPERTIES_ACTION,
  PACKAGE_TRIAL_INFO_ACTION,
  TOP_PROPERTIES_INFO_ACTION,
} from "../configs/Constants";

const initialState = {
  modalPaidTrailPackage: false,
  modalTopProperties: false,
  packageTrialInfo: null,
  topPropertiesInfo: [],
};
const OnboardReducer = createReducer(initialState, {
  [MODAL_PAID_TRIAL_PACKAGE_ACTION]: (state, action) => {
    state.modalPaidTrailPackage = action.payload;
  },
  [MODAL_TOP_PROPERTIES_ACTION]: (state, action) => {
    state.modalTopProperties = action.payload;
  },
  [PACKAGE_TRIAL_INFO_ACTION]: (state, action) => {
    state.packageTrialInfo = action.payload;
  },
  [TOP_PROPERTIES_INFO_ACTION]: (state, action) => {
    state.topPropertiesInfo = action.payload;
  },
});

export default OnboardReducer;
