import { ORDER_BY_DESC } from "../../App/configs/Constants";

export const COMPANY_FILTER_ACTION = "COMPANY_FILTER_ACTION";
export const COMPANY_LIST_ACTION = "COMPANY_LIST_ACTION";
export const COMPANY_LIST_PAY_POST_ACTION = "COMPANY_LIST_PAY_POST_ACTION";
export const COMPANY_LIST_CAN_BUY_CREDIT_ACTION =
  "COMPANY_LIST_CAN_BUY_CREDIT_ACTION";
export const COMPANY_LIST_DELETE_ACTION = "COMPANY_LIST_DELETE_ACTION";
export const COMPANY_DETAIL_ACTION = "COMPANY_DETAIL_ACTION";
export const COMPANY_STAFF_ACTION = "COMPANY_STAFF_ACTION";
export const COMPANY_STAFF_FILTER_ACTION = "COMPANY_STAFF_FILTER_ACTION";
export const COMPANY_PENDING_INVITATION_ACTION =
  "COMPANY_PENDING_INVITATION_ACTION";
export const COMPANY_INVOICE_ACTION = "COMPANY_INVOICE_ACTION";
export const COMPANY_INVOICE_FILTER_ACTION = "COMPANY_INVOICE_FILTER_ACTION";
export const COMPANY_STAFF_DELETE_ACTION = "COMPANY_STAFF_DELETE_ACTION";
export const COMPANY_STAFF_MAKE_ADMIN_ACTION =
  "COMPANY_STAFF_MAKE_ADMIN_ACTION";
export const COMPANY_STAFF_DEACTIVATE_ACTION =
  "COMPANY_STAFF_DEACTIVATE_ACTION";
export const COMPANY_STAFF_REINVITE_ACTION = "COMPANY_STAFF_REINVITE_ACTION";
export const MODAL_COMPANY_STAFF_EDIT_ACTION =
  "MODAL_COMPANY_STAFF_EDIT_ACTION";
export const COMPANY_STAFF_EDIT_ACTION = "COMPANY_STAFF_EDIT_ACTION";
export const COMPANY_CUSTOM_PACKAGES_ACTION = "COMPANY_CUSTOM_PACKAGES_ACTION";
export const MODAL_COMPANY_CUSTOM_PACKAGE_ACTION =
  "MODAL_COMPANY_CUSTOM_PACKAGE_ACTION";
export const COMPANY_CUSTOM_PACKAGE_DELETE_ACTION =
  "COMPANY_CUSTOM_PACKAGE_DELETE_ACTION";
export const MODAL_UPDATE_AUTO_TOPUP_ACTION =
  "COMPANY_MODAL_UPDATE_AUTO_TOPUP_ACTION";

export const PURCHASE_HISTORY_FILTERS_ACTION = "PURCHASE_CREDIT_FILTERS_ACTION";

export const PREPAID_CREDIT_FILTERS_ACTION = "PREPAID_CREDIT_FILTERS_ACTION";

export const CONTRACT_DAILY_TYPE = "DAILY";
export const CONTRACT_MONTHLY_TYPE = "MONTHLY";
export const CONTRACT_ONETIME_TYPE = "ONETIME";

export const LEASE_CREATED_STATUS = "CREATED";
export const LEASE_ONGOING_STATUS = "ON_GOING";
export const LEASE_ENDED_STATUS = "ENDED";

export const FILTER_LEASE_DEFAULT: {
  keyword: string;
  sort_by: string;
  sort_type: string;
  page: number;
  company_id: number;
  market_code: string[];
} = {
  keyword: "",
  sort_by: "status",
  sort_type: ORDER_BY_DESC,
  company_id: 0,
  page: 1,
  market_code: [],
};

export const INVOICE_DRAFT_STATUS = 0;
export const INVOICE_PENDING_PAYMENT_STATUS = 1;
export const INVOICE_PAID_STATUS = 2;
