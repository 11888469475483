import React, { useContext } from "react";
import { LocalizationContext } from "../../../../locales/Translation";

const MakeAdminLabelGuide = () => {
  const { translations } = useContext(LocalizationContext);

  return (
    <>
      <div>{translations.makeAdminLabel}:</div>
      <ul>
        <li>- {translations.makeAdminSubLabel_1}</li>
        <li>- {translations.makeAdminSubLabel_2}</li>
        <li>- {translations.makeAdminSubLabel_3}</li>
      </ul>
    </>
  );
};

export default MakeAdminLabelGuide;
