import clientRequestService from "../../../services/ClientRequestService";
import {
  API_GET_TEAM_MEMBER,
  API_INVITE_TEAM_MEMBER,
} from "../configs/Endpoints";

export const invitePartnerTeamMember = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: API_INVITE_TEAM_MEMBER,
    data,
    isCompany: true,
  });
};

export const getPartnerTeamMember = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: API_GET_TEAM_MEMBER,
    data,
    isCompany: true,
  });
};
