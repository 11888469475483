export const API_LOGIN = "/oauth/v2/token";
export const API_FORGOT_PASSWORD = "/api/v1/forgot-password";
export const API_REMINDER_CALLBACK = "/api/v1/reminder-callback/:token";
export const API_RESET_PASSWORD = "/api/v1/reset-password/%token%";
export const PERMISSION_API = "/api/v1/my-permision/%companyId%";

export const API_CHECK_STILL_LOGIN = "/api/v1/user/check-login";

export const API_CHECK_ROOM_BOOKING_STATUS =
  "/api/v1/property/anonymous/allow-to-change-booking-status/room?token=%token%";

export const API_CHECK_DESK_BOOKING_STATUS =
  "/api/v1/property/anonymous/allow-to-change-booking-status/desk?token=%token%";
export const API_CHANGE_ROOM_BOOKING_STATUS =
  "/api/v1/property/anonymous/change-booking-status/room?token=%token%";

export const API_CHANGE_DESK_BOOKING_STATUS =
  "/api/v1/property/anonymous/change-booking-status/desk?token=%token%";
