import React, { useContext, useState } from "react";
import ModalCenterTemplate from "../../../../../App/views/ModalCenterTemplate";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../../components/Button";
import { LocalizationContext } from "../../../../../../locales/Translation";
import SearchUser from "./SearchUser";
import TableUser from "./TableUser";
import "../../../../styles/invite-employee-google.scss";
import CheckboxCustom from "../../../../../../components/Checkbox";
import {
  employeeGoogleChooseSelector,
  isModalImportEmployeeGoogleSelector,
} from "../../../../selectors/EmployeeSelectors";
import { useDispatch, useSelector } from "react-redux";
import { modalImportEmployeeGoogleAction } from "../../../../actions/EmployeeActions";
import {
  inviteEmployeeByGoogleService,
  toastGoogleInviteSuccess,
} from "../../../../services/EmployeeServices";
import { employeesThunk } from "../../../../thunks/EmployeeThunks";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { useLocation } from "react-router-dom";
import {
  EMPLOYEES_BUSINESS_LINK,
  INTEGRATIONS_LINK,
} from "../../../../../App/configs/ListRoutes";
import { integrationListThunk } from "../../../../../Setting/thunks/IntegrationThunk";
import { GATrackingEvent } from "../../../../../../services/GATrackingService";

const ModalInviteEmployeeByGoogle = () => {
  const { translations } = useContext(LocalizationContext);
  const location = useLocation();
  const isModalImportEmployeeGoogle = useSelector(
    isModalImportEmployeeGoogleSelector
  );
  const dispatch = useDispatch();
  const employeeGoogleChoose = useSelector(employeeGoogleChooseSelector);

  const [loading, setLoading] = useState(false);
  const [autoInvite, setAutoInvite] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState("");

  const _onClose = () => {
    GATrackingEvent("gg_integration_invite_cancel");
    dispatch(modalImportEmployeeGoogleAction(false));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const payload = {
        employees: employeeGoogleChoose,
        auto_invite: autoInvite,
      };
      await inviteEmployeeByGoogleService(payload);
      if (employeeGoogleChoose.length === 0) {
        toastSuccess("Successfully Updated");
      } else if (employeeGoogleChoose.length === 1) {
        toastGoogleInviteSuccess({
          badgeText: translations.SUCCESSFULLY_ADDED,
          title: `${employeeGoogleChoose[0].first_name} ${employeeGoogleChoose[0].last_name} ${translations.ggInviteSuccessTitle}`,
          description: translations.ggInviteSuccessDes,
        });
      } else {
        toastGoogleInviteSuccess({
          badgeText: translations.SUCCESSFULLY_ADDED,
          title: `${translations.listUsers} ${translations.ggInviteSuccessTitle}`,
          description: translations.ggInviteSuccessDes,
        });
      }

      _onClose();

      if (location.pathname === EMPLOYEES_BUSINESS_LINK) {
        dispatch<any>(employeesThunk());
      } else if (location.pathname === INTEGRATIONS_LINK) {
        dispatch<any>(integrationListThunk());
      }
    } catch (e: any) {
      toastError(e?.status?.message);
    } finally {
      GATrackingEvent("gg_integration_invite_user");
      setLoading(false);
    }
  };

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={_onClose}
      modalClassName={"modal-invite-employee-google"}
      open={isModalImportEmployeeGoogle}
    >
      <Modal.Header>
        <span>{translations.inviteEmployees}</span>
      </Modal.Header>
      <Modal.Content>
        <SearchUser searchValue={searchValue} setSearchValue={setSearchValue} />
        <TableUser searchValue={searchValue} />
        <CheckboxCustom
          checked={autoInvite}
          onChange={() => {
            setAutoInvite((prevState) => {
              if (prevState) {
                GATrackingEvent("gg_integration_uncheck_auto_invite");
              }

              return !prevState;
            });
          }}
          label={translations.ggAutomaticInvite}
        />
      </Modal.Content>
      <Modal.Actions className={"border-0"}>
        <ButtonCustom
          disabled={loading}
          height={"47px"}
          fontSize={"16px"}
          onClick={_onClose}
        >
          {translations.cancel}
        </ButtonCustom>
        <ButtonCustom
          loading={loading}
          fontSize={"16px"}
          className={"primary"}
          height={"47px"}
          onClick={handleSubmit}
        >
          {translations.invite}
        </ButtonCustom>
      </Modal.Actions>
    </ModalCenterTemplate>
  );
};

export default ModalInviteEmployeeByGoogle;
