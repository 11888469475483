import React, { useContext, useEffect, useRef, useState } from "react";
import Label from "../../../../../components/Label";
import ButtonCustom from "../../../../../components/Button";
import SizeBox from "../../../../../components/SizeBox";
import DropdownWithCheckboxMenu from "../../../../../components/DropdownWithCheckboxMenu";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import { LocalizationContext } from "../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import { propertyFilterAction } from "../../../actions/BookActions";
import {
  deskAmenitiesSelector,
  filterMarketSelector,
  propertyFilterSelector,
} from "../../../selectors/BookSelectors";
import {
  FILTER_AMENITY_KEY,
  FILTER_END_TIME_KEY,
  FILTER_SORT_BY_BEST_RATED,
  FILTER_SORT_BY_KEY,
  FILTER_SORT_BY_LOWER_PRICE,
  FILTER_SORT_BY_NEAREST,
  FILTER_SORT_BY_PROMOTIONS,
  FILTER_START_TIME_KEY,
} from "../../../configs/Constants";
import { convertDate2StatEndTimeUsingMoment } from "../../../services/Common";
import DropdownWithRadioMenu from "../../../../../components/DropdownWithRadioMenu";
import moment from "moment-timezone";
import DatePickerSingleV2 from "../../../../../components/DatePickerSingleV2";
import { areObjectsEqual } from "../../../../../utils/commons";
import Autocomplete from "react-google-autocomplete";

const FilterDesk = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const propertyFilter = useSelector(propertyFilterSelector);
  const filterMarket = useSelector(filterMarketSelector);
  const [dateChoose, setDateChoose] = useState(
    sessionStorage.getItem("DEFAULT_DATE")
  );

  const deskAmenities = useSelector(deskAmenitiesSelector);
  const [place, setPlace] = useState<any>(null);
  const inputRef = useRef<any>(null);

  useEffect(() => {
    handleAutocompleteSize();
  }, [inputRef]);

  const handleAutocompleteSize = () => {
    const styleTag = document.createElement("style");

    if (window.innerWidth > 992) {
      if (inputRef?.current?.clientWidth) {
        styleTag.textContent = `
      .pac-container {
      width: ${inputRef.current.clientWidth + 161}px !important;
      }
    `;

        document.head.appendChild(styleTag);
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (!filterMarket) {
          return;
        }

        _handleShowAvailability();
      } catch (e) {
        console.log(e);
      }
    })();
  }, [filterMarket, place]);

  const decoratedListAmenity = () => {
    return deskAmenities.map((amenity: any) => {
      return {
        checked:
          propertyFilter[FILTER_AMENITY_KEY].length === 0
            ? false
            : propertyFilter[FILTER_AMENITY_KEY].includes(amenity?.id),
        id: amenity?.id,
        name: (
          <label>
            <img alt={""} src={amenity?.icon} />
            {amenity?.name}
          </label>
        ),
      };
    });
  };

  const decoratedListSortBy = () => {
    return [
      {
        checked: propertyFilter[FILTER_SORT_BY_KEY] === FILTER_SORT_BY_NEAREST,
        id: FILTER_SORT_BY_NEAREST,
        name: translations.deskSortBy[FILTER_SORT_BY_NEAREST],
      },
      {
        checked:
          propertyFilter[FILTER_SORT_BY_KEY] === FILTER_SORT_BY_LOWER_PRICE,
        id: FILTER_SORT_BY_LOWER_PRICE,
        name: translations.deskSortBy[FILTER_SORT_BY_LOWER_PRICE],
      },
      {
        checked:
          propertyFilter[FILTER_SORT_BY_KEY] === FILTER_SORT_BY_BEST_RATED,
        id: FILTER_SORT_BY_BEST_RATED,
        name: translations.deskSortBy[FILTER_SORT_BY_BEST_RATED],
      },
      {
        checked:
          propertyFilter[FILTER_SORT_BY_KEY] === FILTER_SORT_BY_PROMOTIONS,
        id: FILTER_SORT_BY_PROMOTIONS,
        name: translations.deskSortBy[FILTER_SORT_BY_PROMOTIONS],
      },
    ];
  };

  const _handleShowAvailability = () => {
    let startTime = null;
    let endTime = null;

    if (dateChoose) {
      const time = convertDate2StatEndTimeUsingMoment(
        filterMarket?.timezone,
        dateChoose
      );

      startTime = time.startTime;
      endTime = time.endTime;
    }

    const filters = {
      ...propertyFilter,
      [FILTER_START_TIME_KEY]: startTime,
      [FILTER_END_TIME_KEY]: endTime,
      page: 1,
    };

    if (filterMarket) {
      filters.city_code = filterMarket.city_code;
    }

    if (place?.geometry?.location?.lat() && place?.geometry?.location?.lng()) {
      filters.lat = place?.geometry?.location?.lat();
      filters.lng = place?.geometry?.location?.lng();
      filters.searchByLandmark = true;
    }

    // if (!areObjectsEqual(filters, propertyFilter)) {
    dispatch(propertyFilterAction(filters));
    // }
  };

  const _handleFilterAmenity = (amenityId: string) => {
    let amenitiesFilter = [...propertyFilter[FILTER_AMENITY_KEY]];

    if (amenitiesFilter.includes(amenityId)) {
      amenitiesFilter = amenitiesFilter.filter(
        (id: string) => id !== amenityId
      );
    } else {
      amenitiesFilter.push(amenityId);
    }

    sessionStorage.setItem("DEFAULT_AMENITIES", amenitiesFilter.toString());
    const filters = {
      ...propertyFilter,
      [FILTER_AMENITY_KEY]: amenitiesFilter,
      page: 1,
    };
    if (!areObjectsEqual(filters, propertyFilter)) {
      dispatch(propertyFilterAction(filters));
    }
  };

  const _handleFilterSortBy = (sortIndex: any) => {
    const filters = {
      ...propertyFilter,
      [FILTER_SORT_BY_KEY]: sortIndex,
      page: 1,
    };

    sessionStorage.setItem("DEFAULT_SORTBY", sortIndex);

    if (!areObjectsEqual(filters, propertyFilter)) {
      dispatch(propertyFilterAction(filters));
    }
  };

  return (
    <div className={"book-filter-wrapper"}>
      <div className="book-filter-wrapper__top">
        <div className={"filter-item"}>
          <Label label={"Date"} fontSize={"12px"} />
          <DatePickerSingleV2
            format={"DD/MM/YYYY"}
            allowClear={false}
            value={dateChoose ? moment(dateChoose) : null}
            disabledDate={(date: any) => {
              return moment(date).isBefore(moment().subtract({ d: 1 }));
            }}
            showToday={false}
            className={"w-100"}
            onChange={(date: any) => {
              const time = convertDate2StatEndTimeUsingMoment(
                filterMarket?.timezone,
                date
              );

              const startTime = time.startTime;
              const endTime = time.endTime;

              const filters = {
                ...propertyFilter,
                defaultDate: date,
                [FILTER_START_TIME_KEY]: startTime,
                [FILTER_END_TIME_KEY]: endTime,
                page: 1,
                default_date: date,
              };
              sessionStorage.setItem("DEFAULT_DATE", date);
              dispatch(propertyFilterAction(filters));
              setDateChoose(date);
            }}
          />
          <div className={"filter-sub-label"}>
            Timezone: {filterMarket?.timezone}
          </div>
        </div>
        <SizeBox width={"16px"} />
        <div className={"filter-item"}>
          <Label
            label={translations.NearWhere}
            fontSize={"12px"}
            placeholder={"Search"}
          />
          <Autocomplete
            defaultValue={
              sessionStorage.getItem("DEFAULT_LOCATION")
                ? JSON.parse(sessionStorage.getItem("DEFAULT_LOCATION") || "")
                    ?.address
                : undefined
            }
            ref={inputRef}
            onChange={(e: any) => {
              if (e?.target?.value?.trim()?.length === 0) {
                const filters = {
                  ...propertyFilter,
                  lat: null,
                  lng: null,
                  searchByLandmark: false,
                };
                dispatch(propertyFilterAction(filters));
              }
            }}
            className={"google-search-input"}
            apiKey={process.env.REACT_APP_GG_KEY}
            style={{ width: "100%" }}
            onPlaceSelected={(place) => {
              const sessionObj = {
                address: place?.formatted_address,
                lat: place?.geometry?.location?.lat(),
                lng: place?.geometry?.location?.lng(),
              };
              sessionStorage.setItem(
                "DEFAULT_LOCATION",
                JSON.stringify(sessionObj)
              );
              setPlace(place);
            }}
            options={{
              types: [],
              componentRestrictions: {
                country: filterMarket?.alpha2_country_code || "",
              },
            }}
          />
          <SizeBox height={"20px"} />
        </div>

        <div className={"filter-button"}>
          <ButtonCustom
            height={"44px"}
            className={"primary w-100 mr-0"}
            onClick={_handleShowAvailability}
          >
            {translations.show}
          </ButtonCustom>
          <SizeBox height={"20px"} extraClass={"d-none d-md-flex"} />
        </div>
      </div>
      <SizeBox height={"12px"} />
      <div className={"book-filter-wrapper__bottom"}>
        <DropdownWithCheckboxMenu
          menuTitle={translations.propertyAmenities.amenities}
          reverseText={false}
          rowClassName={"book-filter-sub__item-row"}
          onChange={(event: any, { value }: { value: string }) => {
            _handleFilterAmenity(value);
          }}
          listCheckBox={decoratedListAmenity()}
          label={
            <span className={"filter-amenity"}>
              Amenities
              {propertyFilter.amenities.length ? (
                <span className={"filter-amenity-length"}>
                  {propertyFilter.amenities.length}
                </span>
              ) : null}
            </span>
          }
          icon={
            <img
              className={"dropdown icon"}
              src={PUBLIC_URL + "/assets/icons/icon-caret-down.svg"}
            />
          }
        />

        <div className={"line"} />

        <DropdownWithRadioMenu
          rowClassName={"book-filter-sub__item-row"}
          onChange={(event: any, { value }: { value: string }) => {
            _handleFilterSortBy(value);
          }}
          listCheckBox={decoratedListSortBy()}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          label={translations.deskSortBy[propertyFilter.sort_by]}
          icon={
            <img
              className={"dropdown icon"}
              src={PUBLIC_URL + "/assets/icons/icon-caret-down.svg"}
            />
          }
        />
      </div>
    </div>
  );
};

export default FilterDesk;
