import { Link, NavLink, useLocation } from "react-router-dom";
import { PROPERTY_USER_GUIDE, PUBLIC_URL } from "../../configs/Constants";
import ButtonCustom from "../../../../components/Button";
import ProfileDropdown from "./ProfileDropdown";
import { useDispatch } from "react-redux";
import { modalNavbarMobileAction } from "../../actions/AppActions";
import {
  DASHBOARD_PROPERTY_LINK,
  EMPLOYEES_PROPERTY_LINK,
  INVOICE_PROPERTY_LINK,
  MEDIA_FILE_LINK,
  PROPERTY_DETAIL_LINK,
  PROPERTY_LIST_LINK,
} from "../../configs/ListRoutes";
import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "../../../../utils/commons";
import NavBarPropertyMobile from "../ModalNavBarMobile/NavBarPropertyMobile";
import { LocalizationContext } from "../../../../locales/Translation";
import { usePermissionCheck } from "../../../../utils/custom-hooks";
import {
  COMPANY_PROPERTY_USER_PER,
  INVOICE_MANAGE_PER,
  UI_PROPERTY_MANAGE_PER,
} from "../../../../utils/PermissionConstant";
import PendingInvitePopup from "../../../Dashboard/views/Components/PendingInvitePopup";
import BellNotification from "./Components/BellNotification";
import MediaIcon from "./Components/MediaIcon";

const NavBar = () => {
  const dispatch = useDispatch();
  const { translations } = useContext(LocalizationContext);
  const [propertyNavActive, setPropertyNavActive] = useState<boolean>(false);
  const location = useLocation();
  const [mediaNavActive, setMediaNavActive] = useState<boolean>(false);

  useEffect(() => {
    if (
      location.pathname === PROPERTY_LIST_LINK ||
      location.pathname.includes(PROPERTY_DETAIL_LINK.replaceAll(":id/", ""))
    ) {
      setPropertyNavActive(true);
    } else {
      setPropertyNavActive(false);
    }

    if (location.pathname === MEDIA_FILE_LINK) {
      setMediaNavActive(true);
    } else {
      setMediaNavActive(false);
    }
  }, [location]);

  return (
    <div className={"navbar"}>
      {isMobile() ? (
        <ButtonCustom
          onClick={() => dispatch(modalNavbarMobileAction(true))}
          className={"primary-alt navbar__toggle"}
          height={"37px"}
        >
          <i className="uil uil-bars" />
        </ButtonCustom>
      ) : (
        <Link to={DASHBOARD_PROPERTY_LINK} className={"navbar__logo"}>
          <img src={PUBLIC_URL + "/assets/logo.svg"} alt="deskimo-logo" />
        </Link>
      )}

      <div className={"navbar__left"}>
        <NavLink
          className={({ isActive }) => (isActive ? "active" : "")}
          to={DASHBOARD_PROPERTY_LINK}
        >
          <i className="uil uil-chart" />
          {translations.navBar.dashboard}
        </NavLink>
        {usePermissionCheck(UI_PROPERTY_MANAGE_PER) && (
          <NavLink
            className={({ isActive }) =>
              propertyNavActive || isActive ? "active" : ""
            }
            to={PROPERTY_LIST_LINK}
          >
            <i className="uil uil-building" />
            {translations.properties}
          </NavLink>
        )}
        {usePermissionCheck(COMPANY_PROPERTY_USER_PER) && (
          <NavLink
            className={({ isActive }) => (isActive ? "active" : "")}
            to={EMPLOYEES_PROPERTY_LINK}
          >
            <i className="uil uil-users-alt" />
            {translations.users}
          </NavLink>
        )}
        {usePermissionCheck(INVOICE_MANAGE_PER) && (
          <NavLink
            className={({ isActive }) => (isActive ? "active" : "")}
            to={INVOICE_PROPERTY_LINK}
          >
            <i className="uil uil-receipt-alt" />
            {translations.invoices}
          </NavLink>
        )}
        <NavLink
          className={({ isActive }) =>
            mediaNavActive || isActive ? "active" : ""
          }
          to={MEDIA_FILE_LINK}
        >
          <MediaIcon isActive={mediaNavActive} />
          {translations.navBar.mediaFileTitle}
        </NavLink>
        <a href={PROPERTY_USER_GUIDE} target={"_blank"} rel="noreferrer">
          <i className="bx bx-help-circle" />
          {translations.help}
        </a>
      </div>
      <div className={"navbar__right"}>
        <BellNotification />
        <ProfileDropdown />
        <PendingInvitePopup />

        {isMobile() ? <NavBarPropertyMobile /> : null}
      </div>
    </div>
  );
};

export default NavBar;
