import ModalCenterTemplate from "../../../../App/views/ModalCenterTemplate";
import { modalPropertyScannerQrCodeAction } from "../../../actions/PropertyActions";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/Button";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import { Modal } from "semantic-ui-react";
import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import {
  deskimoPropertyScannerQrCodeSelector,
  modalDeskimoPropertyScannerQrCodeSelector,
} from "../../../selectors/PropertySelector";
import { QRCodeCanvas } from "qrcode.react";
import { Buffer } from "buffer";
import Radio from "../../../../../components/Radio";

const STEP_AT_PRESENT = true;

const ModalGetQrCode = () => {
  const [firstStepCheck, setFirstStepCheck] = useState(true);
  const [secondStepCheck, setSecondStepCheck] = useState(false);
  const [thirdStepCheck, setThirdStepCheck] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const { translations } = useContext(LocalizationContext);
  const isModalPropertyScannerQrCode = useSelector(
    modalDeskimoPropertyScannerQrCodeSelector
  );
  const propertyScannerQrCode = useSelector(
    deskimoPropertyScannerQrCodeSelector
  );
  const dispatch = useDispatch();

  useEffect(() => {
    _decodeQrCode(propertyScannerQrCode);
  }, [propertyScannerQrCode]);

  const _decodeQrCode = (data: string) => {
    setQrCode(Buffer.from(data, "base64").toString("ascii"));
    return;
  };

  const _onResetStep = () => {
    setSecondStepCheck(false);
    setThirdStepCheck(false);
  };

  useEffect(() => {
    if (isModalPropertyScannerQrCode === false) {
      _onResetStep();
    }
  }, [isModalPropertyScannerQrCode]);

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={() => dispatch(modalPropertyScannerQrCodeAction(false))}
      modalClassName={"modal-general"}
      open={isModalPropertyScannerQrCode}
    >
      <Modal.Header>
        {translations.propertyScanners.scannerSetup}
        <ButtonCustom
          onClick={() => {
            dispatch(modalPropertyScannerQrCodeAction(false));
            _onResetStep();
          }}
          className={"modal-close-cta"}
        >
          <>
            <span>{translations.close}</span>
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Modal.Content className="qr-code-modal-content">
        <div className="qr-code-step-header-section">
          <div className="step-one-section">
            <div className="step-header-title">
              {translations.propertyScanners.stepOne}
            </div>
            <div>{translations.propertyScanners.stepOneLabel}</div>
          </div>
          <div className="step-two-section">
            <div className="step-header-title">
              {translations.propertyScanners.stepTwo}
            </div>
            <div>{translations.propertyScanners.stepTwoLabel}</div>
          </div>
          <div className="step-three-section">
            <div className="step-header-title">
              {translations.propertyScanners.stepThree}
            </div>
            <div>{translations.propertyScanners.stepThreeLabel}</div>
          </div>
        </div>
        <div className="qr-code-step-section">
          <Radio
            value={STEP_AT_PRESENT}
            currentValue={firstStepCheck}
            onClick={() => {
              setFirstStepCheck(true);
              setSecondStepCheck(false);
              setThirdStepCheck(false);
            }}
          />
          <hr
            className={
              firstStepCheck && secondStepCheck ? "first-line-active" : ""
            }
          />
          <Radio
            value={STEP_AT_PRESENT}
            currentValue={secondStepCheck}
            onClick={() => {
              setFirstStepCheck(true);
              setSecondStepCheck(true);
              setThirdStepCheck(false);
            }}
          />
          <hr
            className={
              firstStepCheck && secondStepCheck && thirdStepCheck
                ? "second-line-active"
                : ""
            }
          />
          <Radio
            value={STEP_AT_PRESENT}
            currentValue={thirdStepCheck}
            onClick={() => {
              setFirstStepCheck(true);
              setSecondStepCheck(true);
              setThirdStepCheck(true);
            }}
          />
        </div>
        <div className="qr-code-section">
          {firstStepCheck && !secondStepCheck && !thirdStepCheck ? (
            <img
              alt="initialise_qr_code"
              height={252}
              width={252}
              src={PUBLIC_URL + "/assets/images/new-scanner-password.png"}
            />
          ) : null}
          {firstStepCheck && secondStepCheck && !thirdStepCheck ? (
            <QRCodeCanvas size={252} value={qrCode} level="M" />
          ) : null}
          {firstStepCheck && secondStepCheck && thirdStepCheck ? (
            <img
              alt="restart_qr_code"
              height={252}
              width={252}
              src={PUBLIC_URL + "/assets/images/qr-code-restart-device.png"}
            />
          ) : null}
        </div>
      </Modal.Content>
    </ModalCenterTemplate>
  );
};

export default ModalGetQrCode;
