import React from "react";
// import { LocalizationContext } from "../../../../../../locales/Translation";
import { useSelector } from "react-redux";
import { ConfigMarketExchangeRateDataType } from "../../../../configs/DeclareTypes";
import TableCustom from "../../../../../../components/Table";
import { configMarketExchangeRateListSelector } from "../../../../selectors/ConfigSelectors";

type Props = {
  loading: boolean;
  onChangePagination: any;
};

const MarketExchangeRateTable = ({ loading, onChangePagination }: Props) => {
  // const { translations } = useContext(LocalizationContext);
  const marketExchangeRateList = useSelector(
    configMarketExchangeRateListSelector
  );

  const columns = ["From", "To", "Exchange rate"];

  const _formatInputData = () => {
    if (!marketExchangeRateList.items) {
      return [];
    }
    return marketExchangeRateList?.items.map(
      ({ from, to, value }: ConfigMarketExchangeRateDataType) => {
        return {
          from,
          to,
          value,
        };
      }
    );
  };

  return (
    <div
      className={"employee-table"}
      style={marketExchangeRateList?.length < 3 ? { paddingBottom: 100 } : {}}
    >
      <TableCustom
        loading={loading}
        showTotal={false}
        tableData={marketExchangeRateList ? _formatInputData() : []}
        totalItems={marketExchangeRateList?.pagination?.total}
        itemPerPage={marketExchangeRateList?.pagination?.limit}
        currentPage={marketExchangeRateList?.pagination?.page}
        onChangePagination={onChangePagination}
        columns={columns}
      />
    </div>
  );
};

export default MarketExchangeRateTable;
