import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { deskimoPropertyListService } from "../../../../services/PropertyServices";
import { deskimoPrivateOfficeFilterTmpAction } from "../../../../actions/PropertyActions";
import { useDispatch, useSelector } from "react-redux";
import { deskimoPrivateOfficeFiltersTmpSelector } from "../../../../selectors/PropertySelector";

const SelectWorkspace = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const filtersTmp = useSelector(deskimoPrivateOfficeFiltersTmpSelector);
  const _initOptions = async (keyword = "") => {
    try {
      const { data } = await deskimoPropertyListService({
        keyword,
        page: 1,
      });

      const temp = data?.items?.map(
        ({
          workspace_id,
          workspace_name,
        }: {
          workspace_id: string;
          workspace_name: string;
        }) => {
          return {
            text: workspace_name,
            value: workspace_id,
          };
        }
      );
      setData(temp);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    (async () => {
      await _initOptions();
    })();
  }, []);

  const handleSearch = async (newValue: any) => {
    await _initOptions(newValue);
  };

  const handleChange = (newValue: any) => {
    dispatch(
      deskimoPrivateOfficeFilterTmpAction({
        ...filtersTmp,
        filter: { ...filtersTmp.filter, workspaces: newValue },
      })
    );
  };

  return (
    <>
      <Select
        showSearch
        value={filtersTmp.filter.workspaces}
        placeholder={"Select Workspace..."}
        mode={"multiple"}
        maxTagCount={"responsive"}
        style={{ width: "100%" }}
        defaultActiveFirstOption={false}
        suffixIcon={null}
        filterOption={false}
        onSearch={handleSearch}
        onChange={handleChange}
        notFoundContent={null}
        options={(data || []).map((d: any) => ({
          value: d.value,
          label: d.text,
        }))}
        removeIcon={<i className="uil uil-times-circle" />}
      />
    </>
  );
};

export default SelectWorkspace;
