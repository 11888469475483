import { MarkerExtended } from "@react-google-maps/marker-clusterer";
import { BookMeetingRoomType, BookPropertyType } from "../configs/DeclareType";
import { handleClickPin } from "./BookServices";
import { infoWindowDataAction } from "../actions/BookActions";
import { DESK_TYPE, MEETING_ROOM_TYPE } from "../configs/Constants";
import { store } from "../../App/configs/Store";
import {
  CIRCLE_RADIUS_ZOOM,
  DISTANCE_CLOSE_MAP_ZOOM,
  DISTANCE_MAP_ZOOM,
} from "../configs/MapConstants";
import {
  computeDistanceBetween,
  LatLngLike,
  LatLngLiteral,
} from "spherical-geometry-js";

export const onCheckMarkersSameLatLng = (markers: MarkerExtended[]) => {
  return markers.every(
    (val) =>
      val.getPosition()?.lat() === markers[0].getPosition()?.lat() &&
      val.getPosition()?.lng() === markers[0].getPosition()?.lng()
  );
};

export const onCheckMarkerBelongCluster = (
  markers: MarkerExtended[],
  highlightId: number
) => {
  if (!highlightId) {
    return false;
  }

  return markers.some((val) => val.getTitle() === String(highlightId));
};

export const markerConvertAround = (markers: MarkerExtended[]) => {
  return markers.map((val) => {
    return {
      id: Number(val.getTitle()),
      lat: val.getPosition()?.lat() || 0,
      lng: val.getPosition()?.lng() || 0,
    };
  });
};

export const onClickMarkerProperty = ({
  lat,
  lng,
  id,
  name,
  thumbnail,
  address,
  type,
  open_time,
  is_favorite,
  full_day_price,
  is_open,
}: BookPropertyType) => {
  const dispatch = store.dispatch;

  handleClickPin({ lat, lng, id });

  dispatch(
    infoWindowDataAction({
      type: DESK_TYPE,
      lat,
      lng,
      id,
      name,
      address,
      office: type,
      openTime: open_time,
      thumbnailUrl: thumbnail,
      unit: "day",
      isFavorite: is_favorite,
      price: full_day_price,
      isClosed: !is_open,
    })
  );
};

export const onClickMarkerMeetingRoom = ({
  lat,
  lng,
  meeting_room_id,
  name,
  company_name,
  is_favorite,
  rate_per_hour,
  capacity,
  address,
  thumbnail,
  currency,
}: BookMeetingRoomType) => {
  const dispatch = store.dispatch;
  handleClickPin({ lat, lng, id: meeting_room_id });
  dispatch(
    infoWindowDataAction({
      type: MEETING_ROOM_TYPE,
      lat,
      lng,
      id: meeting_room_id,
      name,
      address,
      office: company_name,
      capacity,
      thumbnailUrl: thumbnail,
      unit: "HR",
      isFavorite: is_favorite,
      price: `${currency} ${rate_per_hour}`,
    })
  );
};

export const getDistanceMapZoom = (zoomLevel: number) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return zoomLevel > 22 ? DISTANCE_MAP_ZOOM[22] : DISTANCE_MAP_ZOOM[zoomLevel];
};

export const getDistanceCloseMapZoom = (zoomLevel: number) => {
  return zoomLevel > 22
    ? DISTANCE_CLOSE_MAP_ZOOM[22]
    : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      DISTANCE_CLOSE_MAP_ZOOM[zoomLevel];
};

export const getCircleRadiusZoom = (zoomLevel: number) => {
  return zoomLevel > 22
    ? CIRCLE_RADIUS_ZOOM[22]
    : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      CIRCLE_RADIUS_ZOOM[zoomLevel];
};

export const checkPositionInsideCircle = (
  from: LatLngLike,
  to: LatLngLike,
  radius: number
) => {
  return computeDistanceBetween(from, to) <= radius;
};

export const getHeading = (from: LatLngLiteral, to: LatLngLiteral) => {
  const lat1 = (from.lat * Math.PI) / 180;
  const lat2 = (to.lat * Math.PI) / 180;
  const dLon = ((to.lng - from.lng) * Math.PI) / 180;

  const y = Math.sin(dLon) * Math.cos(lat2);
  const x =
    Math.cos(lat1) * Math.sin(lat2) -
    Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);

  const brng = Math.atan2(y, x);

  return ((brng * 180) / Math.PI + 360) % 360;
};
