import React, { useContext, useEffect, useState } from "react";
import SizeBox from "../../../../../components/SizeBox";
import ButtonCustom from "../../../../../components/Button";
import moment from "moment-timezone";
import {
  dayOptions,
  DURATION_ARR,
} from "../../../../App/configs/DateTimeConfigs";
import DropdownCustom from "../../../../../components/DropdownCustom";
import {
  calculateBookingTime,
  getLocalDateFromAnotherTimezoneMoment,
  getLocalTimeAndDurationFromAnotherTimezone,
  makeBookingRoomService,
} from "../../../services/Common";
import {
  checkAvailableRoomService,
  getPropertyAvailableHourByDateService,
} from "../../../services/BookServices";
import { MeetRoomDetailProps } from "../../../configs/DeclareType";
import { useDispatch, useSelector } from "react-redux";
import {
  meetingRoomFiltersSelector,
  roomDetailSelector,
} from "../../../selectors/BookSelectors";
import { userCompanyChooseSelector } from "../../../../User/selectors/UserSelectors";
import { toastError } from "../../../../../components/Toast";
import { LocalizationContext } from "../../../../../locales/Translation";
import CheckboxCustom from "../../../../../components/Checkbox";
import { getUrlParameter } from "../../../../../utils/commons";
import DatePickerSingleV2 from "../../../../../components/DatePickerSingleV2";
import { getCreditRemainingThunk } from "../../../../App/thunks/AppThunk";
import ModalAddVoucherForBooking from "../../../../App/views/ModalAddVoucherForBooking";
import { modalAddVoucherForBookingAction } from "../../../../App/actions/AppActions";
import BoxAddVoucherForBooking from "../../../../App/views/BoxAddVoucherForBooking";
import Divide from "../../../../../components/Divide";
import Label from "../../../../../components/Label";

const BookingBox = () => {
  const { translations } = useContext(LocalizationContext);
  const roomDetail: MeetRoomDetailProps = useSelector(roomDetailSelector);
  const userCompanyChoose = useSelector(userCompanyChooseSelector);
  const roomFilters = useSelector(meetingRoomFiltersSelector);
  const defaultDate = getUrlParameter("default-date");
  const defaultStartTime = getUrlParameter("default-start-time");
  const defaultDuration = getUrlParameter("default-duration");
  const dispatch = useDispatch();
  let options = DURATION_ARR(1, 24);

  if (
    roomDetail?.minimum_booking_time &&
    roomDetail?.minimum_booking_time > 0
  ) {
    const minimumBookingTime = Math.floor(roomDetail.minimum_booking_time / 30);
    options = DURATION_ARR(minimumBookingTime, 24);
  }

  const defaultTime = getLocalTimeAndDurationFromAnotherTimezone(
    roomFilters?.start_time,
    roomFilters?.end_time,
    roomDetail.timezone
  );

  const [dateChoose, setDateChoose] = useState<any>(
    defaultDate
      ? moment(Number(defaultDate) * 1000)
      : getLocalDateFromAnotherTimezoneMoment(
          roomFilters?.start_time,
          roomDetail.timezone
        )
  );
  const [startTime, setStartTime] = useState<any>(
    defaultStartTime ? defaultStartTime : defaultTime.startTime
  );
  const [startTimeOptions, setStartTimeOptions] = useState<any>([]);
  const [duration, setDuration] = useState<any>(
    defaultDuration
      ? roomDetail?.minimum_booking_time &&
        roomDetail?.minimum_booking_time > Number(defaultDuration)
        ? roomDetail.minimum_booking_time
        : Number(defaultDuration)
      : roomDetail?.minimum_booking_time &&
        roomDetail?.minimum_booking_time > defaultTime.duration
      ? roomDetail.minimum_booking_time
      : defaultTime.duration
  );

  const [loading, setLoading] = useState<boolean>(false);

  const [bookingReview, setBookingReview] = useState<
    | null
    | string
    | {
        currency: string;
        total_final_price: string;
        rate_per_hour: string;
        vouchers?: any;
        discount?: any;
        price_before_discount?: any;
      }
  >(null);

  const [isBuffer, setIsBuffer] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);

  useEffect(() => {
    _handleStartTimeOptions();
  }, [dateChoose]);

  useEffect(() => {
    (async () => {
      await _checkAvailableTime();
    })();
  }, [dateChoose, startTime, duration, isBuffer, isRefresh]);

  const _handleStartTimeOptions = async () => {
    const payload = {
      property_id: roomDetail?.property_id,
      date: moment(dateChoose).tz(roomDetail.timezone).startOf("date").unix(),
    };

    const { data } = await getPropertyAvailableHourByDateService(payload);

    let options: any[] = [];

    if (data === "Open 24 hours") {
      options = dayOptions();
    } else if (data === "Closed") {
      options = [];
    } else {
      const minTime = moment(data?.from, "hh:mm A").format("HH:mm");
      const maxTime = moment(data?.to, "hh:mm A").format("HH:mm");
      options = dayOptions({ minTime, maxTime });
      if (defaultStartTime) {
        setStartTime(defaultStartTime);
      } else {
        setStartTime(options[0].value);
      }
    }

    setStartTimeOptions(options);
  };

  const _checkAvailableTime = async () => {
    setLoading(true);
    try {
      const payload = {
        start_time: calculateBookingTime(
          dateChoose,
          startTime,
          undefined,
          roomDetail?.timezone,
          isBuffer
        ),
        end_time: calculateBookingTime(
          dateChoose,
          startTime,
          duration,
          roomDetail?.timezone,
          isBuffer
        ),
        meeting_room_id: roomDetail.meeting_room_id,
      };
      const response = await checkAvailableRoomService(payload);
      setBookingReview(response?.data);
    } catch (error: any) {
      switch (error?.status?.code) {
        case 1005:
          setBookingReview(translations.errorMessage.meetingBooked);
          break;
        case 2000:
          setBookingReview(translations.errorMessage.paymentNotAccept);
          break;
        case 5000:
          setBookingReview(translations.errorMessage.businessCreditCardMiss);
          break;
        case 5001:
          setBookingReview(translations.errorMessage.businessCreditCardInvalid);
          break;
        default:
          setBookingReview(translations.errorMessage.meetingRoomUnavailable);
      }
    } finally {
      setLoading(false);
    }
  };

  const _makeBooking = async () => {
    try {
      setLoading(true);
      await makeBookingRoomService({
        duration,
        startTime,
        dateChoose,
        roomId: roomDetail.meeting_room_id,
        timezone: roomDetail.timezone,
        paymentCompanyId: userCompanyChoose?.company_id,
        isBuffer,
      });
      dispatch<any>(getCreditRemainingThunk());
    } catch (error: any) {
      switch (error?.status?.code) {
        case 1002:
        case 1012:
        case 1014:
          toastError(error?.status?.message);
          break;
        case 1005:
          toastError(translations.errorMessage.meetingBooked);
          break;
        case 2000:
          toastError(translations.errorMessage.paymentNotAccept);
          break;
        case 5000:
          toastError(translations.errorMessage.businessCreditCardMiss);
          break;
        case 5001:
          toastError(translations.errorMessage.businessCreditCardInvalid);
          break;
        default:
          toastError(translations.errorMessage.meetingRoomUnavailable);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={"book-detail__booking-wrapper"}>
        <div className={"book-detail__booking"}>
          <div className={"book-detail__booking-summary"}>
            <h3 className={"title"}>Booking Summary</h3>
            <>
              <div className={"label"}>Date</div>
              <DatePickerSingleV2
                format={"DD/MM/YYYY"}
                allowClear={false}
                value={dateChoose}
                disabledDate={(date: any) => {
                  return moment(date).isBefore(moment().subtract({ d: 1 }));
                }}
                showToday={false}
                className={"w-100 border-radius-right-0"}
                onChange={(date: any) => {
                  setDateChoose(date);
                }}
              />
            </>
            <SizeBox height={"20px"} />
            <div className={"row-booking-room"}>
              <div className={"item"}>
                <div className={"label"}>Start time</div>
                <DropdownCustom
                  scrolling
                  selectUI
                  value={startTime}
                  options={startTimeOptions}
                  onChange={(e, { value }) => {
                    setStartTime(value);
                  }}
                  pointing={"top"}
                />
              </div>
              <div className={"item"}>
                <Label
                  fontSize={"14px"}
                  label={"Duration"}
                  labelGuide={
                    roomDetail?.minimum_booking_time
                      ? `The minimum booking duration for this meeting room is  ${
                          options.find((item) => item.value == duration)
                            ?.text ||
                          roomDetail?.minimum_booking_time + " minutes"
                        }`
                      : null
                  }
                />
                <DropdownCustom
                  scrolling
                  selectUI
                  options={options}
                  value={duration}
                  onChange={(e, { value }) => {
                    setDuration(value);
                  }}
                  pointing={"top"}
                />
              </div>
            </div>
            <div className={"buffer-booking"}>
              <CheckboxCustom
                className={"w-100 custom-checkbox"}
                onChange={(e: any, data: any) => {
                  setIsBuffer(data?.checked);
                }}
                checked={isBuffer}
                label={"Add 15-minute buffer time before and after the meeting"}
              />
            </div>
            <SizeBox height={"20px"} />
            <BoxAddVoucherForBooking
              isRefresh={isRefresh}
              setIsRefresh={setIsRefresh}
            />
          </div>
          <div className={"book-detail__booking-bottom"}>
            {bookingReview === null ? null : typeof bookingReview ===
              "string" ? (
              <div className="row">
                <div className={"label"}>{bookingReview}</div>
              </div>
            ) : (
              <>
                {bookingReview?.discount && bookingReview?.discount > 0 ? (
                  <>
                    <div className="row">
                      <div className={"label-small"}>Hourly Rate</div>
                      <div className={"label-small"}>
                        {bookingReview?.currency} {bookingReview?.rate_per_hour}
                      </div>
                    </div>
                    <SizeBox height={"16px"} />
                    <>
                      <div className="row">
                        <div className={"label-small"}>Subtotal</div>
                        <div className={"label-small text-right"}>
                          {bookingReview?.currency}{" "}
                          {bookingReview?.price_before_discount}
                        </div>
                      </div>
                      <SizeBox height={"16px"} />
                    </>

                    <>
                      <div className="row">
                        <div className={"label-small"}>Discount</div>
                        <div className={"label-small text-right"}>
                          - {bookingReview?.currency}{" "}
                          {bookingReview?.discount < 1
                            ? "0"
                            : bookingReview?.discount}
                        </div>
                      </div>
                    </>

                    <Divide
                      height={"1px"}
                      type={"horizontal"}
                      margin={"16px 0"}
                    />
                  </>
                ) : null}

                <div className="row">
                  <div className={"label"}>Total Amount Paid</div>
                  <div className={"label"}>
                    {bookingReview?.currency}{" "}
                    {Number(bookingReview?.total_final_price) < 1
                      ? "0"
                      : bookingReview?.total_final_price}
                  </div>
                </div>
              </>
            )}
            {typeof bookingReview === "string" ? null : (
              <>
                <SizeBox height={"16px"} />
                <ButtonCustom
                  width={"100%"}
                  height={"50px"}
                  className={"primary book-confirm-cta"}
                  onClick={_makeBooking}
                  loading={loading}
                  disabled={loading}
                >
                  <b>Book now</b>
                </ButtonCustom>
              </>
            )}
          </div>
        </div>
      </div>
      {/*<ModalAddVoucherForBooking*/}
      {/*  isRefresh={isRefresh}*/}
      {/*  setIsRefresh={setIsRefresh}*/}
      {/*/>*/}
    </>
  );
};

export default BookingBox;
