export const deskimoPropertyListSelector = (state: any) =>
  state.PropertyReducer.deskimoPropertyList;

export const deskimoPropertyListFilterSelector = (state: any) =>
  state.PropertyReducer.deskimoPropertyListFilter;

export const deskimoPropertyCompanyListSelector = (state: any) =>
  state.PropertyReducer.deskimoPropertyCompanyList;

export const deskimoPropertyCompanyListFilterSelector = (state: any) =>
  state.PropertyReducer.deskimoPropertyCompanyListFilter;

export const deskimoPropertyStaffListSelector = (state: any) =>
  state.PropertyReducer.deskimoPropertyStaffList;

export const deskimoPropertyStaffListFilterSelector = (state: any) =>
  state.PropertyReducer.deskimoPropertyStaffListFilter;

export const deskimoPropertyStaffDetailSelector = (state: any) =>
  state.EmployeeReducer.Detail;

export const modalDeskimoWorkspaceStaffListSelector = (state: any) =>
  state.PropertyReducer.modalDeskimoWorkspaceStaffList;

export const propertyListSelector = (state: any) =>
  state.PropertyReducer.propertyList;

export const meetingRoomListSelector = (state: any) =>
  state.PropertyReducer.meetingRoomList;

export const propertyDetailSelector = (state: any) =>
  state.PropertyReducer.propertyDetail;

export const meetingRoomDetailSelector = (state: any) =>
  state.PropertyReducer.meetingRoomDetail;

export const managePMSRoomListSelector = (state: any) =>
  state.PropertyReducer.managePMSRoomList;

export const managePMSAddCalendarSelector = (state: any) =>
  state.PropertyReducer.managePMSAddCalendarInput;

export const isModalManagePMSSelector = (state: any) =>
  state.PropertyReducer.isModalManagePMS;

export const isModalManagePMSAddRoomSelector = (state: any) =>
  state.PropertyReducer.isModalManagePMSAddRoom;

export const isModalManagePMSAddCalendarSelector = (state: any) =>
  state.PropertyReducer.isModalManagePMSAddCalendar;

export const isModalMeetingRoomUpdateSelector = (state: any) =>
  state.PropertyReducer.isModalMeetingRoomUpdate;

export const isModalMeetingRoomCreateSelector = (state: any) =>
  state.PropertyReducer.isModalMeetingRoomCreate;

export const isModalMeetingRoomAssignSelector = (state: any) =>
  state.PropertyReducer.isModalMeetingRoomAssign;

export const isModalAddStaffSelector = (state: any) =>
  state.PropertyReducer.isModalAddStaff;

export const userReviewSelector = (state: any) =>
  state.PropertyReducer.userReview;

export const deskimoPropertyAmenityListSelector = (state: any) =>
  state.PropertyReducer.deskimoPropertyAmenityList;

export const isModalAddEditAmenitySelector = (state: any) =>
  state.PropertyReducer.isModalAddEditAmenity;

export const deskimoPropertyAmenityDetailSelector = (state: any) =>
  state.PropertyReducer.deskimoPropertyAmenityDetail;

export const deskimoPropertyScannerListSelector = (state: any) =>
  state.PropertyReducer.deskimoPropertyScannerList;

export const deskimoPropertyScannerFilterSelector = (state: any) =>
  state.PropertyReducer.deskimoPropertyScannerFilter;

export const modalDeskimoPropertyScannerQrCodeSelector = (state: any) =>
  state.PropertyReducer.isModalPropertyScannerQrCode;

export const deskimoPropertyScannerQrCodeSelector = (state: any) =>
  state.PropertyReducer.propertyScannerQrCode;

export const deskimoPropertyScannerDetailSelector = (state: any) =>
  state.PropertyReducer.deskimoPropertyScannerDetail;

export const propertyCategoriesSelector = (state: any) =>
  state.PropertyReducer.propertyCategories;

export const propertyIntegrationsQRCodeListSelector = (state: any) =>
  state.PropertyReducer.propertyIntegrationsQRCodeList;

export const propertyIntegrationsAPIListSelector = (state: any) =>
  state.PropertyReducer.propertyIntegrationsAPIList;

export const propertyIntegrationsWebhookListSelector = (state: any) =>
  state.PropertyReducer.propertyIntegrationsWebhookList;

export const deskimoPrivateOfficeModalSelector = (state: any) =>
  state.PropertyReducer.deskimoPrivateOfficeModal;

export const modalCreateStaffSelector = (state: any) =>
  state.PropertyReducer.modalCreateStaff;

export const partnerLogSelector = (state: any) =>
  state.PropertyReducer.partnerLog;

export const deskimoUserReviewsSelector = (state: any) =>
  state.PropertyReducer.deskimoUserReviews;

export const deskimoPrivateOfficeListSelector = (state: any) =>
  state.PropertyReducer.deskimoPrivateOfficeList;

export const deskimoPrivateOfficeFiltersSelector = (state: any) =>
  state.PropertyReducer.deskimoPrivateOfficeFilters;

export const deskimoPrivateOfficeExportSelectedSelector = (state: any) =>
  state.PropertyReducer.deskimoPrivateOfficeExportSelected;

export const deskimoPrivateOfficeFiltersTmpSelector = (state: any) =>
  state.PropertyReducer.deskimoPrivateOfficeFiltersTmp;

export const modalRewardSelector = (state: any) =>
  state.PropertyReducer.modalReward;
export const rewardListSelector = (state: any) =>
  state.PropertyReducer.rewardList;
