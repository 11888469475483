import React from "react";
import BookingTable from "../BookingTable";

const UpcomingTab = ({ loading, setLoading }: any) => {
  const columns = [
    "Room Name",
    "Property Details",
    "Duration",
    "Booking Date",
    "Total Fee",
    "Status",
    "Action",
  ];

  return (
    <BookingTable columns={columns} loading={loading} setLoading={setLoading} />
  );
};

export default UpcomingTab;
