import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { LocalizationContext } from "../../../../locales/Translation";
import ButtonCustom from "../../../../components/Button";
import { useNavigate } from "react-router-dom";
import { CONFIG_MARKET_CREATE_LINK } from "../../../App/configs/ListRoutes";
import "./styles.scss";
import MarketTable from "./Components/MarketTable";
import {
  configMarketCommissionShareThunk,
  configMarketExchangeRateListThunk,
  configMarketListThunk,
} from "../../thunks/ConfigThunks";
import Search from "./Components/Search";
import MarketExchangeRateTable from "./Components/MarketExchangeRateTable";
import SizeBox from "../../../../components/SizeBox";
import MarketCommissionShareRateInput from "./Components/MarketCommissionShareRateInput";

const FILTER_DEFAULT = {
  keyword: "",
  page: 1,
};

const FILTER_MARKET_EXCHANGE_RATE_DEFAULT = {
  page: 1,
};

const MarketList = () => {
  const { translations } = useContext(LocalizationContext);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(FILTER_DEFAULT);
  const [pageFilter, setPageFilter] = useState(
    FILTER_MARKET_EXCHANGE_RATE_DEFAULT
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      setFilter(FILTER_DEFAULT);
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await dispatch<any>(configMarketListThunk(filter));
        await dispatch<any>(configMarketCommissionShareThunk(null));
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [filter]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await dispatch<any>(configMarketExchangeRateListThunk(pageFilter));
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [pageFilter]);

  const _onSearch = (value: string) => {
    setFilter((prevState) => {
      return { ...prevState, keyword: value, page: 1 };
    });
  };

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    setFilter((prevState) => {
      return { ...prevState, page: activePage };
    });
  };

  const _onChangeMarketExchangeRatePagination = (
    e: SyntheticEvent,
    { activePage }: any
  ) => {
    setPageFilter({ page: activePage });
  };

  return (
    <>
      <div className={"filter-section filter-market-list"}>
        <div className={"filter-left"}>
          <Search onSearch={_onSearch} />
        </div>
        <ButtonCustom
          onClick={() => navigate(CONFIG_MARKET_CREATE_LINK)}
          height={"36px"}
          fontSize={"12px"}
          className={"primary text-nowrap mr-0"}
        >
          <>Add Market +</>
        </ButtonCustom>
      </div>

      <MarketTable loading={loading} onChangePagination={_onChangePagination} />
      <SizeBox height={"81.25px"} />
      <label>Market exchange rate (updated automatically)</label>
      <SizeBox height={"21px"} />
      <MarketExchangeRateTable
        loading={loading}
        onChangePagination={_onChangeMarketExchangeRatePagination}
      />
      <SizeBox height={"33px"} />
      <label>Market commission share confirmation</label>
      <SizeBox height={"21px"} />
      <MarketCommissionShareRateInput />
    </>
  );
};

export default MarketList;
