import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import {
  dashboardStatisticPropertyService,
  dashboardStatisticService,
  deskBookingPropertyService,
  deskBookingService,
  deskUsagePropertyService,
  deskUsageService,
  getActivityTrackingService,
  getFreeCreditService,
  getPropertyByCompanyService,
  getPropertyPendingBookingsService,
  listPropertyAutocompleteService,
  roomBookingPropertyService,
  roomBookingService,
  transactionInfoService,
} from "../services/DashboardServices";
import {
  dashboardActivityLogAction,
  dashboardActivityLogLoadMoreAction,
  dashboardDeskBookingAction,
  dashboardDeskUsageAction,
  dashboardPropertyListAction,
  dashboardPropertyPendingBookingsAction,
  dashboardRoomBookingAction,
  dashboardStatisticAction,
  dashboardStatisticBookingPropertyAction,
  dashboardStatisticDeskimoAction,
  dashboardStatisticOfficePropertyAction,
  dashboardStatisticPropertyAction,
  filterListPropertyAction,
  filterListPropertySelectedAction,
  getFreeCreditAction,
  transactionInfoAction,
} from "../actions/DashboardActions";
import { store } from "../../App/configs/Store";
import { BUSINESS, PROPERTY } from "../configs/Constants";
import { CURRENT_COMPANY_ID } from "../../../configs/Constants";

export const dashboardStatisticThunk = (productType?: number) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const filterDateRange = store.getState().DashboardReducer.filterDateRange;

    if (!filterDateRange) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const payload: any = { ...filterDateRange };

    if (productType) {
      payload.product = productType;
    }

    const { data } = await dashboardStatisticService(payload);
    dispatch<any>(dashboardStatisticAction(data));
  };
};

export const dashboardStatisticDeskimoThunk = () => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const filterDateRange = store.getState().DashboardReducer.filterDateRange;
    const filterListPropertySelected =
      store.getState().DashboardReducer.filterListPropertySelected;
    if (!filterDateRange) {
      return;
    }

    const payload = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...filterDateRange,
      market: store.getState().DashboardReducer.dashboardStatisticFilter.market,
      properties: filterListPropertySelected || [],
    };
    const { data } = await dashboardStatisticService(payload, true);
    dispatch<any>(dashboardStatisticDeskimoAction(data));
  };
};

export const dashboardStatisticPropertyThunk = (
  type: "desk" | "meeting-room" | "office"
) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const filterDateRange = store.getState().DashboardReducer.filterDateRange;
    const userCompanyChoose: any =
      store.getState().UserReducer.userCompanyChoose;
    const filterListPropertySelected =
      store.getState().DashboardReducer.filterListPropertySelected;

    if (!userCompanyChoose || !filterDateRange) {
      return;
    }

    const payload = {
      company_id: userCompanyChoose?.company_id,
      property_id: 0,
      type,
      properties: filterListPropertySelected || [],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...filterDateRange,
    };
    const { data } = await dashboardStatisticPropertyService(payload);

    switch (type) {
      case "desk":
        dispatch<any>(dashboardStatisticPropertyAction(data));
        break;
      case "meeting-room":
        dispatch<any>(dashboardStatisticBookingPropertyAction(data));
        break;
      case "office":
        dispatch<any>(dashboardStatisticOfficePropertyAction(data));
        break;
      default:
        break;
    }
  };
};

export const dashboardDeskUsageThunk = (
  type: typeof BUSINESS | typeof PROPERTY,
  propertyContext = false,
  deskimoAdmin?: boolean,
  productType?: number
) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const dateRange = store.getState().DashboardReducer.filterDateRange;
    const filterPropertyList =
      store.getState().DashboardReducer.filterListPropertySelected;
    if (!dateRange) {
      return;
    }

    const dashboardDeskUsageFilter = deskimoAdmin
      ? store.getState().DashboardReducer.dashboardDeskimoDeskUsageFilter
      : store.getState().DashboardReducer.dashboardDeskUsageFilter;

    const market =
      store.getState().DashboardReducer.dashboardStatisticFilter.market;
    const payload = {
      properties: filterPropertyList || [],
      ...dashboardDeskUsageFilter,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...dateRange,
      market: market,
    };

    if (propertyContext) {
      const dashboardPropertyChoose: any =
        store.getState().DashboardReducer.dashboardPropertyChoose;
      payload.property_id = dashboardPropertyChoose?.property_id;
    }

    if (productType) {
      payload.product = productType;
    }

    let data;

    if (type === BUSINESS) {
      const res = await deskUsageService(payload);
      data = res.data;
    } else if (type === PROPERTY) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      payload.status = payload.filter;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete payload.filter;
      const res = await deskUsagePropertyService(payload, deskimoAdmin);
      data = res.data;
    }

    if (!data) {
      return null;
    }

    if (Array.isArray(data)) {
      dispatch<any>(dashboardDeskUsageAction(null));
      return null;
    }

    dispatch<any>(dashboardDeskUsageAction(data));
    return data;
  };
};

export const dashboardDeskBookingThunk = (
  type: typeof BUSINESS | typeof PROPERTY,
  propertyContext = false,
  deskimoAdmin?: boolean,
  productType?: number
) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const dashboardDeskBookingFilter = deskimoAdmin
      ? store.getState().DashboardReducer.dashboardDeskimoDeskBookingFilter
      : store.getState().DashboardReducer.dashboardDeskBookingFilter;
    const dateRange = store.getState().DashboardReducer.filterDateRange;
    const market =
      store.getState().DashboardReducer.dashboardStatisticFilter.market;
    const filterListPropertySelected =
      store.getState().DashboardReducer.filterListPropertySelected;

    const payload = {
      properties: filterListPropertySelected || [],
      ...dashboardDeskBookingFilter,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...dateRange,
      market: market,
    };

    if (propertyContext) {
      const dashboardPropertyChoose: any =
        store.getState().DashboardReducer.dashboardPropertyChoose;
      payload.property_id = dashboardPropertyChoose?.property_id;
    }

    if (productType) {
      payload.product = productType;
    }

    let data;
    if (type === BUSINESS) {
      const res = await deskBookingService(payload);
      data = res.data;
    } else if (type === PROPERTY) {
      const res = await deskBookingPropertyService(payload, deskimoAdmin);
      data = res.data;
    }
    if (Array.isArray(data)) {
      dispatch<any>(dashboardDeskBookingAction(null));
      return null;
    }

    dispatch<any>(dashboardDeskBookingAction(data));
    return data;
  };
};

export const dashboardRoomBookingThunk = (productType?: number) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const dashboardRoomBookingFilter =
      store.getState().DashboardReducer.dashboardRoomBookingFilter;
    const dateRange = store.getState().DashboardReducer.filterDateRange;
    const market =
      store.getState().DashboardReducer.dashboardStatisticFilter.market;
    const payload = {
      ...dashboardRoomBookingFilter,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...dateRange,
      market: market,
    };

    if (productType) {
      payload.product = productType;
    }

    const { data } = await roomBookingService(payload);

    if (Array.isArray(data)) {
      dispatch<any>(dashboardRoomBookingAction(null));
    } else {
      dispatch<any>(dashboardRoomBookingAction(data));
    }
  };
};

export const dashboardRoomBookingPropertyThunk = (
  propertyContext = false,
  deskimoAdmin?: boolean
) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const dashboardRoomBookingFilter = deskimoAdmin
      ? store.getState().DashboardReducer.dashboardDeskimoRoomBookingFilter
      : store.getState().DashboardReducer.dashboardRoomBookingFilter;
    const dateRange: null | object =
      store.getState().DashboardReducer.filterDateRange;
    const market =
      store.getState().DashboardReducer.dashboardStatisticFilter.market;
    const filterListPropertySelected =
      store.getState().DashboardReducer.filterListPropertySelected;

    let payload = {
      properties: filterListPropertySelected || [],
      ...dashboardRoomBookingFilter,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...dateRange,
      market: market,
    };

    if (propertyContext) {
      const dashboardPropertyChoose: any =
        store.getState().DashboardReducer.dashboardPropertyChoose;
      payload = {
        ...payload,
        ...{ property_id: dashboardPropertyChoose?.property_id },
      };
    }

    const { data } = await roomBookingPropertyService(payload, deskimoAdmin);

    if (Array.isArray(data)) {
      dispatch<any>(dashboardRoomBookingAction(null));
      return null;
    }

    dispatch<any>(dashboardRoomBookingAction(data));
    return data;
  };
};

export const dashboardPropertyListThunk = () => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const companyId: any = localStorage.getItem(CURRENT_COMPANY_ID);

    if (!companyId) {
      throw new Error();
    }
    const { data } = await getPropertyByCompanyService(companyId);

    dispatch<any>(dashboardPropertyListAction(data));
    return data;
  };
};

export const dashboardPropertyPendingBookingsThunk = () => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const userCompanyChoose: any =
      store.getState().UserReducer.userCompanyChoose;
    const dashboardPropertyChoose: any =
      store.getState().DashboardReducer.dashboardPropertyChoose;

    if (!userCompanyChoose || !dashboardPropertyChoose) {
      throw new Error();
    }
    const { data } = await getPropertyPendingBookingsService(
      userCompanyChoose.company_id,
      dashboardPropertyChoose.property_id
    );

    dispatch<any>(dashboardPropertyPendingBookingsAction(data));
  };
};

export const dashboardActivityLogThunk = (lastId?: number) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const filterDateRange: null | object =
      store.getState().DashboardReducer.filterDateRange;

    if (!filterDateRange) {
      return;
    }

    const payload = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...filterDateRange,
      last_id: 0,
    };
    if (lastId) {
      payload.last_id = lastId;
    }

    const { data } = await getActivityTrackingService(payload);

    if (lastId) {
      dispatch(dashboardActivityLogLoadMoreAction(data?.activities || []));
    } else {
      dispatch(dashboardActivityLogAction(data?.activities || []));
    }
  };
};

export const getFreeCreditThunk = (companyId: string) => {
  return async (dispatch: any) => {
    const { data } = await getFreeCreditService(companyId);
    dispatch(getFreeCreditAction(data));
  };
};

export const transactionInfoThunk = (payload: object) => {
  return async (dispatch: any) => {
    const { data } = await transactionInfoService(payload);
    dispatch(transactionInfoAction(data));
  };
};

export const getFilterListPropertyThunk = (keyword: any) => {
  return async (dispatch: any) => {
    const { data } = await listPropertyAutocompleteService(keyword);
    dispatch(filterListPropertyAction(data));
    dispatch(
      filterListPropertySelectedAction(data.map((property: any) => property.id))
    );
  };
};
