import { createReducer } from "@reduxjs/toolkit";

import {
  LIST_BOOKING_COMPLETED_ACTION,
  LIST_BOOKING_COMPLETED_PAYLOAD_ACTION,
  LIST_DESK_BOOKING_ACTION,
  LIST_DESK_BOOKING_PAYLOAD_ACTION,
  LIST_ROOM_BOOKING_ACTION,
  LIST_ROOM_BOOKING_PAYLOAD_ACTION,
  MODAL_ROOM_BOOKING_DETAIL_ACTION,
  ONGOING_DATA_ACTION,
  REFRESH_LIST_DESK_BOOKING_ACTION,
  REFRESH_LIST_ROOM_BOOKING_ACTION,
} from "../configs/Constants";
import produce from "immer";

const initialState = {
  listRoomBookingPayload: {
    status: 0,
    page: 1,
  },
  listRoomBooking: [],
  modalRoomBookingDetail: {
    open: false,
    booking_id: null,
    type: null,
  },
  refreshRoomBookingList: false,
  listDeskBookingPayload: {
    status: 0,
    page: 1,
  },
  listDeskBooking: [],
  modalDeskBookingDetail: {
    open: false,
    data: null,
  },
  refreshDeskBookingList: false,
  ongoingData: {},
  listBookingCompleted: {
    items: [],
    pagination: {},
  },
  listBookingCompletedPayload: {
    page: 1,
  },
};

const BookingReducer = createReducer(initialState, {
  [LIST_ROOM_BOOKING_ACTION]: (state, action) => {
    state.listRoomBooking = action.payload;
  },

  [REFRESH_LIST_ROOM_BOOKING_ACTION]: (state, action) => {
    state.refreshRoomBookingList = !state.refreshRoomBookingList;
  },

  [LIST_ROOM_BOOKING_PAYLOAD_ACTION]: (state, action: any) => {
    const payload = action?.payload;
    state.listRoomBookingPayload = produce(
      state.listRoomBookingPayload,
      (draft: any) => {
        return { ...draft, ...payload };
      }
    );
  },

  [MODAL_ROOM_BOOKING_DETAIL_ACTION]: (state, action: any) => {
    const payload = action?.payload;
    state.modalRoomBookingDetail = produce(
      state.modalRoomBookingDetail,
      (draft: any) => {
        return { ...draft, ...payload };
      }
    );
  },

  [LIST_DESK_BOOKING_ACTION]: (state, action) => {
    state.listDeskBooking = action.payload;
  },

  [REFRESH_LIST_DESK_BOOKING_ACTION]: (state, action) => {
    state.refreshDeskBookingList = !state.refreshDeskBookingList;
  },

  [LIST_DESK_BOOKING_PAYLOAD_ACTION]: (state, action: any) => {
    const payload = action?.payload;
    state.listDeskBookingPayload = produce(
      state.listDeskBookingPayload,
      (draft: any) => {
        return { ...draft, ...payload };
      }
    );
  },

  [ONGOING_DATA_ACTION]: (state, action) => {
    state.ongoingData = action.payload;
  },

  [LIST_BOOKING_COMPLETED_ACTION]: (state, action) => {
    state.listBookingCompleted = action.payload;
  },

  [LIST_BOOKING_COMPLETED_PAYLOAD_ACTION]: (state, action) => {
    state.listBookingCompletedPayload = action.payload;
  },
});

export default BookingReducer;
