import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import DropdownWithCheckboxMenu from "../../../../../components/DropdownWithCheckboxMenu";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import { useDispatch, useSelector } from "react-redux";
import { dashboardRoomBookingFilterAction } from "../../../actions/DashboardActions";
import {
  PROPERTY,
  ROOM_BOOKING_ALL_STATUS,
  ROOM_BOOKING_CANCELLED_STATUS,
  ROOM_BOOKING_COMPLETED_STATUS,
  ROOM_BOOKING_CONFIRMED_STATUS,
  ROOM_BOOKING_DECLINED_STATUS,
  ROOM_BOOKING_NOT_REFUNDED_STATUS,
  ROOM_BOOKING_PENDING_REFUNDED_STATUS,
  ROOM_BOOKING_PENDING_STATUS,
  ROOM_BOOKING_REFUND_FILTER,
  ROOM_BOOKING_REFUNDED_STATUS,
} from "../../../configs/Constants";
import {
  dashboardFilterDateRangeSelector,
  dashboardPropertyChooseSelector,
  dashboardRoomBookingFilterSelector,
} from "../../../selectors/DashboardSelectors";
import { LocalizationContext } from "../../../../../locales/Translation";
import { createRoomBookingStatusFilterService } from "../../../services/DashboardServices";
import { usePermissionCheck } from "../../../../../utils/custom-hooks";
import { UI_ACCESS_TRANSACTION_BY_FILTER_PER } from "../../../../../utils/PermissionConstant";

const FilterRoomBooking = () => {
  const { translations } = useContext(LocalizationContext);

  const dispatch = useDispatch();
  const dashboardRoomBookingFilter = useSelector(
    dashboardRoomBookingFilterSelector
  );
  const dashboardFilterDateRange = useSelector(
    dashboardFilterDateRangeSelector
  );

  const [roomBookingStatus, setRoomBookingStatus] = useState<
    { id: string; name: string }[]
  >([]);

  const dashboardPropertyChoose = useSelector(dashboardPropertyChooseSelector);

  const propertyContext = usePermissionCheck(
    UI_ACCESS_TRANSACTION_BY_FILTER_PER
  );

  if (propertyContext) {
    useEffect(() => {
      (async () => {
        try {
          if (
            dashboardFilterDateRange === null ||
            dashboardPropertyChoose === null
          ) {
            return;
          }
          await createRoomBookingStatusFilterService({
            translations,
            setStatus: setRoomBookingStatus,
            type: PROPERTY,
            propertyContext,
          });
        } catch (e) {
          console.log("e", e);
        }
      })();
    }, [
      dashboardRoomBookingFilter?.keyword,
      dashboardFilterDateRange,
      dashboardPropertyChoose,
    ]);
  } else {
    useEffect(() => {
      (async () => {
        try {
          if (dashboardFilterDateRange === null) {
            return;
          }
          await createRoomBookingStatusFilterService({
            translations,
            setStatus: setRoomBookingStatus,
            type: PROPERTY,
            propertyContext,
          });
        } catch (e) {
          console.log("e", e);
        }
      })();
    }, [dashboardRoomBookingFilter?.keyword, dashboardFilterDateRange]);
  }

  const _onChangeFilter = (e: SyntheticEvent, data: any) => {
    if (data.value === ROOM_BOOKING_ALL_STATUS) {
      if (data.checked) {
        dispatch(
          dashboardRoomBookingFilterAction({
            key: "filter_status",
            value: [
              ROOM_BOOKING_ALL_STATUS,
              ROOM_BOOKING_CONFIRMED_STATUS,
              ROOM_BOOKING_PENDING_STATUS,
              ROOM_BOOKING_DECLINED_STATUS,
              ROOM_BOOKING_CANCELLED_STATUS,
              ROOM_BOOKING_COMPLETED_STATUS,
            ],
          })
        );
        dispatch(
          dashboardRoomBookingFilterAction({
            key: "filter_refund_status",
            value: [
              ROOM_BOOKING_PENDING_REFUNDED_STATUS,
              ROOM_BOOKING_REFUNDED_STATUS,
              ROOM_BOOKING_NOT_REFUNDED_STATUS,
            ],
          })
        );
      } else {
        dispatch(
          dashboardRoomBookingFilterAction({
            key: "filter_status",
            value: [],
          })
        );
        dispatch(
          dashboardRoomBookingFilterAction({
            key: "filter_refund_status",
            value: [],
          })
        );
      }
      return;
    }

    if (data.checked) {
      if (ROOM_BOOKING_REFUND_FILTER.includes(data.value)) {
        const newValue = [
          ...dashboardRoomBookingFilter.filter_refund_status,
          data.value,
        ];
        dispatch(
          dashboardRoomBookingFilterAction({
            key: "filter_refund_status",
            value: newValue,
          })
        );
      } else {
        const newValue = [
          ...dashboardRoomBookingFilter.filter_status,
          data.value,
        ];
        dispatch(
          dashboardRoomBookingFilterAction({
            key: "filter_status",
            value: newValue,
          })
        );
      }
      return;
    }

    if (ROOM_BOOKING_REFUND_FILTER.includes(data.value)) {
      const newValue = dashboardRoomBookingFilter.filter_refund_status.filter(
        (status: string) =>
          status !== data.value && status !== ROOM_BOOKING_ALL_STATUS
      );
      dispatch(
        dashboardRoomBookingFilterAction({
          key: "filter_refund_status",
          value: newValue,
        })
      );
    } else {
      const newValue = dashboardRoomBookingFilter.filter_status.filter(
        (status: string) =>
          status !== data.value && status !== ROOM_BOOKING_ALL_STATUS
      );
      dispatch(
        dashboardRoomBookingFilterAction({
          key: "filter_status",
          value: newValue,
        })
      );
    }
  };

  return (
    <div className={"dropdown-filter"}>
      <DropdownWithCheckboxMenu
        onChange={_onChangeFilter}
        listCheckBox={roomBookingStatus.map((filter: any) => {
          if (ROOM_BOOKING_REFUND_FILTER.includes(filter.id)) {
            if (
              dashboardRoomBookingFilter.filter_refund_status.includes(
                filter.id
              )
            ) {
              filter.checked = true;
            } else {
              filter.checked = false;
            }

            return filter;
          }

          if (dashboardRoomBookingFilter.filter_status.includes(filter.id)) {
            filter.checked = true;
          } else {
            filter.checked = false;
          }

          return filter;
        })}
        label={translations.filters}
        icon={
          <img
            alt={"Deskimo"}
            src={PUBLIC_URL + "/assets/icons/icon-filter.svg"}
          />
        }
      />
    </div>
  );
};

export default FilterRoomBooking;
