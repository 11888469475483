import React from "react";
import PropertyList from "./PropertyList";
import "../../../styles/dashboard_property.scss";
import ManualCheckIn from "./ManualCheckIn";
import PendingBooking from "./PendingBooking";
import PropertyStatus from "./PropertyStatus";
import ModalDeclineReason from "./Modal/ModalDeclineReason";

const Property = () => {
  return (
    <div className={"dashboard_property_box"}>
      <PropertyList />
      <ManualCheckIn />
      <PendingBooking />
      <PropertyStatus />
      <ModalDeclineReason />
    </div>
  );
};

export default Property;
