import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { PUBLIC_URL } from "../../configs/Constants";
import { BILLING_LINK } from "../../configs/ListRoutes";
import { LocalizationContext } from "../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import { modalAddCardAction } from "../../../Setting/actions/BiilingActions";
import { ROLE_BUSINESS_ADMIN_ID } from "../../../User/configs/Constants";
import { UserCompanyType } from "../../../User/configs/DeclareTypes";
import { userCompanyChooseSelector } from "../../../User/selectors/UserSelectors";

const NoCreditCard = () => {
  const { translations } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userCompanyChoose: UserCompanyType = useSelector(
    userCompanyChooseSelector
  );

  if (
    userCompanyChoose?.role_id !== ROLE_BUSINESS_ADMIN_ID ||
    userCompanyChoose?.has_credit_card ||
    userCompanyChoose?.is_partner
  ) {
    return null;
  }

  return (
    <div className={"notification-global"}>
      <div className={"container"}>
        <span>{translations.addCreditCardToBusinessAccount}</span>
        <button
          onClick={() => {
            navigate(BILLING_LINK);
            dispatch(modalAddCardAction(true));
          }}
          className={"add-card-btn"}
        >
          <img
            alt={"Deskimo"}
            src={PUBLIC_URL + "/assets/icons/icon-credit-card-line.svg"}
            className="mr-8px"
          />
          {translations.addYourCardNow}
        </button>
      </div>
    </div>
  );
};

export default NoCreditCard;
