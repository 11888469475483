import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { ALLOW_TO_FIRST_VISIT_ONLY_PROMOTION_RULE } from "../../../../configs/Constants";
import CheckboxCustom from "../../../../../../components/Checkbox";
import { FormikValues } from "formik";
import { FormikSetFieldValueType } from "../../../../configs/DeclareTypes";
import { CampaignCreateEditContext } from "../../../../reducers/CampaignCreateEditContext";

const RuleFirstVisit = () => {
  const { translations } = useContext(LocalizationContext);
  const { setFieldValue, values, isSubmitting } = useContext(
    CampaignCreateEditContext
  );

  const index = values.advance_rules.findIndex(
    ({ type }: { type: string }) =>
      type === ALLOW_TO_FIRST_VISIT_ONLY_PROMOTION_RULE
  );

  return (
    <div className={"campaign-create-rule"}>
      <div className={"left"}>
        <div className={"text"}>
          {translations.voucherRules[ALLOW_TO_FIRST_VISIT_ONLY_PROMOTION_RULE]}
        </div>
        <CheckboxCustom
          checked={values.advance_rules[index].value}
          disabled={isSubmitting}
          onChange={() => {
            setFieldValue(
              `advance_rules[${index}].value`,
              !values.advance_rules[index].value
            );
          }}
        />
      </div>
      <div className={"right"} />
    </div>
  );
};

export default RuleFirstVisit;
