import React, { useContext } from "react";
import SectionWhiteBoxItem from "../../../../../../components/SectionWhiteBoxItem/SectionWhiteBoxItem";
import { CampaignDetailType } from "../../../../configs/DeclareTypes";
import { useSelector } from "react-redux";
import { campaignDetailSelector } from "../../../../selectors/MarketingSelectors";
import { LocalizationContext } from "../../../../../../locales/Translation";

const VoucherFixed = () => {
  const { translations } = useContext(LocalizationContext);

  const campaignDetail: CampaignDetailType = useSelector(
    campaignDetailSelector
  );

  return (
    <>
      <div className={"section-white-box-column"}>
        <SectionWhiteBoxItem
          label={"Voucher type"}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={translations.voucherType[campaignDetail.voucher_reward.type]}
        />
        <SectionWhiteBoxItem
          label={"Voucher value"}
          value={`${campaignDetail.voucher_reward.currency} ${campaignDetail.voucher_reward.value}`}
        />
      </div>

      <div className={"section-white-box-column"}>
        <SectionWhiteBoxItem
          label={"Currency"}
          value={campaignDetail.voucher_reward.currency}
        />

        <SectionWhiteBoxItem
          label={"Voucher valid for X days from the date a user redeems it"}
          value={
            campaignDetail.voucher_reward.redeem_duration
              ? `${campaignDetail.voucher_reward.redeem_duration} days`
              : "-"
          }
        />
      </div>
    </>
  );
};

export default VoucherFixed;
