import ModalRightSideBarTemplate from "../../../../../../../App/views/ModalRightSideBarTemplate";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isModalManagePMSSelector } from "../../../../../../selectors/PropertySelector";
import {
  modalManagePMSAction,
  modalManagePMSAddRoomAction,
} from "../../../../../../actions/PropertyActions";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../../../../components/Button";
import { LocalizationContext } from "../../../../../../../../locales/Translation";
import { PUBLIC_URL } from "../../../../../../../App/configs/Constants";
import ManagePMSTable from "./ManagePMSTable";
import SizeBox from "../../../../../../../../components/SizeBox";

const ModalManagePMS = ({ refreshList, setRefreshList }: any) => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const isModalManagePMS = useSelector(isModalManagePMSSelector);

  return (
    <ModalRightSideBarTemplate
      trigger={null}
      onClose={() => dispatch(modalManagePMSAction(false))}
      modalClassName={"modal-right-sidebar-v2"}
      open={isModalManagePMS}
    >
      <Modal.Header>
        Manage PMS Rooms
        <ButtonCustom
          onClick={() => dispatch(modalManagePMSAction(false))}
          className={"m-0 p-0 none-bg close-modal"}
        >
          <>
            {translations.settings.close}
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Modal.Content>
        <div>
          <ButtonCustom
            onClick={() => dispatch(modalManagePMSAddRoomAction(true))}
            className={"primary-alt"}
          >
            <>
              Add More PMS Rooms
              <i className="uil uil-plus" />
            </>
          </ButtonCustom>
        </div>
        <SizeBox height={"27px"} />
        <ManagePMSTable
          refreshList={refreshList}
          setRefreshList={setRefreshList}
        />
      </Modal.Content>
    </ModalRightSideBarTemplate>
  );
};

export default ModalManagePMS;
