import React, { useContext, useEffect, useRef, useState } from "react";
import { LocalizationContext } from "../../../../locales/Translation";
import { Link } from "react-router-dom";
import {
  BOOK_DESK_LINK,
  BOOK_INQUIRE_OFFICE_LINK,
  BOOK_ROOM_LINK,
} from "../../../App/configs/ListRoutes";
import Desk from "../Desk";
import Room from "../Room";
import MasterTemplate from "../../../App/views/MasterTemplate";
import {
  ROLE_BUSINESS_ADMIN_ID,
  ROLE_BUSINESS_USER_ID,
} from "../../../User/configs/Constants";
import { Tab } from "semantic-ui-react";
import "./styles.scss";
import MapView from "../MapView";
import { useDispatch, useSelector } from "react-redux";
import {
  globalSettingSelector,
  myProfileSelector,
} from "../../../App/selectors/AppSelectors";
import Flag from "react-world-flags";
import {
  filterMarketAction,
  filterMarketOptionsAction,
  propertyFilterKeywordAction,
} from "../../actions/BookActions";
import {
  deskAmenitiesSelector,
  roomAmenitiesSelector,
} from "../../selectors/BookSelectors";
import { UserCompanyType } from "../../../User/configs/DeclareTypes";
import { userCompanyChooseSelector } from "../../../User/selectors/UserSelectors";
import {
  deskAmenitiesThunk,
  roomAmenitiesThunk,
} from "../../thunks/BookThunks";
import { FILTER_MARKET_SESSION } from "../../configs/Constants";
import InquirePrivateOffice from "../InquirePrivateOffice";
import FormRight from "../InquirePrivateOffice/FormRight";
import Market from "../Components/Market";
import classNames from "classnames";
import Button from "../../../../components/Button";

type Props = {
  tabIndex: number;
};

const Tabs = ({ tabIndex }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const myProfile = useSelector(myProfileSelector);
  const dispatch = useDispatch();
  const globalSetting = useSelector(globalSettingSelector);
  const userCompanyChoose: UserCompanyType = useSelector(
    userCompanyChooseSelector
  );
  const deskAmenities = useSelector(deskAmenitiesSelector);
  const roomAmenities = useSelector(roomAmenitiesSelector);

  const [visibleScrollToTop, setVisibleScrollToTop] = useState(false);
  const leftSectionRef = useRef(null);

  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.classList.add("prevent-scroll");
    }
    return () => {
      const body = document.querySelector("body");

      if (body) {
        body.classList.remove("prevent-scroll");
      }
    };
  }, []);

  useEffect(() => {
    dispatch(propertyFilterKeywordAction(""));
    if (tabIndex === 0 && !deskAmenities.length) {
      dispatch<any>(deskAmenitiesThunk());
    }

    if (tabIndex === 1 && !roomAmenities.length) {
      dispatch<any>(roomAmenitiesThunk());
    }
  }, [tabIndex]);

  useEffect(() => {
    (async () => {
      if (!globalSetting?.location?.length) {
        return;
      }

      const results = globalSetting?.location.map((location: any) => {
        return {
          key: location?.city_code,
          text: (
            <div className={"dropdown-market-item"}>
              <Flag
                code={location?.alpha2_country_code}
                className={"flag-custom"}
              />
              <span className={"dropdown-market-text"}>
                {location?.city_name}
              </span>
            </div>
          ),
          value: location?.city_code,
        };
      });

      dispatch(filterMarketOptionsAction(results));

      const filterMarketSession = sessionStorage.getItem(FILTER_MARKET_SESSION);
      let currentMarket: any = null;

      if (filterMarketSession) {
        currentMarket = globalSetting?.location.find(
          ({ city_code }: { city_code: string }) =>
            city_code === filterMarketSession
        );
      }

      if (!currentMarket) {
        currentMarket = globalSetting?.location.find(
          ({ city_code }: { city_code: string }) =>
            city_code === userCompanyChoose.market_code
        );
      }

      if (currentMarket) {
        dispatch(filterMarketAction(currentMarket));
        sessionStorage.setItem(FILTER_MARKET_SESSION, currentMarket.city_code);
      } else {
        dispatch(filterMarketAction(globalSetting?.location[0]));
      }
    })();
  }, [globalSetting]);

  const panes = [
    {
      menuItem: {
        as: Link,
        id: "tab1",
        to: BOOK_DESK_LINK,
        content: translations.desks,
        exact: true,
      },
      render: () => <Desk />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab2",
        to: BOOK_ROOM_LINK,
        content: translations.meetingRooms,
        exact: true,
      },
      render: () => <Room />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab2",
        to: BOOK_INQUIRE_OFFICE_LINK,
        content: translations.PrivateOffices,
        exact: true,
      },
      render: () => <InquirePrivateOffice />,
    },
  ];

  const _onScroll = ({ target }: any) => {
    const offsetTop = target.scrollTop;

    if (offsetTop < 300) {
      setVisibleScrollToTop(false);
    } else {
      setVisibleScrollToTop(true);
    }
  };

  const _scrollTopTop = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    leftSectionRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <MasterTemplate
      noWrapper
      roleAccept={[ROLE_BUSINESS_ADMIN_ID, ROLE_BUSINESS_USER_ID]}
      wrapperClass={
        "book-page-wrapper " +
        (tabIndex === 2 ? " private-office-list-page" : "")
      }
    >
      <div className={"book-page__wrapper"}>
        <div
          ref={leftSectionRef}
          className={classNames("book-page__left-section", {
            banner: myProfile?.login_on_behalf,
          })}
          onScroll={_onScroll}
        >
          <div className={"filter-top"}>
            <div className={"text-title"}>
              {`Hi ${
                myProfile?.first_name || myProfile?.full_name || ""
              }, let's make a booking`}
            </div>

            <Market />
          </div>
          <Tab
            menu={{ text: true }}
            activeIndex={tabIndex}
            panes={panes}
            className={"tab-custom"}
          />
        </div>

        <div className={"book-page__right-section"}>
          {tabIndex === 2 ? <FormRight /> : <MapView tabActive={tabIndex} />}
        </div>

        <Button
          className={classNames("book-page__scroll-top", {
            hide: !visibleScrollToTop,
          })}
          onClick={_scrollTopTop}
        >
          <i className="uil uil-top-arrow-to-top"></i>
        </Button>
      </div>
    </MasterTemplate>
  );
};

export default Tabs;
