import React, { useContext } from "react";
import Label from "../../../../../components/Label";
import { LocalizationContext } from "../../../../../locales/Translation";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import InputCustom from "../../../../../components/Input";

type Props = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
  handleChange: any;
};

const PaymentReference = ({
  values,
  errors,
  touched,
  isSubmitting,
  handleChange,
}: Props) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <>
      <Label
        label={translations.paymentReference}
        fontSize={"24px"}
        className={"section-title"}
      />
      <div className={"detail_divide"}>
        <div className={"left_section"}>
          <InputCustom
            labelClassName={"label-xl"}
            label={translations.bankAccountNumber}
            labelRequired
            value={values.bank_account_number}
            error={errors.bank_account_number}
            touched={touched.bank_account_number}
            name={"bank_account_number"}
            onChange={handleChange}
            disabled={isSubmitting}
          />

          <InputCustom
            labelClassName={"label-xl"}
            label={translations.bankName}
            labelRequired
            value={values.bank_name}
            error={errors.bank_name}
            touched={touched.bank_name}
            name={"bank_name"}
            onChange={handleChange}
            disabled={isSubmitting}
          />

          <InputCustom
            labelClassName={"label-xl"}
            label={translations.SWIFT_BICCode}
            value={values.swift_code}
            error={errors.swift_code}
            touched={touched.swift_code}
            name={"swift_code"}
            onChange={handleChange}
            disabled={isSubmitting}
          />
        </div>
        <div className={"right_section"}>
          <InputCustom
            labelClassName={"label-xl"}
            label={translations.bankAccountName}
            labelRequired
            value={values.bank_account_name}
            error={errors.bank_account_name}
            touched={touched.bank_account_name}
            name={"bank_account_name"}
            onChange={handleChange}
            disabled={isSubmitting}
          />

          <InputCustom
            labelClassName={"label-xl"}
            label={translations.branchCode}
            value={values.branch_code}
            error={errors.branch_code}
            touched={touched.branch_code}
            name={"branch_code"}
            onChange={handleChange}
            disabled={isSubmitting}
          />

          <InputCustom
            labelClassName={"label-xl"}
            label={translations.bankAddress}
            value={values.bank_address}
            error={errors.bank_address}
            touched={touched.bank_address}
            name={"bank_address"}
            onChange={handleChange}
            disabled={isSubmitting}
          />
        </div>
      </div>

      <div className={"property_company_detail_line"} />
    </>
  );
};

export default PaymentReference;
