import React from "react";
import { DeskimoUserDetailType } from "../../../configs/DeclareTypes";
import { useSelector } from "react-redux";
import { employeeDetailSelector } from "../../../selectors/EmployeeSelectors";
import UserCreditItem from "./UserCreditItem";

const UserCredit = () => {
  const { credit }: DeskimoUserDetailType = useSelector(employeeDetailSelector);

  if (!credit || credit.filter((item) => item.expired_date).length < 1) {
    return null;
  }

  return (
    <>
      {credit
        .filter((item) => item.expired_date)
        .map((item) => {
          return <UserCreditItem key={item.company_id} item={item} />;
        })}
    </>
  );
};

export default UserCredit;
