import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import DeskimoPropertiesCompanyListTable from "./Components/DeskimoPropertiesCompanyListTable";
import { useDispatch, useSelector } from "react-redux";
import { getDeskimoPropertyCompanyThunk } from "../../thunks/PropertyThunks";
import {
  deskimoPropertyCompanyListFilterAction,
  deskimoPropertyCompanyListFilterApplyAllAction,
} from "../../actions/PropertyActions";
import { deskimoPropertyCompanyListFilterSelector } from "../../selectors/PropertySelector";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../App/configs/Constants";
import ButtonCustom from "../../../../components/Button";
import { DESKIMO_PROPERTY_COMPANY_CREATE_LINK } from "../../../App/configs/ListRoutes";
import { useNavigate } from "react-router-dom";
import { globalSettingSelector } from "../../../App/selectors/AppSelectors";
import { useGetAllQuery, useSetQuery } from "../../../../utils/custom-hooks";
import SearchWithDebounce from "../../../../components/SearchBar4Table/SearchWithDebounce";
import { LocalizationContext } from "../../../../locales/Translation";

const DeskimoPropertiesCompanyList = () => {
  const { translations } = useContext(LocalizationContext);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const propertyCompanyListFilter = useSelector(
    deskimoPropertyCompanyListFilterSelector
  );
  const globalSetting = useSelector(globalSettingSelector);

  const [addParamsAlready, setAddParamsAlready] = useState<boolean>(false);
  const getAllQuery = useGetAllQuery();
  const setQuery = useSetQuery();

  useEffect(() => {
    try {
      if (!propertyCompanyListFilter.market_codes.length) {
        const temp = globalSetting?.location.map(
          ({ city_code }: { city_code: string }) => city_code
        );

        dispatch(
          deskimoPropertyCompanyListFilterAction({
            key: "market_codes",
            value: temp,
          })
        );
      }

      if (!Object.keys(getAllQuery).length) {
        return;
      }
      dispatch(deskimoPropertyCompanyListFilterApplyAllAction(getAllQuery));
    } finally {
      setAddParamsAlready(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (!addParamsAlready) {
        return;
      }
      setQuery(propertyCompanyListFilter);
      await _getPropertyCompanyList();
    })();
  }, [addParamsAlready, propertyCompanyListFilter]);

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    dispatch(
      deskimoPropertyCompanyListFilterAction({ key: "page", value: activePage })
    );
  };

  const _onChangeSortCol = (col: any) => {
    if (propertyCompanyListFilter.sort_by !== col.key) {
      dispatch(
        deskimoPropertyCompanyListFilterAction({
          key: "sort_by",
          value: col.key,
        })
      );
      dispatch(
        deskimoPropertyCompanyListFilterAction({
          key: "order_by",
          value: ORDER_BY_DESC,
        })
      );
    } else {
      dispatch(
        deskimoPropertyCompanyListFilterAction({
          key: "sort_by",
          value: col.key,
        })
      );
      if (propertyCompanyListFilter.order_by === ORDER_BY_NONE) {
        dispatch(
          deskimoPropertyCompanyListFilterAction({
            key: "order_by",
            value: ORDER_BY_DESC,
          })
        );
      } else if (propertyCompanyListFilter.order_by === ORDER_BY_DESC) {
        dispatch(
          deskimoPropertyCompanyListFilterAction({
            key: "order_by",
            value: ORDER_BY_ASC,
          })
        );
      } else
        dispatch(
          deskimoPropertyCompanyListFilterAction({
            key: "order_by",
            value: ORDER_BY_NONE,
          })
        );
    }
  };

  const _getPropertyCompanyList = async () => {
    try {
      if (!propertyCompanyListFilter.market_codes.length) {
        return;
      }
      setLoading(true);
      await dispatch<any>(
        getDeskimoPropertyCompanyThunk(propertyCompanyListFilter)
      );
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const _onRefresh = async () => {
    await dispatch<any>(
      getDeskimoPropertyCompanyThunk(propertyCompanyListFilter)
    );
  };

  const _onChangeMarketFilter = (values: string[]) => {
    dispatch(
      deskimoPropertyCompanyListFilterAction({
        key: "market_codes",
        value: values,
      })
    );
  };

  if (!addParamsAlready) {
    return null;
  }

  return (
    <div>
      <div className={"filter-section"}>
        <SearchWithDebounce
          initialValue={propertyCompanyListFilter?.keyword}
          placeholder={translations.newPropertyPage.searchByPropertyCompany}
          onChange={(value) =>
            dispatch(
              deskimoPropertyCompanyListFilterAction({ key: "keyword", value })
            )
          }
        />
        <div className={"dropdown-filter d-flex"}>
          <ButtonCustom
            onClick={() => navigate(DESKIMO_PROPERTY_COMPANY_CREATE_LINK)}
            height={"36px"}
            fontSize={"12px"}
            className={"primary text-nowrap mr-8px"}
          >
            <>Add Company +</>
          </ButtonCustom>
        </div>
      </div>
      <DeskimoPropertiesCompanyListTable
        setLoading={setLoading}
        loading={loading}
        onChangePagination={_onChangePagination}
        onChangeSortCol={_onChangeSortCol}
        onRefresh={_onRefresh}
        onChangeMarketFilter={_onChangeMarketFilter}
      />
    </div>
  );
};

export default DeskimoPropertiesCompanyList;
