import clientRequestService from "../../../services/ClientRequestService";
import {
  GET_PROPERTY_LIST_FILTERS_API,
  POST_MEETING_ROOM_LIST_API,
  GET_PROPERTY_DETAIL_API,
  POST_PROPERTY_LIST_API,
  GET_MEETING_ROOM_DETAIL_API,
  PUT_UPDATE_PROPERTY_API,
  PUT_UPDATE_MEETING_ROOM_API,
  POST_USER_REVIEW_API,
  GET_STAFF_API,
  PUT_PUBLISH_PROPERTY_API,
  PUT_PUBLISH_ROOM_API,
  DELETE_ROOM_API,
  POST_DESKIMO_PROPERTY_LIST_API,
  POST_DESKIMO_PROPERTY_COMPANY_LIST_API,
  POST_DESKIMO_PROPERTY_STAFF_LIST_API,
  PUT_UPDATE_DESKIMO_PROPERTY_STAFF_API,
  POST_DESKIMO_PROPERTY_STAFF_DETAILS_API,
  GET_PROPERTY_NOTES_API,
  PROPERTY_NOTE_API,
  DELETE_PROPERTY_NOTE_API,
  PROPERTY_NOTE_VERSIONING_API,
  GET_PROPERTY_CATEGORIES_API,
  CREATE_PROPERTY_CATEGORY_API,
  DELETE_PROPERTY_CATEGORY_API,
  GET_PROPERTY_HOLIDAYS_API,
  PRIVATE_OFFICE_API,
  EDIT_PRIVATE_OFFICE_API,
  PRIVATE_OFFICE_MANAGE_LIST_API,
  PRIVATE_OFFICE_MANAGE_DETAIL_API,
  PRIVATE_OFFICE_DETAIL_API,
  PRIVATE_OFFICE_MANAGE_STATUS_API,
  PRIVATE_OFFICE_MANAGE_DELETE_API,
  API_CHANGE_REVIEW_STATUS,
  DESKIMO_USER_REVIEWS_API,
  DESKIMO_USER_REVIEWS_FEEDBACK_API,
  POST_DESKIMO_PRIVATE_OFFICE_LIST_API,
  PRIVATE_OFFICE_MANAGE_VISIBILITY_API,
  PROPERTY_REWARD_LIST_API,
  PROPERTY_REWARD_DETAIL_API,
  CREATE_REWARD_API,
  UPDATE_REWARD_API,
  DELETE_REWARD_API,
} from "../configs/Endpoints";

export const propertyListFiltersService = async () => {
  return await clientRequestService({
    method: "get",
    url: GET_PROPERTY_LIST_FILTERS_API,
    isCompany: true,
  });
};

export const deskimoPropertyListService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: POST_DESKIMO_PROPERTY_LIST_API,
    data,
  });
};

export const deskimoPropertyCompanyListService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: POST_DESKIMO_PROPERTY_COMPANY_LIST_API,
    data,
  });
};

export const deskimoPropertyStaffListService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: POST_DESKIMO_PROPERTY_STAFF_LIST_API,
    data,
  });
};

export const deskimoPropertyStaffDetailService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: POST_DESKIMO_PROPERTY_STAFF_DETAILS_API,
    isCompany: true,
    data,
  });
};

export const propertyListService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: POST_PROPERTY_LIST_API,
    isCompany: true,
    data,
  });
};

export const meetingRoomListService = async (
  data: object,
  noCompany?: boolean
) => {
  return await clientRequestService({
    method: "post",
    url: POST_MEETING_ROOM_LIST_API,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    isCompany: !noCompany,
    data,
  });
};

export const propertyDetailService = async (id: string) => {
  return await clientRequestService({
    method: "get",
    url: GET_PROPERTY_DETAIL_API.replaceAll("%id%", id),
    isCompany: true,
  });
};

export const meetingRoomDetailService = async (id: string) => {
  return await clientRequestService({
    method: "get",
    url: GET_MEETING_ROOM_DETAIL_API.replaceAll("%id%", id),
    isCompany: true,
  });
};

export const updatePropertyService = async (data: object) => {
  return await clientRequestService({
    method: "put",
    url: PUT_UPDATE_PROPERTY_API,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    isCompany: !data?.company_id,
    data,
  });
};

export const updateMeetingRoomService = async (data: object) => {
  return await clientRequestService({
    method: "put",
    url: PUT_UPDATE_MEETING_ROOM_API,
    isCompany: true,
    data,
  });
};

export const userReviewService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: POST_USER_REVIEW_API,
    isCompany: true,
    data,
  });
};

export const userReviewChangeStatusService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: API_CHANGE_REVIEW_STATUS,
    data,
  });
};

export const getStaffService = async (propertyId: string) => {
  return await clientRequestService({
    method: "get",
    url: GET_STAFF_API.replaceAll("%propertyId%", propertyId),
    isCompany: true,
  });
};

export const updateStaffService = async () => {
  return await clientRequestService({
    method: "POST",
    url: PUT_UPDATE_DESKIMO_PROPERTY_STAFF_API,
    isCompany: true,
  });
};

export const handlePublishService = async (data: object, isCompany = true) => {
  return await clientRequestService({
    method: "put",
    url: PUT_PUBLISH_PROPERTY_API,
    isCompany,
    data,
  });
};

export const handlePublishRoomService = async (data: object) => {
  return await clientRequestService({
    method: "put",
    url: PUT_PUBLISH_ROOM_API,
    isCompany: true,
    data,
  });
};

export const deleteMeetingRoomService = async (data: object) => {
  return await clientRequestService({
    method: "delete",
    url: DELETE_ROOM_API,
    isCompany: true,
    data,
  });
};

export const getPropertyNotesService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: GET_PROPERTY_NOTES_API,
    data,
  });
};

export const propertyNotesService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: PROPERTY_NOTE_API,
    data,
  });
};

export const deletePropertyNoteService = async (id: number) => {
  return await clientRequestService({
    method: "delete",
    url: DELETE_PROPERTY_NOTE_API.replaceAll("%id%", String(id)),
  });
};
export const propertyNoteVersioningService = async (id: number) => {
  return await clientRequestService({
    url: PROPERTY_NOTE_VERSIONING_API.replaceAll("%id%", String(id)),
  });
};

export const getPropertyCategoriesService = async () => {
  return await clientRequestService({
    method: "GET",
    url: GET_PROPERTY_CATEGORIES_API,
  });
};

export const createPropertyCategoryService = async (data: object) => {
  return await clientRequestService({
    method: "POST",
    url: CREATE_PROPERTY_CATEGORY_API,
    data,
  });
};

export const deletePropertyCategoryService = async (id: string | number) => {
  return await clientRequestService({
    method: "DELETE",
    url: DELETE_PROPERTY_CATEGORY_API.replaceAll("%id%", id.toString()),
  });
};

export const getPropertyHolidaysService = async (id: number) => {
  return await clientRequestService({
    url: GET_PROPERTY_HOLIDAYS_API.replaceAll("%companyId%", id.toString()),
  });
};

export const createPrivateOfficeService = async (payload: object) => {
  return await clientRequestService({
    method: "POST",
    url: PRIVATE_OFFICE_API,
    data: payload,
  });
};

export const editPrivateOfficeService = async (id: string, payload: object) => {
  return await clientRequestService({
    method: "PUT",
    url: EDIT_PRIVATE_OFFICE_API.replaceAll("%id%", id),
    data: payload,
  });
};
export const privateOfficeMangeListService = async (payload: object) => {
  return await clientRequestService({
    method: "POST",
    url: PRIVATE_OFFICE_MANAGE_LIST_API,
    data: payload,
  });
};

export const privateOfficeStatusService = async (payload: object) => {
  return await clientRequestService({
    method: "PUT",
    url: PRIVATE_OFFICE_MANAGE_STATUS_API,
    data: payload,
  });
};

export const privateOfficeDeleteService = async (privateOfficeId: number) => {
  return await clientRequestService({
    method: "DELETE",
    url: PRIVATE_OFFICE_MANAGE_DELETE_API.replaceAll(
      "%privateOfficeId%",
      String(privateOfficeId)
    ),
  });
};

export const privateOfficeMangeDetailService = async (
  companyId: string,
  privateOfficeId: string
) => {
  return await clientRequestService({
    url: PRIVATE_OFFICE_MANAGE_DETAIL_API.replaceAll(
      "%companyId%",
      companyId
    ).replaceAll("%privateOfficeId%", privateOfficeId),
  });
};

export const privateOfficeDetailService = async (slug: string) => {
  return await clientRequestService({
    url: PRIVATE_OFFICE_DETAIL_API.replaceAll("%slug%", slug),
  });
};

export const onInheritScheduleAction = (
  type: string,
  setOperationHour: any
) => {
  setOperationHour((prevState: any) => {
    const openHourWeekDays = prevState.find(
      ({ type }: { type: string }) => type === "openHour"
    ).week_days;

    return prevState.map((schedule: any) => {
      if (schedule.type === type) {
        return { ...schedule, week_days: openHourWeekDays };
      }

      return schedule;
    });
  });
};

export const getDeskimoUserReviewsService = async (payload: object) => {
  return await clientRequestService({
    method: "POST",
    url: DESKIMO_USER_REVIEWS_API,
    data: payload,
  });
};

export const getDeskimoUserReviewsFeedbackService = async (
  propertyId: string,
  limit?: string
) => {
  return await clientRequestService({
    method: "get",
    url: `${DESKIMO_USER_REVIEWS_FEEDBACK_API.replaceAll(
      "/%propertyId%",
      propertyId ? `/${propertyId}` : ""
    )}${limit ? `/${limit}` : ""}`,
  });
};

export const deskimoPropertyPrivateListService = async (data: any) => {
  const newData = {
    ...data,
    filter: {
      ...data.filter,
      markets: data.filter.markets.map((market: any) =>
        market === "0" ? 0 : market
      ),
    },
  };

  return await clientRequestService({
    method: "post",
    url: POST_DESKIMO_PRIVATE_OFFICE_LIST_API,
    data: newData,
  });
};

export const privateOfficeVisibilityService = async (
  privateOfficeId: string,
  payload: object
) => {
  return await clientRequestService({
    method: "PATCH",
    url: PRIVATE_OFFICE_MANAGE_VISIBILITY_API.replaceAll(
      "%privateOfficeId%",
      privateOfficeId
    ),
    data: payload,
  });
};

export const propertyRewardListService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: PROPERTY_REWARD_LIST_API,
    isCompany: true,
    data,
  });
};

export const propertyRewardDetailService = async (
  propertyId: string,
  rewardId: string
) => {
  return await clientRequestService({
    method: "get",
    url: PROPERTY_REWARD_DETAIL_API.replaceAll(
      "%propertyId%",
      propertyId
    ).replaceAll("%rewardId%", rewardId),
    isCompany: true,
  });
};

export const createRewardService = async (data: object, propertyId: string) => {
  return await clientRequestService({
    method: "post",
    url: CREATE_REWARD_API.replaceAll("%propertyId%", propertyId),
    data,
  });
};

export const updateRewardService = async (
  data: object,
  propertyId: string,
  rewardId: string
) => {
  return await clientRequestService({
    method: "put",
    url: UPDATE_REWARD_API.replaceAll("%propertyId%", propertyId).replaceAll(
      "%rewardId%",
      rewardId
    ),
    data,
  });
};

export const removeRewardService = async (
  propertyId: string,
  rewardId: string
) => {
  return await clientRequestService({
    method: "delete",
    url: DELETE_REWARD_API.replaceAll("%propertyId%", propertyId).replaceAll(
      "%rewardId%",
      rewardId
    ),
  });
};
