import React from "react";
import { listBookingCompletedPayloadAction } from "../../../../actions/BookingActions";
import TableCustom from "../../../../../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  listBookingCompletedPayloadSelector,
  listBookingCompletedSelector,
} from "../../../../selectors/BookingSelectors";
import { FORMAT_DATE_TIME_VARIANT_8 } from "../../../../../App/configs/DateTimeConfigs";
import moment from "moment";

const CompletedTab = ({ loading, setLoading }: any) => {
  const listBookingCompleted = useSelector(listBookingCompletedSelector);
  const listBookingCompletedPayload = useSelector(
    listBookingCompletedPayloadSelector
  );
  const dispatch = useDispatch();

  const columns = [
    "Transaction ID",
    "Name",
    "Property Details",
    "Duration",
    "Booking Date",
    "Total Fee",
  ];

  const decoratedData = () => {
    if (
      listBookingCompleted?.items &&
      listBookingCompleted?.items?.length > 0
    ) {
      return listBookingCompleted?.items.map((booking: any, index: number) => {
        return {
          code: booking?.code,
          col1: booking?.property_name ? booking?.property_name : booking?.name,
          col2: (
            <div
              className={"table-booking__property-details table-booking__cell"}
            >
              <div className={"table-booking__property-details-item"}>
                <i className="uil uil-building" />
                <span>
                  {booking?.type ? booking?.type : booking?.company_name}
                </span>
              </div>
              {booking?.address ? (
                <div className={"table-booking__property-details-item"}>
                  <i className="uil uil-map" />
                  <span>{booking?.address}</span>
                </div>
              ) : null}
            </div>
          ),
          col3: booking?.duration,
          col4: booking?.formatted_booking_date
            ? booking?.formatted_booking_date
            : moment(booking?.start_time * 1000).format(
                FORMAT_DATE_TIME_VARIANT_8
              ),
          col5: booking?.total_fee ? (
            <p className={"text-center"}>{booking?.total_fee}</p>
          ) : (
            <p className={"text-center"}>
              {booking?.currency_code + " " + booking?.total_price}
            </p>
          ),
        };
      });
    }
    return [];
  };

  return (
    <TableCustom
      showTotal={false}
      columns={columns}
      currentPage={listBookingCompletedPayload.page}
      loading={loading}
      tableClass={"table-booking"}
      tableData={decoratedData()}
      itemPerPage={listBookingCompleted?.pagination?.limit}
      totalItems={listBookingCompleted?.pagination?.total}
      onChangePagination={(e: any, { activePage }: any) => {
        dispatch(listBookingCompletedPayloadAction({ page: activePage }));
      }}
    />
  );
};

export default CompletedTab;
