import React, { useEffect } from "react";
import Revenue from "./Revenue";
import VisitAndBooking from "./VisitAndBooking";
import MarketBreakdown from "./MarketBreakdown";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardFilterDateRangeSelector,
  dashboardFilterDateTypeSelector,
  dashboardStatisticFilterSelector,
  filterListPropertySelectedSelector,
} from "../../../../selectors/DashboardSelectors";
import { dashboardStatisticDeskimoThunk } from "../../../../thunks/DashboardThunks";
import OngoingVisits from "./OngoingVisits";
import {
  AUTO_REFRESH_INTERVAL_TIME,
  FILTER_DATE_TODAY,
} from "../../../../configs/Constants";

const Diagram = () => {
  const dispatch = useDispatch();

  const dashboardFilterDateRange = useSelector(
    dashboardFilterDateRangeSelector
  );
  const dashboardFilterMarket = useSelector(dashboardStatisticFilterSelector);
  const dashboardFilterDateType = useSelector(dashboardFilterDateTypeSelector);
  const filterListPropertySelected = useSelector(
    filterListPropertySelectedSelector
  );
  useEffect(() => {
    if (dashboardFilterDateRange === null) {
      return;
    }

    _init();
    const interval = setInterval(_autoRefresh, AUTO_REFRESH_INTERVAL_TIME);
    return () => {
      clearInterval(interval);
    };
  }, [
    dashboardFilterDateRange,
    dashboardFilterMarket,
    filterListPropertySelected,
  ]);

  const _init = async () => {
    try {
      await dispatch<any>(dashboardStatisticDeskimoThunk());
    } catch (e) {
      console.log("e", e);
    }
  };

  const _autoRefresh = async () => {
    if (dashboardFilterDateType !== FILTER_DATE_TODAY) {
      return;
    }
    await _init();
  };

  return (
    <div className={"dashboard_chart_wrapper"}>
      <Revenue />
      <VisitAndBooking />
      <MarketBreakdown />
      {/*<Account />*/}
      <OngoingVisits />
    </div>
  );
};

export default Diagram;
