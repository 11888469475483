import React, { useContext, useRef, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import SizeBox from "../../../../../components/SizeBox";
import { DASHBOARD_PROPERTY_TYPE } from "../../../configs/DeclareTypes";
import { useSelector } from "react-redux";
import { dashboardPropertyChooseSelector } from "../../../selectors/DashboardSelectors";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import { LocalizationContext } from "../../../../../locales/Translation";

const PropertyScanner = () => {
  const { translations } = useContext(LocalizationContext);

  const dashboardPropertyChoose: DASHBOARD_PROPERTY_TYPE = useSelector(
    dashboardPropertyChooseSelector
  );

  const [step, setStep] = useState(0);

  if (!dashboardPropertyChoose?.scanner_list?.length) {
    return (
      <>
        <div className={"dashboard_property_status_box"}>
          <div className={"dashboard_property_scanner_wrapper"}>
            <h2 className={"dashboard_chart_title"}>
              {translations.noQRCodeScannerAvailable}
            </h2>
          </div>
          <SizeBox height={"12px"} />
          <h6 className={"dashboard_property_scanner_id"}>
            {translations.contactDeskimoToOrderScanner}
          </h6>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={"dashboard_property_status_box"}>
        <div className={"dashboard_property_scanner_wrapper"}>
          <h2 className={"dashboard_chart_title"}>
            {translations.QRCodeScannerStatus}
          </h2>
          {dashboardPropertyChoose?.scanner_list?.length !== 1 ? (
            <div className={"dashboard_property_scanner_index"}>
              {step + 1}/{dashboardPropertyChoose?.scanner_list?.length}
              <SizeBox width={"6px"} />
              <button
                onClick={() => {
                  if (step === 0) {
                    return;
                  }
                  setStep((prevState) => prevState - 1);
                }}
                className={"dashboard_property_scanner_arrow"}
              >
                <img
                  src={PUBLIC_URL + "/assets/icons/icon-arrow-round-left.svg"}
                />
              </button>
              <button
                onClick={() => {
                  if (
                    step >=
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    dashboardPropertyChoose?.scanner_list?.length - 1
                  ) {
                    return;
                  }
                  setStep((prevState) => prevState + 1);
                }}
                className={"dashboard_property_scanner_arrow"}
              >
                <img
                  src={PUBLIC_URL + "/assets/icons/icon-arrow-round-right.svg"}
                />
              </button>
            </div>
          ) : null}
        </div>
        <SizeBox height={"12px"} />
        <Carousel
          dynamicHeight
          selectedItem={step}
          showStatus={false}
          onChange={(index) => setStep(index)}
          showThumbs={false}
        >
          {dashboardPropertyChoose?.scanner_list?.map((item) => {
            return (
              <div
                key={item.deviceId}
                className={"dashboard_property_status_item"}
              >
                <div
                  className={
                    item.status
                      ? "dashboard_property_status"
                      : "dashboard_property_status_close"
                  }
                >
                  •{" "}
                  {item.status
                    ? translations.healthy
                    : translations.disconnected}
                </div>
                <SizeBox height={"12px"} />
                <h6 className={"dashboard_property_scanner_id"}>
                  {translations.scannerID}: {item.deviceId}
                </h6>
                {item.status ? null : (
                  <>
                    <SizeBox height={"5px"} />
                    <h6 className={"dashboard_property_scanner_id"}>
                      {translations.thisScannerIsNotWorkingCurrently}
                    </h6>
                  </>
                )}
              </div>
            );
          })}
        </Carousel>
      </div>
    </>
  );
};

export default PropertyScanner;
