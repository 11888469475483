import Radio from "../../../../../components/Radio";
import React from "react";

const ReportPropertyStatusRow = ({ label, value, checked, onChange }: any) => {
  return (
    <div className={"radio-row"} onClick={onChange}>
      <Radio value={value} checked={checked} onChange={onChange} />
      <span>{label}</span>
    </div>
  );
};

export default ReportPropertyStatusRow;
