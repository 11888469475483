import "./styles.scss";

const MiddleDot = ({ size, color = "#000", className }: any) => {
  return (
    <div className={"middle-dot-wrapper"}>
      <div
        style={{ width: size, height: size, backgroundColor: color }}
        className={"middle-dot-component " + className}
      ></div>
    </div>
  );
};

export default MiddleDot;
