import React from "react";
import Label from "../../../../../../components/Label";
import SizeBox from "../../../../../../components/SizeBox";
import { FormikSetFieldValueType } from "../../../../configs/DeclareTypes";
import classNames from "classnames";

type Props = {
  value: string[];
  setFieldValue: FormikSetFieldValueType;
  disabled: boolean;
  error: string;
};

const DateOfWeek = ({ value, setFieldValue, disabled, error }: Props) => {
  const DAY_OF_WEEKS = [
    { id: "Mon", label: "Mon", weight: 1 },
    { id: "Tue", label: "Tue", weight: 2 },
    { id: "Wed", label: "Wed", weight: 3 },
    { id: "Thu", label: "Thu", weight: 4 },
    { id: "Fri", label: "Fri", weight: 5 },
    { id: "Sat", label: "Sat", weight: 6 },
    { id: "Sun", label: "Sun", weight: 7 },
  ];
  const _onClick = (id: string) => {
    if (disabled) {
      return;
    }

    if (value.includes(id)) {
      const temp = value.filter((item) => item !== id);
      setFieldValue("usage_week_days", temp);
      return;
    }

    let newValue = [...value, id];
    newValue = newValue.sort((a: any, b: any) => {
      const aWeight = DAY_OF_WEEKS.find((item: any) => item?.id === a)?.weight;
      const bWeight = DAY_OF_WEEKS.find((item: any) => item?.id === b)?.weight;
      if (aWeight && bWeight) {
        return aWeight - bWeight;
      }
      return 0;
    });

    setFieldValue("usage_week_days", newValue);
  };

  return (
    <>
      <Label label={"Days of the week used"} />
      <SizeBox height={"16px"} />
      <div className={"week-days"}>
        {DAY_OF_WEEKS.map(({ label, id }) => (
          <div
            className={classNames("week-day-item", {
              active: value.includes(id),
            })}
            key={id}
            onClick={() => _onClick(id)}
          >
            {label}
          </div>
        ))}
      </div>
      {error ? <span className={"error"}>{error}</span> : null}
      <SizeBox height={"16px"} />
    </>
  );
};

export default DateOfWeek;
