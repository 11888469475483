import { PUBLIC_URL } from "../../App/configs/Constants";

export const INVOICE_STATUS_POSTED = 1;
export const INVOICE_STATUS_PAID = 2;
export const INVOICE_STATUS_COLOR = [
  {
    id: INVOICE_STATUS_POSTED,
    color: "orange",
  },
  {
    id: INVOICE_STATUS_PAID,
    color: "green",
  },
];

export const BILLING_HISTORY_FILTER = [
  {
    id: 1,
    name: "Posted",
    checked: true,
  },
  {
    id: 2,
    name: "Paid",
    checked: true,
  },
];

export const CREDIT_TRANSACTION_FILTER = [
  {
    id: 1,
    name: "Active",
    checked: true,
  },
  {
    id: 2,
    name: "Expired",
    checked: true,
  },
  {
    id: 0,
    name: "Pending payment",
    checked: true,
  },
  {
    id: 3,
    name: "Cancelled",
    checked: true,
  },
];

export const BRAND_LOGO = [
  {
    id: "visa",
    imgUrl: PUBLIC_URL + "/assets/brands/visa.png",
  },
  {
    id: "amex",
    imgUrl: PUBLIC_URL + "/assets/brands/amex.png",
  },
  {
    id: "discover",
    imgUrl: PUBLIC_URL + "/assets/brands/discover.png",
  },
  {
    id: "jcb",
    imgUrl: PUBLIC_URL + "/assets/brands/jcb.png",
  },
  {
    id: "mastercard",
    imgUrl: PUBLIC_URL + "/assets/brands/mastercard.png",
  },
  {
    id: "unionpay",
    imgUrl: PUBLIC_URL + "/assets/brands/unionpay.png",
  },
];

export const PAY_AS_YOU_GO_TYPE = 1;
export const PAY_MONTHLY_TYPE = 2;
export const MARKET_CURRENCY = 1;
export const COMPANY_CURRENCY = 2;
