import clientRequestService from "../../../services/ClientRequestService";
import { METHOD_POST } from "../../../configs/Constants";
import {
  API_ENTITLEMENT_SET_PASSWORD,
  API_ENTITLEMENT_VALIDATE_PHONE,
  API_GET_PACKAGE_TRIAL,
  API_GET_RECOMMENDED_PROPERTIES,
  API_GET_USER_PHONE,
  API_PENDING_INVITE_GET_OTP,
  API_PENDING_INVITE_SUBMIT_OTP,
  API_VERIFY_TOKEN,
  GET_OTP,
} from "../configs/Endpoints";
import { toastError } from "../../../components/Toast";

export const verifyTokenValidService = (data: { token: string }) => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_VERIFY_TOKEN,
    isAuth: false,
    data,
  });
};

export const entitlementSetPasswordService = (data: object) => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_ENTITLEMENT_SET_PASSWORD,
    isAuth: false,
    data,
  });
};

export const getOTPService = async (data: object) => {
  return clientRequestService({
    method: METHOD_POST,
    url: GET_OTP,
    isAuth: false,
    data,
  });
};

export const entitlementValidatePhoneService = async (data: object) => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_ENTITLEMENT_VALIDATE_PHONE,
    isAuth: false,
    data,
  });
};

export const getUserPhoneService = async (data: any) => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_GET_USER_PHONE,
    data,
  });
};

export const getUserPhoneWithoutAuthService = async (data: any) => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_GET_USER_PHONE,
    data,
    isAuth: false,
  });
};

export const getPendingInviteOTPService = async (
  data: object,
  hashToken: string
) => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_PENDING_INVITE_GET_OTP.replaceAll("%hashToken%", hashToken),
    data: data,
    isAuth: false,
  });
};

export const submitPendingInviteOTPService = async (
  data: object,
  hashToken: string
) => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_PENDING_INVITE_SUBMIT_OTP.replaceAll("%hashToken%", hashToken),
    data: data,
    isAuth: false,
  });
};

export const getPackageTrialService = async () => {
  return clientRequestService({
    url: API_GET_PACKAGE_TRIAL,
    isCompany: true,
  });
};

export const getTopPropertiesService = async (type: string) => {
  return clientRequestService({
    method: "POST",
    url: API_GET_RECOMMENDED_PROPERTIES.replaceAll("%type%", type),
    isCompany: true,
  });
};

export const handleOTPCaptchaService = (error: any, showReCaptcha: any) => {
  if (error?.status?.code === 113) {
    showReCaptcha(true);
    throw new Error();
  }

  toastError(error?.status?.message);
};
