import React, { useState } from "react";
import ButtonCustom from "../../../../../components/Button";
import { PAYMENT_SUCCESS } from "../../../configs/Constants";
import { leaseSendReceiptService } from "../../../services/DashboardServices";
import { toastError } from "../../../../../components/Toast";
import DropdownCustom from "../../../../../components/DropdownCustom";
import { Dropdown } from "semantic-ui-react";

type Props = {
  status: string;
  code: string;
};
const ActionsBusinessAdmin = ({ status, code }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const _onSendReceipt = async () => {
    try {
      setLoading(true);
      const { data } = await leaseSendReceiptService(code);
      window.open(data.url, "blank");
    } catch (e: any) {
      toastError(e?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  if (status !== PAYMENT_SUCCESS) {
    return null;
  }

  return (
    <DropdownCustom
      disabled={loading}
      className={"table-action-dropdown"}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        {status === PAYMENT_SUCCESS ? (
          <Dropdown.Item
            onClick={() => _onSendReceipt()}
            text={"Get Payment Receipt"}
            icon={<i className="uil icon uil-file-download-alt" />}
          />
        ) : null}
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default ActionsBusinessAdmin;
