import React, { useEffect, useState } from "react";
import { FormikValues } from "formik";
import { debounce } from "lodash";
import { businessCompanyCreditService } from "../../../services/BusinessServices";
import Label from "../../../../../components/Label";
import DropdownSearchServerSingle from "../../../../../components/DropdownSearchServerSingle";

type Props = {
  values: FormikValues;
  setFieldValue: any;
  disabled?: boolean;
  error: boolean;
  resetForm: () => void;
  onChange: (companyId: number) => void;
};

const DropdownCompany = ({
  values,
  setFieldValue,
  disabled,
  resetForm,
  onChange,
}: Props) => {
  const [options, setOptions] = useState<
    {
      key: number;
      text: string;
      id: number;
      timezone: string;
      currency: string;
    }[]
  >([]);

  useEffect(() => {
    (async () => {
      await _initOptions();
    })();
  }, []);

  const _initOptions = async (keyword = "") => {
    try {
      const { data } = await businessCompanyCreditService({ keyword });

      const temp = data.map(
        ({
          company_id,
          company_name,
          ...value
        }: {
          company_id: number;
          company_name: string;
        }) => {
          return {
            key: company_id,
            text: company_name,
            id: company_id,
            ...value,
          };
        }
      );
      setOptions(temp);
    } catch (e) {
      console.log(e);
    }
  };

  const debounce_onChange = debounce(async (keyword: string) => {
    await _initOptions(keyword);
  }, 300);

  return (
    <>
      <Label label={"Add prepaid credit package for"} />
      <DropdownSearchServerSingle
        value={{ text: values.company_name, id: values.company_id }}
        onSearch={(searchQuery) => debounce_onChange(searchQuery)}
        options={options}
        disabled={disabled}
        placeholder={"Select company"}
        onChange={(value) => {
          resetForm();
          setFieldValue("company_id", value.id);
          setFieldValue("company_name", value.text);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setFieldValue("currency", value.currency);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setFieldValue("auto_topup_credit", value.auto_topup_credit);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setFieldValue("company_recharge_amount", value.recharge_amount);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setFieldValue("company_trigger_amount", value.trigger_credit_amount);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setFieldValue("company_discount_percent", value.discount);
          onChange(Number(value.id));
        }}
      />
    </>
  );
};

export default DropdownCompany;
