import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import { useSelector } from "react-redux";
import {
  deskimoPropertyListFilterSelector,
  deskimoPropertyListSelector,
} from "../../../../Property/selectors/PropertySelector";
import { useNavigate } from "react-router-dom";
import DropdownCustom from "../../../../../components/DropdownCustom";
import ButtonCustom from "../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import TableCustom from "../../../../../components/Table";
import MarketFilterMultiple from "../../../../../components/FilterMarket/MarketFilterMultiple";
import MediaFileName from "./MediaFileName";
import MediaMarket from "./MediaMarket";
import { mediaFileListSelector } from "../../../selectors/MediaFileSelectors";

type Props = {
  loading: boolean;
  onChangePagination: any;
  onChangeSortCol: any;
  onChangeMarket: any;
  onRefresh: () => void;
  setLoading?: any;
  filter?: any;
  onDeleteFile: any;
  setFilter?: any;
  isPropertyUser?: boolean;
};

const MediaFileTable = ({
  loading,
  onChangePagination,
  onChangeSortCol,
  onChangeMarket,
  filter,
  setFilter,
  onDeleteFile,
  onRefresh,
  isPropertyUser,
}: Props) => {
  const { translations } = useContext(LocalizationContext);
  const mediaFileList = useSelector(mediaFileListSelector);

  const columns = [
    { key: "name", col: "Name" },
    { key: "market", col: "Market" },
    { key: "upload_date", col: "Date Uploaded" },
    { key: "file_size", col: "File Size" },
    { key: null, col: "Action" },
  ];

  const decorateDataTable = () => {
    if (mediaFileList?.items && mediaFileList?.items?.length > 0) {
      return mediaFileList?.items?.map((file: any) => {
        return {
          col1: (
            <MediaFileName
              isPropertyUser={isPropertyUser}
              onRefresh={onRefresh}
              file={file}
            />
          ),
          col2: (
            <MediaMarket
              isPropertyUser={isPropertyUser}
              onRefresh={onRefresh}
              file={file}
            />
          ),
          col3: <div>{file?.upload_date}</div>,
          col4: <div>{file?.file_size}</div>,
          col9: (
            <DropdownCustom
              direction={"left"}
              icon={null}
              trigger={
                <ButtonCustom
                  style={{ width: "38px", height: "38px" }}
                  className={"primary-alt"}
                >
                  <i className="uil uil-setting" style={{ fontSize: "21px" }} />
                </ButtonCustom>
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => window.open(file?.file_url)}
                  text={translations.downloadFile}
                  icon={
                    <img
                      src={PUBLIC_URL + "/assets/icons/icon-download-black.svg"}
                    />
                  }
                />
                {isPropertyUser ? null : (
                  <Dropdown.Item
                    onClick={() => onDeleteFile(file?.reference)}
                    text={translations.deleteFile}
                    icon={
                      <i
                        style={{ marginRight: 11, fontSize: 16 }}
                        className="uil uil-trash-alt"
                      />
                    }
                  />
                )}
              </Dropdown.Menu>
            </DropdownCustom>
          ),
        };
      });
    }

    return [];
  };

  return (
    <div>
      <TableCustom
        noHeader={false}
        loading={loading}
        showTotal
        tableData={decorateDataTable()}
        onChangeSortCol={onChangeSortCol}
        orderBy={filter?.sort_type}
        sortBy={filter?.sort_by}
        sortTable
        columns={columns}
        itemPerPage={mediaFileList?.pagination?.limit}
        currentPage={filter?.page}
        totalItems={mediaFileList?.pagination?.total}
        unit={"media file(s)"}
        onChangePagination={onChangePagination}
        topRightComponent={
          isPropertyUser ? (
            <></>
          ) : (
            <MarketFilterMultiple
              isSubmitting={loading}
              onChange={onChangeMarket}
              values={filter?.markets}
            />
          )
        }
      />
    </div>
  );
};

export default MediaFileTable;
