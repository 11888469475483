import SizeBox from "../../../../../../../components/SizeBox";
import React, { useContext, useState } from "react";
import EditActions from "./EditActions";
import { useSelector } from "react-redux";
import { propertyDetailSelector } from "../../../../../selectors/PropertySelector";
import PropertyContactEdit from "./PropertyContactEdit";
import { LocalizationContext } from "../../../../../../../locales/Translation";

const PropertyContact = ({ loading, setLoading }: any) => {
  const { translations } = useContext(LocalizationContext);

  const [editMode, setEditMode] = useState(false);
  const propertyDetail = useSelector(propertyDetailSelector);

  return (
    <div className={"section"}>
      <SizeBox height={"16px"} />
      <div
        className={
          "d-flex justify-content-between " +
          (editMode ? "" : "align-items-center")
        }
      >
        <div className={"pr-70 w-100"}>
          <div className={"section-title"}>
            {translations.propertyContactDetails}
          </div>
          <SizeBox height={"22px"} />
          {editMode ? (
            <PropertyContactEdit
              setEditMode={setEditMode}
              setLoading={setLoading}
              loading={loading}
            />
          ) : (
            <div className={"contact"}>
              <div className={"contact-item"}>
                {translations.contactName}:{" "}
                {propertyDetail?.contact_details?.name}
              </div>
              <div className={"contact-item"}>
                {translations.contactEmail}:{" "}
                {propertyDetail?.contact_details?.email}
              </div>
              <div className={"contact-item"}>
                {translations.contactPhone}:{" "}
                {propertyDetail?.contact_details?.phone}
              </div>
            </div>
          )}
        </div>

        {!editMode && (
          <EditActions editMode={editMode} setEditMode={setEditMode} />
        )}
      </div>
      <SizeBox height={"36px"} />
    </div>
  );
};

export default PropertyContact;
