import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deskimoPrivateOfficeExportSelectedSelector,
  deskimoPrivateOfficeFiltersSelector,
  deskimoPrivateOfficeListSelector,
} from "../../../../selectors/PropertySelector";
import TableCustom from "../../../../../../components/Table";
import CheckboxCustom from "../../../../../../components/Checkbox";
import { deskimoPrivateOfficeExportAction } from "../../../../actions/PropertyActions";
import Action from "../../../PropertyDetail/Components/PrivateOfficesTab/Action";
import ModalCreatePrivateOffice from "../../../PropertyDetail/Components/PrivateOfficesTab/Components/ModalCreatePrivateOffice";
import { Label as LabelSemantic } from "semantic-ui-react";

type Props = {
  loading: boolean;
  onChangeSortCol: any;
  onChangePagination: any;
  refreshList: any;
  setRefreshList: any;
  checkAll: any;
  setCheckAll: any;
};

const PrivateOfficeList = ({
  loading,
  onChangeSortCol,
  onChangePagination,
  refreshList,
  setRefreshList,
  checkAll,
  setCheckAll,
}: Props) => {
  const privateOfficeList = useSelector(deskimoPrivateOfficeListSelector);
  const privateOfficeFilters = useSelector(deskimoPrivateOfficeFiltersSelector);
  const listPrivateOfficeExport = useSelector(
    deskimoPrivateOfficeExportSelectedSelector
  );
  const dispatch = useDispatch();

  const columns = [
    {
      key: "",
      col: (
        <CheckboxCustom
          checked={checkAll}
          onChange={(e, data: any) => {
            setCheckAll(data.checked);
            if (data.checked) {
              dispatch(
                deskimoPrivateOfficeExportAction(
                  privateOfficeList.private_offices.map(
                    (office: any) => office.id
                  )
                )
              );
            } else {
              dispatch(deskimoPrivateOfficeExportAction([]));
            }
          }}
        />
      ),
    },
    { key: "name", col: "Private Office Name" },
    { key: "company", col: "Property Company" },
    { key: "workspace", col: "Workspace" },
    { key: "market", col: "Market" },
    {
      key: "area",
      col: (
        <div
          style={{ display: "flex", flexFlow: "column", alignItems: "center" }}
        >
          Office size
          <span>
            (m<sup>2</sup>)
          </span>
        </div>
      ),
    },
    { key: "capacity", col: "Capacity (people)" },
    { key: "dailyRate", col: "Daily Rate" },
    { key: "monthlyRate", col: "Monthly Rate" },
    { key: "status", col: "Status" },
    { key: null, col: "Actions" },
  ];

  const handleCheckExportPrivateOffice = (checked: any, officeId: any) => {
    if (checked) {
      const newListExport = [...listPrivateOfficeExport, officeId];
      dispatch(deskimoPrivateOfficeExportAction(newListExport));
    } else {
      const newListExport = [...listPrivateOfficeExport].filter(
        (item) => item !== officeId
      );
      dispatch(deskimoPrivateOfficeExportAction(newListExport));
    }
  };

  const decorateTableData = () => {
    if (
      privateOfficeList?.private_offices &&
      privateOfficeList?.private_offices?.length > 0
    ) {
      return privateOfficeList.private_offices.map((office: any) => {
        return {
          col1: (
            <CheckboxCustom
              onChange={(e, data) =>
                handleCheckExportPrivateOffice(data.checked, office?.id)
              }
              checked={listPrivateOfficeExport.includes(office?.id)}
            />
          ),
          col2: office?.name,
          col3: office?.company_name,
          col4: office?.property_name,
          col5: office?.market,
          col6: office?.area,
          col7: office?.capacity,
          col8: office?.daily_rate
            ? `${office?.currency} ${office?.daily_rate}`
            : "-",
          col9: office?.monthly_rate
            ? `${office?.currency} ${office?.monthly_rate}`
            : "-",
          col10: (
            <div>
              <div>{office?.status_name}</div>
              {office.status ? (
                <LabelSemantic
                  className={office?.visibility ? "blue" : ""}
                  size={"mini"}
                  style={{ marginTop: 2 }}
                >
                  {office?.visibility_name}
                </LabelSemantic>
              ) : null}
            </div>
          ),
          col11: (
            <Action
              visibilityAction={office?.visibility_action}
              visibility={office?.visibility}
              propertyId={office?.property_id}
              companyId={office?.company_id}
              id={office?.id}
              status={office?.status}
              onRefresh={() => setRefreshList(!refreshList)}
            />
          ),
        };
      });
    }

    return [];
  };

  return (
    <div>
      <TableCustom
        tableClass={"table-private-office"}
        tableLayoutFixed
        noHeader={false}
        loading={loading}
        showTotal
        tableData={decorateTableData()}
        sortTable={true}
        onChangeSortCol={onChangeSortCol}
        sortBy={privateOfficeFilters.sort_by}
        orderBy={privateOfficeFilters.order_by}
        columns={columns}
        currentPage={privateOfficeFilters?.page}
        totalItems={privateOfficeList?.pagination?.total}
        itemPerPage={privateOfficeList?.pagination?.limit}
        unit={"private offices"}
        onChangePagination={onChangePagination}
      />
      <ModalCreatePrivateOffice
        onRefresh={() => setRefreshList(!refreshList)}
      />
    </div>
  );
};

export default PrivateOfficeList;
