import React, { useState } from "react";
import FilterMarket from "../../../../../../components/FilterMarket";
import Filter from "../../../Dashboard/Filter";
import SizeBox from "../../../../../../components/SizeBox";
import ButtonCustom from "../../../../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardStatisticFilterAction,
  modalBookingDeskManualAction,
  modalBookingRoomManualAction,
  modalCreateWalkInsManualAction,
} from "../../../../actions/DashboardActions";
import { dashboardStatisticFilterSelector } from "../../../../selectors/DashboardSelectors";
import FilterListProperty from "../../../Components/FilterListProperty";

const SectionTop = () => {
  const dispatch = useDispatch();
  const dashBoardFilter = useSelector(dashboardStatisticFilterSelector);

  const setMarket = async (value: string) => {
    dispatch(dashboardStatisticFilterAction({ market: value }));
  };

  return (
    <div className={"deskimo-dashboard__section-top"}>
      <div className={"left"}>
        <FilterMarket value={dashBoardFilter.market} onChange={setMarket} />
        <SizeBox width={"8px"} />
        <Filter />
      </div>
      <div className={"right"}></div>
    </div>
  );
};

export default SectionTop;
