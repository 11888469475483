import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { Formik, FormikValues } from "formik";
import * as yup from "yup";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import ModalCenterTemplate from "../../../../../App/views/ModalCenterTemplate";
import React, { useContext, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { partnerCheckinWalkinService } from "../../../../services/DashboardServices";
import SizeBox from "../../../../../../components/SizeBox";
import InputCustom from "../../../../../../components/Input";
import { userCompanyChooseSelector } from "../../../../../User/selectors/UserSelectors";
import { ModalPartnerCreateWalkInsSelector } from "../../../../selectors/DashboardSelectors";
import { modalPartnerCreateWalkInsAction } from "../../../../actions/DashboardActions";
import { QRCodeCanvas } from "qrcode.react";
import "./style.scss";
import CopyToClipboard from "../../../../../../components/CopyToClipboard";

const ModalPartnerCreateWalkIns = () => {
  const { translations } = useContext(LocalizationContext);
  const ModalCreateWalkins = useSelector(ModalPartnerCreateWalkInsSelector);
  const dispatch = useDispatch();
  const userCompanyChoose = useSelector(userCompanyChooseSelector);
  const [qrData, setQrData] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const _onCloseModal = () => {
    setQrData(null);
    dispatch(modalPartnerCreateWalkInsAction({ open: false }));
  };

  const _onClickSave = async (values: FormikValues) => {
    try {
      const response = await partnerCheckinWalkinService(values);
      if (response?.data) {
        setQrData(response.data);
      }
    } catch (error: any) {
      toastError(error?.status?.message);
    }
  };

  const downloadQRCode = (event: any) => {
    if (!(event && event.target)) {
      return;
    }
    const canvas = event.target;
    const image = canvas.toDataURL("image/png");

    const link = document.createElement("a");
    link.href = image;
    link.download = "QRCode.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={_onCloseModal}
      modalClassName={"modal-general"}
      open={ModalCreateWalkins.open}
    >
      <Modal.Header>
        Manual Check-in
        <ButtonCustom onClick={_onCloseModal} className={"modal-close-cta"}>
          <>
            <span>{translations.close}</span>
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      {qrData ? (
        <>
          <Modal.Content>
            <div className={"qrcode-result-wrapper"}>
              <ButtonCustom className={"download-qrcode-cta primary"}>
                <>
                  Download QR Code <i className="uil uil-image-download"></i>
                </>
              </ButtonCustom>
              <QRCodeCanvas
                size={256}
                className={"qrcode-result"}
                value={qrData?.request_token}
                onClick={downloadQRCode}
              />
            </div>
            <div className={"checkin-code"}>
              <CopyToClipboard
                text={qrData?.code}
                copyText={"Copy Code To Clipboard"}
                trigger={
                  <div className={"d-flex gap-8"}>
                    <span>{qrData?.code}</span>
                    <i className="uil uil-copy"></i>
                  </div>
                }
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <ButtonCustom
              className={"primary"}
              height={"47px"}
              onClick={() => {
                _onCloseModal();
              }}
            >
              Close
            </ButtonCustom>
          </Modal.Actions>
        </>
      ) : (
        <Formik
          initialValues={{
            full_name: "",
            email: "",
          }}
          validationSchema={yup.object().shape({
            email: yup.string().email().label("Email"),
          })}
          onSubmit={_onClickSave}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <>
              <Modal.Content>
                <form className={"form-layout"}>
                  <div className={"d-flex flex-column gap-24"}>
                    <div className={"flex-1"}>
                      <InputCustom
                        label={"User Name"}
                        value={values.full_name}
                        onChange={handleChange}
                        name={"full_name"}
                        error={errors.full_name}
                        touched={touched.full_name}
                      />
                    </div>
                    <div className={"flex-1"}>
                      <InputCustom
                        label={"Email"}
                        value={values.email}
                        onChange={handleChange}
                        name={"email"}
                        error={errors.email}
                        touched={touched.email}
                      />
                    </div>
                  </div>
                  <SizeBox height={"16px"} />
                </form>
              </Modal.Content>
              <Modal.Actions>
                <ButtonCustom
                  disabled={isSubmitting}
                  height={"47px"}
                  onClick={_onCloseModal}
                >
                  {translations.cancel}
                </ButtonCustom>
                <ButtonCustom
                  disabled={!values.email}
                  loading={isSubmitting}
                  className={"primary"}
                  height={"47px"}
                  onClick={handleSubmit}
                >
                  {translations.createWalkIns}
                </ButtonCustom>
              </Modal.Actions>
            </>
          )}
        </Formik>
      )}
    </ModalCenterTemplate>
  );
};

export default ModalPartnerCreateWalkIns;
