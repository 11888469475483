import clientRequestService from "../../../services/ClientRequestService";
import {
  METHOD_DELETE,
  METHOD_GET,
  METHOD_POST,
  METHOD_PUT,
} from "../../../configs/Constants";
import {
  GET_PROPERTY_INTEGRATION_QRCODE,
  CREATE_PROPERTY_INTEGRATION_QRCODE,
  UPDATE_PROPERTY_INTEGRATION_QRCODE,
  CHANGE_STATUS_PROPERTY_INTEGRATION_QRCODE,
  DELETE_PROPERTY_INTEGRATION_QRCODE,
  GET_PROPERTY_INTEGRATION_API,
  GET_PROPERTY_INTEGRATION_WEBHOOKS,
  UPDATE_PROPERTY_INTEGRATION_WEBHOOKS,
  CHANGE_STATUS_PROPERTY_INTEGRATION_WEBHOOK,
  PROPERTY_INTEGRATION_CHECKING,
  SEND_TEST_PROPERTY_INTEGRATION_WEBHOOK,
  PARTNER_LOG_API,
} from "../configs/Endpoints";

export const propertyIntegrationsCheckingService = async (
  propertyId: string
) => {
  return await clientRequestService({
    method: METHOD_POST,
    url: PROPERTY_INTEGRATION_CHECKING.replaceAll("%propertyId%", propertyId),
  });
};

export const propertyIntegrationsQRCodeListService = async (
  propertyId: string
) => {
  return await clientRequestService({
    method: METHOD_GET,
    url: GET_PROPERTY_INTEGRATION_QRCODE.replaceAll("%propertyId%", propertyId),
    // url: "https://run.mocky.io/v3/3c903522-4dd4-4549-af57-a0f2d08cdfa4",
  });
};

export const propertyIntegrationsCreateQRCodeService = async (
  propertyId: string,
  data: object
) => {
  return await clientRequestService({
    method: METHOD_POST,
    url: CREATE_PROPERTY_INTEGRATION_QRCODE.replaceAll(
      "%propertyId%",
      propertyId
    ),
    data,
  });
};

export const propertyIntegrationsUpdateQRCodeService = async (
  propertyId: string,
  qrcodeId: string,
  data: object
) => {
  return await clientRequestService({
    method: METHOD_PUT,
    url: UPDATE_PROPERTY_INTEGRATION_QRCODE.replaceAll(
      "%propertyId%",
      propertyId
    ).replaceAll("%qrcodeId%", qrcodeId),
    data,
  });
};

export const propertyIntegrationsChangeStatusQRCodeService = async (
  propertyId: string,
  qrcodeId: string,
  data: object
) => {
  return await clientRequestService({
    method: METHOD_PUT,
    url: CHANGE_STATUS_PROPERTY_INTEGRATION_QRCODE.replaceAll(
      "%propertyId%",
      propertyId
    ).replaceAll("%qrcodeId%", qrcodeId),
    data,
  });
};

export const propertyIntegrationsRemoveQRCodeService = async (
  propertyId: string,
  qrcodeId: string
) => {
  return await clientRequestService({
    method: METHOD_DELETE,
    url: DELETE_PROPERTY_INTEGRATION_QRCODE.replaceAll(
      "%propertyId%",
      propertyId
    ).replaceAll("%qrcodeId%", qrcodeId),
  });
};

export const propertyIntegrationsAPICredentialService = async (
  propertyId: string
) => {
  return await clientRequestService({
    method: METHOD_GET,
    url: GET_PROPERTY_INTEGRATION_API.replaceAll("%propertyId%", propertyId),
    // url: "https://run.mocky.io/v3/ae31d4b4-1d8f-4493-953d-a99bfcfe5f01",
  });
};

export const propertyIntegrationsWebhookService = async (
  propertyId: string
) => {
  return await clientRequestService({
    method: METHOD_GET,
    url: GET_PROPERTY_INTEGRATION_WEBHOOKS.replaceAll(
      "%propertyId%",
      propertyId
    ),
    // url: "https://run.mocky.io/v3/95086e1b-61eb-4245-a693-db6f27f5c1a0",
  });
};

export const propertyIntegrationsWebhookUpdateURLService = async (
  propertyId: string,
  webhookId: string,
  data: object
) => {
  return await clientRequestService({
    method: METHOD_PUT,
    url: UPDATE_PROPERTY_INTEGRATION_WEBHOOKS.replaceAll(
      "%propertyId%",
      propertyId
    ).replaceAll("%webhookId%", webhookId),
    data,
  });
};

export const propertyIntegrationsWebhookStatusService = async (
  propertyId: string,
  webhookId: string,
  data: object
) => {
  return await clientRequestService({
    method: METHOD_PUT,
    url: CHANGE_STATUS_PROPERTY_INTEGRATION_WEBHOOK.replaceAll(
      "%propertyId%",
      propertyId
    ).replaceAll("%webhookId%", webhookId),
    data,
  });
};

export const propertyIntegrationsWebhookSendTestService = async (
  propertyId: string,
  webhookId: string
) => {
  return await clientRequestService({
    method: METHOD_POST,
    url: SEND_TEST_PROPERTY_INTEGRATION_WEBHOOK.replaceAll(
      "%propertyId%",
      propertyId
    ).replaceAll("%webhookId%", webhookId),
  });
};

export const partnerLogService = async (data: object) => {
  return await clientRequestService({
    isCompany: true,
    method: METHOD_POST,
    url: PARTNER_LOG_API,
    data,
  });
};
