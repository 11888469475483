import SearchDeskimoPropertiesAmenity from "./Components/SearchDeskimoPropertiesAmenity";
import { useDispatch } from "react-redux";
import DeskimoPropertiesAmenityListTable from "./Components/DeskimoPropertiesAmenityListTable/DeskimoPropertiesAmenityListTable";
import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { getDeskimoPropertyAmenityListThunk } from "../../thunks/PropertyThunks";
import ModalEditAddAmenity from "./Components/ModalEditAddAmenity";
import ButtonCustom from "../../../../components/Button";
import {
  deskimoPropertyAmenityDetailAction,
  modalAddEditAmenityAction,
} from "../../actions/PropertyActions";
import { LocalizationContext } from "../../../../locales/Translation";
import "./style.scss";
import { AMENITY_DESK_TYPE, AMENITY_ROOM_TYPE } from "../../configs/Constants";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../App/configs/Constants";

const FILTER_DEFAULT = {
  keyword: "",
  page: 1,
  types: [AMENITY_DESK_TYPE, AMENITY_ROOM_TYPE],
  sort_by: "",
  order_by: "",
  get_all: true,
};

const DeskimoPropertiesAmenities = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [amenityListFilter, setAmenityListFilter] =
    useState<any>(FILTER_DEFAULT);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    (async () => await _getPropertyAmenitiesList())();
  }, [amenityListFilter, refreshing]);

  const _getPropertyAmenitiesList = async () => {
    try {
      setLoading(true);
      await dispatch<any>(
        getDeskimoPropertyAmenityListThunk(amenityListFilter)
      );
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    setAmenityListFilter({
      ...amenityListFilter,
      page: activePage,
    });
  };

  const _onSearch = (value: string) => {
    setAmenityListFilter({ keyword: value, page: 1 });
  };

  const _onChangeSortCol = (col: any) => {
    if (amenityListFilter.sort_by !== col.key) {
      setAmenityListFilter((prevState: any) => {
        return { ...prevState, sort_by: col.key, order_by: ORDER_BY_ASC };
      });
    } else {
      if (amenityListFilter.order_by === ORDER_BY_NONE) {
        setAmenityListFilter((prevState: any) => {
          return { ...prevState, sort_by: col.key, order_by: ORDER_BY_ASC };
        });
      }
      if (amenityListFilter.order_by === ORDER_BY_DESC) {
        setAmenityListFilter((prevState: any) => {
          return { ...prevState, sort_by: col.key, order_by: ORDER_BY_NONE };
        });
      }
      if (amenityListFilter.order_by === ORDER_BY_ASC) {
        setAmenityListFilter((prevState: any) => {
          return { ...prevState, sort_by: col.key, order_by: ORDER_BY_DESC };
        });
      }
    }
  };

  return (
    <>
      <div className="amenity-main-pane">
        <SearchDeskimoPropertiesAmenity onSearch={_onSearch} />
        <div className={"d-flex"}>
          <ButtonCustom
            className={"primary"}
            style={{ width: "max-content", height: "36px" }}
            onClick={() => {
              dispatch(modalAddEditAmenityAction(true));
              dispatch(deskimoPropertyAmenityDetailAction({}));
            }}
          >
            {`${translations.propertyAmenities.addAmenityButtonLabel} +`}
          </ButtonCustom>
        </div>
      </div>

      <DeskimoPropertiesAmenityListTable
        loading={loading}
        onChangePagination={_onChangePagination}
        onChangeSortCol={_onChangeSortCol}
        filter={amenityListFilter}
      />
      <ModalEditAddAmenity
        filter={amenityListFilter}
        setRefreshing={setRefreshing}
      />
    </>
  );
};

export default DeskimoPropertiesAmenities;
