import { Dimmer, Loader, Segment } from "semantic-ui-react";
import "./style.scss";

type Props = {
  fullScreen?: boolean;
};
const LoaderCustom = ({ fullScreen }: Props) => {
  return (
    <Segment
      className={"loader-wrapper"}
      style={fullScreen && { minHeight: "100vh" }}
    >
      <Dimmer active>
        <Loader />
      </Dimmer>
    </Segment>
  );
};

export default LoaderCustom;
