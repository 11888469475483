const MarketingIcon = ({
  strokeColor = "#21252A",
  isActive = false,
}: {
  strokeColor?: string;
  isActive: boolean;
}) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11541_53245)">
        <path
          d="M17.8965 2.6256C17.6816 2.48658 17.4358 2.40247 17.1808 2.38068C16.9258 2.35888 16.6694 2.40006 16.434 2.5006L8.72148 5.5381C8.53889 5.61241 8.34362 5.65062 8.14648 5.6506H3.20898C2.79458 5.6506 2.39716 5.81522 2.10413 6.10825C1.8111 6.40127 1.64648 6.7987 1.64648 7.2131V7.3381H0.0839844V11.0881H1.64648V11.2506C1.65628 11.6585 1.82521 12.0463 2.11718 12.3313C2.40915 12.6163 2.801 12.7757 3.20898 12.7756L5.08398 16.7506C5.21095 17.0183 5.41083 17.2447 5.66069 17.4039C5.91054 17.5631 6.20023 17.6486 6.49648 17.6506H7.28398C7.69622 17.6473 8.09044 17.4812 8.38077 17.1886C8.67109 16.8959 8.834 16.5003 8.83398 16.0881V12.9256L16.434 15.9631C16.6209 16.0375 16.8203 16.0757 17.0215 16.0756C17.3336 16.0706 17.6376 15.975 17.8965 15.8006C18.1021 15.6618 18.2717 15.476 18.3912 15.2586C18.5108 15.0413 18.5768 14.7986 18.584 14.5506V3.9131C18.5828 3.65875 18.5196 3.40852 18.3998 3.18414C18.28 2.95977 18.1072 2.76804 17.8965 2.6256ZM7.27148 7.2131V11.2506H3.20898V7.2131H7.27148ZM7.27148 16.0881H6.48398L4.94648 12.7756H7.27148V16.0881ZM9.29648 11.4381C9.14807 11.3623 8.99332 11.2995 8.83398 11.2506V7.1256C8.99179 7.09304 9.14652 7.04704 9.29648 6.9881L17.0215 3.9131V14.5131L9.29648 11.4381ZM18.6215 7.6506V10.7756C19.0359 10.7756 19.4333 10.611 19.7263 10.318C20.0194 10.0249 20.184 9.6275 20.184 9.2131C20.184 8.7987 20.0194 8.40127 19.7263 8.10825C19.4333 7.81522 19.0359 7.6506 18.6215 7.6506Z"
          fill={isActive ? "#003DBC" : strokeColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_11541_53245">
          <rect
            width="20"
            height="20"
            fill={isActive ? "#003DBC" : strokeColor}
            transform="translate(0.0839844)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MarketingIcon;
