import React from "react";
import SizeBox from "../../../../../../../components/SizeBox";
import RatingCell from "../../UserReviewTab/RatingCell";

type Props = {
  propertyDetail: any;
};
const PropertyUserReview = ({ propertyDetail }: Props) => {
  return (
    <div className={"property-user-rate"}>
      <div className={"property-rate fw-500"}>Average Rating</div>
      <SizeBox height={16} />
      <div className={"property-rating"}>
        <span className={"rate"}>{propertyDetail?.avg_rate?.toFixed(2)}</span>
        <span className={"review"}>
          ({propertyDetail?.review_amount} reviews)
        </span>
      </div>
      <RatingCell
        stars={Math.round(propertyDetail?.avg_rate || 0)}
        iconPath={"/assets/icons/icon-star-yellow.svg"}
      />
    </div>
  );
};

export default PropertyUserReview;
