import React from "react";
import { Checkbox, CheckboxProps } from "semantic-ui-react";
import "./style.scss";
import classNames from "classnames";

interface Props extends CheckboxProps {
  content?: any;
  value?: any;
  onChange?: any;
  fontSize?: any;
  disabled?: any;
  contentHtml?: boolean;
  [x: string]: any;
}

const BoxToggleSlider = ({
  content,
  value,
  onChange,
  fontSize,
  disabled,
  contentHtml,
  ...props
}: Props) => {
  return (
    <div className={classNames("box-toggle-slider", { disabled: disabled })}>
      {contentHtml ? (
        content
      ) : (
        <span className={"content"} style={{ fontSize: fontSize }}>
          {content}
        </span>
      )}
      <Checkbox {...props} toggle checked={value} onChange={onChange} />
    </div>
  );
};

export default BoxToggleSlider;
