import MasterTemplate from "../../../App/views/MasterTemplate";
import EmployeeTable from "./Components/EmployeeTable";
import React, { useContext } from "react";
import ButtonCustom from "../../../../components/Button";
import ModalEditEmployee from "./Components/ModalEditEmployee";
import FilterEmployee from "./Components/EmployeeTable/FilterEmployee";
import SearchEmployee from "../EmployeeBusiness/Components/EmployeeTable/SearchEmployee";
import "../../styles/employee.scss";
import ModalDetailEmployee from "./Components/ModalDetailEmployee";
import { useDispatch } from "react-redux";
import { modalAddEmployeeAction } from "../../../App/actions/AppActions";
import AddEmployeePropertyModal from "./Components/ModalAddEmployeeProperty";
import { ROLE_PROPERTY_ADMIN_ID } from "../../configs/Constants";
import { LocalizationContext } from "../../../../locales/Translation";

const EmployeeList = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  return (
    <MasterTemplate roleAccept={[ROLE_PROPERTY_ADMIN_ID]}>
      <div className={"filter-section filter-section-employee"}>
        <SearchEmployee />
        <div className={"dropdown-filter d-flex"}>
          <ButtonCustom
            onClick={() => dispatch(modalAddEmployeeAction(true))}
            height={"36px"}
            fontSize={"12px"}
            className={"primary text-nowrap mr-8px"}
          >
            <>{translations.addUser} +</>
          </ButtonCustom>
          <FilterEmployee />
        </div>
      </div>

      <EmployeeTable />

      <ModalEditEmployee />
      <ModalDetailEmployee />
      <AddEmployeePropertyModal />
    </MasterTemplate>
  );
};

export default EmployeeList;
