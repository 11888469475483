import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../components/Button";
import { modalMyProfileAction } from "../../../actions/AppActions";
import { LANGUAGES, PUBLIC_URL } from "../../../configs/Constants";
import { Formik } from "formik";
import * as yup from "yup";
import Label from "../../../../../components/Label";
import SizeBox from "../../../../../components/SizeBox";
import InputCustom from "../../../../../components/Input";
import React, { useContext, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myProfileSelector } from "../../../selectors/AppSelectors";
import DropdownCustom from "../../../../../components/DropdownCustom";
import { toastError, toastSuccess } from "../../../../../components/Toast";
import { updateMyProfileService } from "../../../services/AppServices";
import { getMyProfileThunk } from "../../../thunks/AppThunk";
import { APP_LANGUAGE_CACHE } from "../../../../../configs/Constants";
import { LocalizationContext } from "../../../../../locales/Translation";
import ModalCropImage from "../../ModalCropImage";
import { fileValidation } from "../../../../../utils/commons";

type Props = {
  setEditMode?: any;
};

const Edit = ({ setEditMode }: Props) => {
  const { translations } = useContext(LocalizationContext);

  const dispatch = useDispatch();
  const myProfile = useSelector(myProfileSelector);
  const uploadFileRef = useRef<any>();
  const [previewImage, setPreviewImage] = useState(null);
  const translation = useContext(LocalizationContext);
  const [loading, setLoading] = useState(false);
  const [openModalCrop, setOpenModalCrop] = useState(false);
  const [imgSrc, setImgSrc] = useState(myProfile?.avatar);

  const PreviewImage = ({ uri }: any) => {
    return <img src={uri} />;
  };

  const _handleSave = async (values: any) => {
    setLoading(true);
    try {
      await updateMyProfileService(values);
      await dispatch<any>(getMyProfileThunk());
      translation.setAppLanguage(values.language);
      localStorage.setItem(APP_LANGUAGE_CACHE, values.language);
      toastSuccess("User profile successfully updated");
      setEditMode(false);
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUploadFile = async (e: any) => {
    const reader: any = new FileReader();
    reader.addEventListener("load", () =>
      setImgSrc(reader.result.toString() || "")
    );
    reader.readAsDataURL(e.target.files[0]);
    setOpenModalCrop(true);
  };

  return (
    <Formik
      initialValues={{
        avatar_uri: myProfile?.avatar,
        first_name: myProfile?.first_name,
        last_name: myProfile?.last_name,
        language: myProfile?.current_lang,
      }}
      validationSchema={yup.object().shape({
        first_name: yup.string().required().label("First Name"),
        language: yup.string().required().label("Language"),
      })}
      onSubmit={_handleSave}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <>
          <Modal.Header>
            <ButtonCustom
              onClick={() => dispatch(modalMyProfileAction(false))}
              className={"m-0 p-0 none-bg close-modal"}
            >
              <>
                {translations.close}
                <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
              </>
            </ButtonCustom>
          </Modal.Header>
          <Modal.Content className={"scroll"}>
            <form className={"form-layout"}>
              <div>
                <Label
                  className={"sm"}
                  label={translations.profilePicture}
                  labelGuide={translations.uploadImageFormat}
                />
                <div className={"d-flex align-items-center mb-48px"}>
                  <div className={"my-profile__avatar"}>
                    {previewImage ? (
                      <PreviewImage uri={previewImage} />
                    ) : (
                      <img
                        src={
                          values?.avatar_uri
                            ? values?.avatar_uri
                            : PUBLIC_URL + "/assets/default-add-avatar.svg"
                        }
                      />
                    )}

                    <input
                      className={"d-none"}
                      ref={uploadFileRef}
                      type="file"
                      accept="image/*"
                      onChange={async (event: any) => {
                        const isFileValid = fileValidation(event.target.files);

                        if (isFileValid) {
                          const _URL = window.URL || window.webkitURL;
                          const img = new Image();
                          const objectUrl = _URL.createObjectURL(
                            event.target.files[0]
                          );

                          img.src = objectUrl;
                          img.onload = async () => {
                            _URL.revokeObjectURL(objectUrl);
                            await handleUploadFile(event);
                            event.target.value = null;
                          };
                          setOpenModalCrop(true);
                        } else {
                          toastError("The file must be smaller than 2MB");
                        }
                      }}
                    />
                  </div>

                  <SizeBox width={"32px"} />
                  <ButtonCustom
                    onClick={(e: any) => {
                      e.preventDefault();
                      uploadFileRef.current.click();
                    }}
                    className={"primary-alt"}
                    height={"36px"}
                  >
                    <>
                      {translations.uploadImage}
                      <SizeBox width={"14px"} />
                      <i className="uil uil-upload-alt" />
                    </>
                  </ButtonCustom>
                </div>
              </div>
              <SizeBox height={"36px"} />
              <div className={"form-row"}>
                <InputCustom
                  labelRequired={true}
                  label={translations.firstName}
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.first_name}
                  error={errors.first_name}
                  name={"first_name"}
                />
                <InputCustom
                  labelRequired={true}
                  label={translations.lastName}
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.last_name}
                  error={errors.last_name}
                  name={"last_name"}
                />
              </div>
              <Label
                label={translations.language}
                size={"12px"}
                fontSize={"12px"}
                className={"sm"}
              />
              <DropdownCustom
                value={values.language}
                style={{ height: 44, borderRadius: 4 }}
                className={"w-100"}
                selectUI={true}
                options={LANGUAGES}
                onChange={(e, data: any) => {
                  setFieldValue("language", data.value);
                }}
              />
              <div className={"mb-16px"} />
              <SizeBox height={"7px"} />
            </form>
          </Modal.Content>
          <Modal.Actions>
            <ButtonCustom
              height={"47px"}
              onClick={() => {
                setEditMode(false);
                dispatch(modalMyProfileAction(false));
              }}
            >
              {translations.cancel}
            </ButtonCustom>
            <ButtonCustom
              loading={loading}
              className={"primary"}
              height={"47px"}
              onClick={handleSubmit}
            >
              {translations.save}
            </ButtonCustom>
          </Modal.Actions>

          <ModalCropImage
            imgSrc={imgSrc}
            open={openModalCrop}
            setOpen={setOpenModalCrop}
            setFieldValue={setFieldValue}
            fieldKey={"avatar_uri"}
            setPreviewImage={setPreviewImage}
          />
        </>
      )}
    </Formik>
  );
};

export default Edit;
