export const ACCESS_TOKEN_CACHE = "ACCESS_TOKEN_CACHE";
export const APP_LANGUAGE_CACHE = "APP_LANGUAGE_CACHE";
export const CURRENT_COMPANY_ID = "CURRENT_COMPANY_ID";
export const PARTNER_MODE_CACHE = "PARTNER_MODE_CACHE";
export const CURRENT_PROPERTY_ID = "CURRENT_PROPERTY_ID";
export const DASHBOARD_FILTER_TYPE = "DASHBOARD_FILTER_TYPE";
export const GOOGLE_TOKEN_CACHE = "GOOGLE_TOKEN_CACHE";
export const FAKING_CODE_CACHE = "FAKING_CODE_CACHE";
export const USERS_FILTER_MARKET = "USERS_FILTER_MARKET";
export const USERS_FILTER_COMPANY = "USERS_FILTER_COMPANY";
export const LAST_CHECK_LOGIN_WITH_ENTITLEMENT_ID =
  "LAST_CHECK_LOGIN_WITH_ENTITLEMENT_ID";
export const LAST_CHECK_LOGIN_WITH_ENTITLEMENT_ID_DURATION = 21600000; //milisecond
export const EXPIRE_DATE_TOPUP_POPUP = "EXPIRE_DATE_TOPUP_POPUP";
export const LOGIN_TIMES = "LOGIN_TIMES";

// Constant Request
export const METHOD_GET = "get";
export const METHOD_POST = "post";
export const METHOD_PUT = "put";
export const METHOD_PATCH = "patch";
export const METHOD_DELETE = "delete";
export const REQUEST_TIME_OUT = 30000; // <-- ms
export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const INTERNAL_SERVER_ERROR = 500;
