import clientRequestService from "../../../services/ClientRequestService";
import { METHOD_POST } from "../../../configs/Constants";
import {
  API_CHANGE_TO_PAYG,
  API_CONTRACTS_PAYMENT,
  API_CREATE_BOOKING_FOR_PARTNER,
  API_DASHBOARD_CHECK_USAGE,
  API_DASHBOARD_STATISTIC,
  API_DASHBOARD_STATISTIC_PROPERTY,
  API_DELETE_VISIT,
  API_DESK_BOOKING,
  API_DESK_BOOKING_PROPERTY,
  API_DESK_BOOKING_STATUS,
  API_DESK_BOOKING_STATUS_PROPERTY,
  API_DESK_USAGE,
  API_DESK_USAGE_PROPERTY,
  API_DESK_USAGE_STATUS,
  API_DESK_USAGE_STATUS_PROPERTY,
  API_FETCH_PENDING_INVITE,
  API_GET_ACTIVITY_TRACKING,
  API_GET_DECLINE_REASONS,
  API_GET_FREE_CREDIT,
  API_GET_LIST_MEETING_ROOM_FOR_PARTNER,
  API_GET_LIST_PROPERTIES_FOR_PARTNER,
  API_GET_PROPERTY_FUTURE_CLOSURES,
  API_HANDLE_SCANNER,
  API_LEASE_SEND_RECEIPT,
  API_LIST_PROPERTY_AUTOCOMPLETE,
  API_MANUAL_BOOKING_GET_LIST_DESK,
  API_MANUAL_BOOKING_GET_LIST_PAYMENT,
  API_MANUAL_BOOKING_GET_LIST_ROOM,
  API_MANUAL_BOOKING_GET_LIST_USER,
  API_MANUAL_BOOKING_SUBMIT_DESK,
  API_MANUAL_BOOKING_SUBMIT_ROOM,
  API_MANUAL_BOOKING_SUBMIT_WALK_INS,
  API_MANUAL_CHECK_IN,
  API_MANUAL_CHECK_OUT,
  API_ONBOARDING_PROMOTION,
  API_PARTNER_CHECKIN_MANUALLY,
  API_PARTNER_CHECKOUT_MANUALLY,
  API_PARTNER_CREATE_MEETING_ROOM_BOOKING,
  API_PARTNER_HOT_DESK_BOOKING_STATUS,
  API_PARTNER_MANUAL_CHECK_IN,
  API_PARTNER_MEETING_ROOM_BOOKING_STATUS,
  API_PAY_NOW_LEASE_PAYMENT,
  API_PAY_WITH_CREDIT,
  API_PENDING_INVITE_ACCEPT_ACTION,
  API_PENDING_INVITE_REJECT_ACTION,
  API_PROPERTY_BY_COMPANY,
  API_PROPERTY_FUTURE_CLOSURES,
  API_PROPERTY_PENDING_BOOKINGS,
  API_PROPERTY_PROMOTION_DETAIL,
  API_PROPERTY_PROMOTION_SET_UP,
  API_PROPERTY_STATUS,
  API_REPAY,
  API_ROOM_BOOKING,
  API_ROOM_BOOKING_PROPERTY,
  API_ROOM_BOOKING_STATUS,
  API_ROOM_BOOKING_STATUS_PROPERTY,
  API_STATISTIC_ON_GOING,
  API_TRANSACTION_INFO,
} from "../configs/Enpoints";
import moment from "moment-timezone";
import {
  BUSINESS,
  DESK_USAGE_AWAITING_PAYMENT_STATUS,
  DESK_USAGE_COMPLETE_STATUS,
  DESK_USAGE_ON_GOING_STATUS,
  FILTER_DATE_CUSTOM,
  FILTER_DATE_LAST_14_DAYS,
  FILTER_DATE_LAST_30_DAYS,
  FILTER_DATE_LAST_7_DAYS,
  FILTER_DATE_LAST_90_DAYS,
  FILTER_DATE_LAST_MONTH,
  FILTER_DATE_LAST_WEEK,
  FILTER_DATE_THIS_MONTH,
  FILTER_DATE_THIS_WEEK,
  FILTER_DATE_TODAY,
  FILTER_DATE_YESTERDAY,
  PROPERTY,
  ROOM_BOOKING_ALL_STATUS,
  ROOM_BOOKING_CANCELLED_STATUS,
  ROOM_BOOKING_COMPLETED_STATUS,
  ROOM_BOOKING_CONFIRMED_STATUS,
  ROOM_BOOKING_DECLINED_STATUS,
  ROOM_BOOKING_NOT_REFUNDED_STATUS,
  ROOM_BOOKING_PENDING_PAYMENT_STATUS,
  ROOM_BOOKING_PENDING_REFUNDED_STATUS,
  ROOM_BOOKING_PENDING_STATUS,
  ROOM_BOOKING_REFUNDED_STATUS,
} from "../configs/Constants";
import {
  DeskUsageStatusType,
  RoomBookingStatusType,
} from "../configs/DeclareTypes";
import { store } from "../../App/configs/Store";
import { Dispatch, SetStateAction } from "react";
import { SWAL_CONFIRM_OPTION } from "../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../components/SweetAlert";
import { isGlobalIndicatorAction } from "../../App/actions/AppActions";
import { toastError, toastSuccess } from "../../../components/Toast";

export const dashboardStatisticService = (
  data:
    | {
        start_time: number;
        end_time: number;
        timezone: string;
        market?: string;
      }
    | object,
  deskimoAdmin?: boolean
) => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_DASHBOARD_STATISTIC,
    data,
    isCompany: !deskimoAdmin,
  });
};

export const dashboardStatisticPropertyService = (
  data:
    | {
        start_time: number;
        end_time: number;
      }
    | object
) => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_DASHBOARD_STATISTIC_PROPERTY,
    data,
    isCompany: true,
  });
};

export const deskUsageService = (data: object) => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_DESK_USAGE,
    isCompany: true,
    data,
  });
};

export const deskUsagePropertyService = (
  data: object,
  deskimoAdmin?: boolean
) => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_DESK_USAGE_PROPERTY,
    isCompany: !deskimoAdmin,
    data,
  });
};

export const deskBookingService = (data: object) => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_DESK_BOOKING,
    isCompany: true,
    data,
  });
};

export const deskBookingPropertyService = (
  data: object,
  deskimoAdmin?: boolean
) => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_DESK_BOOKING_PROPERTY,
    isCompany: !deskimoAdmin,
    data,
  });
};

export const roomBookingService = (data: object) => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_ROOM_BOOKING,
    isCompany: true,
    data,
  });
};

export const roomBookingPropertyService = (
  data: object,
  deskimoAdmin?: boolean
) => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_ROOM_BOOKING_PROPERTY,
    isCompany: !deskimoAdmin,
    data,
  });
};

export const deskUsageStatusService = (data: object) => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_DESK_USAGE_STATUS,
    isCompany: true,
    data,
  });
};

export const deskUsageStatusPropertyService = (data: object) => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_DESK_USAGE_STATUS_PROPERTY,
    isCompany: true,
    data,
  });
};

export const deskBookingStatusService = (data: object) => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_DESK_BOOKING_STATUS,
    isCompany: true,
    data,
  });
};

export const deskBookingPropertyStatusService = (
  data: object,
  deskimoAdmin?: boolean
) => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_DESK_BOOKING_STATUS_PROPERTY,
    isCompany: !deskimoAdmin,
    data,
  });
};

export const roomBookingStatusService = (data: object) => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_ROOM_BOOKING_STATUS,
    isCompany: true,
    data,
  });
};

export const roomBookingPropertyStatusService = (
  data: object,
  deskimoAdmin?: boolean
) => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_ROOM_BOOKING_STATUS_PROPERTY,
    isCompany: !deskimoAdmin,
    data,
  });
};

export const onChangeFilterDateTypeService = (
  value: string | number | true | (string | number | boolean)[],
  timezone = moment.tz.guess()
) => {
  const momentStartJs = moment
    .tz(timezone)
    .set({ hour: 0, minute: 0, second: 0 })
    .utc();
  const momentEndJs = moment
    .tz(timezone)
    .set({ hour: 23, minute: 59, second: 59 })
    .utc();

  const momentTimezone = moment.tz(timezone);

  let dateRange:
    | { start_time: number; end_time: number; timezone: string }
    | object = {};

  switch (value) {
    case FILTER_DATE_TODAY:
      dateRange = {
        start_time: momentStartJs.unix(),
        end_time: momentEndJs.unix(),
        timezone,
      };
      break;
    case FILTER_DATE_YESTERDAY:
      dateRange = {
        start_time: momentStartJs.subtract(1, "day").unix(),
        end_time: momentEndJs.subtract(1, "day").unix(),
        timezone,
      };
      break;
    case FILTER_DATE_THIS_WEEK:
      dateRange = {
        start_time: momentTimezone.clone().startOf("isoWeek").utc().unix(),
        end_time: momentTimezone.clone().endOf("isoWeek").utc().unix(),
        timezone,
      };
      break;
    case FILTER_DATE_THIS_MONTH:
      dateRange = {
        start_time: momentTimezone.clone().startOf("month").utc().unix(),
        end_time: momentTimezone.clone().endOf("month").utc().unix(),
        timezone,
      };
      break;
    case FILTER_DATE_LAST_WEEK:
      dateRange = {
        start_time: momentTimezone
          .clone()
          .subtract(1, "week")
          .startOf("isoWeek")
          .utc()
          .unix(),
        end_time: momentTimezone
          .clone()
          .subtract(1, "week")
          .endOf("isoWeek")
          .utc()
          .unix(),
        timezone: timezone,
      };
      break;
    case FILTER_DATE_LAST_MONTH:
      dateRange = {
        start_time: momentTimezone
          .clone()
          .subtract(1, "month")
          .startOf("month")
          .utc()
          .unix(),
        end_time: momentTimezone
          .clone()
          .subtract(1, "month")
          .endOf("month")
          .utc()
          .unix(),
        timezone: timezone,
      };
      break;
    case FILTER_DATE_LAST_14_DAYS:
      dateRange = {
        start_time: momentStartJs.subtract(14, "day").unix(),
        end_time: momentEndJs.unix(),
        timezone,
      };
      break;
    case FILTER_DATE_LAST_7_DAYS:
      dateRange = {
        start_time: moment().utc().subtract(168, "hours").unix(),
        end_time: momentEndJs.unix(),
        timezone,
      };
      break;
    case FILTER_DATE_LAST_30_DAYS:
      dateRange = {
        start_time: momentStartJs.subtract(30, "day").unix(),
        end_time: momentEndJs.unix(),
        timezone,
      };
      break;
    case FILTER_DATE_LAST_90_DAYS:
      dateRange = {
        start_time: momentStartJs.subtract(90, "day").unix(),
        end_time: momentEndJs.unix(),
        timezone,
      };
      break;
    case FILTER_DATE_CUSTOM:
      // defaultValue from last month to today
      dateRange = {
        start_time: momentStartJs.subtract(30, "day").unix(),
        end_time: momentEndJs.unix(),
        timezone,
      };
      break;
    default:
      // FILTER ALL TIME
      dateRange = {};
  }
  return dateRange;
};

export const createDeskUsageStatusFilterService = async ({
  translations,
  setStatus,
  type,
  propertyContext = false,
  productType,
}: {
  translations: any;
  setStatus: Dispatch<SetStateAction<{ id: number; name: string }[]>>;
  type: typeof BUSINESS | typeof PROPERTY;
  propertyContext?: boolean;
  productType?: number;
}) => {
  const dateRange = store.getState().DashboardReducer.filterDateRange;
  const filterPropertyList =
    store.getState().DashboardReducer.filterListPropertySelected;
  if (!dateRange) {
    return;
  }

  const dashboardDeskUsageFilter =
    store.getState().DashboardReducer.dashboardDeskUsageFilter;

  const payload = {
    ...dashboardDeskUsageFilter,
    properties: filterPropertyList || [],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...dateRange,
  };

  if (propertyContext) {
    const dashboardPropertyChoose: any =
      store.getState().DashboardReducer.dashboardPropertyChoose;
    payload.property_id = dashboardPropertyChoose?.property_id;
  }

  if (productType) {
    payload.product = productType;
  }

  let data;

  if (type === BUSINESS) {
    const res: { data: DeskUsageStatusType[] } = await deskUsageStatusService(
      payload
    );
    data = res.data;
  } else if (type === PROPERTY) {
    const res: { data: DeskUsageStatusType[] } =
      await deskUsageStatusPropertyService(payload);
    data = res.data;
  }

  if (!data) {
    return;
  }

  const deskUsageFilterTemp = [
    {
      id: DESK_USAGE_ON_GOING_STATUS,
      name: `${
        translations.deskBookingStatus[DESK_USAGE_ON_GOING_STATUS]
      } (${getDeskUsageStatusTotalUtil(data, DESK_USAGE_ON_GOING_STATUS)})`,
    },
    {
      id: DESK_USAGE_COMPLETE_STATUS,
      name: `${
        translations.deskBookingStatus[DESK_USAGE_COMPLETE_STATUS]
      } (${getDeskUsageStatusTotalUtil(data, DESK_USAGE_COMPLETE_STATUS)})`,
    },
    // {
    //   id: DESK_USAGE_AWAITING_PAYMENT_STATUS,
    //   name: `${
    //     translations.deskBookingStatus[DESK_USAGE_AWAITING_PAYMENT_STATUS]
    //   } (${getDeskUsageStatusTotalUtil(
    //     data,
    //     DESK_USAGE_AWAITING_PAYMENT_STATUS
    //   )})`,
    // },
  ];

  setStatus(deskUsageFilterTemp);
};

const getDeskUsageStatusTotalUtil = (
  data: DeskUsageStatusType[],
  statusId: number
) => {
  return data.find(({ status_id }) => Number(status_id) === statusId)?.total;
};

const getDeskBookingStatusTotalUtil = (
  data: DeskUsageStatusType[],
  statusId: number
) => {
  return data.find(({ status_id }) => Number(status_id) === statusId)?.total;
};

export const createDeskBookingStatusFilterService = async ({
  translations,
  setStatus,
  type,
  propertyContext = false,
  deskimoAdmin,
  productType,
}: {
  translations: any;
  setStatus: Dispatch<SetStateAction<{ id: string; name: string }[]>>;
  type: typeof BUSINESS | typeof PROPERTY;
  propertyContext?: boolean;
  deskimoAdmin?: boolean;
  productType?: number;
}) => {
  const dateRange = store.getState().DashboardReducer.filterDateRange;
  const filterPropertyList =
    store.getState().DashboardReducer.filterListPropertySelected;
  if (!dateRange) {
    return;
  }

  const dashboardDeskBookingFilter = deskimoAdmin
    ? store.getState().DashboardReducer.dashboardDeskimoDeskBookingFilter
    : store.getState().DashboardReducer.dashboardDeskBookingFilter;

  const payload = {
    ...dashboardDeskBookingFilter,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...dateRange,
    properties: filterPropertyList || [],
  };

  if (propertyContext) {
    const dashboardPropertyChoose: any =
      store.getState().DashboardReducer.dashboardPropertyChoose;
    payload.property_id = dashboardPropertyChoose?.property_id;
  }

  if (productType) {
    payload.product = productType;
  }

  let data;

  if (type === BUSINESS) {
    const res: { data: RoomBookingStatusType[] } =
      await deskBookingStatusService(payload);
    data = res.data;
  } else if (type === PROPERTY) {
    const res: { data: RoomBookingStatusType[] } =
      await deskBookingPropertyStatusService(payload, deskimoAdmin);
    data = res.data;
  }

  if (!data) {
    return;
  }

  let deskBookingFilterTemp: any = [];

  if (deskimoAdmin) {
    deskBookingFilterTemp = [
      {
        id: ROOM_BOOKING_ALL_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_ALL_STATUS]
        } (${getRoomBookingStatusTotalUtil(data, ROOM_BOOKING_ALL_STATUS)})`,
      },
      {
        id: ROOM_BOOKING_CONFIRMED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_CONFIRMED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_CONFIRMED_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_PENDING_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_PENDING_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_PENDING_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_DECLINED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_DECLINED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_DECLINED_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_CANCELLED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_CANCELLED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_CANCELLED_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_COMPLETED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_COMPLETED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_COMPLETED_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_PENDING_PAYMENT_STATUS,
        name: `${
          translations.roomBookingStatusFilter[
            ROOM_BOOKING_PENDING_PAYMENT_STATUS
          ]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_PENDING_PAYMENT_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_REFUNDED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_REFUNDED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_REFUNDED_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_NOT_REFUNDED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_NOT_REFUNDED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_NOT_REFUNDED_STATUS
        )})`,
      },
    ];
  } else if (type === BUSINESS) {
    deskBookingFilterTemp = [
      {
        id: ROOM_BOOKING_ALL_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_ALL_STATUS]
        } (${getRoomBookingStatusTotalUtil(data, ROOM_BOOKING_ALL_STATUS)})`,
      },
      {
        id: ROOM_BOOKING_CONFIRMED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_CONFIRMED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_CONFIRMED_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_PENDING_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_PENDING_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_PENDING_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_CANCELLED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_CANCELLED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_CANCELLED_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_COMPLETED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_COMPLETED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_COMPLETED_STATUS
        )})`,
      },
      // {
      //   id: ROOM_BOOKING_PENDING_PAYMENT_STATUS,
      //   name: `${
      //     translations.roomBookingStatusFilter[
      //       ROOM_BOOKING_PENDING_PAYMENT_STATUS
      //     ]
      //   } (${getRoomBookingStatusTotalUtil(
      //     data,
      //     ROOM_BOOKING_PENDING_PAYMENT_STATUS
      //   )})`,
      // },
      // {
      //   id: ROOM_BOOKING_PENDING_REFUNDED_STATUS,
      //   name: `${
      //     translations.roomBookingStatusFilter[
      //       ROOM_BOOKING_PENDING_REFUNDED_STATUS
      //     ]
      //   } (${getRoomBookingStatusTotalUtil(
      //     data,
      //     ROOM_BOOKING_PENDING_REFUNDED_STATUS
      //   )})`,
      // },
      // {
      //   id: ROOM_BOOKING_REFUNDED_STATUS,
      //   name: `${
      //     translations.roomBookingStatusFilter[ROOM_BOOKING_REFUNDED_STATUS]
      //   } (${getRoomBookingStatusTotalUtil(data, ROOM_BOOKING_REFUNDED_STATUS)})`,
      // },
      // {
      //   id: ROOM_BOOKING_NOT_REFUNDED_STATUS,
      //   name: `${
      //     translations.roomBookingStatusFilter[ROOM_BOOKING_NOT_REFUNDED_STATUS]
      //   } (${getRoomBookingStatusTotalUtil(
      //     data,
      //     ROOM_BOOKING_NOT_REFUNDED_STATUS
      //   )})`,
      // },
    ];
  } else if (type === PROPERTY) {
    deskBookingFilterTemp = [
      {
        id: ROOM_BOOKING_ALL_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_ALL_STATUS]
        } (${getRoomBookingStatusTotalUtil(data, ROOM_BOOKING_ALL_STATUS)})`,
      },
      {
        id: ROOM_BOOKING_CONFIRMED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_CONFIRMED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_CONFIRMED_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_PENDING_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_PENDING_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_PENDING_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_DECLINED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_DECLINED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_DECLINED_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_CANCELLED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_CANCELLED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_CANCELLED_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_COMPLETED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_COMPLETED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_COMPLETED_STATUS
        )})`,
      },
    ];
  }

  setStatus(deskBookingFilterTemp);
};

export const createRoomBookingStatusFilterService = async ({
  translations,
  setStatus,
  type,
  propertyContext = false,
  deskimoAdmin,
  productType,
}: {
  translations: any;
  setStatus: Dispatch<SetStateAction<{ id: string; name: string }[]>>;
  type: typeof BUSINESS | typeof PROPERTY;
  propertyContext?: boolean;
  deskimoAdmin?: boolean;
  productType?: number;
}) => {
  const dateRange = store.getState().DashboardReducer.filterDateRange;
  const filterPropertyList =
    store.getState().DashboardReducer.filterListPropertySelected;
  if (!dateRange) {
    return;
  }

  const dashboardRoomBookingFilter = deskimoAdmin
    ? store.getState().DashboardReducer.dashboardDeskimoRoomBookingFilter
    : store.getState().DashboardReducer.dashboardRoomBookingFilter;

  const payload = {
    ...dashboardRoomBookingFilter,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...dateRange,
    properties: filterPropertyList || [],
  };

  if (propertyContext) {
    const dashboardPropertyChoose: any =
      store.getState().DashboardReducer.dashboardPropertyChoose;
    payload.property_id = dashboardPropertyChoose?.property_id;
  }

  if (productType) {
    payload.product = productType;
  }

  let data;

  if (type === BUSINESS) {
    const res: { data: RoomBookingStatusType[] } =
      await roomBookingStatusService(payload);
    data = res.data;
  } else if (type === PROPERTY) {
    const res: { data: RoomBookingStatusType[] } =
      await roomBookingPropertyStatusService(payload, deskimoAdmin);
    data = res.data;
  }

  if (!data) {
    return;
  }

  let roomBookingFilterTemp: any = [];

  if (deskimoAdmin) {
    roomBookingFilterTemp = [
      {
        id: ROOM_BOOKING_ALL_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_ALL_STATUS]
        } (${getRoomBookingStatusTotalUtil(data, ROOM_BOOKING_ALL_STATUS)})`,
      },
      {
        id: ROOM_BOOKING_CONFIRMED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_CONFIRMED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_CONFIRMED_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_PENDING_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_PENDING_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_PENDING_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_DECLINED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_DECLINED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_DECLINED_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_CANCELLED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_CANCELLED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_CANCELLED_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_COMPLETED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_COMPLETED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_COMPLETED_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_PENDING_PAYMENT_STATUS,
        name: `${
          translations.roomBookingStatusFilter[
            ROOM_BOOKING_PENDING_PAYMENT_STATUS
          ]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_PENDING_PAYMENT_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_REFUNDED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_REFUNDED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_REFUNDED_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_NOT_REFUNDED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_NOT_REFUNDED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_NOT_REFUNDED_STATUS
        )})`,
      },
    ];
  } else if (type === BUSINESS) {
    roomBookingFilterTemp = [
      {
        id: ROOM_BOOKING_ALL_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_ALL_STATUS]
        } (${getRoomBookingStatusTotalUtil(data, ROOM_BOOKING_ALL_STATUS)})`,
      },
      {
        id: ROOM_BOOKING_CONFIRMED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_CONFIRMED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_CONFIRMED_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_PENDING_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_PENDING_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_PENDING_STATUS
        )})`,
      },
      // {
      //   id: ROOM_BOOKING_DECLINED_STATUS,
      //   name: `${
      //     translations.roomBookingStatusFilter[ROOM_BOOKING_DECLINED_STATUS]
      //   } (${getRoomBookingStatusTotalUtil(
      //     data,
      //     ROOM_BOOKING_DECLINED_STATUS
      //   )})`,
      // },
      {
        id: ROOM_BOOKING_CANCELLED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_CANCELLED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_CANCELLED_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_COMPLETED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_COMPLETED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_COMPLETED_STATUS
        )})`,
      },
      // {
      //   id: ROOM_BOOKING_PENDING_PAYMENT_STATUS,
      //   name: `${
      //     translations.roomBookingStatusFilter[
      //       ROOM_BOOKING_PENDING_PAYMENT_STATUS
      //     ]
      //   } (${getRoomBookingStatusTotalUtil(
      //     data,
      //     ROOM_BOOKING_PENDING_PAYMENT_STATUS
      //   )})`,
      // },
      // {
      //   id: ROOM_BOOKING_PENDING_REFUNDED_STATUS,
      //   name: `${
      //     translations.roomBookingStatusFilter[
      //       ROOM_BOOKING_PENDING_REFUNDED_STATUS
      //     ]
      //   } (${getRoomBookingStatusTotalUtil(
      //     data,
      //     ROOM_BOOKING_PENDING_REFUNDED_STATUS
      //   )})`,
      // },
      // {
      //   id: ROOM_BOOKING_REFUNDED_STATUS,
      //   name: `${
      //     translations.roomBookingStatusFilter[ROOM_BOOKING_REFUNDED_STATUS]
      //   } (${getRoomBookingStatusTotalUtil(data, ROOM_BOOKING_REFUNDED_STATUS)})`,
      // },
      // {
      //   id: ROOM_BOOKING_NOT_REFUNDED_STATUS,
      //   name: `${
      //     translations.roomBookingStatusFilter[ROOM_BOOKING_NOT_REFUNDED_STATUS]
      //   } (${getRoomBookingStatusTotalUtil(
      //     data,
      //     ROOM_BOOKING_NOT_REFUNDED_STATUS
      //   )})`,
      // },
    ];
  } else if (type === PROPERTY) {
    roomBookingFilterTemp = [
      {
        id: ROOM_BOOKING_ALL_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_ALL_STATUS]
        } (${getRoomBookingStatusTotalUtil(data, ROOM_BOOKING_ALL_STATUS)})`,
      },
      {
        id: ROOM_BOOKING_CONFIRMED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_CONFIRMED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_CONFIRMED_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_PENDING_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_PENDING_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_PENDING_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_DECLINED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_DECLINED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_DECLINED_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_CANCELLED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_CANCELLED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_CANCELLED_STATUS
        )})`,
      },
      {
        id: ROOM_BOOKING_COMPLETED_STATUS,
        name: `${
          translations.roomBookingStatusFilter[ROOM_BOOKING_COMPLETED_STATUS]
        } (${getRoomBookingStatusTotalUtil(
          data,
          ROOM_BOOKING_COMPLETED_STATUS
        )})`,
      },
    ];
  }

  setStatus(roomBookingFilterTemp);
};

const getRoomBookingStatusTotalUtil = (
  data: RoomBookingStatusType[],
  statusId: string
) => {
  return (
    data.find(
      ({ status_id, refund_status_id }) =>
        status_id === statusId || refund_status_id === statusId
    )?.total || 0
  );
};

export const getOnboardingPromotionService = () => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_ONBOARDING_PROMOTION,
    isCompany: true,
  });
};

export const bookingPropertyActionService = ({
  url,
  data,
}: {
  url: string;
  data: object;
}) => {
  return clientRequestService({
    method: METHOD_POST,
    url: url,
    isCompany: true,
    data,
  });
};

export const getPropertyByCompanyService = (companyId: string) => {
  return clientRequestService({
    url: API_PROPERTY_BY_COMPANY.replaceAll("%companyId%", companyId),
  });
};

export const getPropertyPendingBookingsService = (
  companyId: string,
  propertyId: string
) => {
  return clientRequestService({
    url: API_PROPERTY_PENDING_BOOKINGS.replaceAll(
      "%companyId%",
      companyId
    ).replaceAll("%propertyId%", propertyId),
  });
};

export const manualCheckInService = (data: object) => {
  return clientRequestService({
    method: "POST",
    url: API_MANUAL_CHECK_IN,
    data,
    isCompany: true,
  });
};

export const manualCheckOutService = (data: object) => {
  return clientRequestService({
    method: "POST",
    url: API_MANUAL_CHECK_OUT,
    data,
    isCompany: true,
  });
};

export const deleteVisitService = (visitId: number) => {
  return clientRequestService({
    method: "delete",
    url: API_DELETE_VISIT.replaceAll("%visitId%", String(visitId)),
  });
};

export const payWithCreditService = (data: object) => {
  return clientRequestService({
    method: "POST",
    url: API_PAY_WITH_CREDIT,
    data,
  });
};

export const propertyStatusService = (data: object) => {
  return clientRequestService({
    method: "PUT",
    url: API_PROPERTY_STATUS,
    data,
    isCompany: true,
  });
};

export const propertyFutureClosuresService = (data: object) => {
  return clientRequestService({
    method: "POST",
    url: API_PROPERTY_FUTURE_CLOSURES,
    data,
  });
};

export const getPropertyFutureClosuresService = (propertyId: number) => {
  return clientRequestService({
    url: API_GET_PROPERTY_FUTURE_CLOSURES.replaceAll(
      "%propertyId%",
      String(propertyId)
    ),
  });
};

export const getActivityTrackingService = (data: object) => {
  return clientRequestService({
    method: "POST",
    url: API_GET_ACTIVITY_TRACKING,
    isCompany: true,
    data,
  });
};

export const propertyPromotionSetupService = (data: object) => {
  return clientRequestService({
    method: "POST",
    url: API_PROPERTY_PROMOTION_SET_UP,
    isCompany: true,
    data,
  });
};

export const propertyPromotionDetailService = (data: object) => {
  return clientRequestService({
    method: "POST",
    url: API_PROPERTY_PROMOTION_DETAIL,
    isCompany: true,
    data,
  });
};

export const handleScannerService = (data: object) => {
  return clientRequestService({
    method: "POST",
    url: API_HANDLE_SCANNER,
    isCompany: true,
    data,
  });
};

export const fetchPendingInviteService = () => {
  return clientRequestService({
    method: "GET",
    url: API_FETCH_PENDING_INVITE,
  });
};

export const acceptPendingInviteService = (hashToken: string) => {
  return clientRequestService({
    method: "GET",
    url: API_PENDING_INVITE_ACCEPT_ACTION.replaceAll("%hashToken%", hashToken),
    isAuth: false,
  });
};

export const rejectPendingInviteService = (hashToken: string) => {
  if (!hashToken) {
    return;
  }

  return clientRequestService({
    method: "GET",
    url: API_PENDING_INVITE_REJECT_ACTION.replaceAll("%hashToken%", hashToken),
    isAuth: false,
  });
};

export const getDeclineReasonsService = (type: string) => {
  return clientRequestService({
    method: "GET",
    url: API_GET_DECLINE_REASONS.replaceAll("%type%", type),
    isAuth: false,
  });
};

export const manualPartnerBookingGetPropertiesService = (data: object) => {
  return clientRequestService({
    method: "POST",
    url: API_GET_LIST_PROPERTIES_FOR_PARTNER,
    data,
  });
};

export const partnerGetMeetingRoomService = (data: object) => {
  return clientRequestService({
    method: "POST",
    url: API_GET_LIST_MEETING_ROOM_FOR_PARTNER,
    data,
  });
};

export const manualBookingGetPropertiesService = (data: object) => {
  return clientRequestService({
    method: "POST",
    url: API_MANUAL_BOOKING_GET_LIST_DESK,
    data,
  });
};

export const manualBookingGetRoomsService = (id: string) => {
  return clientRequestService({
    method: "GET",
    url: API_MANUAL_BOOKING_GET_LIST_ROOM.replaceAll("%id%", id),
  });
};

export const manualBookingGetUsersService = (data: object) => {
  return clientRequestService({
    method: "POST",
    url: API_MANUAL_BOOKING_GET_LIST_USER,
    data,
  });
};

export const manualBookingGetPaymentMethodService = (id: string) => {
  return clientRequestService({
    method: "GET",
    url: API_MANUAL_BOOKING_GET_LIST_PAYMENT.replaceAll("%id%", id),
  });
};

export const manualBookingSubmitDeskService = (data: object) => {
  return clientRequestService({
    method: "POST",
    url: API_MANUAL_BOOKING_SUBMIT_DESK,
    data,
  });
};

export const manualPartnerBookingSubmitDeskService = (data: object) => {
  return clientRequestService({
    method: "POST",
    url: API_CREATE_BOOKING_FOR_PARTNER,
    data,
  });
};

export const manualBookingSubmitRoomService = (data: object) => {
  return clientRequestService({
    method: "POST",
    url: API_MANUAL_BOOKING_SUBMIT_ROOM,
    data,
  });
};

export const partnerCheckInManualService = (data: object) => {
  return clientRequestService({
    method: "POST",
    url: API_PARTNER_MANUAL_CHECK_IN,
    data,
  });
};

export const partnerCreateMeetingBookingService = (data: object) => {
  return clientRequestService({
    method: "POST",
    url: API_PARTNER_CREATE_MEETING_ROOM_BOOKING,
    data,
  });
};

export const manualBookingSubmitWalkInsService = (data: object) => {
  return clientRequestService({
    method: "POST",
    url: API_MANUAL_BOOKING_SUBMIT_WALK_INS,
    data,
  });
};

export const contractPaymentService = (data: object) => {
  return clientRequestService({
    method: "POST",
    url: API_CONTRACTS_PAYMENT,
    data,
  });
};
export const getFreeCreditService = (companyId: string) => {
  return clientRequestService({
    url: API_GET_FREE_CREDIT + `?company_id=${companyId}`,
  });
};

export const transactionInfoService = (data: object) => {
  return clientRequestService({
    method: "post",
    url: API_TRANSACTION_INFO,
    data,
  });
};

export const getStatisticOngoingService = (data: object) => {
  return clientRequestService({
    method: "post",
    url: API_STATISTIC_ON_GOING,
    data,
  });
};

export const dashboardCheckUsageService = (data?: object) => {
  return clientRequestService({
    method: "post",
    url: API_DASHBOARD_CHECK_USAGE,
    data,
  });
};

export const leasePaymentRePayService = (code: string) => {
  return clientRequestService({
    method: "post",
    url: API_PAY_NOW_LEASE_PAYMENT.replaceAll("%leaseId%", code),
  });
};

export const leaseSendReceiptService = (token: string) => {
  return clientRequestService({
    method: "post",
    url: API_LEASE_SEND_RECEIPT.replaceAll(":token", token),
  });
};

export const onPayWithCredit = async (
  type: string,
  transactionId: number | string,
  callback?: () => void
) => {
  const dispatch = store.dispatch;

  const options = SWAL_CONFIRM_OPTION({
    title: "Are you sure you want to pay with credit?",
    confirmText: "Yes",
    cancelText: "No",
    showCancelButton: true,
  });

  SweetAlertCommon({
    options,
    confirmCallback: async () => {
      try {
        dispatch(isGlobalIndicatorAction(true));
        const payload = { type, id: transactionId };
        await payWithCreditService(payload);
        callback && (await callback());
        toastSuccess("Updated Successfully!");
      } catch (e: any) {
        toastError(e?.status?.message);
      } finally {
        dispatch(isGlobalIndicatorAction(false));
      }
    },
    cancelCallback: () => null,
  });
};

export const partnerHotDeskBookingStatusService = (
  ref: string,
  statusId: number
) => {
  return clientRequestService({
    method: "post",
    url: API_PARTNER_HOT_DESK_BOOKING_STATUS.replaceAll(
      "%ref%",
      ref
    ).replaceAll("%status%", String(statusId)),
  });
};

export const partnerMeetingRoomBookingStatusService = (
  ref: string,
  statusId: number
) => {
  return clientRequestService({
    method: "post",
    url: API_PARTNER_MEETING_ROOM_BOOKING_STATUS.replaceAll(
      "%ref%",
      ref
    ).replaceAll("%status%", String(statusId)),
  });
};

export const partnerCheckoutManuallyService = (data: object) => {
  return clientRequestService({
    method: "post",
    url: API_PARTNER_CHECKOUT_MANUALLY,
    data,
  });
};

export const partnerCheckinWalkinService = async (data: object) => {
  return clientRequestService({
    method: "post",
    url: API_PARTNER_CHECKIN_MANUALLY,
    data,
  });
};

export const listPropertyAutocompleteService = async (keyword?: string) => {
  const keywordDecorated = keyword || "";
  return clientRequestService({
    method: "get",
    isCompany: true,
    url: API_LIST_PROPERTY_AUTOCOMPLETE.replaceAll(
      "%keyword%",
      keywordDecorated
    ),
  });
};

export const changeToPAYGService = async (data: object) => {
  return clientRequestService({
    method: "post",
    url: API_CHANGE_TO_PAYG,
    data,
  });
};

export const repayService = async (data: object) => {
  return clientRequestService({
    method: "post",
    url: API_REPAY,
    data,
  });
};
