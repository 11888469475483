import SizeBox from "../../../../../../../components/SizeBox";
import React, { useContext, useState, useEffect } from "react";
import * as yup from "yup";
import Label from "../../../../../../../components/Label";
import InputCustom from "../../../../../../../components/Input";
import { Formik } from "formik";
import { LocalizationContext } from "../../../../../../../locales/Translation";
import CheckboxCustom from "../../../../../../../components/Checkbox";
import DropzoneComponent from "./DropzoneComponent";
import { usePlacesWidget } from "react-google-autocomplete";
import Map from "../../../../../../../components/Map";
import { useDispatch, useSelector } from "react-redux";
import {
  propertyCategoriesSelector,
  propertyDetailSelector,
} from "../../../../../selectors/PropertySelector";
import ButtonCustom from "../../../../../../../components/Button";
import { updatePropertyService } from "../../../../../services/PropertyServices";
import { useParams } from "react-router-dom";
import { toastError } from "../../../../../../../components/Toast";
import {
  getPropertyCategoriesThunk,
  getPropertyDetailThunk,
} from "../../../../../thunks/PropertyThunks";
import axios from "axios";
import { API_GET_ADDRESS_BY_LAT_LNG } from "../../../../../configs/Endpoints";
import { myProfileSelector } from "../../../../../../App/selectors/AppSelectors";
import { MyProfileType } from "../../../../../../User/configs/DeclareTypes";
import { ROLE_DESKIMO_ADMIN_ID } from "../../../../../../User/configs/Constants";
import DropdownCustom from "../../../../../../../components/DropdownCustom";
import { tagsListService } from "../../../../../../Configuration/services/ConfigServices";

const PropertyMetaEdit = ({ setEditMode, setLoading, loading }: any) => {
  const { translations } = useContext(LocalizationContext);
  const myProfile: MyProfileType = useSelector(myProfileSelector);
  const [tagOptions, setTagOptions] = useState([]);
  const { id } = useParams();
  const [geo, setGeo] = useState({
    lat: "",
    lng: "",
  });
  const [address, setAddress] = useState("");
  const dispatch = useDispatch();
  const propertyDetail = useSelector(propertyDetailSelector);
  const [currentValue, setCurrentValue] = useState("");
  const [options, setOptions] = useState<any>([]);
  const listCategory = useSelector(propertyCategoriesSelector);

  useEffect(() => {
    (async () => {
      setAddress(propertyDetail?.address);
      setGeo({
        lat: propertyDetail?.lat,
        lng: propertyDetail?.lng,
      });
      const { data } = await tagsListService({ page: 1 });
      const tags = data?.items?.map(
        ({ id, name }: { id: number; name: string }) => ({
          key: id,
          text: name,
          value: id,
        })
      );
      setTagOptions(tags);
      await fetchListCategory();
      setCurrentValue(
        propertyDetail?.property_categories.map((cate: any) => cate?.name)
      );
    })();
  }, [propertyDetail]);

  useEffect(() => {
    if (listCategory.length > 0) {
      setOptions(
        listCategory.map((category: any) => {
          return {
            key: category?.id,
            text: category?.name + ` (${category?.total_property})`,
            value: category?.name,
          };
        })
      );
    } else {
      setOptions([]);
    }
  }, [listCategory]);

  const fetchListCategory = async () => {
    try {
      await dispatch<any>(getPropertyCategoriesThunk());
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const onPlaceChanged = async (place: any) => {
    if (!place.geometry) {
      return;
    }
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    setGeo({ lat, lng });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setAddress(ref?.current?.value);
  };

  const { ref }: any = usePlacesWidget({
    onPlaceSelected: (place) => {
      onPlaceChanged(place);
    },
    options: {
      types: ["establishment"],
      fields: ["place_id", "geometry", "name"],
    },
  });

  const _markerOnDragEnd = async (e: any) => {
    try {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      axios.get(API_GET_ADDRESS_BY_LAT_LNG({ lat, lng })).then((response) => {
        const results = response?.data?.results;
        const address = results[0].formatted_address;
        setAddress(address);
        setGeo({
          lat,
          lng,
        });
      });
    } catch (e) {
      console.log("error: ", e);
    }
  };

  const _handleSave = async (values: any) => {
    values.property_id = id;
    values.lat = geo.lat;
    values.lng = geo.lng;
    values.address = address;
    values.property_categories = currentValue;
    try {
      setLoading(true);
      await updatePropertyService(values);
      await dispatch<any>(getPropertyDetailThunk(id ? id : ""));
      setEditMode(false);
    } catch (response: any) {
      toastError(response?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAdditionCategory = (e: any, { value }: any) => {
    setOptions([...options, { key: value, text: value, value }]);
  };

  const handleChangeCategory = (e: any, { value }: any) => {
    setCurrentValue(value);
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          name: propertyDetail?.name || "",
          company_name: propertyDetail?.company_name || "",
          hourly_rate: propertyDetail?.hourly_rate || "",
          daily_rate: propertyDetail?.daily_rate || "",
          hot_desk_mode: {
            allow_walk_in:
              propertyDetail?.hot_desk_mode?.allow_walk_in || false,
            allow_booking:
              propertyDetail?.hot_desk_mode?.allow_booking || false,
            allow_desk_limitation_condition:
              propertyDetail?.hot_desk_mode?.allow_desk_limitation_condition ||
              false,
            desk_limitation_amount:
              propertyDetail?.hot_desk_mode?.desk_limitation_amount || "",
            auto_approved:
              propertyDetail?.hot_desk_mode?.auto_approved || false,
          },
          images: propertyDetail?.images || [],
          address: propertyDetail?.address || "",
          address_street_name: propertyDetail?.address_street_name || "",
          address_building: propertyDetail?.address_building || "",
          address_floor: propertyDetail?.address_floor || "",
          address_unit: propertyDetail?.address_unit || "",
          address_postal_code: propertyDetail?.address_postal_code || "",
          address_city: propertyDetail?.address_city || "",
          address_map_pins: address,
          tag_ids: propertyDetail?.tag_ids
            ? propertyDetail?.tag_ids?.map(({ id }: { id: number }) => id)
            : [],
          property_categories: propertyDetail?.property_categories || [],
        }}
        validationSchema={yup.object().shape({
          name: yup.string().required().label(translations.propertyName),
          address_building: yup
            .string()
            .required()
            .label(translations.buildingName),
          address_floor: yup.string().required().label(translations.floor),
          hot_desk_mode: yup.object({
            desk_limitation_amount: yup
              .number()
              .min(1)
              .when(["allow_desk_limitation_condition"], {
                is: (value: boolean) => value,
                then: yup
                  .number()
                  .required()
                  .label(translations.limitNumberAvailableDesks),
              }),
          }),
        })}
        onSubmit={_handleSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <>
            <form className={"form-layout"}>
              <div className="property-meta-edit">
                <div className="form-row">
                  <InputCustom
                    label={translations.propertyName}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.name}
                    error={errors.name}
                    name={"name"}
                  />
                  <InputCustom
                    label={"Company"}
                    value={values.company_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.company_name}
                    error={errors.company_name}
                    name={"company_name"}
                    disabled
                  />
                </div>
                <div className="form-row">
                  <InputCustom
                    label={translations.hourlyRateIncludingGSTVAT}
                    value={values.hourly_rate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.hourly_rate}
                    error={errors.hourly_rate}
                    name={"hourly_rate"}
                    inputLeftIcon={propertyDetail?.currency}
                    inputLeftIconBorder
                    disabled={myProfile?.role_id !== ROLE_DESKIMO_ADMIN_ID}
                  />
                  <InputCustom
                    label={translations.dailyRateCap}
                    value={values.daily_rate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.daily_rate}
                    error={errors.daily_rate}
                    name={"daily_rate"}
                    inputLeftIcon={propertyDetail?.currency}
                    inputLeftIconBorder
                    disabled={myProfile?.role_id !== ROLE_DESKIMO_ADMIN_ID}
                  />
                </div>
                <div className={"edit-property-categories"}>
                  <Label
                    fontSize={"12px"}
                    label={"Category"}
                    className={"sm"}
                    labelGuide={
                      "Add or select a label to allow users to easily filter for the right workspace"
                    }
                  />
                  <DropdownCustom
                    disabled={myProfile.role_id !== ROLE_DESKIMO_ADMIN_ID}
                    options={options}
                    selectUI
                    search
                    selection
                    allowAdditions={myProfile.role_id === ROLE_DESKIMO_ADMIN_ID}
                    multiple
                    value={currentValue}
                    onAddItem={handleAdditionCategory}
                    onChange={handleChangeCategory}
                  />
                </div>
                {myProfile.role_id === ROLE_DESKIMO_ADMIN_ID && (
                  <>
                    <SizeBox height={"24px"} />
                    <Label
                      fontSize={"12px"}
                      label={"Limit access to business with the following tags"}
                      className={"sm"}
                    />
                    <DropdownCustom
                      selectUI
                      options={tagOptions}
                      value={values.tag_ids}
                      multiple
                      selection
                      onChange={(event, { value }) => {
                        setFieldValue("tag_ids", value);
                      }}
                    />

                    <SizeBox height={"24px"} />
                    <Label
                      fontSize={"12px"}
                      label={translations.hotDesk}
                      className={"sm mb-0"}
                    />
                    <SizeBox height={"24px"} className={"d-sm-none"} />
                    <div className={"d-flex"}>
                      <CheckboxCustom
                        onChange={(e: any, data: any) => {
                          setFieldValue(
                            "hot_desk_mode.allow_walk_in",
                            data.checked
                          );
                        }}
                        checked={values.hot_desk_mode.allow_walk_in}
                        label={"Allow desk walk-ins"}
                      />
                      <SizeBox width={"45px"} />
                      <CheckboxCustom
                        onChange={(e: any, data: any) => {
                          setFieldValue(
                            "hot_desk_mode.allow_booking",
                            data.checked
                          );
                        }}
                        checked={values.hot_desk_mode.allow_booking}
                        label={"Allow desk advanced bookings"}
                      />
                    </div>

                    <SizeBox height={"24px"} />

                    <div>
                      <CheckboxCustom
                        onChange={(e: any, data: any) => {
                          setFieldValue(
                            "hot_desk_mode.allow_desk_limitation_condition",
                            data.checked
                          );
                        }}
                        checked={
                          values.hot_desk_mode.allow_desk_limitation_condition
                        }
                        label={translations.limitNumberAvailableDesks}
                        disabled={isSubmitting}
                      />
                      {values.hot_desk_mode.allow_desk_limitation_condition ? (
                        <>
                          <SizeBox height={"16px"} />
                          <div className={"form-row mb-0"}>
                            <InputCustom
                              containerClass={"mb-0"}
                              value={
                                values.hot_desk_mode.desk_limitation_amount
                              }
                              onChange={handleChange}
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              touched={
                                touched?.hot_desk_mode?.desk_limitation_amount
                              }
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              error={
                                errors?.hot_desk_mode?.desk_limitation_amount
                              }
                              name={"hot_desk_mode.desk_limitation_amount"}
                              inputLeftIconBorder
                              disabled={isSubmitting}
                              placeholder={translations.noOfDesks}
                            />
                            <div />
                          </div>
                        </>
                      ) : null}
                    </div>

                    <SizeBox height={"24px"} />
                    <div>
                      <CheckboxCustom
                        onChange={(e: any, data: any) => {
                          setFieldValue(
                            "hot_desk_mode.auto_approved",
                            data.checked
                          );
                        }}
                        checked={values.hot_desk_mode.auto_approved}
                        label={"Automatically approve day pass bookings"}
                      />
                    </div>
                  </>
                )}
              </div>
              <SizeBox height={"24px"} />
              <Label
                fontSize={"12px"}
                label={translations.yourPhotos}
                className={"sm"}
              />
              <SizeBox className={"d-sm-none"} />
              <DropzoneComponent
                boxClass={"property-meta-edit"}
                containerClass={"property-detail-large-image"}
                onDragEnd={(
                  sourceId: any,
                  sourceIndex: any,
                  targetIndex: any
                ) => {
                  if (sourceIndex === targetIndex) return;

                  if (values.images) {
                    let newState = JSON.parse(JSON.stringify(values.images));
                    const sourceItem = newState[sourceIndex];

                    newState = newState.filter(
                      (image: any) => image.id !== sourceItem.id
                    );

                    if (sourceItem) {
                      newState.splice(targetIndex, 0, sourceItem);

                      newState.forEach((item: any, index: number) => {
                        item.weight = index;
                      });

                      setFieldValue("images", newState);
                    }
                  }
                }}
                isDragDropChangeOrder={true}
                value={values.images}
                deletePhotos={(id: string) => {
                  console.log("id", id);
                  const newImages = values.images.filter(
                    (image: any) => image?.id !== id
                  );
                  setFieldValue("images", newImages);
                }}
                setPhotos={(data: any) =>
                  setFieldValue("images", [...values.images, ...data])
                }
              />
              <SizeBox height={"46px"} />
              <div className="property-meta-edit">
                <InputCustom
                  label={translations.addressDisplayNameStreetNumberStreetName}
                  value={values.address_street_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.address_street_name}
                  error={errors.address_street_name}
                  name={"address_street_name"}
                />
                <SizeBox height={"29px"} className={"d-sm-none"} />
                <InputCustom
                  label={translations.buildingName}
                  labelRequired={true}
                  value={values.address_building}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.address_building}
                  error={errors.address_building}
                  name={"address_building"}
                />
                <SizeBox height={"29px"} className={"d-sm-none"} />
                <div className={"form-row"}>
                  <InputCustom
                    label={translations.floor}
                    labelRequired={true}
                    value={values.address_floor}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.address_floor}
                    error={errors.address_floor}
                    name={"address_floor"}
                  />
                  <InputCustom
                    label={translations.unit}
                    value={values.address_unit}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.address_unit}
                    error={errors.address_unit}
                    name={"address_unit"}
                  />
                </div>
                <SizeBox height={"29px"} />
                <div className={"form-row"}>
                  <InputCustom
                    label={translations.settings.postalCode}
                    value={values.address_postal_code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.address_postal_code}
                    error={errors.address_postal_code}
                    name={"address_postal_code"}
                  />
                  <InputCustom
                    label={translations.settings.city}
                    value={values.address_city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.address_city}
                    error={errors.address_city}
                    name={"address_city"}
                  />
                </div>
                <SizeBox height={"29px"} />
                <Label
                  fontSize={"12px"}
                  label={translations.addressForMapPin}
                  className={"sm"}
                />
                <div className="input-component">
                  <div className={"input-wrapper"} style={{ height: 44 }}>
                    <div className={"ui input"} style={{ height: "100%" }}>
                      <input
                        placeholder={"Type office address"}
                        ref={ref}
                        value={address}
                        onChange={({ target }) => {
                          setAddress(target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <SizeBox height={"29px"} />
                <Label fontSize={"12px"} label={"Map"} className={"sm"} />
                <Map
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  lat={parseFloat(geo?.lat)}
                  lng={parseFloat(geo?.lng)}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div className={"gg-map"} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  draggable
                  onDragEnd={_markerOnDragEnd}
                />
              </div>
            </form>
            <div className={"edit-cta-wrapper edit-mode"}>
              <ButtonCustom
                onClick={() => setEditMode(false)}
                className={"outline-primary"}
                fontSize={"12px"}
                height={"37px"}
              >
                <>{translations.cancel}</>
              </ButtonCustom>
              <ButtonCustom
                loading={loading}
                onClick={handleSubmit}
                className={"primary-alt"}
                fontSize={"12px"}
                height={"37px"}
              >
                <>{translations.save}</>
              </ButtonCustom>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default PropertyMetaEdit;
