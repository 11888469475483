import React from "react";

const KeyIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33398 12.0004L6.66732 10.6671H8.00065L8.90732 9.7604C9.8341 10.0833 10.843 10.082 11.769 9.75678C12.6949 9.43155 13.4831 8.80163 14.0044 7.97013C14.5257 7.13863 14.7493 6.15479 14.6387 5.17964C14.528 4.20449 14.0896 3.29578 13.3952 2.60225C12.7008 1.90873 11.7915 1.47145 10.8162 1.362C9.84095 1.25255 8.85739 1.4774 8.02654 1.99976C7.19569 2.52212 6.56677 3.31104 6.2427 4.2374C5.91863 5.16376 5.91861 6.1727 6.24265 7.09907L1.33398 12.0004V14.6671H4.00065L5.33398 13.3337V12.0004Z"
        stroke="#495057"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3346 5.33333C11.7028 5.33333 12.0013 5.03486 12.0013 4.66667C12.0013 4.29848 11.7028 4 11.3346 4C10.9664 4 10.668 4.29848 10.668 4.66667C10.668 5.03486 10.9664 5.33333 11.3346 5.33333Z"
        stroke="#495057"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default KeyIcon;
