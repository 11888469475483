import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../components/Button";
import ModalCenterTemplate from "../../../../App/views/ModalCenterTemplate";
import { useDispatch, useSelector } from "react-redux";
import {
  assignUserListSelector,
  modalAssignUserSelector,
  userListSelector,
} from "../../../selectors/BookSelectors";
import { LocalizationContext } from "../../../../../locales/Translation";
import {
  assignUserListAction,
  modalAssignUserAction,
} from "../../../actions/BookActions";
import SizeBox from "../../../../../components/SizeBox";
import InputCustom from "../../../../../components/Input";
import { getUserListThunk } from "../../../thunks/BookThunks";
import AssignUserItem from "./AssignUserItem";
import LoaderCustom from "../../../../../components/Loader";
import { debounce } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { LIMIT_TOTAL_BOOKING } from "../../../configs/Constants";

const ModalAssignUser = () => {
  const modalAssignUser = useSelector(modalAssignUserSelector);
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const userList = useSelector(userListSelector);
  const [assignListTmp, setAssignListTmp] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const assignedUserList = useSelector(assignUserListSelector);
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [total, setTotal] = useState(0);

  const calculateTotalCurrentBooking = (increase: number, decrease: number) => {
    setTotal((prevState) => prevState + increase - decrease);
  };

  useEffect(() => {
    if (!modalAssignUser?.open) {
      setPage(1);
      setKeyword("");
      setAssignListTmp([]);
      setTotal(0);
    } else {
      let totalTmp = total;
      assignedUserList.forEach((user: any) => {
        totalTmp += user.slot;
      });
      setTotal(totalTmp);
    }
  }, [modalAssignUser]);

  useEffect(() => {
    if (modalAssignUser?.open) {
      _fetchUserList();
      if (assignedUserList.length > 0) {
        setAssignListTmp(assignedUserList);
      }
    }
  }, [modalAssignUser, assignedUserList, keyword]);

  useEffect(() => {
    if (page < userList?.pagination?.last_page) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [userList]);

  const _fetchUserList = async (appendData?: any, nextPage?: any) => {
    setLoading(true);
    try {
      const filter = {
        keyword: keyword.trim().toLowerCase(),
        statuses: [1],
        page: nextPage ? page + 1 : page,
      };
      await dispatch<any>(getUserListThunk(filter, appendData));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const _onClose = () => {
    setAssignListTmp([]);
    dispatch(modalAssignUserAction({ open: false }));
  };

  const onChangeAssignUser = (user: any, data: any) => {
    if (data?.checked) {
      setAssignListTmp((prevState: any) => {
        //Check if user is not existed in list
        if (!prevState.find((item: any) => item?.id === user?.id)) {
          return [...prevState, user];
        }
        return prevState;
      });
      const existedUser = assignedUserList.find(
        (item: any) => item?.id === user?.id
      );
      if (existedUser) {
        calculateTotalCurrentBooking(existedUser?.slot, 0);
      } else {
        calculateTotalCurrentBooking(1, 0);
      }
    } else {
      setAssignListTmp((prevState: any) => {
        return prevState.filter((item: any) => item?.id !== user?.id);
      });
      const existedUser = assignedUserList.find(
        (item: any) => item?.id === user?.id
      );
      if (existedUser) {
        calculateTotalCurrentBooking(0, existedUser?.slot);
      } else {
        calculateTotalCurrentBooking(0, 1);
      }
    }
  };

  const handleAssignUser = () => {
    dispatch(assignUserListAction(assignListTmp));
    dispatch(modalAssignUserAction({ open: false }));
  };

  const onSearch = debounce((e, data) => {
    setPage(1);
    setKeyword(data?.value);
  }, 500);

  const fetchMoreData = async () => {
    if (page <= userList?.pagination?.last_page) {
      setPage(page + 1);
      await _fetchUserList(true, true);
    }
  };

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={_onClose}
      modalClassName={classNames("modal-center-v2 modal-assign-user")}
      open={modalAssignUser?.open}
    >
      <Modal.Header>
        Assign Booking
        <ButtonCustom className={"none-bg close-modal"} onClick={_onClose}>
          <>
            Close <i className="uil uil-times-circle" />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Modal.Content scrolling className={"scroll"}>
        {loading ? <LoaderCustom /> : null}

        <div>Your booking will be valid for the selected user</div>
        <SizeBox height={"16px"} />
        <InputCustom
          onChange={onSearch}
          placeholder={"Search by Name, Email"}
          inputLeftIcon={<i className="uil uil-search" />}
        />
        <div
          className={"assign-user-list scroll scroll-blue"}
          id={"assign-user-list-id"}
        >
          <InfiniteScroll
            dataLength={userList?.data?.length || 25}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<h4 className={"text-center"}>Loading...</h4>}
            scrollableTarget={"assign-user-list-id"}
          >
            {userList?.data?.map((user: any, index: number) => (
              <AssignUserItem
                disabled={
                  assignListTmp.filter((item: any) => item?.id === user?.id)
                    .length < 1 && total >= LIMIT_TOTAL_BOOKING
                }
                onChangeAssignUser={onChangeAssignUser}
                key={index}
                user={user}
                checked={
                  assignListTmp.filter((item: any) => item?.id === user?.id)
                    .length > 0
                }
              />
            ))}
          </InfiniteScroll>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <ButtonCustom
          className={"default"}
          height={"60px"}
          onClick={() => {
            _onClose();
          }}
        >
          Cancel
        </ButtonCustom>
        <SizeBox width={"16px"} />
        <ButtonCustom
          className={"primary"}
          height={"60px"}
          onClick={handleAssignUser}
        >
          Assign
        </ButtonCustom>
      </Modal.Actions>
    </ModalCenterTemplate>
  );
};

export default ModalAssignUser;
