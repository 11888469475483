import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import {
  addFavoriteItem,
  getDeskAmenitiesService,
  getMeetingRoomListService,
  getPropertiesMarketCount,
  getPropertyListService,
  getPropertyMapService,
  getRoomAmenitiesService,
  getRoomMarketCount,
  getUserAssignService,
  privateOfficeListService,
  propertyDetailViewService,
  removeFavoriteItem,
  rewardListService,
  roomDetailViewService,
} from "../services/BookServices";
import {
  appendUserListAction,
  deskAmenitiesAction,
  getUserListAction,
  listMeetingRoomAction,
  privateOfficeListAction,
  propertyDetailAction,
  propertyFavoriteAction,
  propertyListAction,
  propertyMapAction,
  propertyMarketCountAction,
  rewardListAction,
  roomAmenitiesAction,
  roomDetailAction,
  roomFavoriteAction,
  roomMarketCountAction,
} from "../actions/BookActions";
import { FavoriteDispatchType } from "../configs/DeclareType";
import {
  DEFAULT_SLOT,
  FAVORITE_MEETING_ROOM,
  FAVORITE_PROPERTY,
  FAVORITE_REMOVE,
} from "../configs/Constants";

export const getMeetingRoomListThunk = (payload: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await getMeetingRoomListService(payload);
    dispatch<any>(listMeetingRoomAction(data));
  };
};

export const getPropertyListThunk = (payload: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await getPropertyListService(payload);
    dispatch<any>(propertyListAction(data));
  };
};

export const getPropertyMapThunk = (payload: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await getPropertyMapService(payload);
    dispatch<any>(propertyMapAction(data?.results));
  };
};

export const propertyFavoriteThunk = (data: FavoriteDispatchType) => {
  return async (dispatch: any) => {
    const { isFavorite, id } = data;
    const nextFavorite = isFavorite ? 0 : 1;

    if (nextFavorite === FAVORITE_REMOVE) {
      removeFavoriteItem({ item_id: id, item_type: FAVORITE_PROPERTY });
    } else {
      addFavoriteItem({ item_id: id, item_type: FAVORITE_PROPERTY });
    }

    dispatch(propertyFavoriteAction(id));
  };
};

export const roomFavoriteThunk = (data: FavoriteDispatchType) => {
  return async (dispatch: any) => {
    const { isFavorite, id } = data;
    const nextFavorite = isFavorite ? 0 : 1;

    if (nextFavorite === FAVORITE_REMOVE) {
      removeFavoriteItem({
        item_id: id,
        item_type: FAVORITE_MEETING_ROOM,
      });
    } else {
      addFavoriteItem({ item_id: id, item_type: FAVORITE_MEETING_ROOM });
    }

    dispatch(roomFavoriteAction(id));
  };
};

export const propertyDetailThunk = (id: string) => {
  return async (dispatch: any) => {
    const { data } = await propertyDetailViewService(id);
    dispatch(propertyDetailAction(data));
  };
};

export const roomDetailThunk = (id: string) => {
  return async (dispatch: any) => {
    const { data } = await roomDetailViewService(id);
    dispatch(roomDetailAction(data));
  };
};

export const propertyMarketCountThunk = (payload: object) => {
  return async (dispatch: any) => {
    const { data } = await getPropertiesMarketCount(payload);
    dispatch(propertyMarketCountAction(data));
  };
};

export const roomMarketCountThunk = (payload: object) => {
  return async (dispatch: any) => {
    const { data } = await getRoomMarketCount(payload);
    dispatch(roomMarketCountAction(data));
  };
};

export const getUserListThunk = (payload: object, appendData: any) => {
  return async (dispatch: any) => {
    const { data } = await getUserAssignService(payload);
    if (data?.data?.length > 0) {
      data?.data?.forEach((item: any) => {
        item.slot = DEFAULT_SLOT;
      });
    }
    if (appendData) {
      dispatch(appendUserListAction(data));
    } else {
      dispatch(getUserListAction(data));
    }
  };
};

export const deskAmenitiesThunk = () => {
  return async (dispatch: any) => {
    const { data } = await getDeskAmenitiesService();
    dispatch(deskAmenitiesAction(data));
  };
};

export const roomAmenitiesThunk = () => {
  return async (dispatch: any) => {
    const { data } = await getRoomAmenitiesService();
    dispatch(roomAmenitiesAction(data));
  };
};

export const privateOfficeListThunk = (payload: object) => {
  return async (dispatch: any) => {
    const { data, extra } = await privateOfficeListService(payload);
    dispatch(privateOfficeListAction({ data, extra }));
  };
};

export const rewardListThunk = (propertyId: string) => {
  return async (dispatch: any) => {
    const { data } = await rewardListService(propertyId);
    dispatch(rewardListAction(data));
  };
};
