import { Dimmer, Loader } from "semantic-ui-react";
import { Formik, FormikHelpers, FormikValues } from "formik";
import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import InputCustom from "../../../../../components/Input";
import Label from "../../../../../components/Label";
import DropdownCustom from "../../../../../components/DropdownCustom";
import TableCustom from "../../../../../components/Table";
import { configMarketPublicHolidaysListSelector } from "../../../selectors/ConfigSelectors";
import { configMarketPublicHolidaysListThunk } from "../../../thunks/ConfigThunks";
import { ConfigMarketPublicHolidaysDataType } from "../../../configs/DeclareTypes";
import ButtonCustom from "../../../../../components/Button";
import {
  configCreateMarketPublicHolidayService,
  configDeleteMarketPublicHolidaysService,
} from "../../../services/ConfigServices";
import { deleteMarketPublicHolidaysAction } from "../../../actions/ConfigActions";
import { SWAL_DELETE_OPTION } from "../../../../../components/SweetAlert/configs/SwalConfigs";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import { SweetAlertCommon } from "../../../../../components/SweetAlert";
import { toastError, toastSuccess } from "../../../../../components/Toast";
import moment from "moment";
import { SELECT_YEAR_NONE_VALUE } from "../../../configs/Constants";
import * as yup from "yup";

type OptionsType = {
  id: string;
  value: string;
  text: string;
};

const PublicHolidaysManagementTab = () => {
  const { translations } = useContext(LocalizationContext);
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);

  const columns = ["Public Holiday Name", "Date", "Action"];

  const DEFAULT_PAYLOAD = {
    market_id: String(id),
    page: 1,
  };
  const [payload, setPayload] = useState(DEFAULT_PAYLOAD);

  const [dateOptions, setDateOptions] = useState<OptionsType[]>([]);
  const [monthOptions, setMonthOptions] = useState<OptionsType[]>([]);
  const [yearOptions, setYearOptions] = useState<OptionsType[]>([]);

  const dispatch = useDispatch();
  const publicHolidaysList = useSelector(
    configMarketPublicHolidaysListSelector
  );

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    setPayload((prevState: any) => {
      return { ...prevState, page: activePage };
    });
  };

  const _onDeletePublicHolidays = async (id: number) => {
    try {
      const iconHtml = (
        <img
          src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
          className={"swal2-icon__error"}
          alt="error_icon"
        />
      );
      const title = "Are you sure you want to delete this holiday?";
      const confirmText = "Delete";
      const cancelText = "Cancel";
      const confirmClass = "danger";
      const options = SWAL_DELETE_OPTION({
        title,
        iconHtml,
        confirmText,
        confirmClass,
        cancelText,
      });
      SweetAlertCommon({
        options,
        confirmCallback: async () => {
          await configDeleteMarketPublicHolidaysService(String(id));
          dispatch(deleteMarketPublicHolidaysAction(id));
        },
        cancelCallback: () => null,
      });
    } catch (e: any) {
      toastError(e?.status?.message);
    }
  };

  useEffect(() => {
    _setDatesUtil(moment().daysInMonth());

    // Month
    const months = moment.months().map((name, index) => {
      return { id: `month-${index}`, value: String(index + 1), text: name };
    });
    setMonthOptions(months);

    // Year
    const currentYear = moment().get("year");
    const yearTemp = new Array(20).fill("").map((x, i) => currentYear + i);
    const years = yearTemp.map((name) => {
      const year = String(name);
      return { id: `year-${name}`, value: year, text: year };
    });

    setYearOptions([
      { id: "year-optional", text: "---", value: SELECT_YEAR_NONE_VALUE },
      ...years,
    ]);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await dispatch<any>(configMarketPublicHolidaysListThunk(payload));
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [payload]);

  const _formatInputData = () => {
    if (!publicHolidaysList?.item) {
      return [];
    }
    return publicHolidaysList?.item?.map(
      ({ id, name, date }: ConfigMarketPublicHolidaysDataType) => {
        return {
          name,
          date,
          action: (
            <ButtonCustom
              className={"button-delete-public-holidays"}
              fontSize={"22px"}
              padding={"12px"}
              onClick={() => _onDeletePublicHolidays(id)}
            >
              <i className="uil uil-trash" />
            </ButtonCustom>
          ),
        };
      }
    );
  };

  const _setDatesUtil = (daysInMonth: number) => {
    if (dateOptions.length === daysInMonth) {
      return;
    }

    const dates = new Array(daysInMonth).fill("").map((item, index) => {
      const date = String(index + 1);
      return { id: `date-${date}`, value: date, text: date };
    });
    setDateOptions(dates);
  };

  const _handleChangeMonth = ({
    date,
    month,
    year,
    setFieldValue,
  }: {
    date: string;
    month: string;
    year: string;
    setFieldValue: any;
  }) => {
    setFieldValue("month", month);
    let dayInMonth = moment(month, "MM").daysInMonth();

    if (String(year) !== SELECT_YEAR_NONE_VALUE) {
      dayInMonth = moment(`${month}-${year}`, "MM-YYYY").daysInMonth();
    }
    _setDatesUtil(dayInMonth);

    if (Number(date) > dayInMonth) {
      setFieldValue("date", dayInMonth);
    }
  };

  const _handleChangeYear = ({
    date,
    month,
    year,
    setFieldValue,
  }: {
    date: string;
    month: string;
    year: string;
    setFieldValue: any;
  }) => {
    setFieldValue("year", year);
    let dayInMonth;

    if (String(year) !== SELECT_YEAR_NONE_VALUE) {
      dayInMonth = moment(`${month}-${year}`, "MM-YYYY").daysInMonth();
    } else {
      dayInMonth = moment(month, "MM").daysInMonth();
    }
    _setDatesUtil(dayInMonth);

    if (Number(date) > dayInMonth) {
      setFieldValue("date", dayInMonth);
    }
  };

  const _onSubmit = async (
    values: FormikValues,
    { resetForm }: FormikHelpers<FormikValues>
  ) => {
    try {
      const payload = {
        market_id: id,
        name: values.name,
        day: values.date,
        month: values.month,
        year: values.year,
      };
      await configCreateMarketPublicHolidayService(payload);
      await dispatch<any>(configMarketPublicHolidaysListThunk(DEFAULT_PAYLOAD));
      resetForm();
      toastSuccess("Created Public Holiday Successfully");
    } catch (e: any) {
      toastError(e?.status?.message);
    }
  };

  return (
    <>
      {loading ? (
        <Dimmer active>
          <Loader />
        </Dimmer>
      ) : (
        <div className={"section-white-box"}>
          <Label
            label={translations.editMarket.editMarketTitle}
            fontSize={"24px"}
            className={"section-title"}
          />
          <Formik
            enableReinitialize
            initialValues={{
              name: "",
              date: moment().get("date").toString(),
              month: (moment().get("month") + 1).toString(),
              year: SELECT_YEAR_NONE_VALUE,
            }}
            validationSchema={yup.object().shape({
              name: yup.string().required().label("Name"),
            })}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onSubmit={_onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              resetForm,
            }) => (
              <>
                <div className={"input-section-public-holidays"}>
                  <InputCustom
                    label={"Public Holiday Name"}
                    labelRequired
                    value={values.name}
                    name={"name"}
                    onChange={handleChange}
                    containerClass={"input-name-public-holidays"}
                    error={errors.name}
                    touched={touched.name}
                  />
                  <div className={"input-right-section-public-holidays"}>
                    <div>
                      <Label label={"Date"} fontSize="12px" />
                      <DropdownCustom
                        selection
                        selectUI
                        options={dateOptions}
                        value={values.date}
                        onChange={(e, { value }) => {
                          setFieldValue("date", value);
                        }}
                      />
                    </div>
                    <div className={"input-month-section-public-holidays"}>
                      <Label label={"Month"} labelRequired fontSize="12px" />
                      <DropdownCustom
                        selection
                        selectUI
                        options={monthOptions}
                        value={values.month}
                        onChange={(e, { value }) => {
                          if (typeof value !== "string") {
                            return;
                          }
                          _handleChangeMonth({
                            date: values.date,
                            month: value,
                            year: values.year,
                            setFieldValue,
                          });
                        }}
                      />
                    </div>
                    <div>
                      <Label label={"Year (optional)"} fontSize="12px" />
                      <DropdownCustom
                        selection
                        selectUI
                        options={yearOptions}
                        value={values.year}
                        onChange={(e, { value }) => {
                          if (typeof value !== "string") {
                            return;
                          }
                          _handleChangeYear({
                            date: values.date,
                            month: values.month,
                            year: value,
                            setFieldValue,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <Label label={"Public Holidays"} fontSize="16px" />
                    <TableCustom
                      loading={loading}
                      columns={columns}
                      tableData={publicHolidaysList ? _formatInputData() : []}
                      currentPage={publicHolidaysList?.pagination?.page}
                      totalItems={publicHolidaysList?.pagination?.total}
                      unit={""}
                      showTotal={false}
                      onChangePagination={_onChangePagination}
                    />
                  </div>
                  <div
                    className={
                      "section-white-box-actions action-section-public-holiday"
                    }
                  >
                    <ButtonCustom
                      className={"cancel mr-10px"}
                      onClick={resetForm}
                    >
                      {translations.cancel}
                    </ButtonCustom>
                    <ButtonCustom
                      className={"primary mr-0"}
                      onClick={handleSubmit}
                      loading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      {translations.save}
                    </ButtonCustom>
                  </div>
                </div>
              </>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default PublicHolidaysManagementTab;
