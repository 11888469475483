import React, { useEffect, useState } from "react";
import "./styles.scss";
import UserSummary from "./Components/UserSummary";
import UserDetail from "./Components/UserDetail";
import MasterTemplate from "../../../App/views/MasterTemplate";
import UsageHistory from "./Components/UsageHistory";
import UserDetailReviews from "./Components/UserDetailReviews";
import { useParams } from "react-router-dom";
import ModalEditEmployee from "../UsersDeskimo/Components/ModalEditEmployee";
import { useDispatch, useSelector } from "react-redux";
import { deskimoUserDetailThunk } from "../../thunks/EmployeeThunks";
import { ROLE_DESKIMO_ADMIN_ID } from "../../configs/Constants";
import SectionWhiteBox from "../../../../components/SectionWhiteBox";
import ButtonCustom from "../../../../components/Button";
import {
  modalBookingDeskManualAction,
  modalBookingRoomManualAction,
  modalCreateWalkInsManualAction,
} from "../../../Dashboard/actions/DashboardActions";
import SizeBox from "../../../../components/SizeBox";
import ModalBookingRoom from "../../../Dashboard/views/DashboardDeskimo/Components/ModalBookingRoom";
import ModalBookingDesk from "../../../Dashboard/views/DashboardDeskimo/Components/ModalBookingDesk";
import ModalCreateWalkIns from "../../../Dashboard/views/DashboardDeskimo/Components/ModalCreateWalkIns";
import { employeeDetailSelector } from "../../selectors/EmployeeSelectors";
import LoaderCustom from "../../../../components/Loader";
import VoucherUsage from "./Components/VoucherUsage";

const UserDetails = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const userDetail = useSelector(employeeDetailSelector);
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        if (!id) {
          return;
        }
        await dispatch<any>(deskimoUserDetailThunk(id));
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <MasterTemplate
        containerClass={"user-detail-page"}
        roleAccept={[ROLE_DESKIMO_ADMIN_ID]}
      >
        {loading ? (
          <LoaderCustom fullScreen />
        ) : (
          <SectionWhiteBox isShowBack>
            <div className={"manual-booking-section"}>
              <ButtonCustom
                onClick={() =>
                  dispatch(modalCreateWalkInsManualAction({ open: true }))
                }
                height={"36px"}
                className={"outline-primary"}
              >
                Create Walk-In
              </ButtonCustom>
              <SizeBox width={"8px"} />
              <ButtonCustom
                onClick={() =>
                  dispatch(modalBookingDeskManualAction({ open: true }))
                }
                height={"36px"}
                className={"outline-primary"}
              >
                Book a desk
              </ButtonCustom>
              <SizeBox width={"8px"} />
              <ButtonCustom
                onClick={() =>
                  dispatch(modalBookingRoomManualAction({ open: true }))
                }
                height={"36px"}
                className={"outline-primary"}
              >
                Book a room
              </ButtonCustom>
            </div>
            <UserSummary />
            <UserDetail />
            <UsageHistory />
            <VoucherUsage />
            <UserDetailReviews />
            <ModalBookingRoom userDetail={userDetail} />
            <ModalBookingDesk userDetail={userDetail} />
            <ModalCreateWalkIns userDetail={userDetail} />
          </SectionWhiteBox>
        )}
      </MasterTemplate>
      <ModalEditEmployee />
    </>
  );
};

export default UserDetails;
