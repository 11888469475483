import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import { Chart, registerables } from "chart.js";
import { DashboardStatisticType } from "../../../configs/DeclareTypes";
import { useSelector } from "react-redux";
import { dashboardStatisticSelector } from "../../../selectors/DashboardSelectors";
import UtilDoughnutChart from "./UtilDoughnutChart";

Chart.register(...registerables);

const Account = () => {
  const { translations } = useContext(LocalizationContext);
  const dashboardStatistic: DashboardStatisticType = useSelector(
    dashboardStatisticSelector
  );

  return (
    <div className={"dashboard_chart_box_wrapper"}>
      <div className={"dashboard_chart_box"}>
        <h2 className={"dashboard_chart_title"}>
          {translations.currentDeskimoAccounts}
        </h2>
        <div className={"dashboard_chart_inner"}>
          <UtilDoughnutChart
            color={["#FFBE00", "#16C79A", "#EE261A"]}
            labels={[
              translations.pending,
              translations.active,
              translations.deactivated,
            ]}
            data={dashboardStatistic.current_user}
          />
        </div>
      </div>
    </div>
  );
};

export default Account;
