import { Slider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { deskimoPrivateOfficeFiltersTmpSelector } from "../../../../selectors/PropertySelector";
import { deskimoPrivateOfficeFilterTmpAction } from "../../../../actions/PropertyActions";
import { debounce } from "lodash";

const SelectCapacity = () => {
  const dispatch = useDispatch();
  const filtersTmp = useSelector(deskimoPrivateOfficeFiltersTmpSelector);

  const marks = {
    1: "1 person",
    50: "Any",
  };

  const onChange = (value: any) => {
    if (value >= 50) {
      dispatch(
        deskimoPrivateOfficeFilterTmpAction({
          ...filtersTmp,
          filter: { ...filtersTmp.filter, capacity: "all" },
        })
      );
    } else {
      dispatch(
        deskimoPrivateOfficeFilterTmpAction({
          ...filtersTmp,
          filter: { ...filtersTmp.filter, capacity: value },
        })
      );
    }
  };

  return (
    <div className={"pricing-rate-section"}>
      <Slider
        value={
          filtersTmp?.filter.capacity === "all"
            ? 50
            : filtersTmp?.filter.capacity
        }
        onChange={(value: any) => {
          onChange(value);
        }}
        min={1}
        max={50}
        marks={marks}
        defaultValue={50}
      />
    </div>
  );
};

export default SelectCapacity;
