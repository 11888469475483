import React, { useContext, useState } from "react";
import { PUBLIC_URL } from "../../../configs/Constants";
import { LocalizationContext } from "../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import {
  creditRemaining,
  myProfileSelector,
} from "../../../selectors/AppSelectors";
import { SWAL_DELETE_OPTION } from "../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../components/SweetAlert";
import { stopAutoTopUpService } from "../../../services/AppServices";
import { getCreditRemainingThunk } from "../../../thunks/AppThunk";
import CreditBalancePopup from "./CreditBalancePopup";
import { Popup } from "semantic-ui-react";
import { UserCompanyType } from "../../../../User/configs/DeclareTypes";
import { userCompanyChooseSelector } from "../../../../User/selectors/UserSelectors";

type Props = {
  isMobile?: boolean;
};
const CreditBalance = ({ isMobile }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const remainingCredit = useSelector(creditRemaining);
  const userProfile = useSelector(myProfileSelector);
  const userCompanyChoose: UserCompanyType = useSelector(
    userCompanyChooseSelector
  );

  const _stopAutoTopUp = () => {
    if (loading) return;
    const icon = null;
    const title = `Deskimo will top up ${remainingCredit?.recharge_amount} automatically when the balance falls below ${remainingCredit?.trigger_amount}`;
    const text = "Do you want want to stop the auto top-up?";
    const confirmText = "Stop automatic top-up  ";
    const containerSize = "md";
    const options = SWAL_DELETE_OPTION({
      title,
      text,
      icon,
      confirmText,
      containerSize,
      showCloseButton: true,
    });
    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setLoading(true);
        try {
          await stopAutoTopUpService();
          await dispatch<any>(getCreditRemainingThunk());
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  return (
    <div className={"credit-balance"}>
      <div className={"credit-balance-top"}>
        {translations.prepaidCreditBalance}
        {userCompanyChoose?.can_buy_credit ? <CreditBalancePopup /> : null}
      </div>
      <div className={"credit-balance-bottom"}>
        {remainingCredit?.remaining_credit}
        {remainingCredit?.auto_recharge && userProfile?.login_on_behalf ? (
          <img
            style={loading ? { opacity: 0.5 } : { opacity: 1 }}
            onClick={_stopAutoTopUp}
            src={PUBLIC_URL + "/assets/icons/icon-arrow-repeat.svg"}
            alt="icon-repeat"
            className={"clickable"}
          />
        ) : remainingCredit?.auto_recharge ? (
          <Popup
            content={translations?.stopAutoTopUp}
            trigger={
              <img
                src={PUBLIC_URL + "/assets/icons/icon-arrow-repeat.svg"}
                alt="icon-repeat"
              />
            }
            position={isMobile ? "bottom left" : "bottom center"}
            hoverable
          />
        ) : null}
      </div>
    </div>
  );
};

export default CreditBalance;
