import React, { useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  DROPZONE_MAX_FILE_3MB,
  DROPZONE_MAX_FILE_3MB_TEXT,
} from "../../../../App/configs/DropzoneConfig";
import { uploadContractFileService } from "../../../../App/services/AppServices";
import { toastError, toastSuccess } from "../../../../../components/Toast";
import { LocalizationContext } from "../../../../../locales/Translation";
import Label from "../../../../../components/Label";

type Props = {
  onChangeUri: any;
  contractFile?: string;
};

const DropzoneContract = ({ onChangeUri, contractFile }: Props) => {
  const { translations } = useContext(LocalizationContext);

  const [fileUrl, setFileUrl] = useState<string>("");
  const [isDragOver, setIsDragOver] = useState(false);

  useEffect(() => {
    if (contractFile) {
      setFileUrl(contractFile);
    }
  }, [contractFile]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/pdf",
    maxSize: DROPZONE_MAX_FILE_3MB,
    useFsAccessApi: false,
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles?.length > 0) {
        const formData = new FormData();
        formData.append("contract_file", acceptedFiles[0]);
        try {
          const { data } = await uploadContractFileService(formData);
          console.log("data", data);
          onChangeUri(data?.contract_file_uri);
          if (data) {
            setFileUrl(data?.uri);
            toastSuccess("Uploaded Successfully");
          }
        } catch (error) {
          console.log("Error: ", error);
        }

        setIsDragOver(false);
      }
    },

    onDropRejected: (fileRejections) => {
      let isFileTooLarge;
      fileRejections.forEach((file) => {
        isFileTooLarge =
          file.errors.filter((error) => error.code === "file-too-large")
            .length > 0;
        if (isFileTooLarge) {
          toastError(
            `File ${file.file.name} is larger than ${DROPZONE_MAX_FILE_3MB_TEXT}`
          );
        }
      });
    },

    onDragOver: () => {
      setIsDragOver(true);
    },

    onDragLeave: () => {
      setIsDragOver(false);
    },
  });

  return (
    <>
      <Label className={"label label-xl"} label={"Contract File"} />
      <div
        {...getRootProps({
          className: "dropzone dropzone-box" + (isDragOver ? " drag-over" : ""),
        })}
      >
        <input {...getInputProps()} />
        <div className={"dropzone-guide-title"}>
          {translations.dropYourImageFilesHereOr}
        </div>
        <div className={"dropzone-guide-subtitle"}>
          PDF {translations.maxFileSizeOf} 3MB
        </div>
      </div>
      {fileUrl ? (
        <a href={fileUrl} target={"_blank"} rel="noreferrer">
          <i className="uil uil-download-alt"></i> Download link here.
        </a>
      ) : null}
    </>
  );
};

export default DropzoneContract;
