import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ACCESS_TOKEN_CACHE } from "../../../../configs/Constants";
import { logoutService } from "../../../User/services/UserServices";
import { isAuthAction } from "../../actions/AuthenticationActions";
import LoaderCustom from "../../../../components/Loader";
import { customerIOClearIdentifyUser } from "../../../../services/customer-io/CustomerIOService";

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const accessToken = localStorage.getItem(ACCESS_TOKEN_CACHE);
        if (accessToken) {
          await logoutService();
        }
      } finally {
        customerIOClearIdentifyUser();
        localStorage.removeItem(ACCESS_TOKEN_CACHE);
        dispatch(isAuthAction(false));
        window.location.href = "/login";
      }
    })();
  }, []);

  return <LoaderCustom />;
};

export default Logout;
