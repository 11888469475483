import React, { useContext, useState } from "react";
import DropdownCustom from "../../../../components/DropdownCustom";
import ButtonCustom from "../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import { PUBLIC_URL } from "../../../App/configs/Constants";
import { SWAL_DELETE_OPTION } from "../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../components/SweetAlert";
import { employeePropertyDeleteService } from "../../../User/services/EmployeeServices";
import { toastError, toastSuccess } from "../../../../components/Toast";
import { LocalizationContext } from "../../../../locales/Translation";
import {
  deleteConfigPrepaidCreditPackageService,
  updateStatusConfigPrepaidCreditPackageService,
} from "../../services/ConfigServices";
import {
  propertyStaffActivateService,
  propertyStaffDeactivateService,
} from "../../../Property/services/PropertyStaffServices";
import { deskimoPropertyStaffChangeStatusAction } from "../../../Property/actions/PropertyActions";

type Props = {
  id: number;
  status: number;
  setIsOpenModal: any;
  onSetRefreshing: any;
};

const Action = ({ id, status, setIsOpenModal, onSetRefreshing }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const [disabled, setDisabled] = useState(false);

  const _onChangeStatus = async () => {
    const icon = null;
    const title =
      status > 0
        ? "Are you sure you want to un-publish this prepaid credit package?"
        : "Are you sure you want to publish this prepaid credit package?";
    const confirmText = status > 0 ? "Un-publish" : "Publish";
    const cancelText = translations.cancel;
    const confirmClass = status > 0 ? "danger" : "primary";
    const options = SWAL_DELETE_OPTION({
      title,
      icon,
      confirmText,
      confirmClass,
      cancelText,
    });
    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setDisabled(true);
        try {
          await updateStatusConfigPrepaidCreditPackageService(
            id.toString(),
            status > 0 ? "un-publish" : "publish"
          );
          onSetRefreshing();
        } catch (response: any) {
          toastError(response?.status?.message);
        } finally {
          setDisabled(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  const _onDelete = async () => {
    const iconHtml = (
      <img
        src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
        className={"swal2-icon__error"}
      />
    );
    const title =
      "Are you sure you want to delete this prepaid credit package?";
    const confirmText = translations.delete;
    const cancelText = translations.cancel;
    const options = SWAL_DELETE_OPTION({
      title,
      iconHtml,
      confirmText,
      cancelText,
    });
    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setDisabled(true);
        try {
          await deleteConfigPrepaidCreditPackageService(id.toString());
          onSetRefreshing();
        } catch (response: any) {
          toastError(response?.status?.message);
        } finally {
          setDisabled(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  return (
    <DropdownCustom
      disabled={disabled}
      className={"table-action-dropdown"}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        <>
          <Dropdown.Item
            onClick={() => setIsOpenModal({ open: true, data: id })}
            text={"Edit"}
            icon={
              <i className="uil uil-edit icon" style={{ fontSize: "16px" }} />
            }
          />
          <Dropdown.Item
            onClick={_onChangeStatus}
            text={status > 0 ? "Un-publish" : "Publish"}
            icon={
              <i
                className="uil uil-toggle-off icon"
                style={{ fontSize: "16px" }}
              />
            }
          />
          <Dropdown.Item
            onClick={_onDelete}
            text={"Delete"}
            icon={
              <i
                className="uil uil-trash-alt icon"
                style={{ fontSize: "16px" }}
              />
            }
          />
        </>
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default Action;
