import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import SizeBox from "../../../../../../components/SizeBox";
import { DeskimoStatisticType } from "../../../../configs/DeclareTypes";
import { useSelector } from "react-redux";
import { dashboardStatisticDeskimoSelector } from "../../../../selectors/DashboardSelectors";

const VisitAndBooking = () => {
  const dashboardStatisticDeskimo: DeskimoStatisticType = useSelector(
    dashboardStatisticDeskimoSelector
  );

  return (
    <div className={"dashboard_chart_box_wrapper"}>
      <div className={"dashboard_chart_box"}>
        <h2 className={"dashboard_chart_title"}>Visits and Bookings</h2>
        <div className={"dashboard_chart_line"}>
          <h2 className={"dashboard_chart_sub_title"}>Total</h2>
          <SizeBox height={"18px"} />
          <span className={"dashboard_chart_title"}>
            {dashboardStatisticDeskimo.total_visit_and_booking || 0}
          </span>
        </div>
        <div className={"dashboard_chart_line"}>
          <div className={"d-flex"}>
            <div className={"col-4"}>
              <h2 className={"dashboard_chart_sub_title"}>Desk Visits</h2>
              <SizeBox height={"18px"} />
              <span className={"dashboard_chart_title"}>
                {dashboardStatisticDeskimo.total_visit || 0}
              </span>
            </div>
            <div className={"col-4"}>
              <h2 className={"dashboard_chart_sub_title"}>Day Passes</h2>
              <SizeBox height={"18px"} />
              <span className={"dashboard_chart_title"}>
                {dashboardStatisticDeskimo.total_hotdesk_booking || 0}
              </span>
            </div>
            <div className={"col-4"}>
              <h2 className={"dashboard_chart_sub_title"}>Room Bookings</h2>
              <SizeBox height={"18px"} />
              <span className={"dashboard_chart_title"}>
                {dashboardStatisticDeskimo.total_room_booking || 0}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitAndBooking;
