import React, { useEffect, useState } from "react";
// import { LocalizationContext } from "../../../../../../locales/Translation";
import { useSelector } from "react-redux";
import {
  deskimoPropertyStaffListFilterSelector,
  deskimoPropertyStaffListSelector,
} from "../../../../selectors/PropertySelector";
import TableCustom from "../../../../../../components/Table";
import Company from "../../../../../User/views/UsersDeskimo/Components/EmployeeTable/Company";
import Action from "./Action";
import MarketFilterMultiple from "../../../../../../components/FilterMarket/MarketFilterMultiple";
import { Label as LabelSemantic } from "semantic-ui-react";

type Props = {
  loading: boolean;
  onChangeSortCol: any;
  onChangePagination: any;
  onChangeMarketFilter: any;
};

const DeskimoPropertiesStaffListTable = ({
  loading,
  onChangeSortCol,
  onChangePagination,
  onChangeMarketFilter,
}: Props) => {
  // const { translations } = useContext(LocalizationContext);
  const staffList = useSelector(deskimoPropertyStaffListSelector);
  const staffListFilter = useSelector(deskimoPropertyStaffListFilterSelector);
  const [staffTable, setStaffTable] = useState([]);

  const columns = [
    { key: "name", col: "User name" },
    { key: "company", col: "Company" },
    { key: "email", col: "Email" },
    { key: "phone", col: "Contact Phone Number" },
    { key: "status", col: "Status" },
    { key: null, col: "Action" },
  ];

  useEffect(() => {
    if (staffList && staffList.property_users) {
      const data = staffList.property_users.map((user: any) => {
        return {
          col1: (
            <div>
              {user?.full_name}
              {user?.is_admin && (
                <LabelSemantic size={"mini"} className={"ml-4px"}>
                  Admin
                </LabelSemantic>
              )}
            </div>
          ),
          col2: <Company company={user?.property_staffs} />,
          col3: user?.email,
          col4: user?.phone ? user?.phone : "-",
          col5: user?.status_name,
          col6: (
            <Action
              userId={user?.id}
              entitlementId={user?.property_company_id}
              userStatus={user?.status}
              companyId={user?.company_id}
              loginOnBehalf={user?.login_on_behalf}
            />
          ),
        };
      });
      setStaffTable(data as never);
    } else {
      setStaffTable([]);
    }
  }, [staffList]);

  return (
    <div>
      <TableCustom
        noHeader={false}
        loading={loading}
        showTotal
        tableData={staffTable}
        sortTable={true}
        onChangeSortCol={onChangeSortCol}
        sortBy={staffListFilter.sort_by}
        orderBy={staffListFilter.order_by}
        columns={columns}
        currentPage={staffListFilter.page}
        totalItems={staffList?.pagination?.total}
        itemPerPage={staffList?.pagination?.limit}
        unit={"staffs"}
        onChangePagination={onChangePagination}
        topRightComponent={
          <MarketFilterMultiple
            onChange={onChangeMarketFilter}
            values={staffListFilter.market_codes}
            isSubmitting={loading}
          />
        }
      />
    </div>
  );
};

export default DeskimoPropertiesStaffListTable;
