import React, { useContext } from "react";
import Label from "../../../../../../components/Label";
import SizeBox from "../../../../../../components/SizeBox";
import { LocalizationContext } from "../../../../../../locales/Translation";
import classNames from "classnames";

type Props = {
  value: string[];
  onChange: (value: string[]) => void;
};

const WeekDays = ({ value, onChange }: Props) => {
  const { translations } = useContext(LocalizationContext);

  const WEEK_DAYS = [
    {
      name: "Mon",
      value: "mon",
    },
    {
      name: "Tue",
      value: "tue",
    },
    {
      name: "Wed",
      value: "wed",
    },
    {
      name: "Thu",
      value: "thu",
    },
    {
      name: "Fri",
      value: "fri",
    },
    {
      name: "Sat",
      value: "sat",
    },
    {
      name: "Sun",
      value: "sun",
    },
  ];

  const _onClick = (itemValue: string) => {
    if (value.includes(itemValue)) {
      onChange(value.filter((item) => item !== itemValue));
      return;
    }

    onChange([...value, itemValue]);
  };

  return (
    <div>
      <Label label={translations.selectUp3Weekdays} />
      <SizeBox height={"8px"} />
      <div className={"book-inquire_week-day"}>
        {WEEK_DAYS.map(({ name, value: itemValue }) => (
          <div
            onClick={() => _onClick(itemValue)}
            className={classNames("item", {
              active: value.includes(itemValue),
            })}
            key={itemValue}
          >
            {name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default WeekDays;
