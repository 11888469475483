import React, { useRef, useContext, useState } from "react";
import { Modal } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import {
  modalAddEmployeeAction,
  modalImportEmployeeAction,
  userGuideAction,
} from "../../actions/AppActions";
import {
  isModalAddEmployeeSelector,
  userGuideSelector,
} from "../../selectors/AppSelectors";
import "./styles.scss";
import ButtonCustom from "../../../../components/Button";
import ModalCenterTemplate from "../ModalCenterTemplate";
import { PUBLIC_URL } from "../../configs/Constants";
import { Formik } from "formik";
import * as yup from "yup";
import InputCustom from "../../../../components/Input";
import Label from "../../../../components/Label";
import { DIALING_CODE } from "../../configs/PhonePrefix";
import SizeBox from "../../../../components/SizeBox";
import BoxToggleSlider from "../../../../components/BoxToggleSlider";
import { employeeImportService } from "../../../User/services/EmployeeServices";
import { LocalizationContext } from "../../../../locales/Translation";
import { toastError, toastSuccess } from "../../../../components/Toast";
import ModalCropImage from "../ModalCropImage";
import { employeesThunk } from "../../../User/thunks/EmployeeThunks";
import { fileValidation, containWords } from "../../../../utils/commons";
import MakeAdminLabelGuide from "./MakeAdminLableGuide";
import InputPhoneFlag from "../../../../components/InputPhoneFlag";
import { userCompanyChooseSelector } from "../../../User/selectors/UserSelectors";
import { useNavigate } from "react-router-dom";
import { EMPLOYEES_BUSINESS_LINK } from "../../configs/ListRoutes";

const AddEmployeeModal = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isModalAddEmployee = useSelector(isModalAddEmployeeSelector);
  const uploadFileRef = useRef<any>();
  const [previewImage, setPreviewImage] = useState(null);
  const [openModalCrop, setOpenModalCrop] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const userGuide = useSelector(userGuideSelector);
  const userCompanyChoose = useSelector(userCompanyChooseSelector);
  const EXIST_USER_STRINGS = [
    "Your invitation has been sent",
    "regarding your invitation request",
  ];

  const _onClickSave = async (values: any, { resetForm }: any) => {
    try {
      const payload = { ...values };
      delete payload.isAdmin;
      if (values.isAdmin) {
        payload.role_id = 2;
      }

      payload.country_code = DIALING_CODE.filter(
        (country) =>
          country.key.trim().toLowerCase() ===
          values.country_code.trim().toLowerCase()
      )[0].value.replaceAll("+", "");

      const { data } = await employeeImportService([payload], 1);
      if (data.success.length) {
        toastSuccess(translations.employeeAddedSuccessfully);
        await dispatch<any>(employeesThunk());
        resetForm();
        setPreviewImage(null);
        setImgSrc(null);
        _onCloseModal();
        navigate(EMPLOYEES_BUSINESS_LINK);
        _goToUserGuideNextStep();
      } else {
        const firstUser = data?.fail[0];
        const message = firstUser?.message;

        if (containWords(message, EXIST_USER_STRINGS)) {
          toastSuccess(message);
          _onCloseModal();
        } else {
          toastError(message || firstUser);
        }
      }
    } catch (e) {
      console.log("e", e);
    }
  };

  const handleUploadFile = async (e: any) => {
    const reader: any = new FileReader();
    reader.addEventListener("load", () =>
      setImgSrc(reader.result.toString() || "")
    );
    reader.readAsDataURL(e.target.files[0]);
    setOpenModalCrop(true);
  };

  const PreviewImage = ({ uri }: any) => {
    return <img src={uri} />;
  };

  const _onCloseModal = () => {
    setPreviewImage(null);
    dispatch(modalAddEmployeeAction(false));
    _goToUserGuideNextStep();
  };

  const _goToUserGuideNextStep = () => {
    if (userGuide?.run && userGuide?.stepIndex === 3) {
      dispatch(modalAddEmployeeAction(false));
      dispatch(modalImportEmployeeAction(true));
      setTimeout(() => {
        dispatch(userGuideAction({ key: "stepIndex", value: 4 }));
        dispatch(userGuideAction({ key: "run", value: true }));
      }, 400);
    }
  };

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={_onCloseModal}
      modalClassName={"modal-general guide-modal-add-employee"}
      open={isModalAddEmployee}
    >
      <Modal.Header>
        {translations.addEmployee}
        <ButtonCustom onClick={_onCloseModal} className={"modal-close-cta"}>
          <>
            <span>{translations.close}</span>
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Formik
        initialValues={{
          avatar_uri: "",
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          country_code: userCompanyChoose?.alpha2_country_code || "SG",
          isAdmin: false,
        }}
        validationSchema={yup.object().shape({
          first_name: yup.string().required().label("First Name"),
          email: yup.string().email().required().label("Email"),
          phone: yup.number(),
        })}
        onSubmit={_onClickSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <>
            <Modal.Content>
              <form className={"form-layout"}>
                <div>
                  <Label
                    className={"sm"}
                    label={translations.profilePicture}
                    labelGuide={translations.uploadImageFormat}
                  />

                  <div className={"d-flex align-items-center mb-48px"}>
                    <div className={"my-profile__avatar"}>
                      {previewImage ? (
                        <PreviewImage uri={previewImage} />
                      ) : (
                        <img
                          src={
                            values?.avatar_uri
                              ? values?.avatar_uri
                              : PUBLIC_URL + "/assets/default-add-avatar.svg"
                          }
                        />
                      )}

                      <input
                        className={"d-none"}
                        ref={uploadFileRef}
                        type="file"
                        accept="image/*"
                        onChange={async (event: any) => {
                          const isFileValid = fileValidation(
                            event.target.files
                          );

                          if (isFileValid) {
                            const _URL = window.URL || window.webkitURL;
                            const img = new Image();
                            const objectUrl = _URL.createObjectURL(
                              event.target.files[0]
                            );

                            img.src = objectUrl;
                            img.onload = async () => {
                              _URL.revokeObjectURL(objectUrl);
                              await handleUploadFile(event);
                              event.target.value = null;
                            };
                            setOpenModalCrop(true);
                          } else {
                            toastError("The file must be smaller than 2MB");
                          }
                        }}
                      />
                    </div>
                    <SizeBox width={"32px"} />

                    <ButtonCustom
                      onClick={(e: any) => {
                        e.preventDefault();
                        uploadFileRef.current.click();
                      }}
                      className={"primary-alt"}
                      height={"36px"}
                    >
                      <>
                        {translations.uploadImage}
                        <SizeBox width={"14px"} />
                        <i className="uil uil-upload-alt" />
                      </>
                    </ButtonCustom>
                  </div>
                </div>
                <SizeBox height={"12px"} />
                <div className={"form-row"}>
                  <InputCustom
                    labelRequired={true}
                    label={"First Name"}
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.first_name}
                    error={errors.first_name}
                    name={"first_name"}
                  />
                  <InputCustom
                    label={translations.lastName}
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.last_name}
                    error={errors.last_name}
                    name={"last_name"}
                  />
                </div>
                <InputCustom
                  labelRequired
                  label={translations.emailAddress}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.email}
                  error={errors.email}
                  name={"email"}
                />
                <div className={"mb-16px"} />
                <InputPhoneFlag
                  label={translations.mobilePhoneNumber}
                  labelGuide={translations.addMobilePhoneLabelGuide}
                  selectedFlag={values.country_code}
                  onSelectFlag={(code) => setFieldValue("country_code", code)}
                  error={errors.phone}
                  touched={touched.phone}
                  phoneChange={(value) => setFieldValue("phone", value)}
                  phoneValue={values.phone}
                />
                <SizeBox height={"7px"} />
                <span className={"mini-text symbol-required-pre"}>
                  {translations.mandatoryFields}
                </span>
                <SizeBox height={"12px"} />
                <BoxToggleSlider
                  content={
                    <Label
                      fontSize={"14px"}
                      size={"16px"}
                      label={translations.makeAdmin}
                      labelGuide={<MakeAdminLabelGuide />}
                    />
                  }
                  value={values.isAdmin}
                  onChange={() => setFieldValue("isAdmin", !values.isAdmin)}
                />
                <SizeBox height={"12px"} />
                <ButtonCustom
                  onClick={() => {
                    dispatch(modalAddEmployeeAction(false));
                    dispatch(modalImportEmployeeAction(true));

                    _goToUserGuideNextStep();
                  }}
                  height={"36px"}
                  className={"primary-alt"}
                >
                  <>
                    {translations.bulkInvite} (XLSX) <SizeBox width={"14px"} />
                    <i className="uil uil-upload-alt" />
                  </>
                </ButtonCustom>
                <ModalCropImage
                  imgSrc={imgSrc}
                  open={openModalCrop}
                  setOpen={setOpenModalCrop}
                  setFieldValue={setFieldValue}
                  fieldKey={"avatar_uri"}
                  setPreviewImage={setPreviewImage}
                />
              </form>
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom
                disabled={isSubmitting}
                height={"47px"}
                onClick={_onCloseModal}
              >
                {translations.cancel}
              </ButtonCustom>
              <ButtonCustom
                loading={isSubmitting}
                disabled={isSubmitting}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {translations.add}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalCenterTemplate>
  );
};

export default AddEmployeeModal;
