import React, { useContext, useRef, useState } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import {
  creditPackageAmountInfoService,
  purchaseCustomPackageService,
} from "../../../services/BusinessServices";
import { toastError, toastSuccess } from "../../../../../components/Toast";
import { Formik, FormikValues } from "formik";
import ModalRightSideBarTemplate from "../../../../App/views/ModalRightSideBarTemplate";
import * as yup from "yup";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../components/Button";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import InputCustom from "../../../../../components/Input";
import TextAreaCustom from "../../../../../components/Input/TextArea";
import SizeBox from "../../../../../components/SizeBox";
import BoxToggleSlider from "../../../../../components/BoxToggleSlider";
import Label from "../../../../../components/Label";
import DropdownCompany from "./DropdownCompay";
import { validateNumber } from "../../../../../services/FormatTextService";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import { BUSINESS_COMPANY_LIST_LINK } from "../../../../App/configs/ListRoutes";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSetRefreshing: () => void;
};
const ModalAddCredit = ({ isOpen, onClose, onSetRefreshing }: Props) => {
  const { translations } = useContext(LocalizationContext);

  const formRef = useRef(null);
  const [buyNowSubmitting, setBuyNowSubmitting] = useState<boolean>(false);
  const [reserveSubmitting, setReserveSubmitting] = useState<boolean>(false);

  const [creditAmountInfo, setCreditAmountInfo] = useState<any>(null);
  const _handleSubmit = async (values: FormikValues) => {
    try {
      if (values.is_reserve) {
        setReserveSubmitting(true);
      } else {
        setBuyNowSubmitting(true);
      }
      const payload = { ...values };
      delete payload.company_name;
      delete payload.price;
      delete payload.currency;
      delete payload.company_recharge_amount;
      delete payload.company_trigger_amount;
      delete payload.company_discount_percent;
      delete payload.auto_topup_credit;

      await purchaseCustomPackageService(payload);
      toastSuccess(translations.createSuccessPrompt);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formRef.current.resetForm();
      onClose();
      onSetRefreshing();
    } catch (e: any) {
      if (e?.status?.code === 2030) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formRef.current.resetForm();
        onClose();
        onSetRefreshing();
      }
      toastError(e?.status?.message);
    } finally {
      setBuyNowSubmitting(false);
      setReserveSubmitting(false);
    }
  };

  const _getCreditAmountInfo = debounce(async (payload: object) => {
    const { data } = await creditPackageAmountInfoService(payload);
    setCreditAmountInfo(data);
  }, 300);

  const _renderPercent = (
    amount: string | number,
    percent: string | number
  ) => {
    const value = (
      (Number(amount || 0) * (100 - Number(percent || 0))) /
      100
    ).toFixed(2);
    return value;
  };

  return (
    <ModalRightSideBarTemplate
      open={isOpen}
      modalClassName={"office-lease-modal"}
      onClose={onClose}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          company_id: null,
          company_name: "",
          company_recharge_amount: "",
          company_trigger_amount: "",
          company_discount_percent: "",
          credit_amount: 0,
          discount_percentage: null,
          is_reserve: 0,
          expiry_method: null,
          auto_topup: 1,
          package_info: "",
          currency: "$",
          extend_duration_times: null,
          auto_topup_with_lastest: 0,
          applied_reached_number_from_market: 0,
          auto_topup_credit: false,
        }}
        validationSchema={yup.object().shape({
          company_id: yup.number().required().label("Company"),
          credit_amount: yup.number().min(1).required().label("Credit amount"),
        })}
        onSubmit={_handleSubmit}
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
          handleChange,
          resetForm,
        }) => (
          <>
            <Modal.Header>
              Buy Prepaid Credit Package
              <ButtonCustom
                onClick={onClose}
                className="m-0 p-0 none-bg close-modal"
              >
                <>
                  {translations.close}
                  <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
                </>
              </ButtonCustom>
            </Modal.Header>
            <Modal.Content className={"scroll two-line-actions"}>
              <DropdownCompany
                values={values}
                setFieldValue={setFieldValue}
                error={Boolean(touched.company_id && errors.company_id)}
                resetForm={resetForm}
                onChange={(companyId: number) => {
                  _getCreditAmountInfo({
                    param: "amount",
                    value: 0,
                    company_id: companyId,
                  });
                }}
              />

              <div className="d-flex">
                <InputCustom
                  labelRequired
                  containerClass={"flex-1"}
                  disabled={!values.company_id}
                  label={"Credit amount"}
                  name={"credit_amount"}
                  value={values.credit_amount}
                  onChange={(e: any, { value }: any) => {
                    const text = validateNumber(value);
                    setFieldValue("credit_amount", text);

                    _getCreditAmountInfo({
                      param: "amount",
                      value: Number(text),
                      company_id: values.company_id,
                    });
                  }}
                  touched={touched.credit_amount}
                  error={errors.credit_amount}
                  inputLeftIcon={values.currency}
                  inputLeftIconBorder
                  placeholder={"0.00"}
                />

                <SizeBox width={"16px"} />
                <InputCustom
                  containerClass={"flex-1"}
                  disabled={!values.company_id}
                  label={"Discount"}
                  name={"discount_percentage"}
                  value={values.discount_percentage}
                  onChange={(e: any, { value }: any) => {
                    let text = validateNumber(value);
                    if (Number(text) > 100) {
                      text = "100";
                    }
                    setFieldValue("discount_percentage", text);
                  }}
                  touched={touched.discount_percentage}
                  error={errors.discount_percentage}
                  inputLeftIcon={"%"}
                  inputLeftIconBorder
                  placeholder={creditAmountInfo?.discount || "0"}
                />
              </div>
              <InputCustom
                disabled
                label={"Price"}
                value={_renderPercent(
                  values.credit_amount,
                  values.discount_percentage || creditAmountInfo?.discount
                )}
                inputLeftIcon={values.currency}
                inputLeftIconBorder
                placeholder={"0.00"}
              />
              <div className={"d-flex"}>
                <InputCustom
                  disabled={!values.company_id}
                  containerClass={"flex-1"}
                  label={"Expiry period"}
                  name={"expiry_method"}
                  onChange={(e: any, { value }: any) => {
                    let text = validateNumber(value);
                    if (text === "0") {
                      text = "1";
                    }
                    setFieldValue("expiry_method", text === "" ? null : text);
                  }}
                  value={values.expiry_method}
                  touched={touched.expiry_method}
                  error={errors.expiry_method}
                  inputLeftIcon={"Day(s)"}
                  inputLeftIconBorder
                  placeholder={creditAmountInfo?.duration || "0"}
                  removeLetter
                />
                <SizeBox width={"16px"} />
                <InputCustom
                  containerClass={"flex-1"}
                  disabled={!values.company_id}
                  label={"No. of times to rollover unused credits"}
                  name={"extend_duration_times"}
                  value={values.extend_duration_times}
                  touched={touched.extend_duration_times}
                  error={errors.extend_duration_times}
                  inputLeftIconBorder
                  onChange={(e: any, { value }: any) => {
                    setFieldValue(
                      "extend_duration_times",
                      value === "" ? null : value
                    );
                  }}
                  placeholder={creditAmountInfo?.extend_time || "0"}
                  removeLetter
                />
              </div>

              <TextAreaCustom
                label={"Package Info"}
                value={values.package_info}
                name={"package_info"}
                onChange={handleChange}
                error={errors.package_info}
                touched={touched.package_info}
                disabled={!values.company_id}
              />

              <SizeBox height={"16px"} />
              {values.auto_topup_credit ? (
                <Label
                  label={`${values.company_name} has already enabled auto top-up.`}
                  labelGuide={
                    creditAmountInfo
                      ? `Auto top-up with ${values.currency} ${values.company_recharge_amount} with a ${values.company_discount_percent}%. 
                        Auto top-up when balance falls ${values.currency} ${values.company_trigger_amount}`
                      : false
                  }
                />
              ) : (
                <BoxToggleSlider
                  disabled={!values.company_id}
                  value={values.auto_topup}
                  onChange={(e: any, data: any) =>
                    setFieldValue("auto_topup", data.checked ? 1 : 0)
                  }
                  contentHtml
                  content={
                    <Label
                      fontSize={"14"}
                      label={"Enable auto top-up"}
                      labelGuide={
                        creditAmountInfo
                          ? `Auto top-up with ${creditAmountInfo?.currency} ${values.credit_amount} with a ${creditAmountInfo?.discount}%. 
                        Auto top-up when balance falls ${creditAmountInfo?.currency} ${creditAmountInfo?.trigger_amount}`
                          : false
                      }
                    />
                  }
                />
              )}
              <SizeBox height={"60px"} />
            </Modal.Content>
            <Modal.Actions className={"credit-modal-actions"}>
              <ButtonCustom
                loading={reserveSubmitting}
                disabled={
                  reserveSubmitting || buyNowSubmitting || !values.company_id
                }
                onClick={() => {
                  setFieldValue("is_reserve", 1);
                  handleSubmit();
                }}
                height={"48px"}
                className={"reserve-btn outline-primary"}
              >
                Reserve
              </ButtonCustom>
              <div className={"d-flex"}>
                <ButtonCustom
                  disabled={
                    buyNowSubmitting || reserveSubmitting || !values.company_id
                  }
                  height={"48px"}
                  onClick={() => {
                    resetForm();
                    onClose();
                  }}
                >
                  {translations.discard}
                </ButtonCustom>
                <ButtonCustom
                  loading={buyNowSubmitting}
                  disabled={
                    buyNowSubmitting || reserveSubmitting || !values.company_id
                  }
                  className={"primary"}
                  height={"48px"}
                  onClick={() => {
                    setFieldValue("is_reserve", 0);
                    handleSubmit();
                  }}
                >
                  Buy Now
                </ButtonCustom>
              </div>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalRightSideBarTemplate>
  );
};

export default ModalAddCredit;
