import React, { useContext } from "react";
import { Modal } from "semantic-ui-react";
import InputCustom from "../../../../../../components/Input";
import TextAreaCustom from "../../../../../../components/Input/TextArea";
import { Formik, FormikValues } from "formik";
import { LocalizationContext } from "../../../../../../locales/Translation";
import ModalCenterTemplate from "../../../../../App/views/ModalCenterTemplate";
import ButtonCustom from "../../../../../../components/Button";
import SizeBox from "../../../../../../components/SizeBox";
import OfficeLeasesContext from "../../Context";
import { officeLeaseDepositService } from "../../../../services/BusinessServices";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { values } from "lodash";
import * as yup from "yup";

const ModalAddDeposit = () => {
  const { translations } = useContext(LocalizationContext);
  const { modalDepositInfo, setModalDepositInfo, onSetRefreshing } =
    useContext(OfficeLeasesContext);

  const _onClose = () => {
    setModalDepositInfo({ open: false });
  };

  const _onSubmit = async (values: FormikValues) => {
    try {
      const payload = {
        ...values,
        lease_contract_id: modalDepositInfo?.leaseId,
        type: modalDepositInfo?.type,
      };

      await officeLeaseDepositService(payload);
      toastSuccess(translations.createSuccessPrompt);
      onSetRefreshing();
      _onClose();
    } catch (e: any) {
      toastError(e?.status?.message);
    }
  };

  return (
    <ModalCenterTemplate
      trigger={null}
      onClose={_onClose}
      modalClassName={"modal-center-v2 deposit-modal"}
      open={modalDepositInfo?.open}
    >
      <Formik
        initialValues={{ price: "", note: "" }}
        onSubmit={_onSubmit}
        validationSchema={yup.object().shape({
          price: yup
            .number()
            .typeError("Please input valid number")
            .required()
            .label("Price"),
        })}
      >
        {({
          touched,
          errors,
          values,
          isSubmitting,
          handleSubmit,
          handleChange,
        }) => (
          <>
            {" "}
            <Modal.Header>
              Add Deposit{" "}
              {modalDepositInfo?.type === "BUSINESS"
                ? "from Business"
                : "to Property"}
              <ButtonCustom
                className={"none-bg close-modal"}
                onClick={_onClose}
              >
                <>
                  Close <i className="uil uil-times-circle" />
                </>
              </ButtonCustom>
            </Modal.Header>
            <Modal.Content>
              <>
                <InputCustom
                  label={"Deposit"}
                  inputLeftIcon={modalDepositInfo?.currency || "$"}
                  inputLeftIconBorder
                  name={"price"}
                  value={values.price}
                  onChange={handleChange}
                  touched={touched.price}
                  error={errors.price}
                  removeLetter
                />
                <TextAreaCustom
                  label={"Notes"}
                  name={"note"}
                  value={values.note}
                  onChange={handleChange}
                  touched={touched.note}
                  error={errors.note}
                />
              </>
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom height={"47px"} onClick={_onClose}>
                {translations.cancel}
              </ButtonCustom>
              <SizeBox width={"16px"} />
              <ButtonCustom
                loading={isSubmitting}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                Add Deposit
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalCenterTemplate>
  );
};

export default ModalAddDeposit;
