import React, { useContext } from "react";
import { LocalizationContext } from "../../../../locales/Translation";
import { Link } from "react-router-dom";
import {
  DESK_BOOKING_LINK,
  ROOM_BOOKING_LINK,
} from "../../../App/configs/ListRoutes";
import DeskBooking from "../DeskBooking";
import RoomBooking from "../RoomBooking";
import MasterTemplate from "../../../App/views/MasterTemplate";
import {
  ROLE_BUSINESS_ADMIN_ID,
  ROLE_BUSINESS_USER_ID,
} from "../../../User/configs/Constants";
import { Tab } from "semantic-ui-react";
import "./styles.scss";
import SizeBox from "../../../../components/SizeBox";
import ModalBookingDetail from "../Components/ModalBookingDetail";

type Props = {
  tabIndex?: number;
};

const Tabs = ({ tabIndex }: Props) => {
  const { translations } = useContext(LocalizationContext);

  const panes = [
    {
      menuItem: {
        as: Link,
        id: "tab1",
        to: DESK_BOOKING_LINK,
        content: <div className={"custom-checkbox"}>{translations.desks}</div>,
        exact: true,
        key: 1,
      },
      render: () => <DeskBooking />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab2",
        to: ROOM_BOOKING_LINK,
        content: (
          <div className={"custom-checkbox"}>{translations.meetingRooms}</div>
        ),
        exact: true,
        key: 2,
      },
      render: () => <RoomBooking />,
    },
  ];

  return (
    <MasterTemplate
      roleAccept={[ROLE_BUSINESS_USER_ID, ROLE_BUSINESS_ADMIN_ID]}
    >
      <div>
        <div className={"page-title"}>{translations.myActivities}</div>
        <SizeBox height={"36px"} />
      </div>
      <Tab
        menu={{ text: true }}
        activeIndex={tabIndex}
        panes={panes}
        className={"tab-custom tab-radio"}
        style={{ padding: "0 !important" }}
      />
      <ModalBookingDetail />
    </MasterTemplate>
  );
};

export default Tabs;
