export const mapStateSelector = (state: any): google.maps.Map =>
  state.BookReducer.mapState;

export const meetingRoomListSelector = (state: any) =>
  state.BookReducer.meetingRoomList;

export const meetingRoomFiltersSelector = (state: any) =>
  state.BookReducer.filters;

export const refreshMeetingRoomSelector = (state: any) =>
  state.BookReducer.refreshRoomList;

export const modalConfirmBookingSelector = (state: any) =>
  state.BookReducer.modalConfirmBooking;

export const modalBookingSuccessSelector = (state: any) =>
  state.BookReducer.modalBookingSuccess;

export const meetingRoomDataTmpSelector = (state: any) =>
  state.BookReducer.meetingRoomDataTmp;

export const propertyListSelector = (state: any) =>
  state.BookReducer.propertyList;

export const propertyMapSelector = (state: any) =>
  state.BookReducer.propertyMap;

export const propertyFilterSelector = (state: any) =>
  state.BookReducer.propertyFilter;

export const filterMarketSelector = (state: any) =>
  state.BookReducer.filterMarket;

export const propertyFilterKeywordSelector = (state: any) =>
  state.BookReducer.propertyFilterKeyword;

export const infoWindowDataSelector = (state: any) =>
  state.BookReducer.infoWindowData;

export const modalConfirmDeskOpenSelector = (state: any) =>
  state.BookReducer.modalConfirmDeskOpen;

export const modalConfirmDeskDataSelector = (state: any) =>
  state.BookReducer.modalConfirmDeskData;

export const bookingSuccessDeskIdSelector = (state: any) =>
  state.BookReducer.bookingSuccessDeskId;

export const propertyDetailViewSelector = (state: any) =>
  state.BookReducer.propertyDetail;

export const roomDetailSelector = (state: any) => state.BookReducer.roomDetail;

export const propertyMarketCountSelector = (state: any) =>
  state.BookReducer.propertyMarketCount;

export const roomMarketCountSelector = (state: any) =>
  state.BookReducer.roomMarketCount;

export const filterMarketOptionsSelector = (state: any) =>
  state.BookReducer.filterMarketOptions;

export const modalAssignUserSelector = (state: any) =>
  state.BookReducer.modalAssignUser;

export const userListSelector = (state: any) => state.BookReducer.userList;

export const assignUserListSelector = (state: any) =>
  state.BookReducer.assignList;

export const assignBookingIdSelector = (state: any) =>
  state.BookReducer.assignBookingId;

export const modalAssignBookingSubmittedSelector = (state: any) =>
  state.BookReducer.modalAssignBookingSubmitted;

export const deskAmenitiesSelector = (state: any) =>
  state.BookReducer.deskAmenities;
export const roomAmenitiesSelector = (state: any) =>
  state.BookReducer.roomAmenities;

export const pinHighlightIdSelector = (state: any) =>
  state.BookReducer.pinHighlightId;

export const circleInfoSelector = (state: any) => state.BookReducer.circleInfo;
export const modalPrivateOfficeSelector = (state: any) =>
  state.BookReducer.modalPrivateOffice;

export const privateOfficeListSelector = (state: any) =>
  state.BookReducer.privateOfficeList;

export const privateOfficeFilterSelector = (state: any) =>
  state.BookReducer.privateOfficeFilter;

export const marketHasPrivateOfficesSelector = (state: any) =>
  state.BookReducer.marketHasPrivateOffices;

export const modalCheckinSelector = (state: any) =>
  state.BookReducer.modalCheckin;

export const modalRewardsSelector = (state: any) =>
  state.BookReducer.modalRewards;

export const rewardListSelector = (state: any) => state.BookReducer.rewardList;
