import { createReducer } from "@reduxjs/toolkit";
import {
  AFFILIATE_LIST_ACTION,
  BILLING_CONTACT_ACTION,
  BILLING_INFO_ACTION,
  GET_PROPERTIES_ACTION,
  GET_SETTINGS_ACTION,
  INTEGRATION_LIST_ACTION,
  INTEGRATION_UPDATE_ACTION,
  INVOICE_DETAIL_ACTION,
  MODAL_ADD_BILLING_CONTACT_ACTION,
  MODAL_ADD_CARD_ACTION,
  MODAL_BILLING_CONTACT_ACTION,
  MODAL_BILLING_INFORMATION_ACTION,
  MODAL_UPDATE_PAYMENT_METHOD_ACTION,
  MONTHLY_USAGE_ACTION,
  PAYMENT_HISTORY_ACTION,
  PAYMENT_HISTORY_FILTERS_ACTION,
  PAYMENT_METHOD_ACTION,
  PREPAID_CREDIT_TRANSACTION_ACTION,
  PREPAID_CREDIT_USAGE_ACTION,
} from "../configs/Actions";
import { IntegrationProps } from "../configs/DeclareTypes";

const initialState = {
  //Billing Tab
  paymentHistory: [],
  invoiceDetail: {},
  monthlyUsage: {},
  paymentMethod: [],
  billingContact: [],
  billingInfo: {},
  //Paid Credit Tab
  creditTransaction: {},
  creditUsage: {},
  isModalUpdatePaymentMethod: false,
  isModalAddCreditCard: false,
  isModalBillingContact: false,
  isModalAddBillingContact: false,
  isModalBillingInformation: false,
  settings: {},
  properties: [],
  paymentHistoryFilters: {
    statuses: [],
    page: 1,
    order_by: "",
    sort_by: "",
  },
  integrationList: null,
  affiliateList: {},
};
const SettingReducer = createReducer(initialState, {
  //Billing Tab

  [PAYMENT_HISTORY_ACTION]: (state: any, action: any) => {
    state.paymentHistory = action.payload || {};
  },
  [INVOICE_DETAIL_ACTION]: (state: any, action: any) => {
    state.invoiceDetail = action.payload;
  },
  [MONTHLY_USAGE_ACTION]: (state: any, action: any) => {
    state.monthlyUsage = action.payload;
  },
  [PAYMENT_METHOD_ACTION]: (state: any, action: any) => {
    state.paymentMethod = action.payload;
  },
  [BILLING_CONTACT_ACTION]: (state: any, action: any) => {
    state.billingContact = action.payload;
  },
  [BILLING_INFO_ACTION]: (state: any, action: any) => {
    state.billingInfo = action.payload;
  },
  //Paid Credit Tab
  [PREPAID_CREDIT_TRANSACTION_ACTION]: (state: any, action: any) => {
    state.creditTransaction = action.payload;
  },
  [PREPAID_CREDIT_USAGE_ACTION]: (state: any, action: any) => {
    state.creditUsage = action.payload;
  },

  [MODAL_UPDATE_PAYMENT_METHOD_ACTION]: (state: any, action: any) => {
    state.isModalUpdatePaymentMethod = action.payload;
  },

  [MODAL_ADD_CARD_ACTION]: (state: any, action: any) => {
    state.isModalAddCreditCard = action.payload;
  },

  [MODAL_BILLING_CONTACT_ACTION]: (state: any, action: any) => {
    state.isModalBillingContact = action.payload;
  },

  [MODAL_ADD_BILLING_CONTACT_ACTION]: (state: any, action: any) => {
    state.isModalAddBillingContact = action.payload;
  },

  [MODAL_BILLING_INFORMATION_ACTION]: (state: any, action: any) => {
    state.isModalBillingInformation = action.payload;
  },

  [GET_SETTINGS_ACTION]: (state: any, action: any) => {
    state.settings = action.payload;
  },

  [PAYMENT_HISTORY_FILTERS_ACTION]: (state: any, action: any) => {
    state.paymentHistoryFilters = action.payload;
  },

  [INTEGRATION_LIST_ACTION]: (state: any, action: any) => {
    state.integrationList = action.payload;
  },

  [INTEGRATION_UPDATE_ACTION]: (state: any, action: any) => {
    state.integrationList.settings = state.integrationList.settings.map(
      (item: IntegrationProps) => {
        if (item.id !== action.payload.id) {
          return item;
        }

        return { ...item, [action.payload.key]: action.payload.value };
      }
    );
  },

  [GET_PROPERTIES_ACTION]: (state: any, action: any) => {
    state.properties = action.payload;
  },

  [AFFILIATE_LIST_ACTION]: (state: any, action: any) => {
    state.affiliateList = action.payload;
  },
});

export default SettingReducer;
