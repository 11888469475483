import React, { useEffect, useState } from "react";
import SizeBox from "../../../../../../../components/SizeBox";
import { getDeskimoUserReviewsFeedbackService } from "../../../../../services/PropertyServices";
import classNames from "classnames";
import { Loader } from "semantic-ui-react";

type Props = {
  propertyId: string;
  bookPropertyDetail?: boolean;
};

const PropertyOutstanding = ({ propertyId, bookPropertyDetail }: Props) => {
  const [outstandingData, setOutstandingData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getDeskimoUserReviewsFeedbackService(
          propertyId,
          "3"
        );
        setOutstandingData(data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if ((bookPropertyDetail && !outstandingData?.length) || loading) {
    return null;
  }

  return (
    <div
      className={classNames("property-user-outstanding", {
        "book-detail__section": bookPropertyDetail,
      })}
    >
      <div
        className={
          bookPropertyDetail
            ? "book-detail__section-title"
            : "property-rate fw-500"
        }
      >
        Top Features
      </div>
      <SizeBox height={16} />
      <div
        className={classNames("property-user-outstanding-inside", {
          "no-data": !outstandingData?.length,
        })}
      >
        {outstandingData?.length ? (
          <>
            {outstandingData.map(({ name, icon_url }) => {
              return (
                <div key={name} className={"item"}>
                  <span className={"thumb"}>
                    <img alt={name} src={icon_url} />
                  </span>
                  <span className={"text"}>{name}</span>
                </div>
              );
            })}
          </>
        ) : (
          <div>This property currently has no highlighted features.</div>
        )}
      </div>
    </div>
  );
};

export default PropertyOutstanding;
