import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  DASHBOARD_DESKIMO_LINK,
  LOGOUT_LINK,
} from "../../../App/configs/ListRoutes";
import { useEffect, useState } from "react";
import { getURlDownloadFileService } from "../../services/InvoiceServices";
import {
  ROLE_BUSINESS_ADMIN_ID,
  ROLE_DESKIMO_ADMIN_ID,
  ROLE_PROPERTY_ADMIN_ID,
} from "../../../User/configs/Constants";
import MasterTemplate from "../../../App/views/MasterTemplate";
import { PUBLIC_URL } from "../../../App/configs/Constants";
import { isAuthSelector } from "../../../Authentication/selectors/AuthenticationSelectors";
import { API_DOMAIN } from "../../../../configs/Endpoints";
import Loader from "../../../../components/Loader";

const DownloadInvoice = () => {
  const { token } = useParams();
  const isAuth = useSelector(isAuthSelector);
  const [downloadUrl, setDownloadUrl] = useState<any>(null);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isAuth || !token) {
      sessionStorage.setItem("redirect_url", window.location.pathname);
      navigate(LOGOUT_LINK, { replace: true });
    } else {
      downloadInvoice();
    }
  }, [isAuth]);

  const downloadInvoice = async () => {
    try {
      setLoading(true);
      const url = API_DOMAIN + `/api/v1/generate/download/${token}`;
      const response = await getURlDownloadFileService(url);
      setDownloadUrl(response?.data?.url);
      window.open(response?.data?.url, "_blank");
    } catch (e) {
      navigate(DASHBOARD_DESKIMO_LINK, { replace: true });
    } finally {
      setLoading(false);
    }
  };

  return (
    <MasterTemplate
      isCheckAuth={false}
      roleAccept={[
        ROLE_DESKIMO_ADMIN_ID,
        ROLE_BUSINESS_ADMIN_ID,
        ROLE_PROPERTY_ADMIN_ID,
      ]}
    >
      {loading ? (
        <Loader fullScreen />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexFlow: "column nowrap",
            gap: 16,
          }}
        >
          <img
            style={{ width: 500 }}
            src={PUBLIC_URL + "/assets/images/download-invoice.png"}
            alt=""
          />
          <div>
            Download not starting? Try this{" "}
            <span
              onClick={() => window.open(downloadUrl, "_blank")}
              className={"text-link"}
            >
              direct download link
            </span>
          </div>
        </div>
      )}
    </MasterTemplate>
  );
};

export default DownloadInvoice;
