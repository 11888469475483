import React from "react";
import { useSelector } from "react-redux";
import { meetingRoomListSelector } from "../../selectors/BookSelectors";
import NoResult from "../Components/NoResult";
import { BookMeetingRoomType } from "../../configs/DeclareType";
import RoomListRender from "./RoomListRender";
import CardPlaceholder from "../Desk/CardPlaceholder";

type Props = {
  loading: boolean;
};

const RoomList = ({ loading }: Props) => {
  const meetingRoomList: BookMeetingRoomType[] = useSelector(
    meetingRoomListSelector
  );

  if (loading) {
    return <CardPlaceholder />;
  }

  if (!meetingRoomList.length && !loading) {
    return <NoResult />;
  }

  return (
    <>
      <RoomListRender data={meetingRoomList} favoriteSection />
      <RoomListRender data={meetingRoomList} />
    </>
  );
};

export default RoomList;
