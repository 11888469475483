import React from "react";
import "./style.scss";
import { Popup } from "semantic-ui-react";
import classNames from "classnames";

type Props = {
  label?: string | JSX.Element;
  id?: string;
  className?: string;
  labelGuide?: any;
  size?: string;
  fontSize?: string;
  required?: boolean;
  contentClassName?: string;
  flowing?: boolean;
  [x: string]: any;
};

const Label = ({
  label,
  id,
  className,
  labelGuide,
  size,
  fontSize = "12px",
  required,
  contentClassName = "",
  flowing,
  ...props
}: Props) => {
  return (
    <label
      {...props}
      className={classNames("custom-label", className, { required: required })}
      htmlFor={id}
    >
      <span className={contentClassName} style={{ fontSize }}>
        {label}
      </span>
      {labelGuide && (
        <Popup
          hoverable
          content={labelGuide}
          trigger={
            <i
              className="uil uil-info-circle input-label-icon"
              style={{ fontSize: size }}
            />
          }
          position="top center"
          flowing={flowing}
          // open={false}
        />
      )}
    </label>
  );
};

export default Label;
