import React from "react";
import "./style.scss";

const AmenityItemSelect = ({ item, onChangeCheck, onChangeFree }: any) => {
  const { id, name, uri, is_chosen, is_free } = item;

  const _onChangeCheck = () => {
    onChangeCheck(id);
  };

  const _onChangeFree = () => {
    onChangeFree(id);
  };

  return (
    <div className="amenity-item">
      <div className="amenity-item__checkbox custom-checkbox-fullwidth mb-3">
        <input
          value="1"
          type="checkbox"
          id={`${name}-${id}-check`}
          onChange={_onChangeCheck}
          checked={is_chosen}
          className="custom-checkbox-input"
        />
        <label
          htmlFor={`${name}-${id}-check`}
          className="custom-checkbox-label custom-checkbox-label-revert"
        >
          <img src={uri} alt={"Deskimo"} className="amenity-image" />
          <p className="amenity-name">{name}</p>
          <div className="amenity-checkbox-fee">
            <input
              type="checkbox"
              id={`${name}-${id}-free`}
              checked={!is_free}
              onChange={_onChangeFree}
              className="custom-checkbox-input-fee"
            />
            <label
              htmlFor={`${name}-${id}-free`}
              className="custom-checkbox-fee"
            >
              <i className="uil uil-dollar-sign-alt" />
            </label>
          </div>
        </label>
      </div>
    </div>
  );
};

export default AmenityItemSelect;
