import React, { useContext } from "react";
import DropdownWithCheckboxMenuWithTotal from "../../../../../../components/DropdownWithCheckboxMenuWithTotal";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { LocalizationContext } from "../../../../../../locales/Translation";

type Props = {
  marketOptions: { id: string; name: string }[];
  values: string[];
  onChange: any;
};

const MarketFilter = ({ marketOptions, values, onChange }: Props) => {
  const { translations } = useContext(LocalizationContext);
  return (
    <DropdownWithCheckboxMenuWithTotal
      values={values}
      listCheckBox={marketOptions}
      label={translations.companyInvoices.marketFilterLabel}
      onChange={onChange}
      icon={
        <img
          alt={"Deskimo"}
          src={PUBLIC_URL + "/assets/icons/icon-filter.svg"}
        />
      }
    />
  );
};

export default MarketFilter;
