export const POST_GET_PAYMENT_HISTORY_API = "/api/v1/company-invoices";
export const POST_GET_PAYMENT_HISTORY_PROPERTY_API =
  "/api/v1/property-com/company-invoices";
export const GET_PAYMENT_METHOD_API = "/api/v1/payment-method/%companyId%";
export const GET_BILLING_INFO_API = "/api/v1/company/%companyId%";
export const GET_BILLING_CONTACT_API = "/api/v1/billing-contact/%companyId%";
export const GET_MONTHLY_USAGE_API = "/api/v1/monthly-usage/%companyId%";
export const GET_INVOICE_DETAIL_API =
  "/api/v1/company-invoices/%companyId%/invoice/%token%";

export const GET_INVOICE_DETAIL_PROPERTY_API =
  "/api/v1/property-com/%companyId%/company-invoices/invoice/%token%";
//Paid Credit Tab
export const POST_GET_PREPAID_CREDIT_TRANSACTION_API =
  "/api/v1/credit-transactions";
export const POST_GET_PREPAID_CREDIT_USAGE_API = "/api/v1/credit-usage";

//Payment Method
export const POST_CREATE_PAYMENT_METHOD_API = "/api/v1/payment-method";
export const POST_UPDATE_PRIMARY_CARD_API = "/api/v1/payment-method/primary";
export const DELETE_REMOVE_CARD_API = "/api/v1/payment-method/%companyId%";

//Billing Contact
export const POST_CREATE_BILLING_CONTACT_API = "/api/v1/billing-contact";
export const DELETE_CREATE_BILLING_CONTACT_API =
  "/api/v1/billing-contact/%companyId%";

//Billing information
export const PUT_UPDATE_BILLING_INFORMATION_API = "/api/v1/company";

//Settings
export const GET_SETTINGS_API = "/api/v1/company/%companyId%/setting";
export const POST_UPDATE_SETTINGS_API = "/api/v1/company/setting";
export const POST_REQUEST_POST_PAY_API = "/api/v1/company/setting/request-post";
export const GET_PROPERTIES_API = "/api/v1/company/%companyId%/property";
export const GET_INVOICE_FILTER_API =
  "/api/v1/company-invoices/%companyId%/count-status";
export const GET_INVOICE_PROPERTY_FILTER_API =
  "/api/v1/property-com/%companyId%/company-invoices/count-status";
export const GET_TRANSACTION_FILTER_API =
  "/api/v1/credit-transactions/%companyId%/count-status";

export const API_COMPANY_INTEGRATION =
  "/api/v1/external/company/%companyId%/integrations";
export const API_COMPANY_INTEGRATION_CONFIG =
  "/api/v1/external/company/%companyId%/users/integration/%itemId%";

export const API_PARTNER_GET_REVENUE =
  "/api/v3/business-partner/%companyId%/revenue";

export const API_AFFILIATE_LIST = "/api/v3/affiliate";
export const API_AFFILIATE_INFO = "/api/v3/affiliate/%companyId%";
export const API_AFFILIATE_REQUEST_PAYOUT =
  "/api/v3/affiliate/%reference%/%companyId%/payout-request";

export const API_AFFILIATE_COMPANY_STATUS =
  "/api/v3/affiliate-tracking/%reference%/%companyId%/status";
