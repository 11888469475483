import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import TableCustom from "../../../../../components/Table";
import { getPartnerLogThunk } from "../../../../Property/thunks/PropertyThunks";
import { useDispatch, useSelector } from "react-redux";
import { partnerLogSelector } from "../../../../Property/selectors/PropertySelector";
import moment from "moment-timezone";
import {
  FORMAT_DATE_TIME_VARIANT_10,
  FORMAT_DATE_VARIANT_2,
} from "../../../../App/configs/DateTimeConfigs";
import CircleStatus from "../../../../../components/CircleStatus";
import JSONPretty from "react-json-pretty";
import ModalCenterTemplate from "../../../../App/views/ModalCenterTemplate";
import { LocalizationContext } from "../../../../../locales/Translation";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../components/Button";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import CopyToClipboard from "../../../../../components/CopyToClipboard";
import JSONCell from "../Components/JSONCell";
import Search from "../../../../Marketing/views/CampaignList/Components/Search";
import DateRangePickerV2 from "../../../../../components/DateRangePickerV2";

const OperationLog = () => {
  const { translations } = useContext(LocalizationContext);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    keyword: "",
    start_time: null,
    end_time: null,
    order_by: 0,
    asc: false,
    page: 1,
  });
  const [modalJSON, setModalJSON] = useState({
    open: false,
    data: {
      title: "",
      content: "",
    },
  });
  const partnerLog = useSelector(partnerLogSelector);

  useEffect(() => {
    _getPartnerLog();
  }, [filters]);

  const _getPartnerLog = async () => {
    try {
      setLoading(true);
      await dispatch<any>(getPartnerLogThunk(filters));
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const decorateTableData = () => {
    if (partnerLog?.log?.length > 0) {
      return partnerLog?.log.map((log: any) => {
        return {
          col1:
            log?.status === 200 ? (
              <>
                <CircleStatus marginRight={"6px"} />
                {log?.status}
              </>
            ) : (
              <>
                <CircleStatus color={"orange"} marginRight={"6px"} />
                {log?.status}
              </>
            ),
          col2: <span style={{ fontWeight: "500" }}>{log?.method}</span>,
          col3: (
            <div style={{ maxWidth: 200 }}>
              <span className={"overflow-wrap"}>{log?.endpoint}</span>
            </div>
          ),
          col4:
            log?.payload?.length > 0 ? (
              <div className={"json-copy"}>
                <JSONCell
                  title={"API Payload"}
                  content={log?.payload}
                  setModalJSON={setModalJSON}
                  maxHeight={84}
                />
                <CopyToClipboard
                  text={log?.payload}
                  copyText={"Copy API Payload"}
                  trigger={<i className="uil uil-copy"></i>}
                />
              </div>
            ) : null,
          col5:
            log?.result?.length > 0 ? (
              <div className={"json-copy"}>
                <JSONCell
                  title={"API Result"}
                  content={log?.result}
                  setModalJSON={setModalJSON}
                  maxHeight={84}
                />
                <CopyToClipboard
                  text={log?.result}
                  copyText={"Copy API Result"}
                  trigger={<i className="uil uil-copy"></i>}
                />
              </div>
            ) : null,
          col6: log?.duration,
          col7: moment(log?.created_at * 1000).format(
            FORMAT_DATE_TIME_VARIANT_10
          ),
        };
      });
    }
    return [];
  };

  const _onSearch = (value: string) => {
    setFilters((prevState) => {
      return { ...prevState, keyword: value, page: 1 };
    });
  };

  console.log("filters: ", filters);

  return (
    <div className={"deskimo-tab-section"}>
      <div className="deskimo-tab-title">Operation Log</div>
      <div style={{ display: "flex" }}>
        <Search
          onSearch={_onSearch}
          placeholder={"Search by Endpoints, Methods, Result,..."}
        />
        <DateRangePickerV2
          format={"DD/MM/YYYY"}
          value={
            filters?.start_time && filters?.end_time
              ? [
                  moment.unix(filters.start_time || 0).tz("UTC"),
                  moment.unix(filters.end_time || 0).tz("UTC"),
                ]
              : [moment(), moment().subtract({ d: 30 })]
          }
          onChange={(value: any) => {
            if (!value) {
              return;
            }
            setFilters((preState: any) => {
              return {
                ...preState,
                page: 1,
                start_time: moment(
                  moment(value[0]).format(FORMAT_DATE_VARIANT_2),
                  FORMAT_DATE_VARIANT_2
                )
                  .clone()
                  .tz("UTC", true)
                  .startOf("day")
                  .utc()
                  .unix(),
                end_time: moment(
                  moment(value[1]).format(FORMAT_DATE_VARIANT_2),
                  FORMAT_DATE_VARIANT_2
                )
                  .clone()
                  .tz("UTC", true)
                  .endOf("day")
                  .utc()
                  .unix(),
              };
            });
          }}
        />
      </div>
      <div>
        <TableCustom
          loading={loading}
          showTotal={true}
          totalItems={partnerLog?.pagination?.total}
          currentPage={filters.page}
          onChangePagination={(e: SyntheticEvent, { activePage }: any) =>
            setFilters({ ...filters, page: activePage })
          }
          tableData={decorateTableData()}
          columns={[
            "Status",
            "Method",
            "Endpoint",
            "Payload",
            "Result",
            "Duration",
            "Created at",
          ]}
        />
      </div>
      <ModalCenterTemplate
        size={"md"}
        trigger={null}
        onClose={() =>
          setModalJSON({ open: false, data: { title: "", content: "" } })
        }
        modalClassName={"modal-general modal-json"}
        open={modalJSON.open}
      >
        <Modal.Header>
          {modalJSON.data.title}
          <ButtonCustom
            onClick={() =>
              setModalJSON({ open: false, data: { title: "", content: "" } })
            }
            className={"modal-close-cta"}
          >
            <>
              <span>{translations.close}</span>
              <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
            </>
          </ButtonCustom>
        </Modal.Header>
        <Modal.Content scrolling>
          <form className={"form-layout"}>
            <JSONPretty data={modalJSON.data.content}></JSONPretty>
          </form>
        </Modal.Content>
        <Modal.Actions>
          <ButtonCustom
            disabled={false}
            className={"default"}
            height={"47px"}
            onClick={() =>
              setModalJSON({ open: false, data: { title: "", content: "" } })
            }
          >
            {translations.close}
          </ButtonCustom>
          <ButtonCustom
            disabled={false}
            loading={false}
            className={"primary"}
            height={"47px"}
            onClick={() => {
              navigator.clipboard.writeText(modalJSON.data.content);
              setModalJSON({ open: false, data: { title: "", content: "" } });
            }}
          >
            Copy to Clipboard
          </ButtonCustom>
        </Modal.Actions>
      </ModalCenterTemplate>
    </div>
  );
};

export default OperationLog;
