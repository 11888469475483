import { FormikValues } from "formik";
import Label from "../../../../../components/Label";
import SizeBox from "../../../../../components/SizeBox";
import React from "react";
import CheckboxCustom from "../../../../../components/Checkbox";

type Props = {
  values: FormikValues;
  setFieldValue: any;
};

const ProductAccess = ({ values, setFieldValue }: Props) => {
  return (
    <>
      <Label
        label={"Product Access"}
        fontSize={"24px"}
        className={"section-title"}
      />
      <div className={"detail_divide"}>
        <div className={"detail_col"}>
          <Label
            label={"Company can access to the following Deskimo products"}
          />
          <div className={"list-checkbox-wrapper"}>
            {values?.products?.length > 0
              ? values.products.map((product: any, index: number) => (
                  <CheckboxCustom
                    key={index}
                    className={"custom-checkbox checkbox-label-md"}
                    label={product.name}
                    checked={product.is_active}
                    onChange={(event, data) => {
                      const newProducts = values.products.map((item: any) => {
                        if (item.id === product.id) {
                          item.is_active = data.checked;
                        }
                        return item;
                      });
                      setFieldValue("products", newProducts);
                    }}
                  />
                ))
              : null}
          </div>
        </div>
      </div>

      <SizeBox height={"24px"} />
      <div className={"property_company_detail_line"} />
    </>
  );
};

export default ProductAccess;
