import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import ModalCenterTemplate from "../../../../../App/views/ModalCenterTemplate";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../../components/Button";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { companyIntegrationService } from "../../../../services/SettingServices";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import CheckboxCustom from "../../../../../../components/Checkbox";
import { useDispatch } from "react-redux";
import { integrationUpdateAction } from "../../../../actions/IntegrationAction";
import { GATrackingEvent } from "../../../../../../services/GATrackingService";

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  autoInviteDefault: boolean;
  id: number;
};

const ModalIntegrationConfig = ({
  isOpen,
  setIsOpen,
  autoInviteDefault,
  id,
}: Props) => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [autoInvite, setAutoInvite] = useState<boolean>(autoInviteDefault);

  const _onSave = async () => {
    try {
      setLoading(true);
      const payload = {
        auto_invite: autoInvite,
      };
      await companyIntegrationService(id, payload);
      dispatch(
        integrationUpdateAction({ id, key: "auto_invite", value: autoInvite })
      );
      toastSuccess("Successfully Updated");
      setIsOpen(false);
    } catch (e: any) {
      toastError(e?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const _onClose = () => {
    setIsOpen(false);
  };

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={_onClose}
      modalClassName={"modal-general"}
      open={isOpen}
    >
      <Modal.Header>
        <span>Google Workspace Settings</span>
      </Modal.Header>
      <Modal.Content>
        <CheckboxCustom
          checked={autoInvite}
          onChange={() =>
            setAutoInvite((prevState) => {
              GATrackingEvent("gg_integration_setting_uncheck_auto_invite");
              return !prevState;
            })
          }
          label={translations.ggAutomaticInvite}
        />
      </Modal.Content>
      <Modal.Actions className={"border-0"}>
        <ButtonCustom
          disabled={loading}
          height={"47px"}
          fontSize={"16px"}
          onClick={_onClose}
        >
          {translations.cancel}
        </ButtonCustom>
        <ButtonCustom
          loading={loading}
          fontSize={"16px"}
          className={"primary"}
          height={"47px"}
          onClick={_onSave}
        >
          {translations.save}
        </ButtonCustom>
      </Modal.Actions>
    </ModalCenterTemplate>
  );
};

export default ModalIntegrationConfig;
