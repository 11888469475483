import DeskimoPropertiesScannerListTable from "./Components/DeskimoPropertiesScannerListTable";
import { useContext, useEffect, useState } from "react";
import { getDeskimoPropertyScannerListThunk } from "../../thunks/PropertyThunks";
import { useDispatch, useSelector } from "react-redux";
import { SyntheticEvent } from "react-toastify/dist/utils";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../App/configs/Constants";
import ModalGetQrCode from "./Components/ModalGetQrCode";
import ButtonCustom from "../../../../components/Button";
import { useNavigate } from "react-router-dom";
import { DESKIMO_PROPERTY_SCANNER_CREATE_LINK } from "../../../App/configs/ListRoutes";
import { LocalizationContext } from "../../../../locales/Translation";
import { globalSettingSelector } from "../../../App/selectors/AppSelectors";
import { deskimoPropertyScannerFilterAction } from "../../actions/PropertyActions";
import { deskimoPropertyScannerFilterSelector } from "../../selectors/PropertySelector";
import { useGetAllQuery, useSetQuery } from "../../../../utils/custom-hooks";
import SearchWithDebounce from "../../../../components/SearchBar4Table/SearchWithDebounce";

export const DeskimoPropertiesScanners = () => {
  const { translations } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const deskimoPropertyScannerFilter = useSelector(
    deskimoPropertyScannerFilterSelector
  );
  const globalSetting = useSelector(globalSettingSelector);

  const [addParamsAlready, setAddParamsAlready] = useState<boolean>(false);
  const getAllQuery = useGetAllQuery();
  const setQuery = useSetQuery();

  useEffect(() => {
    try {
      if (!deskimoPropertyScannerFilter?.market_codes?.length) {
        const temp = globalSetting?.location.map(
          ({ city_code }: { city_code: string }) => city_code
        );

        dispatch(deskimoPropertyScannerFilterAction({ market_codes: temp }));
      }

      if (!Object.keys(getAllQuery).length) {
        return;
      }

      dispatch(deskimoPropertyScannerFilterAction(getAllQuery));
    } finally {
      setAddParamsAlready(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (
          !deskimoPropertyScannerFilter.market_codes.length ||
          !addParamsAlready
        ) {
          return;
        }

        setLoading(true);
        setQuery(deskimoPropertyScannerFilter);
        await dispatch<any>(
          getDeskimoPropertyScannerListThunk(deskimoPropertyScannerFilter)
        );
      } finally {
        setLoading(false);
      }
    })();
  }, [addParamsAlready, deskimoPropertyScannerFilter]);

  const _onChangeSortCol = (col: any) => {
    if (deskimoPropertyScannerFilter.sort_by !== col.key) {
      dispatch(
        deskimoPropertyScannerFilterAction({
          sort_by: col.key,
          order_by: ORDER_BY_DESC,
        })
      );
    } else {
      dispatch(
        deskimoPropertyScannerFilterAction({
          sort_by: col.key,
        })
      );
      if (deskimoPropertyScannerFilter.order_by === ORDER_BY_ASC) {
        dispatch(
          deskimoPropertyScannerFilterAction({
            order_by: ORDER_BY_NONE,
          })
        );
      } else if (deskimoPropertyScannerFilter.order_by === ORDER_BY_NONE) {
        dispatch(
          deskimoPropertyScannerFilterAction({
            order_by: ORDER_BY_DESC,
          })
        );
      } else {
        dispatch(
          deskimoPropertyScannerFilterAction({
            order_by: ORDER_BY_ASC,
          })
        );
      }
    }
  };

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    dispatch(
      deskimoPropertyScannerFilterAction({
        page: activePage,
      })
    );
  };

  const _onChangeMarket = (values: string[]) => {
    dispatch(
      deskimoPropertyScannerFilterAction({
        market_codes: values,
        page: 1,
      })
    );
  };

  const _onChangeStatus = (values: string[]) => {
    dispatch(
      deskimoPropertyScannerFilterAction({
        status: values,
        page: 1,
      })
    );
  };

  if (!addParamsAlready) {
    return null;
  }

  return (
    <>
      <div className={"filter-section"}>
        <SearchWithDebounce
          initialValue={deskimoPropertyScannerFilter?.keyword}
          placeholder={translations.propertyScanners.searchPlaceholder}
          onChange={(value) =>
            dispatch(
              deskimoPropertyScannerFilterAction({
                keyword: value,
                page: 1,
              })
            )
          }
        />
        <ButtonCustom
          className="primary"
          height="34px"
          onClick={() => navigate(DESKIMO_PROPERTY_SCANNER_CREATE_LINK)}
        >
          {`${translations.propertyScanners.addScannerButtonLabel} +`}
        </ButtonCustom>
      </div>
      <DeskimoPropertiesScannerListTable
        filter={deskimoPropertyScannerFilter}
        loading={loading}
        onChangeSortCol={_onChangeSortCol}
        onChangePagination={_onChangePagination}
        onChangeMarket={_onChangeMarket}
        onChangeStatus={_onChangeStatus}
      />
      <ModalGetQrCode />
    </>
  );
};
