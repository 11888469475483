import React, { useContext, useRef, useState } from "react";
import { Modal } from "semantic-ui-react";
import "./styles.scss";
import ButtonCustom from "../../../../components/Button";
import ModalCenterTemplate from "../ModalCenterTemplate";
import classNames from "classnames";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { dataURItoBlob } from "../../../../utils/commons";
import { uploadImageService } from "../../services/AppServices";
import { LocalizationContext } from "../../../../locales/Translation";

const ModalCropImage = ({
  imgSrc,
  open,
  setOpen,
  setFieldValue,
  fieldKey,
  setPreviewImage,
}: any) => {
  const { translations } = useContext(LocalizationContext);

  const [loading, setLoading] = useState(false);
  const [completedCrop, setCompletedCrop] = useState<any>(null);
  const _handleSave = async () => {
    setLoading(true);
    try {
      const result = completedCrop.getCroppedCanvas().toDataURL();
      if (!result) {
        return;
      }
      const file = dataURItoBlob(result);
      const formData = new FormData();
      formData.append("avatar", file);
      const response = await uploadImageService(formData);
      setFieldValue(fieldKey, response?.data?.avatar_uri);
      setPreviewImage(response?.data?.uri);
      setOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const cropperRef = useRef(null);
  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setCompletedCrop(cropper);
  };

  return (
    <ModalCenterTemplate
      trigger={null}
      onClose={() => setOpen(false)}
      modalClassName={classNames("md")}
      open={open}
    >
      <Modal.Header>Crop Image</Modal.Header>
      <Modal.Content>
        <Cropper
          src={imgSrc}
          style={{ height: 400, width: "100%" }}
          viewMode={3}
          background={false}
          responsive={false}
          dragMode={"move"}
          autoCropArea={0.75}
          checkOrientation={false}
          movable={true}
          scalable={false}
          zoomable={true}
          autoCrop={true}
          aspectRatio={1}
          guides={false}
          crop={onCrop}
          ref={cropperRef}
        />
      </Modal.Content>
      <Modal.Actions className={"modal-crop__actions"}>
        <ButtonCustom height={"47px"} onClick={() => setOpen(false)}>
          {translations.cancel}
        </ButtonCustom>
        <ButtonCustom
          loading={loading}
          className={"primary"}
          height={"47px"}
          onClick={_handleSave}
        >
          {translations.done}
        </ButtonCustom>
      </Modal.Actions>
    </ModalCenterTemplate>
  );
};

export default ModalCropImage;
