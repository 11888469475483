import React, { useContext, useState } from "react";
import { Modal } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";

import "./styles.scss";
import ButtonCustom from "../../../../../../components/Button";
import ModalCenterTemplate from "../../../../../App/views/ModalCenterTemplate";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import UploadFileSection from "./Components/UploadFileSection";
import { LocalizationContext } from "../../../../../../locales/Translation";

const ModalUploadFile = ({ setOpen, onRefresh }: any) => {
  const { translations } = useContext(LocalizationContext);
  const [tabIndex, setTabIndex] = useState(1);
  const [fileUploaded, setFileUploaded] = useState<any>(null);

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={() => {
        setOpen(false);
      }}
      modalClassName={"modal-general guide-modal-import-employee"}
      open={true}
    >
      <Modal.Header>
        Upload media file
        <ButtonCustom
          onClick={() => {
            setOpen(false);
          }}
          className={"modal-close-cta"}
        >
          <>
            <span>{translations.close}</span>
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      {tabIndex === 1 ? (
        <>
          <UploadFileSection onRefresh={onRefresh} setOpen={setOpen} />
        </>
      ) : (
        <></>
      )}
    </ModalCenterTemplate>
  );
};

export default ModalUploadFile;
