import { Formik } from "formik";
import * as yup from "yup";
import InputCustom from "../../../../components/Input";
import ButtonCustom from "../../../../components/Button";
import { useContext } from "react";
import { LocalizationContext } from "../../../../locales/Translation";

type Props = {
  onClick: (values: any) => void;
};

const ResetPasswordForm = ({ onClick }: Props) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <Formik
      initialValues={{
        plain_password: "",
        confirmPassword: "",
      }}
      validationSchema={yup.object().shape({
        plain_password: yup.string().required("Password is required"),
        confirmPassword: yup
          .string()
          .test("passwords-match", "Passwords must match", function (value) {
            return this.parent.plain_password === value;
          }),
      })}
      onSubmit={onClick}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form>
          <InputCustom
            label={translations.newPassword}
            value={values.plain_password}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.plain_password}
            error={errors.plain_password}
            name={"plain_password"}
            type={"password"}
            passwordShow
          />
          <InputCustom
            label={translations.repeatNewPassword}
            value={values.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.confirmPassword}
            error={errors.confirmPassword}
            name={"confirmPassword"}
            type={"password"}
            passwordShow
          />
          <div className={"actions"}>
            <ButtonCustom
              height={"46px"}
              onClick={handleSubmit}
              loading={isSubmitting}
              className={"primary w-100"}
            >
              {translations.updatePassword}
            </ButtonCustom>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
