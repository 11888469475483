import { getCompanyIntegrationService } from "../services/SettingServices";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { integrationListAction } from "../actions/IntegrationAction";

export const integrationListThunk = () => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await getCompanyIntegrationService();
    dispatch<any>(integrationListAction(response?.data));
  };
};
