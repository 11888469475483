import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../../../locales/Translation";
import { INVOICE_DETAIL_LINK } from "../../../../../../App/configs/ListRoutes";
import { getURlDownloadFileService } from "../../../../../services/InvoiceServices";
import { PUBLIC_URL } from "../../../../../../App/configs/Constants";
import { businessInvoiceRepayService } from "../../../../../../Business/services/BusinessServices";
import {
  toastError,
  toastSuccess,
} from "../../../../../../../components/Toast";
import DropdownCustom from "../../../../../../../components/DropdownCustom";
import ButtonCustom from "../../../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

type Props = {
  loading: boolean;
  item: any;
  onRefresh: () => void;
};

const Action = ({ item, onRefresh, loading }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const { invoice_xlsx_url, invoice_pdf_url, token, can_pay_now } = item;

  const _onDetail = () => {
    navigate(INVOICE_DETAIL_LINK.replaceAll(":token", token));
  };

  const handleDownloadFile = async (url: string) => {
    if (!url) {
      return;
    }
    try {
      const response = await getURlDownloadFileService(url);
      window.open(response?.data?.url, "_blank");
    } catch (error) {
      console.log(Error);
    }
  };

  const _payNow = async () => {
    try {
      const { data } = await businessInvoiceRepayService(token);

      const nextAction = data?.next_action;
      if (nextAction) {
        window.open(nextAction, "_blank");
        return;
      }

      toastSuccess(translations.rePaySuccess);
      onRefresh();
    } catch (e: any) {
      toastError(e?.status?.message);
    }
  };

  return (
    <DropdownCustom
      disabled={loading}
      className={"table-action-dropdown"}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={_onDetail}
          text={translations.propertyPage.details}
          icon={<img src={PUBLIC_URL + "/assets/icons/icon-message.svg"} />}
        />
        <Dropdown.Item
          onClick={() => handleDownloadFile(invoice_pdf_url)}
          text={translations.settings.downloadAccountStatement}
          icon={
            <img
              className={"icon"}
              src={PUBLIC_URL + "/assets/icons/icon-download-black.svg"}
              alt=""
            />
          }
        />
        <Dropdown.Item
          onClick={() => handleDownloadFile(invoice_xlsx_url)}
          text={translations.settings.downloadXLSXFile}
          icon={
            <img
              className={"icon"}
              src={PUBLIC_URL + "/assets/icons/icon-xlsx-file-outline.svg"}
              alt=""
            />
          }
        />

        {can_pay_now ? (
          <Dropdown.Item
            onClick={_payNow}
            text={"Pay with CC"}
            icon={<i className="uil uil-history icon" />}
          />
        ) : null}
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default Action;
