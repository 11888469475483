export const POST_CONFIG_MARKET_LIST_API = "api/v1/des-admin/markets";
export const POST_CONFIG_MARKET_EXCHANGE_RATE_API =
  "api/v1/des-admin/market/exchange-rate";
export const GET_CONFIG_COMMISSION_SHARE_API =
  "api/v1/des-admin/market/commission-share";
export const POST_CREATE_CONFIG_MARKET_GENERAL_INFORMATION_API =
  "api/v1/des-admin/market/general-information";
export const GET_MARKET_TOGGLE_API = "api/v3/market-toggles";
// export const GET_MARKET_TOGGLE_API =
//   "https://run.mocky.io/v3/c30255b3-842c-42c6-ac6e-3b69785ac876";

export const POST_UPDATE_CONFIG_MARKET_GENERAL_INFORMATION_API =
  "api/v1/des-admin/market/general-information";
export const POST_UPDATE_CONFIG_COMMISSION_SHARE_API =
  "api/v1/des-admin/market/update-commission-share";
export const POST_UPDATE_CONFIG_MARKET_STATUS_API =
  "api/v1/des-admin/market/publish/:id";
export const GET_CONFIG_MARKET_GENERAL_INFORMATION_API =
  "api/v1/des-admin/market/:id/general-information";
export const POST_UPDATE_CONFIG_MARKET_COVER_IMAGE_API =
  "api/v1/des-admin/market/upload-cover-image";
export const POST_CONFIG_PREPAID_CREDIT_PACKAGE_LIST = "api/v3/credit-packages";
export const GET_CONFIG_PREPAID_CREDIT_PACKAGE_DETAIL =
  "api/v3/credit-packages/%id%";
export const POST_CREATE_CONFIG_PREPAID_CREDIT_PACKAGE =
  "api/v3/credit-package";
export const PUT_CONFIG_PREPAID_CREDIT_PACKAGE_UPDATE_STATUS =
  "api/v3/credit-packages/%id%/%action%";
export const DELETE_CONFIG_PREPAID_CREDIT_PACKAGE =
  "api/v3/credit-packages/%id%";

export const POST_CONFIG_MARKET_PUBLIC_HOLIDAYS_API =
  "api/v1/des-admin/market/public-holiday";
export const POST_CREATE_MARKET_PUBLIC_HOLIDAY_API =
  "api/v1/des-admin/market/create-public-holiday";
export const DELETE_MARKET_PUBLIC_HOLIDAYS_API =
  "api/v1/des-admin/market/delete-public-holiday/:id";

export const POST_CONFIG_MARKET_LANDMARK_API =
  "api/v1/des-admin/market/landmarks";
export const POST_CREATE_MARKET_LANDMARK_API =
  "/api/v1/des-admin/market/create-landmark";
export const DELETE_MARKET_LANDMARK_API =
  "api/v1/des-admin/market/delete-landmark/:id";

export const GET_ADDRESS_BY_GOOGLE_MAPS_API = ({ lat, long }: any) => {
  return `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${process.env.REACT_APP_GG_KEY}`;
};

export const GET_ONBOARD_PROMOTION_DATA_API =
  "api/v1/des-admin/market/%marketId%/onboarding-promotion";

export const GET_ONBOARD_PROMOTION_PAID_TRIAL_API =
  "/api/v3/des-admin/market/%marketId%/credit-package-onboarding-promotion";
export const POST_UPDATE_ONBOARD_PROMOTION_DATA_API =
  "api/v1/des-admin/market/%marketId%/onboarding-promotion";

export const UPDATE_ONBOARD_PROMOTION_PAID_TRIAL_API =
  "/api/v3/des-admin/market/%marketId%/credit-package-onboarding-promotion";
export const TAGS_LIST_API = "/api/v1/tags";
export const CREATE_TAG_API = "/api/v1/create-tag";
export const REMOVE_TAG_API = "/api/v1/remove-tag/%id%";

//Partner
export const PARTNER_CREDENTIAL_INFO_API =
  "/api/v3/business-partner/%companyId%/integration-credentials";
export const PARTNER_WEBHOOK_LIST_API =
  "/api/v3/business-partner/%companyId%/webhooks";
export const PARTNER_WEBHOOK_UPDATE_STATUS_API =
  "/api/v3/business-partner/%companyId%/webhooks/%webhookId%/status";
export const PARTNER_WEBHOOK_UPDATE_URL_API =
  "/api/v3/business-partner/%companyId%/webhooks/%webhookId%";
export const PARTNER_WEBHOOK_SEND_TEST_API =
  "/api/v3/business-partner/%companyId%/webhooks/%webhookId%/send-test";
export const PARTNER_LIMITATION_API =
  "/api/v3/business-partner/%companyId%/api-rate-limit";

export const GET_ROLE_PERMISSION_API = "/api/v3/permissions";
export const SAVE_ROLE_PERMISSION_API = "/api/v3/permissions";

//Accounting
export const GET_ACCOUNTING_API = "/api/v3/gbv-report";
export const REGENERATE_GBV_API = "/api/v3/gbv-report/%reference%";
export const CHECK_REGENERATE_GBV_API = "/api/v3/gbv-report/%reference%/check";
export const EXPORT_EXCEL_GBV_API = "/api/v3/gbv-report/%reference%";
