import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../../../locales/Translation";
import { useDispatch } from "react-redux";
import {
  changeToPAYGService,
  deleteVisitService,
  manualCheckOutService,
  onPayWithCredit,
  repayService,
} from "../../../../../services/DashboardServices";
import { dashboardDeskUsageThunk } from "../../../../../thunks/DashboardThunks";
import {
  DESK_USAGE_AWAITING_PAYMENT_STATUS,
  DESK_USAGE_ON_GOING_STATUS,
  PROPERTY,
  WALK_IN,
} from "../../../../../configs/Constants";
import {
  toastError,
  toastSuccess,
} from "../../../../../../../components/Toast";
import ButtonCustom from "../../../../../../../components/Button";
import { SWAL_DELETE_OPTION } from "../../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../../components/SweetAlert";
import { PUBLIC_URL } from "../../../../../../App/configs/Constants";
import { Dropdown } from "semantic-ui-react";
import DropdownCustom from "../../../../../../../components/DropdownCustom";
import { isGlobalIndicatorAction } from "../../../../../../App/actions/AppActions";

type Props = {
  statusId: number;
  visitId: number;
  canPayWithCredit: boolean;
  loading: boolean;
  setLoading: any;
  canChangeToPAYG?: boolean;
};

const DeskUsageAction = ({
  statusId,
  visitId,
  canPayWithCredit,
  loading,
  setLoading,
  canChangeToPAYG,
}: Props) => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const _onClickCheckout = async () => {
    const payload = {
      visit_id: visitId,
    };

    const icon = null;
    const title = "Are you sure you want to \n check-out?";
    const confirmText = "No";
    const cancelText = "Yes";
    const cancelClass = "primary";
    const options = SWAL_DELETE_OPTION({
      title,
      icon,
      confirmText,
      cancelText,
      cancelClass,
      confirmClass: "secondary",
    });

    SweetAlertCommon({
      options,
      cancelCallback: async () => {
        setLoading(true);
        try {
          dispatch(isGlobalIndicatorAction(true));
          await manualCheckOutService(payload);
          await dispatch<any>(
            dashboardDeskUsageThunk(PROPERTY, undefined, true)
          );
          toastSuccess("Check-out successful!");
        } catch (e: any) {
          toastError(e?.status?.message);
        } finally {
          dispatch(isGlobalIndicatorAction(false));
          setLoading(false);
        }
      },
      confirmCallback: () => null,
    });
  };

  const _onClickDelete = async () => {
    const iconHtml = (
      <img
        src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
        className={"swal2-icon__error"}
      />
    );
    const title = "Are you sure you want to delete this walk-in?";
    const confirmText = "Yes";
    const cancelText = "No";
    const options = SWAL_DELETE_OPTION({
      title,
      iconHtml,
      confirmText,
      cancelText,
      confirmClass: "danger",
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setLoading(true);
        try {
          dispatch(isGlobalIndicatorAction(true));
          await deleteVisitService(visitId);
          await dispatch<any>(
            dashboardDeskUsageThunk(PROPERTY, undefined, true)
          );
          toastSuccess("Deleted Successfully!");
        } catch (e: any) {
          toastError(e?.status?.message);
        } finally {
          setLoading(false);
          dispatch(isGlobalIndicatorAction(false));
        }
      },
      cancelCallback: () => null,
    });
  };

  const _onClickChangeToPAYG = async () => {
    const payload = {
      id: visitId,
      type: "VISIT",
    };

    const icon = null;
    const title = "Are you sure you want to \n change this visit to PAYG?";
    const confirmText = "Yes";
    const cancelText = "No";
    const options = SWAL_DELETE_OPTION({
      title,
      icon,
      confirmText,
      cancelText,
      cancelClass: "secondary",
      confirmClass: "primary",
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setLoading(true);
        try {
          await changeToPAYGService(payload);
          await dispatch<any>(
            dashboardDeskUsageThunk(PROPERTY, undefined, true)
          );
          toastSuccess("Update visit to PAYG successful!");
        } catch (e: any) {
          toastError(e?.status?.message);
        } finally {
          setLoading(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  const _onClickRepay = async () => {
    const payload = {
      id: visitId,
    };

    const icon = null;
    const title = "Are you sure you want to repay for this visit?";
    const confirmText = "Yes";
    const cancelText = "No";
    const options = SWAL_DELETE_OPTION({
      title,
      icon,
      confirmText,
      cancelText,
      cancelClass: "secondary",
      confirmClass: "primary",
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setLoading(true);
        try {
          await repayService(payload);
          await dispatch<any>(
            dashboardDeskUsageThunk(PROPERTY, undefined, true)
          );
          toastSuccess("Repay successful!");
        } catch (e: any) {
          toastError(e?.status?.message);
        } finally {
          setLoading(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  return (
    <DropdownCustom
      disabled={loading}
      className={"table-action-dropdown"}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        {statusId === DESK_USAGE_ON_GOING_STATUS ? (
          <Dropdown.Item
            disabled={loading}
            onClick={_onClickCheckout}
            text={translations.checkout}
            icon={<i className="uil icon uil-left-indent-alt" />}
          />
        ) : (
          <Dropdown.Item
            disabled={loading}
            onClick={_onClickDelete}
            text={translations.delete}
            icon={<i className="uil icon uil-trash-alt" />}
          />
        )}

        {canPayWithCredit ? (
          <Dropdown.Item
            onClick={() =>
              onPayWithCredit(WALK_IN, visitId, () =>
                dispatch<any>(
                  dashboardDeskUsageThunk(PROPERTY, undefined, true)
                )
              )
            }
            text={"Pay with credit"}
            icon={<i className="uil icon uil-bill" />}
          />
        ) : null}

        {canChangeToPAYG && (
          <Dropdown.Item
            onClick={_onClickChangeToPAYG}
            text={"Charge by PAYG"}
            icon={<i className="uil icon uil-exchange" />}
          />
        )}

        {statusId === DESK_USAGE_AWAITING_PAYMENT_STATUS && (
          <Dropdown.Item
            onClick={_onClickRepay}
            text={"Repay"}
            icon={<i className="uil icon uil-redo" />}
          />
        )}
      </Dropdown.Menu>
    </DropdownCustom>
  );

  // if (canPayWithCredit) {
  //   return (
  //     <DropdownCustom
  //       className={"table-action-dropdown"}
  //       direction={"left"}
  //       icon={null}
  //       trigger={
  //         <ButtonCustom
  //           style={{ width: "38px", height: "38px" }}
  //           className={"primary-alt"}
  //         >
  //           <i className="uil uil-setting" style={{ fontSize: "21px" }} />
  //         </ButtonCustom>
  //       }
  //     >
  //       <Dropdown.Menu>
  //         {statusId === DESK_USAGE_ON_GOING_STATUS ? (
  //           <Dropdown.Item
  //             onClick={_onClickCheckout}
  //             text={translations.checkout}
  //             icon={<i className="uil icon uil-left-indent-alt" />}
  //           />
  //         ) : (
  //           <Dropdown.Item
  //             onClick={_onClickDelete}
  //             text={translations.delete}
  //             icon={<i className="uil icon uil-trash-alt" />}
  //           />
  //         )}
  //
  //         <Dropdown.Item
  //           onClick={() =>
  //             onPayWithCredit(
  //               WALK_IN,
  //               visitId,
  //               dispatch<any>(
  //                 dashboardDeskUsageThunk(PROPERTY, undefined, true)
  //               )
  //             )
  //           }
  //           text={"Pay with credit"}
  //           icon={<i className="uil icon uil-bill" />}
  //         />
  //       </Dropdown.Menu>
  //     </DropdownCustom>
  //   );
  // }

  // if (statusId === DESK_USAGE_ON_GOING_STATUS) {
  //   return (
  //     <ButtonCustom
  //       className={"dashboard_checkout_btn"}
  //       fontSize={"12px"}
  //       onClick={_onClickCheckout}
  //     >
  //       {translations.checkout}
  //     </ButtonCustom>
  //   );
  // } else {
  //   return (
  //     <ButtonCustom
  //       className={"dashboard_checkout_btn"}
  //       fontSize={"12px"}
  //       onClick={_onClickDelete}
  //     >
  //       {translations.delete}
  //     </ButtonCustom>
  //   );
  // }
};

export default DeskUsageAction;
