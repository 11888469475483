import React, { useContext, useRef } from "react";
import "./styles.scss";
import SelectCapacity from "./InquireOfficeModal/SelectCapacity";
import InputCustom from "../../../../components/Input";
import Date from "./InquireOfficeModal/Date";
import FrequencyUsage from "./InquireOfficeModal/FrequencyUsage";
import WeekDays from "./InquireOfficeModal/WeekDays";
import TextAreaCustom from "../../../../components/Input/TextArea";
import ButtonCustom from "../../../../components/Button";
import SizeBox from "../../../../components/SizeBox";
import { LocalizationContext } from "../../../../locales/Translation";
import { useSelector } from "react-redux";
import { PUBLIC_URL } from "../../../App/configs/Constants";
import { SWAL_CONFIRM_OPTION } from "../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../components/SweetAlert";
import { Formik, FormikValues } from "formik";
import moment from "moment-timezone";
import { toastError } from "../../../../components/Toast";
import * as yup from "yup";
import InputPhoneFlag from "../../../../components/InputPhoneFlag";
import { myProfileSelector } from "../../../App/selectors/AppSelectors";
import {
  getSelectValueOfPrivateOfficeCapacity,
  inquireOfficeService,
} from "../../services/BookServices";
import {
  getCCA2FromDialingCode,
  getDialingCodeFromCCA2,
} from "../../../../services/CountryService";
import classNames from "classnames";

type Props = {
  onCloseModal?: () => void;
  moreInfo: {
    privateOfficeId?: number;
    currency: string;
    timezone: string;
    capacity?: number;
  };
  fullWidth?: boolean;
};

const InquireForm = ({ onCloseModal, moreInfo, fullWidth }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const myProfile = useSelector(myProfileSelector);

  const formRef = useRef(null);

  const _onSubmit = async (values: FormikValues) => {
    try {
      const payload: any = {
        ...values,
        estimate_start_date: moment(values.estimate_start_date)
          .tz(moreInfo.timezone, true)
          .utc()
          .unix(),
        from_source: "BO_APP",
        phone: `${getDialingCodeFromCCA2(values.country_code)} ${values.phone}`,
        currency: moreInfo?.currency,
      };

      delete payload.country_code;

      const privateOfficeId = moreInfo?.privateOfficeId;

      if (privateOfficeId) {
        payload.private_office_id = privateOfficeId;
      }
      await inquireOfficeService(payload);

      if (onCloseModal) {
        onCloseModal();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formRef.current.resetForm();
      }

      const iconHtml = (
        <img src={PUBLIC_URL + "/assets/icons/icon-check-green.svg"} />
      );
      const title = translations.thanksSubmitInquiry;
      const text = translations.thanksSubmitInquiryDescription;
      const confirmText = translations.close;
      const options = SWAL_CONFIRM_OPTION({
        title,
        text,
        iconHtml,
        confirmText,
        confirmClass: "primary",
        showCancelButton: false,
      });
      SweetAlertCommon({
        options,
        confirmCallback: () => null,
      });
    } catch (e: any) {
      toastError(e?.status?.message);
    }
  };

  return (
    <div
      className={classNames("book-inquire_box", {
        "full-width": fullWidth,
      })}
    >
      <div className={"title"}>{translations.inquireForPrivateOffice}</div>
      <div className={"description"}>
        {translations.SelectTheFrequencyOfUsageAndYourMonthlyBudget}
      </div>
      <Formik
        innerRef={formRef}
        initialValues={{
          capacity: moreInfo?.capacity
            ? getSelectValueOfPrivateOfficeCapacity(moreInfo?.capacity)
            : "1",
          monthly_budget: "",
          estimate_start_date: moment()
            .add(1, "month")
            .startOf("month")
            .toDate(),
          frequency: "part_time",
          frequency_information: [],
          additional_comments: "",
          phone: myProfile?.phone_number || "",
          country_code: getCCA2FromDialingCode(myProfile?.country_code) || "SG",
          fullname: myProfile?.full_name,
          email: myProfile?.email,
        }}
        validationSchema={yup.object().shape({
          monthly_budget: yup
            .number()
            .typeError(translations.settings.inputTypeNumberOnly),
          phone: yup.number().required().label(translations.phoneNumber),
        })}
        onSubmit={_onSubmit}
      >
        {({
          handleChange,
          setFieldValue,
          isSubmitting,
          values,
          handleSubmit,
          touched,
          errors,
          resetForm,
        }) => (
          <>
            <div className={"book-inquire_form"}>
              <SelectCapacity
                value={values.capacity}
                onChange={(value) => setFieldValue("capacity", value)}
              />
              <InputCustom
                label={translations.monthlyBudget}
                inputLeftIcon={moreInfo.currency}
                inputLeftIconBorder
                name={"monthly_budget"}
                value={values.monthly_budget}
                onChange={handleChange}
                touched={touched.monthly_budget}
                error={errors.monthly_budget}
              />

              <Date
                label={translations.estimatedStartDate}
                value={values.estimate_start_date}
                onChange={(value) =>
                  setFieldValue("estimate_start_date", value)
                }
              />
              <FrequencyUsage
                value={values.frequency}
                onChange={(value) => setFieldValue("frequency", value)}
              />
            </div>
            <SizeBox height={"16px"} />

            {values.frequency === "part_time" ? (
              <>
                <WeekDays
                  value={values.frequency_information}
                  onChange={(value) =>
                    setFieldValue("frequency_information", value)
                  }
                />
                <SizeBox height={"16px"} />
              </>
            ) : null}

            {myProfile?.phone_number ? null : (
              <>
                <InputPhoneFlag
                  label={translations.phoneNumber}
                  selectedFlag={values.country_code}
                  onSelectFlag={(code) => setFieldValue("country_code", code)}
                  error={errors.phone}
                  touched={touched.phone}
                  phoneChange={(value) => setFieldValue("phone", value)}
                  phoneValue={values.phone}
                  required
                />
                <SizeBox height={"16px"} />
              </>
            )}

            <TextAreaCustom
              label={translations.additionalComments}
              rows={"7"}
              name={"additional_comments"}
              value={values.additional_comments}
              onChange={handleChange}
            />
            <div className={"actions"}>
              {fullWidth ? (
                <>
                  <ButtonCustom
                    loading={isSubmitting}
                    onClick={resetForm}
                    fontSize={"16px"}
                  >
                    {translations.cancel}
                  </ButtonCustom>
                  <SizeBox width={"16px"} />
                </>
              ) : null}
              <ButtonCustom
                className={"yellow"}
                loading={isSubmitting}
                onClick={handleSubmit}
                fontSize={"16px"}
              >
                {translations.SendInquiry}
              </ButtonCustom>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default InquireForm;
