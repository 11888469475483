import Loader from "../../../../components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { toastError, toastSuccess } from "../../../../components/Toast";
import { requestParkingChangeStatusService } from "../../../Book/services/BookServices";

const ParkingRequestCallback = () => {
  const { action, bookingId, reference, bookingType, token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        if (!action || !bookingType || !bookingId || !reference || !token) {
          navigate("/");
          return;
        }
        const res = await requestParkingChangeStatusService(
          Number(bookingId),
          reference,
          {
            status: action,
            type: bookingType,
            token,
          }
        );
        toastSuccess(res?.status?.message);

        const nextUrl = res?.data?.next_url;
        if (res?.data?.next_url) {
          navigate(nextUrl);
        } else {
          navigate("/");
        }
      } catch (e: any) {
        toastError(e?.status?.message);
        navigate("/");
      }
    })();
  }, []);

  return <Loader fullScreen />;
};

export default ParkingRequestCallback;
