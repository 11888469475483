import MasterTemplate from "../../../App/views/MasterTemplate";
import SearchProperty from "./Components/SearchProperty";
import FilterProperty from "./Components/FilterProperty";
import "./style.scss";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { getPropertyThunk } from "../../thunks/PropertyThunks";
import { useDispatch, useSelector } from "react-redux";
import { propertyListFiltersService } from "../../services/PropertyServices";
import { ROLE_PROPERTY_ADMIN_ID } from "../../../User/configs/Constants";
import PropertyListTable from "./Components/PropertyListTable";
import { userCompanyChooseSelector } from "../../../User/selectors/UserSelectors";

const PropertyList = () => {
  const dispatch = useDispatch();
  const [propertyFilter, setPropertyFilter] = useState({
    page: 1,
    keyword: "",
    statuses: [],
  });
  const [dropdownPropertyFilter, setDropdownPropertyFilter] = useState([]);
  const [refreshList, setRefreshList] = useState(false);

  const userCompanyChoose = useSelector(userCompanyChooseSelector);

  useEffect(() => {
    setPropertyFilter((prevState) => ({ ...prevState, page: 1 }));
  }, [userCompanyChoose]);

  useEffect(() => {
    _getPropertyFilters();
  }, []);

  useEffect(() => {
    if (!propertyFilter?.statuses?.length) {
      return;
    }
    _getPropertyList();
  }, [propertyFilter, refreshList]);

  const _getPropertyFilters = async () => {
    try {
      const response = await propertyListFiltersService();
      const filters = response?.data;
      if (filters.length > 0) {
        setDropdownPropertyFilter(
          filters.map((filter: any) => {
            return {
              id: filter?.status,
              name: `${filter?.status_name} (${filter?.total})`,
            };
          })
        );
        setPropertyFilter((prevState) => {
          return {
            ...prevState,
            statuses: filters.map((filter: any) => filter?.status),
          };
        });
      }
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    setPropertyFilter((prevState) => {
      return { ...prevState, page: activePage };
    });
  };

  const _getPropertyList = async () => {
    try {
      await dispatch<any>(getPropertyThunk(propertyFilter));
    } catch (e) {
      console.error(e);
    }
  };

  const _onChangeFilter = (e: SyntheticEvent, data: any) => {
    if (data.checked) {
      setPropertyFilter((prevState: any) => {
        return {
          ...prevState,
          statuses: [...propertyFilter.statuses, data.value],
        };
      });
    } else {
      setPropertyFilter((prevState: any) => {
        return {
          ...prevState,
          statuses: propertyFilter.statuses.filter(
            (status: any) => status !== data.value
          ),
        };
      });
    }
  };

  const _onSearch = (data: any, { value }: any) => {
    setPropertyFilter((prevState) => {
      return { ...prevState, page: 1, keyword: value };
    });
  };

  return (
    <MasterTemplate roleAccept={[ROLE_PROPERTY_ADMIN_ID]}>
      <div className={"filter-section"}>
        <SearchProperty onSearch={_onSearch} />
        <div className={"dropdown-filter d-flex"}>
          <FilterProperty
            values={propertyFilter.statuses}
            onChangeFilter={_onChangeFilter}
            dropdownPropertyFilter={dropdownPropertyFilter}
          />
        </div>
      </div>

      <PropertyListTable
        page={propertyFilter.page}
        onChangePagination={_onChangePagination}
        setRefreshList={setRefreshList}
        refreshList={refreshList}
      />
    </MasterTemplate>
  );
};

export default PropertyList;
