import React, { useContext } from "react";
import { LocalizationContext } from "../../../../locales/Translation";

type Props = {
  price: string;
  originalPrice?: string;
  unit?: string;
};
const Price = ({ price, originalPrice, unit }: Props) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <div className={"book-detail__section"}>
      <div className={"book-detail__section-title"}>
        {translations.settings.price}
      </div>
      <div className={"book-detail__price-wrapper"}>
        {originalPrice ? (
          <div className={"book-detail__original-price"}>
            <span className={"value"}>
              {originalPrice} / {unit ? unit : translations.day}
            </span>
          </div>
        ) : null}
        <div className={"book-detail__price"}>
          {translations.StartingFrom}{" "}
          <span className={"value"}>
            {price} / {unit ? unit : translations.day}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Price;
