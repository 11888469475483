import React, { useContext } from "react";
import Avatar from "../../../../../components/Avatar";
import PhoneWithFlag from "../../../../../components/PhoneWithFlag";
import ButtonCustom from "../../../../../components/Button";
import { LocalizationContext } from "../../../../../locales/Translation";
import { modalEditEmployeeAction } from "../../../actions/EmployeeActions";
import { useDispatch, useSelector } from "react-redux";
import { employeeDetailSelector } from "../../../selectors/EmployeeSelectors";
import { DeskimoUserDetailType } from "../../../configs/DeclareTypes";
import Label from "../../../../../components/Label";
import { getTwoFirstLetter } from "../../../../../services/FormatTextService";
import { Link } from "react-router-dom";
import {
  BUSINESS_COMPANY_DETAIL_LINK,
  DESKIMO_PROPERTY_COMPANY_DETAIL_LINK,
  DESKIMO_USER_DETAIL_LINK,
} from "../../../../App/configs/ListRoutes";
import {
  ROLE_BUSINESS_ADMIN_ID,
  ROLE_BUSINESS_USER_ID,
} from "../../../configs/Constants";
import { onLogInAsAccount } from "../../../services/UserServices";

const UserSummary = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const {
    first_name,
    last_name,
    email,
    dialing_code,
    phone,
    user_types,
    avatar,
    user_id,
  }: DeskimoUserDetailType = useSelector(employeeDetailSelector);

  const fullName = `${first_name} ${last_name}`;

  return (
    <>
      <div className={"user_detail_summary"}>
        <Avatar
          size={"112px"}
          noTooltip
          imgUrl={avatar}
          capName={getTwoFirstLetter(fullName)}
        />
        <div className={"info"}>
          <div className={"d-flex"}>
            <h2 className={"name"}>{fullName}</h2>

            <div className={"action"}>
              <ButtonCustom
                height={"30px"}
                fontSize={"12px"}
                className={"edit-link reverse mr-0"}
                onClick={() => dispatch(modalEditEmployeeAction(true))}
              >
                <>
                  {translations.edit}
                  <i className="uil uil-edit" />
                </>
              </ButtonCustom>
            </div>
          </div>
          <div className={"user-detail__info"}>
            <div className={"user-detail__info-left"}>
              <p>{email}</p>
              <PhoneWithFlag dialingCode={dialing_code} phoneNumber={phone} />
            </div>
            <div className={"user-detail__info-right"}>
              {user_types.length ? (
                <div>
                  <Label
                    style={{ marginBottom: 8 }}
                    fontSize={"14px"}
                    className={"label"}
                    label={translations.userType + ":"}
                  />
                  <div className={"user-type-list"}>
                    {user_types.map((item, index) => (
                      <div className={"user-type-item"} key={index}>
                        <Link
                          to={
                            item.role_id === ROLE_BUSINESS_USER_ID ||
                            item.role_id === ROLE_BUSINESS_ADMIN_ID
                              ? BUSINESS_COMPANY_DETAIL_LINK.replaceAll(
                                  ":id",
                                  String(item.company_id)
                                )
                              : DESKIMO_PROPERTY_COMPANY_DETAIL_LINK.replaceAll(
                                  ":id",
                                  String(item.company_id)
                                )
                          }
                          key={item.company_name}
                          className={"type"}
                        >
                          <span className={"middle-dot"}>·</span>
                          {item.role} ({item.company_name})
                        </Link>
                        <span className={"middle-dot"}>·</span>
                        <span
                          className={"text-link"}
                          onClick={async () => {
                            await onLogInAsAccount(
                              user_id,
                              DESKIMO_USER_DETAIL_LINK.replaceAll(
                                ":id",
                                String(user_id)
                              ),
                              item?.company_id
                            );
                          }}
                        >
                          Log in as user account
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className={"section-white-box_line info"}></div>
    </>
  );
};

export default UserSummary;
