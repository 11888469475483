import { createReducer } from "@reduxjs/toolkit";
import {
  IS_AUTH_ACTION,
  PERMISSION_ACTION,
  REFRESH_SESSION_ACTION,
} from "../configs/Constants";

const initializeState = {
  isAuth: false,
  permissionList: [],
  refreshSession: false,
};

const AuthenticationReducer = createReducer(initializeState, {
  [IS_AUTH_ACTION]: (state, action) => {
    state.isAuth = action.payload;
  },
  [PERMISSION_ACTION]: (state, action) => {
    state.permissionList = action.payload;
  },
  [REFRESH_SESSION_ACTION]: (state) => {
    state.refreshSession = !state.refreshSession;
  },
});

export default AuthenticationReducer;
