import React from "react";
import { useSelector } from "react-redux";
import { businessCompanyDetailSelector } from "../../../selectors/BusinessSelectors";
import { BusinessCompanyDetailType } from "../../../configs/DeclareTypes";
import Information from "./Information";

const WMSConfiguration = () => {
  const businessCompanyDetail: BusinessCompanyDetailType = useSelector(
    businessCompanyDetailSelector
  );
  return (
    <>
      <div className={"company-detail__title"}>
        <div className="section-white-box_title">WMS Configuration</div>
      </div>
      <div className="section-white-box-column">
        <div>
          <Information
            label={"Number of free trial days"}
            value={`${businessCompanyDetail.number_of_trial_day || 0} ${
              businessCompanyDetail.number_of_trial_day > 1 ? "days" : "day"
            }`}
          />
          <Information
            label={"WMS price per user"}
            value={`USD ${businessCompanyDetail.wms_price_per_user || 0}`}
          />
        </div>
      </div>
      <div className={"section-white-box_line"} />
    </>
  );
};

export default WMSConfiguration;
