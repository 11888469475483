import ButtonCustom from "../../../../../components/Button";
import React, { useState, useContext } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import SizeBox from "../../../../../components/SizeBox";
import ScheduleTable from "./ScheduleTable";
import ModalReportPropertyStatus from "./ModalReportPropertyStatus/ModalReportPropertyStatus";

const Schedule = ({ propertyDetail, type }: any) => {
  const [tabIndex, setTabIndex] = useState(1);
  const { translations } = useContext(LocalizationContext);
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        className={"book-detail__section"}
        style={{ marginTop: 17, marginBottom: 36 }}
      >
        <div className={"d-flex justify-content-between flex-1 "}>
          <div className={"book-detail__section-title"}>
            {translations.schedule}
          </div>
          <ButtonCustom
            onClick={() => {
              setOpen(true);
            }}
            style={{ marginBottom: 12, height: 36, marginRight: 0 }}
            height={"36px"}
            className={"button-custom pill outline-primary"}
          >
            Report an issue with the property
          </ButtonCustom>
        </div>
        <div>
          <div className={"button-group yellow"}>
            <ButtonCustom
              onClick={() => setTabIndex(1)}
              height={"56px"}
              className={tabIndex === 1 ? "active" : ""}
            >
              {translations.propertyPage.operatingHours}
            </ButtonCustom>
            <ButtonCustom
              onClick={() => setTabIndex(2)}
              height={"56px"}
              className={tabIndex === 2 ? "active" : ""}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: translations.propertyPage.communityTeamOperatingHours,
                }}
              />
            </ButtonCustom>
            <ButtonCustom
              onClick={() => setTabIndex(3)}
              height={"56px"}
              className={tabIndex === 3 ? "active" : ""}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: translations.propertyPage.airconOperatingHours,
                }}
              />
            </ButtonCustom>
          </div>
          <div>
            <SizeBox height={"23px"} />
            {tabIndex === 1 ? (
              <ScheduleTable
                schedule={propertyDetail?.schedule_info?.open_hour}
              />
            ) : null}
            {tabIndex === 2 ? (
              <ScheduleTable
                schedule={propertyDetail?.schedule_info?.community}
              />
            ) : null}
            {tabIndex === 3 ? (
              <ScheduleTable schedule={propertyDetail?.schedule_info?.aircon} />
            ) : null}
          </div>
        </div>
      </div>
      {open ? (
        <ModalReportPropertyStatus open={open} setOpen={setOpen} type={type} />
      ) : null}
    </>
  );
};

export default Schedule;
