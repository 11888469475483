import React, { useEffect, useRef, useState } from "react";
import SectionWhiteBox from "../../../../components/SectionWhiteBox";
import "./styles.scss";
import Table from "../../../../components/Table";
import Action from "./Action";
import moment from "moment";
import FilterYear from "./FilterYear";
import SizeBox from "../../../../components/SizeBox";
import { accountingListSelector } from "../../selectors/ConfigSelectors";
import { useDispatch, useSelector } from "react-redux";
import { toastError } from "../../../../components/Toast";
import { getAccountingThunk } from "../../thunks/ConfigThunks";
import {
  exportExcelService,
  regenerateGBVService,
} from "../../services/ConfigServices";
import { SweetAlertCommon } from "../../../../components/SweetAlert";
import { SWAL_CUSTOM_CONTENT } from "../../../../components/SweetAlert/configs/SwalConfigs";
import ButtonCustom from "../../../../components/Button";
import SwalRegenerate from "./components/SwalRegenerate";

const Accounting = () => {
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    year: moment().get("years"),
  });
  const accountingList = useSelector(accountingListSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        getListAccounting();
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [filters]);

  const getListAccounting = async () => {
    try {
      dispatch<any>(getAccountingThunk(filters));
    } catch (error: any) {
      toastError(error?.status?.message);
    }
  };

  const _onRegenerate = async (reference: string) => {
    try {
      setLoading(true);
      const { data } = await regenerateGBVService(reference);
      SweetAlertCommon({
        options: SWAL_CUSTOM_CONTENT({
          content: <SwalRegenerate reference={data?.checked_token} />,
          cancelButtonClass: "d-none",
          denyButtonClass: "d-none",
          confirmButtonText: (
            <ButtonCustom className={"w-100 m-0"} height={"36px"}>
              Close
            </ButtonCustom>
          ),
        }),
        confirmCallback() {
          console.log("Confirm");
        },
        cancelCallback() {
          console.log("Cancel");
        },
      });
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const _onExportExcel = async (reference: string) => {
    try {
      setLoading(true);
      const response = await exportExcelService(reference);
      if (response?.data?.download_link) {
        window.open(response?.data?.download_link, "blank");
      }
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const _formatData = () => {
    if (accountingList?.items && accountingList?.items?.length > 0) {
      return accountingList?.items?.map((item: any) => ({
        name: item?.name,
        action: (
          <Action
            reference={item?.reference}
            onRegenerate={_onRegenerate}
            onExportExcel={_onExportExcel}
          />
        ),
      }));
    }
    return [];
  };

  return (
    <SectionWhiteBox className={"accounting"}>
      <h1>Export Net/GBV</h1>
      <div>
        <FilterYear
          value={filters.year}
          onChange={(value) => setFilters(() => ({ year: value }))}
        />
      </div>
      <SizeBox height={"30px"} />

      <Table
        tableClass={"accounting-table"}
        showTotal={false}
        columns={["Month", "Action"]}
        tableData={_formatData()}
        loading={loading}
        totalItems={undefined}
      />
    </SectionWhiteBox>
  );
};

export default Accounting;
