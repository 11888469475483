import React, { useEffect, useState } from "react";
import FilterPrivateOffice from "../Components/FilterPrivateOffice";
import SizeBox from "../../../../components/SizeBox";
import PrivateOfficeList from "./PrivateOfficeList";
import ModalInquireOffice from "./InquireOfficeModal/ModalInquireOffice";
import { useDispatch, useSelector } from "react-redux";
import { modalPrivateOfficeAction } from "../../actions/BookActions";
import {
  filterMarketSelector,
  marketHasPrivateOfficesSelector,
  modalPrivateOfficeSelector,
  privateOfficeFilterSelector,
} from "../../selectors/BookSelectors";
import { privateOfficeListThunk } from "../../thunks/BookThunks";
import InquireForm from "./InquireForm";
import CardPlaceholder from "../Desk/CardPlaceholder";

const InquirePrivateOffice = () => {
  const dispatch: any = useDispatch();
  const modalPrivateOffice = useSelector(modalPrivateOfficeSelector);
  const [loading, setLoading] = useState(false);

  const privateOfficeFilter = useSelector(privateOfficeFilterSelector);
  const filterMarket = useSelector(filterMarketSelector);

  const marketHasPrivateOffices = useSelector(marketHasPrivateOfficesSelector);

  useEffect(() => {
    (async () => {
      try {
        if (!privateOfficeFilter?.city_code) {
          return;
        }
        setLoading(true);
        await dispatch(privateOfficeListThunk(privateOfficeFilter));
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [privateOfficeFilter]);

  return (
    <>
      {loading ? (
        <CardPlaceholder />
      ) : marketHasPrivateOffices ? (
        <>
          <FilterPrivateOffice />
          <SizeBox height={"16px"} />
          <PrivateOfficeList />
        </>
      ) : (
        <InquireForm
          fullWidth
          moreInfo={
            modalPrivateOffice?.data || {
              currency: filterMarket?.currency,
              timezone: filterMarket?.timezone,
            }
          }
        />
      )}

      <ModalInquireOffice
        open={modalPrivateOffice?.isOpen}
        onClose={() => dispatch(modalPrivateOfficeAction({ isOpen: false }))}
        moreInfo={
          modalPrivateOffice?.data || {
            currency: filterMarket?.currency,
            timezone: filterMarket?.timezone,
          }
        }
      />
    </>
  );
};

export default InquirePrivateOffice;
