import Radio from "../../../../../../../components/Radio";
import InputCustom from "../../../../../../../components/Input";

const ConditionRow = ({
  isRadio,
  isCheck,
  content,
  unit,
  onChangeRadio,
  onChangeInput,
  inputValue,
  touched,
  error,
}: any) => {
  return (
    <div className={"condition-row "}>
      {isRadio ? (
        <Radio
          onChange={onChangeRadio}
          className={"p-0 m-0"}
          checked={isCheck}
        />
      ) : null}

      <div className={"condition-info " + (isCheck ? "" : "disabled")}>
        <div>{content}</div>
        <div className={"condition-value"}>
          {unit ? (
            <InputCustom
              value={inputValue}
              onChange={onChangeInput}
              containerClass={"mb-0"}
              type={"number"}
              touched={touched}
              error={error}
            />
          ) : null}

          <div style={{ width: 44 }}>{unit}</div>
        </div>
      </div>
    </div>
  );
};

export default ConditionRow;
