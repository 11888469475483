import React, { useContext, useState, useEffect } from "react";
import { Modal } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { isModalAddStaffSelector } from "../../../../../../selectors/PropertySelector";
import { LocalizationContext } from "../../../../../../../../locales/Translation";
import { toastError } from "../../../../../../../../components/Toast";
import {
  modalAddStaffAction,
  modalCreatePropertyStaffAction,
} from "../../../../../../actions/PropertyActions";
import ModalCenterTemplate from "../../../../../../../App/views/ModalCenterTemplate";
import ButtonCustom from "../../../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../../../App/configs/Constants";
import DropdownCustom from "../../../../../../../../components/DropdownCustom";
import { getStaffService } from "../../../../../../services/PropertyServices";
import { useParams } from "react-router-dom";
import { ROLE_PROPERTY_ADMIN } from "../../../../../../configs/Constants";

const ModalAddStaff = ({ addStaff }: any) => {
  const dispatch = useDispatch();
  const isModalAddStaff = useSelector(isModalAddStaffSelector);
  const [loading, setLoading] = useState(false);
  const { translations } = useContext(LocalizationContext);
  const [staff, setStaff] = useState<any>([]);
  const [staffSelected, setStaffSelected] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    getStaff();
  }, []);

  const getStaff = async () => {
    try {
      if (!id) {
        return;
      }
      const response = await getStaffService(id);
      if (response?.data && response?.data?.length > 0) {
        setStaff(
          response?.data.map((user: any) => {
            return {
              key: user?.user_id,
              value: user?.user_id,
              text: user?.full_name,
              user_id: user?.user_id,
              full_name: user?.full_name,
              avatar: user?.avatar,
              cap_name: user?.cap_name,
              role_id: user?.roleId,
            };
          })
        );
        setStaffSelected(response?.data[0].user_id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //Call Api get user list

  const _onClickSave = async () => {
    if (!staff.length) {
      return;
    }

    try {
      setLoading(true);
      const newStaff = staff.filter(
        (user: any) => user?.key === staffSelected
      )[0];
      newStaff.is_property_admin = newStaff?.role_id === ROLE_PROPERTY_ADMIN;
      addStaff(newStaff);
      const newStaffList = staff.filter(
        (user: any) => user.user_id !== staffSelected
      );
      setStaff(newStaffList);
      dispatch(modalAddStaffAction(false));

      if (!newStaffList.length) {
        return;
      }
      setStaffSelected(newStaffList[0].user_id);
    } catch (response: any) {
      console.log(response);
      toastError(response?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={() => dispatch(modalAddStaffAction(false))}
      modalClassName={"modal-general modal-vertical-centered"}
      open={isModalAddStaff}
    >
      <>
        <Modal.Header>
          {"Add Staff"}
          <ButtonCustom
            height={"24px"}
            onClick={() => dispatch(modalAddStaffAction(false))}
            className={"modal-close-cta"}
          >
            <>
              <span>{translations.settings.close}</span>
              <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
            </>
          </ButtonCustom>
        </Modal.Header>
        <Modal.Content style={{ paddingBottom: 0 }}>
          <div
            className="form-layout"
            style={{
              display: "flex",
              flexFlow: "column nowrap",
              gap: 12,
              alignItems: "flex-start",
            }}
          >
            <div className="form-row w-100">
              <DropdownCustom
                scrolling
                style={{ height: 44, borderRadius: 4 }}
                className={"w-100"}
                selectUI={true}
                options={staff?.length > 0 ? staff : []}
                onChange={(e, data: any) => {
                  setStaffSelected(data.value);
                }}
                defaultValue={staff?.length > 0 ? staff[0]?.value : ""}
              />
            </div>
            <ButtonCustom
              className={"link px-0 mr-0"}
              width={"auto"}
              height={"32px"}
              onClick={() =>
                dispatch(modalCreatePropertyStaffAction({ open: true }))
              }
            >
              <>
                <img
                  src={PUBLIC_URL + "/assets/icons/icon-plus-blue.svg"}
                  alt={""}
                />
                Add a new staff member
              </>
            </ButtonCustom>
          </div>
        </Modal.Content>
        <Modal.Actions style={{ borderTop: "none", paddingTop: 15 }}>
          <ButtonCustom
            className={"default"}
            height={"47px"}
            onClick={() => dispatch(modalAddStaffAction(false))}
          >
            {translations.settings.cancel}
          </ButtonCustom>
          <ButtonCustom
            loading={loading}
            className={"primary"}
            height={"47px"}
            onClick={_onClickSave}
          >
            {"Add"}
          </ButtonCustom>
        </Modal.Actions>
      </>
    </ModalCenterTemplate>
  );
};

export default ModalAddStaff;
