import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./style.scss";

type Props = {
  options?: any;
  confirmCallback?: any;
  cancelCallback?: any;
  denyCallback?: any;
};

export const SweetAlertCommon = ({
  options,
  confirmCallback,
  cancelCallback = undefined,
  denyCallback = undefined,
}: Props) => {
  setTimeout(() => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      icon: "question",
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes",
      showCancelButton: true,
      ...options,
    }).then((result: any) => {
      if (result.value) {
        if (options?.input) {
          confirmCallback(result.value);
        } else {
          confirmCallback();
        }
      }
      if (result.dismiss === "cancel") {
        if (!cancelCallback) {
          return;
        }
        cancelCallback();
      }
      if (result?.isDenied) {
        console.log("Denied");
        denyCallback();
      }
    });
  }, 100);
};
