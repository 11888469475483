import { Dimmer, Tab } from "semantic-ui-react";
import PropertyMeta from "./Components/PropertyMeta";
import PropertyDescription from "./Components/PropertyDescription";
import PropertyHowToGetThere from "./Components/PropertyHowToGetThere";
import PropertyParkingLocation from "./Components/PropertyParkingLocation";
import PropertyContact from "./Components/PropertyContact";
import PropertyAmenities from "./Components/PropertyAmenities";
import PropertyWifi from "./Components/PropertyWifi";
import PropertySchedule from "./Components/PropertySchedule";
import PropertyStaff from "./Components/PropertyStaff";
import PropertyHouseRule from "./Components/PropertyHouseRule";
import React from "react";
import Loader from "../../../../../../components/Loader";

const DetailsTab = ({ loading, setLoading }: any) => {
  return (
    <Tab.Pane attached={false} className={"setting-tab property"}>
      {loading ? (
        <Dimmer active>
          <Loader />
        </Dimmer>
      ) : (
        <>
          <PropertyMeta loading={loading} setLoading={setLoading} />
          <PropertyDescription loading={loading} setLoading={setLoading} />
          <PropertyHowToGetThere loading={loading} setLoading={setLoading} />
          <PropertyParkingLocation loading={loading} setLoading={setLoading} />
          <PropertyContact loading={loading} setLoading={setLoading} />
          <PropertyAmenities loading={loading} setLoading={setLoading} />
          <PropertyWifi loading={loading} setLoading={setLoading} />
          <div className={"d-flex section property-schedule-staff__wrapper"}>
            <PropertySchedule loading={loading} setLoading={setLoading} />
            <PropertyStaff />
          </div>
          <PropertyHouseRule loading={loading} setLoading={setLoading} />
        </>
      )}
    </Tab.Pane>
  );
};

export default DetailsTab;
