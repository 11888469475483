import "./styles.scss";

type Props = {
  text: string;
  icon?: any;
  image?: any;
  className?: string;
};

const CustomBadge = ({ text, icon, image, className }: Props) => {
  return (
    <div className={"custom-badge " + className}>
      {icon ? icon : null}
      {image ? image : null}
      <span style={{ whiteSpace: "nowrap" }}>{text}</span>
    </div>
  );
};

export default CustomBadge;
