import clientRequestService from "../../../services/ClientRequestService";
import {
  DELETE_PROPERTY_COMPANY_API,
  DESKIMO_COMPANY_PUBLISH_API,
  GET_LIST_MARKET_COMPANY_API,
  GET_PROPERTY_COMPANY_API,
  GET_PROPERTY_INVOICE_DETAIL_API,
  GET_RETRIEVE_PROPERTY_LIST_API,
  POST_ADD_CUSTOM_FEE_API,
  POST_DELETE_PROPERTY_INVOICE_API,
  POST_FETCH_LIST_PROPERTY_INVOICE_API,
  POST_GENERATE_PROPERTY_INVOICE_API,
  PROPERTY_COMPANY_API,
  PROPERTY_INVOICE_POST_PAID_API,
  RESEND_PROPERTY_INVOICE_API,
} from "../configs/Endpoints";

export const propertyCompanyService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: PROPERTY_COMPANY_API,
    data,
  });
};

export const getPropertyCompanyService = async (companyId: string) => {
  return await clientRequestService({
    url: GET_PROPERTY_COMPANY_API.replaceAll("%companyId%", companyId),
  });
};

export const companyPublishService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: DESKIMO_COMPANY_PUBLISH_API,
    data,
  });
};

export const deletePropertyCompanyService = async (companyId: string) => {
  return await clientRequestService({
    method: "delete",
    url: DELETE_PROPERTY_COMPANY_API.replaceAll("%companyId%", companyId),
  });
};

export const getPropertyInvoiceListService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: POST_FETCH_LIST_PROPERTY_INVOICE_API,
    data,
  });
};

export const getListMarketAndCompanyService = async (data: {
  getOnlyActive: number;
}) => {
  return await clientRequestService({
    method: "POST",
    url: GET_LIST_MARKET_COMPANY_API,
    data,
  });
};

export const generatePropertyInvoiceService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: POST_GENERATE_PROPERTY_INVOICE_API,
    data,
  });
};

export const getPropertyInvoiceDetailService = async (
  companyId: any,
  token: any
) => {
  return await clientRequestService({
    method: "get",
    url: GET_PROPERTY_INVOICE_DETAIL_API.replaceAll(
      "%companyId%",
      companyId
    ).replaceAll("%token%", token),
  });
};

export const deleteInvoiceService = async (token: any) => {
  return await clientRequestService({
    method: "post",
    url: POST_DELETE_PROPERTY_INVOICE_API.replaceAll("%token%", token),
  });
};

export const resendPropertyInvoiceService = async (token: any) => {
  return await clientRequestService({
    method: "post",
    url: RESEND_PROPERTY_INVOICE_API.replaceAll("%token%", token),
  });
};

export const addCustomFeeService = async (data: any, token: any) => {
  return await clientRequestService({
    method: "post",
    url: POST_ADD_CUSTOM_FEE_API.replaceAll("%token%", token),
    data,
  });
};

export const deleteCustomFeeService = async (data: any, token: any) => {
  return await clientRequestService({
    method: "delete",
    url: POST_ADD_CUSTOM_FEE_API.replaceAll("%token%", token),
    data,
  });
};

export const postPaidInvoiceService = async (token: string, data: object) => {
  return await clientRequestService({
    method: "post",
    url: PROPERTY_INVOICE_POST_PAID_API.replaceAll("%token%", token),
    data,
  });
};

export const getAllPropertyList = async () => {
  return await clientRequestService({
    method: "get",
    url: GET_RETRIEVE_PROPERTY_LIST_API,
  });
};
