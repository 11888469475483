import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import Label from "../../../../../../components/Label";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import {
  CAMPAIGN_RULES_BUSINESS_ONLY,
  CAMPAIGN_RULES_DEFAULT,
  DISTRIBUTION_TYPE_DESKIMO,
  VOUCHER_REDEEM_CREDIT_TYPE,
  VOUCHER_REDEEM_FIXED_TYPE,
  VOUCHER_REDEEM_ON_BOARDING_TYPE,
  VOUCHER_REDEEM_PERCENTAGE_CREDIT_PACKAGE_DISCOUNT_TYPE,
  VOUCHER_REDEEM_PERCENTAGE_PERIOD_DISCOUNT_TYPE,
  VOUCHER_REDEEM_PERCENTAGE_TYPE,
} from "../../../../configs/Constants";
import VoucherFixed from "./VoucherFixed";
import VoucherPercentage from "./VoucherPercentage";
import VoucherCredit from "./VoucherCredit";
import VoucherOnboard from "./VoucherOnboard";
import VoucherPeriod from "./VoucherPeriod";
import { CampaignCreateEditContext } from "../../../../reducers/CampaignCreateEditContext";
import VoucherCreditPackage from "./VoucherCreditPackage";

const Voucher = () => {
  const { translations } = useContext(LocalizationContext);
  const { setFieldValue, values } = useContext(CampaignCreateEditContext);

  const options = [
    {
      key: VOUCHER_REDEEM_FIXED_TYPE,
      value: VOUCHER_REDEEM_FIXED_TYPE,
      text: translations.voucherType[VOUCHER_REDEEM_FIXED_TYPE],
    },
    {
      key: VOUCHER_REDEEM_PERCENTAGE_TYPE,
      value: VOUCHER_REDEEM_PERCENTAGE_TYPE,
      text: translations.voucherType[VOUCHER_REDEEM_PERCENTAGE_TYPE],
    },
    {
      key: VOUCHER_REDEEM_CREDIT_TYPE,
      value: VOUCHER_REDEEM_CREDIT_TYPE,
      text: translations.voucherType[VOUCHER_REDEEM_CREDIT_TYPE],
    },
    {
      key: VOUCHER_REDEEM_ON_BOARDING_TYPE,
      value: VOUCHER_REDEEM_ON_BOARDING_TYPE,
      text: translations.voucherType[VOUCHER_REDEEM_ON_BOARDING_TYPE],
    },
    {
      key: VOUCHER_REDEEM_PERCENTAGE_PERIOD_DISCOUNT_TYPE,
      value: VOUCHER_REDEEM_PERCENTAGE_PERIOD_DISCOUNT_TYPE,
      text: translations.voucherType[
        VOUCHER_REDEEM_PERCENTAGE_PERIOD_DISCOUNT_TYPE
      ],
    },
    {
      key: VOUCHER_REDEEM_PERCENTAGE_CREDIT_PACKAGE_DISCOUNT_TYPE,
      value: VOUCHER_REDEEM_PERCENTAGE_CREDIT_PACKAGE_DISCOUNT_TYPE,
      text: translations.voucherType[
        VOUCHER_REDEEM_PERCENTAGE_CREDIT_PACKAGE_DISCOUNT_TYPE
      ],
    },
  ];

  return (
    <>
      <Label label={"Voucher"} fontSize={"24px"} className={"section-title"} />
      <div className={"detail_divide"}>
        <div className={"left_section"}>
          <Label label={"Voucher type"} fontSize={"12px"} />
          <DropdownCustom
            selection
            selectUI
            options={options}
            value={values.voucher_reward.type}
            onChange={(e, { value }) => {
              setFieldValue("voucher_reward.type", value);
              if (
                value === VOUCHER_REDEEM_FIXED_TYPE ||
                value === VOUCHER_REDEEM_PERCENTAGE_TYPE
              ) {
                setFieldValue("advance_rules", CAMPAIGN_RULES_DEFAULT);
              } else {
                setFieldValue("advance_rules", CAMPAIGN_RULES_BUSINESS_ONLY);
                setFieldValue("distribution_type", DISTRIBUTION_TYPE_DESKIMO);
              }
            }}
          />
        </div>
      </div>
      {values.voucher_reward.type === VOUCHER_REDEEM_FIXED_TYPE ? (
        <VoucherFixed />
      ) : values.voucher_reward.type === VOUCHER_REDEEM_PERCENTAGE_TYPE ? (
        <VoucherPercentage />
      ) : values.voucher_reward.type === VOUCHER_REDEEM_CREDIT_TYPE ? (
        <VoucherCredit />
      ) : values.voucher_reward.type === VOUCHER_REDEEM_ON_BOARDING_TYPE ? (
        <VoucherOnboard />
      ) : values.voucher_reward.type ===
        VOUCHER_REDEEM_PERCENTAGE_CREDIT_PACKAGE_DISCOUNT_TYPE ? (
        <VoucherCreditPackage />
      ) : (
        <VoucherPeriod />
      )}
      <div className={"section-white-box_line"} />
    </>
  );
};

export default Voucher;
