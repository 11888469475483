import React, { useState } from "react";
import ButtonCustom from "../../../../../components/Button";
import { useSelector } from "react-redux";
import { globalSettingSelector } from "../../../../App/selectors/AppSelectors";
import { updateMediaFileService } from "../../../services/MediaFileServices";
import { toastError, toastSuccess } from "../../../../../components/Toast";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import DropdownWithCheckboxMenu from "../../../../../components/DropdownWithCheckboxMenu";

type Props = {
  file: any;
  onRefresh: any;
  isPropertyUser?: boolean;
};

const MediaMarket = ({ file, onRefresh, isPropertyUser }: Props) => {
  const [editMode, setEditMode] = useState(false);
  const globalSetting = useSelector(globalSettingSelector);
  const [tmpMarket, setTmpMarket] = useState<any>(
    file?.markets?.length > 0
      ? file?.markets.map((market: any) => market?.code)
      : []
  );
  const [loading, setLoading] = useState(false);

  const updateMediaFile = async () => {
    setLoading(true);
    try {
      if (file?.reference) {
        const payload = {
          market_ids: tmpMarket,
        };
        await updateMediaFileService(file?.reference, payload);
        await onRefresh();
        toastSuccess("The file has been updated successfully!");
        setEditMode(false);
      }
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const decoratedListMarket = () => {
    return globalSetting.location.map((market: any) => {
      return {
        checked: tmpMarket.includes(market?.city_code),
        id: market?.city_code,
        name: <label>{market?.city_name}</label>,
      };
    });
  };

  const _handleOnChangeMarket = (marketId: string) => {
    let markets = [...tmpMarket];

    if (markets.includes(marketId)) {
      markets = markets.filter((id: string) => id !== marketId);
    } else {
      markets.push(marketId);
    }

    setTmpMarket(markets);
  };

  return (
    <div>
      <div className={"d-flex gap-8"}>
        {editMode ? (
          <DropdownWithCheckboxMenu
            disabled={loading}
            reverseText={false}
            rowClassName={"book-filter-sub__item-row"}
            onChange={(event: any, { value }: { value: string }) => {
              _handleOnChangeMarket(value);
            }}
            listCheckBox={decoratedListMarket()}
            label={<span className={"filter-amenity"}>Select Markets</span>}
            icon={
              <img
                className={"dropdown icon"}
                src={PUBLIC_URL + "/assets/icons/icon-caret-down.svg"}
              />
            }
          />
        ) : (
          <span>
            {file?.markets?.length > 0 ? (
              <ul>
                {file?.markets.map((market: any, index: number) => (
                  <li key={index}>{market?.name}</li>
                ))}
              </ul>
            ) : (
              "All markets"
            )}
          </span>
        )}

        {editMode && !isPropertyUser ? (
          <div
            className={"d-flex gap-8"}
            style={{ marginLeft: 8, position: "relative", zIndex: 1000 }}
          >
            <ButtonCustom
              disabled={loading}
              height={"44px"}
              onClick={() => setEditMode(false)}
              className={"danger-link p-0 mr-0"}
            >
              Cancel
            </ButtonCustom>
            <ButtonCustom
              disabled={loading}
              loading={loading}
              height={"44px"}
              onClick={updateMediaFile}
              className={"link p-0 mr-0"}
            >
              Save
            </ButtonCustom>
          </div>
        ) : (
          <>
            {isPropertyUser ? null : (
              <ButtonCustom
                height={"14px"}
                onClick={() => setEditMode(true)}
                className={"edit-link p-0 mr-0"}
              >
                <i className="uil uil-edit-alt"></i>
              </ButtonCustom>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MediaMarket;
