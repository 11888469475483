import {
  EMPLOYEE_DETAIL_ACTION,
  EMPLOYEE_EDIT_INLINE_ACTION,
  EMPLOYEE_GOOGLE_ACTION,
  EMPLOYEE_GOOGLE_CHOOSE_ACTION,
  EMPLOYEES_ACTION,
  EMPLOYEES_FILTERS_ACTION,
  MODAL_CREATE_DESKIMO_ADMIN_ACTION,
  MODAL_DETAIL_EMPLOYEE_ACTION,
  MODAL_EDIT_EMPLOYEE_ACTION,
  MODAL_IMPORT_EMPLOYEE_GOOGLE_ACTION,
  USERS_ACTION,
  USERS_FILTERS_ACTION,
  USERS_FILTERS_RESET_ACTION,
} from "../configs/Constants";
import { EmployeeFilterType, UsersFilterType } from "../configs/DeclareTypes";

export const employeesAction = (data: object | null) => {
  return {
    type: EMPLOYEES_ACTION,
    payload: data,
  };
};

export const employeesFilterAction = (data: {
  key: EmployeeFilterType;
  value: string | number | number[];
}) => {
  return {
    type: EMPLOYEES_FILTERS_ACTION,
    payload: data,
  };
};

export const usersAction = (data: object | null) => {
  return {
    type: USERS_ACTION,
    payload: data,
  };
};

export const usersFilterAction = (data: object) => {
  return {
    type: USERS_FILTERS_ACTION,
    payload: data,
  };
};

export const modalEditEmployeeAction = (data: boolean) => {
  return {
    type: MODAL_EDIT_EMPLOYEE_ACTION,
    payload: data,
  };
};

export const modalDetailEmployeeAction = (data: boolean) => {
  return {
    type: MODAL_DETAIL_EMPLOYEE_ACTION,
    payload: data,
  };
};

export const employeeDetailAction = (data?: any) => {
  return {
    type: EMPLOYEE_DETAIL_ACTION,
    payload: data,
  };
};

export const employeeEditInlineAction = (data?: any) => {
  return {
    type: EMPLOYEE_EDIT_INLINE_ACTION,
    payload: data,
  };
};

export const employeeGoogleAction = (data: object[]) => {
  return {
    type: EMPLOYEE_GOOGLE_ACTION,
    payload: data,
  };
};

export const employeeGoogleChooseAction = (data: string[]) => {
  return {
    type: EMPLOYEE_GOOGLE_CHOOSE_ACTION,
    payload: data,
  };
};

export const modalImportEmployeeGoogleAction = (data: boolean) => {
  return {
    type: MODAL_IMPORT_EMPLOYEE_GOOGLE_ACTION,
    payload: data,
  };
};

export const usersFilterResetAction = () => {
  return {
    type: USERS_FILTERS_RESET_ACTION,
  };
};

export const modalCreateDeskimoAdminAction = (data: object) => {
  return {
    type: MODAL_CREATE_DESKIMO_ADMIN_ACTION,
    payload: data,
  };
};
