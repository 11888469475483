import React from "react";
import { PropertyRatingType } from "../../../configs/DeclareType";
import { Rating } from "react-simple-star-rating";
import ButtonCustom from "../../../../../components/Button";
import SizeBox from "../../../../../components/SizeBox";

type Props = {
  rating: PropertyRatingType;
  handleReadMore?: any;
  isReadMore?: boolean;
  readMoreLoading?: boolean;
};

const RatingCustom = ({
  rating,
  handleReadMore,
  isReadMore,
  readMoreLoading,
}: Props) => {
  return (
    <div className={"book-detail__section "}>
      <div className={"book-detail__section-title"}>Reviews & Ratings</div>
      <div className={"book-detail__rating"}>
        <div className={"rate"}>
          <Progress star={5} percent={rating.rating_per_star.star_5} />
          <Progress star={4} percent={rating.rating_per_star.star_4} />
          <Progress star={3} percent={rating.rating_per_star.star_3} />
          <Progress star={2} percent={rating.rating_per_star.star_2} />
          <Progress star={1} percent={rating.rating_per_star.star_1} />
        </div>
        <div className={"summary"}>
          <div className={"title"}>{rating.star}</div>
          <Rating
            size={14}
            ratingValue={(parseFloat(String(rating.star)) / 5) * 100}
            readonly
            fillColor={"#FFBE00"}
            style={{ top: "2px" }}
          />
          <div className={"description"}>({rating.total_format})</div>
        </div>
      </div>
      <div className={"book-detail__rating-review-list scroll"}>
        {rating.user_review.map(
          ({ reviewer, comment, rating_count, star, time_ago }, index) => {
            return (
              <Review
                key={index}
                reviewer={reviewer}
                reviewCount={rating_count}
                star={star}
                content={comment}
                time_ago={time_ago}
              />
            );
          }
        )}
      </div>
      {isReadMore ? (
        <div>
          <SizeBox height={"16px"} />
          <ButtonCustom
            width={"112px"}
            height={"42px"}
            className={"outline-primary"}
            loading={readMoreLoading}
            onClick={handleReadMore}
          >
            Read More
          </ButtonCustom>
        </div>
      ) : null}
    </div>
  );
};

const Progress = ({ star, percent }: { star: number; percent: string }) => {
  return (
    <div className={"book-detail__progress"}>
      <div className={"star"}>{star}</div>
      <div className={"progress"}>
        <div
          className={"current-progress"}
          style={{ width: `${percent}%` }}
        ></div>
      </div>
    </div>
  );
};

const Review = ({
  reviewer,
  star,
  reviewCount,
  content,
  time_ago,
}: {
  reviewer: string;
  star: number;
  reviewCount: string;
  content: string;
  time_ago: string;
}) => {
  return (
    <div className={"book-detail__review"}>
      <div className={"reviewer-wrapper"}>
        <div className={"reviewer"}>{reviewer}</div>
        <Rating
          size={17}
          ratingValue={(star / 5) * 100}
          readonly
          fillColor={"#FFBE00"}
          style={{ top: "2px" }}
        />
      </div>
      <div className={"review-count"}>
        (user reviewed {reviewCount}{" "}
        {Number(reviewCount) < 2 ? "space" : "spaces"})
      </div>
      <div className="content">{content}</div>
      <div className={"time-ago"}>{time_ago}</div>
    </div>
  );
};

export default RatingCustom;
