import React, { useContext } from "react";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import SizeBox from "../../../../../components/SizeBox";
import { LocalizationContext } from "../../../../../locales/Translation";
import InputCustom from "../../../../../components/Input";
import TextArea from "../../../../../components/Input/TextArea";
import Label from "../../../../../components/Label";
import { WIFI_ACCESS_TYPE } from "../../../configs/Constants";
import Radio from "../../../../../components/Radio";

type Props = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
  handleChange: any;
  setFieldValue: any;
};
const Wifi = ({
  isSubmitting,
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
}: Props) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <div className={"section"}>
      <SizeBox height={"16px"} />
      <div className={"section-title"}>{translations.wifi}</div>
      <SizeBox height={"16px"} />
      <InputCustom
        label={translations.wifiName}
        value={values.wifi.wifiName}
        onChange={handleChange}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        touched={touched.wifi?.wifiName}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error={errors.wifi?.wifiName}
        name={"wifi.wifiName"}
        labelRequired
        disabled={isSubmitting}
      />
      <Label label={"Wifi access type"} required />
      <SizeBox height={"16px"} />
      <div>
        <Radio
          checked={values.wifi.wifiAccessType === WIFI_ACCESS_TYPE.PASSWORD}
          label={"Access with password"}
          onChange={() =>
            setFieldValue("wifi.wifiAccessType", WIFI_ACCESS_TYPE.PASSWORD)
          }
        />
        <Radio
          checked={
            values.wifi.wifiAccessType === WIFI_ACCESS_TYPE.PASS_INSTRUCTION
          }
          label={"Access with Instructions"}
          onChange={() =>
            setFieldValue(
              "wifi.wifiAccessType",
              WIFI_ACCESS_TYPE.PASS_INSTRUCTION
            )
          }
        />
      </div>
      <SizeBox height={"16px"} />
      {values.wifi.wifiAccessType === WIFI_ACCESS_TYPE.PASSWORD ? (
        <InputCustom
          value={values.wifi.wifiPass}
          onChange={handleChange}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          touched={touched.wifi?.wifiPass}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          error={errors.wifi?.wifiPass}
          name={"wifi.wifiPass"}
          disabled={isSubmitting}
          placeholder={"Please input your Wifi password"}
        />
      ) : (
        <TextArea
          value={values.wifi.wifiInstruction}
          onChange={handleChange}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          touched={touched.wifi?.wifiInstruction}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          error={errors.wifi?.wifiInstruction}
          name={"wifi.wifiInstruction"}
          disabled={isSubmitting}
          placeholder={
            "Please enter your instructions for how to connect to Wifi"
          }
        />
      )}
      <SizeBox height={"16px"} />
    </div>
  );
};

export default Wifi;
