import DashboardRoutes from "../../Dashboard/configs/Routes";
import AuthenticationRoutes from "../../Authentication/configs/Routes";
import SettingRoutes from "../../Setting/configs/Routes";
import BusinessRoutes from "../../Business/configs/Routes";
import UserRoutes from "../../User/configs/Routes";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import {
  ACCESS_TOKEN_CACHE,
  CURRENT_COMPANY_ID,
  PARTNER_MODE_CACHE,
} from "../../../configs/Constants";
import {
  isAuthAction,
  permissionAction,
} from "../../Authentication/actions/AuthenticationActions";
import { Dimmer, Loader } from "semantic-ui-react";
import { LocalizationContext } from "../../../locales/Translation";
import {
  isAuthSelector,
  refreshSessionSelector,
} from "../../Authentication/selectors/AuthenticationSelectors";
import { userCompanyThunk } from "../../User/thunks/UserThunks";
import {
  useCompanyAction,
  useCompanyChooseAction,
} from "../../User/actions/UserActions";
import { modalStartUserGuideAction } from "../actions/AppActions";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import {
  GASendUserID,
  GATrackingEvent,
  GATrackingPageView,
} from "../../../services/GATrackingService";
import {
  BOOK_DESK_DETAIL_LINK,
  BOOK_INQUIRE_OFFICE_DETAIL_LINK,
  BOOK_ROOM_DETAIL_LINK,
  DASHBOARD_PARTNER_LINK,
  EMPLOYEES_BUSINESS_LINK,
  LOGOUT_LINK,
  TITLES,
} from "./ListRoutes";
import { permissionThunk } from "../../Authentication/thunks/AuthenticationThunks";
import { userCompanyChooseSelector } from "../../User/selectors/UserSelectors";
import PropertyRoutes from "../../Property/configs/Routes";
import {
  getCreditRemainingThunk,
  getGlobalSettingThunk,
  getMyProfileThunk,
} from "../thunks/AppThunk";
import {
  MyProfileType,
  UserCompanyType,
} from "../../User/configs/DeclareTypes";
import {
  ROLE_BUSINESS_ADMIN_ID,
  ROLE_BUSINESS_USER_ID,
  ROLE_DESKIMO_ADMIN_ID,
  ROLE_PARTNER_ID,
  ROLE_PROPERTY_ADMIN_ID,
  ROLE_PROPERTY_STAFF_ID,
} from "../../User/configs/Constants";
import QRReader from "../../Dashboard/views/QRReader";
import {
  isGlobalIndicatorSelector,
  myProfileSelector,
} from "../selectors/AppSelectors";
import MarketingRoutes from "../../Marketing/configs/Routes";
import ConfigRoutes from "../../Configuration/configs/Routes";
import BookRoutes from "../../Book/configs/Routes";
import BookingRoutes from "../../Booking/configs/Routes";
import OnboardRoutes from "../../Onboard/configs/Routes";
import { LOGIN_AS_COMPANY_ID } from "./Constants";
import { toastError } from "../../../components/Toast";
import MediaFileRoutes from "../../MediaFile/configs/Routes";

const AppRoutes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { initializeAppLanguage } = useContext(LocalizationContext);
  const isAuth = useSelector(isAuthSelector);
  const userCompanyChoose = useSelector(userCompanyChooseSelector);
  const myProfile = useSelector(myProfileSelector);
  const isGlobalIndicator = useSelector(isGlobalIndicatorSelector);
  const refreshSession = useSelector(refreshSessionSelector);

  const [loading, setLoading] = useState<boolean>(true);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const { pathname } = useLocation();

  useEffect(() => {
    GASendUserID();
  }, [myProfile, isAuth]);

  useEffect(() => {
    // Handle page change
    GATrackingPageView(pathname);
    setPageTitle();
  }, [pathname]);

  const setPageTitle = () => {
    let match;
    for (const pathPattern in TITLES) {
      if (matchPath(pathPattern, pathname)) {
        match = matchPath(pathPattern, pathname);
      }
    }

    if (match) {
      document.title = `${TITLES[match?.pattern?.path]} | Deskimo`;
    } else {
      document.title = "Deskimo";
    }
  };

  useEffect(() => {
    initializeAppLanguage();

    const access_token = localStorage.getItem(ACCESS_TOKEN_CACHE);
    if (access_token) {
      dispatch(isAuthAction(true));
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!isAuth) {
          return;
        }
        setLoading(true);
        const res = await Promise.all([
          dispatch<any>(getMyProfileThunk()),
          dispatch<any>(getGlobalSettingThunk()),
          dispatch<any>(userCompanyThunk()),
        ]);
        const { role_id, is_new_user }: MyProfileType = res[0];

        if (role_id === ROLE_DESKIMO_ADMIN_ID) {
          dispatch(useCompanyAction([]));
          dispatch(useCompanyChooseAction({}));
          dispatch(permissionAction([]));
          localStorage.removeItem(CURRENT_COMPANY_ID);
          return;
        }

        const userCompany = res[2];

        if (!userCompany.length) {
          toastError(
            "You do not have affiliation with any company. Kindly consult your administrator to obtain access to the website."
          );
          setTimeout(() => {
            navigate(LOGOUT_LINK);
          }, 3000);
          return;
        }

        let currentCompanyId = localStorage.getItem(LOGIN_AS_COMPANY_ID);

        if (currentCompanyId) {
          localStorage.removeItem(LOGIN_AS_COMPANY_ID);
          localStorage.setItem(CURRENT_COMPANY_ID, currentCompanyId);
        } else {
          currentCompanyId = localStorage.getItem(CURRENT_COMPANY_ID);
        }

        const partnerMode = Boolean(localStorage.getItem(PARTNER_MODE_CACHE));
        let currentCompany: UserCompanyType | null;

        const findCurrentCompany: UserCompanyType | undefined =
          userCompany.find(
            ({
              company_id,
              is_partner,
            }: {
              company_id: number;
              is_partner: boolean;
            }) =>
              company_id === Number(currentCompanyId) &&
              is_partner === partnerMode
          );

        if (findCurrentCompany) {
          currentCompany = findCurrentCompany;
          dispatch(useCompanyChooseAction(findCurrentCompany));
        } else {
          const firstCompany: UserCompanyType = userCompany[0];
          currentCompany = firstCompany;

          dispatch(useCompanyChooseAction(firstCompany));
          localStorage.setItem(
            CURRENT_COMPANY_ID,
            String(firstCompany.company_id)
          );
        }

        dispatch<any>(permissionThunk()); // Call async
        setFirstLoad(false);

        if (
          currentCompany.role_id !== ROLE_BUSINESS_ADMIN_ID ||
          currentCompany.is_partner
        ) {
          return;
        }

        const isShowUserGuide = _showBusinessUserGuide(is_new_user);
        if (isShowUserGuide) {
          return;
        }

        if (
          pathname.includes(BOOK_DESK_DETAIL_LINK.replaceAll("/:id", "")) ||
          pathname.includes(BOOK_ROOM_DETAIL_LINK.replaceAll("/:id", "")) ||
          pathname.includes(
            BOOK_INQUIRE_OFFICE_DETAIL_LINK.replaceAll("/:slug", "")
          )
        ) {
          return;
        }

        if (!currentCompany?.has_employee) {
          navigate(EMPLOYEES_BUSINESS_LINK, { replace: true });
          return;
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [isAuth, refreshSession]);

  useEffect(() => {
    (async () => {
      try {
        if (!isAuth) {
          return;
        }

        if (!firstLoad) {
          dispatch<any>(permissionThunk());
        }

        if (!Object.entries(userCompanyChoose).length) {
          return;
        }

        if (
          (userCompanyChoose?.is_partner &&
            userCompanyChoose?.role_id === ROLE_BUSINESS_ADMIN_ID) ||
          userCompanyChoose?.role_id === ROLE_PARTNER_ID
        ) {
          navigate(DASHBOARD_PARTNER_LINK);
          return;
        }

        if (
          userCompanyChoose?.role_id === ROLE_BUSINESS_ADMIN_ID &&
          !userCompanyChoose?.is_partner
        ) {
          dispatch<any>(getCreditRemainingThunk());
        }

        if (
          userCompanyChoose?.role_id === ROLE_BUSINESS_ADMIN_ID ||
          userCompanyChoose?.role_id === ROLE_BUSINESS_USER_ID
        ) {
          const data = await dispatch<any>(
            getMyProfileThunk(userCompanyChoose?.company_id)
          );

          GATrackingEvent("generic_tracking", {
            category: "tracking",
            action: "visit",
            Label: `${data?.full_name} - ${userCompanyChoose?.company_name} - ${data?.country_code} ${data?.phone_number} - ${data?.email}>`,
          });
        } else {
          dispatch<any>(getMyProfileThunk());
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [userCompanyChoose]);

  const _showBusinessUserGuide = (isNewUser: number) => {
    const screenWidth = screen.width;

    if (!isNewUser || screenWidth < 992) {
      return false;
    }

    dispatch(modalStartUserGuideAction(true));
    return true;
  };

  if (loading) {
    return (
      <Dimmer active>
        <Loader />
      </Dimmer>
    );
  }

  return (
    <>
      <AuthenticationRoutes />
      <DashboardRoutes />
      <SettingRoutes />
      <UserRoutes />
      <PropertyRoutes />
      <BusinessRoutes />
      <MarketingRoutes />
      <ConfigRoutes />
      <BookRoutes />
      <BookingRoutes />
      <OnboardRoutes />
      <MediaFileRoutes />
      {(userCompanyChoose?.role_id === ROLE_PROPERTY_ADMIN_ID ||
        userCompanyChoose?.role_id === ROLE_PROPERTY_STAFF_ID) && <QRReader />}
      <Dimmer active={isGlobalIndicator} page>
        <Loader />
      </Dimmer>
    </>
  );
};

export default AppRoutes;
