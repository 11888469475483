import React, { useContext } from "react";
import Avatar from "../../../../../components/Avatar";
import { Dropdown } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { userCompanyChooseSelector } from "../../../../User/selectors/UserSelectors";
import { UserCompanyType } from "../../../../User/configs/DeclareTypes";
import DropdownMultiple from "../../../../../components/DropdownMultiple";
import {
  modalAddVoucherAction,
  modalDownloadAppAction,
  modalMyProfileAction,
  modalUpdatePasswordAction,
} from "../../../actions/AppActions";
import { myProfileSelector } from "../../../selectors/AppSelectors";
import { LocalizationContext } from "../../../../../locales/Translation";
import { BUSINESS_COMPANY_VOUCHER_ADD_PER } from "../../../../../utils/PermissionConstant";
import { usePermissionCheck } from "../../../../../utils/custom-hooks";
import ProfileIcon from "./icon/ProfileIcon";
import VoucherIcon from "./icon/VoucherIcon";
import KeyIcon from "./icon/KeyIcon";
import DownloadIcon from "./icon/DownloadIcon";
import SwitchIcon from "./icon/SwitchIcon";
import LogoutIcon from "./icon/LogoutIcon";
import {
  ROLE_BUSINESS_ADMIN_ID,
  ROLE_DESKIMO_ADMIN_ID,
} from "../../../../User/configs/Constants";
import { useLocation, useNavigate } from "react-router-dom";
import SwitchCompany from "./SwitchCompany";
import { PARTNER_PREFIX_URL } from "../../../configs/Constants";
import ModalUserProfile from "../../ModalUserProfile";
import ModalUpdatePassword from "../../ModalUpdatePassword";
import ModalDownloadApp from "../../ModalDownloadApp";
import { LOGOUT_LINK } from "../../../configs/ListRoutes";

const ProfileDropdown = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const userCompanyChoose: UserCompanyType = useSelector(
    userCompanyChooseSelector
  );

  const myProfile = useSelector(myProfileSelector);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const _onClickLogout = () => {
    navigate(LOGOUT_LINK);
  };

  return (
    <>
      <DropdownMultiple
        direction={"left"}
        trigger={<Profile />}
        className={"navbar__profile"}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => dispatch(modalMyProfileAction(true))}
            text={translations.myProfile}
            icon={<ProfileIcon />}
          />
          {usePermissionCheck(BUSINESS_COMPANY_VOUCHER_ADD_PER) &&
            userCompanyChoose?.is_partner !== true && (
              <Dropdown.Item
                onClick={() => dispatch(modalAddVoucherAction(true))}
                text={translations.addVoucher}
                icon={<VoucherIcon />}
              />
            )}
          <Dropdown.Item
            onClick={() => dispatch(modalUpdatePasswordAction(true))}
            text={translations.changePassword}
            icon={<KeyIcon />}
          />
          {userCompanyChoose?.is_partner ? null : (
            <Dropdown.Item
              onClick={() => dispatch(modalDownloadAppAction(true))}
              text={translations.deskimoApp}
              icon={<DownloadIcon />}
            />
          )}

          {(userCompanyChoose?.role_id === ROLE_BUSINESS_ADMIN_ID &&
            !pathname.includes(PARTNER_PREFIX_URL)) ||
          myProfile?.role_id === ROLE_DESKIMO_ADMIN_ID ? null : (
            <SwitchCompany icon={<SwitchIcon />} />
          )}
          <Dropdown.Item
            text={translations.logOut}
            onClick={_onClickLogout}
            icon={<LogoutIcon />}
          />
        </Dropdown.Menu>
      </DropdownMultiple>
      <ModalUserProfile />
      <ModalUpdatePassword />
      <ModalDownloadApp />
    </>
  );
};

const Profile = () => {
  const myProfile = useSelector(myProfileSelector);

  return (
    <div className={"navbar__profile"}>
      <Avatar
        size={"34px"}
        imgUrl={myProfile?.avatar}
        capName={myProfile?.cap_name}
        name={myProfile?.full_name}
        noTooltip
      />
      <div className={"navbar__profile-meta"}>
        <div className={"navbar__profile-name"}>{myProfile?.full_name}</div>
        <div className={"navbar__profile-title"}>{myProfile?.email}</div>
      </div>
    </div>
  );
};

export default ProfileDropdown;
