import clientRequestService from "../../../services/ClientRequestService";
import {
  DELETE_MANAGE_PMS_ROOM_API,
  DELETE_ROOM_API,
  POST_GET_LIST_UNUSED_PMS_ROOM_API,
  POST_MANAGE_PMS_ADD_CALENDAR_API,
  POST_MANAGE_PMS_ASSIGN_CALENDAR_API,
  POST_MANAGE_PMS_ROOM_API,
  PUT_UPDATE_MANAGE_PMS_AUTO_CONFIRM_API,
} from "../configs/Endpoints";

export const managePMSRoomListService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: POST_MANAGE_PMS_ROOM_API,
    isCompany: true,
    data,
  });
};

export const managePMSAddCalendarService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: POST_MANAGE_PMS_ADD_CALENDAR_API,
    data,
  });
};

export const managePMSAssignCalendarService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: POST_MANAGE_PMS_ASSIGN_CALENDAR_API,
    data,
  });
};

export const updateManagePMSAutoConfirmService = async (data: object) => {
  return await clientRequestService({
    method: "put",
    url: PUT_UPDATE_MANAGE_PMS_AUTO_CONFIRM_API,
    isCompany: true,
    data,
  });
};

export const deleteManagePMSRoomService = async (data: object) => {
  return await clientRequestService({
    method: "delete",
    url: DELETE_MANAGE_PMS_ROOM_API,
    isCompany: true,
    data,
  });
};

export const disconnectPMSRoomService = async (data: object) => {
  return await clientRequestService({
    method: "delete",
    url: DELETE_MANAGE_PMS_ROOM_API,
    data,
  });
};

export const getListUnusedPMSRoomService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: POST_GET_LIST_UNUSED_PMS_ROOM_API,
    data,
  });
};
