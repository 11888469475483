import React from "react";
import DropdownCustom from "../../../../../components/DropdownCustom";
import { useDispatch, useSelector } from "react-redux";
import { globalSettingSelector } from "../../../../App/selectors/AppSelectors";
import {
  filterMarketOptionsSelector,
  filterMarketSelector,
} from "../../../selectors/BookSelectors";
import { filterMarketAction } from "../../../actions/BookActions";
import { FILTER_MARKET_SESSION } from "../../../configs/Constants";
import "./styles.scss";

const Market = () => {
  const dispatch = useDispatch();
  const globalSetting = useSelector(globalSettingSelector);
  const filterMarket = useSelector(filterMarketSelector);
  const filterMarketOptions = useSelector(filterMarketOptionsSelector);

  return (
    <DropdownCustom
      className={"dropdown-select-market"}
      options={filterMarketOptions}
      value={filterMarket?.city_code}
      onChange={(event, { value }) => {
        if (typeof value !== "string") {
          return;
        }

        const marketChoose = globalSetting?.location.find(
          ({ city_code }: { city_code: string }) => city_code === value
        );
        dispatch(filterMarketAction(marketChoose));
        sessionStorage.setItem(FILTER_MARKET_SESSION, marketChoose.city_code);
      }}
    />
  );
};

export default Market;
