import React, { useContext } from "react";
import SectionWhiteBoxItem from "../../../../../../components/SectionWhiteBoxItem/SectionWhiteBoxItem";
import { CampaignDetailType } from "../../../../configs/DeclareTypes";
import { useSelector } from "react-redux";
import { campaignDetailSelector } from "../../../../selectors/MarketingSelectors";
import { LocalizationContext } from "../../../../../../locales/Translation";

const VoucherCreditPackagePercentage = () => {
  const { translations } = useContext(LocalizationContext);

  const campaignDetail: CampaignDetailType = useSelector(
    campaignDetailSelector
  );
  return (
    <>
      <div className={"section-white-box-column"}>
        <SectionWhiteBoxItem
          label={"Voucher type"}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={translations.voucherType[campaignDetail.voucher_reward.type]}
        />

        <SectionWhiteBoxItem
          label={"Percentage discount"}
          value={`${campaignDetail.voucher_reward.value}%`}
        />
      </div>

      <div className={"section-white-box-column"}>
        <SectionWhiteBoxItem
          label={"Currency"}
          value={campaignDetail.voucher_reward.currency}
        />

        <SectionWhiteBoxItem
          label={"Credit amount"}
          value={campaignDetail.voucher_reward.credit_amount}
        />
      </div>

      <div className={"section-white-box-column"}>
        <SectionWhiteBoxItem
          label={"Duration"}
          value={campaignDetail.voucher_reward.duration}
        />

        <SectionWhiteBoxItem
          label={"Roll over"}
          value={campaignDetail.voucher_reward.roll_over}
        />
      </div>
    </>
  );
};

export default VoucherCreditPackagePercentage;
