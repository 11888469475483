import React, { useContext, useState } from "react";
import ButtonCustom from "../../../../../components/Button";
import ModalManualCheckIn from "./Modal/ModalManualCheckIn";
import { LocalizationContext } from "../../../../../locales/Translation";

const ManualCheckIn = () => {
  const { translations } = useContext(LocalizationContext);

  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <ButtonCustom
        className={"dashboard_property_manual_btn"}
        onClick={() => {
          setOpen(true);
        }}
      >
        {translations.manualCheckIn}
      </ButtonCustom>
      <ModalManualCheckIn open={open} setOpen={setOpen} />
    </>
  );
};

export default ManualCheckIn;
