import { Dropdown, DropdownItem, DropdownMenu, Label } from "semantic-ui-react";
import { PUBLIC_URL } from "../../modules/App/configs/Constants";
import React, { useEffect, useState } from "react";
import "./styles.scss";
import classNames from "classnames";
import { useDetectClickOutside } from "react-detect-click-outside";

type ValueItemProps = { text: string; id: string | number };

type Props = {
  value: ValueItemProps[];
  options: ValueItemProps[];
  onChange: (item: ValueItemProps[]) => void;
  onSearch: (keyword: string) => void;
  disabled?: boolean;
  placeholder?: string;
};

const DropdownSearchServer = ({
  value,
  options,
  onChange,
  onSearch,
  disabled,
  placeholder,
}: Props) => {
  const [focus, setFocus] = useState<boolean>(false);
  const [optionsInit, setOptionsInit] = useState(options);

  const ref = useDetectClickOutside({ onTriggered: () => setFocus(false) });

  useEffect(() => {
    const temp = options.filter(({ id }) => {
      return !value.map(({ id }) => id).includes(id);
    });
    setOptionsInit(temp);
  }, [value, options]);

  const _onSelect = (item: ValueItemProps) => {
    const res = [...value, item];
    onChange(res);
  };

  const _onRemove = (itemId: string | number) => {
    const res = value.filter(({ id }) => id !== itemId);
    onChange(res);
  };

  return (
    <div
      ref={ref}
      className={classNames(
        "ui multiple dropdown dropdown-custom select-ui selection dropdown-search-server",
        {
          visible: focus,
          active: focus,
          disabled: disabled,
        }
      )}
      onClick={() => setFocus(true)}
    >
      {value.map(({ text, id }, index, array) => {
        return (
          <Label
            key={id}
            onClick={(e) => {
              e.preventDefault();
              _onRemove(id);
            }}
          >
            {text}
            <i className={"delete icon"} />
          </Label>
        );
      })}
      <img
        className="dropdown icon"
        src={PUBLIC_URL + "/assets/icons/icon-caret-down.svg"}
        alt={"Deksimo"}
      />
      <Dropdown
        className={"dropdown-search-server"}
        placeholder={placeholder}
        multiple
        search
        onSearchChange={(event, { searchQuery }) => {
          onSearch(searchQuery);
        }}
        icon={null}
      >
        <DropdownMenu>
          {optionsInit.map((item, index) => {
            return (
              <DropdownItem key={index} onClick={() => _onSelect(item)}>
                {item.text}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default DropdownSearchServer;
