import LoginTemplate from "../../../App/views/LoginTemplate";
import { useNavigate, Navigate } from "react-router-dom";
import {
  loginGeneralService,
  resetPasswordService,
} from "../../services/AuthenticationServices";
import { useSelector } from "react-redux";
import { isAuthSelector } from "../../selectors/AuthenticationSelectors";
import { toastError, toastSuccess } from "../../../../components/Toast";
import { useContext } from "react";
import { LocalizationContext } from "../../../../locales/Translation";
import React from "react";
import ResetPasswordForm from "./ResetPasswordForm";
import { useQuery } from "../../../../utils/custom-hooks";

const ResetPassword = () => {
  const { translations } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const isAuth = useSelector(isAuthSelector);
  const query = useQuery();
  const token = query.get("token");

  const _onClick = async (data: { plain_password: string }) => {
    try {
      if (!token) {
        return;
      }
      const { data: dataRes } = await resetPasswordService(data, token);
      toastSuccess("Password changed successfully");
      await loginGeneralService(
        { email: dataRes?.email, password: data.plain_password },
        translations
      );
      navigate("/", { replace: true });
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      toastError(translations.errorMessage.contactAdmin);
    }
  };

  if (isAuth || !token) {
    return <Navigate to={"/"} />;
  }

  return (
    <LoginTemplate>
      <div className={"login__title"}>{translations.updatePassword}</div>
      <ResetPasswordForm onClick={_onClick} />
    </LoginTemplate>
  );
};

export default ResetPassword;
