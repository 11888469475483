import React, { useContext, useEffect, useState } from "react";
import "../../../Dashboard/styles/notification.scss";
import { modalAddEmployeeAction } from "../../actions/AppActions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { LocalizationContext } from "../../../../locales/Translation";
import { onboardPromotionInfoSelector } from "../../selectors/AppSelectors";

const BusinessAdminFreeCredit = () => {
  const { translations } = useContext(LocalizationContext);
  const onboardPromotionInfo = useSelector(onboardPromotionInfoSelector);

  const dispatch = useDispatch();
  const [info, setInfo] = useState<any>(null);

  useEffect(() => {
    (async () => {
      try {
        if (!onboardPromotionInfo) {
          setInfo(null);
          return;
        }

        const currentTime = moment();
        const startTime = moment.unix(onboardPromotionInfo.start_time);
        const endTime = moment.unix(onboardPromotionInfo.end_time);
        if (currentTime.isBetween(startTime, endTime)) {
          setInfo(onboardPromotionInfo);
        } else {
          setInfo(null);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [onboardPromotionInfo]);

  if (!info) {
    return null;
  }

  return (
    <div className={"dashboard_notification"}>
      <span className={"dashboard_notification_text"}>
        {translations.employeesAddedBetween}{" "}
        {moment.unix(info.start_time).format("MMM DD")} -{" "}
        {moment.unix(info.end_time).format("MMM DD")} {translations.willReceive}{" "}
        {info.credit_amount} {translations.inFreeDeskimoCredits}! -{" "}
        <a
          href={"#"}
          onClick={(e) => {
            e.preventDefault();
            dispatch(modalAddEmployeeAction(true));
          }}
        >
          {translations.addEmployeesNow}
        </a>
      </span>
    </div>
  );
};

export default BusinessAdminFreeCredit;
