import React, { useContext, useEffect, useState } from "react";
import MasterTemplate from "../../../App/views/MasterTemplate";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, FormikValues } from "formik";
import * as yup from "yup";
import Information from "./Components/Information";
import ButtonCustom from "../../../../components/Button";
import { LocalizationContext } from "../../../../locales/Translation";
import { campaignDetailThunk } from "../../thunks/MarketingThunks";
import { useDispatch, useSelector } from "react-redux";
import { campaignDetailAction } from "../../actions/MarketingActions";
import { campaignDetailSelector } from "../../selectors/MarketingSelectors";
import { CampaignDetailType } from "../../configs/DeclareTypes";
import {
  ALLOW_TO_FIRST_VISIT_ONLY_PROMOTION_RULE,
  ALLOW_TO_NEW_COMPANY_ONLY_RULE,
  CAMPAIGN_RULES_DEFAULT,
  DISTRIBUTION_TYPE_DESKIMO,
  VOUCHER_REDEEM_CREDIT_TYPE,
  VOUCHER_REDEEM_FIXED_TYPE,
  VOUCHER_REDEEM_ON_BOARDING_TYPE,
  VOUCHER_REDEEM_PERCENTAGE_CREDIT_PACKAGE_DISCOUNT_TYPE,
  VOUCHER_REDEEM_PERCENTAGE_TYPE,
} from "../../configs/Constants";
import {
  creteCampaignService,
  editCampaignService,
} from "../../services/MarketingServices";
import { toastError, toastSuccess } from "../../../../components/Toast";
import { ROLE_DESKIMO_ADMIN_ID } from "../../../User/configs/Constants";
import Voucher from "./Components/Voucher";
import "./styles.scss";
import Rules from "./Components/Rules";
import moment from "moment-timezone";
import { getSettingService } from "../../../App/services/AppServices";
import { CampaignCreateEditContext } from "../../reducers/CampaignCreateEditContext";
import { Dimmer, DropdownItemProps, Loader } from "semantic-ui-react";
import { TIMEZONE_SINGAPORE } from "../../../App/configs/Constants";
import { MARKETING_CAMPAIGNS_LINK } from "../../../App/configs/ListRoutes";
import RulesForBusinessOnly from "./Components/Rules/RulesForBusinessOnly";

const CampaignCreateEdit = () => {
  const { translations } = useContext(LocalizationContext);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const campaignDetail: CampaignDetailType = useSelector(
    campaignDetailSelector
  );
  const [timezoneOptions, setTimezoneOptions] = useState<DropdownItemProps[]>(
    []
  );

  const [currencyOptions, setCurrencyOptions] = useState<DropdownItemProps[]>(
    []
  );

  const startTimeDefault = moment()
    .tz(TIMEZONE_SINGAPORE, true)
    .startOf("day")
    .unix();

  const endTimeDefault = moment()
    .tz(TIMEZONE_SINGAPORE, true)
    .add(1, "month")
    .endOf("day")
    .unix();

  const _getSetting = async () => {
    const { data } = await getSettingService();
    const currencyTemp: DropdownItemProps[] = [];
    const timezoneTemp: DropdownItemProps[] = [];
    data?.location.forEach(
      ({ currency, timezone }: { currency: string; timezone: string }) => {
        currencyTemp.push({ id: currency, text: currency, value: currency });
        timezoneTemp.push({ id: timezone, text: timezone, value: timezone });
      }
    );
    setCurrencyOptions(currencyTemp);
    setTimezoneOptions(timezoneTemp);
  };

  useEffect(() => {
    (async () => {
      try {
        if (!id) {
          dispatch(campaignDetailAction({}));
        } else {
          await dispatch<any>(campaignDetailThunk(id));
        }
        await _getSetting();
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const _onSubmit = async (values: FormikValues) => {
    try {
      const payload = { ...values };

      const formatAdvanceRule = values.advance_rules.map((item: any) => {
        if (
          item.type === ALLOW_TO_FIRST_VISIT_ONLY_PROMOTION_RULE ||
          item.type === ALLOW_TO_NEW_COMPANY_ONLY_RULE
        ) {
          return item;
        }
        const newValue = Array.isArray(item.value)
          ? item.value.map(({ id }: { id: string }) => id)
          : null;
        return { ...item, value: newValue };
      });
      payload.advance_rules = formatAdvanceRule;
      if (id) {
        await editCampaignService(payload, id);
        toastSuccess("Updated successfully");
      } else {
        await creteCampaignService(payload);
        toastSuccess("Created successfully");
      }
      navigate(MARKETING_CAMPAIGNS_LINK);
    } catch (e: any) {
      toastError(e?.status?.message);
    }
  };

  return (
    <MasterTemplate roleAccept={[ROLE_DESKIMO_ADMIN_ID]}>
      {loading ? (
        <Dimmer active>
          <Loader />
        </Dimmer>
      ) : (
        <div className={"section-white-box property_company_detail"}>
          <Formik
            enableReinitialize
            initialValues={{
              id: id || null,
              name: campaignDetail?.name || "",
              display_name: campaignDetail?.display_name || "",
              description: campaignDetail?.description || "",
              start_time: campaignDetail?.start_time || startTimeDefault,
              end_time: campaignDetail?.end_time || endTimeDefault,
              timezone: campaignDetail?.timezone || TIMEZONE_SINGAPORE,
              voucher_code: campaignDetail?.voucher_code || "",
              vouchers_number_available:
                campaignDetail?.vouchers_number_available || "",
              voucher_reward: {
                type:
                  campaignDetail?.voucher_reward?.type ||
                  VOUCHER_REDEEM_FIXED_TYPE,
                currency: campaignDetail?.voucher_reward?.currency || "SGD",
                value:
                  campaignDetail?.voucher_reward?.value === undefined
                    ? ""
                    : campaignDetail?.voucher_reward?.value,
                percentage_max_redeem_value:
                  campaignDetail?.voucher_reward
                    ?.percentage_max_redeem_value === undefined
                    ? ""
                    : campaignDetail?.voucher_reward
                        ?.percentage_max_redeem_value,
                redeem_duration:
                  campaignDetail?.voucher_reward?.redeem_duration || "",
                free_credit_duration:
                  campaignDetail?.voucher_reward?.free_credit_duration || "",
                free_credit_onboard_start_time:
                  campaignDetail?.voucher_reward
                    ?.free_credit_onboard_start_time || "",
                free_credit_onboard_end_time:
                  campaignDetail?.voucher_reward
                    ?.free_credit_onboard_end_time || "",
                credit_amount:
                  campaignDetail?.voucher_reward?.credit_amount === undefined
                    ? null
                    : campaignDetail?.voucher_reward?.credit_amount,
                duration:
                  campaignDetail?.voucher_reward?.duration === undefined
                    ? null
                    : campaignDetail?.voucher_reward?.duration,
                roll_over:
                  campaignDetail?.voucher_reward?.roll_over === undefined
                    ? null
                    : campaignDetail?.voucher_reward?.roll_over,
              },
              advance_rules: campaignDetail?.advance_rules
                ? campaignDetail?.advance_rules
                : CAMPAIGN_RULES_DEFAULT,
              distribution_type: campaignDetail?.distribution_type
                ? campaignDetail?.distribution_type
                : DISTRIBUTION_TYPE_DESKIMO,
            }}
            validationSchema={yup.object().shape({
              name: yup.string().required().label("Campaign name"),
              description: yup.string().required().label("Description"),
              voucher_code: yup.string().required().label("Campaign name"),
              display_name: yup
                .string()
                .required()
                .label("In-app display name"),
              vouchers_number_available: yup
                .number()
                .required()
                .label("Voucher code"),
              voucher_reward: yup.object().shape({
                value: yup.number().required().label("Voucher value"),
                redeem_duration: yup
                  .number()
                  .typeError("Must be a number")
                  .when(["type"], {
                    is: (value: string) =>
                      value !== VOUCHER_REDEEM_CREDIT_TYPE &&
                      value !==
                        VOUCHER_REDEEM_PERCENTAGE_CREDIT_PACKAGE_DISCOUNT_TYPE,
                    then: yup.number().required().label("Valid day"),
                  }),
                free_credit_onboard_start_time: yup.string().when(["type"], {
                  is: (value: string) =>
                    value === VOUCHER_REDEEM_ON_BOARDING_TYPE,
                  then: yup.string().required().label("Onboard campaign time"),
                }),
              }),
            })}
            onSubmit={_onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              resetForm,
            }) => (
              <CampaignCreateEditContext.Provider
                value={{
                  values,
                  errors,
                  touched,
                  handleChange,
                  isSubmitting,
                  setFieldValue,
                  currencyOptions,
                  timezoneOptions,
                }}
              >
                <Information />
                <Voucher />
                {values.voucher_reward.type === VOUCHER_REDEEM_FIXED_TYPE ||
                values.voucher_reward.type ===
                  VOUCHER_REDEEM_PERCENTAGE_TYPE ? (
                  <Rules />
                ) : (
                  <RulesForBusinessOnly />
                )}
                <div className={"section-white-box-actions"}>
                  <ButtonCustom
                    className={"cancel mr-10px"}
                    onClick={resetForm}
                    disabled={isSubmitting}
                  >
                    {translations.cancel}
                  </ButtonCustom>
                  <ButtonCustom
                    disabled={isSubmitting}
                    className={"primary mr-0"}
                    onClick={handleSubmit}
                    loading={isSubmitting}
                  >
                    {translations.save}
                  </ButtonCustom>
                </div>
              </CampaignCreateEditContext.Provider>
            )}
          </Formik>
        </div>
      )}
    </MasterTemplate>
  );
};

export default CampaignCreateEdit;
