import Loader from "../../../../components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { reminderCallbackService } from "../../services/AuthenticationServices";
import { toastError, toastSuccess } from "../../../../components/Toast";

const ReminderCallback = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        if (!token) {
          navigate("/");
          return;
        }
        const res = await reminderCallbackService(token);
        toastSuccess(res?.status?.message);

        const nextUrl = res?.data?.next_url;
        if (res?.data?.next_url) {
          navigate(nextUrl);
        } else {
          navigate("/");
        }
      } catch (e: any) {
        toastError(e?.status?.message);
        navigate("/");
      }
    })();
  }, []);

  return <Loader fullScreen />;
};

export default ReminderCallback;
