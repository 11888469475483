import { useDispatch } from "react-redux";
import React, { useContext } from "react";
import { LocalizationContext } from "../../../../locales/Translation";
import { SWAL_DELETE_OPTION } from "../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../components/SweetAlert";
import { toastError, toastSuccess } from "../../../../components/Toast";
import { PUBLIC_URL } from "../../../App/configs/Constants";
import DropdownCustom from "../../../../components/DropdownCustom";
import ButtonCustom from "../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import {
  employeeDeActiveService,
  employeeDeleteService,
} from "../../services/EmployeeServices";
import { teamMemberListThunk } from "../../thunks/PartnerTeamThunks";

type Props = {
  userId: number;
  statusId: number;
};

const Action = ({ userId, statusId }: Props) => {
  const dispatch: any = useDispatch();
  const { translations } = useContext(LocalizationContext);

  const _reload = async () => {
    await dispatch(teamMemberListThunk());
  };

  const _onDeactive = async () => {
    const icon = null;
    const title = isDeactiveUser()
      ? translations.areYouSureYouWantToActivateThisUser
      : translations.areYouSureYouWantToDeactivateThisUser;
    const confirmText = isDeactiveUser()
      ? translations.activate
      : translations.deactivate;
    const cancelText = translations.cancel;
    const confirmClass = isDeactiveUser() ? "primary" : "danger";
    const options = SWAL_DELETE_OPTION({
      title,
      icon,
      confirmText,
      confirmClass,
      cancelText,
    });
    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          await employeeDeActiveService(String(userId));
          await _reload();
          toastSuccess(
            isDeactiveUser()
              ? "Activated Successfully!"
              : "Deactivated Successfully!"
          );
        } catch (response: any) {
          toastError(response?.status?.message);
        }
      },
      cancelCallback: () => null,
    });
  };

  const _onDelete = async () => {
    const iconHtml = (
      <img
        src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
        className={"swal2-icon__error"}
      />
    );
    const title = translations.areYouSureWantToDeleteThisAccount;
    const confirmText = translations.delete;
    const cancelText = translations.cancel;
    const options = SWAL_DELETE_OPTION({
      title,
      iconHtml,
      confirmText,
      cancelText,
    });
    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          await employeeDeleteService(String(userId));
          toastSuccess("Deleted Successfully!");
          await _reload();
        } catch (response: any) {
          toastError(response?.status?.message);
        }
      },
      cancelCallback: () => null,
    });
  };

  const isDeactiveUser = () => {
    return statusId === 2;
  };

  return (
    <DropdownCustom
      className={"table-action-dropdown"}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={_onDeactive}
          text={
            isDeactiveUser()
              ? translations.activateUser
              : translations.deactivateUser
          }
          icon={
            isDeactiveUser() ? (
              <i
                className="uil uil-toggle-on icon"
                style={{ fontSize: "16px" }}
              />
            ) : (
              <i
                className="uil uil-toggle-off icon"
                style={{ fontSize: "16px" }}
              />
            )
          }
        />
        <Dropdown.Item
          onClick={_onDelete}
          text={translations.deleteUser}
          icon={
            <i
              className="uil uil-trash-alt icon"
              style={{ fontSize: "16px" }}
            />
          }
        />
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default Action;
