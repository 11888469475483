import { getCompanyByUserService } from "../services/UserServices";
import { useCompanyAction } from "../actions/UserActions";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";

export const userCompanyThunk = () => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await getCompanyByUserService();
    dispatch<any>(useCompanyAction(data));
    return data;
  };
};
