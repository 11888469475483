import React, { useContext } from "react";
import SearchBar4Table from "../../../../../components/SearchBar4Table";
import { LocalizationContext } from "../../../../../locales/Translation";
import { debounce } from "lodash";

const SearchDeskimoPropertiesAmenity = ({ onSearch }: any) => {
  const { translations } = useContext(LocalizationContext);

  const debounce_onChange = debounce(function (data: any, { value }: any) {
    onSearch(value);
  }, 500);

  return (
    <SearchBar4Table
      className={"search-bar-4-table"}
      placeholder={translations.propertyAmenities.searchAmenityPlaceholder}
      onChange={debounce_onChange}
    />
  );
};

export default SearchDeskimoPropertiesAmenity;
