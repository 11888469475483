import {
  LIST_BOOKING_COMPLETED_ACTION,
  LIST_BOOKING_COMPLETED_PAYLOAD_ACTION,
  LIST_DESK_BOOKING_ACTION,
  LIST_DESK_BOOKING_PAYLOAD_ACTION,
  LIST_ROOM_BOOKING_ACTION,
  LIST_ROOM_BOOKING_PAYLOAD_ACTION,
  MODAL_ROOM_BOOKING_DETAIL_ACTION,
  ONGOING_DATA_ACTION,
  REFRESH_LIST_DESK_BOOKING_ACTION,
  REFRESH_LIST_ROOM_BOOKING_ACTION,
} from "../configs/Constants";

export const listRoomBookingAction = (data: any) => {
  return {
    type: LIST_ROOM_BOOKING_ACTION,
    payload: data,
  };
};

export const listRoomBookingPayloadAction = (data: any) => {
  return {
    type: LIST_ROOM_BOOKING_PAYLOAD_ACTION,
    payload: data,
  };
};

export const refreshListRoomBookingAction = () => {
  return {
    type: REFRESH_LIST_ROOM_BOOKING_ACTION,
  };
};

export const modalRoomBookingDetailAction = (data: any) => {
  return {
    type: MODAL_ROOM_BOOKING_DETAIL_ACTION,
    payload: data,
  };
};

export const listDeskBookingAction = (data: any) => {
  return {
    type: LIST_DESK_BOOKING_ACTION,
    payload: data,
  };
};

export const listDeskBookingPayloadAction = (data: any) => {
  return {
    type: LIST_DESK_BOOKING_PAYLOAD_ACTION,
    payload: data,
  };
};

export const refreshListDeskBookingAction = () => {
  return {
    type: REFRESH_LIST_DESK_BOOKING_ACTION,
  };
};

export const onGoingDataAction = (data: any) => {
  return {
    type: ONGOING_DATA_ACTION,
    payload: data,
  };
};

export const listBookingCompletedAction = (data: any) => {
  return {
    type: LIST_BOOKING_COMPLETED_ACTION,
    payload: data,
  };
};

export const listBookingCompletedPayloadAction = (data: any) => {
  return {
    type: LIST_BOOKING_COMPLETED_PAYLOAD_ACTION,
    payload: data,
  };
};
