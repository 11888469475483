import moment from "moment/moment";

export const customerIOIdentifyUser = (email: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!window?._cio) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window?._cio.identify({
    // Required attributes — you must include your customer's identifier (this can be either an email address or a unique ID value) in a property named "id". See below for more details.
    id: email,

    // Strongly recommended when you first identify someone
    created_at: moment().unix(), // Timestamp in representing when the user
    // first signed up in Unix format.

    // Other attributes (properties you'll reference with liquid)
    // email: "person@example.com",
    // first_name: "Cool",
    // last_name: "Person",
    // plan_name: "premium",
  });
};

export const customerIOClearIdentifyUser = () => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!window?._cio) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window?._cio?.reset();
  } catch (e) {
    console.log(e);
  }
};
