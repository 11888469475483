import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardFilterDateRangeSelector,
  dashboardFilterDateTypeSelector,
  dashboardDeskimoRoomBookingFilterSelector,
  dashboardRoomBookingSelector,
  dashboardStatisticFilterSelector,
  transactionInfoSelector,
  filterListPropertySelectedSelector,
} from "../../../../selectors/DashboardSelectors";
import {
  dashboardRoomBookingPropertyThunk,
  transactionInfoThunk,
} from "../../../../thunks/DashboardThunks";
import SizeBox from "../../../../../../components/SizeBox";
import { RoomBookingDeskimoType } from "../../../../configs/DeclareTypes";
import { dashboardDeskimoRoomBookingFilterAction } from "../../../../actions/DashboardActions";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../../../App/configs/Constants";
import TableCustom from "../../../../../../components/Table";
import { Link } from "react-router-dom";
import {
  DESKIMO_PROPERTY_DETAIL_LINK,
  DESKIMO_PROPERTY_DETAIL_MEETING_ROOM_LINK,
} from "../../../../../App/configs/ListRoutes";
import { Popup } from "semantic-ui-react";
import {
  AUTO_REFRESH_INTERVAL_TIME,
  FILTER_DATE_TODAY,
  ROOM_BOOKING,
} from "../../../../configs/Constants";
import BookingActions from "./components/BookingActions";
import UserNameAndIndicator from "./components/UserNameAndIndicator";
import ReadMore from "../../../../../../components/ReadMore";

const TableRoomBookings = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);

  const dashboardRoomBooking = useSelector(dashboardRoomBookingSelector);
  const dashboardRoomBookingFilter = useSelector(
    dashboardDeskimoRoomBookingFilterSelector
  );
  const dashboardFilterDateRange = useSelector(
    dashboardFilterDateRangeSelector
  );
  const filterListPropertySelected = useSelector(
    filterListPropertySelectedSelector
  );
  const dashboardFilterMarket = useSelector(dashboardStatisticFilterSelector);
  const dashboardFilterDateType = useSelector(dashboardFilterDateTypeSelector);

  useEffect(() => {
    if (dashboardFilterDateRange === null) {
      return;
    }

    _init();
    const interval = setInterval(_autoRefresh, AUTO_REFRESH_INTERVAL_TIME);
    return () => {
      clearInterval(interval);
    };
  }, [
    dashboardRoomBookingFilter,
    dashboardFilterDateRange,
    dashboardFilterMarket,
    filterListPropertySelected,
  ]);

  const _init = async () => {
    try {
      setLoading(true);
      const data = await dispatch<any>(
        dashboardRoomBookingPropertyThunk(false, true)
      );

      _getTransactionInfo(data?.items);
    } catch (e) {
      console.log("e", e);
    } finally {
      setLoading(false);
    }
  };

  const _autoRefresh = async () => {
    if (dashboardFilterDateType !== FILTER_DATE_TODAY) {
      return;
    }
    const data = await dispatch<any>(
      dashboardRoomBookingPropertyThunk(false, true)
    );
    _getTransactionInfo(data?.items);
  };

  const _getTransactionInfo = (data: []) => {
    if (!data?.length) {
      return;
    }

    const payload = {
      transaction_ids: data.map(({ id }: any) => id),
      type: ROOM_BOOKING,
    };

    dispatch<any>(transactionInfoThunk(payload));
  };

  const meetingRoomField = ({
    meeting_room_name,
    meeting_room_id,
    property_name,
    meeting_room_image,
    property_id,
    property_company_name,
    property_company_id,
    booking_note,
  }: {
    meeting_room_name: string;
    meeting_room_id: number;
    property_name: string;
    meeting_room_image: string;
    property_id: number;
    property_company_name: string;
    property_company_id: number;
    booking_note?: string;
  }) => (
    <div className={"room-table_meeting-wrapper"}>
      <img src={meeting_room_image} alt={meeting_room_name} />
      <div>
        <div className={"table_title"}>{translations.meetingRoom}:</div>
        <Link
          to={
            DESKIMO_PROPERTY_DETAIL_MEETING_ROOM_LINK.replaceAll(
              ":id",
              String(property_id)
            ).replaceAll(":companyId", String(property_company_id)) +
            `?meeting_id=${meeting_room_id}`
          }
        >
          {meeting_room_name}
        </Link>
        <SizeBox height={"10px"} />
        <div className={"table_title"}>{translations.property}:</div>
        <Popup
          content={property_company_name}
          trigger={
            <Link
              to={DESKIMO_PROPERTY_DETAIL_LINK.replaceAll(
                ":id",
                String(property_id)
              ).replaceAll(":companyId", String(property_company_id))}
            >
              <Popup
                content={property_company_name}
                trigger={<span>{property_name}</span>}
              />
            </Link>
          }
        />

        {booking_note ? (
          <>
            <SizeBox height={"10px"} />
            <div className={"table_title"}>{translations.note}:</div>
            <ReadMore>{booking_note}</ReadMore>
          </>
        ) : null}
      </div>
    </div>
  );

  const dateTimeField = ({
    created_time,
    start_time,
    duration,
  }: {
    created_time: string;
    start_time: string;
    duration: string;
  }) => (
    <>
      <div className={"table_title"}>Created time:</div>
      <div>{created_time}</div>
      <SizeBox height={"10px"} />
      <div className={"table_title"}>{translations.startTime}:</div>
      <div>{start_time}</div>
      <SizeBox height={"10px"} />
      <div className={"table_title"}>{translations.duration}:</div>
      <div>{duration}</div>
    </>
  );

  const costField = ({
    hourly_rate,
    total_price,
    discount,
    total_final_price,
    stripe_link,
  }: {
    hourly_rate: string;
    total_price: string;
    discount?: string;
    total_final_price?: string;
    stripe_link?: string;
  }) => (
    <>
      <div className={"table_title"}>Price:</div>
      <div>
        {stripe_link ? (
          <a
            href={stripe_link}
            target={"_blank"}
            className={"text-nowrap"}
            rel="noreferrer"
          >
            {total_price} <i className="uil uil-link-alt"></i>
          </a>
        ) : (
          total_price
        )}
      </div>
      {discount ? (
        <>
          <SizeBox height={"10px"} />
          <div className={"table_title"}>Discount:</div>
          <div>{discount}</div>
        </>
      ) : null}

      {total_final_price ? (
        <>
          <SizeBox height={"10px"} />
          <div className={"table_title"}>Final Price:</div>
          <div>
            {stripe_link ? (
              <a
                href={stripe_link}
                target={"_blank"}
                className={"text-nowrap"}
                rel="noreferrer"
              >
                {total_final_price} <i className="uil uil-link-alt"></i>
              </a>
            ) : (
              total_final_price
            )}
          </div>
        </>
      ) : null}
    </>
  );

  const statusField = ({
    status_id,
    refund_amount,
    refund_status_id,
    status_name,
    is_request_cancellation,
    cancellation_request,
  }: {
    status_id: number;
    refund_amount: string | null;
    refund_status_id: number | null;
    status_name: string;
    is_request_cancellation: number;
    cancellation_request?: string;
  }) => (
    <>
      <div>
        <b>Status: </b>
        {status_name}
      </div>
      <SizeBox height={"10px"} />
      <div>
        <b>Cancellation request: </b>
        {cancellation_request}
      </div>
    </>
  );

  const _formatInputData = () => {
    return dashboardRoomBooking.items.map(
      ({
        transaction_id,
        employee_name,
        meeting_room_name,
        property_name,
        start_time,
        duration,
        hourly_rate,
        total_price,
        refund_amount,
        refund_status_id,
        status_id,
        actions,
        id,
        employee_id,
        from_source,
        company,
        is_company_payment_method,
        meeting_room_image,
        employee_avatar,
        cap_name,
        property_id,
        created_time,
        status_name,
        is_request_cancellation,
        refund_type,
        price_after_discount,
        price_after_discount_amount,
        free_credit_amount,
        currency,
        discount,
        total_final_price,
        property_company_name,
        meeting_room_id,
        property_company_id,
        can_pay_with_credit,
        cancellation_request,
        stripe_link,
        booking_note,
      }: RoomBookingDeskimoType) => {
        return {
          transaction_id,
          userName: (
            <UserNameAndIndicator
              transactionId={id}
              userName={employee_name}
              userId={employee_id}
              showAvatar
              avatarUrl={employee_avatar}
              capName={cap_name}
              isCompanyPaymentMethod={is_company_payment_method}
              companyName={company}
            />
          ),
          from_source,
          meetingRoomField: meetingRoomField({
            property_name,
            meeting_room_name,
            meeting_room_image,
            property_id,
            property_company_name,
            meeting_room_id,
            property_company_id,
            booking_note,
          }),
          dateTimeField: dateTimeField({ created_time, start_time, duration }),
          // company,
          // paymentType: is_company_payment_method ? "Business" : "Personal",
          costField: costField({
            hourly_rate,
            total_price,
            discount,
            total_final_price,
            stripe_link,
          }),
          // note: note || "-",
          statusField: statusField({
            status_id,
            refund_amount,
            refund_status_id,
            status_name,
            is_request_cancellation,
            cancellation_request,
          }),
          action: (
            <BookingActions
              statusId={status_id}
              bookingId={id}
              actions={actions}
              onCallback={_init}
              setRefresh={_init}
              refundType={refund_type}
              priceAfterDiscount={price_after_discount}
              priceAfterDiscountAmount={price_after_discount_amount}
              freeCreditAmount={free_credit_amount}
              currency={currency}
              type={ROOM_BOOKING}
              canPayWithCredit={can_pay_with_credit}
            />
          ),
        };
      }
    );
  };

  const columns = [
    { key: "meeting_room_by_transaction_id", col: translations.transactionID },
    { key: "meeting_room_by_employee_name", col: translations.userName },
    { key: "meeting_room_by_employee_source", col: "Source" },
    { key: "meeting_room_by_meeting_room", col: translations.meetingRoom },
    // { key: "meeting_room_by_start_time", col: translations.dateTime },
    { key: "meeting_room_by_created_time", col: "Date" },
    // { key: "meeting_room_by_company", col: translations.company },
    // { key: "meeting_room_by_payment_type", col: "Payment type" },
    { key: "meeting_room_by_cost", col: translations.revenue },
    { key: "meeting_room_by_status", col: translations.status },
    { key: "meeting_room_by_action", col: translations.action },
  ];

  const _onChangeSortCol = (col: any) => {
    if (dashboardRoomBookingFilter.sort_by !== col.key) {
      dispatch(
        dashboardDeskimoRoomBookingFilterAction({
          key: "sort_by",
          value: col.key,
        })
      );
      dispatch(
        dashboardDeskimoRoomBookingFilterAction({
          key: "order_by",
          value: ORDER_BY_ASC,
        })
      );
    } else {
      dispatch(
        dashboardDeskimoRoomBookingFilterAction({
          key: "sort_by",
          value: col.key,
        })
      );
      if (dashboardRoomBookingFilter.order_by === ORDER_BY_NONE) {
        dispatch(
          dashboardDeskimoRoomBookingFilterAction({
            key: "order_by",
            value: ORDER_BY_ASC,
          })
        );
      }
      if (dashboardRoomBookingFilter.order_by === ORDER_BY_ASC) {
        dispatch(
          dashboardDeskimoRoomBookingFilterAction({
            key: "order_by",
            value: ORDER_BY_DESC,
          })
        );
      }
      if (dashboardRoomBookingFilter.order_by === ORDER_BY_DESC) {
        dispatch(
          dashboardDeskimoRoomBookingFilterAction({
            key: "order_by",
            value: ORDER_BY_NONE,
          })
        );
      }
    }
  };

  return (
    <TableCustom
      orderBy={dashboardRoomBookingFilter.order_by}
      sortBy={dashboardRoomBookingFilter.sort_by}
      sortTable={true}
      onChangeSortCol={_onChangeSortCol}
      tableData={dashboardRoomBooking ? _formatInputData() : []}
      columns={columns}
      loading={loading}
      totalItems={dashboardRoomBooking?.pagination?.total}
      currentPage={dashboardRoomBooking?.pagination?.page}
      showTotal
      unit={translations.transactions}
      itemPerPage={dashboardRoomBooking?.pagination?.limit}
      onChangePagination={(e: any, { activePage }: any) => {
        dispatch(
          dashboardDeskimoRoomBookingFilterAction({
            key: "page",
            value: activePage,
          })
        );
      }}
    />
  );
};

export default TableRoomBookings;
