import React from "react";
import MarkerCustom from "./MarkerCustom";
import { Clusterer } from "@react-google-maps/marker-clusterer";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { BOOK_DESK_TAB } from "../../configs/Constants";
import { useSelector } from "react-redux";
import {
  pinHighlightIdSelector,
  meetingRoomListSelector,
  circleInfoSelector,
  propertyListSelector,
} from "../../selectors/BookSelectors";
import {
  BookMeetingRoomType,
  BookPropertyType,
} from "../../configs/DeclareType";
import {
  checkPositionInsideCircle,
  onClickMarkerMeetingRoom,
  onClickMarkerProperty,
} from "../../services/MapServices";

type Props = {
  tabActive: number;
  clusterer: Clusterer | MarkerClusterer | undefined;
};

const MarkerList = ({ clusterer, tabActive }: Props) => {
  const propertyMap: BookPropertyType[] =
    useSelector(propertyListSelector)?.results;
  const meetingRoomList: BookMeetingRoomType[] = useSelector(
    meetingRoomListSelector
  );

  const pinHighlightId = useSelector(pinHighlightIdSelector);
  const circleInfo = useSelector(circleInfoSelector);

  if (tabActive === BOOK_DESK_TAB) {
    return (
      <>
        {propertyMap.map((item) => {
          const {
            id,
            is_open,
            lat,
            lng,
            discount_percentage,
            is_favorite,
            display_new_label,
          } = item;

          if (
            circleInfo &&
            checkPositionInsideCircle(
              { lat: circleInfo.lat, lng: circleInfo.lng },
              { lat, lng },
              circleInfo.radius
            )
          ) {
            return null;
          }

          return (
            <MarkerCustom
              key={id}
              position={{ lat, lng }}
              clusterer={clusterer}
              isClose={!is_open}
              isDiscount={!!discount_percentage}
              isFavorite={is_favorite}
              onClick={() => onClickMarkerProperty(item)}
              highlight={id === pinHighlightId}
              title={String(id)}
              newBadge={display_new_label}
            />
          );
        })}
      </>
    );
  }

  return (
    <>
      {meetingRoomList.map((item) => {
        const { meeting_room_id, lat, lng, is_favorite, display_new_label } =
          item;

        if (
          circleInfo &&
          checkPositionInsideCircle(
            { lat: circleInfo.lat, lng: circleInfo.lng },
            { lat, lng },
            circleInfo.radius
          )
        ) {
          return null;
        }

        return (
          <MarkerCustom
            key={meeting_room_id}
            position={{ lat, lng }}
            clusterer={clusterer}
            isFavorite={is_favorite}
            onClick={() => onClickMarkerMeetingRoom(item)}
            highlight={meeting_room_id === pinHighlightId}
            title={String(meeting_room_id)}
            newBadge={display_new_label}
          />
        );
      })}
    </>
  );
};

export default MarkerList;
