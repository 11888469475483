import React, { useContext } from "react";
import { LocalizationContext } from "../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import { myProfileSelector } from "../../selectors/AppSelectors";
import { MyProfileType } from "../../../User/configs/DeclareTypes";
import { handleLogInOnBehalf } from "../../../User/services/UserServices";
import { isGlobalIndicatorAction } from "../../actions/AppActions";
import LoginBackAsAdminIcon from "./LoginBackAsAdminIcon";
import { useNavigate } from "react-router-dom";

const LoginOnBehalf = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const myProfile: MyProfileType = useSelector(myProfileSelector);

  const _comeBack = async () => {
    try {
      dispatch(isGlobalIndicatorAction(true));
      await handleLogInOnBehalf(null, navigate);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(isGlobalIndicatorAction(false));
    }
  };

  if (!myProfile?.login_on_behalf) {
    return null;
  }

  return (
    <div className={"notification-global"}>
      <div className={"container"}>
        <span>
          {translations.youAreLoginOn} &quot;{myProfile.email}&quot;
        </span>
        <button onClick={_comeBack} className={"add-card-btn"}>
          <LoginBackAsAdminIcon
            className={"back-to-admin-icon"}
            strokeColor={"#FFFFFF"}
          />
          {translations.loginAdDeskimoAccount}
        </button>
      </div>
    </div>
  );
};

export default LoginOnBehalf;
