import ButtonCustom from "../../../../../../../../components/Button";
import React, { useContext, useState } from "react";
import {
  toastError,
  toastSuccess,
} from "../../../../../../../../components/Toast";
import { useDropzone } from "react-dropzone";
import "./style.scss";
import { Modal } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationContext } from "../../../../../../../../locales/Translation";
import InputCustom from "../../../../../../../../components/Input";
import { uploadMediaFileService } from "../../../../../../services/MediaFileServices";
import {
  uploadFileService,
  uploadImageService,
} from "../../../../../../../App/services/AppServices";

const UploadFileSection = ({ onRefresh, setOpen }: any) => {
  const dispatch = useDispatch();
  const [isDragOver, setIsDragOver] = useState(false);
  const { translations } = useContext(LocalizationContext);
  const [fileName, setFileName] = useState("");
  const [fileUpload, setFileUpload] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    accept: [
      ".jpg",
      ".jpeg",
      ".png",
      ".webp",
      ".pdf",
      ".doc",
      ".docx",
      ".xlsx",
      ".xls",
      ".flv",
      ".mp4",
      ".m3u8",
      ".ts",
      ".3pg",
      ".mov",
      ".avi",
      ".wmv",
    ],
    maxSize: 3 * 1024 * 1024,
    useFsAccessApi: false,
    onDrop: async (acceptedFiles: any) => {
      setFileUpload(acceptedFiles[0]);
      setIsDragOver(false);
    },

    onDropRejected: (fileRejections: any) => {
      let isFileTooLarge;
      fileRejections.forEach((file: any) => {
        isFileTooLarge =
          file.errors.filter((error: any) => error.code === "file-too-large")
            .length > 0;
        if (isFileTooLarge) {
          toastError(`File ${file.file.name} is larger than 3MB`);
        }
      });
    },

    onDragOver: () => {
      setIsDragOver(true);
    },

    onDragLeave: () => {
      setIsDragOver(false);
    },
  });

  const _handleUploadFile = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", fileUpload);
      const response = await uploadFileService(formData);

      const payload = {
        name: fileName,
        ...response?.data,
      };

      await uploadMediaFileService(payload);
      toastSuccess("The file has been uploaded successfully!");
      setOpen(false);
      onRefresh();
    } catch (e: any) {
      toastError(e?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal.Content>
        <div>
          <InputCustom
            value={fileName}
            onChange={(e: any, data: any) => setFileName(data.value)}
            label={"File Name"}
          />

          <div
            {...getRootProps({
              className: "dropzone-box" + (isDragOver ? " drag-over" : ""),
            })}
          >
            <input {...getInputProps()} />
            <i className="uil uil-cloud-upload" />
            <div className={"title"}>{translations.dragAndDropFilesHereOr}</div>
            <div className={"cta"}>{translations.browseFiles}</div>
          </div>
          {fileUpload && (
            <div className={"file-uploaded"}>
              <div className={"right"}>
                <div className={"file-name"}>
                  <span>{fileUpload?.name}</span>
                  <ButtonCustom
                    height={"16px"}
                    onClick={() => setFileUpload(null)}
                    className={"close"}
                  >
                    <i className="uil uil-times" />
                  </ButtonCustom>
                </div>
                <div className={"process-bar"}>
                  <div className={"process"} style={{ width: "100%" }} />
                </div>
                <div className={"percent"}>100%</div>
              </div>
            </div>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <ButtonCustom
          height={"47px"}
          onClick={() => {
            setOpen(false);
          }}
        >
          {translations.cancel}
        </ButtonCustom>
        <ButtonCustom
          loading={loading}
          disabled={!fileUpload || loading}
          className={"primary"}
          height={"47px"}
          onClick={_handleUploadFile}
        >
          Upload
        </ButtonCustom>
      </Modal.Actions>
    </>
  );
};

export default UploadFileSection;
