import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useContext } from "react";
import { LocalizationContext } from "../../locales/Translation";
import "./style.scss";
import classNames from "classnames";

interface Props {
  route: string;
  goBack?: boolean;
  className?: string;
  label?: string;
}

const ButtonGoBack = ({ route, goBack, className, label }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const location = useLocation();

  if (goBack && location.key !== "default") {
    return (
      <a
        onClick={() => navigate(-1)}
        className={classNames("section-go-back-arrow ", className)}
      >
        <i className="uil uil-arrow-left" /> {label || translations.back}
      </a>
    );
  }

  return (
    <Link
      to={route}
      className={classNames("section-go-back-arrow ", className)}
    >
      <i className="uil uil-arrow-left" /> {translations.back}
    </Link>
  );
};

export default ButtonGoBack;
