import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../../App/configs/Constants";
import moment from "moment-timezone";
import { FORMAT_DATE_TIME_VARIANT_3 } from "../../../../App/configs/DateTimeConfigs";
import TableCustom from "../../../../../components/Table";
import MarketFilterMultiple from "../../../../../components/FilterMarket/MarketFilterMultiple";
import { Link } from "react-router-dom";
import { BUSINESS_COMPANY_DETAIL_LINK } from "../../../../App/configs/ListRoutes";
import { creditHistoryService } from "../../../services/BusinessServices";
import { useDispatch, useSelector } from "react-redux";
import { globalSettingSelector } from "../../../../App/selectors/AppSelectors";
import CompanyFilter from "../../../../../components/CompanyFilter";
import Action from "./Action";
import StatusFilter from "./StatusFilter";
import {
  CREDIT_CANCELLED_STATUS,
  CREDIT_EXPIRED_STATUS,
  CREDIT_PAID_STATUS,
  CREDIT_PENDING_STATUS,
} from "../constants";
import Label from "../../../../../components/Label";
import { purchaseHistoryFiltersSelector } from "../../../selectors/BusinessSelectors";
import { purchaseHistoryFiltersAction } from "../../../actions/BusinessActions";
import { useGetAllQuery, useSetQuery } from "../../../../../utils/custom-hooks";
import SearchWithDebounce from "../../../../../components/SearchBar4Table/SearchWithDebounce";
import Tabs from "../Tabs";
import ButtonCustom from "../../../../../components/Button";
import ModalAddCredit from "../ModalAddCredit";

const PurchaseHistoryTable = () => {
  const { translations } = useContext(LocalizationContext);
  const globalSetting = useSelector(globalSettingSelector);

  const [loading, setLoading] = useState(false);

  const purchaseHistoryFilters = useSelector(purchaseHistoryFiltersSelector);

  const [tableData, setTableData] = useState<any>(null);

  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [onRefreshing, setOnSetRefreshing] = useState(false);

  const _onRefreshing = () => {
    setOnSetRefreshing((prevState) => !prevState);
  };

  const [addParamsAlready, setAddParamsAlready] = useState<boolean>(false);
  const getAllQuery = useGetAllQuery();
  const setQuery = useSetQuery();

  useEffect(() => {
    try {
      const marketCodeList = globalSetting?.location.map(
        ({ city_code }: { city_code: string }) => city_code
      );
      dispatch(
        purchaseHistoryFiltersAction({
          market_codes: marketCodeList,
        })
      );

      if (!Object.keys(getAllQuery).length) {
        return;
      }

      dispatch(purchaseHistoryFiltersAction(getAllQuery));
    } finally {
      setAddParamsAlready(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!addParamsAlready) {
          return;
        }

        const payload = { ...purchaseHistoryFilters };
        if (!payload.market_codes.length) {
          return;
        }

        if (payload.statuses === "all") {
          payload.statuses = [
            CREDIT_PENDING_STATUS,
            CREDIT_PAID_STATUS,
            CREDIT_EXPIRED_STATUS,
            CREDIT_CANCELLED_STATUS,
          ];
        } else {
          payload.statuses = [payload.statuses];
        }
        setLoading(true);
        setQuery(payload);
        const { data } = await creditHistoryService(payload);
        setTableData(data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [addParamsAlready, purchaseHistoryFilters, onRefreshing]);

  const columns = [
    { key: "purchase_history_by_company", col: "Company" },
    // {
    //   key: "purchase_history_by_credit_status",
    //   col: (
    //     <div style={{ minWidth: 100 }}>
    //       Company in <br /> auto top-up
    //     </div>
    //   ),
    // },
    {
      key: "purchase_history_by_credit_package",
      col: "Prepaid Credits Package",
    },
    { key: "purchase_history_by_credit_amount", col: "Credit Amount" },
    { key: "purchase_history_by_discount", col: "% Discount" },
    { key: "purchase_history_by_price", col: "Price" },
    { key: "purchase_history_by_remaining_credit", col: "Remaining Credit" },
    { key: "purchase_history_by_purchase_date", col: "Purchase Date" },
    { key: "purchase_history_by_expiry_date", col: "Expiry Date" },
    { key: "purchase_history_by_status", col: translations.status },
    { key: null, col: translations.action },
  ];

  const _formatInputData = () => {
    return tableData?.items?.map(
      ({
        id,
        discount_percent,
        transaction_date,
        status,
        company_id,
        expiry_date,
        company_name,
        package_name,
        status_name,
        total_price,
        credit_amount,
        remaining_credit,
        actions,
        timezone,
        auto_topup,
        trigger_amount,
        recharge_amount,
        roll_over_left,
      }: any) => {
        return {
          col1: (
            <Link to={BUSINESS_COMPANY_DETAIL_LINK.replace(":id", company_id)}>
              {company_name}
            </Link>
          ),
          // col1a:
          //   auto_topup && trigger_amount && recharge_amount ? (
          //     <div className={"text-center"}>
          //       Yes{" "}
          //       <Popup
          //         className={"input-label-text-guide"}
          //         content={
          //           <>
          //             When your balance falls below <b>{trigger_amount}</b>,
          //             your credits will be topped up by <b>{recharge_amount}</b>
          //             .
          //           </>
          //         }
          //         trigger={
          //           <i className="uil uil-info-circle input-label-icon" />
          //         }
          //         position="top center"
          //       />
          //     </div>
          //   ) : (
          //     <div className={"text-center"}>No</div>
          //   ),
          col2: package_name,
          col3: credit_amount,
          col4: discount_percent + "%",
          col5: total_price,
          col6: remaining_credit,
          col7: transaction_date
            ? moment
                .unix(transaction_date)
                .tz(timezone)
                .format(FORMAT_DATE_TIME_VARIANT_3)
            : null,
          col8: expiry_date ? (
            <div>
              {moment
                .unix(expiry_date)
                .tz(timezone)
                .format(FORMAT_DATE_TIME_VARIANT_3)}
              <br />
              <Label
                label={roll_over_left}
                labelGuide={
                  !auto_topup && roll_over_left
                    ? "Auto top-up is disabled, rollover will not happen"
                    : null
                }
                className={"font-weight-thin"}
              />
            </div>
          ) : (
            ""
          ),
          col9: status_name,
          col10: (
            <Action
              actions={actions}
              id={id}
              expiryDate={expiry_date}
              onSetRefreshing={_onRefreshing}
              timezone={timezone}
              rollOverLeft={roll_over_left}
            />
          ),
        };
      }
    );
  };

  const _onChangeSortCol = (col: any) => {
    if (purchaseHistoryFilters.sort_by !== col.key) {
      dispatch(
        purchaseHistoryFiltersAction({
          sort_by: col.key,
          sort_type: ORDER_BY_ASC,
        })
      );
    } else {
      if (purchaseHistoryFilters.sort_type === ORDER_BY_NONE) {
        dispatch(
          purchaseHistoryFiltersAction({
            sort_by: col.key,
            sort_type: ORDER_BY_ASC,
          })
        );
      }
      if (purchaseHistoryFilters.sort_type === ORDER_BY_DESC) {
        dispatch(
          purchaseHistoryFiltersAction({
            sort_by: "",
            sort_type: ORDER_BY_NONE,
          })
        );
      }
      if (purchaseHistoryFilters.sort_type === ORDER_BY_ASC) {
        dispatch(
          purchaseHistoryFiltersAction({
            sort_by: col.key,
            sort_type: ORDER_BY_DESC,
          })
        );
      }
    }
  };

  const _onChangeMarketFilter = (values: string[]) => {
    dispatch(
      purchaseHistoryFiltersAction({
        market_codes: values,
        page: 1,
      })
    );
  };

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    dispatch(
      purchaseHistoryFiltersAction({
        page: activePage,
      })
    );
  };

  if (!addParamsAlready) {
    return null;
  }

  return (
    <>
      <div className={"package-function-section"}>
        <SearchWithDebounce
          initialValue={purchaseHistoryFilters?.keyword}
          placeholder={"Search by Transaction ID, Company Name"}
          onChange={(value) => {
            dispatch(
              purchaseHistoryFiltersAction({
                page: 1,
                keyword: value,
              })
            );
          }}
        />
        <Tabs />
        <ButtonCustom
          height={"36px"}
          className={"primary"}
          onClick={() => setIsOpenModal(true)}
        >
          Buy Prepaid Credit Package
        </ButtonCustom>
      </div>
      <div style={tableData?.items?.length < 3 ? { paddingBottom: 100 } : {}}>
        <TableCustom
          onChangeSortCol={_onChangeSortCol}
          sortTable
          loading={loading}
          showTotal
          tableData={tableData?.items ? _formatInputData() : []}
          columns={columns}
          orderBy={purchaseHistoryFilters.sort_type}
          sortBy={purchaseHistoryFilters.sort_by}
          itemPerPage={tableData?.pagination?.limit}
          currentPage={purchaseHistoryFilters.page}
          totalItems={tableData?.pagination?.total}
          unit={"transactions"}
          onChangePagination={_onChangePagination}
          topRightComponent={
            <>
              <StatusFilter
                status={purchaseHistoryFilters.statuses}
                onChange={(value) =>
                  dispatch(
                    purchaseHistoryFiltersAction({
                      statuses: value,
                      page: 1,
                    })
                  )
                }
              />
              <CompanyFilter
                companyId={purchaseHistoryFilters.company_id}
                onChange={(value) =>
                  dispatch(
                    purchaseHistoryFiltersAction({
                      company_id: value,
                      page: 1,
                    })
                  )
                }
                marketCodes={purchaseHistoryFilters.market_codes}
                type={2}
                showDeletedCompany
              />
              <MarketFilterMultiple
                onChange={_onChangeMarketFilter}
                values={purchaseHistoryFilters.market_codes}
                isSubmitting={loading}
              />
            </>
          }
        />
      </div>
      <ModalAddCredit
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        onSetRefreshing={_onRefreshing}
      />
    </>
  );
};

export default PurchaseHistoryTable;
