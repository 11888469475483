import React from "react";
import Label from "../../../../../components/Label";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import SizeBox from "../../../../../components/SizeBox";
import ToggleSlider from "../../../../../components/ToggleSlider";
import Radio from "../../../../../components/Radio";
import { Link } from "react-router-dom";
import { BUSINESS_COMPANY_LIST_LINK } from "../../../../App/configs/ListRoutes";

type Props = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
  handleChange: any;
  setFieldValue: any;
};

const BusinessPaymentMethodConfiguration = ({
  values,
  setFieldValue,
}: Props) => {
  return (
    <>
      <Label
        label={"Business Payment Method Configuration"}
        fontSize={"24px"}
        className={"section-title"}
      />
      <div className={"detail_divide"}>
        <div className={"left_section d-flex flex-column"}>
          <Label
            label={"Currency to be used for transactions"}
            className={"mb-8px"}
          />
          <div className={"d-flex"}>
            {values?.payment_method?.company_methods &&
            values?.payment_method?.company_methods?.length > 0
              ? values?.payment_method?.company_methods?.map(
                  (method: any, index: number) => (
                    <div className="flex-1" key={index}>
                      <Radio
                        value={method?.id}
                        onChange={(e, { value }) => {
                          const selectedOption =
                            values?.payment_method?.company_methods?.find(
                              (method: any) => method?.id === value
                            );
                          setFieldValue(
                            "payment_method.current_company_method",
                            selectedOption
                          );
                        }}
                        checked={
                          values?.payment_method?.current_company_method?.id ===
                          method?.id
                        }
                        className={"pl-0"}
                        label={
                          <Label
                            fontSize={"14px"}
                            label={method?.name}
                            labelGuide={method?.description}
                          />
                        }
                      />
                    </div>
                  )
                )
              : null}
          </div>
          <SizeBox height={"16px"} />
          <Label label={"Payment Method"} className={"mb-8px"} />
          <div className={"d-flex"}>
            {values?.payment_method?.business_methods &&
            values?.payment_method?.business_methods?.length > 0
              ? values?.payment_method?.business_methods?.map(
                  (method: any, index: number) => (
                    <div className="flex-1" key={index}>
                      <Radio
                        disabled={!method?.can_modified}
                        value={method?.id}
                        onChange={(e, { value }) => {
                          const selectedOption =
                            values?.payment_method?.business_methods?.find(
                              (method: any) => method?.id === value
                            );
                          setFieldValue(
                            "payment_method.current_business_method",
                            selectedOption
                          );
                        }}
                        checked={
                          values?.payment_method?.current_business_method
                            ?.id === method?.id
                        }
                        className={"pl-0"}
                        label={
                          <Label
                            fontSize={"14px"}
                            label={method?.name}
                            labelGuide={
                              method?.can_modified ? (
                                ""
                              ) : !method?.is_verified ? (
                                <>
                                  <span style={{ whiteSpace: "nowrap" }}>
                                    {method?.description}
                                  </span>
                                  <br />
                                  <Link to={BUSINESS_COMPANY_LIST_LINK}>
                                    Verify now
                                  </Link>
                                </>
                              ) : (
                                method?.description
                              )
                            }
                          />
                        }
                      />
                    </div>
                  )
                )
              : null}
          </div>

          <SizeBox height={"32px"} />
          <ToggleSlider
            value={!values.can_buy_credit}
            label={"Disable purchase credit package"}
            onChange={() =>
              setFieldValue("can_buy_credit", !values.can_buy_credit)
            }
          />
        </div>
      </div>

      <SizeBox height={"24px"} />
      <div className={"property_company_detail_line"} />
    </>
  );
};

export default BusinessPaymentMethodConfiguration;
