import React, { useContext, useEffect, useState } from "react";
import ButtonCustom from "../../../../../components/Button";
import classNames from "classnames";
import {
  TAB_DESK_USAGE_INDEX,
  TAB_PRIVATE_OFFICES_INDEX,
  TAB_ROOM_BOOKINGS_INDEX,
} from "../../../configs/Constants";
import TabDesks from "./TabDesks";
import TabMeetingRooms from "./TabMeetingRooms";
import SizeBox from "../../../../../components/SizeBox";
import Filter from "../../Dashboard/Filter";
import {
  dashboardActivityLogThunk,
  dashboardStatisticPropertyThunk,
} from "../../../thunks/DashboardThunks";
import { useDispatch, useSelector } from "react-redux";
import { userCompanyChooseSelector } from "../../../../User/selectors/UserSelectors";
import {
  dashboardFilterDateRangeSelector,
  filterListPropertySelectedSelector,
} from "../../../selectors/DashboardSelectors";
import { LocalizationContext } from "../../../../../locales/Translation";
import TabPrivateOffices from "./TabPrivateOffices";
import { Dimmer, Loader } from "semantic-ui-react";
import FilterListProperty from "../../Components/FilterListProperty";

const Summary = () => {
  const { translations } = useContext(LocalizationContext);

  const dispatch = useDispatch();
  const dashboardFilterDateRange = useSelector(
    dashboardFilterDateRangeSelector
  );
  const userCompanyChoose = useSelector(userCompanyChooseSelector);
  const filterListPropertySelected = useSelector(
    filterListPropertySelectedSelector
  );
  const [tabIndex, setTabIndex] = useState(TAB_DESK_USAGE_INDEX);
  const _onChangeTab = (value: number) => {
    setTabIndex(value);
  };
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (dashboardFilterDateRange === null) {
          return;
        }
        await Promise.all([
          dispatch<any>(dashboardStatisticPropertyThunk("desk")),
          dispatch<any>(dashboardStatisticPropertyThunk("meeting-room")),
          dispatch<any>(dashboardActivityLogThunk()),
        ]);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [dashboardFilterDateRange, userCompanyChoose, filterListPropertySelected]);

  return (
    <>
      <div className={"filter-section"}>
        <div className={"d-flex gap-12"}>
          <Filter className={"pb-0"} />
          <FilterListProperty />
        </div>
        <div className={"button-group yellow"}>
          <ButtonCustom
            height={"36px"}
            className={classNames({
              active: tabIndex === TAB_DESK_USAGE_INDEX,
            })}
            onClick={() => _onChangeTab(TAB_DESK_USAGE_INDEX)}
          >
            {translations.desks}
          </ButtonCustom>
          <ButtonCustom
            height={"36px"}
            className={classNames({
              active: tabIndex === TAB_ROOM_BOOKINGS_INDEX,
            })}
            onClick={() => _onChangeTab(TAB_ROOM_BOOKINGS_INDEX)}
          >
            {translations.meetingRooms}
          </ButtonCustom>
          <ButtonCustom
            height={"36px"}
            className={classNames({
              active: tabIndex === TAB_PRIVATE_OFFICES_INDEX,
            })}
            onClick={() => _onChangeTab(TAB_PRIVATE_OFFICES_INDEX)}
          >
            Private Offices
          </ButtonCustom>
        </div>
        <div />
      </div>

      <SizeBox height={"32px"} />
      {loading ? (
        <Dimmer page active>
          <Loader />
        </Dimmer>
      ) : null}
      <div
        className={classNames({
          "d-none": tabIndex !== TAB_DESK_USAGE_INDEX,
        })}
      >
        <TabDesks />
      </div>

      <div
        className={classNames({
          "d-none": tabIndex !== TAB_ROOM_BOOKINGS_INDEX,
        })}
      >
        <TabMeetingRooms />
      </div>

      <div
        className={classNames({
          "d-none": tabIndex !== TAB_PRIVATE_OFFICES_INDEX,
        })}
      >
        <TabPrivateOffices />
      </div>
    </>
  );
};

export default Summary;
