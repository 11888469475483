import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import UtilDoughnutChart from "./UtilDoughnutChart";
import { DashboardStatisticType } from "../../../configs/DeclareTypes";
import { useSelector } from "react-redux";
import { dashboardStatisticSelector } from "../../../selectors/DashboardSelectors";

const CostBreakDown = () => {
  const { translations } = useContext(LocalizationContext);

  const dashboardStatistic: DashboardStatisticType = useSelector(
    dashboardStatisticSelector
  );

  return (
    <div className={"dashboard_chart_box_wrapper"}>
      <div className={"dashboard_chart_box"}>
        <h2 className={"dashboard_chart_title"}>
          {translations.costBreakdown}
        </h2>
        <div className={"dashboard_chart_inner"}>
          <UtilDoughnutChart
            color={["#16C79A", "#EE261A", "#FFBE00"]}
            labels={[
              translations.meetingRooms,
              translations.dayPasses,
              translations.hotDesks,
            ]}
            data={[
              dashboardStatistic.cost_statistic.booking_cost,
              dashboardStatistic.cost_statistic.day_pass_cost,
              dashboardStatistic.cost_statistic.visit_cost,
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default CostBreakDown;
