import React, { useContext } from "react";
import { LocalizationContext } from "../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import { getPackageTrialInfoThunk } from "../../../Onboard/thunks/OnboardThunks";
import { toastError } from "../../../../components/Toast";
import { isGlobalIndicatorAction } from "../../actions/AppActions";
import { modalPaidTrialPackageAction } from "../../../Onboard/actions/OnboardActions";
import { onboardPromotionInfoSelector } from "../../selectors/AppSelectors";
import { userCompanyChooseSelector } from "../../../User/selectors/UserSelectors";

const BusinessAdminPaidTrial = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch<any>();
  const onboardPromotionInfo = useSelector(onboardPromotionInfoSelector);

  const userCompanyChoose = useSelector(userCompanyChooseSelector);

  const _onClick = async () => {
    try {
      dispatch(isGlobalIndicatorAction(true));
      await dispatch(getPackageTrialInfoThunk());
      dispatch(modalPaidTrialPackageAction(true));
    } catch (e: any) {
      toastError(e?.status?.message);
    } finally {
      dispatch(isGlobalIndicatorAction(false));
    }
  };

  if (userCompanyChoose?.is_partner || !userCompanyChoose?.can_buy_credit) {
    return null;
  }

  return (
    <div className={"dashboard_notification"}>
      <span className={"dashboard_notification_text"}>
        {translations.TryAt} {onboardPromotionInfo?.discount}{" "}
        {translations.offAndAccessOver} {onboardPromotionInfo?.total_workspace}{" "}
        {translations.workspacesWorldwide}{" "}
        <a href={"#"} onClick={_onClick}>
          - {translations.StartYourTrialNow}
        </a>
      </span>
    </div>
  );
};

export default BusinessAdminPaidTrial;
