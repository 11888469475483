import React from "react";

const LocationCompanyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#21252A"
        d="M12 22.5l-6.327-7.462a25.635 25.635 0 01-.261-.338A8.167 8.167 0 013.75 9.75a8.25 8.25 0 1116.5 0 8.164 8.164 0 01-1.661 4.948l-.001.002s-.225.296-.259.335L12 22.5zm-5.391-8.704c.002 0 .176.231.215.28L12 20.182l5.183-6.113c.032-.04.208-.273.209-.274A6.676 6.676 0 0018.75 9.75a6.75 6.75 0 00-13.5 0 6.679 6.679 0 001.36 4.046h-.001z"
      ></path>
      <path
        fill="#21252A"
        d="M15.75 13.5h-1.5v-6h-4.5v6h-1.5v-6A1.502 1.502 0 019.75 6h4.5a1.501 1.501 0 011.5 1.5v6z"
      ></path>
      <path
        fill="#21252A"
        d="M11.25 12h1.5v1.5h-1.5V12zm0-3h1.5v1.5h-1.5V9z"
      ></path>
    </svg>
  );
};

export default LocationCompanyIcon;
