import React from "react";
import { useNavigate } from "react-router-dom";
import { BILLING_LINK } from "../../configs/ListRoutes";
import { useSelector } from "react-redux";
import { ROLE_BUSINESS_ADMIN_ID } from "../../../User/configs/Constants";
import { UserCompanyType } from "../../../User/configs/DeclareTypes";
import { userCompanyChooseSelector } from "../../../User/selectors/UserSelectors";

const InvoiceUnpaid = () => {
  const navigate = useNavigate();
  const userCompanyChoose: UserCompanyType = useSelector(
    userCompanyChooseSelector
  );

  if (
    userCompanyChoose?.role_id !== ROLE_BUSINESS_ADMIN_ID ||
    !userCompanyChoose?.unpaid_invoice
  ) {
    return null;
  }

  return (
    <div className={"notification-global danger"}>
      <div className={"container"}>
        <span>
          🔔 You have an unpaid account statement. Please settle it at your
          earliest convenience to avoid any disruptions.
        </span>
        <button
          onClick={() => {
            navigate(BILLING_LINK);
          }}
          className={"add-card-btn"}
        >
          <i className="uil uil-receipt-alt" style={{ marginRight: 8 }}></i>
          Go to Account Statement
        </button>
      </div>
    </div>
  );
};

export default InvoiceUnpaid;
