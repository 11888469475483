import React from "react";
import "./style.scss";
import { Popup } from "semantic-ui-react";
import classNames from "classnames";
import { InputMask } from "@react-input/mask";

type Props = {
  label?: string;
  labelGuide?: string;
  id?: string;
  inputRightIcon?: string | JSX.Element;
  inputLeftIcon?: string | JSX.Element;
  inputLeftIconBorder?: boolean;
  touched?: string | boolean;
  error?: string;
  labelRequired?: boolean;
  containerClass?: string;
  height?: string;
  disabled?: boolean;
  labelClassName?: string;
  [x: string]: any;
};

// eslint-disable-next-line react/display-name
const InputCardNumber = ({
  label,
  labelGuide,
  id,
  inputRightIcon,
  inputLeftIcon,
  inputLeftIconBorder = false,
  touched,
  error,
  labelRequired = false,
  containerClass,
  height = "44px",
  disabled,
  labelClassName = "",
  ...props
}: Props) => {
  const modify = (input: string) => {
    return {
      mask:
        input.startsWith("35") || input.startsWith("37")
          ? "____-______-_____"
          : "____-____-____-____-____",
    };
  };

  return (
    <div
      className={classNames("input-component", containerClass, {
        disabled: disabled,
      })}
    >
      {label && (
        <div className={"label"}>
          <label
            htmlFor={id}
            className={classNames(labelClassName, {
              required: labelRequired,
            })}
          >
            {label}
          </label>
          {labelGuide && (
            <Popup
              className={"input-label-text-guide"}
              content={labelGuide}
              trigger={<i className="uil uil-info-circle input-label-icon" />}
              position="top center"
            />
          )}
        </div>
      )}
      <div
        style={{ height }}
        className={classNames("input-wrapper", {
          "input-wrapper-icon": inputRightIcon,
          "input-wrapper-left-icon": inputLeftIcon,
        })}
      >
        {inputLeftIcon && (
          <div
            className={classNames("input-left-icon", {
              border: inputLeftIconBorder,
            })}
          >
            {inputLeftIcon}
          </div>
        )}
        <div className="ui input" style={{ height: "100%" }}>
          <InputMask
            mask="____-____-____-____"
            replacement={{ _: /\d/ }}
            modify={modify}
            {...props}
          />
        </div>

        {inputRightIcon ? (
          <div className={"input-right-icon"}>{inputRightIcon}</div>
        ) : null}
      </div>
      {touched && error ? <span className={"error"}>{error}</span> : null}
    </div>
  );
};
export default InputCardNumber;
