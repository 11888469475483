import { Routes, Route } from "react-router-dom";

import {
  MARKETING_CAMPAIGN_CRATE_LINK,
  MARKETING_CAMPAIGN_DETAIL_LINK,
  MARKETING_CAMPAIGN_EDIT_LINK,
  MARKETING_CAMPAIGNS_LINK,
  REWARD_REDEEM_LINK,
} from "../../App/configs/ListRoutes";
import CampaignList from "../views/CampaignList";
import CampaignDetail from "../views/CampaignDetail";
import CampaignCreateEdit from "../views/CampaignCreateEdit";
import RewardRedeem from "../views/RewardRedeem";

const MarketingRoutes = () => {
  return (
    <Routes>
      <Route path={MARKETING_CAMPAIGNS_LINK} element={<CampaignList />} />
      <Route
        path={MARKETING_CAMPAIGN_DETAIL_LINK}
        element={<CampaignDetail />}
      />
      <Route
        path={MARKETING_CAMPAIGN_CRATE_LINK}
        element={<CampaignCreateEdit />}
      />
      <Route
        path={MARKETING_CAMPAIGN_EDIT_LINK}
        element={<CampaignCreateEdit />}
      />
      <Route path={REWARD_REDEEM_LINK} element={<RewardRedeem />} />
    </Routes>
  );
};

export default MarketingRoutes;
