import React, { useContext } from "react";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import SizeBox from "../../../../../components/SizeBox";
import { LocalizationContext } from "../../../../../locales/Translation";
import InputCustom from "../../../../../components/Input";
import ButtonCustom from "../../../../../components/Button";
import EditLocationGuideItem from "../../PropertyDetail/Components/DetailsTab/Components/EditLocationGuideItem";

type Props = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
  handleChange: any;
  setFieldValue: any;
  setLocationGuide: any;
  locationGuide: any;
};
const HowToGetThere = ({
  isSubmitting,
  setFieldValue,
  values,
  errors,
  touched,
  handleChange,
  setLocationGuide,
  locationGuide,
}: Props) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <>
      <div className={"section"}>
        <SizeBox height={"16px"} />
        <div className={"section-title"}>{translations.howToGetThere}</div>
        <SizeBox height={"16px"} />
        <InputCustom
          label={translations.carDropOffPoint}
          value={values.how_to_get_there.car_drop_off_point}
          onChange={handleChange}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          touched={touched.how_to_get_there?.car_drop_off_point}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          error={errors.how_to_get_there?.car_drop_off_point}
          name={"how_to_get_there.car_drop_off_point"}
          disabled={isSubmitting}
        />
        <InputCustom
          label={translations.nearestMRTStation}
          value={values.how_to_get_there.nearest_mrt_station}
          onChange={handleChange}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          touched={touched.how_to_get_there?.nearest_mrt_station}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          error={errors.how_to_get_there?.nearest_mrt_station}
          name={"how_to_get_there.nearest_mrt_station"}
          disabled={isSubmitting}
        />
        <InputCustom
          label={translations.frontEntranceTheSpace}
          value={values.how_to_get_there.front_endtrance_of_the_space}
          onChange={handleChange}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          touched={touched.how_to_get_there?.front_endtrance_of_the_space}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          error={errors.how_to_get_there?.front_endtrance_of_the_space}
          name={"how_to_get_there.front_endtrance_of_the_space"}
          disabled={isSubmitting}
        />
        <SizeBox height={"16px"} />
        <div className={"edit-location-guide-list"}>
          {locationGuide && locationGuide.length > 0
            ? locationGuide.map((item: any, index: number) => (
                <EditLocationGuideItem
                  key={index}
                  index={index}
                  item={item}
                  setLocationGuide={setLocationGuide}
                  locationGuide={locationGuide}
                />
              ))
            : null}
        </div>
        {locationGuide.length > 0 ? (
          <SizeBox height={"13px"} />
        ) : (
          <SizeBox height={"20px"} />
        )}
        <ButtonCustom
          onClick={(e: any) => {
            e.preventDefault();
            setLocationGuide((prevState: any) => {
              const newState = JSON.parse(JSON.stringify(prevState));
              newState.push({
                title: "",
                description: "",
                picture: "",
                img_key: "",
                id: prevState[prevState.length - 1]
                  ? prevState[prevState.length - 1].id + 1
                  : 1,
              });
              return newState;
            });
          }}
          className={"primary-alt w-100 mr-0"}
        >
          <>
            {locationGuide.length > 0
              ? "Add additional step"
              : "Add step by step guide"}
            <SizeBox width={"12px"} />
            <i className="uil uil-plus" />
          </>
        </ButtonCustom>
        <SizeBox height={"40px"} />
      </div>
    </>
  );
};

export default HowToGetThere;
