import APISection from "../Components/APISection";
import SizeBox from "../../../../../components/SizeBox";
import WebHookSection from "../Components/WebHookSection";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  partnerCredentialInfoThunk,
  partnerWebhookListThunk,
} from "../../../thunks/ConfigThunks";
import { UserCompanyType } from "../../../../User/configs/DeclareTypes";
import { userCompanyChooseSelector } from "../../../../User/selectors/UserSelectors";

const GeneralSetting = () => {
  const dispatch = useDispatch();
  const userCompanyChoose: UserCompanyType = useSelector(
    userCompanyChooseSelector
  );

  useEffect(() => {
    _getPartnerCredentialInfo();
    _getPartnerWebhookList();
  }, []);

  const _getPartnerCredentialInfo = async () => {
    if (!userCompanyChoose?.company_id) return;

    try {
      await dispatch<any>(
        partnerCredentialInfoThunk(userCompanyChoose?.company_id?.toString())
      );
    } catch (error: any) {
      console.log("Error: ", error);
    }
  };

  const _getPartnerWebhookList = async () => {
    if (!userCompanyChoose?.company_id) return;

    try {
      await dispatch<any>(
        partnerWebhookListThunk(userCompanyChoose?.company_id?.toString())
      );
    } catch (error: any) {
      console.log("Error: ", error);
    }
  };

  return (
    <>
      <APISection />
      <SizeBox height={"32px"} />
      <WebHookSection />
    </>
  );
};

export default GeneralSetting;
