export const POST_MEETING_ROOM_LIST_API = "/api/v2/meeting-rooms";
export const POST_PROPERTY_LIST_API = "/api/v1/properties";
export const POST_CHECK_ROOM_AVAILABLE_API =
  "/api/v2/meeting-rooms/check-available-time";
export const POST_CONFIRM_AND_PAY_ROOM_API =
  "/api/v2/meeting-rooms/confirm-pay";
export const POST_BOOKING_ROOM_DETAIL_API =
  "/api/v1/meeting-rooms/booking/%bookingId%";
export const PROPERTY_BOOK_REVIEW_API = "/api/v1/hot-desk-booking/%id%/review";
export const PROPERTY_BOOK_CONFIRM_PAY_API =
  "/api/v1/hot-desk-booking/confirm-pay";
export const PROPERTY_BOOKING_DETAIL_API = "/api/v1/hot-desk-booking/%id%";
export const ASSIGN_BOOKING_DETAIL_API =
  "/api/v1/hotdesk/group-booking/%groupBookingId%";
export const READ_MORE_RATING_API = "/api/v1/visit-evaluation/list";

// Map count
export const PROPERTY_MARKET_COUNT_API = "/api/v1/properties/display-map";
export const ROOM_MARKET_COUNT_API = "/api/v1/meeting-rooms/display-map";

// Favorite
export const FAVORITE_ADD_API = "/api/v1/add-to-favorite";
export const FAVORITE_REMOVE_API = "/api/v1/remove-favorite";

// Detail
export const PROPERTY_DETAIL_VIEW_API = "/api/v1/property/%id%";
export const ROOM_DETAIL_VIEW_API = "/api/v1/meeting-room/%id%";
export const PROPERTY_AVAILABLE_API = "/api/v1/hot-desk-booking/available-time";
export const API_PROPERTY_AVAILABLE_HOUR_BY_DATE =
  "/api/v1/property-available-time";

// Url shorten
export const TINY_DOMAIN = "https://tinyurl.com/api-create.php";
export const TINY_API_DOMAIN = "https://api.tinyurl.com";
export const TINY_URL_KEY =
  "4x4bVTWn3OoaFTg5BHc2uJ5nsL3OsAIgRn9erMogFeYuL7PsFt5YAOqq1epz";

// Assign Booking
export const USER_ASSIGN_LIST = "/api/v1/employees";
export const ASSIGN_BOOKING_LIST = "/api/v1/hotdesk/group-booking";
export const ASSIGN_BOOKING_REVIEW = "/api/v1/hotdesk/group-booking/review";
export const GET_CURRENT_USER_LOCATION =
  "https://api.ipgeolocation.io/ipgeo?apiKey=d5b00b6c0ec2490592216aa7cc012d2c";

export const DESK_AMENITIES_API = "/api/v1/property/using-amenity/property";

export const ROOM_AMENITIES_API = "/api/v1/property/using-amenity/meeting_room";
export const INQUIRE_OFFICE_API = "/api/v2/send-private-office-inquiry";

export const PRIVATE_OFFICE_LIST_API = "/api/v2/private-offices";
export const REPORT_PROPERTY_STATUS_API = "/api/v1/user-report";
export const REQUEST_PARKING_API = "/api/v3/booking/%bookingId%/vehicle";

export const REQUEST_PARKING_STATUS_API =
  "/api/v3/booking/%bookingId%/vehicle/%vehicleRef%/status";

export const REWARD_LIST_API = "/api/v1/%propertyId%/rewards";
