import React, { SyntheticEvent, useEffect, useState } from "react";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../../App/configs/Constants";
import moment from "moment-timezone";
import { FORMAT_DATE_TIME_VARIANT_3 } from "../../../../App/configs/DateTimeConfigs";
import TableCustom from "../../../../../components/Table";
import MarketFilterMultiple from "../../../../../components/FilterMarket/MarketFilterMultiple";
import { Link } from "react-router-dom";
import { BUSINESS_COMPANY_DETAIL_LINK } from "../../../../App/configs/ListRoutes";
import { creditUsageService } from "../../../services/BusinessServices";
import { useDispatch, useSelector } from "react-redux";
import { globalSettingSelector } from "../../../../App/selectors/AppSelectors";
import CompanyFilter from "../../../../../components/CompanyFilter";
import { prepaidCreditFiltersSelector } from "../../../selectors/BusinessSelectors";
import { prepaidCreditFiltersAction } from "../../../actions/BusinessActions";
import { useGetAllQuery, useSetQuery } from "../../../../../utils/custom-hooks";

const Table = () => {
  const globalSetting = useSelector(globalSettingSelector);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const prepaidCreditFilters = useSelector(prepaidCreditFiltersSelector);
  const [tableData, setTableData] = useState<any>(null);

  const [addParamsAlready, setAddParamsAlready] = useState<boolean>(false);
  const getAllQuery = useGetAllQuery();
  const setQuery = useSetQuery();

  useEffect(() => {
    try {
      if (!prepaidCreditFilters.market_codes.length) {
        dispatch(
          prepaidCreditFiltersAction({
            market_codes: globalSetting?.location.map(
              ({ city_code }: { city_code: string }) => city_code
            ),
          })
        );
      }

      if (!Object.keys(getAllQuery).length) {
        return;
      }
      dispatch(prepaidCreditFiltersAction(getAllQuery));
    } finally {
      setAddParamsAlready(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!prepaidCreditFilters.market_codes.length || !addParamsAlready) {
          return;
        }
        setLoading(true);
        setQuery(prepaidCreditFilters);
        const { data } = await creditUsageService(prepaidCreditFilters);
        setTableData(data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [addParamsAlready, prepaidCreditFilters]);

  const columns = [
    { key: "credit_usage_by_transaction", col: "Transaction ID" },
    {
      key: "credit_usage_by_transaction_date",
      col: "Transaction Date",
    },
    { key: "credit_usage_by_company", col: "Company" },
    { key: "credit_usage_by_package", col: "Prepaid Credits Package" },
    { key: "credit_usage_by_amount", col: "Credits Used" },
  ];

  const _formatInputData = () => {
    return tableData?.items?.map(
      ({
        code,
        company_id,
        company_name,
        package_name,
        used_date,
        timezone,
        used_credit,
      }: any) => {
        return {
          col1: code,
          col2: moment
            .unix(used_date)
            .tz(timezone)
            .format(FORMAT_DATE_TIME_VARIANT_3),
          col3: (
            <Link to={BUSINESS_COMPANY_DETAIL_LINK.replace(":id", company_id)}>
              {company_name}
            </Link>
          ),
          col4: package_name,
          col5: used_credit,
        };
      }
    );
  };

  const _onChangeSortCol = (col: any) => {
    if (prepaidCreditFilters.sort_by !== col.key) {
      dispatch(
        prepaidCreditFiltersAction({
          sort_by: col.key,
          sort_type: ORDER_BY_ASC,
        })
      );
    } else {
      if (prepaidCreditFilters.sort_type === ORDER_BY_NONE) {
        dispatch(
          prepaidCreditFiltersAction({
            sort_by: col.key,
            sort_type: ORDER_BY_ASC,
          })
        );
      }
      if (prepaidCreditFilters.sort_type === ORDER_BY_DESC) {
        dispatch(
          prepaidCreditFiltersAction({
            sort_by: col.key,
            sort_type: ORDER_BY_NONE,
          })
        );
      }
      if (prepaidCreditFilters.sort_type === ORDER_BY_ASC) {
        dispatch(
          prepaidCreditFiltersAction({
            sort_by: col.key,
            sort_type: ORDER_BY_DESC,
          })
        );
      }
    }
  };

  const _onChangeMarketFilter = (values: string[]) => {
    dispatch(
      prepaidCreditFiltersAction({
        market_codes: values,
        page: 1,
      })
    );
  };

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    dispatch(
      prepaidCreditFiltersAction({
        page: activePage,
      })
    );
  };

  if (!addParamsAlready) {
    return null;
  }

  return (
    <div style={tableData?.items?.length < 3 ? { paddingBottom: 100 } : {}}>
      <TableCustom
        onChangeSortCol={_onChangeSortCol}
        sortTable
        loading={loading}
        showTotal
        tableData={tableData?.items ? _formatInputData() : []}
        columns={columns}
        orderBy={prepaidCreditFilters.sort_type}
        sortBy={prepaidCreditFilters.sort_by}
        itemPerPage={tableData?.pagination?.limit}
        currentPage={prepaidCreditFilters.page}
        totalItems={tableData?.pagination?.total}
        unit={"transactions"}
        onChangePagination={_onChangePagination}
        topRightComponent={
          <>
            <CompanyFilter
              companyId={prepaidCreditFilters.company_id}
              onChange={(value) =>
                dispatch(
                  prepaidCreditFiltersAction({
                    company_id: value,
                    page: 1,
                  })
                )
              }
              marketCodes={prepaidCreditFilters.market_codes}
              type={2}
              showDeletedCompany
            />
            <MarketFilterMultiple
              onChange={_onChangeMarketFilter}
              values={prepaidCreditFilters.market_codes}
            />
          </>
        }
      />
    </div>
  );
};

export default Table;
