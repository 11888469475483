import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { getDeskimoPropertyThunk } from "../../thunks/PropertyThunks";
import { useDispatch, useSelector } from "react-redux";
import DeskimoPropertiesListTable from "./Components/DeskimoPropertiesListTable";
import { Link } from "react-router-dom";
import { deskimoPropertyListFilterSelector } from "../../selectors/PropertySelector";
import {
  deskimoPropertyListFilterAction,
  deskimoPropertyListFilterApplyAllAction,
} from "../../actions/PropertyActions";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../App/configs/Constants";
import "./styles.scss";
import { LocalizationContext } from "../../../../locales/Translation";
import ButtonCustom from "../../../../components/Button";
import { PROPERTY_CREATE_LINK } from "../../../App/configs/ListRoutes";
import { globalSettingSelector } from "../../../App/selectors/AppSelectors";
import StatusFilter from "./Components/StatusFilter";
import { useGetAllQuery, useSetQuery } from "../../../../utils/custom-hooks";
import SearchWithDebounce from "../../../../components/SearchBar4Table/SearchWithDebounce";

const DeskimoPropertiesList = () => {
  const { translations } = useContext(LocalizationContext);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const propertyListFilter = useSelector(deskimoPropertyListFilterSelector);

  const globalSetting = useSelector(globalSettingSelector);

  const [addParamsAlready, setAddParamsAlready] = useState<boolean>(false);
  const getAllQuery = useGetAllQuery();
  const setQuery = useSetQuery();

  useEffect(() => {
    try {
      if (!propertyListFilter.market_code.length) {
        const temp = globalSetting?.location.map(
          ({ city_code }: { city_code: string }) => city_code
        );

        dispatch(
          deskimoPropertyListFilterAction({
            key: "market_code",
            value: temp,
          })
        );
      }

      if (!Object.keys(getAllQuery).length) {
        return;
      }

      dispatch(deskimoPropertyListFilterApplyAllAction(getAllQuery));
    } finally {
      setAddParamsAlready(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (!addParamsAlready) {
        return;
      }
      setQuery(propertyListFilter);
      await _getPropertyList();
    })();
  }, [addParamsAlready, propertyListFilter]);

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    dispatch(
      deskimoPropertyListFilterAction({ key: "page", value: activePage })
    );
  };

  const _getPropertyList = async () => {
    try {
      if (!propertyListFilter.market_code.length) {
        return;
      }
      setLoading(true);
      await dispatch<any>(getDeskimoPropertyThunk(propertyListFilter));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const _onRefresh = async () => {
    await dispatch<any>(getDeskimoPropertyThunk(propertyListFilter));
  };

  const _onChangeSortCol = (col: any) => {
    if (propertyListFilter.sort_by !== col.key) {
      dispatch(
        deskimoPropertyListFilterAction({ key: "sort_by", value: col.key })
      );
      dispatch(
        deskimoPropertyListFilterAction({
          key: "order_by",
          value: ORDER_BY_DESC,
        })
      );
    } else {
      dispatch(
        deskimoPropertyListFilterAction({ key: "sort_by", value: col.key })
      );
      if (propertyListFilter.order_by === ORDER_BY_NONE) {
        dispatch(
          deskimoPropertyListFilterAction({
            key: "order_by",
            value: ORDER_BY_DESC,
          })
        );
      } else if (propertyListFilter.order_by === ORDER_BY_DESC) {
        dispatch(
          deskimoPropertyListFilterAction({
            key: "order_by",
            value: ORDER_BY_ASC,
          })
        );
      } else
        dispatch(
          deskimoPropertyListFilterAction({
            key: "order_by",
            value: ORDER_BY_NONE,
          })
        );
    }
  };

  const _onChangeMarket = (values: string[]) => {
    dispatch(
      deskimoPropertyListFilterAction({
        key: "market_code",
        value: values,
      })
    );
  };

  if (!addParamsAlready) {
    return null;
  }

  return (
    <div>
      <div className={"filter-section"}>
        <div className={"filter-section-left"}>
          <SearchWithDebounce
            initialValue={propertyListFilter?.keyword}
            placeholder={translations.newPropertyPage.searchByProperty}
            onChange={(value) =>
              dispatch(
                deskimoPropertyListFilterAction({ key: "keyword", value })
              )
            }
          />
          <StatusFilter />
        </div>
        <Link to={PROPERTY_CREATE_LINK}>
          <ButtonCustom className={"primary"} height={"36px"}>
            <>{translations.addWorkspace} +</>
          </ButtonCustom>
        </Link>
      </div>
      <DeskimoPropertiesListTable
        onChangePagination={_onChangePagination}
        setLoading={setLoading}
        loading={loading}
        onChangeSortCol={_onChangeSortCol}
        onChangeMarket={_onChangeMarket}
        onRefresh={_onRefresh}
      />
    </div>
  );
};

export default DeskimoPropertiesList;
