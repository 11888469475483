import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useContext, useEffect, useState } from "react";
import {
  MeetRoomDetailProps,
  PropertyDetailType,
} from "../../configs/DeclareType";
import { roomDetailSelector } from "../../selectors/BookSelectors";
import { roomDetailThunk } from "../../thunks/BookThunks";
import { BOOK_ROOM_LINK } from "../../../App/configs/ListRoutes";
import MasterTemplate from "../../../App/views/MasterTemplate";
import { Dimmer, Loader } from "semantic-ui-react";
import SectionWhiteBox from "../../../../components/SectionWhiteBox";
import Thumbnail from "../BookProperty/Component/Thumbnail";
import Amenities from "../BookProperty/Component/Amenities";
import Copy from "../BookProperty/Component/Copy";
import Rating from "../BookProperty/Component/Rating";
import RoomSuggestion from "../BookProperty/Component/RoomSuggestion";
import BookingBox from "./Component/BookingBox";
import ModalBookingSubmitted from "../Components/ModalBookingSubmitted";
import {
  clipboardCopyWithAsyncFunction,
  shareMeetingRoomMessage,
} from "../../services/Common";
import { toastSuccess } from "../../../../components/Toast";
import { LocalizationContext } from "../../../../locales/Translation";
import { propertyDetailViewService } from "../../services/BookServices";
import {
  ROLE_BUSINESS_ADMIN_ID,
  ROLE_BUSINESS_USER_ID,
} from "../../../User/configs/Constants";
import Address from "../BookProperty/Component/Address";
import Schedule from "../BookProperty/Component/Schedule";

const BookRoom = () => {
  const { translations } = useContext(LocalizationContext);

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);

  const roomDetail: MeetRoomDetailProps = useSelector(roomDetailSelector);

  const [propertyData, setPropertyData] = useState<PropertyDetailType | null>(
    null
  );

  useEffect(() => {
    (async () => {
      try {
        if (!id) {
          return;
        }
        setLoading(true);
        await dispatch<any>(roomDetailThunk(id));
      } catch (e) {
        navigate(BOOK_ROOM_LINK);
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      try {
        if (!roomDetail?.property_id || !id) {
          return;
        }
        const body = document.querySelector("body");
        if (body) {
          body.classList.remove("prevent-scroll");
        }
        const { data } = await propertyDetailViewService(
          String(roomDetail?.property_id)
        );
        setPropertyData(data);
      } catch (e) {
        setPropertyData(null);
      }
    })();
  }, [roomDetail]);

  const _share = () =>
    shareMeetingRoomMessage(
      translations,
      roomDetail.meeting_room_id,
      roomDetail.web_url
    );

  return (
    <MasterTemplate
      roleAccept={[ROLE_BUSINESS_USER_ID, ROLE_BUSINESS_ADMIN_ID]}
    >
      {loading ? (
        <Dimmer active>
          <Loader />
        </Dimmer>
      ) : (
        <>
          <SectionWhiteBox isShowBack backRoute={BOOK_ROOM_LINK}>
            <div className={"book-detail__name-wrapper"}>
              <h2 className={"book-detail__name"}>{roomDetail?.name}</h2>
            </div>
            <div className={"book-detail__description-wrapper"}>
              <div className={"book-detail__description"}>
                {propertyData ? (
                  <span>
                    <i className="uil uil-star" />
                    {propertyData.rating.star} ({propertyData.rating.total}{" "}
                    {propertyData.rating.total < 2 ? "review" : "reviews"})
                  </span>
                ) : null}
                <span className={"dot"}>•</span>
                <span>
                  <i className="uil uil-building" />
                  {roomDetail.company_name}
                </span>
                <span className={"dot"}>•</span>
                <span>
                  <i className="uil uil-map" />
                  {roomDetail.address}
                </span>
                <span className={"dot"}>•</span>
                <span>
                  <i className="uil uil-users-alt" />
                  {roomDetail.capacity}{" "}
                  {roomDetail.capacity > 1 ? "People" : "Person"}
                </span>
              </div>
              <button
                className={
                  "book-detail__description book-detail__description-share"
                }
                onClick={async () => {
                  clipboardCopyWithAsyncFunction(_share);
                  toastSuccess(translations.copyToClipboard);
                }}
              >
                <i className="uil uil-upload"></i>
                &nbsp;&nbsp;Share
              </button>
            </div>
            <Thumbnail thumbnail={roomDetail.images} />
            <div className={"book-detail__book-section"}>
              <div>
                <Amenities amenities={roomDetail.amenities} />
                <Schedule propertyDetail={roomDetail} type={"MEETING_ROOM"} />
                <Copy
                  title={"Booking Requirements"}
                  content={roomDetail.booking_requirement}
                />
                <Copy
                  title={"Cancellation Policy"}
                  content={roomDetail.cancel_policy}
                />
                {propertyData ? <Rating rating={propertyData.rating} /> : null}
              </div>
              <div>
                <BookingBox />
              </div>
            </div>
            <Address lat={roomDetail.lat} lng={roomDetail.lng} />
            <RoomSuggestion
              rooms={
                propertyData
                  ? propertyData?.meeting_rooms.filter(
                      ({ id: roomId }) => roomId !== Number(id)
                    )
                  : []
              }
            />
          </SectionWhiteBox>
          <ModalBookingSubmitted />
        </>
      )}
    </MasterTemplate>
  );
};

export default BookRoom;
