import React, { useEffect, useState } from "react";
import Filters from "./Components/Filters";
import "./styles.scss";
import Table from "./Components/Table";
import { useSelector } from "react-redux";
import { globalSettingSelector } from "../../../App/selectors/AppSelectors";
import ModalAddEdit from "./Components/ModalAddEdit";
import {
  getBusinessCompaniesListService,
  officeLeasesContractListService,
} from "../../services/BusinessServices";
import ModalAddDeposit from "./Components/ModalAddDeposit";
import OfficeLeasesContext from "./Context";
import { toastError } from "../../../../components/Toast";
import { FILTER_LEASE_DEFAULT } from "../../configs/Constants";

const OfficeLease = () => {
  const [filters, setFilters] = useState(FILTER_LEASE_DEFAULT);
  const globalSetting = useSelector(globalSettingSelector);

  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [businessCompanyOptions, setBusinessCompanyOptions] = useState<[]>([]);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [leaseChooseId, setLeaseChooseId] = useState<null>(null);

  const [modalDepositInfo, setModalDepositInfo] = useState<object>({
    open: false,
    leaseId: null,
    type: "BUSINESS",
    currency: null,
  });

  const [refreshing, setRefreshing] = useState<boolean>(false);

  const _onSetRefreshing = () => {
    setRefreshing((prevState) => !prevState);
    return null;
  };

  useEffect(() => {
    (async () => {
      setFilters((prevState) => {
        return {
          ...prevState,
          market_code: globalSetting?.location.map(
            ({ city_code }: { city_code: string }) => city_code
          ),
        };
      });
      const { data } = await getBusinessCompaniesListService();
      const temp = data.map(({ company_id, company_name }: any) => ({
        key: company_id,
        text: company_name,
        value: company_id,
      }));
      setBusinessCompanyOptions(temp);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!filters.market_code.length) {
          return;
        }
        setLoading(true);
        const { data } = await officeLeasesContractListService(filters);
        setTableData(data);
      } catch (e: any) {
        toastError(e?.stattus?.message);
      } finally {
        setLoading(false);
      }
    })();
  }, [filters, refreshing]);

  return (
    <OfficeLeasesContext.Provider
      value={{
        businessCompanyOptions,
        tableData,
        loading,
        filters,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setFilters,
        leaseChooseId,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setLeaseChooseId,
        modalOpen,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setModalOpen,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        modalDepositInfo,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setModalDepositInfo,
        onSetRefreshing: _onSetRefreshing,
      }}
    >
      <Filters onOpenModal={() => setModalOpen(true)} />
      <Table />
      <ModalAddEdit loading={loading} setLoading={setLoading} />
      <ModalAddDeposit />
    </OfficeLeasesContext.Provider>
  );
};

export default OfficeLease;
