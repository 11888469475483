import SizeBox from "../../../../../../../components/SizeBox";
import React, { useContext, useEffect, useState } from "react";
import EditActions from "./EditActions";
import RichTextEditor from "react-rte";
import { useDispatch, useSelector } from "react-redux";
import { propertyDetailSelector } from "../../../../../selectors/PropertySelector";
import { useParams } from "react-router-dom";
import { updatePropertyService } from "../../../../../services/PropertyServices";
import { getPropertyDetailThunk } from "../../../../../thunks/PropertyThunks";
import { toastError } from "../../../../../../../components/Toast";
import { LocalizationContext } from "../../../../../../../locales/Translation";
import RichText from "../../../../../../../components/RichText";

const PropertyHouseRule = ({ setLoading, loading, ...props }: any) => {
  const { translations } = useContext(LocalizationContext);

  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const propertyDetail = useSelector(propertyDetailSelector);
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (propertyDetail?.house_rules) {
      setValue(
        RichTextEditor.createValueFromString(
          propertyDetail?.house_rules,
          "html"
        )
      );
    } else {
      setValue(RichTextEditor.createEmptyValue());
    }
  }, [propertyDetail]);

  const _handleSave = async () => {
    const payload = {
      house_rules: value.toString("html"),
      property_id: id,
    };
    setLoading(true);
    try {
      await updatePropertyService(payload);
      await dispatch<any>(getPropertyDetailThunk(id ? id : ""));
      setEditMode(false);
    } catch (response: any) {
      toastError(response?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (value: any) => {
    setValue(value);
    if (props.onChange) {
      props.onChange(value.toString("html"));
    }
  };

  const toolbarConfig = {
    display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS"],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };

  return (
    <div className={"section"}>
      <SizeBox height={"16px"} />
      <div
        className={
          "d-flex justify-content-between " +
          (editMode ? "" : "align-items-center")
        }
      >
        <div className={"pr-70 w-100"}>
          <div className={"section-title"}>{translations.houseRules}</div>
          <SizeBox height={"16px"} />
          {editMode ? (
            <div>
              <RichText value={value} onChange={onChange} />
            </div>
          ) : (
            <div className={"property-text"}>
              {propertyDetail?.house_rules ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: propertyDetail?.house_rules,
                  }}
                />
              ) : (
                translations.notAvailable
              )}
            </div>
          )}
        </div>

        <EditActions
          loading={loading}
          editMode={editMode}
          setEditMode={setEditMode}
          submit={_handleSave}
        />
      </div>
    </div>
  );
};

export default PropertyHouseRule;
