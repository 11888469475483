import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import classNames from "classnames";
import { updatePrimaryCardService } from "../../../../Setting/services/SettingServices";
import { useParams } from "react-router-dom";
import ButtonCustom from "../../../../../components/Button";
import { businessCompanyDetailThunk } from "../../../thunks/BusinessThunks";
import { useDispatch } from "react-redux";
import { toastError } from "../../../../../components/Toast";
import { removeBusinessCreditCardService } from "../../../../Setting/services/Common";
import { LocalizationContext } from "../../../../../locales/Translation";

type Props = {
  item: {
    card_number: string;
    card_holder_name: string;
    expired_date: string;
    business_credit_card_payment_id: number;
    brand: string;
    is_primary: boolean;
  };
  cardPrimaryId: null | number;
  setCardPrimaryId: Dispatch<SetStateAction<number | null>>;
};

const CreditCardItem = ({ item, setCardPrimaryId, cardPrimaryId }: Props) => {
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { translations } = useContext(LocalizationContext);

  const {
    business_credit_card_payment_id,
    card_number,
    expired_date,
    card_holder_name,
    brand,
  } = item;

  const _makeCardPrimary = async () => {
    try {
      if (!id) {
        return;
      }
      setLoading(true);
      const payload = {
        company_id: id,
        business_credit_card_payment_id,
      };
      await updatePrimaryCardService(payload);
      await dispatch<any>(businessCompanyDetailThunk(id));
      setCardPrimaryId(business_credit_card_payment_id);
    } catch (e: any) {
      toastError(e?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const _removeCard = async () => {
    if (!id) {
      return;
    }
    setDeleteLoading(true);
    await removeBusinessCreditCardService(
      business_credit_card_payment_id,
      translations,
      () => dispatch<any>(businessCompanyDetailThunk(id)),
      null,
      id
    );
    setDeleteLoading(false);
  };

  return (
    <div className={"company-detail__card-box"}>
      <ButtonCustom
        className={"card-delete"}
        onClick={_removeCard}
        loading={deleteLoading}
        disabled={loading}
      >
        <i className="uil uil-trash-alt" />
      </ButtonCustom>
      <div className={"card-no"}>{card_number}</div>
      <div className={"card-name"}>{card_holder_name || ""}</div>
      <div className={"card-expiry"}>{expired_date}</div>
      <img
        className={"card-img"}
        src={
          PUBLIC_URL + brand === "mastercard"
            ? "/assets/icons/icon-master-card.svg"
            : "/assets/icons/icon-visa.svg"
        }
      />
      <ButtonCustom
        loading={loading}
        onClick={_makeCardPrimary}
        className={classNames("card-btn", {
          active: business_credit_card_payment_id === cardPrimaryId,
        })}
        disabled={loading}
      >
        {business_credit_card_payment_id === cardPrimaryId
          ? "Primary card"
          : "Make Primary"}
      </ButtonCustom>
    </div>
  );
};

export default CreditCardItem;
