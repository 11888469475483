import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import {
  getCreditRemainingService,
  getMyProfileService,
  getPackageInfoService,
  getSettingService,
  getVoucherForBookingService,
} from "../services/AppServices";
import {
  creditPackagesAction,
  getCreditRemainingAction,
  getListVoucherForBookingAction,
  getMyProfileAction,
  globalSettingAction,
  onboardPromotionInfoAction,
} from "../actions/AppActions";
import { getOnboardingPromotionService } from "../../Dashboard/services/DashboardServices";
import { customerIOIdentifyUser } from "../../../services/customer-io/CustomerIOService";

export const getCreditRemainingThunk = () => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await getCreditRemainingService();
    dispatch<any>(getCreditRemainingAction(response?.data));
  };
};

export const getMyProfileThunk = (companyId?: number) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await getMyProfileService(companyId);
    customerIOIdentifyUser(data?.email);
    dispatch<any>(getMyProfileAction(data));
    return data;
  };
};

export const getGlobalSettingThunk = () => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await getSettingService();
    dispatch<any>(globalSettingAction(data));
  };
};

export const creditPackagesThunk = () => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await getPackageInfoService();
    dispatch<any>(creditPackagesAction(data));
    return data;
  };
};

export const onboardPromotionInfoThunk = () => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await getOnboardingPromotionService();
    dispatch<any>(onboardPromotionInfoAction(data));
  };
};

export const getListVoucherForBookingThunk = () => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await getVoucherForBookingService();
    dispatch<any>(getListVoucherForBookingAction(data));
  };
};
