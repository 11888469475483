import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { businessCompanyCustomPackageThunk } from "../../thunks/BusinessThunks";
import { businessCompanyCustomPackageDataSelector } from "../../selectors/BusinessSelectors";
import ModalCreateEditPackage from "./Components/ModalCreatePackage";
import Search from "./Components/PackagesTable/Search";
import ButtonCustom from "../../../../components/Button";
import "./style.scss";
import classNames from "classnames";
import { modalBusinessCompanyCustomPackageCreateEditAction } from "../../actions/BusinessActions";
import PackagesTable from "./Components/PackagesTable/PackagesTable";
import { LocalizationContext } from "../../../../locales/Translation";
import { globalSettingSelector } from "../../../App/selectors/AppSelectors";
import { useGetAllQuery, useSetQuery } from "../../../../utils/custom-hooks";

const CUSTOM_PACKAGE_MODE = 1;
const EXPENSE_PACKAGE_MODE = 2;

const DEFAULT_PAYLOAD: {
  package_type: number;
  page: number;
  keyword: string;
  market_code: string[];
  order_by: string;
  sort_by: string;
} = {
  package_type: CUSTOM_PACKAGE_MODE,
  page: 1,
  keyword: "",
  market_code: [],
  order_by: "",
  sort_by: "ASC",
};

const CompanyCustomPackages = () => {
  const { translations } = useContext(LocalizationContext);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(DEFAULT_PAYLOAD);
  const [marketOptions, setMarketOptions] = useState<[]>([]);
  const customPackageData = useSelector(
    businessCompanyCustomPackageDataSelector
  );
  const [editId, setEditId] = useState<number | null>(null);

  const globalSetting = useSelector(globalSettingSelector);
  const [addParamsAlready, setAddParamsAlready] = useState<boolean>(false);
  const getAllQuery = useGetAllQuery();
  const setQuery = useSetQuery();

  useEffect(() => {
    try {
      const marketList = globalSetting?.location.map(
        ({
          city_code,
          city_name,
        }: {
          city_code: string;
          city_name: string;
        }) => {
          return {
            id: city_code,
            name: city_name,
          };
        }
      );
      setMarketOptions(marketList);

      if (!Object.keys(getAllQuery).length) {
        setFilter((prevState) => {
          return {
            ...prevState,
            market_code: marketList.map(({ id }: { id: string }) => id),
          };
        });
      } else {
        setFilter(getAllQuery);
      }
    } finally {
      setAddParamsAlready(true);
    }
  }, []);

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    setFilter((prevState) => {
      return { ...prevState, page: activePage };
    });
  };

  const _onChangeMarket = (values: string[]) => {
    setFilter((prevState) => {
      return { ...prevState, market_code: values };
    });
  };

  const _onSearch = (value: string) => {
    setFilter((prevState) => {
      return {
        ...prevState,
        keyword: value,
      };
    });
  };

  useEffect(() => {
    (async () => {
      try {
        if (!filter?.market_code?.length) {
          return;
        }
        if (!addParamsAlready) {
          return;
        }
        setQuery(filter);
        setLoading(true);
        await dispatch<any>(businessCompanyCustomPackageThunk(filter));
      } catch (e: any) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [addParamsAlready, filter]);

  if (!addParamsAlready) {
    return null;
  }

  return (
    <>
      <div className={"package-function-section"}>
        <Search onSearch={_onSearch} />
        <div className={"button-packages-section"}>
          <ButtonCustom
            height={"36px"}
            className={classNames("button-packages-mode", {
              active: filter.package_type === CUSTOM_PACKAGE_MODE,
            })}
            onClick={() =>
              setFilter((prevState) => {
                return {
                  ...prevState,
                  package_type: CUSTOM_PACKAGE_MODE,
                };
              })
            }
          >
            {translations.customPackages.customPackagesModeTitle}
          </ButtonCustom>
          <ButtonCustom
            height={"36px"}
            className={classNames("button-packages-mode", {
              active: filter.package_type === EXPENSE_PACKAGE_MODE,
            })}
            onClick={() =>
              setFilter((prevState) => {
                return {
                  ...prevState,
                  package_type: EXPENSE_PACKAGE_MODE,
                };
              })
            }
          >
            {translations.customPackages.expensePackagesModeTitle}
          </ButtonCustom>
        </div>
        <div>
          {filter.package_type === CUSTOM_PACKAGE_MODE ? (
            <ButtonCustom
              onClick={() => {
                setEditId(null);
                dispatch(
                  modalBusinessCompanyCustomPackageCreateEditAction(true)
                );
              }}
              height={"36px"}
              className={"primary"}
            >
              {translations.customPackages.addCustomServicePackageButton}
            </ButtonCustom>
          ) : filter.package_type === EXPENSE_PACKAGE_MODE ? (
            <ButtonCustom
              onClick={() => {
                setEditId(null);
                dispatch(
                  modalBusinessCompanyCustomPackageCreateEditAction(true)
                );
              }}
              height={"36px"}
              className={"primary"}
            >
              {translations.customPackages.addExpenseServicePackageButton}
            </ButtonCustom>
          ) : null}
        </div>
      </div>
      <PackagesTable
        tableData={customPackageData}
        loading={loading}
        onChangePagination={_onChangePagination}
        onChangeMarket={_onChangeMarket}
        marketOptions={marketOptions}
        filter={filter}
        setFilter={setFilter}
        packageType={filter.package_type}
        setEditId={setEditId}
      />
      <ModalCreateEditPackage
        filter={filter}
        packageType={filter.package_type}
        editId={editId}
      />
    </>
  );
};

export default CompanyCustomPackages;
