import TableCustom from "../../../../../../components/Table";
import React, { Dispatch, SetStateAction, useContext } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useSelector } from "react-redux";
import {
  usersFilterSelector,
  usersSelector,
} from "../../../../selectors/EmployeeSelectors";
import { DeskimoUserType } from "../../../../configs/DeclareTypes";
import Action from "./Action";
import Company from "./Company";
import moment from "moment";
import { FORMAT_DATE_TIME_VARIANT_2 } from "../../../../../App/configs/DateTimeConfigs";
import Market from "./Market";

type Props = {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  onChangeSortCol: any;
  onChangePagination: any;
};

const EmployeeTable = ({
  loading,
  setLoading,
  onChangeSortCol,
  onChangePagination,
}: Props) => {
  const { translations } = useContext(LocalizationContext);
  const usersList = useSelector(usersSelector);
  const usersFilter = useSelector(usersFilterSelector);

  const columns = [
    { key: "name", col: translations.name },
    { key: "email", col: translations.email },
    {
      key: "phone_number",
      col: translations.phoneNo,
    },
    { key: null, col: translations.company },
    {
      key: "registration",
      col: translations.registration,
    },
    { key: "last_seen", col: translations.lastSeen },
    { key: "visits_count", col: translations.visitsCount },
    { key: "status", col: translations.status },
    { key: null, col: translations.action },
  ];

  const _formatInputData = () => {
    return usersList?.users?.map(
      ({
        full_name,
        visit_count,
        id,
        status,
        company,
        last_seen,
        phone_number,
        created_at,
        status_id,
        email,
        login_on_behalf,
        login_as_wms_user,
        is_black_list,
      }: DeskimoUserType) => {
        return {
          col1: <div>{full_name}</div>,
          col2: <div style={{ wordBreak: "break-word" }}>{email}</div>,
          col3: phone_number,
          col4: <Company company={company} />,
          col5: moment.unix(created_at).format(FORMAT_DATE_TIME_VARIANT_2),
          col6: last_seen
            ? moment.unix(last_seen).format(FORMAT_DATE_TIME_VARIANT_2)
            : "-",
          col7: visit_count,
          col8: is_black_list ? translations.Blacklisted : status,
          col9: (
            <Action
              loading={loading}
              setLoading={setLoading}
              userId={id}
              statusId={status_id}
              loginOnBehalf={login_on_behalf}
              loginAsWMS={login_as_wms_user}
              isBlackList={is_black_list}
            />
          ),
        };
      }
    );
  };

  return (
    <div
      className={"employee-table"}
      style={usersList?.users?.length < 3 ? { paddingBottom: 100 } : {}}
    >
      <TableCustom
        onChangeSortCol={onChangeSortCol}
        sortTable={true}
        loading={loading}
        showTotal
        tableData={usersList ? _formatInputData() : []}
        columns={columns}
        orderBy={usersFilter.order_by}
        sortBy={usersFilter.sort_by}
        currentPage={usersFilter.page}
        totalItems={usersList?.pagination?.total}
        unit={translations.usersLowerCase}
        onChangePagination={onChangePagination}
        topRightComponent={<Market />}
      />
    </div>
  );
};

export default EmployeeTable;
