import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DESKIMO_PROPERTY_COMPANY_LINK } from "../../../App/configs/ListRoutes";
import { PUBLIC_URL } from "../../../App/configs/Constants";
import MasterTemplate from "../../../App/views/MasterTemplate";
import { LocalizationContext } from "../../../../locales/Translation";
import "./styles.scss";
import Information from "./Components/Infomation";
import * as yup from "yup";
import { Formik, FormikValues } from "formik";
import Rooms from "./Components/Rooms";
import PaymentTerm from "./Components/PaymentTerm";
import BillingDetail from "./Components/BillingDetail";
import ButtonCustom from "../../../../components/Button";
import {
  getPropertyCompanyService,
  propertyCompanyService,
} from "../../services/PropertyCompanyServices";
import { PropertyCompanyDetailType } from "../../configs/DeclareType";
import { toastError, toastSuccess } from "../../../../components/Toast";
import PaymentReference from "./Components/PaymentReference";
import { ROLE_DESKIMO_ADMIN_ID } from "../../../User/configs/Constants";
import { getSettingService } from "../../../App/services/AppServices";
import ButtonGoBack from "../../../../components/ButtonGoBack";

const PropertyCompanyDetail = () => {
  const { translations } = useContext(LocalizationContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const [propertyCompany, setPropertyCompany] =
    useState<PropertyCompanyDetailType | null>(null);

  const [marketList, setMarketList] = useState([]);

  useEffect(() => {
    (async () => {
      _getSetting();

      if (!id) {
        return;
      }

      const { data } = await getPropertyCompanyService(id);
      setPropertyCompany(data);
    })();
  }, []);

  const _getSetting = async () => {
    const { data } = await getSettingService();
    setMarketList(data?.location);
  };

  const _onSubmit = async (values: FormikValues) => {
    try {
      await propertyCompanyService(values);
      if (id) {
        toastSuccess(translations.editSuccessPrompt);
      } else {
        toastSuccess(translations.createSuccessPrompt);
      }
      navigate(DESKIMO_PROPERTY_COMPANY_LINK);
    } catch (e: any) {
      toastError(e?.status.message);
    }
  };

  return (
    <MasterTemplate roleAccept={[ROLE_DESKIMO_ADMIN_ID]}>
      <ButtonGoBack goBack route={DESKIMO_PROPERTY_COMPANY_LINK} />
      <div className={"section-white-box property_company_detail"}>
        <Formik
          enableReinitialize
          initialValues={{
            company_id: propertyCompany?.company_id || null,
            company_name: propertyCompany?.company_name || "",
            company_branch_name: propertyCompany?.company_brand_name || "",
            company_address: propertyCompany?.company_address || "",
            contact_name: propertyCompany?.contact_name || "",
            contact_email: propertyCompany?.contact_email || "",
            contact_phone: propertyCompany?.contact_phone || "",
            dialing_code: propertyCompany?.dialing_code || "",
            account_manager_name: propertyCompany?.account_manager_name || "",
            account_manager_phone: propertyCompany?.account_manager_phone || "",
            account_manager_email: propertyCompany?.account_manager_email || "",
            market_code: propertyCompany?.market_code || "SG",
            contact_file: propertyCompany?.contract_file || "",
            description: propertyCompany?.description || "",
            room_cancel_policy: propertyCompany?.room_cancellation_policy || "",
            room_booking_requirement:
              propertyCompany?.room_booking_requirement || "",
            percentage_commission: propertyCompany?.percentage_commission || "",
            monthly_payment_processing_fee:
              propertyCompany?.monthly_payment_processing_fee !== undefined
                ? propertyCompany?.monthly_payment_processing_fee
                : "",
            payment_term_in_days: propertyCompany?.payment_term_in_days || "",
            room_percentage_commission:
              propertyCompany?.room_percentage_commission || "",
            minimum_des_commisson:
              propertyCompany?.minimum_des_commission || "",
            is_gst: propertyCompany?.is_gst || false,
            bank_account_number: propertyCompany?.bank_account_number || "",
            bank_account_name: propertyCompany?.bank_account_name || "",
            bank_name: propertyCompany?.bank_name || "",
            bank_address: propertyCompany?.bank_address || "",
            branch_code: propertyCompany?.brand_code || "",
            swift_code: propertyCompany?.swift_code || "",
            billing_company_number: propertyCompany?.billing_number || "",
            billing_company_name: propertyCompany?.billing_name || "",
            billing_company_representative:
              propertyCompany?.billing_company_representative || "",
            billing_company_address: propertyCompany?.billing_address || "",
            currency: propertyCompany?.currency || "SGD",
            hot_desk_booking_delay_time:
              propertyCompany?.hot_desk_booking_delay_time || null,
            meeting_room_booking_delay_time:
              propertyCompany?.meeting_room_booking_delay_time || null,
            has_workspace_integration:
              propertyCompany?.has_workspace_integration || false,
            affiliate_reference: "",
          }}
          validationSchema={yup.object().shape({
            company_name: yup
              .string()
              .required()
              .label(translations.companyName),
            company_branch_name: yup
              .string()
              .required()
              .label(translations.companyBrandName),
            company_address: yup
              .string()
              .required()
              .label(translations.companyAddress),
            contact_email: yup
              .string()
              .email()
              .required()
              .label(translations.contactEmail),
            contact_phone: yup.number().label(translations.contactPhone),
            account_manager_name: yup
              .string()
              .label(translations.deskimoAccountManagerName),
            account_manager_phone: yup
              .string()
              .label(translations.deskimoAccountManagerPhoneNumber),
            percentage_commission: yup
              .string()
              .required()
              .label(translations.percentageCommission),
            monthly_payment_processing_fee: yup
              .string()
              .required()
              .label(translations.monthlyPaymentProcessingFee),
            payment_term_in_days: yup
              .string()
              .required()
              .label(translations.paymentTermsInDays),
            room_percentage_commission: yup
              .string()
              .required()
              .label(translations.meetingRoomPercentageCommission),
            bank_account_number: yup
              .string()
              .required()
              .label(translations.bankAccountNumber),
            bank_account_name: yup
              .string()
              .required()
              .label(translations.bankAccountName),
            bank_name: yup.string().required().label(translations.bankName),
            hot_desk_booking_delay_time: yup
              .number()
              .nullable(true)
              .typeError("Please input number only"),
            meeting_room_booking_delay_time: yup
              .number()
              .nullable(true)
              .typeError("Please input number only"),
          })}
          onSubmit={_onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            resetForm,
          }) => (
            <>
              <Information
                values={values}
                errors={errors}
                touched={touched}
                isSubmitting={isSubmitting}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                marketList={marketList}
                propertyCompany={propertyCompany}
              />

              <Rooms
                values={values}
                errors={errors}
                touched={touched}
                isSubmitting={isSubmitting}
                handleChange={handleChange}
              />

              <PaymentTerm
                values={values}
                errors={errors}
                touched={touched}
                isSubmitting={isSubmitting}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
              />

              <PaymentReference
                values={values}
                errors={errors}
                touched={touched}
                isSubmitting={isSubmitting}
                handleChange={handleChange}
              />

              <BillingDetail
                values={values}
                errors={errors}
                touched={touched}
                isSubmitting={isSubmitting}
                handleChange={handleChange}
              />

              <div className={"section-white-box-actions"}>
                <ButtonCustom
                  className={"cancel mr-10px"}
                  onClick={resetForm}
                  disabled={isSubmitting}
                >
                  {translations.cancel}
                </ButtonCustom>
                <ButtonCustom
                  className={"primary mr-0"}
                  onClick={handleSubmit}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  {id ? translations.save : translations.add}
                </ButtonCustom>
              </div>
            </>
          )}
        </Formik>
      </div>
    </MasterTemplate>
  );
};

export default PropertyCompanyDetail;
