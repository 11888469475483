import clientRequestService from "../../../services/ClientRequestService";
import {
  ACCESS_TOKEN_CACHE,
  BAD_REQUEST,
  METHOD_POST,
} from "../../../configs/Constants";
import { isAuthAction } from "../actions/AuthenticationActions";
import { store } from "../../App/configs/Store";
import {
  CLIENT_ID,
  CLIENT_SECRET,
  GRANT_TYPE,
  GRANT_TYPE_FORCE_LOGIN,
  GRANT_TYPE_OTP_LOGIN,
} from "../../../configs/Endpoints";
import {
  API_CHANGE_DESK_BOOKING_STATUS,
  API_CHANGE_ROOM_BOOKING_STATUS,
  API_CHECK_DESK_BOOKING_STATUS,
  API_CHECK_ROOM_BOOKING_STATUS,
  API_CHECK_STILL_LOGIN,
  API_FORGOT_PASSWORD,
  API_LOGIN,
  API_REMINDER_CALLBACK,
  API_RESET_PASSWORD,
  PERMISSION_API,
} from "../configs/Endpoints";
import { toastError } from "../../../components/Toast";
import { DESK_BOOKING, ROOM_BOOKING } from "../../Dashboard/configs/Constants";
import { isGlobalIndicatorAction } from "../../App/actions/AppActions";
import { DASHBOARD_DESKIMO_LINK } from "../../App/configs/ListRoutes";
import { SWAL_CONFIRM_OPTION } from "../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../components/SweetAlert";
import { PUBLIC_URL } from "../../App/configs/Constants";

export const loginService = async (data: {
  email?: string;
  password?: string;
  token?: string;
}) => {
  const payload = {
    //Change grant type in case Force Login
    grant_type: data?.token ? GRANT_TYPE_FORCE_LOGIN : GRANT_TYPE,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    ...data,
  };
  return await clientRequestService({
    isAuth: false,
    method: METHOD_POST,
    url: API_LOGIN + "?from_client=WEB",
    data: payload,
  });
};

export const loginWithOTPService = async (data: {
  otp: number;
  phone_number: string;
  country_code: string;
}) => {
  const payload = {
    grant_type: GRANT_TYPE_OTP_LOGIN,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    ...data,
  };
  return await clientRequestService({
    isAuth: false,
    method: METHOD_POST,
    url: API_LOGIN + "?from_client=WEB",
    data: payload,
  });
};

export const forgotPasswordService = async (data: { username: string }) => {
  return await clientRequestService({
    isAuth: false,
    method: METHOD_POST,
    url: API_FORGOT_PASSWORD,
    data: data,
  });
};

export const resetPasswordService = async (
  data: {
    plain_password: string;
  },
  token: string
) => {
  return await clientRequestService({
    isAuth: false,
    method: METHOD_POST,
    url: API_RESET_PASSWORD.replaceAll("%token%", token),
    data: data,
  });
};

export const getPermissionService = async () => {
  return await clientRequestService({
    isCompany: true,
    url: PERMISSION_API,
  });
};

export const loginGeneralService = async (
  values: object,
  translations: any
) => {
  try {
    const dispatch = store.dispatch;
    const { access_token } = await loginService(values);

    localStorage.setItem(ACCESS_TOKEN_CACHE, access_token);
    dispatch(isAuthAction(true));
  } catch (error: any) {
    const errorDescription = error?.response?.data?.error_description;
    if (errorDescription) {
      toastError(errorDescription);
    } else {
      toastError(translations.errorMessage.userNamePasswordNotValid);
    }
    throw error;
  }
};

export const checkStillLoginService = async (entitlementId?: any) => {
  return await clientRequestService({
    url: entitlementId
      ? API_CHECK_STILL_LOGIN + `?entitlement_id=${entitlementId}`
      : API_CHECK_STILL_LOGIN,
  });
};

export const checkBookingStatusService = async (
  type: string,
  token: string
) => {
  return await clientRequestService({
    url:
      type === ROOM_BOOKING
        ? API_CHECK_ROOM_BOOKING_STATUS.replaceAll("%token%", token)
        : API_CHECK_DESK_BOOKING_STATUS.replaceAll("%token%", token),
    isAuth: false,
  });
};

export const changeBookingStatusService = async (
  type: string,
  token: string,
  data?: object
) => {
  return await clientRequestService({
    method: "post",
    url:
      type === ROOM_BOOKING
        ? API_CHANGE_ROOM_BOOKING_STATUS.replaceAll("%token%", token)
        : API_CHANGE_DESK_BOOKING_STATUS.replaceAll("%token%", token),
    data,
    isAuth: false,
  });
};

export const handleCheckBookingStatusService = async (
  type: string | null,
  token: string | undefined,
  navigate: any,
  callback?: () => void
) => {
  const dispatch = store.dispatch;
  try {
    dispatch(isGlobalIndicatorAction(true));

    if (!token || (type !== ROOM_BOOKING && type !== DESK_BOOKING)) {
      throw Error();
    }
    await checkBookingStatusService(type, token);
    callback && (await callback());
  } catch (e: any) {
    const options = SWAL_CONFIRM_OPTION({
      iconHtml: `<img src=${
        PUBLIC_URL + "/assets/icons/icon-error-red.svg"
      } />`,
      title: e?.status?.message,
      confirmText: "OK",
      confirmClass: "danger",
    });
    SweetAlertCommon({
      options: { ...options, allowOutsideClick: false },
      confirmCallback: () => navigate(DASHBOARD_DESKIMO_LINK),
    });
  } finally {
    dispatch(isGlobalIndicatorAction(false));
  }
};

export const reminderCallbackService = (token: string) => {
  return clientRequestService({
    isAuth: false,
    method: METHOD_POST,
    url: API_REMINDER_CALLBACK.replace(":token", token),
    data: {},
  });
};
