import React, { useContext } from "react";
import SearchBar4Table from "../../../../../../components/SearchBar4Table";
import { debounce } from "lodash";
import OfficeLeasesContext from "../../Context";

const Search = () => {
  const { setFilters } = useContext(OfficeLeasesContext);
  const debounce_onChange = debounce(function (data: any, { value }: any) {
    setFilters((prevState: any) => ({ ...prevState, keyword: value }));
  }, 500);

  return (
    <SearchBar4Table
      onChange={debounce_onChange}
      placeholder={"Search by Business Company, Property"}
    />
  );
};

export default Search;
