import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import TableCustom from "../../../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import { configTagsListThunk } from "../../thunks/ConfigThunks";
import { configTagsListSelector } from "../../selectors/ConfigSelectors";
import Action from "./Components/Action";
import { LocalizationContext } from "../../../../locales/Translation";
import ButtonCustom from "../../../../components/Button";
import ModalAddTag from "./Components/ModalAddTag";

const Tags = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [onRefresh, setOnRefresh] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState({ page: 1 });

  const configTagsList = useSelector(configTagsListSelector);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await dispatch<any>(configTagsListThunk(filters));
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [onRefresh, filters]);

  const _formatInputData = () => {
    if (!configTagsList?.items) {
      return [];
    }
    return configTagsList?.items?.map(({ name, id }: any) => {
      return {
        name,
        action: <Action id={id} setOnRefresh={setOnRefresh} />,
      };
    });
  };

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    setFilters((prevState) => {
      return { ...prevState, page: activePage };
    });
  };

  const columns = ["Tag Name", translations.action];

  return (
    <>
      <div className={"filter-section justify-content-end"}>
        <ButtonCustom
          onClick={() => setModalOpen(true)}
          height={"36px"}
          fontSize={"12px"}
          className={"primary text-nowrap mr-0"}
        >
          <>Add Tag +</>
        </ButtonCustom>
      </div>
      <div
        className={"employee-table"}
        style={configTagsList?.item?.length < 3 ? { paddingBottom: 100 } : {}}
      >
        <TableCustom
          loading={loading}
          tableData={configTagsList ? _formatInputData() : []}
          columns={columns}
          currentPage={configTagsList?.pagination?.page}
          totalItems={configTagsList?.pagination?.total}
          unit={""}
          onChangePagination={_onChangePagination}
        />
      </div>
      <ModalAddTag
        onClose={() => setModalOpen(false)}
        open={modalOpen}
        setOnRefresh={setOnRefresh}
      />
    </>
  );
};

export default Tags;
