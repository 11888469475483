export const GET_CREDIT_REMAINING_API = "/api/v1/credit-remaining/%companyId%";
export const DELETE_AUTO_TOP_UP_API = "/api/v1/auto-recharge/%companyId%";
export const GET_PACKAGE_API = "/api/v1/credit-package/%companyId%";
export const GET_COMPANY_VOUCHER_API = "/api/v1/company-vouchers/%companyId%";
export const COMPANY_VOUCHER_DELETE_API =
  "/api/v1/company-vouchers/%companyId%";
export const POST_BUY_PACKAGE_API = "/api/v1/transaction";
export const BUY_TRIAL_PACKAGE_API = "/api/v3/trial-package/%companyId%/buy";
export const POST_VALIDATE_FILE_API =
  "/api/v1/company/%companyId%/employee-file/validate";
export const POST_INVITE_FILE_API =
  "/api/v1/company/%companyId%/employee-file/import";

export const POST_ADD_VOUCHER_API = "/api/v1/add-voucher";
export const POST_CHANGE_PASSWORD_API = "/api/v1/change-password";
export const GET_MY_PROFILE_API = "/api/v1/my-user-profile";

export const POST_UPDATE_MY_PROFILE_API = "/api/v1/update-my-profile";

//Upload Image
export const POST_UPLOAD_IMAGE = "/api/v1/upload-avatar";
export const POST_UPLOAD_FILE = "/api/v3/media_lib/upload-file";
export const UPLOAD_CONTRACT_FILE_API =
  "/api/v1/des-admin/upload-contract-file";

export const EMPLOYEE_INVITE_FILE_URL =
  "https://production-deskimo.s3.ap-southeast-1.amazonaws.com/media/company/business-user-template.xlsx";

export const API_SETTING = "/api/v1/setting";

export const MARKET_SETTINGS_API = "/api/v1/des-admin/get-setting-information";
export const POST_PENDING_BOOKING_NOTIFICATION_API =
  "/api/v1/dashboard/get-notification-pending-booking-number";

//Voucher
export const GET_LIST_VOUCHER_FOR_BOOKING_API = "/api/v1/promotion/vouchers";
export const ADD_VOUCHER_FOR_BOOKING_API = "/api/v1/promotion/voucher/add";
export const REDEEM_VOUCHER_FOR_BOOKING_API =
  "/api/v1/promotion/voucher/redeem";
export const REMOVE_VOUCHER_FOR_BOOKING_API = "api/v1/promotion/voucher/remove";
