import React, { useContext } from "react";
import DropdownMultiple from "../../../../../components/DropdownMultiple";
import { Dropdown } from "semantic-ui-react";
import { LocalizationContext } from "../../../../../locales/Translation";
import ConfigurationIcon from "../Components/ConfigurationIcon";
import { useNavigate } from "react-router-dom";
import {
  AFFILIATE_LINK,
  BILLING_LINK,
  DASHBOARD_BUSINESS_LINK,
  EMPLOYEES_BUSINESS_LINK,
  INTEGRATIONS_LINK,
  PRE_PAID_LINK,
  SETTING_LINK,
} from "../../../configs/ListRoutes";
import SwitchCompany from "../ProfileDropdown/SwitchCompany";

const Configuration = () => {
  const { translations } = useContext(LocalizationContext);
  const navigate = useNavigate();

  return (
    <DropdownMultiple
      trigger={
        <div className={"navbar__item"} style={{ height: 52 }}>
          <ConfigurationIcon />
          {translations.navBar.configurationHeaderTitle}
        </div>
      }
      className={"navbar__profile navbar_configuration guide-configuration"}
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => navigate(DASHBOARD_BUSINESS_LINK)}
          text={translations.navBar.dashboard}
          icon={<i className="uil uil-graph-bar icon" />}
        />

        <Dropdown.Item
          onClick={() => navigate(SETTING_LINK)}
          text={translations.allowances}
          icon={<i className="uil uil-wallet icon" />}
        />
        <Dropdown.Item
          onClick={() => navigate(EMPLOYEES_BUSINESS_LINK)}
          text={translations.navBar.employees}
          icon={<i className="uil uil-users-alt icon" />}
        />
        <Dropdown.Item
          onClick={() => navigate(PRE_PAID_LINK)}
          text={translations.settings.prepaidCredit}
          icon={<i className="uil uil-dollar-sign icon" />}
        />
        <Dropdown.Item
          onClick={() => navigate(BILLING_LINK)}
          text={translations.settings.billing}
          icon={<i className="uil uil-receipt-alt icon" />}
        />
        <Dropdown.Item
          onClick={() => navigate(AFFILIATE_LINK)}
          text={translations.settings.affiliate}
          icon={<i className="uil uil-megaphone icon" />}
        />
        <Dropdown.Item
          onClick={() => navigate(INTEGRATIONS_LINK)}
          text={translations.navBar.settings}
          icon={<i className="uil uil-setting icon" />}
        />

        <SwitchCompany />
      </Dropdown.Menu>
    </DropdownMultiple>
  );
};

export default Configuration;
