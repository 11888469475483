import React, { useEffect, useState } from "react";
import CheckboxCustom from "../../../../../../components/Checkbox";
import UserItem from "./EmployeeItem";
import SizeBox from "../../../../../../components/SizeBox";
import { useDispatch, useSelector } from "react-redux";
import {
  employeeGoogleChooseSelector,
  employeeGoogleSelector,
} from "../../../../selectors/EmployeeSelectors";
import { EmployeeGoogleType } from "../../../../configs/DeclareTypes";
import { employeeGoogleChooseAction } from "../../../../actions/EmployeeActions";
import { GATrackingEvent } from "../../../../../../services/GATrackingService";

type Props = {
  searchValue: string;
};

const TableUser = ({ searchValue }: Props) => {
  const employeeGoogle = useSelector(employeeGoogleSelector);
  const employeeGoogleChoose = useSelector(employeeGoogleChooseSelector);
  const dispatch = useDispatch();

  const [checked, setChecked] = useState<boolean>(false);

  const employeeNotInvited = [...employeeGoogle].filter(
    (item: EmployeeGoogleType) => !item.is_invited
  );

  useEffect(() => {
    const temp = [...employeeGoogle].filter(
      (item: EmployeeGoogleType) => !item.is_invited
    );
    dispatch<any>(employeeGoogleChooseAction(temp));
  }, [employeeGoogle]);

  useEffect(() => {
    if (employeeGoogleChoose.length === employeeNotInvited.length) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [employeeGoogleChoose]);

  const _onCheck = () => {
    if (checked) {
      GATrackingEvent("gg_integration_uncheck_all_user");
      dispatch<any>(employeeGoogleChooseAction([]));
      return;
    }

    const temp = [...employeeGoogle].filter(
      (item: EmployeeGoogleType) => !item.is_invited
    );
    dispatch<any>(employeeGoogleChooseAction(temp));
  };

  return (
    <>
      <div className={"employee_google_total"}>
        <CheckboxCustom checked={checked} onChange={() => _onCheck()} />
        <div className={"employee_google_total_text"}>
          <div>Total Users ({employeeGoogle.length})</div>
          <SizeBox height={"4px"} />
          <div className={"select_text"}>
            Selected Users ({employeeGoogleChoose.length})
          </div>
        </div>
      </div>

      <div className={"employee_google_users"}>
        {employeeGoogle
          .filter(({ first_name, last_name, email }: EmployeeGoogleType) => {
            const fullName = first_name + " " + last_name;
            return (
              fullName.includes(searchValue) || email.includes(searchValue)
            );
          })
          .map((item: EmployeeGoogleType) => {
            return <UserItem key={item.email} item={item} />;
          })}
      </div>
    </>
  );
};

export default TableUser;
