import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { getListMediaFileService } from "../services/MediaFileServices";
import { mediaFileListActions } from "../actions/MediaFileActions";

export const mediaFileListThunk = (payload: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await getListMediaFileService(payload);
    dispatch<any>(mediaFileListActions(data));
  };
};
