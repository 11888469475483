import { Popup } from "semantic-ui-react";
import React, { useState } from "react";
import "./style.scss";
import classNames from "classnames";

const CopyToClipboardTrigger = ({ text, copyText, trigger }: any) => {
  const [copy, setCopy] = useState(false);
  return (
    <Popup
      className={classNames("copy-to-clipboard-wrapper", {
        copied: copy,
      })}
      onClose={() => {
        setCopy(false);
      }}
      on={"click"}
      content={
        <div className={"copy-to-clipboard "}>
          {copy ? (
            <>
              <i className="uil uil-clipboard-notes" />
              Copied
            </>
          ) : (
            <>
              <i className="uil uil-clipboard" />
              {copyText}
            </>
          )}
        </div>
      }
      trigger={
        <div
          onClick={() => {
            navigator.clipboard.writeText(text);
            setCopy(true);
          }}
        >
          {trigger}
        </div>
      }
    />
  );
};

export default CopyToClipboardTrigger;
