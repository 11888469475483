import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import SizeBox from "../../../../../../components/SizeBox";
import { DeskimoStatisticType } from "../../../../configs/DeclareTypes";
import { useSelector } from "react-redux";
import { dashboardStatisticDeskimoSelector } from "../../../../selectors/DashboardSelectors";

const Revenue = () => {
  const { translations } = useContext(LocalizationContext);

  const dashboardStatisticDeskimo: DeskimoStatisticType = useSelector(
    dashboardStatisticDeskimoSelector
  );

  return (
    <div className={"dashboard_chart_box_wrapper"}>
      <div className={"dashboard_chart_box"}>
        <h2 className={"dashboard_chart_title"}>{translations.revenue}</h2>
        <div className={"dashboard_chart_line"}>
          <h2 className={"dashboard_chart_sub_title"}>
            GBV ({dashboardStatisticDeskimo.currency})
          </h2>
          <SizeBox height={"18px"} />
          <span className={"dashboard_chart_title"}>
            ${dashboardStatisticDeskimo.GBV}
          </span>
        </div>
        <div className={"dashboard_chart_line"}>
          <h2 className={"dashboard_chart_sub_title"}>
            Net revenue ({dashboardStatisticDeskimo.currency})
          </h2>
          <SizeBox height={"18px"} />
          <span className={"dashboard_chart_title"}>
            ${dashboardStatisticDeskimo.net_revenue}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Revenue;
