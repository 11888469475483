import { BRAND_LOGO } from "../configs/Constants";
import { SweetAlertCommon } from "../../../components/SweetAlert";
import {
  SWAL_CONFIRM_OPTION_V2,
  SWAL_DELETE_OPTION,
} from "../../../components/SweetAlert/configs/SwalConfigs";
import { toastError, toastSuccess } from "../../../components/Toast";
import React from "react";
import { removeCreditCardService } from "./SettingServices";

export const getLogoBrand = (brandLink: any) => {
  let imgUrl = "";
  if (brandLink) {
    const brand = BRAND_LOGO.filter((brand) => brand.id === brandLink);
    if (brand && brand.length > 0) {
      imgUrl = brand[0].imgUrl;
    }
  }
  return imgUrl;
};

export const removeBusinessCreditCardService = async (
  id: number,
  translations: any,
  callback: () => void,
  confirmCode?: string | null,
  companyId?: string
) => {
  try {
    const payload: any = {
      business_credit_card_payment_id: id,
    };

    if (confirmCode) {
      payload.confirm_code = confirmCode;
    }

    if (companyId) {
      payload.company_id = companyId;
    }

    console.log("code payload", payload);

    await removeCreditCardService(payload);
    callback && (await callback());
    toastSuccess(translations.settings.removeCardSuccessMsg);
  } catch (e: any) {
    removeCreditWithHasAutoTopUp(e, id, translations, callback, companyId);
  }
};

export const removeCreditWithHasAutoTopUp = (
  data: any,
  id: number,
  translations: any,
  callback: () => void,
  companyId?: string
) => {
  const code = data?.status?.code;
  const confirmCode = data?.data?.confirm_code;

  if (!code) {
    return;
  }

  console.log("code", code);

  switch (code) {
    case 5001: {
      SweetAlertCommon({
        options: SWAL_CONFIRM_OPTION_V2({
          title: translations.UnableToRemoveThisCard,
          html: (
            <span>
              {translations.removeCardCase5001}{" "}
              <a href={"mailto:support@deskimo.com"}>support@deskimo.com</a>.
            </span>
          ),
          confirmText: translations.confirm,
        }),
        confirmCallback: () => null,
      });

      break;
    }
    case 5002: {
      SweetAlertCommon({
        options: SWAL_DELETE_OPTION({
          title: translations.ConfirmCardRemoval,
          html: (
            <span>
              {translations.removeCardCase5002_1} <b>{translations.before}</b>{" "}
              {translations.removeCardCase5002_2}
            </span>
          ),
          confirmText: translations.RemoveCard,
        }),
        confirmCallback: async () => {
          try {
            await removeBusinessCreditCardService(
              id,
              translations,
              callback,
              confirmCode,
              companyId
            );
          } catch (response: any) {
            toastError(response?.status?.message);
          }
        },
        cancelCallback: () => null,
      });
      break;
    }
    case 5003: {
      SweetAlertCommon({
        options: SWAL_CONFIRM_OPTION_V2({
          title: translations.UnableToRemoveThisCard,
          html: (
            <span>
              {translations.removeCardCase5003}{" "}
              <a href={"mailto:support@deskimo.com"}>support@deskimo.com</a>.
            </span>
          ),
          confirmText: translations.confirm,
        }),
        confirmCallback: () => null,
      });

      break;
    }
    case 5004: {
      SweetAlertCommon({
        options: SWAL_DELETE_OPTION({
          title: translations.ConfirmCardRemoval,
          text: translations.removeCardCase5004,
          confirmText: translations.RemoveCard,
        }),
        confirmCallback: async () => {
          try {
            await removeBusinessCreditCardService(
              id,
              translations,
              callback,
              confirmCode,
              companyId
            );
          } catch (response: any) {
            toastError(response?.status?.message);
          }
        },
        cancelCallback: () => null,
      });
      break;
    }
    default:
      toastError(data?.status?.message);
  }
};
