import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import * as yup from "yup";
import ButtonCustom from "../../../../../components/Button";
import { Formik } from "formik";
import SizeBox from "../../../../../components/SizeBox";
import InputPhoneFlag from "../../../../../components/InputPhoneFlag";
import {
  CALLING_CODE,
  DIALING_CODE,
} from "../../../../App/configs/PhonePrefix";

type Props = {
  handleAddPhone: (data: { dialing_code: string; phone: string }) => void;
};

const CreatePhoneNumber = ({ handleAddPhone }: Props) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <div className="business-onboard">
      <div className="title">{translations.buOnboard.enterYourPhoneNumber}</div>
      <div className="description">
        {translations.buOnboard.enterYourPhoneNumberDescription}
      </div>
      <Formik
        initialValues={{
          dialing_code: "65",
          phone: "",
        }}
        validationSchema={yup.object().shape({
          dialing_code: yup.string().required().label(translations.newPassword),
          phone: yup.string().required().label(translations.phoneNumber),
        })}
        onSubmit={(values) => handleAddPhone(values)}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            <InputPhoneFlag
              inLineUI
              selectedFlag={DIALING_CODE.filter((country) => {
                return (
                  country?.value?.replaceAll("+", "") === values.dialing_code
                );
              })[0]?.key.toUpperCase()}
              onSelectFlag={(value) => {
                setFieldValue(
                  "dialing_code",
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  CALLING_CODE[value].secondary.replaceAll("+", "")
                );
              }}
              phoneValue={values.phone}
              phoneChange={(value) => setFieldValue("phone", value)}
              touched={touched.phone}
              error={errors.phone}
              label={translations.phoneNumber}
            />
            <SizeBox height={"158px"} />
            <ButtonCustom
              className={"primary"}
              height={"47px"}
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={isSubmitting || !values.phone.length}
            >
              {translations.submit}
            </ButtonCustom>
          </>
        )}
      </Formik>
    </div>
  );
};

export default CreatePhoneNumber;
