import React, { useEffect } from "react";
import "../../../Dashboard/styles/notification.scss";
import { useDispatch, useSelector } from "react-redux";
import { userCompanyChooseSelector } from "../../../User/selectors/UserSelectors";
import { UserCompanyType } from "../../../User/configs/DeclareTypes";
import {
  ROLE_BUSINESS_ADMIN_ID,
  ROLE_BUSINESS_USER_ID,
} from "../../../User/configs/Constants";
import BusinessAdminFreeCredit from "./BusinessAdminFreeCredit";
import BusinessUserCreditRemain from "./BusinessUserCreditRemain";
import BusinessAdminPaidTrial from "./BusinessAdminPaidTrail";
import {
  ONBOARD_PROMOTION_FREE_TRAIL,
  ONBOARD_PROMOTION_PAID_TRAIL,
} from "../../../Configuration/configs/Constants";
import { onboardPromotionInfoThunk } from "../../thunks/AppThunk";
import { onboardPromotionInfoSelector } from "../../selectors/AppSelectors";

const NotificationBelowNav = () => {
  const dispatch = useDispatch();
  const userCompanyChoose: UserCompanyType = useSelector(
    userCompanyChooseSelector
  );

  const onboardPromotionInfo = useSelector(onboardPromotionInfoSelector);

  useEffect(() => {
    if (
      userCompanyChoose?.role_id !== ROLE_BUSINESS_ADMIN_ID ||
      userCompanyChoose?.is_partner
    ) {
      return;
    }

    dispatch<any>(onboardPromotionInfoThunk());
  }, [userCompanyChoose]);

  if (userCompanyChoose?.role_id === ROLE_BUSINESS_USER_ID) {
    return <BusinessUserCreditRemain />;
  }

  if (
    userCompanyChoose?.role_id === ROLE_BUSINESS_ADMIN_ID &&
    onboardPromotionInfo?.type === ONBOARD_PROMOTION_FREE_TRAIL
  ) {
    return <BusinessAdminFreeCredit />;
  }

  if (
    userCompanyChoose?.role_id === ROLE_BUSINESS_ADMIN_ID &&
    onboardPromotionInfo?.type === ONBOARD_PROMOTION_PAID_TRAIL
  ) {
    return <BusinessAdminPaidTrial />;
  }

  return null;
};

export default NotificationBelowNav;
