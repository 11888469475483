import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "../../locales/Translation";
import classNames from "classnames";
import { PUBLIC_URL } from "../../modules/App/configs/Constants";
import ButtonGoBack from "../ButtonGoBack";

type Props = {
  isShowBack?: boolean;
  backRoute?: string;
  children: JSX.Element | JSX.Element[];
  className?: string;
};

const SectionWhiteBox = ({
  isShowBack,
  backRoute,
  children,
  className,
}: Props) => {
  const { translations } = useContext(LocalizationContext);
  const navigate = useNavigate();

  return (
    <>
      {isShowBack && backRoute ? (
        <ButtonGoBack goBack route={backRoute} />
      ) : isShowBack ? (
        <div onClick={() => navigate(-1)} className={"section-go-back-arrow"}>
          <img
            src={PUBLIC_URL + "/assets/icons/arrow-left.png"}
            alt="deskimo-logo"
          />{" "}
          {translations.back}
        </div>
      ) : null}
      <div className={classNames("section-white-box", className)}>
        {children}
      </div>
    </>
  );
};

export default SectionWhiteBox;
