import {
  PROPERTY_LIST_ACTION,
  MEETING_ROOM_LIST_ACTION,
  PROPERTY_DETAIL_ACTION,
  MODAL_MEETING_ROOM_UPDATE_ACTION,
  MEETING_ROOM_DETAIL_ACTION,
  UPDATE_MEETING_ROOM_ACTION,
  MODAL_MEETING_ROOM_CREATE_ACTION,
  MODAL_ADD_STAFF_ACTION,
  USER_REVIEW_ACTION,
  DESKIMO_PROPERTY_LIST_ACTION,
  DESKIMO_PROPERTY_COMPANY_LIST_ACTION,
  DESKIMO_PROPERTY_STAFF_LIST_ACTION,
  DESKIMO_PROPERTY_LIST_FILTER_ACTION,
  DESKIMO_PROPERTY_COMPANY_LIST_FILTER_ACTION,
  DESKIMO_PROPERTY_STAFF_LIST_FILTER_ACTION,
  DESKIMO_PROPERTY_LIST_FILTER_RESET_ACTION,
  DESKIMO_PROPERTY_COMPANY_LIST_FILTER_RESET_ACTION,
  DESKIMO_PROPERTY_STAFF_LIST_FILTER_RESET_ACTION,
  MODAL_MANAGE_PMS_ACTION,
  MODAL_MANAGE_PMS_ADD_ROOM_ACTION,
  MODAL_MANAGE_PMS_ADD_CALENDAR_ACTION,
  MANAGE_PMS_ROOM_LIST_ACTION,
  UPDATE_MANAGE_PMS_AUTO_CONFIRM_ACTION,
  MANAGE_PMS_ADD_CALENDAR_ACTION,
  MODAL_DESKIMO_WORKSPACE_STAFF_LIST,
  MANAGE_PMS_DELETE_ROOM_ACTION,
  MODAL_MEETING_ROOM_ASSIGN_ACTION,
  MEETING_ROOM_ASSIGN_ACTION,
  DESKIMO_PROPERTY_STAFF_DELETE_ACTION,
  DESKIMO_PROPERTY_STAFF_CHANGE_STATUS_ACTION,
  DESKIMO_PROPERTY_AMENITY_LIST_ACTION,
  MODAL_ADD_EDIT_AMENITY_ACTION,
  DESKIMO_PROPERTY_AMENITY_DELETE_ACTION,
  DESKIMO_PROPERTY_AMENITY_DETAIL_ACTION,
  DESKIMO_PROPERTY_COMPANY_DELETE_ACTION,
  DESKIMO_PROPERTY_SCANNER_LIST_ACTION,
  MODAL_PROPERTY_SCANNER_QR_CODE_ACTION,
  DESKIMO_PROPERTY_SCANNER_QR_CODE_ASSIGN_ACTION,
  DESKIMO_PROPERTY_SCANNER_DETAIL_ACTION,
  DESKIMO_PROPERTY_SCANNER_CHANGE_STATUS_ACTION,
  GET_PROPERTY_CATEGORIES_ACTION,
  GET_QRCODE_LIST_ACTION,
  GET_API_LIST_ACTION,
  GET_WEBHOOK_LIST_ACTION,
  DESKIMO_PRIVATE_OFFICE_MODAL_ACTION,
  MODAL_CREATE_STAFF_ACTION,
  DESKIMO_PROPERTY_LIST_FILTER_APPLY_ALL_ACTION,
  DESKIMO_PROPERTY_COMPANY_LIST_FILTER_APPLY_ALL_ACTION,
  DESKIMO_PROPERTY_STAFF_LIST_FILTER_APPLY_ALL_ACTION,
  DESKIMO_PROPERTY_SCANNER_FILTER_ACTION,
  GET_PARTNER_LOG_ACTION,
  GET_DESKIMO_USER_REVIEWS_ACTION,
  GET_PRIVATE_OFFICE_LIST_ACTION,
  FILTER_PRIVATE_OFFICE_LIST_ACTION,
  EXPORT_PRIVATE_OFFICE_LIST_ACTION,
  FILTER_TMP_PRIVATE_OFFICE_LIST_ACTION,
  MODAL_REWARD_ACTION,
  REWARD_LIST_ACTION,
} from "../configs/Actions";
import {
  DeskimoPropertyCompanyListFilterType,
  DeskimoPropertyListFilterType,
  DeskimoPropertyStaffListFilterType,
} from "../configs/DeclareType";
import { CREATE_TYPE, EDIT_TYPE } from "../configs/Constants";

export const deskimoPropertyListAction = (data: object | null) => {
  return {
    type: DESKIMO_PROPERTY_LIST_ACTION,
    payload: data,
  };
};

export const deskimoPropertyCompanyListAction = (data: object | null) => {
  return {
    type: DESKIMO_PROPERTY_COMPANY_LIST_ACTION,
    payload: data,
  };
};

export const deskimoPropertyCompanyDeleteAction = (companyId: string) => {
  return {
    type: DESKIMO_PROPERTY_COMPANY_DELETE_ACTION,
    payload: companyId,
  };
};
export const deskimoPropertyStaffListAction = (data: object | null) => {
  return {
    type: DESKIMO_PROPERTY_STAFF_LIST_ACTION,
    payload: data,
  };
};

export const deskimoPropertyListFilterAction = (data: {
  key: DeskimoPropertyListFilterType;
  value: string | number[] | string[];
}) => {
  return {
    type: DESKIMO_PROPERTY_LIST_FILTER_ACTION,
    payload: data,
  };
};

export const deskimoPropertyListFilterApplyAllAction = (data: object) => {
  return {
    type: DESKIMO_PROPERTY_LIST_FILTER_APPLY_ALL_ACTION,
    payload: data,
  };
};

export const deskimoPropertyCompanyListFilterAction = (data: {
  key: DeskimoPropertyCompanyListFilterType;
  value: string | number[] | string[];
}) => {
  return {
    type: DESKIMO_PROPERTY_COMPANY_LIST_FILTER_ACTION,
    payload: data,
  };
};

export const deskimoPropertyCompanyListFilterApplyAllAction = (
  data: object
) => {
  return {
    type: DESKIMO_PROPERTY_COMPANY_LIST_FILTER_APPLY_ALL_ACTION,
    payload: data,
  };
};

export const deskimoPropertyStaffListFilterAction = (data: {
  key: DeskimoPropertyStaffListFilterType;
  value: string | number | number[] | string[];
}) => {
  return {
    type: DESKIMO_PROPERTY_STAFF_LIST_FILTER_ACTION,
    payload: data,
  };
};

export const deskimoPropertyStaffListFilterApplyAllAction = (data: object) => {
  return {
    type: DESKIMO_PROPERTY_STAFF_LIST_FILTER_APPLY_ALL_ACTION,
    payload: data,
  };
};
export const propertyListAction = (data: object | null) => {
  return {
    type: PROPERTY_LIST_ACTION,
    payload: data,
  };
};

export const meetingRoomListAction = (data: object | null) => {
  return {
    type: MEETING_ROOM_LIST_ACTION,
    payload: data,
  };
};

export const propertyDetailAction = (data: object | null) => {
  return {
    type: PROPERTY_DETAIL_ACTION,
    payload: data,
  };
};

export const meetingRoomDetailAction = (data: object | null) => {
  return {
    type: MEETING_ROOM_DETAIL_ACTION,
    payload: data,
  };
};

export const modalMeetingRoomUpdateAction = (value: any) => {
  return {
    type: MODAL_MEETING_ROOM_UPDATE_ACTION,
    payload: value,
  };
};

export const updateMeetingRoomAction = (value: any) => {
  return {
    type: UPDATE_MEETING_ROOM_ACTION,
    payload: value,
  };
};

export const assignMeetingRoomAction = (value: any) => {
  return {
    type: MEETING_ROOM_ASSIGN_ACTION,
    payload: value,
  };
};

export const updateManagePMSAutoConfirmAction = (roomId: number) => {
  return {
    type: UPDATE_MANAGE_PMS_AUTO_CONFIRM_ACTION,
    payload: roomId,
  };
};

export const deleteManagePMSRoomAction = (roomId: number) => {
  return {
    type: MANAGE_PMS_DELETE_ROOM_ACTION,
    payload: roomId,
  };
};

export const modalManagePMSAction = (value: any) => {
  return {
    type: MODAL_MANAGE_PMS_ACTION,
    payload: value,
  };
};

export const managePMSRoomListAction = (value: any) => {
  return {
    type: MANAGE_PMS_ROOM_LIST_ACTION,
    payload: value,
  };
};

export const managePMSAddCalendarAction = (value: any) => {
  return {
    type: MANAGE_PMS_ADD_CALENDAR_ACTION,
    payload: value,
  };
};

export const modalManagePMSAddRoomAction = (value: any) => {
  return {
    type: MODAL_MANAGE_PMS_ADD_ROOM_ACTION,
    payload: value,
  };
};

export const modalManagePMSAddCalendarAction = (value: any) => {
  return {
    type: MODAL_MANAGE_PMS_ADD_CALENDAR_ACTION,
    payload: value,
  };
};

export const modalMeetingRoomAssignCalendarAction = (value: any) => {
  return {
    type: MODAL_MEETING_ROOM_ASSIGN_ACTION,
    payload: value,
  };
};

export const modalMeetingRoomCreateAction = (value: any) => {
  return {
    type: MODAL_MEETING_ROOM_CREATE_ACTION,
    payload: value,
  };
};

export const modalAddStaffAction = (value: any) => {
  return {
    type: MODAL_ADD_STAFF_ACTION,
    payload: value,
  };
};

export const modalDeskimoWorkspaceStaffListAction = (value: {
  open: boolean;
  id: number | null;
  companyId?: number | null;
}) => {
  return {
    type: MODAL_DESKIMO_WORKSPACE_STAFF_LIST,
    payload: value,
  };
};

export const userReviewAction = (data: object | null) => {
  return {
    type: USER_REVIEW_ACTION,
    payload: data,
  };
};

export const deskimoPropertyListFilterResetAction = () => {
  return {
    type: DESKIMO_PROPERTY_LIST_FILTER_RESET_ACTION,
  };
};

export const deskimoPropertyCompanyListFilterResetAction = () => {
  return {
    type: DESKIMO_PROPERTY_COMPANY_LIST_FILTER_RESET_ACTION,
  };
};

export const deskimoPropertyStaffListFilterResetAction = () => {
  return {
    type: DESKIMO_PROPERTY_STAFF_LIST_FILTER_RESET_ACTION,
  };
};

export const deskimoPropertyStaffDeleteAction = (userId: number) => {
  return {
    type: DESKIMO_PROPERTY_STAFF_DELETE_ACTION,
    payload: userId,
  };
};

export const deskimoPropertyStaffChangeStatusAction = (userId: number) => {
  return {
    type: DESKIMO_PROPERTY_STAFF_CHANGE_STATUS_ACTION,
    payload: userId,
  };
};

export const deskimoPropertyAmenityListAction = (data: object | null) => {
  return {
    type: DESKIMO_PROPERTY_AMENITY_LIST_ACTION,
    payload: data,
  };
};

export const modalAddEditAmenityAction = (value: boolean) => {
  return {
    type: MODAL_ADD_EDIT_AMENITY_ACTION,
    payload: value,
  };
};

export const deskimoPropertyAmenityDetailAction = (data: object | null) => {
  return {
    type: DESKIMO_PROPERTY_AMENITY_DETAIL_ACTION,
    payload: data,
  };
};

export const deskimoPropertyAmenityDeleteAction = (amenityId: number) => {
  return {
    type: DESKIMO_PROPERTY_AMENITY_DELETE_ACTION,
    payload: amenityId,
  };
};

export const deskimoPropertyScannerListAction = (data: object) => {
  return {
    type: DESKIMO_PROPERTY_SCANNER_LIST_ACTION,
    payload: data,
  };
};

export const deskimoPropertyScannerFilterAction = (data: object) => {
  return {
    type: DESKIMO_PROPERTY_SCANNER_FILTER_ACTION,
    payload: data,
  };
};

export const deskimoPropertyScannerDetailAction = (
  data: string | undefined
) => {
  return {
    type: DESKIMO_PROPERTY_SCANNER_DETAIL_ACTION,
    payload: data,
  };
};

export const modalPropertyScannerQrCodeAction = (status: boolean) => {
  return {
    type: MODAL_PROPERTY_SCANNER_QR_CODE_ACTION,
    payload: status,
  };
};

export const assignQrCodePropertyScannerAction = (data: string) => {
  return {
    type: DESKIMO_PROPERTY_SCANNER_QR_CODE_ASSIGN_ACTION,
    payload: data,
  };
};

export const changeStatusPropertyScannerAction = (token: string) => {
  return {
    type: DESKIMO_PROPERTY_SCANNER_CHANGE_STATUS_ACTION,
    payload: token,
  };
};

export const getPropertyCategoriesAction = (data: object) => {
  return {
    type: GET_PROPERTY_CATEGORIES_ACTION,
    payload: data,
  };
};

export const getPropertyIntegrationsQRCodeListAction = (data: object) => {
  return {
    type: GET_QRCODE_LIST_ACTION,
    payload: data,
  };
};

export const getPropertyIntegrationsAPIListAction = (data: object) => {
  return {
    type: GET_API_LIST_ACTION,
    payload: data,
  };
};

export const getPropertyIntegrationsWebhookListAction = (data: object) => {
  return {
    type: GET_WEBHOOK_LIST_ACTION,
    payload: data,
  };
};

export const deskimoModalPrivateOfficeAction = (data: {
  isOpen: boolean;
  type?: typeof CREATE_TYPE | typeof EDIT_TYPE;
  privateOfficeId?: string;
  companyId?: string;
  propertyId?: string;
}) => {
  return {
    type: DESKIMO_PRIVATE_OFFICE_MODAL_ACTION,
    payload: data,
  };
};

export const modalCreatePropertyStaffAction = (data: object) => {
  return {
    type: MODAL_CREATE_STAFF_ACTION,
    payload: data,
  };
};

export const partnerLogAction = (data: object) => {
  return {
    type: GET_PARTNER_LOG_ACTION,
    payload: data,
  };
};

export const deskimoUserReviewsAction = (data: object) => {
  return {
    type: GET_DESKIMO_USER_REVIEWS_ACTION,
    payload: data,
  };
};

export const deskimoPrivateOfficeListAction = (data: object) => {
  return {
    type: GET_PRIVATE_OFFICE_LIST_ACTION,
    payload: data,
  };
};

export const deskimoPrivateOfficeFilterAction = (data: object) => {
  return {
    type: FILTER_PRIVATE_OFFICE_LIST_ACTION,
    payload: data,
  };
};

export const deskimoPrivateOfficeExportAction = (data: any) => {
  return {
    type: EXPORT_PRIVATE_OFFICE_LIST_ACTION,
    payload: data,
  };
};

export const deskimoPrivateOfficeFilterTmpAction = (data: object) => {
  return {
    type: FILTER_TMP_PRIVATE_OFFICE_LIST_ACTION,
    payload: data,
  };
};

export const modalRewardAction = (data: object) => {
  return {
    type: MODAL_REWARD_ACTION,
    payload: data,
  };
};

export const rewardListAction = (data: object) => {
  return {
    type: REWARD_LIST_ACTION,
    payload: data,
  };
};
