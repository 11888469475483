import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import { toastError, toastSuccess } from "../../../../components/Toast";
import { getRolePermissionThunk } from "../../thunks/ConfigThunks";
import { useDispatch, useSelector } from "react-redux";
import LoaderCustom from "../../../../components/Loader";
import { Table } from "semantic-ui-react";
import { rolePermissionSelector } from "../../selectors/ConfigSelectors";
import CheckboxCustom from "../../../../components/Checkbox";
import { saveRolePermissionService } from "../../services/ConfigServices";
import ButtonCustom from "../../../../components/Button";
import Search from "../MarketList/Components/Search";
import { LocalizationContext } from "../../../../locales/Translation";

const RolePermission = () => {
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const dispatch = useDispatch();
  const rolePermission = useSelector(rolePermissionSelector);
  const [updatedPermissions, setUpdatedPermissions] = useState<any>({});
  const [changedPermissions, setChangedPermissions] = useState({});
  const [keyword, setKeyword] = useState("");
  const { translations } = useContext(LocalizationContext);

  const getRolePermission = async () => {
    try {
      setLoading(true);
      await dispatch<any>(getRolePermissionThunk());
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRolePermission();
  }, []);

  useEffect(() => {
    if (rolePermission?.roles) {
      _initDataTable();
    }
  }, [rolePermission]);

  const _initDataTable = () => {
    let decoratedPermission: any = {};
    rolePermission?.permissions?.forEach((permission: any) => {
      const roleKeys: any = {};
      if (permission?.role_keys?.length > 0) {
        permission.role_keys.forEach((roleKeyItem: any) => {
          roleKeys[roleKeyItem] = true;
        });
      }

      decoratedPermission = {
        ...decoratedPermission,
        [permission?.key]: {
          ...roleKeys,
        },
      };
    });

    setUpdatedPermissions(decoratedPermission);
  };

  const handleCheckboxChange = (roleKey: any, permKey: any, checked: any) => {
    setUpdatedPermissions((prevState: any) => ({
      ...prevState,
      [permKey]: {
        ...prevState[permKey],
        [roleKey]: checked,
      },
    }));

    setChangedPermissions((prevState) => ({
      ...prevState,
      [permKey]: true,
    }));
  };

  const handleSave = async () => {
    const formattedPermissions = Object.keys(updatedPermissions).map(
      (permKey) => {
        const roleKeys = Object.keys(updatedPermissions[permKey])
          .filter((roleKey) => updatedPermissions[permKey][roleKey])
          .map(Number); // Assuming role keys are integers

        const permission = rolePermission?.permissions?.find(
          (p: any) => p.key === permKey
        );

        return {
          key: permission.key,
          name: permission.name,
          role_keys: roleKeys,
        };
      }
    );

    const payload = { permissions: formattedPermissions };

    try {
      setSubmitLoading(true);
      await saveRolePermissionService(payload);
      toastSuccess("Roles and Permissions updated successfully!");
      setChangedPermissions({});
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setSubmitLoading(false);
    }
  };

  if (!rolePermission?.roles) return <></>;

  return (
    <>
      <div
        className={"d-flex justify-content-between"}
        style={{ marginBottom: 16 }}
      >
        <Search
          placeholder={"Search by Permission"}
          onSearch={(value: any) => setKeyword(value)}
        />

        <ButtonCustom
          loading={submitLoading}
          disabled={submitLoading}
          height={"36px"}
          className={"primary"}
          onClick={handleSave}
        >
          <>
            Save permissions{" "}
            <i style={{ marginLeft: 4 }} className="uil uil-save"></i>
          </>
        </ButtonCustom>
      </div>
      <Table selectable striped className={"table-custom"}>
        <Table.Header style={{ position: "sticky", top: 53, zIndex: 1000 }}>
          <Table.Row>
            <th>Permission / Role</th>
            {rolePermission && rolePermission?.roles?.length > 0
              ? rolePermission?.roles?.map((role: any) => (
                  <th key={role?.key}>{role?.name}</th>
                ))
              : null}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {loading ? <LoaderCustom /> : null}
          {rolePermission && rolePermission?.permissions?.length > 0
            ? rolePermission?.permissions
                .filter((perm: any) =>
                  perm.name
                    .toLowerCase()
                    .trim()
                    .includes(keyword.toLowerCase().trim())
                )
                .map((permission: any) => (
                  <tr key={permission?.key}>
                    <td>{permission?.name}</td>
                    {rolePermission?.roles.map((role: any) => (
                      <td key={role?.key}>
                        <CheckboxCustom
                          onChange={(e: any, data: any) =>
                            handleCheckboxChange(
                              role?.key,
                              permission?.key,
                              data.checked
                            )
                          }
                          checked={
                            updatedPermissions[permission?.key]?.[role.key] ||
                            false
                          }
                        />
                      </td>
                    ))}
                  </tr>
                ))
            : null}

          {rolePermission?.permissions.filter((perm: any) =>
            perm.name
              .toLowerCase()
              .trim()
              .includes(keyword.toLowerCase().trim())
          ).length < 1 ? (
            <Table.Row>
              <Table.Cell colSpan={rolePermission?.roles?.length}>
                <p className={"text-center"}>{translations.noDataAvailable}</p>
              </Table.Cell>
            </Table.Row>
          ) : null}
        </Table.Body>
      </Table>
    </>
  );
};

export default RolePermission;
