import { debounce } from "lodash";
import DropdownCustom from "../DropdownCustom";
import React, { useEffect } from "react";
import "./styles.scss";
import { Dropdown } from "semantic-ui-react";

const DropdownSearchAndPaging = ({
  onOpen,
  onChange,
  options,
  value,
  onSearchChange,
  fetchNextPage,
  listCompanyRaw,
  ...props
}: any) => {
  const handleScroll = debounce(() => {
    const menu = document.querySelector(".dropdown-search-paging .menu");
    if (menu) {
      const { scrollTop, scrollHeight, clientHeight } = menu;
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        fetchNextPage();
      }
    }
  }, 300);

  useEffect(() => {
    const menu = document.querySelector(".dropdown-search-paging .menu");
    if (menu) {
      menu.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (menu) {
        menu.removeEventListener("scroll", handleScroll);
      }
    };
  }, [listCompanyRaw]);

  return (
    <Dropdown
      {...props}
      onOpen={onOpen}
      search
      className={"dropdown-search-paging select-ui dropdown-custom"}
      onChange={onChange}
      onSearchChange={debounce((e, data) => {
        onSearchChange(e, data);
      }, 300)}
      selectUI={true}
      options={options}
      value={value}
      clearable={true}
    />
  );
};

export default DropdownSearchAndPaging;
