import clientRequestService from "../../../services/ClientRequestService";
import {
  METHOD_DELETE,
  METHOD_GET,
  METHOD_POST,
} from "../../../configs/Constants";
import {
  GET_ON_GOING_API,
  POST_BOOKING_COMPLETED_API,
  POST_CANCEL_DESK_API,
  POST_CANCEL_ROOM_API,
  POST_DESK_BOOKING_LIST_API,
  POST_ON_GOING_API,
  POST_ROOM_BOOKING_LIST_API,
} from "../configs/Endpoints";

export const getRoomBookingListService = async (payload: any) => {
  return clientRequestService({
    method: METHOD_POST,
    url: POST_ROOM_BOOKING_LIST_API,
    data: payload,
    isCompany: true,
  });
};

export const getDeskBookingListService = async (payload: any) => {
  return clientRequestService({
    method: METHOD_POST,
    url: POST_DESK_BOOKING_LIST_API,
    data: payload,
    isCompany: true,
  });
};

export const requestCancelRoomBookingService = async (bookingId: any) => {
  return clientRequestService({
    method: METHOD_POST,
    url: POST_CANCEL_ROOM_API,
    data: {
      booking_id: bookingId,
    },
  });
};

export const requestCancelDeskBookingService = async (bookingId: any) => {
  return clientRequestService({
    method: METHOD_POST,
    url: POST_CANCEL_DESK_API,
    data: {
      booking_id: bookingId,
    },
  });
};

export const ongoingDataService = async () => {
  return clientRequestService({
    method: METHOD_GET,
    url: GET_ON_GOING_API,
  });
};

export const getGuestService = async (ongoingId: any) => {
  return clientRequestService({
    method: METHOD_POST,
    url: POST_ON_GOING_API.replace("%ongoingId%", ongoingId),
  });
};

export const getBookingCompletedService = async (data: object) => {
  return clientRequestService({
    method: METHOD_POST,
    url: POST_BOOKING_COMPLETED_API,
    data,
  });
};
