import SizeBox from "../../../../../../../../components/SizeBox";
import Label from "../../../../../../../../components/Label";
import Map from "../../../../../../../../components/Map";
import React from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import axios from "axios";
import { API_GET_ADDRESS_BY_LAT_LNG } from "../../../../../../configs/Endpoints";

const MapAutocompleted = ({ address, setAddress, geo, setGeo }: any) => {
  const { ref }: any = usePlacesWidget({
    onPlaceSelected: (place) => {
      onPlaceChanged(place);
    },
    options: {
      types: ["establishment"],
      fields: ["place_id", "geometry", "name", "address_components"],
    },
  });

  const onPlaceChanged = async (place: any) => {
    if (!place.geometry) {
      return;
    }

    if (!place.address_components || place.address_components.length < 1) {
      return;
    }

    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    setGeo({ lat, lng });

    let countryCode = "";
    let countryName = "";

    for (let i = 0; i < place.address_components.length; i += 1) {
      const addressObj = place.address_components[i];
      for (let j = 0; j < addressObj.types.length; j += 1) {
        if (addressObj.types[j] === "country") {
          /*outputs result if it is country*/
          if (!countryCode && addressObj.short_name) {
            countryCode = addressObj.short_name;
            countryName = addressObj.long_name;
          }
        }
      }
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setAddress(ref?.current?.value);
  };

  const _markerOnDragEnd = async (e: any) => {
    try {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      axios.get(API_GET_ADDRESS_BY_LAT_LNG({ lat, lng })).then((response) => {
        const results = response?.data?.results;
        const address = results[0].formatted_address;
        setAddress(address);
        setGeo({
          lat,
          lng,
        });
      });
    } catch (e) {
      console.log("error: ", e);
    }
  };

  return (
    <>
      <div className="input-component">
        <div className={"input-wrapper"} style={{ height: 44 }}>
          <div className={"ui input"} style={{ height: "100%" }}>
            <input
              placeholder={"Type office address"}
              ref={ref}
              value={address}
              onChange={({ target }) => {
                setAddress(target.value);
              }}
            />
          </div>
        </div>
      </div>
      <Label fontSize={"12px"} label={"Map"} className={"sm"} />
      <Map
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        lat={parseFloat(geo?.lat)}
        lng={parseFloat(geo?.lng)}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div className={"gg-map"} />}
        mapElement={<div style={{ height: `100%` }} />}
        draggable
        onDragEnd={_markerOnDragEnd}
      />
    </>
  );
};

export default MapAutocompleted;
