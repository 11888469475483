import ToggleSlider from "../../../../../../components/ToggleSlider";
import { useDispatch, useSelector } from "react-redux";
import { deskimoPrivateOfficeFiltersTmpSelector } from "../../../../selectors/PropertySelector";
import { deskimoPrivateOfficeFilterTmpAction } from "../../../../actions/PropertyActions";

const Status = () => {
  const dispatch = useDispatch();
  const filtersTmp = useSelector(deskimoPrivateOfficeFiltersTmpSelector);

  const onChange = (checked: any) => {
    dispatch(
      deskimoPrivateOfficeFilterTmpAction({
        ...filtersTmp,
        filter: { ...filtersTmp.filter, status: checked ? [1] : [0] },
      })
    );
  };

  return (
    <ToggleSlider
      checked={filtersTmp?.filter?.status > 0}
      onChange={(e: any, data: any) => onChange(data.checked)}
      label={"Published"}
    />
  );
};
export default Status;
