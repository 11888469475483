import React, { useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActivityPropertySelector } from "../../../../selectors/DashboardSelectors";
import { dashboardActivityLogThunk } from "../../../../thunks/DashboardThunks";
import { LocalizationContext } from "../../../../../../locales/Translation";

const ActivityLog = () => {
  const { translations } = useContext(LocalizationContext);

  const dispatch = useDispatch();
  const listInnerRef = useRef(null);

  const dashboardActivityProperty:
    | { id: number; message: string; created_date: string }[]
    | [] = useSelector(dashboardActivityPropertySelector);

  const _onLoadMore = async () => {
    try {
      await dispatch<any>(
        dashboardActivityLogThunk(
          dashboardActivityProperty[dashboardActivityProperty.length - 1].id
        )
      );
    } catch (e) {
      console.log(e);
    }
  };

  const onScroll = async () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        await _onLoadMore();
      }
    }
  };

  return (
    <div className={"dashboard_chart_box_wrapper_3"}>
      <div className={"dashboard_chart_box"}>
        <h2 className={"dashboard_chart_sub_title_grey"}>
          {translations.activity}
        </h2>
        <div
          className={"dashboard_activity_log"}
          onScroll={onScroll}
          ref={listInnerRef}
        >
          {dashboardActivityProperty?.length ? (
            dashboardActivityProperty.map((item, index) => {
              return (
                <div
                  key={"ActivityLog" + index}
                  className={"dashboard_activity_log_item"}
                >
                  <span>
                    {item.created_date}: {item.message}
                  </span>
                </div>
              );
            })
          ) : (
            <p className="text-center">{translations.noDataAvailable}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActivityLog;
