import {
  affiliateListService,
  createPaymentMethodService,
  getBillingContactService,
  getBillingInfoService,
  getInvoiceDetailService,
  getListPropertiesService,
  getMonthlyUsageService,
  getPaymentHistoryService,
  getPaymentMethodService,
  getPropertyInvoicesService,
  getSettingService,
} from "../services/SettingServices";
import {
  affiliateListAction,
  getBillingContactAction,
  getBillingInfoAction,
  getInvoiceDetailAction,
  getMonthlyUsageAction,
  getPaymentHistoryAction,
  getPaymentMethodAction,
  getPropertiesAction,
  getSettingAction,
} from "../actions/BiilingActions";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";

type getPaymentHistoryProps = {
  statuses: [];
  page: number;
  order_by?: string;
  sort_by?: string;
};
export const getPaymentHistoryThunk = (payload: getPaymentHistoryProps) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await getPaymentHistoryService(payload);
    dispatch<any>(
      getPaymentHistoryAction({
        data: response?.data,
      })
    );
  };
};

export const getPaymentHistoryPropertyThunk = (data: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await getPropertyInvoicesService(data);
    dispatch<any>(
      getPaymentHistoryAction({
        data: response?.data,
      })
    );
  };
};

export const getInvoiceDetailThunk = (token: string) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await getInvoiceDetailService(token);
    dispatch<any>(getInvoiceDetailAction(response?.data));
  };
};

export const getMonthlyUsageThunk = () => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await getMonthlyUsageService();
    dispatch<any>(getMonthlyUsageAction(response?.data));
  };
};

export const getPaymentMethodThunk = () => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await getPaymentMethodService();
    dispatch<any>(getPaymentMethodAction(response?.data));
  };
};

export const getBillingContactThunk = () => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await getBillingContactService();
    dispatch<any>(getBillingContactAction(response?.data));
  };
};

export const getBillingInfoThunk = () => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await getBillingInfoService();
    dispatch<any>(getBillingInfoAction(response?.data));
  };
};

export const getSettingThunk = () => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await getSettingService();
    dispatch<any>(getSettingAction(response?.data));
  };
};

export const getPropertiesThunk = () => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await getListPropertiesService();
    dispatch<any>(getPropertiesAction(response?.data));
  };
};

export const affiliateListThunk = (data: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await affiliateListService(data);
    dispatch<any>(affiliateListAction(response?.data));
  };
};
