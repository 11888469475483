import ModalRightSideBarTemplate from "../../../../../App/views/ModalRightSideBarTemplate";
import { useDispatch, useSelector } from "react-redux";
import { modalBusinessCompanyCustomPackageCreateEditAction } from "../../../../actions/BusinessActions";
import { DropdownItemProps, Modal, TextArea } from "semantic-ui-react";
import ButtonCustom from "../../../../../../components/Button";
import {
  PUBLIC_URL,
  TIMEZONE_SINGAPORE,
} from "../../../../../App/configs/Constants";
import { Formik, FormikValues } from "formik";
import * as yup from "yup";
import Label from "../../../../../../components/Label";
import InputCustom from "../../../../../../components/Input";
import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { FormikSetFieldValueType } from "../../../../configs/DeclareTypes";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import moment from "moment-timezone";
import { getSettingService } from "../../../../../App/services/AppServices";
import "./styles.scss";
import {
  businessCompanyPackageCreateService,
  businessCompanyPackageDetailService,
} from "../../../../services/BusinessServices";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { businessCompanyCustomPackageThunk } from "../../../../thunks/BusinessThunks";
import { isModalBusinessCompanyCustomPackageSelector } from "../../../../selectors/BusinessSelectors";
import DropdownCompany from "./DropdownCompnay";
import DatePickerSingle from "../../../../../../components/DatePickerSingle";
import { VOUCHER_REDEEM_ON_BOARDING_TYPE } from "../../../../../Marketing/configs/Constants";

const CUSTOM_PACKAGE_MODE = 1;
const EXPENSE_PACKAGE_MODE = 2;

const ModalCreatePackage = ({ packageType, filter, editId }: any) => {
  const { translations } = useContext(LocalizationContext);
  const [currencyOptions, setCurrencyOptions] = useState<DropdownItemProps[]>(
    []
  );
  const dispatch = useDispatch();

  const [editData, setEditData] = useState<any>(null);

  const isModalCreateEditPackage = useSelector(
    isModalBusinessCompanyCustomPackageSelector
  );
  const _getSetting = async () => {
    const { data } = await getSettingService();
    const currencyTemp: DropdownItemProps[] = [];
    data?.location.forEach(({ currency }: { currency: string }) => {
      currencyTemp.push({ id: currency, text: currency, value: currency });
    });
    setCurrencyOptions(currencyTemp);
  };

  const _getPackageDetail = async () => {
    if (!editId) {
      setEditData(null);
      return;
    }
    const { data } = await businessCompanyPackageDetailService(editId);
    setEditData(data);
  };

  useEffect(() => {
    (async () => {
      try {
        if (!isModalCreateEditPackage) {
          return;
        }

        await Promise.all([_getSetting(), _getPackageDetail()]);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [isModalCreateEditPackage]);

  const _onChangeCurrency = (
    newCurrency:
      | boolean
      | number
      | string
      | (boolean | number | string)[]
      | undefined,
    setFieldValue: FormikSetFieldValueType
  ) => {
    if (typeof newCurrency !== "string") {
      return;
    }
    setFieldValue("currency", newCurrency);
  };

  const _onCloseModal = () => {
    dispatch(modalBusinessCompanyCustomPackageCreateEditAction(false));
  };

  const _onSubmit = async (values: FormikValues) => {
    try {
      let payload = {
        ...values,
        package_type: packageType,
        amount: Number(values.amount),
      };

      if (editId) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        payload = { ...payload, id: editId };
      }

      await businessCompanyPackageCreateService(payload);

      await dispatch<any>(businessCompanyCustomPackageThunk(filter));
      dispatch(modalBusinessCompanyCustomPackageCreateEditAction(false));

      if (editId) {
        toastSuccess(translations.editSuccessPrompt);
      } else {
        toastSuccess(translations.createSuccessPrompt);
      }
    } catch (e) {
      if (editId) {
        toastError(translations.editFailPrompt);
      } else {
        toastError(translations.createFailPrompt);
      }
      console.error(e);
    }
  };

  return (
    <ModalRightSideBarTemplate
      modalClassName={"modal-right-sidebar-v2"}
      onClose={_onCloseModal}
      open={isModalCreateEditPackage}
    >
      <Modal.Header>
        {editId && packageType === CUSTOM_PACKAGE_MODE
          ? translations.customPackages.editCustomServicePackage
          : editId && packageType === EXPENSE_PACKAGE_MODE
          ? translations.customPackages.editExpenseServicePackage
          : packageType === CUSTOM_PACKAGE_MODE
          ? translations.customPackages.customServicePackageTitle
          : packageType === EXPENSE_PACKAGE_MODE
          ? translations.customPackages.expenseServicePackageTitle
          : null}
        <ButtonCustom
          onClick={_onCloseModal}
          className={"m-0 p-0 none-bg close-modal"}
        >
          <>
            {translations.close}
            <img
              alt="close_icon"
              src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"}
            />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Formik
        enableReinitialize
        initialValues={{
          package_name: editData?.package_name || "",
          company_id: editData?.company_id || "",
          company_name: editData?.company_name || "",
          amount: editData?.amount || "",
          currency: editData?.currency
            ? editData?.currency?.toUpperCase()
            : "SGD",
          description: editData?.description || "",
          discount_amount: editData?.discount_amount || "",
          recognition_date: editData?.recognition_date
            ? editData?.recognition_date
            : moment().tz(TIMEZONE_SINGAPORE).endOf("date").utc().unix(),
        }}
        validationSchema={yup.object().shape(
          packageType === CUSTOM_PACKAGE_MODE
            ? {
                package_name: yup
                  .string()
                  .required()
                  .label(translations.customPackages.packageNameYupLabel),
                company_id: yup
                  .string()
                  .required()
                  .label(translations.customPackages.companyLabel),
                amount: yup
                  .number()
                  .required()
                  .nullable()
                  .label(translations.customPackages.amountLabel),
                discount_amount: yup.number().min(0).nullable(),
              }
            : {
                package_name: yup
                  .string()
                  .required()
                  .label(translations.customPackages.packageNameYupLabel),
                amount: yup
                  .number()
                  .required()
                  .nullable()
                  .label(translations.customPackages.amountLabel),
                discount_amount: yup.number().min(0).nullable(),
              }
        )}
        onSubmit={_onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          handleSubmit,
          handleReset,
          setFieldValue,
        }) => (
          <>
            <Modal.Content className={"scroll"}>
              <InputCustom
                labelRequired
                label={translations.customPackages.packageNameLabel}
                value={values.package_name}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.package_name}
                error={errors.package_name}
                name={"package_name"}
              />
              {packageType === CUSTOM_PACKAGE_MODE ? (
                <div className={"mb-17px"}>
                  <DropdownCompany
                    values={values}
                    setFieldValue={setFieldValue}
                    error={errors.company_id}
                    touched={touched.company_id}
                    disabled={isSubmitting}
                  />
                </div>
              ) : null}
              <div className={"d-flex"}>
                <InputCustom
                  containerClass={"w-100"}
                  label={translations.customPackages.amountLabel}
                  labelRequired
                  value={values.amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.amount}
                  error={errors.amount}
                  name={"amount"}
                  inputLeftIcon={values.currency}
                  inputLeftIconBorder
                  removeLetter
                  allowNegativeNumber
                />
                <InputCustom
                  containerClass={"w-100 ml-15px"}
                  label={translations.customPackages.discountLabel}
                  value={values.discount_amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.discount_amount}
                  error={errors.discount_amount}
                  name={"discount_amount"}
                  inputLeftIcon={"%"}
                  inputLeftIconBorder
                  removeLetter
                />
              </div>
              <div className={"d-flex justify-content-between mb-16px"}>
                <div className={"w-100"}>
                  <Label
                    label={translations.customPackages.currencyLabel}
                    required
                    fontSize={"12px"}
                    className={"sm"}
                  />
                  <DropdownCustom
                    selectUI
                    options={currencyOptions}
                    value={values.currency}
                    className={"currency-dropdown-input"}
                    onChange={(e, { value }) =>
                      _onChangeCurrency(value, setFieldValue)
                    }
                    disabled={isSubmitting}
                    scrolling
                  />
                </div>

                <div className={"w-100 ml-15px"}>
                  <Label
                    label={translations.customPackages.recognitionLabel}
                    required
                    fontSize={"12px"}
                    className={"sm"}
                  />
                  <div>
                    <DatePickerSingle
                      squareUI
                      oneTap
                      value={moment
                        .unix(values.recognition_date)
                        .tz(TIMEZONE_SINGAPORE)
                        .toDate()}
                      onChange={(value: any) => {
                        if (!value) {
                          return;
                        }

                        setFieldValue(
                          "recognition_date",
                          moment(value)
                            .tz(TIMEZONE_SINGAPORE)
                            .endOf("date")
                            .utc()
                            .unix()
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <Label
                fontSize={"12px"}
                className={"sm"}
                label={
                  translations.customPackages.descriptionLabelAndPlaceholder
                }
              />
              <TextArea
                className={"text-area"}
                placeholder={
                  translations.customPackages.descriptionLabelAndPlaceholder
                }
                rows={4}
                value={values.description}
                error={errors.description}
                touched={touched.description}
                name={"description"}
                onChange={handleChange}
                disabled={isSubmitting}
              />
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom height={"47px"} onClick={handleReset}>
                {translations.cancel}
              </ButtonCustom>
              <ButtonCustom
                type={"submit"}
                loading={isSubmitting}
                className={"primary"}
                height={"47px"}
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                {translations.save}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalRightSideBarTemplate>
  );
};

export default ModalCreatePackage;
