import classNames from "classnames";
import { headerPopupToppingUpAction } from "../../../../App/actions/AppActions";
import { Modal } from "semantic-ui-react";
import ModalCenterTemplate from "../../../../App/views/ModalCenterTemplate";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalCheckinSelector } from "../../../selectors/BookSelectors";
import { modalCheckinAction } from "../../../actions/BookActions";
import { LocalizationContext } from "../../../../../locales/Translation";
import "./style.scss";
import { PUBLIC_URL } from "../../../../App/configs/Constants";

export default function ModalCheckin() {
  const dispatch = useDispatch();
  const modalCheckin = useSelector(modalCheckinSelector);
  const { translations } = useContext(LocalizationContext);

  const _onClose = () => {
    dispatch(modalCheckinAction({ open: false }));
  };

  return (
    <ModalCenterTemplate
      trigger={null}
      onClose={_onClose}
      modalClassName={classNames("modal-scan-qrcode")}
      onOpen={() => {
        dispatch(headerPopupToppingUpAction(false));
      }}
      onMount={() => {
        dispatch(headerPopupToppingUpAction(false));
      }}
      open={modalCheckin.open}
    >
      <Modal.Content>
        <div className={"modal-body"}>
          <button
            onClick={() => _onClose()}
            className="close"
            style={{ background: "none" }}
          >
            <svg
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="0.5" width="32" height="32" rx="16" fill="white" />
              <path
                d="M17.9119 15.9999L24.2119 9.70994C24.4002 9.52164 24.506 9.26624 24.506 8.99994C24.506 8.73364 24.4002 8.47825 24.2119 8.28994C24.0236 8.10164 23.7682 7.99585 23.5019 7.99585C23.2356 7.99585 22.9802 8.10164 22.7919 8.28994L16.5019 14.5899L10.2119 8.28994C10.0236 8.10164 9.76819 7.99585 9.50189 7.99585C9.23559 7.99585 8.9802 8.10164 8.79189 8.28994C8.60359 8.47825 8.4978 8.73364 8.4978 8.99994C8.4978 9.26624 8.60359 9.52164 8.79189 9.70994L15.0919 15.9999L8.79189 22.2899C8.69816 22.3829 8.62377 22.4935 8.573 22.6154C8.52223 22.7372 8.49609 22.8679 8.49609 22.9999C8.49609 23.132 8.52223 23.2627 8.573 23.3845C8.62377 23.5064 8.69816 23.617 8.79189 23.7099C8.88486 23.8037 8.99546 23.8781 9.11732 23.9288C9.23917 23.9796 9.36988 24.0057 9.50189 24.0057C9.6339 24.0057 9.76461 23.9796 9.88647 23.9288C10.0083 23.8781 10.1189 23.8037 10.2119 23.7099L16.5019 17.4099L22.7919 23.7099C22.8849 23.8037 22.9955 23.8781 23.1173 23.9288C23.2392 23.9796 23.3699 24.0057 23.5019 24.0057C23.6339 24.0057 23.7646 23.9796 23.8865 23.9288C24.0083 23.8781 24.1189 23.8037 24.2119 23.7099C24.3056 23.617 24.38 23.5064 24.4308 23.3845C24.4816 23.2627 24.5077 23.132 24.5077 22.9999C24.5077 22.8679 24.4816 22.7372 24.4308 22.6154C24.38 22.4935 24.3056 22.3829 24.2119 22.2899L17.9119 15.9999Z"
                fill="black"
              />
            </svg>
          </button>
          <div className="modal-scan-qrcode-content">
            <div className="left-side">
              <div className="modal-scan-qrcode-top">
                <div className="d-flex flex-column gap-16">
                  <div className="sub-title">Instant Access</div>
                  <div className="title">
                    Download the <br />
                    Deskimo App
                  </div>
                </div>
                <div className="desc">
                  <p>
                    With our mobile app you can access hundreds of workspaces
                    across Asia and the Middle East without the need for booking
                    in advance.
                  </p>
                  <p>
                    Simply check in with the app at the entrance and enter any
                    workspace.{" "}
                  </p>

                  <p>Pay by the minute; never more than a day pass.</p>
                </div>
              </div>
              <div className="modal-scan-qrcode-bottom">
                <div className={"modal-scan-qr-code-mobile"}>
                  <a
                    target={"_blank"}
                    href="https://deskimo.me/app"
                    rel="noreferrer"
                  >
                    <img src={PUBLIC_URL + "/assets/images/ios-download.png"} />
                  </a>
                  <a
                    target={"_blank"}
                    href="https://deskimo.me/app"
                    rel="noreferrer"
                  >
                    <img
                      src={PUBLIC_URL + "/assets/images/android-download.png"}
                    />
                  </a>
                </div>
                <div className="modal-scan-qrcode-image">
                  <img src={PUBLIC_URL + "/assets/images/qr-code.png"} />
                </div>
              </div>
            </div>
            <div className="right-side">
              <img src={PUBLIC_URL + "/assets/images/modal-qr-bg.png"} />
            </div>
          </div>
        </div>
      </Modal.Content>
    </ModalCenterTemplate>
  );
}
