import React, { useState, useEffect } from "react";
import TableCustom from "../../../../../../components/Table";
import RatingCell from "./RatingCell";
import { userReviewSelector } from "../../../../selectors/PropertySelector";
import { useSelector } from "react-redux";
import Action from "./Action";
import { useParams } from "react-router-dom";
import InterestingService from "./InterestingService";
import Filter from "./Filter";
import {
  ROLE_PROPERTY_ADMIN,
  ROLE_PROPERTY_STAFF,
} from "../../../../configs/Constants";
import { MyProfileType } from "../../../../../User/configs/DeclareTypes";
import { myProfileSelector } from "../../../../../App/selectors/AppSelectors";

const UserReviewTable = ({ filter, setFilter, loading }: any) => {
  const userReview = useSelector(userReviewSelector);
  const [tableData, setTableData] = useState([]);
  const { id } = useParams();
  const myProfile: MyProfileType = useSelector(myProfileSelector);
  const isPropertyUser =
    myProfile?.role_id === ROLE_PROPERTY_ADMIN ||
    myProfile?.role_id === ROLE_PROPERTY_STAFF;

  useEffect(() => {
    decoratedData();
  }, [userReview]);

  const decoratedData = () => {
    if (userReview?.reviews?.length > 0) {
      setTableData(
        userReview?.reviews?.map((data: any) => {
          if (isPropertyUser) {
            return {
              col1: <div className={"text-nowrap"}>{data?.date}</div>,
              col2: <RatingCell stars={data?.rating} />,
              col3: <div>{data?.comments}</div>,
              col4: (
                <InterestingService
                  comment={data?.extra_comment}
                  services={data?.good_services}
                />
              ),
            };
          }
          return {
            col1: <div className={"text-nowrap"}>{data?.date}</div>,
            col2: <RatingCell stars={data?.rating} />,
            col3: <div>{data?.comments}</div>,
            col4: (
              <InterestingService
                comment={data?.extra_comment}
                services={data?.good_services}
              />
            ),
            col5: (
              <Action
                initialValue={data?.status}
                reviewId={data?.id}
                propertyId={id}
              />
            ),
          };
        })
      );
    } else {
      setTableData([]);
    }
  };

  const columns = [
    <div key={"Date"}>Date</div>,
    <div key={"Rating"}>Rating</div>,
    <div key={"Comments"}>Comments</div>,
    <div key={"Services"}>Outstanding Service</div>,
  ];

  if (!isPropertyUser) {
    columns.push(<div key={"Actions"}>Display/Hide Review</div>);
  }

  return (
    <div>
      <TableCustom
        tableLayoutFixed
        tableClass={"user-review-table"}
        tableHeaderClass={"user-review-table__header"}
        showTotal
        tableData={tableData}
        columns={columns}
        currentPage={filter.page}
        totalItems={userReview?.pagination?.total}
        unit={"reviews"}
        onChangePagination={(e: any, { activePage }: any) => {
          setFilter((prevState: any) => ({ ...prevState, page: activePage }));
        }}
        topRightComponent={<Filter filter={filter} setFilter={setFilter} />}
        loading={loading}
      />
    </div>
  );
};

export default UserReviewTable;
