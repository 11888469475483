import { Modal } from "semantic-ui-react";
import React, { useContext, useState } from "react";
import ButtonCustom from "../../../../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import ModalRightSideBarTemplate from "../../../../../App/views/ModalRightSideBarTemplate";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { Formik, FormikValues } from "formik";
import * as yup from "yup";
import Label from "../../../../../../components/Label";
import InputCustom from "../../../../../../components/Input";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { LocalizationContext } from "../../../../../../locales/Translation";
import {
  deskimoPropertyAmenityDetailSelector,
  isModalAddEditAmenitySelector,
} from "../../../../selectors/PropertySelector";
import { modalAddEditAmenityAction } from "../../../../actions/PropertyActions";
import BoxToggleSlider from "../../../../../../components/BoxToggleSlider";
import "./style.scss";
import { propertyAmenityAddEditService } from "../../../../services/PropertyAmenityServices";
import DropzoneIcon from "./DropzoneIcon";
import CheckboxCustom from "../../../../../../components/Checkbox";
import SizeBox from "../../../../../../components/SizeBox";
import {
  AMENITY_DESK_TYPE,
  AMENITY_ROOM_TYPE,
} from "../../../../configs/Constants";
import { SWAL_DELETE_OPTION } from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";

const ModalEditAddAmenity = ({ filter, setRefreshing }: any) => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const isModalAddEditAmenity = useSelector(isModalAddEditAmenitySelector);
  const [loading, setLoading] = useState<boolean>(false);
  const amenityDetail = useSelector(deskimoPropertyAmenityDetailSelector);

  const _onCloseModal = () => {
    dispatch(modalAddEditAmenityAction(false));
  };

  const _handleSave = async (values: FormikValues) => {
    try {
      setLoading(true);
      const payload = { ...values };

      if (amenityDetail.id) {
        payload.id = amenityDetail.id;

        if (
          (amenityDetail?.desk_amenities &&
            !values.types.includes(AMENITY_DESK_TYPE)) ||
          (amenityDetail?.room_amenities &&
            !values.types.includes(AMENITY_ROOM_TYPE))
        ) {
          await _onConfirmSave(payload);
          return;
        }
      }

      await propertyAmenityAddEditService(payload);

      if (amenityDetail.id) {
        toastSuccess(translations.editSuccessPrompt);
      } else {
        toastSuccess(translations.createSuccessPrompt);
      }
      dispatch(modalAddEditAmenityAction(false));
      setRefreshing((prevState: boolean) => !prevState);
    } catch (e) {
      if (amenityDetail.id) {
        toastError(translations.editFailPrompt);
      } else {
        toastError(translations.createFailPrompt);
      }
    } finally {
      setLoading(false);
    }
  };

  const _onConfirmSave = async (payload: any) => {
    try {
      let title = "";

      if (
        !payload.types.includes(AMENITY_DESK_TYPE) &&
        amenityDetail?.desk_amenities &&
        !payload.types.includes(AMENITY_ROOM_TYPE) &&
        amenityDetail?.room_amenities
      ) {
        title = `${translations.theAmenityAssignedTo} ${amenityDetail?.desk_amenities} ${translations["desk(s)"]} ${translations.and} ${amenityDetail?.room_amenities} ${translations["room(s)"]}.
        ${translations.areYouSureWantToRemoveAssignThisAmenity}`;
      } else if (
        !payload.types.includes(AMENITY_DESK_TYPE) &&
        amenityDetail?.desk_amenities
      ) {
        title = `${translations.theAmenityAssignedTo} ${amenityDetail?.desk_amenities} ${translations["desk(s)"]}.
        ${translations.areYouSureWantToRemoveAssignThisAmenity}`;
      } else if (
        !payload.types.includes(AMENITY_ROOM_TYPE) &&
        amenityDetail?.room_amenities
      ) {
        title = `${translations.theAmenityAssignedTo} ${amenityDetail?.room_amenities} ${translations["room(s)"]}.
        ${translations.areYouSureWantToRemoveAssignThisAmenity}`;
      }

      const iconHtml = (
        <img
          src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
          className={"swal2-icon__error"}
          alt="error_icon"
        />
      );
      const confirmText = translations.confirm;
      const cancelText = translations.cancel;
      const confirmClass = "danger";
      const options = SWAL_DELETE_OPTION({
        title,
        iconHtml,
        confirmText,
        cancelText,
        confirmClass,
      });
      SweetAlertCommon({
        options,
        confirmCallback: async () => {
          try {
            await propertyAmenityAddEditService(payload);
            toastSuccess(translations.editSuccessPrompt);
            dispatch(modalAddEditAmenityAction(false));
            setRefreshing((prevState: boolean) => !prevState);
          } catch (e) {
            toastSuccess(translations.editFailPrompt);
          }
        },
        cancelCallback: () => null,
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ModalRightSideBarTemplate
      trigger={null}
      onClose={_onCloseModal}
      modalClassName={"modal-right-sidebar-v2 modal-amenity"}
      open={isModalAddEditAmenity}
    >
      <Modal.Header>
        {!amenityDetail.id
          ? translations.propertyAmenities.addAmenity
          : translations.propertyAmenities.editAmenity}
        <ButtonCustom
          onClick={_onCloseModal}
          className={"m-0 p-0 none-bg close-modal"}
        >
          <>
            {translations.close}
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Formik
        enableReinitialize
        initialValues={{
          name: amenityDetail?.name || "",
          icon_key: amenityDetail?.icon_key || undefined,
          image_url: amenityDetail?.image_url || undefined,
          is_searchable: amenityDetail?.is_searchable || 1,
          types: amenityDetail?.types || [],
        }}
        validationSchema={yup.object().shape({
          name: yup
            .string()
            .label(translations.propertyAmenities.nameRequiredLabel)
            .required(translations.propertyAmenities.nameRequiredPrompt),
          icon_key: yup
            .string()
            .required(translations.propertyAmenities.iconImageRequiredPrompt),
        })}
        onSubmit={_handleSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <>
            <Modal.Content className={"scroll"}>
              <InputCustom
                label={translations.propertyAmenities.amenityNameColumnHeader}
                value={values.name}
                error={errors.name}
                onChange={handleChange}
                touched={touched.name}
                name={"name"}
              />
              <DropzoneIcon
                setFieldValue={setFieldValue}
                values={values.icon_key}
                iconPreview={values.image_url}
                error={errors.icon_key}
                touched={touched.icon_key}
              />
              <Label label={"Type of Amenity"} fontSize={"12px"} />
              <SizeBox height={"16px"} />
              <div>
                <CheckboxCustom
                  label={"Hot Desks"}
                  checked={values.types.includes(AMENITY_DESK_TYPE)}
                  onChange={() => {
                    if (values.types.includes(AMENITY_DESK_TYPE)) {
                      setFieldValue(
                        "types",
                        values.types.filter(
                          (item: number) => item !== AMENITY_DESK_TYPE
                        )
                      );
                    } else {
                      setFieldValue("types", [
                        ...values.types,
                        AMENITY_DESK_TYPE,
                      ]);
                    }
                  }}
                />
                <CheckboxCustom
                  label={"Meeting Rooms"}
                  checked={values.types.includes(AMENITY_ROOM_TYPE)}
                  onChange={() => {
                    if (values.types.includes(AMENITY_ROOM_TYPE)) {
                      setFieldValue(
                        "types",
                        values.types.filter(
                          (item: number) => item !== AMENITY_ROOM_TYPE
                        )
                      );
                    } else {
                      setFieldValue("types", [
                        ...values.types,
                        AMENITY_ROOM_TYPE,
                      ]);
                    }
                  }}
                />
              </div>
              <SizeBox height={"48px"} />
              <BoxToggleSlider
                value={values.is_searchable}
                onChange={(e: any, data: any) =>
                  setFieldValue("is_searchable", data.checked)
                }
                content={
                  <Label
                    fontSize={"14px"}
                    size={"16px"}
                    label={translations.showAsFilter}
                  />
                }
              />
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom height={"47px"} onClick={_onCloseModal}>
                {translations.discard}
              </ButtonCustom>
              <ButtonCustom
                type={"submit"}
                loading={loading}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {amenityDetail.id
                  ? translations.saveChanges
                  : translations.create}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalRightSideBarTemplate>
  );
};

export default ModalEditAddAmenity;
