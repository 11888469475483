import React, { useContext } from "react";
import DayOperation from "../../../../../../../components/DayOperation";
import { Checkbox } from "semantic-ui-react";
import classnames from "classnames";
import ScheduleHoliday from "./ScheduleHoliday";
import Label from "../../../../../../../components/Label";
import BoxToggleSlider from "../../../../../../../components/BoxToggleSlider";
import SizeBox from "../../../../../../../components/SizeBox";
import { LocalizationContext } from "../../../../../../../locales/Translation";

type Props = {
  hourType?: number;
  isShowHoliday?: boolean;
  inheritOperation?: any;
  setInheritOperation?: any;
  hourGroupData: any;
  onChangeSchedule: any;
  closeOnHoliday?: boolean;
  setCloseOnHoliday?: any;
  isShowInherit?: boolean;
  isInherit?: boolean;
  onChangeInherit?: any;
};

const ScheduleList = ({
  hourType = 2,
  isShowHoliday = false,
  inheritOperation,
  setInheritOperation,
  hourGroupData,
  onChangeSchedule,
  closeOnHoliday,
  setCloseOnHoliday,
  isShowInherit,
  isInherit,
  onChangeInherit,
}: Props) => {
  const { translations } = useContext(LocalizationContext);

  const _onChangeInheritSchedule = () => {
    if (hourType === 1) {
      setInheritOperation((prevState: any) => {
        return { ...prevState, inheritOperation: !prevState?.inheritOperation };
      });
    } else if (hourType === 2) {
      setInheritOperation((prevState: any) => {
        return {
          ...prevState,
          inheritAirconOperation: !prevState?.inheritAirconOperation,
        };
      });
    }
  };

  return (
    <>
      <div className={"schedule-title"}>{hourGroupData?.typeName}</div>
      {isShowInherit && (
        <>
          <BoxToggleSlider
            value={isInherit}
            onChange={(e: any, data: any) => {
              onChangeInherit(data.checked);
            }}
            content={
              <Label
                fontSize={"14px"}
                size={"16px"}
                label={translations.useTheOperatingHours}
                className={"mb-0"}
              />
            }
          />
          <SizeBox height={"20px"} />
        </>
      )}
      <div className={"schedule-schedule-content"}>
        <div className={"schedule-wrapper"}>
          {inheritOperation && hourType === 2 ? (
            <div className={"inherit-schedule-toggle"}>
              <span>Use the office aircon hours</span>
              <Checkbox
                toggle={true}
                checked={
                  hourType === 2
                    ? inheritOperation?.inheritAirconOperation
                    : false
                }
                onChange={_onChangeInheritSchedule}
              />
            </div>
          ) : null}
          <div
            className={classnames("schedule-choose", {
              "d-none":
                hourType === 2 && inheritOperation?.inheritAirconOperation,
              "pt-0": !inheritOperation,
            })}
          >
            {hourGroupData?.week_days.map((day: any) => {
              return (
                <DayOperation
                  weekDay={day.week_day}
                  type={hourGroupData?.type}
                  onChangeSchedule={onChangeSchedule}
                  fromTime={day?.from_time}
                  toTime={day?.to_time}
                  key={day?.week_day_title + `-${hourGroupData.type}`}
                  label={day?.week_day_title}
                  openType={day?.open_type.toString()}
                  name={day?.week_day_title + `-${hourGroupData?.type}`}
                />
              );
            })}
          </div>

          {isShowHoliday && (
            <ScheduleHoliday
              closeOnHoliday={closeOnHoliday}
              setCloseOnHoliday={setCloseOnHoliday}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ScheduleList;
