import React, { useContext, useEffect, useState } from "react";
import { Tab } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { ROOM_BOOKING_LINK } from "../../../App/configs/ListRoutes";
import { LocalizationContext } from "../../../../locales/Translation";
import UpcomingTab from "./Components/UpcomingTab";
import CompletedTab from "./Components/CompletedTab";
import CancelledTab from "./Components/CancelledTab";
import { useDispatch, useSelector } from "react-redux";
import { getRoomBookingListThunk } from "../../thunks/BookingThunks";
import {
  refreshRoomBookingSelector,
  roomBookingPayloadSelector,
} from "../../selectors/BookingSelectors";
import { listRoomBookingPayloadAction } from "../../actions/BookingActions";
import { userCompanyChooseSelector } from "../../../User/selectors/UserSelectors";

const RoomBooking = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const roomBookingPayload = useSelector(roomBookingPayloadSelector);
  const [loading, setLoading] = useState(false);
  const refreshRoomBookingList = useSelector(refreshRoomBookingSelector);
  const userCompanyChoose = useSelector(userCompanyChooseSelector);

  useEffect(() => {
    _fetchListRoomBooking();
  }, [roomBookingPayload, refreshRoomBookingList, userCompanyChoose]);

  const _fetchListRoomBooking = async () => {
    setLoading(true);
    try {
      const payload = {
        status: roomBookingPayload.status + 1,
        page: roomBookingPayload.page,
      };
      await dispatch<any>(getRoomBookingListThunk(payload));
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const panes = [
    {
      menuItem: {
        as: Link,
        id: 1,
        to: ROOM_BOOKING_LINK,
        content: translations.upcoming,
        exact: true,
        key: 1,
      },
      render: () => <UpcomingTab loading={loading} setLoading={setLoading} />,
    },
    {
      menuItem: {
        as: Link,
        id: 2,
        to: ROOM_BOOKING_LINK,
        content: translations.completed,
        exact: true,
        key: 2,
      },
      render: () => <CompletedTab loading={loading} setLoading={setLoading} />,
    },
    {
      menuItem: {
        as: Link,
        id: 3,
        to: ROOM_BOOKING_LINK,
        content: translations.cancelled,
        exact: true,
        key: 3,
      },
      render: () => <CancelledTab loading={loading} setLoading={setLoading} />,
    },
  ];

  return (
    <Tab
      menu={{ text: true }}
      activeIndex={roomBookingPayload?.status}
      onTabChange={(e, { activeIndex }: any) => {
        dispatch(
          listRoomBookingPayloadAction({
            status: activeIndex,
          })
        );
      }}
      panes={panes}
      className={"tab-custom p-0"}
    />
  );
};

export default RoomBooking;
