import React from "react";

type Props = {
  comment: string;
  services: [];
};

const InterestingService = ({ services, comment }: Props) => {
  if (!services.length && !comment) {
    return <div>-</div>;
  }

  return (
    <div className={"user-review-table_services"}>
      {services.map(({ icon_url, name }, index) => (
        <div className={"service"} key={index}>
          <img src={icon_url} />
          {name}
        </div>
      ))}
      {comment ? (
        <p>
          <b>Note:</b> {comment}
        </p>
      ) : null}
    </div>
  );
};

export default InterestingService;
