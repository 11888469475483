import React from "react";
import DropdownCustom from "../../../../../components/DropdownCustom";
import {
  CREDIT_CANCELLED_STATUS,
  CREDIT_EXPIRED_STATUS,
  CREDIT_PAID_STATUS,
  CREDIT_PENDING_STATUS,
} from "../constants";

type Props = {
  status: number | string;
  onChange: (value: any) => void;
};
const StatusFilter = ({ status, onChange }: Props) => {
  const options = [
    {
      key: 0,
      text: "All Statuses",
      value: "all",
    },
    { key: 1, text: "Pending payment", value: CREDIT_PENDING_STATUS },
    { key: 2, text: "Paid", value: CREDIT_PAID_STATUS },
    { key: 3, text: "Expired", value: CREDIT_EXPIRED_STATUS },
    { key: 4, text: "Cancelled", value: CREDIT_CANCELLED_STATUS },
  ];

  return (
    <DropdownCustom
      squareUI
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options={options}
      search
      value={status}
      onChange={(e, { value }) => onChange(value)}
    />
  );
};

export default StatusFilter;
