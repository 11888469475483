import SizeBox from "../../../../../../components/SizeBox";
import UserReviewTable from "./UserReviewTable";
import React, { useEffect, useState } from "react";
import { getUserReviewThunk } from "../../../../thunks/PropertyThunks";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  useGetAllQuery,
  useSetQuery,
} from "../../../../../../utils/custom-hooks";
import PropertyUserReview from "../DetailsTab/Components/PropertyUserReview";
import PropertyOutstanding from "../DetailsTab/Components/PropertyOutstanding";
import { propertyDetailSelector } from "../../../../selectors/PropertySelector";

const UserReviewTab = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [filter, setFilter] = useState({ page: 1, services_ids: [] });
  const [loading, setLoading] = useState(false);

  const [addParamsAlready, setAddParamsAlready] = useState<boolean>(false);
  const getAllQuery = useGetAllQuery();
  const setQuery = useSetQuery();

  const propertyDetail = useSelector(propertyDetailSelector);

  useEffect(() => {
    try {
      if (!Object.keys(getAllQuery).length) {
        return;
      }

      setFilter(getAllQuery);
    } finally {
      setAddParamsAlready(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (!addParamsAlready) {
        return;
      }
      setQuery(filter);

      await _getUserReview();
    })();
  }, [addParamsAlready, filter]);

  const _getUserReview = async () => {
    try {
      setLoading(true);
      const payload = {
        property_id: id,
        ...filter,
      };
      await dispatch<any>(getUserReviewThunk(payload));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SizeBox height={"32px"} />
      {Object.keys(propertyDetail).length ? (
        <div className={"property-user-review"}>
          <PropertyUserReview propertyDetail={propertyDetail} />
          <PropertyOutstanding propertyId={String(id)} />
        </div>
      ) : null}
      <UserReviewTable
        filter={filter}
        setFilter={setFilter}
        loading={loading}
      />
    </>
  );
};

export default UserReviewTab;
