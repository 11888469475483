import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import { useSelector } from "react-redux";
import {
  deskimoPropertyListFilterSelector,
  deskimoPropertyListSelector,
} from "../../../selectors/PropertySelector";
import { useNavigate } from "react-router-dom";
import { SWAL_DELETE_OPTION } from "../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../components/SweetAlert";
import { handlePublishService } from "../../../services/PropertyServices";
import { toastError, toastSuccess } from "../../../../../components/Toast";
import DropdownCustom from "../../../../../components/DropdownCustom";
import ButtonCustom from "../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import {
  DESKIMO_PROPERTY_DETAIL_LINK,
  PROPERTY_DETAIL_USER_REVIEW_LINK,
} from "../../../../App/configs/ListRoutes";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import TableCustom from "../../../../../components/Table";
import MarketFilterMultiple from "../../../../../components/FilterMarket/MarketFilterMultiple";

type Props = {
  loading: boolean;
  onChangePagination: any;
  onChangeSortCol: any;
  onChangeMarket: any;
  onRefresh: () => void;
  setLoading?: any;
};

const DeskimoPropertiesListTable = ({
  loading,
  onChangePagination,
  onChangeSortCol,
  onChangeMarket,
  onRefresh,
  setLoading,
}: Props) => {
  const { translations } = useContext(LocalizationContext);
  const propertyList = useSelector(deskimoPropertyListSelector);
  const propertyListFilter = useSelector(deskimoPropertyListFilterSelector);

  const [propertyTable, setPropertyTable] = useState([]);
  const navigate = useNavigate();

  const columns = [
    { key: "workspace_by_name", col: "Name" },
    { key: "workspace_by_company", col: "Company" },
    { key: "workspace_by_market", col: "Market" },
    { key: "workspace_by_rating", col: "Rating" },
    { key: "workspace_by_visit", col: "Visits" },
    { key: "workspace_by_hourly_rate", col: "Hourly Rate" },
    { key: "workspace_by_day_cap", col: "Day Cap" },
    { key: "workspace_by_day_status", col: "Status" },
    { key: null, col: "Action" },
  ];

  const _handlePublishProperty = (id: any, isPublish: number) => {
    const icon = null;
    const title =
      isPublish > 0
        ? translations.propertyPage.publishMsg
        : translations.propertyPage.unPublishMsg;
    const confirmText =
      isPublish > 0
        ? translations.propertyPage.publish
        : translations.propertyPage.unPublish;
    const confirmClass = "primary";
    const options = SWAL_DELETE_OPTION({
      title,
      icon,
      confirmText,
      confirmClass,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setLoading(true);
        try {
          const payload = {
            property_id: id,
            status: isPublish,
          };
          await handlePublishService(payload, false);
          toastSuccess(
            isPublish > 0
              ? translations.propertyPage.publishSuccessMsg
              : translations.propertyPage.unPublishSuccessMsg
          );
          onRefresh();
        } catch (error: any) {
          if (error?.status?.message) {
            toastError(error?.status?.message);
          }
        } finally {
          setLoading(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  useEffect(() => {
    if (propertyList && propertyList.items) {
      const data = propertyList.items.map((item: any) => {
        return {
          col1: <div>{item?.workspace_name}</div>,
          col2: <div>{item?.company_name}</div>,
          col3: <div>{item?.market_code ? item?.market_code : "-"}</div>,
          col4: (
            <div>
              {!item?.rating && !item?.number_of_review ? (
                "-"
              ) : item?.number_of_review < 2 ? (
                <div>
                  {item?.rating} ({item?.number_of_review} review)
                </div>
              ) : (
                <div>
                  {item?.rating} ({item?.number_of_review} reviews)
                </div>
              )}
            </div>
          ),
          col5: (
            <div>
              {item?.number_of_visit ? (
                <div>{item?.number_of_visit}</div>
              ) : (
                "None"
              )}
            </div>
          ),
          col6: (
            <div>
              {item?.hourly_rate ? <div>{item?.hourly_rate}</div> : "-"}
            </div>
          ),
          col7: <div>{item?.day_cap ? <div>{item?.day_cap}</div> : "-"}</div>,
          col8: <div>{item?.status}</div>,
          col9: (
            <DropdownCustom
              direction={"left"}
              icon={null}
              trigger={
                <ButtonCustom
                  style={{ width: "38px", height: "38px" }}
                  className={"primary-alt"}
                >
                  <i className="uil uil-setting" style={{ fontSize: "21px" }} />
                </ButtonCustom>
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    window.open(
                      DESKIMO_PROPERTY_DETAIL_LINK.replaceAll(
                        ":id",
                        item?.workspace_id
                      ).replaceAll(":companyId", item?.company_id),
                      "_blank"
                    )
                  }
                  text={translations.viewDetails}
                  icon={
                    <img src={PUBLIC_URL + "/assets/icons/icon-message.svg"} />
                  }
                />
                <Dropdown.Item
                  onClick={() =>
                    navigate(
                      PROPERTY_DETAIL_USER_REVIEW_LINK.replaceAll(
                        ":id",
                        item?.workspace_id
                      )
                    )
                  }
                  text={translations.showReviews}
                  icon={<img src={PUBLIC_URL + "/assets/icons/icon-eye.svg"} />}
                />
                {/*<Dropdown.Item*/}
                {/*  text={"PMS Integration"}*/}
                {/*  icon={*/}
                {/*    <i*/}
                {/*      className="uil uil-eye icon"*/}
                {/*      style={{ fontSize: "16px" }}*/}
                {/*    />*/}
                {/*  }*/}
                {/*/>*/}
                {item?.is_publish ? (
                  <Dropdown.Item
                    onClick={() =>
                      _handlePublishProperty(item?.workspace_id, 0)
                    }
                    text={translations.propertyPage.unPublish}
                    icon={
                      <i
                        className="uil uil-toggle-off icon"
                        style={{ fontSize: "16px" }}
                      />
                    }
                  />
                ) : (
                  <Dropdown.Item
                    onClick={() =>
                      _handlePublishProperty(item?.workspace_id, 1)
                    }
                    text={translations.propertyPage.publish}
                    icon={
                      <i
                        className="uil uil-toggle-on icon"
                        style={{ fontSize: "16px" }}
                      />
                    }
                  />
                )}
              </Dropdown.Menu>
            </DropdownCustom>
          ),
        };
      });
      setPropertyTable(data as never);
    } else {
      setPropertyTable([]);
    }
  }, [propertyList]);

  return (
    <div>
      <TableCustom
        noHeader={false}
        loading={loading}
        showTotal
        tableData={propertyTable}
        onChangeSortCol={onChangeSortCol}
        orderBy={propertyListFilter.order_by}
        sortBy={propertyListFilter.sort_by}
        sortTable
        columns={columns}
        itemPerPage={propertyList?.pagination?.limit}
        currentPage={propertyList?.pagination?.page}
        totalItems={propertyList?.pagination?.total}
        unit={"workspaces"}
        onChangePagination={onChangePagination}
        topRightComponent={
          <MarketFilterMultiple
            isSubmitting={loading}
            onChange={onChangeMarket}
            values={propertyListFilter.market_code}
          />
        }
      />
    </div>
  );
};

export default DeskimoPropertiesListTable;
