import React, { useContext } from "react";
import CircleStatus from "../../../../../../components/CircleStatus";
import TableCustom from "../../../../../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  roomBookingListSelector,
  roomBookingPayloadSelector,
} from "../../../../selectors/BookingSelectors";
import {
  FORMAT_DATE_VARIANT_3,
  FORMAT_TIME_VARIANT_1,
} from "../../../../../App/configs/DateTimeConfigs";
import { listRoomBookingPayloadAction } from "../../../../actions/BookingActions";
import {
  ROOM_STATUS_CANCELLED,
  ROOM_STATUS_COMPLETED,
  ROOM_STATUS_UPCOMING,
} from "../../../../configs/Constants";
import moment from "moment-timezone";
import { LocalizationContext } from "../../../../../../locales/Translation";
import Action from "./Action";
import SizeBox from "../../../../../../components/SizeBox";

const BookingTable = ({ columns, loading, setLoading }: any) => {
  const { translations } = useContext(LocalizationContext);

  const listRoomBooking = useSelector(roomBookingListSelector);
  const roomBookingPayload = useSelector(roomBookingPayloadSelector);
  const dispatch = useDispatch();

  const renderStatusCircle = (statusId: any) => {
    switch (statusId) {
      case 0:
        return "yellow";
      case 5:
        return "yellow";
      case 1:
        return "green";
      default:
        return "blue";
    }
  };

  const decoratedData = () => {
    if (listRoomBooking?.items?.length > 0) {
      return listRoomBooking?.items.map((room: any, index: number) => {
        return {
          col1: (
            <div className={"table-booking__room-name table-booking__cell"}>
              <img src={room?.thumbnail} alt="" />
              <span>{room?.meeting_room_name}</span>
            </div>
          ),
          col2: (
            <div
              className={"table-booking__property-details table-booking__cell"}
            >
              <div className={"table-booking__property-details-item"}>
                <i className="uil uil-building" />
                <span>{room?.brand_name}</span>
              </div>
              <div className={"table-booking__property-details-item"}>
                <i className="uil uil-map" />
                <span>{room?.address}</span>
              </div>
            </div>
          ),
          col3: <div className={"table-booking__cell"}>{room?.duration}</div>,
          col4: (
            <div className={"table-booking__cell"}>
              {moment
                .tz(room?.booking_start_time * 1000, room?.timezone)
                .format(FORMAT_DATE_VARIANT_3)}{" "}
              <br />
              {moment
                .tz(room?.booking_start_time * 1000, room?.timezone)
                .format(FORMAT_TIME_VARIANT_1)}{" "}
              -{" "}
              {moment
                .tz(room?.booking_end_time * 1000, room?.timezone)
                .format(FORMAT_TIME_VARIANT_1)}
            </div>
          ),
          col5: (
            <div className={"table-booking__cell"}>
              {room?.currency} {room?.total_final_price}
            </div>
          ),
          col6: (
            <div className={"table-booking__cell"}>
              {roomBookingPayload?.status === ROOM_STATUS_UPCOMING ? (
                <div className={"table-booking__status"}>
                  <CircleStatus color={renderStatusCircle(room?.status_id)} />
                  <span>{translations.roomBookingStatus[room?.status_id]}</span>
                </div>
              ) : null}
              {roomBookingPayload?.status === ROOM_STATUS_COMPLETED
                ? null
                : null}
              {roomBookingPayload?.status === ROOM_STATUS_CANCELLED ? (
                <>
                  {moment
                    .tz(room?.updated_at * 1000, room?.timezone)
                    .format(FORMAT_DATE_VARIANT_3)}
                  <br />
                  {moment
                    .tz(room?.updated_at * 1000, room?.timezone)
                    .format(FORMAT_TIME_VARIANT_1)}
                </>
              ) : null}

              {room?.parking_request ? (
                <>
                  <SizeBox height={16} />
                  <div>
                    <b>Parking:</b> {room?.parking_request?.status?.text}
                  </div>
                </>
              ) : null}
            </div>
          ),
          col7: (
            <Action
              loading={loading}
              setLoading={setLoading}
              cancellationPolicy={room?.cancellation_policy}
              status={roomBookingPayload?.status}
              isRequestCancel={room?.request_cancel}
              bookingId={room?.booking_id}
              meetingRoomId={room?.meeting_room_id}
            />
          ),
        };
      });
    }
    return [];
  };

  return (
    <TableCustom
      showTotal={false}
      columns={columns}
      currentPage={roomBookingPayload?.page}
      loading={loading}
      tableClass={"table-booking"}
      tableData={decoratedData()}
      itemPerPage={listRoomBooking?.pagination?.limit}
      totalItems={listRoomBooking?.pagination?.total}
      onChangePagination={(e: any, { activePage }: any) => {
        dispatch(
          listRoomBookingPayloadAction({
            page: activePage,
          })
        );
      }}
    />
  );
};

export default BookingTable;
