import React, { useContext } from "react";
import Label from "../../../../../../components/Label";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import { LocalizationContext } from "../../../../../../locales/Translation";

type Props = {
  value: string;
  onChange: (value: any) => void;
};
const FrequencyUsage = ({ value, onChange }: Props) => {
  const { translations } = useContext(LocalizationContext);

  const options = [
    { key: "2", text: translations.partTime, value: "part_time" },
    { key: "3", text: translations.permanent, value: "permanent" },
    { key: "1", text: translations.oneTime, value: "one_time" },
  ];

  return (
    <div>
      <Label label={translations.frequencyOfUsage} />
      <DropdownCustom
        selectUI
        options={options}
        value={value}
        onChange={(event, { value }) => onChange(value)}
      />
    </div>
  );
};

export default FrequencyUsage;
