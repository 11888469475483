import React, { useContext } from "react";
import Label from "../../../../../components/Label";
import { TextArea } from "semantic-ui-react";
import SizeBox from "../../../../../components/SizeBox";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import { LocalizationContext } from "../../../../../locales/Translation";

type Props = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
  handleChange: any;
};

const Rooms = ({
  values,
  errors,
  touched,
  handleChange,
  isSubmitting,
}: Props) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <>
      <Label
        label={translations.rooms}
        fontSize={"24px"}
        className={"section-title"}
      />

      {/*<Label*/}
      {/*  fontSize={"18px"}*/}
      {/*  label={translations.defaultCancellationPolicy}*/}
      {/*  labelGuide={translations.thisWillApplyAllMeetingRooms}*/}
      {/*/>*/}

      {/*<TextArea*/}
      {/*  className={"text-area"}*/}
      {/*  rows={4}*/}
      {/*  value={values.room_cancel_policy}*/}
      {/*  error={errors.room_cancel_policy}*/}
      {/*  touched={touched.room_cancel_policy}*/}
      {/*  name={"room_cancel_policy"}*/}
      {/*  onChange={handleChange}*/}
      {/*  disabled={isSubmitting}*/}
      {/*/>*/}

      {/*<SizeBox height={"40px"} />*/}

      <Label
        className={"label-xl"}
        contentClassName={""}
        label={translations.bookingRequirements}
        labelGuide={translations.thisWillApplyAllMeetingRooms}
      />

      <TextArea
        className={"text-area"}
        rows={4}
        value={values.room_booking_requirement}
        error={errors.room_booking_requirement}
        touched={touched.room_booking_requirement}
        name={"room_booking_requirement"}
        onChange={handleChange}
        disabled={isSubmitting}
      />

      <SizeBox height={"40px"} />
      <div className={"property_company_detail_line"} />
    </>
  );
};

export default Rooms;
