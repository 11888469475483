import { Table } from "semantic-ui-react";
import React, { useContext, useEffect } from "react";
import "./style.scss";
import PaginationCustom from "../Pagination";
import {
  ITEMS_PER_PAGE,
  ORDER_BY_ASC,
  ORDER_BY_DESC,
} from "../../modules/App/configs/Constants";
import LoaderCustom from "../Loader";
import { LocalizationContext } from "../../locales/Translation";
import classNames from "classnames";

type Props = {
  tableData?: any;
  columns?: any;
  itemPerPage?: any;
  currentPage?: any;
  totalItems?: number;
  showTotal?: boolean;
  onChangePagination?: any;
  loading?: boolean;
  unit?: string;
  sortTable?: boolean;
  onChangeSortCol?: any;
  sortBy?: any;
  orderBy?: any;
  noHeader?: boolean;
  tableHeaderClass?: string;
  tableClass?: string;
  topRightComponent?: JSX.Element;
  scrollInside?: boolean;
  keepHeader?: boolean;
  tableLayoutFixed?: boolean;
  emptyText?: string;
};

const TableCustom = ({
  tableData,
  columns,
  itemPerPage = ITEMS_PER_PAGE,
  totalItems,
  currentPage,
  showTotal = true,
  onChangePagination,
  loading,
  unit = "items",
  sortTable = false,
  onChangeSortCol,
  sortBy,
  orderBy,
  noHeader = false,
  tableHeaderClass = "",
  tableClass = "",
  topRightComponent,
  scrollInside,
  keepHeader,
  tableLayoutFixed,
  emptyText,
}: Props) => {
  const { translations } = useContext(LocalizationContext);

  useEffect(() => {
    _handleResize();
    window.addEventListener("resize", _handleResize);
    return () => {
      window.removeEventListener("resize", _handleResize);
    };
  }, []);

  const _handleResize = () => {
    const tableEl = document.getElementsByClassName("table-custom")[0];
    const scrollInsideEnable = tableEl.classList.contains("scroll-inside");

    if (!scrollInsideEnable || !(tableEl instanceof HTMLElement)) {
      return;
    }

    const offsetTop = tableEl.offsetTop;

    const windowHeight = window.innerHeight;

    const headerEl = tableEl.querySelector("thead");
    const bodyEl = tableEl.querySelector("tbody");

    if (
      !(headerEl instanceof HTMLElement) ||
      !(bodyEl instanceof HTMLElement)
    ) {
      return;
    }

    const headerHeight = headerEl.offsetHeight;
    const footerHeight = 86;

    let scrollHeight =
      windowHeight - offsetTop - headerHeight - footerHeight - 39;

    if (scrollHeight < 200) {
      scrollHeight = 200;
    }
    bodyEl.style.maxHeight = `${scrollHeight}px`;
  };

  let tableColumns = null;
  if (columns && columns?.length > 0) {
    if (sortTable) {
      tableColumns = columns.map((col: any, index: number) => {
        return (
          <Table.HeaderCell
            width={col?.width}
            className={classNames("table-sort__header text-nowrap", {
              active: sortBy?.col === col.key,
            })}
            key={index}
            onClick={() => {
              if (col?.key) {
                onChangeSortCol(col);
              }
            }}
          >
            <div className={"th-sort"}>
              {col.col}
              {col?.key ? (
                <div className={"table-sort__column"}>
                  <i
                    className={classNames("uil uil-angle-up", {
                      active: orderBy === ORDER_BY_ASC && sortBy === col?.key,
                    })}
                  />
                  <i
                    className={classNames("uil uil-angle-down", {
                      active: orderBy === ORDER_BY_DESC && sortBy === col?.key,
                    })}
                  />
                </div>
              ) : null}
            </div>
          </Table.HeaderCell>
        );
      });
    } else {
      tableColumns = columns.map((col: any, index: number) => {
        if (col?.width) {
          return (
            <Table.HeaderCell
              width={col?.width}
              className={tableHeaderClass + (col?.col ? "" : " empty-col ")}
              key={index}
            >
              {col?.col}
            </Table.HeaderCell>
          );
        }

        return (
          <Table.HeaderCell
            className={tableHeaderClass + (col ? "" : " empty-col ")}
            key={index}
          >
            {col}
          </Table.HeaderCell>
        );
      });
    }
  }

  const Row = (itemObject: any) => {
    let row = null;
    const rowLength = Object.keys(itemObject).length;
    if (rowLength < columns.length) {
      Object.keys(itemObject);
    }

    row = Object.keys(itemObject).map(function (key, index) {
      return (
        <Table.Cell key={index} className={key}>
          {itemObject[key]}
        </Table.Cell>
      );
    });
    return row;
  };

  return (
    <>
      {showTotal || topRightComponent ? (
        <div className={"table-custom-header"}>
          {showTotal ? (
            <div className={"table-offset-limit"}>
              {totalItems ? (
                <>
                  {`${translations.showing} ${
                    1 + (currentPage - 1) * itemPerPage
                  } ${translations.to}
        ${
          itemPerPage * currentPage > totalItems
            ? totalItems
            : itemPerPage * currentPage
        } ${translations.of} ${totalItems} 
        ${unit ? ` ${unit}` : ""}`}
                </>
              ) : null}
            </div>
          ) : null}
          {topRightComponent ? (
            <div className={"table-custom-top-right"}>{topRightComponent}</div>
          ) : null}
        </div>
      ) : null}

      <Table
        selectable
        striped
        className={classNames("table-custom", tableClass, {
          "scroll-inside": scrollInside,
          "keep-header": keepHeader,
          "layout-fixed": tableLayoutFixed,
        })}
      >
        {noHeader ? null : (
          <Table.Header>
            <Table.Row>{tableColumns}</Table.Row>
          </Table.Header>
        )}

        <Table.Body>
          {loading ? <LoaderCustom /> : null}
          {tableData.length > 0 &&
            tableData?.map((item: any, index: number) => {
              return <Table.Row key={index}>{Row(item)}</Table.Row>;
            })}
          {tableData.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan={columns.length}>
                <p className={"text-center"}>
                  {emptyText ? emptyText : translations.noDataAvailable}
                </p>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
        {tableData.length > 0 && totalItems != undefined && (
          <Table.Footer>
            <Table.Row>
              <Table.Cell
                colSpan={columns.length}
                className={"table-pagination"}
              >
                <PaginationCustom
                  onChangePagination={onChangePagination}
                  defaultPage={currentPage}
                  totalPage={Math.ceil(totalItems / itemPerPage)}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
    </>
  );
};

export default TableCustom;
