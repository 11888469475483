import React, { useEffect, useState } from "react";
import { DeskimoUserTypesType } from "../../../../configs/DeclareTypes";
import ToggleSlider from "../../../../../../components/ToggleSlider";
import "./styles.scss";
import { Label } from "semantic-ui-react";

type Props = {
  item: DeskimoUserTypesType;
  setEntitlements: any;
};

const Company = ({ item, setEntitlements }: Props) => {
  const [clickDelete, setClickDelete] = useState<any>(false);
  const [clickRevert, setClickRevert] = useState<any>(false);

  useEffect(() => {
    setClickDelete(item.is_deleted);
  }, [item.is_deleted]);

  const _onMakeAdmin = () => {
    setEntitlements((prevState: any) => {
      return prevState.map((data: any) => {
        if (data.property_company_user_id !== item.property_company_user_id) {
          return data;
        }

        const admin = data.is_admin ? 0 : 1;

        return { ...data, is_admin: !!admin, make_admin: admin };
      });
    });
  };

  const _onDelete = () => {
    setClickDelete(true);
    setClickRevert(false);
    setEntitlements((prevState: any) => {
      return prevState.map((data: any) => {
        if (data.property_company_user_id !== item.property_company_user_id) {
          return data;
        }

        return { ...data, remove: data?.remove ? 0 : 1 };
      });
    });
  };

  const _onUnDelete = () => {
    setClickDelete(false);
    setClickRevert(true);
    setEntitlements((prevState: any) => {
      return prevState.map((data: any) => {
        if (data.property_company_user_id !== item.property_company_user_id) {
          return data;
        }

        return { ...data, remove: 0 };
      });
    });
  };

  const _onRevert = () => {
    setClickDelete(false);
    setClickRevert(true);
    setEntitlements((prevState: any) => {
      return prevState.map((data: any) => {
        if (data.property_company_user_id !== item.property_company_user_id) {
          return data;
        }

        return { ...data, revert: true };
      });
    });
  };

  const _onUnRevert = () => {
    setClickRevert(false);
    setClickDelete(true);
    setEntitlements((prevState: any) => {
      return prevState.map((data: any) => {
        if (data.property_company_user_id !== item.property_company_user_id) {
          return data;
        }

        return { ...data, revert: null };
      });
    });
  };

  return (
    <>
      <div className={"deskimo-user__company"}>
        <span className={"name"}>
          {item.company_name}{" "}
          {clickDelete ? (
            <Label size={"mini"} className={"ml-4px"}>
              Deleted
            </Label>
          ) : null}
        </span>
        <ToggleSlider
          disabled={clickDelete}
          checked={item.is_admin}
          className={"make-admin"}
          onChange={_onMakeAdmin}
        />
        {item.is_deleted ? (
          <>
            {clickRevert ? (
              <div className={"delete"} onClick={_onUnRevert}>
                <i
                  className="uil uil-trash-alt icon"
                  style={{ fontSize: "16px" }}
                />
              </div>
            ) : (
              <div className={"delete"} onClick={_onRevert}>
                <span style={{ lineHeight: "22px" }}>Revert</span>
              </div>
            )}
          </>
        ) : (
          <>
            {clickDelete ? (
              <div className={"delete"} onClick={_onUnDelete}>
                <span style={{ lineHeight: "22px" }}>Revert</span>
              </div>
            ) : (
              <div className={"delete"} onClick={_onDelete}>
                <i
                  className="uil uil-trash-alt icon"
                  style={{ fontSize: "16px" }}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Company;
