import React, { useContext } from "react";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import Label from "../../../../../../components/Label";
import OfficeLeasesContext from "../../Context";

type Props = {
  value: string;
  onChange: (value: any) => void;
  disabled: boolean;
  error: boolean;
};

const DropdownCompany = ({ onChange, value, disabled, error }: Props) => {
  const { businessCompanyOptions } = useContext(OfficeLeasesContext);

  return (
    <>
      <Label label={"Generate lease for"} />
      <DropdownCustom
        error={error}
        disabled={disabled}
        search
        onChange={(event, { value }) => onChange(value)}
        value={value}
        selectUI
        options={businessCompanyOptions}
        placeholder={"Select company"}
      />
    </>
  );
};

export default DropdownCompany;
