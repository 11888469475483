import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMeetingRoomListThunk,
  roomMarketCountThunk,
} from "../../thunks/BookThunks";
import {
  meetingRoomFiltersSelector,
  modalConfirmBookingSelector,
} from "../../selectors/BookSelectors";
import FilterRoom from "../Components/FilterRoom";
import SizeBox from "../../../../components/SizeBox";
import ModalConfirmBooking from "../Components/ModalConfirmBooking";
import ModalBookingSubmitted from "../Components/ModalBookingSubmitted";
import RoomList from "./RoomList";
import { listMeetingRoomFilterAction } from "../../actions/BookActions";

const Room = () => {
  const dispatch = useDispatch();
  const roomFilters = useSelector(meetingRoomFiltersSelector);
  const [loading, setLoading] = useState(false);
  const [populateFromSessionStorage, setPopulateFromSessionStorage] =
    useState(false);
  const modalConfirmBooking = useSelector(modalConfirmBookingSelector);

  useEffect(() => {
    const defaultDate = sessionStorage.getItem("DEFAULT_ROOM_DATE");
    const defaultTime = sessionStorage.getItem("DEFAULT_ROOM_TIME");
    const defaultDuration = sessionStorage.getItem("DEFAULT_ROOM_DURATION");
    const defaultCapacity = Number(
      sessionStorage.getItem("DEFAULT_ROOM_CAPACITY")
    );
    const defaultLocation = sessionStorage.getItem("DEFAULT_ROOM_LOCATION");
    const defaultAmenity = sessionStorage.getItem("DEFAULT_ROOM_AMENITIES");
    const defaultSort = Number(sessionStorage.getItem("DEFAULT_ROOM_SORTBY"));

    const defaultFilters = {
      ...roomFilters,
      defaultDuration: defaultDuration
        ? defaultDuration
        : roomFilters?.defaultDuration,
      capacity_select: defaultCapacity
        ? defaultCapacity
        : roomFilters?.capacity,
      sort_by: defaultSort ? defaultSort : roomFilters?.sort_by,
      amenities: defaultAmenity
        ? defaultAmenity.split(",").map(Number)
        : roomFilters?.amenities,
      lat: defaultLocation ? JSON.parse(defaultLocation).lat : null,
      lng: defaultLocation ? JSON.parse(defaultLocation).lng : null,
      defaultDate: defaultDate ? defaultDate : roomFilters?.defaultDate,
      defaultTime: defaultTime ? defaultTime : roomFilters?.defaultTime,
    };

    dispatch(listMeetingRoomFilterAction(defaultFilters));

    setPopulateFromSessionStorage(true);
  }, [populateFromSessionStorage]);

  useEffect(() => {
    if (!populateFromSessionStorage) return;

    (async () => {
      try {
        if (!roomFilters?.city_code) {
          return;
        }
        setLoading(true);
        await dispatch<any>(getMeetingRoomListThunk(roomFilters));
        dispatch<any>(roomMarketCountThunk(roomFilters));
      } finally {
        setLoading(false);
      }
    })();
  }, [roomFilters, populateFromSessionStorage]);

  return (
    <>
      <FilterRoom populateFromSessionStorage={populateFromSessionStorage} />
      <SizeBox height={"16px"} />
      <RoomList loading={loading} />

      {modalConfirmBooking.open ? <ModalConfirmBooking /> : <> </>}

      <ModalBookingSubmitted />
    </>
  );
};

export default Room;
