export const UI_PROPERTY_MANAGE_PER = "ui.property.manage.per";
export const COMPANY_PROPERTY_USER_PER = "company.property.user.per";
export const INVOICE_MANAGE_PER = "invoice.manage.per";
export const BUSINESS_COMPANY_VOUCHER_ADD_PER =
  "business.company.voucher.add.per";
export const UI_ACCESS_STATISTIC_PROPERTY_PER =
  "ui.access.statistic.property.per";
export const UI_ACCESS_TRANSACTION_BY_FILTER_PER =
  "ui.access.transaction.by.property.filter.per";
export const UI_ACCESS_ALLOW_SET_UP_BOOKING_MODE_PER =
  "ui.allow.setup.booking.mode.per";

export const UI_ACCESS_PARTNER_DASHBOARD =
  "manage.new.bo.dashboard.business.partner.per";
