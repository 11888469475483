import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { Formik } from "formik";
import * as yup from "yup";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import ModalCenterTemplate from "../../../../../App/views/ModalCenterTemplate";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalCreateWalkInsSelector } from "../../../../selectors/DashboardSelectors";
import { modalCreateWalkInsManualAction } from "../../../../actions/DashboardActions";
import { LocalizationContext } from "../../../../../../locales/Translation";
import {
  manualBookingGetPaymentMethodService,
  manualBookingGetPropertiesService,
  manualBookingGetUsersService,
  manualBookingSubmitWalkInsService,
} from "../../../../services/DashboardServices";
import Label from "../../../../../../components/Label";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import { debounce } from "lodash";
import SizeBox from "../../../../../../components/SizeBox";
import DatePickerSingle from "../../../../../../components/DatePickerSingle";
import { calculateBookingTimeTillSecond } from "../../../../../Book/services/Common";
import { TimePicker } from "antd";
import InputCustom from "../../../../../../components/Input";

type ManualBookingProperty = {
  id: number;
  name: string;
  timezone: string;
};

type ManualBookingRoom = {
  id: number;
  name: string;
};

type ManualBookingUser = {
  email: string;
  id: number;
  name: string;
  phone_number: string;
};

type ManualBookingPaymentMethod = {
  id: number;
  name: string;
};

type ManualBookingDataType = {
  listProperty: ManualBookingProperty[];
  listMeetingRoom: ManualBookingRoom[];
  listUser: ManualBookingUser[];
  listPaymentMethod: ManualBookingPaymentMethod[];
  propertySelected: string;
  meetingRoomSelected: string;
  userSelected: string;
  paymentMethodSelected: string;
  dateSelected: Date;
  startTime: string;
  endTime: string;
  minTime: string;
  maxTime: string;
};

const ModalCreateWalkIns = ({ userDetail }: any) => {
  const { translations } = useContext(LocalizationContext);
  const modalCreateWalkIns = useSelector(ModalCreateWalkInsSelector);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const initData = {
    listProperty: [],
    listMeetingRoom: [],
    listUser: [],
    listPaymentMethod: [],
    propertySelected: "",
    meetingRoomSelected: "",
    userSelected: userDetail?.user_id || "",
    paymentMethodSelected: "",
    dateSelected: new Date(),
    startTime: "",
    endTime: "",
    minTime: "",
    maxTime: "",
  };
  const [modalData, setModalData] = useState<ManualBookingDataType>(initData);
  const [propertyKeyword, setPropertyKeyword] = useState("");
  const [userKeyword, setUserKeyword] = useState("");

  const _onCloseModal = () => {
    dispatch(modalCreateWalkInsManualAction({ open: false }));
    setModalData(initData);
  };

  useEffect(() => {
    if (modalCreateWalkIns.open) {
      _fetchListProperty();
    }
  }, [propertyKeyword]);

  useEffect(() => {
    if (modalCreateWalkIns.open) {
      _fetchListUser();
    }
  }, [userKeyword]);

  useEffect(() => {
    if (modalCreateWalkIns.open && modalData.userSelected) {
      _fetchListPaymentMethod();
    }
  }, [modalData.userSelected, modalCreateWalkIns]);

  const _fetchListProperty = async () => {
    if (modalCreateWalkIns.open) {
      try {
        const payload = {
          keyword: propertyKeyword,
        };
        const response = await manualBookingGetPropertiesService(payload);
        setModalData({ ...modalData, listProperty: response?.data });
      } catch (error: any) {
        toastError(error?.status?.message);
      }
    }
  };

  const _fetchListUser = async () => {
    if (modalCreateWalkIns.open) {
      try {
        const payload = {
          keyword: userKeyword,
        };
        const response = await manualBookingGetUsersService(payload);
        setModalData({ ...modalData, listUser: response?.data });
      } catch (error: any) {
        toastError(error?.status?.message);
      }
    }
  };

  const _fetchListPaymentMethod = async () => {
    if (modalCreateWalkIns.open) {
      try {
        const response = await manualBookingGetPaymentMethodService(
          modalData?.userSelected.toString()
        );

        setModalData({
          ...modalData,
          listPaymentMethod: response?.data,
          paymentMethodSelected: response?.data[0]?.id
            ? String(response?.data[0]?.id)
            : "",
        });
      } catch (error: any) {
        toastError(error?.status?.message);
      }
    }
  };

  const getPropertyTimezone = () => {
    return modalData.listProperty.find(
      (property) => property.id === parseInt(modalData.propertySelected)
    )?.timezone;
  };

  const getBookingDurationTillSecond = () => {
    const [startTimeHour, startTimeMinute, startTimeSecond] =
      modalData.startTime.split(":");
    const [endTimeHour, endTimeMinute, endTimeSecond] =
      modalData.endTime.split(":");
    return (
      parseInt(endTimeHour) * 3600 +
      parseInt(endTimeMinute) * 60 +
      parseInt(endTimeSecond) -
      parseInt(startTimeHour) * 3600 -
      parseInt(startTimeMinute) * 60 -
      parseInt(startTimeSecond)
    );
  };

  const _onClickSave = async () => {
    setLoading(true);
    try {
      const timezone = getPropertyTimezone();
      const duration = getBookingDurationTillSecond();
      if (!timezone) return;

      const payload = {
        property_id: modalData.propertySelected,
        user_id: modalData.userSelected,
        payment_method_id: modalData.paymentMethodSelected,
        checkin_time: calculateBookingTimeTillSecond(
          modalData.dateSelected,
          modalData.startTime,
          undefined,
          timezone
        ),
        checkout_time: calculateBookingTimeTillSecond(
          modalData.dateSelected,
          modalData.startTime,
          duration,
          timezone
        ),
      };

      await manualBookingSubmitWalkInsService(payload);
      _onCloseModal();
      toastSuccess("Create walk-in successfully");
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={_onCloseModal}
      modalClassName={"modal-general"}
      open={modalCreateWalkIns.open}
    >
      <Modal.Header>
        Create Walk-in
        <ButtonCustom
          height={"28px"}
          onClick={_onCloseModal}
          className={"modal-close-cta"}
        >
          <>
            <span>{translations.close}</span>
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Formik
        initialValues={{}}
        validationSchema={yup.object().shape({})}
        onSubmit={_onClickSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <>
            <Modal.Content>
              <form className={"form-layout"}>
                <div>
                  <Label
                    fontSize={"12px"}
                    className={"sm"}
                    label={"Property"}
                  />
                  <DropdownCustom
                    selectUI
                    search
                    onSearchChange={debounce((event, data) => {
                      setPropertyKeyword(data.searchQuery);
                    }, 200)}
                    onChange={(event, data) => {
                      setModalData({
                        ...modalData,
                        propertySelected: data.value
                          ? data.value.toString()
                          : "",
                      });
                    }}
                    options={modalData.listProperty.map(
                      (property: ManualBookingProperty) => {
                        return {
                          key: property.id,
                          text: property.name,
                          value: property.id,
                        };
                      }
                    )}
                    className={"dropdown-fullwidth-common-style"}
                  />
                </div>
                <SizeBox height={"16px"} />
                <div>
                  <Label
                    fontSize={"12px"}
                    className={"sm"}
                    label={"Walk-in Date"}
                  />
                  <DatePickerSingle
                    value={modalData.dateSelected}
                    inputStyle
                    oneTap
                    onChange={(date: Date) => {
                      setModalData({ ...modalData, dateSelected: date });
                    }}
                  />
                </div>
                <SizeBox height={"16px"} />
                <div>
                  <Label
                    fontSize={"12px"}
                    className={"sm"}
                    label={"Walk-in Time"}
                  />
                  <TimePicker.RangePicker
                    format={"HH:mm:ss"}
                    className={"w-100"}
                    onChange={(time, timeString) => {
                      setModalData({
                        ...modalData,
                        startTime: timeString[0],
                        endTime: timeString[1],
                      });
                    }}
                  />
                </div>
                <SizeBox height={"16px"} />
                <div>
                  <Label fontSize={"12px"} className={"sm"} label={"User"} />
                  {userDetail ? (
                    <InputCustom disabled value={userDetail.email} />
                  ) : (
                    <DropdownCustom
                      selectUI
                      search
                      onSearchChange={debounce((event, data) => {
                        setUserKeyword(data.searchQuery);
                      }, 200)}
                      onChange={(event, data) => {
                        setModalData({
                          ...modalData,
                          userSelected: data.value ? data.value.toString() : "",
                        });
                      }}
                      options={modalData.listUser.map(
                        (user: ManualBookingUser) => {
                          return {
                            key: user.id,
                            text: user.email + " (" + user.phone_number + " )",
                            value: user.id,
                          };
                        }
                      )}
                      className={"dropdown-fullwidth-common-style"}
                    />
                  )}
                </div>
                <SizeBox height={"16px"} />
                <div>
                  <Label
                    fontSize={"12px"}
                    className={"sm"}
                    label={"Payment Method"}
                  />
                  <DropdownCustom
                    selectUI
                    search
                    value={Number(modalData?.paymentMethodSelected)}
                    onChange={(event, data) => {
                      setModalData({
                        ...modalData,
                        paymentMethodSelected: data.value
                          ? data.value.toString()
                          : "",
                      });
                    }}
                    onOpen={async () => {
                      if (modalData.listPaymentMethod.length < 1) {
                        await _fetchListPaymentMethod();
                      }
                    }}
                    options={modalData.listPaymentMethod.map(
                      (paymentMethod: ManualBookingPaymentMethod) => {
                        return {
                          key: paymentMethod.id,
                          text: paymentMethod.name,
                          value: paymentMethod.id,
                        };
                      }
                    )}
                    className={"dropdown-fullwidth-common-style"}
                  />
                </div>
              </form>
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom height={"47px"} onClick={_onCloseModal}>
                {translations.cancel}
              </ButtonCustom>
              <ButtonCustom
                disabled={
                  modalData.propertySelected.length < 1 ||
                  modalData.userSelected.length < 1 ||
                  modalData.paymentMethodSelected.length < 1 ||
                  modalData.startTime.length < 1 ||
                  modalData.endTime.length < 1
                }
                loading={isSubmitting}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {translations.create}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalCenterTemplate>
  );
};

export default ModalCreateWalkIns;
