import React from "react";
import ButtonCustom from "../../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import { ROOM_STATUS_UPCOMING, TYPE_ROOM } from "../../../../configs/Constants";
import { useNavigate } from "react-router-dom";
import { BOOK_ROOM_DETAIL_LINK } from "../../../../../App/configs/ListRoutes";
import { SWAL_CUSTOM_CONTENT } from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import { requestCancelRoomBookingService } from "../../../../services/BookingServices";
import {
  modalRoomBookingDetailAction,
  refreshListRoomBookingAction,
} from "../../../../actions/BookingActions";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { useDispatch } from "react-redux";

type Props = {
  status: number;
  meetingRoomId: number;
  isRequestCancel: boolean;
  bookingId: number;
  cancellationPolicy: string;
  loading: boolean;
  setLoading: any;
};

const Action = ({
  status,
  meetingRoomId,
  isRequestCancel,
  cancellationPolicy,
  bookingId,
  loading,
  setLoading,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const _onCancellationRequest = () => {
    const content = (
      <div className={"booking-cancel-request-box"}>
        <div className={"booking-cancel-request-title"}>
          Cancellation Policy
        </div>
        <ul className={"booking-cancel-request-content"}>
          <div dangerouslySetInnerHTML={{ __html: cancellationPolicy }} />
        </ul>
      </div>
    );
    const options = SWAL_CUSTOM_CONTENT({
      content,
      confirmButtonText: "Cancel",
      cancelButtonText: "No",
      confirmButtonClass: "danger button ui " + (loading ? " disabled" : ""),
      wrapperClass: "booking-cancel-request-alert",
    });
    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          setLoading(true);
          await requestCancelRoomBookingService(bookingId);

          dispatch(refreshListRoomBookingAction());
          toastSuccess("Request cancellation successfully!");
        } catch (error: any) {
          toastError(error?.status?.message);
        } finally {
          setLoading(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  if (status !== ROOM_STATUS_UPCOMING) {
    return (
      <DropdownCustom
        disabled={loading}
        className={"table-action-dropdown"}
        direction={"left"}
        icon={null}
        trigger={
          <ButtonCustom
            style={{ width: "38px", height: "38px" }}
            className={"primary-alt"}
          >
            <i className="uil uil-setting" style={{ fontSize: "21px" }} />
          </ButtonCustom>
        }
      >
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() =>
              navigate(
                BOOK_ROOM_DETAIL_LINK.replaceAll(
                  ":id",
                  meetingRoomId.toString()
                )
              )
            }
            text={"Book Again"}
            icon={<i className="uil uil-repeat icon" />}
          />
        </Dropdown.Menu>
      </DropdownCustom>
    );
  }

  return (
    <DropdownCustom
      disabled={loading}
      className={"table-action-dropdown"}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() =>
            dispatch(
              modalRoomBookingDetailAction({
                open: true,
                booking_id: bookingId,
                type: TYPE_ROOM,
              })
            )
          }
          text={"View details"}
          icon={<i className="uil uil-comment-alt-lines icon" />}
        />
        <Dropdown.Item
          disabled={isRequestCancel}
          onClick={_onCancellationRequest}
          text={isRequestCancel ? "Cancel request sent" : "Cancel booking"}
          icon={
            isRequestCancel ? (
              <i className="uil uil-message icon" />
            ) : (
              <i className="uil uil-times icon" />
            )
          }
        />
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default Action;
