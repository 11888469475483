import React, { useEffect, useRef } from "react";
import ButtonCustom from "../../../../../components/Button";
import {
  headerPopupToppingUpAction,
  modalPackageTopUpAction,
  userGuideAction,
} from "../../../actions/AppActions";
import { useDispatch, useSelector } from "react-redux";
import {
  headerPopupToppingUpSelector,
  userGuideSelector,
} from "../../../selectors/AppSelectors";
import { Popup } from "semantic-ui-react";
import CreditBalance from "./CreditBalance";
import { UserCompanyType } from "../../../../User/configs/DeclareTypes";
import { userCompanyChooseSelector } from "../../../../User/selectors/UserSelectors";

const CreditTopUp = () => {
  const headerPopupToppingUp = useSelector(headerPopupToppingUpSelector);
  const dispatch = useDispatch();
  const userGuide = useSelector(userGuideSelector);

  const popupTriggerRef = useRef<any>(null);
  const userCompanyChoose: UserCompanyType = useSelector(
    userCompanyChooseSelector
  );

  useEffect(() => {
    document.addEventListener(
      "click",
      () => {
        dispatch(headerPopupToppingUpAction(false));
      },
      true
    );
    if (popupTriggerRef && headerPopupToppingUp) {
      setTimeout(() => {
        popupTriggerRef.current.triggerRef.current.focus();
      }, 400);
    }
  }, [headerPopupToppingUp]);

  return (
    <div className={"navbar__credit_balance guide-navbar__credit_balance"}>
      <CreditBalance />

      {!userCompanyChoose?.can_buy_credit ? null : (
        <Popup
          className={"popup-header-topping-up"}
          content="When you are ready, add credits by topping up your account here"
          position="bottom right"
          hoverable={true}
          on={headerPopupToppingUp ? ["focus"] : undefined}
          open={headerPopupToppingUp}
          closeOnDocumentClick={true}
          ref={popupTriggerRef}
          trigger={
            <ButtonCustom
              height={"32px"}
              className={"outline-primary"}
              onClick={() => {
                dispatch(headerPopupToppingUpAction(false));
                dispatch(modalPackageTopUpAction(true));
                if (userGuide?.run && userGuide?.stepIndex === 5) {
                  dispatch(userGuideAction({ key: "stepIndex", value: 6 }));
                }
              }}
            >
              Top up
            </ButtonCustom>
          }
        />
      )}
    </div>
  );
};

export default CreditTopUp;
