import React, { useContext } from "react";
import {
  modalAddEmployeeAction,
  modalImportEmployeeAction,
  userGuideAction,
} from "../../../actions/AppActions";
import { useDispatch, useSelector } from "react-redux";
import { userGuideSelector } from "../../../selectors/AppSelectors";
import DropdownMultiple from "../../../../../components/DropdownMultiple";
import { Dropdown } from "semantic-ui-react";
import { LocalizationContext } from "../../../../../locales/Translation";
import ButtonInviteEmployeeByGoogle from "../../../../User/views/EmployeeBusiness/Components/ButtonInviteEmployeeByGoogle";

const AddEmployee = () => {
  const dispatch = useDispatch();
  const userGuide = useSelector(userGuideSelector);
  const { translations } = useContext(LocalizationContext);

  return (
    <DropdownMultiple
      direction={"right"}
      trigger={<span className={"title"}>Add Employees</span>}
      className={"navbar__add-employee guide-add-employee"}
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => {
            dispatch(modalAddEmployeeAction(true));
            if (userGuide?.run && userGuide?.stepIndex === 2) {
              dispatch(userGuideAction({ key: "stepIndex", value: 3 }));
            }
          }}
          text={"Individual invite"}
          icon={<i className="uil uil-users-alt icon"></i>}
        />
        <Dropdown.Item
          onClick={() => {
            dispatch(modalAddEmployeeAction(false));
            dispatch(modalImportEmployeeAction(true));
          }}
          text={translations.bulkInvite}
          icon={<i className="uil uil-upload icon" />}
        />
        <ButtonInviteEmployeeByGoogle
          buttonClass={"navbar__add-employee__google"}
        />
      </Dropdown.Menu>
    </DropdownMultiple>
  );
};

export default AddEmployee;
