import React, { useContext } from "react";
import { Modal } from "semantic-ui-react";
import ModalCenterTemplate from "../../../../../../App/views/ModalCenterTemplate";
import ButtonCustom from "../../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../../App/configs/Constants";
import SizeBox from "../../../../../../../components/SizeBox";
import { LocalizationContext } from "../../../../../../../locales/Translation";
import moment from "moment-timezone";
import { FORMAT_DATE_TIME_VARIANT_8 } from "../../../../../../App/configs/DateTimeConfigs";
import Avatar from "./Avatar";

type Props = {
  data: any;
  onClose: () => void;
};
const ModalNoteDetail = ({ onClose, data }: Props) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <ModalCenterTemplate
      size={"md"}
      open={data.open}
      onClose={onClose}
      modalClassName={"property-note-detail-modal"}
    >
      <Modal.Header className={"ui"}>
        <span>{data?.title}</span>
        <ButtonCustom
          height={"28px"}
          onClick={onClose}
          className={"modal-close-cta"}
        >
          <>
            {translations.close}
            <SizeBox width={"8px"} />
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Modal.Content>
        <div className={"comment"}>{data?.comment}</div>
        <div className={"user"}>
          <Avatar capName={data?.cap_name} imgUrl={data?.avatar} />
          <span>{data?.name}</span>
        </div>
        <div className={"created-on"}>
          {translations.createdOn}{" "}
          <span className={"created-on-date"}>
            {moment.unix(data?.created_at).format(FORMAT_DATE_TIME_VARIANT_8)}
          </span>
        </div>
      </Modal.Content>
    </ModalCenterTemplate>
  );
};

export default ModalNoteDetail;
