import { Formik } from "formik";
import * as yup from "yup";
import InputCustom from "../../../../../../components/Input";
import ButtonCustom from "../../../../../../components/Button";
import {
  ADMIN_LOGOUT_URL,
  GRANT_TYPE_SOCIAL_LOGIN,
} from "../../../../../../configs/Endpoints";
import { Link, useSearchParams } from "react-router-dom";
import {
  ACCOUNT_CREATE_OPTION_LINK,
  FORGOT_PASSWORD_LINK,
  LOGIN_LINKED_IN_LINK,
} from "../../../../../App/configs/ListRoutes";
import { useContext, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import SizeBox from "../../../../../../components/SizeBox";
import { useGoogleLogin } from "@react-oauth/google";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";

const LoginForm = ({ onLogin }: any) => {
  const { translations } = useContext(LocalizationContext);
  const [searchParams] = useSearchParams();

  const [googleLoading, setGoogleLoading] = useState(false);
  const [linkedinLoading, setLinkedinLoading] = useState(false);

  const login = useGoogleLogin({
    onSuccess: async ({ code }) => {
      setGoogleLoading(true);
      const payload = {
        grant_type: GRANT_TYPE_SOCIAL_LOGIN,
        provider: "google",
        code,
      };
      await onLogin(payload);
      setGoogleLoading(false);
    },
    flow: "auth-code",
  });

  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID || "",
    redirectUri: `${window.location.origin}${LOGIN_LINKED_IN_LINK}`,
    scope: "email profile openid",
    onSuccess: async (code) => {
      setLinkedinLoading(true);
      const payload = {
        grant_type: GRANT_TYPE_SOCIAL_LOGIN,
        provider: "linkedin",
        code,
      };
      await onLogin(payload);
      setLinkedinLoading(false);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <>
      <div className={"login-pre-link"}>
        New user?{" "}
        {searchParams.get("individual_option") ? (
          <Link to={ACCOUNT_CREATE_OPTION_LINK} className={"login-link"}>
            Register new account
          </Link>
        ) : (
          <a
            className={"login-link"}
            href={process.env.REACT_APP_ADMIN_URL + "/business/onboard"}
            target={"_blank"}
            rel="noreferrer"
          >
            Register new account
          </a>
        )}
      </div>
      <SizeBox height={"32px"} />
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={yup.object().shape({
          email: yup.string().email().required().label("Email"),
          password: yup.string().required().label("Password"),
        })}
        onSubmit={onLogin}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form>
            <InputCustom
              label={translations.emailAddress}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.email}
              error={errors.email}
              name={"email"}
            />
            <div className={"mb-16px"} />
            <InputCustom
              label={translations.password}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.password}
              error={errors.password}
              name={"password"}
              type={"password"}
              passwordShow
            />
            <Link className={"login-link"} to={FORGOT_PASSWORD_LINK}>
              {translations.forgotPassword}?
            </Link>
            <SizeBox height={"28px"} />
            <div className={"actions"}>
              <ButtonCustom
                height={"46px"}
                onClick={handleSubmit}
                loading={isSubmitting}
                className={"primary w-100"}
              >
                {translations.login}
              </ButtonCustom>
            </div>
          </form>
        )}
      </Formik>

      <div className={"login-line"}>
        <span>OR</span>
      </div>

      <div className={"login-social"}>
        <ButtonCustom
          onClick={() => login()}
          className={"btn-google"}
          loading={googleLoading}
          disabled={googleLoading}
        >
          <>
            <img src={PUBLIC_URL + "/assets/icons/icon-google.svg"} />
            Sign in with Google
          </>
        </ButtonCustom>

        <SizeBox width={"8px"} />

        <ButtonCustom
          onClick={linkedInLogin}
          className={"btn-linked-in"}
          loading={linkedinLoading}
          disabled={linkedinLoading}
        >
          <>
            <img src={PUBLIC_URL + "/assets/icons/icon-linked-in.svg"} />
            Sign in with LinkedIn
          </>
        </ButtonCustom>
      </div>

      <iframe
        // Handle auto logout admin page
        style={{ display: "none" }}
        src={ADMIN_LOGOUT_URL}
      />
    </>
  );
};

export default LoginForm;
