import React, { ReactDOM, useContext, useState } from "react";
import { LocalizationContext } from "../../locales/Translation";
import { Accordion } from "semantic-ui-react";
import "./styles.scss";

type Props = {
  title?: any;
  content?: any;
  defaultOpen?: boolean;
  icon?: JSX.Element;
};
const SimpleCollapseExpandBlank = ({
  title,
  content,
  defaultOpen = true,
  icon = <i className="uil uil-angle-down" />,
}: Props) => {
  const { translations } = useContext(LocalizationContext);

  const [activeIndex, setActiveIndex] = useState(defaultOpen ? 0 : -1);

  const handleCollapseExpand = () => {
    if (activeIndex === 0) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(0);
    }
  };

  return (
    <div className={"simple-collapse-expand-blank-wrapper"}>
      <Accordion className={"w-100 simple-collapse-expand-blank"}>
        <Accordion.Title
          className={"simple-collapse-expand-blank__title"}
          onClick={handleCollapseExpand}
          active={activeIndex === 0}
          index={0}
        >
          <>
            {title}
            {icon}
          </>
        </Accordion.Title>
        <Accordion.Content className={"scroll"} active={activeIndex === 0}>
          {content}
        </Accordion.Content>
      </Accordion>
    </div>
  );
};

export default SimpleCollapseExpandBlank;
