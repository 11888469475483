import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import {
  getDeskBookingListService,
  getRoomBookingListService,
} from "../services/BookingServices";
import {
  listDeskBookingAction,
  listRoomBookingAction,
} from "../actions/BookingActions";

export const getRoomBookingListThunk = (payload: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await getRoomBookingListService(payload);
    dispatch<any>(listRoomBookingAction(data));
  };
};

export const getDeskBookingListThunk = (payload: any, isClear?: any) => {
  if (isClear) {
    return async (dispatch: Dispatch<AnyAction>) => {
      dispatch<any>(listDeskBookingAction([]));
    };
  }
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await getDeskBookingListService(payload);
    dispatch<any>(listDeskBookingAction(data));
  };
};
