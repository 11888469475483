import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SWAL_DELETE_OPTION } from "../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../components/SweetAlert";
import { toastError, toastSuccess } from "../../../../../components/Toast";
import DropdownCustom from "../../../../../components/DropdownCustom";
import ButtonCustom from "../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import {
  DESKIMO_PROPERTY_COMPANY_DETAIL_LINK,
  // PROPERTY_DETAIL_LINK,
  // PROPERTY_EDIT_LINK,
} from "../../../../App/configs/ListRoutes";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import TableCustom from "../../../../../components/Table";
import {
  deskimoPropertyCompanyListFilterSelector,
  deskimoPropertyCompanyListSelector,
} from "../../../selectors/PropertySelector";
import {
  deletePropertyCompanyService,
  companyPublishService,
} from "../../../services/PropertyCompanyServices";
import { deskimoPropertyCompanyDeleteAction } from "../../../actions/PropertyActions";
import MarketFilterMultiple from "../../../../../components/FilterMarket/MarketFilterMultiple";

type Props = {
  loading: boolean;
  onChangePagination: any;
  onChangeSortCol: any;
  onChangeMarketFilter: any;
  onRefresh: () => void;
  setLoading?: any;
};

const DeskimoPropertiesCompanyListTable = ({
  loading,
  onChangePagination,
  onChangeSortCol,
  onRefresh,
  onChangeMarketFilter,
  setLoading,
}: Props) => {
  const { translations } = useContext(LocalizationContext);
  const companyList = useSelector(deskimoPropertyCompanyListSelector);
  const companyListFilter = useSelector(
    deskimoPropertyCompanyListFilterSelector
  );
  const [companyTable, setCompanyTable] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const columns = [
    { key: "workspace_company_by_name", col: "Company Name" },
    { key: "workspace_company_by_contact_name", col: "Contact Name" },
    { key: "workspace_company_by_contact_email", col: "Email" },
    {
      key: "workspace_company_by_contact_phone",
      col: "Contact Phone Number",
    },
    { key: "workspace_company_by_market", col: "Market" },
    { key: "workspace_company_by_created_date", col: "Created Date" },
    { key: "workspace_company_by_status", col: "Status" },
    { key: null, col: "Action" },
  ];

  const _onDelete = async (companyId: string) => {
    try {
      setLoading(true);
      await deletePropertyCompanyService(companyId);
      dispatch(deskimoPropertyCompanyDeleteAction(companyId));
      toastSuccess(translations.deleteSuccessPrompt);
    } catch (e) {
      toastSuccess(translations.deleteFailPrompt);
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handlePublishPropertyCompany = (id: number, isPublish: number) => {
    const icon = null;
    const title =
      isPublish > 0
        ? "Are you sure you want to publish this company?"
        : "Are you sure you want to un-publish this company?";
    const confirmText =
      isPublish > 0
        ? translations.propertyPage.publish
        : translations.propertyPage.unPublish;
    const confirmClass = "primary";
    const options = SWAL_DELETE_OPTION({
      title,
      icon,
      confirmText,
      confirmClass,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setLoading(true);
        try {
          const payload = {
            company_id: id,
            status: isPublish,
          };
          await companyPublishService(payload);
          toastSuccess(
            isPublish > 0
              ? "Successfully published the company"
              : "Successfully un-published the company"
          );
          onRefresh();
        } catch (error: any) {
          if (error?.status?.message) {
            toastError(error?.status?.message);
          }
        } finally {
          setLoading(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  useEffect(() => {
    if (companyList && companyList.items) {
      const data = companyList.items.map((item: any) => {
        return {
          col1: <div>{item?.company_name}</div>,
          col2: <div>{item?.contact_name}</div>,
          col3: <div>{item?.contact_email}</div>,
          col4: <div>{item?.contact_phone}</div>,
          col5: <div>{item?.market_name}</div>,
          col6: <div>{item?.created_date}</div>,
          col7: (
            <div>
              {item?.status === 1
                ? "Active"
                : item?.status === 0
                ? "Inactive"
                : "-"}
            </div>
          ),
          col8: (
            <DropdownCustom
              direction={"left"}
              icon={null}
              trigger={
                <ButtonCustom
                  style={{ width: "38px", height: "38px" }}
                  className={"primary-alt"}
                >
                  <i className="uil uil-setting" style={{ fontSize: "21px" }} />
                </ButtonCustom>
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    navigate(
                      DESKIMO_PROPERTY_COMPANY_DETAIL_LINK.replaceAll(
                        ":id",
                        item?.company_id
                      )
                    );
                  }}
                  text={translations.newPropertyPage.viewCompany}
                  icon={
                    <img src={PUBLIC_URL + "/assets/icons/icon-message.svg"} />
                  }
                />
                <Dropdown.Item
                  onClick={() => {
                    navigate(
                      DESKIMO_PROPERTY_COMPANY_DETAIL_LINK.replaceAll(
                        ":id",
                        item?.company_id
                      )
                    );
                  }}
                  text={translations.newPropertyPage.editCompany}
                  icon={
                    <i
                      className="uil uil-edit icon"
                      style={{ fontSize: "16px" }}
                    />
                  }
                />

                {item?.status ? (
                  <Dropdown.Item
                    onClick={() =>
                      handlePublishPropertyCompany(item?.company_id, 0)
                    }
                    text={translations.propertyPage.unPublish}
                    icon={
                      <i
                        className="uil uil-toggle-off icon"
                        style={{ fontSize: "16px" }}
                      />
                    }
                  />
                ) : (
                  <Dropdown.Item
                    onClick={() =>
                      handlePublishPropertyCompany(item?.company_id, 1)
                    }
                    text={translations.propertyPage.publish}
                    icon={
                      <i
                        className="uil uil-toggle-on icon"
                        style={{ fontSize: "16px" }}
                      />
                    }
                  />
                )}
                <Dropdown.Item
                  onClick={() => _onDelete(item?.company_id)}
                  text={translations.newPropertyPage.deleteCompany}
                  icon={
                    <i
                      className="uil uil-trash-alt icon"
                      style={{ fontSize: "16px" }}
                    />
                  }
                />
              </Dropdown.Menu>
            </DropdownCustom>
          ),
        };
      });
      setCompanyTable(data as never);
    } else {
      setCompanyTable([]);
    }
  }, [companyList]);

  return (
    <div>
      <TableCustom
        noHeader={false}
        loading={loading}
        showTotal
        tableData={companyTable}
        onChangeSortCol={onChangeSortCol}
        orderBy={companyListFilter.order_by}
        sortBy={companyListFilter.sort_by}
        sortTable
        columns={columns}
        currentPage={companyList?.pagination?.page}
        itemPerPage={companyList?.pagination?.limit}
        totalItems={companyList?.pagination?.total}
        unit={"companies"}
        onChangePagination={onChangePagination}
        topRightComponent={
          <MarketFilterMultiple
            isSubmitting={loading}
            onChange={onChangeMarketFilter}
            values={companyListFilter.market_codes}
          />
        }
      />
    </div>
  );
};

export default DeskimoPropertiesCompanyListTable;
