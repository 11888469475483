import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import SizeBox from "../../../../../components/SizeBox";
import { DashboardStatisticType } from "../../../configs/DeclareTypes";
import { useSelector } from "react-redux";
import { dashboardStatisticSelector } from "../../../selectors/DashboardSelectors";

const TotalCosts = () => {
  const { translations } = useContext(LocalizationContext);
  const dashboardStatistic: DashboardStatisticType = useSelector(
    dashboardStatisticSelector
  );

  return (
    <div className={"dashboard_chart_box_wrapper"}>
      <div className={"dashboard_chart_box"}>
        <h2 className={"dashboard_chart_title"}>{translations.costOverview}</h2>
        <div className={"dashboard_chart_line"}>
          <h2 className={"dashboard_chart_sub_title"}>
            {translations.totalCost}
          </h2>
          <SizeBox height={"18px"} />
          <span className={"dashboard_chart_title"}>
            {dashboardStatistic.currency} {dashboardStatistic.total_cost}
          </span>
        </div>
        <div className={"dashboard_chart_line"}>
          <h2 className={"dashboard_chart_sub_title"}>
            {translations.avgCostPerUser}
          </h2>
          <SizeBox height={"18px"} />
          <span className={"dashboard_chart_title"}>
            {dashboardStatistic.currency} {dashboardStatistic.cost_per_user}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TotalCosts;
