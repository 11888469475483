import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../../App/configs/Constants";
import { Formik, setIn } from "formik";
import * as yup from "yup";
import Label from "../../../../../../../components/Label";
import SizeBox from "../../../../../../../components/SizeBox";
import ModalCenterTemplate from "../../../../../../App/views/ModalCenterTemplate";
import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../../../locales/Translation";
import { QRCodeCanvas } from "qrcode.react";
import DropdownCustom from "../../../../../../../components/DropdownCustom";
import { encryptSHA256 } from "../../../../../../../utils/commons";
import CheckboxCustom from "../../../../../../../components/Checkbox";
import { useParams } from "react-router-dom";
import {
  propertyIntegrationsCreateQRCodeService,
  propertyIntegrationsUpdateQRCodeService,
} from "../../../../../services/PropertyIntegrationServices";
import DatePickerSingleV2 from "../../../../../../../components/DatePickerSingleV2";

import moment from "moment-timezone";
import {
  toastError,
  toastSuccess,
} from "../../../../../../../components/Toast";
import {
  DEEPLINK_TOKEN,
  QRCODE_CHECK_IN,
  QRCODE_CHECKOUT_OUT,
} from "../../../../../configs/Constants";
import { sha256 } from "js-sha256";
import { getPropertyIntegrationsQRCodeListThunk } from "../../../../../thunks/PropertyThunks";
import { useDispatch } from "react-redux";

const ModalCreateUpdateQrCode = ({ open, setOpen }: any) => {
  const { translations } = useContext(LocalizationContext);
  const [QRCodeString, setQRCodeString] = useState<any>("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const [initFormData, setInitFormData] = useState({
    qrcode_token: "",
    type: QRCODE_CHECK_IN,
    expired_date: moment(),
    is_never_expired: 1,
  });

  useEffect(() => {
    initializeData();
  }, [open]);

  const generateQRCodeString = () => {
    return DEEPLINK_TOKEN.replaceAll(
      "%token%",
      sha256(
        moment().unix() +
          (Math.floor(Math.random() * 1000) + 1) +
          (id ? id : "")
      )
    );
  };

  const initializeData = () => {
    if (open?.data) {
      console.log("open: ", open);
      setInitFormData({
        qrcode_token: open?.data?.qrcode_token,
        type: open?.data?.type,
        expired_date: open?.data?.expired_date
          ? moment(open?.data?.expired_date * 1000)
          : moment(),
        is_never_expired: open?.data?.is_never_expired,
      });
    } else {
      setInitFormData({
        qrcode_token: "",
        type: QRCODE_CHECK_IN,
        expired_date: moment(),
        is_never_expired: 1,
      });
      setQRCodeString(generateQRCodeString());
    }
  };

  const _onCloseModal = () => {
    setOpen({ open: false, data: null });
  };

  const _onClickSave = async (values: any) => {
    const expiredDate = moment(values.expired_date)
      .tz("Asia/Jakarta", true)
      .endOf("day")
      .utc()
      .unix();

    if (!id) return;
    try {
      if (open?.data) {
        const payload = {
          ...values,
          expired_date: expiredDate,
        };
        await propertyIntegrationsUpdateQRCodeService(
          id,
          open?.data?.id,
          payload
        );
        await dispatch<any>(getPropertyIntegrationsQRCodeListThunk(id));
        toastSuccess("Update QR Code successfully");
      } else {
        const payload = {
          ...values,
          expired_date: expiredDate,
          qrcode_token: QRCodeString,
        };
        await propertyIntegrationsCreateQRCodeService(id, payload);
        await dispatch<any>(getPropertyIntegrationsQRCodeListThunk(id));
        toastSuccess("Create QR Code successfully");
      }
      _onCloseModal();
    } catch (e: any) {
      console.log("Error: ", e);
      toastError(e?.status?.message);
    }
  };

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={_onCloseModal}
      modalClassName={"modal-general"}
      open={open?.open}
    >
      <Modal.Header>
        {open?.data ? "Edit" : "Create"} QR Code
        <ButtonCustom
          height={"24px"}
          onClick={_onCloseModal}
          className={"modal-close-cta"}
        >
          <>
            <span>{translations.close}</span>
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Formik
        enableReinitialize={true}
        initialValues={initFormData}
        validationSchema={yup.object().shape({
          type: yup.string().required().label("Type"),
        })}
        onSubmit={_onClickSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => {
          return (
            <>
              <Modal.Content style={{ paddingBottom: 0 }}>
                <div className={"form-box"}>
                  <form className={"form-layout"}>
                    <div>
                      <div className={"d-flex align-items-center mb-48px"}>
                        <SizeBox width={"32px"} />
                      </div>
                    </div>
                    <Label label={"Type"} required />
                    <DropdownCustom
                      value={values.type}
                      style={{ height: 44, borderRadius: 5 }}
                      className={"w-100"}
                      selectUI={true}
                      options={[
                        { id: 1, text: "Check-in", value: QRCODE_CHECK_IN },
                        {
                          id: 2,
                          text: "Check-out",
                          value: QRCODE_CHECKOUT_OUT,
                        },
                      ]}
                      onChange={(e, data: any) => {
                        setFieldValue("type", data.value);
                      }}
                    />

                    {values?.is_never_expired > 0 ? null : (
                      <>
                        <SizeBox height={"16px"} />

                        <Label label={"Expire Date"} required />
                        <DatePickerSingleV2
                          format={"DD/MM/YYYY"}
                          allowClear={false}
                          value={values.expired_date}
                          disabledDate={(date: any) => {
                            return moment().isBefore(
                              moment().subtract({ d: 1 })
                            );
                          }}
                          showToday={false}
                          className={"w-100"}
                          onChange={(date: any) => {
                            setFieldValue("expired_date", date);
                          }}
                        />
                      </>
                    )}

                    <SizeBox height={"16px"} />

                    <div style={{ padding: "11px 0", marginBottom: 10 }}>
                      <CheckboxCustom
                        labelSizeSmall
                        label={"Never expires"}
                        checked={values?.is_never_expired > 0}
                        onChange={(e, data) =>
                          setFieldValue(
                            "is_never_expired",
                            data.checked ? 1 : 0
                          )
                        }
                      />
                    </div>
                  </form>
                </div>
              </Modal.Content>
              <Modal.Actions style={{ padding: "32px 0 42px" }}>
                <ButtonCustom height={"47px"} onClick={_onCloseModal}>
                  {translations.cancel}
                </ButtonCustom>
                <ButtonCustom
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  className={"primary"}
                  height={"47px"}
                  onClick={handleSubmit}
                >
                  {open?.data ? translations.save : translations.create}
                </ButtonCustom>
              </Modal.Actions>
            </>
          );
        }}
      </Formik>
    </ModalCenterTemplate>
  );
};

export default ModalCreateUpdateQrCode;
