import { useParams } from "react-router-dom";
import { SyntheticEvent, useEffect, useState } from "react";
import { getPropertyNotesService } from "../../../../../services/PropertyServices";
import CardItem from "./CardItem";
import { PropertyNoteType } from "../../../../../configs/DeclareType";
import Pagination from "../../../../../../../components/Pagination";

type Props = {
  setModalAddNote: any;
  setModalNoteDetail: any;
  refresh: boolean;
  onRefresh: any;
  setPagination: any;
  pagination: any;
};
const List = ({
  setModalAddNote,
  refresh,
  onRefresh,
  setModalNoteDetail,
  setPagination,
  pagination,
}: Props) => {
  const { id } = useParams();
  const [listNotes, setListNotes] = useState<PropertyNoteType[]>([]);
  const [filter, setFilter] = useState({
    property_id: id,
    page: 1,
  });

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getPropertyNotesService(filter);
        if (Array.isArray(data)) {
          setListNotes([]);
          setPagination(null);
          return;
        }
        setListNotes(data.items);
        setPagination(data.pagination);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [filter, refresh]);

  return (
    <>
      <div className={"property-note-card-list"}>
        {listNotes.map((item) => (
          <CardItem
            item={item}
            key={item.id}
            setModalAddNote={setModalAddNote}
            setModalNoteDetail={setModalNoteDetail}
            onRefresh={onRefresh}
          />
        ))}
      </div>
      {listNotes.length ? (
        <div className={"property-note-pagination"}>
          <Pagination
            onChangePagination={(e: SyntheticEvent, { activePage }: any) =>
              setFilter((prevState) => ({ ...prevState, page: activePage }))
            }
            totalPage={Math.ceil(pagination?.total / pagination?.limit)}
            defaultPage={pagination?.page}
          />
        </div>
      ) : null}
    </>
  );
};

export default List;
