import React, { useEffect, useState } from "react";
import MarkerRound from "./MarkerRound";
import { Circle, Marker } from "@react-google-maps/api";
import { Polyline } from "@react-google-maps/api";
import { BOOK_DESK_TAB } from "../../configs/Constants";
import {
  BookMeetingRoomType,
  BookPropertyType,
} from "../../configs/DeclareType";
import { useDispatch, useSelector } from "react-redux";
import {
  mapStateSelector,
  meetingRoomListSelector,
  pinHighlightIdSelector,
  propertyListSelector,
} from "../../selectors/BookSelectors";
import {
  getCircleRadiusZoom,
  getDistanceCloseMapZoom,
  getDistanceMapZoom,
  onClickMarkerMeetingRoom,
  onClickMarkerProperty,
} from "../../services/MapServices";
import { computeOffset } from "spherical-geometry-js";
import { PUBLIC_URL } from "../../../App/configs/Constants";
import { mapMoveAnimate } from "../../services/Common";
import {
  circleInfoAction,
  infoWindowDataAction,
} from "../../actions/BookActions";

type Props = {
  data: {
    id: number;
    lat: number;
    lng: number;
    isClosed: boolean;
  }[];
  setMarkerSameLatLng: any;
  tabActive: number;
  zoomLevel: number;
};
const MarkerChildren = ({
  data,
  setMarkerSameLatLng,
  tabActive,
  zoomLevel,
}: Props) => {
  const dispatch = useDispatch();
  const propertyMap: BookPropertyType[] =
    useSelector(propertyListSelector)?.results;
  const meetingRoomList: BookMeetingRoomType[] = useSelector(
    meetingRoomListSelector
  );
  const pinHighlightId = useSelector(pinHighlightIdSelector);

  const [markers, setMarkers] = useState<
    { id: number; lat: number; lng: number }[]
  >([]);

  const [markerClose, setMarkerClose] = useState<any>(null);

  const [circleRadius, setCircleRadius] = useState(0);

  const mapState = useSelector(mapStateSelector);

  const _onClick = ({
    id,
    lat,
    lng,
  }: {
    id: number;
    lat: number;
    lng: number;
  }) => {
    if (tabActive === BOOK_DESK_TAB) {
      const item = propertyMap.find(({ id }) => id === id);

      if (!item) {
        return;
      }
      onClickMarkerProperty({ ...item, lat, lng });
      return;
    }

    const item = meetingRoomList.find(
      ({ meeting_room_id }) => meeting_room_id === id
    );
    if (!item) {
      return;
    }
    onClickMarkerMeetingRoom({ ...item, lat, lng });
  };

  useEffect(() => {
    if (!data.length) {
      setMarkerClose(null);
      setMarkers([]);
      return;
    }

    const originPoint = new google.maps.LatLng(data[0].lat, data[0].lng);
    const dataLength = data.length;

    const temp = data.map(({ id, isClosed }, index) => {
      let degree = 350;

      if (dataLength <= 5) {
        degree = 80;
      } else if (dataLength <= 8) {
        degree = 170;
      } else if (dataLength <= 14) {
        degree = 260;
      }

      const bearing = (degree / dataLength) * index + 10;

      const { latitude, longitude } = computeOffset(
        originPoint,
        getDistanceMapZoom(zoomLevel),
        bearing
      );
      return {
        id,
        lat: latitude,
        lng: longitude,
        isClosed,
      };
    });

    setMarkers(temp);

    const { latitude, longitude } = computeOffset(
      originPoint,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getDistanceCloseMapZoom(zoomLevel),
      45
    );

    setCircleRadius(getCircleRadiusZoom(zoomLevel));

    setMarkerClose({ lat: latitude, lng: longitude });
  }, [zoomLevel, data]);

  const _onClose = () => {
    const center = { lat: data[0].lat, lng: data[0].lng };
    setMarkerSameLatLng([]);
    dispatch(infoWindowDataAction(null));
    // Tips: Move map make cluster calculator trigger
    mapMoveAnimate({
      map: mapState,
      cameraOptions: { center },
    });
  };

  if (!data.length) {
    return null;
  }

  return (
    <>
      {markers.map(({ id, lat, lng }) => {
        return (
          <>
            <MarkerRound
              key={"MarkerChildren" + id.toString()}
              position={{ lat, lng }}
              onClick={() => _onClick({ id, lat, lng })}
              highlight={id === pinHighlightId}
            />
            <Polyline
              path={[
                { lat: data[0]?.lat, lng: data[0]?.lng },
                { lat, lng },
              ]}
              options={{
                strokeColor: "#fff",
                strokeWeight: 1.5,
              }}
            />
          </>
        );
      })}
      {markerClose ? (
        <>
          <Marker
            position={{
              lat: markerClose.lat,
              lng: markerClose.lng,
            }}
            icon={{
              url: PUBLIC_URL + "/assets/icons/pin/icon-pin-times.svg",
              size: new google.maps.Size(24, 24),
              scaledSize: new google.maps.Size(24, 24),
            }}
            onClick={_onClose}
          />
        </>
      ) : null}
      <Circle
        onRadiusChanged={() => {
          dispatch(
            circleInfoAction({
              lat: data[0]?.lat,
              lng: data[0]?.lng,
              radius: circleRadius,
            })
          );
        }}
        onUnmount={() => {
          dispatch(circleInfoAction(null));
        }}
        radius={circleRadius}
        center={{ lat: data[0]?.lat, lng: data[0]?.lng }}
        options={{
          strokeWeight: 0,
          fillColor: "#003DBC",
          fillOpacity: 0.1,
        }}
      />
      {/*<OverlayView*/}
      {/*  position={{ lat: data[0]?.lat, lng: data[0]?.lng }}*/}
      {/*  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}*/}
      {/*>*/}
      {/*  <div*/}
      {/*    style={{*/}
      {/*      width: 280,*/}
      {/*      height: 280,*/}
      {/*      background: "rgba(0,61,188,0.1)",*/}
      {/*      position: "absolute",*/}
      {/*      top: "50%",*/}
      {/*      left: "50%",*/}
      {/*      transform: "translate(-50%, -50%)",*/}
      {/*      borderRadius: "100%",*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</OverlayView>*/}
    </>
  );
};

export default MarkerChildren;
