import React from "react";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import {
  BUSINESS_COMPANY_PREPAID_CREDIT_LINK,
  BUSINESS_COMPANY_PURCHASE_HISTORY_LINK,
} from "../../../App/configs/ListRoutes";

const Tabs = () => {
  const { pathname } = useLocation();
  return (
    <div className={"button-packages-section"}>
      <Link
        to={BUSINESS_COMPANY_PURCHASE_HISTORY_LINK}
        className={classNames("ui button button-custom button-packages-mode", {
          active: pathname.includes(BUSINESS_COMPANY_PURCHASE_HISTORY_LINK),
        })}
      >
        Purchase History
      </Link>
      <Link
        to={BUSINESS_COMPANY_PREPAID_CREDIT_LINK}
        className={classNames("ui button button-custom button-packages-mode", {
          active: pathname.includes(BUSINESS_COMPANY_PREPAID_CREDIT_LINK),
        })}
      >
        Prepaid Credits Usage
      </Link>
    </div>
  );
};

export default Tabs;
