import SearchWithDebounce from "../../../../components/SearchBar4Table/SearchWithDebounce";
import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import MediaFileTable from "./Components/MediaFileTable";
import { ROLE_DESKIMO_ADMIN_ID } from "../../../User/configs/Constants";
import MasterTemplate from "../../../App/views/MasterTemplate";
import ButtonCustom from "../../../../components/Button";
import ModalUploadFile from "./Components/ModalUploadFile";
import { mediaFileListThunk } from "../../thunks/MediaFileThunks";
import { debounce } from "lodash";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
  PUBLIC_URL,
} from "../../../App/configs/Constants";
import { toastError, toastSuccess } from "../../../../components/Toast";
import { deleteMediaFileService } from "../../services/MediaFileServices";
import { SWAL_DELETE_OPTION } from "../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../components/SweetAlert";
import {
  globalSettingSelector,
  myProfileSelector,
} from "../../../App/selectors/AppSelectors";
import {
  ROLE_PROPERTY_ADMIN,
  ROLE_PROPERTY_STAFF,
} from "../../../Property/configs/Constants";
import {
  MyProfileType,
  UserCompanyType,
} from "../../../User/configs/DeclareTypes";
import { CURRENT_COMPANY_ID } from "../../../../configs/Constants";
import { userCompanyChooseSelector } from "../../../User/selectors/UserSelectors";

const MediaFileList = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const globalSetting = useSelector(globalSettingSelector);
  const myProfile: MyProfileType = useSelector(myProfileSelector);
  const isPropertyUser =
    myProfile?.role_id === ROLE_PROPERTY_ADMIN ||
    myProfile?.role_id === ROLE_PROPERTY_STAFF;
  const userCompanyChoose: UserCompanyType = useSelector(
    userCompanyChooseSelector
  );
  const [filter, setFilter] = useState({
    keyword: "",
    markets: [],
    page: 1,
    sort_by: "",
    sort_type: "ASC",
    company_id: isPropertyUser ? userCompanyChoose?.company_id : undefined,
  });
  const [refresh, setRefresh] = useState(false);

  // console.log("userCompanyChoose: ", userCompanyChoose);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setFilter({ ...filter, company_id: userCompanyChoose?.company_id });
  }, [userCompanyChoose]);

  useEffect(() => {
    if (globalSetting) {
      _getMediaFileList();
    }
  }, [filter, refresh]);

  const _getMediaFileList = async () => {
    await dispatch<any>(mediaFileListThunk(filter));
  };

  const _onChangePagination = (e: any, page: any) => {
    setFilter({ ...filter, page: page?.activePage });
  };

  const _onChangeSortCol = (col: any) => {
    if (filter.sort_by !== col.key) {
      setFilter((prevState) => {
        return { ...prevState, sort_by: col.key, sort_type: ORDER_BY_ASC };
      });
    } else {
      if (filter.sort_type === ORDER_BY_NONE) {
        setFilter((prevState) => {
          return { ...prevState, sort_by: col.key, sort_type: ORDER_BY_ASC };
        });
      }
      if (filter.sort_type === ORDER_BY_DESC) {
        setFilter((prevState) => {
          return { ...prevState, sort_by: "", sort_type: ORDER_BY_NONE };
        });
      }
      if (filter.sort_type === ORDER_BY_ASC) {
        setFilter((prevState) => {
          return { ...prevState, sort_by: col.key, sort_type: ORDER_BY_DESC };
        });
      }
    }
  };

  const _onChangeMarket = (markets: any) => {
    setFilter({ ...filter, markets });
  };

  const _onRefresh = () => {
    setRefresh(!refresh);
  };

  const _onDeleteFile = async (ref: any) => {
    const title = "Are you sure you want to delete this media file?";
    const confirmText = "Delete";
    const confirmClass = "danger";
    const options = SWAL_DELETE_OPTION({
      title,
      confirmText,
      confirmClass,
      iconHtml: (
        <img
          src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
          className={"swal2-icon__error"}
        />
      ),
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setLoading(true);
        try {
          await deleteMediaFileService(ref);
          setRefresh(!refresh);
          toastSuccess("The file has been deleted successfully");
        } catch (error: any) {
          if (error?.status?.message) {
            toastError(error?.status?.message);
          }
        } finally {
          setLoading(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  return (
    <MasterTemplate
      roleAccept={[
        ROLE_DESKIMO_ADMIN_ID,
        ROLE_PROPERTY_ADMIN,
        ROLE_PROPERTY_STAFF,
      ]}
    >
      <div>
        <div className={"filter-section"}>
          <div className={"filter-section-left"}>
            <SearchWithDebounce
              initialValue={""}
              placeholder={translations.newPropertyPage?.searchByFileName}
              onChange={debounce(
                (value) => setFilter({ ...filter, keyword: value }),
                300
              )}
            />
          </div>
          {isPropertyUser ? null : (
            <ButtonCustom onClick={() => setOpen(true)} className={"primary"}>
              Add Media File +
            </ButtonCustom>
          )}
        </div>
        <MediaFileTable
          loading={loading}
          onChangePagination={_onChangePagination}
          onChangeSortCol={_onChangeSortCol}
          onChangeMarket={_onChangeMarket}
          onRefresh={_getMediaFileList}
          filter={filter}
          setFilter={setFilter}
          onDeleteFile={_onDeleteFile}
          isPropertyUser={isPropertyUser}
        />
      </div>
      {open ? (
        <ModalUploadFile onRefresh={_onRefresh} setOpen={setOpen} />
      ) : (
        <></>
      )}
    </MasterTemplate>
  );
};

export default MediaFileList;
