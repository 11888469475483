import React, { useContext } from "react";
import { Dropdown } from "semantic-ui-react";
import LocationCompanyIcon from "./icon/LocationCompanyIcon";
import { onChooseCompany } from "../../../services/AppServices";
import { PUBLIC_URL } from "../../../configs/Constants";
import classNames from "classnames";
import { LOGIN_TIMES } from "../../../../../configs/Constants";
import { UserCompanyType } from "../../../../User/configs/DeclareTypes";
import { useSelector } from "react-redux";
import {
  userCompanyChooseSelector,
  userCompanySelector,
} from "../../../../User/selectors/UserSelectors";
import { LocalizationContext } from "../../../../../locales/Translation";

type Props = {
  icon?: JSX.Element;
};

const SwitchCompany = ({ icon }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const userCompany: UserCompanyType[] = useSelector(userCompanySelector);
  const userCompanyChoose: UserCompanyType = useSelector(
    userCompanyChooseSelector
  );

  if (userCompany?.length < 2) {
    return null;
  }

  return (
    <Dropdown.Item>
      <Dropdown
        text={translations.switchCompany}
        icon={icon || <LocationCompanyIcon />}
        basic
        direction={"left"}
        className={"dropdown-switch-company__configuration"}
      >
        <Dropdown.Menu>
          {userCompany.map((item, index) => (
            <Dropdown.Item
              key={index}
              text={item.company_name}
              onClick={() => {
                localStorage.setItem(LOGIN_TIMES, String(0));
                onChooseCompany(item);
              }}
              icon={
                <img
                  width={16}
                  height={16}
                  alt={item.company_name}
                  src={item.logo || PUBLIC_URL + "/logo192.png"}
                />
              }
              className={classNames({
                active:
                  userCompanyChoose?.company_id === item.company_id &&
                  userCompanyChoose?.is_partner === item?.is_partner,
              })}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Dropdown.Item>
  );
};

export default SwitchCompany;
