import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import Action from "../CompanyTable/Action";
import TableCustom from "../../../../../../components/Table";
import {
  businessCompanyFilterSelector,
  businessCompanyListSelector,
} from "../../../../selectors/BusinessSelectors";
import { BusinessCompanyType } from "../../../../configs/DeclareTypes";
import ToggleSlider from "../../../../../../components/ToggleSlider";
import MarketFilterMultiple from "../../../../../../components/FilterMarket/MarketFilterMultiple";
import { businessCompanyCanBuyCreditThunk } from "../../../../thunks/BusinessThunks";

type Props = {
  loading: boolean;
  onChangeSortCol: any;
  onChangePagination: any;
  onChangeMarketFilter: any;
  onChangeVerifyPostPay: (id: number) => void;
  onRefresh: () => void;
};

const CompanyTable = ({
  loading,
  onChangeSortCol,
  onChangePagination,
  onChangeMarketFilter,
  onChangeVerifyPostPay,
  onRefresh,
}: Props) => {
  const { translations } = useContext(LocalizationContext);
  const businessCompanyList = useSelector(businessCompanyListSelector);
  const companyFilter = useSelector(businessCompanyFilterSelector);

  const dispatch = useDispatch();

  const columns = [
    { key: "business_company_by_name", col: "Company Name" },
    { key: "business_company_by_contact_name", col: "Contact Info" },
    { key: "has_credit_card", col: "Credit Card" },
    // {
    //   key: "business_company_by_contact_phone",
    //   col: "Contact Phone Number",
    // },
    { key: "business_company_by_market", col: "Market" },
    {
      key: "business_company_by_created_date",
      col: "Created Date",
    },
    { key: "business_company_by_type", col: "Type" },
    { key: "business_company_by_saas_fee", col: "Monthly SaaS Fee" },
    {
      key: "business_company_by_post_pay_verified",
      col: "Verified for post-pay",
    },
    {
      key: "business_company_by_auto_topup",
      col: "Auto top-up",
    },
    // {
    //   key: null,
    //   col: "Top-up available",
    // },
    { key: "business_company_by_status", col: translations.status },
    { key: null, col: translations.action },
  ];

  const _formatInputData = () => {
    return businessCompanyList?.items?.map(
      ({
        company_id,
        company_name,
        contact_name,
        contact_phone,
        market_name,
        status_name,
        post_pay_requested,
        post_pay_verified,
        type,
        created_date,
        status_id,
        has_saas_fee,
        total_saas_fee,
        has_auto_topup,
        business_admin_id,
        login_as_wms_user,
        has_credit_card,
        can_buy_credit,
      }: BusinessCompanyType) => {
        return {
          col1: company_name,
          col2: (
            <ul>
              <li>Name: {contact_name}</li>
              <li>Phone: {contact_phone}</li>
            </ul>
          ),
          hasCreditCard: has_credit_card ? "Yes" : "No",
          // col3: contact_phone,
          col4: market_name,
          col5: created_date,
          col6: type,
          col6a: has_saas_fee ? total_saas_fee : "",
          col7: (
            <ToggleSlider
              value={post_pay_verified}
              onChange={() => onChangeVerifyPostPay(company_id)}
            />
          ),
          col8: has_auto_topup ? translations.yes : translations.no,
          // col8a: (
          //   <ToggleSlider
          //     value={!can_buy_credit}
          //     onChange={() =>
          //       dispatch<any>(
          //         businessCompanyCanBuyCreditThunk(company_id, {
          //           can_buy_credit: !can_buy_credit,
          //         })
          //       )
          //     }
          //   />
          // ),
          col9: status_name,
          col10: (
            <Action
              companyName={company_name}
              businessAdminId={business_admin_id}
              loginAsWMS={login_as_wms_user}
              companyId={company_id}
              status={status_id}
              onRefresh={onRefresh}
            />
          ),
        };
      }
    );
  };

  return (
    <div
      className={"employee-table"}
      style={
        businessCompanyList?.items?.length < 3 ? { paddingBottom: 100 } : {}
      }
    >
      <TableCustom
        onChangeSortCol={onChangeSortCol}
        sortTable
        loading={loading}
        showTotal
        tableData={businessCompanyList ? _formatInputData() : []}
        columns={columns}
        orderBy={companyFilter.order_by}
        sortBy={companyFilter.sort_by}
        itemPerPage={businessCompanyList?.pagination?.limit}
        currentPage={companyFilter.page}
        totalItems={businessCompanyList?.pagination?.total}
        unit={"companies"}
        onChangePagination={onChangePagination}
        topRightComponent={
          <MarketFilterMultiple
            onChange={onChangeMarketFilter}
            values={companyFilter.market_code}
          />
        }
      />
    </div>
  );
};

export default CompanyTable;
