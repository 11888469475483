import { Accordion } from "semantic-ui-react";
import React, { useContext, useState } from "react";
import ButtonCustom from "../Button";
import "./style.scss";
import { LocalizationContext } from "../../locales/Translation";

type Props = {
  title?: any;
  content?: any;
};
const SimpleCollapseExpand = ({ title, content }: Props) => {
  const { translations } = useContext(LocalizationContext);

  const [activeIndex, setActiveIndex] = useState(-1);

  const handleCollapseExpand = () => {
    if (activeIndex === 0) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(0);
    }
  };

  return (
    <div className={"swal-text-in-box w-100"}>
      <Accordion className={"w-100 simple-collapse-expand"}>
        <Accordion.Title
          className={"d-flex justify-content-between p-0"}
          onClick={handleCollapseExpand}
          active={activeIndex === 0}
          index={0}
        >
          <>
            {title}
            <ButtonCustom className={"none-bg link p-0 m-0"}>
              {activeIndex === 0 ? translations.hide : translations.show}
            </ButtonCustom>
          </>
        </Accordion.Title>
        <Accordion.Content className={"scroll"} active={activeIndex === 0}>
          {content}
        </Accordion.Content>
      </Accordion>
    </div>
  );
};

export default SimpleCollapseExpand;
