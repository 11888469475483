import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalRightSideBarTemplate from "../ModalRightSideBarTemplate";
import { isModalMyProfileSelector } from "../../selectors/AppSelectors";
import { modalMyProfileAction } from "../../actions/AppActions";
import Edit from "./Edit";
import View from "./View";

const ModalUserProfile = () => {
  const dispatch = useDispatch();
  const isModalUserProfile = useSelector(isModalMyProfileSelector);
  const [editMode, setEditMode] = useState(false);

  return (
    <ModalRightSideBarTemplate
      trigger={null}
      onClose={() => {
        dispatch(modalMyProfileAction(false));
        setEditMode(false);
      }}
      modalClassName={"modal-edit-employee"}
      open={isModalUserProfile}
    >
      {editMode ? (
        <Edit setEditMode={setEditMode} />
      ) : (
        <View setEditMode={setEditMode} />
      )}
    </ModalRightSideBarTemplate>
  );
};

export default ModalUserProfile;
