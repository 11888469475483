import ModalRightSideBarTemplate from "../../../../../../../App/views/ModalRightSideBarTemplate";
import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deskimoPrivateOfficeModalSelector,
  propertyDetailSelector,
} from "../../../../../../selectors/PropertySelector";
import { deskimoModalPrivateOfficeAction } from "../../../../../../actions/PropertyActions";
import { Formik } from "formik";
import * as yup from "yup";
import { Modal, TextArea } from "semantic-ui-react";
import SizeBox from "../../../../../../../../components/SizeBox";
import Label from "../../../../../../../../components/Label";
import ButtonCustom from "../../../../../../../../components/Button";
import { LocalizationContext } from "../../../../../../../../locales/Translation";
import { PUBLIC_URL } from "../../../../../../../App/configs/Constants";
import InputCustom from "../../../../../../../../components/Input";
import Divide from "../../../../../../../../components/Divide";
import DropzoneComponent from "../../../DetailsTab/Components/DropzoneComponent";
import AmenityItemSelect from "../../../../../../../../components/AmenityItemSelect";
import { useParams } from "react-router-dom";
import {
  createPrivateOfficeService,
  editPrivateOfficeService,
  privateOfficeMangeDetailService,
} from "../../../../../../services/PropertyServices";
import {
  toastError,
  toastSuccess,
} from "../../../../../../../../components/Toast";
import {
  AMENITY_ROOM_TYPE,
  CREATE_TYPE,
} from "../../../../../../configs/Constants";
import { getPropertyAmenityListService } from "../../../../../../services/PropertyAmenityServices";
import BoxToggleSlider from "../../../../../../../../components/BoxToggleSlider";

type Props = {
  onRefresh: () => void;
};

const ModalCreatePrivateOffice = ({ onRefresh }: Props) => {
  const [loading, setLoading] = useState(false);
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const deskimoPrivateOfficeModal = useSelector(
    deskimoPrivateOfficeModalSelector
  );
  const { id: propertyId, companyId } = useParams();
  const [amenities, setAmenities] = useState<any>([]);
  const propertyDetail = useSelector(propertyDetailSelector);

  const [privateOfficeDetail, setPrivateOfficeDetail] = useState<any>(null);

  useEffect(() => {
    (async () => {
      const privateOfficeId = deskimoPrivateOfficeModal?.privateOfficeId;

      if (!deskimoPrivateOfficeModal.isOpen || !privateOfficeId) {
        return;
      }

      const { data } = await privateOfficeMangeDetailService(
        deskimoPrivateOfficeModal.companyId
          ? deskimoPrivateOfficeModal.companyId
          : companyId,
        privateOfficeId
      );
      setPrivateOfficeDetail(data);
      setAmenities(
        data.amenities.map(
          ({ id, name, uri, is_chosen, is_free, file_key }: any) => {
            return {
              id: id,
              name: name,
              icon_key: file_key,
              is_chosen,
              is_free,
              uri,
            };
          }
        )
      );
    })();
  }, [deskimoPrivateOfficeModal.isOpen]);

  useEffect(() => {
    (async () => {
      try {
        const amenityListFilter = {
          page: 1,
          item_per_page: 100,
          types: [AMENITY_ROOM_TYPE],
        };

        const { data } = await getPropertyAmenityListService(amenityListFilter);

        setAmenities(
          data.amenities.map((amenity: any) => {
            return {
              id: amenity?.id,
              name: amenity?.name,
              icon_key: amenity?.image_url,
              is_chosen: false,
              is_free: true,
              uri: amenity?.image_url,
            };
          })
        );
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const _onClose = () => {
    dispatch(deskimoModalPrivateOfficeAction({ isOpen: false }));
    setPrivateOfficeDetail(null);
  };

  const _handleSave = async (values: any) => {
    const payload = {
      ...values,
      amenities: amenities,
      property_id: deskimoPrivateOfficeModal?.propertyId
        ? deskimoPrivateOfficeModal?.propertyId
        : propertyId,
      company_id: deskimoPrivateOfficeModal.companyId
        ? deskimoPrivateOfficeModal.companyId
        : companyId,
    };

    setLoading(true);
    try {
      if (deskimoPrivateOfficeModal.type === CREATE_TYPE) {
        await createPrivateOfficeService(payload);
        toastSuccess("Created Successfully!");
        _onClose();
        onRefresh();
        return;
      }

      const privateOfficeId = deskimoPrivateOfficeModal?.privateOfficeId;

      if (!privateOfficeId) {
        return;
      }

      payload.private_office_id = privateOfficeId;
      await editPrivateOfficeService(privateOfficeId, payload);
      toastSuccess("Updated Successfully!");
      _onClose();
      onRefresh();
    } catch (response: any) {
      toastError(response?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const validate = (values: any) => {
    const errors: any = {};
    if (!values.daily_rate && !values.monthly_rate) {
      errors.daily_rate = "You need to enter at least one of the two fields";
      errors.monthly_rate = "You need to enter at least one of the two fields";
    }
    return errors;
  };

  return (
    <ModalRightSideBarTemplate
      trigger={null}
      onClose={_onClose}
      modalClassName={"modal-edit-employee"}
      open={deskimoPrivateOfficeModal?.isOpen}
    >
      <Formik
        enableReinitialize
        initialValues={{
          name: privateOfficeDetail?.name || "",
          daily_rate: privateOfficeDetail?.daily_rate || "",
          booking_requirement: privateOfficeDetail?.booking_requirement || "",
          capacity: privateOfficeDetail?.capacity || "",
          images: privateOfficeDetail?.images || [],
          position_guide: privateOfficeDetail?.position_guide || "",
          monthly_rate: privateOfficeDetail?.monthly_rate || "",
          area: privateOfficeDetail?.area || "",
          what_include: privateOfficeDetail?.what_include || "",
        }}
        validate={validate}
        validationSchema={yup.object().shape({
          name: yup.string().required().label("Private Office Name"),
          daily_rate: yup.string().label("Daily Rate"),
          monthly_rate: yup.string().label("Monthly Rate"),
          capacity: yup.string().required().label("Capacity"),
        })}
        onSubmit={_handleSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            <Modal.Header>
              <div>
                {deskimoPrivateOfficeModal?.type === CREATE_TYPE
                  ? "Create Private Office"
                  : "Edit Private Office"}
              </div>
              <ButtonCustom
                onClick={_onClose}
                className={"m-0 p-0 none-bg close-modal"}
              >
                <>
                  {translations.settings.close}
                  <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
                </>
              </ButtonCustom>
            </Modal.Header>
            <Modal.Content className={"scroll"}>
              <InputCustom
                label={"Private Office Name"}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.name}
                error={errors.name}
                name={"name"}
                labelRequired
              />
              <div className={"mb-16px"} />
              <InputCustom
                labelRequired={true}
                containerClass={"flex-1"}
                label={translations.dailyRate}
                value={values.daily_rate}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.daily_rate}
                error={errors.daily_rate}
                name={"daily_rate"}
                inputLeftIcon={
                  deskimoPrivateOfficeModal?.privateOfficeId
                    ? privateOfficeDetail?.currency
                    : propertyDetail?.currency
                }
                inputLeftIconBorder
                removeLetter
              />
              <div className={"mb-16px"} />
              <InputCustom
                labelRequired={true}
                containerClass={"flex-1"}
                label={translations.monthlyRate}
                value={values.monthly_rate}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.monthly_rate}
                error={errors.monthly_rate}
                name={"monthly_rate"}
                inputLeftIcon={
                  deskimoPrivateOfficeModal?.privateOfficeId
                    ? privateOfficeDetail?.currency
                    : propertyDetail?.currency
                }
                inputLeftIconBorder
                removeLetter
              />
              <div className={"mb-16px"} />
              <div className="d-flex gap-16">
                <InputCustom
                  labelRequired={true}
                  label={translations.capacity}
                  value={values.capacity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.capacity}
                  error={errors.capacity}
                  name={"capacity"}
                  inputLeftIcon={"People"}
                  inputLeftIconBorder
                  removeLetter
                />
                <InputCustom
                  label={"Office size"}
                  value={values.area}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.area}
                  error={errors.area}
                  name={"area"}
                  inputLeftIcon={
                    <span>
                      m<sup>2</sup>
                    </span>
                  }
                  inputLeftIconBorder
                  removeLetter
                />
              </div>
              <Label
                fontSize={"12px"}
                label={"What’s included?"}
                className={"sm"}
              />
              <TextArea
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.what_include}
                error={errors.what_include}
                name={"what_include"}
                className={"text-area"}
                rows={6}
                value={values.what_include}
              />
              <div className={"mb-16px"} />
              <Divide margin={"0"} type={"horizontal"} />
              <SizeBox height={"21px"} />
              <Label
                required={true}
                fontSize={"12px"}
                label={"Photos"}
                className={"sm"}
              />
              <DropzoneComponent
                onDragEnd={(
                  sourceId: any,
                  sourceIndex: any,
                  targetIndex: any
                ) => {
                  if (sourceIndex === targetIndex) return;

                  if (values.images) {
                    let newState = JSON.parse(JSON.stringify(values.images));
                    const sourceItem = newState[sourceIndex];

                    newState = newState.filter(
                      (image: any) => image.id !== sourceItem.id
                    );

                    if (sourceItem) {
                      newState.splice(targetIndex, 0, sourceItem);

                      newState.forEach((item: any, index: number) => {
                        item.weight = index;
                      });

                      setFieldValue("images", newState);
                    }
                  }
                }}
                isDragDropChangeOrder={true}
                value={values.images}
                deletePhotos={(id: any) => {
                  const newImages = values.images.filter(
                    (image: any) => image?.id !== id
                  );
                  setFieldValue("images", newImages);
                }}
                setPhotos={(data: any) =>
                  setFieldValue("images", [...values.images, ...data])
                }
              />
              <Divide type={"horizontal"} />
              <SizeBox height={"16px"} />
              <div>
                {amenities.map((amenity: any) => {
                  return (
                    <AmenityItemSelect
                      item={amenity}
                      key={amenity?.id}
                      onChangeCheck={(id: any) => {
                        const newState = amenities.map((item: any) => {
                          if (parseInt(item.id) === parseInt(id)) {
                            return { ...item, is_chosen: !item.is_chosen };
                          }

                          return item;
                        });
                        setAmenities(newState);
                      }}
                      onChangeFree={(id: any) => {
                        const newState = amenities.map((item: any) => {
                          if (parseInt(item.id) === parseInt(id)) {
                            return { ...item, is_free: !item.is_free };
                          }

                          return item;
                        });
                        setAmenities(newState);
                      }}
                    />
                  );
                })}
              </div>
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom height={"47px"} onClick={_onClose}>
                {translations.settings.cancel}
              </ButtonCustom>
              <ButtonCustom
                loading={loading}
                type={"submit"}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {translations.settings.save}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalRightSideBarTemplate>
  );
};

export default ModalCreatePrivateOffice;
