import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DESKIMO_USER_DETAIL_LINK } from "../../../../../../App/configs/ListRoutes";
import { Label as LabelSemantic, Popup } from "semantic-ui-react";
import classNames from "classnames";
import { transactionInfoSelector } from "../../../../../selectors/DashboardSelectors";
import { useSelector } from "react-redux";

type Props = {
  transactionId: number;
  userName: string;
  userId?: number;
  showAvatar?: boolean;
  avatarUrl?: string;
  capName?: string;
  isGuest?: boolean;
  isCompanyPaymentMethod?: number;
  companyName: string;
  mainVisitorName?: string;
};

const UserNameAndIndicator = ({
  transactionId,
  userId,
  userName,
  showAvatar,
  avatarUrl,
  capName,
  companyName,
  isCompanyPaymentMethod,
  isGuest,
  mainVisitorName,
}: Props) => {
  const transactionInfo = useSelector(transactionInfoSelector);

  const [info, setInfo] = useState<{
    transaction_id: string;
    is_business_admin: boolean;
    is_credit_plan: boolean;
    remaining_credit: string;
    is_auto_topup: boolean;
    is_trial: boolean;
    trial_expire_date: string;
    has_saas_fee: boolean;
    saas_fee: string;
    is_partner: boolean;
    is_new_user: boolean;
  } | null>(null);

  useEffect(() => {
    if (!transactionInfo?.length) {
      return;
    }

    const transaction = transactionInfo.find(
      ({ transaction_id }: any) => transaction_id === transactionId
    );
    setInfo(transaction);
  }, [transactionInfo, transactionId]);

  const _renderPopupContent = () => {
    if (info?.is_credit_plan && info?.is_auto_topup) {
      return (
        <span>
          {companyName}.<br />
          {info?.remaining_credit} credits plan with auto top-up
        </span>
      );
    }

    if (info?.is_trial) {
      return (
        <span>
          {companyName}.<br />
          Trial until {info?.trial_expire_date}
        </span>
      );
    }

    if (info?.has_saas_fee && !info?.is_credit_plan) {
      return (
        <span>
          {companyName}.<br />
          {info?.saas_fee} SaaS fee
        </span>
      );
    }

    return companyName;
  };

  const Avatar = () => {
    if (!showAvatar) {
      return null;
    }

    if (avatarUrl) {
      return <img className={"room-table-user-avatar"} src={avatarUrl} />;
    }

    return <div className={"room-table-user-avatar"}>{capName}</div>;
  };

  return (
    <div className={"dashboard__user-name"}>
      <>
        <Avatar />
        {userId ? (
          <Link
            className={"user-name"}
            to={DESKIMO_USER_DETAIL_LINK.replaceAll(":id", String(userId))}
          >
            {userName}
          </Link>
        ) : (
          <span className={"user-name"}>{userName}</span>
        )}

        {!info ? null : (
          <>
            {isCompanyPaymentMethod ? (
              <Popup
                trigger={
                  <span
                    className={classNames("business-payment-circle", {
                      yellow:
                        info?.is_credit_plan &&
                        !info?.is_auto_topup &&
                        !info?.is_trial &&
                        !info?.has_saas_fee,
                      blue: info?.has_saas_fee && !info?.is_credit_plan,
                      red: info?.is_trial,
                      green: info?.is_credit_plan && info?.is_auto_topup,
                    })}
                  >
                    B
                  </span>
                }
                content={_renderPopupContent()}
                position={"top center"}
                wide={"very"}
              />
            ) : null}
            {info?.is_business_admin && (
              <span className={"business-payment-circle blue"}>A</span>
            )}
            {info?.is_partner && (
              <Popup
                trigger={
                  <span className={"business-payment-circle blue-outline"}>
                    P
                  </span>
                }
                content={
                  <span>
                    Partner company:
                    <br />
                    {companyName}
                  </span>
                }
                position={"top center"}
              />
            )}
            {info?.is_new_user && (
              <Popup
                trigger={
                  <span className={"business-payment-circle yellow"}>N</span>
                }
                content={<span>First visit or booking of the user</span>}
                position={"top center"}
              />
            )}
          </>
        )}
        {"  "}
        {isGuest && (
          <Popup
            trigger={<LabelSemantic size={"mini"}>Guest</LabelSemantic>}
            content={mainVisitorName}
            position={"top center"}
            // wide={"very"}
          />
        )}
      </>
    </div>
  );
};

export default UserNameAndIndicator;
