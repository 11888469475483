import { Button } from "semantic-ui-react";
import "./style.scss";

interface Props {
  height?: string;
  padding?: string;
  fontSize?: string;
  className?: string;
  children?: string | JSX.Element | JSX.Element[];
  loading?: boolean;
  width?: string;
  [x: string]: any;
}

const ButtonCustom = ({
  height = "47px",
  padding,
  fontSize,
  children,
  className,
  width = "auto",
  ...props
}: Props) => {
  return (
    <Button
      style={{ height, padding, fontSize, width }}
      className={`button-custom ${className ? className : ""}`}
      {...props}
    >
      {children}
    </Button>
  );
};

export default ButtonCustom;
