import React from "react";
import { useSelector } from "react-redux";
import { campaignDetailSelector } from "../../../selectors/MarketingSelectors";
import { CampaignDetailType } from "../../../configs/DeclareTypes";
import SectionWhiteBoxItem from "../../../../../components/SectionWhiteBoxItem/SectionWhiteBoxItem";
import moment from "moment-timezone";
import { FORMAT_DATE_VARIANT_2 } from "../../../../App/configs/DateTimeConfigs";
import SizeBox from "../../../../../components/SizeBox";

const Summary = () => {
  const campaignDetail: CampaignDetailType = useSelector(
    campaignDetailSelector
  );

  return (
    <>
      <div className={"section-white-box_title"}>Campaign</div>
      <SizeBox height={"40px"} />
      <div className={"section-white-box-column"}>
        <SectionWhiteBoxItem
          label={"Campaign name"}
          value={campaignDetail.name}
        />
        <SectionWhiteBoxItem
          label={"In-app displayed name"}
          value={campaignDetail.display_name}
        />
      </div>
      <SectionWhiteBoxItem
        breakLine
        label={"Description"}
        value={campaignDetail.description || "-"}
      />
      <div className={"section-white-box-column"}>
        <SectionWhiteBoxItem
          label={"Duration"}
          value={`${moment
            .unix(campaignDetail.start_time)
            .tz(campaignDetail.timezone)
            .format(FORMAT_DATE_VARIANT_2)} - ${moment
            .unix(campaignDetail.end_time)
            .tz(campaignDetail.timezone)
            .format(FORMAT_DATE_VARIANT_2)}`}
        />
        <SectionWhiteBoxItem
          label={"Voucher code"}
          value={campaignDetail.voucher_code}
        />
      </div>

      <div className={"section-white-box-column"}>
        <SectionWhiteBoxItem
          label={"Number of vouchers available"}
          value={campaignDetail.vouchers_number_available || "-"}
        />
        {campaignDetail.distribution_type ? (
          <SectionWhiteBoxItem
            label={"Cost of promotion paid by"}
            value={campaignDetail.distribution_type || ""}
          />
        ) : null}
      </div>

      <div className={"section-white-box_line"} />
    </>
  );
};

export default Summary;
