import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import ModalCenterTemplate from "../../../../../App/views/ModalCenterTemplate";
import { Formik } from "formik";
import { Modal, TextArea } from "semantic-ui-react";
import ButtonCustom from "../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { useDispatch, useSelector } from "react-redux";
import {
  declineReasonsSelector,
  modalDeclineReasonsSelector,
} from "../../../../selectors/DashboardSelectors";
import {
  dashboardPropertyPendingBookingRemoveAction,
  modalDeclineReasonsAction,
} from "../../../../actions/DashboardActions";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import { bookingPropertyActionService } from "../../../../services/DashboardServices";
import { toastError, toastSuccess } from "../../../../../../components/Toast";

const ModalDeclineReason = () => {
  const { translations } = useContext(LocalizationContext);
  const modalDeclineReason = useSelector(modalDeclineReasonsSelector);
  const dispatch = useDispatch();
  const declineReasons = useSelector(declineReasonsSelector);

  const _handleSave = async (values: any) => {
    try {
      if (
        values?.value &&
        declineReasons.find((reason: any) => reason?.id === values?.value)
          ?.description &&
        !values.description
      ) {
        toastError("Please input the description");
        return;
      }
      const data = {
        ...modalDeclineReason?.data,
        reason_id: values?.value,
        description: values?.description,
      };
      await bookingPropertyActionService({
        url: modalDeclineReason?.data?.url,
        data,
      });
      dispatch<any>(
        dashboardPropertyPendingBookingRemoveAction(
          modalDeclineReason?.data?.booking_id
        )
      );
      dispatch(modalDeclineReasonsAction({ open: false }));
      toastSuccess("Declined successfully!");

      if (modalDeclineReason.callBack) {
        modalDeclineReason.callBack();
      }
    } catch (error: any) {
      toastError(error?.status?.message);
    }
  };

  const isDescription = (reasonId: any) => {
    return declineReasons.filter((reason: any) => reason.id === reasonId)[0]
      ?.description;
  };

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={() => dispatch(modalDeclineReasonsAction({ open: false }))}
      modalClassName={"modal-general"}
      open={modalDeclineReason.open}
    >
      <Formik
        enableReinitialize
        initialValues={{
          value: declineReasons[0]?.id,
          description: "",
        }}
        onSubmit={_handleSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            <Modal.Header>
              <span>{translations.rejectBooking}</span>
              <ButtonCustom
                onClick={() =>
                  dispatch(modalDeclineReasonsAction({ open: false }))
                }
                className={"modal-close-cta"}
              >
                <span>Close</span>
                <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
              </ButtonCustom>
            </Modal.Header>
            <Modal.Content className={"pb-0"}>
              <form className={"form-layout"}>
                <div className={"form-decline-text"}>
                  {translations.rejectBookingSelectReason}
                </div>
                <DropdownCustom
                  scrolling
                  selectUI
                  onChange={(e, data) => {
                    setFieldValue("value", data.value);
                    setFieldValue("description", "");
                  }}
                  value={values.value}
                  className={"w-100 form-decline-dropdown"}
                  options={declineReasons.map((reason: any) => {
                    return {
                      key: reason.id,
                      value: reason.id,
                      text: reason.text,
                    };
                  })}
                />
                {isDescription(values.value) ? (
                  <TextArea
                    placeholder={"Description"}
                    className={"text-area form-decline-textarea"}
                    rows={4}
                    value={values.description}
                    error={errors.description}
                    touched={touched.description}
                    name={"description"}
                    onChange={handleChange}
                    disabled={isSubmitting}
                  />
                ) : null}
              </form>
              <hr className={"form-decline-hr"} />
            </Modal.Content>
            <Modal.Actions className={"border-0"}>
              <ButtonCustom
                onClick={() =>
                  dispatch(modalDeclineReasonsAction({ open: false }))
                }
              >
                {translations.no}
              </ButtonCustom>
              <ButtonCustom
                loading={isSubmitting}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {translations.submitCancelBooking}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalCenterTemplate>
  );
};

export default ModalDeclineReason;
