import React, { useEffect, useState } from "react";
import { BookPropertyType } from "../../configs/DeclareType";
import ListCard from "../Components/ListCard";
import {
  handleBookDeskService,
  handleClickCardShowMarker,
} from "../../services/BookServices";
import { DESK_TYPE } from "../../configs/Constants";
import { propertyFavoriteThunk } from "../../thunks/BookThunks";
import { useDispatch, useSelector } from "react-redux";
import {
  propertyFilterKeywordSelector,
  propertyFilterSelector,
  propertyMapSelector,
} from "../../selectors/BookSelectors";
import {
  BOOK_DESK_DETAIL_LINK,
  BOOK_DESK_DETAIL_WITH_DEFAULT_DATE_LINK,
} from "../../../App/configs/ListRoutes";
import { Accordion } from "semantic-ui-react";
import moment from "moment";

const DeskListFavorite = () => {
  const dispatch = useDispatch();
  const propertyFilterKeyword = useSelector(propertyFilterKeywordSelector);
  const propertyFilter = useSelector(propertyFilterSelector);

  // Property map view get all items
  const propertyMap: BookPropertyType[] = useSelector(propertyMapSelector);

  const [isPrebook, setIsPrebook] = useState<boolean>(false);

  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (!propertyFilter?.end_time && isPrebook) {
      setIsPrebook(false);
      return;
    }

    if (propertyFilter?.end_time && !isPrebook) {
      setIsPrebook(true);
      return;
    }
  }, [propertyFilter?.end_time]);

  const _checkDataLength = () => {
    return propertyMap.some(({ is_favorite }) => is_favorite);
  };

  const _checkFavoriteLength = () => {
    return propertyMap.some(({ is_favorite }) => is_favorite);
  };

  useEffect(() => {
    setShow(_checkDataLength());
  }, [propertyMap]);

  if (!show) {
    return null;
  }

  return (
    <Accordion>
      <Accordion.Title
        active={!_checkFavoriteLength() ? true : open}
        index={0}
        onClick={() => setOpen((prevState) => !prevState)}
      >
        Favorites
        <i className="uil uil-angle-down" />
      </Accordion.Title>
      <Accordion.Content active={!_checkFavoriteLength() ? true : open}>
        <>
          {propertyMap
            .filter(
              ({ name, type, address, property_address = "" }) =>
                name
                  .toLowerCase()
                  .includes(propertyFilterKeyword.toLowerCase()) ||
                type
                  .toLowerCase()
                  .includes(propertyFilterKeyword.toLowerCase()) ||
                property_address
                  .toLowerCase()
                  .includes(propertyFilterKeyword.toLowerCase()) ||
                address
                  .toLowerCase()
                  .includes(propertyFilterKeyword.toLowerCase())
            )
            .map((item) => {
              if (!item.is_favorite) {
                return null;
              }

              const {
                id,
                name,
                thumbnail,
                address,
                type,
                open_time,
                full_day_price,
                is_favorite,
                lat,
                lng,
                star,
                reviewer_count,
                price,
                currency_code,
                is_open,
                allow_desk_limitation_condition,
                available_desk,
                display_new_label,
              } = item;

              return (
                <ListCard
                  id={id}
                  key={id}
                  name={name}
                  office={type}
                  address={address}
                  openTime={open_time}
                  thumbnailUrl={thumbnail}
                  rating={star ? `${star} (${reviewer_count})` : ""}
                  price={full_day_price}
                  unit={"day"}
                  lat={lat}
                  lng={lng}
                  isFavorite={Boolean(is_favorite)}
                  bookCallback={() => handleBookDeskService(item)}
                  onClickCallback={() =>
                    handleClickCardShowMarker({
                      lat,
                      lng,
                      id,
                      name,
                      address,
                      type: DESK_TYPE,
                      openTime: open_time,
                      rating: star,
                      price: full_day_price,
                      unit: "day",
                      isFavorite: is_favorite,
                      office: type,
                      thumbnailUrl: thumbnail,
                    })
                  }
                  favoriteCallback={() =>
                    dispatch<any>(
                      propertyFavoriteThunk({
                        id: id,
                        isFavorite: is_favorite,
                      })
                    )
                  }
                  goToDetailLink={
                    propertyFilter?.defaultDate
                      ? BOOK_DESK_DETAIL_WITH_DEFAULT_DATE_LINK.replaceAll(
                          ":id",
                          String(id)
                        ).replaceAll(
                          ":defaultDate",
                          String(moment(propertyFilter?.defaultDate).unix())
                        )
                      : BOOK_DESK_DETAIL_LINK.replaceAll(":id", String(id))
                  }
                  isClosed={
                    !is_open ||
                    (allow_desk_limitation_condition && available_desk === 0)
                  }
                  newBadge={display_new_label}
                />
              );
            })}
        </>
      </Accordion.Content>
    </Accordion>
  );
};

export default DeskListFavorite;
