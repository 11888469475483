import SizeBox from "../../../../../../../components/SizeBox";
import React, { useState, useContext, useEffect } from "react";
import EditActions from "./EditActions";
import { useDispatch, useSelector } from "react-redux";
import { propertyDetailSelector } from "../../../../../selectors/PropertySelector";
import Avatar from "../../../../../../../components/Avatar";
import ButtonCustom from "../../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../../App/configs/Constants";
import {
  SWAL_CUSTOM_CONTENT,
  SWAL_DELETE_OPTION,
} from "../../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../../components/SweetAlert";
import { toastError } from "../../../../../../../components/Toast";
import { LocalizationContext } from "../../../../../../../locales/Translation";
import { updatePropertyService } from "../../../../../services/PropertyServices";
import { getPropertyDetailThunk } from "../../../../../thunks/PropertyThunks";
import { useParams } from "react-router-dom";
import { modalAddStaffAction } from "../../../../../actions/PropertyActions";
import ModalAddStaff from "./ModalAddStaff";
import ModalCreateStaff from "./ModalCreateStaff";
import { Dropdown, Label } from "semantic-ui-react";
import DropdownCustom from "../../../../../../../components/DropdownCustom";
import {
  onLogInAsAccount,
  onLoginAsWMSUser,
} from "../../../../../../User/services/UserServices";
import {
  DESKIMO_PROPERTY_STAFF_LINK,
  DESKIMO_USERS_LINK,
} from "../../../../../../App/configs/ListRoutes";
import {
  makePropertyAdmin,
  propertyStaffReInviteService,
} from "../../../../../services/PropertyStaffServices";
import SwalSuccess from "../../../../../../../components/SweetAlert/Templates/SwalSuccess";
import LoaderCustom from "../../../../../../../components/Loader";
import { ROLE_PROPERTY_ADMIN } from "../../../../../configs/Constants";
import { ROLE_DESKIMO_ADMIN_ID } from "../../../../../../User/configs/Constants";
import { MyProfileType } from "../../../../../../User/configs/DeclareTypes";
import { myProfileSelector } from "../../../../../../App/selectors/AppSelectors";

const PropertyStaff = () => {
  const propertyDetail = useSelector(propertyDetailSelector);
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [staffs, setStaffs] = useState<any>([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const myProfile: MyProfileType = useSelector(myProfileSelector);
  const isDeskimoAdmin = myProfile?.role_id === ROLE_DESKIMO_ADMIN_ID;
  const isPropertyAdmin = myProfile?.role_id === ROLE_PROPERTY_ADMIN;
  useEffect(() => {
    if (propertyDetail?.property_staffs) {
      setStaffs(propertyDetail?.property_staffs);
    }
  }, [propertyDetail]);

  const handleDeleteStaff = async (userId: any) => {
    const iconHtml = (
      <img
        src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
        className={"swal2-icon__error"}
      />
    );
    const icon = null;
    const title = translations.propertyPage.unassignUserMsg;
    const confirmText = translations.unAssign;
    const options = SWAL_DELETE_OPTION({
      title,
      iconHtml,
      icon,
      confirmText,
    });
    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        const payload = {
          property_staffs: staffs.filter(
            (staff: any) => staff?.user_id !== userId
          ),
          property_id: id,
        };
        setLoading(true);
        try {
          await updatePropertyService(payload);
          await dispatch<any>(getPropertyDetailThunk(id ? id : ""));
        } catch (error: any) {
          toastError(error?.status?.message);
        } finally {
          setLoading(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  const handleSave = async (newStaffList: any) => {
    const payload = {
      property_staffs: newStaffList,
      property_id: id,
    };
    setLoading(true);
    try {
      await updatePropertyService(payload);
      await dispatch<any>(getPropertyDetailThunk(id ? id : ""));
    } catch (response: any) {
      console.log(response);
      toastError(response?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const _addStaff = async (newStaff: any) => {
    const newStaffList = [...staffs, newStaff];
    // setStaffs([...staffs, newStaff]);
    await handleSave(newStaffList);
  };

  const _onReinviteUser = async (entitlementId: any, companyId: any) => {
    setLoading(true);
    try {
      await propertyStaffReInviteService({
        user_id: String(entitlementId),
        company_id: String(companyId),
      });
      SweetAlertCommon({
        options: SWAL_CUSTOM_CONTENT({
          content: <SwalSuccess title={translations.inviteResent} />,
          cancelButtonClass: "d-none",
          denyButtonClass: "d-none",
          confirmButtonText: (
            <ButtonCustom className={"primary w-100"} height={"36px"}>
              {translations.done}
            </ButtonCustom>
          ),
        }),
        confirmCallback() {
          return null;
        },
        cancelCallback() {
          return null;
        },
      });
    } catch (e) {
      toastError("Reinvite unsuccessful");
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const _onMakeAdmin = async (userId: string) => {
    setLoading(true);
    try {
      await makePropertyAdmin(userId);
      await dispatch<any>(getPropertyDetailThunk(id ? id : ""));
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className={"flex-1 pl-38 property-staff__wrapper"}
        style={{ marginTop: 17, marginBottom: 36 }}
      >
        <div
          className={"d-flex justify-content-between flex-1 align-items-center"}
        >
          <div className={"section-title"} style={{ lineHeight: "37px" }}>
            {translations.propertyStaff}
          </div>
        </div>
        <SizeBox height={"25px"} className={"d-sm-none"} />
        <div className={"property-detail__list-staff"}>
          {loading ? <LoaderCustom /> : null}

          {staffs.length > 0
            ? staffs.map((staff: any, index: number) => {
                return (
                  <div key={index} className={"staff-item"}>
                    <div className={"staff-item__avatar"}>
                      <Avatar
                        size={"100%"}
                        imgUrl={staff?.avatar}
                        capName={staff?.cap_name}
                        name={staff?.full_name}
                      />
                    </div>
                    <div className="staff-item__name">{staff?.full_name}</div>
                    <div className="staff-item__role d-flex">
                      {staff?.is_property_admin
                        ? translations.propertyAdmin
                        : translations.propertyStaff}

                      <>
                        {staff?.user_status ? (
                          <Label
                            size={"mini"}
                            color={
                              staff?.user_status == "Active" ? "blue" : "red"
                            }
                            content={staff?.user_status}
                          />
                        ) : null}
                        <>
                          {isPropertyAdmin || isDeskimoAdmin ? (
                            <DropdownCustom
                              disabled={loading}
                              className={"w-auto"}
                              direction={"left"}
                              icon={null}
                              trigger={
                                <ButtonCustom
                                  style={{ width: "36px", height: "36px" }}
                                  className={"primary-alt"}
                                >
                                  <i
                                    style={{ fontSize: "21px" }}
                                    className="uil uil-ellipsis-h"
                                  ></i>
                                </ButtonCustom>
                              }
                            >
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() =>
                                    _onReinviteUser(
                                      staff?.property_company_id,
                                      staff?.company_id
                                    )
                                  }
                                  text={translations.reInviteUser}
                                  icon={
                                    <i
                                      className="uil uil-plus-circle icon"
                                      style={{ fontSize: "16px" }}
                                    />
                                  }
                                />
                                <Dropdown.Item
                                  onClick={() =>
                                    _onMakeAdmin(staff?.property_company_id)
                                  }
                                  text={
                                    staff?.is_property_admin
                                      ? "Revoke Admin"
                                      : "Make Admin"
                                  }
                                  icon={
                                    <i
                                      className="uil uil-key-skeleton icon"
                                      style={{ fontSize: "16px" }}
                                    />
                                  }
                                />
                                <Dropdown.Item
                                  onClick={() =>
                                    handleDeleteStaff(staff?.user_id)
                                  }
                                  text={"Unassign from property"}
                                  icon={
                                    <i
                                      className="uil uil-times icon"
                                      style={{ fontSize: "16px" }}
                                    />
                                  }
                                />

                                {staff?.login_on_behalf && isDeskimoAdmin ? (
                                  <Dropdown.Item
                                    onClick={async () => {
                                      await onLogInAsAccount(
                                        staff?.user_id,
                                        DESKIMO_PROPERTY_STAFF_LINK,
                                        staff?.company_id
                                      );
                                    }}
                                    text={translations.loginUserAccount}
                                    icon={
                                      <img
                                        src={
                                          PUBLIC_URL +
                                          "/assets/icons/icon-log-out-circle.svg"
                                        }
                                      />
                                    }
                                  />
                                ) : null}
                              </Dropdown.Menu>
                            </DropdownCustom>
                          ) : (
                            <></>
                          )}
                        </>
                      </>
                    </div>
                  </div>
                );
              })
            : null}

          <div className={"text-center"}>
            {isPropertyAdmin || isDeskimoAdmin ? (
              <>
                <SizeBox height={"16px"} />
                <ButtonCustom
                  onClick={() => dispatch(modalAddStaffAction(true))}
                  className={"none-bg edit-link add-staff-cta"}
                  fontSize={"14px"}
                >
                  <>
                    Add Staff
                    <i className="uil uil-plus ml-10px" />
                  </>
                </ButtonCustom>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <ModalAddStaff addStaff={_addStaff} />
      <ModalCreateStaff />
    </>
  );
};

export default PropertyStaff;
