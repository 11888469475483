import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import SectionWhiteBoxItem from "../../../../../components/SectionWhiteBoxItem/SectionWhiteBoxItem";
import { DeskimoUserDetailType } from "../../../configs/DeclareTypes";
import { useSelector } from "react-redux";
import { employeeDetailSelector } from "../../../selectors/EmployeeSelectors";
import UserCredit from "./UserCredit";
import SizeBox from "../../../../../components/SizeBox";

const LANGUAGE_FORMAT = {
  en: "English",
  id: "Bahasa",
};

const SYSTEM_FORMAT = {
  ios: "iOS",
  android: "Android",
};

const UserDetail = () => {
  const { translations } = useContext(LocalizationContext);

  const {
    date_time_register,
    system,
    version,
    language,
    personal_account_balance,
    lifetime_usage,
    lifetime_spend,
    number_of_booking,
    access_pool_budget,
    credit,
  }: DeskimoUserDetailType = useSelector(employeeDetailSelector);

  return (
    <>
      <div className={"user_detail_section"}>
        <h2 className={"section-white-box_title"}>
          {translations.userDetails}
        </h2>
        <div className={"section-white-box-column"}>
          <div>
            <SectionWhiteBoxItem
              label={translations.datetimeOfRegistration}
              value={date_time_register}
            />
            {/*<SectionWhiteBoxItem*/}
            {/*  label={translations.system}*/}
            {/*  // eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
            {/*  value={SYSTEM_FORMAT[system]}*/}
            {/*/>*/}
            <SectionWhiteBoxItem
              label={translations.systemLanguage}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              value={LANGUAGE_FORMAT[language]}
            />
            {/*<SectionWhiteBoxItem*/}
            {/*  label={translations.latestAppVersion}*/}
            {/*  value={version}*/}
            {/*/>*/}
            <UserCredit />
            {personal_account_balance?.length > 1 ? (
              <>
                <SectionWhiteBoxItem
                  label={translations.personalAccountBalance}
                  value={" "}
                  containerClass={"user_detail_number_booking_wrapper mb-8px"}
                  valueContainerClass={"user_detail_number_booking"}
                />
                {personal_account_balance.map(
                  ({ company_name, personal_account_balance }, index) => {
                    return (
                      <div key={index} className={"user-company-credit__line"}>
                        <span className={"middle-dot"}>·</span>
                        <span>
                          {personal_account_balance} ({company_name})
                        </span>
                      </div>
                    );
                  }
                )}
                <SizeBox height={"16px"} />
              </>
            ) : (
              <SectionWhiteBoxItem
                label={translations.personalAccountBalance}
                value={personal_account_balance.map(
                  ({ company_name, personal_account_balance }) =>
                    `${personal_account_balance} (${company_name})`
                )}
                containerClass={"user_detail_number_booking_wrapper"}
                valueContainerClass={"user_detail_number_booking"}
              />
            )}

            {!access_pool_budget?.length ? null : access_pool_budget?.length ===
              1 ? (
              <SectionWhiteBoxItem
                label={translations.hasAccessPooledBudget}
                value={
                  access_pool_budget[0]?.has_monthly_budget
                    ? `${translations.yes}, ${access_pool_budget[0]?.remaining_budget} (${access_pool_budget[0]?.company_name})`
                    : `${translations.no} (${access_pool_budget[0]?.company_name})`
                }
                containerClass={"user_detail_number_booking_wrapper"}
                valueContainerClass={"user_detail_number_booking"}
              />
            ) : (
              <>
                <SectionWhiteBoxItem
                  label={translations.hasAccessPooledBudget}
                  value={" "}
                  containerClass={"user_detail_number_booking_wrapper mb-8px"}
                  valueContainerClass={"user_detail_number_booking"}
                />
                {access_pool_budget.map(
                  (
                    { remaining_budget, has_monthly_budget, company_name },
                    index
                  ) => {
                    return (
                      <div key={index} className={"user-company-credit__line"}>
                        <span className={"middle-dot"}>·</span>
                        {has_monthly_budget ? (
                          <>
                            <span>{translations.yes}, </span>
                            {remaining_budget} ({company_name})
                          </>
                        ) : (
                          <>
                            <span>{translations.no} </span>({company_name})
                          </>
                        )}
                      </div>
                    );
                  }
                )}
                <SizeBox height={"16px"} />
              </>
            )}
          </div>
          <div>
            <SectionWhiteBoxItem
              label={translations.lifetimeUsage}
              value={lifetime_usage}
            />
            <SectionWhiteBoxItem
              containerClass={"mb-8px"}
              label={translations.lifetimeSpend}
              value={lifetime_spend}
            />
            {/*<div className={"user-company-credit__line"}>*/}
            {/*  <span className={"middle-dot"}>·</span>*/}
            {/*  <span style={{ width: 156, display: "inline-block" }}>*/}
            {/*    Used onboarding credits:{" "}*/}
            {/*  </span>{" "}*/}
            {/*  SGD 150.00*/}
            {/*</div>*/}
            {/*<div className={"user-company-credit__line"}>*/}
            {/*  <span className={"middle-dot"}>·</span>*/}
            {/*  <span style={{ width: 156, display: "inline-block" }}>*/}
            {/*    Used vouchers:{" "}*/}
            {/*  </span>{" "}*/}
            {/*  SGD 300.00*/}
            {/*</div>*/}
            {/*<div className={"user-company-credit__line"}>*/}
            {/*  <span className={"middle-dot"}>·</span>*/}
            {/*  <span style={{ width: 156, display: "inline-block" }}>*/}
            {/*    Paid:{" "}*/}
            {/*  </span>{" "}*/}
            {/*  SGD 250.00*/}
            {/*</div>*/}
            {/*<SizeBox height={"16px"} />*/}
            <SectionWhiteBoxItem
              containerClass={"mb-8px"}
              label={translations.numberOfUses}
              value={
                number_of_booking.number_of_desk_walkin +
                number_of_booking.number_of_desk_hotdesk_booking +
                number_of_booking.number_of_desk_meeting_room_booking +
                " time(s)"
              }
              // containerClass={"user_detail_number_booking_wrapper"}
              valueContainerClass={"user_detail_number_booking"}
            />
            <div className={"user-company-credit__line"}>
              <span className={"middle-dot"}>·</span>
              <span style={{ width: 156, display: "inline-block" }}>
                Walk-in(s):{" "}
              </span>{" "}
              {number_of_booking.number_of_desk_walkin}
            </div>
            <div className={"user-company-credit__line"}>
              <span className={"middle-dot"}>·</span>
              <span style={{ width: 156, display: "inline-block" }}>
                Day Pass(es):{" "}
              </span>{" "}
              {number_of_booking.number_of_desk_hotdesk_booking}
            </div>
            <div className={"user-company-credit__line"}>
              <span className={"middle-dot"}>·</span>
              <span style={{ width: 156, display: "inline-block" }}>
                Meeting Room(s):{" "}
              </span>{" "}
              {number_of_booking.number_of_desk_meeting_room_booking}
            </div>
          </div>
        </div>
      </div>
      <div className={"section-white-box_line info"} />
    </>
  );
};

export default UserDetail;
