import {
  getPaidCreditTransactionService,
  getPaidCreditUsageService,
} from "../services/SettingServices";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import {
  getPaidCreditTransactionAction,
  getPaidCreditUsageAction,
} from "../actions/PrepaidCreditActions";

type CreditTransaction = {
  page: number;
  keyword?: string;
  statuses?: any;
  filters?: any;
};

export const getCreditTransactionThunk = ({
  page,
  keyword,
  statuses,
}: CreditTransaction) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const payload = {
      page,
      keyword,
      statuses,
    };
    const response = await getPaidCreditTransactionService(payload);
    dispatch<any>(getPaidCreditTransactionAction(response?.data));
  };
};

export const getCreditUsageThunk = ({ page, keyword }: CreditTransaction) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const payload = {
      page,
      keyword,
    };
    const response = await getPaidCreditUsageService(payload);
    dispatch<any>(getPaidCreditUsageAction(response?.data));
  };
};
