import TableCustom from "../../../../../../../components/Table";
import React, { useState } from "react";
import WebhookAction from "./WebhookAction";
import WebhookUrl from "./WebhookUrl";
import { useDispatch, useSelector } from "react-redux";
import { propertyIntegrationsWebhookListSelector } from "../../../../../selectors/PropertySelector";
import moment from "moment";
import {
  propertyIntegrationsWebhookSendTestService,
  propertyIntegrationsWebhookStatusService,
  propertyIntegrationsWebhookUpdateURLService,
} from "../../../../../services/PropertyIntegrationServices";
import { useParams } from "react-router-dom";
import { SWAL_DELETE_OPTION } from "../../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../../components/SweetAlert";
import {
  toastError,
  toastSuccess,
} from "../../../../../../../components/Toast";
import { getPropertyIntegrationsWebhookListThunk } from "../../../../../thunks/PropertyThunks";
import WebhookActions from "./WebhookActions";
import { isValidUrl } from "../../../../../../../utils/commons";

const WebHookSection = () => {
  const listWebhook = useSelector(propertyIntegrationsWebhookListSelector);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const _onSaveNewUrl = async (webhookId: any, newUrl: any) => {
    try {
      if (!id) return;

      if (!isValidUrl(newUrl)) {
        toastError("The Url is invalid");
        return;
      }

      const payload = {
        webhook_url: newUrl,
      };
      await propertyIntegrationsWebhookUpdateURLService(id, webhookId, payload);
      await dispatch<any>(getPropertyIntegrationsWebhookListThunk(id));
      toastSuccess("Update Webhook Url successfully!");
    } catch (error: any) {
      console.log("Error: ", error);
      toastError(error?.status?.message);
    }
  };

  const _onChangeStatus = async (isChecked: any, webhookId: any) => {
    try {
      if (!id) return;
      const payload = {
        status: isChecked,
      };
      const icon = null;
      const title = `Are you sure you want to ${
        isChecked ? "Activate" : "Deactivate"
      } this Webhook?`;
      const confirmText = "Confirm";
      const confirmClass = isChecked ? "primary" : "danger";
      const options = SWAL_DELETE_OPTION({
        title,
        icon,
        confirmText,
        confirmClass,
      });
      SweetAlertCommon({
        options,
        confirmCallback: async () => {
          setLoading(true);
          await propertyIntegrationsWebhookStatusService(
            id,
            webhookId,
            payload
          );
          await dispatch<any>(getPropertyIntegrationsWebhookListThunk(id));
          setLoading(false);
          toastSuccess(
            isChecked ? "Successfully Activated" : "Successfully Deactivated"
          );
        },
        cancelCallback: () => null,
      });
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const _onSend = async (webhookId: any) => {
    setLoading(true);
    try {
      if (!id) return;

      await propertyIntegrationsWebhookSendTestService(id, webhookId);
      toastSuccess("Send a test successfully!");
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const decorateTableData = () => {
    if (listWebhook.length > 0) {
      return listWebhook.map((item: any, index: number) => {
        return {
          col1: <span className={"fw-500"}>{item?.name}</span>,
          col2: item?.last_trigger
            ? moment(item?.last_trigger * 1000).format("DD/MM/YYYY")
            : "-",
          col3: (
            <WebhookUrl
              webhook={item}
              onSaveNewUrl={_onSaveNewUrl}
              url={item?.webhook_url}
            />
          ),
          col4: (
            <WebhookAction
              item={item}
              onChangeStatus={_onChangeStatus}
              status={item?.status}
            />
          ),
          col5: <WebhookActions onSend={_onSend} item={item} />,
        };
      });
    }
    return [];
  };

  return (
    <div className={"deskimo-tab-section"}>
      <div className="deskimo-tab-title">Webhook</div>
      <TableCustom
        loading={loading}
        showTotal={false}
        tableData={decorateTableData()}
        columns={[
          "Action Name",
          "Last Trigger",
          "Webhook URL",
          "Activation",
          "Actions",
        ]}
      />
    </div>
  );
};

export default WebHookSection;
