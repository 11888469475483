export const CREDIT_TRANSACTION_RECORD_PAYMENT_ACTION =
  "CREDIT_TRANSACTION_RECORD_PAYMENT_ACTION";
export const CREDIT_TRANSACTION_CHANGE_EXPIRY_ACTION =
  "CREDIT_TRANSACTION_CHANGE_EXPIRY_ACTION";
export const CREDIT_TRANSACTION_PAY_WITH_CC_ACTION =
  "CREDIT_TRANSACTION_PAY_WITH_CC_ACTION";
export const CREDIT_TRANSACTION_STOP_AUTO_TOPUP_ACTION =
  "CREDIT_TRANSACTION_STOP_AUTO_TOPUP_ACTION";
export const CREDIT_TRANSACTION_CANCEL_CREDIT_PACKAGE_ACTION =
  "CREDIT_TRANSACTION_CANCEL_CREDIT_PACKAGE_ACTION";

export const CREDIT_PENDING_STATUS = 0;
export const CREDIT_PAID_STATUS = 1;
export const CREDIT_EXPIRED_STATUS = 2;
export const CREDIT_CANCELLED_STATUS = 3;
export const CREDIT_STRIPE_LINK_EXPIRED_STATUS = 4;
export const CREDIT_FREE_PAID_STATUS = 5;
