import React, { useContext, useEffect, useState } from "react";
import { Tab } from "semantic-ui-react";
import { LocalizationContext } from "../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import {
  deskBookingPayloadSelector,
  listBookingCompletedPayloadSelector,
  refreshDeskBookingSelector,
} from "../../selectors/BookingSelectors";
import { getDeskBookingListThunk } from "../../thunks/BookingThunks";
import { Link } from "react-router-dom";
import { DESK_BOOKING_LINK } from "../../../App/configs/ListRoutes";
import UpcomingTab from "../DeskBooking/Components/UpcomingTab";
import CompletedTab from "../DeskBooking/Components/CompletedTab";
import CancelledTab from "../DeskBooking/Components/CancelledTab";
import {
  listBookingCompletedAction,
  listDeskBookingPayloadAction,
  onGoingDataAction,
} from "../../actions/BookingActions";
import { userCompanyChooseSelector } from "../../../User/selectors/UserSelectors";
import OngoingTab from "./Components/OngoingTab";
import {
  getBookingCompletedService,
  ongoingDataService,
} from "../../services/BookingServices";

const DeskBooking = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const deskBookingPayload = useSelector(deskBookingPayloadSelector);
  const [loading, setLoading] = useState(false);
  const refreshDeskBookingList = useSelector(refreshDeskBookingSelector);
  const userCompanyChoose = useSelector(userCompanyChooseSelector);
  const listBookingCompletedPayload = useSelector(
    listBookingCompletedPayloadSelector
  );

  useEffect(() => {
    _fetchListDeskBooking();
  }, [
    deskBookingPayload,
    refreshDeskBookingList,
    userCompanyChoose,
    listBookingCompletedPayload,
  ]);

  const _fetchListDeskBooking = async () => {
    setLoading(true);
    try {
      if (deskBookingPayload.status === 0) {
        const response = await ongoingDataService();
        if (response?.data?.length === 0) {
          return;
        }
        if (response.data) {
          dispatch(onGoingDataAction(response.data));
        }
        return;
      }

      if (deskBookingPayload.status === 2) {
        const payload = {
          page: listBookingCompletedPayload.page,
          is_completed: true,
        };
        const response = await getBookingCompletedService(payload);
        if (response?.data) {
          dispatch(listBookingCompletedAction(response.data));
        }
        return;
      }

      const payload = {
        status: deskBookingPayload?.status,
        page: deskBookingPayload?.page,
      };
      await dispatch<any>(getDeskBookingListThunk(payload));
    } catch (error) {
      console.log("error: ", error);
      await dispatch<any>(getDeskBookingListThunk(undefined, true));
    } finally {
      setLoading(false);
    }
  };

  const panes = [
    {
      menuItem: {
        as: Link,
        id: "tab1",
        to: DESK_BOOKING_LINK,
        content: translations.ongoing,
        exact: true,
        key: 0,
      },
      render: () => <OngoingTab />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab1",
        to: DESK_BOOKING_LINK,
        content: translations.upcoming,
        exact: true,
        key: 1,
      },
      render: () => <UpcomingTab loading={loading} setLoading={setLoading} />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab2",
        to: DESK_BOOKING_LINK,
        content: translations.completed,
        exact: true,
        key: 2,
      },
      render: () => <CompletedTab loading={loading} setLoading={setLoading} />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab3",
        to: DESK_BOOKING_LINK,
        content: translations.cancelled,
        exact: true,
        key: 3,
      },
      render: () => <CancelledTab loading={loading} setLoading={setLoading} />,
    },
  ];

  return (
    <Tab
      menu={{ text: true }}
      activeIndex={deskBookingPayload?.status}
      onTabChange={(e, { activeIndex }) => {
        dispatch(
          listDeskBookingPayloadAction({
            status: activeIndex,
          })
        );
      }}
      panes={panes}
      className={"tab-custom p-0"}
    />
  );
};

export default DeskBooking;
