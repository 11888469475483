import React, { useContext, useEffect, useState } from "react";
import ButtonCustom from "../../../../../../components/Button";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import InputCustom from "../../../../../../components/Input";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { employeeEditInlineService } from "../../../../services/EmployeeServices";
import { useDispatch } from "react-redux";
import { toastError } from "../../../../../../components/Toast";
import { employeeEditInlineAction } from "../../../../actions/EmployeeActions";
import { LocalizationContext } from "../../../../../../locales/Translation";
import {
  addCommaToNumber,
  numberCommaFormat,
} from "../../../../../../services/FormatTextService";

type Props = {
  budgetName: string;
  setLoading?: any;
  id?: any;
  canNotModify: boolean;
};

const NONE_BUDGET = 0;
const UNLIMITED_BUDGET = "Unlimited";

const OPTION_CAPPED = 1;
const OPTION_UNCAPPED = 2;

const MonthlyBudget = ({ budgetName, id, canNotModify }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const [edit, setEdit] = useState<boolean>(false);
  const [monthlyBudget, setMonthlyBudget] = useState<string | number>(
    budgetName !== UNLIMITED_BUDGET ? budgetName : NONE_BUDGET
  );
  const [value, setValue] = useState<any>(
    budgetName !== UNLIMITED_BUDGET ? OPTION_CAPPED : OPTION_UNCAPPED
  );

  useEffect(() => {
    setValue(budgetName !== UNLIMITED_BUDGET ? OPTION_CAPPED : OPTION_UNCAPPED);
    setMonthlyBudget(
      budgetName !== UNLIMITED_BUDGET ? budgetName : NONE_BUDGET
    );
  }, [budgetName]);

  useEffect(() => {
    if (innerWidth < 768) {
      return;
    }

    const elOpen = document.querySelectorAll(".monthly-budget");

    const tableHeaderEl = document.querySelector(
      ".employee-table thead tr th:nth-child(3)"
    );

    const tableBodyEl = document.querySelectorAll(
      ".employee-table tbody tr td:nth-child(3)"
    );

    if (!tableHeaderEl || !tableBodyEl) {
      return;
    }

    if (elOpen.length) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tableHeaderEl.style.width = "300px";

      tableBodyEl.forEach((item) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        item.style.width = "300px";
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tableHeaderEl.style.width = "";

      tableBodyEl.forEach((item) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        item.style = "";
      });
    }
  }, [edit]);

  const dispatch = useDispatch();

  const options = [
    {
      key: "Capped",
      text: translations.statusCapped,
      value: OPTION_CAPPED,
    },
    {
      key: "Uncapped",
      text: translations.statusUncapped,
      value: OPTION_UNCAPPED,
    },
  ];

  const _onSave = () => {
    try {
      (async () => _onChangeMonthlyBudget())();
    } catch (error) {
      console.error(error);
    }
  };

  const _onChangeMonthlyBudget = async () => {
    try {
      if (monthlyBudget < 0) {
        toastError(translations.monthlyBudgetGreaterZero);
        return;
      }

      const payload = {
        employee_id: id,
        monthly_budget:
          value === 1
            ? isNaN(monthlyBudget as number) ||
              (monthlyBudget as string).length === 0
              ? -1
              : monthlyBudget
            : -1,
      };

      await employeeEditInlineService(payload);
      dispatch(employeeEditInlineAction(payload));
      setEdit(false);
      if (value === OPTION_CAPPED) {
        setMonthlyBudget((prevState: any) => parseFloat(prevState).toFixed(2));
      } else {
        setMonthlyBudget(translations.statusUncapped);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {edit ? (
        <div className={"monthly-budget"}>
          <DropdownCustom
            options={options}
            value={value}
            onChange={(e, { value }) => {
              setValue(value);
            }}
            selectUI
            className={"monthly-budget__select"}
          />

          {value === OPTION_CAPPED ? (
            <InputCustom
              value={monthlyBudget}
              type={"number"}
              onChange={(e: any, data: any) => setMonthlyBudget(data.value)}
              containerClass={"monthly-budget__input"}
            />
          ) : null}

          <div className={"btn-group"}>
            <button className={"btn"} onClick={() => setEdit(false)}>
              <img
                src={PUBLIC_URL + "/assets/icons/icon-times-circle-red.svg"}
              />
            </button>
            <button className={"btn"} onClick={_onSave}>
              <img
                src={PUBLIC_URL + "/assets/icons/icon-check-circle-blue.svg"}
              />
            </button>
          </div>
        </div>
      ) : (
        <div className={"d-flex align-items-center"}>
          {isNaN(monthlyBudget as number)
            ? translations.statusUncapped
            : numberCommaFormat(monthlyBudget)}

          {canNotModify ? null : (
            <ButtonCustom
              className={"link none-bg edit-link px-0 mr-0 ml-4px"}
              onClick={() => setEdit(true)}
            >
              <i className="uil uil-edit" />
            </ButtonCustom>
          )}
        </div>
      )}
    </>
  );
};

export default MonthlyBudget;
