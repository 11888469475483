import { PUBLIC_URL } from "../../../configs/Constants";
import { getPendingBookingNotificationService } from "../../../services/AppServices";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DASHBOARD_DESKIMO_LINK } from "../../../configs/ListRoutes";
import { DASHBOARD_PROPERTY_TYPE } from "../../../../Dashboard/configs/DeclareTypes";
import { useSelector } from "react-redux";
import { dashboardPropertyChooseSelector } from "../../../../Dashboard/selectors/DashboardSelectors";
import { myProfileSelector } from "../../../selectors/AppSelectors";
import { ROLE_DESKIMO_ADMIN_ID } from "../../../../User/configs/Constants";

const BellNotification = () => {
  const [notificationAmount, setNotificationAmount] = useState(0);
  const dashboardPropertyChoose: DASHBOARD_PROPERTY_TYPE = useSelector(
    dashboardPropertyChooseSelector
  );
  const myProfile = useSelector(myProfileSelector);

  useEffect(() => {
    fetchPendingBookingNotification();
  }, [dashboardPropertyChoose]);

  useEffect(() => {
    const interval = setInterval(async () => {
      fetchPendingBookingNotification();
    }, 10000);

    return () => clearInterval(interval);
  }, [dashboardPropertyChoose]);

  const fetchPendingBookingNotification = async () => {
    const payload = {
      market: null,
      property:
        myProfile?.role_id === ROLE_DESKIMO_ADMIN_ID
          ? null
          : dashboardPropertyChoose?.property_id,
      company: null,
    };
    const response = await getPendingBookingNotificationService(payload);
    if (response?.data?.notification_number >= 0) {
      setNotificationAmount(response?.data?.notification_number);
    }
  };

  return (
    <Link to={DASHBOARD_DESKIMO_LINK} className={"bell-notification"}>
      <img src={PUBLIC_URL + "/assets/icons/icon-bell.png"} alt="" />
      {notificationAmount > 0 ? (
        <span className={"bell-notification-amount"}>{notificationAmount}</span>
      ) : null}
    </Link>
  );
};

export default BellNotification;
