import DropdownCustom from "../../../../../components/DropdownCustom";
import React, { useState } from "react";

const DropdownPaymentMethod = ({ onChange }: any) => {
  const [paymentMethod, setPaymentMethod] = useState("manually");

  return (
    <DropdownCustom
      className={"credit-record-dropdown dropdown-fullwidth-common-style"}
      squareUI
      options={[
        { key: 1, text: "Manual", value: "manually" },
        { key: 2, text: "Credit card", value: "credit_card" },
        { key: 3, text: "Bank transfer", value: "bank" },
      ]}
      value={paymentMethod}
      onChange={(e, { value }: any) => {
        onChange(value);
        setPaymentMethod(value);
      }}
      placeholder={"Select payment"}
    />
  );
};

export default DropdownPaymentMethod;
