import React, { useContext } from "react";
import SizeBox from "../../../../../components/SizeBox";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  LIST_AMENITY,
  LIST_FREE_AMENITY,
  LIST_PAID_AMENITY,
} from "../../../configs/Constants";
import Label from "../../../../../components/Label";
import { LocalizationContext } from "../../../../../locales/Translation";

type Props = {
  items: any;
  setItems: any;
  setFree: any;
  free: any;
  paid: any;
  setPaid: any;
};
const Amenities = ({
  setItems,
  items,
  paid,
  setPaid,
  setFree,
  free,
}: Props) => {
  const { translations } = useContext(LocalizationContext);

  const onDragEnd = (result: any) => {
    const { source, destination, reason } = result;
    if (!destination) {
      return;
    }

    if (
      reason === "DROP" &&
      destination &&
      source &&
      source.droppableId !== destination.droppableId
    ) {
      //Remove Item from Source Column
      let sourceItem: any;
      switch (source.droppableId) {
        case LIST_AMENITY:
          sourceItem = items[source.index];
          if (sourceItem) {
            setItems(items.filter((item: any) => item.id !== sourceItem.id));
          }
          break;
        case LIST_FREE_AMENITY:
          sourceItem = free[source.index];
          if (sourceItem) {
            setFree(free.filter((item: any) => item.id !== sourceItem.id));
          }
          break;
        case LIST_PAID_AMENITY:
          sourceItem = paid[source.index];
          if (sourceItem) {
            setPaid(paid.filter((item: any) => item.id !== sourceItem.id));
          }
          break;
      }
      //Add Item into Destination column
      switch (destination.droppableId) {
        case LIST_AMENITY:
          if (sourceItem) {
            setItems([
              ...items,
              { ...sourceItem, is_chosen: false, is_free: false },
            ]);
          }
          break;
        case LIST_FREE_AMENITY:
          if (sourceItem) {
            setFree([
              ...free,
              { ...sourceItem, is_chosen: true, is_free: true },
            ]);
          }
          break;
        case LIST_PAID_AMENITY:
          if (sourceItem) {
            setPaid([
              ...paid,
              { ...sourceItem, is_chosen: true, is_free: false },
            ]);
          }
          break;
      }
    }
  };

  return (
    <div className="section">
      <SizeBox height={"16px"} />
      {/*<div className={"section-title"}>Amenities</div>*/}
      <div className={"dnd-wrapper"}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={LIST_AMENITY}>
            {(provided) => (
              <div className={"amenity-column"}>
                <Label
                  label={translations.moveAmenitiesByDraggingAndDropping}
                  fontSize={"12px"}
                  className={"fw-500"}
                  labelGuide={"ddf"}
                />
                <SizeBox height={"20px"} />
                <div ref={provided.innerRef} className={"amenity-list"}>
                  {items.map((item: any, index: number) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          className={"amenity-item"}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className={"amenity-item__image"}>
                            <img src={item.uri} alt="item.content" />
                          </div>
                          {item.name}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
          <Droppable droppableId={LIST_FREE_AMENITY}>
            {(provided, snapshot) => (
              <div className={"amenity-column"}>
                <Label
                  label={translations.freeAmenities}
                  fontSize={"12px"}
                  className={"fw-500"}
                />
                <SizeBox height={"20px"} />
                <div ref={provided.innerRef} className={"amenity-list free"}>
                  {free.map((item: any, index: number) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          className={"amenity-item"}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className={"amenity-item__image"}>
                            <img src={item.uri} alt="item.content" />
                          </div>
                          {item.name}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
          <Droppable droppableId={LIST_PAID_AMENITY}>
            {(provided, snapshot) => (
              <div className={"amenity-column"}>
                <Label
                  label={translations.paidAmenities}
                  fontSize={"12px"}
                  className={"fw-500"}
                />
                <SizeBox height={"20px"} />
                <div ref={provided.innerRef} className={"amenity-list paid"}>
                  {paid.map((item: any, index: number) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          className={"amenity-item"}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className={"amenity-item__image"}>
                            <img src={item.uri} alt="item.content" />
                          </div>
                          {item.name}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default Amenities;
