import React from "react";
import Label from "../../../../../components/Label";
import InputCustom from "../../../../../components/Input";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import SizeBox from "../../../../../components/SizeBox";

type Props = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
  handleChange: any;
};

const WMSConfiguration = ({
  values,
  errors,
  touched,
  isSubmitting,
  handleChange,
}: Props) => {
  return (
    <>
      <Label
        label={"WMS Configuration"}
        fontSize={"24px"}
        className={"section-title"}
      />
      <div className={"detail_divide"}>
        <div className={"left_section"}>
          <InputCustom
            containerClass={"mb-0"}
            label={"Number of free trial days"}
            value={values.number_of_trial_day}
            error={errors.number_of_trial_day}
            touched={touched.number_of_trial_day}
            name={"number_of_trial_day"}
            onChange={handleChange}
            disabled={isSubmitting}
            inputLeftIcon={"Days"}
            inputLeftIconBorder
            removeLetter
          />
        </div>

        <div className={"right_section"}>
          <InputCustom
            containerClass={"mb-0"}
            label={"WMS price per user"}
            value={values.wms_price_per_user}
            error={errors.wms_price_per_user}
            touched={touched.wms_price_per_user}
            name={"wms_price_per_user"}
            onChange={handleChange}
            disabled={isSubmitting}
            inputLeftIcon={"USD"}
            inputLeftIconBorder
            removeLetter
          />
        </div>
      </div>

      <SizeBox height={"24px"} />
      <div className={"property_company_detail_line"} />
    </>
  );
};

export default WMSConfiguration;
