import { Routes, Route } from "react-router-dom";
import {
  BU_ONBOARD_LINK,
  PENDING_INVITE_ACCEPT_LINK,
  PENDING_INVITE_REJECT_LINK,
} from "../../App/configs/ListRoutes";
import BusinessUserOnboard from "../views/BusinessUserOnboard";
import { TYPE_ACCEPT, TYPE_REJECT } from "./Constants";
import BusinessUserPendingInvite from "../views/BusinessUserPendingInvite";

const OnboardRoutes = () => {
  return (
    <Routes>
      <Route path={BU_ONBOARD_LINK} element={<BusinessUserOnboard />} />
      <Route
        path={PENDING_INVITE_ACCEPT_LINK}
        element={<BusinessUserPendingInvite type={TYPE_ACCEPT} />}
      />
      <Route
        path={PENDING_INVITE_REJECT_LINK}
        element={<BusinessUserPendingInvite type={TYPE_REJECT} />}
      />
    </Routes>
  );
};

export default OnboardRoutes;
