export const POST_ROOM_BOOKING_LIST_API =
  "/api/v1/business-com/meeting-room-bookings";

export const POST_DESK_BOOKING_LIST_API =
  "/api/v1/business-com/hotdesk/bookings";

export const POST_CANCEL_ROOM_API = "/api/v1/meeting-rooms/booking/cancel";

export const POST_CANCEL_DESK_API =
  "/api/v1/hot-desk-bookings/request-cancellation";

export const GET_ON_GOING_API = "/api/v1/check-in/on-going";
export const POST_ON_GOING_API = "/api/v1/guest/ongoing-visit/%ongoingId%";
export const POST_BOOKING_COMPLETED_API =
  "/api/v1/property/transactions/history";
