import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import SearchBar4Table from "../../../../../components/SearchBar4Table";
import { debounce } from "lodash";

type Props = {
  onSearch: any;
  placeholder?: string;
};

const Search = ({
  onSearch,
  placeholder = "Search by campaign name, voucher code",
}: Props) => {
  const { translations } = useContext(LocalizationContext);

  const debounce_onChange = debounce((e, { value }) => {
    onSearch(value);
  }, 500);

  return (
    <SearchBar4Table placeholder={placeholder} onChange={debounce_onChange} />
  );
};

export default Search;
