import React, { useState } from "react";
import "./style.scss";
import ButtonCustom from "../Button";
import CheckboxCustom from "../Checkbox";
import classNames from "classnames";
import classnames from "classnames";

type CheckBoxType = { id: number | string; name: string };

type Props = {
  label?: string;
  icon?: JSX.Element;
  listCheckBox: CheckBoxType[];
  onChange: any;
  values: string[] | number[];
  disabled?: boolean;
  className?: string;
  checkAll?: any;
  clearAll?: any;
};

const DropdownWithCheckboxMenuWithTotal = ({
  label,
  icon = <></>,
  listCheckBox,
  onChange,
  values,
  disabled,
  className,
  checkAll,
  clearAll,
}: Props) => {
  const [open, setOpen] = useState(false);
  const menu =
    listCheckBox?.length > 0
      ? listCheckBox.map((checkbox: CheckBoxType, index: number) => {
          return (
            <div key={index} className={"dropdown-with-checkbox__row"}>
              <CheckboxCustom
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                checked={values.includes(checkbox?.id)}
                value={checkbox?.id}
                onChange={onChange}
                reverseText={true}
                label={checkbox?.name}
                disabled={disabled}
              />
            </div>
          );
        })
      : null;

  return (
    <div className={classnames("dropdown-with-checkbox", className)}>
      <ButtonCustom
        height={"44px"}
        className={classNames("filter", {
          show: open,
        })}
        onClick={() => setOpen(!open)}
      >
        <span className={"mr-10px"}>{label}</span>
        {icon}
      </ButtonCustom>
      {open ? (
        <>
          <div
            className={"dropdown-with-checkbox__overlay"}
            onClick={() => setOpen(false)}
          />
          <div className={"dropdown-with-checkbox__menu"}>
            {checkAll ? (
              <div
                style={{
                  marginBottom: 18,
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 4,
                }}
              >
                <span onClick={checkAll} className={"text-link link"}>
                  Check All
                </span>
                |
                <span onClick={clearAll} className={"fw-500 link"}>
                  Clear
                </span>
              </div>
            ) : null}

            <div>{menu}</div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default DropdownWithCheckboxMenuWithTotal;
