import React, { useRef, useState } from "react";
import InputCustom from "../../../../../../../components/Input";
import ButtonCustom from "../../../../../../../components/Button";
import { fileValidation } from "../../../../../../../utils/commons";
import { toastError } from "../../../../../../../components/Toast";
import { uploadImageService } from "../../../../../../App/services/AppServices";
import { PUBLIC_URL } from "../../../../../../App/configs/Constants";
import SizeBox from "../../../../../../../components/SizeBox";

const EditLocationGuideItem = ({
  item,
  locationGuide,
  setLocationGuide,
}: any) => {
  const pictureRef = useRef<any>(null);
  const [picture, setPicture] = useState(item?.picture);

  return (
    <div className={"edit-location-item"}>
      <div className={"edit-location-item-input-row"}>
        <InputCustom
          label={"Step Title"}
          value={item?.title}
          onChange={(e: any, data: any) => {
            setLocationGuide((prevState: any) => {
              const newState = JSON.parse(JSON.stringify(prevState));
              newState.forEach((location: any) => {
                if (location?.id === item.id) {
                  location.title = e.target.value;
                }
              });
              return newState;
            });
          }}
        />
        <InputCustom
          label={"Description"}
          value={item?.description}
          onChange={(e: any, data: any) => {
            setLocationGuide((prevState: any) => {
              const newState = JSON.parse(JSON.stringify(prevState));
              newState.forEach((location: any) => {
                if (location?.id === item.id) {
                  location.description = e.target.value;
                }
              });
              return newState;
            });
          }}
        />
        <input
          className={"d-none"}
          ref={pictureRef}
          type="file"
          accept="image/*"
          onChange={async (event: any) => {
            const isFileValid = fileValidation(event.target.files);

            if (isFileValid) {
              const formData = new FormData();
              formData.append("avatar", event.target.files[0]);

              try {
                const response = await uploadImageService(formData);
                setPicture(response?.data?.uri);
                setLocationGuide((prevState: any) => {
                  const newState = JSON.parse(JSON.stringify(prevState));
                  newState.forEach((location: any) => {
                    if (location?.id === item.id) {
                      location.img_key = response?.data?.avatar_uri;
                      location.picture = response?.data?.uri;
                    }
                  });
                  return newState;
                });
              } catch (error) {
                console.log("Error: ", error);
              }
            } else {
              toastError("The file must be smaller than 2MB");
            }
          }}
        />
      </div>
      <div>
        <>
          <ButtonCustom
            onClick={(e: any) => {
              e.preventDefault();
              pictureRef?.current?.click();
            }}
            className={"default"}
            height={"36px"}
          >
            <>
              Upload Image
              <i className="uil uil-upload-alt" />
            </>
          </ButtonCustom>
          {!item?.picture ? (
            <span className={"no-image"}>No image uploaded</span>
          ) : null}
        </>
        {item?.picture ? (
          <div className={"edit-location-item-img-box"}>
            <SizeBox height={"16px"} />
            <img
              className={"edit-location-item-img"}
              src={picture ? picture : item?.picture}
            />
            <span
              onClick={() => {
                setLocationGuide((prevState: any) => {
                  const newState = JSON.parse(JSON.stringify(prevState));
                  newState.forEach((location: any) => {
                    if (location?.id === item.id) {
                      location.picture = "";
                      location.img_key = "";
                    }
                  });
                  return newState;
                });
              }}
              className={"edit-location-item-img-delete-cta"}
            >
              <img
                src={PUBLIC_URL + "/assets/icons/icon-x-circle.png"}
                alt=""
              />
            </span>
          </div>
        ) : null}
      </div>
      <div
        onClick={() => {
          setLocationGuide(
            locationGuide.filter((location: any) => location.id !== item.id)
          );
        }}
        className={"delete-location-guide-cta"}
      >
        <i className="uil uil-times" /> Delete
      </div>
    </div>
  );
};

export default EditLocationGuideItem;
