import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useContext, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import {
  BUSINESS_COMPANY_EDIT_LINK,
  BUSINESS_COMPANY_DETAIL_LINK,
  BUSINESS_COMPANY_LIST_LINK,
} from "../../../../../App/configs/ListRoutes";
import { SWAL_DELETE_OPTION } from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import ButtonCustom from "../../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import { businessCompanyDeleteThunk } from "../../../../thunks/BusinessThunks";
import { companyPublishService } from "../../../../../Property/services/PropertyCompanyServices";
import { modalUpdateAutoTopUpAction } from "../../../../actions/BusinessActions";
import {
  onLogInAsAccount,
  onLoginAsWMSUser,
} from "../../../../../User/services/UserServices";

type Props = {
  companyName: string;
  companyId: number;
  loginAsWMS: string;
  businessAdminId: number | null;
  status: number;
  onRefresh: () => void;
};

const Action = ({
  companyName,
  companyId,
  status,
  onRefresh,
  businessAdminId,
  loginAsWMS,
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { translations } = useContext(LocalizationContext);
  const [disabled, setDisabled] = useState(false);

  const _onEditUser = async () => {
    try {
      navigate(BUSINESS_COMPANY_EDIT_LINK.replaceAll(":id", String(companyId)));
    } catch (e) {
      console.log(e);
    }
  };

  const _onDetail = () => {
    navigate(BUSINESS_COMPANY_DETAIL_LINK.replaceAll(":id", String(companyId)));
  };

  const handlePublishCompany = (action: number) => {
    const icon = null;
    const title =
      action > 0
        ? `Are you sure you want to publish the company ${companyName}?`
        : `Are you sure you want to un-publish the company ${companyName}?`;
    const confirmText =
      action > 0
        ? translations.propertyPage.publish
        : translations.propertyPage.unPublish;
    const confirmClass = "primary";
    const options = SWAL_DELETE_OPTION({
      title,
      icon,
      confirmText,
      confirmClass,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setDisabled(true);
        try {
          const payload = {
            company_id: companyId,
            status: action,
          };
          await companyPublishService(payload);
          toastSuccess(
            action > 0
              ? "Successfully published the company"
              : "Successfully un-published the company"
          );
          await onRefresh();
        } catch (error: any) {
          if (error?.status?.message) {
            toastError(error?.status?.message);
          }
        } finally {
          setDisabled(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  const _onDelete = async () => {
    const options = SWAL_DELETE_OPTION({
      title: `Are you sure want to delete the company ${companyName}?`,
      iconHtml: (
        <img
          src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
          className={"swal2-icon__error"}
        />
      ),
      confirmText: translations.delete,
      cancelText: translations.cancel,
    });
    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setDisabled(true);
        try {
          await dispatch<any>(businessCompanyDeleteThunk(companyId));
          await onRefresh();
          toastSuccess("Deleted Successfully");
        } catch (error: any) {
          if (error?.status?.code === 10000) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            const { confirm_code, remaining_credit } = error?.data;
            _onDeleteWithWarning(confirm_code, remaining_credit);
            return;
          }
          toastError(error?.status?.message);
        } finally {
          setDisabled(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  const _onDeleteWithWarning = (
    confirmCode: string,
    remaining_credit: string
  ) => {
    if (!confirmCode) {
      return;
    }
    const options = SWAL_DELETE_OPTION({
      title: `The company ${companyName} has ${remaining_credit} in remaining credits that will expire once the company is deleted. Do you want to continue?`,
      iconHtml: (
        <img
          src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
          className={"swal2-icon__error"}
        />
      ),
      confirmText: "Yes, delete",
      cancelText: translations.cancel,
    });
    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setDisabled(true);
        try {
          await dispatch<any>(
            businessCompanyDeleteThunk(companyId, { confirm_code: confirmCode })
          );
          await onRefresh();
          toastSuccess("Deleted Successfully");
        } catch (response: any) {
          toastError(response?.status?.message);
        } finally {
          setDisabled(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  const _openModalUpdateAutoTopUp = async () => {
    dispatch(modalUpdateAutoTopUpAction({ open: true, data: companyId }));
  };

  return (
    <DropdownCustom
      disabled={disabled}
      className={"table-action-dropdown"}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={_onDetail}
          text={"Details"}
          icon={<img src={PUBLIC_URL + "/assets/icons/icon-message.svg"} />}
        />
        <Dropdown.Item
          onClick={_onEditUser}
          text={"Edit Company"}
          icon={
            <i className="uil uil-edit icon" style={{ fontSize: "16px" }} />
          }
        />

        {status ? (
          <Dropdown.Item
            onClick={() => handlePublishCompany(0)}
            text={translations.propertyPage.unPublish}
            icon={
              <i
                className="uil uil-toggle-off icon"
                style={{ fontSize: "16px" }}
              />
            }
          />
        ) : (
          <Dropdown.Item
            onClick={() => handlePublishCompany(1)}
            text={translations.propertyPage.publish}
            icon={
              <i
                className="uil uil-toggle-on icon"
                style={{ fontSize: "16px" }}
              />
            }
          />
        )}

        <Dropdown.Item
          onClick={_onDelete}
          text={"Delete Company"}
          icon={
            <i
              className="uil uil-trash-alt icon"
              style={{ fontSize: "16px" }}
            />
          }
        />

        <Dropdown.Item
          onClick={_openModalUpdateAutoTopUp}
          text={"Change auto top-up information"}
          icon={
            <i className="uil uil-edit icon" style={{ fontSize: "16px" }} />
          }
        />

        {businessAdminId ? (
          <Dropdown.Item
            onClick={() =>
              onLogInAsAccount(
                businessAdminId,
                BUSINESS_COMPANY_LIST_LINK,
                companyId
              )
            }
            text={translations.loginUserAccount}
            icon={
              <img src={PUBLIC_URL + "/assets/icons/icon-log-out-circle.svg"} />
            }
          />
        ) : null}

        {loginAsWMS ? (
          <Dropdown.Item
            onClick={() => onLoginAsWMSUser(loginAsWMS)}
            text={translations.loginWMSAccount}
            icon={
              <img src={PUBLIC_URL + "/assets/icons/icon-log-out-circle.svg"} />
            }
          />
        ) : null}
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default Action;
