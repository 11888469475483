const WorkspacesIcon = ({
  strokeColor = "#21252A",
  isActive = false,
}: {
  strokeColor?: string;
  isActive?: boolean;
}) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16667 19.25H12.8333M17.4167 19.25V4.58333C17.4167 4.0971 17.2235 3.63079 16.8797 3.28697C16.5359 2.94315 16.0696 2.75 15.5833 2.75H6.41667C5.93044 2.75 5.46412 2.94315 5.1203 3.28697C4.77649 3.63079 4.58333 4.0971 4.58333 4.58333V19.25H17.4167ZM17.4167 19.25H19.25H17.4167ZM17.4167 19.25H12.8333H17.4167ZM4.58333 19.25H2.75H4.58333ZM4.58333 19.25H9.16667H4.58333ZM8.25 6.41667H9.16667H8.25ZM8.25 10.0833H9.16667H8.25ZM12.8333 6.41667H13.75H12.8333ZM12.8333 10.0833H13.75H12.8333ZM9.16667 19.25V14.6667C9.16667 14.4236 9.26324 14.1904 9.43515 14.0185C9.60706 13.8466 9.84022 13.75 10.0833 13.75H11.9167C12.1598 13.75 12.3929 13.8466 12.5648 14.0185C12.7368 14.1904 12.8333 14.4236 12.8333 14.6667V19.25H9.16667Z"
        stroke={isActive ? "#003DBC" : strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WorkspacesIcon;
