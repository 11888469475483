import React, { useContext, useState } from "react";
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from "react-joyride";
import {
  dashboardUserGuideSteps,
  JoyrideStyles,
} from "../../configs/UserGuide";
import { LocalizationContext } from "../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import {
  isModalAddEmployeeSelector,
  isModalImportEmployeeSelector,
  userGuideSelector,
} from "../../../App/selectors/AppSelectors";
import {
  modalAddEmployeeAction,
  modalImportEmployeeAction,
  modalPackageTopUpAction,
  userGuideAction,
} from "../../../App/actions/AppActions";
import {
  BOOK_DESK_LINK,
  EMPLOYEES_BUSINESS_LINK,
} from "../../../App/configs/ListRoutes";
import { userCompanyChooseSelector } from "../../../User/selectors/UserSelectors";
import { useNavigate } from "react-router-dom";

const UserGuideDashboard = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isModalAddEmployee = useSelector(isModalAddEmployeeSelector);
  const isModalImportEmployee = useSelector(isModalImportEmployeeSelector);

  const userGuide = useSelector(userGuideSelector);
  const userCompanyChoose = useSelector(userCompanyChooseSelector);

  const [steps, setSteps] = useState<any>(
    dashboardUserGuideSteps(translations, dispatch)
  );

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      dispatch(modalPackageTopUpAction(false));
      dispatch(userGuideAction({ key: "run", value: false }));

      if (!userCompanyChoose?.has_employee) {
        navigate(EMPLOYEES_BUSINESS_LINK, { replace: true });
        return;
      }

      if (!userCompanyChoose?.has_completed_transaction) {
        navigate(BOOK_DESK_LINK, { replace: true });
        return;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      if (!isModalAddEmployee && index === 2) {
        dispatch(modalAddEmployeeAction(true));
        setTimeout(() => {
          dispatch(userGuideAction({ key: "stepIndex", value: nextStepIndex }));
          dispatch(userGuideAction({ key: "run", value: true }));
        }, 400);
        return;
      } else if (!isModalImportEmployee && index === 3) {
        dispatch(modalAddEmployeeAction(false));
        dispatch(modalImportEmployeeAction(true));
        setTimeout(() => {
          dispatch(userGuideAction({ key: "stepIndex", value: nextStepIndex }));
          dispatch(userGuideAction({ key: "run", value: true }));
        }, 400);
        return;
      } else if (index === 4) {
        window.scrollTo({ top: 0 });
        dispatch(modalImportEmployeeAction(false));
      }
      // else if (index === 5) {
      //   dispatch(modalPackageTopUpAction(true));
      // }
      else if (index === 0 || index === 1) {
        window.scrollTo({ top: 0 });
      }
      dispatch(userGuideAction({ key: "stepIndex", value: nextStepIndex }));
    }
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      run={userGuide.run}
      scrollToFirstStep
      steps={steps}
      stepIndex={userGuide.stepIndex}
      styles={JoyrideStyles}
      hideCloseButton
      hideBackButton
      disableOverlayClose
    />
  );
};

export default UserGuideDashboard;
