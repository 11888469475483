import { Routes, Route } from "react-router-dom";
import EmployeeBusiness from "../views/EmployeeBusiness";
import EmployeeProperty from "../views/EmployeeProperty";
import EmployeeDeskimo from "../views/UsersDeskimo";
import UserDetails from "../views/UserDetails";
import {
  EMPLOYEES_BUSINESS_LINK,
  DESKIMO_USERS_LINK,
  DESKIMO_USER_DETAIL_LINK,
  EMPLOYEES_PROPERTY_LINK,
  PARTNER_TEAM_LINK,
} from "../../App/configs/ListRoutes";
import PartnerTeams from "../views/PartnerTeams";

const UserRoutes = () => {
  return (
    <Routes>
      <Route path={EMPLOYEES_BUSINESS_LINK} element={<EmployeeBusiness />} />
      <Route path={EMPLOYEES_PROPERTY_LINK} element={<EmployeeProperty />} />
      <Route path={DESKIMO_USERS_LINK} element={<EmployeeDeskimo />} />
      <Route path={DESKIMO_USER_DETAIL_LINK} element={<UserDetails />} />
      <Route path={PARTNER_TEAM_LINK} element={<PartnerTeams />} />
    </Routes>
  );
};

export default UserRoutes;
