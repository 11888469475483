export const API_VERIFY_TOKEN = "/api/v1/entitlement/validate-token";
export const API_ENTITLEMENT_SET_PASSWORD = "/api/v1/entitlement/set-password";
export const GET_OTP = "/api/v1/otp";
export const API_GET_USER_PHONE = "/api/v1/pending-request-invitation/extract";
export const API_PENDING_INVITE_GET_OTP =
  "api/v1/pending-request-invitation/otp-send/%hashToken%";
export const API_PENDING_INVITE_SUBMIT_OTP =
  "api/v1/pending-request-invitation/otp-verify/%hashToken%";
export const API_ENTITLEMENT_VALIDATE_PHONE =
  "/api/v1/entitlement/validate-phone";

export const API_GET_PACKAGE_TRIAL = "/api/v3/trial-package/%companyId%";
export const API_GET_RECOMMENDED_PROPERTIES =
  "/api/v3/property/recommendation/%type%";
