import LoginTemplate from "../../../App/views/LoginTemplate";
import { useNavigate, Navigate } from "react-router-dom";
import { forgotPasswordService } from "../../services/AuthenticationServices";
import { useSelector } from "react-redux";
import { isAuthSelector } from "../../selectors/AuthenticationSelectors";
import { toastError, toastSuccess } from "../../../../components/Toast";
import { useContext } from "react";
import { LocalizationContext } from "../../../../locales/Translation";
import React from "react";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { LOGIN_LINK } from "../../../App/configs/ListRoutes";

const ForgotPassword = () => {
  const { translations } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const isAuth = useSelector(isAuthSelector);

  const _onClick = async (values: { username: string }) => {
    try {
      await forgotPasswordService(values);
      toastSuccess("Please check your email to reset password!");
      navigate(LOGIN_LINK, { replace: true });
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      toastError(translations.errorMessage.contactAdmin);
    }
  };

  if (isAuth) {
    return <Navigate to={"/"} />;
  }

  return (
    <LoginTemplate>
      <div className={"login__title"}>{translations.forgotPassword}</div>
      <div className={"login__sub_title"}>
        {translations.forgotPasswordDescription}
      </div>
      <ForgotPasswordForm onClick={_onClick} />
    </LoginTemplate>
  );
};

export default ForgotPassword;
