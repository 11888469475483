import React, { useContext } from "react";
import Label from "../../../../../components/Label";
import InputCustom from "../../../../../components/Input";
import { LocalizationContext } from "../../../../../locales/Translation";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import SizeBox from "../../../../../components/SizeBox";
import DropdownCustom from "../../../../../components/DropdownCustom";
import { useParams } from "react-router-dom";
import ToggleSlider from "../../../../../components/ToggleSlider";

type Props = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
  handleChange: any;
  setFieldValue: any;
  marketList: any[];
};

const PaymentTerms = ({
  values,
  errors,
  touched,
  isSubmitting,
  handleChange,
  setFieldValue,
  marketList,
}: Props) => {
  const { translations } = useContext(LocalizationContext);
  const { id } = useParams();

  return (
    <>
      <Label
        label={translations.paymentTerms}
        fontSize={"24px"}
        className={"section-title"}
      />
      <div className={"detail_divide"}>
        <div className={"left_section"}>
          <InputCustom
            label={"Payment terms in days"}
            value={values.payment_term}
            error={errors.payment_term}
            touched={touched.payment_term}
            name={"payment_term"}
            onChange={handleChange}
            disabled={isSubmitting}
            removeLetter
          />
        </div>

        <div className={"right_section"}>
          <Label
            fontSize={"12px"}
            className={"label"}
            label={"Currency"}
            required
          />
          <DropdownCustom
            className={"property_company_detail_dropdown"}
            options={marketList.map((item, index) => {
              return {
                key: index,
                text: item.currency,
                value: item.currency,
              };
            })}
            disabled={!!id}
            value={values.currency}
            onChange={(e, { value }) => {
              setFieldValue("currency", value);
              const marketObj = marketList.find(
                ({ currency }) => currency === value
              );
              if (!marketObj) {
                return;
              }
              setFieldValue("market_code", marketObj.city_code);
            }}
          />
        </div>
      </div>

      <ToggleSlider
        className={"flex-1"}
        label={"Charge the monthly SaaS fee"}
        value={values.has_saas_fee}
        onChange={(e: any, data: any) => {
          setFieldValue("has_saas_fee", data.checked);
        }}
      />

      {values?.has_saas_fee ? (
        <div className={"detail_divide"}>
          <div className={"left_section"}>
            <SizeBox height={"16px"} />
            <InputCustom
              containerClass={"mb-0"}
              value={values.total_saas_fee}
              error={errors.total_saas_fee}
              touched={touched.total_saas_fee}
              name={"total_saas_fee"}
              onChange={handleChange}
              disabled={isSubmitting}
              inputLeftIcon={values?.currency}
              inputLeftIconBorder
            />
          </div>
        </div>
      ) : null}

      <SizeBox height={"24px"} />
      <div className={"property_company_detail_line"} />
    </>
  );
};

export default PaymentTerms;
