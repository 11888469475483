import React from "react";
import { Checkbox, CheckboxProps } from "semantic-ui-react";
import "./style.scss";

interface Props extends CheckboxProps {
  label?: string;
  reverseText?: boolean;
  labelSizeSmall?: boolean;
  [x: string]: any;
}
const CheckboxCustom = ({
  label,
  reverseText = false,
  labelSizeSmall,
  ...props
}: Props) => {
  if (reverseText) {
    return (
      <>
        <span className={"label-reverse"}>{label}</span>
        <Checkbox className={"custom-checkbox"} label={null} {...props} />
      </>
    );
  }
  return (
    <Checkbox
      className={`custom-checkbox ${
        labelSizeSmall ? "custom-checkbox-small" : ""
      }`}
      label={label}
      {...props}
    />
  );
};

export default CheckboxCustom;
