import React, { useState, useEffect } from "react";
import TableCustom from "../../../../../components/Table";
import RatingCell from "./RatingCell";
import { deskimoUserReviewsSelector } from "../../../selectors/PropertySelector";
import { useSelector } from "react-redux";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../../App/configs/Constants";
import ReadMore from "../../../../../components/ReadMore";
import InterestingService from "../../PropertyDetail/Components/UserReviewTab/InterestingService";
import Filter from "../../PropertyDetail/Components/UserReviewTab/Filter";

const UserReviewTable = ({ filters, setFilters, loading }: any) => {
  const deskimoUserReviews = useSelector(deskimoUserReviewsSelector);

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    decoratedData();
  }, [deskimoUserReviews]);

  const decoratedData = () => {
    if (deskimoUserReviews?.reviews?.length > 0) {
      setTableData(
        deskimoUserReviews?.reviews?.map((data: any) => {
          return {
            col1: <div className={"text-nowrap"}>{data?.date}</div>,
            col2: data?.user,
            col3: data?.property,
            col4: data?.market,
            col5: <RatingCell stars={data?.rating} />,
            col6: (
              <InterestingService
                comment={data?.extra_comment}
                services={data?.good_services}
              />
            ),
            colw25: (
              <div>
                {data?.comments?.length > 0 ? (
                  <ReadMore>{data?.comments?.replaceAll("\n", "")}</ReadMore>
                ) : null}
              </div>
            ),
          };
        })
      );
    } else {
      setTableData([]);
    }
  };

  const columns = [
    { key: "date", col: "Date" },
    { key: "user", col: "User" },
    { key: "property", col: "Workspace" },
    { key: "market", col: "Market" },
    { key: "rating", col: "Rating" },
    { key: "outstanding_service", col: "Outstanding Service" },
    { key: "comments", col: "Review" },
  ];

  const _onChangeSortCol = (col: any) => {
    if (filters.sort_by !== col.key) {
      setFilters({ ...filters, sort_by: col.key, order_by: ORDER_BY_ASC });
    } else {
      setFilters({ ...filters, sort_by: col.key });
      if (filters.order_by === ORDER_BY_NONE) {
        setFilters({ ...filters, order_by: ORDER_BY_ASC });
      }
      if (filters.order_by === ORDER_BY_ASC) {
        setFilters({ ...filters, order_by: ORDER_BY_DESC });
      }
      if (filters.order_by === ORDER_BY_DESC) {
        setFilters({ ...filters, order_by: ORDER_BY_NONE });
      }
    }
  };

  return (
    <TableCustom
      orderBy={filters.order_by}
      sortBy={filters.sort_by}
      onChangeSortCol={_onChangeSortCol}
      sortTable
      showTotal
      tableData={tableData}
      columns={columns}
      currentPage={filters.page}
      totalItems={deskimoUserReviews?.pagination?.total}
      unit={"reviews"}
      onChangePagination={(e: any, { activePage }: any) => {
        setFilters({ ...filters, page: activePage });
      }}
      topRightComponent={<Filter filter={filters} setFilter={setFilters} />}
      loading={loading}
    />
  );
};

export default UserReviewTable;
