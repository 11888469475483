const CompaniesIcon = ({
  strokeColor = "#21252A",
  isActive = false,
}: {
  strokeColor?: string;
  isActive: boolean;
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 18.75L4.72751 12.5319C4.65425 12.4385 4.58174 12.3445 4.51001 12.25C3.60937 11.0636 3.12282 9.61452 3.12501 8.125C3.12501 6.30164 3.84934 4.55295 5.13865 3.26364C6.42796 1.97433 8.17664 1.25 10 1.25C11.8234 1.25 13.5721 1.97433 14.8614 3.26364C16.1507 4.55295 16.875 6.30164 16.875 8.125C16.8772 9.61384 16.3909 11.0623 15.4906 12.2481L15.49 12.25C15.49 12.25 15.3025 12.4963 15.2744 12.5294L10 18.75ZM5.50751 11.4969C5.50876 11.4969 5.65376 11.6894 5.68688 11.7306L10 16.8175L14.3188 11.7237C14.3463 11.6894 14.4925 11.4956 14.4931 11.495C15.2289 10.5257 15.6265 9.34189 15.625 8.125C15.625 6.63316 15.0324 5.20242 13.9775 4.14752C12.9226 3.09263 11.4918 2.5 10 2.5C8.50816 2.5 7.07742 3.09263 6.02253 4.14752C4.96764 5.20242 4.37501 6.63316 4.37501 8.125C4.3737 9.34265 4.77174 10.5271 5.50813 11.4969H5.50751Z"
        fill={isActive ? "#003DBC" : strokeColor}
      />
      <path
        d="M13.125 11.25H11.875V6.25H8.125V11.25H6.875V6.25C6.87533 5.91858 7.00713 5.60083 7.24148 5.36648C7.47583 5.13213 7.79358 5.00033 8.125 5H11.875C12.2064 5.00033 12.5242 5.13213 12.7585 5.36648C12.9929 5.60083 13.1247 5.91858 13.125 6.25V11.25Z"
        fill={isActive ? "#003DBC" : strokeColor}
      />
      <path
        d="M9.375 10H10.625V11.25H9.375V10ZM9.375 7.5H10.625V8.75H9.375V7.5Z"
        fill={isActive ? "#003DBC" : strokeColor}
      />
    </svg>
  );
};

export default CompaniesIcon;
