import {
  PARTNER_INTEGRATION_LINK,
  PARTNER_LIMITATION_LINK,
  PARTNER_OPERATION_LOG_LINK,
} from "../../../App/configs/ListRoutes";
import { Link, Route } from "react-router-dom";
import MasterTemplate from "../../../App/views/MasterTemplate";
import {
  ROLE_BUSINESS_ADMIN_ID,
  ROLE_PARTNER_ID,
} from "../../../User/configs/Constants";
import { Tab } from "semantic-ui-react";
import React from "react";
import GeneralSetting from "./GeneralSetting";
import OperationLog from "./OperationLog";
import Limitation from "./Limitation";
import { UI_ACCESS_PARTNER_DASHBOARD } from "../../../../utils/PermissionConstant";

type Props = {
  tabIndex?: number;
};

const PartnerIntegration = ({ tabIndex }: Props) => {
  const panes = [
    {
      menuItem: {
        as: Link,
        id: "tab1",
        to: PARTNER_INTEGRATION_LINK,
        content: "General Settings",
        exact: true,
      },
      pane: <Route path={PARTNER_INTEGRATION_LINK} />,
      render: () => <GeneralSetting />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab2",
        to: PARTNER_OPERATION_LOG_LINK,
        content: "Operation Log",
        exact: true,
      },
      pane: <Route path={PARTNER_OPERATION_LOG_LINK} />,
      render: () => <OperationLog />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab3",
        to: PARTNER_LIMITATION_LINK,
        content: "Limitation Statistics",
        exact: true,
      },
      pane: <Route path={PARTNER_LIMITATION_LINK} />,
      render: () => <Limitation />,
    },
  ];
  return (
    <MasterTemplate
      roleAccept={[ROLE_BUSINESS_ADMIN_ID, ROLE_PARTNER_ID]}
      permission={UI_ACCESS_PARTNER_DASHBOARD}
    >
      <Tab
        menu={{ text: true }}
        activeIndex={tabIndex}
        panes={panes}
        className={"tab-custom"}
      />
    </MasterTemplate>
  );
};

export default PartnerIntegration;
