import React, { useContext } from "react";
import ButtonCustom from "../../../../../components/Button";
import Avatar from "../../../../../components/Avatar";
import { useDispatch, useSelector } from "react-redux";
import { assignUserListSelector } from "../../../selectors/BookSelectors";
import { LIMIT_TOTAL_BOOKING } from "../../../configs/Constants";
import { assignUserListAction } from "../../../actions/BookActions";
import { toastError } from "../../../../../components/Toast";
import { LocalizationContext } from "../../../../../locales/Translation";

const BookingBoxAssignUserRow = ({ user, onPreview }: any) => {
  const dispatch = useDispatch();
  const assignUserList = useSelector(assignUserListSelector);
  const { translations } = useContext(LocalizationContext);

  const handleMinus = () => {
    let newState = JSON.parse(JSON.stringify(assignUserList));
    newState.forEach((item: any) => {
      if (item?.id === user?.id) {
        if (item.slot <= 1) {
          newState = newState.filter((item: any) => item?.id !== user?.id);
        } else {
          item.slot -= 1;
        }
      }
    });
    dispatch(assignUserListAction(newState));
  };

  const handlePlus = () => {
    let totalSlot = 0;
    if (assignUserList?.length > 0) {
      assignUserList.forEach((user: any) => {
        totalSlot += user?.slot;
      });
    }

    if (totalSlot >= LIMIT_TOTAL_BOOKING) {
      toastError(translations?.errorMessage?.reachLimitBooking);
      return;
    }

    const newState = JSON.parse(JSON.stringify(assignUserList));
    newState.forEach((item: any) => {
      if (item?.id === user?.id) {
        item.slot += 1;
      }
    });

    dispatch(assignUserListAction(newState));
  };

  return (
    <tr className={"assigned-item"}>
      <td className={"assigned-user-info text-left"}>
        <Avatar
          noTooltip={true}
          imgUrl={user?.avatar}
          capName={user?.cap_name}
        />
        <span>{user?.full_name}</span>
      </td>
      <td className={"assigned-user-actions text-right"}>
        <ButtonCustom
          onClick={handleMinus}
          className={"circle"}
          height={"32px"}
          width={"32px"}
        >
          <i className="uil uil-minus" />
        </ButtonCustom>
        <span>{user?.slot}</span>
        <ButtonCustom
          onClick={handlePlus}
          className={"circle"}
          height={"32px"}
          width={"32px"}
        >
          <i className="uil uil-plus" />
        </ButtonCustom>
      </td>
    </tr>
  );
};

export default BookingBoxAssignUserRow;
