import React, { useState, useEffect, SyntheticEvent, useContext } from "react";
import { Tab } from "semantic-ui-react";
import SearchBar4Table from "../../../../../../components/SearchBar4Table";
import ButtonCustom from "../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getCreditTransactionThunk,
  getCreditUsageThunk,
} from "../../../../thunks/PaidCreditThunk";
import TransactionTable from "./Components/TransactionTable";
import UsageTable from "./Components/UsageTable";

import {
  paymentPaidCreditTransactionSelector,
  paymentPaidCreditUsageSelector,
} from "../../../../selectors/PaidCreditSelector";
import moment from "moment";
import { getValidTimeStamp } from "../../../../../../utils/commons";
import {
  FORMAT_DATE_VARIANT_1,
  FORMAT_DATE_VARIANT_2,
} from "../../../../../App/configs/DateTimeConfigs";
import { getTransactionFiltersService } from "../../../../services/SettingServices";
import DropdownWithCheckboxMenuWithTotal from "../../../../../../components/DropdownWithCheckboxMenuWithTotal";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { userCompanyChooseSelector } from "../../../../../User/selectors/UserSelectors";

const PrepaidCreditTab = () => {
  const dispatch = useDispatch();
  const { translations } = useContext(LocalizationContext);
  const userCompanyChoose = useSelector(userCompanyChooseSelector);

  const [creditTransactionFilter, setCreditTransactionFilter] = useState({
    page: 1,
    keyword: "",
    statuses: [],
  });

  const [creditUsageFilter, setCreditUsageFilter] = useState({
    page: 1,
    keyword: "",
  });

  const creditTransaction = useSelector(paymentPaidCreditTransactionSelector);
  const creditUsage = useSelector(paymentPaidCreditUsageSelector);
  const [transactionTableLoading, setTransactionTableLoading] = useState(false);
  const [usageTableLoading, setUsageTableLoading] = useState(false);
  const [dropdownTransactionFilter, setDropdownTransactionFilter] = useState(
    []
  );

  const _getCreditTransaction = async () => {
    setTransactionTableLoading(true);
    try {
      await dispatch<any>(getCreditTransactionThunk(creditTransactionFilter));
    } catch (e) {
      console.log(e);
    } finally {
      setTransactionTableLoading(false);
    }
  };

  const _getCreditUsage = async () => {
    setUsageTableLoading(true);
    try {
      await dispatch<any>(getCreditUsageThunk(creditUsageFilter));
    } catch (e) {
      console.log(e);
    } finally {
      setUsageTableLoading(false);
    }
  };

  const _getTransactionFilters = async () => {
    try {
      const response = await getTransactionFiltersService();
      const filters = response?.data;
      if (filters.length > 0) {
        setDropdownTransactionFilter(
          filters.map((filter: any) => {
            return {
              id: filter?.status,
              name: `${filter?.status_name} (${filter?.total})`,
            };
          })
        );
        setCreditTransactionFilter((prevState) => {
          return {
            ...prevState,
            statuses: filters.map((filter: any) => filter?.status),
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    _getCreditTransaction();
  }, [creditTransactionFilter, userCompanyChoose]);

  useEffect(() => {
    _getCreditUsage();
  }, [creditUsageFilter, userCompanyChoose]);

  useEffect(() => {
    _getTransactionFilters();
  }, [userCompanyChoose]);

  const [purchaseHistory, setPurchaseHistory] = useState(true);

  const columns = [
    <div key={"Transaction ID"}>{translations.settings.transactionId}</div>,
    <div key={"Credits Package"}>
      {translations.settings.prepaidCreditsPackage}
    </div>,
    <div key={"Percentage"}>
      {translations.settings.percentage} <br /> {translations.settings.discount}
    </div>,
    <div key={"Price"}>{translations.settings.price}</div>,
    <div key={"Credit Amount"}>{translations.settings.creditAmount}</div>,
    <div key={"Remaining Credit"}>{translations.settings.remainingCredit}</div>,
    <div key={"Purchase Date"}>{translations.settings.purchaseDate}</div>,
    <div key={"Expiry Date"}>{translations.settings.expiryDate}</div>,
    <div key={"Status"}>{translations.status}</div>,
  ];

  const UsageColumns = [
    translations.CreditId,
    translations.settings.prepaidCreditsPackage,
    translations.settings.remainingCredit,
    translations.settings.purchaseDate,
    translations.settings.transactionId,
    translations.type,
    translations.TransactionUsageDate,
    translations.TransactionValue,
    translations.RefundedCredits,
  ];

  const decorateTransactionTable = (data: []) => {
    if (!data || data.length < 1) return [];
    return data.map((field: any) => {
      return {
        col1: field?.code,
        col2: field?.package_name,
        col3: field?.discount_percent + "%",
        col4: field?.total_price,
        col5: field?.credit_amount,
        col6: field?.remaining_credit,
        col7: moment(getValidTimeStamp(field?.transaction_date)).format(
          FORMAT_DATE_VARIANT_1
        ),
        col8: moment(getValidTimeStamp(field?.expired_date)).format(
          FORMAT_DATE_VARIANT_1
        ),
        col9: field?.status_name,
      };
    });
  };

  const transactionTableData = decorateTransactionTable(
    creditTransaction?.items
  );

  const decorateUsageTable = (data: []) => {
    if (!data || data.length < 1) return [];
    return data.map((field: any) => {
      return {
        col1: field?.credit_id,
        col2: field?.package_name,
        col3: field?.remaining_credit,
        col4: moment(getValidTimeStamp(field?.purchase_date)).format(
          FORMAT_DATE_VARIANT_2
        ),
        col5: field?.code,
        col6: field?.type,
        col7: moment(getValidTimeStamp(field?.used_date)).format(
          FORMAT_DATE_VARIANT_2
        ),
        col8: field?.used_credit,
        col9: field?.refunded_credit,
      };
    });
  };

  const usageTableData = decorateUsageTable(creditUsage.items);

  const _onChangeFilter = (e: SyntheticEvent, data: any) => {
    if (data.checked) {
      setCreditTransactionFilter((prevState: any) => {
        return {
          ...prevState,
          statuses: [...creditTransactionFilter.statuses, data.value],
        };
      });
    } else {
      setCreditTransactionFilter((prevState: any) => {
        return {
          ...prevState,
          statuses: creditTransactionFilter.statuses.filter(
            (status: any) => status !== data.value
          ),
        };
      });
    }
  };

  const _onChangeTransactionPagination = (
    e: SyntheticEvent,
    { activePage }: any
  ) => {
    setCreditTransactionFilter((prevState) => {
      return { ...prevState, page: activePage };
    });
  };

  const _onChangeUsagePagination = (e: SyntheticEvent, { activePage }: any) => {
    setCreditUsageFilter((prevState) => {
      return { ...prevState, page: activePage };
    });
  };

  const _onSearch = (data: any, { value }: any) => {
    if (purchaseHistory) {
      setCreditTransactionFilter((prevState) => {
        return { ...prevState, page: 1, keyword: value };
      });
    } else {
      setCreditUsageFilter((prevState) => {
        return { ...prevState, page: 1, keyword: value };
      });
    }
  };

  return (
    <Tab.Pane attached={false} className={"prepaid-credit-tab"}>
      <div className={"filter-section"}>
        <SearchBar4Table
          onChange={_onSearch}
          placeholder={translations.settings.searchPlaceHolder}
        />
        <div className={"button-group yellow"}>
          <ButtonCustom
            onClick={() => setPurchaseHistory(true)}
            height={"36px"}
            className={purchaseHistory ? "active" : ""}
          >
            {translations.settings.purchaseHistory}
          </ButtonCustom>
          <ButtonCustom
            onClick={() => setPurchaseHistory(false)}
            height={"36px"}
            className={purchaseHistory ? "" : "active"}
          >
            {translations.settings.prepaidCreditsUsage}
          </ButtonCustom>
        </div>
        <div className={"dropdown-filter"}>
          {purchaseHistory ? (
            <DropdownWithCheckboxMenuWithTotal
              onChange={_onChangeFilter}
              values={creditTransactionFilter.statuses}
              listCheckBox={dropdownTransactionFilter}
              label={translations.settings.filters}
              icon={<img src={PUBLIC_URL + "/assets/icons/icon-filter.svg"} />}
            />
          ) : null}
        </div>
      </div>

      <div className={"prepaid-table"}>
        {purchaseHistory ? (
          <TransactionTable
            tableData={transactionTableData}
            columns={columns}
            currentPage={creditTransactionFilter.page}
            loadingTable={transactionTableLoading}
            onChangePagination={_onChangeTransactionPagination}
            totalItems={creditTransaction?.pagination?.total}
          />
        ) : (
          <UsageTable
            tableData={usageTableData}
            columns={UsageColumns}
            loadingTable={usageTableLoading}
            onChangePagination={_onChangeUsagePagination}
            currentPage={creditUsageFilter.page}
            totalItems={creditUsage?.pagination?.total}
          />
        )}
      </div>
    </Tab.Pane>
  );
};

export default PrepaidCreditTab;
