import SizeBox from "../../../../../../components/SizeBox";
import { useParams } from "react-router-dom";
import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { propertyDetailSelector } from "../../../../selectors/PropertySelector";
import Action from "./Action";
import FilterStatus from "./FilterStatus";
import TableCustom from "../../../../../../components/Table";
import { LocalizationContext } from "../../../../../../locales/Translation";
import ModalCreatePrivateOffice from "./Components/ModalCreatePrivateOffice";
import ButtonCustom from "../../../../../../components/Button";
import { deskimoModalPrivateOfficeAction } from "../../../../actions/PropertyActions";
import { CREATE_TYPE } from "../../../../configs/Constants";
import { privateOfficeMangeListService } from "../../../../services/PropertyServices";
import SearchBar4Table from "../../../../../../components/SearchBar4Table";
import { debounce } from "lodash";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../../../App/configs/Constants";
import { Label as LabelSemantic } from "semantic-ui-react";

const PrivateOfficesTab = () => {
  const { translations } = useContext(LocalizationContext);

  const { id: propertyId, companyId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState({
    page: 1,
    keyword: "",
    order_by: "",
    sort_by: "",
    statuses: [0, 1],
    property_id: propertyId,
    code: "",
    company_id: companyId,
  });
  const [refreshList, setRefreshList] = useState(false);
  const [privateOfficeList, setPrivateOfficeList] = useState({
    private_offices: [],
    pagination: { total: 0 },
  });
  const propertyDetailData = useSelector(propertyDetailSelector);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await privateOfficeMangeListService(filter);
        setPrivateOfficeList(data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [filter, refreshList]);

  const decorateData = () => {
    return privateOfficeList?.private_offices?.map(
      ({
        name,
        capacity,
        daily_rate,
        id,
        status,
        status_name,
        visibility_name,
        visibility,
        visibility_action,
      }: any) => {
        return {
          name,
          capacity: `${capacity} people`,
          daily_rate,
          status: (
            <div>
              <div>{status_name}</div>
              {status ? (
                <LabelSemantic
                  className={visibility ? "blue" : ""}
                  size={"mini"}
                  style={{ marginTop: 2 }}
                >
                  {visibility_name}
                </LabelSemantic>
              ) : null}
            </div>
          ),
          col6: (
            <Action
              visibilityAction={visibility_action}
              visibility={visibility}
              id={id}
              status={status}
              onRefresh={() => setRefreshList((prevState) => !prevState)}
            />
          ),
        };
      }
    );
  };

  const columns = [
    { key: "name", col: "Private Office Name" },
    { key: "capacity", col: translations.capacity },
    { key: "daily_rate", col: translations.dailyRate },
    { key: "status", col: translations.status },
    { key: null, col: translations.action },
  ];

  const debounce_onChange = debounce((e, { value }) => {
    setFilter((prevState) => ({ ...prevState, keyword: value }));
  }, 500);

  const onChangeFilter = (e: SyntheticEvent, data: any) => {
    if (data.checked) {
      setFilter((prevState: any) => {
        return {
          ...prevState,
          statuses: [...prevState.statuses, data.value],
        };
      });
    } else {
      setFilter((prevState: any) => {
        return {
          ...prevState,
          statuses: prevState.statuses.filter(
            (status: any) => status !== data.value
          ),
        };
      });
    }
  };

  const _onChangePagination = (e: any, { activePage }: any) => {
    setFilter((prevState) => {
      return { ...prevState, page: activePage };
    });
  };

  const _onChangeSortCol = (col: any) => {
    if (filter.sort_by !== col.key) {
      setFilter((prevState) => {
        return { ...prevState, sort_by: col.key, order_by: ORDER_BY_ASC };
      });
    } else {
      if (filter.order_by === ORDER_BY_NONE) {
        setFilter((prevState) => {
          return { ...prevState, sort_by: col.key, order_by: ORDER_BY_ASC };
        });
      }
      if (filter.order_by === ORDER_BY_DESC) {
        setFilter((prevState) => {
          return { ...prevState, sort_by: col.key, order_by: ORDER_BY_NONE };
        });
      }
      if (filter.order_by === ORDER_BY_ASC) {
        setFilter((prevState) => {
          return { ...prevState, sort_by: col.key, order_by: ORDER_BY_DESC };
        });
      }
    }
  };

  return (
    <>
      <div className="meeting-room-header">
        <div className="property-name-header">{propertyDetailData.name}</div>
        <div className={"button-groups"}>
          <ButtonCustom
            onClick={() => {
              dispatch(
                deskimoModalPrivateOfficeAction({
                  isOpen: true,
                  type: CREATE_TYPE,
                })
              );
            }}
            className={"outline-primary manage-pms-cta"}
            height={"36px"}
          >
            <>
              Add New Private Office
              <i className="uil uil-plus" />
            </>
          </ButtonCustom>
        </div>
      </div>
      <SizeBox height={"32px"} />
      <div className={"filter-section"}>
        <SearchBar4Table
          placeholder={"Search by name"}
          onChange={debounce_onChange}
        />
        <div className={"dropdown-filter d-flex"}>
          <FilterStatus
            filters={filter.statuses}
            onChangeFilter={onChangeFilter}
          />
        </div>
      </div>
      <TableCustom
        loading={loading}
        showTotal
        sortTable
        orderBy={filter.order_by}
        sortBy={filter.sort_by}
        onChangeSortCol={_onChangeSortCol}
        tableData={decorateData()}
        columns={columns}
        currentPage={filter.page}
        totalItems={privateOfficeList?.pagination?.total}
        unit={translations.meetingRooms}
        onChangePagination={_onChangePagination}
        tableClass={"table-meeting-room"}
      />
      <ModalCreatePrivateOffice
        onRefresh={() => setRefreshList((prevState) => !prevState)}
      />
    </>
  );
};

export default PrivateOfficesTab;
