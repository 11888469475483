import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isModalManagePMSAddRoomSelector } from "../../../../../../selectors/PropertySelector";
import {
  managePMSAddCalendarAction,
  modalManagePMSAddCalendarAction,
  modalManagePMSAddRoomAction,
} from "../../../../../../actions/PropertyActions";
import { Modal } from "semantic-ui-react";
import { LocalizationContext } from "../../../../../../../../locales/Translation";
import ModalCenterTemplate from "../../../../../../../App/views/ModalCenterTemplate";
import { PUBLIC_URL } from "../../../../../../../App/configs/Constants";
import ButtonCustom from "../../../../../../../../components/Button";
import PMSIntegrationItemSelect from "../../../../../../../../components/PMSIntegrationItemSelect";
// import ButtonAddRoomByGoogle from "./ButtonAddRoomByGoogle.tsx.dist";
import GoogleLogin from "react-google-login";
import SizeBox from "../../../../../../../../components/SizeBox";

const ModalManagePMSAddRoom = () => {
  const GOOGLE_CALENDAR_CHECKED = "GOOGLE_CALENDAR_CHECK";
  // const [loading, setLoading] = useState(false);
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const isModalManagePMSAddRoom = useSelector(isModalManagePMSAddRoomSelector);
  const [check, setCheck] = useState<null | string>(GOOGLE_CALENDAR_CHECKED);

  return (
    <ModalCenterTemplate
      trigger={null}
      onClose={() => dispatch(modalManagePMSAddRoomAction(false))}
      open={isModalManagePMSAddRoom}
      size={"md"}
      modalClassName={"modal-center-v2"}
    >
      <Modal.Header>
        Connect to Google Calendar
        <ButtonCustom
          height={"auto"}
          onClick={() => dispatch(modalManagePMSAddRoomAction(false))}
          className={"m-0 p-0 none-bg close-modal"}
        >
          <>
            {translations.settings.close}
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Modal.Content>
        <PMSIntegrationItemSelect
          onClick={() => setCheck(GOOGLE_CALENDAR_CHECKED)}
          checked={check === GOOGLE_CALENDAR_CHECKED}
          uri={
            "https://upload.wikimedia.org/wikipedia/commons/a/a5/Google_Calendar_icon_%282020%29.svg"
          }
          label={"Connect to Google Calendar"}
        />
        <SizeBox height={"26px"} />
      </Modal.Content>
      <Modal.Actions className={"d-flex justify-content-between"}>
        <ButtonCustom
          className={"flex-1 m-0"}
          height={"47px"}
          onClick={() => dispatch(modalManagePMSAddRoomAction(false))}
        >
          {translations.settings.cancel}
        </ButtonCustom>
        <SizeBox width={"16px"} />
        {/*{check === GOOGLE_CALENDAR_CHECKED ? (*/}
        {/*  <GoogleLogin*/}
        {/*    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}*/}
        {/*    render={(renderProps) => (*/}
        {/*      <ButtonCustom*/}
        {/*        // loading={loading}*/}
        {/*        type={"submit"}*/}
        {/*        className={"primary flex-1 m-0"}*/}
        {/*        height={"47px"}*/}
        {/*        onClick={() => {*/}
        {/*          renderProps.onClick();*/}
        {/*          // setCheck("");*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        {translations.connect}*/}
        {/*      </ButtonCustom>*/}
        {/*    )}*/}
        {/*    onSuccess={(response) => {*/}
        {/*      console.log(response);*/}
        {/*      dispatch(*/}
        {/*        managePMSAddCalendarAction({ auth_code: response.code })*/}
        {/*      );*/}
        {/*      dispatch(modalManagePMSAddCalendarAction(true));*/}
        {/*      dispatch(modalManagePMSAddRoomAction(false));*/}
        {/*    }}*/}
        {/*    cookiePolicy={"single_host_origin"}*/}
        {/*    uxMode={"popup"}*/}
        {/*    responseType="code"*/}
        {/*    accessType={"offline"}*/}
        {/*    prompt={"consent"}*/}
        {/*    scope={"https://www.googleapis.com/auth/calendar"}*/}
        {/*    isSignedIn={false}*/}
        {/*  />*/}
        {/*) : (*/}
        <ButtonCustom
          // loading={loading}
          type={"submit"}
          className={"primary flex-1 m-0"}
          height={"47px"}
          onClick={() => {
            dispatch(modalManagePMSAddRoomAction(false));
            dispatch(modalManagePMSAddCalendarAction(true));
            // alert("Please Choose A Calendar Service!");
          }}
        >
          {translations.connect}
        </ButtonCustom>
        {/*)}*/}
      </Modal.Actions>
    </ModalCenterTemplate>
  );
};

export default ModalManagePMSAddRoom;
