import ReactGA from "react-ga4";
import { store } from "../modules/App/configs/Store";
import { UaEventOptions } from "react-ga4/types/ga4";

export const GATrackingInit = () => {
  if (!process.env.REACT_APP_GA_TRACKING_ID) {
    return;
  }

  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

  const myProfile: any = store.getState().AppReducer.myProfile;

  if (myProfile?.email) {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
      gaOptions: {
        userId: myProfile?.email,
      },
    });
  } else {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  }
};

export const GASendUserID = () => {
  const myProfile: any = store.getState().AppReducer.myProfile;
  if (!myProfile?.email) {
    return;
  }

  ReactGA.set({ userId: myProfile?.email });
};

export const GATrackingPageView = (pageName: string) => {
  ReactGA.send({ hitType: "pageview", page: pageName });
};

export const GATrackingEvent = (
  optionsOrName: UaEventOptions | string,
  params?: object
) => {
  ReactGA.event(optionsOrName, params);
};
