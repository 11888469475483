import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import Table from "../../../../../components/Table";
import { getDeskimoUserUsageService } from "../../../services/EmployeeServices";
import { useParams } from "react-router-dom";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../../App/configs/Constants";
import { usersFilterAction } from "../../../actions/EmployeeActions";
import { Popup } from "semantic-ui-react";

const UsageHistory = () => {
  const { translations } = useContext(LocalizationContext);
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);

  const [usageHistory, setUsageHistory] = useState<null | {
    usage_histories: [];
    pagination: any;
  }>(null);

  const [usageHistoryFilter, setUsageHistoryFilter] = useState({
    page: 1,
    sort_by: "",
    order_by: ORDER_BY_NONE,
  });

  useEffect(() => {
    (async () => {
      try {
        if (!id) {
          return;
        }
        setLoading(true);
        const { data } = await getDeskimoUserUsageService(
          usageHistoryFilter,
          id
        );
        if (Array.isArray(data)) {
          setUsageHistory(null);
          return;
        }
        setUsageHistory(data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [usageHistoryFilter]);

  const _formatInputData = () => {
    if (!usageHistory) {
      return [];
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return usageHistory.usage_histories.map(
      ({
        code,
        gross_revenue,
        date,
        country_name,
        total_time,
        end_time,
        start_time,
        property_name,
        net_revenue,
        type,
        is_cancel,
      }: any) => {
        return {
          code: code.replaceAll(" ", ""),
          type: (
            <div style={{ display: "inline", whiteSpace: "nowrap" }}>
              {type}{" "}
              {is_cancel ? (
                <Popup
                  position={"top center"}
                  content={"Cancelled"}
                  trigger={
                    <span className={"badge-circle badge-danger"}>C</span>
                  }
                />
              ) : null}
            </div>
          ),
          property_name,
          country_name,
          date,
          start_time,
          end_time,
          total_time,
          gross_revenue,
          net_revenue,
        };
      }
    );
  };

  const columns = [
    { key: "code", col: translations.transactionID },
    { key: "type", col: translations.type },
    { key: "workspace", col: translations.workspace },
    { key: "market", col: translations.market },
    { key: "date", col: translations.date },
    { key: "start_time", col: translations.startTime },
    { key: "end_time", col: translations.endTime },
    { key: "minute", col: translations.minutes },
    { key: "GBV", col: translations.GBV },
    { key: "net_revenue", col: translations.netRevenue },
  ];

  const _onChangeSortCol = (col: any) => {
    if (usageHistoryFilter.sort_by !== col.key) {
      setUsageHistoryFilter((prevState) => {
        return { ...prevState, sort_by: col.key, order_by: ORDER_BY_ASC };
      });
    } else {
      if (usageHistoryFilter.order_by === ORDER_BY_NONE) {
        setUsageHistoryFilter((prevState) => {
          return { ...prevState, sort_by: col.key, order_by: ORDER_BY_ASC };
        });
      }
      if (usageHistoryFilter.order_by === ORDER_BY_DESC) {
        setUsageHistoryFilter((prevState) => {
          return { ...prevState, sort_by: col.key, order_by: ORDER_BY_NONE };
        });
      }
      if (usageHistoryFilter.order_by === ORDER_BY_ASC) {
        setUsageHistoryFilter((prevState) => {
          return { ...prevState, sort_by: col.key, order_by: ORDER_BY_DESC };
        });
      }
    }
  };

  return (
    <>
      <h2 className={"section-white-box_title"}>{translations.usageDetails}</h2>
      <div className={"user_detail_table_wrapper"}>
        <Table
          loading={loading}
          tableClass={"user_detail_table"}
          sortTable
          showTotal={false}
          columns={columns}
          tableData={usageHistory ? _formatInputData() : []}
          currentPage={usageHistory?.pagination?.page}
          itemPerPage={usageHistory?.pagination?.limit}
          totalItems={usageHistory?.pagination?.total}
          onChangePagination={(e: any, { activePage }: any) =>
            setUsageHistoryFilter((prevState) => {
              return { ...prevState, page: activePage };
            })
          }
          onChangeSortCol={_onChangeSortCol}
          sortBy={usageHistoryFilter.sort_by}
          orderBy={usageHistoryFilter.order_by}
        />
      </div>
      <div className={"section-white-box_line"}></div>
    </>
  );
};

export default UsageHistory;
