import React, { useState, useEffect, useContext } from "react";
import { Modal } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import {
  headerPopupToppingUpAction,
  modalAddEmployeeAction,
  modalPackageTopUpAction,
  userGuideAction,
} from "../../actions/AppActions";
import {
  creditPackagesSelector,
  isModalPackageTopUpSelector,
  onboardPromotionInfoSelector,
  userGuideSelector,
} from "../../selectors/AppSelectors";
import "./styles.scss";
import ButtonCustom from "../../../../components/Button";
import ModalCenterTemplate from "../ModalCenterTemplate";
import SizeBox from "../../../../components/SizeBox";
import { PUBLIC_URL } from "../../configs/Constants";
import { SweetAlertCommon } from "../../../../components/SweetAlert";
import { SWAL_CONFIRM_OPTION } from "../../../../components/SweetAlert/configs/SwalConfigs";
import { buyPackageService } from "../../services/AppServices";
import {
  creditPackagesThunk,
  getCreditRemainingThunk,
  getMyProfileThunk,
  onboardPromotionInfoThunk,
} from "../../thunks/AppThunk";
import classNames from "classnames";
import { LocalizationContext } from "../../../../locales/Translation";
import { toastError } from "../../../../components/Toast";
import { useNavigate } from "react-router-dom";
import { BILLING_LINK } from "../../configs/ListRoutes";
import { userCompanyChooseSelector } from "../../../User/selectors/UserSelectors";
import PackageItem from "./PackageItem";
import Slider from "react-slick";
import PackagePlaceholder from "./PackagePlaceholder";
import { ONBOARD_PROMOTION_PAID_TRAIL } from "../../../Configuration/configs/Constants";

const ModalTopUp = () => {
  const { translations } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const creditPackages = useSelector(creditPackagesSelector);

  const [active, setActive] = useState(0);
  const dispatch = useDispatch();
  const isModalPackageTopUp = useSelector(isModalPackageTopUpSelector);

  const [loading, setLoading] = useState(false);
  const userGuide = useSelector(userGuideSelector);
  const userCompanyChoose = useSelector(userCompanyChooseSelector);

  const [loadingPackage, setLoadingPackage] = useState<boolean>(false);

  const onboardPromotionInfo = useSelector(onboardPromotionInfoSelector);

  useEffect(() => {
    (async () => {
      if (!isModalPackageTopUp) {
        return;
      }

      setLoadingPackage(true);
      const res = await dispatch<any>(creditPackagesThunk());

      const isPopular = res?.packages?.find(
        ({ is_most_popular }: { is_most_popular: string }) => is_most_popular
      );
      if (isPopular) {
        setActive(isPopular?.id);
      } else {
        setActive(res?.packages[0]?.id);
      }
      setLoadingPackage(false);
    })();
  }, [isModalPackageTopUp]);

  const handlePurchasePackage = async () => {
    setLoading(true);
    try {
      const payload = {
        package_id: active,
      };

      await buyPackageService(payload);
      _onClose();
      if (userGuide?.run && userGuide?.stepIndex === 6) {
        dispatch(userGuideAction({ key: "run", value: false }));
      }

      if (onboardPromotionInfo?.type === ONBOARD_PROMOTION_PAID_TRAIL) {
        dispatch<any>(onboardPromotionInfoThunk());
      }

      dispatch<any>(getCreditRemainingThunk());
      dispatch<any>(getMyProfileThunk(userCompanyChoose?.company_id));

      const options = SWAL_CONFIRM_OPTION({
        title:
          "Payment successful!\nAdd your employees and start using your credits.",
        iconHtml: (
          <img src={PUBLIC_URL + "/assets/icons/icon-check-green.svg"} />
        ),
        cancelText: translations.close,
        confirmText: "Add employee",
        confirmClass: "primary",
        showCancelButton: true,
      });

      SweetAlertCommon({
        options: options,
        confirmCallback: () => dispatch(modalAddEmployeeAction(true)),
        cancelCallback: () => null,
      });
    } catch (error: any) {
      toastError(error.status.message);
      navigate(BILLING_LINK);
    } finally {
      setLoading(false);
      _onClose();
    }
  };

  const _handleRenderTriggerNumber = () => {
    const item: any = creditPackages?.packages.find(
      ({ id }: { id: number }) => id === active
    );
    return item ? `${item?.currency} ${item?.trigger_amount}` : "";
  };

  const _handleRenderRemainingCreditsValid = () => {
    const item: any = creditPackages?.packages.find(
      ({ id }: { id: number }) => id === active
    );

    if (!item) {
      return "";
    }

    const extendTimes =
      parseInt(item?.duration) * parseInt(item?.extend_time || 1);
    const months = extendTimes / 30;

    return months < 1
      ? `${extendTimes} ${translations.days}`
      : `${Math.ceil(months)} ${
          Math.ceil(months) > 1 ? translations.months : translations.month
        }`;
  };

  const _onClose = () => {
    dispatch(modalPackageTopUpAction(false));
  };

  return (
    <>
      <ModalCenterTemplate
        trigger={null}
        onClose={_onClose}
        modalClassName={classNames(
          "modal-package-topup guide-modal-package-topup",
          {
            "guide-modal-package-topup-show":
              userGuide.run && userGuide.stepIndex === 6,
          }
        )}
        onOpen={() => {
          dispatch(headerPopupToppingUpAction(false));
        }}
        onMount={() => {
          dispatch(headerPopupToppingUpAction(false));
        }}
        open={isModalPackageTopUp}
      >
        <Modal.Header>
          {translations.topUpModalTitle}
          <ButtonCustom
            onClick={_onClose}
            className="m-0 p-0 none-bg close-modal"
            height={"auto"}
          >
            <>
              {translations.close}
              <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
            </>
          </ButtonCustom>
        </Modal.Header>
        <Modal.Content>
          {loadingPackage ? (
            <PackagePlaceholder />
          ) : (
            <Slider
              slidesToShow={
                window.screen.width < 768
                  ? 1
                  : creditPackages.packages.length > 3
                  ? 3
                  : creditPackages.packages.length
              }
              slidesToScroll={
                window.screen.width < 768
                  ? 1
                  : creditPackages.packages.length > 3
                  ? 3
                  : creditPackages.packages.length
              }
              arrows={false}
              infinite={false}
              className={"slick-package-list"}
              dots
              swipe={creditPackages.packages.length > 3}
            >
              {creditPackages.packages.map((item: any, index: number) => (
                <PackageItem
                  key={index}
                  item={item}
                  active={active}
                  setActive={setActive}
                />
              ))}
            </Slider>
          )}

          <SizeBox height={"15px"} />
          {loadingPackage ? (
            <SizeBox height={"29px"} />
          ) : (
            <>
              <div className={"modal-text"}>
                {translations.topUpModalConfig_4} {_handleRenderTriggerNumber()}
                .
              </div>
              <div className={"modal-text"}>
                {translations.topUpModalConfig_5}{" "}
                {_handleRenderRemainingCreditsValid()}.
              </div>
            </>
          )}
        </Modal.Content>
        <Modal.Actions>
          <ButtonCustom
            disabled={loadingPackage || loading}
            height={"47px"}
            fontSize={"16px"}
            onClick={() => {
              _onClose();
              if (userGuide?.run && userGuide?.stepIndex === 6) {
                dispatch(userGuideAction({ key: "run", value: false }));
              }
            }}
          >
            {translations.cancel}
          </ButtonCustom>
          <SizeBox width={"16px"} />
          <ButtonCustom
            disabled={loadingPackage || loading}
            loading={loading}
            className={"primary"}
            height={"47px"}
            fontSize={"16px"}
            onClick={handlePurchasePackage}
          >
            {translations.purchase}
          </ButtonCustom>
        </Modal.Actions>
      </ModalCenterTemplate>
    </>
  );
};

export default ModalTopUp;
