import React, { useContext, useEffect, useState } from "react";
import ModalCenterTemplate from "../../../../App/views/ModalCenterTemplate";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../components/Button";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import { LocalizationContext } from "../../../../../locales/Translation";
import InputCustom from "../../../../../components/Input";
import { debounce } from "lodash";
import {
  addPaymentViaCustomerRefService,
  getPaymentViaCustomerRefService,
} from "../../../services/BusinessServices";
import { toastError, toastSuccess } from "../../../../../components/Toast";
import DropdownCustom from "../../../../../components/DropdownCustom";
import Label from "../../../../../components/Label";
import { useParams } from "react-router-dom";
import { businessCompanyDetailThunk } from "../../../thunks/BusinessThunks";
import { useDispatch } from "react-redux";
import { isGlobalIndicatorAction } from "../../../../App/actions/AppActions";

type Props = {
  open: boolean;
  onClose: () => void;
};

const ModalAddStripeRef = ({ onClose, open }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const { id } = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [paymentInfo, setPaymentInfo] = useState<any>(null);

  const [options, setOptions] = useState<any>([]);
  const [paymentChoose, setPaymentChoose] = useState<any>(undefined);

  useEffect(() => {
    if (open) {
      return;
    }

    setPaymentChoose(undefined);
    setOptions([]);
  }, [open]);

  useEffect(() => {
    if (!paymentInfo?.methods?.length) {
      setPaymentChoose(undefined);
      setOptions([]);
      return;
    }

    setOptions(
      paymentInfo?.methods?.map(({ id, name }: any) => ({
        key: id,
        text: name,
        value: id,
      }))
    );

    setPaymentChoose(paymentInfo?.methods[0]?.id);
  }, [paymentInfo]);

  const _onChange = debounce(async (e: any, { value }: any) => {
    try {
      if (!value) {
        setPaymentChoose(undefined);
        setOptions([]);
        return;
      }
      dispatch(isGlobalIndicatorAction(true));
      const { data } = await getPaymentViaCustomerRefService(value);
      setPaymentInfo(
        Array.isArray(data)
          ? {
              error:
                "We couldn't find a credit card associated with this customer ID",
            }
          : data
      );
    } catch (e: any) {
      toastError(e?.status?.message);
    } finally {
      dispatch(isGlobalIndicatorAction(false));
    }
  }, 1000);

  const _onAddPayment = async () => {
    try {
      if (!id) {
        return;
      }

      setLoading(true);
      const payment = paymentInfo?.methods?.find(
        ({ id }: any) => id === paymentChoose
      );

      if (!payment) {
        return;
      }

      const { id: paymentMethodId, name, secret, holder_name } = payment;
      const payload = {
        customer_ref: paymentInfo?.customer_ref,
        payment_method_id: paymentMethodId,
        market_id: paymentInfo?.market_id,
        company_id: id,
        payment_method_name: name,
        secret,
        holder_name,
      };

      await addPaymentViaCustomerRefService(payload);
      await dispatch<any>(businessCompanyDetailThunk(id));
      onClose();
      toastSuccess("Added Successfully!");
    } catch (e: any) {
      toastError(e?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalCenterTemplate
      open={open}
      onClose={onClose}
      modalClassName={"modal-general"}
      style={{ maxWidth: 515 }}
    >
      <Modal.Header>
        Add Credit Card
        <ButtonCustom onClick={onClose} className={"modal-close-cta"}>
          <>
            <span>{translations.close}</span>
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>

      <Modal.Content>
        <InputCustom
          label={"Customer ID"}
          labelRequired
          placeholder={"e.g. CUS_XXXXXXXX"}
          onChange={_onChange}
          touched
          error={paymentInfo?.error}
        />

        {options.length ? (
          <>
            <Label label={"Credit Card"} />
            <DropdownCustom
              className={"w-100"}
              squareUI
              options={options}
              value={paymentChoose}
              onChange={(event, { value }) => setPaymentChoose(value)}
            />
          </>
        ) : null}
      </Modal.Content>

      <Modal.Actions>
        <ButtonCustom onClick={onClose}>{translations.cancel}</ButtonCustom>
        <ButtonCustom
          disabled={!paymentInfo?.methods?.length || loading}
          loading={loading}
          className={"primary"}
          onClick={_onAddPayment}
        >
          {translations.add}
        </ButtonCustom>
      </Modal.Actions>
    </ModalCenterTemplate>
  );
};

export default ModalAddStripeRef;
