import React from "react";
import ModalCenterTemplate from "../../../../App/views/ModalCenterTemplate";
import InquirePrivateOfficeForm from "../InquireForm";

type Props = {
  open: boolean;
  onClose: () => void;
  moreInfo: { privateOfficeId?: number; currency: string; timezone: string };
};

const ModalInquireOffice = ({ onClose, open, moreInfo }: Props) => {
  return (
    <ModalCenterTemplate
      open={open}
      onClose={onClose}
      modalClassName={"book-detail__modal-inquire"}
    >
      <InquirePrivateOfficeForm onCloseModal={onClose} moreInfo={moreInfo} />
    </ModalCenterTemplate>
  );
};

export default ModalInquireOffice;
