import React from "react";
import { Styles } from "react-joyride";
import { userGuideSendBulkInviteAction } from "../../App/actions/AppActions";

export const JoyrideStyles: Styles = {
  options: {
    zIndex: 10000,
  },
  tooltipContent: {
    padding: "unset",
    color: "white",
  },
  tooltipContainer: {
    textAlign: "left",
  },
  buttonNext: {
    height: 47,
    background: "#FFBE00",
    borderRadius: 8,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 19,
    letterSpacing: "0.02em",
    color: "#13100C",
    paddingLeft: 20,
    paddingRight: 20,
    minWidth: 147,
    outline: "none",
  },
};

const UserGuideProgress = ({
  translations,
  totalStep,
  currentStep,
}: {
  translations: any;
  totalStep: number;
  currentStep: number;
}) => {
  return (
    <>
      <div className={"react-joyride__tooltip__progress"}>
        <span
          className={"progress"}
          style={{ width: ((currentStep * 100) / totalStep).toFixed(2) + "%" }}
        />
      </div>
      <div className={"react-joyride__tooltip__step"}>
        {translations.step} {currentStep} {translations.of} {totalStep}
      </div>
    </>
  );
};

export const dashboardUserGuideSteps = (translations: any, dispatch: any) => {
  return [
    {
      content: (
        <>
          <h2 className={"react-joyride__tooltip__title"}>
            {translations.userGuideBusinessAdmin.step_0_title}
          </h2>
          <p className={"react-joyride__tooltip__subtitle"}>
            {translations.userGuideBusinessAdmin.step_0_subtitle_1}
          </p>
          <p className={"react-joyride__tooltip__subtitle"}>
            {translations.userGuideBusinessAdmin.step_0_subtitle_2}
          </p>
          <p className={"react-joyride__tooltip__subtitle_bold"}>
            1. {translations.userGuideBusinessAdmin.step_0_subtitle_3}
          </p>
          <p className={"react-joyride__tooltip__subtitle_bold"}>
            2. {translations.userGuideBusinessAdmin.step_0_subtitle_4}
          </p>
          <p className={"react-joyride__tooltip__subtitle_bold"}>
            3. {translations.userGuideBusinessAdmin.step_0_subtitle_5}
          </p>
        </>
      ),
      locale: {
        next: translations.userGuideBusinessAdmin.step_0_action,
      },
      styles: {
        options: {
          width: 579,
        },
      },
      placement: "center",
      target: "body",
    },
    {
      content: (
        <>
          <UserGuideProgress
            currentStep={1}
            totalStep={5}
            translations={translations}
          />
          <h2 className={"react-joyride__tooltip__title"}>
            {translations.userGuideBusinessAdmin.step_1_title}
          </h2>
          <p className={"react-joyride__tooltip__subtitle"}>
            {translations.userGuideBusinessAdmin.step_1_subtitle}
          </p>
        </>
      ),
      styles: {
        options: {
          width: 584,
        },
      },
      placement: "left",
      spotlightPadding: 20,
      locale: {
        next: translations.next,
      },
      target: ".guide-dashboard_chart_wrapper",
    },
    {
      content: (
        <>
          <UserGuideProgress
            currentStep={2}
            totalStep={5}
            translations={translations}
          />
          <h2 className={"react-joyride__tooltip__title"}>
            {translations.userGuideBusinessAdmin.step_2_title}
          </h2>
        </>
      ),
      placement: "bottom-end",
      styles: {
        options: {
          width: 579,
        },
      },
      locale: {
        next: translations.next,
      },
      spotlightPadding: 20,
      target: ".guide-add-employee",
      spotlightClicks: true,
    },
    {
      content: (
        <>
          <UserGuideProgress
            currentStep={3}
            totalStep={5}
            translations={translations}
          />
          <h2 className={"react-joyride__tooltip__title"}>
            {translations.userGuideBusinessAdmin.step_3_title}
          </h2>
          <p className={"react-joyride__tooltip__subtitle"}>
            {translations.userGuideBusinessAdmin.step_3_subtitle}
          </p>
        </>
      ),
      spotlightPadding: 0,
      styles: {
        options: {
          width: 445,
        },
      },
      locale: {
        next: translations.next,
      },
      placement: "left-end",
      target: ".guide-modal-add-employee",
      spotlightClicks: true,
    },
    {
      content: (
        <>
          <UserGuideProgress
            currentStep={4}
            totalStep={5}
            translations={translations}
          />
          <h2 className={"react-joyride__tooltip__title"}>
            {translations.userGuideBusinessAdmin.step_4_title}
          </h2>
          <p className={"react-joyride__tooltip__subtitle"}>
            {translations.userGuideBusinessAdmin.step_4_subtitle}
          </p>
          <button
            className={"react-joyride__tooltip__button"}
            onClick={() => {
              dispatch(userGuideSendBulkInviteAction(true));
            }}
          >
            {translations.userGuideBusinessAdmin.step_4_action_1}
          </button>
        </>
      ),
      styles: {
        options: {
          width: 478,
        },
      },
      spotlightPadding: 0,
      locale: {
        next: translations.userGuideBusinessAdmin.step_4_action_2,
      },
      placement: "left",
      target: ".guide-modal-import-employee",
      spotlightClicks: true,
    },
    {
      content: (
        <>
          <UserGuideProgress
            currentStep={5}
            totalStep={5}
            translations={translations}
          />
          <h2 className={"react-joyride__tooltip__title"}>
            {translations.userGuideBusinessAdmin.step_configure_title}
          </h2>
          <p className={"react-joyride__tooltip__subtitle"}>
            {translations.userGuideBusinessAdmin.step_configure_subtitle}
          </p>
        </>
      ),
      spotlightPadding: 0,
      styles: {
        options: {
          width: 445,
        },
      },
      locale: {
        last: translations.done,
      },
      placement: "bottom-end",
      target: ".guide-configuration",
      spotlightClicks: true,
    },
  ];
};

export const dashboardUserGuideSteps_v2 = (
  translations: any,
  dispatch: any
) => {
  return [
    {
      content: (
        <>
          <h2 className={"react-joyride__tooltip__title"}>
            {translations.userGuideBusinessAdmin.step_0_title}
          </h2>
          <p className={"react-joyride__tooltip__subtitle"}>
            {translations.userGuideBusinessAdmin.step_0_subtitle_1}
          </p>
          <p className={"react-joyride__tooltip__subtitle"}>
            {translations.userGuideBusinessAdmin.step_0_subtitle_2}
          </p>
          <p className={"react-joyride__tooltip__subtitle_bold"}>
            1. {translations.userGuideBusinessAdmin.step_0_subtitle_3}
          </p>
          <p className={"react-joyride__tooltip__subtitle_bold"}>
            2. {translations.userGuideBusinessAdmin.step_0_subtitle_4}
          </p>
          <p className={"react-joyride__tooltip__subtitle_bold"}>
            3. {translations.userGuideBusinessAdmin.step_0_subtitle_5}
          </p>
        </>
      ),
      locale: {
        next: translations.userGuideBusinessAdmin.step_0_action,
      },
      styles: {
        options: {
          width: 579,
        },
      },
      placement: "center",
      target: "body",
    },
    {
      content: (
        <>
          <UserGuideProgress
            currentStep={1}
            totalStep={3}
            translations={translations}
          />
          <h2 className={"react-joyride__tooltip__title"}>
            {translations.userGuideBusinessAdmin.step_2_title}
          </h2>
        </>
      ),
      placement: "bottom-end",
      styles: {
        options: {
          width: 579,
        },
      },
      locale: {
        next: translations.next,
      },
      spotlightPadding: 20,
      target: ".guide-add-employee",
      spotlightClicks: true,
    },
    {
      content: (
        <>
          <UserGuideProgress
            currentStep={2}
            totalStep={3}
            translations={translations}
          />
          <h2 className={"react-joyride__tooltip__title"}>
            {translations.userGuideBusinessAdmin.step_book_title}
          </h2>
        </>
      ),
      spotlightPadding: 0,
      styles: {
        options: {
          width: 445,
        },
      },
      locale: {
        next: translations.next,
      },
      placement: "bottom-end",
      target: ".guide-book",
      spotlightClicks: true,
    },
    {
      content: (
        <>
          <UserGuideProgress
            currentStep={3}
            totalStep={3}
            translations={translations}
          />
          <h2 className={"react-joyride__tooltip__title"}>
            {translations.userGuideBusinessAdmin.step_6_title}
          </h2>
          <p className={"react-joyride__tooltip__subtitle"}>
            {translations.userGuideBusinessAdmin.step_6_subtitle}
          </p>
        </>
      ),
      styles: {
        options: {
          width: 458,
        },
      },
      spotlightPadding: 0,
      locale: {
        last: translations.done,
      },
      placement: "bottom-end",
      target: ".guide-navbar__credit_balance",
      spotlightClicks: true,
    },
  ];
};

export const dashboardUserGuideSteps_v1 = (
  translations: any,
  dispatch: any
) => {
  return [
    {
      content: (
        <>
          <h2 className={"react-joyride__tooltip__title"}>
            {translations.userGuideBusinessAdmin.step_0_title}
          </h2>
          <p className={"react-joyride__tooltip__subtitle"}>
            {translations.userGuideBusinessAdmin.step_0_subtitle_1}
          </p>
          <p className={"react-joyride__tooltip__subtitle"}>
            {translations.userGuideBusinessAdmin.step_0_subtitle_2}
          </p>
          <p className={"react-joyride__tooltip__subtitle_bold"}>
            1. {translations.userGuideBusinessAdmin.step_0_subtitle_3}
          </p>
          <p className={"react-joyride__tooltip__subtitle_bold"}>
            2. {translations.userGuideBusinessAdmin.step_0_subtitle_4}
          </p>
          <p className={"react-joyride__tooltip__subtitle_bold"}>
            3. {translations.userGuideBusinessAdmin.step_0_subtitle_5}
          </p>
        </>
      ),
      locale: {
        next: translations.userGuideBusinessAdmin.step_0_action,
      },
      styles: {
        options: {
          width: 579,
        },
      },
      placement: "center",
      target: "body",
    },
    {
      content: (
        <>
          <UserGuideProgress
            currentStep={1}
            totalStep={6}
            translations={translations}
          />
          <h2 className={"react-joyride__tooltip__title"}>
            {translations.userGuideBusinessAdmin.step_1_title}
          </h2>
          <p className={"react-joyride__tooltip__subtitle"}>
            {translations.userGuideBusinessAdmin.step_1_subtitle}
          </p>
        </>
      ),
      styles: {
        options: {
          width: 584,
        },
      },
      placement: "left",
      spotlightPadding: 20,
      locale: {
        next: translations.next,
      },
      target: ".guide-dashboard_chart_wrapper",
    },
    {
      content: (
        <>
          <UserGuideProgress
            currentStep={2}
            totalStep={6}
            translations={translations}
          />
          <h2 className={"react-joyride__tooltip__title"}>
            {translations.userGuideBusinessAdmin.step_2_title}
          </h2>
        </>
      ),
      placement: "right",
      styles: {
        options: {
          width: 579,
        },
      },
      locale: {
        next: translations.next,
      },
      spotlightPadding: 20,
      target: ".guide-add-employee",
      spotlightClicks: true,
    },
    {
      content: (
        <>
          <UserGuideProgress
            currentStep={3}
            totalStep={6}
            translations={translations}
          />
          <h2 className={"react-joyride__tooltip__title"}>
            {translations.userGuideBusinessAdmin.step_3_title}
          </h2>
          <p className={"react-joyride__tooltip__subtitle"}>
            {translations.userGuideBusinessAdmin.step_3_subtitle}
          </p>
        </>
      ),
      spotlightPadding: 0,
      styles: {
        options: {
          width: 445,
        },
      },
      locale: {
        next: translations.next,
      },
      placement: "left-end",
      target: ".guide-modal-add-employee",
      spotlightClicks: true,
    },
    {
      content: (
        <>
          <UserGuideProgress
            currentStep={4}
            totalStep={6}
            translations={translations}
          />
          <h2 className={"react-joyride__tooltip__title"}>
            {translations.userGuideBusinessAdmin.step_4_title}
          </h2>
          <p className={"react-joyride__tooltip__subtitle"}>
            {translations.userGuideBusinessAdmin.step_4_subtitle}
          </p>
          <button
            className={"react-joyride__tooltip__button"}
            onClick={() => {
              dispatch(userGuideSendBulkInviteAction(true));
            }}
          >
            {translations.userGuideBusinessAdmin.step_4_action_1}
          </button>
        </>
      ),
      styles: {
        options: {
          width: 478,
        },
      },
      spotlightPadding: 0,
      locale: {
        next: translations.userGuideBusinessAdmin.step_4_action_2,
      },
      placement: "left-end",
      target: ".guide-modal-import-employee",
      spotlightClicks: true,
    },
    {
      content: (
        <>
          <UserGuideProgress
            currentStep={5}
            totalStep={6}
            translations={translations}
          />
          <h2 className={"react-joyride__tooltip__title"}>
            {translations.userGuideBusinessAdmin.step_5_title}
          </h2>
          <p className={"react-joyride__tooltip__subtitle"}>
            {translations.userGuideBusinessAdmin.step_5_subtitle}
          </p>
        </>
      ),
      spotlightPadding: 20,
      locale: {
        next: translations.next,
      },
      styles: {
        options: {
          width: 580,
        },
      },
      placement: "bottom",
      target: ".guide-navbar__credit_balance",
      spotlightClicks: true,
    },
    {
      content: (
        <>
          <UserGuideProgress
            currentStep={6}
            totalStep={6}
            translations={translations}
          />
          <h2 className={"react-joyride__tooltip__title"}>
            {translations.userGuideBusinessAdmin.step_6_title}
          </h2>
          <p className={"react-joyride__tooltip__subtitle"}>
            {translations.userGuideBusinessAdmin.step_6_subtitle}
          </p>
        </>
      ),
      styles: {
        options: {
          width: 458,
        },
      },
      spotlightPadding: 0,
      locale: {
        last: translations.skip,
      },
      placement: "left-end",
      target: ".guide-modal-package-topup",
      spotlightClicks: true,
    },
  ];
};
