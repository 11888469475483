import React from "react";

const ScheduleTable = ({ schedule }: any) => {
  return (
    <table className={"schedule-table"}>
      <tbody>
        {schedule && schedule?.length > 0
          ? schedule?.map((day: any, index: number) => {
              const info = day.split(",");
              return (
                <tr key={index}>
                  <td className={"text-capitalize"}>{info[0]}</td>
                  <td>{info[1]}</td>
                </tr>
              );
            })
          : null}
      </tbody>
    </table>
  );
};

export default ScheduleTable;
