import React from "react";
import MasterTemplate from "../../../App/views/MasterTemplate";
import { Link, Route } from "react-router-dom";
import { ROLE_DESKIMO_ADMIN_ID } from "../../../User/configs/Constants";
import "./styles.scss";
import { Tab } from "semantic-ui-react";
import {
  CONFIG_MAIN_LINK,
  CONFIG_MARKET_CREATE_LINK,
} from "../../../App/configs/ListRoutes";
import MarketCreateEditTab from "../MarketCreateEdit/MarketCreateEditTab";
import ButtonGoBack from "../../../../components/ButtonGoBack";

type Props = {
  tabIndex?: number;
};

const MarketCreate = ({ tabIndex }: Props) => {
  const panes = [
    { menuItem: <ButtonGoBack route={CONFIG_MAIN_LINK} className="mb-0" /> },
    {
      menuItem: {
        as: Link,
        id: "tab1",
        to: CONFIG_MARKET_CREATE_LINK,
        content: "Markets",
        exact: true,
      },
      pane: <Route path={CONFIG_MARKET_CREATE_LINK} />,
      render: () => <MarketCreateEditTab />,
    },
    // {
    //   menuItem: {
    //     as: Link,
    //     id: "tab2",
    //     to: PRE_PAID_LINK,
    //     content: "Public Holidays Management",
    //     exact: true,
    //   },
    //   pane: <Route path={PRE_PAID_LINK} />,
    //   render: () => <PrepaidCreditTab />,
    // },
    // {
    //   menuItem: {
    //     as: Link,
    //     id: "tab3",
    //     to: BILLING_LINK,
    //     content: "Landmark Management",
    //     exact: true,
    //   },
    //   pane: <Route path={PRE_PAID_LINK} />,
    //   render: () => <BillingTab />,
    // },
  ];
  return (
    <MasterTemplate roleAccept={[ROLE_DESKIMO_ADMIN_ID]}>
      <Tab
        menu={{ text: true }}
        activeIndex={tabIndex}
        panes={panes}
        className={"tab-custom"}
      />
    </MasterTemplate>
  );
};

export default MarketCreate;
