import React, { useContext } from "react";
import InputCustom from "../../../../../../components/Input";
import { getIn } from "formik";
import VoucherCurrency from "./VoucherCurrency";
import { CampaignCreateEditContext } from "../../../../reducers/CampaignCreateEditContext";

const VoucherCredit = () => {
  const { errors, touched, isSubmitting, handleChange, values } = useContext(
    CampaignCreateEditContext
  );

  return (
    <div className={"campaign-create-type"}>
      <InputCustom
        label={"Amount of total free credit for the whole company"}
        labelRequired
        value={values.voucher_reward.value}
        name={"voucher_reward.value"}
        onChange={handleChange}
        error={getIn(errors, "voucher_reward.value")}
        touched={getIn(touched, "voucher_reward.value")}
        disabled={isSubmitting}
      />
      <VoucherCurrency />
    </div>
  );
};

export default VoucherCredit;
