export const configMarketListSelector = (state: any) =>
  state.ConfigReducer.configMarketList;

export const configMarketExchangeRateListSelector = (state: any) =>
  state.ConfigReducer.configMarketExchangeRateList;

export const configMarketCommissionShareSelector = (state: any) =>
  state.ConfigReducer.configMarketCommissionShareValue;

export const configMarketGeneralInformationSelector = (state: any) =>
  state.ConfigReducer.configMarketGeneralInformation;

export const configMarketPublicHolidaysListSelector = (state: any) =>
  state.ConfigReducer.configMarketPublicHolidaysList;

export const configMarketLandmarkListSelector = (state: any) =>
  state.ConfigReducer.configMarketLandmarkList;

export const configTagsListSelector = (state: any) =>
  state.ConfigReducer.configTagsList;

export const marketToggleListSelector = (state: any) =>
  state.ConfigReducer.marketToggleList;

//Partner
export const partnerCredentialInfoSelector = (state: any) =>
  state.ConfigReducer.partnerCredentialInfo;
export const partnerWebhookListSelector = (state: any) =>
  state.ConfigReducer.partnerWebhookList;
export const partnerLimitationSelector = (state: any) =>
  state.ConfigReducer.partnerLimitation;

export const rolePermissionSelector = (state: any) =>
  state.ConfigReducer.rolePermission;

export const accountingListSelector = (state: any) =>
  state.ConfigReducer.accountingList;
