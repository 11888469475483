import {
  INTEGRATION_LIST_ACTION,
  INTEGRATION_UPDATE_ACTION,
} from "../configs/Actions";

export const integrationListAction = (value: any) => {
  return {
    type: INTEGRATION_LIST_ACTION,
    payload: value,
  };
};

export const integrationUpdateAction = (value: {
  id: number;
  key: string;
  value: any;
}) => {
  return {
    type: INTEGRATION_UPDATE_ACTION,
    payload: value,
  };
};
