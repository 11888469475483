import Label from "../../../../../components/Label";
import { Progress } from "semantic-ui-react";
import "./style.scss";
import Divide from "../../../../../components/Divide";
import { useEffect, useState } from "react";
import { UserCompanyType } from "../../../../User/configs/DeclareTypes";
import { useDispatch, useSelector } from "react-redux";
import { userCompanyChooseSelector } from "../../../../User/selectors/UserSelectors";
import { partnerLimitationThunk } from "../../../thunks/ConfigThunks";
import { partnerLimitationSelector } from "../../../selectors/ConfigSelectors";
import LoaderCustom from "../../../../../components/Loader";

const Limitation = () => {
  const [loading, setLoading] = useState(false);
  const userCompanyChoose: UserCompanyType = useSelector(
    userCompanyChooseSelector
  );
  const partnerLimitation = useSelector(partnerLimitationSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    _fetchPartnerLimitation();
  }, []);

  const _fetchPartnerLimitation = async () => {
    setLoading(true);
    try {
      await dispatch<any>(
        partnerLimitationThunk(userCompanyChoose?.company_id?.toString())
      );
    } catch (error: any) {
      console.log("Error: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={"limitation-wrapper flex-column gap-30"}>
      {loading ? <LoaderCustom /> : null}
      <div className="flex-column">
        <div className={"page-title"}>Response Time</div>
        <div className={"flex-row flex-1 gap-15"}>
          <div className={"flex-column flex-1"}>
            <Label className={"fw-400"} label={"Last 24 hours"} />
            <div className={"typo-20-700"}>
              {partnerLimitation?.response_time_24_hour_value}{" "}
              <span className={"typo-20-400"}>
                {partnerLimitation?.response_time_24_hour_unit}
              </span>
            </div>
          </div>
          <Divide margin={"0"} height={"49px"} />
          <div className={"flex-column flex-1"}>
            <Label className={"fw-400"} label={"Last 14 days"} />
            <div className={"typo-20-700"}>
              {partnerLimitation?.response_time_14_day_value}{" "}
              <span className={"typo-20-400"}>
                {partnerLimitation?.response_time_14_day_unit}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-column">
        <div className={"page-title"}># of API calls</div>
        <div className={"flex-row flex-1 gap-15"}>
          <div className={"flex-column flex-1"}>
            <Label className={"fw-400"} label={"Last 24 hours"} />
            <div className={"typo-20-700"}>
              {partnerLimitation?.number_api_called_24_hour_value}
            </div>
          </div>
          <Divide margin={"0"} height={"49px"} />
          <div className={"flex-column flex-1"}>
            <Label className={"fw-400"} label={"Last 14 days"} />
            <div className={"typo-20-700"}>
              {partnerLimitation?.number_api_called_14_day_value}
            </div>
          </div>
        </div>
      </div>
      <div className="flex-column">
        <div className="flex-row flex-1 gap-30">
          <div className="flex-column flex-1">
            <div className={"page-title d-flex align-items-center gap-12"}>
              Authentication API Limit{" "}
              <span className={"typo-12-400"}>
                {partnerLimitation?.auth_api_limit_max_per_month} requests /
                month
              </span>
            </div>
            <Label className={"typo-12-400 m-0"} label={"Current Usage"} />
            <Progress
              className={"deskimo-progress blue my-8"}
              percent={
                partnerLimitation?.auth_api_limit_current_usage
                  ? Math.ceil(
                      (partnerLimitation?.auth_api_limit_current_usage /
                        partnerLimitation?.auth_api_limit_max_per_month) *
                        100
                    )
                  : 0
              }
            >
              <Label
                className={"mt-8 justify-content-end"}
                label={
                  partnerLimitation?.auth_api_limit_current_usage
                    ? `${partnerLimitation?.auth_api_limit_current_usage} out of ${partnerLimitation?.auth_api_limit_max_per_month} requests`
                    : ""
                }
              />
            </Progress>
          </div>
          <div className="flex-column flex-1">
            <div className={"page-title d-flex align-items-center gap-12"}>
              Other API Limit{" "}
              <span className={"typo-12-400"}>
                {partnerLimitation?.other_api_limit_max_per_month} requests /
                month
              </span>
            </div>
            <Label className={"typo-12-400 m-0"} label={"Current Usage"} />
            <Progress
              className={"deskimo-progress blue my-8"}
              percent={
                partnerLimitation?.other_api_limit_current_usage
                  ? Math.ceil(
                      (partnerLimitation?.other_api_limit_current_usage /
                        partnerLimitation?.other_api_limit_max_per_month) *
                        100
                    )
                  : 0
              }
            >
              <Label
                className={"mt-8 justify-content-end"}
                label={
                  partnerLimitation?.other_api_limit_current_usage
                    ? `${partnerLimitation?.other_api_limit_current_usage} out of ${partnerLimitation?.other_api_limit_max_per_month} requests`
                    : ""
                }
              />
            </Progress>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Limitation;
