import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import {
  employeeDetailAction,
  employeeGoogleAction,
  employeesAction,
  usersAction,
} from "../actions/EmployeeActions";
import {
  getDeskimoUserDetailService,
  getDeskimoUsersService,
  getEmployeeByGoogleService,
  getEmployeeDetailPropertyService,
  getEmployeeDetailService,
  getEmployeesPropertyService,
  getEmployeesService,
} from "../services/EmployeeServices";
import { store } from "../../App/configs/Store";
import { BUSINESS, PROPERTY } from "../../Dashboard/configs/Constants";
import { UserCompanyType } from "../configs/DeclareTypes";

export const employeesThunk = (
  type: typeof BUSINESS | typeof PROPERTY = BUSINESS
) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const employeesFilter = store.getState().EmployeeReducer.employeesFilter;
    let data;

    if (type === BUSINESS) {
      const res = await getEmployeesService(employeesFilter);
      data = res.data;
    } else if (type === PROPERTY) {
      const res = await getEmployeesPropertyService(employeesFilter);
      data = res.data;
    }

    if (Array.isArray(data)) {
      dispatch<any>(employeesAction(null));
    } else {
      dispatch<any>(employeesAction(data));
    }
  };
};

export const deskimoUsersThunk = () => {
  const usersFilter = store.getState().EmployeeReducer.usersFilter;
  return async (dispatch: Dispatch<AnyAction>) => {
    const payload = { ...usersFilter };
    if (payload?.market_code === "0") {
      payload.market_code = "";
    }
    if (payload?.company_id === "0") {
      payload.company_id = "";
    }
    const { data } = await getDeskimoUsersService(payload);
    dispatch<any>(usersAction(data?.users ? data : null));
  };
};

export const employeeDetailThunk = (invitationId: number) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await getEmployeeDetailService({
      invitation_id: invitationId,
    });
    dispatch<any>(employeeDetailAction(data));
  };
};

export const employeeDetailPropertyThunk = (userId: number) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const userCompanyChoose: UserCompanyType | null =
      store.getState().UserReducer.userCompanyChoose;

    if (userCompanyChoose === null) {
      return;
    }

    const { data } = await getEmployeeDetailPropertyService(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      userCompanyChoose?.company_id,
      userId
    );
    dispatch<any>(employeeDetailAction(data));
    return data;
  };
};

export const deskimoUserDetailThunk = (userId: string) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await getDeskimoUserDetailService(userId);
    dispatch<any>(employeeDetailAction(data));
  };
};

export const employeeByGoogleThunk = (code: string) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await getEmployeeByGoogleService({
      auth_code: code,
    });
    dispatch<any>(employeeGoogleAction(data));
  };
};
