import ModalRightSideBarTemplate from "../../../../../../../App/views/ModalRightSideBarTemplate";
import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deskimoPropertyAmenityListSelector,
  isModalMeetingRoomCreateSelector,
  propertyDetailSelector,
} from "../../../../../../selectors/PropertySelector";
import { modalMeetingRoomCreateAction } from "../../../../../../actions/PropertyActions";
import { Formik } from "formik";
import * as yup from "yup";
import { Modal, TextArea } from "semantic-ui-react";
import SizeBox from "../../../../../../../../components/SizeBox";
import Label from "../../../../../../../../components/Label";
import ButtonCustom from "../../../../../../../../components/Button";
import { LocalizationContext } from "../../../../../../../../locales/Translation";
import { PUBLIC_URL } from "../../../../../../../App/configs/Constants";
import InputCustom from "../../../../../../../../components/Input";
import Divide from "../../../../../../../../components/Divide";
import DropzoneComponent from "../../../DetailsTab/Components/DropzoneComponent";
import AmenityItemSelect from "../../../../../../../../components/AmenityItemSelect";
import { useParams } from "react-router-dom";
import { updateMeetingRoomService } from "../../../../../../services/PropertyServices";
import {
  toastError,
  toastSuccess,
} from "../../../../../../../../components/Toast";
import {
  getDeskimoPropertyAmenityListThunk,
  getMeetingRoomThunk,
} from "../../../../../../thunks/PropertyThunks";
import {
  AMENITY_ROOM_TYPE,
  MINIMUM_BOOKING_TIME,
} from "../../../../../../configs/Constants";
import DropdownCustom from "../../../../../../../../components/DropdownCustom";
import { MyProfileType } from "../../../../../../../User/configs/DeclareTypes";
import { myProfileSelector } from "../../../../../../../App/selectors/AppSelectors";
import { ROLE_DESKIMO_ADMIN_ID } from "../../../../../../../User/configs/Constants";

const ModalCreateMeetingRoom = ({ refreshList, setRefreshList }: any) => {
  const [loading, setLoading] = useState(false);
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const isModalMeetingRoomCreate = useSelector(
    isModalMeetingRoomCreateSelector
  );
  const { id } = useParams();
  const [amenities, setAmenities] = useState<any>([]);
  const propertyDetail = useSelector(propertyDetailSelector);
  const listAmenities = useSelector(deskimoPropertyAmenityListSelector);
  const options = MINIMUM_BOOKING_TIME();
  const myProfile: MyProfileType = useSelector(myProfileSelector);

  useEffect(() => {
    _fetchListAmenities();
  }, []);

  useEffect(() => {
    if (
      listAmenities &&
      listAmenities?.amenities &&
      listAmenities?.amenities.length > 0
    ) {
      setAmenities(
        listAmenities?.amenities.map((amenity: any) => {
          return {
            id: amenity?.id,
            name: amenity?.name,
            icon_key: amenity?.image_url,
            is_chosen: false,
            is_free: true,
            uri: amenity?.image_url,
          };
        })
      );
    }
  }, [listAmenities]);

  const _handleSave = async (values: any) => {
    const payload = {
      ...values,
      property_id: id,
      amenities: amenities,
    };
    setLoading(true);
    try {
      await updateMeetingRoomService(payload);
      dispatch(modalMeetingRoomCreateAction(false));
      const meetingRoomFilter = {
        page: 1,
        keyword: "",
        statuses: [0, 1],
        property_id: id,
      };
      await dispatch<any>(getMeetingRoomThunk(meetingRoomFilter));
      setRefreshList(!refreshList);
      toastSuccess("Successfully created meeting room");
    } catch (response: any) {
      toastError(response?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const _fetchListAmenities = async () => {
    try {
      const amenityListFilter = {
        page: 1,
        item_per_page: 100,
        types: [AMENITY_ROOM_TYPE],
      };
      await dispatch<any>(
        getDeskimoPropertyAmenityListThunk(amenityListFilter)
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ModalRightSideBarTemplate
      trigger={null}
      onClose={() => dispatch(modalMeetingRoomCreateAction(false))}
      modalClassName={"modal-edit-employee"}
      open={isModalMeetingRoomCreate}
    >
      <Formik
        enableReinitialize
        initialValues={{
          name: "",
          hourly_rate: "",
          daily_rate: "",
          booking_requirement: "",
          capacity: "",
          images: [],
          amenities: [],
          position_guide: "",
          minimum_booking_time: 0,
        }}
        validationSchema={yup.object().shape({
          name: yup.string().required().label("Meeting Room Name"),
        })}
        onSubmit={_handleSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            <Modal.Header>
              <div>{"Create Meeting Room"}</div>
              <ButtonCustom
                onClick={() => dispatch(modalMeetingRoomCreateAction(false))}
                className={"m-0 p-0 none-bg close-modal"}
              >
                <>
                  {translations.settings.close}
                  <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
                </>
              </ButtonCustom>
            </Modal.Header>
            <Modal.Content className={"scroll"}>
              <InputCustom
                label={"Meeting Room Name"}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.name}
                error={errors.name}
                name={"name"}
              />
              <div className={"mb-16px"} />
              <Label
                fontSize={"12px"}
                labelGuide={
                  "Add 1-2 sentences with information about the location of the meeting room for visitors to find it more easily once they arrive at your workspace."
                }
                label={"Meeting Room Location"}
                className={"sm"}
              />
              <TextArea
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.position_guide}
                error={errors.position_guide}
                name={"position_guide"}
                className={"text-area"}
                rows={6}
                value={values.position_guide}
              />
              <div className={"mb-16px"} />
              <div className={"form-row"}>
                <InputCustom
                  label={translations.hourlyRate}
                  value={values.hourly_rate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.hourly_rate}
                  error={errors.hourly_rate}
                  name={"hourly_rate"}
                  inputLeftIcon={propertyDetail?.currency}
                  inputLeftIconBorder
                  labelGuide={translations.rememberIncludeTaxToTotalAmount}
                  removeLetter
                />
                <InputCustom
                  label={translations.dailyRateFullDay}
                  value={values.daily_rate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.daily_rate}
                  error={errors.daily_rate}
                  name={"daily_rate"}
                  inputLeftIcon={propertyDetail?.currency}
                  inputLeftIconBorder
                  labelGuide={translations.rememberIncludeTaxToTotalAmount}
                  removeLetter
                />
              </div>
              <div className={"form-row"}>
                <InputCustom
                  label={translations.capacity}
                  value={values.capacity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.capacity}
                  error={errors.capacity}
                  name={"capacity"}
                  inputLeftIcon={"People"}
                  inputLeftIconBorder
                  removeLetter
                />
                {myProfile?.role_id === ROLE_DESKIMO_ADMIN_ID ? (
                  <div>
                    <Label
                      fontSize={"12px"}
                      label={"Minimum Booking Time"}
                      className={"sm"}
                    />
                    <DropdownCustom
                      onChange={(e: any, data: any) => {
                        setFieldValue("minimum_booking_time", data.value);
                      }}
                      selectUI
                      scrolling
                      className={"w-100"}
                      pointing
                      value={values.minimum_booking_time}
                      options={options}
                    />
                  </div>
                ) : (
                  <SizeBox width={"0px"} height={"17px"} />
                )}
              </div>
              <Label
                fontSize={"12px"}
                label={"Booking Requirements"}
                className={"sm"}
              />
              <TextArea
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.booking_requirement}
                error={errors.booking_requirement}
                name={"booking_requirement"}
                className={"text-area"}
                rows={6}
                value={values.booking_requirement}
              />
              <div className={"mb-16px"} />
              <Divide type={"horizontal"} />
              <SizeBox height={"21px"} />
              <Label fontSize={"12px"} label={"Photos"} className={"sm"} />
              <DropzoneComponent
                onDragEnd={(
                  sourceId: any,
                  sourceIndex: any,
                  targetIndex: any
                ) => {
                  if (sourceIndex === targetIndex) return;
                  if (values.images) {
                    let newState = JSON.parse(JSON.stringify(values.images));
                    const sourceItem = newState[sourceIndex];

                    newState = newState.filter(
                      (image: any) => image.id !== sourceItem.id
                    );

                    if (sourceItem) {
                      newState.splice(targetIndex, 0, sourceItem);

                      newState.forEach((item: any, index: number) => {
                        item.weight = index;
                      });

                      setFieldValue("images", newState);
                    }
                  }
                }}
                isDragDropChangeOrder={true}
                value={values.images}
                deletePhotos={(id: any) => {
                  const newImages = values.images.filter(
                    (image: any) => image?.id !== id
                  );
                  setFieldValue("images", newImages);
                }}
                setPhotos={(data: any) =>
                  setFieldValue("images", [...values.images, ...data])
                }
              />
              <Divide type={"horizontal"} />
              <SizeBox height={"16px"} />
              <div>
                {amenities?.length > 0
                  ? amenities.map((amenity: any) => {
                      return (
                        <AmenityItemSelect
                          item={amenity}
                          key={amenity?.id}
                          onChangeCheck={(id: any) => {
                            const newState = amenities.map((item: any) => {
                              if (parseInt(item.id) === parseInt(id)) {
                                return { ...item, is_chosen: !item.is_chosen };
                              }

                              return item;
                            });
                            setAmenities(newState);
                          }}
                          onChangeFree={(id: any) => {
                            const newState = amenities.map((item: any) => {
                              if (parseInt(item.id) === parseInt(id)) {
                                return { ...item, is_free: !item.is_free };
                              }

                              return item;
                            });
                            setAmenities(newState);
                          }}
                        />
                      );
                    })
                  : null}
              </div>
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom
                height={"47px"}
                onClick={() => dispatch(modalMeetingRoomCreateAction(false))}
              >
                {translations.settings.cancel}
              </ButtonCustom>
              <ButtonCustom
                loading={loading}
                type={"submit"}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {translations.settings.save}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalRightSideBarTemplate>
  );
};

export default ModalCreateMeetingRoom;
