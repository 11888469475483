import { Radio } from "antd";
import { useState } from "react";
import InputCustom from "../../../../../../components/Input";
import { useDispatch, useSelector } from "react-redux";
import { deskimoPrivateOfficeFilterTmpAction } from "../../../../actions/PropertyActions";
import { deskimoPrivateOfficeFiltersTmpSelector } from "../../../../selectors/PropertySelector";

const SelectPricingRate = () => {
  const [value, setValue] = useState("daily");
  const dispatch = useDispatch();
  const filtersTmp = useSelector(deskimoPrivateOfficeFiltersTmpSelector);

  const onChange = ({ target: { value } }: any) => {
    setValue(value);
  };

  const optionsWithDisabled = [
    {
      label: "Daily Rate",
      value: "daily",
    },
    {
      label: "Monthly Rate",
      value: "monthly",
    },
  ];

  return (
    <div className={"pricing-rate-section"}>
      <Radio.Group
        options={optionsWithDisabled}
        onChange={onChange}
        value={value}
        optionType="button"
        buttonStyle="solid"
      />
      {value === "daily" ? (
        <div className={"pricing-rate-inputs"}>
          <InputCustom
            type={"number"}
            value={
              filtersTmp?.filter?.pricing_rates?.type === "daily"
                ? filtersTmp?.filter?.pricing_rates?.from
                : ""
            }
            onChange={(e: any, data: any) => {
              const newPricingRate = { ...filtersTmp?.filter?.pricing_rates };
              newPricingRate.type = "daily";
              newPricingRate.from = data.value;
              dispatch(
                deskimoPrivateOfficeFilterTmpAction({
                  ...filtersTmp,
                  filter: {
                    ...filtersTmp.filter,
                    pricing_rates: newPricingRate,
                  },
                })
              );
            }}
            placeholder={"Min rate"}
          />
          <span>to</span>
          <InputCustom
            type={"number"}
            value={
              filtersTmp?.filter?.pricing_rates?.type === "daily"
                ? filtersTmp?.filter?.pricing_rates?.to
                : ""
            }
            onChange={(e: any, data: any) => {
              const newPricingRate = { ...filtersTmp?.filter?.pricing_rates };
              newPricingRate.type = "daily";
              newPricingRate.to = data.value;
              dispatch(
                deskimoPrivateOfficeFilterTmpAction({
                  ...filtersTmp,
                  filter: {
                    ...filtersTmp.filter,
                    pricing_rates: newPricingRate,
                  },
                })
              );
            }}
            placeholder={"Max rate"}
          />
        </div>
      ) : (
        <div className={"pricing-rate-inputs"}>
          <InputCustom
            type={"number"}
            value={
              filtersTmp?.filter?.pricing_rates?.type === "monthly"
                ? filtersTmp?.filter?.pricing_rates?.from
                : ""
            }
            onChange={(e: any, data: any) => {
              const newPricingRate = { ...filtersTmp?.filter?.pricing_rates };
              newPricingRate.type = "monthly";
              newPricingRate.from = data.value;
              dispatch(
                deskimoPrivateOfficeFilterTmpAction({
                  ...filtersTmp,
                  filter: {
                    ...filtersTmp.filter,
                    pricing_rates: newPricingRate,
                  },
                })
              );
            }}
            placeholder={"Min rate"}
          />
          <span>to</span>
          <InputCustom
            type={"number"}
            value={
              filtersTmp?.filter?.pricing_rates?.type === "monthly"
                ? filtersTmp?.filter?.pricing_rates?.to
                : ""
            }
            onChange={(e: any, data: any) => {
              const newPricingRate = { ...filtersTmp?.filter?.pricing_rates };
              newPricingRate.type = "monthly";
              newPricingRate.to = data.value;
              dispatch(
                deskimoPrivateOfficeFilterTmpAction({
                  ...filtersTmp,
                  filter: {
                    ...filtersTmp.filter,
                    pricing_rates: newPricingRate,
                  },
                })
              );
            }}
            placeholder={"Max rate"}
          />
        </div>
      )}
    </div>
  );
};

export default SelectPricingRate;
