import React, { useContext, useState, useEffect } from "react";
import TableCustom from "../../../../../../components/Table";
import { LocalizationContext } from "../../../../../../locales/Translation";
import Action from "./Action";
import FilterMeetingRoom from "./FilterMeetingRoom";
import SearchMeetingRoom from "./SearchMeetingRoom";
import { meetingRoomListSelector } from "../../../../selectors/PropertySelector";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import {
  modalManagePMSAddCalendarAction,
  modalMeetingRoomAssignCalendarAction,
} from "../../../../actions/PropertyActions";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { SWAL_DELETE_OPTION } from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import { disconnectPMSRoomService } from "../../../../services/ManagePMSServices";
import { toastError, toastSuccess } from "../../../../../../components/Toast";

const MeetingRoomTable = ({
  meetingRoomFilter,
  onChangeFilter,
  onChangePagination,
  onChangeSearch,
  setRefreshList,
  refreshList,
  loading,
  onChangeSortCol,
  roomSelected,
  setRoomSelected,
}: any) => {
  const { translations } = useContext(LocalizationContext);
  const meetingRoomList = useSelector(meetingRoomListSelector);
  const dispatch = useDispatch();
  const [meetingRoomTable, setMeetingRoomTable] = useState([]);

  const _disconnectPMS = async (external_token: any) => {
    const iconHtml = (
      <img
        src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
        className={"swal2-icon__error"}
      />
    );
    const title = "Are you sure you want to disconnect this room?";
    const confirmText = (
      <div
        className={"d-flex"}
        style={{ justifyItems: "center", justifyContent: "center" }}
      >
        <div style={{ marginLeft: 5 }}>Disconnect</div>
      </div>
    );
    const confirmClass = "danger";
    const options = SWAL_DELETE_OPTION({
      title,
      iconHtml,
      confirmText,
      confirmClass,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          const payload = {
            external_room_token: external_token,
          };
          await disconnectPMSRoomService(payload);
          toastSuccess("Disconnected successfully!");
          setRefreshList(!refreshList);
        } catch (error: any) {
          toastError(error?.status?.message);
        }
      },
      cancelCallback() {
        console.log("Cancel");
      },
    });
  };

  useEffect(() => {
    if (meetingRoomList && meetingRoomList.meeting_rooms) {
      const data = meetingRoomList.meeting_rooms.map((meetingRoom: any) => {
        return {
          col1: <div>{meetingRoom?.name}</div>,
          col2: (
            <div>
              {meetingRoom?.assign_PMS_room ? (
                <div
                  className={"assign-button"}
                  onClick={() => _disconnectPMS(meetingRoom?.token)}
                >
                  Disconnect
                </div>
              ) : (
                <div
                  className={"assign-button"}
                  onClick={() => {
                    setRoomSelected(meetingRoom);
                    dispatch(modalMeetingRoomAssignCalendarAction(true));
                  }}
                >
                  Connect
                </div>
              )}
            </div>
          ),
          col3: <div>{meetingRoom?.capacity} people</div>,
          col4: <div>{meetingRoom?.hourly_rate}</div>,
          col5: (
            <div>{meetingRoom?.status === 0 ? "Unpublished" : "Published"}</div>
          ),
          col6: (
            <Action
              className={"dropdown-meeting-room-table"}
              id={meetingRoom?.id}
              meetingRoom={meetingRoom}
              setRefreshList={setRefreshList}
              refreshList={refreshList}
            />
          ),
        };
      });
      setMeetingRoomTable(data);
    } else {
      setMeetingRoomTable([]);
    }
  }, [meetingRoomList]);

  const columns = [
    { key: "name", col: "Meeting Room Name" },
    { key: null, col: "Connect PMS Rooms" },
    { key: "capacity", col: translations.capacity },
    { key: "hourly_rate", col: translations.hourlyRate },
    { key: "status", col: translations.status },
    { key: null, col: translations.action },
  ];

  return (
    <div>
      <div className={"filter-section"}>
        <SearchMeetingRoom onSearch={onChangeSearch} />
        <div className={"dropdown-filter d-flex"}>
          <FilterMeetingRoom
            filters={meetingRoomFilter.statuses}
            onChangeFilter={onChangeFilter}
          />
        </div>
      </div>
      <TableCustom
        loading={loading}
        showTotal
        sortTable
        orderBy={meetingRoomFilter.order_by}
        sortBy={meetingRoomFilter.sort_by}
        onChangeSortCol={onChangeSortCol}
        tableData={meetingRoomTable}
        columns={columns}
        currentPage={meetingRoomFilter.page}
        totalItems={meetingRoomList?.pagination?.total}
        unit={translations.meetingRooms}
        onChangePagination={onChangePagination}
        tableClass={"table-meeting-room"}
      />
    </div>
  );
};

export default MeetingRoomTable;
