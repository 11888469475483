import { useState } from "react";
import "./styles.scss";

const ReadMore = ({ children }: any) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(text?.length > 100);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  if (text?.length < 100) {
    return <p>{text}</p>;
  }

  return (
    <p className="read-more-text">
      {isReadMore ? text.slice(0, 100) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? "...Read More" : " Read Less"}
      </span>
    </p>
  );
};

export default ReadMore;
