import "./style.scss";
import { Checkbox, CheckboxProps } from "semantic-ui-react";
import classNames from "classnames";

interface Props extends CheckboxProps {
  content?: string | JSX.Element;
  value?: any;
  onChange?: any;
  revertLabel?: boolean;
  className?: string;
  fontSize?: string;
  [x: string]: any;
}

const ToggleSlider = ({
  content,
  value,
  onChange,
  revertLabel = false,
  className,
  fontSize,
  ...props
}: Props) => {
  return (
    <div
      className={classNames("toggle-slider-custom", className, {
        revert: revertLabel,
      })}
    >
      {revertLabel ? null : (
        <span style={{ fontSize: fontSize }}>{content}</span>
      )}
      <Checkbox toggle={true} checked={value} onChange={onChange} {...props} />
      {revertLabel ? (
        <span style={{ fontSize: fontSize }}>{content}</span>
      ) : null}
    </div>
  );
};

export default ToggleSlider;
