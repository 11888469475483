import React, { useState } from "react";
import ButtonCustom from "../../../../../components/Button";
import { SweetAlertCommon } from "../../../../../components/SweetAlert";
import { SWAL_CONFIRM_OPTION_V2 } from "../../../../../components/SweetAlert/configs/SwalConfigs";
import {
  LEASE,
  PAYMENT_FAIL,
  PAYMENT_SCHEDULED,
  PAYMENT_SUCCESS,
} from "../../../configs/Constants";
import {
  leasePaymentRePayService,
  leaseSendReceiptService,
  onPayWithCredit,
} from "../../../services/DashboardServices";
import { toastError, toastSuccess } from "../../../../../components/Toast";
import DropdownCustom from "../../../../../components/DropdownCustom";
import { Dropdown } from "semantic-ui-react";

type Props = {
  status: string;
  code: string;
  onRefreshing: () => void;
  canPayWithCredit: boolean;
};
const Actions = ({ status, code, onRefreshing, canPayWithCredit }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const _onPayNow = () => {
    const options = SWAL_CONFIRM_OPTION_V2({
      title: "Are you sure want to pay with CC?",
      showCancelButton: true,
      confirmText: "OK",
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          setLoading(true);
          await leasePaymentRePayService(code);
          toastSuccess("Payment Successfully");
        } catch (e: any) {
          toastError(e?.status?.message);
        } finally {
          await onRefreshing();
          setLoading(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  const _onSendReceipt = async () => {
    try {
      setLoading(true);
      const { data } = await leaseSendReceiptService(code);
      window.open(data.url, "blank");
    } catch (e: any) {
      toastError(e?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  if (
    status !== PAYMENT_FAIL &&
    status !== PAYMENT_SCHEDULED &&
    !canPayWithCredit &&
    status !== PAYMENT_SUCCESS
  ) {
    return null;
  }

  return (
    <DropdownCustom
      disabled={loading}
      className={"table-action-dropdown"}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        {status === PAYMENT_SUCCESS ? (
          <Dropdown.Item
            onClick={() => _onSendReceipt()}
            text={"Get Payment Receipt"}
            icon={<i className="uil icon uil-file-download-alt" />}
          />
        ) : null}

        {status === PAYMENT_FAIL || status === PAYMENT_SCHEDULED ? (
          <Dropdown.Item
            onClick={_onPayNow}
            text={"Pay with CC"}
            icon={<i className="uil icon uil-credit-card" />}
          />
        ) : null}

        {canPayWithCredit ? (
          <Dropdown.Item
            onClick={() => onPayWithCredit(LEASE, code, onRefreshing)}
            text={"Pay with credit"}
            icon={<i className="uil icon uil-bill" />}
          />
        ) : null}
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default Actions;
