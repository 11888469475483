export const CONFIG_MARKET_LIST_ACTION = "CONFIG_MARKET_LIST_ACTION";
export const CONFIG_MARKET_EXCHANGE_RATE_LIST_ACTION =
  "CONFIG_MARKET_EXCHANGE_RATE_LIST_ACTION";
export const CONFIG_MARKET_GENERAL_INFORMATION_ACTION =
  "CONFIG_MARKET_GENERAL_INFORMATION_ACTION";
export const CONFIG_MARKET_COMMISSION_SHARE_ACTION =
  "CONFIG_MARKET_COMMISSION_SHARE_ACTION";

export const CONFIG_UPDATE_MARKET_GENERAL_INFORMATION_ACTION =
  "CONFIG_UPDATE_MARKET_GENERAL_INFORMATION_ACTION";
export const CONFIG_UPDATE_MARKET_STATUS_ACTION =
  "CONFIG_UPDATE_MARKET_STATUS_ACTION";

export const CONFIG_MARKET_PUBLIC_HOLIDAYS_LIST_ACTION =
  "CONFIG_MARKET_PUBLIC_HOLIDAYS_LIST_ACTION";

export const CONFIG_CREATE_MARKET_PUBLIC_HOLIDAYS =
  "CONFIG_CREATE_MARKET_PUBLIC_HOLIDAYS";

export const CONFIG_DELETE_MARKET_PUBLIC_HOLIDAYS =
  "CONFIG_DELETE_MARKET_PUBLIC_HOLIDAYS";

export const CONFIG_MARKET_LANDMARK_LIST_ACTION =
  "CONFIG_MARKET_LANDMARK_LIST_ACTION";

export const CONFIG_CREATE_MARKET_LANDMARK = "CONFIG_CREATE_MARKET_LANDMARK";

export const CONFIG_DELETE_MARKET_LANDMARK = "CONFIG_DELETE_MARKET_LANDMARK";

export const SELECT_YEAR_NONE_VALUE = "";

export const CONFIG_TAGS_LIST_ACTION = "CONFIG_TAGS_LIST_ACTION";

export const MARKET_TOGGLE_LIST_ACTION = "MARKET_TOGGLE_LIST_ACTION";

export const ONBOARD_PROMOTION_NONE = 0;
export const ONBOARD_PROMOTION_FREE_TRAIL = 1;
export const ONBOARD_PROMOTION_PAID_TRAIL = 2;

export const PARTNER_CREDENTIAL_INFO_ACTION = "PARTNER_CREDENTIAL_INFO_ACTION";
export const PARTNER_WEBHOOK_LIST_ACTION = "PARTNER_WEBHOOK_LIST_ACTION";
export const PARTNER_LIMITATION_ACTION = "PARTNER_LIMITATION_ACTION";

export const ROLE_PERMISSION_ACTION = "ROLE_PERMISSION_ACTION";
export const SAVE_ROLE_PERMISSION_ACTION = "SAVE_ROLE_PERMISSION_ACTION";

export const ACCOUNTING_ACTION = "ACCOUNTING_ACTION";
