import React from "react";
import DropdownCustom from "../../../../../components/DropdownCustom";

type Props = {
  status: string;
  onChange: (value: any) => void;
};

const FilterStatus = ({ onChange, status }: Props) => {
  return (
    <DropdownCustom
      selectUI
      squareUI
      options={[
        { key: 4, text: "All statuses", value: "4" },
        { key: 2, text: "Published", value: "2" },
        { key: 0, text: "Unpublished", value: "0" },
        { key: 1, text: "Expired", value: "1" },
      ]}
      search
      value={status ? status : "4"}
      onChange={(e, { value }) => {
        onChange(value);
      }}
    />
  );
};

export default FilterStatus;
