import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import { LocalizationContext } from "../../../../locales/Translation";
import { useNavigate, useParams } from "react-router-dom";
import { getSettingService } from "../../../App/services/AppServices";
import { Formik, FormikValues } from "formik";
import { toastError, toastSuccess } from "../../../../components/Toast";
import { BUSINESS_COMPANY_LIST_LINK } from "../../../App/configs/ListRoutes";
import MasterTemplate from "../../../App/views/MasterTemplate";
import { ROLE_DESKIMO_ADMIN_ID } from "../../../User/configs/Constants";
import * as yup from "yup";
import ButtonCustom from "../../../../components/Button";
import { BusinessCompanyDetailType } from "../../configs/DeclareTypes";
import ContactInfo from "./Components/ContactInfo";
import Contract from "./Components/Contract";
import WMSConfiguration from "./Components/WMSConfiguration";
import OnboardingPromotion from "./Components/OnboardingPromotion";
import {
  businessCompanyDetailService,
  companyUpdateCreateService,
} from "../../services/BusinessServices";
import SectionWhiteBox from "../../../../components/SectionWhiteBox";
import PaymentTerms from "./Components/PaymentTerms";
import LoaderCustom from "../../../../components/Loader";
import Tags from "./Components/Tags";
import ProductAccess from "./Components/ProductAccess";
import PartnerConfiguration from "./Components/PartnerConfiguration";
import BusinessPaymentMethodConfiguration from "./Components/BusinessPaymentMethodConfiguration";
import WorkspaceRestriction from "./Components/WorkspaceRestriction";
import AffiliateSection from "./Components/AffiliateSection";

const CompanyEditCreate = () => {
  const { translations } = useContext(LocalizationContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);

  const [businessCompanyData, setBusinessCompanyData] =
    useState<BusinessCompanyDetailType | null>(null);

  const [marketList, setMarketList] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        _getSetting();

        if (!id) {
          return;
        }

        const { data } = await businessCompanyDetailService(id);
        setBusinessCompanyData(data);
      } catch (e: any) {
        toastError(e?.status?.message);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const _getSetting = async () => {
    const { data } = await getSettingService();
    setMarketList(data?.location);
  };

  const _onSubmit = async (values: FormikValues) => {
    try {
      values.property_restriction = values.property_restriction.map(
        (property: any) => {
          return {
            id: property?.key,
            name: property?.text,
          };
        }
      );
      await companyUpdateCreateService(values);
      if (id) {
        toastSuccess(translations.editSuccessPrompt);
      } else {
        toastSuccess(translations.createSuccessPrompt);
      }
      navigate(BUSINESS_COMPANY_LIST_LINK);
    } catch (e: any) {
      toastError(e?.status.message);
    }
  };

  return (
    <MasterTemplate roleAccept={[ROLE_DESKIMO_ADMIN_ID]}>
      <>
        {loading ? <LoaderCustom /> : null}
        <SectionWhiteBox
          isShowBack
          backRoute={BUSINESS_COMPANY_LIST_LINK}
          className={"business-company-create"}
        >
          <Formik
            enableReinitialize
            initialValues={{
              company_id: businessCompanyData?.company_id || null,
              company_name: businessCompanyData?.company_name || "",
              contact_name: businessCompanyData?.contact_name || "",
              contact_email: businessCompanyData?.contact_email || "",
              contact_phone: businessCompanyData?.contact_phone || "",
              dialing_code: businessCompanyData?.dialing_code || "65",
              contact_address: businessCompanyData?.contact_address || "",
              payment_term: businessCompanyData?.payment_term || "",
              currency: businessCompanyData?.currency || "SGD",
              market_code: businessCompanyData?.market_code || "SG",
              free_credit: businessCompanyData?.free_credit || "",
              free_credit_duration:
                businessCompanyData?.free_credit_duration || "",
              onboard_campaign_start_time:
                businessCompanyData?.onboard_campaign_start_time || null,
              onboard_campaign_end_time:
                businessCompanyData?.onboard_campaign_end_time || null,
              number_of_trial_day:
                businessCompanyData?.number_of_trial_day || "",
              wms_price_per_user: businessCompanyData?.wms_price_per_user || "",
              contract_file: businessCompanyData?.contract_file || "",
              tag_ids: businessCompanyData?.tag_ids?.map(({ id }) => id) || [],
              force_credit_popup:
                typeof businessCompanyData?.force_credit_popup === "boolean"
                  ? businessCompanyData?.force_credit_popup
                  : true,
              has_saas_fee: businessCompanyData?.has_saas_fee || false,
              total_saas_fee: businessCompanyData?.total_saas_fee || 0,
              products: businessCompanyData?.products,
              partner_desk_commission:
                businessCompanyData?.partner_desk_commission || 0,
              partner_room_commission:
                businessCompanyData?.partner_room_commission || 0,
              authentication_api_limit:
                businessCompanyData?.authentication_api_limit || 0,
              other_api_limit: businessCompanyData?.other_api_limit || 0,
              payment_method: businessCompanyData?.payment_method || null,
              property_restriction:
                businessCompanyData?.property_restriction &&
                businessCompanyData?.property_restriction?.length > 0
                  ? businessCompanyData?.property_restriction.map(
                      (property: any) => {
                        return {
                          key: property?.id,
                          text: property?.name,
                          value: property?.id,
                        };
                      }
                    )
                  : [],
              can_buy_credit: businessCompanyData?.can_buy_credit !== false,
              company_affiliate: businessCompanyData?.company_affiliate || {
                is_active: false,
                commission: 0,
                duration: 0,
              },
            }}
            validationSchema={yup.object().shape({
              company_affiliate: yup.object().shape({
                is_active: yup.boolean(),
                commission: yup.number().when("is_active", {
                  is: true,
                  then: yup
                    .number()
                    .required()
                    .min(0)
                    .max(100)
                    .label("Commission"),
                  otherwise: yup
                    .number()
                    .min(0)
                    .max(100)
                    .nullable()
                    .label("Commission"),
                }),
                duration: yup.number().when("is_active", {
                  is: true,
                  then: yup
                    .number()
                    .required()
                    .min(0)
                    .max(100)
                    .label("Duration"),
                  otherwise: yup
                    .number()
                    .min(0)
                    .max(100)
                    .nullable()
                    .label("Duration"),
                }),
              }),
              contact_name: yup.string().required().label("Contact name"),
              company_name: yup.string().required().label("Company name"),
              partner_desk_commission: yup
                .number()
                .min(0)
                .max(100, "Maximum value is 100"),
              partner_room_commission: yup
                .number()
                .min(0)
                .max(100, "Maximum value is 100"),
              authentication_api_limit: yup.number(),
              other_api_limit: yup.number(),
              contact_email: yup
                .string()
                .email()
                .required()
                .label("Contact email"),
              contact_phone: yup.number().label("Contact phone"),
            })}
            onSubmit={_onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              resetForm,
            }) => (
              <>
                <ContactInfo
                  values={values}
                  errors={errors}
                  touched={touched}
                  isSubmitting={isSubmitting}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />

                <Tags values={values} setFieldValue={setFieldValue} />

                <ProductAccess values={values} setFieldValue={setFieldValue} />

                <PaymentTerms
                  values={values}
                  errors={errors}
                  touched={touched}
                  isSubmitting={isSubmitting}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  marketList={marketList}
                />

                <AffiliateSection
                  values={values}
                  errors={errors}
                  touched={touched}
                  isSubmitting={isSubmitting}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />

                {values?.products?.find((item) => item?.id === 4)?.is_active ? (
                  <PartnerConfiguration
                    errors={errors}
                    touched={touched}
                    isSubmitting={isSubmitting}
                    handleChange={handleChange}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                ) : null}

                <OnboardingPromotion
                  values={values}
                  errors={errors}
                  touched={touched}
                  isSubmitting={isSubmitting}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />

                {businessCompanyData ? (
                  <>
                    <BusinessPaymentMethodConfiguration
                      values={values}
                      errors={errors}
                      touched={touched}
                      isSubmitting={isSubmitting}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />

                    <WorkspaceRestriction
                      values={values}
                      errors={errors}
                      touched={touched}
                      isSubmitting={isSubmitting}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  </>
                ) : null}

                <WMSConfiguration
                  values={values}
                  errors={errors}
                  touched={touched}
                  isSubmitting={isSubmitting}
                  handleChange={handleChange}
                />

                <Contract values={values} setFieldValue={setFieldValue} />

                <div className={"section-white-box-actions"}>
                  <ButtonCustom
                    className={"cancel mr-10px"}
                    onClick={resetForm}
                    disabled={isSubmitting}
                  >
                    {translations.cancel}
                  </ButtonCustom>
                  <ButtonCustom
                    className={"primary mr-0"}
                    onClick={handleSubmit}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    {id ? translations.save : translations.add}
                  </ButtonCustom>
                </div>
              </>
            )}
          </Formik>
        </SectionWhiteBox>
      </>
    </MasterTemplate>
  );
};

export default CompanyEditCreate;
