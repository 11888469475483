export const API_BUSINESS_COMPANY_POST_PAY =
  "/api/v1/des-admin/business-company/%companyId%/post-pay";

export const API_BUSINESS_COMPANY_CAN_BUY_CREDIT =
  "api/v1/des-admin/business-companies/%companyId%/credit";
export const API_BUSINESS_COMPANY_DELETE =
  "/api/v1/des-admin/business-company/%companyId%/delete";
export const API_BUSINESS_COMPANY_DETAIL =
  "/api/v3/des-admin/business-company/%companyId%/detail";

export const API_BUSINESS_COMPANY = "/api/v1/des-admin/business-company";

export const EDIT_BUSINESS_COMPANY_STAFF_API = "/api/v1/employee/profile";

export const BUSINESS_INVOICE_POST_PAID_API =
  "/api/v1/des-admin/business-company/update-invoice-status/%token%";

export const BUSINESS_INVOICE_DELETE_API =
  "/api/v1/des-admin/business-company/delete-invoice/%token%";

export const REINVITE_BUSINESS_COMPANY_USER_API =
  "/api/v1/employee/%id%/re-invite";

export const DELETE_BUSINESS_COMPANY_USER_API = "/api/v1/employee/%id%/delete";

export const DEACTIVATE_BUSINESS_COMPANY_USER_API =
  "/api/v1/employee/%id%/deactivate";

export const UPDATE_BUSINESS_COMPANY_USER_API =
  "/api/v1/employee/update-employee-profile";

export const POST_BUSINESS_CUSTOM_PACKAGE_LIST_API =
  "/api/v1/des-admin/custom-packages";

export const BUSINESS_CUSTOM_PACKAGE_DETAIL_API =
  "/api/v1/des-admin/custom-package/:packageId";

export const DELETE_BUSINESS_PACKAGE_API =
  "/api/v1/des-admin/custom-package/:packageType/:packageId";

export const POST_CREATE_BUSINESS_PACKAGE_API =
  "/api/v1/des-admin/custom-package";

export const GET_BUSINESS_INVOICE_API =
  "/api/v1/company-invoices/:companyId/invoice/:token";

export const POST_GENERATE_BUSINESS_INVOICE_API =
  "api/v1/company-invoices/generate-manually";

export const API_BUSINESS_COMPANY_LIST = "/api/v1/des-admin/business-companies";

export const API_BUSINESS_COMPANY_AUTO_TOPUP_INFO =
  "api/v3/business-company/%companyId%/auto-topup";

export const API_CREDIT_PACKAGE_AMOUNT_INFO =
  "/api/v3/credit-packages/query/param";

export const API_ALL_BUSINESS_COMPANY_LIST =
  "/api/v1/des-admin/all-business-companies";

export const POST_DELETE_MODIFY_CUSTOM_FEE_API =
  "api/v1/company-invoices/%companyId%/invoice/%token%/item";

export const OFFICE_LEASES_CONTRACT_API = "/api/v1/lease-contract";
export const OFFICE_LEASES_CONTRACT_CHECK_API = "/api/v3/lease-contract/check";
// export const OFFICE_LEASES_CONTRACT_CHECK_API =
//   "https://run.mocky.io/v3/a1de2147-e459-415c-bedc-46dcc483cd68";
export const OFFICE_LEASES_CONTRACT_LIST_API = "/api/v1/lease-contracts";
export const OFFICE_LEASES_DETAIL_API = "/api/v1/lease-contracts/%id%";
export const OFFICE_LEASES_DEPOSIT_API = "/api/v1/lease-contract-deposits";
export const OFFICE_LEASES_REFUND_API =
  "/api/v1/lease-contract-deposits/refund";
export const OFFICE_LEASES_END_API = "/api/v1/lease-contracts/%id%/end-lease";
export const CREDIT_HISTORY_API = "/api/v1/credit-transactions";
export const CREDIT_USAGE_API = "/api/v1/credit-usage";
export const CANCEL_CREDIT_PACKAGE_API = "/api/v1/cancel-package";
export const DISABLE_PACKAGE_ROLL_OVER_API =
  "/api/v3/credit-transaction/%transactionId%/off-rollover";
export const STOP_AUT_TOP_UP_API = "/api/v1/change-auto-topup-package-status";
export const CREDIT_PAY_CC_API = "/api/v1/pay-with-cc-credit-payment";
export const CREDIT_CHANGE_EXPIRY_DATE_API =
  "/api/v1/change-credit-package-expiry";
export const RECORD_CREDIT_PAYMENT_API = "/api/v1/record-credit-payment";
export const BUSINESS_COMPANY_CREDIT_PACKAGE_LIST_API =
  "/api/v1/des-admin/business-companies-credit-package";
export const PURCHASE_CUSTOM_PACKAGE_API = "/api/v1/purchase-custom-package";
export const BUSINESS_INVOICE_REPAY_API =
  "/api/v3/company-invoices/%token%/pay-now";

export const RESEND_BUSINESS_INVOICE_API =
  "/api/v3/company-invoices/%token%/resend";
export const COMPANY_LIST_TYPE_BUSINESS =
  "/api/v1/companies/paging-autocomplete/%keyword%";
export const COMPANY_LIST_TYPE_WORKSPACE =
  "/api/v1/companies/paging-autocomplete/%keyword%?type=1&show_deleted=%showDeleted%&page=%page%";

export const DESKIMO_ADMIN_DELETE_INVITATION =
  "/api/v1/pending-request-invitation/%pendingInvitationId%";
export const DELETE_INVITATION =
  "/api/v1/pending-request-invitation/%pendingInvitationId%?company_id=%companyId%";

export const API_GET_PAYMENT_VIA_CUSTOMER_REF =
  "/api/v3/payment-method/customer-ref/%customerRef%";

export const API_ADD_PAYMENT_VIA_CUSTOMER_REF =
  "/api/v3/payment-method/customer-ref";

export const API_AUTOCOMPLETE_AFFILIATE_COMPANY = "/api/v3/affiliate-company";
