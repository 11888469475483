import React from "react";

const NoResult = () => {
  return (
    <div className={"text-center"}>
      <div className={"no-data-available"}>
        <i className="uil uil-frown" />
        Sorry, no results found!
      </div>
    </div>
  );
};

export default NoResult;
