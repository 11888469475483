import { useDispatch, useSelector } from "react-redux";
import {
  paymentHistoryFilterSelector,
  paymentHistorySelector,
} from "../../selectors/BillingSelector";
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { getInvoicePropertyFiltersService } from "../../services/SettingServices";
import { paymentHistoryFilterAction } from "../../actions/BiilingActions";
import CircleStatus from "../../../../components/CircleStatus";
import { PUBLIC_URL } from "../../../App/configs/Constants";
import SizeBox from "../../../../components/SizeBox";
import DropdownWithCheckboxMenuWithTotal from "../../../../components/DropdownWithCheckboxMenuWithTotal";
import TableCustom from "../../../../components/Table";
import SearchBar4Table from "../../../../components/SearchBar4Table";
import { getPaymentHistoryPropertyThunk } from "../../thunks/BillingThunk";
import { debounce } from "lodash";
import { LocalizationContext } from "../../../../locales/Translation";
import Action from "./Action";
import { Link } from "react-router-dom";
import { INVOICE_DETAIL_PROPERTY_LINK } from "../../../App/configs/ListRoutes";

const InvoiceList = () => {
  const { translations } = useContext(LocalizationContext);

  const dispatch = useDispatch();
  const paymentHistory = useSelector(paymentHistorySelector);
  const [tableFilter, setTableFilter] = useState({
    statuses: [],
    page: 1,
    order_by: "",
    sort_by: "",
    keyword: "",
  });
  const paymentHistoryFilters = useSelector(paymentHistoryFilterSelector);

  const [loadingTable, setLoadingTable] = useState(true);

  const _getInvoiceFilters = async () => {
    try {
      const response = await getInvoicePropertyFiltersService();
      const filters = response?.data;
      if (filters.length > 0) {
        dispatch(paymentHistoryFilterAction(filters));

        setTableFilter((prevState) => {
          return {
            ...prevState,
            statuses: filters.map((filter: any) => filter?.status),
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    _getInvoiceFilters();
  }, []);

  //Handle for payment history table
  useEffect(() => {
    (async () => {
      try {
        if (!tableFilter?.statuses?.length) {
          return;
        }
        setLoadingTable(true);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await dispatch<any>(getPaymentHistoryPropertyThunk(tableFilter));
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingTable(false);
      }
    })();
  }, [tableFilter]);

  const invoiceStatus = ["Draft", "Pending Payment", "Paid"];

  const decorateData = (data: []) => {
    if (!data || data.length < 1) return [];
    return data.map((field: any) => {
      return {
        col1: (
          <Link
            to={INVOICE_DETAIL_PROPERTY_LINK.replaceAll(":token", field?.token)}
          >
            {field?.invoice_no}
          </Link>
        ),
        col2: field?.name,
        col3: field?.total,
        col4: field?.time_period,
        col5: field?.due_date,
        col6: (
          <div className={"d-flex align-items-center"}>
            <CircleStatus color={getInvoiceColor(field?.status)} />
            {invoiceStatus[field?.status]}
          </div>
        ),
        col7: (
          <Action
            token={field?.token}
            pdfUrl={field?.invoice_csv_url}
            xlxsUrl={field?.invoice_xlsx_url}
          />
        ),
      };
    });
  };

  const getInvoiceColor = (status: number) => {
    switch (status) {
      case 0:
        return "orange";
      case 1:
        return "yellow";
      case 2:
        return "green";
    }
  };

  const tableData = decorateData(paymentHistory?.data?.invoices);

  const columns = [
    translations.invoiceNo,
    translations.company,
    translations.totalAmount,
    translations.timePeriod,
    translations.settings.dueDate,
    translations.status,
    translations.action,
  ];

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    setTableFilter((prevState) => {
      return { ...prevState, page: activePage };
    });
  };

  const _onChangeFilter = (e: SyntheticEvent, data: any) => {
    if (data.checked) {
      setTableFilter((prevState: any) => {
        return {
          ...prevState,
          statuses: [...tableFilter.statuses, data.value],
        };
      });
    } else {
      setTableFilter((prevState: any) => {
        return {
          ...prevState,
          statuses: tableFilter.statuses.filter(
            (status) => status !== data.value
          ),
        };
      });
    }
  };

  const getTableFilter = () => {
    if (paymentHistoryFilters.length) {
      return paymentHistoryFilters.map((filter: any) => {
        return {
          id: filter?.status,
          name: `${filter?.status_name} (${filter?.total})`,
        };
      });
    }
  };

  const debounce_onChange = debounce(function (value) {
    setTableFilter((prevState: any) => {
      return { ...prevState, keyword: value };
    });
  }, 500);

  return (
    <div className={"billing-tab__right"}>
      <div className="billing-tab__right-title">Invoice History</div>
      <SizeBox height={"25px"} />
      <div className={"d-flex justify-content-between"}>
        <SearchBar4Table
          placeholder={translations.searchByInvoiceNo}
          onChange={(event, { value }) => debounce_onChange(value)}
        />
        <div className={"dropdown-filter"}>
          <DropdownWithCheckboxMenuWithTotal
            values={tableFilter.statuses}
            onChange={_onChangeFilter}
            listCheckBox={getTableFilter()}
            label={translations.filters}
            icon={<img src={PUBLIC_URL + "/assets/icons/icon-filter.svg"} />}
          />
        </div>
      </div>
      <SizeBox height={"15px"} />
      <TableCustom
        sortTable={false}
        loading={loadingTable}
        onChangePagination={_onChangePagination}
        showTotal
        tableData={tableData}
        columns={columns}
        currentPage={paymentHistory?.data?.pagination?.page}
        totalItems={paymentHistory?.data?.pagination?.total}
        unit={translations.invoices}
      />
    </div>
  );
};

export default InvoiceList;
