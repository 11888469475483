import React, { useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  DROPZONE_MAX_FILE_3MB,
  DROPZONE_MAX_FILE_3MB_TEXT,
} from "../../../../../App/configs/DropzoneConfig";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { toastError } from "../../../../../../components/Toast";
import Label from "../../../../../../components/Label";
import { FormikSetFieldValueType } from "../../../../../Configuration/configs/DeclareTypes";
import "./style.scss";
import ButtonCustom from "../../../../../../components/Button";
import { uploadIconImageService } from "../../../../services/PropertyAmenityServices";

type Props = {
  setFieldValue: FormikSetFieldValueType;
  iconPreview: string;
  values: string;
  error: any;
  touched: any;
};

const DropzoneIcon = ({
  setFieldValue,
  iconPreview,
  error,
  touched,
}: Props) => {
  const { translations } = useContext(LocalizationContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDragOver, setIsDragOver] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: [".jpg", ".png", ".jpeg"],
    maxSize: DROPZONE_MAX_FILE_3MB,
    useFsAccessApi: false,
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles?.length > 0) {
        const formData = new FormData();
        formData.append("icon", acceptedFiles[0]);
        try {
          setLoading(true);
          const response = await uploadIconImageService(formData);
          if (response.data) {
            setFieldValue("icon_key", response.data.file_key);
            setFieldValue("image_url", response.data.url);
          }
        } catch (response: any) {
          console.error(response?.status.message);
        } finally {
          setLoading(false);
        }

        setIsDragOver(false);
      }
    },

    onDropRejected: (fileRejections) => {
      let isFileTooLarge;
      fileRejections.forEach((file) => {
        isFileTooLarge =
          file.errors.filter((error) => error.code === "file-too-large")
            .length > 0;
        if (isFileTooLarge) {
          toastError(
            `Icon ${file.file.name} is larger than ${DROPZONE_MAX_FILE_3MB_TEXT}`
          );
        }
      });
    },

    onDragOver: () => {
      setIsDragOver(true);
    },

    onDragLeave: () => {
      setIsDragOver(false);
    },
  });

  const _onRemoveIcon = () => {
    setFieldValue("icon_key", "");
    setFieldValue("image_url", "");
  };

  return (
    <div className="mb-32px">
      <Label
        fontSize={"12px"}
        className={"dropzone-input-label"}
        label={translations.propertyAmenities.iconColumnHeader}
      />
      {iconPreview ? (
        <div className="icon-preview-img">
          <img src={iconPreview} width={68} height={68} alt="icon_thumbnail" />
          <ButtonCustom
            type={"button"}
            onClick={_onRemoveIcon}
            className={"image-preview-delete"}
          >
            <i className="uil uil-trash" />
          </ButtonCustom>
        </div>
      ) : null}
      <div
        {...getRootProps({
          className:
            "dropzone dropzone-box" +
            (isDragOver ? " drag-over" : "") +
            (loading ? " disabled" : ""),
        })}
      >
        <input {...getInputProps()} />
        <i className="uil uil-cloud-upload pt-14px" />
        <div className={"dropzone-guide-title flex-column pb-14px"}>
          <div className="pb-10px">{translations.dragAndDropFilesHereOr}</div>
          <ButtonCustom
            className="primary-alt"
            height={"36px"}
            loading={loading}
          >
            {translations.propertyAmenities.browseFile}
          </ButtonCustom>
        </div>
      </div>
      {error && touched ? <span className={"error"}>{error}</span> : null}
    </div>
  );
};

export default DropzoneIcon;
