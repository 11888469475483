import { FormikValues } from "formik";
import React, { useEffect, useState } from "react";
import { partnerGetMeetingRoomService } from "../../../../services/DashboardServices";
import { debounce } from "lodash";
import DropdownSearchServerSingle from "../../../../../../components/DropdownSearchServerSingle";
import { useSelector } from "react-redux";
import { userCompanyChooseSelector } from "../../../../../User/selectors/UserSelectors";

type Props = {
  values: FormikValues;
  setFieldValue: any;
  disabled?: boolean;
};

const DropdownMeetingRoom = ({ values, setFieldValue, disabled }: Props) => {
  const [options, setOptions] = useState<
    {
      key: number;
      text: string;
      id: number;
    }[]
  >([]);
  const userCompanyChoose = useSelector(userCompanyChooseSelector);

  useEffect(() => {
    (async () => {
      await _initOptions();
    })();
  }, [values.property_reference]);

  const _initOptions = async (keyword = "") => {
    try {
      setFieldValue("meeting_room_reference", "");
      setFieldValue("meeting_room_name", "");

      if (!values.property_reference) {
        return;
      }

      const {
        data: { meeting_rooms },
      } = await partnerGetMeetingRoomService({
        market_code: userCompanyChoose.market_code,
        property_reference: values.property_reference,
        lat: null,
        lng: null,
        from_time: null,
        to_time: null,
        keyword,
        page: 1,
      });

      if (!meeting_rooms?.length) {
        setOptions([]);
        return;
      }

      const temp = meeting_rooms?.map(
        ({ reference, name }: { reference: string; name: string }) => {
          return {
            key: reference,
            text: name,
            id: reference,
          };
        }
      );
      setOptions(temp);
    } catch (e) {
      console.log(e);
    }
  };

  const debounce_onChange = debounce(async (keyword: string) => {
    await _initOptions(keyword);
  }, 300);

  return (
    <>
      <DropdownSearchServerSingle
        isFullWidth={true}
        value={{
          text: values.meeting_room_name,
          id: values.meeting_room_reference,
        }}
        onSearch={(searchQuery) => debounce_onChange(searchQuery)}
        options={options}
        disabled={disabled || !values.property_reference}
        placeholder={"Select meeting room"}
        onChange={(value) => {
          setFieldValue("meeting_room_reference", value.id);
          setFieldValue("meeting_room_name", value.text);
        }}
      />
    </>
  );
};

export default DropdownMeetingRoom;
