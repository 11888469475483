import TableCustom from "../../../../../../components/Table";
import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import { Label as LabelSemantic } from "semantic-ui-react";
import { employeesThunk } from "../../../../thunks/EmployeeThunks";
import {
  employeesFilterSelector,
  employeesSelector,
} from "../../../../selectors/EmployeeSelectors";
import { EmployeePropertyType } from "../../../../configs/DeclareTypes";
import { employeesFilterAction } from "../../../../actions/EmployeeActions";
import Action from "./Action";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../../../App/configs/Constants";
import { PROPERTY } from "../../../../../Dashboard/configs/Constants";

const EmployeeTable = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const employees = useSelector(employeesSelector);
  const employeesFilter = useSelector(employeesFilterSelector);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await dispatch<any>(employeesThunk(PROPERTY));
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [employeesFilter]);

  const columns = [
    translations.userName,
    translations.email,
    translations.accountStatus,
    translations.action,
  ];

  const _formatInputData = () => {
    return employees?.data?.map(
      ({
        last_name,
        first_name,
        email,
        status,
        user_id,
        is_admin,
      }: EmployeePropertyType) => {
        return {
          col1: (
            <div>
              {first_name + " " + last_name}
              {is_admin && (
                <LabelSemantic size={"mini"} className={"ml-4px"}>
                  {translations.propertyAdmin}
                </LabelSemantic>
              )}
            </div>
          ),
          truncate1: email,
          col6: translations.accountStatusList[status],
          col7: (
            <Action
              userId={user_id}
              status={status}
              isAdmin={is_admin}
              setLoading={setLoading}
            />
          ),
        };
      }
    );
  };

  const _onChangeSortCol = (col: any) => {
    if (employeesFilter.sort_by !== col.key) {
      dispatch(employeesFilterAction({ key: "sort_by", value: col.key }));
      dispatch(employeesFilterAction({ key: "order_by", value: ORDER_BY_ASC }));
    } else {
      dispatch(employeesFilterAction({ key: "sort_by", value: col.key }));
      if (employeesFilter.order_by === ORDER_BY_NONE) {
        dispatch(
          employeesFilterAction({ key: "order_by", value: ORDER_BY_ASC })
        );
      }
      if (employeesFilter.order_by === ORDER_BY_ASC) {
        dispatch(
          employeesFilterAction({ key: "order_by", value: ORDER_BY_DESC })
        );
      }
      if (employeesFilter.order_by === ORDER_BY_DESC) {
        dispatch(
          employeesFilterAction({ key: "order_by", value: ORDER_BY_NONE })
        );
      }
    }
  };

  return (
    <div
      className={"employee-table"}
      style={employees?.data?.length < 3 ? { paddingBottom: 100 } : {}}
    >
      <TableCustom
        onChangeSortCol={_onChangeSortCol}
        sortTable={false}
        loading={loading}
        showTotal
        tableData={employees ? _formatInputData() : []}
        columns={columns}
        currentPage={employees?.pagination?.page}
        totalItems={employees?.pagination?.total}
        unit={translations.usersLowerCase}
        onChangePagination={(e: any, { activePage }: any) => {
          dispatch(employeesFilterAction({ key: "page", value: activePage }));
        }}
      />
    </div>
  );
};

export default EmployeeTable;
