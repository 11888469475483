import React, { useEffect, useRef, useState } from "react";
import TableCustom from "../../../../../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import { Tab } from "semantic-ui-react";
import ButtonCustom from "../../../../../../components/Button";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
  PUBLIC_URL,
} from "../../../../../App/configs/Constants";
import "./style.scss";
import { affiliateListThunk } from "../../../../thunks/BillingThunk";
import { affiliateListSelector } from "../../../../selectors/BillingSelector";
import CopyToClipboard from "../../../../../../components/CopyToClipboard";
import moment from "moment";
import Radio from "../../../../../../components/Radio";
import SizeBox from "../../../../../../components/SizeBox";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import {
  affiliateRequestPayoutService,
  getAffiliateInfoService,
} from "../../../../services/SettingServices";
import { myProfileSelector } from "../../../../../App/selectors/AppSelectors";
import Action from "./Action";
import CopyToClipboardTrigger from "../../../../../../components/CopyToClipboard/CoppyToClipboardTrigger";
import { QRCode } from "antd";
import { QRCodeCanvas } from "qrcode.react";
import html2canvas from "html2canvas";

const AffiliateTab = () => {
  const dispatch = useDispatch();
  const affiliateList = useSelector(affiliateListSelector);
  const myProfile = useSelector(myProfileSelector);

  const [affiliateInfo, setAffiliateInfo] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>({
    page: 1,
    order_type: "",
    order_by: "ASC",
  });
  const [payoutOption, setPayoutOption] = useState(null);
  const [submitPayoutLoading, setSubmitPayoutLoading] = useState(false);
  const qrWorkspaceRef = useRef<any>(null);
  const qrBusinessRef = useRef<any>(null);

  useEffect(() => {
    (() => _onInit())();
  }, []);

  useEffect(() => {
    (async () => _onInitTable)();
  }, [filters]);

  const _onInitTable = async () => {
    try {
      setLoading(true);
      await dispatch<any>(affiliateListThunk(filters));
    } catch (e: any) {
      toastError(e?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const _onInit = async () => {
    try {
      const { data } = await getAffiliateInfoService();
      setAffiliateInfo(data);

      if (data?.request_info?.length) {
        setPayoutOption(data?.request_info[0].key);
      }
    } catch (e: any) {
      toastError(e?.status?.message);
    }
  };

  const _onSubmitPayout = async () => {
    try {
      setSubmitPayoutLoading(true);
      await affiliateRequestPayoutService(affiliateInfo?.reference, {
        option: payoutOption,
      });
      await _onInit();
      toastSuccess("Submitted Successfully!");
    } catch (e: any) {
      toastError(e?.status?.message);
    } finally {
      setSubmitPayoutLoading(false);
    }
  };

  const columns = [
    { key: null, col: "Company Name" },
    { key: null, col: "Type" },
    { key: null, col: "Sign-up month" },
    { key: null, col: "Status" },
    { key: null, col: "Revenue" },
  ];

  const columnsDeskimoAdmin = [
    { key: null, col: "Company Name" },
    { key: null, col: "Type" },
    { key: null, col: "Sign-up month" },
    { key: null, col: "Status" },
    { key: null, col: "Revenue" },
    { key: null, col: "Action" },
  ];

  const onChangeSortColumn = (col: any) => {
    if (filters.order_by !== col.key) {
      setFilters((prevState: any) => {
        return { ...prevState, order_by: col.key, order_type: ORDER_BY_ASC };
      });
    } else {
      if (filters.order_type === ORDER_BY_NONE) {
        setFilters((prevState: any) => {
          return { ...prevState, order_by: col.key, order_type: ORDER_BY_ASC };
        });
      }
      if (filters.order_type === ORDER_BY_DESC) {
        setFilters((prevState: any) => {
          return { ...prevState, order_by: col.key, order_type: ORDER_BY_NONE };
        });
      }
      if (filters.order_type === ORDER_BY_ASC) {
        setFilters((prevState: any) => {
          return { ...prevState, order_by: col.key, order_type: ORDER_BY_DESC };
        });
      }
    }
  };

  const decorateData = () => {
    if (!affiliateList?.items?.length) {
      return [];
    }

    if (myProfile?.login_on_behalf) {
      return affiliateList?.items?.map((field: any) => {
        return {
          name: field?.name,
          commission: field?.type,
          singUpMonth: moment.unix(field?.onboard_date).format("MM/YYYY"),
          status: (
            <span className={field?.status?.toLowerCase().replaceAll(" ", "-")}>
              {field?.status}
            </span>
          ),
          revenue: `${field?.currency} ${field?.revenue}`,
          action: (
            <Action
              reference={field?.reference}
              payable={field?.payable}
              onRefresh={_onInitTable}
            />
          ),
        };
      });
    }

    return affiliateList?.items?.map((field: any) => {
      return {
        name: field?.name,
        commission: field?.type,
        singUpMonth: moment.unix(field?.onboard_date).format("MM/YYYY"),
        status: (
          <span className={field?.status?.toLowerCase().replaceAll(" ", "-")}>
            {field?.status}
          </span>
        ),
        revenue: `${field?.currency} ${field?.revenue}`,
      };
    });
  };

  const downloadQRCode = (ref: any) => {
    const qrCodeElement: any = ref.current;

    html2canvas(qrCodeElement).then((canvas) => {
      const image = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.download = "qrcode.png";
      link.href = image;
      link.click();
    });
  };

  return (
    <>
      <Tab.Pane
        attached={false}
        className={"setting-tab flex-column affiliate-tab"}
      >
        <div className={"affiliate-info"}>
          <div className="affiliate-info-left">
            <div className="title">Earn with Deskimo</div>
            <div className="desc">
              <div className="text">
                Join the Deskimo Affiliate Program and start earning by
                <br />
                referring companies or workspaces to Deskimo.
              </div>
            </div>
            <div className="text-strong">How it works</div>
            <ol className={"list-des"}>
              <li>
                Share your personalized referral link with a company or
                workspace
              </li>
              <li>The referred party signs up using your link</li>
              <li>
                We track the generated revenues for{" "}
                {affiliateInfo?.affiliate_duration} months
              </li>
              <li>
                Earn {affiliateInfo?.affiliate_commission}% of the revenues
                generated from your referral
              </li>
              <li>
                After {affiliateInfo?.affiliate_duration} months, request your
                payout in cash or Deskimo credits (50% bonus for credits!)
              </li>
            </ol>
          </div>
          <div className="affiliate-info-right">
            <img src={PUBLIC_URL + "/assets/images/affiliate-hero.png"} />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexFlow: "column nowrap",
            gap: 12,
            marginBottom: 32,
            fontSize: 16,
            color: "black",
          }}
        >
          <div className={"meta"}>
            <div className="text">
              Know a company that could benefit from Deskimo? Share this link:
            </div>
            <div className="text-link">
              <span className={""}>{affiliateInfo?.affiliate_link}</span>
              <div className={"d-flex gap-8"}>
                <CopyToClipboardTrigger
                  text={affiliateInfo?.affiliate_link}
                  trigger={
                    <ButtonCustom
                      height={"30px"}
                      style={{ padding: 8, margin: 0 }}
                      className={"primary"}
                    >
                      <>
                        Copy <i className="uil uil-copy"></i>
                      </>
                    </ButtonCustom>
                  }
                />
                <ButtonCustom
                  onClick={() => downloadQRCode(qrBusinessRef)}
                  className={"yellow"}
                  height={"34px"}
                >
                  <div className={"d-flex gap-8 text-nowrap"}>
                    QR Code <i className="uil uil-qrcode-scan"></i>
                  </div>
                </ButtonCustom>
                <div className={"qrcode-image-hidden"} ref={qrBusinessRef}>
                  <div className={"qrcode-wrapper"}>
                    <img
                      src={
                        PUBLIC_URL +
                        "/assets/images/qr-code-frame-variant-2.svg"
                      }
                      alt=""
                    />
                    <div className={"qr-code-image-box"}>
                      <QRCodeCanvas
                        size={225}
                        value={affiliateInfo?.affiliate_link}
                        imageSettings={{
                          src: PUBLIC_URL + "/assets/logo-square.svg",
                          x: undefined,
                          y: undefined,
                          height: 36,
                          width: 36,
                          excavate: true,
                        }}
                      />
                      <div
                        className={"qrcode-image-hidden-text"}
                        style={{ visibility: "hidden" }}
                      >
                        Deskimo.com
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"meta"}>
            <div className="text">
              Know a workspace that should join Deskimo?
            </div>

            <div className="text-link">
              <span className={""}>
                {affiliateInfo?.workspace_affiliate_link}
              </span>
              <div className={"d-flex gap-8 text-nowrap"}>
                <CopyToClipboardTrigger
                  text={affiliateInfo?.workspace_affiliate_link}
                  trigger={
                    <ButtonCustom
                      height={"30px"}
                      style={{ padding: 8, margin: 0 }}
                      className={"primary"}
                    >
                      <>
                        Copy <i className="uil uil-copy"></i>
                      </>
                    </ButtonCustom>
                  }
                />
                <ButtonCustom
                  onClick={() => downloadQRCode(qrWorkspaceRef)}
                  className={"yellow"}
                  height={"34px"}
                >
                  <div className={"d-flex gap-8"}>
                    QR Code <i className="uil uil-qrcode-scan"></i>
                  </div>
                </ButtonCustom>
                <div className={"qrcode-image-hidden"} ref={qrWorkspaceRef}>
                  <div className={"qrcode-wrapper"}>
                    <img
                      src={
                        PUBLIC_URL +
                        "/assets/images/qr-code-frame-variant-2.svg"
                      }
                      alt=""
                    />
                    <div className={"qr-code-image-box"}>
                      <QRCodeCanvas
                        size={225}
                        value={affiliateInfo?.workspace_affiliate_link}
                        imageSettings={{
                          src: PUBLIC_URL + "/assets/logo-square.svg",
                          x: undefined,
                          y: undefined,
                          height: 36,
                          width: 36,
                          excavate: true,
                        }}
                      />
                      <div
                        className={"qrcode-image-hidden-text"}
                        style={{ visibility: "hidden" }}
                      >
                        Deskimo.com
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div style={{ fontSize: 16, fontWeight: 600, color: "black" }}>
            Your earnings
          </div>
          <SizeBox height={8} />
          <TableCustom
            emptyText={
              "Your affiliate code hasn't been used by any company yet."
            }
            tableClass={"bordered"}
            columns={myProfile?.login_on_behalf ? columnsDeskimoAdmin : columns}
            sortTable
            loading={loading}
            showTotal={false}
            tableData={decorateData()}
            currentPage={filters.page}
            totalItems={25}
            sortBy={filters.order_by}
            orderBy={filters.order_type}
            onChangeSortCol={onChangeSortColumn}
            onChangePagination={(_: any, { activePage }: any) => {
              setFilters({ ...filters, page: activePage });
            }}
          />
        </div>
        {affiliateInfo?.request_info?.length ? (
          <>
            <SizeBox height={16} />
            <div style={{ fontSize: 14, fontWeight: 600 }}>Request payout</div>
            <SizeBox height={16} />
            {affiliateInfo.request_info.map(({ key, text }: any) => (
              <>
                <Radio
                  key={key}
                  checked={payoutOption === key}
                  label={text}
                  onClick={() => setPayoutOption(key)}
                />
                <SizeBox height={16} />
              </>
            ))}

            <div>
              <ButtonCustom
                height={"28px"}
                fontSize={"12px"}
                className={"primary"}
                onClick={_onSubmitPayout}
                loading={submitPayoutLoading}
              >
                Submit Request
              </ButtonCustom>
            </div>
          </>
        ) : null}
      </Tab.Pane>
    </>
  );
};

export default AffiliateTab;
