import MasterTemplate from "../../../App/views/MasterTemplate";
import Summary from "./Summary";
import React from "react";
import "../../styles/dashboard.scss";
import SizeBox from "../../../../components/SizeBox";
import UsageList from "./UsageList";
import Property from "./Property";
import {
  ROLE_PROPERTY_ADMIN_ID,
  ROLE_PROPERTY_STAFF_ID,
} from "../../../User/configs/Constants";
import { usePermissionCheck } from "../../../../utils/custom-hooks";
import { UI_ACCESS_STATISTIC_PROPERTY_PER } from "../../../../utils/PermissionConstant";

const Dashboard = () => {
  return (
    <MasterTemplate
      roleAccept={[ROLE_PROPERTY_ADMIN_ID, ROLE_PROPERTY_STAFF_ID]}
    >
      <Property />
      {usePermissionCheck(UI_ACCESS_STATISTIC_PROPERTY_PER) && (
        <>
          <SizeBox height={"80px"} />
          <Summary />
        </>
      )}
      <SizeBox height={"80px"} />
      <UsageList />
    </MasterTemplate>
  );
};

export default Dashboard;
