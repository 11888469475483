import React, { useContext, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import {
  TAB_DESK_BOOKINGS_INDEX,
  TAB_DESK_USAGE_INDEX,
  TAB_PRIVATE_OFFICES_INDEX,
  TAB_ROOM_BOOKINGS_INDEX,
} from "../../../../configs/Constants";
import Search from "../../../Dashboard/UsageList/Search";
import ButtonCustom from "../../../../../../components/Button";
import classNames from "classnames";
import FilterDeskUsage from "../UsageList/FilterDeskUsage";
import FilterDeskBooking from "../UsageList/FilterDeskBooking";
import FilterRoomBooking from "../UsageList/FilterRoomBooking";
import TableDeskUsage from "../UsageList/TableDeskUsage";
import TableDeskBookings from "../UsageList/TableDeskBookings";
import TableRoomBookings from "../UsageList/TableRoomBookings";
import FilterPrivateOffice from "../../../Dashboard/OfficeLeases/Filter";
import OfficeLeases from "../../../Dashboard/OfficeLeases";

const UsageList = () => {
  const { translations } = useContext(LocalizationContext);
  const [tabIndex, setTabIndex] = useState(TAB_DESK_USAGE_INDEX);
  const _onChangeTab = (value: number) => {
    setTabIndex(value);
  };

  return (
    <>
      <div className={"filter-section"}>
        <Search isDeskimoAdmin />
        <div className={"button-group yellow"}>
          <ButtonCustom
            height={"36px"}
            className={classNames({
              active: tabIndex === TAB_DESK_USAGE_INDEX,
            })}
            onClick={() => _onChangeTab(TAB_DESK_USAGE_INDEX)}
          >
            {translations.deskWalkIn}
          </ButtonCustom>
          <ButtonCustom
            height={"36px"}
            className={classNames({
              active: tabIndex === TAB_DESK_BOOKINGS_INDEX,
            })}
            onClick={() => _onChangeTab(TAB_DESK_BOOKINGS_INDEX)}
          >
            {translations.deskBookings}
          </ButtonCustom>
          <ButtonCustom
            height={"36px"}
            className={classNames({
              active: tabIndex === TAB_ROOM_BOOKINGS_INDEX,
            })}
            onClick={() => _onChangeTab(TAB_ROOM_BOOKINGS_INDEX)}
          >
            {translations.roomBookings}
          </ButtonCustom>
          <ButtonCustom
            height={"36px"}
            className={classNames({
              active: tabIndex === TAB_PRIVATE_OFFICES_INDEX,
            })}
            onClick={() => _onChangeTab(TAB_PRIVATE_OFFICES_INDEX)}
          >
            Private Offices
          </ButtonCustom>
        </div>

        {tabIndex === TAB_DESK_USAGE_INDEX ? (
          <FilterDeskUsage />
        ) : tabIndex === TAB_DESK_BOOKINGS_INDEX ? (
          <FilterDeskBooking />
        ) : tabIndex === TAB_ROOM_BOOKINGS_INDEX ? (
          <FilterRoomBooking />
        ) : (
          <FilterPrivateOffice />
        )}
      </div>

      <div className={"dashboard_table"}>
        {tabIndex === TAB_DESK_USAGE_INDEX ? (
          <TableDeskUsage />
        ) : tabIndex === TAB_DESK_BOOKINGS_INDEX ? (
          <TableDeskBookings />
        ) : tabIndex === TAB_ROOM_BOOKINGS_INDEX ? (
          <TableRoomBookings />
        ) : (
          <OfficeLeases />
        )}
      </div>
    </>
  );
};

export default UsageList;
