import { Accordion, Dropdown, Modal } from "semantic-ui-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import ButtonCustom from "../../../../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { modalEditEmployeeAction } from "../../../../actions/EmployeeActions";
import {
  employeeDetailSelector,
  isModalEditEmployeeSelector,
} from "../../../../selectors/EmployeeSelectors";
import ModalRightSideBarTemplate from "../../../../../App/views/ModalRightSideBarTemplate";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { Formik } from "formik";
import * as yup from "yup";
import Label from "../../../../../../components/Label";
import SizeBox from "../../../../../../components/SizeBox";
import InputCustom from "../../../../../../components/Input";
import BoxToggleSlider from "../../../../../../components/BoxToggleSlider";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { EmployeePropertyDetailType } from "../../../../configs/DeclareTypes";
import {
  employeePropertyUpdateService,
  searchPropertyService,
} from "../../../../services/EmployeeServices";
import { employeesThunk } from "../../../../thunks/EmployeeThunks";
import { LocalizationContext } from "../../../../../../locales/Translation";
import MakeAdminLabelGuide from "../../../../../App/views/ModalAddEmployee/MakeAdminLableGuide";
import { PROPERTY } from "../../../../../Dashboard/configs/Constants";
import { ROLE_PROPERTY_ADMIN_ID } from "../../../../configs/Constants";

const ModalEditEmployee = () => {
  const { translations } = useContext(LocalizationContext);

  const dispatch = useDispatch();
  const isModalEditEmployee = useSelector(isModalEditEmployeeSelector);
  const formRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState([0, 1]);
  const [listPropertyAssigned, setListPropertyAssigned] = useState<any>([]);
  const [searchText, setSearchText] = useState("");
  const [listPropertySearch, setListPropertySearch] = useState([]);
  const [propertyWillAdd, setPropertyWillAdd] = useState<any>({});
  const [tmpProperty, setTmpProperty] = useState("");

  const employeeDetail: EmployeePropertyDetailType = useSelector(
    employeeDetailSelector
  );

  useEffect(() => {
    _fetchListProperty();
  }, [searchText]);

  useEffect(() => {
    if (employeeDetail?.properties?.length > 0) {
      setListPropertyAssigned(
        employeeDetail?.properties.map((property: any) => {
          return {
            key: property?.id,
            text: property?.name,
            value: property?.id,
          };
        })
      );
    }
  }, [employeeDetail]);

  const _fetchListProperty = async () => {
    try {
      const response = await searchPropertyService({ keyword: searchText });
      if (response?.data && response?.data?.length > 0) {
        setListPropertySearch(
          response?.data.map((property: any) => {
            return {
              key: property?.id,
              text: property?.text,
              value: property?.id,
            };
          })
        );
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const _handleSave = async (values: any) => {
    try {
      setLoading(true);
      values.properties = listPropertyAssigned.map(
        (property: any) => property.key
      );
      await employeePropertyUpdateService(values);
      dispatch(modalEditEmployeeAction(false));
      await dispatch<any>(employeesThunk(PROPERTY));
      toastSuccess("Updated successfully");
    } catch (e: any) {
      toastError(e?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const _onCloseModal = () => {
    dispatch(modalEditEmployeeAction(false));
    setListPropertyAssigned([]);
  };

  const handleClick = (e: any, title: any) => {
    const index = title.index;
    if (activeIndex.includes(index)) {
      setActiveIndex(activeIndex.filter((item) => item !== index));
    } else {
      setActiveIndex([...activeIndex, index]);
    }
  };

  return (
    <ModalRightSideBarTemplate
      trigger={null}
      onClose={_onCloseModal}
      modalClassName={"modal-right-sidebar-v2 modal-edit-employee"}
      open={isModalEditEmployee}
    >
      <Modal.Header>
        {translations.editUser}
        <ButtonCustom
          onClick={_onCloseModal}
          className={"m-0 p-0 none-bg close-modal"}
        >
          <>
            {translations.close}
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Modal.Content className={"scroll"}>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={{
            user_id: employeeDetail?.user_id,
            first_name: employeeDetail?.first_name || "",
            last_name: employeeDetail?.last_name || "",
            make_admin: employeeDetail?.role_id === ROLE_PROPERTY_ADMIN_ID,
          }}
          validationSchema={yup.object().shape({
            first_name: yup.string().required().label("First Name"),
          })}
          onSubmit={_handleSave}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => (
            <form className={"form-layout"}>
              <Accordion
                className={"accordion-wrapper"}
                defaultActiveIndex={[0, 1]}
                exclusive={false}
                fluid
              >
                <Accordion.Title
                  className={"accordion-title"}
                  active={activeIndex.includes(0)}
                  index={0}
                  onClick={handleClick}
                >
                  Basic Information
                  <img
                    className="dropdown icon"
                    src={PUBLIC_URL + "/assets/icons/icon-caret-down.svg"}
                    alt={""}
                  />
                </Accordion.Title>
                <Accordion.Content active={activeIndex.includes(0)}>
                  <SizeBox height={"16px"} />
                  <InputCustom
                    labelRequired
                    label={"First Name"}
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.first_name}
                    error={errors.first_name}
                    name={"first_name"}
                  />
                  <InputCustom
                    label={translations.lastName}
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.last_name}
                    error={errors.last_name}
                    name={"last_name"}
                  />
                  <div className={"employee-meta__row"}>
                    <div className={"fw-500"}>{translations.email}:</div>{" "}
                    {employeeDetail?.email}
                  </div>
                  {employeeDetail?.phone ? (
                    <>
                      <SizeBox height={"26px"} />
                      <div className={"employee-meta__row"}>
                        <div className={"fw-500"}>{translations.phoneNo}:</div>{" "}
                        {employeeDetail?.phone}
                      </div>
                    </>
                  ) : null}
                </Accordion.Content>
                <BoxToggleSlider
                  value={values.make_admin}
                  onChange={(e: any, data: any) =>
                    setFieldValue("make_admin", data.checked)
                  }
                  content={
                    <Label
                      fontSize={"14px"}
                      size={"16px"}
                      label={translations.makeAdmin}
                      labelGuide={<MakeAdminLabelGuide />}
                    />
                  }
                />
                <SizeBox height={"16px"} />
                <>
                  <Accordion.Title
                    className={"accordion-title"}
                    active={activeIndex.includes(1)}
                    index={1}
                    onClick={handleClick}
                  >
                    Property
                    <img
                      className="dropdown icon"
                      src={PUBLIC_URL + "/assets/icons/icon-caret-down.svg"}
                      alt={""}
                    />
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex.includes(1)}>
                    <div
                      className={"d-flex w-100"}
                      style={{ marginBottom: 16 }}
                    >
                      <Dropdown
                        onSearchChange={(event, data) =>
                          setSearchText(data.searchQuery)
                        }
                        onChange={(event, data: any) => {
                          setTmpProperty(data?.value);
                          const isAssigned =
                            listPropertyAssigned.filter(
                              (property: any) => property?.value === data?.value
                            ).length > 0;
                          if (!isAssigned) {
                            setPropertyWillAdd(
                              listPropertySearch.filter(
                                (property: any) =>
                                  property?.value === data.value
                              )[0]
                            );
                          }
                        }}
                        value={tmpProperty}
                        className={"dropdown-search-property"}
                        placeholder="Search property name..."
                        search
                        icon={"search"}
                        selection
                        options={listPropertySearch}
                      />
                      <SizeBox width={"16px"} />
                      <ButtonCustom
                        type={"button"}
                        onClick={() => {
                          setSearchText("");
                          setTmpProperty("");
                          if (propertyWillAdd) {
                            setListPropertyAssigned([
                              ...listPropertyAssigned,
                              propertyWillAdd,
                            ]);
                            setPropertyWillAdd(null);
                          }
                        }}
                        className={"default text-nowrap add-property-cta"}
                      >
                        Add Property
                      </ButtonCustom>
                    </div>
                    <div>
                      {listPropertyAssigned.length > 0
                        ? listPropertyAssigned.map(
                            (property: any, index: number) => {
                              if (!property?.text) {
                                return null;
                              }
                              return (
                                <div
                                  key={index}
                                  className={"property-assigned"}
                                >
                                  {property?.text}{" "}
                                  <ButtonCustom
                                    type={"button"}
                                    onClick={() => {
                                      setListPropertyAssigned(
                                        (prevState: any) => {
                                          let newState = JSON.parse(
                                            JSON.stringify(prevState)
                                          );
                                          newState = newState.filter(
                                            (item: any) => {
                                              return item.key != property.key;
                                            }
                                          );
                                          return newState;
                                        }
                                      );
                                    }}
                                    className={"none-bg"}
                                  >
                                    <i className="uil uil-times" />
                                  </ButtonCustom>
                                </div>
                              );
                            }
                          )
                        : null}
                    </div>
                  </Accordion.Content>
                </>
              </Accordion>
              <SizeBox height={"26px"} />
            </form>
          )}
        </Formik>
      </Modal.Content>
      <Modal.Actions>
        <ButtonCustom height={"47px"} onClick={_onCloseModal}>
          {translations.cancel}
        </ButtonCustom>
        <ButtonCustom
          type={"submit"}
          loading={loading}
          className={"primary"}
          height={"47px"}
          onClick={() => {
            formRef?.current?.handleSubmit();
          }}
        >
          {translations.save}
        </ButtonCustom>
      </Modal.Actions>
    </ModalRightSideBarTemplate>
  );
};

export default ModalEditEmployee;
