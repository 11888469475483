import React, { useContext } from "react";
import { LocalizationContext } from "../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import { userCompanyChooseSelector } from "../../selectors/UserSelectors";
import { DIALING_CODE } from "../../../App/configs/PhonePrefix";
import ModalCenterTemplate from "../../../App/views/ModalCenterTemplate";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../components/Button";
import { PUBLIC_URL } from "../../../App/configs/Constants";
import { Formik } from "formik";
import * as yup from "yup";
import SizeBox from "../../../../components/SizeBox";
import InputCustom from "../../../../components/Input";
import InputPhoneFlag from "../../../../components/InputPhoneFlag";
import { isModalAddTeamMemberAction } from "../../actions/PartnerTeamActions";
import { isModalAddTeamMemberSelector } from "../../selectors/PartnerTeamMemberSelectors";
import { invitePartnerTeamMember } from "../../services/PartnerTeamService";
import { toastError, toastSuccess } from "../../../../components/Toast";
import { teamMemberListThunk } from "../../thunks/PartnerTeamThunks";

const ModalAddTeamMember = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch: any = useDispatch();

  const userCompanyChoose = useSelector(userCompanyChooseSelector);
  const isModalAddTeamMember = useSelector(isModalAddTeamMemberSelector);

  const _onClickSave = async (values: any, { resetForm }: any) => {
    try {
      const payload = { ...values };

      if (payload.phone) {
        payload.country_code = DIALING_CODE.filter(
          (country) =>
            country.key.trim().toLowerCase() ===
            values.country_code.trim().toLowerCase()
        )[0].value.replaceAll("+", "");
      } else {
        payload.country_code = "";
      }

      await invitePartnerTeamMember(payload);
      toastSuccess("Added Successfully!");
      dispatch(teamMemberListThunk());
      resetForm();
      _onCloseModal();
    } catch (e: any) {
      toastError(e?.status?.message);
    }
  };

  const _onCloseModal = () => {
    dispatch(isModalAddTeamMemberAction(false));
  };

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={_onCloseModal}
      modalClassName={"modal-general"}
      open={isModalAddTeamMember}
    >
      <Modal.Header>
        Add Team Member
        <ButtonCustom onClick={_onCloseModal} className={"modal-close-cta"}>
          <>
            <span>{translations.close}</span>
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          country_code: userCompanyChoose?.alpha2_country_code || "SG",
        }}
        validationSchema={yup.object().shape({
          first_name: yup.string().required().label("First Name"),
          email: yup.string().email().required().label("Email"),
          phone: yup.number(),
        })}
        onSubmit={_onClickSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <>
            <Modal.Content>
              <form className={"form-layout"}>
                <div className={"form-row"}>
                  <InputCustom
                    labelRequired={true}
                    label={"First Name"}
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.first_name}
                    error={errors.first_name}
                    name={"first_name"}
                  />
                  <InputCustom
                    label={translations.lastName}
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.last_name}
                    error={errors.last_name}
                    name={"last_name"}
                  />
                </div>
                <InputCustom
                  labelRequired
                  label={translations.emailAddress}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.email}
                  error={errors.email}
                  name={"email"}
                />
                <div className={"mb-16px"} />
                <InputPhoneFlag
                  label={translations.mobilePhoneNumber}
                  selectedFlag={values.country_code}
                  onSelectFlag={(code) => setFieldValue("country_code", code)}
                  error={errors.phone}
                  touched={touched.phone}
                  phoneChange={(value) => setFieldValue("phone", value)}
                  phoneValue={values.phone}
                />
                <SizeBox height={"7px"} />
                <span className={"mini-text symbol-required-pre"}>
                  {translations.mandatoryFields}
                </span>
              </form>
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom
                disabled={isSubmitting}
                height={"47px"}
                onClick={_onCloseModal}
              >
                {translations.cancel}
              </ButtonCustom>
              <ButtonCustom
                loading={isSubmitting}
                disabled={isSubmitting}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {translations.add}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalCenterTemplate>
  );
};

export default ModalAddTeamMember;
