import React from "react";
import moment from "moment-timezone";
import { FORMAT_DATE_TIME_VARIANT_9 } from "../../../../../../App/configs/DateTimeConfigs";
import Avatar from "./Avatar";

type Props = {
  item: any[];
};
const EditLog = ({ item }: Props) => {
  return (
    <div className={"property-note-edit-log"}>
      {item.map(({ date, name, avatar, cap_name }, index) => (
        <div className={"item"} key={index}>
          <div className={"date"}>
            {moment.unix(date).format(FORMAT_DATE_TIME_VARIANT_9)}
          </div>
          <div className={"user"}>
            <Avatar imgUrl={avatar} capName={cap_name} />
            <span className={"user-name "}>{name}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EditLog;
