import { Slider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { deskimoPrivateOfficeFiltersTmpSelector } from "../../../../selectors/PropertySelector";
import { deskimoPrivateOfficeFilterTmpAction } from "../../../../actions/PropertyActions";

const SelectArea = () => {
  const dispatch = useDispatch();
  const filtersTmp = useSelector(deskimoPrivateOfficeFiltersTmpSelector);

  const marks = {
    1: (
      <span>
        {"< 5"} m<sup>2</sup>
      </span>
    ),
    50: "Any",
  };

  const onChange = (value: any) => {
    if (value >= 50) {
      dispatch(
        deskimoPrivateOfficeFilterTmpAction({
          ...filtersTmp,
          filter: { ...filtersTmp.filter, area: "all" },
        })
      );
    } else {
      dispatch(
        deskimoPrivateOfficeFilterTmpAction({
          ...filtersTmp,
          filter: { ...filtersTmp.filter, area: value },
        })
      );
    }
  };

  return (
    <div className={"pricing-rate-section"}>
      <Slider
        value={filtersTmp?.filter.area === "all" ? 50 : filtersTmp?.filter.area}
        onChange={(value: any) => {
          onChange(value);
        }}
        min={1}
        max={50}
        marks={marks}
        defaultValue={50}
      />
    </div>
  );
};

export default SelectArea;
