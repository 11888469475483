const TypeSelection = ({
  type,
  isCheck,
  icon,
  iconChecked,
  onClick,
}: {
  type: string;
  isCheck: boolean;
  icon: string;
  iconChecked: string;
  onClick?: any;
}) => {
  return (
    <div
      onClick={onClick}
      className={"type-selection-item " + (isCheck ? "checked" : "")}
    >
      {isCheck ? <i className="uil uil-check" /> : null}
      {icon ? (
        <img
          className={"type-selection-item__icon"}
          src={isCheck ? iconChecked : icon}
          alt=""
        />
      ) : null}
      <div className={"type-selection-item__name"}>{type}</div>
    </div>
  );
};

export default TypeSelection;
