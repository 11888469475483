import React, { useContext } from "react";
import SearchBar4Table from "../../../../../components/SearchBar4Table";
import { LocalizationContext } from "../../../../../locales/Translation";

const SearchProperty = ({ onSearch }: any) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <SearchBar4Table
      placeholder={translations.propertyPage.searchByProperty}
      onChange={onSearch}
    />
  );
};

export default SearchProperty;
