import ToggleSlider from "../../../../../../../components/ToggleSlider";
import React from "react";

const QRCodeStatus = ({ item, onChangeStatus, status }: any) => {
  return (
    <ToggleSlider
      value={status > 0}
      onChange={(e: any, data: any) => {
        onChangeStatus(item?.id, data.checked ? 1 : 0);
      }}
    />
  );
};

export default QRCodeStatus;
