import React, { useState } from "react";
import classNames from "classnames";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../../components/Button";
import ModalCenterTemplate from "../../../../../App/views/ModalCenterTemplate";
import "./styles.scss";
import ReportPropertyStatusRow from "../ReportPropertyStatusRow";
import { toastError } from "../../../../../../components/Toast";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { myProfileSelector } from "../../../../../App/selectors/AppSelectors";
import { submitReportPropertyStatusService } from "../../../../services/BookServices";
import TextArea from "../../../../../../components/Input/TextArea";

const ModalReportPropertyStatus = ({
  open,
  setOpen,
  type = "PROPERTY",
}: any) => {
  const myProfile = useSelector(myProfileSelector);
  const [value, setValue] = useState("");
  const [description, setDescription] = useState("");
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const _onClose = () => {
    setOpen(false);
  };

  const _onSubmit = async () => {
    setLoading(true);
    try {
      const payload = {
        full_name: myProfile?.full_name,
        email: myProfile?.email,
        id,
        type,
        comment: "",
        options: [value],
        description,
      };
      await submitReportPropertyStatusService(payload);
      setShowSuccess(true);
    } catch (e: any) {
      toastError(e?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={_onClose}
      modalClassName={classNames(
        "modal-center-v2 modal-report-property-status"
      )}
      open={open}
    >
      <Modal.Content>
        {showSuccess ? (
          <>
            <div className={"success-modal"}>
              <svg
                width="86"
                height="86"
                viewBox="0 0 86 86"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="86" height="86" rx="43" fill="#16C79A" />
                <path
                  d="M67.0459 25.8373C66.7128 25.5014 66.3165 25.2348 65.8798 25.0529C65.4431 24.871 64.9748 24.7773 64.5017 24.7773C64.0287 24.7773 63.5603 24.871 63.1237 25.0529C62.687 25.2348 62.2907 25.5014 61.9576 25.8373L35.2617 52.569L24.0459 41.3173C23.7 40.9832 23.2917 40.7205 22.8443 40.5442C22.3969 40.3678 21.9192 40.2814 21.4384 40.2897C20.9576 40.298 20.4831 40.401 20.0421 40.5926C19.601 40.7843 19.2021 41.061 18.868 41.4069C18.5339 41.7527 18.2712 42.161 18.0949 42.6084C17.9185 43.0558 17.8321 43.5336 17.8404 44.0144C17.8487 44.4952 17.9517 44.9697 18.1433 45.4107C18.335 45.8517 18.6117 46.2507 18.9576 46.5848L32.7176 60.3448C33.0507 60.6806 33.447 60.9472 33.8837 61.1291C34.3203 61.3111 34.7887 61.4047 35.2617 61.4047C35.7348 61.4047 36.2031 61.3111 36.6398 61.1291C37.0765 60.9472 37.4728 60.6806 37.8059 60.3448L67.0459 31.1048C67.4096 30.7692 67.6999 30.362 67.8984 29.9087C68.097 29.4554 68.1995 28.9659 68.1995 28.471C68.1995 27.9762 68.097 27.4867 67.8984 27.0334C67.6999 26.5801 67.4096 26.1728 67.0459 25.8373Z"
                  fill="white"
                />
              </svg>
              <div className={"d-flex flex-column align-items-center gap-12"}>
                <div className={"success-title"}>
                  Thank you for letting us know!
                </div>
                <p>
                  We appreciate your contribution. We will review your feedback
                  and update the listing accordingly.
                </p>
              </div>
              <ButtonCustom
                style={{ margin: 0, width: "100%", height: 44 }}
                className={"primary"}
                height={"44px"}
                onClick={() => _onClose()}
              >
                Close
              </ButtonCustom>
            </div>
          </>
        ) : (
          <>
            <div className={"d-flex"}>
              <div className={"title"} style={{ flex: 1 }}>
                Let us know <br />
                the issue with this property
              </div>
              <ButtonCustom
                height={"24px"}
                style={{ padding: 0, margin: 0 }}
                className={"none-bg close-modal"}
                onClick={_onClose}
              >
                <i style={{ fontSize: 24 }} className="uil uil-times" />
              </ButtonCustom>
            </div>

            <div className={"list-row-radio d-flex flex-column gap-12"}>
              <ReportPropertyStatusRow
                label={"Permanently closed"}
                value={"PERMANT_CLOSED"}
                onChange={() => setValue("PERMANT_CLOSED")}
                checked={value === "PERMANT_CLOSED"}
              />
              <ReportPropertyStatusRow
                label={"Operating hours changed "}
                value={"HOUR_CHANGED"}
                onChange={() => setValue("HOUR_CHANGED")}
                checked={value === "HOUR_CHANGED"}
              />
              <ReportPropertyStatusRow
                label={"Temporarily closed"}
                value={"TMP_CLOSED"}
                onChange={() => setValue("TMP_CLOSED")}
                checked={value === "TMP_CLOSED"}
              />
              <ReportPropertyStatusRow
                label={"Other"}
                value={"OTHER"}
                onChange={() => setValue("OTHER")}
                checked={value === "OTHER"}
              />
              {value === "OTHER" ? (
                <TextArea
                  value={description}
                  onChange={(e: any, data: any) => setDescription(data?.value)}
                />
              ) : null}
            </div>

            <ButtonCustom
              loading={loading}
              disabled={loading || !value}
              style={{ margin: 0, width: "100%", height: 44 }}
              className={value ? "yellow" : "default"}
              height={"44px"}
              onClick={_onSubmit}
            >
              Continue
            </ButtonCustom>
          </>
        )}
      </Modal.Content>
    </ModalCenterTemplate>
  );
};

export default ModalReportPropertyStatus;
