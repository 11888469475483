import React, { useContext } from "react";
import Label from "../../../../../../components/Label";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import { CampaignCreateEditContext } from "../../../../reducers/CampaignCreateEditContext";

const VoucherCurrency = () => {
  const { currencyOptions, values, setFieldValue, isSubmitting } = useContext(
    CampaignCreateEditContext
  );

  return (
    <div>
      <Label label={"Currency"} fontSize={"12px"} />
      <DropdownCustom
        selection
        selectUI
        options={currencyOptions}
        value={values.voucher_reward.currency}
        onChange={(e, { value }) =>
          setFieldValue("voucher_reward.currency", value)
        }
        disabled={isSubmitting}
      />
    </div>
  );
};

export default VoucherCurrency;
