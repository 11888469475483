import SizeBox from "../../../../../../../components/SizeBox";
import React, { useState, useEffect, useContext } from "react";
import EditActions from "./EditActions";
import { useDispatch, useSelector } from "react-redux";
import { propertyDetailSelector } from "../../../../../selectors/PropertySelector";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  LIST_AMENITY,
  LIST_FREE_AMENITY,
  LIST_PAID_AMENITY,
} from "../../../../../configs/Constants";
import Label from "../../../../../../../components/Label";
import { useParams } from "react-router-dom";
import { updatePropertyService } from "../../../../../services/PropertyServices";
import { getPropertyDetailThunk } from "../../../../../thunks/PropertyThunks";
import { toastError } from "../../../../../../../components/Toast";
import { LocalizationContext } from "../../../../../../../locales/Translation";

const PropertyAmenities = ({ setLoading, loading }: any) => {
  const { translations } = useContext(LocalizationContext);

  const [editMode, setEditMode] = useState(false);
  const propertyDetail = useSelector(propertyDetailSelector);
  const [items, setItems] = useState<any>([]);
  const [free, setFree] = useState<any>([]);
  const [paid, setPaid] = useState<any>([]);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (propertyDetail?.amenities && propertyDetail?.amenities?.length > 0) {
      setItems(
        propertyDetail?.amenities.filter((amenity: any) => !amenity.is_chosen)
      );
      setFree(
        propertyDetail?.amenities.filter(
          (amenity: any) => amenity.is_free && amenity.is_chosen
        )
      );
      setPaid(
        propertyDetail?.amenities.filter(
          (amenity: any) => !amenity.is_free && amenity.is_chosen
        )
      );
    }
  }, [propertyDetail]);

  const onDragEnd = (result: any) => {
    const { source, destination, reason } = result;
    if (!destination) {
      return;
    }

    if (reason === "DROP" && destination && source) {
      // if (source.droppableId !== destination.droppableId) {
      // Move feature
      // Remove Item from Source Column
      let sourceItem: any;
      switch (source.droppableId) {
        case LIST_AMENITY:
          sourceItem = items[source.index];
          if (sourceItem) {
            setItems(items.filter((item: any) => item.id !== sourceItem.id));
          }
          break;
        case LIST_FREE_AMENITY:
          sourceItem = free[source.index];
          if (sourceItem) {
            setFree(free.filter((item: any) => item.id !== sourceItem.id));
          }
          break;
        case LIST_PAID_AMENITY:
          sourceItem = paid[source.index];
          if (sourceItem) {
            setPaid(paid.filter((item: any) => item.id !== sourceItem.id));
          }
          break;
      }
      // Add Item into Destination column
      switch (destination.droppableId) {
        case LIST_AMENITY:
          if (sourceItem) {
            setItems((prevState: any) => {
              //DeepClone
              const newState = JSON.parse(JSON.stringify(prevState));
              newState.splice(destination.index, 0, {
                ...sourceItem,
                is_chosen: false,
                is_free: false,
              });

              newState.forEach((item: any, index: number) => {
                item.weight = index;
              });

              return newState;
            });
          }
          break;
        case LIST_FREE_AMENITY:
          if (sourceItem) {
            setFree((prevState: any) => {
              //DeepClone
              const newState = JSON.parse(JSON.stringify(prevState));
              newState.splice(destination.index, 0, {
                ...sourceItem,
                is_chosen: true,
                is_free: true,
              });

              newState.forEach((item: any, index: number) => {
                item.weight = index;
              });
              return newState;
            });
          }
          break;
        case LIST_PAID_AMENITY:
          if (sourceItem) {
            setPaid((prevState: any) => {
              //DeepClone
              const newState = JSON.parse(JSON.stringify(prevState));
              newState.splice(destination.index, 0, {
                ...sourceItem,
                is_chosen: true,
                is_free: false,
              });

              newState.forEach((item: any, index: number) => {
                item.weight = index;
              });
              return newState;
            });
          }
          break;
      }
      // }
    }
  };

  const handleSubmit = async () => {
    const payload = {
      amenities: items.concat(free, paid),
      property_id: id,
    };
    setLoading(true);
    try {
      await updatePropertyService(payload);
      await dispatch<any>(getPropertyDetailThunk(id ? id : ""));
      setEditMode(false);
    } catch (response: any) {
      toastError(response?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={"section"}>
      <SizeBox height={"16px"} />
      <div
        className={
          "d-flex justify-content-between " +
          (editMode ? "" : "align-items-center")
        }
      >
        <div className={"pr-70 w-100 amenity-section"}>
          {editMode ? null : (
            <>
              <div className={"section-title"}>
                {translations.propertyAmenities.amenities}
              </div>
              <SizeBox height={"16px"} />
            </>
          )}
          {editMode ? (
            <div className={"dnd-wrapper"}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={LIST_AMENITY}>
                  {(provided, snapshot) => (
                    <div className={"amenity-column"}>
                      <Label
                        label={translations.listOfAmenities}
                        fontSize={"12px"}
                        className={"fw-500"}
                        labelGuide={
                          translations.moveAmenitiesByDraggingAndDropping
                        }
                      />
                      <SizeBox height={"20px"} />
                      <div ref={provided.innerRef} className={"amenity-list"}>
                        {items.map((item: any, index: number) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                className={"amenity-item"}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className={"amenity-item__image"}>
                                  <img src={item.uri} alt="item.content" />
                                </div>
                                {item.name}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
                <Droppable droppableId={LIST_FREE_AMENITY}>
                  {(provided, snapshot) => (
                    <div className={"amenity-column"}>
                      <Label
                        label={translations.freeAmenities}
                        fontSize={"12px"}
                        className={"fw-500"}
                      />
                      <SizeBox height={"20px"} />
                      <div
                        ref={provided.innerRef}
                        className={"amenity-list free"}
                      >
                        {free.map((item: any, index: number) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                className={"amenity-item"}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className={"amenity-item__image"}>
                                  <img src={item.uri} alt="item.content" />
                                </div>
                                {item.name}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
                <Droppable droppableId={LIST_PAID_AMENITY}>
                  {(provided, snapshot) => (
                    <div className={"amenity-column"}>
                      <Label
                        label={translations.paidAmenities}
                        fontSize={"12px"}
                        className={"fw-500"}
                      />
                      <SizeBox height={"20px"} />
                      <div
                        ref={provided.innerRef}
                        className={"amenity-list paid"}
                      >
                        {paid.map((item: any, index: number) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                className={"amenity-item"}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className={"amenity-item__image"}>
                                  <img src={item.uri} alt="item.content" />
                                </div>
                                {item.name}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          ) : (
            <div className={"amenities"}>
              {propertyDetail?.amenities?.length > 0
                ? propertyDetail?.amenities.map(
                    (amenity: any, index: number) => {
                      if (amenity?.is_chosen) {
                        return (
                          <div key={amenity?.id} className={"amenity"}>
                            <div className={"amenity-square"}>
                              <img src={amenity?.uri} alt="icon" />
                              {!amenity?.is_free ? (
                                <span className={"amenity-paid"}>
                                  <i className="uil uil-dollar-sign" />
                                </span>
                              ) : null}
                            </div>
                            <div className={"amenity-title"}>
                              {amenity?.name}
                            </div>
                          </div>
                        );
                      }
                      return null;
                    }
                  )
                : null}
            </div>
          )}
        </div>

        <EditActions
          editMode={editMode}
          setEditMode={setEditMode}
          loading={loading}
          submit={handleSubmit}
        />
      </div>
    </div>
  );
};

export default PropertyAmenities;
