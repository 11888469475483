import React, { useContext, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  isModalManagePMSAddCalendarSelector,
  managePMSAddCalendarSelector,
} from "../../../../../../selectors/PropertySelector";
import { modalManagePMSAddCalendarAction } from "../../../../../../actions/PropertyActions";
import { Message, Modal } from "semantic-ui-react";
import { LocalizationContext } from "../../../../../../../../locales/Translation";
import ModalCenterTemplate from "../../../../../../../App/views/ModalCenterTemplate";
import { PUBLIC_URL } from "../../../../../../../App/configs/Constants";
import ButtonCustom from "../../../../../../../../components/Button";
import { Formik, useFormikContext } from "formik";
import InputCustom from "../../../../../../../../components/Input";
import "./style.scss";
import {
  SWAL_CONFIRM_OPTION,
  SWAL_DELETE_OPTION,
} from "../../../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../../../components/SweetAlert";
import { useParams } from "react-router-dom";
import {
  addManagePMSAddCalendarThunk,
  getManagePMSRoomThunk,
} from "../../../../../../thunks/PropertyThunks";
import SizeBox from "../../../../../../../../components/SizeBox";
import { managePMSAddCalendarService } from "../../../../../../services/ManagePMSServices";
import { Toast } from "react-toastify/dist/components";
import Label from "../../../../../../../../components/Label";

const ModalManagePMSAddCalendar = () => {
  const { id } = useParams();
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const isModalManagePMSAddCalendar = useSelector(
    isModalManagePMSAddCalendarSelector
  );
  const formContext = useFormikContext();
  const managePMSAddCalendar = useSelector(managePMSAddCalendarSelector);
  const [loading, setLoading] = useState(false);

  const _onSubmitCalendar = async (values: any) => {
    const payload = {
      channel_id: 4,
      ...values,
      property_id: id,
      auto_confirm_booking: 0,
      // auth_code: managePMSAddCalendar.auth_code,
    };
    setLoading(true);

    const roomThunkPayload = { unused: 0, property_id: id };
    try {
      await dispatch<any>(addManagePMSAddCalendarThunk(payload));
      const iconHtml = (
        <img
          src={PUBLIC_URL + "/assets/icons/icon-check-green.svg"}
          className={"swal2-icon__error"}
        />
      );
      const title = "Verified successfully";
      const confirmText = "Done";
      const confirmClass = "primary";
      const cancelClass = "d-none";
      const options = SWAL_CONFIRM_OPTION({
        title,
        iconHtml,
        confirmText,
        confirmClass,
        cancelClass,
      });

      SweetAlertCommon({
        options,
        confirmCallback: async () =>
          await dispatch<any>(getManagePMSRoomThunk(roomThunkPayload)).then(
            dispatch(modalManagePMSAddCalendarAction(false))
          ),
      });
    } catch (e: any) {
      const iconHtml = (
        <img
          src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
          className={"swal2-icon__error"}
        />
      );
      const title = "Verification Failed";
      const confirmText = (
        <div
          className={"d-flex"}
          style={{ justifyItems: "center", justifyContent: "center" }}
        >
          <i
            className={"uil uil-sync"}
            style={{ transform: "rotate(-30deg)" }}
          />
          <div style={{ marginLeft: 5 }}>Try Again</div>
        </div>
      );
      const confirmClass = "danger-outline";
      const options = SWAL_DELETE_OPTION({
        title,
        iconHtml,
        confirmText,
        confirmClass,
      });

      SweetAlertCommon({
        options,
        confirmCallback: () => {
          console.log("Close");
        },
        cancelCallback: () => dispatch(modalManagePMSAddCalendarAction(false)),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalCenterTemplate
      trigger={null}
      onClose={() => dispatch(modalManagePMSAddCalendarAction(false))}
      open={isModalManagePMSAddCalendar}
      size={"md"}
      modalClassName={"modal-center-v2"}
    >
      <Formik
        enableReinitialize
        initialValues={{
          name: "",
          link: "",
        }}
        onSubmit={_onSubmitCalendar}
      >
        {({ values, handleChange, handleBlur, handleSubmit }) => (
          <>
            <Modal.Header>
              Connect to Google Calendar
              <ButtonCustom
                height={"auto"}
                onClick={() => dispatch(modalManagePMSAddCalendarAction(false))}
                className={"m-0 p-0 none-bg close-modal"}
              >
                <>
                  {translations.settings.close}
                  <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
                </>
              </ButtonCustom>
            </Modal.Header>
            <Modal.Content>
              <Message color={"blue"} size={"small"}>
                <Label
                  label={
                    <>
                      <p>
                        <b>
                          <u>Step 1:</u>
                        </b>{" "}
                        Go to your google calendar setting and sharing
                      </p>
                      <p>
                        <b>
                          <u>Step 2:</u>
                        </b>{" "}
                        Add deskimo@production-deskimo.iam.gserviceaccount.com
                        with “make changes and manage sharing” permission in
                        “add people and groups”
                      </p>
                      <p>
                        <b>
                          <u>Step 3:</u>
                        </b>{" "}
                        Find the button “Get sharable link
                      </p>
                      <p>
                        <b>
                          <u>Step 4:</u>
                        </b>{" "}
                        Copy the link to Calendar URL field in the “Connect to
                        Google Calendar” form
                      </p>
                      <p>
                        <b>
                          <u>Step 5:</u>
                        </b>{" "}
                        Click confirm
                      </p>
                    </>
                  }
                />
              </Message>
              <InputCustom
                label={"Calendar Name"}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                name={"name"}
              />
              <InputCustom
                label={"Calendar URL"}
                value={values.link}
                onChange={handleChange}
                onBlur={handleBlur}
                name={"link"}
              />
              {/*<div className={"label"}>Support Video</div>*/}
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom
                className={"flex-1 m-0"}
                height={"47px"}
                onClick={() => dispatch(modalManagePMSAddCalendarAction(false))}
              >
                {translations.settings.cancel}
              </ButtonCustom>
              <SizeBox width={"16px"} />
              <ButtonCustom
                disabled={values?.name === "" || values?.link === ""}
                loading={loading}
                type={"submit"}
                height={"47px"}
                className={"primary flex-1 m-0"}
                onClick={handleSubmit}
              >
                {translations.confirm}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalCenterTemplate>
  );
};

export default ModalManagePMSAddCalendar;
