import React, { useEffect, useState } from "react";
import { Marker, MarkerProps } from "@react-google-maps/api";
import { PUBLIC_URL } from "../../../App/configs/Constants";

interface Props extends MarkerProps {
  isClosed?: boolean;
  highlight?: boolean;
  onClick: () => void;
}
const MarkerRound = ({ isClosed, position, highlight, onClick }: Props) => {
  const [iconUrl, setIconUrl] = useState("/assets/icons/pin/icon-pin.svg");
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (hovered || highlight) {
      setIconUrl("/assets/icons/pin/icon-pin-round-highlight.svg");
    } else {
      if (isClosed) {
        setIconUrl("/assets/icons/pin/icon-pin-round-closed.svg");
      } else {
        setIconUrl("/assets/icons/pin/icon-pin-round.svg");
      }
    }
  }, [hovered, highlight]);

  return (
    <Marker
      onClick={onClick}
      position={position}
      icon={{
        url: PUBLIC_URL + iconUrl,
        size:
          hovered || highlight
            ? new google.maps.Size(56, 56)
            : new google.maps.Size(42, 42),
        scaledSize:
          hovered || highlight
            ? new google.maps.Size(56, 56)
            : new google.maps.Size(42, 42),
        anchor:
          hovered || highlight
            ? new google.maps.Point(28, 28)
            : new google.maps.Point(21, 21),
      }}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    />
  );
};

export default MarkerRound;
