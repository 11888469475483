export const PUBLIC_URL = process.env.PUBLIC_URL;
export const ITEMS_PER_PAGE = 25;

export const MODAL_PACKAGE_TOP_UP_ACTION = "MODAL_PACKAGE_TOP_UP_ACTION";
export const MODAL_ADD_EMPLOYEE_ACTION = "MODAL_ADD_EMPLOYEE_ACTION";
export const MODAL_IMPORT_EMPLOYEE_ACTION = "MODAL_IMPORT_EMPLOYEE_ACTION";
export const MODAL_MY_PROFILE_ACTION = "MODAL_MY_PROFILE_ACTION";
export const MODAL_UPDATE_PASSWORD_ACTION = "MODAL_UPDATE_PASSWORD_ACTION";
export const MODAL_ADD_VOUCHER_ACTION = "MODAL_ADD_VOUCHER_ACTION";
export const MODAL_ADD_VOUCHER_FOR_BOOKING_ACTION =
  "MODAL_ADD_VOUCHER_FOR_BOOKING_ACTION";
export const MODAL_DOWNLOAD_APP_ACTION = "MODAL_DOWNLOAD_APP_ACTION";
export const MODAL_NAVBAR_MOBILE_ACTION = "MODAL_NAVBAR_MOBILE_ACTION";
export const IS_GLOBAL_INDICATOR_ACTION = "IS_GLOBAL_INDICATOR_ACTION";
export const HEADER_POPUP_TOPPING_UP_ACTION = "HEADER_POPUP_TOPPING_UP_ACTION";
export const GET_LIST_VOUCHER_FOR_BOOKING_ACTION =
  "GET_LIST_VOUCHER_FOR_BOOKING_ACTION";

//Navbar
export const CREDIT_REMAINING_ACTION = "CREDIT_REMAINING_ACTION";
export const CREDIT_PACKAGES_ACTION = "CREDIT_PACKAGES_ACTION";
export const GET_MY_PROFILE_ACTION = "GET_MY_PROFILE_ACTION";
export const GET_GLOBAL_SETTING_ACTION = "GET_GLOBAL_SETTING_ACTION";
export const UPDATE_MY_PROFILE_ACTION = "UPDATE_MY_PROFILE_ACTION";

//Top up
export const AUTO_RECHARGE_EVERY_MONTH = 1;
export const AUTO_RECHARGE_UNDER_AMOUNT = 2;
export const NONE_AUTO_RECHARGE = 3;

//SORT
export const ORDER_BY_ASC = "ASC";
export const ORDER_BY_DESC = "DESC";
export const ORDER_BY_NONE = "";

// User guide
export const MODAL_START_USER_GUIDE_ACTION = "MODAL_START_USER_GUIDE_ACTION";
export const USER_GUIDE_ACTION = "USER_GUIDE_ACTION";
export const USER_GUIDE_SEND_BULK_INVITE_ACTION =
  "USER_GUIDE_SEND_BULK_INVITE_ACTION";

export const ONBOARD_PROMOTION_INFO_ACTION = "ONBOARD_PROMOTION_INFO_ACTION";

export const PROPERTY_USER_GUIDE =
  "https://docs.google.com/document/d/1dQV5_3er-q15Za75HcHVsBWKeiGlSLUofKkhnrZkE7c/edit#heading=h.rdk1t5jx9k5q";

// TIMEZONE
export const TIMEZONE_SINGAPORE = "Asia/Singapore";

export const LANGUAGES = [
  { key: 1, value: "en", text: "English" },
  { key: 2, value: "id", text: "Bahasa Indonesian" },
];

export const INTERVAL_TIME_CHECK_LOGIN = 10000;

export const SHOW_TOPUP_NONE = 0;
export const SHOW_TOPUP_POPUP = 1;
export const SHOW_TOPUP_TRIAL_END_BY_EXPIRED_DATE = 2;
export const SHOW_TOPUP_TRIAL_END_BY_OUT_OF_CREDIT = 3;

export const LOGIN_AS_SOURCE = "LOGIN_AS_SOURCE";
export const LOGIN_AS_COMPANY_ID = "LOGIN_AS_COMPANY_ID";

export const PARTNER_PREFIX_URL = "/partner";
