import { ROLE_DESKIMO_ADMIN_ID } from "../../../../../User/configs/Constants";
import { BUSINESS_COMPANY_INVOICES_LINK } from "../../../../../App/configs/ListRoutes";
import MasterTemplate from "../../../../../App/views/MasterTemplate";
import React, { useContext, useEffect, useState } from "react";
import ButtonCustom from "../../../../../../components/Button";
import SizeBox from "../../../../../../components/SizeBox";
import { Grid, Table } from "semantic-ui-react";
import InputCustom from "../../../../../../components/Input";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import LoaderCustom from "../../../../../../components/Loader";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useNavigate, useParams } from "react-router-dom";
import { SWAL_DELETE_OPTION } from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { getURlDownloadFileService } from "../../../../../Setting/services/InvoiceServices";
import ButtonGoBack from "../../../../../../components/ButtonGoBack";
import {
  addBusinessInvoiceCustomFeeService,
  deleteBusinessInvoiceCustomFeeService,
  getBusinessInvoiceDetailService,
} from "../../../../services/BusinessServices";

const CompanyInvoiceDetail = () => {
  const { translations } = useContext(LocalizationContext);
  const { token, id } = useParams();
  const [invoice, setInvoice] = useState({
    invoice: {
      invoice_date: "",
      invoice_due_date: "",
      invoice_no: "",
      company_name: "",
      company_email: "",
      address: "",
      period: "",
      market_entity_bank_name: "",
      market_entity_bank_account: "",
      market_entity_bank_holder: "",
      reference: "",
      market_entity_name: "",
      market_entity_address: "",
      market_entity_phone: "",
      market_entity_email: "",
      total: "",
      status: 0,
      invoice_csv_url: "",
      invoice_xlsx_url: "",
      currency: "",
      payment_date: "",
    },
    invoice_items: [],
  });
  const [loadingXLSX, setLoadingXLSX] = useState(false);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customFee, setCustomFee] = useState({
    name: "",
    price: "",
  });
  const [loadingCustomFee, setLoadingCustomFee] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const navigation = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await getBusinessInvoiceDetailService(id, token);
        setInvoice(response?.data);
      } catch (error: any) {
        toastError(error?.status?.message);
        navigation(BUSINESS_COMPANY_INVOICES_LINK);
      } finally {
        setLoading(false);
      }
    })();
  }, [refreshList]);

  const _onDeleteCustomFee = async (id: any) => {
    const iconHtml = (
      <img
        src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
        className={"swal2-icon__error"}
      />
    );
    const title = translations.companyInvoices.askDeleteInvoiceTitle;
    const confirmText = (
      <div
        className={"d-flex"}
        style={{ justifyItems: "center", justifyContent: "center" }}
      >
        <div style={{ marginLeft: 5 }}>{translations.delete}</div>
      </div>
    );
    const confirmClass = "danger";
    const options = SWAL_DELETE_OPTION({
      title,
      iconHtml,
      confirmText,
      confirmClass,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          const payload = {
            invoice_item_id: id,
          };
          const response = await deleteBusinessInvoiceCustomFeeService(
            payload,
            token,
            id
          );
          if (response?.status?.code !== 200) {
            toastError(response?.status?.message);
            return;
          }

          toastSuccess(
            translations.companyInvoices.deleteCustomFeeSuccessPrompt
          );
          setRefreshList(!refreshList);
        } catch (error: any) {
          toastError(error?.status?.message);
        }
      },
      cancelCallback: () => null,
    });
  };

  let tableRowHTML = null;
  if (invoice.invoice_items.length > 0) {
    tableRowHTML = invoice.invoice_items.map((item: any, index) => {
      return (
        <Table.Row key={index}>
          <Table.Cell>{item?.name}</Table.Cell>
          <Table.Cell>{item?.quantity}</Table.Cell>
          <Table.Cell>{item?.unit_price}</Table.Cell>
          <Table.Cell>
            <div className={"d-flex align-items-center"}>
              {item?.total_price}
              {item?.delete === true && invoice.invoice.status === 0 ? (
                <ButtonCustom
                  onClick={async () => _onDeleteCustomFee(item?.id)}
                  className={"none-bg p-0 ml-auto"}
                  height={"14px"}
                  style={{ color: "#BC0000", fontSize: 20 }}
                >
                  <i className="uil uil-trash" />
                </ButtonCustom>
              ) : null}
            </div>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  const handleDownloadFile = async (url: string) => {
    if (!url) {
      return;
    }
    try {
      const response = await getURlDownloadFileService(url);
      window.open(response?.data?.url, "_blank");
    } catch (error) {
      console.log(Error);
    } finally {
      setLoadingCSV(false);
      setLoadingXLSX(false);
    }
  };

  const addCustomFee = async () => {
    if (customFee.name === "" || customFee.price === "") {
      return;
    }
    setLoadingCustomFee(true);
    try {
      const payload = {
        name: customFee.name,
        price: customFee.price,
      };
      await addBusinessInvoiceCustomFeeService(payload, token, id);
      toastSuccess(translations.companyInvoices.customFeeAddedSuccessPrompt);
      setCustomFee({
        name: "",
        price: "",
      });
      setRefreshList(!refreshList);
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setLoadingCustomFee(false);
    }
  };

  return (
    <MasterTemplate roleAccept={[ROLE_DESKIMO_ADMIN_ID]}>
      <ButtonGoBack route={BUSINESS_COMPANY_INVOICES_LINK} />
      <div className={"d-flex w-100 justify-content-end"}>
        <ButtonCustom
          loading={loadingXLSX}
          onClick={() => {
            setLoadingXLSX(true);
            handleDownloadFile(invoice.invoice.invoice_xlsx_url);
          }}
          className={"outline-primary none-bg m-0"}
          height={"36px"}
        >
          {translations.settings.downloadXLSXFile}
        </ButtonCustom>
        <SizeBox width={"12px"} />
        <ButtonCustom
          loading={loadingCSV}
          onClick={() => {
            setLoadingCSV(true);
            handleDownloadFile(invoice.invoice.invoice_csv_url);
          }}
          className={"outline-primary none-bg m-0"}
          height={"36px"}
        >
          {translations.settings.downloadAccountStatement}
        </ButtonCustom>
      </div>
      <SizeBox height={"32px"} />
      <div className={"invoice-wrapper"}>
        <div className={"invoice-title"}>
          {translations.settings.corporateInvoice}
        </div>
        <SizeBox height={"42px"} />
        <Grid>
          <Grid.Column computer={8} tablet={16} mobile={16}>
            <div>{invoice.invoice.company_name}</div>
            <div>{invoice.invoice.company_email}</div>
            <div>{invoice.invoice.address}</div>
            <SizeBox height={"30px"} />
            <div>
              {translations.settings.timePeriod}: {invoice.invoice.period}
            </div>

            <div>
              {translations.companyInvoices.labelGSTVATCharged}:{" "}
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/*// @ts-ignore*/}
              {invoice.invoice.have_gst ? translations.yes : translations.no}
            </div>

            <SizeBox height={"72px"} />

            <div className={"invoice-sub-title"}>
              {translations.settings.accountStatementDate}:
            </div>
            <div>{invoice.invoice.invoice_date}</div>

            <SizeBox height={"35px"} />

            <div className={"invoice-sub-title"}>
              {translations.settings.accountStatementNumber}:
            </div>
            <div>{invoice?.invoice.invoice_no}</div>

            <SizeBox height={"35px"} />

            <div className={"invoice-sub-title"}>
              {translations.settings.reference}:
            </div>
            <div>
              {invoice.invoice.company_name} - {invoice.invoice.reference}{" "}
            </div>
          </Grid.Column>
          <Grid.Column computer={8} tablet={16} mobile={16}>
            <div className={"invoice-sub-title"}>
              {translations.settings.dueDate}:{" "}
              {invoice?.invoice?.invoice_due_date}
            </div>
            <div>{translations.settings.balanceToBePaidTo}:</div>
            <div>
              {translations.settings.bank}:{" "}
              {invoice.invoice.market_entity_bank_name}
            </div>
            <div>
              {translations.settings.accountNo}:{" "}
              {invoice.invoice.market_entity_bank_account}
            </div>
            <div>
              {translations.settings.accountHolder}:{" "}
              {invoice.invoice.market_entity_bank_holder}
            </div>
            <SizeBox height={"72px"} />
            <div className={"invoice-sub-title"}>Bill to:</div>
            {/*<div>*/}
            {/*  {invoice.invoice.company_name} - {invoice.invoice.reference}{" "}*/}
            {/*</div>*/}
            {/*<div>{invoice.invoice.market_entity_name}</div>*/}
            <div>{invoice.invoice.market_entity_address}</div>
            <div>{invoice.invoice.market_entity_phone}</div>
            <div>{invoice.invoice.market_entity_email}</div>

            {invoice?.invoice?.payment_date ? (
              <>
                <SizeBox height={"35px"} />
                <div className={"invoice-sub-title"}>Payment date:</div>
                <div>{invoice.invoice.payment_date}</div>
              </>
            ) : null}
          </Grid.Column>
        </Grid>
        <SizeBox height={"130px"} />
        <Table className={"invoice-table"}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                {translations.settings.description}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {translations.settings.quantity}
              </Table.HeaderCell>
              <Table.HeaderCell className={"text-nowrap"}>
                {translations.settings.unitPrice}
              </Table.HeaderCell>
              <Table.HeaderCell className={"text-nowrap"}>
                {translations.settings.total} ({invoice.invoice.currency})
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tableRowHTML}
            {invoice.invoice.status === 0 ? (
              <Table.Row>
                <Table.Cell>
                  <InputCustom
                    value={customFee.name}
                    onChange={(event: any, data: any) =>
                      setCustomFee({ ...customFee, name: data.value })
                    }
                    containerClass={"input-36px"}
                    placeholder={
                      translations.companyInvoices.customFeePlaceholder
                    }
                  />
                </Table.Cell>
                <Table.Cell colSpan="2">
                  <InputCustom
                    value={customFee.price}
                    onChange={(event: any, data: any) =>
                      setCustomFee({ ...customFee, price: data.value })
                    }
                    containerClass={"input-36px"}
                    inputLeftIconBorder
                    inputLeftIcon={"$"}
                  />
                </Table.Cell>
                <Table.Cell>
                  <ButtonCustom
                    disabled={customFee.name === "" || customFee.price === ""}
                    onClick={addCustomFee}
                    loading={loadingCustomFee}
                    className={"primary w-100 m-0"}
                    height={"36px"}
                  >
                    {translations.save}
                  </ButtonCustom>
                </Table.Cell>
              </Table.Row>
            ) : null}
            <Table.Row>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>
                <b>
                  {translations.settings.total} ({invoice.invoice.currency})
                </b>
              </Table.Cell>
              <Table.Cell>{invoice.invoice.total}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className={"invoice-stamp"}>
                {invoice.invoice.status === 2 ? (
                  <img src={PUBLIC_URL + "/assets/icons/icon-paid-stamp.svg"} />
                ) : null}
              </Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>
                {translations.companyInvoices.lessAmountPaidCellTitle}
              </Table.Cell>
              <Table.Cell>0.00</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell className={"fw-700 text-nowrap"}>
                {/*{translations.settings.grandTotal}*/}
                <b>
                  {translations.companyInvoices.amountDueCellTitle} (
                  {invoice.invoice.currency})
                </b>
              </Table.Cell>
              <Table.Cell>{invoice.invoice.total}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        {loading ? <LoaderCustom /> : <></>}
      </div>
    </MasterTemplate>
  );
};
export default CompanyInvoiceDetail;
