import SizeBox from "../../../../../../../components/SizeBox";
import React, { useState, useEffect, useContext } from "react";
import EditActions from "./EditActions";
import PropertyMetaEdit from "./PropertyMetaEdit";
import { useSelector } from "react-redux";
import { propertyDetailSelector } from "../../../../../selectors/PropertySelector";
import { useLocation, useParams } from "react-router-dom";
import { LocalizationContext } from "../../../../../../../locales/Translation";
import { myProfileSelector } from "../../../../../../App/selectors/AppSelectors";
import { ROLE_DESKIMO_ADMIN_ID } from "../../../../../../User/configs/Constants";
import { PropertyCategoryType } from "../../../../../configs/DeclareType";
import { Popup } from "semantic-ui-react";
import PropertyOutstanding from "./PropertyOutstanding";
import PropertyUserReview from "./PropertyUserReview";

const PropertyMeta = ({ loading, setLoading }: any) => {
  const { translations } = useContext(LocalizationContext);
  const { id } = useParams();

  const propertyDetail = useSelector(propertyDetailSelector);
  const [editMode, setEditMode] = useState(false);
  const location = useLocation();
  const myProfile = useSelector(myProfileSelector);

  useEffect(() => {
    if (location?.pathname?.includes("edit")) {
      setEditMode(true);
    }
  }, []);

  const _renderHotDeskMode = () => {
    if (
      propertyDetail?.hot_desk_mode?.allow_walk_in &&
      propertyDetail?.hot_desk_mode?.allow_booking
    ) {
      return translations.allowDeskWalkInsDeskAdvancedBookings;
    } else if (propertyDetail?.hot_desk_mode?.allow_walk_in) {
      return translations.allowDeskWalkIns;
    } else if (propertyDetail?.hot_desk_mode?.allow_booking) {
      return translations.allowDeskAdvancedBookings;
    } else {
      return translations.NoHotDesks;
    }
  };

  return (
    <div className={"section"}>
      <div
        className={
          "d-flex justify-content-between " +
          (editMode ? " edit-mode-wrapper " : "align-items-center")
        }
      >
        <div className={"w-100"}>
          {editMode ? (
            <PropertyMetaEdit
              setEditMode={setEditMode}
              loading={loading}
              setLoading={setLoading}
            />
          ) : (
            <>
              <div className={"property-name"}>
                {propertyDetail?.name || ""}
              </div>
              <div className={"company-name"}>
                {propertyDetail?.company_name || ""}
              </div>

              {propertyDetail?.property_categories?.length > 0 ? (
                <div className={"property-categories"}>
                  {propertyDetail?.property_categories.map(
                    (category: PropertyCategoryType, index: number) => {
                      return (
                        <div key={index} className={"property-category-item"}>
                          {category.name}
                        </div>
                      );
                    }
                  )}
                </div>
              ) : null}

              <div className={"d-flex property-rate__wrapper"}>
                <div
                  className={"property-rate fw-500"}
                  style={{ minWidth: 275 }}
                >
                  {translations.hourlyRate}:{" "}
                  {(propertyDetail?.hourly_rate || "") +
                    " " +
                    (propertyDetail?.currency || "")}
                </div>
                <div className={"property-rate fw-500"}>
                  {translations.dailyRateCap}:{" "}
                  {(propertyDetail?.daily_rate || "") +
                    " " +
                    (propertyDetail?.currency || "")}
                </div>
              </div>

              <SizeBox height={"31px"} />
              <div className="property-hot-desk-with-edit-image-section">
                <div className={"property-rate"}>
                  <span className={"fw-500"}>{translations.hotDeskMode}: </span>
                  {_renderHotDeskMode()}
                </div>
                <div className={"edit-image-button"}>
                  <EditActions
                    editMode={editMode}
                    setEditMode={setEditMode}
                    showSave={false}
                  />
                </div>
              </div>
              <SizeBox height={"3px"} />

              {myProfile.role_id === ROLE_DESKIMO_ADMIN_ID ? (
                <>
                  <div className={"property-rate"}>
                    <span className={"fw-500"}>
                      {translations.limitNumberAvailableDesks}:{" "}
                    </span>
                    {propertyDetail?.hot_desk_mode
                      ?.allow_desk_limitation_condition
                      ? propertyDetail?.hot_desk_mode?.desk_limitation_amount
                      : "Disabled"}
                  </div>
                  <SizeBox height={"24px"} />
                </>
              ) : null}

              {propertyDetail?.tag_ids?.length &&
              myProfile.role_id === ROLE_DESKIMO_ADMIN_ID ? (
                <>
                  <div className={"property-rate"}>
                    <span className={"fw-500"}>
                      Limit access to business with the following tags:{" "}
                    </span>
                    {propertyDetail?.tag_ids
                      .map(({ name }: { name: string }) => name)
                      .join(", ")}
                  </div>

                  <SizeBox height={"24px"} />
                </>
              ) : null}

              {propertyDetail?.hot_desk_mode?.auto_approved ? (
                <>
                  <div className={"property-rate"}>
                    <span className={"fw-500"}>
                      {translations.dayPassBookings}:{" "}
                    </span>
                    {translations?.autoApproved}
                    <Popup
                      className={"input-label-text-guide"}
                      content={
                        "Please contact support@deskimo.com to change this setting"
                      }
                      trigger={
                        <i
                          style={{ marginLeft: "8px" }}
                          className="uil uil-info-circle input-label-icon"
                        />
                      }
                      position="top center"
                    />
                  </div>
                  <SizeBox height={32} />
                </>
              ) : null}

              <div className={"property-address"}>
                <i className="uil uil-location-pin-alt" />
                {propertyDetail?.address_street_name}
              </div>

              <SizeBox height={"24px"} />

              <div className={"property-images pr-70 scroll-horizontal"}>
                {propertyDetail?.images?.length > 0
                  ? propertyDetail?.images.map((image: any) => (
                      <img
                        key={image.id}
                        src={image.uri}
                        alt={"property-image"}
                      />
                    ))
                  : null}
              </div>
            </>
          )}
        </div>
      </div>
      <SizeBox height={"36px"} />
    </div>
  );
};

export default PropertyMeta;
