import React from "react";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import "./style.scss";
import { PUBLIC_URL } from "../../modules/App/configs/Constants";

const Map = ({ lat, lng, zoom = 16, draggable, onDragEnd }: any) => {
  if (!lat || !lng) {
    return null;
  }

  return (
    <div>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*// @ts-ignore*/}
      <GoogleMap zoom={zoom} center={{ lat, lng }}>
        <Marker
          icon={{
            url: PUBLIC_URL + "/assets/icons/icon-marker-blue.svg",
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            scaledSize: new window.google.maps.Size(80, 80),
          }}
          position={{ lat, lng }}
          draggable={draggable}
          onDragEnd={onDragEnd}
        />
      </GoogleMap>
    </div>
  );
};

export default withGoogleMap(Map);
