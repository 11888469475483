import React, { useContext } from "react";
import SectionWhiteBoxItem from "../../../../../components/SectionWhiteBoxItem/SectionWhiteBoxItem";
import {
  CampaignDetailType,
  CampaignVoucherRuleType,
} from "../../../configs/DeclareTypes";
import { useSelector } from "react-redux";
import { campaignDetailSelector } from "../../../selectors/MarketingSelectors";
import RuleCheck from "./RuleCheck";
import {
  MATCH_WITH_SPECIFIC_CITIES_RULE,
  MATCH_WITH_SPECIFIC_COMPANIES_RULE,
  MATCH_WITH_SPECIFIC_PROPERTIES_RULE,
  MATCH_WITH_SPECIFIC_USERS_RULE,
} from "../../../configs/Constants";
import { LocalizationContext } from "../../../../../locales/Translation";
import SizeBox from "../../../../../components/SizeBox";

const Rules = () => {
  const { translations } = useContext(LocalizationContext);

  const campaignDetail: CampaignDetailType = useSelector(
    campaignDetailSelector
  );

  const _findValue = (rule: CampaignVoucherRuleType) => {
    const field = campaignDetail.advance_rules.find(
      ({ type }) => type === rule
    );

    if (field && Array.isArray(field?.value)) {
      return field.value.map((item, index) => {
        const text = typeof item !== "number" ? item?.text : "";
        return `${index === 0 ? "" : ", "} ${text}`;
      });
    }

    return null;
  };

  return (
    <>
      <div className={"section-white-box_title"}>
        {translations.advancedRules}
      </div>
      <SizeBox height={"40px"} />
      <RuleCheck />
      <div className={"section-white-box-column"}>
        <SectionWhiteBoxItem
          label={"Markets"}
          value={_findValue(MATCH_WITH_SPECIFIC_CITIES_RULE)}
        />
        <SectionWhiteBoxItem
          label={"Users"}
          value={_findValue(MATCH_WITH_SPECIFIC_USERS_RULE)}
        />
      </div>

      <div className={"section-white-box-column"}>
        <SectionWhiteBoxItem
          label={"Properties"}
          value={_findValue(MATCH_WITH_SPECIFIC_PROPERTIES_RULE)}
        />
        <SectionWhiteBoxItem
          label={"Companies"}
          value={_findValue(MATCH_WITH_SPECIFIC_COMPANIES_RULE)}
        />
      </div>
    </>
  );
};

export default Rules;
