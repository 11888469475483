import React from "react";
import Search from "./Search";
import DropdownType from "./DropdownType";
import DropdownCompany from "./DropdownCompany";
import ButtonCustom from "../../../../../../components/Button";
import SizeBox from "../../../../../../components/SizeBox";

type Props = {
  onOpenModal: () => void;
};

const Filters = ({ onOpenModal }: Props) => {
  return (
    <div className={"office-lease__filter"}>
      <div className={"office-lease__filter-left"}>
        <Search />
        {/*<DropdownType />*/}
        <DropdownCompany />
      </div>
      <ButtonCustom className={"primary"} height={"36px"} onClick={onOpenModal}>
        <>
          Add New Lease <SizeBox width={"8px"} />
          <i className="uil uil-plus" />
        </>
      </ButtonCustom>
    </div>
  );
};

export default Filters;
