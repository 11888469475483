import SizeBox from "../../../../../../../../components/SizeBox";
import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../../../../locales/Translation";

const MonthlyUsage = ({ monthlyUsage }: any) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <div className={"billing-tab__right-summary"}>
      <div className={"item"}>
        <div className={"title"}>{translations.settings.thisMonthUsage}</div>
        <SizeBox height={"34px"} />
        <div className={"amount"}>{monthlyUsage?.monthly_usage}</div>
        {/*<SizeBox height={"22px"} />*/}
        {/*<div className={"meta"}>*/}
        {/*  {translations.settings.toBeChargedBy} {monthlyUsage?.charged_by}*/}
        {/*</div>*/}
        {/*<SizeBox height={"16px"} />*/}
      </div>
    </div>
  );
};

export default MonthlyUsage;
