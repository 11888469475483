import { useSearchParams, useLocation } from "react-router-dom";
import { MutableRefObject, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { permissionListSelector } from "../modules/Authentication/selectors/AuthenticationSelectors";
import {
  isLiteralObject,
  objectParseValid,
} from "../services/FormatTextService";

export function useQuery() {
  const [searchParams] = useSearchParams();

  return searchParams;
}

export function useGetAllQuery() {
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const paramObj: any = {};

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  for (const value of params.keys()) {
    paramObj[value] = objectParseValid(params.get(value));
  }

  return paramObj;
}

export function useSetQuery() {
  const [searchParams, setSearchParams] = useSearchParams();

  return (data: object) => {
    for (const [key, value] of Object.entries(data)) {
      const valueTemp =
        Array.isArray(value) || isLiteralObject(value)
          ? JSON.stringify(value)
          : value;
      searchParams.set(key, valueTemp);
      setSearchParams(searchParams);
    }
  };
}

export function usePermissionCheck(permission: string | undefined) {
  if (!permission) {
    return true;
  }

  const permissionList = useSelector(permissionListSelector);

  return permissionList.includes(permission);
}

export function useOnScreen(ref: MutableRefObject<never>) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}

export function useScreenWidth() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setScreenWidth(window.innerWidth);
      });
    };
  }, []);

  return screenWidth;
}

export default function useFormikScrollToError(isSubmitting: boolean) {
  useEffect(() => {
    if (!isSubmitting) {
      return;
    }

    const el = document.querySelector(".error");
    (el?.parentElement ?? el)?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [isSubmitting]);
}
