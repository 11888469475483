import SizeBox from "../../../SizeBox";
import "./style.scss";

type Props = {
  children?: JSX.Element | JSX.Element[];
  title?: string;
  text?: string;
};

const SwalSuccess = ({ children, title, text }: Props) => {
  return (
    <div className={"swal-success"}>
      <div className={"swal-icon"}>
        <i className="uil uil-check-circle" />
      </div>
      <SizeBox height={"8px"} />
      {title ? (
        <>
          <div className={"swal-error__title"}>{title}</div>
          <SizeBox height={"8px"} />
        </>
      ) : null}

      {text ? <div className={"swal-error__text"}>{text}</div> : null}

      {children}
    </div>
  );
};

export default SwalSuccess;
