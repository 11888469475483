import React, { useContext, useEffect, useState } from "react";
import { Formik, FormikValues } from "formik";
import InputCustom from "../../../../../../components/Input";
import SectionWhiteBox from "../../../../../../components/SectionWhiteBox";
import Label from "../../../../../../components/Label";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import "./style.scss";
import ButtonCustom from "../../../../../../components/Button";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { DESKIMO_PROPERTY_SCANNERS_LINK } from "../../../../../App/configs/ListRoutes";
import { useDispatch, useSelector } from "react-redux";
import { deskimoPropertyScannerDetailSelector } from "../../../../selectors/PropertySelector";
import { useNavigate, useParams } from "react-router-dom";
import { getDeskimoPropertyScannerDetailThunk } from "../../../../thunks/PropertyThunks";
import moment from "moment";
import DatePickerSingle from "../../../../../../components/DatePickerSingle";
import { getAllPropertyList } from "../../../../services/PropertyCompanyServices";
import {
  addDeskimoPropertyScannerService,
  editDeskimoPropertyScannerService,
} from "../../../../services/PropertyScannerServices";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { deskimoPropertyScannerDetailAction } from "../../../../actions/PropertyActions";
import * as yup from "yup";
import Loader from "../../../../../../components/Loader";
import { Dimmer } from "semantic-ui-react";
import SizeBox from "../../../../../../components/SizeBox";

const DYNAMIC_IP_ADDRESS_SETTING = 0;
const STATIC_IP_ADDRESS_SETTING = 1;

const PropertiesScannerCreateEdit = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translations } = useContext(LocalizationContext);
  const [loading, setLoading] = useState<boolean>(true);
  const scannerDetails = useSelector(deskimoPropertyScannerDetailSelector);
  const [propertyData, setPropertyData] = useState([]);

  const DYNAMIC_IP_ADDRESS_PROMPT = translations.propertyScanners.dynamicMode;
  const STATIC_IP_ADDRESS_PROMPT = translations.propertyScanners.staticMode;

  const _handleSubmit = async (values: FormikValues) => {
    if (!token) {
      try {
        const payload = { ...values };
        await addDeskimoPropertyScannerService(payload);
        toastSuccess(translations.createSuccessPrompt);
        navigate(DESKIMO_PROPERTY_SCANNERS_LINK);
      } catch (e: any) {
        toastError(`${e.status.message}`);
        console.error(e);
      }
    } else if (token) {
      try {
        const payload = { ...values };
        await editDeskimoPropertyScannerService(payload, token);
        toastSuccess(translations.editSuccessPrompt);
      } catch (e: any) {
        toastError(`${e.status.message}`);
        console.error(e);
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (token) {
          setLoading(true);
          await dispatch<any>(getDeskimoPropertyScannerDetailThunk(token));
        } else if (!token) {
          dispatch(deskimoPropertyScannerDetailAction(undefined));
          try {
            const response = await getAllPropertyList();
            const properties = response?.data;
            setPropertyData(properties);
          } catch (error) {
            console.error("Error: ", error);
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const decorateIPAddressSetting = [
    {
      key: 0,
      text: DYNAMIC_IP_ADDRESS_PROMPT,
      value: DYNAMIC_IP_ADDRESS_SETTING,
    },
    {
      key: 1,
      text: STATIC_IP_ADDRESS_PROMPT,
      value: STATIC_IP_ADDRESS_SETTING,
    },
  ];

  const decorateListCompany = () => {
    if (scannerDetails?.property_list) {
      const companyList = scannerDetails.property_list.map((property: any) => {
        return {
          key: property?.key,
          text: property?.text,
          value: property?.value,
        };
      });
      companyList.unshift({
        key: 0,
        text: translations.companyInvoices.allCompaniesPlaceholder,
        value: 0,
      });
      return companyList;
    } else if (propertyData) {
      const propertyCompanyList = propertyData.map((property: any) => {
        return {
          key: property?.key,
          text: `${property?.company_name} - ${property?.property_name} (${property?.status_name})`,
          value: property?.property_id,
        };
      });
      propertyCompanyList.unshift({
        key: 0,
        text: translations.companyInvoices.allCompaniesPlaceholder,
        value: 0,
      });
      return propertyCompanyList;
    } else {
      return [];
    }
  };

  return (
    <>
      {loading ? (
        <Dimmer active>
          <Loader />
        </Dimmer>
      ) : (
        <SectionWhiteBox>
          <Formik
            enableReinitialize
            initialValues={{
              name: scannerDetails?.scanner_name || "",
              property_id: scannerDetails?.current_property_value || 0,
              device_id: scannerDetails?.device_id || "",
              date_of_batch:
                scannerDetails?.date_of_batch || moment().utc().unix(),
              ip_setting:
                scannerDetails?.current_ip_setting ||
                DYNAMIC_IP_ADDRESS_SETTING,
              wifi_name: scannerDetails?.wifi_name || "",
              wifi_password: scannerDetails?.wifi_password || "",
              ip_address: scannerDetails?.ip_address || null,
              subnet_mask: scannerDetails?.subnet_mask || null,
              default_gateway_ip_address:
                scannerDetails?.default_gateway_ip_address || null,
              dns: scannerDetails?.dns || null,
              mac_address: scannerDetails?.mac_address || null,
            }}
            validationSchema={yup.object().shape({
              name: yup
                .string()
                .required(translations.propertyScanners.scannerNameValidate),
              device_id: yup
                .string()
                .length(9, translations.propertyScanners.deviceIdLengthValidate)
                .required(translations.propertyScanners.deviceIdValidate),
              wifi_name: yup
                .string()
                .required(translations.propertyScanners.wifiNameValidate),
              wifi_password: yup
                .string()
                .required(translations.propertyScanners.wifiPasswordValidate),
              property_id: yup
                .number()
                .min(1, translations.propertyScanners.propertyCompanyValidate),
            })}
            onSubmit={_handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              isSubmitting,
              resetForm,
              handleSubmit,
              handleChange,
            }) => (
              <>
                <Label
                  label={translations.generalInformationTabTitle}
                  fontSize={"24px"}
                  className={"section-title"}
                />
                <div className="d-flex flex-column">
                  <div className="d-flex w-100 left-input-style">
                    <InputCustom
                      containerClass="custom-input left-input-style"
                      label={translations.propertyScanners.scannerName}
                      labelRequired
                      value={values.name}
                      error={errors.name}
                      touched={touched.name}
                      name="name"
                      onChange={handleChange}
                    />
                    <div className="w-100">
                      <Label
                        required
                        label={translations.propertyScanners.propertyCompany}
                        className="scanner-dropdown-label"
                      />
                      <DropdownCustom
                        className={"dropdown-fix"}
                        search
                        selectUI
                        value={values.property_id}
                        options={decorateListCompany()}
                        onChange={(e, data: any) => {
                          setFieldValue("property_id", data?.value);
                        }}
                      />
                      {errors.property_id && touched.property_id ? (
                        <span className="error">
                          {errors.property_id as string}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className={"w-50 left-input-style-sole-element"}>
                    <Label
                      label={translations.propertyScanners.doaScannerBatch}
                    />
                    <DatePickerSingle
                      value={moment.unix(values.date_of_batch).utc().toDate()}
                      inputStyle
                      oneTap
                      error={errors.date_of_batch}
                      onChange={(date: any) => {
                        setFieldValue(
                          "date_of_batch",
                          moment(date).utc().unix()
                        );
                      }}
                    />
                  </div>
                </div>
                <div className={"section-white-box_line"} />
                <div className="device-settings-section">
                  <Label
                    label={translations.propertyScanners.deviceSettings}
                    fontSize={"24px"}
                    className={"section-title header-section"}
                  />
                  <Label
                    label={translations.propertyScanners.deviceSettingsGuide}
                  />
                </div>
                <div className="d-flex">
                  <InputCustom
                    label={translations.propertyScanners.lastNineDigitsDeviceId}
                    labelRequired
                    containerClass="custom-input left-input-style"
                    value={values.device_id}
                    touched={touched.device_id}
                    error={errors.device_id}
                    name="device_id"
                    onChange={(e: any, { value }: any) => {
                      setFieldValue(
                        "device_id",
                        value.replace(/\D+/g, "").replaceAll(/^0+/g, "")
                      );
                    }}
                  />
                  <InputCustom
                    label={translations.propertyScanners.wifiName}
                    labelRequired
                    containerClass="custom-input"
                    value={values.wifi_name}
                    touched={touched.wifi_name}
                    error={errors.wifi_name}
                    name="wifi_name"
                    onChange={handleChange}
                  />
                </div>
                <div className="d-flex">
                  <InputCustom
                    label={"Mac Address"}
                    containerClass="custom-input left-input-style"
                    value={values.mac_address}
                    touched={touched.mac_address}
                    error={errors.mac_address}
                    name="mac_address"
                    onChange={handleChange}
                  />
                  <InputCustom
                    label={translations.propertyScanners.wifiPassword}
                    labelRequired
                    containerClass="custom-input"
                    value={values.wifi_password}
                    touched={touched.wifi_password}
                    error={errors.wifi_password}
                    name="wifi_password"
                    onChange={handleChange}
                  />
                </div>
                <div className={"d-flex"}>
                  <div className="d-flex flex-column w-100 left-input-style">
                    <Label
                      label={translations.propertyScanners.ipAddressSetting}
                      required
                      className="scanner-dropdown-label"
                    />
                    <DropdownCustom
                      selectUI
                      containerClass="custom-input left-input-style"
                      value={values.ip_setting}
                      options={decorateIPAddressSetting}
                      onChange={(e, data: any) => {
                        setFieldValue("ip_setting", data?.value);
                      }}
                      name="ip_setting"
                    />
                  </div>
                  <div className={"input-component custom-input"} />
                </div>
                {values.ip_setting === STATIC_IP_ADDRESS_SETTING ? (
                  <div className="bottom-end-section">
                    <SizeBox height={"40px"} />
                    <InputCustom
                      label={translations.propertyScanners.ipAddress}
                      containerClass="custom-input"
                      value={values.ip_address}
                      touched={touched.ip_address}
                      error={errors.ip_address}
                      name="ip_address"
                      onChange={handleChange}
                    />
                    <InputCustom
                      label={translations.propertyScanners.subnetMask}
                      containerClass="custom-input"
                      value={values.subnet_mask}
                      error={errors.subnet_mask}
                      name="subnet_mask"
                      onChange={handleChange}
                    />
                    <InputCustom
                      label={translations.propertyScanners.defaultGateway}
                      containerClass="custom-input"
                      value={values.default_gateway_ip_address}
                      touched={touched.default_gateway_ip_address}
                      error={errors.default_gateway_ip_address}
                      name="default_gateway_ip_address"
                      onChange={handleChange}
                    />
                    <InputCustom
                      label={translations.propertyScanners.dns}
                      containerClass="custom-input"
                      value={values.dns}
                      touched={touched.dns}
                      error={errors.dns}
                      name="dns"
                      onChange={handleChange}
                    />
                  </div>
                ) : null}
                <div className={"section-white-box-actions scanner-edition"}>
                  <ButtonCustom
                    className={"cancel mr-10px"}
                    onClick={resetForm}
                  >
                    {translations.cancel}
                  </ButtonCustom>
                  <ButtonCustom
                    className={"primary mr-0"}
                    onClick={handleSubmit}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    {translations.save}
                  </ButtonCustom>
                </div>
              </>
            )}
          </Formik>
        </SectionWhiteBox>
      )}
    </>
  );
};

export default PropertiesScannerCreateEdit;
