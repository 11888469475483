import React from "react";
import "./style.scss";
import { Popup } from "semantic-ui-react";

type Props = {
  size?: string;
  imgUrl?: string;
  capName?: string;
  name?: string;
  noTooltip?: boolean;
};

const Avatar = ({
  size = "36px",
  imgUrl,
  capName = "AU",
  name,
  noTooltip,
}: Props) => {
  return (
    <div
      className={"user-avatar"}
      style={{ width: size, height: size, minWidth: size }}
    >
      {imgUrl ? (
        <img className={"user-avatar__image"} src={imgUrl} />
      ) : (
        <div className={"user-avatar__capname"}>{capName}</div>
      )}
      {!noTooltip && (
        <Popup
          content={name}
          position="top center"
          trigger={<div className="user-tooltip" />}
        />
      )}
    </div>
  );
};

export default Avatar;
