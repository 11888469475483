import ButtonCustom from "../../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import React, { useContext, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import {
  deskimoPropertyStaffChangeStatusAction,
  deskimoPropertyStaffDeleteAction,
  modalDeskimoWorkspaceStaffListAction,
} from "../../../../actions/PropertyActions";
import { useDispatch } from "react-redux";
import {
  SWAL_CUSTOM_CONTENT,
  SWAL_DELETE_OPTION,
} from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import SwalSuccess from "../../../../../../components/SweetAlert/Templates/SwalSuccess";
import {
  propertyStaffActivateService,
  propertyStaffDeactivateService,
  propertyStaffDeleteService,
  propertyStaffReInviteService,
} from "../../../../services/PropertyStaffServices";
import { onLogInAsAccount } from "../../../../../User/services/UserServices";
import { DESKIMO_PROPERTY_STAFF_LINK } from "../../../../../App/configs/ListRoutes";

const USER_ACTIVE_STATUS = 1;
const USER_INACTIVE_STATUS = 0;

const Action = ({
  userId,
  entitlementId,
  userStatus,
  companyId,
  loginOnBehalf,
}: any) => {
  const dispatch = useDispatch();
  const { translations } = useContext(LocalizationContext);
  // const navigate = useNavigate();

  const [disabled, setDisabled] = useState(false);

  const _onEditStaff = async () => {
    dispatch(
      modalDeskimoWorkspaceStaffListAction({
        open: true,
        id: entitlementId,
        companyId,
      })
    );
  };

  const isUserDeactivated = () => {
    return userStatus == USER_INACTIVE_STATUS;
  };

  const _onReinviteUser = async () => {
    setDisabled(true);
    try {
      await propertyStaffReInviteService({
        user_id: String(entitlementId),
        company_id: String(companyId),
      });
      SweetAlertCommon({
        options: SWAL_CUSTOM_CONTENT({
          content: <SwalSuccess title={translations.inviteResent} />,
          cancelButtonClass: "d-none",
          denyButtonClass: "d-none",
          confirmButtonText: (
            <ButtonCustom className={"primary w-100"} height={"36px"}>
              {translations.done}
            </ButtonCustom>
          ),
        }),
        confirmCallback() {
          return null;
        },
        cancelCallback() {
          return null;
        },
      });
    } catch (e) {
      toastError("Reinvite unsuccessful");
      console.error(e);
    } finally {
      setDisabled(false);
    }
  };

  const _onChangeStatusStaff = async () => {
    setDisabled(true);
    try {
      const icon = null;
      const title = isUserDeactivated()
        ? translations.areYouSureYouWantToActivateThisUser
        : translations.areYouSureYouWantToDeactivateThisUser;
      const confirmText = isUserDeactivated()
        ? translations.activate
        : translations.deactivate;
      const cancelText = translations.cancel;
      const confirmClass = isUserDeactivated() ? "primary" : "danger";
      const options = SWAL_DELETE_OPTION({
        title,
        icon,
        confirmText,
        confirmClass,
        cancelText,
      });
      SweetAlertCommon({
        options,
        confirmCallback: async () => {
          setDisabled(true);
          try {
            const payload = {
              userId: entitlementId,
              companyId: companyId,
            };
            if (isUserDeactivated()) {
              await propertyStaffActivateService(payload);
              dispatch(deskimoPropertyStaffChangeStatusAction(userId));
              toastSuccess("Staff activated successfully");
            } else {
              await propertyStaffDeactivateService(payload);
              dispatch(deskimoPropertyStaffChangeStatusAction(userId));
              toastSuccess("Staff deactivated successfully");
            }
          } catch (response: any) {
            toastError(response?.status?.message);
          } finally {
            setDisabled(false);
          }
        },
        cancelCallback: () => null,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setDisabled(false);
    }
  };

  const _onDeleteStaff = async () => {
    setDisabled(true);
    try {
      const title = "Are you sure want to delete this user?";
      const iconHtml = (
        <img
          src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
          className={"swal2-icon__error"}
          alt="error_icon"
        />
      );
      const confirmText = "Delete";
      const confirmClass = "danger";
      const options = SWAL_DELETE_OPTION({
        title,
        iconHtml,
        confirmText,
        confirmClass,
      });
      SweetAlertCommon({
        options,
        confirmCallback: async () => {
          setDisabled(true);
          try {
            const payload = { company_id: companyId, user_id: entitlementId };
            await propertyStaffDeleteService(payload);
            dispatch(deskimoPropertyStaffDeleteAction(userId));
            toastSuccess("Staff has been deleted successfully");
          } catch (e) {
            toastError("Staff has not deleted due to an error");
            console.error(e);
          } finally {
            setDisabled(false);
          }
        },
        cancelCallback: () => null,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <DropdownCustom
      disabled={disabled}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={_onEditStaff}
          text={translations.editUser}
          icon={
            <i className="uil uil-edit icon" style={{ fontSize: "16px" }} />
          }
        />
        <Dropdown.Item
          onClick={_onReinviteUser}
          text={translations.reInviteUser}
          icon={
            <i
              className="uil uil-plus-circle icon"
              style={{ fontSize: "16px" }}
            />
          }
        />
        {userStatus === USER_ACTIVE_STATUS ? (
          <Dropdown.Item
            onClick={_onChangeStatusStaff}
            text={translations.deactivateUser}
            icon={
              <i
                className="uil uil-toggle-off icon"
                style={{ fontSize: "16px" }}
              />
            }
          />
        ) : userStatus === USER_INACTIVE_STATUS ? (
          <Dropdown.Item
            onClick={_onChangeStatusStaff}
            text={translations.activateUser}
            icon={
              <i
                className="uil uil-toggle-on icon"
                style={{ fontSize: "16px" }}
              />
            }
          />
        ) : null}
        <Dropdown.Item
          text={translations.deleteUser}
          onClick={_onDeleteStaff}
          icon={
            <i
              className="uil uil-trash-alt icon"
              style={{ fontSize: "16px" }}
            />
          }
        />
        {loginOnBehalf ? (
          <Dropdown.Item
            onClick={async () => {
              await onLogInAsAccount(
                userId,
                DESKIMO_PROPERTY_STAFF_LINK,
                companyId
              );
            }}
            text={translations.loginUserAccount}
            icon={
              <img src={PUBLIC_URL + "/assets/icons/icon-log-out-circle.svg"} />
            }
          />
        ) : null}
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default Action;
