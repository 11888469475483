import InputCustom from "../../../../../../components/Input";
import "./style.scss";
import { Formik } from "formik";
import * as yup from "yup";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import ButtonCustom from "../../../../../../components/Button";
import { configUpdateCommissionShareService } from "../../../../services/ConfigServices";
import { useState } from "react";
import { useSelector } from "react-redux";
import { configMarketCommissionShareSelector } from "../../../../selectors/ConfigSelectors";

const MarketCommissionShareRateInput = () => {
  const [loading, setLoading] = useState(false);
  const marketCommissionShare = useSelector(
    configMarketCommissionShareSelector
  );

  const _handleSave = async (values: any) => {
    setLoading(true);
    try {
      await configUpdateCommissionShareService(values);
      toastSuccess("Update successfully");
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={"input-white-box"}>
      <Formik
        enableReinitialize
        initialValues={{
          value: marketCommissionShare.value,
        }}
        validationSchema={yup.object().shape({
          value: yup.number().min(0),
        })}
        onSubmit={_handleSave}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <div className={"d-flex align-items-center"}>
            <InputCustom
              containerClass={"commission-cost-input"}
              inputLeftIcon={"%"}
              inputLeftIconBorder={true}
              value={values.value}
              onChange={handleChange}
              name={"value"}
              error={errors.value}
              label={"Commission cost"}
              touched={touched.value}
            />
            <ButtonCustom
              className={"primary button-relocate"}
              loading={loading}
              onClick={handleSubmit}
            >
              Save
            </ButtonCustom>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default MarketCommissionShareRateInput;
