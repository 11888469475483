import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { getPermissionService } from "../services/AuthenticationServices";
import { permissionAction } from "../actions/AuthenticationActions";

export const permissionThunk = () => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await getPermissionService();
    dispatch<any>(permissionAction(data?.permissions));
  };
};
