import { createReducer } from "@reduxjs/toolkit";
import { MEDIA_FILE_LIST_ACTION } from "../configs/Constants";

const initialState = {
  mediaFileList: [],
};
const MediaFileReducer = createReducer(initialState, {
  [MEDIA_FILE_LIST_ACTION]: (state, action) => {
    state.mediaFileList = action.payload;
  },
});

export default MediaFileReducer;
