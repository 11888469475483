import clientRequestService from "../../../services/ClientRequestService";
import {
  API_AUTOCOMPLETE_COMPANIES,
  API_AUTOCOMPLETE_MARKETS,
  API_AUTOCOMPLETE_PROPERTIES,
  API_AUTOCOMPLETE_USERS,
  API_CAMPAIGN,
  API_CAMPAIGN_DETAIL,
  API_CAMPAIGN_LIST,
  API_REWARD,
} from "../configs/Endpoints";
import { FormikValues } from "formik";

export const campaignListService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: API_CAMPAIGN_LIST,
    data: data,
  });
};

export const campaignDetailService = async (id: string) => {
  return await clientRequestService({
    url: API_CAMPAIGN_DETAIL.replaceAll("%id%", id),
  });
};

export const creteCampaignService = async (data: FormikValues) => {
  return await clientRequestService({
    method: "post",
    url: API_CAMPAIGN.replaceAll("/%id%", ""),
    data,
  });
};

export const editCampaignService = async (
  data: FormikValues,
  campaignId: string
) => {
  return await clientRequestService({
    method: "put",
    url: API_CAMPAIGN.replaceAll("%id%", campaignId),
    data,
  });
};

export const updateStatusCampaignService = async (
  campaignId: number,
  payload: object
) => {
  return await clientRequestService({
    method: "patch",
    url: API_CAMPAIGN.replaceAll("%id%", String(campaignId)),
    data: payload,
  });
};

export const deleteCampaignService = async (campaignId: number) => {
  return await clientRequestService({
    method: "delete",
    url: API_CAMPAIGN.replaceAll("%id%", String(campaignId)),
  });
};

export const autocompleteMarketsService = async (keyword = "") => {
  return await clientRequestService({
    url: API_AUTOCOMPLETE_MARKETS.replaceAll(
      keyword ? "%keyword%" : "/%keyword%",
      keyword
    ),
  });
};

export const autocompleteUsersService = async (keyword = "") => {
  return await clientRequestService({
    url: API_AUTOCOMPLETE_USERS.replaceAll(
      keyword ? "%keyword%" : "/%keyword%",
      keyword
    ),
  });
};

export const autocompletePropertiesService = async (keyword = "") => {
  return await clientRequestService({
    url: API_AUTOCOMPLETE_PROPERTIES.replaceAll(
      keyword ? "%keyword%" : "/%keyword%",
      keyword
    ),
  });
};

export const autocompleteCompaniesService = async (keyword = "") => {
  return await clientRequestService({
    url: API_AUTOCOMPLETE_COMPANIES.replaceAll(
      keyword ? "%keyword%" : "/%keyword%",
      keyword
    ),
  });
};

export const rewardDetailService = async (token: string) => {
  return await clientRequestService({
    url: API_REWARD.replaceAll("%token%", token),
    isAuth: false,
  });
};

export const redeemRewardService = async (token: string) => {
  return await clientRequestService({
    method: "put",
    url: API_REWARD.replaceAll("%token%", token),
    isAuth: false,
  });
};
