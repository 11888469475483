import React, { useContext } from "react";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import DropdownWithCheckboxMenu from "../../../../../components/DropdownWithCheckboxMenu";
import { LocalizationContext } from "../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import { deskimoPropertyListFilterSelector } from "../../../selectors/PropertySelector";
import { deskimoPropertyListFilterAction } from "../../../actions/PropertyActions";

const STATUS_LIST = [
  { name: "Published", id: 1 },
  { name: "Unpublished", id: 0 },
];

const StatusFilter = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const propertyListFilter = useSelector(deskimoPropertyListFilterSelector);

  const onChange = (value: any) => {
    const statuses = [...propertyListFilter.statuses];
    const isExist = statuses.includes(value);

    if (isExist) {
      dispatch(
        deskimoPropertyListFilterAction({
          key: "statuses",
          value: statuses.filter((id: number) => id !== value),
        })
      );
      return;
    }

    dispatch(
      deskimoPropertyListFilterAction({
        key: "statuses",
        value: [...statuses, value],
      })
    );
  };

  return (
    <DropdownWithCheckboxMenu
      reverseText={false}
      rowClassName={"book-filter-sub__item-row"}
      onChange={(event: any, { value }: { value: string }) => {
        onChange(value);
      }}
      listCheckBox={STATUS_LIST.map((filter: any) => {
        if (propertyListFilter.statuses.includes(filter.id)) {
          filter.checked = true;
        } else {
          filter.checked = false;
        }

        return filter;
      })}
      label={translations.status}
      icon={
        <img
          alt={"Deskimo"}
          src={PUBLIC_URL + "/assets/icons/icon-filter.svg"}
        />
      }
    />
  );
};

export default StatusFilter;
