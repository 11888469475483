import React, { useEffect, useState } from "react";
import { Marker, MarkerProps } from "@react-google-maps/api";
import { PUBLIC_URL } from "../../../App/configs/Constants";

interface Props extends MarkerProps {
  highlight?: boolean;
  isDiscount?: boolean;
  isFavorite?: boolean | number;
  isClose?: boolean;
  newBadge?: boolean;
}

const MarkerCustom = ({
  highlight,
  isDiscount,
  isFavorite,
  isClose,
  position,
  newBadge,
  ...props
}: Props) => {
  const [iconUrl, setIconUrl] = useState("/assets/icons/pin/icon-pin.svg");
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (hovered) {
      if (newBadge) {
        setIconUrl("/assets/icons/pin/icon-pin-selected-new.svg");
        return;
      }

      setIconUrl("/assets/icons/pin/icon-pin-selected.svg");
      return;
    }

    if (isDiscount) {
      if (highlight) {
        setIconUrl("/assets/icons/pin/icon-pin-selected-discount.svg");
        return;
      }

      if (isFavorite) {
        setIconUrl("/assets/icons/pin/icon-pin-favorite-discount.svg");
        return;
      }
      setIconUrl("/assets/icons/pin/icon-pin-discount.svg");
      return;
    }

    if (highlight) {
      if (newBadge) {
        setIconUrl("/assets/icons/pin/icon-pin-selected-new.svg");
        return;
      }

      setIconUrl("/assets/icons/pin/icon-pin-selected.svg");
      return;
    }

    if (isFavorite) {
      setIconUrl("/assets/icons/pin/icon-pin-favorite.svg");

      return;
    }
    if (isClose) {
      setIconUrl("/assets/icons/pin/icon-pin-closed.svg");
      return;
    }

    if (newBadge) {
      setIconUrl("/assets/icons/pin/icon-pin-new.svg");
      return;
    }

    setIconUrl("/assets/icons/pin/icon-pin.svg");
  }, [hovered, highlight]);

  return (
    <Marker
      position={position}
      {...props}
      icon={{
        url: PUBLIC_URL + iconUrl,
        size: highlight
          ? new google.maps.Size(48, 57)
          : new google.maps.Size(36, 42.6),
        scaledSize: highlight
          ? new google.maps.Size(48, 57)
          : new google.maps.Size(36, 42.6),
      }}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    />
  );
};

export default MarkerCustom;
