import React, { useEffect, useState } from "react";
import { BookMeetingRoomType } from "../../configs/DeclareType";
import ListCard from "../Components/ListCard";
import {
  handleBookRoomService,
  handleClickCardShowMarker,
} from "../../services/BookServices";
import { CARD_TYPE_ROOM, MEETING_ROOM_TYPE } from "../../configs/Constants";
import { useDispatch, useSelector } from "react-redux";
import {
  meetingRoomFiltersSelector,
  propertyFilterKeywordSelector,
} from "../../selectors/BookSelectors";
import { roomFavoriteThunk } from "../../thunks/BookThunks";
import {
  BOOK_ROOM_DETAIL_LINK,
  BOOK_ROOM_DETAIL_WITH_DEFAULT_DATE_LINK,
} from "../../../App/configs/ListRoutes";
import { Accordion } from "semantic-ui-react";

type Props = {
  data: BookMeetingRoomType[];
  favoriteSection?: boolean;
};

const RoomListRender = ({ data, favoriteSection }: Props) => {
  const dispatch = useDispatch();
  const propertyFilterKeyword = useSelector(propertyFilterKeywordSelector);
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(true);
  const filters = useSelector(meetingRoomFiltersSelector);

  const _checkDataLength = () => {
    if (favoriteSection) {
      return data.some(({ is_favorite }) => is_favorite);
    }
    return data.some(({ is_favorite }) => !is_favorite);
  };

  const _checkFavoriteLength = () => {
    return data.some(({ is_favorite }) => is_favorite);
  };

  useEffect(() => {
    setShow(_checkDataLength());
  }, [data]);

  if (!show) {
    return null;
  }

  console.log("filters: ", filters);

  return (
    <Accordion>
      <Accordion.Title
        active={!_checkFavoriteLength() ? true : open}
        index={0}
        onClick={() => setOpen((prevState) => !prevState)}
      >
        {favoriteSection ? (
          <>
            Favorites
            <i className="uil uil-angle-down" />
          </>
        ) : _checkFavoriteLength() ? (
          <>
            Meeting Rooms
            <i className="uil uil-angle-down" />
          </>
        ) : (
          ""
        )}
      </Accordion.Title>
      <Accordion.Content active={!_checkFavoriteLength() ? true : open}>
        {data
          .filter(
            ({
              name,
              address,
              company_name,
              property_name,
              property_address = "",
            }) =>
              name
                .toLowerCase()
                .includes(propertyFilterKeyword.toLowerCase()) ||
              company_name
                .toLowerCase()
                .includes(propertyFilterKeyword.toLowerCase()) ||
              property_name
                .toLowerCase()
                .includes(propertyFilterKeyword.toLowerCase()) ||
              property_address
                .toLowerCase()
                .includes(propertyFilterKeyword.toLowerCase()) ||
              address
                .toLowerCase()
                .includes(propertyFilterKeyword.toLowerCase())
          )
          .map((item) => {
            if (favoriteSection && !item.is_favorite) {
              return null;
            }

            const {
              meeting_room_id,
              name,
              property_name,
              company_name,
              address,
              capacity,
              thumbnail,
              rate_per_hour,
              currency,
              is_favorite,
              lat,
              lng,
              display_new_label,
              distance,
              full_day_price,
              auto_price_calculation,
            } = item;

            const office = property_name + " • " + company_name;
            const price = currency + " " + rate_per_hour;

            return (
              <ListCard
                fullDayPrice={auto_price_calculation ? "" : full_day_price}
                cardType={CARD_TYPE_ROOM}
                distance={distance}
                id={meeting_room_id}
                key={meeting_room_id}
                name={name}
                office={office}
                address={address}
                capacity={capacity}
                thumbnailUrl={thumbnail}
                rating={null}
                price={price}
                unit={"HR"}
                lat={lat}
                lng={lng}
                isFavorite={Boolean(is_favorite)}
                favoriteCallback={() =>
                  dispatch<any>(
                    roomFavoriteThunk({
                      isFavorite: is_favorite,
                      id: meeting_room_id,
                    })
                  )
                }
                bookCallback={() => handleBookRoomService(item)}
                onClickCallback={() =>
                  handleClickCardShowMarker({
                    lat,
                    lng,
                    id: meeting_room_id,
                    name,
                    address,
                    type: MEETING_ROOM_TYPE,
                    capacity,
                    rating: 4,
                    price,
                    unit: "HR",
                    isFavorite: is_favorite,
                    office,
                    thumbnailUrl: thumbnail,
                  })
                }
                goToDetailLink={BOOK_ROOM_DETAIL_WITH_DEFAULT_DATE_LINK.replaceAll(
                  ":id",
                  String(meeting_room_id)
                )
                  .replaceAll(":defaultDate", filters?.defaultDate)
                  .replaceAll(":defaultStartTime", filters?.defaultStartTime)
                  .replaceAll(":defaultDuration", filters?.defaultDuration)}
                newBadge={display_new_label}
              />
            );
          })}
      </Accordion.Content>
    </Accordion>
  );
};

export default RoomListRender;
