import { Modal, Popup } from "semantic-ui-react";
import React, { useContext, useRef, useState } from "react";
import ButtonCustom from "../../../../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { modalEditEmployeeAction } from "../../../../actions/EmployeeActions";
import {
  employeeDetailSelector,
  isModalEditEmployeeSelector,
} from "../../../../selectors/EmployeeSelectors";
import ModalRightSideBarTemplate from "../../../../../App/views/ModalRightSideBarTemplate";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { Formik } from "formik";
import {
  CALLING_CODE,
  DIALING_CODE,
} from "../../../../../App/configs/PhonePrefix";
import * as yup from "yup";
import Label from "../../../../../../components/Label";
import SizeBox from "../../../../../../components/SizeBox";
import InputCustom from "../../../../../../components/Input";
import ReactFlagsSelect from "react-flags-select";
import BoxToggleSlider from "../../../../../../components/BoxToggleSlider";
import ToggleSlider from "../../../../../../components/ToggleSlider";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { EmployeeDetailType } from "../../../../configs/DeclareTypes";
import { employeeUpdateService } from "../../../../services/EmployeeServices";
import { employeesThunk } from "../../../../thunks/EmployeeThunks";
import { uploadImageService } from "../../../../../App/services/AppServices";
import { Link } from "react-router-dom";
import { SETTING_LINK } from "../../../../../App/configs/ListRoutes";
import { fileValidation } from "../../../../../../utils/commons";
import ModalCropImage from "../../../../../App/views/ModalCropImage";
import { LocalizationContext } from "../../../../../../locales/Translation";
import MakeAdminLabelGuide from "../../../../../App/views/ModalAddEmployee/MakeAdminLableGuide";
import InputPhoneFlag from "../../../../../../components/InputPhoneFlag";

const ModalEditEmployee = () => {
  const { translations } = useContext(LocalizationContext);

  const dispatch = useDispatch();
  const isModalEditEmployee = useSelector(isModalEditEmployeeSelector);
  const formRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const uploadFileRef = useRef<any>();
  const [previewImage, setPreviewImage] = useState(null);
  const [openModalCrop, setOpenModalCrop] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);

  const employeeDetail: EmployeeDetailType = useSelector(
    employeeDetailSelector
  );

  const _handleSave = async (values: any) => {
    setLoading(true);
    try {
      values.monthly_budget = parseInt(values.monthly_budget);
      await employeeUpdateService(values);
      dispatch(modalEditEmployeeAction(false));
      await dispatch<any>(employeesThunk());
      toastSuccess("Updated successfully");
    } catch (e: any) {
      toastError(e?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const PreviewImage = ({ uri }: any) => {
    return <img src={uri} />;
  };

  const _onCloseModal = () => {
    setPreviewImage(null);
    dispatch(modalEditEmployeeAction(false));
  };

  const handleUploadFile = async (e: any) => {
    const reader: any = new FileReader();
    reader.addEventListener("load", () =>
      setImgSrc(reader.result.toString() || "")
    );
    reader.readAsDataURL(e.target.files[0]);
    setOpenModalCrop(true);
  };

  return (
    <ModalRightSideBarTemplate
      trigger={null}
      onClose={_onCloseModal}
      modalClassName={"modal-edit-employee"}
      open={isModalEditEmployee}
    >
      <Modal.Header>
        {translations.editEmployee}
        <ButtonCustom
          onClick={_onCloseModal}
          className={"m-0 p-0 none-bg close-modal"}
        >
          <>
            {translations.close}
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Modal.Content className={"scroll"}>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={{
            invitation_id: employeeDetail?.invitation_id || "",
            avatar_uri: employeeDetail?.avatar || "",
            first_name: employeeDetail?.first_name || "",
            last_name: employeeDetail?.last_name || "",
            email: employeeDetail?.email || "",
            phone: employeeDetail?.phone_number || "",
            country_code: DIALING_CODE.filter((country) => {
              const countryCode = employeeDetail?.country_code
                ? employeeDetail?.country_code
                : "65";
              return country?.value?.replaceAll("+", "") === countryCode;
            })[0]?.key.toUpperCase(),
            monthly_budget:
              employeeDetail?.monthly_budget === "Uncapped"
                ? ""
                : parseInt(
                    String(employeeDetail?.monthly_budget)
                      .replaceAll(",", "")
                      .replaceAll(".00", "")
                  ),
            used_company_budget: employeeDetail?.monthly_pooled_budget || false,
            make_admin: employeeDetail?.role === 2,
            currency_code: employeeDetail?.currency || "",
          }}
          validationSchema={yup.object().shape({
            first_name: yup.string().required().label("First Name"),
            email: yup.string().email().required().label("Email"),
            //phone: yup.string().required().label("Phone"),
            monthly_budget: yup
              .number()
              .min(0)
              .typeError("Please input number only")
              .label("Monthly Budget"),
          })}
          onSubmit={_handleSave}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => (
            <form className={"form-layout"}>
              <div>
                <Label
                  className={"sm"}
                  label={translations.profilePicture}
                  labelGuide={translations.uploadImageFormat}
                />

                <div className={"d-flex align-items-center mb-48px"}>
                  <div className={"my-profile__avatar"}>
                    {previewImage ? (
                      <PreviewImage uri={previewImage} />
                    ) : (
                      <img
                        src={
                          values?.avatar_uri
                            ? values?.avatar_uri
                            : PUBLIC_URL + "/assets/default-add-avatar.svg"
                        }
                      />
                    )}

                    <input
                      className={"d-none"}
                      ref={uploadFileRef}
                      type="file"
                      accept="image/*"
                      onChange={async (event: any) => {
                        const isFileValid = fileValidation(event.target.files);

                        if (isFileValid) {
                          const _URL = window.URL || window.webkitURL;
                          const img = new Image();
                          const objectUrl = _URL.createObjectURL(
                            event.target.files[0]
                          );

                          img.src = objectUrl;
                          img.onload = async () => {
                            _URL.revokeObjectURL(objectUrl);
                            await handleUploadFile(event);
                            event.target.value = null;
                          };
                          setOpenModalCrop(true);
                        } else {
                          toastError("The file must be smaller than 2MB");
                        }
                      }}
                    />
                  </div>

                  <SizeBox width={"32px"} />

                  <ButtonCustom
                    onClick={(e: any) => {
                      e.preventDefault();
                      uploadFileRef.current.click();
                    }}
                    className={"primary-alt"}
                    height={"36px"}
                  >
                    <>
                      {translations.uploadImage}
                      <SizeBox width={"14px"} />
                      <i className="uil uil-upload-alt" />
                    </>
                  </ButtonCustom>
                </div>
              </div>
              <SizeBox height={"36px"} />
              <InputCustom
                labelRequired
                label={"First Name"}
                value={values.first_name}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.first_name}
                error={errors.first_name}
                name={"first_name"}
              />
              <InputCustom
                label={translations.lastName}
                value={values.last_name}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.last_name}
                error={errors.last_name}
                name={"last_name"}
              />
              <InputCustom
                labelRequired
                label={translations.emailAddress}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.email}
                error={errors.email}
                name={"email"}
              />
              <div className={"mb-16px"} />
              <InputPhoneFlag
                label={translations.mobilePhoneNumber}
                labelGuide={translations.addMobilePhoneLabelGuide}
                selectedFlag={values.country_code}
                onSelectFlag={(code) => setFieldValue("country_code", code)}
                error={errors.phone}
                touched={touched.phone}
                phoneChange={(value) => setFieldValue("phone", value)}
                phoneValue={values.phone}
                disabled
              />
              <SizeBox height={"7px"} />
              <Label
                fontSize={"12px"}
                className={"sm"}
                label={translations.monthlyBudget_EmptyIsUnlimited}
                labelGuide={translations.monthlyBudgetLabelGuide}
              />
              <InputCustom
                inputLeftIconBorder
                inputLeftIcon={values.currency_code}
                value={values.monthly_budget}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.monthly_budget}
                error={errors.monthly_budget}
                name={"monthly_budget"}
              />
              <SizeBox height={"23px"} />
              <ToggleSlider
                className={"fw-500"}
                revertLabel
                value={values.used_company_budget}
                onChange={(e: any, data: any) =>
                  setFieldValue("used_company_budget", data.checked)
                }
                content={translations.accessToMonthlyCompanyPooledBudget}
                disabled={!employeeDetail?.has_monthly_budget}
              />
              <SizeBox height={"14px"} />
              {employeeDetail?.has_monthly_budget ? null : (
                <div className={"employee__sub-text"}>
                  * {translations.thePooledBudgetOptionIsCurrentlyDeactivated}
                  <Link to={SETTING_LINK} className={"link"}>
                    {translations.activateInSettings}
                  </Link>
                </div>
              )}
              <SizeBox height={"40px"} />
              <Popup
                content="Before promoting to admin, please enter the user’s phone number"
                disabled={!!values.phone.length}
                position={"top right"}
                trigger={
                  <BoxToggleSlider
                    disabled={!values.phone.length}
                    value={values.make_admin}
                    onChange={(e: any, data: any) =>
                      setFieldValue("make_admin", data.checked)
                    }
                    content={
                      <Label
                        fontSize={"14px"}
                        size={"16px"}
                        label={translations.makeAdmin}
                        labelGuide={<MakeAdminLabelGuide />}
                      />
                    }
                  />
                }
              />
              <ModalCropImage
                imgSrc={imgSrc}
                open={openModalCrop}
                setOpen={setOpenModalCrop}
                setFieldValue={setFieldValue}
                fieldKey={"avatar_uri"}
                setPreviewImage={setPreviewImage}
              />
            </form>
          )}
        </Formik>
      </Modal.Content>
      <Modal.Actions>
        <ButtonCustom height={"47px"} onClick={_onCloseModal}>
          {translations.cancel}
        </ButtonCustom>
        <ButtonCustom
          type={"submit"}
          loading={loading}
          className={"primary"}
          height={"47px"}
          onClick={() => {
            formRef?.current?.handleSubmit();
          }}
        >
          {translations.save}
        </ButtonCustom>
      </Modal.Actions>
    </ModalRightSideBarTemplate>
  );
};

export default ModalEditEmployee;
