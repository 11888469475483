import TableCustom from "../../../../../../components/Table";
import { CustomPackageType } from "../../../../configs/DeclareTypes";
import Action from "./Action";
import moment from "moment-timezone";
import { FORMAT_DATE_VARIANT_2 } from "../../../../../App/configs/DateTimeConfigs";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useContext } from "react";
import MarketFilterMultiple from "../../../../../../components/FilterMarket/MarketFilterMultiple";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
  TIMEZONE_SINGAPORE,
} from "../../../../../App/configs/Constants";

const CUSTOM_PACKAGE_MODE = 1;
const EXPENSE_PACKAGE_MODE = 2;

const PackagesTable = ({
  tableData,
  loading,
  onChangePagination,
  onChangeMarket,
  marketOptions,
  filter,
  packageType,
  setEditId,
  setFilter,
}: any) => {
  const { translations } = useContext(LocalizationContext);
  //package_name
  const customPackageColumns = [
    {
      key: "package_name",
      col: translations.customPackages.serviceNameColumnTitle,
    },
    {
      key: "company_name",
      col: translations.customPackages.companyNameColumnTitle,
    },
    {
      key: "amount",
      col: translations.customPackages.amountLabel,
    },
    {
      key: "recognition_date",
      col: translations.customPackages.recognitionLabel,
    },
    {
      key: "",
      col: translations.customPackages.actionsColumnTitle,
    },
  ];

  const expensePackageColumns = [
    {
      key: "package_name",
      col: translations.customPackages.serviceNameColumnTitle,
    },
    {
      key: "amount",
      col: translations.customPackages.amountLabel,
    },
    {
      key: "recognition_date",
      col: translations.customPackages.recognitionLabel,
    },
    {
      key: "",
      col: translations.customPackages.actionsColumnTitle,
    },
  ];

  const _formatInputData = () => {
    if (packageType === CUSTOM_PACKAGE_MODE) {
      return tableData?.item?.map(
        ({
          package_id,
          package_name,
          company_name,
          amount,
          recognition_date,
          is_cancelled,
        }: CustomPackageType) => {
          return {
            col1: package_name,
            col2: company_name,
            col3: amount,
            col4: moment
              .unix(Number(recognition_date))
              .tz(TIMEZONE_SINGAPORE)
              .format(FORMAT_DATE_VARIANT_2),
            col5: (
              <Action
                packageId={package_id}
                packageType={packageType}
                marketOptions={marketOptions}
                cancelStatus={is_cancelled}
                setEditId={setEditId}
              />
            ),
          };
        }
      );
    } else if (packageType === EXPENSE_PACKAGE_MODE) {
      return tableData?.item?.map(
        ({
          package_id,
          package_name,
          amount,
          recognition_date,
          is_cancelled,
        }: CustomPackageType) => {
          return {
            col1: package_name,
            col2: amount,
            col3: moment
              .unix(Number(recognition_date))
              .tz(TIMEZONE_SINGAPORE)
              .format(FORMAT_DATE_VARIANT_2),
            col4: (
              <Action
                packageId={package_id}
                packageType={packageType}
                marketOptions={marketOptions}
                cancelStatus={is_cancelled}
                setEditId={setEditId}
              />
            ),
          };
        }
      );
    }
  };

  const _onChangeSortCol = (col: any) => {
    if (filter.sort_by !== col.key) {
      setFilter({ ...filter, sort_by: col.key, order_by: ORDER_BY_ASC });
    } else {
      setFilter({ ...filter, sort_by: col.key });
      if (filter.order_by === ORDER_BY_NONE) {
        setFilter({ ...filter, order_by: ORDER_BY_ASC });
      }
      if (filter.order_by === ORDER_BY_ASC) {
        setFilter({ ...filter, order_by: ORDER_BY_DESC });
      }
      if (filter.order_by === ORDER_BY_DESC) {
        setFilter({ ...filter, order_by: ORDER_BY_NONE });
      }
    }
  };

  return (
    <TableCustom
      orderBy={filter.order_by}
      sortBy={filter.sort_by}
      onChangeSortCol={_onChangeSortCol}
      sortTable={true}
      columns={
        packageType === CUSTOM_PACKAGE_MODE
          ? customPackageColumns
          : packageType === EXPENSE_PACKAGE_MODE
          ? expensePackageColumns
          : null
      }
      showTotal
      totalItems={tableData?.pagination?.total}
      currentPage={tableData?.pagination?.page}
      itemPerPage={tableData?.pagination?.limit}
      unit={translations.transactions}
      loading={loading}
      tableData={tableData ? _formatInputData() : []}
      onChangePagination={onChangePagination}
      topRightComponent={
        <MarketFilterMultiple
          onChange={onChangeMarket}
          values={filter.market_code}
        />
      }
    />
  );
};

export default PackagesTable;
