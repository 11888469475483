import { Routes, Route } from "react-router-dom";
import {
  ACCOUNTING_LINK,
  CONFIG_MAIN_LINK,
  CONFIG_MARKET_CREATE_LINK,
  CONFIG_MARKET_EDIT_GENERAL_INFORMATION_LINK,
  CONFIG_MARKET_EDIT_LANDMARK_LINK,
  CONFIG_MARKET_EDIT_ONBOARD_PROMOTION,
  CONFIG_MARKET_EDIT_PUBLIC_HOLIDAYS_LINK,
  CONFIG_PREPAID_CREDIT_PACKAGES_CONFIGURATION,
  CONFIG_ROLE_PERMISSION_LINK,
  CONFIG_TAGS,
  PARTNER_INTEGRATION_LINK,
  PARTNER_LIMITATION_LINK,
  PARTNER_OPERATION_LOG_LINK,
} from "../../App/configs/ListRoutes";
import MarketEdit from "../views/MarketEdit";
import MarketCreate from "../views/MarketCreate";
import Configuration from "../views";
import PartnerIntegration from "../views/PartnerIntegration";
import { useSelector } from "react-redux";
import { myProfileSelector } from "../../App/selectors/AppSelectors";

const ConfigRoutes = () => {
  const myProfile = useSelector(myProfileSelector);

  return (
    <Routes>
      <Route
        path={CONFIG_MAIN_LINK}
        element={<Configuration tabName={CONFIG_MAIN_LINK} />}
      />

      {myProfile?.is_show_permission ? (
        <Route
          path={CONFIG_ROLE_PERMISSION_LINK}
          element={<Configuration tabName={CONFIG_ROLE_PERMISSION_LINK} />}
        />
      ) : (
        <></>
      )}

      <Route
        path={CONFIG_TAGS}
        element={<Configuration tabName={CONFIG_TAGS} />}
      />
      <Route
        path={CONFIG_PREPAID_CREDIT_PACKAGES_CONFIGURATION}
        element={
          <Configuration
            tabName={CONFIG_PREPAID_CREDIT_PACKAGES_CONFIGURATION}
          />
        }
      />
      <Route
        path={ACCOUNTING_LINK}
        element={<Configuration tabName={ACCOUNTING_LINK} />}
      />

      <Route
        path={CONFIG_MARKET_EDIT_GENERAL_INFORMATION_LINK}
        element={<MarketEdit tabIndex={1} />}
      />
      <Route
        path={CONFIG_MARKET_EDIT_PUBLIC_HOLIDAYS_LINK}
        element={<MarketEdit tabIndex={2} />}
      />
      <Route
        path={CONFIG_MARKET_EDIT_LANDMARK_LINK}
        element={<MarketEdit tabIndex={3} />}
      />
      <Route
        path={CONFIG_MARKET_EDIT_ONBOARD_PROMOTION}
        element={<MarketEdit tabIndex={4} />}
      />
      <Route
        path={CONFIG_MARKET_CREATE_LINK}
        element={<MarketCreate tabIndex={1} />}
      />
      <Route
        path={PARTNER_INTEGRATION_LINK}
        element={<PartnerIntegration tabIndex={0} />}
      />
      <Route
        path={PARTNER_OPERATION_LOG_LINK}
        element={<PartnerIntegration tabIndex={1} />}
      />
      <Route
        path={PARTNER_LIMITATION_LINK}
        element={<PartnerIntegration tabIndex={2} />}
      />
    </Routes>
  );
};

export default ConfigRoutes;
