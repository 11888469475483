import React, { useContext } from "react";
import ButtonCustom from "../../../../../../components/Button";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { partnerHotDeskBookingStatusService } from "../../../../services/DashboardServices";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { SWAL_DELETE_OPTION } from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import { dashboardDeskBookingThunk } from "../../../../thunks/DashboardThunks";
import {
  BOOKING_APPROVED,
  BOOKING_CANCELED,
  BOOKING_REJECTED,
  BUSINESS,
} from "../../../../configs/Constants";
import { useDispatch } from "react-redux";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import { Dropdown } from "semantic-ui-react";
import { isGlobalIndicatorAction } from "../../../../../App/actions/AppActions";

type Props = {
  bookingId: string;
  statusId: number;
};

const DeskBookingAction = ({ bookingId, statusId }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const _onChangeStatus = async (statusId: number) => {
    const textShow = {
      title: "",
      confirmText: "",
      toastSuccess: "",
      confirmClass: "",
    };

    switch (statusId) {
      case BOOKING_APPROVED:
        textShow.title = "Are you sure want to approve this booking";
        textShow.confirmText = translations.approve;
        textShow.toastSuccess = "Approved Successfully!";
        textShow.confirmClass = "primary";
        break;
      case BOOKING_REJECTED:
        textShow.title = "Are you sure want to decline this booking";
        textShow.confirmText = translations.decline;
        textShow.toastSuccess = "Declined Successfully!";
        textShow.confirmClass = "danger";
        break;
      case BOOKING_CANCELED:
        textShow.title = "Are you sure want to cancel this booking";
        textShow.confirmText = translations.cancel;
        textShow.toastSuccess = "Canceled Successfully!";
        textShow.confirmClass = "danger";
        break;
    }
    const options = SWAL_DELETE_OPTION({
      title: textShow.title,
      showCancelButton: true,
      confirmText: textShow.confirmText,
      confirmClass: textShow.confirmClass,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          dispatch(isGlobalIndicatorAction(true));
          await partnerHotDeskBookingStatusService(bookingId, statusId);
          await dispatch<any>(dashboardDeskBookingThunk(BUSINESS));
          toastSuccess(textShow.toastSuccess);
        } catch (e: any) {
          toastError(e?.status?.message);
        } finally {
          dispatch(isGlobalIndicatorAction(false));
        }
      },
      cancelCallback: () => null,
    });
  };

  return (
    <DropdownCustom
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        {statusId === BOOKING_REJECTED ||
        (statusId !== BOOKING_APPROVED && statusId !== BOOKING_REJECTED) ? (
          <Dropdown.Item
            onClick={() => _onChangeStatus(BOOKING_APPROVED)}
            text={translations.approve}
            icon={<i className="uil uil-check-circle icon" />}
          />
        ) : null}

        {statusId === BOOKING_APPROVED ||
        (statusId !== BOOKING_APPROVED && statusId !== BOOKING_REJECTED) ? (
          <Dropdown.Item
            onClick={() => _onChangeStatus(BOOKING_REJECTED)}
            text={translations.decline}
            icon={<i className="uil uil-file-slash icon" />}
          />
        ) : null}

        <Dropdown.Item
          onClick={() => _onChangeStatus(3)}
          text={translations.cancel}
          icon={<i className="uil uil-times-circle icon" />}
        />
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default DeskBookingAction;
