import React, { useContext } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { PUBLIC_URL } from "../../configs/Constants";
import { LocalizationContext } from "../../../../locales/Translation";
import classNames from "classnames";

type Props = {
  children: JSX.Element | JSX.Element[];
  className?: string;
};

const LoginTemplate = ({ children, className }: Props) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <div className={classNames("login__wrapper", className)}>
      <div className={"login"}>
        <Link to={"/"} className={"login__logo"}>
          <img src={PUBLIC_URL + "/assets/logo.svg"} alt="deskimo-logo" />
        </Link>
        <div className={"login__form"}>{children}</div>
        <div className={"login__actions"}>
          <a
            href={"https://admin.deskimo.com/contact-us"}
            target={"_blank"}
            rel="noreferrer"
          >
            {translations.contactUs}
          </a>
          <a
            href={"https://www.deskimo.com/privacy-policy/"}
            target={"_blank"}
            rel="noreferrer"
          >
            {translations.privacyPolicy}
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginTemplate;
