export const COUNTRY_LIST = [
  {
    key: "1",
    text: "United States",
    value: "1",
  },
  {
    key: "7",
    text: "Russia",
    value: "7",
  },
  {
    key: "20",
    text: "Egypt",
    value: "20",
  },
  {
    key: "27",
    text: "South Africa",
    value: "27",
  },
  {
    key: "30",
    text: "Greece",
    value: "30",
  },
  {
    key: "31",
    text: "Netherlands",
    value: "31",
  },
  {
    key: "32",
    text: "Belgium",
    value: "32",
  },
  {
    key: "33",
    text: "France",
    value: "33",
  },
  {
    key: "34",
    text: "Spain",
    value: "34",
  },
  {
    key: "36",
    text: "Hungary",
    value: "36",
  },
  {
    key: "39",
    text: "Italy",
    value: "39",
  },
  {
    key: "40",
    text: "Romania",
    value: "40",
  },
  {
    key: "41",
    text: "Switzerland",
    value: "41",
  },
  {
    key: "43",
    text: "Austria",
    value: "43",
  },
  {
    key: "44",
    text: "United Kingdom",
    value: "44",
  },
  {
    key: "45",
    text: "Denmark",
    value: "45",
  },
  {
    key: "46",
    text: "Sweden",
    value: "46",
  },
  {
    key: "47",
    text: "Svalbard and Jan Mayen",
    value: "47",
  },
  {
    key: "48",
    text: "Poland",
    value: "48",
  },
  {
    key: "49",
    text: "Germany",
    value: "49",
  },
  {
    key: "51",
    text: "Peru",
    value: "51",
  },
  {
    key: "52",
    text: "Mexico",
    value: "52",
  },
  {
    key: "53",
    text: "Cuba",
    value: "53",
  },
  {
    key: "54",
    text: "Argentina",
    value: "54",
  },
  {
    key: "55",
    text: "Brazil",
    value: "55",
  },
  {
    key: "56",
    text: "Chile",
    value: "56",
  },
  {
    key: "57",
    text: "Colombia",
    value: "57",
  },
  {
    key: "58",
    text: "Venezuela, Bolivarian Republic of Venezuela",
    value: "58",
  },
  {
    key: "60",
    text: "Malaysia",
    value: "60",
  },
  {
    key: "61",
    text: "Cocos (Keeling) Islands",
    value: "61",
  },
  {
    key: "62",
    text: "Indonesia",
    value: "62",
  },
  {
    key: "63",
    text: "Philippines",
    value: "63",
  },
  {
    key: "64",
    text: "New Zealand",
    value: "64",
  },
  {
    key: "65",
    text: "Singapore",
    value: "65",
  },
  {
    key: "66",
    text: "Thailand",
    value: "66",
  },
  {
    key: "77",
    text: "Kazakhstan",
    value: "77",
  },
  {
    key: "81",
    text: "Japan",
    value: "81",
  },
  {
    key: "82",
    text: "Korea, Republic of South Korea",
    value: "82",
  },
  {
    key: "84",
    text: "Vietnam",
    value: "84",
  },
  {
    key: "86",
    text: "China",
    value: "86",
  },
  {
    key: "90",
    text: "Turkey",
    value: "90",
  },
  {
    key: "91",
    text: "India",
    value: "91",
  },
  {
    key: "92",
    text: "Pakistan",
    value: "92",
  },
  {
    key: "93",
    text: "Afghanistan",
    value: "93",
  },
  {
    key: "94",
    text: "Sri Lanka",
    value: "94",
  },
  {
    key: "95",
    text: "Myanmar",
    value: "95",
  },
  {
    key: "98",
    text: "Iran, Islamic Republic of Persian Gulf",
    value: "98",
  },
  {
    key: "211",
    text: "South Sudan",
    value: "211",
  },
  {
    key: "212",
    text: "Morocco",
    value: "212",
  },
  {
    key: "213",
    text: "Algeria",
    value: "213",
  },
  {
    key: "216",
    text: "Tunisia",
    value: "216",
  },
  {
    key: "218",
    text: "Libyan Arab Jamahiriya",
    value: "218",
  },
  {
    key: "220",
    text: "Gambia",
    value: "220",
  },
  {
    key: "221",
    text: "Senegal",
    value: "221",
  },
  {
    key: "222",
    text: "Mauritania",
    value: "222",
  },
  {
    key: "223",
    text: "Mali",
    value: "223",
  },
  {
    key: "224",
    text: "Guinea",
    value: "224",
  },
  {
    key: "225",
    text: "Cote d'Ivoire",
    value: "225",
  },
  {
    key: "226",
    text: "Burkina Faso",
    value: "226",
  },
  {
    key: "227",
    text: "Niger",
    value: "227",
  },
  {
    key: "228",
    text: "Togo",
    value: "228",
  },
  {
    key: "229",
    text: "Benin",
    value: "229",
  },
  {
    key: "230",
    text: "Mauritius",
    value: "230",
  },
  {
    key: "231",
    text: "Liberia",
    value: "231",
  },
  {
    key: "232",
    text: "Sierra Leone",
    value: "232",
  },
  {
    key: "233",
    text: "Ghana",
    value: "233",
  },
  {
    key: "234",
    text: "Nigeria",
    value: "234",
  },
  {
    key: "235",
    text: "Chad",
    value: "235",
  },
  {
    key: "236",
    text: "Central African Republic",
    value: "236",
  },
  {
    key: "237",
    text: "Cameroon",
    value: "237",
  },
  {
    key: "238",
    text: "Cape Verde",
    value: "238",
  },
  {
    key: "239",
    text: "Sao Tome and Principe",
    value: "239",
  },
  {
    key: "240",
    text: "Equatorial Guinea",
    value: "240",
  },
  {
    key: "241",
    text: "Gabon",
    value: "241",
  },
  {
    key: "242",
    text: "Congo",
    value: "242",
  },
  {
    key: "243",
    text: "Congo, The Democratic Republic of the Congo",
    value: "243",
  },
  {
    key: "244",
    text: "Angola",
    value: "244",
  },
  {
    key: "245",
    text: "Guinea-Bissau",
    value: "245",
  },
  {
    key: "246",
    text: "British Indian Ocean Territory",
    value: "246",
  },
  {
    key: "248",
    text: "Seychelles",
    value: "248",
  },
  {
    key: "249",
    text: "Sudan",
    value: "249",
  },
  {
    key: "250",
    text: "Rwanda",
    value: "250",
  },
  {
    key: "251",
    text: "Ethiopia",
    value: "251",
  },
  {
    key: "252",
    text: "Somalia",
    value: "252",
  },
  {
    key: "253",
    text: "Djibouti",
    value: "253",
  },
  {
    key: "254",
    text: "Kenya",
    value: "254",
  },
  {
    key: "255",
    text: "Tanzania, United Republic of Tanzania",
    value: "255",
  },
  {
    key: "256",
    text: "Uganda",
    value: "256",
  },
  {
    key: "257",
    text: "Burundi",
    value: "257",
  },
  {
    key: "258",
    text: "Mozambique",
    value: "258",
  },
  {
    key: "260",
    text: "Zambia",
    value: "260",
  },
  {
    key: "261",
    text: "Madagascar",
    value: "261",
  },
  {
    key: "262",
    text: "Reunion",
    value: "262",
  },
  {
    key: "263",
    text: "Zimbabwe",
    value: "263",
  },
  {
    key: "264",
    text: "Namibia",
    value: "264",
  },
  {
    key: "265",
    text: "Malawi",
    value: "265",
  },
  {
    key: "266",
    text: "Lesotho",
    value: "266",
  },
  {
    key: "267",
    text: "Botswana",
    value: "267",
  },
  {
    key: "268",
    text: "Swaziland",
    value: "268",
  },
  {
    key: "269",
    text: "Comoros",
    value: "269",
  },
  {
    key: "290",
    text: "Saint Helena, Ascension and Tristan Da Cunha",
    value: "290",
  },
  {
    key: "291",
    text: "Eritrea",
    value: "291",
  },
  {
    key: "297",
    text: "Aruba",
    value: "297",
  },
  {
    key: "298",
    text: "Faroe Islands",
    value: "298",
  },
  {
    key: "299",
    text: "Greenland",
    value: "299",
  },
  {
    key: "350",
    text: "Gibraltar",
    value: "350",
  },
  {
    key: "351",
    text: "Portugal",
    value: "351",
  },
  {
    key: "352",
    text: "Luxembourg",
    value: "352",
  },
  {
    key: "353",
    text: "Ireland",
    value: "353",
  },
  {
    key: "354",
    text: "Iceland",
    value: "354",
  },
  {
    key: "355",
    text: "Albania",
    value: "355",
  },
  {
    key: "356",
    text: "Malta",
    value: "356",
  },
  {
    key: "357",
    text: "Cyprus",
    value: "357",
  },
  {
    key: "358",
    text: "Finland",
    value: "358",
  },
  {
    key: "359",
    text: "Bulgaria",
    value: "359",
  },
  {
    key: "370",
    text: "Lithuania",
    value: "370",
  },
  {
    key: "371",
    text: "Latvia",
    value: "371",
  },
  {
    key: "372",
    text: "Estonia",
    value: "372",
  },
  {
    key: "373",
    text: "Moldova",
    value: "373",
  },
  {
    key: "374",
    text: "Armenia",
    value: "374",
  },
  {
    key: "375",
    text: "Belarus",
    value: "375",
  },
  {
    key: "376",
    text: "Andorra",
    value: "376",
  },
  {
    key: "377",
    text: "Monaco",
    value: "377",
  },
  {
    key: "378",
    text: "San Marino",
    value: "378",
  },
  {
    key: "379",
    text: "Holy See (Vatican City State)",
    value: "379",
  },
  {
    key: "380",
    text: "Ukraine",
    value: "380",
  },
  {
    key: "381",
    text: "Serbia",
    value: "381",
  },
  {
    key: "382",
    text: "Montenegro",
    value: "382",
  },
  {
    key: "385",
    text: "Croatia",
    value: "385",
  },
  {
    key: "386",
    text: "Slovenia",
    value: "386",
  },
  {
    key: "387",
    text: "Bosnia and Herzegovina",
    value: "387",
  },
  {
    key: "389",
    text: "Macedonia",
    value: "389",
  },
  {
    key: "420",
    text: "Czech Republic",
    value: "420",
  },
  {
    key: "421",
    text: "Slovakia",
    value: "421",
  },
  {
    key: "423",
    text: "Liechtenstein",
    value: "423",
  },
  {
    key: "500",
    text: "South Georgia and the South Sandwich Islands",
    value: "500",
  },
  {
    key: "501",
    text: "Belize",
    value: "501",
  },
  {
    key: "502",
    text: "Guatemala",
    value: "502",
  },
  {
    key: "503",
    text: "El Salvador",
    value: "503",
  },
  {
    key: "504",
    text: "Honduras",
    value: "504",
  },
  {
    key: "505",
    text: "Nicaragua",
    value: "505",
  },
  {
    key: "506",
    text: "Costa Rica",
    value: "506",
  },
  {
    key: "507",
    text: "Panama",
    value: "507",
  },
  {
    key: "508",
    text: "Saint Pierre and Miquelon",
    value: "508",
  },
  {
    key: "509",
    text: "Haiti",
    value: "509",
  },
  {
    key: "590",
    text: "Saint Martin",
    value: "590",
  },
  {
    key: "591",
    text: "Bolivia, Plurinational State of",
    value: "591",
  },
  {
    key: "593",
    text: "Ecuador",
    value: "593",
  },
  {
    key: "594",
    text: "French Guiana",
    value: "594",
  },
  {
    key: "595",
    text: "Paraguay",
    value: "595",
  },
  {
    key: "596",
    text: "Martinique",
    value: "596",
  },
  {
    key: "597",
    text: "Suriname",
    value: "597",
  },
  {
    key: "598",
    text: "Uruguay",
    value: "598",
  },
  {
    key: "599",
    text: "Netherlands Antilles",
    value: "599",
  },
  {
    key: "670",
    text: "Timor-Leste",
    value: "670",
  },
  {
    key: "672",
    text: "Norfolk Island",
    value: "672",
  },
  {
    key: "673",
    text: "Brunei Darussalam",
    value: "673",
  },
  {
    key: "674",
    text: "Nauru",
    value: "674",
  },
  {
    key: "675",
    text: "Papua New Guinea",
    value: "675",
  },
  {
    key: "676",
    text: "Tonga",
    value: "676",
  },
  {
    key: "677",
    text: "Solomon Islands",
    value: "677",
  },
  {
    key: "678",
    text: "Vanuatu",
    value: "678",
  },
  {
    key: "679",
    text: "Fiji",
    value: "679",
  },
  {
    key: "680",
    text: "Palau",
    value: "680",
  },
  {
    key: "681",
    text: "Wallis and Futuna",
    value: "681",
  },
  {
    key: "682",
    text: "Cook Islands",
    value: "682",
  },
  {
    key: "683",
    text: "Niue",
    value: "683",
  },
  {
    key: "685",
    text: "Samoa",
    value: "685",
  },
  {
    key: "686",
    text: "Kiribati",
    value: "686",
  },
  {
    key: "687",
    text: "New Caledonia",
    value: "687",
  },
  {
    key: "688",
    text: "Tuvalu",
    value: "688",
  },
  {
    key: "689",
    text: "French Polynesia",
    value: "689",
  },
  {
    key: "690",
    text: "Tokelau",
    value: "690",
  },
  {
    key: "691",
    text: "Micronesia, Federated States of Micronesia",
    value: "691",
  },
  {
    key: "692",
    text: "Marshall Islands",
    value: "692",
  },
  {
    key: "850",
    text: "Korea, Democratic People's Republic of Korea",
    value: "850",
  },
  {
    key: "852",
    text: "Hong Kong",
    value: "852",
  },
  {
    key: "853",
    text: "Macao",
    value: "853",
  },
  {
    key: "855",
    text: "Cambodia",
    value: "855",
  },
  {
    key: "856",
    text: "Laos",
    value: "856",
  },
  {
    key: "872",
    text: "Pitcairn",
    value: "872",
  },
  {
    key: "880",
    text: "Bangladesh",
    value: "880",
  },
  {
    key: "886",
    text: "Taiwan",
    value: "886",
  },
  {
    key: "960",
    text: "Maldives",
    value: "960",
  },
  {
    key: "961",
    text: "Lebanon",
    value: "961",
  },
  {
    key: "962",
    text: "Jordan",
    value: "962",
  },
  {
    key: "963",
    text: "Syrian Arab Republic",
    value: "963",
  },
  {
    key: "964",
    text: "Iraq",
    value: "964",
  },
  {
    key: "965",
    text: "Kuwait",
    value: "965",
  },
  {
    key: "966",
    text: "Saudi Arabia",
    value: "966",
  },
  {
    key: "967",
    text: "Yemen",
    value: "967",
  },
  {
    key: "968",
    text: "Oman",
    value: "968",
  },
  {
    key: "970",
    text: "Palestinian Territory, Occupied",
    value: "970",
  },
  {
    key: "971",
    text: "United Arab Emirates",
    value: "971",
  },
  {
    key: "972",
    text: "Israel",
    value: "972",
  },
  {
    key: "973",
    text: "Bahrain",
    value: "973",
  },
  {
    key: "974",
    text: "Qatar",
    value: "974",
  },
  {
    key: "975",
    text: "Bhutan",
    value: "975",
  },
  {
    key: "976",
    text: "Mongolia",
    value: "976",
  },
  {
    key: "977",
    text: "Nepal",
    value: "977",
  },
  {
    key: "992",
    text: "Tajikistan",
    value: "992",
  },
  {
    key: "993",
    text: "Turkmenistan",
    value: "993",
  },
  {
    key: "994",
    text: "Azerbaijan",
    value: "994",
  },
  {
    key: "995",
    text: "Georgia",
    value: "995",
  },
  {
    key: "996",
    text: "Kyrgyzstan",
    value: "996",
  },
  {
    key: "998",
    text: "Uzbekistan",
    value: "998",
  },
  {
    key: "1242",
    text: "Bahamas",
    value: "1242",
  },
  {
    key: "1246",
    text: "Barbados",
    value: "1246",
  },
  {
    key: "1264",
    text: "Anguilla",
    value: "1264",
  },
  {
    key: "1268",
    text: "Antigua and Barbuda",
    value: "1268",
  },
  {
    key: "1284",
    text: "Virgin Islands, British",
    value: "1284",
  },
  {
    key: "1340",
    text: "Virgin Islands, U.S.",
    value: "1340",
  },
  {
    key: "1441",
    text: "Bermuda",
    value: "1441",
  },
  {
    key: "1473",
    text: "Grenada",
    value: "1473",
  },
  {
    key: "1649",
    text: "Turks and Caicos Islands",
    value: "1649",
  },
  {
    key: "1664",
    text: "Montserrat",
    value: "1664",
  },
  {
    key: "1670",
    text: "Northern Mariana Islands",
    value: "1670",
  },
  {
    key: "1671",
    text: "Guam",
    value: "1671",
  },
  {
    key: "1684",
    text: "AmericanSamoa",
    value: "1684",
  },
  {
    key: "1758",
    text: "Saint Lucia",
    value: "1758",
  },
  {
    key: "1767",
    text: "Dominica",
    value: "1767",
  },
  {
    key: "1784",
    text: "Saint Vincent and the Grenadines",
    value: "1784",
  },
  {
    key: "1849",
    text: "Dominican Republic",
    value: "1849",
  },
  {
    key: "1868",
    text: "Trinidad and Tobago",
    value: "1868",
  },
  {
    key: "1869",
    text: "Saint Kitts and Nevis",
    value: "1869",
  },
  {
    key: "1876",
    text: "Jamaica",
    value: "1876",
  },
  {
    key: "1939",
    text: "Puerto Rico",
    value: "1939",
  },
  {
    key: " 345",
    text: "Cayman Islands",
    value: " 345",
  },
];
