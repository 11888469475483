import UserReviewTable from "./Components/UserReviewTable";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { deskimoUserReviewsThunk } from "../../thunks/PropertyThunks";
import { useGetAllQuery, useSetQuery } from "../../../../utils/custom-hooks";

const DeskimoPropertiesUserReviews = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    page: 1,
    sort_by: "",
    order_by: "ASC",
    services_ids: [],
  });
  const [loading, setLoading] = useState(false);

  const [addParamsAlready, setAddParamsAlready] = useState<boolean>(false);
  const getAllQuery = useGetAllQuery();
  const setQuery = useSetQuery();

  useEffect(() => {
    try {
      if (!Object.keys(getAllQuery).length) {
        return;
      }

      setFilters(getAllQuery);
    } finally {
      setAddParamsAlready(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!addParamsAlready) {
          return;
        }

        setLoading(true);

        setQuery(filters);
        await dispatch<any>(deskimoUserReviewsThunk(filters));
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [filters, addParamsAlready]);

  return (
    <UserReviewTable
      filters={filters}
      setFilters={setFilters}
      loading={loading}
    />
  );
};

export default DeskimoPropertiesUserReviews;
