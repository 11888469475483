import React, { useEffect, useState } from "react";
import {
  acceptPendingInviteService,
  fetchPendingInviteService,
  rejectPendingInviteService,
} from "../../services/DashboardServices";
import { SWAL_CUSTOM_CONTENT } from "../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../components/SweetAlert";
import { toastError, toastSuccess } from "../../../../components/Toast";
import { userCompanyThunk } from "../../../User/thunks/UserThunks";
import { useDispatch } from "react-redux";

const PendingInvitePopup = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    _fetchPendingInvite();
  }, []);

  const _fetchPendingInvite = async () => {
    try {
      const response = await fetchPendingInviteService();
      if (response?.status?.code !== 200) {
        return;
      }

      if (!response?.data?.hash_token) {
        return;
      }

      await _handlePendingInviteAction(response?.data);
    } catch (error: any) {
      toastError(error?.status?.message);
    }
  };

  const _handlePendingInviteAction = async (inviteData: any) => {
    const content = (
      <div className={"pending-invite-alert"}>
        <div className={"pending-invite-alert-title"}>Pending Invitation</div>
        <div className={"pending-invite-alert-content"}>
          You have been invited to {inviteData?.company_name} by{" "}
          {inviteData?.company_admin}
        </div>
      </div>
    );
    const options = SWAL_CUSTOM_CONTENT({
      content,
      confirmButtonText: "Accept",
      denyButtonText: "Reject",
      confirmButtonClass:
        "ml-0 primary button ui " + (loading ? " disabled" : ""),
      cancelButtonClass: "danger-alt button ui",
      denyButtonClass: "close-cta button ui",
      wrapperClass: "pending-invite-alert-wrapper",
      reverseButtons: false,
      showDenyButton: true,
      showCancelButton: false,
      showCloseButton: true,
    });
    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setLoading(true);
        try {
          await acceptPendingInviteService(inviteData?.hash_token);
          await dispatch<any>(userCompanyThunk());
          toastSuccess("You have accepted the invitation successfully");
        } catch (error: any) {
          toastError(error?.status?.message);
        } finally {
          setLoading(false);
        }
      },
      cancelCallback: () => null,
      denyCallback: async () => {
        setLoading(true);
        try {
          await rejectPendingInviteService(inviteData?.hash_token);
          toastSuccess("You have rejected the invitation successfully");
        } catch (error: any) {
          toastError(error?.status?.message);
        } finally {
          setLoading(false);
        }
      },
    });
  };

  return <></>;
};

export default PendingInvitePopup;
