import React, { useContext, useState, useEffect } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import { isModalAddEmployeeSelector } from "../../../../../App/selectors/AppSelectors";
import { DIALING_CODE } from "../../../../../App/configs/PhonePrefix";
import {
  employeePropertyInviteService,
  searchPropertyService,
} from "../../../../services/EmployeeServices";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { employeesThunk } from "../../../../thunks/EmployeeThunks";
import { modalAddEmployeeAction } from "../../../../../App/actions/AppActions";
import { Accordion, Dropdown, Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { Formik } from "formik";
import * as yup from "yup";
import Label from "../../../../../../components/Label";
import SizeBox from "../../../../../../components/SizeBox";
import InputCustom from "../../../../../../components/Input";
import BoxToggleSlider from "../../../../../../components/BoxToggleSlider";
import MakeAdminLabelGuide from "../../../../../App/views/ModalAddEmployee/MakeAdminLableGuide";
import { PROPERTY } from "../../../../../Dashboard/configs/Constants";
import InputPhoneFlag from "../../../../../../components/InputPhoneFlag";
import ModalRightSideBarTemplate from "../../../../../App/views/ModalRightSideBarTemplate";
import "./style.scss";
import { userCompanyChooseSelector } from "../../../../selectors/UserSelectors";
import { getCountryISO2 } from "../../../../../../services/CountryService";

const AddEmployeePropertyModal = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const isModalAddEmployee = useSelector(isModalAddEmployeeSelector);
  const [activeIndex, setActiveIndex] = useState([0, 1]);
  const [listPropertyAssigned, setListPropertyAssigned] = useState<any>([]);
  const [searchText, setSearchText] = useState("");
  const [listPropertySearch, setListPropertySearch] = useState([]);
  const [propertyWillAdd, setPropertyWillAdd] = useState<any>(null);
  const [tmpProperty, setTmpProperty] = useState("");
  const userCompanyChoose = useSelector(userCompanyChooseSelector);
  let marketCode = userCompanyChoose?.market_code;

  if (marketCode?.length > 2) {
    marketCode = getCountryISO2("MYS");
  }

  useEffect(() => {
    _fetchListProperty();
  }, [searchText]);

  const _fetchListProperty = async () => {
    try {
      const response = await searchPropertyService({ keyword: searchText });
      if (response?.data && response?.data?.length > 0) {
        setListPropertySearch(
          response?.data.map((property: any) => {
            return {
              key: property?.id,
              text: property?.text,
              value: property?.id,
            };
          })
        );
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const _onClickSave = async (values: any, { resetForm }: any) => {
    try {
      const payload = { ...values };
      payload.properties = listPropertyAssigned.map(
        (property: any) => property.key
      );
      payload.phone_prefix = DIALING_CODE.filter(
        (country) =>
          country.key.trim().toLowerCase() ===
          values.phone_prefix.trim().toLowerCase()
      )[0].value.replaceAll("+", "");

      await employeePropertyInviteService(payload);
      toastSuccess(translations.employeeAddedSuccessfully);
      await dispatch<any>(employeesThunk(PROPERTY));
      resetForm();
      _onCloseModal();
    } catch (e: any) {
      console.log("e", e);
      toastError(e?.status?.message);
    }
  };

  const _onCloseModal = () => {
    dispatch(modalAddEmployeeAction(false));
  };

  const handleClick = (e: any, title: any) => {
    const index = title.index;
    if (activeIndex.includes(index)) {
      setActiveIndex(activeIndex.filter((item) => item !== index));
    } else {
      setActiveIndex([...activeIndex, index]);
    }
  };

  return (
    <ModalRightSideBarTemplate
      modalClassName={"modal-right-sidebar-v2"}
      trigger={null}
      onClose={_onCloseModal}
      open={isModalAddEmployee}
    >
      <Modal.Header>
        {translations.addUser}
        <ButtonCustom
          onClick={_onCloseModal}
          className={"m-0 p-0 none-bg close-modal"}
        >
          <>
            <span>{translations.close}</span>
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
          phone_number: "",
          phone_prefix: marketCode || "SG",
          make_admin: false,
        }}
        validationSchema={yup.object().shape({
          first_name: yup.string().required().label("First Name"),
          email: yup.string().email().required().label("Email"),
        })}
        onSubmit={_onClickSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <>
            <Modal.Content className={"scroll"}>
              <form className={"form-layout"}>
                <Accordion
                  className={"accordion-wrapper"}
                  defaultActiveIndex={[0, 1]}
                  exclusive={false}
                  fluid
                >
                  <Accordion.Title
                    className={"accordion-title"}
                    active={activeIndex.includes(0)}
                    index={0}
                    onClick={handleClick}
                  >
                    Basic Information
                    <img
                      className="dropdown icon"
                      src={PUBLIC_URL + "/assets/icons/icon-caret-down.svg"}
                      alt={""}
                    />
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex.includes(0)}>
                    <div className={"form-row"}>
                      <InputCustom
                        labelRequired
                        label={"First Name"}
                        value={values.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.first_name}
                        error={errors.first_name}
                        name={"first_name"}
                      />
                      <InputCustom
                        label={translations.lastName}
                        value={values.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.last_name}
                        error={errors.last_name}
                        name={"last_name"}
                      />
                    </div>
                    <InputCustom
                      labelRequired
                      label={translations.emailAddress}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched.email}
                      error={errors.email}
                      name={"email"}
                    />
                    <div className={"mb-16px"} />
                    <InputPhoneFlag
                      label={translations.mobilePhoneNumber}
                      labelGuide={translations.addPropertyMobilePhoneLabelGuide}
                      selectedFlag={values.phone_prefix}
                      onSelectFlag={(code) =>
                        setFieldValue("phone_prefix", code)
                      }
                      error={errors.phone_number}
                      touched={touched.phone_number}
                      phoneChange={(value) =>
                        setFieldValue("phone_number", value)
                      }
                      phoneValue={values.phone_number}
                    />
                  </Accordion.Content>
                  <BoxToggleSlider
                    content={
                      <Label
                        fontSize={"14px"}
                        size={"16px"}
                        label={translations.makeAdmin}
                        labelGuide={<MakeAdminLabelGuide />}
                      />
                    }
                    value={values.make_admin}
                    onChange={() =>
                      setFieldValue("make_admin", !values.make_admin)
                    }
                  />
                  <SizeBox height={"16px"} />
                  <>
                    <Accordion.Title
                      className={"accordion-title"}
                      active={activeIndex.includes(1)}
                      index={1}
                      onClick={handleClick}
                    >
                      Property
                      <img
                        className="dropdown icon"
                        src={PUBLIC_URL + "/assets/icons/icon-caret-down.svg"}
                        alt={""}
                      />
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex.includes(1)}>
                      <div
                        className={"d-flex w-100"}
                        style={{ marginBottom: 16 }}
                      >
                        <Dropdown
                          onSearchChange={(event, data) =>
                            setSearchText(data.searchQuery)
                          }
                          onChange={(event, data: any) => {
                            setTmpProperty(data?.value);
                            const isAssigned =
                              listPropertyAssigned.filter(
                                (property: any) =>
                                  property?.value === data?.value
                              ).length > 0;
                            if (!isAssigned) {
                              setPropertyWillAdd(
                                listPropertySearch.filter(
                                  (property: any) =>
                                    property?.value === data.value
                                )[0]
                              );
                            }
                          }}
                          value={tmpProperty}
                          className={"dropdown-search-property"}
                          placeholder="Search property name..."
                          search
                          icon={"search"}
                          selection
                          options={listPropertySearch}
                        />
                        <SizeBox width={"16px"} />
                        <ButtonCustom
                          height={"42px"}
                          type={"button"}
                          onClick={() => {
                            setSearchText("");
                            setTmpProperty("");
                            if (propertyWillAdd) {
                              setListPropertyAssigned([
                                ...listPropertyAssigned,
                                propertyWillAdd,
                              ]);
                            }
                            setPropertyWillAdd(null);
                          }}
                          className={
                            "text-nowrap add-property-cta " +
                            (tmpProperty ? " primary" : "")
                          }
                        >
                          Add Property
                        </ButtonCustom>
                      </div>
                      <div>
                        {listPropertyAssigned.length > 0
                          ? listPropertyAssigned.map(
                              (property: any, index: number) => {
                                if (!property?.text) {
                                  return null;
                                }

                                return (
                                  <div
                                    key={index}
                                    className={"property-assigned"}
                                  >
                                    {property?.text}{" "}
                                    <ButtonCustom
                                      type={"button"}
                                      onClick={() => {
                                        setListPropertyAssigned(
                                          (prevState: any) => {
                                            let newState = JSON.parse(
                                              JSON.stringify(prevState)
                                            );
                                            newState = newState.filter(
                                              (item: any) => {
                                                return (
                                                  item?.key != property?.key
                                                );
                                              }
                                            );
                                            return newState;
                                          }
                                        );
                                      }}
                                      className={"none-bg"}
                                    >
                                      <i className="uil uil-times" />
                                    </ButtonCustom>
                                  </div>
                                );
                              }
                            )
                          : null}
                      </div>
                    </Accordion.Content>
                  </>
                </Accordion>
              </form>
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom height={"47px"} onClick={_onCloseModal}>
                {translations.cancel}
              </ButtonCustom>
              <ButtonCustom
                loading={isSubmitting}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {translations.add}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalRightSideBarTemplate>
  );
};

export default AddEmployeePropertyModal;
