import { Routes, Route } from "react-router-dom";
import {
  BUSINESS_COMPANY_CREATE_LINK,
  BUSINESS_COMPANY_DETAIL_LINK,
  BUSINESS_COMPANY_EDIT_LINK,
  BUSINESS_COMPANY_INVOICE_DETAIL_LINK,
  BUSINESS_COMPANY_INVOICES_LINK,
  BUSINESS_COMPANY_LIST_LINK,
  BUSINESS_COMPANY_OFFICE_LEASE_LINK,
  BUSINESS_COMPANY_PREPAID_CREDIT_LINK,
  BUSINESS_COMPANY_PURCHASE_HISTORY_LINK,
  BUSINESS_COMPANY_STAFF_LINK,
  BUSINESS_CUSTOM_PACKAGES_LINK,
} from "../../App/configs/ListRoutes";
import BusinessTab from "../views/BusinessTab";
import CompanyDetail from "../views/CompanyDetail";
import CompanyInvoiceDetail from "../views/CompanyInvoices/Components/CompanyInvoiceDetail";
import CompanyEditCreate from "../views/CompanyEditCreate";

const BusinessRoutes = () => {
  return (
    <Routes>
      <Route
        path={BUSINESS_COMPANY_LIST_LINK}
        element={<BusinessTab tabIndex={0} />}
      />
      <Route
        path={BUSINESS_COMPANY_STAFF_LINK}
        element={<BusinessTab tabIndex={1} />}
      />
      <Route
        path={BUSINESS_COMPANY_INVOICES_LINK}
        element={<BusinessTab tabIndex={2} />}
      />
      <Route
        path={BUSINESS_COMPANY_PURCHASE_HISTORY_LINK}
        element={<BusinessTab tabIndex={3} />}
      />
      <Route
        path={BUSINESS_COMPANY_PREPAID_CREDIT_LINK}
        element={<BusinessTab tabIndex={4} />}
      />
      <Route
        path={BUSINESS_COMPANY_OFFICE_LEASE_LINK}
        element={<BusinessTab tabIndex={5} />}
      />
      <Route
        path={BUSINESS_CUSTOM_PACKAGES_LINK}
        element={<BusinessTab tabIndex={6} />}
      />
      <Route path={BUSINESS_COMPANY_DETAIL_LINK} element={<CompanyDetail />} />
      <Route
        path={BUSINESS_COMPANY_EDIT_LINK}
        element={<CompanyEditCreate />}
      />
      <Route
        path={BUSINESS_COMPANY_CREATE_LINK}
        element={<CompanyEditCreate />}
      />
      <Route
        path={BUSINESS_COMPANY_INVOICE_DETAIL_LINK}
        element={<CompanyInvoiceDetail />}
      />
    </Routes>
  );
};

export default BusinessRoutes;
