import React, { useContext, useEffect, useState } from "react";
import { getCompanyByKeywordService } from "../../modules/Business/services/BusinessServices";
import { debounce } from "lodash";
import DropdownSearchAndPaging from "../DropdownSearchAndPaging";
import { LocalizationContext } from "../../locales/Translation";

type Props = {
  companyId: number;
  onChange: (value: number | string) => void;
  marketCodes: string[];
  type?: 0 | 1 | 2; // 0: All company type | 1: Property company | 2: Business company;
  showDeletedCompany?: boolean;
};
const CompanyFilter = ({
  companyId,
  onChange,
  marketCodes = [],
  type = 0,
  showDeletedCompany = false,
}: Props) => {
  const { translations } = useContext(LocalizationContext);
  const [loading, setLoading] = useState(false);

  const [listCompanyRaw, setListCompanyRaw] = useState<any>([]);
  const [selectedCompany, setSelectedCompany] = useState(companyId || 0);
  const [companyFilter, setCompanyFilter] = useState<any>({
    keyword: "",
    show_deleted: showDeletedCompany,
    page: "1",
    market_codes: "",
    type,
  });

  useEffect(() => {
    setCompanyFilter((prevState: any) => ({
      ...prevState,
      page: "1",
      market_codes: marketCodes.join(","),
    }));
  }, [marketCodes]);

  useEffect(() => {
    if (!companyFilter?.market_codes?.length) {
      return;
    }
    _getListCompanyByKeyword();
  }, [
    companyFilter.show_deleted,
    companyFilter.keyword,
    companyFilter.market_codes,
  ]);

  useEffect(() => {
    if (companyFilter.page > 1) {
      _getListCompanyByKeyword(true);
    }
  }, [companyFilter.page]);

  const _getListCompanyByKeyword = async (append = false) => {
    setLoading(true);
    const { data } = await getCompanyByKeywordService(companyFilter);
    if (append) {
      setListCompanyRaw({
        items: [...listCompanyRaw.items, ...data.items],
        pagination: data.pagination,
      });
    } else {
      setListCompanyRaw(data);
    }
    setLoading(false);
  };

  const _fetchNextPage = () => {
    const nextPage = listCompanyRaw?.pagination?.next_page;

    if (nextPage) {
      setCompanyFilter((prevState: any) => ({
        ...prevState,
        page: String(nextPage),
      }));
    }
  };

  const decorateListCompany = () => {
    if (listCompanyRaw?.items?.length > 0) {
      const newListCompany = listCompanyRaw?.items?.map((company: any) => {
        return {
          key: company?.id,
          text: company?.text,
          value: company?.id,
        };
      });
      newListCompany.unshift({
        key: 0,
        text: translations.companyInvoices.allCompaniesPlaceholder,
        value: 0,
      });
      return newListCompany;
    } else {
      return [];
    }
  };

  const _handleChangeCompanyFilter = (value: number) => {
    setSelectedCompany(value);
    onChange(value);
  };

  return (
    <DropdownSearchAndPaging
      listCompanyRaw={listCompanyRaw}
      onOpen={() => {
        setCompanyFilter((prevState: any) => ({
          ...prevState,
          page: "1",
        }));
      }}
      onChange={(event: any, data: any) => {
        _handleChangeCompanyFilter(data?.value);
      }}
      onSearchChange={debounce((e, data) => {
        setCompanyFilter({
          ...companyFilter,
          keyword: data?.searchQuery,
          page: 1,
        });
      }, 300)}
      fetchNextPage={_fetchNextPage}
      options={decorateListCompany()}
      value={selectedCompany}
      loading={loading}
    />
  );
};

export default CompanyFilter;
