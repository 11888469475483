import React, { useState, useEffect, useContext } from "react";
import InputCustom from "../../../../../../../components/Input";
import { Formik } from "formik";
import ButtonCustom from "../../../../../../../components/Button";
import { useParams } from "react-router-dom";
import { updatePropertyService } from "../../../../../services/PropertyServices";
import { getPropertyDetailThunk } from "../../../../../thunks/PropertyThunks";
import { toastError } from "../../../../../../../components/Toast";
import { useDispatch, useSelector } from "react-redux";
import { propertyDetailSelector } from "../../../../../selectors/PropertySelector";
import { LocalizationContext } from "../../../../../../../locales/Translation";
import EditLocationGuideItem from "./EditLocationGuideItem";
import SizeBox from "../../../../../../../components/SizeBox";

const PropertyHowToGetThereEdit = ({
  setEditMode,
  setLoading,
  loading,
}: any) => {
  const { translations } = useContext(LocalizationContext);

  const { id } = useParams();
  const propertyDetail = useSelector(propertyDetailSelector);
  const dispatch = useDispatch();
  const [photo, setPhoto] = useState<any>([]);
  const [locationGuide, setLocationGuide] = useState<any>([]);

  const _handleSave = async (values: any) => {
    const payload = {
      how_to_get_there: values,
      property_id: id,
      location_guide: locationGuide,
    };

    if (locationGuide.length > 0) {
      let isHasTitle = true;
      let isHasImage = true;
      locationGuide.forEach((location: any) => {
        if (location.img_key.length < 1) {
          isHasImage = false;
        }
        if (location.title < 1) {
          isHasTitle = false;
        }
      });

      if (!isHasImage) {
        toastError(
          "The step-by-step guide needs to be filled out by filling in the image"
        );
        return;
      }

      if (!isHasTitle) {
        toastError(
          "The step-by-step guide needs to be filled out by filling in the title"
        );
        return;
      }
    }

    payload.how_to_get_there.more_information_photo = photo;
    try {
      await updatePropertyService(payload);
      await dispatch<any>(getPropertyDetailThunk(id ? id : ""));
      setEditMode(false);
    } catch (response: any) {
      toastError(response?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPhoto(propertyDetail?.how_to_get_there?.more_information_photo);
    if (
      propertyDetail?.location_guide &&
      propertyDetail?.location_guide.length > 0
    ) {
      const decoratedLocationGuide = propertyDetail.location_guide.map(
        (location: any, index: number) => {
          return {
            id: index + 1,
            title: location.title,
            description: location.description,
            picture: location.picture,
            img_key: location.img_key,
          };
        }
      );
      setLocationGuide(decoratedLocationGuide);
    }
  }, [propertyDetail]);

  return (
    <div>
      <Formik
        initialValues={{
          car_drop_off_point: propertyDetail?.how_to_get_there
            ?.car_drop_off_point
            ? propertyDetail?.how_to_get_there?.car_drop_off_point
            : "",
          nearest_mrt_station: propertyDetail?.how_to_get_there
            ?.nearest_mrt_station
            ? propertyDetail?.how_to_get_there?.nearest_mrt_station
            : "",
          front_endtrance_of_the_space: propertyDetail?.how_to_get_there
            ?.front_endtrance_of_the_space
            ? propertyDetail?.how_to_get_there?.front_endtrance_of_the_space
            : "",
          more_information_text: propertyDetail?.how_to_get_there
            ?.more_information_text
            ? propertyDetail?.how_to_get_there?.more_information_text
            : "",
          lat: 0,
          lng: 0,
          location_guide: [],
        }}
        onSubmit={_handleSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <>
            <form className={"form-layout"} style={{ width: "50%" }}>
              <InputCustom
                label={translations.carDropOffPoint}
                value={values.car_drop_off_point}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.car_drop_off_point}
                error={errors.car_drop_off_point}
                name={"car_drop_off_point"}
              />
              <InputCustom
                label={translations.nearestMRTStation}
                value={values.nearest_mrt_station}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.nearest_mrt_station}
                error={errors.nearest_mrt_station}
                name={"nearest_mrt_station"}
              />
              <InputCustom
                label={translations.frontEntranceTheSpace}
                value={values.front_endtrance_of_the_space}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.front_endtrance_of_the_space}
                error={errors.front_endtrance_of_the_space}
                name={"front_endtrance_of_the_space"}
              />
              <div className={"edit-location-guide-list"}>
                {locationGuide && locationGuide.length > 0
                  ? locationGuide.map((item: any, index: number) => (
                      <EditLocationGuideItem
                        key={index}
                        index={index}
                        item={item}
                        setLocationGuide={setLocationGuide}
                        locationGuide={locationGuide}
                      />
                    ))
                  : null}
              </div>
              {locationGuide.length > 0 ? (
                <SizeBox height={"13px"} />
              ) : (
                <SizeBox height={"20px"} />
              )}
              <ButtonCustom
                onClick={(e: any) => {
                  e.preventDefault();
                  setLocationGuide((prevState: any) => {
                    const newState = JSON.parse(JSON.stringify(prevState));
                    newState.push({
                      title: "",
                      description: "",
                      picture: "",
                      img_key: "",
                      id: prevState[prevState.length - 1]
                        ? prevState[prevState.length - 1].id + 1
                        : 1,
                    });
                    return newState;
                  });
                }}
                className={"primary-alt w-100 mr-0"}
              >
                <>
                  {locationGuide.length > 0
                    ? "Add additional step"
                    : "Add step by step guide"}
                  <SizeBox width={"12px"} />
                  <i className="uil uil-plus" />
                </>
              </ButtonCustom>
              <div className={"edit-cta-wrapper edit-mode"}>
                <ButtonCustom
                  onClick={() => setEditMode(false)}
                  className={"outline-primary"}
                  fontSize={"12px"}
                  height={"37px"}
                >
                  <>{translations.cancel}</>
                </ButtonCustom>
                <ButtonCustom
                  loading={loading}
                  onClick={handleSubmit}
                  className={"primary-alt"}
                  fontSize={"12px"}
                  height={"37px"}
                >
                  <>{translations.save}</>
                </ButtonCustom>
              </div>
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default PropertyHowToGetThereEdit;
