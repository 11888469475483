import React, { useContext, useState } from "react";
import Label from "../../../../../../components/Label";
import { useDropzone } from "react-dropzone";
import {
  DROPZONE_MAX_FILE_3MB,
  DROPZONE_MAX_FILE_3MB_TEXT,
} from "../../../../../App/configs/DropzoneConfig";
import { toastError } from "../../../../../../components/Toast";
import { LocalizationContext } from "../../../../../../locales/Translation";
import SizeBox from "../../../../../../components/SizeBox";
import { uploadAssetService } from "../../../../services/BusinessServices";
import { FormikValues } from "formik";

type Props = {
  values: FormikValues;
  setFieldValue: any;
};

const UpdateContract = ({ setFieldValue, values }: Props) => {
  const { translations } = useContext(LocalizationContext);

  const [isDragOver, setIsDragOver] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/pdf",
    maxSize: DROPZONE_MAX_FILE_3MB,
    useFsAccessApi: false,
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles?.length > 0) {
        const formData = new FormData();
        formData.append("file", acceptedFiles[0]);
        try {
          const { data } = await uploadAssetService(formData);

          setFieldValue("file_key", data?.file_key);
          setFieldValue("file_url", data?.file_url);
        } catch (error) {
          console.log("Error: ", error);
        }

        setIsDragOver(false);
      }
    },

    onDropRejected: (fileRejections) => {
      let isFileTooLarge;
      fileRejections.forEach((file) => {
        isFileTooLarge =
          file.errors.filter((error) => error.code === "file-too-large")
            .length > 0;
        if (isFileTooLarge) {
          toastError(
            `File ${file.file.name} is larger than ${DROPZONE_MAX_FILE_3MB_TEXT}`
          );
        }
      });
    },

    onDragOver: () => {
      setIsDragOver(true);
    },

    onDragLeave: () => {
      setIsDragOver(false);
    },
  });

  return (
    <>
      <Label label={"Upload contract (optional)"} />
      <SizeBox height={"16px"} />
      <div
        {...getRootProps({
          className: "dropzone dropzone-box" + (isDragOver ? " drag-over" : ""),
        })}
      >
        <input {...getInputProps()} />
        <div className={"dropzone-guide-title"} style={{ fontSize: 14 }}>
          {translations.dropYourImageFilesHereOr}
        </div>
        <div className={"dropzone-guide-subtitle"} style={{ fontSize: 12 }}>
          PDF {translations.maxFileSizeOf} 3MB
        </div>
        <div className={"dropzone-guide-btn"}>Browse Files</div>
      </div>
      {values.file_url ? (
        <a href={values.file_url} target={"_blank"} rel="noreferrer">
          <i className="uil uil-download-alt"></i> Download link here.
        </a>
      ) : null}
      <SizeBox height={"16px"} />
    </>
  );
};

export default UpdateContract;
