//Billing Tab
export const PAYMENT_HISTORY_ACTION = "PAYMENT_HISTORY_ACTION";
export const INVOICE_DETAIL_ACTION = "INVOICE_DETAIL_ACTION";
export const MONTHLY_USAGE_ACTION = "MONTHLY_USAGE_ACTION";
export const PAYMENT_METHOD_ACTION = "PAYMENT_METHOD_ACTION";
export const BILLING_CONTACT_ACTION = "BILLING_CONTACT_ACTION";
export const BILLING_INFO_ACTION = "BILLING_INFO_ACTION";
export const PAYMENT_HISTORY_FILTERS_ACTION = "PAYMENT_HISTORY_FILTERS_ACTION";
export const INTEGRATION_LIST_ACTION = "INTEGRATION_LIST_ACTION";
export const INTEGRATION_UPDATE_ACTION = "INTEGRATION_UPDATE_ACTION";

//Prepaid Credit Tab
export const PREPAID_CREDIT_TRANSACTION_ACTION =
  "PREPAID_CREDIT_TRANSACTION_ACTION";
export const PREPAID_CREDIT_USAGE_ACTION = "PREPAID_CREDIT_USAGE_ACTION";

export const MODAL_UPDATE_PAYMENT_METHOD_ACTION =
  "MODAL_UPDATE_PAYMENT_METHOD_ACTION";
export const MODAL_ADD_CARD_ACTION = "MODAL_ADD_CARD_ACTION";
export const MODAL_BILLING_CONTACT_ACTION = "MODAL_BILLING_CONTACT_ACTION";
export const MODAL_ADD_BILLING_CONTACT_ACTION =
  "MODAL_ADD_BILLING_CONTACT_ACTION";

//Payment Method
export const CREATE_PAYMENT_METHOD_ACTION = "CREATE_PAYMENT_METHOD_ACTION";

//Billing Information
export const MODAL_BILLING_INFORMATION_ACTION =
  "MODAL_BILLING_INFORMATION_ACTION";

//Settings
export const GET_SETTINGS_ACTION = "GET_SETTINGS_ACTION";
export const GET_PROPERTIES_ACTION = "GET_PROPERTIES_ACTION";
export const AFFILIATE_LIST_ACTION = "AFFILIATE_LIST_ACTION";
