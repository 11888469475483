import React, { useContext, useEffect, useState } from "react";
import TableCustom from "../../../../../components/Table";
import { LocalizationContext } from "../../../../../locales/Translation";
import {
  dashboardDeskUsageFilterSelector,
  dashboardDeskUsageSelector,
  dashboardFilterDateRangeSelector,
  dashboardPropertyChooseSelector,
  filterListPropertySelectedSelector,
} from "../../../selectors/DashboardSelectors";
import { useDispatch, useSelector } from "react-redux";
import { DeskUsagePropertyDataType } from "../../../configs/DeclareTypes";
import { dashboardDeskUsageThunk } from "../../../thunks/DashboardThunks";
import { dashboardDeskUsageFilterAction } from "../../../actions/DashboardActions";
import { userCompanyChooseSelector } from "../../../../User/selectors/UserSelectors";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../../App/configs/Constants";
import { Label as LabelSemantic } from "semantic-ui-react";
import { PROPERTY } from "../../../configs/Constants";
import DeskUsageAction from "./components/DeskUsageAction";
import { usePermissionCheck } from "../../../../../utils/custom-hooks";
import { UI_ACCESS_TRANSACTION_BY_FILTER_PER } from "../../../../../utils/PermissionConstant";

const TableDeskUsage = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const dashboardDeskUsage = useSelector(dashboardDeskUsageSelector);
  const dashboardDeskUsageFilter = useSelector(
    dashboardDeskUsageFilterSelector
  );
  const userCompanyChoose = useSelector(userCompanyChooseSelector);
  const dashboardFilterDateRange = useSelector(
    dashboardFilterDateRangeSelector
  );
  const filterListPropertySelected = useSelector(
    filterListPropertySelectedSelector
  );

  const dashboardPropertyChoose = useSelector(dashboardPropertyChooseSelector);
  const propertyContext = usePermissionCheck(
    UI_ACCESS_TRANSACTION_BY_FILTER_PER
  );

  useEffect(() => {
    const interval = setInterval(_init, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (propertyContext) {
    useEffect(() => {
      (async () => {
        if (dashboardPropertyChoose === null) {
          return;
        }
        await _init();
      })();
    }, [
      dashboardDeskUsageFilter,
      userCompanyChoose,
      dashboardFilterDateRange,
      dashboardPropertyChoose,
      filterListPropertySelected,
    ]);
  } else {
    useEffect(() => {
      (async () => {
        await _init();
      })();
    }, [
      dashboardDeskUsageFilter,
      userCompanyChoose,
      dashboardFilterDateRange,
      filterListPropertySelected,
    ]);
  }

  const _init = async () => {
    try {
      if (dashboardFilterDateRange === null) {
        return;
      }
      setLoading(true);
      await dispatch<any>(dashboardDeskUsageThunk(PROPERTY, propertyContext));
    } catch (e) {
      console.log("e", e);
    } finally {
      setLoading(false);
    }
  };

  const UserName = ({ name, isGuest }: { name: string; isGuest: boolean }) => {
    return (
      <div>
        {name}
        {"  "}
        {isGuest && <LabelSemantic size={"mini"}>Guest</LabelSemantic>}
      </div>
    );
  };

  const _formatInputData = () => {
    return dashboardDeskUsage.items.map(
      ({
        transaction_id,
        usage,
        status,
        check_out,
        property_name,
        revenue,
        username,
        check_in,
        visit_id,
      }: DeskUsagePropertyDataType) => {
        return {
          transaction_id,
          property_name,
          employee_name: <UserName name={username} isGuest={false} />,
          check_in_time: check_in ? check_in : "",
          check_out_time: check_out ? check_out : "",
          usage,
          revenue,
          status: translations.deskBookingStatus[status],
          action: <DeskUsageAction statusId={status} visitId={visit_id} />,
        };
      }
    );
  };

  const columns = [
    { key: "visit_by_transaction_id", col: translations.transactionID },
    { key: "visit_by_property_name", col: translations.property },
    { key: "visit_by_employee_name", col: translations.userName },
    { key: "visit_by_check_in", col: translations.checkInTime },
    { key: "visit_by_check_out", col: translations.checkOutTime },
    { key: "visit_by_usage", col: translations.usageMin },
    { key: "visit_by_cost", col: translations.revenue },
    { key: "visit_by_status", col: translations.status },
    { key: "visit_by_action", col: translations.action },
  ];

  const _onChangeSortCol = (col: any) => {
    if (dashboardDeskUsageFilter.sort_by !== col.key) {
      dispatch(
        dashboardDeskUsageFilterAction({ key: "sort_by", value: col.key })
      );
      dispatch(
        dashboardDeskUsageFilterAction({ key: "order_by", value: ORDER_BY_ASC })
      );
    } else {
      dispatch(
        dashboardDeskUsageFilterAction({ key: "sort_by", value: col.key })
      );
      if (dashboardDeskUsageFilter.order_by === ORDER_BY_NONE) {
        dispatch(
          dashboardDeskUsageFilterAction({
            key: "order_by",
            value: ORDER_BY_ASC,
          })
        );
      }
      if (dashboardDeskUsageFilter.order_by === ORDER_BY_ASC) {
        dispatch(
          dashboardDeskUsageFilterAction({
            key: "order_by",
            value: ORDER_BY_DESC,
          })
        );
      }
      if (dashboardDeskUsageFilter.order_by === ORDER_BY_DESC) {
        dispatch(
          dashboardDeskUsageFilterAction({
            key: "order_by",
            value: ORDER_BY_NONE,
          })
        );
      }
    }
  };

  return (
    <TableCustom
      orderBy={dashboardDeskUsageFilter.order_by}
      sortBy={dashboardDeskUsageFilter.sort_by}
      sortTable={true}
      onChangeSortCol={_onChangeSortCol}
      tableData={dashboardDeskUsage ? _formatInputData() : []}
      columns={columns}
      loading={loading}
      totalItems={dashboardDeskUsage?.pagination?.total}
      currentPage={dashboardDeskUsage?.pagination?.page}
      showTotal
      unit={translations.transactions}
      itemPerPage={dashboardDeskUsage?.pagination?.limit}
      onChangePagination={(e: any, { activePage }: any) => {
        dispatch(
          dashboardDeskUsageFilterAction({ key: "page", value: activePage })
        );
      }}
    />
  );
};

export default TableDeskUsage;
