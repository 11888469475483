import React, { SyntheticEvent, useEffect, useState } from "react";
import DeskimoPropertiesStaffListTable from "./Components/DeskimoPropertiesStaffListTable/DeskimoPropertiesStaffListTable";
import { useDispatch, useSelector } from "react-redux";
import { getDeskimoPropertyStaffThunk } from "../../thunks/PropertyThunks";
import { deskimoPropertyStaffListFilterSelector } from "../../selectors/PropertySelector";
import {
  deskimoPropertyListFilterApplyAllAction,
  deskimoPropertyStaffListFilterAction,
  modalDeskimoWorkspaceStaffListAction,
} from "../../actions/PropertyActions";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../App/configs/Constants";
import ModalAddEditStaff from "./Components/ModalAddEditStaff/index";
import ButtonCustom from "../../../../components/Button";
import { globalSettingSelector } from "../../../App/selectors/AppSelectors";
import { useGetAllQuery, useSetQuery } from "../../../../utils/custom-hooks";
import SearchWithDebounce from "../../../../components/SearchBar4Table/SearchWithDebounce";
import CompanyFilter from "../../../../components/CompanyFilter";

const DeskimoPropertiesStaffList = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const propertyStaffListFilter = useSelector(
    deskimoPropertyStaffListFilterSelector
  );
  const globalSetting = useSelector(globalSettingSelector);

  const [addParamsAlready, setAddParamsAlready] = useState<boolean>(false);
  const getAllQuery = useGetAllQuery();
  const setQuery = useSetQuery();

  useEffect(() => {
    try {
      if (!propertyStaffListFilter.market_codes.length) {
        const temp = globalSetting?.location.map(
          ({ city_code }: { city_code: string }) => city_code
        );

        dispatch(
          deskimoPropertyStaffListFilterAction({
            key: "market_codes",
            value: temp,
          })
        );
      }

      if (!Object.keys(getAllQuery).length) {
        return;
      }
      dispatch(deskimoPropertyListFilterApplyAllAction(getAllQuery));
    } finally {
      setAddParamsAlready(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (
        !propertyStaffListFilter.market_codes.length ||
        propertyStaffListFilter.company_id === null ||
        !addParamsAlready
      ) {
        return;
      }
      setQuery(propertyStaffListFilter);
      await _getPropertyStaffList();
    })();
  }, [addParamsAlready, propertyStaffListFilter]);

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    dispatch(
      deskimoPropertyStaffListFilterAction({ key: "page", value: activePage })
    );
  };

  const _onChangeSortCol = (col: any) => {
    if (propertyStaffListFilter.sort_by !== col.key) {
      dispatch(
        deskimoPropertyStaffListFilterAction({ key: "sort_by", value: col.key })
      );
      dispatch(
        deskimoPropertyStaffListFilterAction({
          key: "order_by",
          value: ORDER_BY_DESC,
        })
      );
    } else {
      dispatch(
        deskimoPropertyStaffListFilterAction({ key: "sort_by", value: col.key })
      );
      if (propertyStaffListFilter.order_by === ORDER_BY_ASC) {
        dispatch(
          deskimoPropertyStaffListFilterAction({
            key: "order_by",
            value: ORDER_BY_NONE,
          })
        );
      } else if (propertyStaffListFilter.order_by === ORDER_BY_NONE) {
        dispatch(
          deskimoPropertyStaffListFilterAction({
            key: "order_by",
            value: ORDER_BY_DESC,
          })
        );
      } else {
        dispatch(
          deskimoPropertyStaffListFilterAction({
            key: "order_by",
            value: ORDER_BY_ASC,
          })
        );
      }
    }
  };

  const _getPropertyStaffList = async () => {
    try {
      setLoading(true);
      await dispatch<any>(
        getDeskimoPropertyStaffThunk(propertyStaffListFilter)
      );
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const _onChangeMarketFilter = (values: string[]) => {
    dispatch(
      deskimoPropertyStaffListFilterAction({
        key: "market_codes",
        value: values,
      })
    );
  };

  return (
    <div>
      <div className={"filter-section"}>
        <div className={"filter-section-left"}>
          <SearchWithDebounce
            initialValue={propertyStaffListFilter?.keyword}
            placeholder={"Search by workspace"}
            onChange={(value) =>
              dispatch(
                deskimoPropertyStaffListFilterAction({ key: "keyword", value })
              )
            }
          />
          <CompanyFilter
            companyId={propertyStaffListFilter.company_id}
            onChange={(value) =>
              dispatch(
                deskimoPropertyStaffListFilterAction({
                  key: "company_id",
                  value: value,
                })
              )
            }
            marketCodes={propertyStaffListFilter.market_codes}
            type={1}
          />
        </div>
        <ButtonCustom
          className={"primary"}
          height={"36px"}
          onClick={() =>
            dispatch(
              modalDeskimoWorkspaceStaffListAction({ open: true, id: null })
            )
          }
        >
          Add Workspace Staff +
        </ButtonCustom>
      </div>
      <DeskimoPropertiesStaffListTable
        loading={loading}
        onChangePagination={_onChangePagination}
        onChangeSortCol={_onChangeSortCol}
        onChangeMarketFilter={_onChangeMarketFilter}
      />
      <ModalAddEditStaff />
    </div>
  );
};

export default DeskimoPropertiesStaffList;
