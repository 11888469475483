import { Route, Routes } from "react-router-dom";
import { MEDIA_FILE_LINK } from "../../App/configs/ListRoutes";
import MediaFileList from "../views/MediaFileList";

const MediaFileRoutes = () => {
  return (
    <Routes>
      <Route path={MEDIA_FILE_LINK} element={<MediaFileList />} />
    </Routes>
  );
};

export default MediaFileRoutes;
