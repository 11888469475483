import React, { createContext, useState } from "react";
import LocalizedStrings from "react-localization";
import { APP_LANGUAGE_CACHE } from "../configs/Constants";
import en from "./en.json";
import id from "./id.json";
import moment from "moment";
import "moment/locale/id";
import "moment/locale/en-sg";

const DEFAULT_LANGUAGE = "en";

const languages = { en, id };

const translations = new LocalizedStrings(languages);

export const LocalizationContext = createContext({
  translations,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAppLanguage: (language: string) => {},
  appLanguage: DEFAULT_LANGUAGE,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  initializeAppLanguage: () => {},
});

export const LocalizationProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [appLanguage, setAppLanguage] = useState(DEFAULT_LANGUAGE);
  const setLanguage = async (language: string) => {
    translations.setLanguage(language);
    setAppLanguage(language);
    moment.locale(language);
    localStorage.setItem(APP_LANGUAGE_CACHE, language);
  };

  const initializeAppLanguage = async () => {
    const currentLanguage =
      localStorage.getItem(APP_LANGUAGE_CACHE) || DEFAULT_LANGUAGE;
    await setLanguage(currentLanguage);
    moment.locale(currentLanguage, {
      week: {
        dow: 1, /// Date offset
      },
    });
  };

  return (
    <LocalizationContext.Provider
      value={{
        translations,
        setAppLanguage: setLanguage,
        appLanguage,
        initializeAppLanguage,
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};
