import { Routes, Route } from "react-router-dom";
import Dashboard from "../views/Dashboard";
import DashboardProperty from "../views/DashboardProperty";
import {
  DASHBOARD_DESKIMO_LINK,
  DASHBOARD_BUSINESS_LINK,
  DASHBOARD_PROPERTY_LINK,
  DASHBOARD_PARTNER_LINK,
} from "../../App/configs/ListRoutes";
import DashboardDeskimo from "../views/DashboardDeskimo";
import DashboardPartner from "../views/DashboardPartner";

const DashboardRoutes = () => {
  return (
    <Routes>
      <Route path={DASHBOARD_BUSINESS_LINK} element={<Dashboard />} />
      <Route path={DASHBOARD_PROPERTY_LINK} element={<DashboardProperty />} />
      <Route path={DASHBOARD_DESKIMO_LINK} element={<DashboardDeskimo />} />
      <Route path={DASHBOARD_PARTNER_LINK} element={<DashboardPartner />} />
    </Routes>
  );
};

export default DashboardRoutes;
