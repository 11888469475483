import ButtonCustom from "../../../../../components/Button";
import React, { useContext, useState } from "react";
import { DeskimoUserCreditType } from "../../../configs/DeclareTypes";
import { LocalizationContext } from "../../../../../locales/Translation";
import DatePickerSingle from "../../../../../components/DatePickerSingle";
import moment from "moment-timezone";
import { toastError, toastSuccess } from "../../../../../components/Toast";
import { extendCreditService } from "../../../services/EmployeeServices";
import { deskimoUserDetailThunk } from "../../../thunks/EmployeeThunks";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { BUSINESS_COMPANY_DETAIL_LINK } from "../../../../App/configs/ListRoutes";

const UserCreditItem = ({ item }: { item: DeskimoUserCreditType }) => {
  const { translations } = useContext(LocalizationContext);
  const { id } = useParams();
  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editDate, setEditDate] = useState<any>(
    new Date(item.expired_date * 1000)
  );

  const submit = async () => {
    setLoading(true);
    try {
      if (!item?.entitlement_id) {
        return;
      }
      const payload = { expired_date: moment(editDate).unix() };
      await extendCreditService(
        payload,
        item?.entitlement_id,
        item?.company_id
      );
      toastSuccess("Update credit expiry date successfully");
      setEditMode(false);
      if (!id) {
        return;
      }
      await dispatch<any>(deskimoUserDetailThunk(id));
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div key={item.company_id} className={"user-company-credit"}>
      <div className={"user-company-credit__line"}>
        <Link
          to={BUSINESS_COMPANY_DETAIL_LINK.replaceAll(
            ":id",
            String(item.company_id)
          )}
        >
          Credit balance for {item.company_name}:
        </Link>
      </div>
      <div className={"user-company-credit__line"}>
        <span className={"middle-dot"}>·</span>
        <span style={{ width: 156, display: "inline-block" }}>
          Remaining credit:{" "}
        </span>{" "}
        {item.free_credit}
      </div>
      <div className={"user-company-credit__line"}>
        <span className={"middle-dot"}>·</span>
        <span style={{ width: 156, display: "inline-block" }}>
          Credit expiry date:{" "}
        </span>
        {editMode ? (
          <div className={"edit-cta-wrapper"}>
            <DatePickerSingle
              oneTap
              value={
                new Date(
                  moment(editDate).tz(item.timezone).get("year"),
                  moment(editDate).tz(item.timezone).get("month"),
                  moment(editDate).tz(item.timezone).get("date")
                )
              }
              onChange={(date: any) => {
                setEditDate(moment(date).tz(item.timezone, true));
              }}
              icon={<i className="uil uil-calender" />}
            />
            <ButtonCustom
              onClick={() => setEditMode(false)}
              className={"default"}
              fontSize={"12px"}
              height={"37px"}
            >
              <>{translations.cancel}</>
            </ButtonCustom>
            <ButtonCustom
              disabled={loading}
              loading={loading}
              onClick={submit}
              className={"primary"}
              fontSize={"12px"}
              height={"37px"}
            >
              <>{translations.save}</>
            </ButtonCustom>
          </div>
        ) : (
          <>
            {moment(item?.expired_date * 1000)
              .tz(item?.timezone)
              .format("DD/MM/YYYY")}

            <ButtonCustom
              onClick={() => setEditMode(true)}
              className={"edit-link reverse"}
              padding={"0"}
              fontSize={"16px"}
            >
              <i className="uil uil-edit" />
            </ButtonCustom>
          </>
        )}
      </div>
    </div>
  );
};

export default UserCreditItem;
