import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import {
  campaignDetailAction,
  campaignListAction,
} from "../actions/MarketingActions";
import {
  campaignDetailService,
  campaignListService,
} from "../services/MarketingServices";

export const campaignListThunk = (payload: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await campaignListService(payload);
    dispatch<any>(campaignListAction(data));
  };
};

export const campaignDetailThunk = (id: string) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await campaignDetailService(id);
    dispatch<any>(campaignDetailAction(data));
  };
};
