import UserRowItem from "../../../../../../../../components/UserRowItem";
import ButtonCustom from "../../../../../../../../components/Button";
import { useDispatch } from "react-redux";
import { modalAddBillingContactAction } from "../../../../../../actions/BiilingActions";
import Label from "../../../../../../../../components/Label";
import { useContext } from "react";
import { LocalizationContext } from "../../../../../../../../locales/Translation";

type Props = {
  openBillingContact?: any;
  billingContact?: any;
};

const BillingContact = ({ openBillingContact, billingContact }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  return (
    <div className="billing-contact">
      <div className={"billing-section-header"}>
        <Label
          label={translations.settings.billingContacts}
          labelGuide={translations.settings.billingContactsTooltip}
        />
        <ButtonCustom
          onClick={openBillingContact}
          className={"link edit-link p-0"}
        >
          <span>{translations.settings.edit}</span>
          <i className="uil uil-edit" />
        </ButtonCustom>
      </div>
      {billingContact && billingContact.length > 0 ? (
        <div className="desk">
          <div className="contact-list user-reserved-list d-block w-100 scroll">
            {billingContact.map((contact: any, index: number) => (
              <UserRowItem
                key={index}
                name={contact?.full_name}
                title={contact?.email}
                size={"32px"}
                capName={contact?.capName}
              />
            ))}
          </div>
        </div>
      ) : (
        <ButtonCustom
          height={"36px"}
          className={"outline-primary w-100"}
          onClick={() => dispatch(modalAddBillingContactAction(true))}
        >
          {translations.settings.addBillingContact}
        </ButtonCustom>
      )}
    </div>
  );
};

export default BillingContact;
