import React, { useContext, useState } from "react";
import "react-medium-image-zoom/dist/styles.css";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../components/Button";
import { Carousel } from "react-responsive-carousel";
import { LocalizationContext } from "../../../../../locales/Translation";
import Slider from "react-slick";

type Props = {
  thumbnail: string[];
};

const Thumbnail = ({ thumbnail }: Props) => {
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);
  const { translations } = useContext(LocalizationContext);

  return (
    <>
      <Slider
        className={
          "private-office-carousel " +
          (thumbnail?.length < 3 ? " no-slider " : "")
        }
        slidesToShow={thumbnail?.length >= 3 ? 1 : thumbnail?.length}
        slidesToScroll={1}
        infinite={thumbnail?.length >= 3}
        centerMode={thumbnail?.length >= 3}
        centerPadding={window.screen.width > 767 ? "40px" : "4px"}
        variableWidth={window.screen.width > 767}
        dots
      >
        {thumbnail.slice(0, 5).map((src, index) => {
          return (
            <div key={index} className={"private-office-carousel__item"}>
              <img
                className={"private-office-carousel__image"}
                onClick={() => {
                  setSelectedItem(index);
                  setOpen(true);
                }}
                src={src}
                alt={src}
              />
            </div>
          );
        })}
      </Slider>
      <Modal
        className={"modal-carousel-images-wrapper"}
        dimmer
        size={"large"}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Modal.Header>
          <span>Gallery</span>
          <ButtonCustom
            height={"22px"}
            onClick={() => setOpen(false)}
            className={"modal-close-cta"}
          >
            <>
              <span>{translations.close}</span>
              <i className="uil uil-times-circle"></i>
            </>
          </ButtonCustom>
        </Modal.Header>
        <Modal.Content>
          <Carousel
            className={"modal-carousel-images"}
            showArrows
            selectedItem={selectedItem}
          >
            {thumbnail.map((src) => {
              return (
                <div key={src}>
                  <img src={src} alt={src} />
                </div>
              );
            })}
          </Carousel>
        </Modal.Content>
        <Modal.Actions>
          <ButtonCustom negative onClick={() => setOpen(false)}>
            Close
          </ButtonCustom>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default Thumbnail;
