import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import Radio from "../Radio";
import {
  DAY_CUSTOM_TIME,
  DAY_CLOSED,
  DAY_24H,
  FROM_TIME,
  TO_TIME,
} from "../../modules/App/configs/DateTimeConfigs";
import { Dropdown } from "semantic-ui-react";
import { dayOptions } from "../../modules/App/configs/DateTimeConfigs";
import { LocalizationContext } from "../../locales/Translation";

const DayOperation = ({
  label,
  name,
  type,
  className = "",
  fromTime,
  toTime,
  onChangeSchedule,
  openType,
  weekDay,
}: any) => {
  const { translations } = useContext(LocalizationContext);

  // const [minTime, setMinTime] = useState(fromTime);
  // const [maxTime, setMaxTime] = useState(toTime);

  const onChangeTime = (dayObject: any, type: any) => {
    // if (type === FROM_TIME) {
    //   setMinTime(dayObject?.from_time);
    // }
    //
    // if (type === TO_TIME) {
    //   setMaxTime(dayObject?.to_time);
    // }
    onChangeSchedule(dayObject);
  };

  return (
    <div className={className + " day-operation"}>
      <div className={"day-operation__radios"}>
        <span className={"day-operation__day"}>{label}</span>
        <div className={"d-flex"}>
          <Radio
            label={translations.custom}
            name={name}
            value={DAY_CUSTOM_TIME}
            currentValue={openType}
            onChange={(e: any, { value }: any) => {
              onChangeTime(
                { type, week_day: weekDay, open_type: value },
                undefined
              );
            }}
          />
          <Radio
            label={translations.closed}
            name={name}
            value={DAY_CLOSED}
            currentValue={openType}
            onChange={(e: any, { value }: any) => {
              onChangeTime(
                { type, week_day: weekDay, open_type: value },
                undefined
              );
            }}
          />
          <Radio
            label={translations.open24hours}
            name={name}
            value={DAY_24H}
            currentValue={openType}
            onChange={(e: any, { value }: any) => {
              onChangeTime(
                { type, week_day: weekDay, open_type: value },
                undefined
              );
            }}
          />
        </div>
      </div>
      {openType === DAY_CUSTOM_TIME && (
        <div className={"day-operation__select"}>
          <Dropdown
            onChange={(e, { value }) =>
              onChangeTime(
                { from_time: value, type, week_day: weekDay },
                FROM_TIME
              )
            }
            value={fromTime || "08:00"}
            selection
            search
            options={dayOptions({ maxTime: toTime })}
          />
          <div className={"line"} />
          <Dropdown
            onChange={(e, { value }) =>
              onChangeTime({ to_time: value, type, week_day: weekDay }, TO_TIME)
            }
            value={toTime || "18:00"}
            selection
            search
            options={dayOptions({ minTime: fromTime })}
          />
        </div>
      )}
    </div>
  );
};

export default DayOperation;
