import React, { useEffect, useState } from "react";
import { Chart, registerables } from "chart.js";
import UtilDoughnutChart from "../../../Dashboard/ChartSummary/UtilDoughnutChart";
import { getStatisticOngoingService } from "../../../../services/DashboardServices";
import {
  AUTO_REFRESH_INTERVAL_TIME,
  FILTER_DATE_ALL_TIME,
  FILTER_DATE_TODAY,
} from "../../../../configs/Constants";
import { useSelector } from "react-redux";
import {
  dashboardFilterDateRangeSelector,
  dashboardFilterDateTypeSelector,
  filterListPropertySelectedSelector,
} from "../../../../selectors/DashboardSelectors";
import moment from "moment-timezone";

Chart.register(...registerables);

const OngoingVisits = () => {
  const dashboardFilterDateRange = useSelector(
    dashboardFilterDateRangeSelector
  );
  const dashboardFilterDateType = useSelector(dashboardFilterDateTypeSelector);
  const filterListPropertySelected = useSelector(
    filterListPropertySelectedSelector
  );
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (!dashboardFilterDateRange) {
      return;
    }
    _getData();
    const interval = setInterval(_onRefresh, AUTO_REFRESH_INTERVAL_TIME);
    return () => {
      clearInterval(interval);
    };
  }, [dashboardFilterDateRange, filterListPropertySelected]);

  const _getData = async () => {
    try {
      let payload;

      if (dashboardFilterDateType === FILTER_DATE_TODAY) {
        payload = { get_on_going_visit: true };
      } else {
        payload = {
          properties: filterListPropertySelected || [],
          get_on_going_visit: false,
          ...dashboardFilterDateRange,
        };
      }

      const { data } = await getStatisticOngoingService(payload);
      setData(data);
    } catch (e) {
      console.log(e);
    }
  };

  const _onRefresh = async () => {
    if (dashboardFilterDateType !== FILTER_DATE_TODAY) {
      return;
    }

    const payload = {
      get_on_going_visit: true,
      properties: filterListPropertySelected || [],
    };

    const { data } = await getStatisticOngoingService(payload);
    setData(data);
  };

  const _renderTitle = () => {
    const filterSameDay = moment
      .unix(dashboardFilterDateRange?.start_time)
      .isSame(moment.unix(dashboardFilterDateRange?.end_time), "day");

    if (dashboardFilterDateType === FILTER_DATE_TODAY) {
      return (
        <h2 className={"dashboard_chart_title dashboard_chart_title-ongoing"}>
          Ongoing visits
        </h2>
      );
    } else if (dashboardFilterDateType === FILTER_DATE_ALL_TIME) {
      return (
        <h2 className={"dashboard_chart_title dashboard_chart_title-ongoing"}>
          All time visits
        </h2>
      );
    } else if (filterSameDay) {
      return (
        <h2
          className={"dashboard_chart_title dashboard_chart_title-ongoing"}
          dangerouslySetInnerHTML={{
            __html: `Visits on \n ${moment
              .unix(dashboardFilterDateRange?.start_time)
              .format("DD. MMM. YY")}`.replaceAll("\n", "<br />"),
          }}
        ></h2>
      );
    } else {
      return (
        <h2
          className={"dashboard_chart_title dashboard_chart_title-ongoing"}
          dangerouslySetInnerHTML={{
            __html: `Visits between \n${moment
              .unix(dashboardFilterDateRange?.start_time)
              .tz("Asia/Singapore")
              .format("DD. MMM.")} - ${moment
              .unix(dashboardFilterDateRange?.end_time)
              .tz("Asia/Singapore")
              .format("DD. MMM. YY")}`.replaceAll("\n", "<br/>"),
          }}
        ></h2>
      );
    }
  };

  return (
    <div className={"dashboard_chart_box_wrapper"}>
      <div className={"dashboard_chart_box"}>
        {_renderTitle()}
        <div className={"dashboard_chart_inner"}>
          <UtilDoughnutChart
            showAllLabel
            labelWithoutPercent
            color={["#1B3EBC", "#F9BE03", "#3EC79A", "#EE261A", "#31531f"]}
            labels={
              data?.visits
                ? data?.visits.map(
                    ({ market_code, amount }: any) =>
                      `${amount}\n${market_code}`
                  )
                : []
            }
            data={
              data?.visits
                ? data?.visits.map(({ percentage }: any) => percentage)
                : []
            }
          />
        </div>

        {data?.total && dashboardFilterDateType === FILTER_DATE_TODAY ? (
          <div className={"deskimo-dashboard_total_usage"}>
            Active sessions: {data?.total}
          </div>
        ) : data?.total && dashboardFilterDateType !== FILTER_DATE_TODAY ? (
          <div className={"deskimo-dashboard_total_usage"}>
            Total sessions: {data?.total}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default OngoingVisits;
