import React, { useState } from "react";
import ButtonCustom from "../../../../../components/Button";
import ParkingRequestModal from "../ModalRequestParking";
import { requestParkingChangeStatusService } from "../../../services/BookServices";
import { toastSuccess } from "../../../../../components/Toast";
import {
  DESK_BOOKING,
  ROOM_BOOKING,
} from "../../../../Dashboard/configs/Constants";
import { PARKING_REQUEST_STATUS } from "../../../configs/Constants";

type Props = {
  bookingId: number;
  parkingRequest: null | {
    reference: string;
    car_number: string;
    status: {
      key:
        | typeof PARKING_REQUEST_STATUS.PENDING
        | typeof PARKING_REQUEST_STATUS.APPROVED
        | typeof PARKING_REQUEST_STATUS.CANCELLED;
      text: string;
    };
  };
  onRefresh: () => Promise<void>;
  bookingType: typeof DESK_BOOKING | typeof ROOM_BOOKING;
};

const ParkingRequest = ({
  parkingRequest,
  onRefresh,
  bookingId,
  bookingType,
}: Props) => {
  const [isOpenRequest, setIsOpenRequest] = useState(false);
  const [loading, setLoading] = useState(false);

  const _onCancel = async () => {
    try {
      if (!parkingRequest?.reference) {
        return;
      }

      setLoading(true);
      await requestParkingChangeStatusService(
        bookingId,
        parkingRequest?.reference,
        {
          status: PARKING_REQUEST_STATUS.CANCELLED,
          type: bookingType,
        }
      );
      await onRefresh();
      toastSuccess("Cancelled Successfully!");
    } finally {
      setLoading(false);
    }
  };

  if (parkingRequest) {
    return (
      <div className={"modal-booking-success__detail p-0"}>
        <div
          className={"title d-flex justify-content-between align-items-center"}
        >
          Parking Request
          <span
            style={{
              fontSize: 13,
              padding: "4px 8px",
              color: "white",
              backgroundColor:
                parkingRequest.status.key === PARKING_REQUEST_STATUS.APPROVED
                  ? "#16C79A"
                  : "#FFC453",
              borderRadius: 24,
              lineHeight: "normal",
            }}
          >
            {parkingRequest.status.text}
          </span>
        </div>
        <div className="select-item">
          <div className="text">Registered License Number</div>
          <div className="value">{parkingRequest.car_number}</div>
        </div>
        {parkingRequest.status.key === PARKING_REQUEST_STATUS.PENDING ? (
          <>
            <ButtonCustom
              width={"100%"}
              height={"36px"}
              onClick={_onCancel}
              disabled={loading}
              loading={loading}
            >
              Cancel
            </ButtonCustom>
          </>
        ) : null}
      </div>
    );
  }

  return (
    <>
      <div className={"modal-booking-success__detail p-0"}>
        <div className={"title"}>Parking Request</div>
        <ButtonCustom
          width={"100%"}
          className={"primary-alt"}
          height={"36px"}
          onClick={() => setIsOpenRequest(true)}
        >
          I need parking
        </ButtonCustom>
      </div>
      <ParkingRequestModal
        open={isOpenRequest}
        onRefresh={onRefresh}
        onClose={() => setIsOpenRequest(false)}
        bookingId={bookingId}
        bookingType={bookingType}
      />
    </>
  );
};

export default ParkingRequest;
