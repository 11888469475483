import React, { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  businessCompanyCanBuyCreditThunk,
  businessCompanyPostPayThunk,
  getBusinessCompanyListThunk,
} from "../../thunks/BusinessThunks";
import CompanyTable from "./Components/CompanyTable";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../App/configs/Constants";
import ButtonCustom from "../../../../components/Button";
import { Link } from "react-router-dom";
import { BUSINESS_COMPANY_CREATE_LINK } from "../../../App/configs/ListRoutes";
import { globalSettingSelector } from "../../../App/selectors/AppSelectors";
import ModalUpdateAutoTopUp from "./Components/ModalUpdateAutoTopUp";
import { businessCompanyFilterSelector } from "../../selectors/BusinessSelectors";
import { businessCompanyFilterAction } from "../../actions/BusinessActions";
import { useGetAllQuery, useSetQuery } from "../../../../utils/custom-hooks";
import SearchWithDebounce from "../../../../components/SearchBar4Table/SearchWithDebounce";

const CompanyList = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const companyFilter = useSelector(businessCompanyFilterSelector);
  const globalSetting = useSelector(globalSettingSelector);

  const [addParamsAlready, setAddParamsAlready] = useState<boolean>(false);
  const getAllQuery = useGetAllQuery();
  const setQuery = useSetQuery();

  useEffect(() => {
    try {
      if (!companyFilter.market_code.length) {
        dispatch(
          businessCompanyFilterAction({
            market_code: globalSetting?.location.map(
              ({ city_code }: { city_code: string }) => city_code
            ),
          })
        );
      }

      if (!Object.keys(getAllQuery).length) {
        return;
      }
      dispatch(businessCompanyFilterAction(getAllQuery));
    } finally {
      setAddParamsAlready(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!addParamsAlready) {
          return;
        }

        setLoading(true);
        setQuery(companyFilter);
        await dispatch<any>(getBusinessCompanyListThunk(companyFilter));
      } finally {
        setLoading(false);
      }
    })();
  }, [addParamsAlready, companyFilter]);

  const _onChangeSortCol = (col: any) => {
    if (companyFilter.sort_by !== col.key) {
      dispatch(
        businessCompanyFilterAction({
          sort_by: col.key,
          order_by: ORDER_BY_ASC,
        })
      );
    } else {
      if (companyFilter.order_by === ORDER_BY_NONE) {
        dispatch(
          businessCompanyFilterAction({
            sort_by: col.key,
            order_by: ORDER_BY_ASC,
          })
        );
      }
      if (companyFilter.order_by === ORDER_BY_DESC) {
        dispatch(
          businessCompanyFilterAction({
            sort_by: col.key,
            order_by: ORDER_BY_NONE,
          })
        );
      }
      if (companyFilter.order_by === ORDER_BY_ASC) {
        dispatch(
          businessCompanyFilterAction({
            sort_by: col.key,
            order_by: ORDER_BY_DESC,
          })
        );
      }
    }
  };

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    dispatch(
      businessCompanyFilterAction({
        page: activePage,
      })
    );
  };

  const _onChangeVerifyPostPay = async (id: number) => {
    dispatch<any>(businessCompanyPostPayThunk(id));
  };

  const _onRefresh = async () => {
    await dispatch<any>(getBusinessCompanyListThunk(companyFilter));
  };

  const _onChangeMarketFilter = (values: string[]) => {
    dispatch(
      businessCompanyFilterAction({
        market_code: values,
        page: 1,
      })
    );
  };

  if (!addParamsAlready) {
    return null;
  }

  return (
    <>
      <div className={"filter-section"}>
        <SearchWithDebounce
          initialValue={companyFilter?.keyword}
          placeholder={"Search by Company Name"}
          onChange={(value) =>
            dispatch(
              businessCompanyFilterAction({
                page: 1,
                keyword: value,
              })
            )
          }
        />
        <Link to={BUSINESS_COMPANY_CREATE_LINK}>
          <ButtonCustom className={"primary"} height={"36px"}>
            Add Company +
          </ButtonCustom>
        </Link>
      </div>
      <CompanyTable
        onChangeSortCol={_onChangeSortCol}
        onChangePagination={_onChangePagination}
        onChangeVerifyPostPay={_onChangeVerifyPostPay}
        loading={loading}
        onChangeMarketFilter={_onChangeMarketFilter}
        onRefresh={_onRefresh}
      />
      <ModalUpdateAutoTopUp />
    </>
  );
};

export default CompanyList;
