import React from "react";
import { PUBLIC_URL } from "../../configs/Constants";
import { Popup } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { userCompanyChooseSelector } from "../../../User/selectors/UserSelectors";
import { UserCompanyType } from "../../../User/configs/DeclareTypes";

const WhatsAppButton = () => {
  const { whats_app_link }: UserCompanyType = useSelector(
    userCompanyChooseSelector
  );
  return (
    <Popup
      content={"Hello! How can I help you?"}
      trigger={
        <a
          href={whats_app_link}
          target={"_blank"}
          className={"whatsapp-box"}
          rel="noreferrer"
        >
          <img src={PUBLIC_URL + "/logo192.png"} />
        </a>
      }
      style={{ fontWeight: 500, fontSize: 16 }}
    />
  );
};

export default WhatsAppButton;
