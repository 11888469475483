import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import SearchBar4Table from "../../../../../../components/SearchBar4Table";
import { debounce } from "lodash";

type Props = {
  onSearch: any;
  placeholder?: any;
  defaultValue?: any;
};

const SearchUser = ({ onSearch, placeholder, defaultValue }: Props) => {
  const { translations } = useContext(LocalizationContext);
  console.log("defaultValue: ", defaultValue);
  const debounce_onChange = debounce((e, { value }) => {
    onSearch(value);
  }, 500);

  return (
    <SearchBar4Table
      placeholder={
        placeholder ? placeholder : translations.searchPlaceholderEmployee
      }
      onChange={debounce_onChange}
      defaultValue={defaultValue}
    />
  );
};

export default SearchUser;
