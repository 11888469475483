const MediaIcon = ({
  strokeColor = "#21252A",
  isActive = false,
}: {
  strokeColor?: string;
  isActive?: boolean;
}) => {
  return (
    <i
      className="uil uil-folder"
      style={isActive ? { color: "#003DBC" } : { color: strokeColor }}
    ></i>
  );
};

export default MediaIcon;
