import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../../../locales/Translation";
import moment from "moment";
import { FORMAT_TIME_VARIANT_1 } from "../../../../../../App/configs/DateTimeConfigs";

const ScheduleTable = ({ schedule }: any) => {
  const { translations } = useContext(LocalizationContext);

  const scheduleType = (type: any, fromTime: string, toTime: string) => {
    switch (type) {
      case 1:
        return translations.open24hours;
      case 2:
        return `${moment(fromTime, "HH:mm").format(
          FORMAT_TIME_VARIANT_1
        )} - ${moment(toTime, "HH:mm").format(FORMAT_TIME_VARIANT_1)}`;
      case 3:
        return translations.closed;
    }
  };

  return (
    <table className={"schedule-table"}>
      <tbody>
        {schedule?.week_days && schedule?.week_days.length > 0
          ? schedule?.week_days.map((day: any, index: number) => {
              return (
                <tr key={index}>
                  <td className={"text-capitalize"}>{day?.week_day_title}</td>
                  <td>
                    {scheduleType(day?.open_type, day?.from_time, day?.to_time)}
                  </td>
                </tr>
              );
            })
          : null}
      </tbody>
    </table>
  );
};

export default ScheduleTable;
