import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import ModalRightSideBarTemplate from "../../../../../App/views/ModalRightSideBarTemplate";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  billingContactSelector,
  isModalBillingContactSelector,
} from "../../../../selectors/BillingSelector";
import {
  modalAddBillingContactAction,
  modalBillingContactAction,
} from "../../../../actions/BiilingActions";
import SizeBox from "../../../../../../components/SizeBox";
import Divide from "../../../../../../components/Divide";
import UserRowItem from "../../../../../../components/UserRowItem";
import SearchBar4Table from "../../../../../../components/SearchBar4Table";
import { SWAL_DELETE_OPTION } from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import { removeBillingContactService } from "../../../../services/SettingServices";
import { getBillingContactThunk } from "../../../../thunks/BillingThunk";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { LocalizationContext } from "../../../../../../locales/Translation";

const ModalBillingContact = () => {
  const dispatch = useDispatch();
  const isModalBillingContact = useSelector(isModalBillingContactSelector);
  const billingContacts = useSelector(billingContactSelector);
  const [keyword, setKeyword] = useState("");
  const { translations } = useContext(LocalizationContext);

  const _handleDeleteBillingContact = (id: any) => {
    const icon = null;
    const title = translations.settings.removeBillingContactMsg;
    const confirmText = translations.settings.remove;
    const options = SWAL_DELETE_OPTION({
      title,
      icon,
      confirmText,
    });
    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          await removeBillingContactService({
            contact_id: id,
          });
          await dispatch<any>(getBillingContactThunk());
          toastSuccess(translations.settings.removeBillingContactSuccessMsg);
        } catch (response: any) {
          toastError(response?.status?.message);
        }
      },
      cancelCallback: () => null,
    });
  };

  let billingContactHTML = null;
  if (billingContacts && billingContacts.length > 0) {
    const filteredBillingContact = billingContacts.filter((contact: any) => {
      if (contact?.full_name.trim().toLowerCase().includes(keyword)) {
        return contact;
      }
    });
    if (filteredBillingContact && filteredBillingContact.length > 0) {
      billingContactHTML = filteredBillingContact.map(
        (contact: any, index: number) => {
          return (
            <UserRowItem
              key={index}
              name={contact?.full_name}
              title={contact?.email}
              size={"32px"}
              capName={contact?.cap_name}
              isAction={true}
              handleAction={() => _handleDeleteBillingContact(contact?.id)}
              imgUrl={null}
            />
          );
        }
      );
    }
  }

  return (
    <ModalRightSideBarTemplate
      trigger={null}
      onClose={() => dispatch(modalBillingContactAction(false))}
      modalClassName={"modal-edit-employee"}
      open={isModalBillingContact}
    >
      <Modal.Header className={"border-bottom"}>
        <div>
          {translations.settings.billingContacts}
          <SizeBox height={"19px"} />
          <div className={"sub-title"}>
            {translations.settings.billingContactSubtitle}
          </div>
        </div>
        <ButtonCustom
          onClick={() => dispatch(modalBillingContactAction(false))}
          className={"m-0 p-0 none-bg close-modal"}
        >
          <>
            {translations.settings.close}
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
        <Divide type={"horizontal"} />
      </Modal.Header>
      <Modal.Content className={"scroll"}>
        <SearchBar4Table
          placeholder={translations.settings.searchEmployeePlaceholder}
          onChange={(event, { value }) =>
            setKeyword(value.trim().toLowerCase())
          }
        />
        {billingContactHTML}
        <SizeBox height={"60px"} />
        <div className={"d-flex justify-content-end w-100"}>
          <ButtonCustom
            onClick={() => dispatch(modalAddBillingContactAction(true))}
            className={"primary m-0"}
            height={"44px"}
          >
            {translations.settings.addBillingContact}
          </ButtonCustom>
        </div>
      </Modal.Content>
    </ModalRightSideBarTemplate>
  );
};

export default ModalBillingContact;
