import React, { useContext, useState, useEffect } from "react";
import classNames from "classnames";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../components/Button";
import ModalCenterTemplate from "../../../../App/views/ModalCenterTemplate";
import { LocalizationContext } from "../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import {
  filterMarketSelector,
  meetingRoomDataTmpSelector,
  meetingRoomFiltersSelector,
} from "../../../selectors/BookSelectors";
import {
  meetingRoomDataTmpAction,
  modalConfirmBookingAction,
} from "../../../actions/BookActions";
import moment from "moment-timezone";
import {
  dayOptions,
  DURATION_ARR,
} from "../../../../App/configs/DateTimeConfigs";
import DropdownCustom from "../../../../../components/DropdownCustom";
import SizeBox from "../../../../../components/SizeBox";
import { checkAvailableRoomService } from "../../../services/BookServices";
import {
  calculateBookingTime,
  getLocalTimeAndDurationFromAnotherTimezone,
  makeBookingRoomService,
} from "../../../services/Common";
import { toastError } from "../../../../../components/Toast";
import { userCompanyChooseSelector } from "../../../../User/selectors/UserSelectors";
import CheckboxCustom from "../../../../../components/Checkbox";
import DatePickerSingleV2 from "../../../../../components/DatePickerSingleV2";
import { getCreditRemainingThunk } from "../../../../App/thunks/AppThunk";
import BoxAddVoucherForBooking from "../../../../App/views/BoxAddVoucherForBooking";
import Divide from "../../../../../components/Divide";

const decorateSelectTimeOptions = (date: any, timezone: any) => {
  const isAfter = moment(date).isAfter(moment.tz(timezone));
  let options;
  if (isAfter) {
    options = dayOptions();
  } else {
    options = dayOptions({
      minTime: `${moment.tz(timezone).add(1, "hour").format("HH")}:00`,
    });
  }

  return options;
};

const ModalConfirmBooking = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const filterMarket = useSelector(filterMarketSelector);
  const userCompanyChoose = useSelector(userCompanyChooseSelector);
  const meetingRoomData = useSelector(meetingRoomDataTmpSelector);
  const filters = useSelector(meetingRoomFiltersSelector);

  const [loading, setLoading] = useState(false);
  const [bookingInfo, setBookingInfo] = useState<any>();
  const [isAvailable, setIsAvailable] = useState(false);

  const defaultTime = getLocalTimeAndDurationFromAnotherTimezone(
    filters?.start_time,
    filters?.end_time,
    filterMarket?.timezone
  );

  const [isRefresh, setIsRefresh] = useState(false);
  const [modalFilters, setModalFilters] = useState<any>({
    date: null,
    startTime: null,
    duration: null,
    isBuffer: false,
  });
  const [isInitData, setIsInitData] = useState(false);
  const options = decorateSelectTimeOptions(
    moment(filters?.start_time * 1000),
    filterMarket?.timezone
  );

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    setModalFilters({
      date: filters.start_time ? moment(filters.start_time * 1000) : moment(),
      startTime: getInitStartTime(),
      duration:
        meetingRoomData.minimum_booking_time > defaultTime.duration
          ? meetingRoomData.minimum_booking_time
          : defaultTime.duration,
      isBuffer: false,
    });
    setIsInitData(true);
  };

  function getInitStartTime() {
    const defaultTime = getLocalTimeAndDurationFromAnotherTimezone(
      filters?.start_time,
      filters?.end_time,
      filterMarket?.timezone
    );
    const [hour, minute] = options[0].value.split(":");
    const [defaultHour, defaultMinute] = defaultTime?.startTime?.split(":") || [
      0, 0,
    ];
    if (hour > defaultHour) {
      return options[0].value;
    }
    return defaultTime?.startTime ? defaultTime?.startTime : options[0].value;
  }

  useEffect(() => {
    if (isInitData) {
      checkAvailableRoom();
    }
  }, [modalFilters]);

  const checkAvailableRoom = async () => {
    try {
      setLoading(true);
      if (meetingRoomData?.meeting_room_id) {
        const payload = {
          start_time: calculateBookingTime(
            modalFilters.date,
            modalFilters.startTime,
            undefined,
            filterMarket?.timezone,
            modalFilters.isBuffer
          ),
          end_time: calculateBookingTime(
            modalFilters.date,
            modalFilters.startTime,
            modalFilters.duration,
            filterMarket?.timezone,
            modalFilters.isBuffer
          ),
          meeting_room_id: meetingRoomData.meeting_room_id,
        };
        const response = await checkAvailableRoomService(payload);
        setBookingInfo(response?.data);
        setIsAvailable(true);
      }
    } catch (error: any) {
      switch (error?.status?.code) {
        case 1005:
          toastError(translations.errorMessage.meetingBooked);
          break;
        case 2000:
          toastError(translations.errorMessage.paymentNotAccept);
          break;
        case 5000:
          toastError(translations.errorMessage.businessCreditCardMiss);
          break;
        case 5001:
          toastError(translations.errorMessage.businessCreditCardInvalid);
          break;
        default:
          toastError(translations.errorMessage.meetingRoomUnavailable);
      }
      setIsAvailable(false);
    } finally {
      setLoading(false);
    }
  };

  const makeBooking = async () => {
    try {
      setLoading(true);
      await makeBookingRoomService({
        duration: modalFilters.duration,
        startTime: modalFilters.startTime,
        dateChoose: modalFilters.date,
        roomId: meetingRoomData?.meeting_room_id,
        timezone: filterMarket?.timezone,
        paymentCompanyId: userCompanyChoose?.company_id,
        isBuffer: modalFilters.isBuffer,
      });
      dispatch<any>(getCreditRemainingThunk());
      dispatch(modalConfirmBookingAction({ open: false }));
      dispatch(meetingRoomDataTmpAction(null));
    } catch (error: any) {
      switch (error?.status?.code) {
        case 1002:
        case 1012:
        case 1014:
          toastError(error?.status?.message);
          break;
        case 1005:
          toastError(translations.errorMessage.meetingBooked);
          break;
        case 1007:
          toastError(translations.errorMessage.paymentInValid);
          break;
        case 2000:
          toastError(translations.errorMessage.paymentNotAccept);
          break;
        case 5000:
          toastError(translations.errorMessage.businessCreditCardMiss);
          break;
        case 5001:
          toastError(translations.errorMessage.businessCreditCardInvalid);
          break;
        default:
          toastError(translations.errorMessage.meetingRoomUnavailable);
      }
    } finally {
      setLoading(false);
    }
  };

  const getMinimumBookingTime = () => {
    const minimumBookingTime = Math.floor(
      meetingRoomData.minimum_booking_time / 30
    );
    return DURATION_ARR(minimumBookingTime > 0 ? minimumBookingTime : 1, 24);
  };

  const onDateChange = (dateValue: any) => {
    setModalFilters({ ...modalFilters, date: dateValue });
  };

  return (
    <ModalCenterTemplate
      trigger={null}
      onClose={() => dispatch(modalConfirmBookingAction({ open: false }))}
      modalClassName={classNames("modal-center-v2 modal-confirm-booking")}
      open={true}
    >
      <Modal.Header>Confirm your booking</Modal.Header>
      <Modal.Content>
        <div className={"w-100"}>
          <div className={"modal-confirm-booking__thumbnail"}>
            <img src={meetingRoomData?.thumbnail} alt="" />
          </div>
          <div
            className={"d-flex flex-column"}
            style={{
              padding: 24,
              gap: 32,
              borderBottom: "1px solid #E9ECEE",
            }}
          >
            <div className={"modal-confirm-booking__info"}>
              <div className={"title"}>{meetingRoomData?.name}</div>
              <div className={"item"}>
                <i className="uil uil-building" />
                {meetingRoomData?.property_name +
                  " • " +
                  meetingRoomData?.company_name}
              </div>
              <div className={"item"}>
                <i className="uil uil-map" />
                {meetingRoomData?.address}
              </div>
              <div className={"item mb-0"}>
                <i className="uil uil-users-alt" />
                {meetingRoomData?.capacity}
                {meetingRoomData?.capacity > 1 ? " People" : " Person"}
              </div>
            </div>
            <div className={"modal-confirm-booking__detail"}>
              <div className={"title"}>Booking Details</div>
              <div className={"select-item"}>
                <div className={"text"}>Date</div>
                <div>
                  <DatePickerSingleV2
                    format={"DD/MM/YYYY"}
                    allowClear={false}
                    value={modalFilters.date}
                    disabledDate={(date: any) => {
                      return moment(date).isBefore(moment().subtract({ d: 1 }));
                    }}
                    showToday={false}
                    className={"w-100"}
                    onChange={onDateChange}
                  />
                </div>
              </div>
              <div className={"select-item"}>
                <div className={"text"}>Start Time</div>
                <div>
                  <DropdownCustom
                    upward
                    scrolling
                    selectUI
                    value={modalFilters.startTime}
                    options={options}
                    onChange={(e, { value }) => {
                      setModalFilters({ ...modalFilters, startTime: value });
                    }}
                  />
                </div>
              </div>
              <div className={"select-item"}>
                <div className={"text"}>Duration</div>
                <div>
                  <DropdownCustom
                    scrolling
                    upward
                    selectUI
                    options={getMinimumBookingTime()}
                    value={modalFilters.duration}
                    onChange={(e, { value }) => {
                      setModalFilters({ ...modalFilters, duration: value });
                    }}
                  />
                </div>
              </div>
              <div className={"buffer-booking"}>
                <CheckboxCustom
                  className={"w-100 custom-checkbox"}
                  onChange={(e: any, data: any) => {
                    setModalFilters({
                      ...modalFilters,
                      isBuffer: data?.checked,
                    });
                  }}
                  checked={modalFilters.isBuffer}
                  label={
                    "Add 15-minute buffer time before and after the meeting"
                  }
                />
              </div>
            </div>
          </div>
          <div style={{ padding: 24 }}>
            <BoxAddVoucherForBooking
              isLabelTitle={true}
              isRefresh={isRefresh}
              setIsRefresh={setIsRefresh}
            />

            {bookingInfo?.vouchers && bookingInfo?.vouchers?.length > 0 ? (
              <>
                <SizeBox height={"48px"} />
                <div className="d-flex justify-content-between">
                  <div
                    className={"label-small fw-500"}
                    style={{ fontSize: 20 }}
                  >
                    Subtotal
                  </div>
                  <div
                    className={"label-small text-right"}
                    style={{ fontSize: 20 }}
                  >
                    {bookingInfo?.currency} {bookingInfo?.price_before_discount}
                  </div>
                </div>
                <SizeBox height={"24px"} />
              </>
            ) : (
              <SizeBox height={"12px"} />
            )}

            {bookingInfo?.discount ? (
              <>
                <div className="d-flex justify-content-between">
                  <div
                    className={"label-small fw-500"}
                    style={{ fontSize: 20 }}
                  >
                    Discount
                  </div>
                  <div
                    className={"label-small text-right"}
                    style={{ fontSize: 20 }}
                  >
                    - {bookingInfo?.currency} {bookingInfo.discount}
                  </div>
                </div>
                <Divide height={"1px"} type={"horizontal"} margin={"24px 0"} />
              </>
            ) : null}

            <div className={"modal-confirm-booking__total"}>
              <div className={"title"}>Total</div>
              <div className={"value"}>
                {bookingInfo?.currency}{" "}
                {bookingInfo?.total_final_price
                  ? bookingInfo?.total_final_price
                  : 0}
              </div>
            </div>
            <SizeBox height={"24px"} />
            <div className={"modal-confirm-booking__actions"}>
              <ButtonCustom
                height={"47px"}
                onClick={() => {
                  dispatch(modalConfirmBookingAction({ open: false }));
                }}
              >
                {translations.cancel}
              </ButtonCustom>
              <SizeBox width={"16px"} />
              <ButtonCustom
                disabled={!isAvailable}
                loading={loading}
                className={"primary"}
                height={"47px"}
                onClick={makeBooking}
              >
                Book now
              </ButtonCustom>
            </div>
          </div>
        </div>
      </Modal.Content>
    </ModalCenterTemplate>
  );
};

export default ModalConfirmBooking;
