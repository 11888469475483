import React, { useContext } from "react";
import InputCustom from "../../../../../../components/Input";
import { getIn } from "formik";
import Label from "../../../../../../components/Label";
import VoucherCurrency from "./VoucherCurrency";
import DateRangePicker from "../../../../../../components/DateRangePicker";
import moment from "moment-timezone";
import { CampaignCreateEditContext } from "../../../../reducers/CampaignCreateEditContext";
import { FORMAT_DATE_VARIANT_2 } from "../../../../../App/configs/DateTimeConfigs";
import DateRangePickerV2 from "../../../../../../components/DateRangePickerV2";

const VoucherOnboard = () => {
  const { errors, touched, isSubmitting, handleChange, setFieldValue, values } =
    useContext(CampaignCreateEditContext);

  const startTime = moment
    .unix(values.voucher_reward.free_credit_onboard_start_time)
    .tz(values.timezone);
  const endTime = moment
    .unix(values.voucher_reward.free_credit_onboard_end_time)
    .tz(values.timezone);

  return (
    <div className={"campaign-create-type"}>
      <InputCustom
        label={"Amount of free credit for each new user onboarded"}
        labelRequired
        value={values.voucher_reward.value}
        name={"voucher_reward.value"}
        onChange={handleChange}
        error={getIn(errors, "voucher_reward.value")}
        touched={getIn(touched, "voucher_reward.value")}
        disabled={isSubmitting}
      />
      <VoucherCurrency />
      <InputCustom
        labelRequired
        label={
          "Free credit valid for X days (from the day the user is onboarded)"
        }
        value={values.voucher_reward.redeem_duration}
        name={"voucher_reward.redeem_duration"}
        onChange={handleChange}
        error={getIn(errors, "voucher_reward.redeem_duration")}
        touched={getIn(touched, "voucher_reward.redeem_duration")}
        disabled={isSubmitting}
      />
      <div>
        <Label
          label={"Onboard campaign Start / End Time"}
          labelGuide={
            "The free onboarding credit cannot be added after the campaign ends"
          }
          fontSize={"12px"}
        />

        <div className={"input-component"}>
          <DateRangePickerV2
            className={"w-100"}
            format={"DD/MM/YYYY"}
            defaultValue={
              !values.voucher_reward.free_credit_onboard_start_time &&
              !values.voucher_reward.free_credit_onboard_end_time
                ? null
                : [
                    moment(
                      new Date(
                        startTime.get("year"),
                        startTime.get("month"),
                        startTime.get("date"),
                        0,
                        0,
                        0,
                        0
                      )
                    ),
                    moment(
                      new Date(
                        endTime.get("year"),
                        endTime.get("month"),
                        endTime.get("date"),
                        0,
                        0,
                        0,
                        0
                      )
                    ),
                  ]
            }
            onChange={(value: any) => {
              if (!value) {
                return;
              }
              setFieldValue(
                "voucher_reward.free_credit_onboard_start_time",
                moment(
                  moment(value[0]).format(FORMAT_DATE_VARIANT_2),
                  FORMAT_DATE_VARIANT_2
                )
                  .clone()
                  .tz(values.timezone, true)
                  .startOf("day")
                  .utc()
                  .unix()
              );

              setFieldValue(
                "voucher_reward.free_credit_onboard_end_time",
                moment(
                  moment(value[1]).format(FORMAT_DATE_VARIANT_2),
                  FORMAT_DATE_VARIANT_2
                )
                  .clone()
                  .tz(values.timezone, true)
                  .endOf("day")
                  .utc()
                  .unix()
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default VoucherOnboard;
