import {
  DASHBOARD_ACTIVITY_LOG_ACTION,
  DASHBOARD_DESK_BOOKING_ACTION,
  DASHBOARD_DESK_BOOKING_FILTER_ACTION,
  DASHBOARD_DESK_USAGE_ACTION,
  DASHBOARD_DESK_USAGE_FILTER_ACTION,
  DASHBOARD_FILTER_DATE_ACTION,
  DASHBOARD_FILTER_DATE_RANGE_ACTION,
  DASHBOARD_PROPERTY_CHOOSE_ACTION,
  DASHBOARD_PROPERTY_LIST_ACTION,
  DASHBOARD_PROPERTY_PENDING_BOOKINGS_ACTION,
  DASHBOARD_PROPERTY_PENDING_BOOKING_REMOVE_ACTION,
  DASHBOARD_PROPERTY_STATUS_ACTION,
  DASHBOARD_ROOM_BOOKING_ACTION,
  DASHBOARD_ROOM_BOOKING_FILTER_ACTION,
  DASHBOARD_STATISTIC_ACTION,
  DASHBOARD_STATISTIC_BOOKING_PROPERTY_ACTION,
  DASHBOARD_STATISTIC_PROPERTY_ACTION,
  DASHBOARD_DESK_BOOKING_CHANGE_STATUS_ACTION,
  DASHBOARD_ROOM_BOOKING_CHANGE_STATUS_ACTION,
  DASHBOARD_ACTIVITY_LOG_LOAD_MORE_ACTION,
  GET_DECLINE_REASONS_ACTION,
  MODAL_DECLINE_REASONS_ACTION,
  DASHBOARD_STATISTIC_DESKIMO_ACTION,
  MODAL_BOOKING_DESK_MANUAL_ACTION,
  MODAL_BOOKING_ROOM_MANUAL_ACTION,
  DASHBOARD_LEASE_FILTER_ACTION,
  DASHBOARD_STATISTIC_FILTER_ACTION,
  GET_FREE_CREDIT_ACTION,
  DASHBOARD_DESKIMO_DESK_USAGE_FILTER_ACTION,
  DASHBOARD_DESKIMO_DESK_BOOKING_FILTER_ACTION,
  DASHBOARD_DESKIMO_ROOM_BOOKING_FILTER_ACTION,
  MODAL_CREATE_WALK_INS_MANUAL_ACTION,
  DASHBOARD_STATISTIC_OFFICE_PROPERTY_ACTION,
  TRANSACTION_INFO_ACTION,
  MODAL_CHECK_IN_MANUAL_ACTION,
  MODAL_PARTNER_CREATE_WALKINS_ACTIONS,
  LIST_PROPERTY_FILTER_ACTION,
  LIST_PROPERTY_SELECTED_FILTER_ACTION,
} from "../configs/Constants";
import {
  DeskBookingFilterType,
  DeskUsageFilterType,
  RoomBookingFilterType,
} from "../configs/DeclareTypes";

export const dashboardFilterDateAction = (data: string) => {
  return {
    type: DASHBOARD_FILTER_DATE_ACTION,
    payload: data,
  };
};

export const dashboardFilterDateRangeAction = (
  data:
    | {
        start_time: number;
        end_time: number;
      }
    | object
) => {
  return {
    type: DASHBOARD_FILTER_DATE_RANGE_ACTION,
    payload: data,
  };
};

export const dashboardStatisticAction = (data: object) => {
  return {
    type: DASHBOARD_STATISTIC_ACTION,
    payload: data,
  };
};

export const dashboardStatisticDeskimoAction = (data: object) => {
  return {
    type: DASHBOARD_STATISTIC_DESKIMO_ACTION,
    payload: data,
  };
};

export const dashboardStatisticPropertyAction = (data: object) => {
  return {
    type: DASHBOARD_STATISTIC_PROPERTY_ACTION,
    payload: data,
  };
};

export const dashboardStatisticBookingPropertyAction = (data: object) => {
  return {
    type: DASHBOARD_STATISTIC_BOOKING_PROPERTY_ACTION,
    payload: data,
  };
};

export const dashboardStatisticOfficePropertyAction = (data: object) => {
  return {
    type: DASHBOARD_STATISTIC_OFFICE_PROPERTY_ACTION,
    payload: data,
  };
};

export const dashboardDeskUsageAction = (data: object | null) => {
  return {
    type: DASHBOARD_DESK_USAGE_ACTION,
    payload: data,
  };
};

export const dashboardDeskUsageFilterAction = (data: {
  key: DeskUsageFilterType;
  value: string | number[] | string[];
}) => {
  return {
    type: DASHBOARD_DESK_USAGE_FILTER_ACTION,
    payload: data,
  };
};

export const dashboardDeskimoDeskUsageFilterAction = (data: {
  key: DeskUsageFilterType;
  value: string | number[] | string[];
}) => {
  return {
    type: DASHBOARD_DESKIMO_DESK_USAGE_FILTER_ACTION,
    payload: data,
  };
};

export const dashboardDeskBookingAction = (data: object | null) => {
  return {
    type: DASHBOARD_DESK_BOOKING_ACTION,
    payload: data,
  };
};

export const dashboardDeskBookingChangeStatusAction = (data: {
  id: number;
  statusId: number;
}) => {
  return {
    type: DASHBOARD_DESK_BOOKING_CHANGE_STATUS_ACTION,
    payload: data,
  };
};

export const dashboardDeskBookingFilterAction = (data: {
  key: DeskBookingFilterType;
  value: string | number[] | string[];
}) => {
  return {
    type: DASHBOARD_DESK_BOOKING_FILTER_ACTION,
    payload: data,
  };
};

export const dashboardDeskimoDeskBookingFilterAction = (data: {
  key: DeskBookingFilterType;
  value: string | number[] | string[];
}) => {
  return {
    type: DASHBOARD_DESKIMO_DESK_BOOKING_FILTER_ACTION,
    payload: data,
  };
};

export const dashboardRoomBookingAction = (data: object | null) => {
  return {
    type: DASHBOARD_ROOM_BOOKING_ACTION,
    payload: data,
  };
};

export const dashboardRoomBookingChangeStatusAction = (data: {
  id: number;
  statusId: number;
}) => {
  return {
    type: DASHBOARD_ROOM_BOOKING_CHANGE_STATUS_ACTION,
    payload: data,
  };
};

export const dashboardRoomBookingFilterAction = (data: {
  key: RoomBookingFilterType;
  value: string | number[] | string[];
}) => {
  return {
    type: DASHBOARD_ROOM_BOOKING_FILTER_ACTION,
    payload: data,
  };
};

export const dashboardDeskimoRoomBookingFilterAction = (data: {
  key: RoomBookingFilterType;
  value: string | number[] | string[];
}) => {
  return {
    type: DASHBOARD_DESKIMO_ROOM_BOOKING_FILTER_ACTION,
    payload: data,
  };
};

export const dashboardLeaseFilterAction = (data: {
  key: any;
  value: string | number[] | string[];
}) => {
  return {
    type: DASHBOARD_LEASE_FILTER_ACTION,
    payload: data,
  };
};

export const dashboardPropertyListAction = (data: object[]) => {
  return {
    type: DASHBOARD_PROPERTY_LIST_ACTION,
    payload: data,
  };
};

export const dashboardPropertyChooseAction = (data: object) => {
  return {
    type: DASHBOARD_PROPERTY_CHOOSE_ACTION,
    payload: data,
  };
};

export const dashboardPropertyPendingBookingsAction = (data: object[]) => {
  return {
    type: DASHBOARD_PROPERTY_PENDING_BOOKINGS_ACTION,
    payload: data,
  };
};

export const dashboardPropertyPendingBookingRemoveAction = (id: number) => {
  return {
    type: DASHBOARD_PROPERTY_PENDING_BOOKING_REMOVE_ACTION,
    payload: id,
  };
};

export const propertyStatusAction = (propertyId: number) => {
  return {
    type: DASHBOARD_PROPERTY_STATUS_ACTION,
    payload: propertyId,
  };
};

export const dashboardActivityLogAction = (data: object[]) => {
  return {
    type: DASHBOARD_ACTIVITY_LOG_ACTION,
    payload: data,
  };
};

export const dashboardActivityLogLoadMoreAction = (data: object[]) => {
  return {
    type: DASHBOARD_ACTIVITY_LOG_LOAD_MORE_ACTION,
    payload: data,
  };
};

export const getDeclineReasonsAction = (data: object[]) => {
  return {
    type: GET_DECLINE_REASONS_ACTION,
    payload: data,
  };
};

export const modalDeclineReasonsAction = (data: any) => {
  return {
    type: MODAL_DECLINE_REASONS_ACTION,
    payload: data,
  };
};

export const modalCheckInManualAction = (data: any) => {
  return {
    type: MODAL_CHECK_IN_MANUAL_ACTION,
    payload: data,
  };
};

export const modalBookingDeskManualAction = (data: any) => {
  return {
    type: MODAL_BOOKING_DESK_MANUAL_ACTION,
    payload: data,
  };
};

export const modalBookingRoomManualAction = (data: any) => {
  return {
    type: MODAL_BOOKING_ROOM_MANUAL_ACTION,
    payload: data,
  };
};

export const modalCreateWalkInsManualAction = (data: any) => {
  return {
    type: MODAL_CREATE_WALK_INS_MANUAL_ACTION,
    payload: data,
  };
};

export const dashboardStatisticFilterAction = (data: object) => {
  return {
    type: DASHBOARD_STATISTIC_FILTER_ACTION,
    payload: data,
  };
};

export const getFreeCreditAction = (data: any) => {
  return {
    type: GET_FREE_CREDIT_ACTION,
    payload: data,
  };
};

export const transactionInfoAction = (data: any) => {
  return {
    type: TRANSACTION_INFO_ACTION,
    payload: data,
  };
};

export const modalPartnerCreateWalkInsAction = (data: any) => {
  return {
    type: MODAL_PARTNER_CREATE_WALKINS_ACTIONS,
    payload: data,
  };
};

export const filterListPropertyAction = (data: any) => {
  return {
    type: LIST_PROPERTY_FILTER_ACTION,
    payload: data,
  };
};

export const filterListPropertySelectedAction = (data: any) => {
  return {
    type: LIST_PROPERTY_SELECTED_FILTER_ACTION,
    payload: data,
  };
};
