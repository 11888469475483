import React from "react";
import { MarkerClusterer as MarkerClustererModule } from "@react-google-maps/api";
import {
  GOOGLE_MAP_CLUSTER_OPTIONS,
  GOOGLE_MAP_CLUSTER_STYLES,
} from "../../configs/MapConstants";
import {
  markerConvertAround,
  onCheckMarkerBelongCluster,
  onCheckMarkersSameLatLng,
} from "../../services/MapServices";
import { mapMoveAnimate } from "../../services/Common";
import MarkerList from "./MarkerList";
import { Cluster, MarkerExtended } from "@react-google-maps/marker-clusterer";
import { useDispatch, useSelector } from "react-redux";
import {
  mapStateSelector,
  pinHighlightIdSelector,
} from "../../selectors/BookSelectors";
import { infoWindowDataAction } from "../../actions/BookActions";

type Props = {
  tabActive: number;
  markerSameLatLng: any;
  setMarkerSameLatLng: any;
};

const MarkerCluster = ({
  setMarkerSameLatLng,
  markerSameLatLng,
  tabActive,
}: Props) => {
  const dispatch = useDispatch();
  const mapState = useSelector(mapStateSelector);
  const pinHighlightId = useSelector(pinHighlightIdSelector);

  const _onClick = ({ getBounds, markers }: Cluster) => {
    dispatch(infoWindowDataAction(null));
    const allMarkersSameLocation = onCheckMarkersSameLatLng(markers);
    if (allMarkersSameLocation) {
      const newMarkers = markerConvertAround(markers);
      setMarkerSameLatLng(newMarkers);
      mapMoveAnimate({
        map: mapState,
        cameraOptions: {
          center: {
            lat: newMarkers[0].lat,
            lng: newMarkers[0].lng,
          },
        },
      });
      return;
    }
    mapState.fitBounds(getBounds());
  };

  const _onCalculate = (markers: MarkerExtended[]) => {
    const allMarkersSameLocation = onCheckMarkersSameLatLng(markers);

    const pinHighlightBelongCluster = onCheckMarkerBelongCluster(
      markers,
      pinHighlightId
    );

    if (
      allMarkersSameLocation &&
      markerSameLatLng &&
      markerSameLatLng[0]?.lat === markers[0].getPosition()?.lat() &&
      markerSameLatLng[0]?.lng === markers[0].getPosition()?.lng()
    ) {
      return {
        text: "",
        index: 5,
      };
    }

    if (pinHighlightBelongCluster && allMarkersSameLocation) {
      return {
        text: markers.length,
        index: 2,
      };
    }

    if (pinHighlightBelongCluster) {
      return {
        text: markers.length,
        index: 4,
      };
    }

    return {
      text: markers.length,
      index: allMarkersSameLocation ? 1 : 3,
    };
  };

  return (
    <MarkerClustererModule
      options={GOOGLE_MAP_CLUSTER_OPTIONS}
      zoomOnClick={false}
      onClick={_onClick}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      calculator={_onCalculate}
      styles={GOOGLE_MAP_CLUSTER_STYLES}
    >
      {(clusterer) => (
        <MarkerList tabActive={tabActive} clusterer={clusterer} />
      )}
    </MarkerClustererModule>
  );
};

export default MarkerCluster;
