import { useDispatch, useSelector } from "react-redux";
import TableCustom from "../../../../../../../components/Table";
import { rewardListSelector } from "../../../../../selectors/PropertySelector";
import ButtonCustom from "../../../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import { modalRewardAction } from "../../../../../actions/PropertyActions";
import DropdownCustom from "../../../../../../../components/DropdownCustom";
import React, { useState } from "react";
import { SWAL_DELETE_OPTION } from "../../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../../components/SweetAlert";
import { toastSuccess } from "../../../../../../../components/Toast";
import { removeRewardService } from "../../../../../services/PropertyServices";
import { useParams } from "react-router-dom";
import moment from "moment";

type Props = {
  loading: boolean;
  onChangeSortCol: any;
  filters: any;
  onChangePagination: any;
  refresh: any;
  setRefresh: any;
};

const RewardTable = ({
  onChangeSortCol,
  onChangePagination,
  filters,
  refresh,
  setRefresh,
}: Props) => {
  const rewardList = useSelector(rewardListSelector);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { id: propertyId } = useParams();

  const columns = [
    { key: "description", col: "Reward Description" },
    { key: "condition", col: "Reward Condition" },
    { key: "duration", col: "Generate Reward Expiry Duration" },
    { key: "issued", col: "Issued" },
    { key: "redeemed", col: "Redeemed" },
    { key: "configured_date", col: "Configured Date" },
    { key: null, col: "Action" },
  ];

  const onEditReward = (id: string) => {
    try {
      dispatch(
        modalRewardAction({
          open: true,
          data: {
            id,
          },
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  const onRemoveReward = (id: string) => {
    try {
      if (!propertyId || !id) return;
      const icon = null;
      const title = `Are you sure you want to remove this reward?`;
      const confirmText = "Confirm";
      const confirmClass = "danger";
      const options = SWAL_DELETE_OPTION({
        title,
        icon,
        confirmText,
        confirmClass,
      });
      SweetAlertCommon({
        options,
        confirmCallback: async () => {
          setLoading(true);
          await removeRewardService(propertyId, id);
          toastSuccess("Remove Reward successfully!");
          setRefresh(!refresh);
          setLoading(false);
        },
        cancelCallback: () => null,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const decorateDataTable = () => {
    if (rewardList && rewardList?.items && rewardList?.items.length > 0) {
      return rewardList?.items?.map((reward: any) => {
        return {
          description: reward?.description,
          condition: reward?.condition,
          duration: reward?.duration,
          issued: reward?.issued,
          redeemed: reward?.redeemed,
          configured_date: moment(reward?.configured_date * 1000).format(
            "DD/MM/YYYY"
          ),
          action: (
            <DropdownCustom
              direction={"left"}
              icon={null}
              trigger={
                <ButtonCustom
                  style={{ width: "38px", height: "38px" }}
                  className={"primary-alt"}
                >
                  <i className="uil uil-setting" style={{ fontSize: "21px" }} />
                </ButtonCustom>
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    onEditReward(reward?.id);
                  }}
                  text={"Edit Reward"}
                  icon={
                    <i
                      className="uil uil-edit icon"
                      style={{ fontSize: "16px" }}
                    />
                  }
                />

                <Dropdown.Item
                  onClick={() => onRemoveReward(reward?.id)}
                  text={"Remove Reward"}
                  icon={
                    <i
                      className="uil uil-trash-alt icon"
                      style={{ fontSize: "16px" }}
                    />
                  }
                />
              </Dropdown.Menu>
            </DropdownCustom>
          ),
        };
      });
    }
    return [];
  };

  return (
    <div>
      <TableCustom
        tableData={decorateDataTable()}
        showTotal
        loading={loading}
        noHeader={false}
        onChangeSortCol={onChangeSortCol}
        orderBy={filters?.sort_type}
        sortBy={filters?.sort_by}
        sortTable
        columns={columns}
        itemPerPage={rewardList?.pagination?.limit}
        currentPage={filters?.page}
        totalItems={rewardList?.pagination?.total}
        unit={"rewards"}
        onChangePagination={onChangePagination}
      />
    </div>
  );
};

export default RewardTable;
