import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";

const MakeCompanyAdminLabelGuide = () => {
  const { translations } = useContext(LocalizationContext);

  return (
    <>
      <div>{translations.makeAdminLabel}:</div>
      <ul>
        <li>- {translations.makeAdminSubLabel_1}</li>
        <li>- Full access to users records</li>
        <li>- Full access to all settings</li>
      </ul>
    </>
  );
};

export default MakeCompanyAdminLabelGuide;
