export const businessCompanyFilterSelector = (state: any) =>
  state.BusinessReducer.businessCompanyFilter;

export const businessCompanyListSelector = (state: any) =>
  state.BusinessReducer.companyList;

export const businessCompanyDetailSelector = (state: any) =>
  state.BusinessReducer.companyDetail;

export const businessCompanyStaffSelector = (state: any) =>
  state.BusinessReducer.companyStaff;

export const businessCompanyStaffFilterSelector = (state: any) =>
  state.BusinessReducer.companyStaffFilter;

export const businessCompanyInvoiceSelector = (state: any) =>
  state.BusinessReducer.companyInvoice;

export const businessCompanyInvoiceFilterSelector = (state: any) =>
  state.BusinessReducer.companyInvoiceFilter;

export const isModalBusinessCompanyStaffEditSelector = (state: any) =>
  state.BusinessReducer.isModalCompanyStaffEdit;

export const businessCompanyStaffEditDataSelector = (state: any) =>
  state.BusinessReducer.companyStaffEditData;

export const businessCompanyCustomPackageDataSelector = (state: any) =>
  state.BusinessReducer.companyCustomPackage;

export const isModalBusinessCompanyCustomPackageSelector = (state: any) =>
  state.BusinessReducer.isModalBusinessCompanyCustomPackages;

export const modalUpdateAutoTopUpSelector = (state: any) =>
  state.BusinessReducer.modalUpdateAutoTopUp;

export const businessCompanyPendingInvitationSelector = (state: any) =>
  state.BusinessReducer.companyPendingInvitation;

export const purchaseHistoryFiltersSelector = (state: any) =>
  state.BusinessReducer.purchaseHistoryFilters;

export const prepaidCreditFiltersSelector = (state: any) =>
  state.BusinessReducer.prepaidCreditFilters;
