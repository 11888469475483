import { Formik, FormikValues } from "formik";
import * as yup from "yup";
import { PUBLIC_URL } from "../../../App/configs/Constants";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../components/Button";
import InputCustom from "../../../../components/Input";
import BoxToggleSlider from "../../../../components/BoxToggleSlider";
import Label from "../../../../components/Label";
import ModalRightSideBarTemplate from "../../../App/views/ModalRightSideBarTemplate";
import React, { useContext, useEffect, useRef, useState } from "react";
import { LocalizationContext } from "../../../../locales/Translation";
import { purchaseCustomPackageService } from "../../../Business/services/BusinessServices";
import { toastError, toastSuccess } from "../../../../components/Toast";
import {
  createConfigPrepaidCreditPackageService,
  getConfigPrepaidCreditPackageDetailService,
  updateConfigPrepaidCreditPackageService,
} from "../../services/ConfigServices";
import { useSelector } from "react-redux";
import { globalSettingSelector } from "../../../App/selectors/AppSelectors";
import DropdownCustom from "../../../../components/DropdownCustom";
import Divide from "../../../../components/Divide";
import { validateNumber } from "../../../../services/FormatTextService";
import TextAreaCustom from "../../../../components/Input/TextArea";

type Props = {
  isOpen: any;
  onClose: () => void;
  onSetRefreshing: () => void;
};
const ModalCreateUpdatePrepaidCreditPackage = ({
  isOpen,
  onClose,
  onSetRefreshing,
}: Props) => {
  const { translations } = useContext(LocalizationContext);
  const formRef = useRef(null);
  const globalSetting = useSelector(globalSettingSelector);
  const packageNameRef = useRef(null);

  const [
    configPrepaidCreditPackageDetail,
    setConfigPrepaidCreditPackageDetail,
  ] = useState<any>(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    packageNameRef?.current?.focus();
    if (isOpen.data) {
      _fetchPrepaidCreditPackageData();
    }
  }, [isOpen]);

  const _fetchPrepaidCreditPackageData = async () => {
    try {
      const response = await getConfigPrepaidCreditPackageDetailService(
        isOpen.data
      );
      setConfigPrepaidCreditPackageDetail(response?.data);
    } catch (error: any) {
      console.log("Error: ", error);
    } finally {
      console.log("Finally");
    }
  };

  const _handleSubmit = async (values: FormikValues) => {
    try {
      if (isOpen.data) {
        await updateConfigPrepaidCreditPackageService(values);
        toastSuccess("Update Prepaid Credit Package Successfully!");
      } else {
        await createConfigPrepaidCreditPackageService(values);
        toastSuccess("Create Prepaid Credit Package Successfully!");
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formRef.current.resetForm();
      onClose();
      setConfigPrepaidCreditPackageDetail(null);
      onSetRefreshing();
    } catch (e: any) {
      toastError(e?.status?.message);
    }
  };
  return (
    <ModalRightSideBarTemplate
      open={isOpen?.open}
      modalClassName={"office-lease-modal"}
      onClose={() => {
        setConfigPrepaidCreditPackageDetail(null);
        onClose();
      }}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          id: isOpen?.data ? isOpen?.data : null,
          market_code: configPrepaidCreditPackageDetail?.market_code || "SG",
          name: configPrepaidCreditPackageDetail?.name,
          promotion_text: configPrepaidCreditPackageDetail?.promotion_text,
          amount: configPrepaidCreditPackageDetail?.amount,
          currency: configPrepaidCreditPackageDetail?.currency || "SGD",
          discount: configPrepaidCreditPackageDetail?.discount,
          duration: configPrepaidCreditPackageDetail?.duration,
          extend_time: configPrepaidCreditPackageDetail?.extend_time,
          trigger_amount: configPrepaidCreditPackageDetail?.trigger_amount,
          is_purchasing:
            configPrepaidCreditPackageDetail?.is_purchasing || false,
          full_description:
            configPrepaidCreditPackageDetail?.full_description || "",
        }}
        validationSchema={yup.object().shape({
          name: yup.string().required().label("Package Name"),
          amount: yup.number().required().label("Amount"),
          discount: yup.number().required().min(0).max(100).label("Discount"),
          duration: yup.number().required().min(1).label("Expiry period"),
          trigger_amount: yup
            .string()
            .required()
            .label("Top-up when the credit balance falls below"),
          extend_time: yup
            .number()
            .required()
            .label("No. of times to rollover unused credits"),
        })}
        onSubmit={_handleSubmit}
      >
        {({
          values,
          isSubmitting,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
          handleChange,
          resetForm,
        }) => (
          <>
            <Modal.Header>
              {isOpen.data ? "Update" : "Create"} Prepaid Credit Package
              <ButtonCustom
                onClick={() => {
                  setConfigPrepaidCreditPackageDetail(null);
                  onClose();
                }}
                className="m-0 p-0 none-bg close-modal"
              >
                <>
                  {translations.close}
                  <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
                </>
              </ButtonCustom>
            </Modal.Header>
            <Modal.Content className={"scroll"}>
              <div
                style={{ display: "flex", flexFlow: "column nowrap", gap: 16 }}
              >
                <div>
                  <Label label={"Market"} required />
                  <DropdownCustom
                    value={values.market_code}
                    onChange={(e, data) => {
                      setFieldValue("market_code", data.value);
                      setFieldValue(
                        "currency",
                        globalSetting?.location.find(
                          (market: any) => market.city_code === data.value
                        ).currency
                      );
                    }}
                    className={"mb-0"}
                    selectUI
                    options={globalSetting?.location.map((market: any) => {
                      return {
                        key: market.city_code,
                        text: market.city_name,
                        value: market.city_code,
                      };
                    })}
                  />
                </div>

                <InputCustom
                  ref={packageNameRef}
                  labelRequired
                  containerClass={"flex-1 mb-0"}
                  label={"Package Name"}
                  name={"name"}
                  value={values.name}
                  onChange={handleChange}
                  touched={touched.name}
                  error={errors.name}
                  placeholder={"Package name"}
                />
                <InputCustom
                  containerClass={"flex-1 mb-0"}
                  label={"Promo text"}
                  name={"promotion_text"}
                  value={values.promotion_text}
                  onChange={handleChange}
                  touched={touched.promotion_text}
                  error={errors.promotion_text}
                  placeholder={"Promo text"}
                />

                <TextAreaCustom
                  label={"Extra information"}
                  name={"full_description"}
                  value={values.full_description}
                  onChange={handleChange}
                  touched={touched.full_description}
                  error={errors.full_description}
                />

                <div className="d-flex" style={{ gap: 16 }}>
                  <InputCustom
                    labelRequired
                    containerClass={"flex-1 mb-0"}
                    label={"Amount"}
                    name={"amount"}
                    value={values.amount}
                    onChange={(e: any, { value }: any) => {
                      setFieldValue("amount", validateNumber(value));
                    }}
                    touched={touched.amount}
                    error={errors.amount}
                    inputLeftIcon={values.currency}
                    inputLeftIconBorder
                    placeholder={"0.00"}
                  />
                  <InputCustom
                    labelRequired
                    containerClass={"flex-1 mb-0"}
                    label={"Discount"}
                    name={"discount"}
                    value={values.discount}
                    onChange={(e: any, { value }: any) => {
                      let text = validateNumber(value);
                      if (Number(text) > 100) {
                        text = "100";
                      }
                      setFieldValue("discount", text);
                    }}
                    touched={touched.discount}
                    error={errors.discount}
                    inputLeftIcon={"%"}
                    inputLeftIconBorder
                    placeholder={"0"}
                  />
                </div>

                <div className="d-flex" style={{ gap: 16 }}>
                  <InputCustom
                    labelRequired
                    containerClass={"flex-1 mb-0"}
                    label={"Expiry period"}
                    name={"duration"}
                    value={values.duration}
                    onChange={(e: any, { value }: any) => {
                      setFieldValue("duration", validateNumber(value));
                    }}
                    touched={touched.duration}
                    error={errors.duration}
                    inputLeftIcon={"Days"}
                    inputLeftIconBorder
                    placeholder={"0"}
                  />
                  <InputCustom
                    labelRequired
                    containerClass={"flex-1 mb-0"}
                    label={"No. of times to rollover unused credits"}
                    name={"extend_time"}
                    value={values.extend_time}
                    onChange={(e: any, { value }: any) => {
                      setFieldValue("extend_time", validateNumber(value));
                    }}
                    touched={touched.extend_time}
                    error={errors.extend_time}
                    placeholder={"0"}
                  />
                </div>

                <InputCustom
                  labelRequired
                  containerClass={"mb-0"}
                  label={"Top-up when the credit balance falls below"}
                  name={"trigger_amount"}
                  value={values.trigger_amount}
                  touched={touched.trigger_amount}
                  error={errors.trigger_amount}
                  inputLeftIcon={values.currency}
                  inputLeftIconBorder
                  onChange={(e: any, { value }: any) => {
                    setFieldValue("trigger_amount", validateNumber(value));
                  }}
                  placeholder={"0.00"}
                />

                <BoxToggleSlider
                  value={values.is_purchasing}
                  onChange={(e: any, data: any) => {
                    setFieldValue("is_purchasing", data.checked);
                  }}
                  content={
                    <Label
                      fontSize={"14px"}
                      label={"Available for purchase by users"}
                    />
                  }
                />
              </div>
            </Modal.Content>
            <Modal.Actions className={"credit-modal-actions"}>
              <div className={"d-flex"}>
                <ButtonCustom
                  disabled={isSubmitting}
                  height={"48px"}
                  onClick={() => {
                    resetForm();
                    setConfigPrepaidCreditPackageDetail(null);
                    onClose();
                  }}
                >
                  {translations.back}
                </ButtonCustom>
                <ButtonCustom
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  className={"primary"}
                  height={"48px"}
                  onClick={handleSubmit}
                >
                  {translations.save}
                </ButtonCustom>
              </div>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalRightSideBarTemplate>
  );
};

export default ModalCreateUpdatePrepaidCreditPackage;
