import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardDeskBookingFilterSelector,
  dashboardDeskBookingSelector,
  dashboardFilterDateRangeSelector,
  dashboardPropertyChooseSelector,
  filterListPropertySelectedSelector,
} from "../../../selectors/DashboardSelectors";
import { userCompanyChooseSelector } from "../../../../User/selectors/UserSelectors";
import { dashboardDeskBookingThunk } from "../../../thunks/DashboardThunks";
import { DeskBookingType } from "../../../configs/DeclareTypes";
import {
  dashboardDeskBookingChangeStatusAction,
  dashboardDeskBookingFilterAction,
} from "../../../actions/DashboardActions";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../../App/configs/Constants";
import TableCustom from "../../../../../components/Table";
import BookingAction from "./components/BookingAction";
import { DESK_BOOKING, PROPERTY } from "../../../configs/Constants";
import { usePermissionCheck } from "../../../../../utils/custom-hooks";
import { UI_ACCESS_TRANSACTION_BY_FILTER_PER } from "../../../../../utils/PermissionConstant";
import SizeBox from "../../../../../components/SizeBox";

const TableDeskBookings = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const dashboardDeskBooking = useSelector(dashboardDeskBookingSelector);
  const dashboardDeskBookingFilter = useSelector(
    dashboardDeskBookingFilterSelector
  );
  const userCompanyChoose = useSelector(userCompanyChooseSelector);
  const dashboardFilterDateRange = useSelector(
    dashboardFilterDateRangeSelector
  );
  const dashboardPropertyChoose = useSelector(dashboardPropertyChooseSelector);
  const propertyContext = usePermissionCheck(
    UI_ACCESS_TRANSACTION_BY_FILTER_PER
  );
  const filterListPropertySelected = useSelector(
    filterListPropertySelectedSelector
  );
  const [refresh, setRefresh] = useState(false);

  if (propertyContext) {
    useEffect(() => {
      (async () => {
        await _init();
      })();
    }, [
      dashboardDeskBookingFilter,
      userCompanyChoose,
      dashboardFilterDateRange,
      dashboardPropertyChoose,
      refresh,
      filterListPropertySelected,
    ]);
  } else {
    useEffect(() => {
      (async () => {
        await _init();
      })();
    }, [
      dashboardDeskBookingFilter,
      userCompanyChoose,
      dashboardFilterDateRange,
      refresh,
      filterListPropertySelected,
    ]);
  }

  const _init = async () => {
    try {
      if (dashboardFilterDateRange === null) {
        return;
      }
      setLoading(true);
      await dispatch<any>(dashboardDeskBookingThunk(PROPERTY, propertyContext));
    } catch (e) {
      console.log("e", e);
    } finally {
      setLoading(false);
    }
  };

  const statusField = ({
    status_id,
    refund_amount,
    refund_status_id,
  }: {
    status_id: number;
    refund_amount: string | null;
    refund_status_id: number | null;
  }) => (
    <>
      <div>{translations.roomBookingStatus[status_id]}</div>
      {refund_status_id ? (
        <div>
          ({translations.roomBookingRefundStatus[refund_status_id]}
          {refund_amount ? ` - ${refund_amount}` : ""})
        </div>
      ) : null}
    </>
  );

  const dateTimeField = ({
    start_time,
    created_time,
  }: {
    start_time: number;
    created_time: string;
  }) => (
    <>
      <div className={"table_title"}>{translations.createdTime}:</div>
      <div>{created_time}</div>
      <SizeBox height={"10px"} />
      <div className={"table_title"}>Booking Date:</div>
      <div>{start_time}</div>
    </>
  );

  const _formatInputData = () => {
    return dashboardDeskBooking.items.map(
      ({
        transaction_id,
        username,
        date,
        status_id,
        revenue,
        refund_status,
        refund_amount,
        actions,
        id,
        created_time,
      }: DeskBookingType) => {
        return {
          transaction_id,
          username,
          date: dateTimeField({ start_time: date, created_time }),
          revenue,
          statusField: statusField({
            status_id,
            refund_amount,
            refund_status_id: refund_status,
          }),
          action: (
            <BookingAction
              refresh={refresh}
              setRefresh={setRefresh}
              type={DESK_BOOKING}
              statusId={status_id}
              bookingId={id}
              actions={actions}
              onCallback={(status) => {
                dispatch(
                  dashboardDeskBookingChangeStatusAction({
                    id,
                    statusId: status,
                  })
                );
              }}
            />
          ),
        };
      }
    );
  };

  const columns = [
    { key: "hot_desk_booking_by_transaction", col: translations.transactionID },
    { key: "hot_desk_booking_by_username", col: translations.userName },
    { key: "hot_desk_booking_by_date", col: translations.date },
    { key: "hot_desk_booking_by_revenue", col: translations.revenue },
    { key: "hot_desk_booking_by_status", col: translations.status },
    { key: "hot_desk_booking_by_action", col: translations.action },
  ];

  const _onChangeSortCol = (col: any) => {
    if (dashboardDeskBookingFilter.sort_by !== col.key) {
      dispatch(
        dashboardDeskBookingFilterAction({ key: "sort_by", value: col.key })
      );
      dispatch(
        dashboardDeskBookingFilterAction({
          key: "order_by",
          value: ORDER_BY_ASC,
        })
      );
    } else {
      dispatch(
        dashboardDeskBookingFilterAction({ key: "sort_by", value: col.key })
      );
      if (dashboardDeskBookingFilter.order_by === ORDER_BY_NONE) {
        dispatch(
          dashboardDeskBookingFilterAction({
            key: "order_by",
            value: ORDER_BY_ASC,
          })
        );
      }
      if (dashboardDeskBookingFilter.order_by === ORDER_BY_ASC) {
        dispatch(
          dashboardDeskBookingFilterAction({
            key: "order_by",
            value: ORDER_BY_DESC,
          })
        );
      }
      if (dashboardDeskBookingFilter.order_by === ORDER_BY_DESC) {
        dispatch(
          dashboardDeskBookingFilterAction({
            key: "order_by",
            value: ORDER_BY_NONE,
          })
        );
      }
    }
  };

  return (
    <TableCustom
      orderBy={dashboardDeskBookingFilter.order_by}
      sortBy={dashboardDeskBookingFilter.sort_by}
      sortTable
      onChangeSortCol={_onChangeSortCol}
      tableData={dashboardDeskBooking ? _formatInputData() : []}
      columns={columns}
      loading={loading}
      totalItems={dashboardDeskBooking?.pagination?.total}
      currentPage={dashboardDeskBooking?.pagination?.page}
      showTotal
      unit={translations.transactions}
      itemPerPage={dashboardDeskBooking?.pagination?.limit}
      onChangePagination={(e: any, { activePage }: any) => {
        dispatch(
          dashboardDeskBookingFilterAction({ key: "page", value: activePage })
        );
      }}
    />
  );
};

export default TableDeskBookings;
