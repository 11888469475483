import React, { useState } from "react";
import {
  SWAL_CONFIRM_OPTION,
  SWAL_DELETE_OPTION,
} from "../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../components/SweetAlert";
import {
  cancelCreditPackageService,
  creditChangeExpiryDateService,
  creditPayCCService,
  creditRecordPaymentService,
  disablePackageRollOverService,
  stopAutoTopUpService,
} from "../../../services/BusinessServices";
import { toastError, toastSuccess } from "../../../../../components/Toast";
import DropdownCustom from "../../../../../components/DropdownCustom";
import ButtonCustom from "../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import {
  CREDIT_TRANSACTION_CANCEL_CREDIT_PACKAGE_ACTION,
  CREDIT_TRANSACTION_CHANGE_EXPIRY_ACTION,
  CREDIT_TRANSACTION_PAY_WITH_CC_ACTION,
  CREDIT_TRANSACTION_RECORD_PAYMENT_ACTION,
  CREDIT_TRANSACTION_STOP_AUTO_TOPUP_ACTION,
} from "../constants";
import { beforeToday } from "rsuite/cjs/DateRangePicker/disabledDateUtils";
import DatePickerSingle from "../../../../../components/DatePickerSingle";
import moment from "moment-timezone";
import DropdownPaymentMethod from "./DropdownPaymentMethod";

type Props = {
  id: number;
  expiryDate: number;
  onSetRefreshing: any;
  actions: string[];
  timezone: string;
  rollOverLeft: string;
};
const Action = ({
  id,
  expiryDate,
  onSetRefreshing,
  actions,
  timezone,
  rollOverLeft,
}: Props) => {
  let paymentValue = "manually";
  let expiryDateChoose = moment.unix(expiryDate).tz(timezone).toDate();
  const [disabled, setDisabled] = useState(false);

  const _onRecordPayment = async () => {
    const title = "Select payment method";
    const confirmText = "Update";
    const cancelText = "Cancel";

    const options = SWAL_CONFIRM_OPTION({
      title,
      confirmText,
      cancelText,
      html: (
        <DropdownPaymentMethod
          onChange={(value: any) => {
            paymentValue = value;
          }}
        />
      ),
      showCancelButton: true,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setDisabled(true);
        try {
          await creditRecordPaymentService({
            package_id: id,
            payment_type: paymentValue,
          });
          toastSuccess("Updated Successfully");
          onSetRefreshing();
        } catch (e: any) {
          toastError(e?.status?.message);
        } finally {
          setDisabled(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  const _onChangeExpiryDate = async () => {
    const title = "Are you sure you want to change the expiry date?";
    const confirmText = "Update";
    const cancelText = "Cancel";

    const options = SWAL_CONFIRM_OPTION({
      title,
      confirmText,
      cancelText,
      html: (
        <DatePickerSingle
          defaultValue={expiryDateChoose}
          calendarDefaultDate={expiryDateChoose}
          squareUI
          disabledDate={beforeToday()}
          oneTap
          onChange={(date: any) => {
            expiryDateChoose = date;
          }}
        />
      ),
      showCancelButton: true,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setDisabled(true);
        try {
          await creditChangeExpiryDateService({
            package_id: id,
            expiry_date: moment(expiryDateChoose)
              .endOf("date")
              .tz(timezone, true)
              .utc()
              .unix(),
          });
          toastSuccess("Changed Successfully");
          onSetRefreshing();
        } catch (e: any) {
          toastError(e?.status?.message);
        } finally {
          setDisabled(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  const _onPayWithCC = async () => {
    const title = "Are you sure you want to pay with credit card?";
    const confirmText = "Yes";
    const cancelText = "No";
    const options = SWAL_CONFIRM_OPTION({
      title,
      confirmText,
      cancelText,
      showCancelButton: true,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setDisabled(true);
        try {
          await creditPayCCService({ package_id: id });
          toastSuccess("Paid Successfully");
          onSetRefreshing();
        } catch (e: any) {
          toastError(e?.status?.message);
        } finally {
          setDisabled(false);
        }
      },
      cancelCallback: () => null,
    });
  };
  const _onStopAutoTopUp = async () => {
    const title = "Are you sure you want to stop auto-topup?";
    const confirmText = "Yes";
    const cancelText = "No";
    const options = SWAL_CONFIRM_OPTION({
      title,
      confirmText,
      cancelText,
      showCancelButton: true,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          await stopAutoTopUpService({ package_id: id, enable: 0 });
          toastSuccess("Stop Successfully");
          onSetRefreshing();
        } catch (e: any) {
          toastError(e?.status?.message);
        }
      },
      cancelCallback: () => null,
    });
  };

  const _onCancelPackage = async () => {
    const iconHtml = (
      <img
        alt="error_icon"
        src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
        className={"swal2-icon__error"}
      />
    );
    const title = "Are you sure want to cancel this credit package?";
    const confirmText = "Yes";
    const cancelText = "No";
    const options = SWAL_DELETE_OPTION({
      title,
      confirmText,
      cancelText,
      iconHtml,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setDisabled(true);
        try {
          await cancelCreditPackageService({ package_id: id });
          toastSuccess("Canceled Successfully");
          onSetRefreshing();
        } catch (e: any) {
          toastError(e?.status?.message);
        } finally {
          setDisabled(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  const _onDisableRollOver = async () => {
    const iconHtml = (
      <img
        alt="error_icon"
        src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
        className={"swal2-icon__error"}
      />
    );
    const title = "Are you sure want to disable roll over?";
    const confirmText = "Yes";
    const cancelText = "No";
    const options = SWAL_DELETE_OPTION({
      title,
      confirmText,
      cancelText,
      iconHtml,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setDisabled(true);
        try {
          await disablePackageRollOverService(String(id));
          toastSuccess("Disabled Successfully");
          onSetRefreshing();
        } catch (e: any) {
          toastError(e?.status?.message);
        } finally {
          setDisabled(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  if (!actions?.length) {
    return null;
  }

  return (
    <DropdownCustom
      disabled={disabled}
      className={"table-action-dropdown"}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        <>
          {actions.map((item, index) => {
            switch (item) {
              case CREDIT_TRANSACTION_RECORD_PAYMENT_ACTION:
                return (
                  <Dropdown.Item
                    key={index}
                    onClick={_onRecordPayment}
                    text={"Record a payment"}
                    icon={
                      <i
                        className="uil uil-edit-alt icon"
                        style={{ fontSize: "16px" }}
                      />
                    }
                  />
                );
              case CREDIT_TRANSACTION_CHANGE_EXPIRY_ACTION:
                return (
                  <Dropdown.Item
                    key={index}
                    onClick={_onChangeExpiryDate}
                    text={"Change expiry date"}
                    icon={
                      <i
                        className="uil uil-calender icon"
                        style={{ fontSize: "16px" }}
                      />
                    }
                  />
                );
              case CREDIT_TRANSACTION_PAY_WITH_CC_ACTION:
                return (
                  <Dropdown.Item
                    key={index}
                    onClick={_onPayWithCC}
                    text={"Pay with CC"}
                    icon={
                      <i
                        className="uil uil-credit-card icon"
                        style={{ fontSize: "16px" }}
                      />
                    }
                  />
                );
              // case CREDIT_TRANSACTION_STOP_AUTO_TOPUP_ACTION:
              //   return (
              //     <Dropdown.Item
              //       key={index}
              //       onClick={_onStopAutoTopUp}
              //       text={"Stop auto-topup"}
              //       icon={
              //         <i
              //           className="uil uil-sync-slash icon"
              //           style={{ fontSize: "16px" }}
              //         />
              //       }
              //     />
              //   );
              case CREDIT_TRANSACTION_CANCEL_CREDIT_PACKAGE_ACTION:
                return (
                  <Dropdown.Item
                    key={index}
                    onClick={_onCancelPackage}
                    text={"Cancel credit package"}
                    icon={
                      <i
                        className="uil uil-exchange icon"
                        style={{ fontSize: "16px" }}
                      />
                    }
                  />
                );
            }
          })}
          {rollOverLeft ? (
            <Dropdown.Item
              onClick={_onDisableRollOver}
              text={"Disable roll over"}
              icon={<i className="uil uil-toggle-off icon" />}
            />
          ) : null}
        </>
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default Action;
