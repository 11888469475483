import React, { Dispatch, SetStateAction, useState } from "react";
import InputCustom from "../../../../../../components/Input";
import { debounce } from "lodash";
import { employeeByGoogleThunk } from "../../../../thunks/EmployeeThunks";
import { useDispatch } from "react-redux";

type Props = {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
};

const SearchUser = ({ searchValue, setSearchValue }: Props) => {
  return (
    <InputCustom
      value={searchValue}
      onChange={(e: any, data: any) => {
        setSearchValue(data.value);
      }}
      placeholder={"Search by Name, Email"}
    />
  );
};

export default SearchUser;
