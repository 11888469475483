import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import {
  deskimoPropertyCompanyListService,
  deskimoPropertyListService,
  deskimoPropertyPrivateListService,
  deskimoPropertyStaffDetailService,
  deskimoPropertyStaffListService,
  getDeskimoUserReviewsService,
  getPropertyCategoriesService,
  meetingRoomDetailService,
  meetingRoomListService,
  propertyDetailService,
  propertyListService,
  propertyRewardListService,
  userReviewService,
} from "../services/PropertyServices";
import {
  deskimoPrivateOfficeListAction,
  deskimoPropertyAmenityDetailAction,
  deskimoPropertyAmenityListAction,
  deskimoPropertyCompanyListAction,
  deskimoPropertyListAction,
  deskimoPropertyScannerDetailAction,
  deskimoPropertyScannerListAction,
  deskimoPropertyStaffListAction,
  deskimoUserReviewsAction,
  getPropertyCategoriesAction,
  getPropertyIntegrationsAPIListAction,
  getPropertyIntegrationsQRCodeListAction,
  getPropertyIntegrationsWebhookListAction,
  managePMSAddCalendarAction,
  managePMSRoomListAction,
  meetingRoomDetailAction,
  meetingRoomListAction,
  partnerLogAction,
  propertyDetailAction,
  propertyListAction,
  rewardListAction,
  userReviewAction,
} from "../actions/PropertyActions";
import {
  managePMSAddCalendarService,
  managePMSRoomListService,
} from "../services/ManagePMSServices";
import {
  getPropertyAmenityDetailService,
  getPropertyAmenityListService,
} from "../services/PropertyAmenityServices";
import {
  getDeskimoPropertyScannerDetailService,
  getDeskimoPropertyScannerListService,
} from "../services/PropertyScannerServices";
import {
  partnerLogService,
  propertyIntegrationsAPICredentialService,
  propertyIntegrationsQRCodeListService,
  propertyIntegrationsWebhookService,
} from "../services/PropertyIntegrationServices";

export const getDeskimoPropertyThunk = (data: any) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    if (data?.market_code && data?.market_code === "all") {
      data = { ...data, market_code: "" };
    }
    const response = await deskimoPropertyListService(data);
    dispatch<any>(deskimoPropertyListAction(response?.data));
  };
};

export const getDeskimoPropertyCompanyThunk = (data: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await deskimoPropertyCompanyListService(data);
    dispatch<any>(deskimoPropertyCompanyListAction(response?.data));
  };
};

export const getDeskimoPropertyStaffThunk = (data: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await deskimoPropertyStaffListService(data);
    dispatch<any>(deskimoPropertyStaffListAction(response?.data));
  };
};

export const getDeskimoPropertyStaffDetailThunk = (data: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await deskimoPropertyStaffDetailService(data);
    dispatch<any>(deskimoPropertyStaffListAction(response?.data));
  };
};

export const getPropertyThunk = (data: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await propertyListService(data);
    dispatch<any>(propertyListAction(response?.data));
  };
};

export const getMeetingRoomThunk = (data: object, noCompany?: boolean) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await meetingRoomListService(data, noCompany);
    dispatch<any>(meetingRoomListAction(response?.data));
  };
};

export const getPropertyDetailThunk = (id: string) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await propertyDetailService(id);
    dispatch<any>(propertyDetailAction(response?.data));
  };
};

export const getMeetingRoomDetailThunk = (id: string) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await meetingRoomDetailService(id);
    dispatch<any>(meetingRoomDetailAction(response?.data));
  };
};

export const getUserReviewThunk = (data: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await userReviewService(data);
    dispatch<any>(userReviewAction(response?.data));
  };
};

export const getManagePMSRoomThunk = (data: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await managePMSRoomListService(data);
    dispatch<any>(managePMSRoomListAction(response?.data));
  };
};

export const addManagePMSAddCalendarThunk = (data: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await managePMSAddCalendarService(data);
    dispatch<any>(managePMSAddCalendarAction(response?.data));
  };
};

export const getDeskimoPropertyAmenityListThunk = (data: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await getPropertyAmenityListService(data);
    dispatch<any>(deskimoPropertyAmenityListAction(response?.data));
  };
};

export const getDeskimoPropertyAmenityDetailThunk = (data: string) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await getPropertyAmenityDetailService(data);
    dispatch<any>(deskimoPropertyAmenityDetailAction(response?.data));
  };
};

export const getDeskimoPropertyScannerListThunk = (data: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await getDeskimoPropertyScannerListService(data);
    dispatch<any>(deskimoPropertyScannerListAction(response?.data));
  };
};

export const getDeskimoPropertyScannerDetailThunk = (
  data: string | undefined
) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await getDeskimoPropertyScannerDetailService(
      data as string
    );
    dispatch<any>(deskimoPropertyScannerDetailAction(response?.data));
  };
};

export const getPropertyCategoriesThunk = () => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await getPropertyCategoriesService();
    dispatch<any>(getPropertyCategoriesAction(response?.data));
  };
};

export const getPropertyIntegrationsQRCodeListThunk = (propertyId: string) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await propertyIntegrationsQRCodeListService(propertyId);
    dispatch<any>(getPropertyIntegrationsQRCodeListAction(response.data));
  };
};

export const getPropertyIntegrationsAPIListThunk = (propertyId: string) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await propertyIntegrationsAPICredentialService(propertyId);
    dispatch<any>(getPropertyIntegrationsAPIListAction(response.data));
  };
};

export const getPropertyIntegrationsWebhookListThunk = (propertyId: string) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await propertyIntegrationsWebhookService(propertyId);
    dispatch<any>(getPropertyIntegrationsWebhookListAction(response.data));
  };
};

export const getPartnerLogThunk = (payload: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await partnerLogService(payload);
    dispatch<any>(partnerLogAction(response.data));
  };
};

export const deskimoUserReviewsThunk = (payload: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await getDeskimoUserReviewsService(payload);
    dispatch<any>(deskimoUserReviewsAction(response.data));
  };
};

export const getDeskimoPrivateOfficeListThunk = (data: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await deskimoPropertyPrivateListService(data);
    dispatch<any>(deskimoPrivateOfficeListAction(response?.data));
  };
};

export const rewardListThunk = (data: object) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await propertyRewardListService(data);
    dispatch<any>(rewardListAction(response?.data));
  };
};
