import { createReducer } from "@reduxjs/toolkit";
import {
  CREDIT_REMAINING_ACTION,
  GET_MY_PROFILE_ACTION,
  IS_GLOBAL_INDICATOR_ACTION,
  MODAL_ADD_EMPLOYEE_ACTION,
  MODAL_ADD_VOUCHER_ACTION,
  MODAL_DOWNLOAD_APP_ACTION,
  MODAL_IMPORT_EMPLOYEE_ACTION,
  MODAL_MY_PROFILE_ACTION,
  MODAL_NAVBAR_MOBILE_ACTION,
  MODAL_PACKAGE_TOP_UP_ACTION,
  MODAL_UPDATE_PASSWORD_ACTION,
  USER_GUIDE_ACTION,
  USER_GUIDE_SEND_BULK_INVITE_ACTION,
  GET_GLOBAL_SETTING_ACTION,
  UPDATE_MY_PROFILE_ACTION,
  HEADER_POPUP_TOPPING_UP_ACTION,
  CREDIT_PACKAGES_ACTION,
  MODAL_START_USER_GUIDE_ACTION,
  ONBOARD_PROMOTION_INFO_ACTION,
  MODAL_ADD_VOUCHER_FOR_BOOKING_ACTION,
  GET_LIST_VOUCHER_FOR_BOOKING_ACTION,
} from "../configs/Constants";
import produce from "immer";

const initializeState = {
  isModalPackageTopUp: false,
  isModalAddEmployee: false,
  isModalImportEmployee: false,
  isModalMyProfile: false,
  isModalUpdatePassword: false,
  isModalAddVoucher: false,
  isModalDownloadApp: false,
  isModalNavbarMobile: false,
  isModalCropImage: false,
  //Packages
  creditPackages: { packages: [], currency: "", trigger_credit: 0 },
  creditRemaining: null,
  myProfile: null,
  userGuide: {
    run: false,
    stepIndex: 0,
  },
  userGuideSendBulkInvite: false,
  isGlobalIndicator: false,
  globalSetting: null,
  headerPopupToppingUp: false,
  modalStartUserGuide: false,
  onboardPromotionInfo: null,
  modalAddVoucherForBooking: false,
  listVoucherForBooking: [],
};

const AppReducer = createReducer(initializeState, {
  [MODAL_PACKAGE_TOP_UP_ACTION]: (state, action) => {
    state.isModalPackageTopUp = action.payload;
  },

  [MODAL_ADD_EMPLOYEE_ACTION]: (state, action) => {
    state.isModalAddEmployee = action.payload;
  },

  [MODAL_IMPORT_EMPLOYEE_ACTION]: (state, action) => {
    state.isModalImportEmployee = action.payload;
  },

  [MODAL_MY_PROFILE_ACTION]: (state, action) => {
    state.isModalMyProfile = action.payload;
  },

  [MODAL_UPDATE_PASSWORD_ACTION]: (state, action) => {
    state.isModalUpdatePassword = action.payload;
  },

  [MODAL_ADD_VOUCHER_ACTION]: (state, action) => {
    state.isModalAddVoucher = action.payload;
  },

  [CREDIT_REMAINING_ACTION]: (state, action) => {
    state.creditRemaining = action.payload;
  },

  [MODAL_DOWNLOAD_APP_ACTION]: (state, action) => {
    state.isModalDownloadApp = action.payload;
  },

  [MODAL_NAVBAR_MOBILE_ACTION]: (state, action) => {
    state.isModalNavbarMobile = action.payload;
  },

  [GET_MY_PROFILE_ACTION]: (state, action) => {
    state.myProfile = action.payload;
  },

  [UPDATE_MY_PROFILE_ACTION]: (state, action) => {
    state.myProfile = produce(state.myProfile, (draft: any) => {
      return { ...draft, ...action.payload };
    });
  },

  [USER_GUIDE_ACTION]: (state, action) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.userGuide[action.payload.key] = action.payload.value;
  },
  [USER_GUIDE_SEND_BULK_INVITE_ACTION]: (state, action) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.userGuideSendBulkInvite = action.payload;
  },

  [IS_GLOBAL_INDICATOR_ACTION]: (state, action) => {
    state.isGlobalIndicator = action.payload;
  },

  [GET_GLOBAL_SETTING_ACTION]: (state, action) => {
    state.globalSetting = action.payload;
  },

  [HEADER_POPUP_TOPPING_UP_ACTION]: (state, action) => {
    state.headerPopupToppingUp = action.payload;
  },

  [CREDIT_PACKAGES_ACTION]: (state, action) => {
    state.creditPackages = action.payload;
  },

  [MODAL_START_USER_GUIDE_ACTION]: (state, action) => {
    state.modalStartUserGuide = action.payload;
  },

  [ONBOARD_PROMOTION_INFO_ACTION]: (state, action) => {
    state.onboardPromotionInfo = action.payload;
  },

  [MODAL_ADD_VOUCHER_FOR_BOOKING_ACTION]: (state, action) => {
    state.modalAddVoucherForBooking = action.payload;
  },

  [GET_LIST_VOUCHER_FOR_BOOKING_ACTION]: (state, action) => {
    state.listVoucherForBooking = action.payload;
  },
});

export default AppReducer;
