export const splitTextToGroupFour = (text: string) => {
  return text
    .replace(/[^\dA-Z]/g, "")
    .replace(/(.{4})/g, "$1 ")
    .trim();
};

export const addCommaToNumber = (text: string | number) => {
  const str = String(text).replace(/\D/g, "");
  return Number(str).toLocaleString();
};

export const getTwoFirstLetter = (name: string) => {
  if (!name) {
    return "";
  }
  const arrName = name.split(" ");
  const first = arrName[0];
  const second = arrName[1];
  return `${first ? first[0] : ""}${second ? second[0] : ""}`;
};

export const numberCommaFormat = (
  number: number | string,
  isDecimal = true
) => {
  try {
    if (!number) {
      return 0;
    }
    let n: number | string = String(number);

    n = n.replaceAll(",", "");

    if (isDecimal) {
      n = parseFloat(n).toFixed(2);
    } else {
      n = parseInt(n);
    }
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } catch (e) {
    return number;
  }
};

export const objectParseValid = (value: string | null) => {
  try {
    if (value === null) {
      return null;
    }
    return JSON.parse(value);
  } catch (e: any) {
    return value;
  }
};

export const isLiteralObject = function (a: any) {
  return !!a && a.constructor === Object;
};

export const validateNumber = (text: string) => {
  return text.replace(/[^.0-9]/g, "");
};
