import React from "react";

const DownloadIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33398 11.3333L1.74798 12.99C1.82009 13.2785 1.98653 13.5345 2.22087 13.7175C2.45521 13.9005 2.74399 14 3.04132 14H12.96C13.2573 14 13.5461 13.9005 13.7804 13.7175C14.0148 13.5345 14.1812 13.2785 14.2533 12.99L14.6673 11.3333M8.00065 10V2V10ZM8.00065 10L5.33398 7.33333L8.00065 10ZM8.00065 10L10.6673 7.33333L8.00065 10Z"
        stroke="#495057"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownloadIcon;
