import React, { useContext, useRef } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import * as yup from "yup";
import ButtonCustom from "../../../../../components/Button";
import { FormikValues, useFormik } from "formik";
import SizeBox from "../../../../../components/SizeBox";
import VerifyOTPResend from "./VerifyOTPResend";
import { UserDataType } from "../../../configs/DeclareType";

type Props = {
  userData: UserDataType;
  resendOTP: ({ type }: { type: number }) => void;
  loginWithOTP: (otp: number) => void;
};

const VerifyOTP = ({ userData, resendOTP, loginWithOTP }: Props) => {
  const { translations } = useContext(LocalizationContext);

  const _onSubmit = async (values: FormikValues) => {
    const otp =
      values.verify_1 + values.verify_2 + values.verify_3 + values.verify_4;
    await loginWithOTP(otp);
  };

  const {
    setFieldValue,
    values,
    handleSubmit,
    isSubmitting,
    resetForm,
    isValid,
  } = useFormik({
    initialValues: {
      verify_1: "",
      verify_2: "",
      verify_3: "",
      verify_4: "",
    },
    validationSchema: yup.object().shape({
      verify_1: yup.string().required(),
      verify_2: yup.string().required(),
      verify_3: yup.string().required(),
      verify_4: yup.string().required(),
    }),
    onSubmit: _onSubmit,
  });

  const otp_1 = useRef<any>(null);
  const otp_2 = useRef<any>(null);
  const otp_3 = useRef<any>(null);
  const otp_4 = useRef<any>(null);

  const _onFocus = () => {
    if (isValid && values.verify_1) {
      resetForm();
      otp_1.current.focus();
    }
  };

  const _onChange = (index: number) => {
    setFieldValue(`verify_${index}`, eval(`otp_${index}`).current.value);
    setFieldValue(`verify_${index + 1}`, "");
    eval(`otp_${index + 1}`).current.focus();
  };

  return (
    <div className="business-onboard">
      <div className="title">{translations.buOnboard.OTPVerification}</div>
      <div className="description">
        {translations.buOnboard.OTPVerificationDescription} +
        {userData.dialing_code} {userData.phone}
      </div>

      <div className={"business-onboard__verify"}>
        <input
          ref={otp_1}
          value={values.verify_1}
          onChange={() => {
            _onChange(1);
          }}
          maxLength={1}
          onFocus={_onFocus}
        />
        <input
          ref={otp_2}
          value={values.verify_2}
          name={"verify_2"}
          onChange={() => {
            _onChange(2);
          }}
          maxLength={1}
          onFocus={_onFocus}
        />
        <input
          ref={otp_3}
          value={values.verify_3}
          name={"verify_3"}
          onChange={() => {
            _onChange(3);
          }}
          maxLength={1}
          onFocus={_onFocus}
        />
        <input
          ref={otp_4}
          value={values.verify_4}
          name={"verify_4"}
          onChange={() => {
            setFieldValue("verify_4", otp_4.current.value);
            otp_4.current.blur();
          }}
          maxLength={1}
          onFocus={_onFocus}
        />
      </div>
      <VerifyOTPResend resendOTP={resendOTP} />
      <SizeBox height={"61px"} />
      <ButtonCustom
        className={"primary w-100"}
        height={"47px"}
        onClick={handleSubmit}
        loading={isSubmitting}
        disabled={
          isSubmitting ||
          !values.verify_1 ||
          !values.verify_2 ||
          !values.verify_3 ||
          !values.verify_4
        }
      >
        {translations.submit}
      </ButtonCustom>
    </div>
  );
};

export default VerifyOTP;
