import { Formik } from "formik";
import * as yup from "yup";
import InputCustom from "../../../../components/Input";
import ButtonCustom from "../../../../components/Button";
import { useContext } from "react";
import { LocalizationContext } from "../../../../locales/Translation";

type Props = {
  onClick: (values: any) => void;
  btnLabel?: string;
};

const ForgotPasswordForm = ({ onClick, btnLabel }: Props) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <Formik
      initialValues={{
        username: "",
      }}
      validationSchema={yup.object().shape({
        username: yup.string().email().required().label("Email"),
      })}
      onSubmit={onClick}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form>
          <InputCustom
            label={translations.emailAddress}
            value={values.username}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.username}
            error={errors.username}
            name={"username"}
          />
          <div className={"actions"}>
            <ButtonCustom
              height={"46px"}
              onClick={handleSubmit}
              loading={isSubmitting}
              className={"primary w-100"}
            >
              {btnLabel || translations.resetPassword}
            </ButtonCustom>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
