import React, { useState } from "react";
import ButtonCustom from "../../../../../../../components/Button";
import DropdownCustom from "../../../../../../../components/DropdownCustom";
import { Dropdown } from "semantic-ui-react";
import { BookingActionType } from "../../../../../configs/DeclareTypes";
import BookingActionButton from "../../../../DashboardProperty/UsageList/components/BookingActionButton";
import { onPayWithCredit } from "../../../../../services/DashboardServices";
import { DESK_BOOKING } from "../../../../../configs/Constants";

type Props = {
  bookingId: number;
  actions: BookingActionType[] | undefined;
  statusId: number;
  onCallback: (status: number) => void;
  type?: string;
  refresh?: boolean;
  setRefresh?: any;
  refundType?: any;
  priceAfterDiscount?: any;
  priceAfterDiscountAmount?: any;
  freeCreditAmount?: any;
  currency?: any;
  canPayWithCredit: boolean;
};

const BookingActions = ({
  actions,
  bookingId,
  statusId,
  onCallback,
  type,
  refresh,
  setRefresh,
  refundType,
  priceAfterDiscount,
  priceAfterDiscountAmount,
  freeCreditAmount,
  currency,
  canPayWithCredit,
}: Props) => {
  if (!canPayWithCredit && !actions?.length) {
    return null;
  }

  const [disabled, setDisabled] = useState(false);

  return (
    <DropdownCustom
      disabled={disabled}
      className={"table-action-dropdown"}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        {actions
          ? actions.map((item: BookingActionType) => {
              return (
                <BookingActionButton
                  disabled={disabled}
                  setDisabled={setDisabled}
                  key={item.name + bookingId}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  type={type}
                  item={item}
                  bookingId={bookingId}
                  onCallback={onCallback}
                  refundType={refundType}
                  priceAfterDiscount={priceAfterDiscount}
                  priceAfterDiscountAmount={priceAfterDiscountAmount}
                  freeCreditAmount={freeCreditAmount}
                  currency={currency}
                  dropdownItemStyle
                />
              );
            })
          : null}

        {canPayWithCredit ? (
          <Dropdown.Item
            onClick={() =>
              onPayWithCredit(type ? type : DESK_BOOKING, bookingId, setRefresh)
            }
            text={"Pay with credit"}
            icon={<i className="uil icon uil-bill" />}
          />
        ) : null}
      </Dropdown.Menu>
    </DropdownCustom>
  );

  // if (!canPayWithCredit && actions?.length) {
  //   return (
  //     <div className={"dashboard_approve_btn_wrapper"}>
  //       {actions.map((item: BookingActionType) => {
  //         return (
  //           <BookingActionButton
  //             key={item.name + bookingId}
  //             refresh={refresh}
  //             setRefresh={setRefresh}
  //             type={type}
  //             item={item}
  //             bookingId={bookingId}
  //             onCallback={onCallback}
  //             refundType={refundType}
  //             priceAfterDiscount={priceAfterDiscount}
  //             priceAfterDiscountAmount={priceAfterDiscountAmount}
  //             freeCreditAmount={freeCreditAmount}
  //             currency={currency}
  //           />
  //         );
  //       })}
  //     </div>
  //   );
  // }

  // if (canPayWithCredit && actions?.length) {
  //   return (
  //     <DropdownCustom
  //       className={"table-action-dropdown"}
  //       direction={"left"}
  //       icon={null}
  //       trigger={
  //         <ButtonCustom
  //           style={{ width: "38px", height: "38px" }}
  //           className={"primary-alt"}
  //         >
  //           <i className="uil uil-setting" style={{ fontSize: "21px" }} />
  //         </ButtonCustom>
  //       }
  //     >
  //       <Dropdown.Menu>
  //         {actions
  //           ? actions.map((item: BookingActionType) => {
  //               return (
  //                 <BookingActionButton
  //                   key={item.name + bookingId}
  //                   refresh={refresh}
  //                   setRefresh={setRefresh}
  //                   type={type}
  //                   item={item}
  //                   bookingId={bookingId}
  //                   onCallback={onCallback}
  //                   refundType={refundType}
  //                   priceAfterDiscount={priceAfterDiscount}
  //                   priceAfterDiscountAmount={priceAfterDiscountAmount}
  //                   freeCreditAmount={freeCreditAmount}
  //                   currency={currency}
  //                   dropdownItemStyle
  //                 />
  //               );
  //             })
  //           : null}
  //         <Dropdown.Item
  //           onClick={() =>
  //             onPayWithCredit(type ? type : DESK_BOOKING, bookingId, setRefresh)
  //           }
  //           text={"Pay with credit"}
  //           icon={<i className="uil icon uil-bill" />}
  //         />
  //       </Dropdown.Menu>
  //     </DropdownCustom>
  //   );
  // }

  // if (canPayWithCredit) {
  //   return (
  //     <ButtonCustom
  //       className={"dashboard_approve_btn"}
  //       fontSize={"12px"}
  //       onClick={() =>
  //         onPayWithCredit(type ? type : DESK_BOOKING, bookingId, setRefresh)
  //       }
  //     >
  //       {"Pay with credit"}
  //     </ButtonCustom>
  //   );
  // }

  return null;
};

export default BookingActions;
