import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, FormikValues } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toastError, toastSuccess } from "../../../../components/Toast";
import "../MarketEdit/Views/styles.scss";
import { Dimmer, DropdownItemProps, Loader } from "semantic-ui-react";
import {
  configMarketGeneralInformationThunk,
  marketToggleListThunk,
} from "../../thunks/ConfigThunks";
import { ConfigMarketGeneralInformationDataType } from "../../configs/DeclareTypes";
import {
  configMarketGeneralInformationSelector,
  marketToggleListSelector,
} from "../../selectors/ConfigSelectors";
import { getSettingsForMarketService } from "../../../App/services/AppServices";
import { ConfigMarketCreateEditContext } from "../../reducers/ConfigMarketCreateEditContext";
import ButtonCustom from "../../../../components/Button";
import { LocalizationContext } from "../../../../locales/Translation";
import {
  configCreateMarketGeneralInformationService,
  configEditMarketGeneralInformationService,
} from "../../services/ConfigServices";
import { CONFIG_MAIN_LINK } from "../../../App/configs/ListRoutes";
import Information from "./Components/Information";
import { configMarketGeneralInformationAction } from "../../actions/ConfigActions";
import { TIMEZONE_SINGAPORE } from "../../../App/configs/Constants";

const MarketCreateEditTab = () => {
  const { translations } = useContext(LocalizationContext);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const configMarketGeneralInformation: ConfigMarketGeneralInformationDataType =
    useSelector(configMarketGeneralInformationSelector);
  const marketToggleList = useSelector(marketToggleListSelector);

  const [timezoneOptions, setTimezoneOptions] = useState<DropdownItemProps[]>(
    []
  );
  const [phonePrefixOptions, setPhonePrefixOptions] = useState<
    DropdownItemProps[]
  >([]);

  const [currencyOptions, setCurrencyOptions] = useState<DropdownItemProps[]>(
    []
  );

  const [initFormValue, setInitFormValue] = useState<any>(null);

  const _getSetting = async () => {
    const { data } = await getSettingsForMarketService();

    const phonePrefixTemp: DropdownItemProps[] = [];
    data?.country_code.map(
      ({
        country_code_value,
        country_code_name,
      }: {
        country_code_value: number;
        country_code_name: string;
      }) => {
        phonePrefixTemp.push({
          id: country_code_value,
          text: country_code_name,
          value: String(country_code_value),
        });
      }
    );

    setPhonePrefixOptions(phonePrefixTemp);

    const currencyTemp: DropdownItemProps[] = [];
    const timezoneTemp: DropdownItemProps[] = [];
    data?.currency.forEach((currency: string) => {
      currencyTemp.push({ id: currency, text: currency, value: currency });
    });
    data?.timezone.forEach(({ name, key }: { name: string; key: string }) => {
      timezoneTemp.push({ id: key, text: name, value: key });
    });
    setCurrencyOptions(currencyTemp);
    setTimezoneOptions(timezoneTemp);
  };

  useEffect(() => {
    if (configMarketGeneralInformation?.market_code) {
      const initValue = {
        market_code: configMarketGeneralInformation?.market_code || "",
        market_name: configMarketGeneralInformation?.market_name || "",
        timezone:
          configMarketGeneralInformation?.timezone || TIMEZONE_SINGAPORE,
        phone_prefix: configMarketGeneralInformation?.phone_prefix || "65",
        currency: configMarketGeneralInformation?.currency || "SGD",
        tax_name: configMarketGeneralInformation?.tax_name || "",
        tax_value: configMarketGeneralInformation?.tax_value || 0,
        dkm_minimum_charge:
          configMarketGeneralInformation?.dkm_minimum_charge || 0,
        stripe_minimum_charge:
          configMarketGeneralInformation?.stripe_minimum_charge || 0,
        stripe_secret_key:
          configMarketGeneralInformation?.stripe_secret_key || "",
        cover_image: "",
        faq: configMarketGeneralInformation?.faq || "",
        terms: configMarketGeneralInformation?.terms || "",
        policy: configMarketGeneralInformation?.policy || "",
        user_guide: configMarketGeneralInformation?.user_guide || "",
        customer_support:
          configMarketGeneralInformation?.customer_support || "",
        business_registration:
          configMarketGeneralInformation?.business_registration || "",
        how_it_work: configMarketGeneralInformation?.how_it_work || "",
        invite_term: configMarketGeneralInformation?.invite_term || "",
        maximum_credit_limit:
          configMarketGeneralInformation?.maximum_credit_limit || "",
        default_display_credit:
          configMarketGeneralInformation?.default_display_credit || "",
        bank_name: configMarketGeneralInformation?.bank_name,
        bank_account: configMarketGeneralInformation?.bank_account || "",
        bank_account_holder:
          configMarketGeneralInformation?.bank_account_holder || "",
        entity_name: configMarketGeneralInformation?.entity_name || "",
        entity_email: configMarketGeneralInformation?.entity_email || "",
        entity_phone: configMarketGeneralInformation?.entity_phone || "",
        entity_address: configMarketGeneralInformation?.entity_address || "",
        is_show_on_mobile:
          configMarketGeneralInformation?.is_show_on_mobile || false,
        user_import_template:
          configMarketGeneralInformation?.user_import_template || "",
        trigger_percent: configMarketGeneralInformation?.trigger_percent || 0,
        account_manager_name:
          configMarketGeneralInformation?.account_manager_name || "",
        account_manager_email:
          configMarketGeneralInformation?.account_manager_email || "",
        account_manager_phone:
          configMarketGeneralInformation?.account_manager_phone || "",
        saas_cost_per_user:
          configMarketGeneralInformation?.saas_cost_per_user || 0,
        hot_desk_booking_delay_time:
          configMarketGeneralInformation?.hot_desk_booking_delay_time || null,
        meeting_room_booking_delay_time:
          configMarketGeneralInformation?.meeting_room_booking_delay_time ||
          null,
        force_show_top_up:
          configMarketGeneralInformation?.force_show_top_up || 0,
        whatsapp_link: configMarketGeneralInformation?.whatsapp_link || "",
        trigger_remind_top_up_credit:
          configMarketGeneralInformation?.trigger_remind_top_up_credit || 0,
        has_saas_fee: configMarketGeneralInformation?.has_saas_fee || false,
        total_saas_fee: configMarketGeneralInformation?.total_saas_fee || 0,
        toggle_config: configMarketGeneralInformation?.toggle_config || [],
        payment_repay_time:
          configMarketGeneralInformation?.payment_repay_time || 1,
        sender_voucher_value:
          configMarketGeneralInformation?.sender_voucher_value || "",
        recipient_voucher_value:
          configMarketGeneralInformation?.recipient_voucher_value || "",
      };

      const marketToggleDecorate = marketToggleList.map((toggle: any) => {
        const toggleObj = {};
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        toggleObj[toggle.key] = false;
        return toggleObj;
      });

      if (configMarketGeneralInformation?.toggle_config[0].length < 1) {
        if (marketToggleDecorate?.length > 0) {
          initValue.toggle_config = marketToggleDecorate;
        }
      } else {
        if (marketToggleDecorate?.length > 0) {
          // Create a map of keys and corresponding values from arr2
          const arr2Map = {};
          configMarketGeneralInformation?.toggle_config.forEach((obj) => {
            const key = Object.keys(obj)[0];
            const value = obj[key];
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            arr2Map[key] = value;
          });

          // Update values in arr1 based on arr2Map
          marketToggleDecorate.forEach((obj: any) => {
            const key = Object.keys(obj)[0];
            // eslint-disable-next-line no-prototype-builtins
            if (arr2Map.hasOwnProperty(key)) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              obj[key] = arr2Map[key];
            }
          });

          initValue.toggle_config = marketToggleDecorate;
        }
      }

      setInitFormValue(initValue);
    } else {
      const initValue = {
        market_code: "",
        market_name: "",
        timezone: TIMEZONE_SINGAPORE,
        phone_prefix: "65",
        currency: "SGD",
        tax_name: "",
        tax_value: 0,
        dkm_minimum_charge: 0,
        stripe_minimum_charge: 0,
        stripe_secret_key: "",
        cover_image: "",
        faq: "",
        terms: "",
        policy: "",
        user_guide: "",
        customer_support: "",
        business_registration: "",
        how_it_work: "",
        invite_term: "",
        maximum_credit_limit: "",
        default_display_credit: "",
        bank_name: configMarketGeneralInformation?.bank_name,
        bank_account: "",
        bank_account_holder: "",
        entity_name: "",
        entity_email: "",
        entity_phone: "",
        entity_address: "",
        is_show_on_mobile: false,
        user_import_template: "",
        trigger_percent: 0,
        account_manager_name: "",
        account_manager_email: "",
        account_manager_phone: "",
        saas_cost_per_user: 0,
        hot_desk_booking_delay_time: null,
        meeting_room_booking_delay_time: null,
        force_show_top_up: 0,
        whatsapp_link: "",
        trigger_remind_top_up_credit: 0,
        has_saas_fee: false,
        total_saas_fee: 0,
        toggle_config: [],
        sender_voucher_value: "",
        recipient_voucher_value: "",
      };
      const marketToggleDecorate = marketToggleList.map((toggle: any) => {
        const toggleObj = {};
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        toggleObj[toggle.key] = false;
        return toggleObj;
      });

      if (marketToggleDecorate?.length > 0) {
        initValue.toggle_config = marketToggleDecorate;
      }

      setInitFormValue(initValue);
    }
  }, [configMarketGeneralInformation, marketToggleList]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (id) {
          await dispatch<any>(configMarketGeneralInformationThunk(id));
        } else {
          dispatch(configMarketGeneralInformationAction({}));
        }
        await dispatch<any>(marketToggleListThunk());
        await _getSetting();
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const _onSubmit = async (values: FormikValues) => {
    try {
      if (!id) {
        const payload = {
          ...values,
          country_code: values.phone_prefix,
          trigger_percent: parseInt(values.trigger_percent.replaceAll(",", "")),
          trigger_remind_top_up_credit: parseInt(
            values.trigger_remind_top_up_credit
          ),
        };
        await configCreateMarketGeneralInformationService(payload);
        toastSuccess("Created successfully");
        navigate(CONFIG_MAIN_LINK);
      }
      if (typeof id === "string") {
        const payload = {
          market_id: parseInt(id),
          ...values,
          country_code: values.phone_prefix,
          trigger_percent: parseFloat(
            values.trigger_percent.replaceAll(",", "")
          ),
          trigger_remind_top_up_credit: parseInt(
            values.trigger_remind_top_up_credit
          ),
        };
        await configEditMarketGeneralInformationService(payload);
        toastSuccess("Updated successfully");
      }
    } catch (e: any) {
      toastError(e?.status?.message);
    }
  };

  return (
    <>
      {loading || !initFormValue ? (
        <Dimmer active>
          <Loader />
        </Dimmer>
      ) : (
        <div className={"section-white-box"}>
          <Formik
            enableReinitialize
            initialValues={initFormValue}
            validationSchema={yup.object().shape({
              market_code: yup
                .string()
                .required(
                  translations.validationMarket.validateMarketCodePrompt
                ),
              market_name: yup
                .string()
                .required(
                  translations.validationMarket.validateMarketNamePrompt
                ),
              timezone: yup
                .string()
                .required(translations.validationMarket.validateTimezonePrompt),
              phone_prefix: yup
                .string()
                .required(
                  translations.validationMarket.validatePhonePrefixPrompt
                ),
              currency: yup
                .string()
                .required(translations.validationMarket.validateCurrencyPrompt),
              tax_name: yup
                .string()
                .required(translations.validationMarket.validateTaxNamePrompt),
              tax_value: yup
                .number()
                .transform((value: string, originalValue: string) => {
                  if (originalValue.toString().includes(",")) {
                    return Number(originalValue.replaceAll(",", ""));
                  }
                  return Number(originalValue);
                })
                .typeError(
                  translations.validationMarket.validateTaxValueTypePrompt
                )
                .required(translations.validationMarket.validateTaxValuePrompt)
                .min(
                  0,
                  translations.validationMarket.validateTaxValueAmountPrompt
                ),
              dkm_minimum_charge: yup
                .number()
                .transform((value, originalValue: string) => {
                  if (originalValue.toString().includes(",")) {
                    return Number(originalValue.replaceAll(",", ""));
                  }
                  return Number(originalValue);
                })
                .typeError(
                  translations.validationMarket.validateDkmMinChargeTypePrompt
                )
                .required(
                  translations.validationMarket.validateDkmMinChargePrompt
                )
                .min(
                  0,
                  translations.validationMarket.validateDkmMinChargeAmountPrompt
                ),
              stripe_minimum_charge: yup
                .number()
                .transform((value, originalValue: string) => {
                  if (originalValue.toString().includes(",")) {
                    return Number(originalValue.replaceAll(",", ""));
                  }
                  return Number(originalValue);
                })
                .typeError(
                  translations.validationMarket
                    .validateStripeMinChargeTypePrompt
                )
                .required(
                  translations.validationMarket.validateStripeMinChargePrompt
                )
                .min(
                  0,
                  translations.validationMarket
                    .validateStripeMinChargeAmountPrompt
                ),
              stripe_secret_key: yup
                .string()
                .required(
                  translations.validationMarket.validateStripeSecretKeyPrompt
                ),
              cover_image: yup.string(),
              faq: yup
                .string()
                .required(translations.validationMarket.validateFaqPrompt),
              terms: yup
                .string()
                .required(translations.validationMarket.validateTermsPrompt),
              policy: yup
                .string()
                .required(translations.validationMarket.validatePolicyPrompt),
              user_guide: yup
                .string()
                .required(
                  translations.validationMarket.validateUserGuidePrompt
                ),
              customer_support: yup
                .string()
                .required(
                  translations.validationMarket.validateCustomerSupportPrompt
                ),
              business_registration: yup
                .string()
                .required(
                  translations.validationMarket.validateBizRegistrationPrompt
                ),
              how_it_work: yup
                .string()
                .required(
                  translations.validationMarket.validateHowItWorksPrompt
                ),
              invite_term: yup
                .string()
                .required(
                  translations.validationMarket.validateInviteTermPrompt
                ),
              maximum_credit_limit: yup
                .string()
                .required(
                  translations.validationMarket.validateMaxCreditLimitPrompt
                ),
              default_display_credit: yup
                .string()
                .required(
                  translations.validationMarket.validateDefaultDispCreditPrompt
                ),
              bank_name: yup
                .string()
                .required(translations.validationMarket.validateBankNamePrompt),
              bank_account: yup
                .string()
                .required(
                  translations.validationMarket.validateBankAccountPrompt
                ),
              bank_account_holder: yup
                .string()
                .required(
                  translations.validationMarket.validateBankAccountHolderPrompt
                ),
              entity_name: yup
                .string()
                .required(translations.validationMarket.validateEntNamePrompt),
              entity_email: yup
                .string()
                .email(
                  translations.validationMarket.validateEntEmailFormatPrompt
                )
                .required(translations.validationMarket.validateEntEmailPrompt),
              entity_phone: yup
                .string()
                .required(translations.validationMarket.validateEntPhonePrompt),
              entity_address: yup
                .string()
                .required(
                  translations.validationMarket.validateEntAddressPrompt
                ),
              user_import_template: yup
                .string()
                .required(
                  translations.validationMarket.validateUserImportTempPrompt
                ),
              trigger_percent: yup
                .number()
                .transform((value, originalValue: string) => {
                  if (originalValue.toString().includes(",")) {
                    return Number(originalValue.replaceAll(",", ""));
                  }
                  return Number(originalValue);
                })
                .typeError(
                  translations.validationMarket.validateRechargeAmountTypePrompt
                )
                .required(
                  translations.validationMarket.validateRechargeAmountPrompt
                )
                .min(
                  0,
                  translations.validationMarket
                    .validateRechargeAmountPositivePrompt
                ),
              account_manager_name: yup.string().nullable(),
              account_manager_phone: yup.string().nullable(),
              account_manager_email: yup
                .string()
                .email(
                  translations.validationMarket
                    .validateAccManagerEmailFormatPrompt
                )
                .nullable(),
              saas_cost_per_user: yup
                .number()
                .transform((value, originalValue: string) => {
                  if (originalValue.toString().includes(",")) {
                    return Number(originalValue.replaceAll(",", ""));
                  }
                  return Number(originalValue);
                })
                .typeError(
                  translations.validationMarket.validateSaasCostTypePrompt
                )
                .min(
                  0,
                  translations.validationMarket.validateSaasCostAmountPrompt
                )
                .nullable(),
              hot_desk_booking_delay_time: yup
                .number()
                .nullable(true)
                .typeError("Please input number only"),
              meeting_room_booking_delay_time: yup
                .number()
                .nullable(true)
                .typeError("Please input number only"),
              trigger_remind_top_up_credit: yup
                .number()
                .typeError(
                  translations.validationMarket
                    .validateTriggerRemindTopUpCreditTypePrompt
                )
                .min(
                  0,
                  translations.validationMarket
                    .validateTriggerRemindTopUpCreditAmountPrompt
                )
                .required(
                  translations.validationMarket.validateTriggerRemindTopUpCredit
                ),
              payment_repay_time: yup
                .number()
                .required()
                .label("Limit Payment Retry"),
              sender_voucher_value: yup
                .string()
                .required()
                .label(translations.editMarket.senderVoucherValue),
              recipient_voucher_value: yup
                .string()
                .required()
                .label(translations.editMarket.recipientVoucherValue),
            })}
            onSubmit={_onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              resetForm,
            }) => (
              <ConfigMarketCreateEditContext.Provider
                value={{
                  values,
                  errors,
                  touched,
                  handleChange,
                  isSubmitting,
                  setFieldValue,
                  currencyOptions,
                  timezoneOptions,
                  phonePrefixOptions,
                }}
              >
                <Information marketId={id} />
                <div className={"section-white-box-actions"}>
                  <ButtonCustom
                    className={"cancel mr-10px"}
                    onClick={resetForm}
                  >
                    {translations.cancel}
                  </ButtonCustom>
                  <ButtonCustom
                    className={"primary mr-0"}
                    onClick={handleSubmit}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    {translations.save}
                  </ButtonCustom>
                </div>
              </ConfigMarketCreateEditContext.Provider>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default MarketCreateEditTab;
