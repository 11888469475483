import React from "react";
import RichTextEditor, { EditorValue, ToolbarConfig } from "react-rte";
import "./styles.scss";

type ChangeHandler = (value: EditorValue) => any;

type Props = {
  value: EditorValue;
  onChange?: ChangeHandler | undefined;
};

const toolbarConfig: ToolbarConfig = {
  BLOCK_TYPE_DROPDOWN: [],
  display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS"],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

const RichText = ({ value, onChange }: Props) => {
  return (
    <RichTextEditor
      toolbarConfig={toolbarConfig}
      toolbarClassName={"custom-toolbar"}
      editorClassName={"custom-editor"}
      className={"custom-rich-text-editor"}
      value={value}
      onChange={onChange}
    />
  );
};

export default RichText;
