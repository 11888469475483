import { createContext } from "react";
import { FILTER_LEASE_DEFAULT } from "../../configs/Constants";

const OfficeLeasesContext = createContext({
  businessCompanyOptions: [],
  tableData: null,
  loading: false,
  filters: FILTER_LEASE_DEFAULT,
  setFilters: (data: object) => null,
  leaseChooseId: null,
  setLeaseChooseId: (value: number | null) => null,
  modalOpen: false,
  setModalOpen: (value: boolean) => null,
  modalDepositInfo: {
    open: false,
    leaseId: null,
    type: "BUSINESS",
    currency: null,
  },
  setModalDepositInfo: (value: any) => null,
  onSetRefreshing: () => null,
});

export default OfficeLeasesContext;
