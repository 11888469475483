import React, { useContext, useState } from "react";
import ButtonCustom from "../../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import { useDispatch } from "react-redux";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import { SWAL_DELETE_OPTION } from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useNavigate } from "react-router-dom";
import { CONFIG_MARKET_EDIT_GENERAL_INFORMATION_LINK } from "../../../../../App/configs/ListRoutes";
import { campaignListUpdateStatusAction } from "../../../../../Marketing/actions/MarketingActions";

import { configUpdateMarketStatusService } from "../../../../services/ConfigServices";
import { configUpdateMarketStatusAction } from "../../../../actions/ConfigActions";

type Props = {
  id: number;
  status: any;
};

const Action = ({ id, status }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { translations } = useContext(LocalizationContext);
  const [disabled, setDisabled] = useState(false);

  const isUnpublish = () => {
    return parseInt(status) === 0;
  };

  const _onEditMarket = async () => {
    navigate(
      CONFIG_MARKET_EDIT_GENERAL_INFORMATION_LINK.replaceAll(":id", String(id))
    );
  };

  const _onChangeMarketStatus = async () => {
    try {
      const icon = null;
      const title = isUnpublish()
        ? "Are you sure want to publish this market?"
        : "Are you sure want to un-publish this market?";
      const confirmText = isUnpublish() ? "Publish" : "Un-publish";
      const cancelText = translations.cancel;
      const confirmClass = isUnpublish() ? "primary" : "danger";
      const options = SWAL_DELETE_OPTION({
        title,
        icon,
        confirmText,
        confirmClass,
        cancelText,
      });
      SweetAlertCommon({
        options,
        confirmCallback: async () => {
          setDisabled(true);
          try {
            if (isUnpublish()) {
              await configUpdateMarketStatusService(id.toString());
              dispatch(configUpdateMarketStatusAction(id));
              toastSuccess("Publish successfully");
            } else {
              await configUpdateMarketStatusService(id.toString());
              dispatch(configUpdateMarketStatusAction(id));
              toastSuccess("Un-publish successfully");
            }
            dispatch(campaignListUpdateStatusAction(id));
          } catch (response: any) {
            toastError(response?.status?.message);
          } finally {
            setDisabled(false);
          }
        },
        cancelCallback: () => null,
      });
    } catch (e) {
      console.error(e);
    }
  };

  // TODO: Delete Pending
  // const _onDelete = async () => {
  //   const iconHtml = (
  //     <img
  //       src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
  //       className={"swal2-icon__error"}
  //     />
  //   );
  //   const title = "Are you sure want to delete this campaign?";
  //   const confirmText = translations.delete;
  //   const cancelText = translations.cancel;
  //   const options = SWAL_DELETE_OPTION({
  //     title,
  //     iconHtml,
  //     confirmText,
  //     cancelText,
  //   });
  //   SweetAlertCommon({
  //     options,
  //     confirmCallback: async () => {
  //       try {
  //         await deleteCampaignService(id);
  //         dispatch(campaignListRemoveItemAction(id));
  //         toastSuccess("Delete successfully!");
  //       } catch (response: any) {
  //         toastError(response?.status?.message);
  //       }
  //     },
  //     cancelCallback: () => null,
  //   });
  // };

  return (
    <DropdownCustom
      disabled={disabled}
      className={"table-action-dropdown"}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={_onEditMarket}
          text={translations.editMarket.editMarketTitle}
          icon={
            <i className="uil uil-edit icon" style={{ fontSize: "16px" }} />
          }
        />
        <Dropdown.Item
          onClick={_onChangeMarketStatus}
          text={isUnpublish() ? "Publish" : "Un-publish"}
          icon={
            <img src={PUBLIC_URL + "/assets/icons/icon-toggle-slider.svg"} />
          }
        />
        {/*<Dropdown.Item*/}
        {/*  onClick={_onDelete}*/}
        {/*  text={"Delete Market"}*/}
        {/*  icon={*/}
        {/*    <i*/}
        {/*      className="uil uil-trash-alt icon"*/}
        {/*      style={{ fontSize: "16px" }}*/}
        {/*    />*/}
        {/*  }*/}
        {/*/>*/}
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default Action;
