import React, { useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./styles.scss";
import { DROPZONE_MAX_FILE_3MB } from "../../../../../App/configs/DropzoneConfig";
import { toastError } from "../../../../../../components/Toast";
import { configUpdateCoverImageService } from "../../../../services/ConfigServices";
import { LocalizationContext } from "../../../../../../locales/Translation";

const UploadImageSection = ({
  touched,
  error,
  setFieldValue,
  imageUploaded,
  setImageUploaded,
}: any) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const { translations } = useContext(LocalizationContext);
  const [imgSrc, setImgSrc] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    accept: [".png", ".gif", ".jpg", ".jpeg"],
    maxSize: DROPZONE_MAX_FILE_3MB,
    useFsAccessApi: false,
    onDrop: async (acceptedImageFiles: any) => {
      if (acceptedImageFiles?.length > 0) {
        const imageData = new FormData();
        imageData.append("cover_image", acceptedImageFiles[0]);

        let data: any;
        try {
          const response = await configUpdateCoverImageService(imageData);
          data = {
            cover_image_uri: response?.data?.cover_image_uri,
            uri: response?.data?.uri,
          };
          setFieldValue("cover_image", data?.cover_image_uri);
          setImgSrc(data?.uri);
        } catch (error) {
          console.error(error);
        }
        setIsDragOver(false);
      }
    },

    onDropRejected: (imageRejections: any) => {
      let isImageTooLarge;
      imageRejections.forEach((image: any) => {
        isImageTooLarge =
          image.imageRejections.filter(
            (error: any) => error.code === "file-too-large"
          ).length > 0;
        if (isImageTooLarge) {
          toastError(`Image ${image.file.name} is larger than 3MB`);
        }
      });
    },

    onDragOver: () => {
      setIsDragOver(true);
    },

    onDragLeave: () => {
      setIsDragOver(false);
    },
  });

  return (
    <>
      <div className={"d-flex"}>
        {imgSrc ? (
          <div className={"position-relative"} style={{ marginRight: 8 }}>
            <button
              className={"delete-image-button"}
              onClick={() => setFieldValue("cover_image", null)}
            >
              <i className={"uil uil-times-circle delete-icon"} />
            </button>
            <img
              src={imgSrc}
              width={108}
              height={87}
              className={"image-view"}
              alt="market cover image"
            />
          </div>
        ) : null}
        {imageUploaded && (
          <div className={"position-relative"}>
            <button
              className={"delete-image-button"}
              onClick={() => setImageUploaded(null)}
            >
              <i className={"uil uil-times-circle delete-icon"} />
            </button>
            <img
              src={imageUploaded.uri}
              width={108}
              height={87}
              className={"image-view"}
              alt="market cover image"
            />
          </div>
        )}
      </div>
      <div
        {...getRootProps({
          className:
            (touched && error
              ? "dropzone-box-image-section-when-error-touch"
              : "dropzone-box-image-section") +
            (isDragOver ? " drag-over" : ""),
        })}
      >
        <input {...getInputProps()} />
        <div className={"title"}>
          {translations.editMarket.dropGuideLineOneLabel} <br />
          {translations.editMarket.dropGuideLineTwoLabel}
        </div>
        <div className={"secondary-title market-create-edit-edtion"}>
          <div className="file-type-guide">JPG, GIF & PNG.</div>
          <div>{translations.editMarket.maxFileSizeGuide}</div>
        </div>
        {/*<div className={"secondary-title"}>Maximum 10 images</div>*/}
      </div>
      <div>
        {touched && error ? (
          <span className={"error-status"}>{error}</span>
        ) : null}
      </div>
    </>
  );
};

export default UploadImageSection;
