import React, { useContext } from "react";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import GoogleLogin from "react-google-login";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { inviteEmployeeAfterLoginGoogle } from "../../../../services/EmployeeServices";
import { GATrackingEvent } from "../../../../../../services/GATrackingService";
import classNames from "classnames";

type Props = {
  buttonClass?: string;
};

const ButtonInviteEmployeeByGoogle = ({ buttonClass }: Props) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
      render={(renderProps) => (
        <button
          className={classNames("btn-google", buttonClass)}
          onClick={() => {
            renderProps.onClick();
            GATrackingEvent("gg_integration_login");
          }}
          disabled={renderProps.disabled}
        >
          <img src={PUBLIC_URL + "/assets/icons/icon-google.svg"} />
          {translations.importFromGoogleWorkspace}
        </button>
      )}
      onSuccess={(response) =>
        inviteEmployeeAfterLoginGoogle({ translations, response })
      }
      cookiePolicy={"single_host_origin"}
      uxMode={"popup"}
      responseType="code"
      accessType={"offline"}
      prompt={"consent"}
      scope={"https://www.googleapis.com/auth/admin.directory.user"}
      isSignedIn={false}
    />
  );
};

export default ButtonInviteEmployeeByGoogle;
