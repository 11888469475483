import React, { useContext } from "react";
import ButtonCustom from "../../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import {
  modalDetailEmployeeAction,
  modalEditEmployeeAction,
} from "../../../../actions/EmployeeActions";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import { useDispatch } from "react-redux";
import {
  employeeDetailPropertyThunk,
  employeesThunk,
} from "../../../../thunks/EmployeeThunks";
import {
  employeePropertyActiveService,
  employeePropertyDeActiveService,
  employeePropertyDeleteService,
  employeePropertyReInviteService,
  employeePropertyUpdateService,
} from "../../../../services/EmployeeServices";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import {
  SWAL_CUSTOM_CONTENT,
  SWAL_DELETE_OPTION,
} from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { toastError } from "../../../../../../components/Toast";
import SwalSuccess from "../../../../../../components/SweetAlert/Templates/SwalSuccess";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { PROPERTY } from "../../../../../Dashboard/configs/Constants";
import { ROLE_PROPERTY_ADMIN_ID } from "../../../../configs/Constants";

type Props = {
  userId: number;
  status?: any;
  setLoading?: any;
  isAdmin: boolean;
};

const Action = ({ userId, status, setLoading, isAdmin }: Props) => {
  const dispatch = useDispatch();
  const { translations } = useContext(LocalizationContext);

  const _onEditUser = async () => {
    try {
      await dispatch<any>(employeeDetailPropertyThunk(userId));
      dispatch(modalEditEmployeeAction(true));
    } catch (e) {
      console.log(e);
    }
  };

  const _reload = async () => {
    try {
      setLoading(true);
      await dispatch<any>(employeesThunk(PROPERTY));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const _onDetail = async () => {
    try {
      await dispatch<any>(employeeDetailPropertyThunk(userId));
      dispatch(modalDetailEmployeeAction(true));
    } catch (e: any) {
      toastError(translations.errorMessage.contactAdmin);
      console.log(e);
    }
  };

  const _onReinvite = async () => {
    try {
      await employeePropertyReInviteService({ user_id: userId });
      SweetAlertCommon({
        options: SWAL_CUSTOM_CONTENT({
          content: <SwalSuccess title={translations.inviteResent} />,
          cancelButtonClass: "d-none",
          denyButtonClass: "d-none",
          confirmButtonText: (
            <ButtonCustom className={"primary w-100"} height={"36px"}>
              {translations.done}
            </ButtonCustom>
          ),
        }),
        confirmCallback() {
          console.log("Submit");
        },
        cancelCallback() {
          console.log("Cancel");
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const _onDeactive = async () => {
    try {
      const icon = null;
      const title = isDeactiveUser()
        ? translations.areYouSureYouWantToActivateThisUser
        : translations.areYouSureYouWantToDeactivateThisUser;
      const confirmText = isDeactiveUser()
        ? translations.activate
        : translations.deactivate;
      const cancelText = translations.cancel;
      const confirmClass = isDeactiveUser() ? "primary" : "danger";
      const options = SWAL_DELETE_OPTION({
        title,
        icon,
        confirmText,
        confirmClass,
        cancelText,
      });
      SweetAlertCommon({
        options,
        confirmCallback: async () => {
          try {
            if (isDeactiveUser()) {
              await employeePropertyActiveService(String(userId));
            } else {
              await employeePropertyDeActiveService(String(userId));
            }
            await _reload();
          } catch (response: any) {
            toastError(response?.status?.message);
          }
        },
        cancelCallback: () => null,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const _onMakeAdmin = async () => {
    const data = await dispatch<any>(employeeDetailPropertyThunk(userId));
    const cancelText = translations.cancel;
    const options = SWAL_DELETE_OPTION({
      title: translations.areYouSureYouWantToMakeThisUserAdmin,
      icon: null,
      confirmText: translations.makeAdmin,
      confirmClass: "primary",
      cancelText,
    });
    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        await changeEmployeeRole(data);
      },
      cancelCallback: () => null,
    });
  };

  const changeEmployeeRole = async (data: object) => {
    setLoading(true);
    try {
      const payload = {
        ...data,
        make_admin: true,
        role_id: ROLE_PROPERTY_ADMIN_ID,
      };
      await employeePropertyUpdateService(payload);
      await _reload();
    } catch (response: any) {
      toastError(response?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const _onDelete = async () => {
    const iconHtml = (
      <img
        src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
        className={"swal2-icon__error"}
      />
    );
    const title = translations.areYouSureWantToDeleteThisAccount;
    const confirmText = translations.delete;
    const cancelText = translations.cancel;
    const options = SWAL_DELETE_OPTION({
      title,
      iconHtml,
      confirmText,
      cancelText,
    });
    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          await employeePropertyDeleteService({ user_id: userId });
          await _reload();
        } catch (response: any) {
          toastError(response?.status?.message);
        }
      },
      cancelCallback: () => null,
    });
  };

  const isDeactiveUser = () => {
    return parseInt(status) === 2;
  };

  return (
    <DropdownCustom
      className={"table-action-dropdown"}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={_onDetail}
          text={translations.viewDetails}
          icon={<img src={PUBLIC_URL + "/assets/icons/icon-message.svg"} />}
        />
        <Dropdown.Item
          onClick={_onEditUser}
          text={translations.editUser}
          icon={
            <i className="uil uil-edit icon" style={{ fontSize: "16px" }} />
          }
        />
        <Dropdown.Item
          onClick={_onReinvite}
          text={translations.reInviteUser}
          icon={<img src={PUBLIC_URL + "/assets/icons/icon-plus-circle.svg"} />}
        />
        <Dropdown.Item
          onClick={_onDeactive}
          text={
            isDeactiveUser()
              ? translations.activateUser
              : translations.deactivateUser
          }
          icon={
            <img src={PUBLIC_URL + "/assets/icons/icon-toggle-slider.svg"} />
          }
        />
        {!isAdmin && (
          <Dropdown.Item
            onClick={_onMakeAdmin}
            text={translations.makeAdmin}
            icon={
              <img src={PUBLIC_URL + "/assets/icons/icon-admin-outlined.svg"} />
            }
          />
        )}
        <Dropdown.Item
          onClick={_onDelete}
          text={translations.deleteUser}
          icon={
            <i
              className="uil uil-trash-alt icon"
              style={{ fontSize: "16px" }}
            />
          }
        />
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default Action;
