import React, { useContext, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import {
  PRODUCT_TYPE_PARTNER,
  TAB_DESK_BOOKINGS_INDEX,
  TAB_DESK_USAGE_INDEX,
  TAB_ROOM_BOOKINGS_INDEX,
} from "../../../../configs/Constants";
import Search from "../../../Dashboard/UsageList/Search";
import ButtonCustom from "../../../../../../components/Button";
import classNames from "classnames";
import FilterDeskUsage from "../../../Dashboard/UsageList/FilterDeskUsage";
import FilterDeskBooking from "../../../Dashboard/UsageList/FilterDeskBooking";
import FilterRoomBooking from "../../../Dashboard/UsageList/FilterRoomBooking";
import TableDeskUsage from "./TableDeskUsage";
import TableDeskBookings from "./TableDeskBookings";
import TableRoomBookings from "./TableRoomBookings";

const UsageList = () => {
  const { translations } = useContext(LocalizationContext);
  const [tabIndex, setTabIndex] = useState(TAB_DESK_BOOKINGS_INDEX);
  const _onChangeTab = (value: number) => {
    setTabIndex(value);
  };

  return (
    <>
      <div className={"filter-section"}>
        <Search />
        <div className={"button-group yellow"}>
          <ButtonCustom
            height={"36px"}
            className={classNames({
              active: tabIndex === TAB_DESK_BOOKINGS_INDEX,
            })}
            onClick={() => _onChangeTab(TAB_DESK_BOOKINGS_INDEX)}
          >
            {translations.deskBookings}
          </ButtonCustom>
          <ButtonCustom
            height={"36px"}
            className={classNames({
              active: tabIndex === TAB_ROOM_BOOKINGS_INDEX,
            })}
            onClick={() => _onChangeTab(TAB_ROOM_BOOKINGS_INDEX)}
          >
            {translations.roomBookings}
          </ButtonCustom>
          <ButtonCustom
            height={"36px"}
            className={classNames({
              active: tabIndex === TAB_DESK_USAGE_INDEX,
            })}
            onClick={() => _onChangeTab(TAB_DESK_USAGE_INDEX)}
          >
            {translations.deskWalkIn}
          </ButtonCustom>
        </div>

        {tabIndex === TAB_DESK_USAGE_INDEX ? (
          <FilterDeskUsage productType={PRODUCT_TYPE_PARTNER} />
        ) : tabIndex === TAB_DESK_BOOKINGS_INDEX ? (
          <FilterDeskBooking productType={PRODUCT_TYPE_PARTNER} />
        ) : tabIndex === TAB_ROOM_BOOKINGS_INDEX ? (
          <FilterRoomBooking productType={PRODUCT_TYPE_PARTNER} />
        ) : null}
      </div>

      <div className={"dashboard_table"}>
        {tabIndex === TAB_DESK_USAGE_INDEX ? (
          <TableDeskUsage />
        ) : tabIndex === TAB_DESK_BOOKINGS_INDEX ? (
          <TableDeskBookings />
        ) : tabIndex === TAB_ROOM_BOOKINGS_INDEX ? (
          <TableRoomBookings />
        ) : null}
      </div>
    </>
  );
};

export default UsageList;
