import { useState } from "react";
import Button from "../../../../../components/Button";
import InputCustom from "../../../../../components/Input";

const WebhookUrl = ({ url, onSaveNewUrl, webhook }: any) => {
  const [editMode, setEditMode] = useState(false);
  const [inputValue, setInputValue] = useState(webhook?.webhook_url);
  const [loading, setLoading] = useState(false);

  return (
    <div className={"webhook-edit-inline"}>
      {editMode ? (
        <InputCustom
          value={inputValue}
          onChange={(e: any, data: any) => {
            setInputValue(data?.value);
          }}
          containerClass={"mb-0"}
        />
      ) : (
        <div className={"webhook-edit-inline__text"}>
          {url?.length > 1 ? (
            <span className={"text-link fw-400"}>{url}</span>
          ) : (
            url
          )}
        </div>
      )}

      {editMode ? (
        <div className={"webhook-edits"}>
          <Button
            onClick={() => setEditMode(false)}
            height={"32px"}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              setLoading(true);
              await onSaveNewUrl(webhook?.id, inputValue);
              setEditMode(false);
              setLoading(false);
            }}
            height={"32px"}
            className={"primary"}
            loading={loading}
            disabled={loading}
          >
            Save
          </Button>
        </div>
      ) : (
        <i onClick={() => setEditMode(true)} className="uil uil-edit" />
      )}
    </div>
  );
};

export default WebhookUrl;
