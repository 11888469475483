import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  propertyFilterSelector,
  propertyListSelector,
} from "../../selectors/BookSelectors";
import NoResult from "../Components/NoResult";
import {
  BookPropertyExtraType,
  BookPropertyType,
} from "../../configs/DeclareType";
import DeskListRender from "./DeskListRender";
import DeskListFavorite from "./DeskListFavorite";
import ButtonCustom from "../../../../components/Button";
import { propertyFilterAction } from "../../actions/BookActions";
import CardPlaceholder from "./CardPlaceholder";

type Props = {
  loading: boolean;
  loadMoreLoading: boolean;
};

const DeskList = ({ loading, loadMoreLoading }: Props) => {
  const dispatch = useDispatch();
  const { results, has_next_page, next_page }: BookPropertyExtraType =
    useSelector(propertyListSelector);

  const propertyFilter = useSelector(propertyFilterSelector);

  const _loadMore = () => {
    const filters = {
      ...propertyFilter,
      page: next_page,
    };
    dispatch(propertyFilterAction(filters));
  };

  if (loading) {
    return <CardPlaceholder />;
  }

  if (!results.length && !loading) {
    return <NoResult />;
  }

  return (
    <>
      <DeskListFavorite />
      <DeskListRender />

      {has_next_page ? (
        <div className={"text-center"}>
          <ButtonCustom
            className={"outline-primary"}
            height={"36px"}
            onClick={_loadMore}
            disabled={loadMoreLoading}
            loading={loadMoreLoading}
          >
            Load more
          </ButtonCustom>
        </div>
      ) : null}
    </>
  );
};

export default DeskList;
