import React from "react";
import { DeskimoUserCompanyType } from "../../../../configs/DeclareTypes";
import { Link } from "react-router-dom";
import {
  BUSINESS_COMPANY_DETAIL_LINK,
  DESKIMO_PROPERTY_COMPANY_DETAIL_LINK,
} from "../../../../../App/configs/ListRoutes";
import {
  ROLE_BUSINESS_ADMIN_ID,
  ROLE_BUSINESS_USER_ID,
  ROLE_PROPERTY_ADMIN_ID,
  ROLE_PROPERTY_STAFF_ID,
} from "../../../../configs/Constants";

type Props = {
  company: DeskimoUserCompanyType[];
};

const Company = ({ company }: Props) => {
  return (
    <>
      {company.map(({ company_name, company_id, role, role_id }, index) => {
        const text = `${company_name} ${role ? "(" + role + ")" : ""}`;
        if (
          role_id === ROLE_BUSINESS_ADMIN_ID ||
          role_id === ROLE_BUSINESS_USER_ID
        ) {
          return (
            <Link
              className={"d-block"}
              to={BUSINESS_COMPANY_DETAIL_LINK.replaceAll(
                ":id",
                String(company_id)
              )}
              key={index}
            >
              {text}
            </Link>
          );
        }

        if (
          role_id === ROLE_PROPERTY_ADMIN_ID ||
          role_id === ROLE_PROPERTY_STAFF_ID
        ) {
          return (
            <>
              <Link
                className={"d-block"}
                to={DESKIMO_PROPERTY_COMPANY_DETAIL_LINK.replaceAll(
                  ":id",
                  String(company_id)
                )}
                key={index}
              >
                {text}
              </Link>
            </>
          );
        }

        return <div key={index}>{text}</div>;
      })}
    </>
  );
};

export default Company;
