import React, { useContext } from "react";
import MasterTemplate from "../../../App/views/MasterTemplate";
import { Link, Route, useParams } from "react-router-dom";
import { ROLE_DESKIMO_ADMIN_ID } from "../../../User/configs/Constants";
import "./styles.scss";
import { Tab } from "semantic-ui-react";
import {
  CONFIG_MAIN_LINK,
  CONFIG_MARKET_EDIT_GENERAL_INFORMATION_LINK,
  CONFIG_MARKET_EDIT_LANDMARK_LINK,
  CONFIG_MARKET_EDIT_ONBOARD_PROMOTION,
  CONFIG_MARKET_EDIT_PUBLIC_HOLIDAYS_LINK,
} from "../../../App/configs/ListRoutes";
import MarketCreateEditTab from "../MarketCreateEdit/MarketCreateEditTab";
import LandmarkManagementTab from "./Views/LandmarkManagementTab";
import PublicHolidaysManagementTab from "./Views/PublicHolidaysManagementTab";
import { LocalizationContext } from "../../../../locales/Translation";
import ButtonGoBack from "../../../../components/ButtonGoBack";
import FreeTrial from "./Views/OnboardPromotion/FreeTrial";
import OnboardPromotion from "./Views/OnboardPromotion";

type Props = {
  tabIndex?: number;
};

const MarketEdit = ({ tabIndex }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const { id } = useParams();
  const panes = [
    {
      menuItem: <ButtonGoBack route={CONFIG_MAIN_LINK} className={"mb-0"} />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab1",
        to: CONFIG_MARKET_EDIT_GENERAL_INFORMATION_LINK.replaceAll(
          ":id",
          id ? id : ""
        ),
        content: translations.generalInformationTabTitle,
        exact: true,
      },
      pane: <Route path={CONFIG_MARKET_EDIT_GENERAL_INFORMATION_LINK} />,
      render: () => <MarketCreateEditTab />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab2",
        to: CONFIG_MARKET_EDIT_PUBLIC_HOLIDAYS_LINK.replaceAll(
          ":id",
          id ? id : ""
        ),
        content: translations.publicHolidaysTabTitle,
        exact: true,
      },
      pane: <Route path={CONFIG_MARKET_EDIT_PUBLIC_HOLIDAYS_LINK} />,
      render: () => <PublicHolidaysManagementTab />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab3",
        to: CONFIG_MARKET_EDIT_LANDMARK_LINK.replaceAll(":id", id ? id : ""),
        content: translations.landmarkTabTitle,
        exact: true,
      },
      pane: <Route path={CONFIG_MARKET_EDIT_LANDMARK_LINK} />,
      render: () => <LandmarkManagementTab />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab4",
        to: CONFIG_MARKET_EDIT_ONBOARD_PROMOTION.replaceAll(
          ":id",
          id ? id : ""
        ),
        content: translations.onboardPromotionTabTitle,
        exact: true,
      },
      pane: <Route path={CONFIG_MARKET_EDIT_ONBOARD_PROMOTION} />,
      render: () => <OnboardPromotion />,
    },
  ];
  return (
    <MasterTemplate roleAccept={[ROLE_DESKIMO_ADMIN_ID]}>
      <Tab
        menu={{ text: true }}
        activeIndex={tabIndex}
        panes={panes}
        className={"tab-custom"}
      />
    </MasterTemplate>
  );
};

export default MarketEdit;
