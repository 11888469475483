import exp from "constants";

export const API_DASHBOARD_STATISTIC = "/api/v1/company-statistic";
export const API_DASHBOARD_STATISTIC_PROPERTY =
  "/api/v1/property-com/statistic";

// Desk usage
export const API_DESK_USAGE = "/api/v1/desk-usage";
export const API_DESK_USAGE_STATUS = "/api/v1/total-desk-usage-by-status";

export const API_DESK_USAGE_PROPERTY = "/api/v1/property-com/visits";
export const API_DESK_USAGE_STATUS_PROPERTY =
  "/api/v1/property-com/visits-amout-by-status";

// Room booking
export const API_ROOM_BOOKING = "/api/v1/room-booking";
export const API_ROOM_BOOKING_STATUS = "/api/v1/total-room-booking-by-status";

export const API_ROOM_BOOKING_PROPERTY = "/api/v1/property-com/room-bookings";
export const API_ROOM_BOOKING_STATUS_PROPERTY =
  "/api/v1/property-com/total-room-booking-by-status";

export const API_ONBOARDING_PROMOTION = "api/v1/company-onboarding-promotion";

// Desk booking
export const API_DESK_BOOKING = "/api/v1/hot-desk-bookings/business";
export const API_DESK_BOOKING_STATUS =
  "/api/v1/total-hot-desk-bookings-by-status/business";

export const API_DESK_BOOKING_PROPERTY =
  "/api/v1/property-com/hot-desk-bookings";
export const API_DESK_BOOKING_STATUS_PROPERTY =
  "/api/v1/property-com/total-desk-usage-by-status";

export const API_PROPERTY_BY_COMPANY =
  "/api/v1/property-com/%companyId%/property-list";

export const API_PROPERTY_PENDING_BOOKINGS =
  "/api/v1/property-com/%companyId%/%propertyId%/pending-bookings";

export const API_MANUAL_CHECK_IN =
  "/api/v1/property-com/visits/checkin-manually";

export const API_PARTNER_MANUAL_CHECK_IN = "/api/v3/walk-ins/check-in-manually";

export const API_MANUAL_CHECK_OUT =
  "/api/v1/property-com/visits/checkout-manually";

export const API_DELETE_VISIT = "/api/v3/visit/%visitId%";
export const API_PAY_WITH_CREDIT = "/api/v3/pay-via-credit";

export const API_PROPERTY_STATUS = "/api/v1/property-com/property/publish";
export const API_PROPERTY_FUTURE_CLOSURES =
  "/api/v1/property-com/property/edit-future-closures";

export const API_GET_PROPERTY_FUTURE_CLOSURES =
  "/api/v1/property-com/property/%propertyId%/view-future-closures";

export const API_GET_ACTIVITY_TRACKING =
  "/api/v1/property-com/activity-tracking";

export const API_PROPERTY_PROMOTION_SET_UP =
  "/api/v1/property-com/promotion/setup";

export const API_PROPERTY_PROMOTION_DETAIL =
  "/api/v1/property-com/promotion/detail";

export const API_HANDLE_SCANNER = "/api/v1/property-com/handle-scanner";

export const API_FETCH_PENDING_INVITE =
  "/api/v1/get-pending-request-invitation";

export const API_PENDING_INVITE_REJECT_ACTION =
  "/api/v1/pending-request-invitation/reject/%hashToken%";

export const API_PENDING_INVITE_ACCEPT_ACTION =
  "/api/v1/pending-request-invitation/accept/%hashToken%";

export const API_GET_DECLINE_REASONS =
  "/api/v1/property/decline-reasons/%type%";

export const API_GET_LIST_PROPERTIES_FOR_PARTNER = "/api/v3/properties";
export const API_GET_LIST_MEETING_ROOM_FOR_PARTNER = "/api/v3/meeting-rooms";

export const API_MANUAL_BOOKING_GET_LIST_DESK =
  "/api/v1/des-admin/manual-booking/properties";

export const API_MANUAL_BOOKING_GET_LIST_ROOM =
  "/api/v1/des-admin/manual-booking/property/%id%/meeting-rooms";

export const API_MANUAL_BOOKING_GET_LIST_USER =
  "/api/v1/des-admin/manual-booking/users";

export const API_MANUAL_BOOKING_GET_LIST_PAYMENT =
  "/api/v1/des-admin/manual-booking/user/%id%/payment-method";

export const API_MANUAL_BOOKING_SUBMIT_DESK =
  "/api/v1/des-admin/manual-booking/hot-desk";

export const API_MANUAL_BOOKING_SUBMIT_ROOM =
  "/api/v1/des-admin/manual-booking/meeting-room";

export const API_PARTNER_CREATE_MEETING_ROOM_BOOKING =
  "/api/v3/meeting-room-bookings";

export const API_MANUAL_BOOKING_SUBMIT_WALK_INS = "/api/v3/walk-in";

export const API_CONTRACTS_PAYMENT = "/api/v1/lease-contract-payments";
export const API_GET_FREE_CREDIT = "/api/v1/free-credit";
export const API_TRANSACTION_INFO = "/api/v3/transaction-profile";
export const API_STATISTIC_ON_GOING = "/api/v1/visit/statistic/on-going";
export const API_DASHBOARD_CHECK_USAGE = "/api/v1/checking-usage";
export const API_PAY_NOW_LEASE_PAYMENT =
  "/api/v3/lease-contract-payment/%leaseId%/pay-now";

export const API_LEASE_SEND_RECEIPT =
  "/api/v3/request-download-lease-payment/:token";

export const API_PARTNER_HOT_DESK_BOOKING_STATUS =
  "/api/v3/hot-desk-bookings/%ref%/update/%status%";

export const API_PARTNER_MEETING_ROOM_BOOKING_STATUS =
  "/api/v3/meeting-room-bookings/%ref%/update/%status%";

export const API_CREATE_BOOKING_FOR_PARTNER = "/api/v3/hot-desk-bookings";
export const API_PARTNER_CHECKOUT_MANUALLY =
  "/api/v3/walk-ins/check-out-manually";
export const API_PARTNER_CHECKIN_MANUALLY = "/api/v3/walk-ins";

//Filter Property List
export const API_LIST_PROPERTY_AUTOCOMPLETE =
  "/api/v1/properties/autocomplete/%companyId%?q=%keyword%";

export const API_CHANGE_TO_PAYG = "/api/v3/change-to-payg";
export const API_REPAY = "/api/v3/walk-in/payment/re-pay";
