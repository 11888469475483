import { createReducer } from "@reduxjs/toolkit";
import {
  CAPACITY_ANY,
  LIST_MEETING_ROOM_ACTION,
  LIST_MEETING_ROOM_FILTER_ACTION,
  MEETING_ROOM_DATA_TMP_ACTION,
  MODAL_CONFIRM_BOOKING_ACTION,
  REFRESH_LIST_MEETING_ROOM_ACTION,
  BOOK_PROPERTY_LIST_ACTION,
  FILTER_SORT_BY_KEY,
  FILTER_SORT_BY_NEAREST,
  FILTER_OPEN_ALL,
  FILTER_OPEN_NOW_KEY,
  FILTER_DISTANCE_KEY,
  FILTER_DISTANCE_ALL,
  FILTER_WORKSPACE_KEY,
  FILTER_WORKSPACE_COWORKING,
  FILTER_REGION_KEY,
  FILTER_AMENITY_KEY,
  FILTER_PAGE_KEY,
  FILTER_MARKET_ACTION,
  PROPERTY_FILTER_ACTION,
  FILTER_ITEMS_PER_PAGE_KEY,
  PROPERTY_FILTER_KEYWORD_ACTION,
  INFO_WINDOW_DATA_ACTION,
  MODAL_BOOKING_SUCCESS_ACTION,
  MODAL_CONFIRM_BOOKING_DESK_ACTION,
  BOOKING_SUCCESS_DESK_ID_ACTION,
  MODAL_CONFIRM_BOOKING_DESK_DATA_ACTION,
  MAP_STATE_ACTION,
  PROPERTY_FAVORITE_ACTION,
  PROPERTY_DETAIL_ACTION,
  ROOM_DETAIL_ACTION,
  FILTER_SORT_BY_DEFAULT,
  ROOM_FAVORITE_ACTION,
  PROPERTY_MARKET_COUNT_ACTION,
  ROOM_MARKET_COUNT_ACTION,
  FILTER_MARKET_OPTIONS_ACTION,
  MODAL_ASSIGN_USER_ACTION,
  GET_USER_LIST_ACTION,
  ASSIGN_USER_LIST_ACTION,
  ASSIGN_BOOKING_ID_ACTION,
  MODAL_ASSIGN_BOOKING_SUBMITTED_ACTION,
  APPEND_USER_LIST_ACTION,
  DESK_AMENITIES_ACTION,
  ROOM_AMENITIES_ACTION,
  PIN_HIGHLIGHT_ACTION,
  CIRCLE_INFO_ACTION,
  MODAL_PRIVATE_OFFICE_ACTION,
  PRIVATE_OFFICE_LIST_ACTION,
  PRIVATE_OFFICE_FILTER_ACTION,
  BOOK_PROPERTY_MAP_ACTION,
  MODAL_CHECKIN_ACTION,
  MODAL_REWARDS_ACTION,
  REWARD_LIST_ACTION,
} from "../configs/Constants";
import produce from "immer";
import { BookMeetingRoomType, BookPropertyType } from "../configs/DeclareType";
import { areObjectsEqual } from "../../../utils/commons";
import moment from "moment";

const initialState = {
  mapState: null,
  filters: {
    city_code: null,
    start_time: null,
    end_time: null,
    sort_by: FILTER_SORT_BY_DEFAULT,
    position: "list",
    items_per_page: 1000,
    page: 1,
    capacity_select: CAPACITY_ANY,
    [FILTER_AMENITY_KEY]: [],
    defaultDate: moment().unix(),
    defaultStartTime: null,
    defaultDuration: 180,
    isSetDefaultValue: false,
  },
  meetingRoomList: [],
  refreshRoomList: false,
  modalConfirmBooking: {
    open: false,
    data: null,
  },
  meetingRoomDataTmp: {},
  infoWindowData: null,
  propertyList: {
    results: [],
    has_next_page: false,
    next_page: null,
    current_page: 1,
    total_pages: 0,
    total_results: 0,
  },
  propertyMap: [],
  propertyFilter: {
    [FILTER_SORT_BY_KEY]: FILTER_SORT_BY_NEAREST,
    [FILTER_OPEN_NOW_KEY]: FILTER_OPEN_ALL,
    [FILTER_DISTANCE_KEY]: FILTER_DISTANCE_ALL,
    [FILTER_WORKSPACE_KEY]: FILTER_WORKSPACE_COWORKING,
    [FILTER_REGION_KEY]: "",
    [FILTER_AMENITY_KEY]: [],
    [FILTER_PAGE_KEY]: 1,
    [FILTER_ITEMS_PER_PAGE_KEY]: 1000,
    position: "list",
    defaultDate: null,
  },
  propertyFilterKeyword: "",
  filterMarket: null,
  modalConfirmDeskOpen: false,
  modalConfirmDeskData: null,
  modalBookingSuccess: {
    open: false,
    data: null,
  },
  bookingSuccessDeskId: null,
  propertyDetail: null,
  roomDetail: null,
  propertyMarketCount: [],
  roomMarketCount: [],
  filterMarketOptions: [],
  modalAssignUser: {
    open: false,
    data: null,
  },
  userList: {
    data: [],
    pagination: {
      last_page: 1,
    },
  },
  assignList: [],
  assignBookingId: null,
  modalAssignBookingSubmitted: {
    open: false,
    data: null,
  },
  deskAmenities: [],
  roomAmenities: [],
  pinHighlightId: null,
  circleInfo: null,
  modalPrivateOffice: { isOpen: false },
  privateOfficeList: [],
  privateOfficeFilter: {
    city_code: "",
    items_per_page: 100,
    page: 1,
    capacity_select: null,
    location: "",
    lat: null,
    lng: null,
    from_source: "BO_APP",
    timezone: "",
  },
  marketHasPrivateOffices: true,
  modalCheckin: {
    open: false,
    data: null,
  },
  modalRewards: {
    open: false,
    data: null,
  },
  rewardList: [],
};
const BookReducer = createReducer(initialState, {
  [MAP_STATE_ACTION]: (state, action) => {
    state.mapState = action.payload;
  },
  [LIST_MEETING_ROOM_ACTION]: (state, action) => {
    state.meetingRoomList = action.payload;
  },
  [LIST_MEETING_ROOM_FILTER_ACTION]: (state, action: any) => {
    const payload = action?.payload;
    state.filters = produce(state.filters, (draft: any) => {
      return { ...draft, ...payload };
    });
  },
  [REFRESH_LIST_MEETING_ROOM_ACTION]: (state, action: any) => {
    state.refreshRoomList = !state.refreshRoomList;
  },
  [MODAL_CONFIRM_BOOKING_ACTION]: (state, action: any) => {
    const payload = action?.payload;
    state.modalConfirmBooking = produce(
      state.modalConfirmBooking,
      (draft: any) => {
        return { ...draft, ...payload };
      }
    );
  },
  [MODAL_BOOKING_SUCCESS_ACTION]: (state, action: any) => {
    const payload = action?.payload;
    state.modalBookingSuccess = produce(
      state.modalBookingSuccess,
      (draft: any) => {
        return { ...draft, ...payload };
      }
    );
  },

  [MODAL_ASSIGN_USER_ACTION]: (state, action: any) => {
    const payload = action?.payload;
    state.modalAssignUser = produce(state.modalAssignUser, (draft: any) => {
      return { ...draft, ...payload };
    });
  },

  [MEETING_ROOM_DATA_TMP_ACTION]: (state, action) => {
    state.meetingRoomDataTmp = action.payload;
  },

  [BOOK_PROPERTY_LIST_ACTION]: (state, action) => {
    const results = action.payload?.results;

    if (action.payload?.current_page === 1) {
      state.propertyList = action.payload;
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.propertyList = {
        ...action.payload,
        results: [...state.propertyList.results, ...results],
      };
    }
  },
  [BOOK_PROPERTY_MAP_ACTION]: (state, action) => {
    state.propertyMap = action.payload || [];
  },
  [PROPERTY_FILTER_ACTION]: (state, action: any) => {
    if (areObjectsEqual(action.payload, state.propertyFilter)) {
      return;
    }

    state.propertyFilter = action.payload;
  },
  [FILTER_MARKET_ACTION]: (state, action: any) => {
    state.filterMarket = action.payload;
    state.filters.city_code = action.payload.city_code;
    state.privateOfficeFilter.city_code = action.payload.city_code;
    state.privateOfficeFilter.timezone = action.payload.timezone;
  },
  [PROPERTY_FILTER_KEYWORD_ACTION]: (state, action: any) => {
    state.propertyFilterKeyword = action.payload;
  },
  [INFO_WINDOW_DATA_ACTION]: (state, action: any) => {
    state.infoWindowData = action.payload;
  },
  [MODAL_CONFIRM_BOOKING_DESK_ACTION]: (state, action: any) => {
    state.modalConfirmDeskOpen = action.payload;
  },
  [MODAL_CONFIRM_BOOKING_DESK_DATA_ACTION]: (state, action: any) => {
    state.modalConfirmDeskData = action.payload;
  },
  [BOOKING_SUCCESS_DESK_ID_ACTION]: (state, action: any) => {
    state.bookingSuccessDeskId = action.payload;
  },
  [PROPERTY_FAVORITE_ACTION]: (state, action: any) => {
    state.propertyList.results.map((item: BookPropertyType) => {
      if (item?.id !== action.payload) {
        return item;
      }

      item.is_favorite = item.is_favorite ? 0 : 1;
      return item;
    });

    state.propertyMap.map((item: BookPropertyType) => {
      if (item?.id !== action.payload) {
        return item;
      }

      item.is_favorite = item.is_favorite ? 0 : 1;
      return item;
    });
  },
  [ROOM_FAVORITE_ACTION]: (state, action: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.meetingRoomList = state.meetingRoomList.map(
      (item: BookMeetingRoomType) => {
        if (item?.meeting_room_id !== action.payload) {
          return item;
        }

        item.is_favorite = item.is_favorite ? 0 : 1;
        return item;
      }
    );
  },
  [PROPERTY_DETAIL_ACTION]: (state, action: any) => {
    state.propertyDetail = action.payload;
  },
  [ROOM_DETAIL_ACTION]: (state, action: any) => {
    state.roomDetail = action.payload;
  },
  [PROPERTY_MARKET_COUNT_ACTION]: (state, action: any) => {
    state.propertyMarketCount = action.payload;
  },
  [ROOM_MARKET_COUNT_ACTION]: (state, action: any) => {
    state.roomMarketCount = action.payload;
  },
  [FILTER_MARKET_OPTIONS_ACTION]: (state, action: any) => {
    state.filterMarketOptions = action.payload;
  },

  [GET_USER_LIST_ACTION]: (state, action: any) => {
    state.userList = action.payload;
  },

  [APPEND_USER_LIST_ACTION]: (state, action: any) => {
    state.userList = produce(state.userList, (draft: any) => {
      draft.data = [...draft.data, ...action.payload.data];
    });
  },

  [ASSIGN_USER_LIST_ACTION]: (state, action: any) => {
    state.assignList = action.payload;
  },

  [ASSIGN_BOOKING_ID_ACTION]: (state, action: any) => {
    state.assignBookingId = action.payload;
  },

  [MODAL_ASSIGN_BOOKING_SUBMITTED_ACTION]: (state, action: any) => {
    const payload = action?.payload;
    state.modalAssignBookingSubmitted = produce(
      state.modalAssignBookingSubmitted,
      (draft: any) => {
        return { ...draft, ...payload };
      }
    );
  },

  [DESK_AMENITIES_ACTION]: (state, action: any) => {
    state.deskAmenities = action.payload;
  },

  [ROOM_AMENITIES_ACTION]: (state, action: any) => {
    state.roomAmenities = action.payload;
  },

  [PIN_HIGHLIGHT_ACTION]: (state, action: any) => {
    state.pinHighlightId = action.payload;
  },
  [CIRCLE_INFO_ACTION]: (state, action: any) => {
    state.circleInfo = action.payload;
  },
  [MODAL_PRIVATE_OFFICE_ACTION]: (state, action: any) => {
    state.modalPrivateOffice = action.payload;
  },
  [PRIVATE_OFFICE_LIST_ACTION]: (state, action: any) => {
    const newData = action.payload?.data || [];

    state.marketHasPrivateOffices = action.payload?.extra?.has_offices;

    if (action.payload?.extra?.current_page === 1) {
      state.privateOfficeList = newData;
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.privateOfficeList = [...state.privateOfficeList, ...newData];
    }
  },
  [PRIVATE_OFFICE_FILTER_ACTION]: (state, action: any) => {
    state.privateOfficeFilter = produce(
      state.privateOfficeFilter,
      (draft: any) => {
        return { ...draft, ...action.payload };
      }
    );
  },
  [MODAL_CHECKIN_ACTION]: (state, action: any) => {
    state.modalCheckin = action.payload;
  },
  [MODAL_REWARDS_ACTION]: (state, action: any) => {
    state.modalRewards = action.payload;
  },

  [REWARD_LIST_ACTION]: (state, action: any) => {
    state.rewardList = action.payload;
  },
});

export default BookReducer;
