import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalRightSideBarTemplate from "../ModalRightSideBarTemplate";
import { isModalNavbarMobileSelector } from "../../selectors/AppSelectors";
import {
  modalAddEmployeeAction,
  modalNavbarMobileAction,
  modalPackageTopUpAction,
} from "../../actions/AppActions";
import {
  BOOK_DESK_LINK,
  DASHBOARD_BUSINESS_LINK,
  DESK_BOOKING_LINK,
  EMPLOYEES_BUSINESS_LINK,
  SETTING_LINK,
} from "../../configs/ListRoutes";
import { PUBLIC_URL } from "../../configs/Constants";
import { Link, useNavigate } from "react-router-dom";
import ButtonCustom from "../../../../components/Button";
import "./style.scss";
import { LocalizationContext } from "../../../../locales/Translation";
import BookIcon from "../NavBar/Components/BookIcon";
import CreditBalance from "../NavBar/BusinessAdmin/CreditBalance";

const ModalNavbarMobile = () => {
  const { translations } = useContext(LocalizationContext);

  const dispatch = useDispatch();
  const isModalNavbarMobile = useSelector(isModalNavbarMobileSelector);
  const navigate = useNavigate();

  const _onClick = (pathName: string) => {
    navigate(pathName, { replace: true });
    dispatch(modalNavbarMobileAction(false));
  };

  return (
    <ModalRightSideBarTemplate
      trigger={null}
      onClose={() => {
        dispatch(modalNavbarMobileAction(false));
      }}
      modalClassName={"modal-edit-employee"}
      open={isModalNavbarMobile}
    >
      <div className={"navbar-mobile__list"}>
        <div className={"d-flex justify-content-between"}>
          <Link to={DASHBOARD_BUSINESS_LINK} className={"navbar__logo"}>
            <img src={PUBLIC_URL + "/assets/logo.svg"} alt="deskimo-logo" />
          </Link>
          <ButtonCustom
            onClick={() => dispatch(modalNavbarMobileAction(false))}
            className={"m-0 p-0 none-bg close-modal"}
          >
            <>
              {translations.close}
              <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
            </>
          </ButtonCustom>
        </div>

        <div className={"navbar-mobile__links"}>
          <div
            className={"navbar-mobile__link"}
            onClick={() => {
              _onClick(DASHBOARD_BUSINESS_LINK);
            }}
          >
            <i className="uil uil-chart" />
            Dashboard
          </div>
          <div
            className={"navbar-mobile__link"}
            onClick={() => {
              _onClick(EMPLOYEES_BUSINESS_LINK);
            }}
          >
            <i className="uil uil-users-alt" />
            Employees
          </div>

          <div
            className={"navbar-mobile__link"}
            onClick={() => {
              _onClick(BOOK_DESK_LINK);
            }}
          >
            <BookIcon />
            {translations.book}
          </div>

          <div
            className={"navbar-mobile__link"}
            onClick={() => {
              _onClick(DESK_BOOKING_LINK);
            }}
          >
            <i className="uil uil-list-ul" />
            {translations.myActivities}
          </div>

          <div
            className={"navbar-mobile__link"}
            onClick={() => {
              _onClick(SETTING_LINK);
            }}
          >
            <i className="uil uil-setting" />
            Settings
          </div>
        </div>

        <div className={"navbar__credit_balance guide-navbar__credit_balance"}>
          <CreditBalance isMobile />

          <ButtonCustom
            height={"36px"}
            className={"outline-primary"}
            onClick={() => dispatch(modalPackageTopUpAction(true))}
          >
            Top up
          </ButtonCustom>
        </div>
        <ButtonCustom
          onClick={() => dispatch(modalAddEmployeeAction(true))}
          height={"36px"}
          className={"primary guide-add-employee"}
        >
          <>
            Add Employees <i className="uil uil-plus" />
          </>
        </ButtonCustom>
      </div>
    </ModalRightSideBarTemplate>
  );
};

export default ModalNavbarMobile;
