import React from "react";
import { MarkerMarketType } from "../../configs/DeclareType";
import { useDispatch, useSelector } from "react-redux";
import {
  filterMarketSelector,
  propertyMarketCountSelector,
  roomMarketCountSelector,
} from "../../selectors/BookSelectors";
import { Marker } from "@react-google-maps/api";
import { BOOK_DESK_TAB } from "../../configs/Constants";
import { PUBLIC_URL } from "../../../App/configs/Constants";
import { filterMarketAction } from "../../actions/BookActions";
import { globalSettingSelector } from "../../../App/selectors/AppSelectors";

type Props = {
  tabActive: number;
};

const MarkerMarketCount = ({ tabActive }: Props) => {
  const propertyMarketCount = useSelector(propertyMarketCountSelector);
  const roomMarketCount = useSelector(roomMarketCountSelector);

  if (tabActive === BOOK_DESK_TAB) {
    return <MarkerMarketItem data={propertyMarketCount} />;
  }
  return <MarkerMarketItem data={roomMarketCount} />;
};

const MarkerMarketItem = ({ data }: { data: MarkerMarketType[] }) => {
  const dispatch = useDispatch();
  const globalSetting = useSelector(globalSettingSelector);
  const filterMarket = useSelector(filterMarketSelector);

  const _onClick = (marketCode: string) => {
    const marketChoose = globalSetting?.location.find(
      ({ city_code }: { city_code: string }) => city_code === marketCode
    );
    dispatch(filterMarketAction(marketChoose));
  };

  return (
    <>
      {data
        ?.filter(
          ({ code }: MarkerMarketType) => code !== filterMarket?.city_code
        )
        ?.map(({ code, lat, total_resource, lng }) => (
          <Marker
            key={code}
            position={{ lat: lat, lng: lng }}
            label={{
              text: String(total_resource),
              color: "#fff",
              fontSize: "14px",
              fontWeight: "600",
            }}
            icon={{
              url: PUBLIC_URL + "/assets/icons/icon-cluster1.png",
              size: new google.maps.Size(60, 60),
              scaledSize: new google.maps.Size(60, 60),
            }}
            onClick={() => _onClick(code)}
          />
        ))}
    </>
  );
};

export default MarkerMarketCount;
