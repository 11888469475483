import React, { useContext } from "react";
import { CampaignDetailType } from "../../../configs/DeclareTypes";
import { useSelector } from "react-redux";
import { campaignDetailSelector } from "../../../selectors/MarketingSelectors";
import { LocalizationContext } from "../../../../../locales/Translation";
import { PUBLIC_URL } from "../../../../App/configs/Constants";

const RuleCheck = () => {
  const { translations } = useContext(LocalizationContext);

  const campaignDetail: CampaignDetailType = useSelector(
    campaignDetailSelector
  );

  return (
    <div>
      {campaignDetail?.advance_rules.map(({ type, value }) => {
        if (!value || !translations.voucherRules[type]) {
          return null;
        }
        return (
          <div key={type} className={"campaign_detail_rule"}>
            <div className={"title"}>
              {translations.voucherRules[type]}
              {value ? (
                <img src={PUBLIC_URL + "/assets/icons/icon-check-blue.svg"} />
              ) : null}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RuleCheck;
