import React, { useContext } from "react";
import InputCustom from "../../../../../../../components/Input";
import { Formik } from "formik";
import ButtonCustom from "../../../../../../../components/Button";
import { useParams } from "react-router-dom";
import { updatePropertyService } from "../../../../../services/PropertyServices";
import { getPropertyDetailThunk } from "../../../../../thunks/PropertyThunks";
import { toastError } from "../../../../../../../components/Toast";
import { useDispatch, useSelector } from "react-redux";
import { propertyDetailSelector } from "../../../../../selectors/PropertySelector";
import { LocalizationContext } from "../../../../../../../locales/Translation";
import InputPhoneFlag from "../../../../../../../components/InputPhoneFlag";
import { DIALING_CODE } from "../../../../../../App/configs/PhonePrefix";

const PropertyContactEdit = ({ setEditMode, setLoading, loading }: any) => {
  const { translations } = useContext(LocalizationContext);

  const { id } = useParams();
  const propertyDetail = useSelector(propertyDetailSelector);
  const dispatch = useDispatch();

  const _handleSave = async (values: any) => {
    try {
      setLoading(true);
      const payload = {
        contact_details: {
          ...values,
        },
        property_id: id,
      };
      await updatePropertyService(payload);
      await dispatch<any>(getPropertyDetailThunk(id || ""));
      setEditMode(false);
    } catch (response: any) {
      toastError(response?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          email: propertyDetail?.contact_details?.email || "",
          name: propertyDetail?.contact_details?.name || "",
          phone: propertyDetail?.contact_details?.contact_phone || "",
          phone_prefix: propertyDetail?.contact_details?.phone_prefix || "65",
        }}
        onSubmit={_handleSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <>
            <form className={"form-layout"}>
              <InputCustom
                label={"Name"}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.name}
                error={errors.name}
                name={"name"}
              />
              <InputCustom
                label={"Email"}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.email}
                error={errors.email}
                name={"email"}
              />
              <InputPhoneFlag
                label={translations.phoneNo}
                required
                selectedFlag={DIALING_CODE.filter((country) => {
                  return (
                    country?.value?.replaceAll("+", "") === values.phone_prefix
                  );
                })[0]?.key.toUpperCase()}
                onSelectFlag={(code) => {
                  setFieldValue(
                    "phone_prefix",
                    DIALING_CODE.filter(
                      (country) => country?.flag.toUpperCase() === code
                    )[0]?.value.replaceAll("+", "")
                  );
                }}
                touched={touched.phone}
                error={errors.phone}
                phoneChange={(value) => setFieldValue("phone", value)}
                phoneValue={values.phone}
                disabled={isSubmitting}
              />
              {/*<InputCustom*/}
              {/*  label={"Phone"}*/}
              {/*  value={values.phone}*/}
              {/*  onChange={handleChange}*/}
              {/*  onBlur={handleBlur}*/}
              {/*  touched={touched.phone}*/}
              {/*  error={errors.phone}*/}
              {/*  name={"phone"}*/}
              {/*/>*/}
              <div className={"edit-cta-wrapper edit-mode"}>
                <ButtonCustom
                  onClick={() => setEditMode(false)}
                  className={"outline-primary"}
                  fontSize={"12px"}
                  height={"37px"}
                >
                  <>{translations.cancel}</>
                </ButtonCustom>
                <ButtonCustom
                  loading={loading}
                  onClick={handleSubmit}
                  className={"primary-alt"}
                  fontSize={"12px"}
                  height={"37px"}
                >
                  <>{translations.save}</>
                </ButtonCustom>
              </div>
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default PropertyContactEdit;
