import React, { useEffect, useState } from "react";
import { autoCompleteAffiliateService } from "../../../../Business/services/BusinessServices";
import DropdownSearchAndPaging from "../../../../../components/DropdownSearchAndPaging";
import { debounce } from "lodash";

type Props = {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
};

const AffiliateCompanySelect = ({ value, onChange, disabled }: Props) => {
  const [loading, setLoading] = useState(false);

  const [listCompanyRaw, setListCompanyRaw] = useState<any>([]);
  const [selectedCompany, setSelectedCompany] = useState(value || "");
  const [companyFilter, setCompanyFilter] = useState<any>({
    keyword: "",
    page: "1",
  });

  useEffect(() => {
    (async () => {
      await _getListCompanyByKeyword();
    })();
  }, [companyFilter.keyword]);

  useEffect(() => {
    (async () => {
      if (companyFilter.page > 1) {
        await _getListCompanyByKeyword(true);
      }
    })();
  }, [companyFilter.page]);

  const _getListCompanyByKeyword = async (append = false) => {
    setLoading(true);
    const { data } = await autoCompleteAffiliateService(companyFilter);
    if (append) {
      setListCompanyRaw({
        items: [...listCompanyRaw.items, ...data.items],
        pagination: data.pagination,
      });
    } else {
      setListCompanyRaw(data);
    }
    setLoading(false);
  };

  const _fetchNextPage = () => {
    const nextPage = listCompanyRaw?.pagination?.next_page;

    if (nextPage) {
      setCompanyFilter((prevState: any) => ({
        ...prevState,
        page: String(nextPage),
      }));
    }
  };

  const decorateListCompany = () => {
    if (listCompanyRaw?.items?.length) {
      return listCompanyRaw?.items?.map(
        ({ reference, name }: { reference: string; name: string }) => {
          return {
            key: reference,
            text: name,
            value: reference,
          };
        }
      );
    } else {
      return [];
    }
  };

  const _handleChangeCompanyFilter = (value: string) => {
    setSelectedCompany(value);
    onChange(value);
  };

  return (
    <DropdownSearchAndPaging
      listCompanyRaw={listCompanyRaw}
      onOpen={() => {
        setCompanyFilter((prevState: any) => ({
          ...prevState,
          page: "1",
        }));
      }}
      onChange={(_: any, data: any) => {
        _handleChangeCompanyFilter(data?.value);
      }}
      onSearchChange={debounce((_, data) => {
        setCompanyFilter({
          ...companyFilter,
          keyword: data?.searchQuery,
          page: 1,
        });
      }, 300)}
      fetchNextPage={_fetchNextPage}
      options={decorateListCompany()}
      value={selectedCompany}
      loading={loading}
      disabled={disabled}
      clearable={true}
    />
  );
};

export default AffiliateCompanySelect;
