import React, { useContext } from "react";
import Label from "../../../../../components/Label";
import InputCustom from "../../../../../components/Input";
import { LocalizationContext } from "../../../../../locales/Translation";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import SizeBox from "../../../../../components/SizeBox";
import ReactFlagsSelect from "react-flags-select";
import {
  CALLING_CODE,
  DIALING_CODE,
} from "../../../../App/configs/PhonePrefix";
import ToggleSlider from "../../../../../components/ToggleSlider";
import BoxToggleSlider from "../../../../../components/BoxToggleSlider";

type Props = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
  handleChange: any;
  setFieldValue: any;
};

const ContactInfo = ({
  values,
  errors,
  touched,
  isSubmitting,
  handleChange,
  setFieldValue,
}: Props) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <>
      <Label
        label={"Contact Info"}
        fontSize={"24px"}
        className={"section-title"}
      />
      <div className={"detail_divide"}>
        <div className={"left_section"}>
          <InputCustom
            label={translations.name}
            value={values.contact_name}
            error={errors.contact_name}
            touched={touched.contact_name}
            name={"contact_name"}
            onChange={handleChange}
            disabled={isSubmitting}
            labelRequired
          />

          <InputCustom
            label={translations.companyName}
            value={values.company_name}
            error={errors.company_name}
            touched={touched.company_name}
            name={"company_name"}
            onChange={handleChange}
            disabled={isSubmitting}
            labelRequired
          />

          <Label
            fontSize={"12px"}
            className={"label"}
            label={translations.phoneNumber}
          />
          <div className={"form-row"}>
            <ReactFlagsSelect
              searchable
              selectedSize={13}
              selected={DIALING_CODE.filter((country) => {
                return (
                  country?.value?.replaceAll("+", "") === values.dialing_code
                );
              })[0]?.key.toUpperCase()}
              onSelect={(code) => {
                setFieldValue(
                  "dialing_code",
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  CALLING_CODE[code].secondary.replaceAll("+", "")
                );
              }}
              showSelectedLabel={false}
              customLabels={CALLING_CODE}
              className={"select-calling-code"}
            />
            <InputCustom
              containerClass={"mb-0"}
              value={values.contact_phone}
              onChange={handleChange}
              touched={touched.contact_phone}
              error={errors.contact_phone}
              name={"contact_phone"}
              removeLetter
            />
          </div>
        </div>

        <div className={"right_section"}>
          <InputCustom
            label={translations.email}
            value={values.contact_email}
            error={errors.contact_email}
            touched={touched.contact_email}
            name={"contact_email"}
            onChange={handleChange}
            disabled={isSubmitting}
            labelRequired
          />

          <InputCustom
            label={translations.settings.address}
            value={values.contact_address}
            error={errors.contact_address}
            touched={touched.contact_address}
            name={"contact_address"}
            onChange={handleChange}
            disabled={isSubmitting}
          />
        </div>
      </div>

      <SizeBox height={"24px"} />
      <div className={"property_company_detail_line"} />
    </>
  );
};

export default ContactInfo;
