export const LIST_AMENITY = "LIST_AMENITY";
export const LIST_FREE_AMENITY = "LIST_FREE_AMENITY";
export const LIST_PAID_AMENITY = "LIST_PAID_AMENITY";

export const STATUS_DISCONNECTED = "disconnected";
export const STATUS_HEALTHY = "healthy";
export const ROLE_PROPERTY_STAFF = 4;
export const ROLE_PROPERTY_ADMIN = 5;

export const AMENITY_DESK_TYPE = 1;
export const AMENITY_ROOM_TYPE = 2;

export const DEEPLINK_TOKEN = `https://deskimo.page.link/?link=https://www.deskimo.com?destination=check-in-scan-flow?token=%token%&apn=com.deskimo&isi=1564621411&ibi=com.deskimo`;

export const QRCODE_CHECK_IN = "QRCODE_CHECK_IN";
export const QRCODE_CHECKOUT_OUT = "QRCODE_CHECKOUT_OUT";
export const API_DOCUMENT_LINK =
  "https://www.deskimo.com/api-workspace-documentation/";

export const CREATE_TYPE = "create";
export const EDIT_TYPE = "edit";

export const WIFI_ACCESS_TYPE = {
  PASSWORD: 0,
  PASS_INSTRUCTION: 1,
};

export const MINIMUM_BOOKING_TIME = () => {
  //Step 30 minutes, maximum 12 hours
  const step = 30;
  const max = 12;
  const time: any = [
    {
      key: 0,
      text: "Select time",
      value: 0,
    },
  ];
  for (let i = 30; i <= max * 60; i += step) {
    time.push({
      key: i,
      text: `${Math.floor(i / 60) > 0 ? Math.floor(i / 60) + " hours" : ""} ${
        i % 60 == 0 ? "" : `${i % 60} minutes`
      }`,
      value: i,
    });
  }
  return time;
};

export const REWARD_RULE_CHECKIN_MORE_THAN = "CHECKIN_MORE_THAN";
export const REWARD_RULE_PURCHASE_DAY_PASS = "PURCHASE_DAY_PASS";
export const REWARD_RULE_BOOK_MEETING_ROOM_FULL_DAY =
  "BOOK_MEETING_ROOM_FULL_DAY";
export const REWARD_RULE_BOOK_MEETING_ROOM_MORE_THAN =
  "BOOK_MEETING_ROOM_MORE_THAN";
