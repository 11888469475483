import React from "react";

const SwitchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      x="0"
      y="0"
      enableBackground="new 0 0 16 16"
      version="1.1"
      viewBox="0 0 16 16"
      xmlSpace="preserve"
      width={16}
      height={16}
    >
      <path
        d="M14.4 6.9v-.1l.6-2.7c0-.2 0-.4-.1-.5s-.3-.2-.4-.3c-.2 0-.4 0-.5.1s-.2.3-.3.4l-.2 1.3c-.6-1-1.4-1.8-2.4-2.4C10 2.2 8.9 2 7.7 2c-1.2.1-2.3.5-3.2 1.1-1 .7-1.7 1.6-2.1 2.7-.1.2-.1.3 0 .5s.2.3.4.4c.1 0 .2.1.3.1s.2 0 .3-.1c.1 0 .2-.1.2-.1.1-.1.1-.1.1-.2.4-.9 1-1.6 1.8-2.2s1.6-.9 2.5-.9c.8 0 1.7.2 2.4.6.7.4 1.3 1 1.8 1.8l-1.1-.2h-.3c-.1 0-.2.1-.2.1l-.2.2c0 .1-.1.2-.1.2v.3c0 .1.1.2.1.2l.2.2c.1 0 .2.1.2.1l2.8.5h.4s.1 0 .1-.1l.1-.1.1-.1c.1 0 .1 0 .1-.1zm-1.1 2.5c-.1 0-.2-.1-.3 0-.1 0-.2 0-.3.1-.1 0-.2.1-.2.1-.1.1-.1.1-.1.2-.4.9-1 1.6-1.8 2.1s-1.7.8-2.7.8c-.8 0-1.7-.2-2.4-.6-.7-.4-1.3-1-1.8-1.8l1.1.2H5c.2 0 .4 0 .5-.2.1-.1.2-.3.2-.5s0-.4-.2-.5c-.1-.1-.2-.2-.4-.2l-2.8-.4H2c-.1 0-.1.1-.2.1l-.1.1h-.1v.2L1 11.9v.3c0 .1 0 .2.1.3 0 .1.1.1.2.2s.2.1.3.1h.1c.2 0 .3-.1.4-.2.1-.1.2-.2.2-.4l.2-1.3c.6 1 1.4 1.9 2.4 2.4 1.1.5 2.2.7 3.4.7 1.2-.1 2.3-.4 3.2-1.1.9-.7 1.7-1.6 2.1-2.6.1-.2.1-.3 0-.5s-.2-.4-.3-.4z"
        className="fill:#495057;"
      ></path>
    </svg>
  );
};

export default SwitchIcon;
