import React, { useContext, useState } from "react";
import { Modal } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import {
  modalImportEmployeeAction,
  userGuideAction,
} from "../../actions/AppActions";
import {
  isModalImportEmployeeSelector,
  userGuideSelector,
} from "../../selectors/AppSelectors";
import "./styles.scss";
import ButtonCustom from "../../../../components/Button";
import ModalCenterTemplate from "../ModalCenterTemplate";
import { PUBLIC_URL } from "../../configs/Constants";
import VerifySection from "./Components/VerifySection";
import UploadFileSection from "./Components/UploadFileSection";
import { SweetAlertCommon } from "../../../../components/SweetAlert";
import { SWAL_CUSTOM_CONTENT } from "../../../../components/SweetAlert/configs/SwalConfigs";
import SwalInviting from "./Components/SwalInviting";
import SwalSuccess from "../../../../components/SweetAlert/Templates/SwalSuccess";
import SizeBox from "../../../../components/SizeBox";
import SwalError from "../../../../components/SweetAlert/Templates/SwalError";
import SimpleCollapseExpand from "../../../../components/SimpleCollapseExpand";
import { LocalizationContext } from "../../../../locales/Translation";

const ImportEmployeeModal = () => {
  const { translations } = useContext(LocalizationContext);

  const [tabIndex, setTabIndex] = useState(1);
  const dispatch = useDispatch();
  const isModalImportEmployee = useSelector(isModalImportEmployeeSelector);
  const [fileUploaded, setFileUploaded] = useState<any>(null);
  const userGuide = useSelector(userGuideSelector);

  const _goToUserGuideNextStep = () => {
    if (userGuide?.run && userGuide?.stepIndex === 4) {
      dispatch(userGuideAction({ key: "stepIndex", value: 5 }));
    }
  };

  const showErrorSwal = (listError: any) => {
    dispatch(modalImportEmployeeAction(false));

    let title: any;
    let content: any;

    if (listError && listError.length > 0) {
      title = (
        <span className={"d-flex align-items-center"}>
          <b className={"error-text"}>{translations.errors}:</b>{" "}
          {listError.length}
        </span>
      );

      content = listError.map((error: any, index: number) => {
        return (
          <div key={index} className={"swal-error-item"}>
            <div className={"swal-error-item__number"}>{index + 1}</div>
            <div className={"swal-error-item__text"}>{error}</div>
          </div>
        );
      });
    }

    SweetAlertCommon({
      options: SWAL_CUSTOM_CONTENT({
        content: (
          <SwalError
            title={translations.inviteUnsuccessful}
            text={translations.inviteUnsuccessfulDes}
          >
            <SizeBox height={"16px"} />
            <SimpleCollapseExpand title={title} content={content} />
          </SwalError>
        ),
        denyButtonClass: "d-none",
        cancelButtonClass: "d-none",
        confirmButtonText: (
          <ButtonCustom className={"primary w-100 m-0"} height={"36px"}>
            {translations.tryAgain}
          </ButtonCustom>
        ),
      }),
      confirmCallback() {
        setTabIndex(1);
        setFileUploaded(null);
        dispatch(modalImportEmployeeAction(true));
      },
      cancelCallback() {
        console.log("Cancel");
      },
    });
  };

  const showSuccessSwal = (totalSuccess: any, warning: any) => {
    setFileUploaded(null);
    let title: any = <></>;
    let content: any = <></>;
    if (warning && warning.length > 0) {
      title = (
        <span className={"d-flex align-items-center"}>
          <b className={"warning-text"}>Warning:</b> {warning.length}
        </span>
      );

      content = warning.map((error: any, index: number) => {
        return (
          <div key={index} className={"swal-error-item"}>
            <div className={"swal-error-item__number"}>{index + 1}</div>
            <div className={"swal-error-item__text"}>{error?.message}</div>
          </div>
        );
      });
    }

    SweetAlertCommon({
      options: SWAL_CUSTOM_CONTENT({
        content: (
          <SwalSuccess
            text={translations.successfullyInvitedDes}
            title={translations.successfullyInvited}
          >
            <SizeBox height={"16px"} />

            <div className={"swal-text-in-box"}>
              <b className={"success-text"}>{translations.newInvites}:</b>{" "}
              {totalSuccess}
            </div>
            <SizeBox height={"16px"} />

            {content && title && warning && warning.length > 0 ? (
              <SimpleCollapseExpand title={title} content={content} />
            ) : (
              <></>
            )}
          </SwalSuccess>
        ),
        denyButtonClass: "d-none",
        cancelButtonClass: "d-none",
        confirmButtonText: translations.done,
        confirmButtonClass: "w-100 primary h-36",
      }),
      confirmCallback() {
        console.log("Confirm");
      },
      cancelCallback() {
        console.log("Cancel");
      },
    });
  };
  const maxCountdown = Math.floor(Math.random() * 5 + 1);

  const showInvitingSwal = () => {
    dispatch(modalImportEmployeeAction(false));
    SweetAlertCommon({
      options: SWAL_CUSTOM_CONTENT({
        content: <SwalInviting maxCountdown={maxCountdown} />,
        cancelButtonClass: "d-none",
        denyButtonClass: "d-none",
        confirmButtonText: (
          <ButtonCustom className={"danger w-100 m-0"} height={"36px"}>
            {translations.cancelTheBulkInvite}
          </ButtonCustom>
        ),
      }),
      confirmCallback() {
        console.log("Confirm");
      },
      cancelCallback() {
        console.log("Cancel");
      },
    });
  };

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={() => {
        setTabIndex(1);
        setFileUploaded(null);
        dispatch(modalImportEmployeeAction(false));
        _goToUserGuideNextStep();
      }}
      modalClassName={"modal-general guide-modal-import-employee"}
      open={isModalImportEmployee}
    >
      <Modal.Header>
        {translations.bulkInviteWith} XLSX
        <ButtonCustom
          onClick={() => {
            dispatch(modalImportEmployeeAction(false));
            _goToUserGuideNextStep();
          }}
          className={"modal-close-cta"}
        >
          <>
            <span>{translations.close}</span>
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      {tabIndex === 1 ? (
        <>
          <UploadFileSection
            setFileUploaded={setFileUploaded}
            fileUploaded={fileUploaded}
            setTabIndex={setTabIndex}
            goToUserGuideNextStep={_goToUserGuideNextStep}
          />
        </>
      ) : (
        <></>
      )}
      {tabIndex === 2 ? (
        <>
          <VerifySection
            setFileUploaded={setFileUploaded}
            fileUploaded={fileUploaded}
            showInvitingSwal={showInvitingSwal}
            showErrorSwal={showErrorSwal}
            showSuccessSwal={showSuccessSwal}
            setTabIndex={setTabIndex}
          />
        </>
      ) : (
        <></>
      )}
    </ModalCenterTemplate>
  );
};

export default ImportEmployeeModal;
