import {
  CREDIT_PACKAGES_ACTION,
  CREDIT_REMAINING_ACTION,
  GET_GLOBAL_SETTING_ACTION,
  GET_LIST_VOUCHER_FOR_BOOKING_ACTION,
  GET_MY_PROFILE_ACTION,
  HEADER_POPUP_TOPPING_UP_ACTION,
  IS_GLOBAL_INDICATOR_ACTION,
  MODAL_ADD_EMPLOYEE_ACTION,
  MODAL_ADD_VOUCHER_ACTION,
  MODAL_ADD_VOUCHER_FOR_BOOKING_ACTION,
  MODAL_DOWNLOAD_APP_ACTION,
  MODAL_IMPORT_EMPLOYEE_ACTION,
  MODAL_MY_PROFILE_ACTION,
  MODAL_NAVBAR_MOBILE_ACTION,
  MODAL_PACKAGE_TOP_UP_ACTION,
  MODAL_START_USER_GUIDE_ACTION,
  MODAL_UPDATE_PASSWORD_ACTION,
  ONBOARD_PROMOTION_INFO_ACTION,
  UPDATE_MY_PROFILE_ACTION,
  USER_GUIDE_ACTION,
  USER_GUIDE_SEND_BULK_INVITE_ACTION,
} from "../configs/Constants";

export const modalPackageTopUpAction = (data: boolean) => {
  return {
    type: MODAL_PACKAGE_TOP_UP_ACTION,
    payload: data,
  };
};

export const modalAddEmployeeAction = (data: boolean) => {
  return {
    type: MODAL_ADD_EMPLOYEE_ACTION,
    payload: data,
  };
};

export const modalImportEmployeeAction = (data: boolean) => {
  return {
    type: MODAL_IMPORT_EMPLOYEE_ACTION,
    payload: data,
  };
};

export const modalMyProfileAction = (data: boolean) => {
  return {
    type: MODAL_MY_PROFILE_ACTION,
    payload: data,
  };
};

export const modalUpdatePasswordAction = (data: boolean) => {
  return {
    type: MODAL_UPDATE_PASSWORD_ACTION,
    payload: data,
  };
};

export const modalAddVoucherAction = (data: boolean) => {
  return {
    type: MODAL_ADD_VOUCHER_ACTION,
    payload: data,
  };
};

export const modalAddVoucherForBookingAction = (data: boolean) => {
  return {
    type: MODAL_ADD_VOUCHER_FOR_BOOKING_ACTION,
    payload: data,
  };
};

export const getListVoucherForBookingAction = (data: any) => {
  return {
    type: GET_LIST_VOUCHER_FOR_BOOKING_ACTION,
    payload: data,
  };
};

//Navbar
export const getCreditRemainingAction = (data: any) => {
  return {
    type: CREDIT_REMAINING_ACTION,
    payload: data,
  };
};

export const getMyProfileAction = (data: any) => {
  return {
    type: GET_MY_PROFILE_ACTION,
    payload: data,
  };
};

export const updateMyProfileAction = (data: any) => {
  return {
    type: UPDATE_MY_PROFILE_ACTION,
    payload: data,
  };
};

export const modalDownloadAppAction = (data: boolean) => {
  return {
    type: MODAL_DOWNLOAD_APP_ACTION,
    payload: data,
  };
};

export const modalNavbarMobileAction = (data: boolean) => {
  return {
    type: MODAL_NAVBAR_MOBILE_ACTION,
    payload: data,
  };
};

export const modalStartUserGuideAction = (data: boolean) => {
  return {
    type: MODAL_START_USER_GUIDE_ACTION,
    payload: data,
  };
};

export const userGuideAction = (data: {
  key: "run" | "stepIndex";
  value: boolean | number;
}) => {
  return {
    type: USER_GUIDE_ACTION,
    payload: data,
  };
};

export const userGuideSendBulkInviteAction = (data: boolean) => {
  return {
    type: USER_GUIDE_SEND_BULK_INVITE_ACTION,
    payload: data,
  };
};

export const isGlobalIndicatorAction = (data: boolean) => {
  return {
    type: IS_GLOBAL_INDICATOR_ACTION,
    payload: data,
  };
};

export const globalSettingAction = (data: any) => {
  return {
    type: GET_GLOBAL_SETTING_ACTION,
    payload: data,
  };
};

export const headerPopupToppingUpAction = (data: any) => {
  return {
    type: HEADER_POPUP_TOPPING_UP_ACTION,
    payload: data,
  };
};

export const creditPackagesAction = (data: any) => {
  return {
    type: CREDIT_PACKAGES_ACTION,
    payload: data,
  };
};

export const onboardPromotionInfoAction = (data: object) => {
  return {
    type: ONBOARD_PROMOTION_INFO_ACTION,
    payload: data,
  };
};
