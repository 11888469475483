import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import { addVoucherService } from "../../../../../App/services/AppServices";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { modalAddVoucherAction } from "../../../../../App/actions/AppActions";
import ModalCenterTemplate from "../../../../../App/views/ModalCenterTemplate";
import { Formik } from "formik";
import * as yup from "yup";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import SizeBox from "../../../../../../components/SizeBox";
import InputCustom from "../../../../../../components/Input";
import Label from "../../../../../../components/Label";
import { manualCheckInService } from "../../../../services/DashboardServices";
import { dashboardPropertyChooseSelector } from "../../../../selectors/DashboardSelectors";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const ModalManualCheckIn = ({ open, setOpen }: Props) => {
  const dashboardPropertyChoose = useSelector(dashboardPropertyChooseSelector);
  const { translations } = useContext(LocalizationContext);

  const [loading, setLoading] = useState(false);

  const _handleSave = async (values: any) => {
    setLoading(true);
    try {
      const payload = {
        ...values,
        property_id: dashboardPropertyChoose?.property_id,
      };
      await manualCheckInService(payload);
      toastSuccess("Check-in successfully!");
      setOpen(false);
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={() => setOpen(false)}
      modalClassName={"modal-general"}
      open={open}
    >
      <Formik
        initialValues={{
          code: "",
        }}
        validationSchema={yup.object().shape({
          code: yup.string().required().label("Code"),
        })}
        onSubmit={_handleSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <>
            <Modal.Header>
              <span>{translations.checkInManuallyWithCode}</span>
              <ButtonCustom
                onClick={() => setOpen(false)}
                className={"modal-close-cta"}
              >
                <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
              </ButtonCustom>
            </Modal.Header>
            <Modal.Content>
              <form className={"form-layout"}>
                <InputCustom
                  label={translations.QRCodeNumber}
                  value={values?.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.code}
                  error={errors.code}
                  name={"code"}
                  onKeyPress={(e: any) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSubmit();
                    }
                  }}
                />
              </form>
            </Modal.Content>
            <Modal.Actions className={"border-0"}>
              <ButtonCustom
                loading={loading}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {translations.checkIn}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalCenterTemplate>
  );
};

export default ModalManualCheckIn;
