import React, { useEffect, useState } from "react";
import Label from "../../../../../../components/Label";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import SizeBox from "../../../../../../components/SizeBox";
import { ConfigMarketGeneralInformationDataType } from "../../../../configs/DeclareTypes";
import { useDispatch, useSelector } from "react-redux";
import { configMarketGeneralInformationSelector } from "../../../../selectors/ConfigSelectors";
import { useParams } from "react-router-dom";
import { configMarketGeneralInformationThunk } from "../../../../thunks/ConfigThunks";
import {
  ONBOARD_PROMOTION_FREE_TRAIL,
  ONBOARD_PROMOTION_PAID_TRAIL,
} from "../../../../configs/Constants";
import FreeTrial from "./FreeTrial";
import PaidTrial from "./PaidTrial";
import None from "./None";
import { Dimmer, Loader } from "semantic-ui-react";

const ONBOARD_PROMOTION_OPTIONS = [
  { id: 0, text: "None", value: 0 },
  { id: 1, text: "Free Trial", value: 1 },
  { id: 2, text: "Paid Trial", value: 2 },
];

const OnboardPromotion = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [dropdownValue, setDropdownValue] = useState<any>(null);

  const [loading, setLoading] = useState(true);
  const configMarketGeneralInformation: ConfigMarketGeneralInformationDataType =
    useSelector(configMarketGeneralInformationSelector);

  useEffect(() => {
    (async () => {
      if (!id) {
        return;
      }
      setLoading(true);
      await dispatch<any>(configMarketGeneralInformationThunk(id));
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    setDropdownValue(configMarketGeneralInformation?.onboarding_promotion_type);
  }, [configMarketGeneralInformation]);

  if (loading) {
    return (
      <Dimmer active>
        <Loader />
      </Dimmer>
    );
  }

  return (
    <div className={"section-white-box"}>
      <div className="detail_divide">
        <div className="left_section pr-8">
          <Label label={"Promotion settings"} />
          <DropdownCustom
            options={ONBOARD_PROMOTION_OPTIONS}
            value={dropdownValue}
            onChange={(event, { value }) => setDropdownValue(value)}
            squareUI
          />
        </div>
        <SizeBox height={"40px"} />
      </div>

      {dropdownValue === ONBOARD_PROMOTION_FREE_TRAIL ? (
        <FreeTrial />
      ) : dropdownValue === ONBOARD_PROMOTION_PAID_TRAIL ? (
        <PaidTrial />
      ) : (
        <None />
      )}
    </div>
  );
};

export default OnboardPromotion;
