import clientRequestService from "../../../services/ClientRequestService";
import {
  METHOD_DELETE,
  METHOD_GET,
  METHOD_POST,
} from "../../../configs/Constants";
import { API_EMPLOYEE_PROPERTY_REINVITE } from "../../User/configs/Endpoints";
import ClientRequestService from "../../../services/ClientRequestService";
import {
  DELETE_PROPERTY_STAFF_DELETE_API,
  GET_PROPERTY_STAFF_ACTIVATE_API,
  GET_PROPERTY_STAFF_DEACTIVATE_API,
  MAKE_PROPERTY_ADMIN,
} from "../configs/Endpoints";

export const propertyStaffReInviteService = async ({
  user_id,
  company_id,
}: {
  user_id: string;
  company_id: string;
}) => {
  return await clientRequestService({
    method: METHOD_POST,
    url: API_EMPLOYEE_PROPERTY_REINVITE,
    data: { user_id, company_id },
  });
};

export const propertyStaffActivateService = async ({
  companyId,
  userId,
}: any) => {
  return await ClientRequestService({
    method: METHOD_GET,
    url: GET_PROPERTY_STAFF_ACTIVATE_API.replaceAll(
      "%companyId%",
      companyId
    ).replaceAll("%userId%", userId),
  });
};

export const propertyStaffDeactivateService = async ({
  companyId,
  userId,
}: any) => {
  return await ClientRequestService({
    method: METHOD_GET,
    url: GET_PROPERTY_STAFF_DEACTIVATE_API.replaceAll(
      "%companyId%",
      companyId
    ).replaceAll("%userId%", userId),
  });
};

export const propertyStaffDeleteService = async (data: object) => {
  return await clientRequestService({
    method: METHOD_DELETE,
    url: DELETE_PROPERTY_STAFF_DELETE_API,
    data,
  });
};

export const makePropertyAdmin = async (id: string) => {
  return await clientRequestService({
    method: METHOD_GET,
    url: MAKE_PROPERTY_ADMIN.replaceAll("%companyUserId%", id),
  });
};
