import { Dimmer, Grid, Loader } from "semantic-ui-react";
import React, { useContext, useEffect, useState } from "react";
import { Formik, FormikValues } from "formik";
import Label from "../../../../../../components/Label";
import { useNavigate, useParams } from "react-router-dom";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import "../styles.scss";
import * as yup from "yup";
import { LocalizationContext } from "../../../../../../locales/Translation";
import InputCustom from "../../../../../../components/Input";
import ButtonCustom from "../../../../../../components/Button";
import {
  getOnboardPromotionDataService,
  updateOnboardPromotionDataService,
} from "../../../../services/ConfigServices";
import SizeBox from "../../../../../../components/SizeBox";
import { CONFIG_MAIN_LINK } from "../../../../../App/configs/ListRoutes";
import { ONBOARD_PROMOTION_FREE_TRAIL } from "../../../../configs/Constants";

const FreeTrial = () => {
  const { translations } = useContext(LocalizationContext);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const [onboardPromotionData, setOnboardPromotionData] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    getOnboardPromotionData();
  }, []);

  const getOnboardPromotionData = async () => {
    try {
      const response = await getOnboardPromotionDataService(id);
      if (response?.data) {
        setOnboardPromotionData(response?.data);
      }
    } catch (error: any) {
      console.log("Error: ", error?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const _onSubmit = async (values: FormikValues) => {
    try {
      await updateOnboardPromotionDataService(id, values);
      toastSuccess("Updated Successfully");
    } catch (e: any) {
      toastSuccess(e?.status?.message);
    }
  };

  return (
    <>
      {loading ? (
        <Dimmer active>
          <Loader />
        </Dimmer>
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            onboarding_new_user_free_credit:
              onboardPromotionData?.onboarding_new_user_free_credit || "",
            onboarding_new_user_free_duration:
              onboardPromotionData?.onboarding_new_user_free_duration || "",
            onboarding_promotion_after_creation:
              onboardPromotionData?.onboarding_promotion_after_creation || "",
            status: ONBOARD_PROMOTION_FREE_TRAIL,
          }}
          validationSchema={yup.object().shape({
            onboarding_new_user_free_credit: yup
              .number()
              .min(0)
              .required()
              .label("Free credit for new user"),
            onboarding_new_user_free_duration: yup
              .number()
              .min(0)
              .required()
              .label("Free credit valid for"),
            onboarding_promotion_after_creation: yup
              .number()
              .min(0)
              .required()
              .label(
                "Onboarding promotion finish after number of days after creation"
              ),
          })}
          onSubmit={_onSubmit}
        >
          {({
            values,
            handleSubmit,
            isSubmitting,
            errors,
            touched,
            handleChange,
          }) => (
            <div>
              <div className="detail_divide">
                <div className="left_section pr-8">
                  <InputCustom
                    label={"Free credit for new user"}
                    labelRequired
                    value={values.onboarding_new_user_free_credit}
                    error={errors.onboarding_new_user_free_credit}
                    touched={touched.onboarding_new_user_free_credit}
                    containerClass="market-input-style"
                    name={"onboarding_new_user_free_credit"}
                    onChange={handleChange}
                    removeLetter
                  />
                  <InputCustom
                    label={
                      "Onboarding promotion finish after number of days after creation"
                    }
                    labelRequired
                    value={values.onboarding_promotion_after_creation}
                    error={errors.onboarding_promotion_after_creation}
                    touched={touched.onboarding_promotion_after_creation}
                    containerClass="market-input-style"
                    name={"onboarding_promotion_after_creation"}
                    onChange={handleChange}
                    removeLetter
                  />
                </div>
                <div className="right_section pl-8">
                  <InputCustom
                    label={"Free credit valid for"}
                    labelRequired
                    value={values.onboarding_new_user_free_duration}
                    error={errors.onboarding_new_user_free_duration}
                    touched={touched.onboarding_new_user_free_duration}
                    containerClass="market-input-style"
                    name={"onboarding_new_user_free_duration"}
                    onChange={handleChange}
                    removeLetter
                  />
                </div>
              </div>

              <div className={"section-white-box-actions"}>
                <ButtonCustom
                  className={"mr-0"}
                  onClick={() => navigate(CONFIG_MAIN_LINK)}
                  disabled={isSubmitting}
                >
                  {translations.cancel}
                </ButtonCustom>
                <SizeBox width={"8px"} />
                <ButtonCustom
                  className={"primary mr-0"}
                  onClick={handleSubmit}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  {translations.save}
                </ButtonCustom>
              </div>
            </div>
          )}
        </Formik>
      )}
    </>
  );
};

export default FreeTrial;
