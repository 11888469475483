import React, { useContext } from "react";
import ButtonCustom from "../../../../../../components/Button";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { partnerCheckoutManuallyService } from "../../../../services/DashboardServices";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { dashboardDeskUsageThunk } from "../../../../thunks/DashboardThunks";
import {
  BUSINESS,
  DESK_USAGE_ON_GOING_STATUS,
  PRODUCT_TYPE_PARTNER,
} from "../../../../configs/Constants";
import { useDispatch, useSelector } from "react-redux";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import { Dropdown } from "semantic-ui-react";
import { isGlobalIndicatorAction } from "../../../../../App/actions/AppActions";
import { userCompanyChooseSelector } from "../../../../../User/selectors/UserSelectors";

type Props = {
  reference?: string;
  statusId: number;
};

const DeskUsageAction = ({ reference, statusId }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const userCompanyChoose = useSelector(userCompanyChooseSelector);

  const _onCheckOut = async () => {
    try {
      if (!reference) {
        return;
      }
      dispatch(isGlobalIndicatorAction(true));

      const payload = {
        reference: reference,
        business_company_id: userCompanyChoose.company_id,
      };
      await partnerCheckoutManuallyService(payload);
      await dispatch<any>(
        dashboardDeskUsageThunk(BUSINESS, false, false, PRODUCT_TYPE_PARTNER)
      );

      toastSuccess("Checked-out Successfully!");
    } catch (e: any) {
      toastError(e?.status?.message);
    } finally {
      dispatch(isGlobalIndicatorAction(false));
    }
  };

  if (statusId !== DESK_USAGE_ON_GOING_STATUS) {
    return null;
  }

  return (
    <DropdownCustom
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={_onCheckOut}
          text={translations.checkout}
          icon={<i className="uil icon uil-left-indent-alt" />}
        />
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default DeskUsageAction;
