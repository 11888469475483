import React, { Dispatch, SetStateAction, useContext } from "react";
import SizeBox from "../../../../components/SizeBox";
import { LocalizationContext } from "../../../../locales/Translation";

type Props = {
  item: any;
  active: number;
  setActive: Dispatch<SetStateAction<number>>;
};

const PackageItem = ({ item, active, setActive }: Props) => {
  const { translations } = useContext(LocalizationContext);

  const {
    description,
    duration,
    extend_time,
    is_most_popular,
    currency,
    amount,
    discount_percent,
    final_amount,
    id,
    full_description,
  } = item;

  return (
    <div
      onClick={() => setActive(id)}
      className={"plan " + (active === id ? "active" : "")}
    >
      <div className={"plan-select"} />

      {is_most_popular !== "" ? (
        <div className={"plan-badge"}>{is_most_popular}</div>
      ) : (
        <SizeBox height={"40px"} />
      )}

      <div className={"plan-title"}>
        {currency} {amount} {translations.credit}
      </div>
      <div className={"plan-text"}>
        {translations.pay} {currency} {final_amount} {translations.only}{" "}
      </div>
      {discount_percent ? (
        <div className={"plan-discount"}>Get {discount_percent}% discount</div>
      ) : null}

      <SizeBox height={"20px"} />
      <div className={"plan-sub"}>
        {translations.validFor} {duration} {translations.days}
      </div>
    </div>
  );
};

export default PackageItem;
