import React, { useContext, useEffect, useState } from "react";
import Label from "../../../../../components/Label";
import InputCustom from "../../../../../components/Input";
import DropdownCustom from "../../../../../components/DropdownCustom";
import ButtonCustom from "../../../../../components/Button";
import SizeBox from "../../../../../components/SizeBox";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import { LocalizationContext } from "../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import {
  CAPACITY_ARR,
  FILTER_AMENITY_KEY,
  FILTER_SORT_BY_DEFAULT,
  FILTER_SORT_BY_KEY,
  FILTER_SORT_BY_LOWER_PRICE,
} from "../../../configs/Constants";
import {
  listMeetingRoomFilterAction,
  propertyFilterAction,
  propertyFilterKeywordAction,
} from "../../../actions/BookActions";
import {
  filterMarketSelector,
  meetingRoomFiltersSelector,
  roomAmenitiesSelector,
} from "../../../selectors/BookSelectors";
import {
  dayOptions,
  DURATION_ARR,
} from "../../../../App/configs/DateTimeConfigs";
import moment from "moment-timezone";
import {
  calculateBookingTime,
  getLocalDateFromAnotherTimezoneMoment,
  getLocalTimeAndDurationFromAnotherTimezone,
} from "../../../services/Common";
import DropdownWithRadioMenu from "../../../../../components/DropdownWithRadioMenu";
import { debounce } from "lodash";
import DropdownWithCheckboxMenu from "../../../../../components/DropdownWithCheckboxMenu";
import DatePickerSingleV2 from "../../../../../components/DatePickerSingleV2";
import { areObjectsEqual } from "../../../../../utils/commons";
import Autocomplete from "react-google-autocomplete";

const FilterRoom = ({ populateFromSessionStorage }: any) => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  // const filters = useSelector(meetingRoomFiltersSelector);
  const roomFilters = useSelector(meetingRoomFiltersSelector);

  const filterMarket = useSelector(filterMarketSelector);
  const defaultTime = getLocalTimeAndDurationFromAnotherTimezone(
    roomFilters?.start_time,
    roomFilters?.end_time,
    filterMarket?.timezone
  );
  // const roomFilters = useSelector(meetingRoomFiltersSelector);
  const roomAmenities = useSelector(roomAmenitiesSelector);

  const [dateChoose, setDateChoose] = useState<any>(
    sessionStorage.getItem("DEFAULT_ROOM_DATE")
      ? sessionStorage.getItem("DEFAULT_ROOM_DATE")
      : getLocalDateFromAnotherTimezoneMoment(
          roomFilters?.start_time,
          filterMarket?.timezone
        )
  );
  const [startTime, setStartTime] = useState<any>(
    sessionStorage.getItem("DEFAULT_ROOM_TIME")
      ? sessionStorage.getItem("DEFAULT_ROOM_TIME")
      : defaultTime.startTime
  );
  const [startTimeOptions, setStartTimeOptions] = useState<any>([]);
  const [duration, setDuration] = useState<any>(
    sessionStorage.getItem("DEFAULT_ROOM_DURATION")
      ? Number(sessionStorage.getItem("DEFAULT_ROOM_DURATION"))
      : defaultTime.duration
  );
  const [place, setPlace] = useState<any>(null);

  useEffect(() => {
    if (!populateFromSessionStorage) return;
    setTimeout(() => {
      _onChangeStartEndTime();
    }, 200);
  }, [populateFromSessionStorage]);

  useEffect(() => {
    _onChangeStartEndTime();
  }, [dateChoose, startTime, duration, place]);

  useEffect(() => {
    _handleStartTimeOptions();
  }, [dateChoose, filterMarket]);

  useEffect(() => {
    const lat = place?.geometry?.location?.lat();
    const lng = place?.geometry?.location?.lng();
    if (lat && lng) {
      dispatch(
        listMeetingRoomFilterAction({
          ...roomFilters,
          lat,
          lng,
          searchByLandmark: true,
        })
      );
    }
  }, [place]);

  const _handleStartTimeOptions = () => {
    const isAfter = moment(dateChoose).isAfter(
      moment.tz(filterMarket?.timezone)
    );
    let options;

    if (isAfter) {
      options = dayOptions();
    } else {
      options = dayOptions({
        minTime: `${moment
          .tz(filterMarket?.timezone)
          .add(1, "hour")
          .format("HH")}:00`,
      });

      const firstValue = options[0].value;
      if (
        !areObjectsEqual(
          { ...roomFilters, defaultStartTime: firstValue },
          roomFilters
        )
      ) {
        dispatch(
          listMeetingRoomFilterAction({
            ...roomFilters,
            defaultStartTime: firstValue,
          })
        );
      }
      setStartTime(firstValue);
    }

    setStartTimeOptions(options);
  };

  const _onChangeStartEndTime = () => {
    console.log("on Change Start Time");
    const startTimeTemp = calculateBookingTime(
      dateChoose,
      startTime,
      undefined,
      filterMarket?.timezone
    );

    const endTimeTemp = calculateBookingTime(
      dateChoose,
      startTime,
      duration,
      filterMarket?.timezone
    );

    if (!endTimeTemp || !startTimeTemp) {
      return;
    }

    const filtersTemp = {
      ...roomFilters,
      start_time: startTimeTemp,
      end_time: endTimeTemp,
      defaultDate: startTimeTemp,
      defaultDuration: duration,
      defaultStartTime: startTime,
      isSetDefaultValue: true,
    };

    if (!areObjectsEqual(filtersTemp, roomFilters)) {
      dispatch(listMeetingRoomFilterAction(filtersTemp));
    }
  };

  const _handleFilterSortBy = (sortIndex: any) => {
    const filtersTemp = {
      ...roomFilters,
      [FILTER_SORT_BY_KEY]: sortIndex,
    };
    sessionStorage.setItem("DEFAULT_ROOM_SORTBY", sortIndex);

    if (!areObjectsEqual(filtersTemp, roomFilters)) {
      dispatch(listMeetingRoomFilterAction(filtersTemp));
    }
  };

  const decoratedListSortBy = () => {
    return [
      {
        checked: roomFilters[FILTER_SORT_BY_KEY] === FILTER_SORT_BY_DEFAULT,
        id: FILTER_SORT_BY_DEFAULT,
        name: translations.roomSortBy[FILTER_SORT_BY_DEFAULT],
      },
      {
        checked: roomFilters[FILTER_SORT_BY_KEY] === FILTER_SORT_BY_LOWER_PRICE,
        id: FILTER_SORT_BY_LOWER_PRICE,
        name: translations.roomSortBy[FILTER_SORT_BY_LOWER_PRICE],
      },
    ];
  };

  const debounceOnChangeKeyWord = debounce(function (
    data: any,
    { value }: any
  ) {
    dispatch(propertyFilterKeywordAction(value));
  },
  500);

  const decoratedListAmenity = () => {
    return roomAmenities.map((amenity: any, index: number) => {
      return {
        checked:
          roomFilters[FILTER_AMENITY_KEY].length === 0
            ? false
            : roomFilters[FILTER_AMENITY_KEY].includes(amenity?.id),
        id: amenity?.id,
        name: (
          <label>
            <img alt={""} src={amenity?.icon} />
            {amenity?.name}
          </label>
        ),
      };
    });
  };

  const _handleFilterAmenity = (amenityId: string) => {
    let amenitiesFilter = [...roomFilters[FILTER_AMENITY_KEY]];

    if (amenitiesFilter.includes(amenityId)) {
      amenitiesFilter = amenitiesFilter.filter(
        (id: string) => id !== amenityId
      );
    } else {
      amenitiesFilter.push(amenityId);
    }

    sessionStorage.setItem(
      "DEFAULT_ROOM_AMENITIES",
      amenitiesFilter.toString()
    );

    const filters = {
      ...roomFilters,
      [FILTER_AMENITY_KEY]: amenitiesFilter,
    };

    if (!areObjectsEqual(filters, roomFilters)) {
      dispatch(listMeetingRoomFilterAction(filters));
    }
  };

  return (
    <div className={"book-filter-wrapper"}>
      <div className="book-filter-wrapper__top book-filter-wrapper__top-room">
        <div className="book-filter-wrapper__top-inside">
          <div className={"filter-item filter-item-date"}>
            <Label label={"Date"} fontSize={"12px"} />
            <DatePickerSingleV2
              format={"DD/MM/YYYY"}
              allowClear={false}
              value={dateChoose ? moment(dateChoose) : null}
              disabledDate={(date: any) => {
                return moment(date).isBefore(moment().subtract({ d: 1 }));
              }}
              showToday={false}
              className={"w-100 border-radius-right-0"}
              onChange={(date: any) => {
                setDateChoose(date);
                sessionStorage.setItem("DEFAULT_ROOM_DATE", date);
              }}
            />
            <div className={"filter-sub-label"}>{filterMarket?.timezone}</div>
          </div>

          <div className={"filter-item filter-item-time"}>
            <SizeBox height={"18px"} />
            <DropdownCustom
              scrolling
              selectUI
              value={startTime}
              options={startTimeOptions}
              onChange={(e, { value }) => {
                setStartTime(value);
                sessionStorage.setItem(
                  "DEFAULT_ROOM_TIME",
                  value ? value.toString() : ""
                );
              }}
              pointing={"top"}
            />
          </div>
        </div>
        <SizeBox width={"16px"} />
        <div className="book-filter-wrapper__top-inside">
          <div className={"filter-item"}>
            <Label label={"Duration"} fontSize={"12px"} />
            <DropdownCustom
              scrolling
              selectUI
              options={DURATION_ARR(1, 20)}
              value={duration}
              onChange={(e, { value }) => {
                setDuration(value);
                sessionStorage.setItem(
                  "DEFAULT_ROOM_DURATION",
                  value ? value.toString() : ""
                );
              }}
              pointing={"top"}
            />
          </div>
          <SizeBox width={"16px"} />
          <div className={"filter-item"}>
            <Label label={translations.capacity} fontSize={"12px"} />
            <DropdownCustom
              scrolling
              value={roomFilters?.capacity_select}
              selectUI
              options={CAPACITY_ARR}
              onChange={(e, data) => {
                dispatch(
                  listMeetingRoomFilterAction({
                    capacity_select: data?.value,
                  })
                );
                sessionStorage.setItem(
                  "DEFAULT_ROOM_CAPACITY",
                  data?.value ? data?.value.toString() : ""
                );
              }}
            />
          </div>
        </div>
      </div>
      <SizeBox height={"12px"} />
      <div className="book-filter-wrapper__top">
        <div className={"filter-item"}>
          <Label label={translations.NearWhere} fontSize={"12px"} />
          <Autocomplete
            defaultValue={
              sessionStorage.getItem("DEFAULT_ROOM_LOCATION")
                ? JSON.parse(
                    sessionStorage.getItem("DEFAULT_ROOM_LOCATION") || ""
                  )?.address
                : undefined
            }
            onChange={(e: any) => {
              if (e?.target?.value?.trim()?.length === 0) {
                const filters = {
                  ...roomFilters,
                  lat: null,
                  lng: null,
                  searchByLandmark: false,
                };
                dispatch(listMeetingRoomFilterAction(filters));
              }
            }}
            className={"google-search-input"}
            apiKey={process.env.REACT_APP_GG_KEY}
            style={{ width: "100%" }}
            onPlaceSelected={(place) => {
              const sessionObj = {
                address: place?.formatted_address,
                lat: place?.geometry?.location?.lat(),
                lng: place?.geometry?.location?.lng(),
              };
              sessionStorage.setItem(
                "DEFAULT_ROOM_LOCATION",
                JSON.stringify(sessionObj)
              );
              setPlace(place);
            }}
            options={{
              types: [],
              componentRestrictions: {
                country: filterMarket?.alpha2_country_code || "",
              },
            }}
          />
        </div>
        <div className={"filter-button"}>
          <ButtonCustom
            height={"44px"}
            className={"primary w-100"}
            onClick={_onChangeStartEndTime}
          >
            {translations.show}
          </ButtonCustom>
        </div>
      </div>

      <SizeBox height={"12px"} />
      <div className={"book-filter-wrapper__bottom"}>
        <DropdownWithCheckboxMenu
          menuTitle={translations.propertyAmenities.amenities}
          reverseText={false}
          rowClassName={"book-filter-sub__item-row"}
          onChange={(event: any, { value }: { value: string }) => {
            _handleFilterAmenity(value);
          }}
          listCheckBox={decoratedListAmenity()}
          label={
            <span className={"filter-amenity"}>
              {translations.propertyAmenities.amenities}
              {roomFilters.amenities.length ? (
                <span className={"filter-amenity-length"}>
                  {roomFilters.amenities.length}
                </span>
              ) : null}
            </span>
          }
          icon={
            <img
              className={"dropdown icon"}
              src={PUBLIC_URL + "/assets/icons/icon-caret-down.svg"}
            />
          }
        />

        <div className={"line"} />

        <DropdownWithRadioMenu
          rowClassName={"book-filter-sub__item-row"}
          onChange={(event: any, { value }: { value: string }) => {
            _handleFilterSortBy(value);
          }}
          listCheckBox={decoratedListSortBy()}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          label={translations.roomSortBy[roomFilters.sort_by]}
          icon={
            <img
              className={"dropdown icon"}
              src={PUBLIC_URL + "/assets/icons/icon-caret-down.svg"}
            />
          }
        />
      </div>
    </div>
  );
};

export default FilterRoom;
