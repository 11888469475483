import React, { useContext, useEffect, useState } from "react";
import { PropertyNoteType } from "../../../../../configs/DeclareType";
import DropdownCustom from "../../../../../../../components/DropdownCustom";
import { PUBLIC_URL } from "../../../../../../App/configs/Constants";
import moment from "moment-timezone";
import { FORMAT_DATE_TIME_VARIANT_8 } from "../../../../../../App/configs/DateTimeConfigs";
import SizeBox from "../../../../../../../components/SizeBox";
import { Dropdown } from "semantic-ui-react";
import { SWAL_DELETE_OPTION } from "../../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../../components/SweetAlert";
import { toastSuccess } from "../../../../../../../components/Toast";
import {
  deletePropertyNoteService,
  propertyNoteVersioningService,
} from "../../../../../services/PropertyServices";
import EditLog from "./EditLog";
import Avatar from "./Avatar";
import { LocalizationContext } from "../../../../../../../locales/Translation";

type Props = {
  item: PropertyNoteType;
  setModalAddNote: any;
  setModalNoteDetail: any;
  onRefresh: any;
};
const CardItem = ({
  item,
  setModalAddNote,
  setModalNoteDetail,
  onRefresh,
}: Props) => {
  const { translations } = useContext(LocalizationContext);

  const [versioning, setVersioning] = useState([]);

  const [hover, setHover] = useState(false);

  const {
    id,
    avatar,
    comment,
    created_at,
    name,
    last_edit,
    title,
    updated_at,
    is_updated,
    cap_name,
  } = item;

  const _onEdit = () => {
    setModalAddNote({
      open: true,
      id,
      comment,
      title,
    });
  };

  const _onDelete = () => {
    try {
      const title = translations.areYouSureDeleteNote;
      const iconHtml = (
        <img
          src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
          className={"swal2-icon__error"}
          alt="error_icon"
        />
      );
      const confirmText = translations.delete;
      const confirmClass = "danger";
      const options = SWAL_DELETE_OPTION({
        title,
        iconHtml,
        confirmText,
        confirmClass,
      });
      SweetAlertCommon({
        options,
        confirmCallback: async () => {
          try {
            await deletePropertyNoteService(id);
            toastSuccess(translations.deleteSuccessPrompt);
            onRefresh();
          } catch (e) {
            console.error(e);
          }
        },
        cancelCallback: () => null,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const _onPreviewDetail = () => {
    setModalNoteDetail({
      open: true,
      id,
      avatar,
      comment,
      created_at,
      name,
      title,
    });
  };

  const _getVersioning = async () => {
    try {
      const { data } = await propertyNoteVersioningService(id);
      setVersioning(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (versioning.length || !hover) {
      return;
    }
    _getVersioning();
  }, [hover]);

  return (
    <div className={"property-note-card-wrapper"} onClick={_onPreviewDetail}>
      <div className={"property-note-card"}>
        <div className={"header"}>
          <div className={"title"}>{title}</div>
          <DropdownCustom
            trigger={
              <div className={"config-button"}>
                <img src={PUBLIC_URL + "/assets/icons/three_dot_icon.svg"} />
              </div>
            }
            icon={null}
            pointing={window.screen.width < 640 ? "top right" : "top left"}
          >
            <Dropdown.Menu>
              <Dropdown.Item onClick={_onEdit}>
                <i className="uil uil-edit-alt" />
                {translations.editNote}
              </Dropdown.Item>
              <Dropdown.Item onClick={_onDelete}>
                <i className="uil uil-trash-alt" />
                {translations.deleteNote}
              </Dropdown.Item>
            </Dropdown.Menu>
          </DropdownCustom>
        </div>
        <div className={"comment"}>{comment}</div>
        <div className={"user"}>
          <Avatar imgUrl={avatar} capName={cap_name} />
          <span>{name}</span>
        </div>
        <div className={"created-on"}>
          {translations.createdOn}{" "}
          <span className={"created-on-date"}>
            {moment.unix(created_at).format(FORMAT_DATE_TIME_VARIANT_8)}
          </span>
        </div>
        {is_updated ? (
          <div
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <SizeBox height={"10px"} />
            <div className={"created-on"}>{translations.lastEditedOn}:</div>
            <SizeBox height={"8px"} />
            <div className={"edited-by"}>
              {moment.unix(updated_at).format(FORMAT_DATE_TIME_VARIANT_8)}{" "}
              {translations.by} {last_edit.name}
            </div>
            {hover && versioning.length ? <EditLog item={versioning} /> : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CardItem;
