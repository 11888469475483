import "./style.scss";

type Props = {
  height?: string;
  width?: string;
  margin?: string;
  backgroundColor?: string;
  type?: string;
};

const Divide = ({
  width = "1px",
  height = "40px",
  margin = "0 16px",
  backgroundColor = "#E9ECEE",
  type = "vertical",
}: Props) => {
  return (
    <div
      style={{ width, height, margin, backgroundColor }}
      className={"custom-divide " + type}
    />
  );
};

export default Divide;
