import React, { useContext, useEffect, useState } from "react";
import ButtonCustom from "../../../../../components/Button";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import { useSelector } from "react-redux";
import { dashboardPropertyChooseSelector } from "../../../selectors/DashboardSelectors";
import { DASHBOARD_PROPERTY_TYPE } from "../../../configs/DeclareTypes";
import ModalAddPromotion from "./Modal/ModalAddPromotion";
import Label from "../../../../../components/Label";
import { Link } from "react-router-dom";
import { PROPERTY_DETAIL_USER_REVIEW_LINK } from "../../../../App/configs/ListRoutes";
import { propertyPromotionDetailService } from "../../../services/DashboardServices";
import { Rating } from "react-simple-star-rating";
import { LocalizationContext } from "../../../../../locales/Translation";
import PropertyShow from "./PropertyShow";
import moment from "moment-timezone";

const PropertyList = () => {
  const { translations } = useContext(LocalizationContext);
  const dashboardPropertyChoose: DASHBOARD_PROPERTY_TYPE = useSelector(
    dashboardPropertyChooseSelector
  );

  const [openAddPromotion, setOpenAddPromotion] = useState<boolean>(false);

  const [promotionApply, setPromotionApply] = useState<{
    id: number;
    value: number;
    start_time: number;
    end_time: number;
    timezone: string;
    rate_per_hour: string;
  } | null>(null);

  useEffect(() => {
    (async () => {
      try {
        if (!dashboardPropertyChoose?.property_id) {
          return;
        }
        const res = await propertyPromotionDetailService({
          property_id: dashboardPropertyChoose?.property_id,
        });
        setPromotionApply(res?.data || null);
      } catch (e) {
        setPromotionApply(null);
      }
    })();
  }, [dashboardPropertyChoose]);

  return (
    <>
      <div className={"dashboard_property_list"}>
        <PropertyShow />
        <div className={"dashboard_property_rate"}>
          <Rating
            size={17}
            ratingValue={
              (parseFloat(dashboardPropertyChoose?.rate_score) / 5) * 100
            }
            readonly
            fillColor={"#003DBC"}
            style={{ top: "2px" }}
          />
          <div className={"dashboard_property_rate_text"}>
            {parseFloat(dashboardPropertyChoose?.rate_score) > 0
              ? dashboardPropertyChoose?.rate_score
              : ""}
          </div>
        </div>
        <Link
          to={PROPERTY_DETAIL_USER_REVIEW_LINK.replaceAll(
            ":id",
            String(dashboardPropertyChoose?.property_id)
          )}
          className={"dashboard_property_review_btn"}
        >
          {translations.showReviews}
        </Link>
        <div>
          <ButtonCustom
            className={"dashboard_property_promotion_btn"}
            onClick={() => setOpenAddPromotion(true)}
          >
            <img
              width={"24px"}
              height={"19px"}
              src={PUBLIC_URL + "/assets/icons/icon-nimbus-marketing.svg"}
            />
            <span>{translations.promotion}</span>
          </ButtonCustom>
          <Label
            fontSize={"12px"}
            className={"fw-500"}
            label={
              promotionApply &&
              promotionApply?.value > 0 &&
              moment().isBetween(
                moment.unix(promotionApply.start_time),
                moment.unix(promotionApply.end_time)
              )
                ? `${translations.promotionApplied}: ${promotionApply?.value}%`
                : ""
            }
          />
        </div>
      </div>
      <ModalAddPromotion
        open={openAddPromotion}
        setOpen={setOpenAddPromotion}
        promotionApply={promotionApply}
        setPromotionApply={setPromotionApply}
      />
    </>
  );
};

export default PropertyList;
