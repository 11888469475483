import "./style.scss";
import ButtonCustom from "../Button";

type Props = {
  imgUrl?: any;
  name?: any;
  title?: any;
  icon?: any;
  capName?: any;
  size?: any;
  isAction?: any;
  handleAction?: any;
  isButtonBorder?: any;
};

const UserRowItem = ({
  imgUrl,
  name,
  title,
  icon,
  capName = "AU",
  size = "36px",
  isAction = false,
  handleAction,
  isButtonBorder = true,
}: Props) => {
  return (
    <div className={"user-row-item"}>
      <div className={"d-flex align-items-center"}>
        <div className={"user-avatar"} style={{ width: size, height: size }}>
          {imgUrl ? (
            <img className={"user-avatar__image"} src={imgUrl} />
          ) : (
            <div className={"user-avatar__capname"}>{capName}</div>
          )}
        </div>
        <div className={"user-row-item__meta"}>
          <div className={"user-row-item__meta-name"}>{name}</div>
          {title ? (
            <div className={"user-row-item__meta-title"}>{title}</div>
          ) : null}
        </div>
      </div>
      {isAction ? (
        <ButtonCustom
          onClick={handleAction}
          className={
            "button button-sm button-outline-default " +
            (isButtonBorder ? "" : "border-0")
          }
        >
          {icon ? icon : <i className="uil uil-times" />}
        </ButtonCustom>
      ) : null}
    </div>
  );
};

export default UserRowItem;
