import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import ModalRightSideBarTemplate from "../../../../../App/views/ModalRightSideBarTemplate";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  isModalUpdatePaymentMethodSelector,
  paymentMethodSelector,
} from "../../../../selectors/BillingSelector";
import {
  modalAddCardAction,
  modalUpdatePaymentMethodAction,
} from "../../../../actions/BiilingActions";
import SizeBox from "../../../../../../components/SizeBox";
import "./style.scss";
import CardItem from "./Components/CardItem";
import { updatePrimaryCardService } from "../../../../services/SettingServices";
import { SWAL_DELETE_OPTION } from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import { getPaymentMethodThunk } from "../../../../thunks/BillingThunk";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { removeBusinessCreditCardService } from "../../../../services/Common";

const ModalUpdatePaymentMethod = () => {
  const paymentMethods = useSelector(paymentMethodSelector);
  const dispatch = useDispatch();
  const isModalUpdatePaymentMethod = useSelector(
    isModalUpdatePaymentMethodSelector
  );
  const [loading, setLoading] = useState(false);
  const { translations } = useContext(LocalizationContext);
  const [primaryCard, setPrimaryCard] = useState<null | object>(null);
  const [anotherCards, setAnotherCards] = useState<any>([]);

  useEffect(() => {
    if (!paymentMethods) {
      return;
    }

    let primaryCardTemp = null;
    const anotherCardsTemp: any = [];

    paymentMethods.forEach((item: any) => {
      if (item?.is_primary) {
        primaryCardTemp = item;
        return;
      }

      anotherCardsTemp.push(item);
    });

    setPrimaryCard(primaryCardTemp);
    setAnotherCards(anotherCardsTemp);
  }, [paymentMethods]);

  const _openModalAddCard = () => {
    dispatch(modalAddCardAction(true));
  };

  const _changePrimaryCard = async (id: any) => {
    setLoading(true);
    try {
      await updatePrimaryCardService({ business_credit_card_payment_id: id });
      await dispatch<any>(getPaymentMethodThunk());
      toastSuccess(translations.settings.updatePrimaryCardSuccessMsg);
    } catch (response: any) {
      toastError(response?.status?.code);
    } finally {
      setLoading(false);
    }
  };

  const _removeCreditCard = async (id: any) => {
    SweetAlertCommon({
      options: SWAL_DELETE_OPTION({
        title: translations.settings.removeCardMsg,
        icon: null,
        confirmText: translations.settings.yesRemove,
      }),
      confirmCallback: async () => {
        await removeBusinessCreditCardService(id, translations, () =>
          dispatch<any>(getPaymentMethodThunk())
        );
      },
      cancelCallback: () => null,
    });
  };

  return (
    <ModalRightSideBarTemplate
      trigger={null}
      onClose={() => dispatch(modalUpdatePaymentMethodAction(false))}
      modalClassName={"modal-edit-employee"}
      open={isModalUpdatePaymentMethod}
    >
      <Modal.Header>
        <div>{translations.settings.updatePaymentMethod}</div>
        <ButtonCustom
          onClick={() => dispatch(modalUpdatePaymentMethodAction(false))}
          className={"m-0 p-0 none-bg close-modal"}
        >
          <>
            {translations.settings.close}
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Modal.Content className={"scroll"}>
        <div className={"list-card"}>
          {primaryCard ? (
            <CardItem removeCreditCard={_removeCreditCard} card={primaryCard} />
          ) : null}
          {anotherCards
            ? anotherCards.map((card: any, index: number) => (
                <CardItem
                  loading={loading}
                  removeCreditCard={_removeCreditCard}
                  onChangePrimaryCard={_changePrimaryCard}
                  card={card}
                  key={index}
                />
              ))
            : null}
        </div>
        <ButtonCustom
          onClick={_openModalAddCard}
          className={"outline-default w-100"}
          height={"36px"}
        >
          <>
            {translations.settings.addCard} <i className="uil uil-plus" />
          </>
        </ButtonCustom>
        <SizeBox height={"74px"} />
        <div className={"text-center"}>
          <img src={PUBLIC_URL + "/assets/icons/icon-stripe.svg"} />
        </div>
      </Modal.Content>
    </ModalRightSideBarTemplate>
  );
};

export default ModalUpdatePaymentMethod;
