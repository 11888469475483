import clientRequestService from "../../../services/ClientRequestService";
import {
  API_AFFILIATE_COMPANY_STATUS,
  API_AFFILIATE_INFO,
  API_AFFILIATE_LIST,
  API_AFFILIATE_REQUEST_PAYOUT,
  API_COMPANY_INTEGRATION,
  API_COMPANY_INTEGRATION_CONFIG,
  API_PARTNER_GET_REVENUE,
  DELETE_CREATE_BILLING_CONTACT_API,
  DELETE_REMOVE_CARD_API,
  GET_BILLING_CONTACT_API,
  GET_BILLING_INFO_API,
  GET_INVOICE_DETAIL_API,
  GET_INVOICE_DETAIL_PROPERTY_API,
  GET_INVOICE_FILTER_API,
  GET_INVOICE_PROPERTY_FILTER_API,
  GET_MONTHLY_USAGE_API,
  GET_PAYMENT_METHOD_API,
  GET_PROPERTIES_API,
  GET_SETTINGS_API,
  GET_TRANSACTION_FILTER_API,
  POST_CREATE_BILLING_CONTACT_API,
  POST_CREATE_PAYMENT_METHOD_API,
  POST_GET_PAYMENT_HISTORY_API,
  POST_GET_PAYMENT_HISTORY_PROPERTY_API,
  POST_GET_PREPAID_CREDIT_TRANSACTION_API,
  POST_GET_PREPAID_CREDIT_USAGE_API,
  POST_REQUEST_POST_PAY_API,
  POST_UPDATE_PRIMARY_CARD_API,
  POST_UPDATE_SETTINGS_API,
  PUT_UPDATE_BILLING_INFORMATION_API,
} from "../configs/Endpoints";
import { CURRENT_COMPANY_ID } from "../../../configs/Constants";

export const getPaymentHistoryService = async (payload: object | null) => {
  return await clientRequestService({
    method: "post",
    url: POST_GET_PAYMENT_HISTORY_API,
    data: payload,
    isCompany: true,
  });
};

export const getPropertyInvoicesService = async (payload: object | null) => {
  return await clientRequestService({
    method: "post",
    url: POST_GET_PAYMENT_HISTORY_PROPERTY_API,
    data: payload,
    isCompany: true,
  });
};

export const getInvoiceDetailService = async (token: string) => {
  return await clientRequestService({
    url: GET_INVOICE_DETAIL_API.replaceAll("%token%", token),
    isCompany: true,
  });
};

export const getMonthlyUsageService = async () => {
  return await clientRequestService({
    url: GET_MONTHLY_USAGE_API,
    isCompany: true,
  });
};

export const getPaymentMethodService = async () => {
  return await clientRequestService({
    url: GET_PAYMENT_METHOD_API,
    isCompany: true,
  });
};

export const getBillingContactService = async () => {
  return await clientRequestService({
    url: GET_BILLING_CONTACT_API,
    isCompany: true,
  });
};

export const getBillingInfoService = async () => {
  return await clientRequestService({
    url: GET_BILLING_INFO_API,
    isCompany: true,
  });
};

//Prepaid credit
export const getPaidCreditTransactionService = async (
  payload: object | null
) => {
  return await clientRequestService({
    method: "post",
    url: POST_GET_PREPAID_CREDIT_TRANSACTION_API,
    isCompany: true,
    data: payload,
  });
};

export const getPaidCreditUsageService = async (payload: object | null) => {
  return await clientRequestService({
    method: "post",
    url: POST_GET_PREPAID_CREDIT_USAGE_API,
    isCompany: true,
    data: payload,
  });
};

//Payment method
export const createPaymentMethodService = async (payload?: object) => {
  return await clientRequestService({
    method: "post",
    url: POST_CREATE_PAYMENT_METHOD_API,
    isCompany: true,
    data: payload,
  });
};

export const updatePrimaryCardService = async (payload?: object) => {
  return await clientRequestService({
    method: "post",
    url: POST_UPDATE_PRIMARY_CARD_API,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    isCompany: !payload?.company_id,
    data: payload,
  });
};

export const removeCreditCardService = async (payload?: object) => {
  let url = DELETE_REMOVE_CARD_API;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const payloadCompanyId = payload?.company_id;

  if (payloadCompanyId) {
    url = url.replaceAll("%companyId%", payloadCompanyId);
  }

  return await clientRequestService({
    method: "delete",
    url,
    isCompany: !payloadCompanyId,
    data: payload,
  });
};

//Add Billing contact
export const addBillingContactService = async (payload?: object) => {
  return await clientRequestService({
    method: "post",
    url: POST_CREATE_BILLING_CONTACT_API,
    isCompany: true,
    data: payload,
  });
};

export const removeBillingContactService = async (payload?: object) => {
  return await clientRequestService({
    method: "delete",
    url: DELETE_CREATE_BILLING_CONTACT_API,
    isCompany: true,
    data: payload,
  });
};

//Billing information
export const updateBillingInformationService = async (payload?: object) => {
  return await clientRequestService({
    method: "put",
    url: PUT_UPDATE_BILLING_INFORMATION_API,
    isCompany: true,
    data: payload,
  });
};

//Invoice
export const getInvoiceService = async (token: any) => {
  return await clientRequestService({
    url: GET_INVOICE_DETAIL_API.replaceAll("%token%", token),
    isCompany: true,
  });
};

export const getInvoiceServiceForDeskimoAdmin = async (
  token: any,
  companyId: any
) => {
  return await clientRequestService({
    url: GET_INVOICE_DETAIL_API.replaceAll("%token%", token).replaceAll(
      "%companyId%",
      companyId
    ),
  });
};

export const getInvoiceDetailPropertyService = async (token: any) => {
  return await clientRequestService({
    url: GET_INVOICE_DETAIL_PROPERTY_API.replaceAll("%token%", token),
    isCompany: true,
  });
};

//Settings
export const getSettingService = async () => {
  return await clientRequestService({
    method: "get",
    url: GET_SETTINGS_API,
    isCompany: true,
  });
};

export const saveSettingService = async (payload?: object) => {
  return await clientRequestService({
    method: "post",
    url: POST_UPDATE_SETTINGS_API,
    isCompany: true,
    data: payload,
  });
};

export const requestPostPayService = async (payload?: any) => {
  return await clientRequestService({
    method: "post",
    url: POST_REQUEST_POST_PAY_API,
    isCompany: true,
    data: payload,
  });
};

export const getListPropertiesService = async () => {
  return await clientRequestService({
    method: "get",
    url: GET_PROPERTIES_API,
    isCompany: true,
  });
};

export const getInvoiceFiltersService = async () => {
  return await clientRequestService({
    method: "get",
    url: GET_INVOICE_FILTER_API,
    isCompany: true,
  });
};

export const getInvoicePropertyFiltersService = async () => {
  return await clientRequestService({
    url: GET_INVOICE_PROPERTY_FILTER_API,
    isCompany: true,
  });
};

export const getTransactionFiltersService = async () => {
  return await clientRequestService({
    method: "get",
    url: GET_TRANSACTION_FILTER_API,
    isCompany: true,
  });
};

export const getCompanyIntegrationService = async (data?: object) => {
  const companyId = localStorage.getItem(CURRENT_COMPANY_ID);
  if (!companyId) {
    return;
  }

  return await clientRequestService({
    method: "post",
    url: API_COMPANY_INTEGRATION.replaceAll("%companyId%", companyId),
    data,
  });
};

export const companyIntegrationService = async (
  itemId: number,
  data?: object
) => {
  const companyId = localStorage.getItem(CURRENT_COMPANY_ID);
  if (!companyId) {
    return;
  }

  return await clientRequestService({
    method: "post",
    url: API_COMPANY_INTEGRATION_CONFIG.replaceAll(
      "%companyId%",
      companyId
    ).replaceAll("%itemId%", String(itemId)),
    data,
  });
};

export const partnerRevenueService = async () => {
  return await clientRequestService({
    url: API_PARTNER_GET_REVENUE,
    isCompany: true,
  });
};

export const affiliateListService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: API_AFFILIATE_LIST,
    data,
    isCompany: true,
  });
};

export const getAffiliateInfoService = async () => {
  return await clientRequestService({
    url: API_AFFILIATE_INFO,
    isCompany: true,
  });
};

export const affiliateRequestPayoutService = async (
  affiliateRef: string,
  data: object
) => {
  return await clientRequestService({
    method: "post",
    url: API_AFFILIATE_REQUEST_PAYOUT.replaceAll("%reference%", affiliateRef),
    data,
    isCompany: true,
  });
};

export const affiliateChangeStatusService = async (
  affiliateRef: string,
  data: object
) => {
  return await clientRequestService({
    method: "post",
    url: API_AFFILIATE_COMPANY_STATUS.replaceAll("%reference%", affiliateRef),
    data,
    isCompany: true,
  });
};
