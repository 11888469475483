import React, { useContext } from "react";
import "./styles.scss";
import ButtonCustom from "../../../../../components/Button";
import { LocalizationContext } from "../../../../../locales/Translation";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import { Link } from "react-router-dom";
import {
  handleHoverCard,
  handleHoverLeaveCard,
} from "../../../services/BookServices";
import classNames from "classnames";
import {
  CARD_TYPE_DESK,
  CARD_TYPE_PRIVATE_OFFICE,
} from "../../../configs/Constants";
import { modalCheckinAction } from "../../../actions/BookActions";
import { useDispatch } from "react-redux";
import CheckInText from "./CheckInText";

type Props = {
  id: number;
  thumbnailUrl: string;
  name: string;
  office?: string;
  address: string;
  openTime?: string;
  capacity?: string | number;
  rating?: string | null;
  price: string;
  unit: string;
  lat?: number;
  lng?: number;
  isFavorite?: boolean | 0 | 1;
  favoriteCallback?: (currentFavorite: boolean | 0 | 1) => void;
  bookCallback?: () => void;
  onClickCallback?: () => void;
  goToDetailLink: string;
  isClosed?: boolean;
  buttonClass?: string;
  buttonText?: string;
  originalDailyRate?: string;
  cardType?: string;
  originalMonthlyRate?: string;
  formattedOriginalMonthlyRate?: string;
  formattedMonthlyRate?: string;
  newBadge?: boolean;
  distance?: string;
  currencyCode?: string;
  hrPrice?: string | number;
  isHidden?: number;
  fullDayPrice?: string;
};

const ListCard = ({
  id,
  thumbnailUrl,
  name,
  address,
  capacity,
  office,
  openTime,
  rating,
  price,
  unit,
  lat,
  lng,
  isFavorite,
  favoriteCallback,
  bookCallback,
  goToDetailLink,
  isClosed,
  buttonClass,
  buttonText,
  originalDailyRate,
  cardType,
  originalMonthlyRate,
  formattedMonthlyRate,
  formattedOriginalMonthlyRate,
  newBadge,
  distance,
  currencyCode,
  hrPrice,
  isHidden,
  fullDayPrice,
}: Props) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <>
      <div
        className={classNames(
          "book-card",
          { "book-card__closed": isClosed },
          { " private-office-card ": cardType === CARD_TYPE_PRIVATE_OFFICE }
        )}
      >
        {newBadge ? (
          <img
            src={PUBLIC_URL + "/assets/icons/badge-new.svg"}
            alt={name}
            className={"book-card__badge-new"}
          />
        ) : null}
        <div className={"book-card__thumbnail"}>
          <Link
            to={goToDetailLink}
            target={"_blank"}
            onMouseEnter={() => {
              if (!id || !lat || !lng) {
                return;
              }
              handleHoverCard({ id, lat, lng });
            }}
            onMouseLeave={() => {
              if (!id || !lat || !lng) {
                return;
              }
              handleHoverLeaveCard();
            }}
          >
            <img
              src={thumbnailUrl || PUBLIC_URL + "/assets/images/logo-text.png"}
              alt={name}
              className={"book-card__thumbnail-img"}
            />
          </Link>
          {isFavorite !== undefined ? (
            <div
              className={"book-card__thumbnail-favorite"}
              onClick={(e) => {
                e.preventDefault();
                favoriteCallback && favoriteCallback(isFavorite);
              }}
            >
              {isFavorite ? (
                <img src={PUBLIC_URL + "/assets/icons/icon-heart-full.svg"} />
              ) : (
                <img src={PUBLIC_URL + "/assets/icons/icon-heart-empty.svg"} />
              )}
            </div>
          ) : null}
        </div>
        <div className={"book-card__info"}>
          <div className={"book-card__info-header"}>
            <div className={"book-card__info-header__name"}>
              <Link
                to={goToDetailLink}
                target={"_blank"}
                onMouseEnter={() => {
                  if (!id || !lat || !lng) {
                    return;
                  }
                  handleHoverCard({ id, lat, lng });
                }}
                onMouseLeave={() => {
                  if (!id || !lat || !lng) {
                    return;
                  }
                  handleHoverLeaveCard();
                }}
              >
                {name}
              </Link>
            </div>
            {rating ? (
              <div className={"book-card__info-header__star"}>
                <img src={PUBLIC_URL + "/assets/icons/icon-star-yellow.svg"} />
                {rating}
              </div>
            ) : null}
          </div>

          {cardType === CARD_TYPE_PRIVATE_OFFICE ? null : (
            <div className={"book-card__info-bottom__price"}>
              {originalMonthlyRate ? (
                <div className={"original-price"}>
                  <span className={"value"}>
                    {formattedOriginalMonthlyRate}
                  </span>{" "}
                  / {unit}
                </div>
              ) : (
                ""
              )}
              <div className={"actual-price"}>
                {cardType === CARD_TYPE_PRIVATE_OFFICE
                  ? translations.StartingFrom
                  : ""}{" "}
                <span className={"value"}>
                  {cardType === CARD_TYPE_PRIVATE_OFFICE
                    ? formattedMonthlyRate
                    : price}
                </span>
                <span className={"book-card__info-bottom__unit"}>
                  {" "}
                  / {unit}
                </span>
              </div>
            </div>
          )}

          {isHidden === 1 || fullDayPrice?.length === 0 ? null : (
            <CheckInText
              cardType={cardType}
              currency={currencyCode}
              price={cardType === CARD_TYPE_DESK ? hrPrice : fullDayPrice}
            />
          )}

          <div className={"book-card__info-items"}>
            {office ? (
              <div className={"book-card__info-item"}>
                <i className="uil uil-building" />
                {office}
              </div>
            ) : null}

            {address ? (
              <div className={"book-card__info-item"}>
                <i className="uil uil-map" />
                {address}
              </div>
            ) : null}

            {openTime ? (
              <div className={"book-card__info-item"}>
                <i className="uil uil-clock" />
                {openTime}
              </div>
            ) : null}

            {capacity ? (
              <div className={"book-card__info-item"}>
                <i className="uil uil-users-alt" />
                {cardType === CARD_TYPE_PRIVATE_OFFICE
                  ? capacity + " " + translations.pax
                  : capacity +
                    " " +
                    (capacity > 1 ? translations.people : translations.person)}
              </div>
            ) : null}

            {distance ? (
              <div className={"book-card__info-item"}>
                <i className="uil uil-location-arrow"></i>
                {distance}
              </div>
            ) : null}
          </div>

          <div className={"book-card__info-bottom"}>
            {cardType === CARD_TYPE_PRIVATE_OFFICE ? (
              <div className={"book-card__info-bottom__price"}>
                {originalMonthlyRate ? (
                  <div className={"original-price"}>
                    <span className={"value"}>
                      {formattedOriginalMonthlyRate}
                    </span>{" "}
                    / {unit}
                  </div>
                ) : (
                  ""
                )}
                <div className={"actual-price"}>
                  {cardType === CARD_TYPE_PRIVATE_OFFICE
                    ? translations.StartingFrom
                    : ""}{" "}
                  <span className={"value"}>
                    {cardType === CARD_TYPE_PRIVATE_OFFICE
                      ? formattedMonthlyRate
                      : price}
                  </span>
                  <span className={"book-card__info-bottom__unit"}>
                    {" "}
                    / {unit}
                  </span>
                </div>
              </div>
            ) : null}

            <div className={"book-ctas"}>
              <ButtonCustom
                className={classNames(
                  "book-card__info-bottom__book",
                  buttonClass
                )}
                height={"32px"}
                onClick={(e: any) => {
                  e.preventDefault();
                  bookCallback && bookCallback();
                }}
              >
                {buttonText
                  ? buttonText
                  : isClosed
                  ? translations.closed
                  : translations.book}
              </ButtonCustom>
            </div>
          </div>
        </div>
        {isClosed ? (
          <Link
            to={goToDetailLink}
            className={"book-card__closed_box"}
            target={"_blank"}
          >
            View Property
          </Link>
        ) : null}
      </div>
    </>
  );
};

export default ListCard;
