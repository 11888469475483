import React from "react";
import MasterTemplate from "../../../App/views/MasterTemplate";
import { ROLE_DESKIMO_ADMIN_ID } from "../../../User/configs/Constants";
import "./styles.scss";
import SectionTop from "./Components/SectionTop";
import Diagram from "./Components/Diagram";
import UsageList from "./Components/UsageList";
import ModalBookingRoom from "./Components/ModalBookingRoom";
import ModalBookingDesk from "./Components/ModalBookingDesk";
import ModalDeclineReason from "../DashboardProperty/Property/Modal/ModalDeclineReason";
import ModalCreateWalkIns from "./Components/ModalCreateWalkIns";

const DashboardDeskimo = () => {
  return (
    <MasterTemplate roleAccept={[ROLE_DESKIMO_ADMIN_ID]}>
      <SectionTop />
      <Diagram />
      <UsageList />
      <ModalBookingRoom />
      <ModalBookingDesk />
      <ModalCreateWalkIns />
      <ModalDeclineReason />
    </MasterTemplate>
  );
};

export default DashboardDeskimo;
