import { FormikErrors, FormikTouched, FormikValues } from "formik";
import Label from "../../../../../components/Label";
import SizeBox from "../../../../../components/SizeBox";
import React from "react";
import InputCustom from "../../../../../components/Input";

type Props = {
  values: FormikValues;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  setFieldValue: any;
  isSubmitting: boolean;
  handleChange: any;
};

const PartnerConfiguration = ({
  values,
  errors,
  touched,
  isSubmitting,
  handleChange,
}: Props) => {
  return (
    <>
      <Label
        label={"Partner Configuration"}
        fontSize={"24px"}
        className={"section-title"}
      />
      <div className={"detail_divide"}>
        <div className={"left_section"}>
          <InputCustom
            type={"number"}
            min={0}
            max={100}
            inputLeftIcon={"%"}
            inputLeftIconBorder={true}
            label={"Hotdesk commission"}
            value={values.partner_desk_commission}
            error={errors.partner_desk_commission}
            touched={touched.partner_desk_commission}
            name={"partner_desk_commission"}
            onChange={handleChange}
            disabled={isSubmitting}
          />
        </div>

        <div className={"right_section"}>
          <InputCustom
            type={"number"}
            min={0}
            max={100}
            inputLeftIcon={"%"}
            inputLeftIconBorder={true}
            label={"Meeting room commission"}
            value={values.partner_room_commission}
            error={errors.partner_room_commission}
            touched={touched.partner_room_commission}
            name={"partner_room_commission"}
            onChange={handleChange}
            disabled={isSubmitting}
          />
        </div>
      </div>

      <div className={"detail_divide"}>
        <div className={"left_section"}>
          <InputCustom
            type={"number"}
            min={0}
            label={"Authentication API Limit"}
            value={values.authentication_api_limit}
            error={errors.authentication_api_limit}
            touched={touched.authentication_api_limit}
            name={"authentication_api_limit"}
            onChange={handleChange}
            disabled={isSubmitting}
          />
        </div>

        <div className={"right_section"}>
          <InputCustom
            type={"number"}
            min={0}
            label={"Other API Limit"}
            value={values.other_api_limit}
            error={errors.other_api_limit}
            touched={touched.other_api_limit}
            name={"other_api_limit"}
            onChange={handleChange}
            disabled={isSubmitting}
          />
        </div>
      </div>

      <SizeBox height={"24px"} />
      <div className={"property_company_detail_line"} />
    </>
  );
};

export default PartnerConfiguration;
