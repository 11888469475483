import { modalCheckinAction } from "../../../actions/BookActions";
import React from "react";
import { useDispatch } from "react-redux";
import { CARD_TYPE_DESK, CARD_TYPE_ROOM } from "../../../configs/Constants";

const CheckInText = ({ cardType, currency, price }: any) => {
  const dispatch = useDispatch();

  if (cardType === CARD_TYPE_DESK) {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          dispatch(modalCheckinAction({ open: true }));
        }}
        className={"check-in-price"}
      >
        Instant access from{" "}
        <span>
          {currency} {price}
        </span>{" "}
        / Hr{" "}
        <span style={{ fontWeight: 400 }}>
          <i className="uil uil-info-circle" />
        </span>
      </div>
    );
  }

  if (cardType === CARD_TYPE_ROOM) {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          dispatch(modalCheckinAction({ open: true }));
        }}
        className={"check-in-price"}
      >
        Full Day Rate:
        <span>
          {currency} {price}
        </span>{" "}
        / Day{" "}
      </div>
    );
  }

  return <></>;
};

export default CheckInText;
