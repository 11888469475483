import {
  ACCOUNTING_ACTION,
  CONFIG_CREATE_MARKET_PUBLIC_HOLIDAYS,
  CONFIG_DELETE_MARKET_LANDMARK,
  CONFIG_DELETE_MARKET_PUBLIC_HOLIDAYS,
  CONFIG_MARKET_COMMISSION_SHARE_ACTION,
  CONFIG_MARKET_EXCHANGE_RATE_LIST_ACTION,
  CONFIG_MARKET_GENERAL_INFORMATION_ACTION,
  CONFIG_MARKET_LANDMARK_LIST_ACTION,
  CONFIG_MARKET_LIST_ACTION,
  CONFIG_MARKET_PUBLIC_HOLIDAYS_LIST_ACTION,
  CONFIG_TAGS_LIST_ACTION,
  CONFIG_UPDATE_MARKET_GENERAL_INFORMATION_ACTION,
  CONFIG_UPDATE_MARKET_STATUS_ACTION,
  MARKET_TOGGLE_LIST_ACTION,
  PARTNER_CREDENTIAL_INFO_ACTION,
  PARTNER_LIMITATION_ACTION,
  PARTNER_WEBHOOK_LIST_ACTION,
  ROLE_PERMISSION_ACTION,
} from "../configs/Constants";
import { createReducer } from "@reduxjs/toolkit";
import { ConfigMarketDataType } from "../configs/DeclareTypes";

const initializeState = {
  configMarketList: [],
  configMarketExchangeRateList: [],
  configMarketGeneralInformation: {},
  configMarketCommissionShareValue: {},
  configMarketListFilter: {
    keyword: "",
    page: 1,
  },
  configMarketPublicHolidaysList: [],
  configMarketLandmarkList: [],
  configTagsList: [],
  marketToggleList: [],
  partnerCredentialInfo: {},
  partnerWebhookList: [],
  partnerLimitation: {},
  rolePermission: {},
  accountingList: [],
};

const ConfigReducer = createReducer(initializeState, {
  [CONFIG_MARKET_LIST_ACTION]: (state, action) => {
    state.configMarketList = action.payload;
  },
  [CONFIG_MARKET_EXCHANGE_RATE_LIST_ACTION]: (state, action) => {
    state.configMarketExchangeRateList = action.payload;
  },
  [CONFIG_MARKET_COMMISSION_SHARE_ACTION]: (state, action) => {
    state.configMarketCommissionShareValue = action.payload;
  },
  [CONFIG_MARKET_GENERAL_INFORMATION_ACTION]: (state, action) => {
    state.configMarketGeneralInformation = action.payload;
  },

  [CONFIG_UPDATE_MARKET_GENERAL_INFORMATION_ACTION]: (state, action) => {
    if (!state.configMarketGeneralInformation) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.configMarketGeneralInformation.item =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.configMarketGeneralInformation.item.map(
        (item: ConfigMarketDataType) => {
          if (item.market_id !== action.payload) {
            return;
          }
          return { ...item };
        }
      );
  },

  [CONFIG_UPDATE_MARKET_STATUS_ACTION]: (state, action) => {
    if (!state.configMarketList) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.configMarketList.item = state.configMarketList.item.map(
      (item: ConfigMarketDataType) => {
        if (item.market_id !== action.payload) {
          return item;
        }
        return { ...item, status_id: item.status_id ? 0 : 1 };
      }
    );
  },
  [CONFIG_MARKET_PUBLIC_HOLIDAYS_LIST_ACTION]: (state, action) => {
    state.configMarketPublicHolidaysList = action.payload;
  },

  [CONFIG_CREATE_MARKET_PUBLIC_HOLIDAYS]: (state, action) => {
    state.configMarketPublicHolidaysList = action.payload || {};
  },

  [CONFIG_DELETE_MARKET_PUBLIC_HOLIDAYS]: (state, action) => {
    if (!state.configMarketPublicHolidaysList) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.configMarketPublicHolidaysList.item =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.configMarketPublicHolidaysList.item.filter(
        (item: any) => item.id !== action?.payload
      );
  },

  [CONFIG_MARKET_LANDMARK_LIST_ACTION]: (state, action) => {
    state.configMarketLandmarkList = action.payload;
  },

  [CONFIG_DELETE_MARKET_LANDMARK]: (state, action) => {
    if (!state.configMarketLandmarkList) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.configMarketLandmarkList.item =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.configMarketLandmarkList.item.filter(
        (landmark: any) => landmark.id !== action?.payload
      );
  },
  [CONFIG_TAGS_LIST_ACTION]: (state, action) => {
    state.configTagsList = action.payload;
  },

  [MARKET_TOGGLE_LIST_ACTION]: (state, action) => {
    state.marketToggleList = action.payload;
  },

  [PARTNER_CREDENTIAL_INFO_ACTION]: (state, action) => {
    state.partnerCredentialInfo = action.payload;
  },

  [PARTNER_WEBHOOK_LIST_ACTION]: (state, action) => {
    state.partnerWebhookList = action.payload;
  },

  [PARTNER_LIMITATION_ACTION]: (state, action) => {
    state.partnerLimitation = action.payload;
  },

  [ROLE_PERMISSION_ACTION]: (state, action) => {
    state.rolePermission = action.payload;
  },

  [ACCOUNTING_ACTION]: (state, action) => {
    state.accountingList = action.payload;
  },
});

export default ConfigReducer;
