import React, { useState } from "react";
import ButtonCustom from "../../../../../components/Button";
import InputCustom from "../../../../../components/Input";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import { toastError, toastSuccess } from "../../../../../components/Toast";
import { updateMediaFileService } from "../../../services/MediaFileServices";

type Props = {
  file: any;
  onRefresh: any;
  isPropertyUser?: any;
};

const MediaFileName = ({ file, onRefresh, isPropertyUser }: Props) => {
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");

  const updateMediaFile = async () => {
    setLoading(true);
    try {
      if (file?.reference) {
        const payload = {
          name: fileName,
        };
        await updateMediaFileService(file?.reference, payload);
        await onRefresh();
        toastSuccess("The file has been updated successfully!");
        setEditMode(false);
      }
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className={"d-flex gap-8 align-items-center"}>
        {editMode ? (
          <InputCustom
            disabled={loading}
            containerClass={"mb-0"}
            onChange={(e: any, data: any) => setFileName(data.value)}
            defaultValue={file?.name}
          />
        ) : (
          <div className={"d-flex gap-8 align-items-center"}>
            <img
              width={44}
              height={44}
              src={file?.thumbnail?.file_url}
              alt=""
            />
            <span style={{ color: "#003DBC" }}>{file?.name}</span>
          </div>
        )}

        {editMode && !isPropertyUser ? (
          <div className={"d-flex gap-8"} style={{ marginLeft: 8 }}>
            <ButtonCustom
              disabled={loading}
              height={"44px"}
              onClick={() => setEditMode(false)}
              className={"danger-link p-0 mr-0"}
            >
              Cancel
            </ButtonCustom>
            <ButtonCustom
              disabled={loading}
              loading={loading}
              height={"44px"}
              onClick={updateMediaFile}
              className={"link p-0 mr-0"}
            >
              Save
            </ButtonCustom>
          </div>
        ) : (
          <>
            {isPropertyUser ? null : (
              <ButtonCustom
                height={"14px"}
                onClick={() => setEditMode(true)}
                className={"edit-link p-0 mr-0"}
              >
                <i className="uil uil-edit-alt"></i>
              </ButtonCustom>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MediaFileName;
