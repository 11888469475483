import SizeBox from "../../../../../../../components/SizeBox";
import React, { useState, useEffect, useContext } from "react";
import RichTextEditor from "react-rte";
import EditActions from "./EditActions";
import { useDispatch, useSelector } from "react-redux";
import { propertyDetailSelector } from "../../../../../selectors/PropertySelector";
import { updatePropertyService } from "../../../../../services/PropertyServices";
import { getPropertyDetailThunk } from "../../../../../thunks/PropertyThunks";
import { toastError } from "../../../../../../../components/Toast";
import { useParams } from "react-router-dom";
import { LocalizationContext } from "../../../../../../../locales/Translation";
import RichText from "../../../../../../../components/RichText";

const PropertyDescription = ({ setLoading, loading, ...props }: any) => {
  const { translations } = useContext(LocalizationContext);

  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const propertyDetail = useSelector(propertyDetailSelector);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (propertyDetail?.description) {
      setValue(
        RichTextEditor.createValueFromString(
          propertyDetail?.description,
          "html"
        )
      );
    } else {
      setValue(RichTextEditor.createEmptyValue());
    }
  }, [propertyDetail]);

  const _handleSave = async () => {
    const payload = {
      description: value.toString("html"),
      property_id: id,
    };
    setLoading(true);
    try {
      await updatePropertyService(payload);
      await dispatch<any>(getPropertyDetailThunk(id ? id : ""));
      setEditMode(false);
    } catch (response: any) {
      toastError(response?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (value: any) => {
    setValue(value);
    if (props.onChange) {
      props.onChange(value.toString("html"));
    }
  };

  return (
    <div className={"section"}>
      <SizeBox height={"16px"} />
      <div
        className={
          "d-flex justify-content-between " +
          (editMode ? "" : "align-items-center")
        }
      >
        <div className={"pr-70 w-100"}>
          <div className={"section-title"}>
            {translations.propertyDescription}
          </div>
          <SizeBox height={"16px"} />
          {editMode ? (
            <div>
              <RichText value={value} onChange={onChange} />
            </div>
          ) : (
            <div className={"property-text"}>
              {propertyDetail?.description ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: propertyDetail?.description,
                  }}
                />
              ) : (
                translations.notAvailable
              )}
            </div>
          )}
        </div>

        <EditActions
          loading={loading}
          editMode={editMode}
          setEditMode={setEditMode}
          submit={_handleSave}
        />
      </div>
      <SizeBox height={"16px"} />
    </div>
  );
};

export default PropertyDescription;
