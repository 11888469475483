import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { Formik, FormikValues } from "formik";
import * as yup from "yup";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import ModalCenterTemplate from "../../../../../App/views/ModalCenterTemplate";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalCheckInManualSelector } from "../../../../selectors/DashboardSelectors";
import { modalCheckInManualAction } from "../../../../actions/DashboardActions";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { partnerCheckInManualService } from "../../../../services/DashboardServices";
import Label from "../../../../../../components/Label";
import SizeBox from "../../../../../../components/SizeBox";
import InputCustom from "../../../../../../components/Input";
import DropdownProperty from "../DropdownProperty";
import { userCompanyChooseSelector } from "../../../../../User/selectors/UserSelectors";
import { dashboardDeskUsageThunk } from "../../../../thunks/DashboardThunks";
import { BUSINESS, PRODUCT_TYPE_PARTNER } from "../../../../configs/Constants";

const ModalCheckInManual = () => {
  const { translations } = useContext(LocalizationContext);
  const modalCheckInManual = useSelector(modalCheckInManualSelector);
  const dispatch = useDispatch();
  const userCompanyChoose = useSelector(userCompanyChooseSelector);

  const _onCloseModal = () => {
    dispatch(modalCheckInManualAction({ open: false }));
  };

  const _onClickSave = async (values: FormikValues) => {
    try {
      const payload: any = {
        reference: values.property_reference,
        code: values.code.replaceAll(" ", ""),
        business_company_id: userCompanyChoose.company_id,
      };

      await partnerCheckInManualService(payload);
      await dispatch<any>(
        dashboardDeskUsageThunk(BUSINESS, false, false, PRODUCT_TYPE_PARTNER)
      );
      _onCloseModal();
      toastSuccess("Checked-in Successfully!");
    } catch (error: any) {
      toastError(error?.status?.message);
    }
  };

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={_onCloseModal}
      modalClassName={"modal-general"}
      open={modalCheckInManual.open}
    >
      <Modal.Header>
        Create Test Walk-ins
        <ButtonCustom onClick={_onCloseModal} className={"modal-close-cta"}>
          <>
            <span>{translations.close}</span>
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Formik
        initialValues={{
          property_reference: "",
          property_name: "",
          code: "",
        }}
        validationSchema={yup.object().shape({
          code: yup.string().required().label("Code"),
        })}
        onSubmit={_onClickSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <>
            <Modal.Content>
              <form className={"form-layout"}>
                <div>
                  <Label
                    fontSize={"12px"}
                    className={"sm"}
                    label={"Property"}
                  />
                  <DropdownProperty
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </div>
                <SizeBox height={"16px"} />
                <InputCustom
                  label={"QR Code Number"}
                  value={values.code}
                  onChange={handleChange}
                  name={"code"}
                  error={errors.code}
                  touched={touched.code}
                />
              </form>
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom
                disabled={isSubmitting}
                height={"47px"}
                onClick={_onCloseModal}
              >
                {translations.cancel}
              </ButtonCustom>
              <ButtonCustom
                disabled={!values.property_reference || !values.code}
                loading={isSubmitting}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {translations.checkIn}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalCenterTemplate>
  );
};

export default ModalCheckInManual;
