import React, { memo, useCallback, useEffect, useState } from "react";
import { GoogleMap } from "@react-google-maps/api";
import InfoWindowCustom from "./InfoWindowCustom";
import "./styles.scss";
import { BOOK_DESK_TAB, BOOK_MEETING_ROOM_TAB } from "../../configs/Constants";
import {
  BookMeetingRoomType,
  BookPropertyType,
} from "../../configs/DeclareType";
import { useDispatch, useSelector } from "react-redux";
import {
  filterMarketSelector,
  mapStateSelector,
  meetingRoomFiltersSelector,
  meetingRoomListSelector,
  propertyFilterSelector,
  propertyListSelector,
} from "../../selectors/BookSelectors";
import {
  infoWindowDataAction,
  mapStateAction,
} from "../../actions/BookActions";
import MarkerMarketCount from "./MarkerMarketCount";
import MarkerChildren from "./MarkerChildren";
import {
  GOOGLE_MAP_CONTAINER_STYLE,
  GOOGLE_MAP_OPTIONS,
  GOOGLE_MAP_ZOOM_DEFAULT,
} from "../../configs/MapConstants";
import MarkerCluster from "./MarkerCluster";

type Props = {
  tabActive: number;
};

const MapView = ({ tabActive }: Props) => {
  const dispatch = useDispatch();

  const [markerSameLatLng, setMarkerSameLatLng] = useState<any>([]);
  const [currentMarkerData, setCurrentMarkerData] = useState<
    { id: number; lat: number; lng: number }[]
  >([]);

  const filterMarket = useSelector(filterMarketSelector);
  const propertyFilter = useSelector(propertyFilterSelector);
  const meetingRoomFilters = useSelector(meetingRoomFiltersSelector);

  const propertyMap: BookPropertyType[] =
    useSelector(propertyListSelector)?.results;

  const meetingRoomList: BookMeetingRoomType[] = useSelector(
    meetingRoomListSelector
  );

  const [zoomLevel, setZoomLevel] = useState(GOOGLE_MAP_ZOOM_DEFAULT);
  const mapState = useSelector(mapStateSelector);

  useEffect(() => {
    return () => {
      dispatch(infoWindowDataAction(null));
    };
  }, [filterMarket, tabActive]);

  useEffect(() => {
    if (
      !mapState ||
      (propertyMap.length === 0 && meetingRoomList.length === 0)
    ) {
      return;
    }

    let similar = true;
    const result: {
      id: number;
      lat: number;
      lng: number;
    }[] = [];

    if (tabActive === BOOK_DESK_TAB) {
      propertyMap.forEach(({ id, lat, lng }, index) => {
        similar = id === currentMarkerData[index]?.id;
        result.push({
          id,
          lat,
          lng,
        });
      });
    } else if (tabActive === BOOK_MEETING_ROOM_TAB) {
      meetingRoomList.forEach(({ meeting_room_id, lat, lng }, index) => {
        similar = meeting_room_id === currentMarkerData[index]?.id;
        result.push({
          id: meeting_room_id,
          lat,
          lng,
        });
      });
    }

    if (similar) {
      return;
    }

    setCurrentMarkerData(result);
  }, [mapState, propertyMap, meetingRoomList]);

  useEffect(() => {
    if (!currentMarkerData.length) {
      return;
    }

    if (
      tabActive === BOOK_DESK_TAB &&
      propertyFilter?.searchByLandmark &&
      propertyFilter?.lat &&
      propertyFilter?.lng
    ) {
      mapState.setCenter({
        lat: propertyFilter?.lat,
        lng: propertyFilter?.lng,
      });
      mapState.setZoom(15);
      return;
    }

    if (
      tabActive === BOOK_MEETING_ROOM_TAB &&
      meetingRoomFilters?.searchByLandmark &&
      meetingRoomFilters?.lat &&
      meetingRoomFilters?.lng
    ) {
      mapState.setCenter({
        lat: meetingRoomFilters?.lat,
        lng: meetingRoomFilters?.lng,
      });
      mapState.setZoom(15);
      return;
    }

    const bounds = new google.maps.LatLngBounds();

    currentMarkerData.forEach((p) => {
      bounds.extend(new google.maps.LatLng(p.lat, p.lng));
    });

    mapState.fitBounds(bounds);
  }, [
    currentMarkerData,
    propertyFilter?.searchByLandmark,
    meetingRoomFilters?.searchByLandmark,
  ]);

  const onLoad = useCallback(function callback(map: any) {
    dispatch(mapStateAction(map));
  }, []);

  const onUnmount = useCallback(function callback() {
    dispatch(mapStateAction(null));
  }, []);

  const _onZoomChanged = () => {
    if (!mapState) {
      return;
    }
    const currentZoom = mapState.getZoom() || GOOGLE_MAP_ZOOM_DEFAULT;
    if (zoomLevel >= currentZoom) {
      // Zoom out
      setMarkerSameLatLng([]);
    }
    setZoomLevel(currentZoom);
  };

  return (
    <GoogleMap
      mapContainerStyle={GOOGLE_MAP_CONTAINER_STYLE}
      zoom={GOOGLE_MAP_ZOOM_DEFAULT}
      onZoomChanged={_onZoomChanged}
      onLoad={onLoad}
      onUnmount={onUnmount}
      clickableIcons={false}
      options={GOOGLE_MAP_OPTIONS}
    >
      <MarkerChildren
        data={markerSameLatLng}
        tabActive={tabActive}
        zoomLevel={zoomLevel}
        setMarkerSameLatLng={setMarkerSameLatLng}
      />
      <MarkerCluster
        tabActive={tabActive}
        markerSameLatLng={markerSameLatLng}
        setMarkerSameLatLng={setMarkerSameLatLng}
      />
      <MarkerMarketCount tabActive={tabActive} />
      <InfoWindowCustom />
    </GoogleMap>
  );
};

export default memo(MapView);
