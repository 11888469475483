export const removeDropdown = () => {
  console.log("scroll");
  const dropdownInside = document.querySelector(
    ".dropdown-custom.active.visible"
  );

  if (!dropdownInside) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  dropdownInside.click();
};
