import LoginTemplate from "../../../App/views/LoginTemplate";
import React from "react";
import { PUBLIC_URL } from "../../../App/configs/Constants";
import "./styles.scss";
import ButtonGoBack from "../../../../components/ButtonGoBack";
import { ACCOUNT_CREATE_OPTION_LINK } from "../../../App/configs/ListRoutes";
import { BrowserView, MobileView } from "react-device-detect";
import SizeBox from "../../../../components/SizeBox";

const AccountCreateIndividual = () => {
  return (
    <LoginTemplate className={"create-individual"}>
      <div className={"qr-section"}>
        <ButtonGoBack
          route={ACCOUNT_CREATE_OPTION_LINK}
          goBack
          className={"go-back"}
          label={"Go back"}
        />
        <p className={"sub-title"}>Instant Access, No Booking Needed</p>
        <h2 className={"title"}>
          Simply Download the
          <br />
          Deskimo App
        </h2>
        <p className={"text"}>
          Use the Deskimo mobile app to tap into <br /> the network of Hot
          Desks, <br /> Meeting Rooms and Private Offices across 6 countries.
          <br />
          Pay only for what you actually use.
        </p>
        <BrowserView>
          <>
            <p className={"scan-title"}>Scan to download now</p>
            <img
              width={230}
              height={230}
              src={PUBLIC_URL + "/assets/images/qr-code-deskimo_me.png"}
            />
          </>
        </BrowserView>
        <MobileView>
          <>
            <p className={"scan-title"}>Click to download now</p>
            <div className={"d-flex align-items-center"}>
              <a href="https://deskimo.me/app">
                <img
                  alt={"Deskimo"}
                  width={135}
                  height={40}
                  src={PUBLIC_URL + "/assets/images/download-apple.svg"}
                />
              </a>
              <SizeBox width={20} />
              <a href="https://deskimo.me/app">
                <img
                  alt={"Deskimo"}
                  width={135}
                  height={40}
                  src={PUBLIC_URL + "/assets/images/download-google.svg"}
                />
              </a>
            </div>
          </>
        </MobileView>
      </div>
      <div className={"thumbnail"}>
        <img src={PUBLIC_URL + "/assets/images/modal-qr-bg.png"} />
      </div>
    </LoginTemplate>
  );
};

export default AccountCreateIndividual;
