import ButtonCustom from "../../../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import DropdownCustom from "../../../../../../../components/DropdownCustom";
import React, { useContext, useRef } from "react";
import { LocalizationContext } from "../../../../../../../locales/Translation";

const WebhookActions = ({ item, onSend }: any) => {
  const { translations } = useContext(LocalizationContext);
  const dropdownRef = useRef(null);

  return (
    <>
      <DropdownCustom
        ref={dropdownRef}
        className={"table-action-dropdown"}
        direction={"left"}
        icon={null}
        trigger={
          <ButtonCustom
            style={{ width: "38px", height: "38px" }}
            className={"primary-alt"}
          >
            <i className="uil uil-setting" style={{ fontSize: "21px" }} />
          </ButtonCustom>
        }
      >
        <Dropdown.Menu>
          <Dropdown.Item
            disabled={item?.webhook_url?.length < 1}
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              dropdownRef?.current?.click();
              setTimeout(() => {
                onSend(item?.id);
              }, 100);
            }}
            text={"Send a test"}
            icon={
              <i
                className="uil uil-message icon"
                style={{ fontSize: "16px" }}
              />
            }
          />
        </Dropdown.Menu>
      </DropdownCustom>
    </>
  );
};

export default WebhookActions;
