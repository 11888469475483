import React, { useContext } from "react";
import Label from "../../../../../components/Label";
import { LocalizationContext } from "../../../../../locales/Translation";
import InputCustom from "../../../../../components/Input";
import moment from "moment-timezone";
import { CampaignCreateEditContext } from "../../../reducers/CampaignCreateEditContext";
import DropdownCustom from "../../../../../components/DropdownCustom";
import { FORMAT_DATE_VARIANT_2 } from "../../../../App/configs/DateTimeConfigs";
import DateRangePickerV2 from "../../../../../components/DateRangePickerV2";
import {
  DISTRIBUTION_TYPE_DESKIMO,
  DISTRIBUTION_TYPE_PROPERTY,
  VOUCHER_REDEEM_FIXED_TYPE,
  VOUCHER_REDEEM_PERCENTAGE_TYPE,
} from "../../../configs/Constants";

const Information = () => {
  const { translations } = useContext(LocalizationContext);
  const {
    errors,
    touched,
    isSubmitting,
    handleChange,
    setFieldValue,
    values,
    timezoneOptions,
  } = useContext(CampaignCreateEditContext);

  const startTime = moment.unix(values.start_time).tz(values.timezone);
  const endTime = moment.unix(values.end_time).tz(values.timezone);

  const _onChangeTimezone = (
    newTimezone:
      | boolean
      | number
      | string
      | (boolean | number | string)[]
      | undefined
  ) => {
    if (typeof newTimezone !== "string") {
      return;
    }
    const prevTimezone = values.timezone;
    setFieldValue("timezone", newTimezone);

    const exStartTime = moment.unix(values.start_time).tz(prevTimezone);
    const exEndTime = moment.unix(values.end_time).tz(prevTimezone);

    setFieldValue(
      "start_time",
      exStartTime.clone().tz(newTimezone, true).utc().unix()
    );

    setFieldValue(
      "end_time",
      exEndTime.clone().tz(newTimezone, true).utc().unix()
    );

    if (
      values.voucher_reward.free_credit_onboard_start_time &&
      values.voucher_reward.free_credit_onboard_end_time
    ) {
      const exStartTime = moment
        .unix(values.voucher_reward.free_credit_onboard_start_time)
        .tz(prevTimezone);
      const exEndTime = moment
        .unix(values.voucher_reward.free_credit_onboard_end_time)
        .tz(prevTimezone);

      setFieldValue(
        "voucher_reward.free_credit_onboard_start_time",
        exStartTime.clone().tz(newTimezone, true).utc().unix()
      );

      setFieldValue(
        "voucher_reward.free_credit_onboard_end_time",
        exEndTime.clone().tz(newTimezone, true).utc().unix()
      );
    }
  };

  return (
    <>
      <Label label={"Campaign"} fontSize={"24px"} className={"section-title"} />
      <div className={"detail_divide"}>
        <div className={"left_section"}>
          <InputCustom
            label={"Campaign name"}
            labelRequired
            labelGuide={
              'Name your campaign so that you can easily identify it later. This is for internal purposes only. E.g. "Foodpanda Indonesia July 2022 promotion"'
            }
            value={values.name}
            error={errors.name}
            touched={touched.name}
            name={"name"}
            onChange={handleChange}
            disabled={isSubmitting}
          />

          <InputCustom
            label={"Description"}
            labelRequired
            value={values.description}
            error={errors.description}
            touched={touched.description}
            name={"description"}
            onChange={handleChange}
            disabled={isSubmitting}
            labelGuide={
              "This field for the description of the campaign, for internal use only"
            }
          />

          <InputCustom
            label={"Voucher code"}
            labelRequired
            value={values.voucher_code}
            error={errors.voucher_code}
            touched={touched.voucher_code}
            name={"voucher_code"}
            onChange={handleChange}
            disabled={isSubmitting}
          />

          <>
            <Label label={"Cost of promotion paid by"} />
            <DropdownCustom
              disabled={
                values.voucher_reward.type !== VOUCHER_REDEEM_FIXED_TYPE &&
                values.voucher_reward.type !== VOUCHER_REDEEM_PERCENTAGE_TYPE
              }
              style={{ height: 44, borderRadius: 4 }}
              selection
              selectUI
              className={"w-100"}
              onChange={(e, { value }) =>
                setFieldValue("distribution_type", value)
              }
              value={values.distribution_type}
              options={[
                {
                  key: 1,
                  text: "Deskimo",
                  value: DISTRIBUTION_TYPE_DESKIMO,
                },
                {
                  key: 2,
                  text: "Property where the voucher is used",
                  value: DISTRIBUTION_TYPE_PROPERTY,
                },
              ]}
            />
          </>
        </div>
        <div className={"right_section"}>
          <InputCustom
            label={"In-app display name"}
            labelRequired
            labelGuide={
              'This is the externally displayed name of the promotion. It will be shown on the end user app upon redemption of the voucher code. Keep it short, e.g. "Foodpanda 20% off"'
            }
            value={values.display_name}
            error={errors.display_name}
            touched={touched.display_name}
            name={"display_name"}
            onChange={handleChange}
            disabled={isSubmitting}
          />

          <Label label={"Timezone"} fontSize={"12px"} />
          <DropdownCustom
            selection
            selectUI
            options={timezoneOptions}
            value={values.timezone}
            className={"input-component"}
            onChange={(e, { value }) => _onChangeTimezone(value)}
            disabled={isSubmitting}
          />

          <Label
            label={"Start Time / End Time"}
            labelGuide={
              "The voucher cannot be redeemed anymore once the campaign ends"
            }
            fontSize={"12px"}
          />
          <div className={"input-component"}>
            <DateRangePickerV2
              className={"w-100"}
              format={"DD/MM/YYYY"}
              defaultValue={[
                moment(
                  new Date(
                    startTime.get("year"),
                    startTime.get("month"),
                    startTime.get("date"),
                    0,
                    0,
                    0,
                    0
                  )
                ),
                moment(
                  new Date(
                    endTime.get("year"),
                    endTime.get("month"),
                    endTime.get("date"),
                    0,
                    0,
                    0,
                    0
                  )
                ),
              ]}
              onChange={(value: any) => {
                if (!value) {
                  return;
                }
                setFieldValue(
                  "start_time",
                  moment(
                    moment(value[0]).format(FORMAT_DATE_VARIANT_2),
                    FORMAT_DATE_VARIANT_2
                  )
                    .clone()
                    .tz(values.timezone, true)
                    .startOf("day")
                    .utc()
                    .unix()
                );

                setFieldValue(
                  "end_time",
                  moment(
                    moment(value[1]).format(FORMAT_DATE_VARIANT_2),
                    FORMAT_DATE_VARIANT_2
                  )
                    .clone()
                    .tz(values.timezone, true)
                    .endOf("day")
                    .utc()
                    .unix()
                );
              }}
            />
          </div>

          <InputCustom
            labelRequired
            label={"Number of vouchers available"}
            value={values.vouchers_number_available}
            error={errors.vouchers_number_available}
            touched={touched.vouchers_number_available}
            name={"vouchers_number_available"}
            onChange={handleChange}
            disabled={isSubmitting}
            labelGuide={
              "Once the number of vouchers available has been redeemed by users, the voucher code cannot be redeemed by any more users"
            }
            removeLetter
          />
        </div>
      </div>
      <div className={"section-white-box_line"} />
    </>
  );
};

export default Information;
