import React, { useEffect, useState } from "react";
import DropdownCustom from "../../../../../components/DropdownCustom";
import { dashboardPropertyChooseAction } from "../../../actions/DashboardActions";
import { CURRENT_PROPERTY_ID } from "../../../../../configs/Constants";
import { usePermissionCheck } from "../../../../../utils/custom-hooks";
import { UI_PROPERTY_MANAGE_PER } from "../../../../../utils/PermissionConstant";
import { Link } from "react-router-dom";
import { PROPERTY_DETAIL_LINK } from "../../../../App/configs/ListRoutes";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import { dashboardPropertyListThunk } from "../../../thunks/DashboardThunks";
import { useDispatch, useSelector } from "react-redux";
import { userCompanyChooseSelector } from "../../../../User/selectors/UserSelectors";
import { DASHBOARD_PROPERTY_TYPE } from "../../../configs/DeclareTypes";
import {
  dashboardPropertyChooseSelector,
  dashboardPropertyListSelector,
} from "../../../selectors/DashboardSelectors";

const PropertyShow = () => {
  const dispatch = useDispatch();
  const userCompanyChoose = useSelector(userCompanyChooseSelector);
  const dashboardPropertyList: DASHBOARD_PROPERTY_TYPE[] = useSelector(
    dashboardPropertyListSelector
  );
  const dashboardPropertyChoose: DASHBOARD_PROPERTY_TYPE = useSelector(
    dashboardPropertyChooseSelector
  );
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState<any>(0);

  const propertyManagePer = usePermissionCheck(UI_PROPERTY_MANAGE_PER);

  useEffect(() => {
    (async () => {
      try {
        const data = await dispatch<any>(dashboardPropertyListThunk());
        if (!data?.length) {
          setOptions([]);
        }
        setOptions(() =>
          data.map(
            ({
              property_name,
              property_id,
            }: {
              property_name: string;
              property_id: number;
            }) => {
              return {
                key: property_id,
                value: property_id,
                text: property_name,
              };
            }
          )
        );

        let property;
        const currentProperty = localStorage.getItem(CURRENT_PROPERTY_ID);
        if (currentProperty) {
          property = data.find(
            ({ property_id }: { property_id: number }) =>
              property_id === Number(currentProperty)
          );

          if (!property) {
            property = data[0];
            localStorage.setItem(
              CURRENT_PROPERTY_ID,
              String(property?.property_id)
            );
          }
        } else {
          property = data[0];
          localStorage.setItem(
            CURRENT_PROPERTY_ID,
            String(property?.property_id)
          );
        }

        setValue(property?.property_id);
        dispatch(dashboardPropertyChooseAction(property));
      } catch (e) {
        console.log(e);
      }
    })();
  }, [userCompanyChoose]);

  if (!dashboardPropertyChoose) {
    return <div className={"dashboard_property_single"} />;
  }

  if (dashboardPropertyList?.length > 1 && options.length) {
    return (
      <div className={"dashboard_property_dropdown_wrapper"}>
        <DropdownCustom
          scrolling
          options={options}
          value={value}
          className={"dashboard_property_dropdown"}
          onChange={(e, { value }) => {
            setValue(value);
            const data = dashboardPropertyList.find(
              ({ property_id }: { property_id: number }) =>
                property_id === value
            );
            if (!data) {
              dispatch(dashboardPropertyChooseAction({}));
              localStorage.removeItem(CURRENT_PROPERTY_ID);
            } else {
              dispatch(dashboardPropertyChooseAction(data));
              localStorage.setItem(CURRENT_PROPERTY_ID, String(value));
            }
          }}
        />
        <Link
          to={PROPERTY_DETAIL_LINK.replaceAll(
            ":id",
            String(dashboardPropertyChoose.property_id)
          )}
        >
          <img src={PUBLIC_URL + "/assets/icons/icon-open-tab.svg"} />
        </Link>
      </div>
    );
  }

  if (dashboardPropertyList?.length === 1 && propertyManagePer) {
    return (
      <Link
        className={"dashboard_property_single"}
        to={PROPERTY_DETAIL_LINK.replaceAll(
          ":id",
          String(dashboardPropertyChoose.property_id)
        )}
      >
        {dashboardPropertyChoose.property_name}
        <img src={PUBLIC_URL + "/assets/icons/icon-open-tab.svg"} />
      </Link>
    );
  }

  if (dashboardPropertyList?.length === 1) {
    return (
      // <div className={"dashboard_property_single"}>
      //   {dashboardPropertyChoose.property_name}
      // </div>
      <Link
        className={"dashboard_property_single"}
        to={PROPERTY_DETAIL_LINK.replaceAll(
          ":id",
          String(dashboardPropertyChoose.property_id)
        )}
      >
        {dashboardPropertyChoose.property_name}
        <img src={PUBLIC_URL + "/assets/icons/icon-open-tab.svg"} />
      </Link>
    );
  }

  return <div className={"dashboard_property_single"} />;
};

export default PropertyShow;
