import ButtonCustom from "../../../../components/Button";
import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import TableCustom from "../../../../components/Table";
import MarketFilterMultiple from "../../../../components/FilterMarket/MarketFilterMultiple";
import { LocalizationContext } from "../../../../locales/Translation";
import { useSelector } from "react-redux";
import { globalSettingSelector } from "../../../App/selectors/AppSelectors";
import moment from "moment-timezone";
import { FORMAT_DATE_TIME_VARIANT_3 } from "../../../App/configs/DateTimeConfigs";
import Action from "./Action";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../App/configs/Constants";
import SearchBar4Table from "../../../../components/SearchBar4Table";
import { debounce } from "lodash";
import ModalCreateUpdatePrepaidCreditPackage from "../ModalCreateUpdatePrepaidCreditPackage";
import { getConfigPrepaidCreditPackageListService } from "../../services/ConfigServices";
import { useGetAllQuery, useSetQuery } from "../../../../utils/custom-hooks";

const FILTER_DEFAULT: {
  page: number;
  keyword: string;
  market_codes: string[];
  sort_by: string;
  sort_type: string;
} = {
  page: 1,
  keyword: "",
  market_codes: [],
  sort_by: "created_date",
  sort_type: ORDER_BY_DESC,
};

const PrepaidCreditPackages = () => {
  const [isOpenModal, setIsOpenModal] = useState<any>({
    open: false,
    data: null,
  });
  const [onRefreshing, setOnSetRefreshing] = useState(false);
  const { translations } = useContext(LocalizationContext);
  const globalSetting = useSelector(globalSettingSelector);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(FILTER_DEFAULT);
  const [tableData, setTableData] = useState<any>(null);
  const [addParamsAlready, setAddParamsAlready] = useState<boolean>(false);
  const getAllQuery = useGetAllQuery();
  const setQuery = useSetQuery();

  useEffect(() => {
    try {
      if (!Object.keys(getAllQuery).length) {
        if (globalSetting) {
          setFilters({
            ...filters,
            market_codes: globalSetting?.location.map(
              (item: any) => item.city_code
            ),
          });
        }
      } else {
        setFilters(getAllQuery);
      }
    } finally {
      setAddParamsAlready(true);
    }
    if (globalSetting) {
      setFilters({
        ...filters,
        market_codes: globalSetting?.location.map(
          (item: any) => item.city_code
        ),
      });
    }
  }, [globalSetting]);

  useEffect(() => {
    if (filters.market_codes.length > 0) {
      if (!addParamsAlready) {
        return;
      }
      setQuery(filters);
      _fetchConfigPrepaidCreditPackageList();
    }
  }, [filters, onRefreshing]);

  const _fetchConfigPrepaidCreditPackageList = async () => {
    try {
      setLoading(true);
      const response = await getConfigPrepaidCreditPackageListService(filters);
      if (response?.data) {
        setTableData(response?.data);
      }
    } catch (e: any) {
      console.log("Error: ", e);
    } finally {
      setLoading(false);
    }
  };

  const _onRefreshing = () => {
    setOnSetRefreshing((prevState) => !prevState);
  };

  const columns = [
    { key: "name", col: "Prepaid Credit Package Name" },
    { key: "market_name", col: "Market" },
    { key: "amount", col: "Amount" },
    { key: "discount", col: "Percentage Discount" },
    { key: "duration", col: "Credits Validity in days" },
    { key: "promotion_text", col: "Promo Text" },
    { key: "created_date", col: "Created Date" },
    { key: "status", col: translations.status },
    { key: null, col: translations.action },
  ];

  const _formatInputData = () => {
    return tableData?.items?.map(
      ({
        id,
        name,
        market_name,
        amount_format,
        discount,
        duration,
        promotion_text,
        created_date = 1,
        status_name,
        status,
      }: any) => {
        return {
          col1: name,
          col2: market_name,
          col3: amount_format,
          col4: discount + "%",
          col5: duration,
          col6: promotion_text,
          col7: moment(created_date * 1000).format(FORMAT_DATE_TIME_VARIANT_3),
          col8: status_name,
          col9: (
            <Action
              id={id}
              status={status}
              setIsOpenModal={setIsOpenModal}
              onSetRefreshing={_onRefreshing}
            />
          ),
        };
      }
    );
  };

  const _onChangeSortCol = (col: any) => {
    if (filters.sort_by !== col.key) {
      setFilters((prevState: any) => {
        return { ...prevState, sort_by: col.key, sort_type: ORDER_BY_ASC };
      });
    } else {
      if (filters.sort_type === ORDER_BY_NONE) {
        setFilters((prevState: any) => {
          return { ...prevState, sort_by: col.key, sort_type: ORDER_BY_ASC };
        });
      }
      if (filters.sort_type === ORDER_BY_DESC) {
        setFilters((prevState: any) => {
          return { ...prevState, sort_by: "", sort_type: ORDER_BY_NONE };
        });
      }
      if (filters.sort_type === ORDER_BY_ASC) {
        setFilters((prevState: any) => {
          return { ...prevState, sort_by: col.key, sort_type: ORDER_BY_DESC };
        });
      }
    }
  };

  const _onChangeMarketFilter = (values: string[]) => {
    setFilters((prevState: any) => {
      return { ...prevState, market_codes: values, page: 1 };
    });
  };

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    setFilters((prevState: any) => {
      return { ...prevState, page: activePage };
    });
  };

  const debounce_onChange = debounce(function (data: any, { value }: any) {
    setFilters({ ...filters, keyword: value });
  }, 500);

  return (
    <>
      <div
        className={"prepaid-credit__filter-top d-flex justify-content-between"}
      >
        <SearchBar4Table
          placeholder={"Search by Prepaid Credit Package Name"}
          onChange={debounce_onChange}
        />
        <ButtonCustom
          height={"36px"}
          className={"primary"}
          onClick={() => setIsOpenModal({ open: true, data: null })}
        >
          Add Prepaid Credit Package +
        </ButtonCustom>
      </div>
      <div style={tableData?.items?.length < 3 ? { paddingBottom: 100 } : {}}>
        <TableCustom
          onChangeSortCol={_onChangeSortCol}
          sortTable
          loading={loading}
          showTotal
          tableData={tableData?.items ? _formatInputData() : []}
          columns={columns}
          orderBy={filters.sort_type}
          sortBy={filters.sort_by}
          itemPerPage={tableData?.pagination?.limit}
          currentPage={filters.page}
          totalItems={tableData?.pagination?.total}
          unit={"transactions"}
          onChangePagination={_onChangePagination}
          topRightComponent={
            <>
              <MarketFilterMultiple
                onChange={_onChangeMarketFilter}
                values={filters.market_codes}
                isSubmitting={loading}
              />
            </>
          }
        />
      </div>

      <ModalCreateUpdatePrepaidCreditPackage
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal({ open: false, data: null })}
        onSetRefreshing={_onRefreshing}
      />
    </>
  );
};

export default PrepaidCreditPackages;
