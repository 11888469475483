import React, { useEffect, useState } from "react";
import Label from "../../../../../../components/Label";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import {
  manualBookingGetPropertiesService,
  manualPartnerBookingGetPropertiesService,
} from "../../../../services/DashboardServices";
import DropdownSearchServer from "../../../../../../components/DropdownSearchServer";
import { FormikValues } from "formik";
import { debounce } from "lodash";
import DropdownSearchServerSingle from "../../../../../../components/DropdownSearchServerSingle";
import { useSelector } from "react-redux";
import { userCompanyChooseSelector } from "../../../../../User/selectors/UserSelectors";

type Props = {
  values: FormikValues;
  setFieldValue: any;
  disabled?: boolean;
};

const DropdownProperty = ({ values, setFieldValue, disabled }: Props) => {
  const userCompanyChoose = useSelector(userCompanyChooseSelector);
  const [options, setOptions] = useState<
    {
      key: number;
      text: string;
      id: number;
      timezone: string;
      currency: string;
    }[]
  >([]);

  useEffect(() => {
    (async () => {
      await _initOptions();
    })();
  }, []);

  const _initOptions = async (keyword = "") => {
    try {
      const { data } = await manualPartnerBookingGetPropertiesService({
        market_code: userCompanyChoose?.market_code,
        lat: null,
        lng: null,
        date: null,
        keyword,
        page: 1,
      });

      const temp = data?.properties?.map(
        ({
          reference,
          name,
          timezone,
          currency_code,
        }: {
          reference: string;
          name: string;
          timezone: string;
          currency_code: string;
        }) => {
          return {
            key: reference,
            text: name,
            id: reference,
            timezone,
            currency_code,
          };
        }
      );
      setOptions(temp);
    } catch (e) {
      console.log(e);
    }
  };

  const debounce_onChange = debounce(async (keyword: string) => {
    await _initOptions(keyword);
  }, 300);

  return (
    <>
      <DropdownSearchServerSingle
        isFullWidth={true}
        value={{ text: values.property_name, id: values.property_reference }}
        onSearch={(searchQuery) => debounce_onChange(searchQuery)}
        options={options}
        disabled={disabled}
        placeholder={"Select property"}
        onChange={(value) => {
          setFieldValue("property_reference", value.id);
          setFieldValue("property_name", value.text);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setFieldValue("timezone", value.timezone);
        }}
      />
    </>
  );
};

export default DropdownProperty;
