export const roomBookingListSelector = (state: any) =>
  state.BookingReducer.listRoomBooking;

export const roomBookingPayloadSelector = (state: any) =>
  state.BookingReducer.listRoomBookingPayload;

export const refreshRoomBookingSelector = (state: any) =>
  state.BookingReducer.refreshRoomBookingList;

export const modalRoomBookingDetailSelector = (state: any) =>
  state.BookingReducer.modalRoomBookingDetail;

export const deskBookingListSelector = (state: any) =>
  state.BookingReducer.listDeskBooking;

export const deskBookingPayloadSelector = (state: any) =>
  state.BookingReducer.listDeskBookingPayload;

export const refreshDeskBookingSelector = (state: any) =>
  state.BookingReducer.refreshDeskBookingList;

export const onGoingDataSelector = (state: any) =>
  state.BookingReducer.ongoingData;

export const listBookingCompletedSelector = (state: any) =>
  state.BookingReducer.listBookingCompleted;

export const listBookingCompletedPayloadSelector = (state: any) =>
  state.BookingReducer.listBookingCompletedPayload;
