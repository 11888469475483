import SizeBox from "../../../../../../components/SizeBox";
import MeetingRoomTable from "./MeetingRoomTable";
import { useParams } from "react-router-dom";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMeetingRoomDetailThunk,
  getMeetingRoomThunk,
  getPropertyDetailThunk,
} from "../../../../thunks/PropertyThunks";
import ModalUpdateMeetingRoom from "./Components/ModalUpdateMeetingRoom";
import ModalCreateMeetingRoom from "./Components/ModalCreateMeetingRoom";
import ModalManagePMS from "./Components/ModalManagePMS";
import ModalManagePMSAddRoom from "./Components/ModalManagePMSAddRoom";
import ModalManagePMSAddCalendar from "./Components/ModalManagePMSAddCalendar";
import ModalAssignMeetingRoom from "./Components/ModalAssignMeetingRoom";
import { propertyDetailSelector } from "../../../../selectors/PropertySelector";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../../../App/configs/Constants";
import {
  modalManagePMSAction,
  modalMeetingRoomCreateAction,
  modalMeetingRoomUpdateAction,
} from "../../../../actions/PropertyActions";
import ButtonCustom from "../../../../../../components/Button";

const MeetingRoomTab = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [meetingRoomFilter, setMeetingRoomFilter] = useState({
    page: 1,
    keyword: "",
    order_by: "",
    sort_by: "",
    statuses: [0, 1],
    property_id: id,
    code: "",
  });
  const [refreshList, setRefreshList] = useState(false);
  const [roomSelected, setRoomSelected] = useState({});
  const propertyDetailData = useSelector(propertyDetailSelector);

  useEffect(() => {
    (async () => _getMeetingRoomList())();
  }, [meetingRoomFilter, refreshList]);

  useEffect(() => {
    _getMeetingId();
    (async () => _getPropertyDetail())();
  }, []);

  const _getPropertyDetail = async () => {
    try {
      await dispatch<any>(getPropertyDetailThunk(id ? id : ""));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const _getMeetingId = () => {
    const queryString = window.location.search;

    if (!queryString) {
      return;
    }
    const urlParams = new URLSearchParams(queryString);
    const meetingId = urlParams.get("meeting_id");

    if (!meetingId) {
      return;
    }
    dispatch<any>(getMeetingRoomDetailThunk(meetingId));
    dispatch(modalMeetingRoomUpdateAction(true));
  };

  const _getMeetingRoomList = async () => {
    setLoading(true);
    try {
      await dispatch<any>(getMeetingRoomThunk(meetingRoomFilter));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const _onChangeFilter = (e: SyntheticEvent, data: any) => {
    if (data.checked) {
      setMeetingRoomFilter((prevState: any) => {
        return {
          ...prevState,
          statuses: [...meetingRoomFilter.statuses, data.value],
        };
      });
    } else {
      setMeetingRoomFilter((prevState: any) => {
        return {
          ...prevState,
          statuses: meetingRoomFilter.statuses.filter(
            (status: any) => status !== data.value
          ),
        };
      });
    }
  };

  const _onChangePagination = (e: any, { activePage }: any) => {
    setMeetingRoomFilter((prevState) => {
      return { ...prevState, page: activePage };
    });
  };

  const _onChangeSearch = (data: any, { value }: any) => {
    setMeetingRoomFilter((prevState) => {
      return { ...prevState, page: 1, keyword: value };
    });
  };

  const _onChangeSortCol = (col: any) => {
    if (meetingRoomFilter.sort_by !== col.key) {
      setMeetingRoomFilter((prevState) => {
        return { ...prevState, sort_by: col.key, order_by: ORDER_BY_ASC };
      });
    } else {
      if (meetingRoomFilter.order_by === ORDER_BY_NONE) {
        setMeetingRoomFilter((prevState) => {
          return { ...prevState, sort_by: col.key, order_by: ORDER_BY_ASC };
        });
      }
      if (meetingRoomFilter.order_by === ORDER_BY_DESC) {
        setMeetingRoomFilter((prevState) => {
          return { ...prevState, sort_by: col.key, order_by: ORDER_BY_NONE };
        });
      }
      if (meetingRoomFilter.order_by === ORDER_BY_ASC) {
        setMeetingRoomFilter((prevState) => {
          return { ...prevState, sort_by: col.key, order_by: ORDER_BY_DESC };
        });
      }
    }
  };

  return (
    <>
      <div className={"meeting-room-header"}>
        <div className="property-name-header">{propertyDetailData.name}</div>
        <div className={"button-groups"}>
          <ButtonCustom
            onClick={() => {
              dispatch(modalManagePMSAction(true));
            }}
            className={"outline-primary manage-pms-cta"}
            height={"36px"}
          >
            <>Manage PMS Rooms</>
          </ButtonCustom>
          <ButtonCustom
            onClick={() => {
              dispatch(modalMeetingRoomCreateAction(true));
            }}
            className={"primary add-meeting-room-cta"}
            height={"36px"}
          >
            <>
              Add New Meeting Room
              <i className="uil uil-plus" />
            </>
          </ButtonCustom>
        </div>
      </div>
      <SizeBox height={"32px"} />
      <MeetingRoomTable
        loading={loading}
        onChangeSearch={_onChangeSearch}
        onChangePagination={_onChangePagination}
        onChangeSortCol={_onChangeSortCol}
        onChangeFilter={_onChangeFilter}
        meetingRoomFilter={meetingRoomFilter}
        setMeetingRoomFilter={setMeetingRoomFilter}
        refreshList={refreshList}
        setRefreshList={setRefreshList}
        roomSelected={roomSelected}
        setRoomSelected={setRoomSelected}
      />
      <ModalManagePMS
        refreshList={refreshList}
        setRefreshList={setRefreshList}
      />
      <ModalManagePMSAddCalendar />
      <ModalManagePMSAddRoom />
      <ModalAssignMeetingRoom
        roomSelected={roomSelected}
        setRoomSelected={setRoomSelected}
        refreshList={refreshList}
        setRefreshList={setRefreshList}
      />
      <ModalUpdateMeetingRoom meetingRoomFilter={meetingRoomFilter} />
      <ModalCreateMeetingRoom
        refreshList={refreshList}
        setRefreshList={setRefreshList}
      />
    </>
  );
};

export default MeetingRoomTab;
