import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useSelector } from "react-redux";
import Action from ".//Action";
import TableCustom from "../../../../../../components/Table";
import { businessCompanyStaffSelector } from "../../../../selectors/BusinessSelectors";
import { EmployeeType } from "../../../../../User/configs/DeclareTypes";
import { Label as LabelSemantic } from "semantic-ui-react";
import MarketFilterMultiple from "../../../../../../components/FilterMarket/MarketFilterMultiple";

type Props = {
  loading: boolean;
  onChangeSortCol: any;
  onChangePagination: any;
  onChangeMarketFilter: any;
  filter: {
    keyword: string;
    sort_by: string;
    order_by: string;
    page: number;
    market_code: string[];
  };
};

const CompanyStaffTable = ({
  loading,
  onChangeSortCol,
  onChangePagination,
  onChangeMarketFilter,
  filter,
}: any) => {
  const { translations } = useContext(LocalizationContext);
  const businessCompanyStaff = useSelector(businessCompanyStaffSelector);

  const columns = [
    { key: "name", col: "User Name" },
    { key: "company", col: "Company" },
    {
      key: "email",
      col: "Email",
    },
    { key: "phone", col: "Contact Phone Number" },
    { key: "status", col: translations.status },
    { key: null, col: translations.action },
  ];

  const _formatInputData = () => {
    return businessCompanyStaff?.data?.map(
      ({
        company_name,
        company_id,
        full_name,
        email,
        status,
        status_name,
        id,
        contact_phone,
        is_admin,
        login_on_behalf,
        login_as_wms_user,
        user_id,
      }: EmployeeType) => {
        return {
          col1: (
            <div>
              {full_name}
              {is_admin && (
                <LabelSemantic size={"mini"} className={"ml-4px"}>
                  Admin
                </LabelSemantic>
              )}
            </div>
          ),
          col2: company_name,
          col3: email,
          col4: contact_phone,
          col5: status_name,
          col6: (
            <Action
              statusCode={status}
              companyId={company_id}
              companyName={company_name}
              invitationId={id}
              isAdmin={is_admin}
              loginOnBehalf={login_on_behalf}
              loginAsWMS={login_as_wms_user}
              userId={user_id}
            />
          ),
        };
      }
    );
  };

  return (
    <div
      className={"employee-table"}
      style={
        businessCompanyStaff?.data?.length < 3 ? { paddingBottom: 100 } : {}
      }
    >
      <TableCustom
        onChangeSortCol={onChangeSortCol}
        sortTable
        loading={loading}
        showTotal
        tableData={businessCompanyStaff ? _formatInputData() : []}
        columns={columns}
        orderBy={filter.order_by}
        sortBy={filter.sort_by}
        itemPerPage={businessCompanyStaff?.pagination?.limit}
        currentPage={filter.page}
        totalItems={businessCompanyStaff?.pagination?.total}
        unit={"users"}
        onChangePagination={onChangePagination}
        topRightComponent={
          <MarketFilterMultiple
            isSubmitting={loading}
            onChange={onChangeMarketFilter}
            values={filter.market_code}
          />
        }
      />
    </div>
  );
};

export default CompanyStaffTable;
