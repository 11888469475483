import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import SizeBox from "../../../../../../components/SizeBox";
import ModalCenterTemplate from "../../../../../App/views/ModalCenterTemplate";
import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import Label from "../../../../../../components/Label";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import {
  LIST_MONTH,
  LIST_YEAR,
} from "../../../../../App/configs/DateTimeConfigs";
import moment from "moment";
import CheckboxCustom from "../../../../../../components/Checkbox";
import { getBusinessCompaniesListService } from "../../../../services/BusinessServices";

const ModalGenerateBusinessCompanyInvoice = ({
  modalGenerate,
  setModalGenerate,
  generateInvoice,
  generateLoading,
}: any) => {
  const { translations } = useContext(LocalizationContext);
  const [calendar, setCalendar] = useState<any>({
    month: 1,
    year: 2023,
  });
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [isBlankInvoice, setIsBlankInvoice] = useState<any>(false);
  const [isMonthlyInvoice, setIsMonthlyInvoice] = useState<any>(false);
  const [isSAASFeeInvoice, setIsSAASFeeInvoice] = useState<any>(false);

  const [listCompanyRaw, setListCompanyRaw] = useState<any>([]);

  useEffect(() => {
    const currentTime = moment();
    setCalendar({
      month: currentTime.month() + 1,
      year: currentTime.year(),
    });
  }, []);

  useEffect(() => {
    if (!modalGenerate) {
      setCalendar({
        month: 1,
        year: 2023,
      });
      setSelectedCompany(0);
      setIsBlankInvoice(false);
      setIsMonthlyInvoice(false);
      setIsSAASFeeInvoice(false);
      return;
    }

    _fetchListAllBusinessCompany();
  }, [modalGenerate]);

  const _fetchListAllBusinessCompany = async () => {
    try {
      const response = await getBusinessCompaniesListService();
      const companies = response?.data;
      setListCompanyRaw(companies);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const decorateListCompany = () => {
    if (listCompanyRaw.length > 0) {
      const newListCompany = listCompanyRaw.map((company: any) => {
        return {
          key: company?.company_id,
          text: company?.company_name,
          value: company?.company_id,
        };
      });
      newListCompany.unshift({
        key: 0,
        text: translations.companyInvoices.allCompaniesPlaceholder,
        value: 0,
      });
      return newListCompany;
    } else {
      return [];
    }
  };

  return (
    <ModalCenterTemplate
      trigger={null}
      onClose={() => setModalGenerate(false)}
      open={modalGenerate}
      size={"md"}
      modalClassName={"modal-center-v2"}
    >
      <Modal.Header>
        {translations.companyInvoices.generateInvoiceLabel}
        <ButtonCustom
          height={"auto"}
          onClick={() => setModalGenerate(false)}
          className={"m-0 p-0 none-bg close-modal"}
        >
          <>
            {translations.settings.close}
            <img
              src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"}
              alt={""}
            />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Modal.Content>
        <div className={"d-flex w-100"}>
          <div className={"flex-1"}>
            <Label
              className={"sm mb-4px"}
              fontSize={"12px"}
              label={translations.companyInvoices.monthLabel}
            />
            <DropdownCustom
              scrolling
              style={{
                border: "1px solid #DEE2E6",
                borderRadius: "4px",
              }}
              className={
                "dropdown-select-country w-100 justify-content-between"
              }
              selectUI={true}
              options={LIST_MONTH}
              value={String(calendar.month)}
              onChange={(event, data) =>
                setCalendar({ ...calendar, month: data.value })
              }
            />
          </div>
          <SizeBox width={"16px"} />
          <div className={"flex-1"}>
            <Label
              className={"sm mb-4px"}
              fontSize={"12px"}
              label={translations.companyInvoices.yearLabel}
            />
            <DropdownCustom
              scrolling
              style={{
                border: "1px solid #DEE2E6",
                borderRadius: "4px",
              }}
              className={
                "dropdown-select-country w-100 justify-content-between"
              }
              selectUI={true}
              options={LIST_YEAR(2022, 2025)}
              value={String(calendar.year)}
              onChange={(event, data) =>
                setCalendar({ ...calendar, year: data.value })
              }
            />
          </div>
        </div>
        <SizeBox height={"16px"} />
        <div
          style={{
            fontWeight: 400,
            fontSize: 12,
            lineHeight: "14px",
            color: "#21252A",
          }}
        >
          {translations.companyInvoices.generateInvoiceNotice}
        </div>
        <SizeBox height={"16px"} />
        <Label
          className={"sm mb-4px"}
          fontSize={"12px"}
          label={translations.companyInvoices.generateInvoiceForLabel}
        />
        <DropdownCustom
          style={{
            border: "1px solid #DEE2E6",
            borderRadius: "4px",
          }}
          className={
            "dropdown-select-country w-100 justify-content-between dropdown-fullwidth-common-style"
          }
          search
          onChange={(event: any, data: any) => {
            setSelectedCompany(data?.value);
          }}
          selectUI={true}
          options={decorateListCompany()}
          value={selectedCompany}
        />
        <SizeBox height={"16px"} />
        {selectedCompany !== 0 ? (
          <div className={"grid-column-2"}>
            <CheckboxCustom
              className={"custom-checkbox checkbox-label-md"}
              label={"Generate blank invoice"}
              checked={isBlankInvoice}
              onChange={(event, data) => {
                setIsBlankInvoice(data.checked);
              }}
            />
            <CheckboxCustom
              className={"custom-checkbox checkbox-label-md"}
              label={"Generate monthly invoice"}
              checked={isMonthlyInvoice}
              onChange={(event, data) => {
                setIsMonthlyInvoice(data.checked);
              }}
            />
            <CheckboxCustom
              className={"custom-checkbox checkbox-label-md"}
              label={"Generate SaaS fee invoice"}
              checked={isSAASFeeInvoice}
              onChange={(event, data) => {
                setIsSAASFeeInvoice(data.checked);
              }}
            />
          </div>
        ) : null}
      </Modal.Content>
      <Modal.Actions className={"d-flex justify-content-between"}>
        <ButtonCustom
          className={"flex-1 m-0"}
          height={"47px"}
          onClick={() => setModalGenerate(false)}
        >
          {translations.settings.cancel}
        </ButtonCustom>
        <SizeBox width={"16px"} />
        <ButtonCustom
          disabled={!isBlankInvoice && !isSAASFeeInvoice && !isMonthlyInvoice}
          loading={generateLoading}
          type={"submit"}
          className={"primary flex-1 m-0"}
          height={"47px"}
          onClick={() =>
            generateInvoice(
              calendar,
              selectedCompany,
              isBlankInvoice,
              isMonthlyInvoice,
              isSAASFeeInvoice
            )
          }
        >
          {translations.companyInvoices.generateAction}
        </ButtonCustom>
      </Modal.Actions>
    </ModalCenterTemplate>
  );
};

export default ModalGenerateBusinessCompanyInvoice;
