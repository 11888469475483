import ModalCenterTemplate from "../../../../App/views/ModalCenterTemplate";
import React, { useEffect, useState } from "react";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../components/Button";
import SizeBox from "../../../../../components/SizeBox";
import { useFormik } from "formik";
import * as yup from "yup";
import Input from "../../../../../components/Input";
import { toastError } from "../../../../../components/Toast";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import { requestParkingService } from "../../../services/BookServices";
import {
  DESK_BOOKING,
  ROOM_BOOKING,
} from "../../../../Dashboard/configs/Constants";

type Props = {
  open: boolean;
  onClose: () => void;
  onRefresh: () => Promise<void>;
  bookingId: number;
  bookingType: typeof DESK_BOOKING | typeof ROOM_BOOKING;
};

const ParkingRequestModal = ({
  open,
  onClose,
  onRefresh,
  bookingId,
  bookingType,
}: Props) => {
  const [showSuccess, setShowSuccess] = useState(true);

  useEffect(() => {
    if (!open) {
      setShowSuccess(false);
    }
  }, [open]);

  const { values, handleChange, touched, handleSubmit, errors, isSubmitting } =
    useFormik({
      initialValues: { car_number: "" },
      onSubmit: async (values) => {
        try {
          const payload = {
            ...values,
            type: bookingType,
          };
          await requestParkingService(bookingId, payload);
          setShowSuccess(true);
        } catch (e: any) {
          toastError(e?.status?.message);
        }
      },
      validationSchema: yup.object().shape({
        car_number: yup.string().required().label("Vehicle license number"),
      }),
    });

  return (
    <ModalCenterTemplate
      trigger={null}
      onClose={onClose}
      modalClassName={"modal-center-v2"}
      open={open}
      style={{ maxWidth: showSuccess ? 344 : 600 }}
    >
      {showSuccess ? (
        <Modal.Content className={"text-center"}>
          <svg
            width="50"
            height="49"
            viewBox="0 0 50 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.818115"
              width="48.3636"
              height="48.3636"
              rx="6.54545"
              fill="#16C79A"
              fillOpacity="0.1"
            />
            <path
              d="M28.6268 19.901L22.9068 25.6343L20.7068 23.4343C20.5872 23.2947 20.4401 23.1814 20.2747 23.1014C20.1093 23.0213 19.9291 22.9764 19.7455 22.9693C19.5619 22.9622 19.3788 22.9931 19.2077 23.0601C19.0366 23.1272 18.8812 23.2288 18.7512 23.3588C18.6213 23.4887 18.5196 23.6441 18.4526 23.8152C18.3855 23.9863 18.3546 24.1694 18.3617 24.3531C18.3688 24.5367 18.4138 24.7169 18.4938 24.8823C18.5738 25.0477 18.6872 25.1948 18.8268 25.3143L21.9601 28.461C22.0847 28.5846 22.2324 28.6823 22.3949 28.7487C22.5573 28.815 22.7313 28.8487 22.9068 28.8477C23.2565 28.8462 23.5917 28.7073 23.8401 28.461L30.5068 21.7943C30.6317 21.6704 30.7309 21.5229 30.7986 21.3604C30.8663 21.1979 30.9012 21.0237 30.9012 20.8477C30.9012 20.6716 30.8663 20.4974 30.7986 20.3349C30.7309 20.1724 30.6317 20.0249 30.5068 19.901C30.2569 19.6527 29.919 19.5133 29.5668 19.5133C29.2145 19.5133 28.8766 19.6527 28.6268 19.901ZM25.0001 10.8477C22.363 10.8477 19.7851 11.6296 17.5925 13.0947C15.3998 14.5598 13.6909 16.6422 12.6817 19.0785C11.6725 21.5149 11.4085 24.1958 11.923 26.7822C12.4374 29.3686 13.7073 31.7444 15.572 33.6091C17.4367 35.4738 19.8125 36.7437 22.3989 37.2581C24.9853 37.7726 27.6662 37.5086 30.1025 36.4994C32.5389 35.4902 34.6213 33.7812 36.0863 31.5886C37.5514 29.3959 38.3334 26.8181 38.3334 24.181C38.3334 22.43 37.9885 20.6962 37.3185 19.0785C36.6484 17.4609 35.6663 15.991 34.4282 14.7529C33.1901 13.5148 31.7202 12.5327 30.1025 11.8626C28.4849 11.1925 26.751 10.8477 25.0001 10.8477ZM25.0001 34.8477C22.8904 34.8477 20.8281 34.2221 19.074 33.05C17.3199 31.8779 15.9527 30.212 15.1454 28.2629C14.338 26.3139 14.1268 24.1692 14.5384 22.1C14.95 20.0309 15.9659 18.1303 17.4576 16.6385C18.9494 15.1468 20.85 14.1309 22.9191 13.7193C24.9883 13.3077 27.133 13.5189 29.082 14.3263C31.0311 15.1336 32.697 16.5008 33.8691 18.2549C35.0412 20.009 35.6668 22.0713 35.6668 24.181C35.6668 27.01 34.5429 29.7231 32.5426 31.7235C30.5422 33.7238 27.8291 34.8477 25.0001 34.8477Z"
              fill="#16C79A"
            />
          </svg>

          <SizeBox height={24} />
          <div style={{ fontSize: 18, fontWeight: 700, color: "#495057" }}>
            Thank you for the information
          </div>
          <SizeBox height={8} />
          <div style={{ fontSize: 14, color: "#13100C" }}>
            We have sent your vehicle’s license number to your reserved
            workspace.
            <br />
            Confirmation from your workspace
            <br />
            will be provided soon.
          </div>
          <SizeBox height={24} />
          <ButtonCustom
            className={"primary m=0"}
            height={"34px"}
            width={"100%"}
            onClick={() => {
              onRefresh();
              onClose();
            }}
          >
            Close
          </ButtonCustom>
        </Modal.Content>
      ) : (
        <>
          <Modal.Header>
            Request Parking
            <ButtonCustom onClick={onClose} className={"modal-close-cta"}>
              <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
            </ButtonCustom>
          </Modal.Header>
          <Modal.Content>
            <p>
              We will need your license number so that we can send it to the
              workspace for their acknowledgement.
            </p>
            <SizeBox height={16} />
            <Input
              value={values.car_number}
              onChange={handleChange}
              name={"car_number"}
              touched={touched.car_number}
              error={errors.car_number}
              label={"Your vehicle license number"}
            />
          </Modal.Content>
          <Modal.Actions>
            <ButtonCustom className={"flex-1 m-0"} onClick={onClose}>
              Cancel
            </ButtonCustom>
            <SizeBox width={"16px"} />
            <ButtonCustom
              className={"primary flex-1 m-0"}
              onClick={handleSubmit}
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Confirm
            </ButtonCustom>
          </Modal.Actions>
        </>
      )}
    </ModalCenterTemplate>
  );
};

export default ParkingRequestModal;
