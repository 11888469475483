import React from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import MarkerCustom from "../../MapView/MarkerCustom";

type Props = {
  lat: number;
  lng: number;
};

const Address = ({ lat, lng }: Props) => {
  if (!lat || !lng) {
    return null;
  }

  return (
    <div className={"book-detail__section"}>
      <div className={"book-detail__section-title"}>Location</div>
      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          height: "527px",
          borderRadius: "12px",
        }}
        zoom={12}
        center={{ lat, lng }}
      >
        <MarkerCustom highlight position={{ lat, lng }} />
      </GoogleMap>
    </div>
  );
};

export default Address;
