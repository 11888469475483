import TableCustom from "../../../../../../components/Table";
import React, { useContext, useEffect, useState } from "react";
import Label from "../../../../../../components/Label";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import { Label as LabelSemantic } from "semantic-ui-react";
import { employeesThunk } from "../../../../thunks/EmployeeThunks";
import {
  employeesFilterSelector,
  employeesSelector,
} from "../../../../selectors/EmployeeSelectors";
import { EmployeeType } from "../../../../configs/DeclareTypes";
import {
  employeeEditInlineAction,
  employeesFilterAction,
} from "../../../../actions/EmployeeActions";
import MonthlyPooledBudget from "./MonthlyPooledBudget";
import Action from "./Action";
import { employeeEditInlineService } from "../../../../services/EmployeeServices";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../../../App/configs/Constants";
import { userCompanyChooseSelector } from "../../../../selectors/UserSelectors";
import MonthlyBudget from "./MonthlyBudget";

const EmployeeTable = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const employees = useSelector(employeesSelector);
  const employeesFilter = useSelector(employeesFilterSelector);
  const userCompanyChoose = useSelector(userCompanyChooseSelector);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await dispatch<any>(employeesThunk());
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [employeesFilter]);

  const columns = [
    { key: "name", col: translations.userName },
    { key: "email", col: translations.email },
    {
      key: "budget",
      col: (
        <Label
          key={translations.monthlyBudget}
          label={
            translations.monthlyBudget +
            ` (${userCompanyChoose?.company_currency})`
          }
          labelGuide={translations.monthlyBudgetGuide}
          fontSize={"12px"}
        />
      ),
    },
    { key: "usage", col: translations.thisMonthUsage },
    {
      key: "company_budget",
      col: (
        <Label
          key={translations.monthlyPooleBudget}
          label={translations.monthlyPooleBudget}
          labelGuide={translations.monthlyPooleBudgetGuide}
          fontSize={"12px"}
        />
      ),
    },
    { key: "status", col: translations.accountStatus },
    { key: null, col: translations.action },
  ];

  const _formatInputData = () => {
    return employees?.data?.map(
      ({
        full_name,
        is_admin,
        email,
        budget_name,
        usage_budget,
        used_company_budget,
        status,
        status_name,
        id,
        has_monthly_budget,
        can_not_modify_budget,
      }: EmployeeType) => {
        return {
          col1: (
            <div>
              {full_name}
              {is_admin && (
                <LabelSemantic size={"mini"} className={"ml-4px"}>
                  Admin
                </LabelSemantic>
              )}
            </div>
          ),
          col2: email,
          col3: (
            <MonthlyBudget
              budgetName={budget_name?.toString()?.replaceAll(",", "")}
              id={id}
              setLoading={setLoading}
              canNotModify={can_not_modify_budget}
            />
          ),
          col4: usage_budget,
          col5: (
            <MonthlyPooledBudget
              id={id}
              onChange={_onChangeMonthlyBudget}
              isCheck={used_company_budget}
              hasMonthlyBudget={has_monthly_budget}
            />
          ),
          col6: status_name,
          col7: (
            <Action invitationId={id} status={status} setLoading={setLoading} />
          ),
        };
      }
    );
  };

  const _onChangeMonthlyBudget = async (e: any, data: any, id: any) => {
    try {
      const payload = {
        employee_id: id,
        used_company_budget: data.checked,
      };

      await employeeEditInlineService(payload);
      await dispatch(employeeEditInlineAction(payload));
    } catch (error) {
      console.log(error);
    }
  };

  const _onChangeSortCol = (col: any) => {
    if (employeesFilter.sort_by !== col.key) {
      dispatch(employeesFilterAction({ key: "sort_by", value: col.key }));
      dispatch(employeesFilterAction({ key: "order_by", value: ORDER_BY_ASC }));
    } else {
      dispatch(employeesFilterAction({ key: "sort_by", value: col.key }));
      if (employeesFilter.order_by === ORDER_BY_NONE) {
        dispatch(
          employeesFilterAction({ key: "order_by", value: ORDER_BY_ASC })
        );
      }
      if (employeesFilter.order_by === ORDER_BY_ASC) {
        dispatch(
          employeesFilterAction({ key: "order_by", value: ORDER_BY_DESC })
        );
      }
      if (employeesFilter.order_by === ORDER_BY_DESC) {
        dispatch(
          employeesFilterAction({ key: "order_by", value: ORDER_BY_NONE })
        );
      }
    }
  };

  return (
    <div
      className={"employee-table"}
      style={employees?.data?.length < 3 ? { paddingBottom: 100 } : {}}
    >
      <TableCustom
        orderBy={employeesFilter.order_by}
        sortBy={employeesFilter.sort_by}
        onChangeSortCol={_onChangeSortCol}
        sortTable={true}
        loading={loading}
        showTotal
        tableData={employees ? _formatInputData() : []}
        columns={columns}
        currentPage={employees?.pagination?.page}
        totalItems={employees?.pagination?.total}
        unit={translations.employeesLowerCase}
        onChangePagination={(e: any, { activePage }: any) => {
          dispatch(employeesFilterAction({ key: "page", value: activePage }));
        }}
        keepHeader
      />
    </div>
  );
};

export default EmployeeTable;
