import React from "react";
import moment from "moment-timezone";
import { FORMAT_DATE_VARIANT_2 } from "../../../../App/configs/DateTimeConfigs";
import DateRangePickerV2 from "../../../../../components/DateRangePickerV2";
import { campaignFilterAction } from "../../../actions/MarketingActions";
import { useDispatch, useSelector } from "react-redux";
import { campaignFilterSelector } from "../../../selectors/MarketingSelectors";

const FilterTime = () => {
  const dispatch = useDispatch();
  const campaignFilter = useSelector(campaignFilterSelector);

  return (
    <>
      <DateRangePickerV2
        format={"DD/MM/YYYY"}
        value={
          campaignFilter.filters.start_time
            ? [
                moment.unix(Number(campaignFilter.filters.start_time)),
                moment.unix(Number(campaignFilter.filters.end_time)),
              ]
            : []
        }
        onChange={(value: any) => {
          if (!value) {
            dispatch(
              campaignFilterAction({
                page: 1,
                filters: {
                  ...campaignFilter.filters,
                  start_time: null,
                  end_time: null,
                },
              })
            );
            return;
          }
          const filterTime = {
            start_time: moment(
              moment(value[0]).format(FORMAT_DATE_VARIANT_2),
              FORMAT_DATE_VARIANT_2
            )
              .clone()
              .tz("UTC", true)
              .startOf("day")
              .utc()
              .unix(),
            end_time: moment(
              moment(value[1]).format(FORMAT_DATE_VARIANT_2),
              FORMAT_DATE_VARIANT_2
            )
              .clone()
              .tz("UTC", true)
              .endOf("day")
              .utc()
              .unix(),
          };
          dispatch(
            campaignFilterAction({
              page: 1,
              filters: { ...campaignFilter.filters, ...filterTime },
            })
          );
        }}
      />
    </>
  );
};

export default FilterTime;
