import ButtonCustom from "../../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import React, { useContext, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
// import { useNavigate } from "react-router-dom";
import {
  deskimoPropertyAmenityDeleteAction,
  modalAddEditAmenityAction,
} from "../../../../actions/PropertyActions";
import { useDispatch } from "react-redux";
import { SWAL_DELETE_OPTION } from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { deletePropertyAmenityService } from "../../../../services/PropertyAmenityServices";
import { getDeskimoPropertyAmenityDetailThunk } from "../../../../thunks/PropertyThunks";

const Action = ({ amenityId, roomAmenities, deskAmenities }: any) => {
  const dispatch = useDispatch();
  const { translations } = useContext(LocalizationContext);
  const [disabled, setDisabled] = useState(false);

  const _onDeleteAmenity = async () => {
    setDisabled(true);
    try {
      let title = translations.propertyAmenities.deleteAmenityModalTitle;

      if (roomAmenities && deskAmenities) {
        title = `${translations.theAmenityAssignedTo} ${deskAmenities} ${translations["desk(s)"]} ${translations.and} ${roomAmenities} ${translations["room(s)"]}.
        ${translations.propertyAmenities.deleteAmenityModalTitle}`;
      } else if (deskAmenities) {
        title = `${translations.theAmenityAssignedTo} ${deskAmenities} ${translations["desk(s)"]}.
        ${translations.propertyAmenities.deleteAmenityModalTitle}`;
      } else if (roomAmenities) {
        title = `${translations.theAmenityAssignedTo} ${roomAmenities} ${translations["room(s)"]}.
        ${translations.propertyAmenities.deleteAmenityModalTitle}`;
      }

      const iconHtml = (
        <img
          src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
          className={"swal2-icon__error"}
          alt="error_icon"
        />
      );
      const confirmText = translations.delete;
      const cancelText = translations.cancel;
      const confirmClass = "danger";
      const options = SWAL_DELETE_OPTION({
        title,
        iconHtml,
        confirmText,
        cancelText,
        confirmClass,
      });
      SweetAlertCommon({
        options,
        confirmCallback: async () => {
          setDisabled(true);
          try {
            await deletePropertyAmenityService(amenityId);
            dispatch(deskimoPropertyAmenityDeleteAction(amenityId));
            toastSuccess(
              translations.propertyAmenities.amenityDeleteSuccessPrompt
            );
          } catch (e) {
            toastError(translations.propertyAmenities.amenityDeleteFailPrompt);
            console.error(e);
          } finally {
            setDisabled(false);
          }
        },
        cancelCallback: () => null,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setDisabled(false);
    }
  };

  const _onEditAmenity = async () => {
    setDisabled(true);
    try {
      await dispatch<any>(getDeskimoPropertyAmenityDetailThunk(amenityId));
      dispatch(modalAddEditAmenityAction(true));
    } catch (e) {
      console.error(e);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <DropdownCustom
      disabled={disabled}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={_onEditAmenity}
          text={translations.propertyAmenities.editAmenity}
          icon={
            <i className="uil uil-edit icon" style={{ fontSize: "16px" }} />
          }
        />
        <Dropdown.Item
          text={translations.propertyAmenities.deleteAmenity}
          onClick={_onDeleteAmenity}
          icon={
            <i
              className="uil uil-trash-alt icon"
              style={{ fontSize: "16px" }}
            />
          }
        />
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default Action;
