import React, { useContext, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import ButtonCustom from "../../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import OfficeLeasesContext from "../../Context";
import {
  officeLeaseEndService,
  officeLeaseRefundService,
} from "../../../../services/BusinessServices";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { LEASE_ENDED_STATUS } from "../../../../configs/Constants";
import {
  SWAL_CONFIRM_OPTION,
  SWAL_DELETE_OPTION,
} from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import { modalDeclineReasonsAction } from "../../../../../Dashboard/actions/DashboardActions";

type Props = {
  id: number;
  currency: string;
  deposit: { business: string; property: string };
  status: string;
};
const Action = ({ id, currency, deposit, status }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const {
    setModalOpen,
    setLeaseChooseId,
    setModalDepositInfo,
    onSetRefreshing,
  } = useContext(OfficeLeasesContext);
  const [disabled, setDisabled] = useState(false);

  const _onRefund = async (type: "BUSINESS" | "PROPERTY") => {
    const icon = null;
    const title = `Are you sure you want to refund the ${
      type === "BUSINESS" ? "company deposit" : "property deposit"
    }?`;
    const confirmText = "Refund";
    const options = SWAL_CONFIRM_OPTION({
      title,
      icon,
      confirmText,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setDisabled(true);
        try {
          const payload = {
            lease_contract_id: id,
            type,
          };
          await officeLeaseRefundService(payload);
          toastSuccess("Refunded Successfully");
          onSetRefreshing();
        } catch (e: any) {
          toastError(e?.status?.message);
        } finally {
          setDisabled(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  const _onEndLease = async () => {
    const title = "Are you sure you want to end this lease?";
    const confirmText = "End";
    const options = SWAL_DELETE_OPTION({
      title,
      confirmText,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        setDisabled(true);
        try {
          await officeLeaseEndService(id);
          toastSuccess("Ended Successfully");
          onSetRefreshing();
        } catch (e: any) {
          toastError(e?.status?.message);
        } finally {
          setDisabled(false);
        }
      },
      cancelCallback: () => null,
    });
  };

  return (
    <DropdownCustom
      disabled={disabled}
      className={"table-action-dropdown"}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => {
            setLeaseChooseId(id);
            setModalOpen(true);
          }}
          text={"Edit lease"}
          icon={
            <i className="uil uil-edit-alt icon" style={{ fontSize: "16px" }} />
          }
        />
        {status !== LEASE_ENDED_STATUS ? (
          <Dropdown.Item
            onClick={_onEndLease}
            text={"End lease"}
            icon={
              <i
                className="uil uil-exchange-alt icon"
                style={{ fontSize: "16px" }}
              />
            }
          />
        ) : null}

        {deposit?.business ? (
          <Dropdown.Item
            onClick={async () => {
              await _onRefund("BUSINESS");
            }}
            text={"Refund for business"}
            icon={
              <i
                className="uil uil-money-insert icon"
                style={{ fontSize: "16px" }}
              />
            }
          />
        ) : (
          <Dropdown.Item
            onClick={() => {
              setModalDepositInfo({
                open: true,
                leaseId: id,
                type: "BUSINESS",
                currency,
              });
            }}
            text={"Add deposit from business"}
            icon={
              <i
                className="uil uil-money-withdraw icon"
                style={{ fontSize: "16px" }}
              />
            }
          />
        )}
        {deposit?.property ? (
          <Dropdown.Item
            onClick={async () => {
              await _onRefund("PROPERTY");
            }}
            text={"Refund for property"}
            icon={
              <i
                className="uil uil-money-insert icon"
                style={{ fontSize: "16px" }}
              />
            }
          />
        ) : (
          <Dropdown.Item
            onClick={() => {
              setModalDepositInfo({
                open: true,
                leaseId: id,
                type: "PROPERTY",
                currency,
              });
            }}
            text={"Add deposit for property"}
            icon={
              <i
                className="uil uil-money-withdraw icon"
                style={{ fontSize: "16px" }}
              />
            }
          />
        )}
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default Action;
