import React, { useContext } from "react";
import SectionWhiteBoxItem from "../../../../../../components/SectionWhiteBoxItem/SectionWhiteBoxItem";
import { CampaignDetailType } from "../../../../configs/DeclareTypes";
import { useSelector } from "react-redux";
import { campaignDetailSelector } from "../../../../selectors/MarketingSelectors";
import moment from "moment-timezone";
import { FORMAT_DATE_VARIANT_2 } from "../../../../../App/configs/DateTimeConfigs";
import { LocalizationContext } from "../../../../../../locales/Translation";

const VoucherOnboard = () => {
  const { translations } = useContext(LocalizationContext);

  const campaignDetail: CampaignDetailType = useSelector(
    campaignDetailSelector
  );
  return (
    <>
      <div className={"section-white-box-column"}>
        <SectionWhiteBoxItem
          label={"Voucher type"}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={translations.voucherType[campaignDetail.voucher_reward.type]}
        />

        <SectionWhiteBoxItem
          label={"Voucher value"}
          value={`${campaignDetail.voucher_reward.currency} ${campaignDetail.voucher_reward.value}`}
        />
      </div>

      <div className={"section-white-box-column"}>
        <SectionWhiteBoxItem
          label={"Currency"}
          value={campaignDetail.voucher_reward.currency}
        />
        <SectionWhiteBoxItem
          label={
            "Free credit valid for X days (from the day the user is onboarded)"
          }
          value={campaignDetail.voucher_reward.redeem_duration}
        />
      </div>

      <div className={"section-white-box-column"}>
        <SectionWhiteBoxItem
          label={"Onboard campaign Start / End Time"}
          value={`${moment
            .unix(campaignDetail.voucher_reward.free_credit_onboard_start_time)
            .tz(campaignDetail.timezone)
            .format(FORMAT_DATE_VARIANT_2)} - ${moment
            .unix(campaignDetail.voucher_reward.free_credit_onboard_end_time)
            .tz(campaignDetail.timezone)
            .format(FORMAT_DATE_VARIANT_2)}`}
        />
      </div>
    </>
  );
};

export default VoucherOnboard;
