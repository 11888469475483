import React from "react";
import { PUBLIC_URL } from "../../../../App/configs/Constants";

type Props = {
  count: number;
};

const Rating = ({ count }: Props) => {
  return (
    <>
      {Array(count)
        .fill(1)
        .map((item, index) => {
          return (
            <img
              key={index}
              src={PUBLIC_URL + "/assets/icons/icon-star-black.svg"}
            />
          );
        })}
    </>
  );
};

export default Rating;
