import React from "react";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import ButtonCustom from "../../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import { affiliateChangeStatusService } from "../../../../services/SettingServices";
import { FAKING_CODE_CACHE } from "../../../../../../configs/Constants";

type Props = {
  reference: string;
  payable: boolean;
  onRefresh: () => Promise<void>;
};

const Action = ({ payable, reference, onRefresh }: Props) => {
  const fakingCode = localStorage.getItem(FAKING_CODE_CACHE);
  const _onMoveToPaidOut = async () => {
    try {
      await affiliateChangeStatusService(reference, {
        status: "PAID_OUT",
        faking_code: fakingCode,
      });
      await onRefresh();
    } catch (error) {
      console.log(Error);
    }
  };

  if (!payable || !fakingCode) {
    return null;
  }

  return (
    <DropdownCustom
      className={"table-action-dropdown"}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={_onMoveToPaidOut}
          text={"Move to paid out"}
          icon={<i className="uil uil-exchange icon" />}
        />
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default Action;
