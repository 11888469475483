import React, { forwardRef, useState } from "react";
import "./style.scss";
import { Input as InputSemantic, Popup } from "semantic-ui-react";
import classnames from "classnames";
import classNames from "classnames";

type Props = {
  label?: string;
  labelGuide?: string;
  id?: string;
  inputRightIcon?: string | JSX.Element;
  inputLeftIcon?: string | JSX.Element;
  inputLeftIconBorder?: boolean;
  placeholder?: string;
  touched?: string | boolean;
  error?: string;
  labelRequired?: boolean;
  containerClass?: string;
  name?: string;
  height?: string;
  passwordShow?: boolean;
  type?: string;
  disabled?: boolean;
  labelClassName?: string;
  removeLetter?: boolean;
  allowNegativeNumber?: boolean;
  [x: string]: any;
};

// eslint-disable-next-line react/display-name
const InputCustom = forwardRef(
  (
    {
      label,
      labelGuide,
      id,
      inputRightIcon,
      inputLeftIcon,
      inputLeftIconBorder = false,
      placeholder,
      touched,
      error,
      labelRequired = false,
      containerClass,
      name,
      height = "44px",
      passwordShow,
      type = "text",
      disabled,
      labelClassName = "",
      removeLetter,
      allowNegativeNumber,
      ...props
    }: Props,
    ref
  ) => {
    const [isShowPassword, setIsShowPassword] = useState(false);

    return (
      <div
        className={classNames("input-component", containerClass, {
          disabled: disabled,
        })}
      >
        {label && (
          <div className={"label"}>
            <label
              htmlFor={id}
              className={classNames(labelClassName, {
                required: labelRequired,
              })}
            >
              {label}
            </label>
            {labelGuide && (
              <Popup
                className={"input-label-text-guide"}
                content={labelGuide}
                trigger={<i className="uil uil-info-circle input-label-icon" />}
                position="top center"
              />
            )}
          </div>
        )}
        <div
          style={{ height }}
          className={classnames("input-wrapper", {
            "input-wrapper-icon": inputRightIcon,
            "input-wrapper-left-icon": inputLeftIcon,
          })}
        >
          {inputLeftIcon && (
            <div
              className={classNames("input-left-icon", {
                border: inputLeftIconBorder,
              })}
            >
              {inputLeftIcon}
            </div>
          )}
          <InputSemantic
            style={{ height: "100%" }}
            name={name}
            id={id}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={ref}
            {...props}
            onChange={(e, data) => {
              let newValue = e.target.value;
              if (name && name?.includes("email")) {
                newValue = e.target.value.replaceAll(" ", "");
              }

              if (removeLetter && allowNegativeNumber) {
                newValue = e.target.value
                  .replace(/[^0-9.-]/g, "")
                  .replace(/^(-)|-+/g, "$1")
                  .replace(/^([^.]*\.)|\.+/g, "$1");
              }

              if (removeLetter && !allowNegativeNumber) {
                newValue = e.target.value.replace(/[^.0-9]/g, "");
              }

              e.target.value = newValue;
              data.value = newValue;

              // eslint-disable-next-line react/prop-types
              return props.onChange(e, data);
            }}
            placeholder={placeholder}
            type={
              passwordShow && isShowPassword
                ? "text"
                : passwordShow && !isShowPassword
                ? "password"
                : type
            }
          />
          {passwordShow ? (
            <div
              className={"input-right-icon input-right-icon-click"}
              onClick={() => setIsShowPassword((prevState) => !prevState)}
            >
              {isShowPassword ? (
                <i className="uil uil-eye-slash" />
              ) : (
                <i className="uil uil-eye" />
              )}
            </div>
          ) : inputRightIcon ? (
            <div className={"input-right-icon"}>{inputRightIcon}</div>
          ) : null}
        </div>
        {touched && error ? <span className={"error"}>{error}</span> : null}
      </div>
    );
  }
);

export default InputCustom;
