import SizeBox from "../../../../../../../../components/SizeBox";
import React, { useEffect, useState } from "react";
import Label from "../../../../../../../../components/Label";
import { useSelector } from "react-redux";
import { userCompanyChooseSelector } from "../../../../../../../User/selectors/UserSelectors";
import { partnerRevenueService } from "../../../../../../services/SettingServices";

const MonthlyUsage = () => {
  const userCompanyChoose = useSelector(userCompanyChooseSelector);
  const [usageData, setUsageData] = useState<any>(null);

  useEffect(() => {
    (async () => {
      const { data } = await partnerRevenueService();
      setUsageData(data);
    })();
  }, [userCompanyChoose]);

  return (
    <div className={"billing-tab__right-summary"}>
      <div className={"item"}>
        <Label label={"Total usage"} className={"fw-400 mb-0"} />
        <SizeBox height={"8px"} />
        <div className={"amount"}>
          {usageData?.total_usage}
          <Label
            fontSize={"10px"}
            className={"fw-400 mb-0 d-inline"}
            label={" / this month"}
          />
        </div>
      </div>
      <div className={"item"}>
        <Label label={"Commission"} className={"fw-400 mb-0"} />
        <SizeBox height={"8px"} />
        <div className={"amount"}>{usageData?.total_commission}</div>
      </div>
      <div className={"item"}>
        <Label label={"Deskimo pending payment"} className={"fw-400 mb-0"} />
        <SizeBox height={"8px"} />
        <div className={"amount"}>{usageData?.total_debt}</div>
      </div>
    </div>
  );
};

export default MonthlyUsage;
