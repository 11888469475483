import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { Formik } from "formik";
import * as yup from "yup";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import ModalCenterTemplate from "../../../../../App/views/ModalCenterTemplate";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalBookingRoomSelector } from "../../../../selectors/DashboardSelectors";
import { modalBookingRoomManualAction } from "../../../../actions/DashboardActions";
import { LocalizationContext } from "../../../../../../locales/Translation";
import {
  manualBookingGetPaymentMethodService,
  manualBookingGetPropertiesService,
  manualBookingGetRoomsService,
  manualBookingGetUsersService,
  manualBookingSubmitRoomService,
} from "../../../../services/DashboardServices";
import Label from "../../../../../../components/Label";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import { debounce } from "lodash";
import SizeBox from "../../../../../../components/SizeBox";
import DatePickerSingle from "../../../../../../components/DatePickerSingle";
import { dayOptions } from "../../../../../App/configs/DateTimeConfigs";
import { calculateBookingTime } from "../../../../../Book/services/Common";
import InputCustom from "../../../../../../components/Input";

type ManualBookingProperty = {
  id: number;
  name: string;
  timezone: string;
};

type ManualBookingRoom = {
  id: number;
  name: string;
};

type ManualBookingUser = {
  email: string;
  id: number;
  name: string;
  phone_number: string;
};

type ManualBookingPaymentMethod = {
  id: number;
  name: string;
};

type ManualBookingDataType = {
  listProperty: ManualBookingProperty[];
  listMeetingRoom: ManualBookingRoom[];
  listUser: ManualBookingUser[];
  listPaymentMethod: ManualBookingPaymentMethod[];
  propertySelected: string;
  meetingRoomSelected: string;
  userSelected: string;
  paymentMethodSelected: string;
  dateSelected: Date;
  startTime: string;
  endTime: string;
  minTime: string;
  maxTime: string;
  userName?: string;
  email?: string;
};

const ModalBookingRoom = ({ userDetail, isPartner = false }: any) => {
  const { translations } = useContext(LocalizationContext);
  const modalBookingRoom = useSelector(modalBookingRoomSelector);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const initData = {
    userName: isPartner ? "" : undefined,
    email: isPartner ? "" : undefined,
    listProperty: [],
    listMeetingRoom: [],
    listUser: [],
    listPaymentMethod: [],
    propertySelected: "",
    meetingRoomSelected: "",
    userSelected: userDetail?.user_id || "",
    paymentMethodSelected: "",
    dateSelected: new Date(),
    startTime: "",
    endTime: "",
    minTime: "",
    maxTime: "",
  };
  const [modalData, setModalData] = useState<ManualBookingDataType>(initData);
  const [propertyKeyword, setPropertyKeyword] = useState("");
  const [userKeyword, setUserKeyword] = useState("");

  const _onCloseModal = () => {
    dispatch(modalBookingRoomManualAction({ open: false }));
    setModalData(initData);
  };

  useEffect(() => {
    if (modalBookingRoom.open) {
      _fetchListProperty();
    }
  }, [modalBookingRoom, propertyKeyword]);

  useEffect(() => {
    if (modalBookingRoom.open && modalData.propertySelected) {
      _fetchListMeetingRoom();
    }
  }, [modalData.propertySelected]);

  useEffect(() => {
    if (modalBookingRoom.open) {
      _fetchListUser();
    }
  }, [userKeyword]);

  useEffect(() => {
    if (modalBookingRoom.open && modalData.userSelected) {
      _fetchListPaymentMethod();
    }
  }, [modalData.userSelected, modalBookingRoom]);

  const _fetchListProperty = async () => {
    if (modalBookingRoom.open) {
      try {
        const payload = {
          keyword: propertyKeyword,
        };
        const response = await manualBookingGetPropertiesService(payload);
        setModalData({ ...modalData, listProperty: response?.data });
      } catch (error: any) {
        toastError(error?.status?.message);
      }
    }
  };

  const _fetchListMeetingRoom = async () => {
    if (modalBookingRoom.open) {
      try {
        const response = await manualBookingGetRoomsService(
          modalData.propertySelected || ""
        );
        setModalData({ ...modalData, listMeetingRoom: response?.data });
      } catch (error: any) {
        toastError(error?.status?.message);
      }
    }
  };

  const _fetchListUser = async () => {
    if (modalBookingRoom.open) {
      try {
        const payload = {
          keyword: userKeyword,
        };
        const response = await manualBookingGetUsersService(payload);
        setModalData({ ...modalData, listUser: response?.data });
      } catch (error: any) {
        toastError(error?.status?.message);
      }
    }
  };

  const _fetchListPaymentMethod = async () => {
    if (modalBookingRoom.open && modalData.userSelected) {
      try {
        const response = await manualBookingGetPaymentMethodService(
          modalData?.userSelected.toString()
        );
        setModalData({
          ...modalData,
          listPaymentMethod: response?.data,
          paymentMethodSelected: response?.data[0]?.id
            ? String(response?.data[0]?.id)
            : "",
        });
      } catch (error: any) {
        toastError(error?.status?.message);
      }
    }
  };

  const getPropertyTimezone = () => {
    return modalData.listProperty.find(
      (property) => property.id === parseInt(modalData.propertySelected)
    )?.timezone;
  };

  const getBookingDuration = () => {
    const [startTimeHour, startTimeMinute] = modalData.startTime.split(":");
    const [endTimeHour, endTimeMinute] = modalData.endTime.split(":");
    return (
      parseInt(endTimeHour) * 60 +
      parseInt(endTimeMinute) -
      parseInt(startTimeHour) * 60 -
      parseInt(startTimeMinute)
    );
  };

  const _onClickSave = async () => {
    setLoading(true);
    try {
      const timezone = getPropertyTimezone();
      const duration = getBookingDuration();
      if (!timezone) return;

      const payload = {
        property: modalData.propertySelected,
        meeting_room: modalData.meetingRoomSelected,
        user: modalData.userSelected,
        payment_method: modalData.paymentMethodSelected,
        start_time: calculateBookingTime(
          modalData.dateSelected,
          modalData.startTime,
          undefined,
          timezone
        ),
        end_time: calculateBookingTime(
          modalData.dateSelected,
          modalData.startTime,
          duration,
          timezone
        ),
        user_name: isPartner ? modalData.userName : undefined,
        email: isPartner ? modalData.email : undefined,
      };
      await manualBookingSubmitRoomService(payload);
      _onCloseModal();
      toastSuccess("Create booking successfully");
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={_onCloseModal}
      modalClassName={"modal-general"}
      open={modalBookingRoom.open}
    >
      <Modal.Header>
        Create a Room Booking
        <ButtonCustom onClick={_onCloseModal} className={"modal-close-cta"}>
          <>
            <span>{translations.close}</span>
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Formik
        initialValues={{}}
        validationSchema={yup.object().shape({})}
        onSubmit={_onClickSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <>
            <Modal.Content>
              <form className={"form-layout"}>
                {isPartner ? (
                  <div className={"d-flex gap-24"}>
                    <div className={"flex-1"}>
                      <div>
                        <Label
                          fontSize={"12px"}
                          className={"sm"}
                          label={"User Name"}
                        />
                        <InputCustom
                          value={modalData.userName}
                          onChange={(e: any, data: any) =>
                            setModalData({ ...modalData, userName: data.value })
                          }
                        />
                      </div>
                      <SizeBox height={"16px"} />
                    </div>
                    <div className={"flex-1"}>
                      <div>
                        <Label
                          fontSize={"12px"}
                          className={"sm"}
                          label={"Email"}
                        />
                        <InputCustom
                          type={"email"}
                          value={modalData.email}
                          onChange={(e: any, data: any) =>
                            setModalData({ ...modalData, email: data.value })
                          }
                        />
                      </div>
                      <SizeBox height={"16px"} />
                    </div>
                  </div>
                ) : null}
                <div>
                  <Label
                    fontSize={"12px"}
                    className={"sm"}
                    label={"Property"}
                  />
                  <DropdownCustom
                    selectUI
                    search
                    onSearchChange={debounce((event, data) => {
                      setPropertyKeyword(data.searchQuery);
                    }, 200)}
                    onChange={(event, data) => {
                      setModalData({
                        ...modalData,
                        propertySelected: data.value
                          ? data.value.toString()
                          : "",
                      });
                    }}
                    options={modalData.listProperty.map(
                      (property: ManualBookingProperty) => {
                        return {
                          key: property.id,
                          text: property.name,
                          value: property.id,
                        };
                      }
                    )}
                    className={"dropdown-fullwidth-common-style"}
                  />
                </div>
                <SizeBox height={"16px"} />
                <div>
                  <Label
                    fontSize={"12px"}
                    className={"sm"}
                    label={"Meeting Room"}
                  />
                  <DropdownCustom
                    selectUI
                    search
                    onChange={(event, data) => {
                      setModalData({
                        ...modalData,
                        meetingRoomSelected: data.value
                          ? data.value.toString()
                          : "",
                      });
                    }}
                    options={modalData.listMeetingRoom.map(
                      (room: ManualBookingRoom) => {
                        return {
                          key: room.id,
                          text: room.name,
                          value: room.id,
                        };
                      }
                    )}
                    className={"dropdown-fullwidth-common-style"}
                  />
                </div>
                <SizeBox height={"16px"} />
                <div>
                  <Label
                    fontSize={"12px"}
                    className={"sm"}
                    label={"Start Date"}
                  />
                  <DatePickerSingle
                    value={modalData.dateSelected}
                    inputStyle
                    oneTap
                    onChange={(date: Date) => {
                      setModalData({ ...modalData, dateSelected: date });
                    }}
                  />
                </div>
                <SizeBox height={"16px"} />
                <div className={"d-flex"}>
                  <div style={{ flex: 1 }}>
                    <Label
                      fontSize={"12px"}
                      className={"sm"}
                      label={"Start Time"}
                    />
                    <DropdownCustom
                      selectUI
                      search
                      onChange={(event, data) => {
                        setModalData({
                          ...modalData,
                          startTime: data.value ? data.value.toString() : "",
                          minTime: data.value ? data.value.toString() : "",
                        });
                      }}
                      options={dayOptions({
                        minTime: undefined,
                        maxTime: modalData.maxTime,
                      })}
                      className={"dropdown-fullwidth-common-style"}
                    />
                  </div>
                  <SizeBox width={"16px"} />
                  <div style={{ flex: 1 }}>
                    <Label
                      fontSize={"12px"}
                      className={"sm"}
                      label={"End Time"}
                    />
                    <DropdownCustom
                      selectUI
                      search
                      onChange={(event, data) => {
                        setModalData({
                          ...modalData,
                          endTime: data.value ? data.value.toString() : "",
                          maxTime: data.value ? data.value.toString() : "",
                        });
                      }}
                      options={dayOptions({
                        minTime: modalData.minTime,
                        maxTime: undefined,
                      })}
                      className={"dropdown-fullwidth-common-style"}
                    />
                  </div>
                </div>
                <SizeBox height={"16px"} />
                {isPartner ? null : (
                  <>
                    <div>
                      <Label
                        fontSize={"12px"}
                        className={"sm"}
                        label={"User"}
                      />
                      {userDetail ? (
                        <InputCustom disabled value={userDetail.email} />
                      ) : (
                        <DropdownCustom
                          selectUI
                          search
                          onSearchChange={debounce((event, data) => {
                            setUserKeyword(data.searchQuery);
                          }, 200)}
                          onChange={(event, data) => {
                            setModalData({
                              ...modalData,
                              userSelected: data.value
                                ? data.value.toString()
                                : "",
                            });
                          }}
                          options={modalData.listUser.map(
                            (user: ManualBookingUser) => {
                              return {
                                key: user.id,
                                text:
                                  user.email + " (" + user.phone_number + " )",
                                value: user.id,
                              };
                            }
                          )}
                          className={"dropdown-fullwidth-common-style"}
                        />
                      )}
                    </div>
                    <SizeBox height={"16px"} />
                    <div>
                      <Label
                        fontSize={"12px"}
                        className={"sm"}
                        label={"Payment Method"}
                      />
                      <DropdownCustom
                        selectUI
                        search
                        value={Number(modalData?.paymentMethodSelected)}
                        onChange={(event, data) => {
                          setModalData({
                            ...modalData,
                            paymentMethodSelected: data.value
                              ? data.value.toString()
                              : "",
                          });
                        }}
                        onOpen={async () => {
                          if (modalData.listPaymentMethod.length < 1) {
                            await _fetchListPaymentMethod();
                          }
                        }}
                        options={modalData.listPaymentMethod.map(
                          (paymentMethod: ManualBookingPaymentMethod) => {
                            return {
                              key: paymentMethod.id,
                              text: paymentMethod.name,
                              value: paymentMethod.id,
                            };
                          }
                        )}
                        className={"dropdown-fullwidth-common-style"}
                      />
                    </div>
                  </>
                )}
              </form>
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom height={"47px"} onClick={_onCloseModal}>
                {translations.cancel}
              </ButtonCustom>
              <ButtonCustom
                disabled={
                  isPartner
                    ? modalData.propertySelected.length < 1 ||
                      modalData.meetingRoomSelected.length < 1 ||
                      modalData.startTime.length < 1 ||
                      modalData.endTime.length < 1
                    : modalData.propertySelected.length < 1 ||
                      modalData.meetingRoomSelected.length < 1 ||
                      modalData.userSelected.length < 1 ||
                      modalData.paymentMethodSelected.length < 1 ||
                      modalData.startTime.length < 1 ||
                      modalData.endTime.length < 1
                }
                loading={isSubmitting}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {translations.create}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalCenterTemplate>
  );
};

export default ModalBookingRoom;
