import MasterTemplate from "../../../App/views/MasterTemplate";
import EmployeeTable from "./Components/EmployeeTable";
import React, { SyntheticEvent, useEffect, useState } from "react";
import ModalEditEmployee from "./Components/ModalEditEmployee";
import "../../styles/employee.scss";
import { useDispatch, useSelector } from "react-redux";
import AddEmployeePropertyModal from "./Components/ModalAddEmployeeProperty";
import { ROLE_DESKIMO_ADMIN_ID } from "../../configs/Constants";
import { usersFilterAction } from "../../actions/EmployeeActions";
import SearchUser from "./Components/EmployeeTable/SearchUser";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../App/configs/Constants";
import { usersFilterSelector } from "../../selectors/EmployeeSelectors";
import { deskimoUsersThunk } from "../../thunks/EmployeeThunks";
import { useGetAllQuery, useSetQuery } from "../../../../utils/custom-hooks";
import { globalSettingSelector } from "../../../App/selectors/AppSelectors";
import CompanyFilter from "../../../../components/CompanyFilter";
import { parseInt } from "lodash";

const EmployeeList = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const usersFilter = useSelector(usersFilterSelector);
  const [addParamsAlready, setAddParamsAlready] = useState<boolean>(false);
  const getAllQuery = useGetAllQuery();
  const setQuery = useSetQuery();
  const globalSetting = useSelector(globalSettingSelector);

  useEffect(() => {
    if (!Object.keys(getAllQuery).length) {
      return;
    }
    dispatch(usersFilterAction(getAllQuery));
    setAddParamsAlready(true);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (addParamsAlready) {
          if (usersFilter?.company_id === "") {
            return;
          }
          setLoading(true);
          await dispatch<any>(deskimoUsersThunk());
          setQuery(usersFilter);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
        setAddParamsAlready(true);
      }
    })();
  }, [usersFilter, addParamsAlready]);

  const _onSearch = (value: string) => {
    dispatch(usersFilterAction({ ...usersFilter, keyword: value }));
  };

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    dispatch(usersFilterAction({ ...usersFilter, page: activePage }));
  };

  const _onChangeSortCol = (col: any) => {
    if (usersFilter.sort_by !== col.key) {
      dispatch(
        usersFilterAction({
          ...usersFilter,
          sort_by: col.key,
          order_by: ORDER_BY_ASC,
        })
      );
    } else {
      dispatch(
        usersFilterAction({
          ...usersFilter,
          sort_by: col.key,
        })
      );
      if (usersFilter.order_by === ORDER_BY_NONE) {
        dispatch(
          usersFilterAction({
            ...usersFilter,
            order_by: ORDER_BY_ASC,
          })
        );
      }
      if (usersFilter.order_by === ORDER_BY_DESC) {
        dispatch(
          usersFilterAction({
            ...usersFilter,
            order_by: ORDER_BY_NONE,
          })
        );
      }
      if (usersFilter.order_by === ORDER_BY_ASC) {
        dispatch(
          usersFilterAction({
            ...usersFilter,
            order_by: ORDER_BY_DESC,
          })
        );
      }
    }
  };

  return (
    <MasterTemplate roleAccept={[ROLE_DESKIMO_ADMIN_ID]}>
      <div className={"filter-section filter-section-employee"}>
        <div className={"d-flex flex-wrap w-100 align-items-center"}>
          <SearchUser
            defaultValue={usersFilter?.keyword}
            onSearch={_onSearch}
          />
          <CompanyFilter
            companyId={parseInt(usersFilter.company_id)}
            type={0}
            marketCodes={
              usersFilter.market_code === 0 || usersFilter.market_code === "0"
                ? globalSetting?.location.map(
                    ({ city_code }: { city_code: string }) => city_code
                  )
                : [usersFilter.market_code]
            }
            onChange={(value) =>
              dispatch(
                usersFilterAction({
                  ...usersFilter,
                  company_id: value,
                  page: 1,
                })
              )
            }
            showDeletedCompany
          />
        </div>
      </div>
      <EmployeeTable
        setLoading={setLoading}
        loading={loading}
        onChangeSortCol={_onChangeSortCol}
        onChangePagination={_onChangePagination}
      />

      <ModalEditEmployee />
      <AddEmployeePropertyModal />
      {/*<ModalCreateDeskimoAdmin />*/}
    </MasterTemplate>
  );
};

export default EmployeeList;
