import { Modal, Popup } from "semantic-ui-react";
import React, { useContext, useState } from "react";
import ButtonCustom from "../../../../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  modalDetailEmployeeAction,
  modalEditEmployeeAction,
} from "../../../../actions/EmployeeActions";
import {
  employeeDetailSelector,
  isModalDetailEmployeeSelector,
} from "../../../../selectors/EmployeeSelectors";
import ModalRightSideBarTemplate from "../../../../../App/views/ModalRightSideBarTemplate";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { EmployeeDetailType } from "../../../../configs/DeclareTypes";
import Label from "../../../../../../components/Label";
import BoxToggleSlider from "../../../../../../components/BoxToggleSlider";
import SizeBox from "../../../../../../components/SizeBox";
import "./style.scss";
import Divide from "../../../../../../components/Divide";
import ToggleSlider from "../../../../../../components/ToggleSlider";
import { ROLE_BUSINESS_ADMIN_ID } from "../../../../configs/Constants";
import { employeeEditInlineService } from "../../../../services/EmployeeServices";
import { SWAL_DELETE_OPTION } from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import { toastError } from "../../../../../../components/Toast";
import {
  employeeDetailThunk,
  employeesThunk,
} from "../../../../thunks/EmployeeThunks";
import moment from "moment";
import { FORMAT_DATE_TIME_VARIANT_2 } from "../../../../../App/configs/DateTimeConfigs";
import { Link } from "react-router-dom";
import { SETTING_LINK } from "../../../../../App/configs/ListRoutes";
import { LocalizationContext } from "../../../../../../locales/Translation";
import MakeAdminLabelGuide from "../../../../../App/views/ModalAddEmployee/MakeAdminLableGuide";

const ModalDetailEmployee = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const isModalDetailEmployee = useSelector(isModalDetailEmployeeSelector);
  const [loading, setLoading] = useState(false);

  const employeeDetail: EmployeeDetailType = useSelector(
    employeeDetailSelector
  );

  const _onChangeAdmin = async (e: any, data: any) => {
    if (data.checked) {
      const icon = null;
      const title = translations.areYouSureYouWantToMakeThisUserAdmin;
      const confirmText = translations.makeAdmin;
      const confirmClass = "primary";
      const options = SWAL_DELETE_OPTION({
        title,
        icon,
        confirmText,
        confirmClass,
      });
      SweetAlertCommon({
        options,
        confirmCallback: async () => {
          changeEmployeeRole(data);
        },
        cancelCallback: () => null,
      });
    } else {
      changeEmployeeRole(data);
    }
  };

  const changeEmployeeRole = async (data: any) => {
    setLoading(true);
    try {
      const payload = {
        employee_id: employeeDetail?.invitation_id,
        make_admin: data.checked,
      };
      await employeeEditInlineService(payload);
      await dispatch<any>(employeeDetailThunk(employeeDetail?.invitation_id));
    } catch (response: any) {
      toastError(response?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const isAdmin = employeeDetail?.role === ROLE_BUSINESS_ADMIN_ID;

  const _openEditModal = async () => {
    dispatch(modalDetailEmployeeAction(false));
    dispatch(modalEditEmployeeAction(true));
  };

  const _onChangeMonthlyBudget = async (e: any, data: any) => {
    setLoading(true);
    try {
      const payload = {
        employee_id: employeeDetail?.invitation_id,
        used_company_budget: data.checked,
      };

      await employeeEditInlineService(payload);
      await dispatch<any>(employeeDetailThunk(employeeDetail?.invitation_id));
      await dispatch<any>(employeesThunk());
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalRightSideBarTemplate
      loading={loading}
      trigger={null}
      onClose={() => dispatch(modalDetailEmployeeAction(false))}
      modalClassName={"modal-edit-employee"}
      open={isModalDetailEmployee}
    >
      <Modal.Header>
        {translations.employeeDetails}
        <ButtonCustom
          onClick={() => dispatch(modalDetailEmployeeAction(false))}
          className={"m-0 p-0 none-bg close-modal"}
        >
          <>
            {translations.close}
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Modal.Content className={"scroll"}>
        <SizeBox height={"24px"} />
        <div className={"avatar-info"}>
          <img
            src={
              employeeDetail?.avatar
                ? employeeDetail?.avatar
                : PUBLIC_URL + "/assets/default-add-avatar.svg"
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src =
                PUBLIC_URL + "/assets/icons/default-add-avatar.svg";
            }}
          />
          <div className={"employee-meta"}>
            <div className={"employee-meta__row"}>
              <div className={"fw-500"}>{translations.name}:</div>{" "}
              {(employeeDetail?.first_name ? employeeDetail?.first_name : "") +
                " " +
                (employeeDetail?.last_name ? employeeDetail?.last_name : "")}
            </div>
            <SizeBox height={"12px"} />
            <div className={"employee-meta__row"}>
              <div className={"fw-500"}>{translations.email}:</div>
              {employeeDetail?.email}
            </div>
            {employeeDetail?.format_phone_number ? (
              <>
                <SizeBox height={"12px"} />
                <div className={"employee-meta__row"}>
                  <div className={"fw-500"}>{translations.phoneNo}:</div>
                  {employeeDetail?.format_phone_number}
                </div>
              </>
            ) : null}
          </div>
        </div>
        <SizeBox height={"18px"} />
        <Divide type={"horizontal"} />
        <SizeBox height={"16px"} />
        <div>
          {employeeDetail?.free_credit ? (
            <div className={"employee-meta__row"}>
              <div className={"fw-500"}>Free credit:</div>
              {employeeDetail?.free_credit}
            </div>
          ) : null}

          {employeeDetail?.expired_date ? (
            <>
              <SizeBox height={"12px"} />
              <div className={"employee-meta__row"}>
                <div className={"fw-500"}>Valid until:</div>{" "}
                {employeeDetail?.expired_date}
              </div>
            </>
          ) : null}

          <SizeBox height={"12px"} />
          <div className={"employee-meta__row"}>
            <div className={"fw-500"}>{translations.monthlyBudget}:</div>{" "}
            {employeeDetail?.monthly_budget}
          </div>
          <SizeBox height={"12px"} />
          <div className={"employee-meta__row"}>
            <div className={"fw-500"}>{translations.thisMonthUsage}:</div>{" "}
            {employeeDetail?.this_month_use}
          </div>
          <SizeBox height={"22px"} />
          <div>
            <Popup
              hoverable
              disabled={employeeDetail?.has_monthly_budget}
              trigger={
                <div>
                  <ToggleSlider
                    value={employeeDetail?.monthly_pooled_budget}
                    onChange={_onChangeMonthlyBudget}
                    className={"employee-meta__slider"}
                    revertLabel={true}
                    content={translations.accessToMonthlyCompanyPooledBudget}
                    disabled={!employeeDetail?.has_monthly_budget}
                  />
                </div>
              }
            >
              <div className={"employee-table__monthly_budget_popup"}>
                {translations.thePooledBudgetOptionIsCurrentlyDeactivated}
                <Link to={SETTING_LINK} className={"link"}>
                  {translations.activateInSettings}
                </Link>
              </div>
            </Popup>
          </div>
          <SizeBox height={"14px"} />
          {employeeDetail?.has_monthly_budget ? null : (
            <div className={"employee__sub-text"}>
              * {translations.thePooledBudgetOptionIsCurrentlyDeactivated}
              <Link to={SETTING_LINK} className={"link"}>
                {translations.activateInSettings}
              </Link>
            </div>
          )}
        </div>
        <Divide type={"horizontal"} />
        <SizeBox height={"16px"} />
        <div>
          <div className={"employee-meta__row"}>
            <div className={"fw-500"}>{translations.invitationCode}:</div>{" "}
            {employeeDetail?.invitation_code}
          </div>
          <SizeBox height={"12px"} />
          <div className={"employee-meta__row"}>
            <div className={"fw-500"}>{translations.accountStatus}:</div>{" "}
            {employeeDetail?.status_name}
          </div>
          <SizeBox height={"12px"} />
          <div className={"employee-meta__row"}>
            <div className={"fw-500"}>{translations.firstAdded}:</div>
            {employeeDetail?.created_at
              ? moment(employeeDetail?.created_at * 1000).format(
                  FORMAT_DATE_TIME_VARIANT_2
                )
              : ""}
          </div>
        </div>
        <Divide type={"horizontal"} />
        <SizeBox height={"22px"} />
        <div>
          <Popup
            content="Before promoting to admin, please enter the user’s phone number"
            disabled={!!employeeDetail?.phone_number?.length}
            position={"top right"}
            trigger={
              <BoxToggleSlider
                disabled={!employeeDetail?.phone_number?.length}
                value={isAdmin}
                onChange={_onChangeAdmin}
                content={
                  <Label
                    fontSize={"14px"}
                    size={"16px"}
                    label={translations.makeAdmin}
                    labelGuide={<MakeAdminLabelGuide />}
                  />
                }
              />
            }
          />
        </div>
        <SizeBox height={"22px"} />

        <ButtonCustom
          onClick={_openEditModal}
          className={"primary-alt"}
          height={"36px"}
        >
          <>{translations.editEmployee}</>
          <SizeBox width={"6px"} />
          <i className="uil uil-edit" />
        </ButtonCustom>
      </Modal.Content>
    </ModalRightSideBarTemplate>
  );
};

export default ModalDetailEmployee;
