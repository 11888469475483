import React, { useEffect, useState } from "react";
import { BusinessCompanyDetailType } from "../../../configs/DeclareTypes";
import { useSelector } from "react-redux";
import { businessCompanyDetailSelector } from "../../../selectors/BusinessSelectors";
import CreditCardItem from "./CreditCardItem";
import ModalAddStripeRef from "./ModalAddStripeRef";

const CreditCard = () => {
  const businessCompanyDetail: BusinessCompanyDetailType = useSelector(
    businessCompanyDetailSelector
  );
  const [modalAddStripeOpen, setModalAddStripeOpen] = useState(false);

  const [cardPrimaryId, setCardPrimaryId] = useState<null | number>(null);

  useEffect(() => {
    if (!businessCompanyDetail?.credit_cards?.length) {
      return;
    }

    const primaryItem = businessCompanyDetail?.credit_cards.find(
      ({ is_primary }) => is_primary
    );

    if (primaryItem?.business_credit_card_payment_id) {
      setCardPrimaryId(primaryItem?.business_credit_card_payment_id);
    } else {
      setCardPrimaryId(null);
    }
  }, []);

  return (
    <>
      <div className={"company-detail__title"}>
        <div className="section-white-box_title">Credit Card Information</div>
        <a
          href={"#"}
          className={"fs-12 fw-500"}
          onClick={(e) => {
            e.preventDefault();
            setModalAddStripeOpen(true);
          }}
        >
          Add <i className="uil uil-plus" />
        </a>
      </div>
      <div className={"company-detail__card"}>
        {businessCompanyDetail?.credit_cards.map((item, index) => {
          return (
            <CreditCardItem
              key={"card" + index}
              item={item}
              cardPrimaryId={cardPrimaryId}
              setCardPrimaryId={setCardPrimaryId}
            />
          );
        })}
      </div>
      <div className={"section-white-box_line"} />
      <ModalAddStripeRef
        onClose={() => setModalAddStripeOpen(false)}
        open={modalAddStripeOpen}
      />
    </>
  );
};

export default CreditCard;
