import React from "react";
import SizeBox from "../../../../../../../components/SizeBox";

type Props = {
  isBusinessPayment: boolean;
  companyName: string;
};
const PaymentType = ({ isBusinessPayment, companyName }: Props) => {
  return (
    <>
      <div>
        <b>Type:</b>
        <br />
        {isBusinessPayment ? "Business" : "Personal"}
      </div>
      {isBusinessPayment && (
        <div>
          <SizeBox height={"10px"} />
          <b>Company:</b>
          <br />
          {companyName}
        </div>
      )}
    </>
  );
};

export default PaymentType;
