import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { MATCH_WITH_SPECIFIC_CITIES_RULE } from "../../../../configs/Constants";
import CheckboxCustom from "../../../../../../components/Checkbox";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import Label from "../../../../../../components/Label";
import { autocompleteMarketsService } from "../../../../services/MarketingServices";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import {
  CampaignVoucherRuleType,
  FormikHandleChangeTye,
  FormikSetFieldValueType,
} from "../../../../configs/DeclareTypes";
import { debounce } from "lodash";
import classNames from "classnames";
import { CampaignCreateEditContext } from "../../../../reducers/CampaignCreateEditContext";
import DropdownSearchServer from "../../../../../../components/DropdownSearchServer";

type Props = {
  ruleType: CampaignVoucherRuleType;
  funcGetValue: (keyword: string) => Promise<{ data: any[] }>;
  selectLabel: string;
};

const RuleItem = ({ ruleType, funcGetValue, selectLabel }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const { setFieldValue, values, isSubmitting } = useContext(
    CampaignCreateEditContext
  );

  const index = values.advance_rules.findIndex(
    ({ type }: { type: string }) => type === ruleType
  );

  const [checked, setChecked] = useState<boolean>(
    Boolean(values.advance_rules[index].value)
  );
  const [options, setOptions] = useState<
    { key: number; text: string; id: number }[]
  >([]);

  useEffect(() => {
    (async () => {
      await _initOptions();
    })();
  }, []);

  useEffect(() => {
    if (checked) {
      return;
    }
    setFieldValue(`advance_rules[${index}].value`, null);
  }, [checked]);

  const _initOptions = async (keyword = "") => {
    try {
      const { data } = await funcGetValue(keyword);

      const temp = data.map(({ id, text }: { id: number; text: string }) => {
        return { key: id, text, id };
      });
      setOptions(temp);
    } catch (e) {
      console.log(e);
    }
  };

  const debounce_onChange = debounce(async (keyword: string) => {
    await _initOptions(keyword);
  }, 300);

  return (
    <div className={"campaign-create-rule"}>
      <div className={"left"}>
        <div className={"text"}>{translations.voucherRules[ruleType]}</div>
        <CheckboxCustom
          checked={checked}
          onChange={() => setChecked((prevState) => !prevState)}
          disabled={isSubmitting}
        />
      </div>
      <div
        className={classNames("right", {
          "d-none": !checked,
        })}
      >
        <Label label={selectLabel} fontSize={"14px"} />
        <DropdownSearchServer
          value={values.advance_rules[index].value || []}
          onSearch={(searchQuery) => debounce_onChange(searchQuery)}
          options={options}
          onChange={(value) =>
            setFieldValue(`advance_rules[${index}].value`, value)
          }
        />
      </div>
    </div>
  );
};

export default RuleItem;
