import React from "react";
import ReactFlagsSelect from "react-flags-select";
import { CALLING_CODE } from "../../modules/App/configs/PhonePrefix";
import InputCustom from "../Input";
import Label from "../Label";
import "./styles.scss";
import classNames from "classnames";

type Props = {
  selectedFlag: string;
  onSelectFlag: (code: string) => void;
  phoneValue: string;
  phoneChange: (value: string) => void;
  touched: any;
  error: any;
  label: string;
  labelGuide?: string;
  disabled?: boolean;
  required?: boolean;
  inLineUI?: boolean;
};

const InputPhoneFlag = ({
  selectedFlag,
  onSelectFlag,
  phoneValue,
  phoneChange,
  touched,
  error,
  label,
  labelGuide,
  disabled,
  required,
  inLineUI,
}: Props) => {
  const _onChange = (e: any, { value }: { value: string }) => {
    const filterValue = value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*?)\..*/g, "$1");
    phoneChange(filterValue);
  };

  return (
    <div
      className={classNames("input-phone-flag", {
        "in-line-ui": inLineUI,
      })}
    >
      <Label
        fontSize={"12px"}
        label={label}
        className={"sm"}
        labelGuide={labelGuide}
        required={required}
      />
      <div className={"d-flex"}>
        <ReactFlagsSelect
          searchable
          selectedSize={13}
          selected={selectedFlag}
          onSelect={onSelectFlag}
          showSelectedLabel={false}
          customLabels={CALLING_CODE}
          className={"select-calling-code"}
          disabled={disabled}
        />
        <InputCustom
          value={phoneValue}
          onChange={_onChange}
          touched={touched}
          error={error}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default InputPhoneFlag;
