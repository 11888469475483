import React, { useContext, useEffect } from "react";
import DropdownCustom from "../../../../../components/DropdownCustom";
import { LocalizationContext } from "../../../../../locales/Translation";
import SizeBox from "../../../../../components/SizeBox";
import { dashboardFilterDateTypeSelector } from "../../../selectors/DashboardSelectors";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardFilterDateAction,
  dashboardFilterDateRangeAction,
} from "../../../actions/DashboardActions";
import {
  FILTER_DATE_ALL_TIME,
  FILTER_DATE_CUSTOM,
  FILTER_DATE_LAST_14_DAYS,
  FILTER_DATE_LAST_30_DAYS,
  FILTER_DATE_LAST_7_DAYS,
  FILTER_DATE_LAST_90_DAYS,
  FILTER_DATE_LAST_MONTH,
  FILTER_DATE_LAST_WEEK,
  FILTER_DATE_THIS_MONTH,
  FILTER_DATE_THIS_WEEK,
  FILTER_DATE_TODAY,
  FILTER_DATE_YESTERDAY,
} from "../../../configs/Constants";
import moment from "moment-timezone";
import {
  dashboardCheckUsageService,
  onChangeFilterDateTypeService,
} from "../../../services/DashboardServices";
import { DASHBOARD_FILTER_TYPE } from "../../../../../configs/Constants";
import DateRangePickerV2 from "../../../../../components/DateRangePickerV2";
import { userCompanyChooseSelector } from "../../../../User/selectors/UserSelectors";
import { myProfileSelector } from "../../../../App/selectors/AppSelectors";
import { ROLE_DESKIMO_ADMIN_ID } from "../../../../User/configs/Constants";
import { TIMEZONE_SINGAPORE } from "../../../../App/configs/Constants";

const Filter = ({ className }: any) => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const dashboardFilterDateType = useSelector(dashboardFilterDateTypeSelector);
  const userCompanyChoose = useSelector(userCompanyChooseSelector);
  const userProfile = useSelector(myProfileSelector);

  useEffect(() => {
    (async () => {
      let filterType = sessionStorage.getItem(DASHBOARD_FILTER_TYPE);

      if (filterType) {
        _onChangeDateType(filterType);
        return;
      }

      if (userProfile?.role_id === ROLE_DESKIMO_ADMIN_ID) {
        filterType = FILTER_DATE_TODAY;
      } else {
        let payload = undefined;

        if (userCompanyChoose?.company_id) {
          payload = { company_id: userCompanyChoose?.company_id };
        }

        const { data } = await dashboardCheckUsageService(payload);
        if (data?.has_usage) {
          filterType = FILTER_DATE_LAST_30_DAYS;
        } else {
          filterType = FILTER_DATE_ALL_TIME;
        }
      }
      _onChangeDateType(filterType);
    })();
  }, []);

  const filterOptions = [
    {
      key: FILTER_DATE_TODAY,
      value: FILTER_DATE_TODAY,
      text: translations.dateFilter.today,
    },
    {
      key: FILTER_DATE_YESTERDAY,
      value: FILTER_DATE_YESTERDAY,
      text: translations.dateFilter.yesterday,
    },
    {
      key: FILTER_DATE_THIS_WEEK,
      value: FILTER_DATE_THIS_WEEK,
      text: translations.dateFilter.thisWeek,
    },
    {
      key: FILTER_DATE_THIS_MONTH,
      value: FILTER_DATE_THIS_MONTH,
      text: translations.dateFilter.thisMonth,
    },
    {
      key: FILTER_DATE_LAST_WEEK,
      value: FILTER_DATE_LAST_WEEK,
      text: translations.dateFilter.lastWeek,
    },
    {
      key: FILTER_DATE_LAST_MONTH,
      value: FILTER_DATE_LAST_MONTH,
      text: translations.dateFilter.lastMonth,
    },
    {
      key: FILTER_DATE_LAST_7_DAYS,
      value: FILTER_DATE_LAST_7_DAYS,
      text: translations.dateFilter.last7Days,
    },
    {
      key: FILTER_DATE_LAST_14_DAYS,
      value: FILTER_DATE_LAST_14_DAYS,
      text: translations.dateFilter.last14Days,
    },
    {
      key: FILTER_DATE_LAST_30_DAYS,
      value: FILTER_DATE_LAST_30_DAYS,
      text: translations.dateFilter.last30Days,
    },
    {
      key: FILTER_DATE_LAST_90_DAYS,
      value: FILTER_DATE_LAST_90_DAYS,
      text: translations.dateFilter.last90Days,
    },
    {
      key: FILTER_DATE_ALL_TIME,
      value: FILTER_DATE_ALL_TIME,
      text: translations.dateFilter.allTime,
    },
    {
      key: FILTER_DATE_CUSTOM,
      value: FILTER_DATE_CUSTOM,
      text: translations.dateFilter.custom,
    },
  ];

  const _onChangeDateType = (value: any) => {
    if (!value) {
      return;
    }

    const dateRange = onChangeFilterDateTypeService(
      value,
      userProfile?.role_id === ROLE_DESKIMO_ADMIN_ID
        ? TIMEZONE_SINGAPORE
        : undefined
    );

    dispatch(dashboardFilterDateRangeAction(dateRange));

    const result = String(value);
    dispatch(dashboardFilterDateAction(result));
    sessionStorage.setItem(DASHBOARD_FILTER_TYPE, result);
  };

  return (
    <div className={"dashboard_filter " + (className ? className : "")}>
      <DropdownCustom
        squareUI
        scrolling
        options={filterOptions}
        onChange={(e, { value }) => _onChangeDateType(value)}
        value={dashboardFilterDateType}
      />
      {dashboardFilterDateType === FILTER_DATE_CUSTOM ? (
        <>
          <SizeBox width={"12px"} />
          <DateRangePickerV2
            format={"DD/MM/YYYY"}
            defaultValue={[
              moment(new Date(new Date().setDate(new Date().getDate() - 30))),
              moment(new Date()),
            ]}
            onChange={(value: any) => {
              if (!value) {
                return;
              }
              let dateRange;
              if (
                userProfile?.role_id === ROLE_DESKIMO_ADMIN_ID
                  ? (dateRange = {
                      start_time: moment(value[0])
                        .tz(TIMEZONE_SINGAPORE, true)
                        .startOf("day")
                        .utc()
                        .unix(),
                      end_time: moment(value[1])
                        .tz(TIMEZONE_SINGAPORE, true)
                        .endOf("day")
                        .utc()
                        .unix(),
                      timezone: TIMEZONE_SINGAPORE,
                    })
                  : (dateRange = {
                      start_time: moment(value[0]).startOf("day").utc().unix(),
                      end_time: moment(value[1]).endOf("day").utc().unix(),
                      timezone: moment.tz.guess(),
                    })
              )
                dispatch(dashboardFilterDateRangeAction(dateRange));
            }}
          />
        </>
      ) : null}
    </div>
  );
};

export default Filter;
