import React from "react";
import { useSelector } from "react-redux";
import { businessCompanyDetailSelector } from "../../../selectors/BusinessSelectors";
import { BusinessCompanyDetailType } from "../../../configs/DeclareTypes";
import Information from "./Information";
import Label from "../../../../../components/Label";
import CustomBadge from "../../../../../components/Badge";
import SizeBox from "../../../../../components/SizeBox";
const BusinessPaymentMethodConfiguration = () => {
  const businessCompanyDetail: BusinessCompanyDetailType = useSelector(
    businessCompanyDetailSelector
  );
  return (
    <>
      <div className={"company-detail__title"}>
        <div className="section-white-box_title">
          Business Payment Method Configuration
        </div>
      </div>
      <div className="section-white-box-column">
        <div>
          <Information
            label={"Currency to be used for transactions"}
            value={
              businessCompanyDetail?.payment_method?.current_company_method
                ?.name || "N/A"
            }
          />
          <Information
            label={"Payment Method"}
            value={
              businessCompanyDetail?.payment_method?.current_business_method
                ?.name || "N/A"
            }
          />
          <Information
            label={"Disable purchase credit package"}
            value={businessCompanyDetail?.can_buy_credit ? "No" : "Yes"}
          />
        </div>
      </div>
      <div className={"section-white-box_line"} />
      {businessCompanyDetail?.property_restriction &&
      businessCompanyDetail?.property_restriction?.length > 0 ? (
        <>
          <div className={"company-detail__title"}>
            <div className="section-white-box_title">Excluded workspaces</div>
          </div>
          <div>
            <div className={"d-flex gap-8 flex-wrap"}>
              {businessCompanyDetail?.property_restriction?.map(
                (property: any, index: number) => (
                  <CustomBadge key={index} text={property?.name} />
                )
              )}
            </div>
          </div>
          <div className={"section-white-box_line"} />
        </>
      ) : null}
    </>
  );
};

export default BusinessPaymentMethodConfiguration;
