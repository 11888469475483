import React from "react";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import DropdownWithCheckboxMenuWithTotal from "../../../../../components/DropdownWithCheckboxMenuWithTotal";

type Props = {
  values: string[];
  onChange: any;
  isSubmitting?: boolean;
};
const FilterStatus = ({ values, onChange, isSubmitting }: Props) => {
  const options = [
    { id: "active", name: "Active" },
    { id: "inactive", name: "Inactive" },
    { id: "healthy", name: "Healthy" },
    { id: "unhealthy", name: "Unhealthy" },
  ];

  const _onChange = (event: any, { value }: { value: string }) => {
    let temp: string[] = [...values];
    const index = temp.findIndex((name) => name === value);

    if (index !== -1 && temp.length < 2) {
      return;
    }

    if (index === -1) {
      temp.push(value);
    } else {
      temp = temp.filter((code) => code !== value);
    }

    onChange(temp);
  };

  return (
    <DropdownWithCheckboxMenuWithTotal
      disabled={isSubmitting}
      values={values}
      listCheckBox={options}
      label={"Status Filter"}
      onChange={_onChange}
      icon={
        <img
          alt={"Deskimo"}
          src={PUBLIC_URL + "/assets/icons/icon-filter.svg"}
        />
      }
    />
  );
};

export default FilterStatus;
