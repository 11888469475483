import { createContext } from "react";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import {
  FormikHandleChangeType,
  FormikSetFieldValueType,
} from "../configs/DeclareTypes";
import { DropdownItemProps } from "semantic-ui-react";

type Props = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
  handleChange: FormikHandleChangeType;
  setFieldValue: FormikSetFieldValueType;
  currencyOptions: DropdownItemProps[];
  timezoneOptions: DropdownItemProps[];
  phonePrefixOptions: DropdownItemProps[];
};
export const ConfigMarketCreateEditContext = createContext<Props>({
  values: {},
  errors: {},
  touched: {},
  isSubmitting: false,
  handleChange: () => null,
  setFieldValue: () => null,
  currencyOptions: [],
  timezoneOptions: [{}],
  phonePrefixOptions: [],
});
