import React from "react";
import { BulletList } from "react-content-loader";

const PackagePlaceholder = () => {
  return (
    <div className={"plan-placeholder"}>
      {window.screen.width < 768 ? (
        <PackagePlaceholderItem />
      ) : (
        <>
          <PackagePlaceholderItem />
          <PackagePlaceholderItem />
          <PackagePlaceholderItem />
        </>
      )}
    </div>
  );
};

const PackagePlaceholderItem = () => {
  return (
    <div className={"plan plan-placeholder-item"}>
      <BulletList />
    </div>
  );
};

export default PackagePlaceholder;
