import { Modal } from "semantic-ui-react";
import React from "react";
import "./style.scss";
import classNames from "classnames";

type Props = {
  children: JSX.Element | JSX.Element[];
  trigger?: JSX.Element | JSX.Element[] | null;
  open: boolean;
  onClose: any;
  modalClassName?: string;
  size?: string;
  [x: string]: any;
};

const ModalCenterTemplate = ({
  children,
  trigger = null,
  open,
  onClose,
  modalClassName = "",
  size,
  ...props
}: Props) => {
  return (
    <Modal
      onClose={onClose}
      open={open}
      trigger={trigger}
      className={classNames("modal-center-template", modalClassName, size)}
      {...props}
    >
      {children}
    </Modal>
  );
};

export default ModalCenterTemplate;
