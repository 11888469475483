import {
  USER_COMPANY_ACTION,
  USER_COMPANY_CHOOSE_ACTION,
  USER_COMPANY_CHOOSE_UPDATE_CREDIT_ACTION,
} from "../configs/Constants";
import { UserCompanyType } from "../configs/DeclareTypes";

export const useCompanyAction = (data: object[]) => {
  return {
    type: USER_COMPANY_ACTION,
    payload: data,
  };
};

export const useCompanyChooseAction = (data: UserCompanyType | object) => {
  return {
    type: USER_COMPANY_CHOOSE_ACTION,
    payload: data,
  };
};

export const useCompanyChooseUpdateCreditAction = () => {
  return {
    type: USER_COMPANY_CHOOSE_UPDATE_CREDIT_ACTION,
  };
};
