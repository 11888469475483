import React, { useContext } from "react";
import ListCard from "../Components/ListCard";
import SizeBox from "../../../../components/SizeBox";
import { useDispatch, useSelector } from "react-redux";
import { modalPrivateOfficeAction } from "../../actions/BookActions";
import { LocalizationContext } from "../../../../locales/Translation";
import {
  privateOfficeListSelector,
  propertyFilterKeywordSelector,
} from "../../selectors/BookSelectors";
import { CARD_TYPE_PRIVATE_OFFICE } from "../../configs/Constants";
const PrivateOfficeList = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch: any = useDispatch();
  const privateOfficeList = useSelector(privateOfficeListSelector);
  const propertyFilterKeyword = useSelector(propertyFilterKeywordSelector);

  return (
    <div>
      {privateOfficeList
        .filter(
          ({ name, property_name, address }: any) =>
            name.toLowerCase().includes(propertyFilterKeyword.toLowerCase()) ||
            property_name
              .toLowerCase()
              .includes(propertyFilterKeyword.toLowerCase()) ||
            address.toLowerCase().includes(propertyFilterKeyword.toLowerCase())
        )
        .map(
          (
            {
              name,
              property_name,
              formatted_daily_rate,
              address,
              thumbnail,
              star,
              reviewer_count,
              open_time,
              private_office_id,
              currency,
              slug,
              timezone,
              capacity,
              formatted_original_daily_rate,
              original_monthly_rate,
              formatted_original_monthly_rate,
              formatted_monthly_rate,
              display_new_label,
            }: any,
            index: number
          ) => {
            return (
              <ListCard
                cardType={CARD_TYPE_PRIVATE_OFFICE}
                capacity={capacity}
                key={index}
                id={private_office_id}
                name={name}
                address={address}
                goToDetailLink={slug}
                thumbnailUrl={thumbnail}
                price={formatted_daily_rate}
                unit={"Month"}
                openTime={open_time}
                rating={`${star} (${reviewer_count})`}
                buttonClass={"outline-primary"}
                buttonText={"Inquire"}
                originalMonthlyRate={original_monthly_rate}
                formattedOriginalMonthlyRate={formatted_original_monthly_rate}
                formattedMonthlyRate={formatted_monthly_rate}
                originalDailyRate={formatted_original_daily_rate}
                bookCallback={() => {
                  dispatch(
                    modalPrivateOfficeAction({
                      isOpen: true,
                      data: {
                        privateOfficeId: private_office_id,
                        currency,
                        timezone,
                        capacity,
                      },
                    })
                  );
                }}
                newBadge={display_new_label}
              />
            );
          }
        )}
      <SizeBox height={"14px"} />
      <div className={"book-inquire-bottom"}>
        {translations.inquireNotFound}
      </div>
      <SizeBox height={"8px"} />
      <div className={"book-inquire-bottom"}>
        <a
          onClick={() =>
            dispatch(
              modalPrivateOfficeAction({
                isOpen: true,
              })
            )
          }
        >
          {translations.SendAnInquiry}
        </a>{" "}
        {translations.andWeWillFindYourIdealOffice}
      </div>
    </div>
  );
};

export default PrivateOfficeList;
