import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { teamMemberListAction } from "../actions/PartnerTeamActions";
import { getPartnerTeamMember } from "../services/PartnerTeamService";
import { store } from "../../App/configs/Store";

export const teamMemberListThunk = () => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const teamMemberFilter =
      store.getState().PartnerTeamReducer.teamMemberFilter;
    const { data } = await getPartnerTeamMember(teamMemberFilter);

    if (Array.isArray(data)) {
      dispatch<any>(teamMemberListAction(null));
    } else {
      dispatch<any>(teamMemberListAction(data));
    }
  };
};
