import React from "react";

type Props = {
  label: string;
  value: string | number;
};

const Information = ({ label, value }: Props) => {
  return (
    <div className={"company-detail__information"}>
      <span>{label}</span>
      <span>{value}</span>
    </div>
  );
};

export default Information;
