import { useEffect, useState } from "react";
import { handleScannerService } from "../../services/DashboardServices";
import { useDispatch } from "react-redux";
import { CURRENT_PROPERTY_ID } from "../../../../configs/Constants";
import { toastError, toastSuccess } from "../../../../components/Toast";
import { useLocation } from "react-router-dom";
import { DASHBOARD_PROPERTY_LINK } from "../../../App/configs/ListRoutes";
import { dashboardDeskUsageThunk } from "../../thunks/DashboardThunks";
import { PROPERTY } from "../../configs/Constants";
import { usePermissionCheck } from "../../../../utils/custom-hooks";
import { UI_ACCESS_TRANSACTION_BY_FILTER_PER } from "../../../../utils/PermissionConstant";
import LoaderCustom from "../../../../components/Loader";

const QRReader = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const propertyContext = usePermissionCheck(
    UI_ACCESS_TRANSACTION_BY_FILTER_PER
  );

  const [loading, setLoading] = useState(false);

  let barcode = "";
  let interval: any;

  useEffect(() => {
    document.addEventListener("keydown", _handleKeyDown);

    return () => {
      document.addEventListener("keydown", _handleKeyDown);
    };
  }, []);

  const _handleKeyDown = (evt: KeyboardEvent) => {
    // evt.preventDefault();

    if (interval) clearInterval(interval);
    if (evt.code == "Enter") {
      if (barcode) handleBarcode(barcode);
      barcode = "";
      return;
    }
    if (evt.key != "Shift") barcode += evt.key;
    interval = setInterval(() => (barcode = ""), 20);
  };

  const handleBarcode = async (scanned_barcode: string) => {
    try {
      const currentPropertyId = localStorage.getItem(CURRENT_PROPERTY_ID);

      if (!currentPropertyId || location.pathname !== DASHBOARD_PROPERTY_LINK) {
        return;
      }

      setLoading(true);
      const payload = {
        token: scanned_barcode,
        property_id: currentPropertyId,
      };
      await handleScannerService(payload);
      dispatch<any>(dashboardDeskUsageThunk(PROPERTY, propertyContext));
      toastSuccess("Successfully");
    } catch (e: any) {
      toastError(e?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoaderCustom fullScreen />;
  }

  return null;
};

export default QRReader;
