import { Formik } from "formik";
import * as yup from "yup";
import ButtonCustom from "../../../../components/Button";
import InputCustom from "../../../../components/Input";
import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import { listVoucherForBookingSelector } from "../../selectors/AppSelectors";
import { getListVoucherForBookingThunk } from "../../thunks/AppThunk";
import {
  addVoucherForBookingService,
  redeemVoucherForBookingService,
  removeVoucherForBookingService,
} from "../../services/AppServices";
import Label from "../../../../components/Label";
import "./style.scss";
import { toastError } from "../../../../components/Toast";

const BoxAddVoucherForBooking = ({
  isRefresh,
  setIsRefresh,
  isLabelTitle = false,
}: any) => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  // const BoxAddVoucherForBooking = useSelector(BoxAddVoucherForBookingSelector);
  const voucherList = useSelector(listVoucherForBookingSelector);
  const [isRemoving, setIsRemoving] = useState(false);

  const _handleSave = async (values: any, { resetForm }: any) => {
    try {
      const payload = {
        voucher_code: values.voucher_code,
      };
      await addVoucherForBookingService(payload);
      await redeemVoucherForBookingService(payload);
      await _getListVoucherForBooking();
      if (typeof setIsRefresh !== "undefined") {
        setIsRefresh(!isRefresh);
      }
    } catch (error: any) {
      console.log("error: ", error);
      toastError(error?.status?.message);
    } finally {
      resetForm();
    }
  };

  const _handleRemoveVoucher = async (userVoucherId: any) => {
    setIsRemoving(true);
    const payload = {
      user_voucher_id: userVoucherId,
    };
    await removeVoucherForBookingService(payload);
    await _getListVoucherForBooking();
    if (typeof setIsRefresh !== "undefined") {
      setIsRefresh(!isRefresh);
    }
    setIsRemoving(false);
  };

  useEffect(() => {
    _getListVoucherForBooking();
  }, []);

  const _getListVoucherForBooking = async () => {
    await dispatch<any>(getListVoucherForBookingThunk());
  };

  const isUsingVoucher = () =>
    voucherList["using"] && voucherList["using"].length > 0;

  return (
    <Formik
      initialValues={{
        voucher_code: "",
      }}
      validationSchema={yup.object().shape({
        voucher_code: yup.string().required().label("Voucher"),
      })}
      onSubmit={_handleSave}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <>
          <form
            className={
              "form-layout form-apply-voucher gap-0 " +
              (isLabelTitle ? " label-title " : "")
              // (isUsingVoucher() ? " gap-0 " : "")
            }
          >
            <div className={"d-flex flex-column voucher-input-wrapper "}>
              <Label label={"Apply voucher"} />
              {isUsingVoucher() ? null : (
                <div className="d-flex align-items-start gap-12">
                  <InputCustom
                    containerClass={"voucher-input mb-0"}
                    value={values?.voucher_code}
                    onChange={handleChange}
                    name={"voucher_code"}
                  />
                  <ButtonCustom
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    height={"40px"}
                    onClick={handleSubmit}
                    className={"primary apply-cta"}
                  >
                    Apply
                  </ButtonCustom>
                </div>
              )}
            </div>
            <div className={"voucher-list"}>
              {isUsingVoucher()
                ? voucherList["using"].map((voucher: any, index: number) => (
                    <div className={"voucher-item"} key={index}>
                      <div className="voucher-meta">
                        <div className="voucher-title">{voucher?.code}</div>
                        <div className="voucher-text">{voucher?.name}</div>
                      </div>
                      <div className="voucher-action">
                        <ButtonCustom
                          height={"36px"}
                          disabled={isRemoving}
                          type={"button"}
                          onClick={() =>
                            _handleRemoveVoucher(voucher?.user_voucher_id)
                          }
                          className={"voucher-remove-action"}
                        >
                          <i className="uil uil-times"></i>
                        </ButtonCustom>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </form>
        </>
      )}
    </Formik>
  );
};

export default BoxAddVoucherForBooking;
