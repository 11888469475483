import { PUBLIC_URL } from "../../App/configs/Constants";

export const GOOGLE_MAP_ZOOM_DEFAULT = 10;

export const GOOGLE_MAP_OPTIONS = {
  styles: [
    {
      featureType: "poi",
      stylers: [{ visibility: "off" }],
    },
  ],
};

export const GOOGLE_MAP_CONTAINER_STYLE = {
  width: "100%",
  height: "100%",
};

export const GOOGLE_MAP_CLUSTER_OPTIONS = {
  imagePath: PUBLIC_URL + "/assets/icons/icon-cluster",
  imageExtension: "png",
};

export const GOOGLE_MAP_CLUSTER_STYLES = [
  {
    url: PUBLIC_URL + "/assets/icons/cluster-same1.png",
    width: 38,
    height: 48,
    textColor: "#fff",
    textSize: 14,
    fontWeight: "700",
  },
  {
    url: PUBLIC_URL + "/assets/icons/cluster-same1.png",
    width: 43,
    height: 53,
    textColor: "#fff",
    textSize: 14,
    fontWeight: "700",
  },
  {
    url: PUBLIC_URL + "/assets/icons/icon-cluster1.png",
    width: 50,
    height: 50,
    textColor: "#fff",
    textSize: 14,
    fontWeight: "700",
  },
  {
    url: PUBLIC_URL + "/assets/icons/icon-cluster1.png",
    width: 60,
    height: 60,
    textColor: "#fff",
    textSize: 14,
    fontWeight: "700",
  },
  {
    url: PUBLIC_URL + "/assets/icons/pin/cluster-click1.png",
    width: 10,
    height: 10,
    textSize: 0,
  },
];

export const DISTANCE_MAP_ZOOM = {
  0: 5760000,
  1: 6500000,
  2: 4000000,
  3: 2000000,
  4: 1000000,
  5: 500000,
  6: 270000,
  7: 130000,
  8: 64000,
  9: 32000,
  10: 16000,
  11: 8000,
  12: 4000,
  13: 2100,
  14: 1100,
  15: 520,
  16: 260,
  17: 140,
  18: 70,
  19: 35,
  20: 20,
  21: 10,
  22: 5,
};

export const DISTANCE_CLOSE_MAP_ZOOM = {
  0: 20480000,
  1: 90000000,
  2: 6000000,
  3: 3200000,
  4: 1600000,
  5: 800000,
  6: 420000,
  7: 220000,
  8: 100000,
  9: 50000,
  10: 25000,
  11: 13000,
  12: 6800,
  13: 3500,
  14: 1800,
  15: 860,
  16: 450,
  17: 230,
  18: 120,
  19: 60,
  20: 30,
  21: 16,
  22: 8,
};

export const CIRCLE_RADIUS_ZOOM = {
  0: 20480000,
  1: 8000000,
  2: 5000000,
  3: 2600000,
  4: 1300000,
  5: 650000,
  6: 350000,
  7: 170000,
  8: 82000,
  9: 42000,
  10: 21000,
  11: 10500,
  12: 5200,
  13: 2700,
  14: 1400,
  15: 680,
  16: 340,
  17: 180,
  18: 90,
  19: 44,
  20: 25,
  21: 12.5,
  22: 6.2,
};
