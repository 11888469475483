import React, { useContext, useEffect, useState } from "react";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import OfficeLeasesContext from "../../Context";

const DropdownCompany = () => {
  const { businessCompanyOptions, setFilters, filters } =
    useContext(OfficeLeasesContext);
  const [options, setOptions] = useState<any>([
    { key: 0, text: "All Companies", value: 0 },
  ]);

  useEffect(() => {
    setOptions((prevState: any) => [...prevState, ...businessCompanyOptions]);
  }, [businessCompanyOptions]);

  return (
    <DropdownCustom
      selectUI
      options={options}
      search
      value={filters?.company_id}
      onChange={(e, { value }) =>
        setFilters((prevState: any) => ({ ...prevState, company_id: value }))
      }
    />
  );
};

export default DropdownCompany;
