import clientRequestService from "../../../services/ClientRequestService";
import { API_GET_COMPANY_BY_USER } from "../../../configs/Endpoints";
import {
  API_GET_FAKING_COMEBACK,
  API_GET_FAKING_LOGIN,
  API_LOG_OUT,
  API_UPDATE_UPDATE_NEW_USER,
} from "../configs/Endpoints";
import {
  ACCESS_TOKEN_CACHE,
  FAKING_CODE_CACHE,
  METHOD_POST,
} from "../../../configs/Constants";
import { refreshSessionAction } from "../../Authentication/actions/AuthenticationActions";
import { store } from "../../App/configs/Store";
import { loginService } from "../../Authentication/services/AuthenticationServices";
import { isGlobalIndicatorAction } from "../../App/actions/AppActions";
import {
  LOGIN_AS_COMPANY_ID,
  LOGIN_AS_SOURCE,
} from "../../App/configs/Constants";

export const getCompanyByUserService = async () => {
  return await clientRequestService({
    url: API_GET_COMPANY_BY_USER,
  });
};

export const logoutService = async () => {
  localStorage.removeItem(FAKING_CODE_CACHE);
  return await clientRequestService({
    method: METHOD_POST,
    url: API_LOG_OUT + "?from_client=WEB",
  });
};

export const getLoginFakingCodeService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: API_GET_FAKING_LOGIN,
    data,
  });
};

export const getLoginComeBackCodeService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: API_GET_FAKING_COMEBACK,
    data,
  });
};

export const handleLogInOnBehalf = async (
  userId?: number | null,
  navigate?: any
) => {
  const dispatch = store.dispatch;
  let faking_code;

  if (userId) {
    const { data } = await getLoginFakingCodeService({ user_id: userId });
    faking_code = data?.faking_code;
    localStorage.setItem(FAKING_CODE_CACHE, data?.faking_code);
  } else {
    const fakingCode = localStorage.getItem(FAKING_CODE_CACHE);
    const { data } = await getLoginComeBackCodeService({
      faking_code: fakingCode,
    });
    faking_code = data?.faking_code;
    localStorage.removeItem(FAKING_CODE_CACHE);
  }

  const { access_token } = await loginService({
    token: faking_code,
  });
  localStorage.setItem(ACCESS_TOKEN_CACHE, access_token);
  dispatch(refreshSessionAction());

  if (!userId) {
    const loginAsSource = localStorage.getItem(LOGIN_AS_SOURCE);
    navigate(loginAsSource);
    window.location.reload();
  }
};

export const updateNewUser = () => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_UPDATE_UPDATE_NEW_USER,
  });
};

export const onLogInAsAccount = async (
  userId: number,
  source: string,
  companyId?: number
) => {
  const dispatch = store.dispatch;
  try {
    dispatch(isGlobalIndicatorAction(true));
    localStorage.setItem(LOGIN_AS_SOURCE, source);

    if (companyId) {
      localStorage.setItem(LOGIN_AS_COMPANY_ID, companyId.toString());
    }

    await handleLogInOnBehalf(userId);
  } catch (e) {
    console.log(e);
  } finally {
    dispatch(isGlobalIndicatorAction(false));
  }
};

export const onLoginAsWMSUser = (wmsLoginCode: string) => {
  const url =
    process.env.REACT_APP_WORK_PLACE_DOMAIN +
    "/login-as-wms-user/" +
    wmsLoginCode;
  window.open(url);
};
