import React, { useState, useEffect, useContext } from "react";
import { modalRewardsAction } from "../../../actions/BookActions";
import classNames from "classnames";
import { Modal } from "semantic-ui-react";
import ModalCenterTemplate from "../../../../App/views/ModalCenterTemplate";
import { useDispatch, useSelector } from "react-redux";
import {
  modalRewardsSelector,
  rewardListSelector,
} from "../../../selectors/BookSelectors";
import { toastError } from "../../../../../components/Toast";
import { LocalizationContext } from "../../../../../locales/Translation";
import { rewardListThunk } from "../../../thunks/BookThunks";
import ButtonCustom from "../../../../../components/Button";

const ModalRewards = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const modalRewards = useSelector(modalRewardsSelector);
  const rewardList = useSelector(rewardListSelector);

  const _onClose = () => {
    dispatch(modalRewardsAction({ open: false }));
  };

  return (
    <ModalCenterTemplate
      trigger={null}
      onClose={_onClose}
      modalClassName={classNames("modal-center-v2")}
      open={modalRewards?.open}
      size={"tiny"}
      style={{ padding: 0, maxWidth: "min(100%, 400px)" }}
    >
      <Modal.Content>
        <div
          style={{
            display: "flex",
            flexFlow: "column nowrap",
            gap: 16,
            padding: "8px 0",
            width: "100%",
          }}
        >
          <div style={{ textAlign: "center", fontSize: 20, fontWeight: "500" }}>
            Rewards 🌟
          </div>
          <div style={{ display: "flex", flexFlow: "column nowrap", gap: 8 }}>
            {rewardList && rewardList?.length > 0
              ? rewardList.map((item: any, index: number) => {
                  return (
                    <div key={index} style={{ display: "flex", gap: 4 }}>
                      <div>🌟</div>
                      <div
                        style={{
                          display: "flex",
                          flexFlow: "column nowrap",
                          gap: 4,
                        }}
                      >
                        <div
                          style={{
                            fontSize: 16,
                            color: "#13100C",
                            fontWeight: "500",
                          }}
                        >
                          {item?.name}
                        </div>
                        <div style={{ fontSize: 14, color: "#13100C" }}>
                          {item?.description}
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
          <ButtonCustom onClick={_onClose} className={"yellow"}>
            Close
          </ButtonCustom>
        </div>
      </Modal.Content>
    </ModalCenterTemplate>
  );
};

export default ModalRewards;
