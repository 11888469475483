import React, { useContext } from "react";
import Label from "../../../../../components/Label";
import { LocalizationContext } from "../../../../../locales/Translation";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import InputCustom from "../../../../../components/Input";

type Props = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
  handleChange: any;
};

const BillingDetail = ({
  values,
  errors,
  touched,
  isSubmitting,
  handleChange,
}: Props) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <>
      <Label
        label={translations.billingDetails}
        fontSize={"24px"}
        className={"section-title"}
      />
      <div className={"detail_divide"}>
        <div className={"left_section"}>
          <InputCustom
            labelClassName={"label-xl"}
            label={translations.companyNumber}
            value={values.billing_company_number}
            error={errors.billing_company_number}
            touched={touched.billing_company_number}
            name={"billing_company_number"}
            onChange={handleChange}
            disabled={isSubmitting}
          />

          <InputCustom
            labelClassName={"label-xl"}
            label={translations.companyRepresentative}
            value={values.billing_company_representative}
            error={errors.billing_company_representative}
            touched={touched.billing_company_representative}
            name={"billing_company_representative"}
            onChange={handleChange}
            disabled={isSubmitting}
          />
        </div>
        <div className={"right_section"}>
          <InputCustom
            labelClassName={"label-xl"}
            label={translations.companyName}
            value={values.billing_company_name}
            error={errors.billing_company_name}
            touched={touched.billing_company_name}
            name={"billing_company_name"}
            onChange={handleChange}
            disabled={isSubmitting}
          />

          <InputCustom
            labelClassName={"label-xl"}
            label={"Billing Address"}
            value={values.billing_company_address}
            error={errors.billing_company_address}
            touched={touched.billing_company_address}
            name={"billing_company_address"}
            onChange={handleChange}
            disabled={isSubmitting}
          />
        </div>
      </div>
    </>
  );
};

export default BillingDetail;
