import {
  ACCOUNTING_ACTION,
  CONFIG_CREATE_MARKET_PUBLIC_HOLIDAYS,
  CONFIG_DELETE_MARKET_LANDMARK,
  CONFIG_DELETE_MARKET_PUBLIC_HOLIDAYS,
  CONFIG_MARKET_COMMISSION_SHARE_ACTION,
  CONFIG_MARKET_EXCHANGE_RATE_LIST_ACTION,
  CONFIG_MARKET_GENERAL_INFORMATION_ACTION,
  CONFIG_MARKET_LANDMARK_LIST_ACTION,
  CONFIG_MARKET_LIST_ACTION,
  CONFIG_MARKET_PUBLIC_HOLIDAYS_LIST_ACTION,
  CONFIG_TAGS_LIST_ACTION,
  CONFIG_UPDATE_MARKET_GENERAL_INFORMATION_ACTION,
  CONFIG_UPDATE_MARKET_STATUS_ACTION,
  MARKET_TOGGLE_LIST_ACTION,
  PARTNER_CREDENTIAL_INFO_ACTION,
  PARTNER_LIMITATION_ACTION,
  PARTNER_WEBHOOK_LIST_ACTION,
  ROLE_PERMISSION_ACTION,
  // CONFIG_UPDATE_MARKET_STATUS_ACTION,
} from "../configs/Constants";

export const configMarketListAction = (data: object[]) => {
  return {
    type: CONFIG_MARKET_LIST_ACTION,
    payload: data,
  };
};

export const configMarketExchangeRateListAction = (data: object[]) => {
  return {
    type: CONFIG_MARKET_EXCHANGE_RATE_LIST_ACTION,
    payload: data,
  };
};

export const configUpdateMarketStatusAction = (id: number) => {
  return {
    type: CONFIG_UPDATE_MARKET_STATUS_ACTION,
    payload: id,
  };
};

export const configMarketCommissionShareAction = (data: object) => {
  return {
    type: CONFIG_MARKET_COMMISSION_SHARE_ACTION,
    payload: data,
  };
};

export const configMarketGeneralInformationAction = (data: object) => {
  return {
    type: CONFIG_MARKET_GENERAL_INFORMATION_ACTION,
    payload: data,
  };
};

export const configEditMarketGeneralInformationAction = (id: number) => {
  return {
    type: CONFIG_UPDATE_MARKET_GENERAL_INFORMATION_ACTION,
    payload: id,
  };
};

export const configMarketPublicHolidaysListAction = (data: object[]) => {
  return {
    type: CONFIG_MARKET_PUBLIC_HOLIDAYS_LIST_ACTION,
    payload: data,
  };
};

export const deleteMarketPublicHolidaysAction = (id: number) => {
  return {
    type: CONFIG_DELETE_MARKET_PUBLIC_HOLIDAYS,
    payload: id,
  };
};

export const createMarketPublicHolidayAction = (data: any) => {
  return {
    type: CONFIG_CREATE_MARKET_PUBLIC_HOLIDAYS,
    payload: data,
  };
};

export const configMarketLandmarkListAction = (data: object[]) => {
  return {
    type: CONFIG_MARKET_LANDMARK_LIST_ACTION,
    payload: data,
  };
};

export const deleteMarketLandmarkAction = (id: number) => {
  return {
    type: CONFIG_DELETE_MARKET_LANDMARK,
    payload: id,
  };
};

export const configTagsListAction = (data: object) => {
  return {
    type: CONFIG_TAGS_LIST_ACTION,
    payload: data,
  };
};

export const marketToggleListAction = (data: any) => {
  return {
    type: MARKET_TOGGLE_LIST_ACTION,
    payload: data,
  };
};

//Partner
export const partnerCredentialInfoAction = (data: any) => {
  return {
    type: PARTNER_CREDENTIAL_INFO_ACTION,
    payload: data,
  };
};

export const partnerWebhookListAction = (data: any) => {
  return {
    type: PARTNER_WEBHOOK_LIST_ACTION,
    payload: data,
  };
};

export const partnerLimitationAction = (data: any) => {
  return {
    type: PARTNER_LIMITATION_ACTION,
    payload: data,
  };
};

export const getRolePermissionAction = (data: any) => {
  return {
    type: ROLE_PERMISSION_ACTION,
    payload: data,
  };
};

export const getAccountingAction = (data: any) => {
  return {
    type: ACCOUNTING_ACTION,
    payload: data,
  };
};
