import React, { useEffect, useState } from "react";
import TableCustom from "../../../../../../components/Table";
import { useSelector } from "react-redux";
import { onGoingDataSelector } from "../../../../selectors/BookingSelectors";
import moment from "moment";
import { FORMAT_DATE_VARIANT_3 } from "../../../../../App/configs/DateTimeConfigs";
import { getGuestService } from "../../../../services/BookingServices";

const OngoingTab = () => {
  const ongoingData = useSelector(onGoingDataSelector);
  const [tableData, setTableData] = useState<any>([]);
  const [guessData, setGuessData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    decorateOngoingData();
  }, [ongoingData]);

  useEffect(() => {
    if (tableData.length > 0) {
      getGuest(ongoingData?.id);
    }
  }, [tableData]);

  const getGuest = async (ongoingId: any) => {
    if (ongoingId) {
      setLoading(true);
      const response = await getGuestService(ongoingId);
      if (response?.data?.length > 0) {
        const data = response?.data.map((guess: any, index: number) => {
          return {
            code: guess?.code,
            col1: guess.guest_name,
            col2: tableData[0]?.col2,
            col3: guess.duration,
            col4: tableData[0]?.col4,
            col5: (
              <p className={"text-center"}>
                {guess.currency_code} {guess.total_price || 0}
              </p>
            ),
          };
        });
        setGuessData(data);
      }
      setLoading(false);
    }
  };

  const decorateOngoingData = () => {
    if (ongoingData?.id) {
      setTableData([
        {
          code: ongoingData?.code,
          col1: ongoingData.name,
          col2: (
            <div
              className={"table-booking__property-details table-booking__cell"}
            >
              <div className={"table-booking__property-details-item"}>
                <i className="uil uil-building" />
                <span>{ongoingData?.type}</span>
              </div>
              <div className={"table-booking__property-details-item"}>
                <i className="uil uil-map" />
                <span>{ongoingData?.address}</span>
              </div>
            </div>
          ),
          col3: ongoingData.duration,
          col4: moment(parseInt(ongoingData.start_time) * 1000).format(
            FORMAT_DATE_VARIANT_3
          ),
          col5: (
            <p className={"text-center"}>
              {ongoingData.currency_code} {ongoingData.total_price || 0}
            </p>
          ),
        },
      ]);
    }
  };

  const columns = [
    "Transaction ID",
    "Name",
    "Property Details",
    "Duration",
    "Booking Date",
    "Total Fee",
  ];

  return (
    <TableCustom
      columns={columns}
      loading={loading}
      tableClass={"table-booking"}
      tableData={tableData.length > 0 ? [...tableData, ...guessData] : []}
    />
  );
};

export default OngoingTab;
