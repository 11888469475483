import TableCustom from "../../../../../../../../components/Table";
import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../../../../locales/Translation";

type Props = {
  tableData: any;
  columns: any;
  currentPage: number;
  totalItems: number;
  loadingTable: boolean;
  onChangePagination: any;
};

const TransactionTable = ({
  tableData,
  columns,
  currentPage,
  totalItems,
  loadingTable,
  onChangePagination,
}: Props) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <TableCustom
      loading={loadingTable}
      onChangePagination={onChangePagination}
      showTotal={true}
      tableData={tableData}
      columns={columns}
      currentPage={currentPage}
      totalItems={totalItems}
      unit={translations.transactions}
    />
  );
};

export default TransactionTable;
