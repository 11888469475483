import { useDispatch, useSelector } from "react-redux";
import { globalSettingSelector } from "../../../../../App/selectors/AppSelectors";
import React, { useEffect } from "react";
import CheckboxCustom from "../../../../../../components/Checkbox";
import { deskimoPrivateOfficeFiltersTmpSelector } from "../../../../selectors/PropertySelector";
import { deskimoPrivateOfficeFilterTmpAction } from "../../../../actions/PropertyActions";

const SelectMarket = () => {
  const globalSetting = useSelector(globalSettingSelector);
  const dispatch = useDispatch();
  const filtersTmp = useSelector(deskimoPrivateOfficeFiltersTmpSelector);

  useEffect(() => {
    if (globalSetting?.location?.length > 0) {
      const checkAllArr = globalSetting?.location.map(
        (item: any) => item.city_code
      );
      dispatch(
        deskimoPrivateOfficeFilterTmpAction({
          ...filtersTmp,
          filter: {
            ...filtersTmp.filter,
            markets: [...checkAllArr, "0"],
          },
        })
      );
    }
  }, [globalSetting]);

  const handleOnChange = (checked: any, marketCode: any, checkAll: any) => {
    if (checked) {
      const newMarketCodeList = [...filtersTmp.filter.markets, marketCode];

      if (checkAll) {
        const checkAllArr = globalSetting?.location.map(
          (item: any) => item.city_code
        );
        dispatch(
          deskimoPrivateOfficeFilterTmpAction({
            ...filtersTmp,
            filter: {
              ...filtersTmp.filter,
              markets: [...checkAllArr, "0"],
            },
          })
        );
      } else {
        if (newMarketCodeList.length === globalSetting?.location?.length) {
          dispatch(
            deskimoPrivateOfficeFilterTmpAction({
              ...filtersTmp,
              filter: {
                ...filtersTmp.filter,
                markets: [...newMarketCodeList, "0"],
              },
            })
          );
        } else {
          dispatch(
            deskimoPrivateOfficeFilterTmpAction({
              ...filtersTmp,
              filter: {
                ...filtersTmp.filter,
                markets: newMarketCodeList.filter((market) => market !== "0"),
              },
            })
          );
        }
      }
    } else {
      const newMarketCodeList = [...filtersTmp.filter.markets].filter(
        (market) => market !== marketCode && market !== "0"
      );

      if (checkAll) {
        dispatch(
          deskimoPrivateOfficeFilterTmpAction({
            ...filtersTmp,
            filter: { ...filtersTmp.filter, markets: [] },
          })
        );
      } else {
        dispatch(
          deskimoPrivateOfficeFilterTmpAction({
            ...filtersTmp,
            filter: {
              ...filtersTmp.filter,
              markets: newMarketCodeList.filter((market) => market !== "0"),
            },
          })
        );
      }
    }
  };

  if (globalSetting?.location?.length > 0) {
    return (
      <div className={"list-filter-market"}>
        <div className={"filter-market-item"}>
          <CheckboxCustom
            checked={filtersTmp?.filter?.markets?.includes("0")}
            value={"0"}
            onChange={(e, data) =>
              handleOnChange(data.checked, undefined, true)
            }
            reverseText={true}
            label={"All markets"}
          />
        </div>
        {globalSetting?.location?.map((location: any, index: number) => {
          return (
            <div key={index} className={"filter-market-item"}>
              <CheckboxCustom
                checked={
                  filtersTmp?.filter?.markets?.includes("0") ||
                  filtersTmp?.filter?.markets?.includes(location?.city_code)
                }
                value={location?.city_code}
                onChange={(e, data) =>
                  handleOnChange(data.checked, location?.city_code, false)
                }
                reverseText={true}
                label={location?.city_name}
              />
            </div>
          );
        })}
      </div>
    );
  }
  return <>Market</>;
};

export default SelectMarket;
