import React, { useContext } from "react";
import Label from "../../../../../components/Label";
import InputCustom from "../../../../../components/Input";
import { LocalizationContext } from "../../../../../locales/Translation";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import { TextArea } from "semantic-ui-react";
import ReactFlagsSelect from "react-flags-select";
import {
  CALLING_CODE,
  DIALING_CODE,
} from "../../../../App/configs/PhonePrefix";
import DropdownCustom from "../../../../../components/DropdownCustom";
import SizeBox from "../../../../../components/SizeBox";
import DropzoneContract from "./DropzoneContract";
import { PropertyCompanyDetailType } from "../../../configs/DeclareType";
import { useParams } from "react-router-dom";
import ToggleSlider from "../../../../../components/ToggleSlider";
import BoxToggleSlider from "../../../../../components/BoxToggleSlider";

type Props = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
  handleChange: any;
  setFieldValue: any;
  marketList: any[];
  propertyCompany: PropertyCompanyDetailType | null;
};

const Information = ({
  values,
  errors,
  touched,
  isSubmitting,
  handleChange,
  setFieldValue,
  marketList,
  propertyCompany,
}: Props) => {
  const { translations } = useContext(LocalizationContext);
  const { id } = useParams();

  return (
    <>
      <Label
        label={
          id
            ? translations.editPropertyCompany
            : translations.createPropertyCompany
        }
        fontSize={"24px"}
        className={"section-title"}
      />
      <div className={"detail_divide"}>
        <div className={"left_section"}>
          <InputCustom
            labelClassName={"label-xl"}
            label={translations.companyEntityName}
            value={values.company_name}
            error={errors.company_name}
            touched={touched.company_name}
            name={"company_name"}
            onChange={handleChange}
            disabled={isSubmitting}
            labelRequired
          />

          <InputCustom
            labelClassName={"label-xl"}
            label={translations.companyManagerNameAuthorizedSignatory}
            value={values.contact_name}
            error={errors.contact_name}
            touched={touched.contact_name}
            name={"contact_name"}
            onChange={handleChange}
            disabled={isSubmitting}
            labelRequired
          />

          <InputCustom
            labelClassName={"label-xl"}
            label={translations.companyAddress}
            value={values.company_address}
            error={errors.company_address}
            touched={touched.company_address}
            name={"company_address"}
            onChange={handleChange}
            disabled={isSubmitting}
            labelRequired
          />

          <InputCustom
            labelClassName={"label-xl"}
            label={translations.deskimoAccountManagerName}
            value={values.account_manager_name}
            error={errors.account_manager_name}
            touched={touched.account_manager_name}
            name={"account_manager_name"}
            onChange={handleChange}
            disabled={isSubmitting}
            labelGuide={
              "Enter the name of Deskimo’s account manager for this workspace"
            }
          />

          <InputCustom
            labelClassName={"label-xl"}
            label={translations.deskimoAccountManagerPhoneNumber}
            value={values.account_manager_phone}
            error={errors.account_manager_phone}
            touched={touched.account_manager_phone}
            name={"account_manager_phone"}
            onChange={handleChange}
            disabled={isSubmitting}
          />

          <InputCustom
            labelClassName={"label-xl"}
            label={translations.deskimoAccountManagerEmail}
            value={values.account_manager_email}
            error={errors.account_manager_email}
            touched={touched.account_manager_email}
            name={"account_manager_email"}
            onChange={handleChange}
            disabled={isSubmitting}
          />

          <Label className={"label-xl"} label={translations.market} />

          <DropdownCustom
            className={"property_company_detail_dropdown"}
            options={marketList.map((item, index) => {
              return {
                key: index,
                text: item.city_name,
                value: item.city_code,
              };
            })}
            value={values.market_code}
            onChange={(e, { value }) => {
              setFieldValue("market_code", value);
              const marketObj = marketList.find(
                ({ city_code }) => city_code === value
              );
              if (!marketObj) {
                return;
              }
              setFieldValue("currency", marketObj.currency);
            }}
            disabled={!!id}
          />

          <ToggleSlider
            revertLabel={true}
            value={values.has_workspace_integration}
            onChange={(e: any, data: any) => {
              setFieldValue("has_workspace_integration", data.checked);
            }}
            content={
              <Label
                contentClassName={"fw-400 ml-0 mb-0"}
                className={"label-xl ml-0"}
                label={"Enable Integrations"}
                labelGuide={
                  "If this option is enabled the workspace will be able to use API, Webhooks and QR codes to check-in and out"
                }
              />
            }
          />
        </div>

        <div className={"right_section"}>
          <InputCustom
            labelClassName={"label-xl"}
            label={translations.companyBrandName}
            value={values.company_branch_name}
            error={errors.company_branch_name}
            touched={touched.company_branch_name}
            name={"company_branch_name"}
            onChange={handleChange}
            disabled={isSubmitting}
            labelRequired
          />

          <InputCustom
            labelClassName={"label-xl"}
            label={translations.companyManagerEmail}
            value={values.contact_email}
            error={errors.contact_email}
            touched={touched.contact_email}
            name={"contact_email"}
            onChange={handleChange}
            disabled={isSubmitting}
            labelRequired
          />

          <Label className={"label-xl"} label={translations.phoneNumber} />
          <div className={"form-row"}>
            <ReactFlagsSelect
              searchable
              selectedSize={13}
              selected={DIALING_CODE.filter((country) => {
                return (
                  country?.value?.replaceAll("+", "") === values.dialing_code
                );
              })[0]?.key.toUpperCase()}
              onSelect={(code) => {
                setFieldValue(
                  "dialing_code",
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  CALLING_CODE[code].secondary.replaceAll("+", "")
                );
              }}
              showSelectedLabel={false}
              customLabels={CALLING_CODE}
              className={"select-calling-code"}
            />
            <InputCustom
              value={values.contact_phone}
              onChange={handleChange}
              touched={touched.contact_phone}
              error={errors.contact_phone}
              name={"contact_phone"}
            />
          </div>
          <InputCustom
            labelClassName={"label-xl"}
            inputRightIcon={"Minutes"}
            label={translations.editMarket.hotDeskBookDelayTimeLabelTitle}
            value={values.hot_desk_booking_delay_time}
            error={errors.hot_desk_booking_delay_time}
            touched={touched.hot_desk_booking_delay_time}
            name={"hot_desk_booking_delay_time"}
            onChange={handleChange}
            disabled={isSubmitting}
          />
          <InputCustom
            labelClassName={"label-xl"}
            inputRightIcon={"Minutes"}
            label={translations.editMarket.roomBookDelayTimeLabelTitle}
            value={values.meeting_room_booking_delay_time}
            error={errors.meeting_room_booking_delay_time}
            touched={touched.meeting_room_booking_delay_time}
            name={"meeting_room_booking_delay_time"}
            onChange={handleChange}
            disabled={isSubmitting}
          />

          <DropzoneContract
            onChangeUri={(uri: string) => setFieldValue("contact_file", uri)}
            contractFile={propertyCompany?.contract_file}
          />
        </div>
      </div>

      <SizeBox height={"40px"} />
      <div className={"property_company_detail_line"} />
    </>
  );
};

export default Information;
