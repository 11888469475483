import SizeBox from "../../../../../../../components/SizeBox";
import React, { useContext, useState } from "react";
import EditActions from "./EditActions";
import InputCustom from "../../../../../../../components/Input";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { propertyDetailSelector } from "../../../../../selectors/PropertySelector";
import { updatePropertyService } from "../../../../../services/PropertyServices";
import { getPropertyDetailThunk } from "../../../../../thunks/PropertyThunks";
import { toastError } from "../../../../../../../components/Toast";
import { useParams } from "react-router-dom";
import ButtonCustom from "../../../../../../../components/Button";
import { LocalizationContext } from "../../../../../../../locales/Translation";
import { WIFI_ACCESS_TYPE } from "../../../../../configs/Constants";
import * as yup from "yup";
import Label from "../../../../../../../components/Label";
import Radio from "../../../../../../../components/Radio";
import TextArea from "../../../../../../../components/Input/TextArea";

const PropertyWifi = ({ setLoading, loading }: any) => {
  const { translations } = useContext(LocalizationContext);

  const { id } = useParams();
  const propertyDetail = useSelector(propertyDetailSelector);
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);

  const _handleSave = async (values: any) => {
    const payload = {
      wifi: values,
      property_id: id,
    };
    setLoading(true);
    try {
      await updatePropertyService(payload);
      await dispatch<any>(getPropertyDetailThunk(id ? id : ""));
      setEditMode(false);
    } catch (response: any) {
      toastError(response?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={"section"}>
      <SizeBox height={"16px"} />
      <div
        className={
          "d-flex justify-content-between " +
          (editMode ? "" : "align-items-center")
        }
      >
        <div className={"pr-70 w-100"}>
          <div className={"section-title"}>{translations.wifiInfo}</div>
          <SizeBox height={"22px"} />
          {editMode ? (
            <Formik
              initialValues={{
                wifiName: propertyDetail?.wifi?.wifiName || "",
                wifiPass: propertyDetail?.wifi?.wifiPass || "",
                wifiAccessType:
                  propertyDetail?.wifi?.wifiAccessType ||
                  WIFI_ACCESS_TYPE.PASSWORD,
                wifiInstruction: propertyDetail?.wifi?.wifiInstruction || "",
              }}
              validationSchema={yup.object().shape({
                wifiName: yup.string().required().label(translations.wifiName),
                wifiPass: yup.string().when(["wifiAccessType"], {
                  is: (value: number) => value === WIFI_ACCESS_TYPE.PASSWORD,
                  then: yup
                    .string()
                    .required()
                    .label(translations.wifiPassword),
                }),
                wifiInstruction: yup.string().when(["wifiAccessType"], {
                  is: (value: number) =>
                    value === WIFI_ACCESS_TYPE.PASS_INSTRUCTION,
                  then: yup
                    .string()
                    .required()
                    .label("Wifi password instruction"),
                }),
              })}
              onSubmit={_handleSave}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <>
                  <form className={"form-layout"}>
                    <InputCustom
                      label={translations.wifiName}
                      value={values.wifiName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched.wifiName}
                      error={errors.wifiName}
                      name={"wifiName"}
                    />
                    <Label label={translations.wifiAccessType} required />
                    <SizeBox height={"16px"} />
                    <div>
                      <Radio
                        checked={
                          values.wifiAccessType === WIFI_ACCESS_TYPE.PASSWORD
                        }
                        label={translations.AccessWithPassword}
                        onChange={() =>
                          setFieldValue(
                            "wifiAccessType",
                            WIFI_ACCESS_TYPE.PASSWORD
                          )
                        }
                      />
                      <Radio
                        checked={
                          values.wifiAccessType ===
                          WIFI_ACCESS_TYPE.PASS_INSTRUCTION
                        }
                        label={translations.accessWithInstructions}
                        onChange={() =>
                          setFieldValue(
                            "wifiAccessType",
                            WIFI_ACCESS_TYPE.PASS_INSTRUCTION
                          )
                        }
                      />
                    </div>
                    <SizeBox height={"16px"} />
                    {values.wifiAccessType ===
                    WIFI_ACCESS_TYPE.PASS_INSTRUCTION ? (
                      <TextArea
                        value={values.wifiInstruction}
                        onChange={handleChange}
                        touched={touched.wifiInstruction}
                        error={errors.wifiInstruction}
                        name={"wifiInstruction"}
                        placeholder={translations.wifiInstructionPlaceholder}
                      />
                    ) : (
                      <InputCustom
                        value={values.wifiPass}
                        onChange={handleChange}
                        touched={touched.wifiPass}
                        error={errors?.wifiPass}
                        name={"wifiPass"}
                        placeholder={translations.wifiPasswordPlaceholder}
                      />
                    )}
                    <div className={"edit-cta-wrapper edit-mode"}>
                      <ButtonCustom
                        onClick={() => setEditMode(false)}
                        className={"outline-primary"}
                        fontSize={"12px"}
                        height={"37px"}
                      >
                        <>{translations.cancel}</>
                      </ButtonCustom>
                      <ButtonCustom
                        loading={loading}
                        disabled={loading}
                        onClick={handleSubmit}
                        className={"primary-alt"}
                        fontSize={"12px"}
                        height={"37px"}
                      >
                        <>{translations.save}</>
                      </ButtonCustom>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          ) : (
            <div className={"property-text"}>
              <div>
                {translations.wifiName}: {propertyDetail?.wifi?.wifiName}
              </div>
              <SizeBox height={"12px"} />
              {propertyDetail?.wifi?.wifiAccessType ===
              WIFI_ACCESS_TYPE.PASS_INSTRUCTION ? (
                <div>
                  {translations.wifiInstruction}:{" "}
                  {propertyDetail?.wifi?.wifiInstruction}
                </div>
              ) : (
                <div>
                  {translations.wifiPassword}: {propertyDetail?.wifi?.wifiPass}
                </div>
              )}
            </div>
          )}
        </div>

        {!editMode && (
          <EditActions editMode={editMode} setEditMode={setEditMode} />
        )}
      </div>
      <SizeBox height={"36px"} />
    </div>
  );
};

export default PropertyWifi;
