import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import { userCompanyChooseSelector } from "../../../../User/selectors/UserSelectors";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../../App/configs/Constants";
import TableCustom from "../../../../../components/Table";
import { contractPaymentService } from "../../../services/DashboardServices";
import { dashboardLeaseFilterAction } from "../../../actions/DashboardActions";
import {
  dashboardFilterDateTypeSelector,
  dashboardLeaseFilterSelector,
  filterListPropertySelectedSelector,
} from "../../../selectors/DashboardSelectors";
import {
  AUTO_REFRESH_INTERVAL_TIME,
  FILTER_DATE_TODAY,
} from "../../../configs/Constants";
import { MyProfileType } from "../../../../User/configs/DeclareTypes";
import { myProfileSelector } from "../../../../App/selectors/AppSelectors";
import { ROLE_DESKIMO_ADMIN_ID } from "../../../../User/configs/Constants";
import Actions from "./Actions";
import {
  BUSINESS_COMPANY_DETAIL_LINK,
  DESKIMO_PROPERTY_DETAIL_LINK,
} from "../../../../App/configs/ListRoutes";
import { Link } from "react-router-dom";
import moment from "moment";
import { FORMAT_DATE_VARIANT_2 } from "../../../../App/configs/DateTimeConfigs";
import ActionsBusinessAdmin from "./ActionsBusinessAdmin";

const OfficeLeases = () => {
  const dispatch = useDispatch();
  const myProfile: MyProfileType = useSelector(myProfileSelector);

  const { translations } = useContext(LocalizationContext);
  const [loading, setLoading] = useState<boolean>(true);
  const userCompanyChoose = useSelector(userCompanyChooseSelector);

  const [data, setData] = useState<any>(null);
  const dashboardLeaseFilter = useSelector(dashboardLeaseFilterSelector);
  const dashboardFilterDateType = useSelector(dashboardFilterDateTypeSelector);
  const filterListPropertySelected = useSelector(
    filterListPropertySelectedSelector
  );
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    _init();
    const interval = setInterval(_autoRefresh, AUTO_REFRESH_INTERVAL_TIME);
    return () => {
      clearInterval(interval);
    };
  }, [
    dashboardLeaseFilter,
    userCompanyChoose,
    refreshing,
    filterListPropertySelected,
  ]);

  const _init = async () => {
    try {
      setLoading(true);
      const { data } = await contractPaymentService({
        ...dashboardLeaseFilter,
        company_id: userCompanyChoose.company_id,
        properties: filterListPropertySelected || [],
      });
      setData(data);
    } catch (e) {
      console.log("e", e);
    } finally {
      setLoading(false);
    }
  };

  const _autoRefresh = async () => {
    if (dashboardFilterDateType !== FILTER_DATE_TODAY) {
      return;
    }
    const { data } = await contractPaymentService({
      ...dashboardLeaseFilter,
      company_id: userCompanyChoose.company_id,
    });
    setData(data);
  };

  const _formatInputData = () => {
    return data?.items?.map(
      ({
        code,
        price,
        name,
        property_name,
        business_name,
        status_name,
        payment_date,
        timezone,
        status,
        time_period,
        property_id,
        company_id,
        property_company_id,
        can_pay_with_credit,
        contract_day,
      }: any) => {
        if (myProfile.role_id === ROLE_DESKIMO_ADMIN_ID) {
          return {
            name,
            contract_day,
            status_name,
            business_name: company_id ? (
              <Link
                className={"d-block"}
                to={BUSINESS_COMPANY_DETAIL_LINK.replaceAll(
                  ":id",
                  String(company_id)
                )}
              >
                {business_name}
              </Link>
            ) : (
              business_name
            ),
            property: property_id ? (
              <Link
                to={DESKIMO_PROPERTY_DETAIL_LINK.replaceAll(
                  ":id",
                  String(property_id)
                ).replaceAll(":companyId", String(property_company_id))}
              >
                <span>{property_name}</span>
              </Link>
            ) : (
              property_name
            ),
            date: time_period,
            price,
            payment_date: payment_date
              ? moment(payment_date * 1000).format(FORMAT_DATE_VARIANT_2)
              : "",
            action: (
              <Actions
                status={status}
                code={code}
                onRefreshing={() => setRefreshing((prevState) => !prevState)}
                canPayWithCredit={can_pay_with_credit}
              />
            ),
          };
        }
        return {
          name,
          contract_day,
          status_name,
          property_name,
          date: time_period,
          price,
          payment_date: payment_date
            ? moment(payment_date * 1000).format(FORMAT_DATE_VARIANT_2)
            : "",
          action: <ActionsBusinessAdmin status={status} code={code} />,
        };
      }
    );
  };

  let columns = [
    {
      key: "name",
      col: "Private Office Transactions",
    },
    { key: "contract_day", col: "Contract Day" },
    { key: "status", col: "Status" },
    { key: "property_name", col: "Property" },
    { key: "time_period", col: "Time Period" },
    { key: "price", col: "Price" },
    { key: "payment_date", col: "Payment Date" },
    { key: null, col: "Action" },
  ];

  if (myProfile.role_id === ROLE_DESKIMO_ADMIN_ID) {
    columns = [
      {
        key: "name",
        col: "Private Office Transactions",
      },
      { key: "contract_day", col: "Contract Day" },
      { key: "status", col: "Status" },
      {
        key: "business_name",
        col: "Business Company",
      },
      { key: "property_name", col: "Property" },
      { key: "time_period", col: "Time Period" },
      { key: "price", col: "Price" },
      { key: "payment_date", col: "Payment Date" },
      { key: null, col: "Action" },
    ];
  }

  const _onChangeSortCol = (col: any) => {
    if (dashboardLeaseFilter.sort_by !== col.key) {
      dispatch(dashboardLeaseFilterAction({ key: "sort_by", value: col.key }));
      dispatch(
        dashboardLeaseFilterAction({ key: "sort_type", value: ORDER_BY_ASC })
      );
    } else {
      if (dashboardLeaseFilter.sort_type === ORDER_BY_NONE) {
        dispatch(
          dashboardLeaseFilterAction({ key: "sort_by", value: col.key })
        );
        dispatch(
          dashboardLeaseFilterAction({ key: "sort_type", value: ORDER_BY_ASC })
        );
      }
      if (dashboardLeaseFilter.sort_type === ORDER_BY_ASC) {
        dispatch(
          dashboardLeaseFilterAction({ key: "sort_by", value: col.key })
        );
        dispatch(
          dashboardLeaseFilterAction({ key: "sort_type", value: ORDER_BY_DESC })
        );
      }
      if (dashboardLeaseFilter.sort_type === ORDER_BY_DESC) {
        dispatch(
          dashboardLeaseFilterAction({ key: "sort_by", value: col.key })
        );
        dispatch(
          dashboardLeaseFilterAction({ key: "sort_type", value: ORDER_BY_NONE })
        );
      }
    }
  };

  return (
    <TableCustom
      orderBy={dashboardLeaseFilter.sort_type}
      sortBy={dashboardLeaseFilter.sort_by}
      sortTable
      onChangeSortCol={_onChangeSortCol}
      tableData={data?.items ? _formatInputData() : []}
      columns={columns}
      loading={loading}
      totalItems={data?.pagination?.total}
      currentPage={data?.pagination?.page}
      showTotal
      unit={translations.transactions}
      itemPerPage={data?.pagination?.limit}
      onChangePagination={(e: any, { activePage }: any) => {
        dispatch(
          dashboardLeaseFilterAction({ key: "page", value: activePage })
        );
      }}
    />
  );
};

export default OfficeLeases;
