import React from "react";
import {
  ROLE_BUSINESS_ADMIN_ID,
  ROLE_PARTNER_ID,
} from "../../../User/configs/Constants";
import MasterTemplate from "../../../App/views/MasterTemplate";
import UsageList from "./Components/UsageList";
import Filter from "../Dashboard/Filter";
import ChartSummary from "../Dashboard/ChartSummary";
import { UI_ACCESS_PARTNER_DASHBOARD } from "../../../../utils/PermissionConstant";
import ButtonCustom from "../../../../components/Button";
import { useDispatch } from "react-redux";
import {
  modalBookingDeskManualAction,
  modalBookingRoomManualAction,
  modalCheckInManualAction,
  modalPartnerCreateWalkInsAction,
} from "../../actions/DashboardActions";
import SizeBox from "../../../../components/SizeBox";
import ModalBookingRoom from "./Components/ModalBookingRoom";
import ModalBookingDesk from "./Components/ModalBookingDesk";
import { PRODUCT_TYPE_PARTNER } from "../../configs/Constants";
import ModalCheckInManual from "./Components/ModalCheckInManual";
import ModalPartnerCreateWalkIns from "./Components/ModalCreateWalkins";

const DashboardPartner = () => {
  const dispatch = useDispatch();
  return (
    <MasterTemplate
      roleAccept={[ROLE_BUSINESS_ADMIN_ID, ROLE_PARTNER_ID]}
      permission={UI_ACCESS_PARTNER_DASHBOARD}
    >
      <div className={"d-flex justify-content-between"}>
        <Filter />
        <div className={"d-flex"}>
          <ButtonCustom
            onClick={() =>
              dispatch(modalPartnerCreateWalkInsAction({ open: true }))
            }
            height={"36px"}
            className={"outline-primary"}
          >
            Manual Check-in
          </ButtonCustom>
          <SizeBox width={"8px"} />

          {/*<ButtonCustom*/}
          {/*  onClick={() => dispatch(modalCheckInManualAction({ open: true }))}*/}
          {/*  height={"36px"}*/}
          {/*  className={"outline-primary"}*/}
          {/*>*/}
          {/*  Create Test Walk-ins*/}
          {/*</ButtonCustom>*/}
          <SizeBox width={"8px"} />
          <ButtonCustom
            onClick={() =>
              dispatch(modalBookingDeskManualAction({ open: true }))
            }
            height={"36px"}
            className={"outline-primary"}
          >
            Book a desk
          </ButtonCustom>
          <SizeBox width={"8px"} />
          <ButtonCustom
            onClick={() =>
              dispatch(modalBookingRoomManualAction({ open: true }))
            }
            height={"36px"}
            className={"outline-primary"}
          >
            Book a room
          </ButtonCustom>
        </div>
      </div>
      <ChartSummary productType={PRODUCT_TYPE_PARTNER} />
      <UsageList />
      <ModalCheckInManual />
      <ModalBookingRoom />
      <ModalBookingDesk />
      <ModalPartnerCreateWalkIns />
    </MasterTemplate>
  );
};

export default DashboardPartner;
