import { createReducer } from "@reduxjs/toolkit";
import {
  DESKIMO_PROPERTY_LIST_ACTION,
  PROPERTY_LIST_ACTION,
  MEETING_ROOM_LIST_ACTION,
  PROPERTY_DETAIL_ACTION,
  MODAL_MEETING_ROOM_UPDATE_ACTION,
  MEETING_ROOM_DETAIL_ACTION,
  UPDATE_MEETING_ROOM_ACTION,
  MODAL_MEETING_ROOM_CREATE_ACTION,
  MODAL_ADD_STAFF_ACTION,
  USER_REVIEW_ACTION,
  DESKIMO_PROPERTY_COMPANY_LIST_ACTION,
  DESKIMO_PROPERTY_STAFF_LIST_ACTION,
  DESKIMO_PROPERTY_LIST_FILTER_ACTION,
  DESKIMO_PROPERTY_STAFF_LIST_FILTER_ACTION,
  DESKIMO_PROPERTY_COMPANY_LIST_FILTER_ACTION,
  DESKIMO_PROPERTY_LIST_FILTER_RESET_ACTION,
  DESKIMO_PROPERTY_COMPANY_LIST_FILTER_RESET_ACTION,
  DESKIMO_PROPERTY_STAFF_LIST_FILTER_RESET_ACTION,
  MODAL_MANAGE_PMS_ACTION,
  MODAL_MANAGE_PMS_ADD_ROOM_ACTION,
  MODAL_MANAGE_PMS_ADD_CALENDAR_ACTION,
  MANAGE_PMS_ROOM_LIST_ACTION,
  UPDATE_MANAGE_PMS_AUTO_CONFIRM_ACTION,
  MANAGE_PMS_ADD_CALENDAR_ACTION,
  MODAL_DESKIMO_WORKSPACE_STAFF_LIST,
  STAFF_DETAIL_ACTION,
  MANAGE_PMS_DELETE_ROOM_ACTION,
  MODAL_MEETING_ROOM_ASSIGN_ACTION,
  MEETING_ROOM_ASSIGN_ACTION,
  DESKIMO_PROPERTY_STAFF_DELETE_ACTION,
  DESKIMO_PROPERTY_STAFF_CHANGE_STATUS_ACTION,
  DESKIMO_PROPERTY_AMENITY_LIST_ACTION,
  MODAL_ADD_EDIT_AMENITY_ACTION,
  DESKIMO_PROPERTY_AMENITY_DELETE_ACTION,
  DESKIMO_PROPERTY_AMENITY_DETAIL_ACTION,
  DESKIMO_PROPERTY_COMPANY_DELETE_ACTION,
  DESKIMO_PROPERTY_SCANNER_LIST_ACTION,
  MODAL_PROPERTY_SCANNER_QR_CODE_ACTION,
  DESKIMO_PROPERTY_SCANNER_QR_CODE_ASSIGN_ACTION,
  DESKIMO_PROPERTY_SCANNER_DETAIL_ACTION,
  DESKIMO_PROPERTY_SCANNER_CHANGE_STATUS_ACTION,
  GET_PROPERTY_CATEGORIES_ACTION,
  GET_QRCODE_LIST_ACTION,
  GET_API_LIST_ACTION,
  GET_WEBHOOK_LIST_ACTION,
  DESKIMO_PRIVATE_OFFICE_MODAL_ACTION,
  MODAL_CREATE_STAFF_ACTION,
  DESKIMO_PROPERTY_LIST_FILTER_APPLY_ALL_ACTION,
  DESKIMO_PROPERTY_COMPANY_LIST_FILTER_APPLY_ALL_ACTION,
  DESKIMO_PROPERTY_STAFF_LIST_FILTER_APPLY_ALL_ACTION,
  DESKIMO_PROPERTY_SCANNER_FILTER_ACTION,
  GET_PARTNER_LOG_ACTION,
  GET_DESKIMO_USER_REVIEWS_ACTION,
  GET_PRIVATE_OFFICE_LIST_ACTION,
  FILTER_PRIVATE_OFFICE_LIST_ACTION,
  EXPORT_PRIVATE_OFFICE_LIST_ACTION,
  FILTER_TMP_PRIVATE_OFFICE_LIST_ACTION,
  DESKIMO_PROPERTY_REWARD_LIST_ACTION,
  MODAL_REWARD_ACTION,
  REWARD_LIST_ACTION,
} from "../configs/Actions";
import produce from "immer";
import { ORDER_BY_ASC, ORDER_BY_NONE } from "../../App/configs/Constants";
import { CREATE_TYPE } from "../configs/Constants";

const deskimoPropertyListFilterDefault = {
  page: 1,
  keyword: "",
  sort_by: "",
  order_by: ORDER_BY_NONE,
  market_code: [],
  statuses: [0, 1],
};

const deskimoPropertyCompanyListFilterDefault = {
  keyword: "",
  sort_by: "",
  order_by: ORDER_BY_NONE,
  page: 1,
  market_codes: [],
};

const deskimoPropertyStaffListFilterDefault = {
  keyword: "",
  sort_by: "name",
  order_by: ORDER_BY_ASC,
  page: 1,
  market_codes: [],
  company_id: 0,
};

const initializeState = {
  data: "",
  propertyScannerQrCode: "",
  propertyScannerToken: "",
  propertyList: [],
  deskimoPropertyList: null,
  deskimoPropertyListFilter: deskimoPropertyListFilterDefault,
  deskimoPropertyCompanyList: null,
  deskimoPropertyCompanyListFilter: deskimoPropertyCompanyListFilterDefault,
  deskimoPropertyStaffList: null,
  deskimoPropertyStaffListFilter: deskimoPropertyStaffListFilterDefault,
  deskimoPropertyAmenityList: [],
  deskimoPropertyAmenityDetail: {},
  deskimoPropertyScannerDetail: {},
  meetingRoomList: [],
  managePMSRoomList: null,
  propertyDetail: {},
  meetingRoomDetail: {},
  userReview: {},
  managePMSAddCalendarInput: {},
  deskimoPropertyScannerFilter: {
    sort_by: "",
    page: 1,
    order_by: "",
    keyword: "",
    market_codes: [],
    status: ["active", "inactive", "healthy", "unhealthy"],
  },
  deskimoPropertyScannerList: [],
  isModalPropertyScannerQrCode: false,
  isModalMeetingRoomUpdate: false,
  isModalMeetingRoomCreate: false,
  isModalMeetingRoomAssign: false,
  isModalAddStaff: false,
  isModalAddEditAmenity: false,
  modalDeskimoWorkspaceStaffList: { open: false, id: null },
  propertyCategories: [],
  propertyIntegrationsQRCodeList: [],
  propertyIntegrationsAPIList: {},
  propertyIntegrationsWebhookList: [],
  partnerLog: [],
  deskimoPrivateOfficeModal: { isOpen: false, type: CREATE_TYPE },
  modalCreateStaff: {
    open: false,
  },
  deskimoUserReviews: {},
  deskimoPrivateOfficeFilters: {
    filter: {
      markets: [0],
      pricing_rates: {
        type: "",
        from: "",
        to: "",
      },
      capacity: "all",
      area: "all",
      companies: [],
      workspaces: [],
      status: [0, 1],
    },
    keyword: "",
    page: 1,
    sort_by: "",
    order_by: "",
  },
  deskimoPrivateOfficeFiltersTmp: {
    filter: {
      markets: [0],
      pricing_rates: {
        type: "",
        from: "",
        to: "",
      },
      capacity: "all",
      area: "all",
      companies: [],
      workspaces: [],
      status: [0, 1],
    },
    keyword: "",
    page: 1,
    sort_by: "",
    order_by: "",
  },
  deskimoPrivateOfficeList: [],
  deskimoPrivateOfficeExportSelected: [],
  deskimoPropertyDetailRewards: {},
  modalReward: {
    open: false,
    data: {},
  },
  rewardList: {},
};

const PropertyReducer = createReducer(initializeState, {
  [DESKIMO_PROPERTY_LIST_ACTION]: (state: any, action: any) => {
    state.deskimoPropertyList = action.payload || [];
  },
  [DESKIMO_PROPERTY_LIST_FILTER_ACTION]: (state, action) => {
    if (action.payload.key === "page") {
      state.deskimoPropertyListFilter["page"] = action.payload.value;
      return;
    }
    state.deskimoPropertyListFilter = {
      ...state.deskimoPropertyListFilter,
      ...{ [action.payload.key]: action.payload.value, page: 1 },
    };
  },

  [DESKIMO_PROPERTY_LIST_FILTER_APPLY_ALL_ACTION]: (
    state: any,
    action: any
  ) => {
    state.deskimoPropertyListFilter = {
      ...state.deskimoPropertyListFilter,
      ...action.payload,
    };
  },

  [DESKIMO_PROPERTY_LIST_FILTER_RESET_ACTION]: (state) => {
    state.deskimoPropertyListFilter = deskimoPropertyListFilterDefault;
  },

  [DESKIMO_PROPERTY_COMPANY_LIST_ACTION]: (state: any, action: any) => {
    state.deskimoPropertyCompanyList = action.payload || [];
  },

  [DESKIMO_PROPERTY_COMPANY_DELETE_ACTION]: (state, action) => {
    if (!state.deskimoPropertyCompanyList) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.deskimoPropertyCompanyList.items =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.deskimoPropertyCompanyList.items.filter(
        (item: any) => item.company_id !== action.payload
      );
  },

  [DESKIMO_PROPERTY_COMPANY_LIST_FILTER_ACTION]: (state, action) => {
    if (action.payload.key === "page") {
      state.deskimoPropertyCompanyListFilter["page"] = action.payload.value;
      return;
    }
    state.deskimoPropertyCompanyListFilter = {
      ...state.deskimoPropertyCompanyListFilter,
      ...{ [action.payload.key]: action.payload.value, page: 1 },
    };
  },

  [DESKIMO_PROPERTY_COMPANY_LIST_FILTER_APPLY_ALL_ACTION]: (state, action) => {
    state.deskimoPropertyCompanyListFilter = {
      ...state.deskimoPropertyCompanyListFilter,
      ...action.payload,
    };
  },

  [DESKIMO_PROPERTY_COMPANY_LIST_FILTER_RESET_ACTION]: (state) => {
    state.deskimoPropertyCompanyListFilter =
      deskimoPropertyCompanyListFilterDefault;
  },

  [DESKIMO_PROPERTY_STAFF_LIST_ACTION]: (state: any, action: any) => {
    state.deskimoPropertyStaffList = action.payload || [];
  },

  [DESKIMO_PROPERTY_STAFF_LIST_FILTER_ACTION]: (state, action) => {
    if (action.payload.key === "page") {
      state.deskimoPropertyStaffListFilter["page"] = action.payload.value;
      return;
    }
    state.deskimoPropertyStaffListFilter = {
      ...state.deskimoPropertyStaffListFilter,
      ...{ [action.payload.key]: action.payload.value, page: 1 },
    };
  },

  [DESKIMO_PROPERTY_STAFF_LIST_FILTER_APPLY_ALL_ACTION]: (state, action) => {
    state.deskimoPropertyStaffListFilter = {
      ...state.deskimoPropertyStaffListFilter,
      ...action.payload,
    };
  },

  [DESKIMO_PROPERTY_STAFF_LIST_FILTER_RESET_ACTION]: (state, action) => {
    state.deskimoPropertyStaffListFilter =
      deskimoPropertyStaffListFilterDefault;
  },

  [PROPERTY_LIST_ACTION]: (state: any, action: any) => {
    state.propertyList = action.payload || [];
  },

  [MEETING_ROOM_LIST_ACTION]: (state: any, action: any) => {
    state.meetingRoomList = action.payload || [];
  },

  [MANAGE_PMS_ROOM_LIST_ACTION]: (state: any, action: any) => {
    state.managePMSRoomList = action.payload || [];
  },

  [USER_REVIEW_ACTION]: (state: any, action: any) => {
    state.userReview = action.payload || {};
  },

  [PROPERTY_DETAIL_ACTION]: (state: any, action: any) => {
    state.propertyDetail = action.payload || {};
  },

  [MEETING_ROOM_DETAIL_ACTION]: (state: any, action: any) => {
    state.meetingRoomDetail = action.payload || {};
  },

  [MEETING_ROOM_ASSIGN_ACTION]: (state: any, action: any) => {
    // In progress, not done.
    state.meetingRoomDetail = action.payload || undefined;
  },

  [MODAL_MANAGE_PMS_ACTION]: (state: any, action: any) => {
    state.isModalManagePMS = action.payload || false;
  },

  [MODAL_MANAGE_PMS_ADD_ROOM_ACTION]: (state: any, action: any) => {
    state.isModalManagePMSAddRoom = action.payload || false;
  },

  [MODAL_MANAGE_PMS_ADD_CALENDAR_ACTION]: (state: any, action: any) => {
    state.isModalManagePMSAddCalendar = action.payload || false;
  },

  [MODAL_MEETING_ROOM_UPDATE_ACTION]: (state: any, action: any) => {
    state.isModalMeetingRoomUpdate = action.payload || false;
  },

  [MODAL_MEETING_ROOM_CREATE_ACTION]: (state: any, action: any) => {
    state.isModalMeetingRoomCreate = action.payload || false;
  },

  [MODAL_MEETING_ROOM_ASSIGN_ACTION]: (state: any, action: any) => {
    state.isModalMeetingRoomAssign = action.payload || false;
  },

  [MODAL_ADD_STAFF_ACTION]: (state: any, action: any) => {
    state.isModalAddStaff = action.payload || false;
  },

  [MODAL_CREATE_STAFF_ACTION]: (state: any, action: any) => {
    state.modalCreateStaff = action.payload || false;
  },

  [MANAGE_PMS_ADD_CALENDAR_ACTION]: (state: any, action: any) => {
    state.managePMSAddCalendarInput = action.payload || {};
  },

  [MANAGE_PMS_DELETE_ROOM_ACTION]: (state, action) => {
    if (!state.managePMSRoomList) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.managePMSRoomList.meeting_rooms =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.managePMSRoomList.meeting_rooms.filter(
        (room: any) => room.id !== action?.payload
      );
  },

  [UPDATE_MANAGE_PMS_AUTO_CONFIRM_ACTION]: (state, action) => {
    if (!state.managePMSRoomList) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.managePMSRoomList.meeting_rooms =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.managePMSRoomList.meeting_rooms.map((room: any) => {
        if (room.id !== action.payload) {
          return room;
        }
        return { ...room, auto_confirm: room?.auto_confirm ? 0 : 1 };
      });
  },

  [MODAL_DESKIMO_WORKSPACE_STAFF_LIST]: (state: any, action: any) => {
    state.modalDeskimoWorkspaceStaffList = action.payload;
  },
  [STAFF_DETAIL_ACTION]: (state: any, action: any) => {
    state.staffDetail = action.payload || {};
  },

  [UPDATE_MEETING_ROOM_ACTION]: (state, action) => {
    state.meetingRoomList = produce(state.meetingRoomList, (draft: any) => {
      const index = draft.data.findIndex(
        (meetingRoom: any) => meetingRoom.id === action.payload.id
      );

      if (index !== -1) {
        draft.data[index] = action.payload;
      }
    });
  },
  [DESKIMO_PROPERTY_STAFF_DELETE_ACTION]: (state, action) => {
    if (!state.deskimoPropertyStaffList) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.deskimoPropertyStaffList.property_users =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.deskimoPropertyStaffList.property_users.filter(
        (user: any) => user.id !== action.payload
      );
  },
  [DESKIMO_PROPERTY_STAFF_CHANGE_STATUS_ACTION]: (state, action) => {
    if (!state.deskimoPropertyStaffList) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.deskimoPropertyStaffList.property_users =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.deskimoPropertyStaffList.property_users.map((user: any) => {
        if (user.id !== action.payload) {
          return user;
        }
        return {
          ...user,
          status: user?.status ? 0 : 1,
          status_name: user?.status_name === "Inactive" ? "Active" : "Inactive",
        };
      });
  },
  [DESKIMO_PROPERTY_AMENITY_LIST_ACTION]: (state, action) => {
    state.deskimoPropertyAmenityList = action.payload;
  },

  [MODAL_ADD_EDIT_AMENITY_ACTION]: (state: any, action: any) => {
    state.isModalAddEditAmenity = action.payload || false;
  },

  [DESKIMO_PROPERTY_AMENITY_DETAIL_ACTION]: (state, action) => {
    state.deskimoPropertyAmenityDetail = action.payload;
  },

  [DESKIMO_PROPERTY_AMENITY_DELETE_ACTION]: (state, action) => {
    if (!state.deskimoPropertyAmenityList) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.deskimoPropertyAmenityList.amenities =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.deskimoPropertyAmenityList.amenities.filter(
        (amenity: any) => amenity.id !== action.payload
      );
  },
  [DESKIMO_PROPERTY_SCANNER_FILTER_ACTION]: (state, action) => {
    state.deskimoPropertyScannerFilter = {
      ...state.deskimoPropertyScannerFilter,
      ...action.payload,
    };
  },
  [DESKIMO_PROPERTY_SCANNER_LIST_ACTION]: (state, action) => {
    state.deskimoPropertyScannerList = action.payload;
  },
  [DESKIMO_PROPERTY_SCANNER_DETAIL_ACTION]: (state, action) => {
    state.deskimoPropertyScannerDetail = action.payload;
  },
  [MODAL_PROPERTY_SCANNER_QR_CODE_ACTION]: (state, action) => {
    state.isModalPropertyScannerQrCode = action.payload || false;
  },
  [DESKIMO_PROPERTY_SCANNER_QR_CODE_ASSIGN_ACTION]: (state, action) => {
    state.propertyScannerQrCode = action.payload;
  },
  [DESKIMO_PROPERTY_SCANNER_CHANGE_STATUS_ACTION]: (state, action) => {
    if (!state.deskimoPropertyScannerList) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.deskimoPropertyScannerList.scanners =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.deskimoPropertyScannerList.scanners.map((scanner: any) => {
        if (scanner.token !== action.payload) {
          return scanner;
        }
        return {
          ...scanner,
          status: scanner?.status ? 0 : 1,
          status_name:
            scanner?.status_name === "Inactive" ? "Active" : "Inactive",
        };
      });
  },
  [GET_PROPERTY_CATEGORIES_ACTION]: (state, action) => {
    state.propertyCategories = action.payload;
  },
  [GET_QRCODE_LIST_ACTION]: (state, action) => {
    state.propertyIntegrationsQRCodeList = action.payload;
  },
  [GET_API_LIST_ACTION]: (state, action) => {
    state.propertyIntegrationsAPIList = action.payload;
  },
  [GET_WEBHOOK_LIST_ACTION]: (state, action) => {
    state.propertyIntegrationsWebhookList = action.payload;
  },
  [DESKIMO_PRIVATE_OFFICE_MODAL_ACTION]: (state, action) => {
    state.deskimoPrivateOfficeModal = action.payload;
  },
  [GET_PARTNER_LOG_ACTION]: (state, action) => {
    state.partnerLog = action.payload;
  },

  [GET_DESKIMO_USER_REVIEWS_ACTION]: (state, action) => {
    state.deskimoUserReviews = action.payload;
  },
  [GET_PRIVATE_OFFICE_LIST_ACTION]: (state, action) => {
    state.deskimoPrivateOfficeList = action.payload;
  },
  [FILTER_PRIVATE_OFFICE_LIST_ACTION]: (state, action) => {
    state.deskimoPrivateOfficeFilters = action.payload;
  },
  [FILTER_TMP_PRIVATE_OFFICE_LIST_ACTION]: (state, action) => {
    state.deskimoPrivateOfficeFiltersTmp = action.payload;
  },
  [EXPORT_PRIVATE_OFFICE_LIST_ACTION]: (state, action) => {
    state.deskimoPrivateOfficeExportSelected = action.payload;
  },

  [DESKIMO_PROPERTY_REWARD_LIST_ACTION]: (state, action) => {
    state.deskimoPropertyDetailRewards = action.payload;
  },

  [MODAL_REWARD_ACTION]: (state, action) => {
    state.modalReward = action.payload;
  },

  [REWARD_LIST_ACTION]: (state, action) => {
    state.rewardList = action.payload;
  },
});

export default PropertyReducer;
