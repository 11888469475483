import React from "react";
import { MeetingRoomProps } from "../../../configs/DeclareType";
import { useNavigate } from "react-router-dom";
import { BOOK_ROOM_DETAIL_LINK } from "../../../../App/configs/ListRoutes";

type Props = {
  rooms: MeetingRoomProps[];
};

const RoomSuggestion = ({ rooms }: Props) => {
  if (!rooms?.length) {
    return null;
  }

  return (
    <div>
      <div className={"book-detail__section-title"}>Meeting Rooms</div>
      <div className={"book-detail__room-suggestion"}>
        {rooms.map((item) => {
          return <RoomItem key={item.id} item={item} />;
        })}
      </div>
    </div>
  );
};

const RoomItem = ({ item }: { item: MeetingRoomProps }) => {
  const navigate = useNavigate();

  return (
    <div className={"book-detail__room-item"}>
      <img className={"thumbnail"} src={item.thumbnail} />
      <div className={"body"}>
        <div className={"name"}>{item.name}</div>
        <div className={"info info-bold"}>
          <i className="uil uil-credit-card" />
          {item.currency} {item.rate_per_hour} / HR
        </div>
        <div className={"info"}>
          <i className="uil uil-users-alt" />
          {item.capacity} {item.capacity > 1 ? "People" : "Person"}
        </div>
        <button
          className={"explore"}
          onClick={() => {
            navigate(BOOK_ROOM_DETAIL_LINK.replaceAll(":id", String(item.id)));
          }}
        >
          Explore Room
        </button>
      </div>
    </div>
  );
};

export default RoomSuggestion;
