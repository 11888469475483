import {
  getPackageTrialService,
  getTopPropertiesService,
} from "../services/OnboardServices";
import {
  packageTrialInfoAction,
  topPropertiesInfoAction,
} from "../actions/OnboardActions";

export const getPackageTrialInfoThunk = () => {
  return async (dispatch: any) => {
    const { data } = await getPackageTrialService();
    dispatch(packageTrialInfoAction(data));
  };
};

export const getTopRatingPropertiesThunk = () => {
  return async (dispatch: any) => {
    const { data } = await getTopPropertiesService("rating");
    dispatch(topPropertiesInfoAction(data));
  };
};
