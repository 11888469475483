export const GRANT_TYPE = process.env.REACT_APP_GRANT_TYPE;
export const GRANT_TYPE_FORCE_LOGIN =
  process.env.REACT_APP_GRANT_TYPE_FORCE_LOGIN;

export const GRANT_TYPE_SOCIAL_LOGIN =
  process.env.REACT_APP_GRANT_TYPE_SOCIAL_LOGIN;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
export const ADMIN_LOGOUT_URL = process.env.REACT_APP_ADMIN_LOGOUT_URL;

export const GRANT_TYPE_OTP_LOGIN = process.env.REACT_APP_GRANT_TYPE_OTP;

export const API_GET_COMPANY_BY_USER = "/api/v1/companies-by-user";
export const API_UPLOAD_ASSET = "/api/v1/file/uploader";
