import React, { useContext, useState } from "react";
import ButtonCustom from "../../../../../../components/Button";
import { CONFIG_MAIN_LINK } from "../../../../../App/configs/ListRoutes";
import SizeBox from "../../../../../../components/SizeBox";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { updateOnboardPromotionDataService } from "../../../../services/ConfigServices";
import { useNavigate, useParams } from "react-router-dom";
import { ONBOARD_PROMOTION_NONE } from "../../../../configs/Constants";
import { toastError, toastSuccess } from "../../../../../../components/Toast";

const None = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { translations } = useContext(LocalizationContext);
  const [loading, setLoading] = useState(false);

  const _onSubmit = async () => {
    try {
      setLoading(true);
      const payload = {
        status: ONBOARD_PROMOTION_NONE,
      };
      await updateOnboardPromotionDataService(id, payload);
      toastSuccess("Updated Successfully!");
    } catch (e: any) {
      toastError(e?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={"section-white-box-actions"}>
      <ButtonCustom
        className={"mr-0"}
        onClick={() => navigate(CONFIG_MAIN_LINK)}
        disabled={loading}
      >
        {translations.cancel}
      </ButtonCustom>
      <SizeBox width={"8px"} />
      <ButtonCustom
        className={"primary mr-0"}
        onClick={_onSubmit}
        loading={loading}
        disabled={loading}
      >
        {translations.save}
      </ButtonCustom>
    </div>
  );
};

export default None;
