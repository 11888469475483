import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import DropdownWithCheckboxMenu from "../../../../../components/DropdownWithCheckboxMenu";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import { useDispatch, useSelector } from "react-redux";
import { dashboardDeskBookingFilterAction } from "../../../actions/DashboardActions";
import {
  BUSINESS,
  ROOM_BOOKING_ALL_STATUS,
  ROOM_BOOKING_CANCELLED_STATUS,
  ROOM_BOOKING_COMPLETED_STATUS,
  ROOM_BOOKING_CONFIRMED_STATUS,
  ROOM_BOOKING_PENDING_STATUS,
} from "../../../configs/Constants";
import {
  dashboardDeskBookingFilterSelector,
  dashboardFilterDateRangeSelector,
} from "../../../selectors/DashboardSelectors";
import { LocalizationContext } from "../../../../../locales/Translation";
import { createDeskBookingStatusFilterService } from "../../../services/DashboardServices";

type Props = {
  productType?: number;
};

const FilterDeskBooking = ({ productType }: Props) => {
  const { translations } = useContext(LocalizationContext);

  const dispatch = useDispatch();
  const dashboardDeskBookingFilter = useSelector(
    dashboardDeskBookingFilterSelector
  );
  const dashboardFilterDateRange = useSelector(
    dashboardFilterDateRangeSelector
  );

  const [deskBookingStatus, setDeskBookingStatus] = useState<
    { id: string; name: string }[]
  >([]);

  useEffect(() => {
    (async () => {
      try {
        if (dashboardFilterDateRange == null) {
          return;
        }
        await createDeskBookingStatusFilterService({
          translations,
          setStatus: setDeskBookingStatus,
          type: BUSINESS,
          propertyContext: false,
          productType,
        });
      } catch (e) {
        console.log("e", e);
      }
    })();
  }, [dashboardDeskBookingFilter?.keyword, dashboardFilterDateRange]);

  const _onChangeFilter = (e: SyntheticEvent, data: any) => {
    if (data.value === ROOM_BOOKING_ALL_STATUS) {
      if (data.checked) {
        dispatch(
          dashboardDeskBookingFilterAction({
            key: "status",
            value: [
              ROOM_BOOKING_ALL_STATUS,
              ROOM_BOOKING_CONFIRMED_STATUS,
              ROOM_BOOKING_PENDING_STATUS,
              ROOM_BOOKING_CANCELLED_STATUS,
              ROOM_BOOKING_COMPLETED_STATUS,
            ],
          })
        );
      } else {
        dispatch(
          dashboardDeskBookingFilterAction({
            key: "status",
            value: [],
          })
        );
      }
      return;
    }

    if (data.checked) {
      const newValue = [...dashboardDeskBookingFilter.status, data.value];
      dispatch(
        dashboardDeskBookingFilterAction({
          key: "status",
          value: newValue,
        })
      );
      return;
    }

    const newValue = dashboardDeskBookingFilter.status.filter(
      (status: string) =>
        status !== data.value && status !== ROOM_BOOKING_ALL_STATUS
    );
    dispatch(
      dashboardDeskBookingFilterAction({
        key: "status",
        value: newValue,
      })
    );
  };

  return (
    <div className={"dropdown-filter"}>
      <DropdownWithCheckboxMenu
        onChange={_onChangeFilter}
        listCheckBox={deskBookingStatus.map((filter: any) => {
          if (dashboardDeskBookingFilter.status.includes(filter.id)) {
            filter.checked = true;
          } else {
            filter.checked = false;
          }

          return filter;
        })}
        label={translations.filters}
        icon={
          <img
            alt={"Deskimo"}
            src={PUBLIC_URL + "/assets/icons/icon-filter.svg"}
          />
        }
      />
    </div>
  );
};

export default FilterDeskBooking;
