import React from "react";
import Label from "../../../../../../components/Label";
import CheckboxCustom from "../../../../../../components/Checkbox";
import SizeBox from "../../../../../../components/SizeBox";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { FormikValues } from "formik";
import { FormikSetFieldValueType } from "../../../../configs/DeclareTypes";
import DatePickerSingle from "../../../../../../components/DatePickerSingle";
import moment from "moment";
import { CONTRACT_ONETIME_TYPE } from "../../../../configs/Constants";
import { beforeToday } from "rsuite/cjs/DateRangePicker/disabledDateUtils";

type Props = {
  values: FormikValues;
  setFieldValue: FormikSetFieldValueType;
  disabled: boolean;
};

const Date = ({ setFieldValue, values, disabled }: Props) => {
  return (
    <>
      <div className={"date-wrapper"}>
        <div className={"date"}>
          <Label label={"Select Date (Start)"} />
          <DatePickerSingle
            disabled={disabled}
            value={moment.unix(values.start_date).toDate()}
            onChange={(value: any) =>
              setFieldValue("start_date", moment(value).unix())
            }
            disabledDate={(value: any) => {
              return moment(value).isBefore(moment().startOf("month"));
            }}
            icon={
              <img
                className={"date-range-picker-custom-caret-icon"}
                src={PUBLIC_URL + "/assets/icons/icon-calendar-black.svg"}
                alt={"Deskimo"}
              />
            }
          />
        </div>
        {values.is_no_end_date ? null : (
          <>
            <SizeBox width={"16px"} />
            <div className={"date"}>
              <Label label={"Select Date (End)"} />
              <DatePickerSingle
                disabledDate={beforeToday()}
                disabled={disabled}
                value={moment.unix(values.end_date).toDate()}
                onChange={(value: any) =>
                  setFieldValue("end_date", moment(value).unix())
                }
                icon={
                  <img
                    className={"date-range-picker-custom-caret-icon"}
                    src={PUBLIC_URL + "/assets/icons/icon-calendar-black.svg"}
                    alt={"Deskimo"}
                  />
                }
              />
            </div>
          </>
        )}
      </div>

      {values.type === CONTRACT_ONETIME_TYPE ? null : (
        <>
          <CheckboxCustom
            disabled={disabled}
            onChange={() =>
              setFieldValue("is_no_end_date", !values.is_no_end_date)
            }
            label={"No end date"}
            checked={values.is_no_end_date}
          />
          <SizeBox height={"16px"} />
        </>
      )}
    </>
  );
};

export default Date;
