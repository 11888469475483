import { Routes, Route } from "react-router-dom";
import {
  BOOK_DESK_DETAIL_LINK,
  BOOK_DESK_DETAIL_WITH_DEFAULT_DATE_LINK,
  BOOK_DESK_LINK,
  BOOK_INQUIRE_OFFICE_DETAIL_LINK,
  BOOK_INQUIRE_OFFICE_LINK,
  BOOK_ROOM_DETAIL_LINK,
  BOOK_ROOM_LINK,
} from "../../App/configs/ListRoutes";
import BookTabs from "../views/Tabs";
import BookProperty from "../views/BookProperty";
import BookRoom from "../views/BookRoom";
import BookInquire from "../views/BookInquire";

const BookRoutes = () => {
  return (
    <Routes>
      <Route path={BOOK_DESK_LINK} element={<BookTabs tabIndex={0} />} />
      <Route path={BOOK_ROOM_LINK} element={<BookTabs tabIndex={1} />} />
      <Route
        path={BOOK_INQUIRE_OFFICE_LINK}
        element={<BookTabs tabIndex={2} />}
      />
      <Route path={BOOK_DESK_DETAIL_LINK} element={<BookProperty />} />
      <Route
        path={BOOK_DESK_DETAIL_WITH_DEFAULT_DATE_LINK}
        element={<BookProperty />}
      />
      <Route path={BOOK_ROOM_DETAIL_LINK} element={<BookRoom />} />
      <Route path={BOOK_INQUIRE_OFFICE_DETAIL_LINK} element={<BookInquire />} />
    </Routes>
  );
};

export default BookRoutes;
