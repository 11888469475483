import { createReducer } from "@reduxjs/toolkit";
import {
  IS_MODAL_ADD_TEAM_MEMBER_ACTION,
  TEAM_MEMBER_FILTER_ACTION,
  TEAM_MEMBER_LIST_ACTION,
} from "../configs/Constants";

const initialState = {
  isModalAddTeamMember: false,
  teamMemberList: null,
  teamMemberFilter: {
    keyword: "",
    sort_by: null,
    sort_type: null,
    page: 1,
    statuses: [0, 1, 2],
  },
};

const PartnerTeamReducer = createReducer(initialState, {
  [IS_MODAL_ADD_TEAM_MEMBER_ACTION]: (state, action) => {
    state.isModalAddTeamMember = action.payload;
  },

  [TEAM_MEMBER_LIST_ACTION]: (state, action) => {
    state.teamMemberList = action.payload;
  },

  [TEAM_MEMBER_FILTER_ACTION]: (state, action) => {
    if (action.payload.page > 1) {
      state.teamMemberFilter["page"] = action.payload.page;
      return;
    }

    state.teamMemberFilter = {
      ...state.teamMemberFilter,
      ...action.payload,
      page: 1,
    };
  },
});

export default PartnerTeamReducer;
