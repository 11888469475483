import MasterTemplate from "../../../App/views/MasterTemplate";
import { useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import {
  getInvoiceService,
  getInvoiceServiceForDeskimoAdmin,
} from "../../services/SettingServices";
import { Dimmer, Grid, Table } from "semantic-ui-react";
import { PUBLIC_URL } from "../../../App/configs/Constants";
import SizeBox from "../../../../components/SizeBox";
import "./style.scss";
import ButtonCustom from "../../../../components/Button";
import { getURlDownloadFileService } from "../../services/InvoiceServices";
import LoaderCustom from "../../../../components/Loader";
import { LocalizationContext } from "../../../../locales/Translation";
import Loader from "../../../../components/Loader";
import {
  ROLE_BUSINESS_ADMIN_ID,
  ROLE_DESKIMO_ADMIN_ID,
  ROLE_PARTNER_ID,
} from "../../../User/configs/Constants";

type Props = {
  isPartner?: boolean;
};

const Invoice = ({ isPartner }: Props) => {
  const { token, companyId } = useParams();
  const [invoice, setInvoice] = useState({
    invoice: {
      invoice_date: "",
      invoice_due_date: "",
      invoice_no: "",
      company_name: "",
      company_email: "",
      address: "",
      period: "",
      market_entity_bank_name: "",
      market_entity_bank_account: "",
      market_entity_bank_holder: "",
      reference: "",
      market_entity_name: "",
      market_entity_address: "",
      market_entity_phone: "",
      market_entity_email: "",
      total: "",
      status: 0,
      invoice_csv_url: "",
      invoice_xlsx_url: "",
      currency: "",
    },
    invoice_items: [],
  });
  const [loadingXLSX, setLoadingXLSX] = useState(false);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [loading, setLoading] = useState(false);
  const { translations } = useContext(LocalizationContext);

  useEffect(() => {
    fetchInvoiceDetail(token);
  }, []);

  const fetchInvoiceDetail = async (token: any) => {
    setLoading(true);
    try {
      let response;
      if (companyId) {
        response = await getInvoiceServiceForDeskimoAdmin(token, companyId);
      } else {
        response = await getInvoiceService(token);
      }

      setInvoice(response?.data);
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setLoading(false);
    }
  };

  let tableRowHTML = null;
  if (invoice.invoice_items.length > 0) {
    tableRowHTML = invoice.invoice_items.map((item: any, index) => {
      return (
        <Table.Row key={index}>
          <Table.Cell>{item?.name}</Table.Cell>
          <Table.Cell>{item?.quantity}</Table.Cell>
          <Table.Cell>{item?.unit_price}</Table.Cell>
          <Table.Cell>{item?.total_price}</Table.Cell>
        </Table.Row>
      );
    });
  }

  const handleDownloadFile = async (url: string) => {
    if (!url) {
      return;
    }
    try {
      const response = await getURlDownloadFileService(url);
      window.open(response?.data?.url, "_blank");
    } catch (error) {
      console.log(Error);
    } finally {
      setLoadingCSV(false);
      setLoadingXLSX(false);
    }
  };

  if (loading) {
    return (
      <MasterTemplate
        roleAccept={[ROLE_BUSINESS_ADMIN_ID, ROLE_DESKIMO_ADMIN_ID]}
      >
        <Dimmer active>
          <Loader />
        </Dimmer>
      </MasterTemplate>
    );
  }

  return (
    <MasterTemplate
      roleAccept={
        isPartner
          ? [ROLE_BUSINESS_ADMIN_ID, ROLE_DESKIMO_ADMIN_ID, ROLE_PARTNER_ID]
          : [ROLE_BUSINESS_ADMIN_ID, ROLE_DESKIMO_ADMIN_ID]
      }
    >
      <div className={"d-flex w-100 justify-content-end"}>
        <ButtonCustom
          loading={loadingXLSX}
          onClick={() => {
            setLoadingXLSX(true);
            handleDownloadFile(invoice.invoice.invoice_xlsx_url);
          }}
          className={"outline-primary none-bg m-0"}
          height={"36px"}
        >
          {translations.settings.downloadXLSXFile}
        </ButtonCustom>
        <SizeBox width={"12px"} />
        <ButtonCustom
          loading={loadingCSV}
          onClick={() => {
            setLoadingCSV(true);
            handleDownloadFile(invoice.invoice.invoice_csv_url);
          }}
          className={"outline-primary none-bg m-0"}
          height={"36px"}
        >
          {translations.settings.downloadAccountStatement}
        </ButtonCustom>
      </div>
      <SizeBox height={"52px"} />
      <div className={"invoice-wrapper"}>
        <div className={"invoice-title"}>
          {translations.settings.corporateInvoice}
        </div>
        <SizeBox height={"42px"} />
        <Grid>
          <Grid.Column computer={8} tablet={16} mobile={16}>
            <div className={"invoice-sub-title"}>
              {translations.settings.to}:{" "}
            </div>
            <div>{invoice.invoice.company_name}</div>
            <div>{invoice.invoice.address}</div>
            <div>{invoice.invoice.company_email}</div>
            <div>
              {translations.settings.timePeriod}: {invoice.invoice.period}
            </div>

            <SizeBox height={"72px"} />

            <div className={"invoice-sub-title"}>
              {translations.settings.accountStatementNumber}:
            </div>
            <div>{invoice?.invoice.invoice_no}</div>

            <SizeBox height={"35px"} />

            <div className={"invoice-sub-title"}>
              {translations.settings.accountStatementDate}:
            </div>
            <div>{invoice.invoice.invoice_date}</div>
          </Grid.Column>
          <Grid.Column computer={8} tablet={16} mobile={16}>
            <div className={"invoice-sub-title"}>
              {translations.settings.dueDate}:{" "}
              {invoice?.invoice?.invoice_due_date}
            </div>
            <div>{translations.settings.balanceToBePaidTo}:</div>
            <div>
              {translations.settings.bank}:{" "}
              {invoice.invoice.market_entity_bank_name}
            </div>
            <div>
              {translations.settings.accountNo}:{" "}
              {invoice.invoice.market_entity_bank_account}
            </div>
            <div>
              {translations.settings.accountHolder}:{" "}
              {invoice.invoice.market_entity_bank_holder}
            </div>
            <SizeBox height={"72px"} />
            <div className={"invoice-sub-title"}>
              {translations.settings.reference}:
            </div>
            <div>
              {invoice.invoice.company_name} - {invoice.invoice.reference}{" "}
            </div>
            <div>{invoice.invoice.market_entity_name}</div>
            <div>{invoice.invoice.market_entity_address}</div>
            <div>{invoice.invoice.market_entity_phone}</div>
            <div>{invoice.invoice.market_entity_email}</div>
          </Grid.Column>
        </Grid>
        <SizeBox height={"130px"} />
        <Table className={"invoice-table"}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                {translations.settings.description}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {translations.settings.quantity}
              </Table.HeaderCell>
              <Table.HeaderCell className={"text-nowrap"}>
                {translations.settings.unitPrice}
              </Table.HeaderCell>
              <Table.HeaderCell className={"text-nowrap"}>
                {translations.settings.total} ({invoice.invoice.currency})
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tableRowHTML}
            <Table.Row>
              <Table.Cell className={"invoice-stamp"}>
                {invoice.invoice.status === 2 ? (
                  <img src={PUBLIC_URL + "/assets/icons/icon-paid-stamp.svg"} />
                ) : null}
              </Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell className={"fw-700 text-nowrap"}>
                {translations.settings.grandTotal}
              </Table.Cell>
              <Table.Cell>{invoice.invoice.total}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        {loading ? <LoaderCustom /> : <></>}
      </div>
    </MasterTemplate>
  );
};

export default Invoice;
