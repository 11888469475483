import React, { useContext, useState } from "react";
import Label from "../../../../../components/Label";
import { LocalizationContext } from "../../../../../locales/Translation";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import SizeBox from "../../../../../components/SizeBox";
import DropdownSearchServer from "../../../../../components/DropdownSearchServer";
import { debounce } from "lodash";
import { autocompletePropertiesService } from "../../../../Marketing/services/MarketingServices";

type Props = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
  handleChange: any;
  setFieldValue: any;
};

const WorkspaceRestriction = ({
  values,
  errors,
  touched,
  isSubmitting,
  handleChange,
  setFieldValue,
}: Props) => {
  const { translations } = useContext(LocalizationContext);
  const [options, setOptions] = useState<
    { key: number; text: string; id: number }[]
  >([]);

  const _initOptions = async (keyword = "") => {
    try {
      const { data } = await autocompletePropertiesService(keyword);

      const temp = data.map(({ id, text }: { id: number; text: string }) => {
        return { key: id, text, id };
      });
      setOptions(temp);
    } catch (e) {
      console.log(e);
    }
  };

  const debounce_onChange = debounce(async (keyword: string) => {
    await _initOptions(keyword);
  }, 300);

  return (
    <>
      <Label
        label={"Workspace Restrictions"}
        fontSize={"24px"}
        className={"section-title"}
      />
      <div className={"detail_divide"}>
        <div className={"left_section d-flex flex-column"}>
          <Label
            label={"Excluded workspaces"}
            className={"mb-8px"}
            labelGuide={
              "Search and add the names of workspaces that you want to restrict access to for your employees"
            }
          />
          <div className={"d-flex"}>
            <DropdownSearchServer
              value={values?.property_restriction}
              onSearch={(searchQuery) => debounce_onChange(searchQuery)}
              options={options}
              onChange={(value) => {
                setFieldValue(
                  `property_restriction`,
                  value.map((property: any) => {
                    return {
                      key: property.key,
                      text: property.text,
                      value: property.value ? property.value : property?.id,
                    };
                  })
                );
              }}
            />
          </div>
        </div>
      </div>

      <SizeBox height={"24px"} />
      <div className={"property_company_detail_line"} />
    </>
  );
};

export default WorkspaceRestriction;
