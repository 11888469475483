import React, { useContext, useEffect, useState } from "react";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import InputCustom from "../../../../../components/Input";
import Label from "../../../../../components/Label";
import DropdownCustom from "../../../../../components/DropdownCustom";
import SizeBox from "../../../../../components/SizeBox";
import CheckboxCustom from "../../../../../components/Checkbox";
import { LocalizationContext } from "../../../../../locales/Translation";
import { getListMarketAndCompanyService } from "../../../services/PropertyCompanyServices";
import DropzoneComponent from "../../PropertyDetail/Components/DetailsTab/Components/DropzoneComponent";
import RichText from "../../../../../components/RichText";
import { tagsListService } from "../../../../Configuration/services/ConfigServices";
import { ROLE_DESKIMO_ADMIN_ID } from "../../../../User/configs/Constants";
import { MyProfileType } from "../../../../User/configs/DeclareTypes";
import { useDispatch, useSelector } from "react-redux";
import { myProfileSelector } from "../../../../App/selectors/AppSelectors";
import { propertyCategoriesSelector } from "../../../selectors/PropertySelector";
import { getPropertyCategoriesThunk } from "../../../thunks/PropertyThunks";
import CompanyFilter from "../../../../../components/CompanyFilter";

type Props = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
  handleChange: any;
  setFieldValue: any;
  description: any;
  setDescription: any;
  options: any;
  setOptions: any;
};

const GeneralInformation = ({
  isSubmitting,
  setFieldValue,
  values,
  errors,
  touched,
  handleChange,
  setDescription,
  description,
  options,
  setOptions,
}: Props) => {
  const { translations } = useContext(LocalizationContext);
  const [propertyCompanyOptions, setPropertyCompanyOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const myProfile: MyProfileType = useSelector(myProfileSelector);
  const listCategory = useSelector(propertyCategoriesSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const res = await Promise.all([
          getListMarketAndCompanyService({
            getOnlyActive: 1,
          }),
          tagsListService({ page: 1 }),
        ]);
        const companies = res[0]?.data?.companies;
        const tagList = res[1]?.data?.items;
        if (!companies && !tagList.length) {
          return;
        }

        const temp = companies.map(
          ({ id, name }: { id: number; name: string }) => ({
            key: id,
            text: name,
            value: id,
          })
        );
        setPropertyCompanyOptions(temp);

        const tags = tagList.map(
          ({ id, name }: { id: number; name: string }) => ({
            key: id,
            text: name,
            value: id,
          })
        );
        setTagOptions(tags);
        await fetchListCategory();
      } catch (e: any) {
        console.log(e);
      }
    })();
  }, []);

  const fetchListCategory = async () => {
    try {
      await dispatch<any>(getPropertyCategoriesThunk());
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    if (listCategory.length > 0) {
      setOptions(
        listCategory.map((category: any) => {
          return {
            key: category?.id,
            text: category?.name + ` (${category?.total_property})`,
            value: category?.name,
          };
        })
      );
    } else {
      setOptions([]);
    }
  }, [listCategory]);

  const handleAdditionCategory = (e: any, { value }: any) => {
    setOptions([...options, { key: value, text: value, value }]);
  };

  const handleChangeCategory = (e: any, { value }: any) => {
    setFieldValue("property_categories", value);
  };

  const _handleChangeCompany = async (id: number) => {
    setFieldValue("company_id", id);
  };

  return (
    <>
      <div className="form-row">
        <InputCustom
          label={translations.propertyName}
          value={values.name}
          onChange={handleChange}
          touched={touched.name}
          error={errors.name}
          name={"name"}
          labelRequired
          disabled={isSubmitting}
        />
        <div>
          <Label label={"Company"} required fontSize={"18px"} />
          <DropdownCustom
            scrolling
            selectUI
            placeholder={"Please select company"}
            options={propertyCompanyOptions}
            value={values.company_id}
            onChange={(event, { value }) => {
              _handleChangeCompany(Number(value));
            }}
            error={Boolean(touched.company_id && errors.company_id)}
            disabled={isSubmitting}
            search
          />
        </div>
      </div>
      <div className="form-row">
        <InputCustom
          label={translations.hourlyRateIncludingGSTVAT}
          value={values.hourly_rate}
          onChange={handleChange}
          touched={touched.hourly_rate}
          error={errors.hourly_rate}
          name={"hourly_rate"}
          inputLeftIconBorder
          labelRequired
          disabled={isSubmitting}
          removeLetter
        />
        <InputCustom
          label={translations.dailyRateCap}
          value={values.daily_rate}
          onChange={handleChange}
          touched={touched.daily_rate}
          error={errors.daily_rate}
          name={"daily_rate"}
          inputLeftIconBorder
          disabled={isSubmitting}
          removeLetter
        />
      </div>

      <div className={"edit-property-categories"}>
        <Label
          fontSize={"12px"}
          label={"Category"}
          className={"sm"}
          labelGuide={
            "Add or select a label to allow users to easily filter for the right workspace"
          }
        />
        <DropdownCustom
          disabled={myProfile.role_id !== ROLE_DESKIMO_ADMIN_ID}
          options={options}
          selectUI
          search
          selection
          allowAdditions={myProfile.role_id === ROLE_DESKIMO_ADMIN_ID}
          multiple
          value={values.property_categories}
          onAddItem={handleAdditionCategory}
          onChange={handleChangeCategory}
        />
      </div>
      <SizeBox height={"40px"} />

      <Label
        fontSize={"12px"}
        label={"Limit access to business with the following tags"}
        className={"sm"}
      />
      <DropdownCustom
        selectUI
        options={tagOptions}
        value={values.tag_ids}
        multiple
        className={"w-100"}
        onChange={(event, { value }) => {
          setFieldValue("tag_ids", value);
        }}
      />

      <SizeBox height={"22px"} />

      <Label
        fontSize={"12px"}
        label={translations.hotDeskMode}
        className={"sm mb-0"}
      />
      <SizeBox height={"24px"} />
      <div className={"d-flex"}>
        <CheckboxCustom
          onChange={(e: any, data: any) => {
            setFieldValue("hot_desk_mode.allow_walk_in", data.checked);
          }}
          checked={values.hot_desk_mode.allow_walk_in}
          label={translations.allowDeskWalkIns}
          disabled={isSubmitting}
        />
        <SizeBox width={"45px"} />
        <CheckboxCustom
          onChange={(e: any, data: any) => {
            setFieldValue("hot_desk_mode.allow_booking", data.checked);
          }}
          checked={values.hot_desk_mode.allow_booking}
          label={translations.allowDeskAdvancedBookings}
          disabled={isSubmitting}
        />
      </div>
      <SizeBox height={"24px"} />
      <div>
        <CheckboxCustom
          onChange={(e: any, data: any) => {
            setFieldValue(
              "hot_desk_mode.allow_desk_limitation_condition",
              data.checked
            );
          }}
          checked={values.hot_desk_mode.allow_desk_limitation_condition}
          label={translations.limitNumberAvailableDesks}
          disabled={isSubmitting}
        />
        {values.hot_desk_mode.allow_desk_limitation_condition ? (
          <>
            <SizeBox height={"16px"} />
            <div className="form-row">
              <InputCustom
                containerClass={"mb-0"}
                value={values.hot_desk_mode.desk_limitation_amount}
                onChange={handleChange}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                touched={touched?.hot_desk_mode?.desk_limitation_amount}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                error={errors?.hot_desk_mode?.desk_limitation_amount}
                name={"hot_desk_mode.desk_limitation_amount"}
                inputLeftIconBorder
                disabled={isSubmitting}
                placeholder={translations.noOfDesks}
              />
              <div />
            </div>
          </>
        ) : null}
      </div>
      <SizeBox height={"24px"} />
      <div>
        <CheckboxCustom
          onChange={(e: any, data: any) => {
            setFieldValue("hot_desk_mode.auto_approved", data.checked);
          }}
          checked={values.hot_desk_mode.auto_approved}
          label={"Automatically approve day pass bookings"}
        />
      </div>
      <SizeBox height={"24px"} />
      <Label
        fontSize={"12px"}
        label={translations.yourPhotos}
        className={"sm"}
      />
      <SizeBox height={"16px"} />
      <DropzoneComponent
        value={values.images}
        deletePhotos={(id: string) => {
          const newImages = values.images.filter(
            (image: any) => image?.id !== id
          );
          setFieldValue("images", newImages);
        }}
        setPhotos={(data: any) => {
          setFieldValue("images", [...values.images, ...data]);
        }}
      />
      <SizeBox height={"46px"} />
      <div className="section">
        <Label fontSize={"12px"} label={"Description"} className={"sm"} />
        <div>
          <RichText
            value={description}
            onChange={(value) => setDescription(value)}
          />
        </div>
        <SizeBox height={"16px"} />
      </div>
    </>
  );
};

export default GeneralInformation;
