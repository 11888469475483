import ButtonCustom from "../../../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import DropdownCustom from "../../../../../../../components/DropdownCustom";
import React, { useContext, useRef } from "react";
import { LocalizationContext } from "../../../../../../../locales/Translation";
import { PUBLIC_URL } from "../../../../../../App/configs/Constants";
import { QRCodeCanvas } from "qrcode.react";
import html2canvas from "html2canvas";

const QRCodeActions = ({ item, edit, onDelete }: any) => {
  const { translations } = useContext(LocalizationContext);
  const qrCodeRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleDownload = (qrcode: any) => {
    const qrCodeElement: any = qrCodeRef.current;

    html2canvas(qrCodeElement).then((canvas) => {
      const image = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.download = qrcode?.type_name + ".png";
      link.href = image;
      link.click();
    });
  };

  return (
    <>
      <DropdownCustom
        ref={dropdownRef}
        className={"table-action-dropdown"}
        direction={"left"}
        icon={null}
        trigger={
          <ButtonCustom
            style={{ width: "38px", height: "38px" }}
            className={"primary-alt"}
          >
            <i className="uil uil-setting" style={{ fontSize: "21px" }} />
          </ButtonCustom>
        }
      >
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              dropdownRef?.current?.click();
              handleDownload(item);
            }}
            text={"Download"}
            icon={
              <i
                className="uil uil-image-download icon"
                style={{ fontSize: "16px" }}
              />
            }
          />
          <Dropdown.Item
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              dropdownRef?.current?.click();
              edit({ open: true, data: item });
            }}
            text="Edit"
            icon={
              <i className="uil uil-edit icon" style={{ fontSize: "16px" }} />
            }
          />
          <Dropdown.Item
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              dropdownRef?.current?.click();
              setTimeout(() => {
                onDelete(item?.id);
              }, 100);
            }}
            text={"Delete"}
            icon={
              <i
                className="uil uil-trash-alt icon"
                style={{ fontSize: "16px" }}
              />
            }
          />
        </Dropdown.Menu>
      </DropdownCustom>

      <div className={"qrcode-image-hidden"} ref={qrCodeRef}>
        <div className={"qrcode-wrapper"}>
          <img src={PUBLIC_URL + "/assets/images/qr-code-frame.svg"} alt="" />
          <div className={"qr-code-image-box"}>
            <QRCodeCanvas
              size={225}
              value={item?.qrcode_token}
              imageSettings={{
                src: PUBLIC_URL + "/assets/logo-square.svg",
                x: undefined,
                y: undefined,
                height: 36,
                width: 36,
                excavate: true,
              }}
            />
            <div className={"qrcode-image-hidden-text"}>
              Scan here to <b>{item?.type_name}</b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QRCodeActions;
