import DropdownCustom from "../../../../../components/DropdownCustom";
import ButtonCustom from "../../../../../components/Button";
import React, { useContext } from "react";
import { Dropdown } from "semantic-ui-react";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import {
  PROPERTY_DETAIL_LINK,
  PROPERTY_EDIT_LINK,
} from "../../../../App/configs/ListRoutes";
import { useNavigate } from "react-router-dom";
import { SWAL_DELETE_OPTION } from "../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../components/SweetAlert";
import { LocalizationContext } from "../../../../../locales/Translation";
import { handlePublishService } from "../../../services/PropertyServices";
import { toastError, toastSuccess } from "../../../../../components/Toast";

const PropertyRow = ({ property, setRefreshList, refreshList }: any) => {
  const starsHTML = [];
  const navigate = useNavigate();
  const { translations } = useContext(LocalizationContext);

  const stars =
    parseInt(property.rate_score) === 5 ? 5 : Math.ceil(property.rate_score);
  for (let i = 1; i <= stars; i++) {
    starsHTML.push(
      <img src={PUBLIC_URL + "/assets/icons/icon-star-fill-blue.svg"} />
    );
  }
  for (let j = 1; j <= 5 - stars; j++) {
    starsHTML.push(<i className="uil uil-star rating-star" />);
  }

  const _handlePublishProperty = (id: any, isPublish: number) => {
    const icon = null;
    const title =
      isPublish > 0
        ? translations.propertyPage.publishMsg
        : translations.propertyPage.unPublishMsg;
    const confirmText =
      isPublish > 0
        ? translations.propertyPage.publish
        : translations.propertyPage.unPublish;
    const confirmClass = "primary";
    const options = SWAL_DELETE_OPTION({
      title,
      icon,
      confirmText,
      confirmClass,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          const payload = {
            property_id: id,
            status: isPublish,
          };
          await handlePublishService(payload);
          toastSuccess(
            isPublish > 0
              ? translations.propertyPage.publishSuccessMsg
              : translations.propertyPage.unPublishSuccessMsg
          );
          setRefreshList(!refreshList);
        } catch (error: any) {
          if (error?.status?.message) {
            toastError(error?.status?.message);
          }
        }
      },
      cancelCallback: () => null,
    });
  };

  return (
    <div className={"property-row"}>
      <div className={"property-row__meta"}>
        <div
          className={"property-row__meta-title"}
          onClick={() =>
            navigate(PROPERTY_DETAIL_LINK.replaceAll(":id", property?.id))
          }
        >
          {property?.name}
        </div>
        <div className={"property-row__meta-company"}>
          <span className={"fw-500"}>{translations.company}:</span>{" "}
          {property?.company_name}
        </div>
        <div className={"property-row__meta-address"}>
          <span className={"fw-500"}>{translations.settings.address}:</span>{" "}
          {property?.address}
        </div>
        <div className={"property-row__meta-meeting-rooms"}>
          {property?.meeting_room?.length > 0 ? (
            <>
              <span className={"fw-500"}>{translations.meetingRooms}:</span>{" "}
              {property?.meeting_room.join(", ")}
            </>
          ) : null}
        </div>
      </div>

      {property?.rate_score && property?.reviews_base_on ? (
        <div className={"property-row__rating"}>
          <div className={"property-row__rating-number"}>
            {property?.rate_score}
          </div>
          <div className={"property-row__rating-stars"}>{starsHTML}</div>
          <div className={"property-row__rating-text"}>
            {translations.basedOn} {property?.reviews_base_on}{" "}
            {translations.reviewsLowercase}
          </div>
        </div>
      ) : (
        <div className={"property-row__rating justify-content-center"}>
          <div className={"property-row__rating-stars mb-0"}>{starsHTML}</div>
        </div>
      )}

      <div className={"property-row__action"}>
        <DropdownCustom
          direction={"left"}
          icon={null}
          trigger={
            <ButtonCustom
              style={{ width: "38px", height: "38px" }}
              className={"primary-alt"}
            >
              <i className="uil uil-setting" style={{ fontSize: "21px" }} />
            </ButtonCustom>
          }
        >
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() =>
                navigate(PROPERTY_DETAIL_LINK.replaceAll(":id", property?.id))
              }
              text={translations.viewDetails}
              icon={<img src={PUBLIC_URL + "/assets/icons/icon-message.svg"} />}
            />
            <Dropdown.Item
              onClick={() =>
                navigate(PROPERTY_EDIT_LINK.replaceAll(":id", property?.id))
              }
              text={translations.editProperty}
              icon={
                <i className="uil uil-edit icon" style={{ fontSize: "16px" }} />
              }
            />
            {/*{property?.is_public > 0 ? (*/}
            {/*  <Dropdown.Item*/}
            {/*    onClick={() => _handlePublishProperty(property?.id, 0)}*/}
            {/*    text={translations.propertyPage.unPublish}*/}
            {/*    icon={*/}
            {/*      <i*/}
            {/*        className="uil uil-toggle-off icon"*/}
            {/*        style={{ fontSize: "16px" }}*/}
            {/*      />*/}
            {/*    }*/}
            {/*  />*/}
            {/*) : (*/}
            {/*  <Dropdown.Item*/}
            {/*    onClick={() => _handlePublishProperty(property?.id, 1)}*/}
            {/*    text={translations.propertyPage.publish}*/}
            {/*    icon={*/}
            {/*      <i*/}
            {/*        className="uil uil-toggle-on icon"*/}
            {/*        style={{ fontSize: "16px" }}*/}
            {/*      />*/}
            {/*    }*/}
            {/*  />*/}
            {/*)}*/}
          </Dropdown.Menu>
        </DropdownCustom>
      </div>
    </div>
  );
};

export default PropertyRow;
