import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import "./styles.scss";
import { DROPZONE_MAX_FILE_3MB } from "../../../../../../App/configs/DropzoneConfig";
import { toastError } from "../../../../../../../components/Toast";
import { configUpdateCoverImageService } from "../../../../../services/ConfigServices";

const UploadImageSection = ({
  setFieldValue,
  imageUploaded,
  setImageUploaded,
  clearImg,
  setClearImg,
}: any) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    if (clearImg) {
      setImgSrc(null);
      setClearImg(false);
    }
  }, [clearImg]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: [".png", ".gif", ".jpg", ".jpeg"],
    maxSize: DROPZONE_MAX_FILE_3MB,
    useFsAccessApi: false,
    onDrop: async (acceptedImageFiles: any) => {
      if (acceptedImageFiles?.length > 0) {
        const imageData = new FormData();
        imageData.append("cover_image", acceptedImageFiles[0]);
        let data: any;
        try {
          const response = await configUpdateCoverImageService(imageData);
          data = {
            cover_image_uri: response?.data?.cover_image_uri,
            uri: response?.data?.uri,
          };
          setFieldValue("image", data?.cover_image_uri);
          setImgSrc(data?.uri);
        } catch (error) {
          console.error(error);
        }
        setIsDragOver(false);
      }
    },

    onDropRejected: (imageRejections: any) => {
      let isImageTooLarge;
      imageRejections.forEach((image: any) => {
        isImageTooLarge =
          image.imageRejections.filter(
            (error: any) => error.code === "file-too-large"
          ).length > 0;
        if (isImageTooLarge) {
          toastError(`Image ${image.file.name} is larger than 3MB`);
        }
      });
    },

    onDragOver: () => {
      setIsDragOver(true);
    },

    onDragLeave: () => {
      setIsDragOver(false);
    },
  });

  return (
    <>
      <div className={"d-flex"}>
        {imgSrc ? (
          <div className={"position-relative"} style={{ marginRight: 8 }}>
            <button
              className={"delete-image-button"}
              onClick={() => setFieldValue("image", null)}
            >
              <i className={"uil uil-times-circle delete-icon"} />
            </button>
            <img
              src={imgSrc}
              width={108}
              height={87}
              className={"image-view"}
              alt="market cover image"
            />
          </div>
        ) : null}
        {imageUploaded && (
          <div className={"position-relative"}>
            <button
              className={"delete-image-button"}
              onClick={() => setImageUploaded(null)}
            >
              <i className={"uil uil-times-circle delete-icon"} />
            </button>
            <img
              src={imageUploaded.uri}
              width={108}
              height={87}
              className={"image-view"}
              alt="market cover image"
            />
          </div>
        )}
      </div>
      <div
        {...getRootProps({
          className:
            "dropzone-box-image-section" + (isDragOver ? " drag-over" : ""),
        })}
      >
        <input {...getInputProps()} />
        <div className={"title"}>
          Drop your image files here or <br />
          click to browse from your computer
        </div>
        <div className={"secondary-title"}>
          JPG, GIF & PNG. Max file size of 3MB
        </div>
        {/*<div className={"secondary-title"}>Maximum 10 images</div>*/}
      </div>
    </>
  );
};

export default UploadImageSection;
