import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import { userCompanyChooseSelector } from "../../../../User/selectors/UserSelectors";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../../App/configs/Constants";
import TableCustom from "../../../../../components/Table";
import { contractPaymentService } from "../../../services/DashboardServices";
import moment from "moment-timezone";
import { FORMAT_DATE_VARIANT_2 } from "../../../../App/configs/DateTimeConfigs";
import {
  dashboardLeaseFilterSelector,
  filterListPropertySelectedSelector,
} from "../../../selectors/DashboardSelectors";
import { dashboardLeaseFilterAction } from "../../../actions/DashboardActions";

const OfficeLeases = () => {
  const { translations } = useContext(LocalizationContext);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const userCompanyChoose = useSelector(userCompanyChooseSelector);
  const dashboardLeaseFilter = useSelector(dashboardLeaseFilterSelector);
  const filterListPropertySelected = useSelector(
    filterListPropertySelectedSelector
  );
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await contractPaymentService({
          ...dashboardLeaseFilter,
          company_id: userCompanyChoose.company_id,
          properties: filterListPropertySelected || [],
        });
        setData(data);
      } catch (e) {
        console.log("e", e);
      } finally {
        setLoading(false);
      }
    })();
  }, [dashboardLeaseFilter, userCompanyChoose, filterListPropertySelected]);

  const _formatInputData = () => {
    return data?.items?.map(
      ({
        code,
        price,
        name,
        property_name,
        company_name,
        status_name,
        time_period,
        timezone,
        payment_date,
      }: any) => {
        return {
          name,
          status_name,
          property_name,
          date: time_period,
          price,
          payment_date: payment_date
            ? moment(payment_date * 1000).format(FORMAT_DATE_VARIANT_2)
            : "",
        };
      }
    );
  };

  const columns = [
    {
      key: "name",
      col: "Private Office Transactions",
    },
    { key: "status", col: "Status" },
    { key: "property_name", col: "Property" },
    { key: "time_period", col: "Time Period" },
    { key: "price", col: "Price" },
    { key: "payment_date", col: "Payment Date" },
  ];

  const _onChangeSortCol = (col: any) => {
    if (dashboardLeaseFilter.sort_by !== col.key) {
      dispatch(dashboardLeaseFilterAction({ key: "sort_by", value: col.key }));
      dispatch(
        dashboardLeaseFilterAction({ key: "sort_type", value: ORDER_BY_ASC })
      );
    } else {
      if (dashboardLeaseFilter.sort_type === ORDER_BY_NONE) {
        dispatch(
          dashboardLeaseFilterAction({ key: "sort_by", value: col.key })
        );
        dispatch(
          dashboardLeaseFilterAction({ key: "sort_type", value: ORDER_BY_ASC })
        );
      }
      if (dashboardLeaseFilter.sort_type === ORDER_BY_ASC) {
        dispatch(
          dashboardLeaseFilterAction({ key: "sort_by", value: col.key })
        );
        dispatch(
          dashboardLeaseFilterAction({ key: "sort_type", value: ORDER_BY_DESC })
        );
      }
      if (dashboardLeaseFilter.sort_type === ORDER_BY_DESC) {
        dispatch(
          dashboardLeaseFilterAction({ key: "sort_by", value: col.key })
        );
        dispatch(
          dashboardLeaseFilterAction({ key: "sort_type", value: ORDER_BY_NONE })
        );
      }
    }
  };

  return (
    <TableCustom
      orderBy={dashboardLeaseFilter.sort_type}
      sortBy={dashboardLeaseFilter.sort_by}
      sortTable
      onChangeSortCol={_onChangeSortCol}
      tableData={data?.items ? _formatInputData() : []}
      columns={columns}
      loading={loading}
      totalItems={data?.pagination?.total}
      currentPage={data?.pagination?.page}
      showTotal
      unit={translations.transactions}
      itemPerPage={data?.pagination?.limit}
      onChangePagination={(e: any, { activePage }: any) => {
        dispatch(
          dashboardLeaseFilterAction({ key: "page", value: activePage })
        );
      }}
    />
  );
};

export default OfficeLeases;
