import { Routes, Route } from "react-router-dom";
import Setting from "../views/Setting";
import {
  AFFILIATE_LINK,
  BILLING_LINK,
  DOWNLOAD_INVOICE_LINK,
  INTEGRATIONS_LINK,
  INVOICE_DETAIL_DESKIMO_ADMIN_LINK,
  INVOICE_DETAIL_LINK,
  INVOICE_DETAIL_PROPERTY_LINK,
  INVOICE_PARTNER_LINK,
  INVOICE_PROPERTY_LINK,
  PARTNER_INVOICE_DETAIL_LINK,
  PRE_PAID_LINK,
  SETTING_LINK,
} from "../../App/configs/ListRoutes";
import Invoice from "../views/Invoice";
import InvoicePropertyDetail from "../views/InvoicePropertyDetail";
import PropertyInvoice from "../views/InvoicePropertyList";
import PartnerInvoice from "../views/InvoicePartnerList";
import DownloadInvoice from "../views/DownloadInvoice";

const SettingRoutes = () => {
  return (
    <Routes>
      <Route path={SETTING_LINK} element={<Setting tabIndex={0} />} key={1} />
      <Route path={PRE_PAID_LINK} element={<Setting tabIndex={1} />} key={2} />
      <Route path={BILLING_LINK} element={<Setting tabIndex={2} />} key={3} />
      <Route
        path={INTEGRATIONS_LINK}
        element={<Setting tabIndex={3} />}
        key={4}
      />
      <Route path={AFFILIATE_LINK} element={<Setting tabIndex={4} />} key={5} />

      <Route path={INVOICE_DETAIL_LINK} element={<Invoice />} />
      <Route path={INVOICE_DETAIL_DESKIMO_ADMIN_LINK} element={<Invoice />} />
      <Route path={INVOICE_PROPERTY_LINK} element={<PropertyInvoice />} />
      <Route
        path={INVOICE_DETAIL_PROPERTY_LINK}
        element={<InvoicePropertyDetail />}
      />

      <Route path={INVOICE_PARTNER_LINK} element={<PartnerInvoice />} />
      <Route path={PARTNER_INVOICE_DETAIL_LINK} element={<Invoice />} />
      <Route path={DOWNLOAD_INVOICE_LINK} element={<DownloadInvoice />} />
    </Routes>
  );
};

export default SettingRoutes;
