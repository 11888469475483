import MasterTemplate from "../../../App/views/MasterTemplate";
import "./style.scss";
import { Tab } from "semantic-ui-react";
import React, { useContext, useEffect, useState } from "react";
import { Link, Route, useParams } from "react-router-dom";
import {
  PROPERTY_DETAIL_INTEGRATIONS_LINK,
  PROPERTY_DETAIL_LINK,
  PROPERTY_DETAIL_MEETING_ROOM_LINK,
  PROPERTY_DETAIL_PRIVATE_OFFICES_LINK,
  PROPERTY_DETAIL_REWARDS_LINK,
  PROPERTY_DETAIL_USER_REVIEW_LINK,
} from "../../../App/configs/ListRoutes";
import { LocalizationContext } from "../../../../locales/Translation";
import DetailsTab from "./Components/DetailsTab";
import UserReviewTab from "./Components/UserReviewTab";
import MeetingRoomTab from "./Components/MeetingRoomTab";
import { useDispatch, useSelector } from "react-redux";
import {
  ROLE_DESKIMO_ADMIN_ID,
  ROLE_PROPERTY_ADMIN_ID,
  ROLE_PROPERTY_STAFF_ID,
} from "../../../User/configs/Constants";
import { propertyDetailSelector } from "../../selectors/PropertySelector";
import IntegrationsTab from "./Components/IntegrationsTab";
import { CURRENT_COMPANY_ID } from "../../../../configs/Constants";
import { getPropertyDetailThunk } from "../../thunks/PropertyThunks";
import PrivateOfficesTab from "./Components/PrivateOfficesTab";
import { findTabIndexByName } from "../../../../utils/commons";
import RewardsTab from "./Components/RewardsTab";
import { MyProfileType } from "../../../User/configs/DeclareTypes";
import { myProfileSelector } from "../../../App/selectors/AppSelectors";

type Props = {
  tabName: string;
};
const PropertyDetail = ({ tabName }: Props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { translations } = useContext(LocalizationContext);
  const propertyDetail = useSelector(propertyDetailSelector);
  const companyId = localStorage.getItem(CURRENT_COMPANY_ID);
  const [loading, setLoading] = useState(false);
  const myProfile: MyProfileType = useSelector(myProfileSelector);

  useEffect(() => {
    (async () => _getPropertyDetail())();
  }, []);

  const _getPropertyDetail = async () => {
    try {
      await dispatch<any>(getPropertyDetailThunk(id ? id : ""));
    } catch (e) {
      console.log(e);
    }
  };

  const panes = [
    {
      name: PROPERTY_DETAIL_LINK,
      menuItem: {
        as: Link,
        id: "tab1",
        to: PROPERTY_DETAIL_LINK.replaceAll(":id", id ? id : ""),
        content: translations.propertyPage.details,
        exact: true,
      },
      pane: <Route path={PROPERTY_DETAIL_LINK} />,
      render: () => <DetailsTab loading={loading} setLoading={setLoading} />,
    },
    {
      name: PROPERTY_DETAIL_USER_REVIEW_LINK,
      menuItem: {
        as: Link,
        id: "tab2",
        to: PROPERTY_DETAIL_USER_REVIEW_LINK.replaceAll(":id", id ? id : ""),
        content: translations.propertyPage.userReviews,
        exact: true,
      },
      pane: <Route path={PROPERTY_DETAIL_USER_REVIEW_LINK} />,
      render: () => <UserReviewTab />,
    },
    {
      name: PROPERTY_DETAIL_MEETING_ROOM_LINK,
      menuItem: {
        as: Link,
        id: "tab3",
        to: PROPERTY_DETAIL_MEETING_ROOM_LINK.replaceAll(":id", id ? id : ""),
        content: translations.meetingRooms,
        exact: true,
      },
      pane: <Route path={PROPERTY_DETAIL_MEETING_ROOM_LINK} />,
      render: () => <MeetingRoomTab />,
    },
    {
      name: PROPERTY_DETAIL_PRIVATE_OFFICES_LINK,
      menuItem: {
        as: Link,
        id: "privateOffice",
        to: PROPERTY_DETAIL_PRIVATE_OFFICES_LINK.replaceAll(
          ":id",
          id || ""
        ).replaceAll(":companyId", companyId || ""),
        content: "Private Offices",
        exact: true,
      },
      pane: <Route path={PROPERTY_DETAIL_PRIVATE_OFFICES_LINK} />,
      render: () => <PrivateOfficesTab />,
    },
  ];

  if (propertyDetail?.has_workspace_integration) {
    panes.push({
      name: PROPERTY_DETAIL_INTEGRATIONS_LINK,
      menuItem: {
        as: Link,
        id: "tab4",
        to: PROPERTY_DETAIL_INTEGRATIONS_LINK.replaceAll(
          ":id",
          id || ""
        ).replaceAll(":companyId", companyId || ""),
        content: "Integrations",
        exact: true,
      },
      pane: <Route path={PROPERTY_DETAIL_INTEGRATIONS_LINK} />,
      render: () => <IntegrationsTab />,
    });
  }

  if (
    myProfile.role_id == ROLE_DESKIMO_ADMIN_ID ||
    myProfile.role_id == ROLE_PROPERTY_ADMIN_ID
  ) {
    panes.push({
      name: PROPERTY_DETAIL_REWARDS_LINK,
      menuItem: {
        as: Link,
        id: "tab6",
        to: PROPERTY_DETAIL_REWARDS_LINK.replaceAll(":id", id || "").replaceAll(
          ":companyId",
          companyId || ""
        ),
        content: "Rewards",
        exact: true,
      },
      pane: <Route path={PROPERTY_DETAIL_REWARDS_LINK} />,
      render: () => <RewardsTab />,
    });
  }

  const defaultIndex = findTabIndexByName(panes, tabName);

  return (
    <MasterTemplate
      containerClass={"position-relative"}
      roleAccept={[
        ROLE_PROPERTY_ADMIN_ID,
        ROLE_DESKIMO_ADMIN_ID,
        ROLE_PROPERTY_STAFF_ID,
      ]}
    >
      <Tab
        menu={{ text: true }}
        activeIndex={defaultIndex}
        panes={panes}
        className={"tab-custom property-tabs"}
      />
    </MasterTemplate>
  );
};

export default PropertyDetail;
