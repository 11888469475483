import React from "react";
import DropdownWithCheckboxMenuWithTotal from "../DropdownWithCheckboxMenuWithTotal";
import { PUBLIC_URL } from "../../modules/App/configs/Constants";
import { useSelector } from "react-redux";
import { globalSettingSelector } from "../../modules/App/selectors/AppSelectors";

type Props = {
  values: string[];
  onChange: any;
  isSubmitting?: boolean;
};

const MarketFilterMultiple = ({ values, onChange, isSubmitting }: Props) => {
  const globalSetting = useSelector(globalSettingSelector);

  const decorateOptions = () => {
    if (!globalSetting) {
      return [];
    }

    return globalSetting?.location.map(
      ({ city_code, city_name }: { city_code: string; city_name: string }) => {
        return {
          id: city_code,
          name: city_name,
        };
      }
    );
  };

  const _onChange = (event: any, { value }: { value: string }) => {
    let temp: string[] = [...values];
    const index = temp.findIndex((name) => name === value);

    if (index !== -1 && temp.length < 2) {
      return;
    }

    if (index === -1) {
      temp.push(value);
    } else {
      temp = temp.filter((code) => code !== value);
    }

    onChange(temp);
  };

  const _checkAll = () => {
    onChange([...decorateOptions().map((option: any) => option.id)]);
  };

  const _clearAll = () => {
    onChange([]);
  };

  return (
    <DropdownWithCheckboxMenuWithTotal
      disabled={isSubmitting}
      values={values}
      listCheckBox={decorateOptions()}
      label={"Market Filter"}
      onChange={_onChange}
      icon={
        <img
          alt={"Deskimo"}
          src={PUBLIC_URL + "/assets/icons/icon-filter.svg"}
        />
      }
      checkAll={_checkAll}
      clearAll={_clearAll}
    />
  );
};

export default MarketFilterMultiple;
