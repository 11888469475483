export const API_CAMPAIGN_LIST = "/api/v1/des-admin/promotions";

export const API_CAMPAIGN_DETAIL = "/api/v1/des-admin/promotion/%id%";

export const API_CAMPAIGN = "/api/v1/des-admin/promotion/%id%";

export const API_AUTOCOMPLETE_MARKETS = "/api/v1/cities/autocomplete/%keyword%";
export const API_AUTOCOMPLETE_USERS = "/api/v1/users/autocomplete/%keyword%";
export const API_AUTOCOMPLETE_PROPERTIES =
  "/api/v1/properties/autocomplete?q=%keyword%";
export const API_AUTOCOMPLETE_COMPANIES =
  "/api/v1/companies/autocomplete/%keyword%";
export const API_REWARD = "/api/v3/ws-reward-redeem-request/%token%";
