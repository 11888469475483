import React, { useEffect } from "react";
import Account from "./Account";
import CostBreakDown from "./CostBreakdown";
import TotalCosts from "./TotalCosts";
import NumberOfBooking from "./NumberOfBooking";
import { dashboardFilterDateRangeSelector } from "../../../selectors/DashboardSelectors";
import { useDispatch, useSelector } from "react-redux";
import { dashboardStatisticThunk } from "../../../thunks/DashboardThunks";
import { userCompanyChooseSelector } from "../../../../User/selectors/UserSelectors";

type Props = {
  productType?: number;
};

const ChartSummary = ({ productType }: Props) => {
  const dispatch = useDispatch();
  const dashboardFilterDateRange = useSelector(
    dashboardFilterDateRangeSelector
  );
  const userCompanyChoose = useSelector(userCompanyChooseSelector);

  useEffect(() => {
    (async () => {
      try {
        if (dashboardFilterDateRange === null) {
          return;
        }

        dispatch<any>(dashboardStatisticThunk(productType));
      } catch (e) {
        console.log("e", e);
      }
    })();
  }, [dashboardFilterDateRange, userCompanyChoose]);

  return (
    <div className={"dashboard_chart_wrapper guide-dashboard_chart_wrapper"}>
      <TotalCosts />
      <CostBreakDown />
      <NumberOfBooking />
      <Account />
    </div>
  );
};

export default ChartSummary;
