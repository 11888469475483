export const DIALING_CODE = [
  {
    key: "aw",
    value: "+297",
    flag: "aw",
    text: "(+297) Aruba",
  },
  {
    key: "af",
    value: "+93",
    flag: "af",
    text: "(+93) Afghanistan",
  },
  {
    key: "ao",
    value: "+244",
    flag: "ao",
    text: "(+244) Angola",
  },
  {
    key: "ai",
    value: "+1264",
    flag: "ai",
    text: "(+1264) Anguilla",
  },
  {
    key: "ax",
    value: "+35818",
    flag: "ax",
    text: "(+35818) Åland Islands",
  },
  {
    key: "al",
    value: "+355",
    flag: "al",
    text: "(+355) Albania",
  },
  {
    key: "ad",
    value: "+376",
    flag: "ad",
    text: "(+376) Andorra",
  },
  {
    key: "ae",
    value: "+971",
    flag: "ae",
    text: "(+971) United Arab Emirates",
  },
  {
    key: "ar",
    value: "+54",
    flag: "ar",
    text: "(+54) Argentina",
  },
  {
    key: "am",
    value: "+374",
    flag: "am",
    text: "(+374) Armenia",
  },
  {
    key: "as",
    value: "+1684",
    flag: "as",
    text: "(+1684) American Samoa",
  },
  {
    key: "aq",
    value: "undefined",
    flag: "aq",
    text: "(undefined) Antarctica",
  },
  {
    key: "tf",
    value: "+262",
    flag: "tf",
    text: "(+262) French Southern and Antarctic Lands",
  },
  {
    key: "ag",
    value: "+1268",
    flag: "ag",
    text: "(+1268) Antigua and Barbuda",
  },
  {
    key: "au",
    value: "+61",
    flag: "au",
    text: "(+61) Australia",
  },
  {
    key: "at",
    value: "+43",
    flag: "at",
    text: "(+43) Austria",
  },
  {
    key: "az",
    value: "+994",
    flag: "az",
    text: "(+994) Azerbaijan",
  },
  {
    key: "bi",
    value: "+257",
    flag: "bi",
    text: "(+257) Burundi",
  },
  {
    key: "be",
    value: "+32",
    flag: "be",
    text: "(+32) Belgium",
  },
  {
    key: "bj",
    value: "+229",
    flag: "bj",
    text: "(+229) Benin",
  },
  {
    key: "bf",
    value: "+226",
    flag: "bf",
    text: "(+226) Burkina Faso",
  },
  {
    key: "bd",
    value: "+880",
    flag: "bd",
    text: "(+880) Bangladesh",
  },
  {
    key: "bg",
    value: "+359",
    flag: "bg",
    text: "(+359) Bulgaria",
  },
  {
    key: "bh",
    value: "+973",
    flag: "bh",
    text: "(+973) Bahrain",
  },
  {
    key: "bs",
    value: "+1242",
    flag: "bs",
    text: "(+1242) Bahamas",
  },
  {
    key: "ba",
    value: "+387",
    flag: "ba",
    text: "(+387) Bosnia and Herzegovina",
  },
  {
    key: "bl",
    value: "+590",
    flag: "bl",
    text: "(+590) Saint Barthélemy",
  },
  {
    key: "sh",
    value: "+290",
    flag: "sh",
    text: "(+290) Saint Helena, Ascension and Tristan da Cunha",
  },
  {
    key: "by",
    value: "+375",
    flag: "by",
    text: "(+375) Belarus",
  },
  {
    key: "bz",
    value: "+501",
    flag: "bz",
    text: "(+501) Belize",
  },
  {
    key: "bm",
    value: "+1441",
    flag: "bm",
    text: "(+1441) Bermuda",
  },
  {
    key: "bo",
    value: "+591",
    flag: "bo",
    text: "(+591) Bolivia",
  },
  {
    key: "bq",
    value: "+599",
    flag: "bq",
    text: "(+599) Caribbean Netherlands",
  },
  {
    key: "br",
    value: "+55",
    flag: "br",
    text: "(+55) Brazil",
  },
  {
    key: "bb",
    value: "+1246",
    flag: "bb",
    text: "(+1246) Barbados",
  },
  {
    key: "bn",
    value: "+673",
    flag: "bn",
    text: "(+673) Brunei",
  },
  {
    key: "bt",
    value: "+975",
    flag: "bt",
    text: "(+975) Bhutan",
  },
  {
    key: "bv",
    value: "+47",
    flag: "bv",
    text: "(+47) Bouvet Island",
  },
  {
    key: "bw",
    value: "+267",
    flag: "bw",
    text: "(+267) Botswana",
  },
  {
    key: "cf",
    value: "+236",
    flag: "cf",
    text: "(+236) Central African Republic",
  },
  {
    key: "ca",
    value: "+1",
    flag: "ca",
    text: "(+1) Canada",
  },
  {
    key: "cc",
    value: "+61",
    flag: "cc",
    text: "(+61) Cocos (Keeling) Islands",
  },
  {
    key: "ch",
    value: "+41",
    flag: "ch",
    text: "(+41) Switzerland",
  },
  {
    key: "cl",
    value: "+56",
    flag: "cl",
    text: "(+56) Chile",
  },
  {
    key: "cn",
    value: "+86",
    flag: "cn",
    text: "(+86) China",
  },
  {
    key: "ci",
    value: "+225",
    flag: "ci",
    text: "(+225) Ivory Coast",
  },
  {
    key: "cm",
    value: "+237",
    flag: "cm",
    text: "(+237) Cameroon",
  },
  {
    key: "cd",
    value: "+243",
    flag: "cd",
    text: "(+243) DR Congo",
  },
  {
    key: "cg",
    value: "+242",
    flag: "cg",
    text: "(+242) Republic of the Congo",
  },
  {
    key: "ck",
    value: "+682",
    flag: "ck",
    text: "(+682) Cook Islands",
  },
  {
    key: "co",
    value: "+57",
    flag: "co",
    text: "(+57) Colombia",
  },
  {
    key: "km",
    value: "+269",
    flag: "km",
    text: "(+269) Comoros",
  },
  {
    key: "cv",
    value: "+238",
    flag: "cv",
    text: "(+238) Cape Verde",
  },
  {
    key: "cr",
    value: "+506",
    flag: "cr",
    text: "(+506) Costa Rica",
  },
  {
    key: "cu",
    value: "+53",
    flag: "cu",
    text: "(+53) Cuba",
  },
  {
    key: "cw",
    value: "+599",
    flag: "cw",
    text: "(+599) Curaçao",
  },
  {
    key: "cx",
    value: "+61",
    flag: "cx",
    text: "(+61) Christmas Island",
  },
  {
    key: "ky",
    value: "+1345",
    flag: "ky",
    text: "(+1345) Cayman Islands",
  },
  {
    key: "cy",
    value: "+357",
    flag: "cy",
    text: "(+357) Cyprus",
  },
  {
    key: "cz",
    value: "+420",
    flag: "cz",
    text: "(+420) Czechia",
  },
  {
    key: "de",
    value: "+49",
    flag: "de",
    text: "(+49) Germany",
  },
  {
    key: "dj",
    value: "+253",
    flag: "dj",
    text: "(+253) Djibouti",
  },
  {
    key: "dm",
    value: "+1767",
    flag: "dm",
    text: "(+1767) Dominica",
  },
  {
    key: "dk",
    value: "+45",
    flag: "dk",
    text: "(+45) Denmark",
  },
  {
    key: "do",
    value: "+1809",
    flag: "do",
    text: "(+1809) Dominican Republic",
  },
  {
    key: "dz",
    value: "+213",
    flag: "dz",
    text: "(+213) Algeria",
  },
  {
    key: "ec",
    value: "+593",
    flag: "ec",
    text: "(+593) Ecuador",
  },
  {
    key: "eg",
    value: "+20",
    flag: "eg",
    text: "(+20) Egypt",
  },
  {
    key: "er",
    value: "+291",
    flag: "er",
    text: "(+291) Eritrea",
  },
  {
    key: "eh",
    value: "+2125288",
    flag: "eh",
    text: "(+2125288) Western Sahara",
  },
  {
    key: "es",
    value: "+34",
    flag: "es",
    text: "(+34) Spain",
  },
  {
    key: "ee",
    value: "+372",
    flag: "ee",
    text: "(+372) Estonia",
  },
  {
    key: "et",
    value: "+251",
    flag: "et",
    text: "(+251) Ethiopia",
  },
  {
    key: "fi",
    value: "+358",
    flag: "fi",
    text: "(+358) Finland",
  },
  {
    key: "fj",
    value: "+679",
    flag: "fj",
    text: "(+679) Fiji",
  },
  {
    key: "fk",
    value: "+500",
    flag: "fk",
    text: "(+500) Falkland Islands",
  },
  {
    key: "fr",
    value: "+33",
    flag: "fr",
    text: "(+33) France",
  },
  {
    key: "fo",
    value: "+298",
    flag: "fo",
    text: "(+298) Faroe Islands",
  },
  {
    key: "fm",
    value: "+691",
    flag: "fm",
    text: "(+691) Micronesia",
  },
  {
    key: "ga",
    value: "+241",
    flag: "ga",
    text: "(+241) Gabon",
  },
  {
    key: "gb",
    value: "+44",
    flag: "gb",
    text: "(+44) United Kingdom",
  },
  {
    key: "ge",
    value: "+995",
    flag: "ge",
    text: "(+995) Georgia",
  },
  {
    key: "gg",
    value: "+44",
    flag: "gg",
    text: "(+44) Guernsey",
  },
  {
    key: "gh",
    value: "+233",
    flag: "gh",
    text: "(+233) Ghana",
  },
  {
    key: "gi",
    value: "+350",
    flag: "gi",
    text: "(+350) Gibraltar",
  },
  {
    key: "gn",
    value: "+224",
    flag: "gn",
    text: "(+224) Guinea",
  },
  {
    key: "gp",
    value: "+590",
    flag: "gp",
    text: "(+590) Guadeloupe",
  },
  {
    key: "gm",
    value: "+220",
    flag: "gm",
    text: "(+220) Gambia",
  },
  {
    key: "gw",
    value: "+245",
    flag: "gw",
    text: "(+245) Guinea-Bissau",
  },
  {
    key: "gq",
    value: "+240",
    flag: "gq",
    text: "(+240) Equatorial Guinea",
  },
  {
    key: "gr",
    value: "+30",
    flag: "gr",
    text: "(+30) Greece",
  },
  {
    key: "gd",
    value: "+1473",
    flag: "gd",
    text: "(+1473) Grenada",
  },
  {
    key: "gl",
    value: "+299",
    flag: "gl",
    text: "(+299) Greenland",
  },
  {
    key: "gt",
    value: "+502",
    flag: "gt",
    text: "(+502) Guatemala",
  },
  {
    key: "gf",
    value: "+594",
    flag: "gf",
    text: "(+594) French Guiana",
  },
  {
    key: "gu",
    value: "+1671",
    flag: "gu",
    text: "(+1671) Guam",
  },
  {
    key: "gy",
    value: "+592",
    flag: "gy",
    text: "(+592) Guyana",
  },
  {
    key: "hk",
    value: "+852",
    flag: "hk",
    text: "(+852) Hong Kong",
  },
  {
    key: "hm",
    value: "",
    flag: "hm",
    text: "() Heard Island and McDonald Islands",
  },
  {
    key: "hn",
    value: "+504",
    flag: "hn",
    text: "(+504) Honduras",
  },
  {
    key: "hr",
    value: "+385",
    flag: "hr",
    text: "(+385) Croatia",
  },
  {
    key: "ht",
    value: "+509",
    flag: "ht",
    text: "(+509) Haiti",
  },
  {
    key: "hu",
    value: "+36",
    flag: "hu",
    text: "(+36) Hungary",
  },
  {
    key: "id",
    value: "+62",
    flag: "id",
    text: "(+62) Indonesia",
  },
  {
    key: "im",
    value: "+44",
    flag: "im",
    text: "(+44) Isle of Man",
  },
  {
    key: "in",
    value: "+91",
    flag: "in",
    text: "(+91) India",
  },
  {
    key: "io",
    value: "+246",
    flag: "io",
    text: "(+246) British Indian Ocean Territory",
  },
  {
    key: "ie",
    value: "+353",
    flag: "ie",
    text: "(+353) Ireland",
  },
  {
    key: "ir",
    value: "+98",
    flag: "ir",
    text: "(+98) Iran",
  },
  {
    key: "iq",
    value: "+964",
    flag: "iq",
    text: "(+964) Iraq",
  },
  {
    key: "is",
    value: "+354",
    flag: "is",
    text: "(+354) Iceland",
  },
  {
    key: "il",
    value: "+972",
    flag: "il",
    text: "(+972) Israel",
  },
  {
    key: "it",
    value: "+39",
    flag: "it",
    text: "(+39) Italy",
  },
  {
    key: "jm",
    value: "+1876",
    flag: "jm",
    text: "(+1876) Jamaica",
  },
  {
    key: "je",
    value: "+44",
    flag: "je",
    text: "(+44) Jersey",
  },
  {
    key: "jo",
    value: "+962",
    flag: "jo",
    text: "(+962) Jordan",
  },
  {
    key: "jp",
    value: "+81",
    flag: "jp",
    text: "(+81) Japan",
  },
  {
    key: "kz",
    value: "+76XX",
    flag: "kz",
    text: "(+76XX) Kazakhstan",
  },
  {
    key: "ke",
    value: "+254",
    flag: "ke",
    text: "(+254) Kenya",
  },
  {
    key: "kg",
    value: "+996",
    flag: "kg",
    text: "(+996) Kyrgyzstan",
  },
  {
    key: "kh",
    value: "+855",
    flag: "kh",
    text: "(+855) Cambodia",
  },
  {
    key: "ki",
    value: "+686",
    flag: "ki",
    text: "(+686) Kiribati",
  },
  {
    key: "kn",
    value: "+1869",
    flag: "kn",
    text: "(+1869) Saint Kitts and Nevis",
  },
  {
    key: "kr",
    value: "+82",
    flag: "kr",
    text: "(+82) South Korea",
  },
  {
    key: "xk",
    value: "+383",
    flag: "xk",
    text: "(+383) Kosovo",
  },
  {
    key: "kw",
    value: "+965",
    flag: "kw",
    text: "(+965) Kuwait",
  },
  {
    key: "la",
    value: "+856",
    flag: "la",
    text: "(+856) Laos",
  },
  {
    key: "lb",
    value: "+961",
    flag: "lb",
    text: "(+961) Lebanon",
  },
  {
    key: "lr",
    value: "+231",
    flag: "lr",
    text: "(+231) Liberia",
  },
  {
    key: "ly",
    value: "+218",
    flag: "ly",
    text: "(+218) Libya",
  },
  {
    key: "lc",
    value: "+1758",
    flag: "lc",
    text: "(+1758) Saint Lucia",
  },
  {
    key: "li",
    value: "+423",
    flag: "li",
    text: "(+423) Liechtenstein",
  },
  {
    key: "lk",
    value: "+94",
    flag: "lk",
    text: "(+94) Sri Lanka",
  },
  {
    key: "ls",
    value: "+266",
    flag: "ls",
    text: "(+266) Lesotho",
  },
  {
    key: "lt",
    value: "+370",
    flag: "lt",
    text: "(+370) Lithuania",
  },
  {
    key: "lu",
    value: "+352",
    flag: "lu",
    text: "(+352) Luxembourg",
  },
  {
    key: "lv",
    value: "+371",
    flag: "lv",
    text: "(+371) Latvia",
  },
  {
    key: "mo",
    value: "+853",
    flag: "mo",
    text: "(+853) Macau",
  },
  {
    key: "mf",
    value: "+590",
    flag: "mf",
    text: "(+590) Saint Martin",
  },
  {
    key: "ma",
    value: "+212",
    flag: "ma",
    text: "(+212) Morocco",
  },
  {
    key: "mc",
    value: "+377",
    flag: "mc",
    text: "(+377) Monaco",
  },
  {
    key: "md",
    value: "+373",
    flag: "md",
    text: "(+373) Moldova",
  },
  {
    key: "mg",
    value: "+261",
    flag: "mg",
    text: "(+261) Madagascar",
  },
  {
    key: "mv",
    value: "+960",
    flag: "mv",
    text: "(+960) Maldives",
  },
  {
    key: "mx",
    value: "+52",
    flag: "mx",
    text: "(+52) Mexico",
  },
  {
    key: "mh",
    value: "+692",
    flag: "mh",
    text: "(+692) Marshall Islands",
  },
  {
    key: "mk",
    value: "+389",
    flag: "mk",
    text: "(+389) North Macedonia",
  },
  {
    key: "ml",
    value: "+223",
    flag: "ml",
    text: "(+223) Mali",
  },
  {
    key: "mt",
    value: "+356",
    flag: "mt",
    text: "(+356) Malta",
  },
  {
    key: "mm",
    value: "+95",
    flag: "mm",
    text: "(+95) Myanmar",
  },
  {
    key: "me",
    value: "+382",
    flag: "me",
    text: "(+382) Montenegro",
  },
  {
    key: "mn",
    value: "+976",
    flag: "mn",
    text: "(+976) Mongolia",
  },
  {
    key: "mp",
    value: "+1670",
    flag: "mp",
    text: "(+1670) Northern Mariana Islands",
  },
  {
    key: "mz",
    value: "+258",
    flag: "mz",
    text: "(+258) Mozambique",
  },
  {
    key: "mr",
    value: "+222",
    flag: "mr",
    text: "(+222) Mauritania",
  },
  {
    key: "ms",
    value: "+1664",
    flag: "ms",
    text: "(+1664) Montserrat",
  },
  {
    key: "mq",
    value: "+596",
    flag: "mq",
    text: "(+596) Martinique",
  },
  {
    key: "mu",
    value: "+230",
    flag: "mu",
    text: "(+230) Mauritius",
  },
  {
    key: "mw",
    value: "+265",
    flag: "mw",
    text: "(+265) Malawi",
  },
  {
    key: "my",
    value: "+60",
    flag: "my",
    text: "(+60) Malaysia",
  },
  {
    key: "yt",
    value: "+262",
    flag: "yt",
    text: "(+262) Mayotte",
  },
  {
    key: "na",
    value: "+264",
    flag: "na",
    text: "(+264) Namibia",
  },
  {
    key: "nc",
    value: "+687",
    flag: "nc",
    text: "(+687) New Caledonia",
  },
  {
    key: "ne",
    value: "+227",
    flag: "ne",
    text: "(+227) Niger",
  },
  {
    key: "nf",
    value: "+672",
    flag: "nf",
    text: "(+672) Norfolk Island",
  },
  {
    key: "ng",
    value: "+234",
    flag: "ng",
    text: "(+234) Nigeria",
  },
  {
    key: "ni",
    value: "+505",
    flag: "ni",
    text: "(+505) Nicaragua",
  },
  {
    key: "nu",
    value: "+683",
    flag: "nu",
    text: "(+683) Niue",
  },
  {
    key: "nl",
    value: "+31",
    flag: "nl",
    text: "(+31) Netherlands",
  },
  {
    key: "no",
    value: "+47",
    flag: "no",
    text: "(+47) Norway",
  },
  {
    key: "np",
    value: "+977",
    flag: "np",
    text: "(+977) Nepal",
  },
  {
    key: "nr",
    value: "+674",
    flag: "nr",
    text: "(+674) Nauru",
  },
  {
    key: "nz",
    value: "+64",
    flag: "nz",
    text: "(+64) New Zealand",
  },
  {
    key: "om",
    value: "+968",
    flag: "om",
    text: "(+968) Oman",
  },
  {
    key: "pk",
    value: "+92",
    flag: "pk",
    text: "(+92) Pakistan",
  },
  {
    key: "pa",
    value: "+507",
    flag: "pa",
    text: "(+507) Panama",
  },
  {
    key: "pn",
    value: "+64",
    flag: "pn",
    text: "(+64) Pitcairn Islands",
  },
  {
    key: "pe",
    value: "+51",
    flag: "pe",
    text: "(+51) Peru",
  },
  {
    key: "ph",
    value: "+63",
    flag: "ph",
    text: "(+63) Philippines",
  },
  {
    key: "pw",
    value: "+680",
    flag: "pw",
    text: "(+680) Palau",
  },
  {
    key: "pg",
    value: "+675",
    flag: "pg",
    text: "(+675) Papua New Guinea",
  },
  {
    key: "pl",
    value: "+48",
    flag: "pl",
    text: "(+48) Poland",
  },
  {
    key: "pr",
    value: "+1787",
    flag: "pr",
    text: "(+1787) Puerto Rico",
  },
  {
    key: "kp",
    value: "+850",
    flag: "kp",
    text: "(+850) North Korea",
  },
  {
    key: "pt",
    value: "+351",
    flag: "pt",
    text: "(+351) Portugal",
  },
  {
    key: "py",
    value: "+595",
    flag: "py",
    text: "(+595) Paraguay",
  },
  {
    key: "ps",
    value: "+970",
    flag: "ps",
    text: "(+970) Palestine",
  },
  {
    key: "pf",
    value: "+689",
    flag: "pf",
    text: "(+689) French Polynesia",
  },
  {
    key: "qa",
    value: "+974",
    flag: "qa",
    text: "(+974) Qatar",
  },
  {
    key: "re",
    value: "+262",
    flag: "re",
    text: "(+262) Réunion",
  },
  {
    key: "ro",
    value: "+40",
    flag: "ro",
    text: "(+40) Romania",
  },
  {
    key: "ru",
    value: "+73XX",
    flag: "ru",
    text: "(+73XX) Russia",
  },
  {
    key: "rw",
    value: "+250",
    flag: "rw",
    text: "(+250) Rwanda",
  },
  {
    key: "sa",
    value: "+966",
    flag: "sa",
    text: "(+966) Saudi Arabia",
  },
  {
    key: "sd",
    value: "+249",
    flag: "sd",
    text: "(+249) Sudan",
  },
  {
    key: "sn",
    value: "+221",
    flag: "sn",
    text: "(+221) Senegal",
  },
  {
    key: "sg",
    value: "+65",
    flag: "sg",
    text: "(+65) Singapore",
  },
  {
    key: "gs",
    value: "+500",
    flag: "gs",
    text: "(+500) South Georgia",
  },
  {
    key: "sj",
    value: "+4779",
    flag: "sj",
    text: "(+4779) Svalbard and Jan Mayen",
  },
  {
    key: "sb",
    value: "+677",
    flag: "sb",
    text: "(+677) Solomon Islands",
  },
  {
    key: "sl",
    value: "+232",
    flag: "sl",
    text: "(+232) Sierra Leone",
  },
  {
    key: "sv",
    value: "+503",
    flag: "sv",
    text: "(+503) El Salvador",
  },
  {
    key: "sm",
    value: "+378",
    flag: "sm",
    text: "(+378) San Marino",
  },
  {
    key: "so",
    value: "+252",
    flag: "so",
    text: "(+252) Somalia",
  },
  {
    key: "pm",
    value: "+508",
    flag: "pm",
    text: "(+508) Saint Pierre and Miquelon",
  },
  {
    key: "rs",
    value: "+381",
    flag: "rs",
    text: "(+381) Serbia",
  },
  {
    key: "ss",
    value: "+211",
    flag: "ss",
    text: "(+211) South Sudan",
  },
  {
    key: "st",
    value: "+239",
    flag: "st",
    text: "(+239) São Tomé and Príncipe",
  },
  {
    key: "sr",
    value: "+597",
    flag: "sr",
    text: "(+597) Suriname",
  },
  {
    key: "sk",
    value: "+421",
    flag: "sk",
    text: "(+421) Slovakia",
  },
  {
    key: "si",
    value: "+386",
    flag: "si",
    text: "(+386) Slovenia",
  },
  {
    key: "se",
    value: "+46",
    flag: "se",
    text: "(+46) Sweden",
  },
  {
    key: "sz",
    value: "+268",
    flag: "sz",
    text: "(+268) Eswatini",
  },
  {
    key: "sx",
    value: "+1721",
    flag: "sx",
    text: "(+1721) Sint Maarten",
  },
  {
    key: "sc",
    value: "+248",
    flag: "sc",
    text: "(+248) Seychelles",
  },
  {
    key: "sy",
    value: "+963",
    flag: "sy",
    text: "(+963) Syria",
  },
  {
    key: "tc",
    value: "+1649",
    flag: "tc",
    text: "(+1649) Turks and Caicos Islands",
  },
  {
    key: "td",
    value: "+235",
    flag: "td",
    text: "(+235) Chad",
  },
  {
    key: "tg",
    value: "+228",
    flag: "tg",
    text: "(+228) Togo",
  },
  {
    key: "th",
    value: "+66",
    flag: "th",
    text: "(+66) Thailand",
  },
  {
    key: "tj",
    value: "+992",
    flag: "tj",
    text: "(+992) Tajikistan",
  },
  {
    key: "tk",
    value: "+690",
    flag: "tk",
    text: "(+690) Tokelau",
  },
  {
    key: "tm",
    value: "+993",
    flag: "tm",
    text: "(+993) Turkmenistan",
  },
  {
    key: "tl",
    value: "+670",
    flag: "tl",
    text: "(+670) Timor-Leste",
  },
  {
    key: "to",
    value: "+676",
    flag: "to",
    text: "(+676) Tonga",
  },
  {
    key: "tt",
    value: "+1868",
    flag: "tt",
    text: "(+1868) Trinidad and Tobago",
  },
  {
    key: "tn",
    value: "+216",
    flag: "tn",
    text: "(+216) Tunisia",
  },
  {
    key: "tr",
    value: "+90",
    flag: "tr",
    text: "(+90) Turkey",
  },
  {
    key: "tv",
    value: "+688",
    flag: "tv",
    text: "(+688) Tuvalu",
  },
  {
    key: "tw",
    value: "+886",
    flag: "tw",
    text: "(+886) Taiwan",
  },
  {
    key: "tz",
    value: "+255",
    flag: "tz",
    text: "(+255) Tanzania",
  },
  {
    key: "ug",
    value: "+256",
    flag: "ug",
    text: "(+256) Uganda",
  },
  {
    key: "ua",
    value: "+380",
    flag: "ua",
    text: "(+380) Ukraine",
  },
  {
    key: "um",
    value: "+268",
    flag: "um",
    text: "(+268) United States Minor Outlying Islands",
  },
  {
    key: "uy",
    value: "+598",
    flag: "uy",
    text: "(+598) Uruguay",
  },
  {
    key: "us",
    value: "+1",
    flag: "us",
    text: "(+1) United States",
  },
  {
    key: "uz",
    value: "+998",
    flag: "uz",
    text: "(+998) Uzbekistan",
  },
  {
    key: "va",
    value: "+3906698",
    flag: "va",
    text: "(+3906698) Vatican City",
  },
  {
    key: "vc",
    value: "+1784",
    flag: "vc",
    text: "(+1784) Saint Vincent and the Grenadines",
  },
  {
    key: "ve",
    value: "+58",
    flag: "ve",
    text: "(+58) Venezuela",
  },
  {
    key: "vg",
    value: "+1284",
    flag: "vg",
    text: "(+1284) British Virgin Islands",
  },
  {
    key: "vi",
    value: "+1340",
    flag: "vi",
    text: "(+1340) United States Virgin Islands",
  },
  {
    key: "vn",
    value: "+84",
    flag: "vn",
    text: "(+84) Vietnam",
  },
  {
    key: "vu",
    value: "+678",
    flag: "vu",
    text: "(+678) Vanuatu",
  },
  {
    key: "wf",
    value: "+681",
    flag: "wf",
    text: "(+681) Wallis and Futuna",
  },
  {
    key: "ws",
    value: "+685",
    flag: "ws",
    text: "(+685) Samoa",
  },
  {
    key: "ye",
    value: "+967",
    flag: "ye",
    text: "(+967) Yemen",
  },
  {
    key: "za",
    value: "+27",
    flag: "za",
    text: "(+27) South Africa",
  },
  {
    key: "zm",
    value: "+260",
    flag: "zm",
    text: "(+260) Zambia",
  },
  {
    key: "zw",
    value: "+263",
    flag: "zw",
    text: "(+263) Zimbabwe",
  },
];

export const CALLING_CODE = {
  AW: {
    primary: "Aruba",
    secondary: "+297",
  },
  AF: {
    primary: "Afghanistan",
    secondary: "+93",
  },
  AO: {
    primary: "Angola",
    secondary: "+244",
  },
  AI: {
    primary: "Anguilla",
    secondary: "+1264",
  },
  AX: {
    primary: "Åland Islands",
    secondary: "+35818",
  },
  AL: {
    primary: "Albania",
    secondary: "+355",
  },
  AD: {
    primary: "Andorra",
    secondary: "+376",
  },
  AE: {
    primary: "United Arab Emirates",
    secondary: "+971",
  },
  AR: {
    primary: "Argentina",
    secondary: "+54",
  },
  AM: {
    primary: "Armenia",
    secondary: "+374",
  },
  AS: {
    primary: "American Samoa",
    secondary: "+1684",
  },
  AQ: {
    primary: "Antarctica",
    secondary: "undefined",
  },
  TF: {
    primary: "French Southern and Antarctic Lands",
    secondary: "+262",
  },
  AG: {
    primary: "Antigua and Barbuda",
    secondary: "+1268",
  },
  AU: {
    primary: "Australia",
    secondary: "+61",
  },
  AT: {
    primary: "Austria",
    secondary: "+43",
  },
  AZ: {
    primary: "Azerbaijan",
    secondary: "+994",
  },
  BI: {
    primary: "Burundi",
    secondary: "+257",
  },
  BE: {
    primary: "Belgium",
    secondary: "+32",
  },
  BJ: {
    primary: "Benin",
    secondary: "+229",
  },
  BF: {
    primary: "Burkina Faso",
    secondary: "+226",
  },
  BD: {
    primary: "Bangladesh",
    secondary: "+880",
  },
  BG: {
    primary: "Bulgaria",
    secondary: "+359",
  },
  BH: {
    primary: "Bahrain",
    secondary: "+973",
  },
  BS: {
    primary: "Bahamas",
    secondary: "+1242",
  },
  BA: {
    primary: "Bosnia and Herzegovina",
    secondary: "+387",
  },
  BL: {
    primary: "Saint Barthélemy",
    secondary: "+590",
  },
  SH: {
    primary: "Saint Helena, Ascension and Tristan da Cunha",
    secondary: "+290",
  },
  BY: {
    primary: "Belarus",
    secondary: "+375",
  },
  BZ: {
    primary: "Belize",
    secondary: "+501",
  },
  BM: {
    primary: "Bermuda",
    secondary: "+1441",
  },
  BO: {
    primary: "Bolivia",
    secondary: "+591",
  },
  BQ: {
    primary: "Caribbean Netherlands",
    secondary: "+599",
  },
  BR: {
    primary: "Brazil",
    secondary: "+55",
  },
  BB: {
    primary: "Barbados",
    secondary: "+1246",
  },
  BN: {
    primary: "Brunei",
    secondary: "+673",
  },
  BT: {
    primary: "Bhutan",
    secondary: "+975",
  },
  BV: {
    primary: "Bouvet Island",
    secondary: "+47",
  },
  BW: {
    primary: "Botswana",
    secondary: "+267",
  },
  CF: {
    primary: "Central African Republic",
    secondary: "+236",
  },
  CA: {
    primary: "Canada",
    secondary: "+1",
  },
  CC: {
    primary: "Cocos (Keeling) Islands",
    secondary: "+61",
  },
  CH: {
    primary: "Switzerland",
    secondary: "+41",
  },
  CL: {
    primary: "Chile",
    secondary: "+56",
  },
  CN: {
    primary: "China",
    secondary: "+86",
  },
  CI: {
    primary: "Ivory Coast",
    secondary: "+225",
  },
  CM: {
    primary: "Cameroon",
    secondary: "+237",
  },
  CD: {
    primary: "DR Congo",
    secondary: "+243",
  },
  CG: {
    primary: "Republic of the Congo",
    secondary: "+242",
  },
  CK: {
    primary: "Cook Islands",
    secondary: "+682",
  },
  CO: {
    primary: "Colombia",
    secondary: "+57",
  },
  KM: {
    primary: "Comoros",
    secondary: "+269",
  },
  CV: {
    primary: "Cape Verde",
    secondary: "+238",
  },
  CR: {
    primary: "Costa Rica",
    secondary: "+506",
  },
  CU: {
    primary: "Cuba",
    secondary: "+53",
  },
  CW: {
    primary: "Curaçao",
    secondary: "+599",
  },
  CX: {
    primary: "Christmas Island",
    secondary: "+61",
  },
  KY: {
    primary: "Cayman Islands",
    secondary: "+1345",
  },
  CY: {
    primary: "Cyprus",
    secondary: "+357",
  },
  CZ: {
    primary: "Czechia",
    secondary: "+420",
  },
  DE: {
    primary: "Germany",
    secondary: "+49",
  },
  DJ: {
    primary: "Djibouti",
    secondary: "+253",
  },
  DM: {
    primary: "Dominica",
    secondary: "+1767",
  },
  DK: {
    primary: "Denmark",
    secondary: "+45",
  },
  DO: {
    primary: "Dominican Republic",
    secondary: "+1809",
  },
  DZ: {
    primary: "Algeria",
    secondary: "+213",
  },
  EC: {
    primary: "Ecuador",
    secondary: "+593",
  },
  EG: {
    primary: "Egypt",
    secondary: "+20",
  },
  ER: {
    primary: "Eritrea",
    secondary: "+291",
  },
  EH: {
    primary: "Western Sahara",
    secondary: "+2125288",
  },
  ES: {
    primary: "Spain",
    secondary: "+34",
  },
  EE: {
    primary: "Estonia",
    secondary: "+372",
  },
  ET: {
    primary: "Ethiopia",
    secondary: "+251",
  },
  FI: {
    primary: "Finland",
    secondary: "+358",
  },
  FJ: {
    primary: "Fiji",
    secondary: "+679",
  },
  FK: {
    primary: "Falkland Islands",
    secondary: "+500",
  },
  FR: {
    primary: "France",
    secondary: "+33",
  },
  FO: {
    primary: "Faroe Islands",
    secondary: "+298",
  },
  FM: {
    primary: "Micronesia",
    secondary: "+691",
  },
  GA: {
    primary: "Gabon",
    secondary: "+241",
  },
  GB: {
    primary: "United Kingdom",
    secondary: "+44",
  },
  GE: {
    primary: "Georgia",
    secondary: "+995",
  },
  GG: {
    primary: "Guernsey",
    secondary: "+44",
  },
  GH: {
    primary: "Ghana",
    secondary: "+233",
  },
  GI: {
    primary: "Gibraltar",
    secondary: "+350",
  },
  GN: {
    primary: "Guinea",
    secondary: "+224",
  },
  GP: {
    primary: "Guadeloupe",
    secondary: "+590",
  },
  GM: {
    primary: "Gambia",
    secondary: "+220",
  },
  GW: {
    primary: "Guinea-Bissau",
    secondary: "+245",
  },
  GQ: {
    primary: "Equatorial Guinea",
    secondary: "+240",
  },
  GR: {
    primary: "Greece",
    secondary: "+30",
  },
  GD: {
    primary: "Grenada",
    secondary: "+1473",
  },
  GL: {
    primary: "Greenland",
    secondary: "+299",
  },
  GT: {
    primary: "Guatemala",
    secondary: "+502",
  },
  GF: {
    primary: "French Guiana",
    secondary: "+594",
  },
  GU: {
    primary: "Guam",
    secondary: "+1671",
  },
  GY: {
    primary: "Guyana",
    secondary: "+592",
  },
  HK: {
    primary: "Hong Kong",
    secondary: "+852",
  },
  HM: {
    primary: "Heard Island and McDonald Islands",
    secondary: "",
  },
  HN: {
    primary: "Honduras",
    secondary: "+504",
  },
  HR: {
    primary: "Croatia",
    secondary: "+385",
  },
  HT: {
    primary: "Haiti",
    secondary: "+509",
  },
  HU: {
    primary: "Hungary",
    secondary: "+36",
  },
  ID: {
    primary: "Indonesia",
    secondary: "+62",
  },
  IM: {
    primary: "Isle of Man",
    secondary: "+44",
  },
  IN: {
    primary: "India",
    secondary: "+91",
  },
  IO: {
    primary: "British Indian Ocean Territory",
    secondary: "+246",
  },
  IE: {
    primary: "Ireland",
    secondary: "+353",
  },
  IR: {
    primary: "Iran",
    secondary: "+98",
  },
  IQ: {
    primary: "Iraq",
    secondary: "+964",
  },
  IS: {
    primary: "Iceland",
    secondary: "+354",
  },
  IL: {
    primary: "Israel",
    secondary: "+972",
  },
  IT: {
    primary: "Italy",
    secondary: "+39",
  },
  JM: {
    primary: "Jamaica",
    secondary: "+1876",
  },
  JE: {
    primary: "Jersey",
    secondary: "+44",
  },
  JO: {
    primary: "Jordan",
    secondary: "+962",
  },
  JP: {
    primary: "Japan",
    secondary: "+81",
  },
  KZ: {
    primary: "Kazakhstan",
    secondary: "+76XX",
  },
  KE: {
    primary: "Kenya",
    secondary: "+254",
  },
  KG: {
    primary: "Kyrgyzstan",
    secondary: "+996",
  },
  KH: {
    primary: "Cambodia",
    secondary: "+855",
  },
  KI: {
    primary: "Kiribati",
    secondary: "+686",
  },
  KN: {
    primary: "Saint Kitts and Nevis",
    secondary: "+1869",
  },
  KR: {
    primary: "South Korea",
    secondary: "+82",
  },
  XK: {
    primary: "Kosovo",
    secondary: "+383",
  },
  KW: {
    primary: "Kuwait",
    secondary: "+965",
  },
  LA: {
    primary: "Laos",
    secondary: "+856",
  },
  LB: {
    primary: "Lebanon",
    secondary: "+961",
  },
  LR: {
    primary: "Liberia",
    secondary: "+231",
  },
  LY: {
    primary: "Libya",
    secondary: "+218",
  },
  LC: {
    primary: "Saint Lucia",
    secondary: "+1758",
  },
  LI: {
    primary: "Liechtenstein",
    secondary: "+423",
  },
  LK: {
    primary: "Sri Lanka",
    secondary: "+94",
  },
  LS: {
    primary: "Lesotho",
    secondary: "+266",
  },
  LT: {
    primary: "Lithuania",
    secondary: "+370",
  },
  LU: {
    primary: "Luxembourg",
    secondary: "+352",
  },
  LV: {
    primary: "Latvia",
    secondary: "+371",
  },
  MO: {
    primary: "Macau",
    secondary: "+853",
  },
  MF: {
    primary: "Saint Martin",
    secondary: "+590",
  },
  MA: {
    primary: "Morocco",
    secondary: "+212",
  },
  MC: {
    primary: "Monaco",
    secondary: "+377",
  },
  MD: {
    primary: "Moldova",
    secondary: "+373",
  },
  MG: {
    primary: "Madagascar",
    secondary: "+261",
  },
  MV: {
    primary: "Maldives",
    secondary: "+960",
  },
  MX: {
    primary: "Mexico",
    secondary: "+52",
  },
  MH: {
    primary: "Marshall Islands",
    secondary: "+692",
  },
  MK: {
    primary: "North Macedonia",
    secondary: "+389",
  },
  ML: {
    primary: "Mali",
    secondary: "+223",
  },
  MT: {
    primary: "Malta",
    secondary: "+356",
  },
  MM: {
    primary: "Myanmar",
    secondary: "+95",
  },
  ME: {
    primary: "Montenegro",
    secondary: "+382",
  },
  MN: {
    primary: "Mongolia",
    secondary: "+976",
  },
  MP: {
    primary: "Northern Mariana Islands",
    secondary: "+1670",
  },
  MZ: {
    primary: "Mozambique",
    secondary: "+258",
  },
  MR: {
    primary: "Mauritania",
    secondary: "+222",
  },
  MS: {
    primary: "Montserrat",
    secondary: "+1664",
  },
  MQ: {
    primary: "Martinique",
    secondary: "+596",
  },
  MU: {
    primary: "Mauritius",
    secondary: "+230",
  },
  MW: {
    primary: "Malawi",
    secondary: "+265",
  },
  MY: {
    primary: "Malaysia",
    secondary: "+60",
  },
  YT: {
    primary: "Mayotte",
    secondary: "+262",
  },
  NA: {
    primary: "Namibia",
    secondary: "+264",
  },
  NC: {
    primary: "New Caledonia",
    secondary: "+687",
  },
  NE: {
    primary: "Niger",
    secondary: "+227",
  },
  NF: {
    primary: "Norfolk Island",
    secondary: "+672",
  },
  NG: {
    primary: "Nigeria",
    secondary: "+234",
  },
  NI: {
    primary: "Nicaragua",
    secondary: "+505",
  },
  NU: {
    primary: "Niue",
    secondary: "+683",
  },
  NL: {
    primary: "Netherlands",
    secondary: "+31",
  },
  NO: {
    primary: "Norway",
    secondary: "+47",
  },
  NP: {
    primary: "Nepal",
    secondary: "+977",
  },
  NR: {
    primary: "Nauru",
    secondary: "+674",
  },
  NZ: {
    primary: "New Zealand",
    secondary: "+64",
  },
  OM: {
    primary: "Oman",
    secondary: "+968",
  },
  PK: {
    primary: "Pakistan",
    secondary: "+92",
  },
  PA: {
    primary: "Panama",
    secondary: "+507",
  },
  PN: {
    primary: "Pitcairn Islands",
    secondary: "+64",
  },
  PE: {
    primary: "Peru",
    secondary: "+51",
  },
  PH: {
    primary: "Philippines",
    secondary: "+63",
  },
  PW: {
    primary: "Palau",
    secondary: "+680",
  },
  PG: {
    primary: "Papua New Guinea",
    secondary: "+675",
  },
  PL: {
    primary: "Poland",
    secondary: "+48",
  },
  PR: {
    primary: "Puerto Rico",
    secondary: "+1787",
  },
  KP: {
    primary: "North Korea",
    secondary: "+850",
  },
  PT: {
    primary: "Portugal",
    secondary: "+351",
  },
  PY: {
    primary: "Paraguay",
    secondary: "+595",
  },
  PS: {
    primary: "Palestine",
    secondary: "+970",
  },
  PF: {
    primary: "French Polynesia",
    secondary: "+689",
  },
  QA: {
    primary: "Qatar",
    secondary: "+974",
  },
  RE: {
    primary: "Réunion",
    secondary: "+262",
  },
  RO: {
    primary: "Romania",
    secondary: "+40",
  },
  RU: {
    primary: "Russia",
    secondary: "+73XX",
  },
  RW: {
    primary: "Rwanda",
    secondary: "+250",
  },
  SA: {
    primary: "Saudi Arabia",
    secondary: "+966",
  },
  SD: {
    primary: "Sudan",
    secondary: "+249",
  },
  SN: {
    primary: "Senegal",
    secondary: "+221",
  },
  SG: {
    primary: "Singapore",
    secondary: "+65",
  },
  GS: {
    primary: "South Georgia",
    secondary: "+500",
  },
  SJ: {
    primary: "Svalbard and Jan Mayen",
    secondary: "+4779",
  },
  SB: {
    primary: "Solomon Islands",
    secondary: "+677",
  },
  SL: {
    primary: "Sierra Leone",
    secondary: "+232",
  },
  SV: {
    primary: "El Salvador",
    secondary: "+503",
  },
  SM: {
    primary: "San Marino",
    secondary: "+378",
  },
  SO: {
    primary: "Somalia",
    secondary: "+252",
  },
  PM: {
    primary: "Saint Pierre and Miquelon",
    secondary: "+508",
  },
  RS: {
    primary: "Serbia",
    secondary: "+381",
  },
  SS: {
    primary: "South Sudan",
    secondary: "+211",
  },
  ST: {
    primary: "São Tomé and Príncipe",
    secondary: "+239",
  },
  SR: {
    primary: "Suriname",
    secondary: "+597",
  },
  SK: {
    primary: "Slovakia",
    secondary: "+421",
  },
  SI: {
    primary: "Slovenia",
    secondary: "+386",
  },
  SE: {
    primary: "Sweden",
    secondary: "+46",
  },
  SZ: {
    primary: "Eswatini",
    secondary: "+268",
  },
  SX: {
    primary: "Sint Maarten",
    secondary: "+1721",
  },
  SC: {
    primary: "Seychelles",
    secondary: "+248",
  },
  SY: {
    primary: "Syria",
    secondary: "+963",
  },
  TC: {
    primary: "Turks and Caicos Islands",
    secondary: "+1649",
  },
  TD: {
    primary: "Chad",
    secondary: "+235",
  },
  TG: {
    primary: "Togo",
    secondary: "+228",
  },
  TH: {
    primary: "Thailand",
    secondary: "+66",
  },
  TJ: {
    primary: "Tajikistan",
    secondary: "+992",
  },
  TK: {
    primary: "Tokelau",
    secondary: "+690",
  },
  TM: {
    primary: "Turkmenistan",
    secondary: "+993",
  },
  TL: {
    primary: "Timor-Leste",
    secondary: "+670",
  },
  TO: {
    primary: "Tonga",
    secondary: "+676",
  },
  TT: {
    primary: "Trinidad and Tobago",
    secondary: "+1868",
  },
  TN: {
    primary: "Tunisia",
    secondary: "+216",
  },
  TR: {
    primary: "Turkey",
    secondary: "+90",
  },
  TV: {
    primary: "Tuvalu",
    secondary: "+688",
  },
  TW: {
    primary: "Taiwan",
    secondary: "+886",
  },
  TZ: {
    primary: "Tanzania",
    secondary: "+255",
  },
  UG: {
    primary: "Uganda",
    secondary: "+256",
  },
  UA: {
    primary: "Ukraine",
    secondary: "+380",
  },
  UM: {
    primary: "United States Minor Outlying Islands",
    secondary: "+268",
  },
  UY: {
    primary: "Uruguay",
    secondary: "+598",
  },
  US: {
    primary: "United States",
    secondary: "+1",
  },
  UZ: {
    primary: "Uzbekistan",
    secondary: "+998",
  },
  VA: {
    primary: "Vatican City",
    secondary: "+3906698",
  },
  VC: {
    primary: "Saint Vincent and the Grenadines",
    secondary: "+1784",
  },
  VE: {
    primary: "Venezuela",
    secondary: "+58",
  },
  VG: {
    primary: "British Virgin Islands",
    secondary: "+1284",
  },
  VI: {
    primary: "United States Virgin Islands",
    secondary: "+1340",
  },
  VN: {
    primary: "Vietnam",
    secondary: "+84",
  },
  VU: {
    primary: "Vanuatu",
    secondary: "+678",
  },
  WF: {
    primary: "Wallis and Futuna",
    secondary: "+681",
  },
  WS: {
    primary: "Samoa",
    secondary: "+685",
  },
  YE: {
    primary: "Yemen",
    secondary: "+967",
  },
  ZA: {
    primary: "South Africa",
    secondary: "+27",
  },
  ZM: {
    primary: "Zambia",
    secondary: "+260",
  },
  ZW: {
    primary: "Zimbabwe",
    secondary: "+263",
  },
};
