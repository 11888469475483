import React, { useEffect, useState } from "react";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import DropdownWithCheckboxMenuWithTotal from "../../../../../../components/DropdownWithCheckboxMenuWithTotal";
import { getDeskimoUserReviewsFeedbackService } from "../../../../services/PropertyServices";
import { useParams } from "react-router-dom";

const Filter = ({ filter, setFilter }: any) => {
  const { id } = useParams();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await getDeskimoUserReviewsFeedbackService(String(id));
      let temp = data.map(({ icon_url, id, name, total_review }: any) => ({
        id,
        name: (
          <div className={"user-review-table_filter_item"}>
            <img src={icon_url} />
            <p>
              {name} ({total_review})
            </p>
          </div>
        ),
      }));

      temp = [{ id: 0, name: "Select All Comments" }, ...temp];

      setOptions(temp);
    })();
  }, []);

  const _onChange = (value: number) => {
    let servicesIds = filter.services_ids;

    const existed = servicesIds.includes(value);

    if (value === 0) {
      if (existed) {
        setFilter((prevState: any) => ({
          ...prevState,
          page: 1,
          services_ids: [],
        }));
      } else {
        setFilter((prevState: any) => ({
          ...prevState,
          page: 1,
          services_ids: options.map(({ id }: any) => id),
        }));
      }

      return;
    }

    if (existed) {
      servicesIds = servicesIds.filter((id: number) => id !== value);
    } else {
      servicesIds = [...servicesIds, value];
    }

    setFilter((prevState: any) => ({
      ...prevState,
      services_ids: servicesIds,
    }));
  };

  return (
    <DropdownWithCheckboxMenuWithTotal
      values={filter.services_ids}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      listCheckBox={options}
      label={"All Comments"}
      onChange={(e: any, { value }: { value: number }) => _onChange(value)}
      icon={
        <img
          alt={"Deskimo"}
          src={PUBLIC_URL + "/assets/icons/icon-filter.svg"}
        />
      }
      className={"user-review-table_filter"}
    />
  );
};

export default Filter;
