import React, { useContext } from "react";
import Label from "../../../../../components/Label";
import { useSelector } from "react-redux";
import { businessCompanyDetailSelector } from "../../../selectors/BusinessSelectors";
import { BusinessCompanyDetailType } from "../../../configs/DeclareTypes";
import Information from "./Information";
import { LocalizationContext } from "../../../../../locales/Translation";
import moment from "moment-timezone";
import { FORMAT_DATE_VARIANT_2 } from "../../../../App/configs/DateTimeConfigs";

const OnboardPromotion = () => {
  const businessCompanyDetail: BusinessCompanyDetailType = useSelector(
    businessCompanyDetailSelector
  );
  return (
    <>
      <div className={"company-detail__title"}>
        <div className="section-white-box_title">
          Onboarding promotion for marketplace
        </div>
      </div>
      <div className="section-white-box-column">
        <div>
          <Information
            label={"Free credit for each new user"}
            value={businessCompanyDetail.free_credit}
          />
          <Information
            label={"Free credit valid for"}
            value={businessCompanyDetail.free_credit_duration}
          />
          <Information
            label={"Force show top up"}
            value={
              businessCompanyDetail.force_credit_popup ? "Enabled" : "Disabled"
            }
          />
        </div>
        <div>
          <Information
            label={"Onboarding campaign starting"}
            value={
              businessCompanyDetail.onboard_campaign_start_time
                ? moment
                    .unix(businessCompanyDetail.onboard_campaign_start_time)
                    .format(FORMAT_DATE_VARIANT_2)
                : ""
            }
          />
          <Information
            label={"Onboarding campaign ending"}
            value={
              businessCompanyDetail.onboard_campaign_end_time
                ? moment
                    .unix(businessCompanyDetail.onboard_campaign_end_time)
                    .format(FORMAT_DATE_VARIANT_2)
                : ""
            }
          />
        </div>
      </div>
      <div className={"section-white-box_line"} />
    </>
  );
};

export default OnboardPromotion;
