import React, { useContext, useState } from "react";
import ButtonCustom from "../../../../../../components/Button";
import ModalAddEditNote from "./Components/ModalAddEditNote";
import "./styles.scss";
import List from "./Components/List";
import ModalNoteDetail from "./Components/ModalNoteDetail";
import TotalDisplay from "./Components/TotalDisplay";
import { LocalizationContext } from "../../../../../../locales/Translation";

const DeskimoNotes = () => {
  const { translations } = useContext(LocalizationContext);

  const [modalAddNote, setModalAddNote] = useState({
    open: false,
  });
  const [modalNoteDetail, setModalNoteDetail] = useState({
    open: false,
  });
  const [refresh, setRefresh] = useState(false);
  const [pagination, setPagination] = useState(null);

  return (
    <>
      <div className={"property-note-header"}>
        <TotalDisplay pagination={pagination} />
        <ButtonCustom
          className={"primary"}
          onClick={() => setModalAddNote({ open: true })}
        >
          <>
            {translations.addNotes}{" "}
            <i
              className="uil uil-plus"
              style={{ fontSize: 16, marginLeft: 8 }}
            />
          </>
        </ButtonCustom>
      </div>
      <List
        setModalAddNote={setModalAddNote}
        refresh={refresh}
        onRefresh={() => setRefresh((prevState) => !prevState)}
        setModalNoteDetail={setModalNoteDetail}
        setPagination={setPagination}
        pagination={pagination}
      />
      <ModalAddEditNote
        data={modalAddNote}
        onClose={() => setModalAddNote({ open: false })}
        onRefresh={() => setRefresh((prevState) => !prevState)}
      />
      <ModalNoteDetail
        data={modalNoteDetail}
        onClose={() => setModalNoteDetail({ open: false })}
      />
    </>
  );
};

export default DeskimoNotes;
