import { FormikErrors, FormikTouched, FormikValues } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { businessCompanyCreditService } from "../../../../services/BusinessServices";
import { debounce } from "lodash";
import Label from "../../../../../../components/Label";
import DropdownSearchServerSingle from "../../../../../../components/DropdownSearchServerSingle";
import { LocalizationContext } from "../../../../../../locales/Translation";

type Props = {
  values: FormikValues;
  setFieldValue: any;
  disabled: boolean;
  error:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
  touched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
};

const DropdownCompany = ({
  values,
  setFieldValue,
  disabled,
  error,
  touched,
}: Props) => {
  const { translations } = useContext(LocalizationContext);

  const [options, setOptions] = useState<
    {
      key: number;
      text: string;
      id: number;
    }[]
  >([]);

  useEffect(() => {
    (async () => {
      await _initOptions();
    })();
  }, []);

  const _initOptions = async (keyword = "") => {
    try {
      const { data } = await businessCompanyCreditService({ keyword });

      const temp = data.map(
        ({
          company_id,
          company_name,
        }: {
          company_id: number;
          company_name: string;
        }) => {
          return {
            key: company_id,
            text: company_name,
            id: company_id,
          };
        }
      );
      setOptions(temp);
    } catch (e) {
      console.log(e);
    }
  };

  const debounce_onChange = debounce(async (keyword: string) => {
    await _initOptions(keyword);
  }, 300);

  return (
    <>
      <Label
        label={translations.customPackages.companyLabel}
        required
        fontSize={"12px"}
        className={"sm"}
      />
      <DropdownSearchServerSingle
        value={{ text: values.company_name, id: values.company_id }}
        onSearch={(searchQuery) => debounce_onChange(searchQuery)}
        options={options}
        disabled={disabled}
        placeholder={"Select company"}
        onChange={(value) => {
          setFieldValue("company_id", value.id);
          setFieldValue("company_name", value.text);
        }}
        isFullWidth
        error={error}
        touched={touched}
      />
    </>
  );
};

export default DropdownCompany;
