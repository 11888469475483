import React from "react";
import Radio from "../Radio";
import "./style.scss";

type Props = {
  uri: string;
  label: string;
  checked: boolean;
  onClick: () => void;
};

const PMSIntegrationItemSelect = ({ uri, label, checked, onClick }: Props) => {
  return (
    <div onClick={onClick} className={"radio-select-wrapper"}>
      <Radio checked={checked} />
      {/*<div className={"d-flex align-items-center"}>*/}
      <img src={uri} width={"36px"} height={"36px"} alt={"socialmediaicon"} />
      <div className={"radio-select__label"}>{label}</div>
      {/*</div>*/}
    </div>
  );
};

export default PMSIntegrationItemSelect;
