import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../locales/Translation";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  BOOK_DESK_LINK,
  BOOK_INQUIRE_OFFICE_LINK,
} from "../../../App/configs/ListRoutes";
import {
  clipboardCopyWithAsyncFunction,
  sharePrivateOfficeMessage,
} from "../../services/Common";
import { readMoreRatingService } from "../../services/BookServices";
import { RATING_ITEM_PER_PAGE } from "../../configs/Constants";
import { propertyDetailAction } from "../../actions/BookActions";
import { toastError, toastSuccess } from "../../../../components/Toast";
import MasterTemplate from "../../../App/views/MasterTemplate";
import {
  ROLE_BUSINESS_ADMIN_ID,
  ROLE_BUSINESS_USER_ID,
} from "../../../User/configs/Constants";
import { Dimmer, Loader } from "semantic-ui-react";
import SectionWhiteBox from "../../../../components/SectionWhiteBox";
import Thumbnail from "../BookProperty/Component/Thumbnail";
import Amenities from "../BookProperty/Component/Amenities";
import Schedule from "../BookProperty/Component/Schedule";
import Copy from "../BookProperty/Component/Copy";
import Rating from "../BookProperty/Component/Rating";
import Address from "../BookProperty/Component/Address";
import InquirePrivateOfficeForm from "../InquirePrivateOffice/InquireForm";
import { privateOfficeDetailService } from "../../../Property/services/PropertyServices";
import Price from "./Price";

const BookInquire = () => {
  const { translations } = useContext(LocalizationContext);
  const { slug } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [readMoreLoading, setReadMoreLoading] = useState<boolean>(false);
  const [isReadMore, setIsReadMore] = useState(true);

  const [privateOfficeDetail, setPrivateOfficeDetail] = useState<any>(null);

  useEffect(() => {
    (async () => {
      try {
        if (!slug) {
          return;
        }

        const body = document.querySelector("body");
        if (body) {
          body.classList.remove("prevent-scroll");
        }
        setLoading(true);
        const { data } = await privateOfficeDetailService(slug);
        setPrivateOfficeDetail(data);
      } catch (e: any) {
        navigate(BOOK_INQUIRE_OFFICE_LINK);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (privateOfficeDetail?.rating?.user_review?.length < 3) {
      setIsReadMore(false);
    }
  }, [privateOfficeDetail]);

  const _share = () =>
    sharePrivateOfficeMessage(translations, privateOfficeDetail.web_url);

  const _handleReadMore = async () => {
    setReadMoreLoading(true);
    try {
      const payload = {
        last_id: privateOfficeDetail?.rating?.user_review.slice(-1)[0]?.id,
        property_id: privateOfficeDetail?.id,
      };
      const response = await readMoreRatingService(payload);

      if (
        response?.data?.length === 0 ||
        response?.data?.length < RATING_ITEM_PER_PAGE
      ) {
        setIsReadMore(false);
      }

      const newPropertyDetailData = JSON.parse(
        JSON.stringify(privateOfficeDetail)
      );
      if (newPropertyDetailData && response?.data?.length > 0) {
        newPropertyDetailData.rating.user_review = [
          ...newPropertyDetailData.rating.user_review,
          ...response.data,
        ];
      }

      dispatch(propertyDetailAction(newPropertyDetailData));
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setReadMoreLoading(false);
    }
  };

  return (
    <MasterTemplate
      roleAccept={[ROLE_BUSINESS_USER_ID, ROLE_BUSINESS_ADMIN_ID]}
    >
      {loading ? (
        <Dimmer active>
          <Loader />
        </Dimmer>
      ) : (
        <>
          <SectionWhiteBox isShowBack backRoute={BOOK_INQUIRE_OFFICE_LINK}>
            <div className={"book-detail__name-wrapper"}>
              <h2 className={"book-detail__name"}>
                {privateOfficeDetail?.name}
              </h2>
            </div>
            <div className={"book-detail__description-wrapper"}>
              <div className={"book-detail__description"}>
                <span>
                  <i className="uil uil-star" />
                  {privateOfficeDetail?.rating?.star} (
                  {privateOfficeDetail?.rating.total}{" "}
                  {privateOfficeDetail?.rating?.total < 2
                    ? "review"
                    : "reviews"}
                  )
                </span>
                {privateOfficeDetail?.address ? (
                  <>
                    <span className={"dot"}>•</span>
                    <span>
                      <i className="uil uil-map" />
                      {privateOfficeDetail?.address}
                    </span>
                  </>
                ) : null}
              </div>
              <button
                className={
                  "book-detail__description book-detail__description-share"
                }
                onClick={() => {
                  clipboardCopyWithAsyncFunction(_share);
                  toastSuccess(translations.copyToClipboard);
                }}
              >
                <i className="uil uil-upload"></i>
                &nbsp;&nbsp;Share
              </button>
            </div>
            <Thumbnail thumbnail={privateOfficeDetail?.images} />
            <div className={"book-detail__book-section"}>
              <div>
                <Price
                  unit={"Month"}
                  originalPrice={
                    privateOfficeDetail?.original_monthly_rate
                      ? `${privateOfficeDetail?.formatted_original_monthly_rate}`
                      : ""
                  }
                  price={`${privateOfficeDetail?.formatted_monthly_rate}`}
                />
                <Amenities amenities={privateOfficeDetail?.amenities} />
                <Schedule propertyDetail={privateOfficeDetail} />
                {/*<Copy title={"About"} content={privateOfficeDetail?.about} />*/}
                <Copy
                  title={"House Rules"}
                  content={privateOfficeDetail?.house_rule}
                />
                <Copy
                  title={"Cancellation Policy"}
                  content={privateOfficeDetail?.cancellation_policy}
                />
                <Rating
                  rating={privateOfficeDetail?.rating}
                  handleReadMore={_handleReadMore}
                  isReadMore={isReadMore}
                  readMoreLoading={readMoreLoading}
                />
              </div>
              <div>
                <div className={"book-detail__booking-wrapper"}>
                  <InquirePrivateOfficeForm
                    moreInfo={{
                      privateOfficeId: privateOfficeDetail.private_office_id,
                      currency: privateOfficeDetail?.currency,
                      timezone: privateOfficeDetail?.timezone,
                      capacity: privateOfficeDetail?.capacity,
                    }}
                  />
                </div>
              </div>
            </div>
            <Address
              lat={privateOfficeDetail?.lat}
              lng={privateOfficeDetail?.lng}
            />
          </SectionWhiteBox>
        </>
      )}
    </MasterTemplate>
  );
};

export default BookInquire;
