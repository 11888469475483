import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";

type Props = {
  data: any;
};

const Revenue = ({ data }: Props) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <div className={"dashboard_chart_box_wrapper_3"}>
      <div className={"dashboard_chart_box"}>
        <h2 className={"dashboard_chart_title"}>
          {translations.revenue} ({data?.currency})
        </h2>
        <div className={"dashboard_chart_inner dashboard_chart_inner__center"}>
          <span className={"dashboard_revenue"}>{data?.revenue}</span>
        </div>
      </div>
    </div>
  );
};

export default Revenue;
