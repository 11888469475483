import React, { useContext } from "react";
import InputCustom from "../../../../../../components/Input";
import { getIn } from "formik";
import VoucherCurrency from "./VoucherCurrency";
import { CampaignCreateEditContext } from "../../../../reducers/CampaignCreateEditContext";

const VoucherPercentage = () => {
  const { errors, touched, isSubmitting, handleChange, setFieldValue, values } =
    useContext(CampaignCreateEditContext);

  return (
    <div className={"campaign-create-type"}>
      <InputCustom
        label={"Percentage Value (%)"}
        labelRequired
        value={values.voucher_reward.value}
        name={"voucher_reward.value"}
        onChange={handleChange}
        error={getIn(errors, "voucher_reward.value")}
        touched={getIn(touched, "voucher_reward.value")}
        disabled={isSubmitting}
      />
      <InputCustom
        label={"Maximum Redeem Value (optional)"}
        labelGuide={
          "Let's say you have a % discount voucher, and you want to give a 50% discount, up to 5 SGD. To do so you put here 5 (SGD) that would limit the total value of the % voucher."
        }
        value={values.voucher_reward.percentage_max_redeem_value}
        name={"voucher_reward.percentage_max_redeem_value"}
        onChange={handleChange}
        error={getIn(errors, "voucher_reward.percentage_max_redeem_value")}
        touched={getIn(touched, "voucher_reward.percentage_max_redeem_value")}
        disabled={isSubmitting}
        removeLetter
      />
      <VoucherCurrency />
      <InputCustom
        label={"Voucher valid for X days from the date a user redeems it"}
        value={values.voucher_reward.redeem_duration}
        name={"voucher_reward.redeem_duration"}
        onChange={handleChange}
        error={getIn(errors, "voucher_reward.redeem_duration")}
        touched={getIn(touched, "voucher_reward.redeem_duration")}
        disabled={isSubmitting}
        labelRequired
        removeLetter
      />
    </div>
  );
};

export default VoucherPercentage;
