import ButtonCustom from "../../../../../components/Button";
import { Modal } from "semantic-ui-react";
import { modalMyProfileAction } from "../../../actions/AppActions";
import { PUBLIC_URL } from "../../../configs/Constants";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Divide from "../../../../../components/Divide";
import "./style.scss";
import { myProfileSelector } from "../../../selectors/AppSelectors";
import { LocalizationContext } from "../../../../../locales/Translation";

type Props = {
  setEditMode?: any;
};

const View = ({ setEditMode }: Props) => {
  const { translations } = useContext(LocalizationContext);

  const dispatch = useDispatch();
  const myProfile = useSelector(myProfileSelector);

  return (
    <>
      <Modal.Header>
        <ButtonCustom
          onClick={() => dispatch(modalMyProfileAction(false))}
          className={"m-0 p-0 none-bg close-modal"}
        >
          <>
            {translations.close}
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Modal.Content className={"scroll"}>
        <div className={"my-profile"}>
          <div className={"my-profile__avatar"}>
            {myProfile?.avatar ? (
              <img src={myProfile?.avatar} />
            ) : (
              <img src={PUBLIC_URL + "/assets/default-add-avatar.svg"} />
            )}
          </div>
          <div className={"my-profile__meta"}>
            <div className={"my-profile__name"}>{myProfile?.full_name}</div>
            <div className={"my-profile__email"}>{myProfile?.email}</div>
            {myProfile?.phone_number === null ? null : (
              <div className={"my-profile__phone"}>
                {"+" + myProfile?.country_code + " " + myProfile?.phone_number}
              </div>
            )}
          </div>
        </div>
        <Divide type={"horizontal"} />
      </Modal.Content>
      <Modal.Actions>
        <ButtonCustom
          height={"47px"}
          onClick={() => {
            setEditMode(false);
            dispatch(modalMyProfileAction(false));
          }}
        >
          {translations.close}
        </ButtonCustom>
        <ButtonCustom
          className={"primary"}
          height={"47px"}
          onClick={() => setEditMode(true)}
        >
          {translations.edit}
        </ButtonCustom>
      </Modal.Actions>
    </>
  );
};

export default View;
