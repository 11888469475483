import React, { useState, useContext, useRef } from "react";
import ButtonCustom from "../../../../../../components/Button";
import {
  bookingPropertyActionService,
  getDeclineReasonsService,
} from "../../../../services/DashboardServices";
import { BookingActionType } from "../../../../configs/DeclareTypes";
import {
  DESK_BOOKING_CONFIRMED_STATUS,
  ROOM_BOOKING,
  TYPE_DESK,
  TYPE_ROOM,
} from "../../../../configs/Constants";
import {
  SWAL_CUSTOM_CONTENT,
  SWAL_DELETE_OPTION,
} from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import { LocalizationContext } from "../../../../../../locales/Translation";
import {
  getDeclineReasonsAction,
  modalDeclineReasonsAction,
} from "../../../../actions/DashboardActions";
import { useDispatch } from "react-redux";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import SizeBox from "../../../../../../components/SizeBox";
import { isNumeric } from "../../../../../../utils/commons";
import classNames from "classnames";
import { Dropdown } from "semantic-ui-react";

const CASE_POPUP = 1;
const CASE_POPUP_INPUT = 2;
const CASE_POPUP_CHECKBOX = 3;
const CASE_POPUP_INPUT_CHECKBOX = 4;

const BookingActionButton = ({
  item,
  bookingId,
  onCallback,
  type,
  refresh,
  setRefresh,
  refundType,
  priceAfterDiscount,
  priceAfterDiscountAmount,
  freeCreditAmount,
  currency,
  dropdownItemStyle,
  disabled,
  setDisabled,
}: {
  item: BookingActionType;
  bookingId: number;
  onCallback: (status: number) => void;
  type?: string;
  refresh?: boolean;
  setRefresh?: any;
  refundType?: any;
  priceAfterDiscount?: any;
  priceAfterDiscountAmount?: any;
  freeCreditAmount?: any;
  currency?: any;
  dropdownItemStyle?: boolean;
  disabled?: boolean;
  setDisabled?: any;
}) => {
  const [loading, setLoading] = useState(false);
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const inputRef = useRef<any>(null);
  const checkboxRef = useRef<any>(null);

  const getDeclineReason = async (type: string) => {
    setDisabled(true);
    try {
      const response = await getDeclineReasonsService(
        type === ROOM_BOOKING ? TYPE_ROOM : TYPE_DESK
      );
      if (response?.data) {
        dispatch(getDeclineReasonsAction(response?.data));
      }
    } catch (error: any) {
      if (error?.status?.message) {
        toastError(error?.status?.message);
      }
      console.log(error);
    } finally {
      setDisabled(false);
    }
  };

  const _onClick = async (url: string, isDecline?: boolean) => {
    if (isDecline) {
      const icon = null;
      const title = translations.declineBookingMsg;
      const confirmText = translations.decline;
      const confirmClass = "danger";
      const options = SWAL_DELETE_OPTION({
        title,
        icon,
        confirmText,
        confirmClass,
      });

      SweetAlertCommon({
        options,
        confirmCallback: async () => {
          setDisabled(true);
          try {
            const url =
              type === ROOM_BOOKING
                ? "/api/v1/property-com/meeting-room/booking/status/Decline"
                : "/api/v1/property-com/desk-booking/update-status/decline";
            await getDeclineReason(type || "");
            dispatch(
              modalDeclineReasonsAction({
                open: true,
                data: {
                  booking_id: bookingId,
                  url,
                },
                callBack: () => {
                  setRefresh(!refresh);
                },
              })
            );
          } catch (error: any) {
            if (error?.status?.message) {
              toastError(error?.status?.message);
            }
          } finally {
            setDisabled(false);
          }
        },
        cancelCallback: () => null,
      });
    } else {
      setDisabled(true);
      await _onAction(DESK_BOOKING_CONFIRMED_STATUS);
      setDisabled(false);
    }
  };

  const prepareRefundPopup = () => {
    if (refundType == CASE_POPUP) {
      return <></>;
    }

    if (refundType == CASE_POPUP_INPUT) {
      return (
        <>
          <div className="refund-subtitle">
            Maximum refund amount: {priceAfterDiscount}
          </div>
          <div className="refund-input">
            <div className={"refund-currency"}>{currency}</div>
            <input
              defaultValue={priceAfterDiscountAmount}
              className={"input-pure-ref"}
              type="text"
              ref={inputRef}
            />
          </div>
        </>
      );
    }

    if (refundType == CASE_POPUP_CHECKBOX) {
      return (
        <>
          <div className="refund-checkbox">
            <input
              ref={checkboxRef}
              type="checkbox"
              id={"refund-checkbox"}
              className="refund-checkbox-input custom-checkbox-input"
            />
            <label
              htmlFor={`refund-checkbox`}
              className="refund-checkbox-label custom-checkbox-label"
            >
              Refund free credit ({freeCreditAmount})
            </label>
          </div>
        </>
      );
    }

    if (refundType == CASE_POPUP_INPUT_CHECKBOX) {
      return (
        <>
          <div className="refund-subtitle">
            Maximum refund amount: {priceAfterDiscount}
          </div>
          <div className="refund-input">
            <div className={"refund-currency"}>{currency}</div>
            <input
              defaultValue={priceAfterDiscountAmount}
              className={"input-pure-ref"}
              type="text"
              ref={inputRef}
            />
          </div>
          <SizeBox height={"12px"} />
          <div className="refund-checkbox">
            <input
              ref={checkboxRef}
              type="checkbox"
              id={"refund-checkbox"}
              className="refund-checkbox-input custom-checkbox-input"
            />
            <label
              htmlFor={`refund-checkbox`}
              className="refund-checkbox-label custom-checkbox-label"
            >
              Refund free credit ({freeCreditAmount})
            </label>
          </div>
        </>
      );
    }
  };

  const _onAction = async (nextStatus: number) => {
    switch (item.name.toLowerCase().trim()) {
      case "refund":
        {
          const content = (
            <div className={"refund-box"}>
              <div className="refund-title">
                Are you sure you want to refund this booking?
              </div>

              {prepareRefundPopup()}
            </div>
          );
          const confirmButtonText = "Yes";
          const cancelButtonText = "No";
          const confirmButtonClass = "primary";
          const options = SWAL_CUSTOM_CONTENT({
            content,
            confirmButtonText,
            confirmButtonClass,
            cancelButtonText,
            wrapperClass: "refund-swal",
          });

          SweetAlertCommon({
            options,
            confirmCallback: async () => {
              setDisabled(true);
              try {
                const data = {
                  booking_id: bookingId,
                  is_refund_free_credit: checkboxRef?.current?.checked,
                  refund_amount:
                    refundType == CASE_POPUP ||
                    refundType == CASE_POPUP_CHECKBOX
                      ? priceAfterDiscountAmount
                      : inputRef?.current?.value,
                };

                if (
                  (refundType == CASE_POPUP_INPUT ||
                    refundType == CASE_POPUP_INPUT_CHECKBOX) &&
                  !isNumeric(inputRef?.current?.value)
                ) {
                  toastError("The refund amount is invalid");
                } else {
                  await bookingPropertyActionService({ url: item.url, data });
                  setRefresh(!refresh);
                  toastSuccess("The refund action was successful");
                }
              } catch (error: any) {
                if (error?.status?.message) {
                  toastError(error?.status?.message);
                }
              } finally {
                setDisabled(false);
              }
            },
            cancelCallback: () => null,
          });
        }
        break;
      default:
        try {
          setLoading(true);
          setDisabled(true);
          const data = { booking_id: bookingId };
          await bookingPropertyActionService({ url: item.url, data });
          onCallback && onCallback(nextStatus);
        } catch (e: any) {
          if (e?.status?.message) {
            toastError(e?.status?.message);
          }
          console.log(e);
        } finally {
          setLoading(false);
          setDisabled(false);
        }
        break;
    }
  };

  const _renderLabel = () => {
    return item?.label === "confirm" || item?.name === "confirm"
      ? translations.approve
      : item?.label
      ? item?.label
      : item?.name;
  };

  const _renderIcon = (buttonName: string) => {
    switch (buttonName.toLowerCase()) {
      case "refund":
        return <i className="uil icon uil-redo" />;
      case "do_not_refund":
        return <i className="uil icon uil-times-circle" />;
      case "do not refund":
        return <i className="uil icon uil-times-circle" />;
      case "cancel":
        return <i className="uil icon uil-times-circle" />;
      case "approve":
        return <i className="uil icon uil-check" />;
      case "decline":
        return <i className="uil icon uil-times-circle" />;
      default:
        return <i className="uil icon uil-bill" />;
    }
  };

  if (dropdownItemStyle) {
    return (
      <Dropdown.Item
        className={classNames({
          dashboard_decline_btn: item.name.toLowerCase() == "decline",
        })}
        onClick={() => _onClick(item.url, item.name.toLowerCase() == "decline")}
        text={_renderLabel()}
        icon={_renderIcon(item.name)}
      />
    );
  }

  return (
    <ButtonCustom
      className={
        item.name == "decline" || item.name == "Decline"
          ? "dashboard_decline_btn"
          : "dashboard_approve_btn" +
            ` ${
              item.name.toLowerCase().trim() == "refund"
                ? item.name.toLowerCase().trim()
                : ""
            }`
      }
      fontSize={"12px"}
      onClick={() => _onClick(item.url, item.name.toLowerCase() == "decline")}
      loading={loading}
      disabled={loading}
    >
      {_renderLabel()}
    </ButtonCustom>
  );
};

export default BookingActionButton;
