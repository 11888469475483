import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import Label from "../../../../../../components/Label";
import RuleFirstVisit from "./RuleFirstVisit";
import RuleItem from "./RuleItem";
import {
  MATCH_WITH_SPECIFIC_CITIES_RULE,
  MATCH_WITH_SPECIFIC_COMPANIES_RULE,
  MATCH_WITH_SPECIFIC_PROPERTIES_RULE,
  MATCH_WITH_SPECIFIC_USERS_RULE,
} from "../../../../configs/Constants";
import {
  autocompleteCompaniesService,
  autocompleteMarketsService,
  autocompletePropertiesService,
  autocompleteUsersService,
} from "../../../../services/MarketingServices";

const Rules = () => {
  const { translations } = useContext(LocalizationContext);

  return (
    <>
      <Label
        label={translations.advancedRules}
        fontSize={"24px"}
        className={"section-title"}
      />
      <div className={"campaign-create-rules"}>
        <RuleFirstVisit />
        <RuleItem
          ruleType={MATCH_WITH_SPECIFIC_CITIES_RULE}
          funcGetValue={autocompleteMarketsService}
          selectLabel={"Markets"}
        />
        <RuleItem
          ruleType={MATCH_WITH_SPECIFIC_USERS_RULE}
          funcGetValue={autocompleteUsersService}
          selectLabel={"Users"}
        />
        <RuleItem
          ruleType={MATCH_WITH_SPECIFIC_PROPERTIES_RULE}
          funcGetValue={autocompletePropertiesService}
          selectLabel={"Properties"}
        />
        <RuleItem
          ruleType={MATCH_WITH_SPECIFIC_COMPANIES_RULE}
          funcGetValue={autocompleteCompaniesService}
          selectLabel={"Companies"}
        />
      </div>
    </>
  );
};

export default Rules;
