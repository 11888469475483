import React, { useContext, useEffect, useState } from "react";
import MasterTemplate from "../../../App/views/MasterTemplate";
import { Dimmer, Loader } from "semantic-ui-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MARKETING_CAMPAIGNS_LINK } from "../../../App/configs/ListRoutes";
import { PUBLIC_URL } from "../../../App/configs/Constants";
import { LocalizationContext } from "../../../../locales/Translation";
import Summary from "./Components/Summary";
import Voucher from "./Components/Voucher";
import Rules from "./Components/Rules";
import { ROLE_DESKIMO_ADMIN_ID } from "../../../User/configs/Constants";
import { useDispatch } from "react-redux";
import { campaignDetailThunk } from "../../thunks/MarketingThunks";
import "./styles.scss";
import SectionWhiteBox from "../../../../components/SectionWhiteBox";

const CampaignDetail = () => {
  const { translations } = useContext(LocalizationContext);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!id) {
        navigate(MARKETING_CAMPAIGNS_LINK);
        return;
      }
      try {
        await dispatch<any>(campaignDetailThunk(id));
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <MasterTemplate roleAccept={[ROLE_DESKIMO_ADMIN_ID]}>
      {loading ? (
        <Dimmer active>
          <Loader />
        </Dimmer>
      ) : (
        <SectionWhiteBox isShowBack backRoute={MARKETING_CAMPAIGNS_LINK}>
          <Summary />
          <Voucher />
          <Rules />
        </SectionWhiteBox>
      )}
    </MasterTemplate>
  );
};

export default CampaignDetail;
