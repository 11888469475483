import {
  modalMeetingRoomCreateAction,
  modalRewardAction,
} from "../../../../../actions/PropertyActions";
import { Formik } from "formik";
import * as yup from "yup";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../../App/configs/Constants";
import InputCustom from "../../../../../../../components/Input";
import Label from "../../../../../../../components/Label";
import ModalRightSideBarTemplate from "../../../../../../App/views/ModalRightSideBarTemplate";
import React, { useContext, useEffect, useState } from "react";
import { modalRewardSelector } from "../../../../../selectors/PropertySelector";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationContext } from "../../../../../../../locales/Translation";
import ConditionRow from "./ConditionRow";
import {
  createRewardService,
  propertyRewardDetailService,
  updateRewardService,
} from "../../../../../services/PropertyServices";
import { useParams } from "react-router-dom";
import {
  REWARD_RULE_BOOK_MEETING_ROOM_FULL_DAY,
  REWARD_RULE_BOOK_MEETING_ROOM_MORE_THAN,
  REWARD_RULE_CHECKIN_MORE_THAN,
  REWARD_RULE_PURCHASE_DAY_PASS,
} from "../../../../../configs/Constants";
import {
  toastError,
  toastSuccess,
} from "../../../../../../../components/Toast";

const ModalCreateReward = ({ refresh, setRefresh }: any) => {
  const modalReward = useSelector(modalRewardSelector);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { translations } = useContext(LocalizationContext);
  const [rewardDetail, setRewardDetail] = useState<any>(null);
  const { id: propertyId } = useParams();
  const [modalLoading, setModalLoading] = useState(false);

  useEffect(() => {
    if (modalReward?.open) {
      getRewardDetail(modalReward?.data?.id);
    }
    return () => {
      setRewardDetail(null);
    };
  }, [modalReward]);

  const getRewardDetail = async (rewardId: string) => {
    setModalLoading(true);
    try {
      if (!propertyId || !rewardId) return;
      const response = await propertyRewardDetailService(propertyId, rewardId);
      if (response?.data) {
        const rewardDetailDecorated = {
          ...response.data,
          rule_type: response.data?.rules[0]?.condition,
          rule_value: response.data?.rules[0]?.value,
          rule_unit: response.data?.rules[0]?.unit,
        };

        setRewardDetail(rewardDetailDecorated);
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setModalLoading(false);
    }
  };

  const _handleSave = async (values: any) => {
    if (!propertyId) return;
    const payload = {
      description: values.description,
      duration: values.duration,
      rules: [
        {
          condition: values?.rule_type,
          value: values?.rule_value,
          unit: values?.rule_unit,
        },
      ],
    };
    try {
      setLoading(true);
      if (modalReward?.data) {
        // Update
        await updateRewardService(payload, propertyId, modalReward?.data?.id);
        toastSuccess("Update reward successfully");
        setRefresh(!refresh);
        dispatch(modalRewardAction({ open: false, data: null }));
      } else {
        // Create
        await createRewardService(payload, propertyId);
        toastSuccess("Create reward successfully");
        setRefresh(!refresh);
        dispatch(modalRewardAction({ open: false, data: null }));
      }
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalRightSideBarTemplate
      loading={modalLoading}
      trigger={null}
      onClose={() => dispatch(modalRewardAction({ open: false }))}
      modalClassName={"modal-edit-employee"}
      open={modalReward?.open}
    >
      <Formik
        enableReinitialize
        initialValues={{
          description: rewardDetail?.description || "",
          duration: rewardDetail?.duration || "",
          rule_type: rewardDetail?.rule_type || REWARD_RULE_CHECKIN_MORE_THAN,
          rule_value: rewardDetail?.rule_value || "",
          rule_unit: rewardDetail?.rule_unit || "minutes",
        }}
        validationSchema={yup.object().shape({
          description: yup.string().required().label("Description"),
          duration: yup.number().min(1).required().label("Duration"),
          rule_value: yup.number().when("rule_type", {
            is: (value: any) =>
              value === REWARD_RULE_CHECKIN_MORE_THAN ||
              value === REWARD_RULE_BOOK_MEETING_ROOM_MORE_THAN,
            then: yup.number().min(1).required().label("Value"),
            otherwise: yup.number().label("Value"),
          }),
        })}
        onSubmit={_handleSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <>
            <Modal.Header>
              <div>
                {modalReward?.data ? "Update" : "Add"} Rewards Configuration
              </div>
              <ButtonCustom
                onClick={() => dispatch(modalRewardAction({ open: false }))}
                className={"m-0 p-0 none-bg close-modal"}
              >
                <>
                  {translations.settings.close}
                  <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
                </>
              </ButtonCustom>
            </Modal.Header>
            <Modal.Content className={"scroll"}>
              <InputCustom
                placeholder={"e.g. 1 cup of barista coffee"}
                label={"Reward Description"}
                labelRequired={true}
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.description}
                error={errors.description}
                name={"description"}
              />
              <div className={"mb-16px"} />
              <Label
                labelGuide={"The condition for a user to receive a reward."}
                label={"Reward Condition"}
              />
              <div className="list-conditions">
                <ConditionRow
                  setFieldValue={setFieldValue}
                  isRadio={true}
                  isCheck={values.rule_type === REWARD_RULE_CHECKIN_MORE_THAN}
                  onChangeRadio={(e: any, data: any) => {
                    setFieldValue("rule_type", REWARD_RULE_CHECKIN_MORE_THAN);
                    setFieldValue("rule_unit", "minutes");
                    setFieldValue("rule_value", "");
                  }}
                  onChangeInput={(e: any, data: any) => {
                    setFieldValue("rule_value", data.value);
                  }}
                  inputValue={
                    values.rule_type === REWARD_RULE_CHECKIN_MORE_THAN
                      ? values.rule_value
                      : null
                  }
                  touched={
                    values.rule_type === REWARD_RULE_CHECKIN_MORE_THAN
                      ? touched.rule_value
                      : undefined
                  }
                  error={
                    values.rule_type === REWARD_RULE_CHECKIN_MORE_THAN
                      ? errors.rule_value
                      : undefined
                  }
                  unit={"minutes"}
                  content={"Hotdesk: Checked in for more than"}
                />
                <ConditionRow
                  isRadio={true}
                  isCheck={values.rule_type === REWARD_RULE_PURCHASE_DAY_PASS}
                  unit={""}
                  onChangeRadio={(e: any, data: any) => {
                    setFieldValue("rule_type", REWARD_RULE_PURCHASE_DAY_PASS);
                    setFieldValue("rule_unit", "");
                    setFieldValue("rule_value", "");
                  }}
                  onChangeInput={(e: any, data: any) => {
                    setFieldValue("rule_value", data.value);
                  }}
                  inputValue={
                    values.rule_type === REWARD_RULE_PURCHASE_DAY_PASS
                      ? values.rule_value
                      : null
                  }
                  content={"Purchase a Day Pass"}
                />
                <ConditionRow
                  isRadio={true}
                  isCheck={
                    values.rule_type === REWARD_RULE_BOOK_MEETING_ROOM_MORE_THAN
                  }
                  onChangeRadio={(e: any, data: any) => {
                    setFieldValue(
                      "rule_type",
                      REWARD_RULE_BOOK_MEETING_ROOM_MORE_THAN
                    );
                    setFieldValue("rule_unit", "minutes");
                    setFieldValue("rule_value", "");
                  }}
                  onChangeInput={(e: any, data: any) => {
                    setFieldValue("rule_value", data.value);
                  }}
                  inputValue={
                    values.rule_type === REWARD_RULE_BOOK_MEETING_ROOM_MORE_THAN
                      ? values.rule_value
                      : null
                  }
                  touched={
                    values.rule_type === REWARD_RULE_BOOK_MEETING_ROOM_MORE_THAN
                      ? touched.rule_value
                      : undefined
                  }
                  error={
                    values.rule_type === REWARD_RULE_BOOK_MEETING_ROOM_MORE_THAN
                      ? errors.rule_value
                      : undefined
                  }
                  unit={"minutes"}
                  content={"Book a Meeting Room for more than"}
                />
                <ConditionRow
                  isRadio={true}
                  isCheck={
                    values.rule_type === REWARD_RULE_BOOK_MEETING_ROOM_FULL_DAY
                  }
                  onChangeRadio={(e: any, data: any) => {
                    setFieldValue(
                      "rule_type",
                      REWARD_RULE_BOOK_MEETING_ROOM_FULL_DAY
                    );
                    setFieldValue("rule_unit", "");
                    setFieldValue("rule_value", "");
                  }}
                  onChangeInput={(e: any, data: any) => {
                    setFieldValue("rule_value", data.value);
                  }}
                  inputValue={
                    values.rule_type === REWARD_RULE_BOOK_MEETING_ROOM_FULL_DAY
                      ? values.rule_value
                      : null
                  }
                  unit={""}
                  content={"Book a Meeting Room for a full day"}
                />
              </div>
              <div className={"mb-16px"} />
              <Label label={"Validity Period"} />
              <ConditionRow
                onChangeInput={(e: any, data: any) =>
                  setFieldValue("duration", data.value)
                }
                inputValue={values.duration}
                touched={touched.duration}
                error={errors.duration}
                isCheck={true}
                unit={"days"}
                content={"Expires after"}
              />
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom
                height={"47px"}
                onClick={() => dispatch(modalRewardAction({ open: false }))}
              >
                {translations.settings.cancel}
              </ButtonCustom>
              <ButtonCustom
                loading={isSubmitting || loading}
                type={"submit"}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {translations.settings.save}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalRightSideBarTemplate>
  );
};

export default ModalCreateReward;
