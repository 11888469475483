import React, { useEffect, useState } from "react";
import { Chart, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";
import { DeskimoStatisticType } from "../../../../configs/DeclareTypes";
import { useSelector } from "react-redux";
import { dashboardStatisticDeskimoSelector } from "../../../../selectors/DashboardSelectors";
import SizeBox from "../../../../../../components/SizeBox";

Chart.register(...registerables);

const MarketBreakdown = () => {
  const dashboardStatisticDeskimo: DeskimoStatisticType = useSelector(
    dashboardStatisticDeskimoSelector
  );

  const [data, setData] = useState<any>({
    labels: [],
    datasets: [
      {
        label: "Net Revenue",
        data: [],
        backgroundColor: "#FFBE00",
      },
      {
        label: "GBV",
        data: [],
        backgroundColor: "#013EBC",
      },
    ],
  });

  useEffect(() => {
    const labels: string[] = [];
    const netRevenueValue: number[] = [];
    const gbvValue: number[] = [];

    dashboardStatisticDeskimo.market_breakdown.forEach(
      ({ market, gbv, net_revenue }) => {
        labels.push(market);
        netRevenueValue.push(net_revenue);
        gbvValue.push(gbv);
      }
    );

    setData({
      labels: labels,
      datasets: [
        {
          label: "Net Revenue",
          data: netRevenueValue,
          backgroundColor: "#FFBE00",
        },
        {
          label: "GBV",
          data: gbvValue,
          backgroundColor: "#013EBC",
        },
      ],
    });
  }, [dashboardStatisticDeskimo.market_breakdown]);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          color: "#343A40",
          font: {
            size: 10,
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: "#868E96",
          font: {
            size: 10,
          },
        },
      },
    },
  };

  return (
    <div className={"dashboard_chart_box_wrapper"}>
      <div className={"dashboard_chart_box"}>
        <h2 className={"dashboard_chart_title"}>Market breakdown</h2>
        <div className={"dashboard_chart_inner"}>
          <SizeBox height={"16px"} />
          <span className={"x-axis__label"}>USD</span>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*// @ts-ignore*/}
          <Bar data={data} options={options} />
          <div className={"deskimo-dashboard__chart-legend"}>
            <div className={"item gbv"}>GBV</div>
            <div className={"item net"}>Net Revenue</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketBreakdown;
