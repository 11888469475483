import MasterTemplate from "../../../App/views/MasterTemplate";
import { useFormik } from "formik";
import * as yup from "yup";
import ButtonCustom from "../../../../components/Button";
import React, { useContext, useEffect, useState } from "react";
import { updatePropertyService } from "../../services/PropertyServices";
import { toastError, toastSuccess } from "../../../../components/Toast";
import RichTextEditor from "react-rte";
import { OPERATION_HOUR_INIT } from "../../../App/configs/DateTimeConfigs";
import { useNavigate } from "react-router-dom";
import { DESKIMO_PROPERTY_LINK } from "../../../App/configs/ListRoutes";
import { LocalizationContext } from "../../../../locales/Translation";
import { ROLE_DESKIMO_ADMIN_ID } from "../../../User/configs/Constants";
import SectionWhiteBox from "../../../../components/SectionWhiteBox";
import GeneralInformation from "./Components/GeneralInformation";
import Address from "./Components/Address";
import HowToGetThere from "./Components/HowToGetThere";
import Contact from "./Components/Contact";
import Amenities from "./Components/Amenities";
import Wifi from "./Components/Wifi";
import Schedule from "./Components/Schedule";
import "./style.scss";
import SizeBox from "../../../../components/SizeBox";
import RichText from "../../../../components/RichText";
import { getPropertyAmenityListService } from "../../services/PropertyAmenityServices";
import { AMENITY_DESK_TYPE, WIFI_ACCESS_TYPE } from "../../configs/Constants";
import Parking from "./Components/Parking";
import useFormikScrollToError from "../../../../utils/custom-hooks";

const CreateProperty = () => {
  const { translations } = useContext(LocalizationContext);
  const navigate = useNavigate();

  const [amenityItems, setAmenityItems] = useState<any>([]);
  const [amenityFree, setAmenityFree] = useState<any>([]);
  const [amenityPaid, setAmenityPaid] = useState<any>([]);
  const [operationHour, setOperationHour] = useState<any>(OPERATION_HOUR_INIT);

  const [description, setDescription] = useState(
    RichTextEditor.createEmptyValue()
  );

  const [houseRule, setHouseRule] = useState(RichTextEditor.createEmptyValue());
  const [locationGuide, setLocationGuide] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    _fetchListAmenities();
  }, []);

  const _fetchListAmenities = async () => {
    try {
      const { data } = await getPropertyAmenityListService({
        types: [AMENITY_DESK_TYPE],
        page: 1,
        item_per_page: 100,
      });

      setAmenityItems(
        data?.amenities.map((amenity: any) => {
          return {
            id: amenity?.id,
            name: amenity?.name,
            icon_key: amenity?.image_url,
            is_chosen: false,
            is_free: true,
            uri: amenity?.image_url,
          };
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const _handleSave = async (values: any) => {
    try {
      const payload = { ...values };

      payload.schedule.operation_hour = operationHour;

      if (description) {
        payload.description = description.toString("html");
      }

      if (houseRule) {
        payload.house_rules = houseRule.toString("html");
      }

      if (amenityItems) {
        payload.amenities = amenityItems.concat(amenityFree, amenityPaid);
      }

      if (locationGuide.length > 0) {
        let isHasTitle = true;
        let isHasImage = true;
        locationGuide.forEach((location: any) => {
          if (location.img_key.length < 1) {
            isHasImage = false;
          }
          if (location.title < 1) {
            isHasTitle = false;
          }
        });

        if (!isHasImage) {
          toastError(
            "The step-by-step guide needs to be filled out by filling in the image"
          );
          return;
        }

        if (!isHasTitle) {
          toastError(
            "The step-by-step guide needs to be filled out by filling in the title"
          );
          return;
        }
        payload.location_guide = locationGuide;
      }

      await updatePropertyService(payload);
      toastSuccess(translations.createSuccessPrompt);
      navigate(DESKIMO_PROPERTY_LINK, { replace: true });
    } catch (error: any) {
      console.log(error);
      toastError(error?.status?.message);
    }
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
      hourly_rate: "",
      daily_rate: "",
      hot_desk_mode: {
        allow_walk_in: false,
        allow_booking: false,
        allow_desk_limitation_condition: false,
        desk_limitation_amount: "",
        auto_approved: false,
      },
      images: [],
      address: "",
      address_street_name: "",
      address_building: "",
      address_floor: "",
      address_unit: "",
      address_postal_code: "",
      address_city: "",
      address_map_pins: "",
      lat: 0,
      lng: 0,
      description: RichTextEditor.createEmptyValue(),
      house_rules: RichTextEditor.createEmptyValue(),
      how_to_get_there: {
        car_drop_off_point: "",
        nearest_mrt_station: "",
        front_endtrance_of_the_space: "",
      },
      location_guide: [],
      photo: [],
      contact_details: {
        email: "",
        name: "",
        phone_prefix: "65",
        phone: "",
      },
      wifi: {
        wifiName: "",
        wifiPass: "",
        wifiAccessType: WIFI_ACCESS_TYPE.PASSWORD,
        wifiInstruction: "",
      },
      schedule: {
        operation_hour: operationHour,
        close_on_holiday: false,
        aircon_inherit: false,
        community_inherit: false,
      },
      company_id: "",
      tag_ids: [],
      property_categories: [],
      parking: [],
      allow_parking_request: false,
      referral_company_id: 0,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required().label(translations.propertyName),
      company_id: yup.string().required().label(translations.company),
      hourly_rate: yup.number().required().label(translations.hourlyRate),
      daily_rate: yup.number().label(translations.dailyRateCap),
      address_street_name: yup
        .string()
        .required()
        .label(translations.settings.address),
      address: yup.string().required().label(translations.settings.address),
      address_building: yup
        .string()
        .required()
        .label(translations.buildingName),
      address_floor: yup.string().required().label(translations.floor),
      contact_details: yup.object({
        name: yup.string().required().label(translations.contactName),
        email: yup.string().required().label(translations.contactEmail),
        phone: yup.string().required().label(translations.contactPhone),
      }),
      wifi: yup.object({
        wifiName: yup.string().required().label(translations.wifiName),
        wifiPass: yup.string().when(["wifiAccessType"], {
          is: (value: number) => value === WIFI_ACCESS_TYPE.PASSWORD,
          then: yup.string().required().label(translations.wifiPassword),
        }),
        wifiInstruction: yup.string().when(["wifiAccessType"], {
          is: (value: number) => value === WIFI_ACCESS_TYPE.PASS_INSTRUCTION,
          then: yup.string().required().label("Wifi password instruction"),
        }),
      }),
      hot_desk_mode: yup.object({
        desk_limitation_amount: yup
          .number()
          .min(1)
          .when(["allow_desk_limitation_condition"], {
            is: (value: boolean) => value,
            then: yup
              .number()
              .required()
              .label(translations.limitNumberAvailableDesks),
          }),
      }),
    }),
    onSubmit: _handleSave,
  });

  useFormikScrollToError(isSubmitting);

  return (
    <MasterTemplate roleAccept={[ROLE_DESKIMO_ADMIN_ID]}>
      <SectionWhiteBox isShowBack backRoute={DESKIMO_PROPERTY_LINK}>
        <div className={"property-meta-edit"}>
          <form className={"create-property"}>
            <GeneralInformation
              isSubmitting={isSubmitting}
              touched={touched}
              errors={errors}
              values={values}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              description={description}
              setDescription={setDescription}
              options={options}
              setOptions={setOptions}
            />

            <HowToGetThere
              values={values}
              errors={errors}
              touched={touched}
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              setLocationGuide={setLocationGuide}
              locationGuide={locationGuide}
            />

            <Address
              values={values}
              errors={errors}
              touched={touched}
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />

            <Parking
              values={values}
              errors={errors}
              touched={touched}
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />

            <Contact
              values={values}
              errors={errors}
              touched={touched}
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />

            <Amenities
              items={amenityItems}
              paid={amenityPaid}
              free={amenityFree}
              setFree={setAmenityFree}
              setPaid={setAmenityPaid}
              setItems={setAmenityItems}
            />

            <Wifi
              values={values}
              errors={errors}
              touched={touched}
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />

            <Schedule
              setFieldValue={setFieldValue}
              values={values}
              operationHour={operationHour}
              setOperationHour={setOperationHour}
            />

            <div className={"section"}>
              <SizeBox height={"16px"} />
              <div className={"section-title"}>{translations.houseRules}</div>
              <SizeBox height={"16px"} />
              <div>
                <RichText
                  value={houseRule}
                  onChange={(value) => setHouseRule(value)}
                />
              </div>
            </div>
            <SizeBox height={"16px"} />

            <div className={"d-flex"}>
              <ButtonCustom
                onClick={() => resetForm()}
                className={"outline-primary w-100"}
                fontSize={"12px"}
                height={"37px"}
                disabled={isSubmitting}
              >
                {translations.cancel}
              </ButtonCustom>
              <ButtonCustom
                loading={isSubmitting}
                onClick={handleSubmit}
                className={"primary w-100"}
                fontSize={"12px"}
                height={"37px"}
              >
                {translations.save}
              </ButtonCustom>
            </div>
          </form>
        </div>
      </SectionWhiteBox>
    </MasterTemplate>
  );
};

export default CreateProperty;
