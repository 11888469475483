import React from "react";
import Flag from "react-world-flags";
import "./styles.scss";
import { CALLING_CODE } from "./CallingCode";

type Props = {
  dialingCode: string | number;
};

const FlagDialingCode = ({ dialingCode }: Props) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Flag code={CALLING_CODE[dialingCode]} className={"flag-custom"} />;
};

export default FlagDialingCode;
