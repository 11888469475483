import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import { isModalAddEmployeeSelector } from "../../../../../App/selectors/AppSelectors";
import { DIALING_CODE } from "../../../../../App/configs/PhonePrefix";
import { employeePropertyInviteService } from "../../../../services/EmployeeServices";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { employeesThunk } from "../../../../thunks/EmployeeThunks";
import { modalAddEmployeeAction } from "../../../../../App/actions/AppActions";
import ModalCenterTemplate from "../../../../../App/views/ModalCenterTemplate";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { Formik } from "formik";
import * as yup from "yup";
import Label from "../../../../../../components/Label";
import SizeBox from "../../../../../../components/SizeBox";
import InputCustom from "../../../../../../components/Input";
import BoxToggleSlider from "../../../../../../components/BoxToggleSlider";
import MakeAdminLabelGuide from "../../../../../App/views/ModalAddEmployee/MakeAdminLableGuide";
import { PROPERTY } from "../../../../../Dashboard/configs/Constants";
import InputPhoneFlag from "../../../../../../components/InputPhoneFlag";

const AddEmployeePropertyModal = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const isModalAddEmployee = useSelector(isModalAddEmployeeSelector);

  const _onClickSave = async (values: any, { resetForm }: any) => {
    try {
      const payload = { ...values };

      payload.phone_prefix = DIALING_CODE.filter(
        (country) =>
          country.key.trim().toLowerCase() ===
          values.phone_prefix.trim().toLowerCase()
      )[0].value.replaceAll("+", "");

      await employeePropertyInviteService(payload);
      toastSuccess(translations.employeeAddedSuccessfully);
      await dispatch<any>(employeesThunk(PROPERTY));
      resetForm();
      _onCloseModal();
    } catch (e: any) {
      console.log("e", e);
      toastError(e?.status?.message);
    }
  };

  const _onCloseModal = () => {
    dispatch(modalAddEmployeeAction(false));
  };

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={_onCloseModal}
      modalClassName={"modal-general"}
      open={isModalAddEmployee}
    >
      <Modal.Header>
        {translations.addUser}
        <ButtonCustom onClick={_onCloseModal} className={"modal-close-cta"}>
          <>
            <span>{translations.close}</span>
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
          phone_number: "",
          phone_prefix: DIALING_CODE.filter(
            (country) => country?.value?.replaceAll("+", "") === "65"
          )[0]?.key.toUpperCase(),
          make_admin: false,
        }}
        validationSchema={yup.object().shape({
          first_name: yup.string().required().label("First Name"),
          email: yup.string().email().required().label("Email"),
        })}
        onSubmit={_onClickSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <>
            <Modal.Content>
              <form className={"form-layout"}>
                <div className={"form-row"}>
                  <InputCustom
                    labelRequired={true}
                    label={"First Name"}
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.first_name}
                    error={errors.first_name}
                    name={"first_name"}
                  />
                  <InputCustom
                    label={translations.lastName}
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.last_name}
                    error={errors.last_name}
                    name={"last_name"}
                  />
                </div>
                <InputCustom
                  labelRequired={true}
                  label={translations.emailAddress}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.email}
                  error={errors.email}
                  name={"email"}
                />
                <div className={"mb-16px"} />
                <InputPhoneFlag
                  label={translations.mobilePhoneNumber}
                  labelGuide={translations.addPropertyMobilePhoneLabelGuide}
                  selectedFlag={values.phone_prefix}
                  onSelectFlag={(code) => setFieldValue("phone_prefix", code)}
                  error={errors.phone_number}
                  touched={touched.phone_number}
                  phoneChange={(value) => setFieldValue("phone_number", value)}
                  phoneValue={values.phone_number}
                />
                <SizeBox height={"7px"} />
                <span className={"mini-text symbol-required-pre"}>
                  {translations.mandatoryFields}
                </span>
                <SizeBox height={"12px"} />
                <BoxToggleSlider
                  content={
                    <Label
                      fontSize={"14px"}
                      size={"16px"}
                      label={translations.makeAdmin}
                      labelGuide={<MakeAdminLabelGuide />}
                    />
                  }
                  value={values.make_admin}
                  onChange={() =>
                    setFieldValue("make_admin", !values.make_admin)
                  }
                />
              </form>
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom height={"47px"} onClick={_onCloseModal}>
                {translations.cancel}
              </ButtonCustom>
              <ButtonCustom
                loading={isSubmitting}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {translations.add}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalCenterTemplate>
  );
};

export default AddEmployeePropertyModal;
