import React from "react";
import { CampaignDetailType } from "../../../../configs/DeclareTypes";
import { useSelector } from "react-redux";
import { campaignDetailSelector } from "../../../../selectors/MarketingSelectors";
import {
  VOUCHER_REDEEM_CREDIT_TYPE,
  VOUCHER_REDEEM_FIXED_TYPE,
  VOUCHER_REDEEM_ON_BOARDING_TYPE,
  VOUCHER_REDEEM_PERCENTAGE_CREDIT_PACKAGE_DISCOUNT_TYPE,
  VOUCHER_REDEEM_PERCENTAGE_TYPE,
} from "../../../../configs/Constants";
import VoucherFixed from "./VoucherFixed";
import VoucherPercentage from "./VoucherPercentage";
import VoucherCredit from "./VoucherCredit";
import VoucherOnboard from "./VoucherOnboard";
import VoucherPeriod from "./VoucherPeriod";
import VoucherCreditPackagePercentage from "./VoucherCreditPackagePercentage";
import SizeBox from "../../../../../../components/SizeBox";

const Voucher = () => {
  const campaignDetail: CampaignDetailType = useSelector(
    campaignDetailSelector
  );

  return (
    <>
      <div className={"section-white-box_title"}>Voucher</div>
      <SizeBox height={"40px"} />

      {campaignDetail.voucher_reward.type === VOUCHER_REDEEM_FIXED_TYPE ? (
        <VoucherFixed />
      ) : campaignDetail.voucher_reward.type ===
        VOUCHER_REDEEM_PERCENTAGE_TYPE ? (
        <VoucherPercentage />
      ) : campaignDetail.voucher_reward.type === VOUCHER_REDEEM_CREDIT_TYPE ? (
        <VoucherCredit />
      ) : campaignDetail.voucher_reward.type ===
        VOUCHER_REDEEM_ON_BOARDING_TYPE ? (
        <VoucherOnboard />
      ) : campaignDetail.voucher_reward.type ===
        VOUCHER_REDEEM_PERCENTAGE_CREDIT_PACKAGE_DISCOUNT_TYPE ? (
        <VoucherCreditPackagePercentage />
      ) : (
        <VoucherPeriod />
      )}

      <div className={"section-white-box_line"} />
    </>
  );
};

export default Voucher;
