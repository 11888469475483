import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import ButtonCustom from "../../../../components/Button";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
  PUBLIC_URL,
} from "../../../App/configs/Constants";
import { useNavigate } from "react-router-dom";
import SearchBar4Table from "../../../../components/SearchBar4Table";
import { debounce } from "lodash";
import DropdownCustom from "../../../../components/DropdownCustom";
import {
  FILTER_DATE_ALL_TIME,
  FILTER_DATE_CUSTOM,
  FILTER_DATE_LAST_14_DAYS,
  FILTER_DATE_LAST_30_DAYS,
  FILTER_DATE_LAST_90_DAYS,
} from "../../../Dashboard/configs/Constants";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import { LocalizationContext } from "../../../../locales/Translation";
import SizeBox from "../../../../components/SizeBox";
import moment from "moment";
import DropdownWithCheckboxMenu from "../../../../components/DropdownWithCheckboxMenu";
import TableCustom from "../../../../components/Table";
import {
  deleteInvoiceService,
  generatePropertyInvoiceService,
  getPropertyInvoiceListService,
  postPaidInvoiceService,
  resendPropertyInvoiceService,
} from "../../services/PropertyCompanyServices";
import { toastError, toastSuccess } from "../../../../components/Toast";
import ModalGeneratePropertyCompanyInvoice from "./Components/ModalGenerateInvoice";
import { DESKIMO_PROPERTY_COMPANY_INVOICE_DETAIL_LINK } from "../../../App/configs/ListRoutes";
import { onChangeFilterDateTypeService } from "../../../Dashboard/services/DashboardServices";
import {
  SWAL_CONFIRM_OPTION_V2,
  SWAL_DELETE_OPTION,
} from "../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../components/SweetAlert";
import { getURlDownloadFileService } from "../../../Setting/services/InvoiceServices";
import MarketFilter from "../../../Business/views/CompanyInvoices/Components/CompanyInvoiceTable/MarketFilter";
import DateRangePickerV2 from "../../../../components/DateRangePickerV2";
import { useSelector } from "react-redux";
import { globalSettingSelector } from "../../../App/selectors/AppSelectors";
import { useGetAllQuery, useSetQuery } from "../../../../utils/custom-hooks";
import CompanyFilter from "../../../../components/CompanyFilter";

const DeskimoPropertiesInvoices = () => {
  const [loading, setLoading] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);
  const { translations } = useContext(LocalizationContext);

  const globalSetting = useSelector(globalSettingSelector);

  const [filters, setFilters] = useState<any>({
    search: "",
    dateType: FILTER_DATE_ALL_TIME,
    dateRange: {
      fromTime: null,
      toTime: null,
    },
    companyId: 0,
    sortBy: null,
    orderBy: null,
    page: 1,
    country_code: [],
    statuses: [0, 1, 2],
  });
  const [refreshList, setRefreshList] = useState(false);
  const [modalGenerate, setModalGenerate] = useState(false);
  const [listInvoiceData, setListInvoiceData] = useState<any>({
    invoices: [],
    pagination: {},
  });
  const navigate = useNavigate();
  const [marketOptions, setMarketOptions] = useState<[]>([]);
  const [addParamsAlready, setAddParamsAlready] = useState<boolean>(false);
  const getAllQuery = useGetAllQuery();
  const setQuery = useSetQuery();

  const FILTER_DATE_ARR = [
    {
      key: FILTER_DATE_LAST_14_DAYS,
      value: FILTER_DATE_LAST_14_DAYS,
      text: translations.dateFilter.last14Days,
    },
    {
      key: FILTER_DATE_LAST_30_DAYS,
      value: FILTER_DATE_LAST_30_DAYS,
      text: translations.dateFilter.last30Days,
    },
    {
      key: FILTER_DATE_LAST_90_DAYS,
      value: FILTER_DATE_LAST_90_DAYS,
      text: translations.dateFilter.last90Days,
    },
    {
      key: FILTER_DATE_ALL_TIME,
      value: FILTER_DATE_ALL_TIME,
      text: translations.dateFilter.allTime,
    },
    {
      key: FILTER_DATE_CUSTOM,
      value: FILTER_DATE_CUSTOM,
      text: translations.dateFilter.custom,
    },
  ];

  useEffect(() => {
    (async () => {
      if (!addParamsAlready) return;
      const marketList = globalSetting?.location.map(
        ({
          city_code,
          city_name,
        }: {
          city_code: string;
          city_name: string;
        }) => {
          return {
            id: city_code,
            name: city_name,
          };
        }
      );
      setMarketOptions(marketList);
      setFilters((prevState: any) => {
        return {
          ...prevState,
          country_code: marketList.map(({ id }: { id: string }) => id),
        };
      });
    })();
  }, [addParamsAlready]);

  useEffect(() => {
    try {
      if (addParamsAlready) {
        if (!filters?.country_code?.length) {
          return;
        }
        _fetchListPropertyInvoice();
        setQuery(filters);
      } else {
        if (!Object.keys(getAllQuery).length) {
          return;
        }

        setFilters(getAllQuery);
      }
    } finally {
      setAddParamsAlready(true);
    }
  }, [filters, refreshList]);

  const _fetchListPropertyInvoice = async () => {
    setLoading(true);
    try {
      const payload = {
        company_id: filters?.companyId,
        statuses: filters?.statuses,
        page: filters?.page,
        sort_by: filters?.sortBy,
        order_by: filters?.orderBy,
        from_time: filters?.dateRange.fromTime,
        to_time: filters?.dateRange.toTime,
        keyword: filters?.search,
        country_code: filters?.country_code,
      };
      const response = await getPropertyInvoiceListService(payload);
      setListInvoiceData(response?.data);
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const debounce_onChange = debounce(function (data: any, { value }: any) {
    onSearch(value);
  }, 500);

  const onSearch = (value: any) => {
    setFilters({ ...filters, search: value, page: 1 });
  };

  const _onChangeDateType = (
    event: SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps
  ) => {
    if (!value) {
      return;
    }

    const dateRangeResult: any = onChangeFilterDateTypeService(value);
    const newFilters = {
      ...filters,
      page: 1,
      dateType: value,
      dateRange: {
        fromTime: dateRangeResult?.start_time,
        toTime: dateRangeResult?.end_time,
      },
    };

    setFilters(newFilters);
  };

  const _generateInvoice = async (
    calendar: any,
    companyId: any,
    isBlankInvoice: boolean
  ) => {
    if (!calendar) {
      return;
    }
    setGenerateLoading(true);
    try {
      const payload = {
        month: String(calendar?.month),
        year: String(calendar?.year),
        company: companyId,
        is_blank_invoice: isBlankInvoice,
      };
      await generatePropertyInvoiceService(payload);
      setModalGenerate(false);
      toastSuccess("Generate invoice successfully!");
      setRefreshList(!refreshList);
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setGenerateLoading(false);
    }
  };

  const deleteInvoice = async (token: any) => {
    const iconHtml = (
      <img
        src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
        className={"swal2-icon__error"}
      />
    );
    const title = "Are you sure you want to delete this invoice?";
    const confirmText = (
      <div
        className={"d-flex"}
        style={{ justifyItems: "center", justifyContent: "center" }}
      >
        <div style={{ marginLeft: 5 }}>Delete</div>
      </div>
    );
    const confirmClass = "danger";
    const options = SWAL_DELETE_OPTION({
      title,
      iconHtml,
      confirmText,
      confirmClass,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          setLoading(true);
          const response = await deleteInvoiceService(token);
          if (response?.status?.code !== 200) {
            toastError(response?.status?.message);
            return;
          }

          toastSuccess("Delete invoice successfully!");
          setRefreshList(!refreshList);
        } catch (error: any) {
          toastError(error?.status?.message);
        } finally {
          setLoading(false);
        }
      },
      cancelCallback: () => {
        console.log("cancel");
      },
    });
  };

  const resendInvoice = async (token: any) => {
    const title = "Are you sure want to resend this invoice to company?";
    const options = SWAL_CONFIRM_OPTION_V2({
      title,
      confirmText: "Send",
      showCancelButton: true,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          setLoading(true);
          await resendPropertyInvoiceService(token);
          toastSuccess("Resent successfully!");
        } catch (error: any) {
          toastError(error?.status?.message);
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const changeStatusInvoice = async (token: string, status: number) => {
    const title =
      status === 0
        ? "Are you sure you want to move this invoice to post?"
        : "Are you sure you want to move this invoice to paid?";

    const options = SWAL_DELETE_OPTION({
      title,
      confirmClass: "primary",
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          setLoading(true);
          const payload = { status: status === 0 ? 1 : 2 };
          await postPaidInvoiceService(token, payload);
          toastSuccess("Updated successfully!");
          setRefreshList(!refreshList);
        } catch (error: any) {
          toastError(error?.status?.message);
        } finally {
          setLoading(false);
        }
      },
      cancelCallback: () => {
        console.log("cancel");
      },
    });
  };

  const columns = [
    { key: "invoice_no", col: "Invoice No." },
    { key: "company", col: "Property Company" },
    { key: "market_name", col: "Market" },
    { key: "amount", col: "Total Amount" },
    { key: "time_period", col: "Time Period" },
    { key: "due_date", col: "Due Date" },
    { key: "status", col: "Status" },
    { key: null, col: "Action" },
  ];

  const handleDownloadFile = async (url: string) => {
    if (!url) {
      return;
    }
    setLoading(true);
    try {
      const response = await getURlDownloadFileService(url);
      window.open(response?.data?.url, "_blank");
    } catch (error) {
      console.log(Error);
    } finally {
      setLoading(false);
    }
  };

  const _onChangeMarketFilter = (event: any, { value }: { value: string }) => {
    const temp = [...filters.country_code];
    const index = temp.findIndex((name) => name === value);
    if (index === -1) {
      temp.push(value);
    } else {
      temp.splice(index, 1);
    }

    const newFilters = { ...filters, country_code: temp, page: 1 };

    setFilters(newFilters);
  };

  const decorateTableData = () => {
    if (!listInvoiceData) {
      return [];
    }

    if (listInvoiceData?.invoices?.length > 0) {
      return listInvoiceData.invoices?.map((invoice: any, index: number) => {
        return {
          col1: (
            <div
              className={"table-cell__link"}
              onClick={() =>
                navigate(
                  DESKIMO_PROPERTY_COMPANY_INVOICE_DETAIL_LINK.replaceAll(
                    ":token",
                    invoice?.token
                  ).replaceAll(":companyId", invoice?.company_id)
                )
              }
            >
              {invoice?.invoice_no}
            </div>
          ),
          col2: invoice?.company_name,
          market_name: invoice?.market_name,
          col3: invoice?.total,
          col4: invoice?.time_period,
          col5: invoice?.due_date,
          col6: invoice?.status_name,
          col7: (
            <DropdownCustom
              disabled={loading}
              direction={"left"}
              icon={null}
              trigger={
                <ButtonCustom
                  style={{ width: "38px", height: "38px" }}
                  className={"primary-alt"}
                >
                  <i className="uil uil-setting" style={{ fontSize: "21px" }} />
                </ButtonCustom>
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    window.open(
                      DESKIMO_PROPERTY_COMPANY_INVOICE_DETAIL_LINK.replaceAll(
                        ":token",
                        invoice?.token
                      ).replaceAll(":companyId", invoice?.company_id),
                      "_blank"
                    )
                  }
                  text={translations.viewDetails}
                  icon={
                    <img
                      className={"icon"}
                      src={PUBLIC_URL + "/assets/icons/icon-message.svg"}
                      alt=""
                    />
                  }
                />
                <Dropdown.Item
                  onClick={() => handleDownloadFile(invoice?.invoice_csv_url)}
                  text={"Download Invoice"}
                  icon={
                    <img
                      className={"icon"}
                      src={PUBLIC_URL + "/assets/icons/icon-download-black.svg"}
                      alt=""
                    />
                  }
                />
                <Dropdown.Item
                  onClick={() => handleDownloadFile(invoice?.invoice_xlsx_url)}
                  text={"Download XLSX File"}
                  icon={
                    <img
                      className={"icon"}
                      src={
                        PUBLIC_URL + "/assets/icons/icon-xlsx-file-outline.svg"
                      }
                      alt=""
                    />
                  }
                />
                {invoice?.resend_able ? (
                  <Dropdown.Item
                    onClick={() => resendInvoice(invoice.token)}
                    text={"Resend"}
                    icon={
                      <i
                        className="uil uil-message icon"
                        style={{ fontSize: "16px" }}
                      />
                    }
                  />
                ) : null}
                {invoice?.status === 0 || invoice?.status === 1 ? (
                  <Dropdown.Item
                    onClick={async () =>
                      changeStatusInvoice(invoice.token, invoice?.status)
                    }
                    text={
                      invoice?.status === 0 ? "Move To Post" : "Move To Paid"
                    }
                    icon={
                      <i
                        className="uil uil-exchange icon"
                        style={{ fontSize: "16px" }}
                      />
                    }
                  />
                ) : null}
                {invoice?.status === 0 && (
                  <Dropdown.Item
                    onClick={async () => deleteInvoice(invoice.token)}
                    text={"Delete Invoice"}
                    icon={
                      <i
                        className="uil uil-trash-alt icon"
                        style={{ fontSize: "16px" }}
                      />
                    }
                  />
                )}
              </Dropdown.Menu>
            </DropdownCustom>
          ),
        };
      });
    }
    return [];
  };

  const _onChangeSortCol = (col: any) => {
    if (filters.sortBy !== col.key) {
      setFilters({
        ...filters,
        sortBy: col.key,
        orderBy: ORDER_BY_ASC,
        page: 1,
      });
    } else {
      if (filters?.orderBy === ORDER_BY_NONE) {
        setFilters({
          ...filters,
          sortBy: col.key,
          orderBy: ORDER_BY_ASC,
          page: 1,
        });
      } else if (filters?.orderBy === ORDER_BY_ASC) {
        setFilters({
          ...filters,
          sortBy: col.key,
          orderBy: ORDER_BY_DESC,
          page: 1,
        });
      } else {
        setFilters({
          ...filters,
          sortBy: null,
          orderBy: ORDER_BY_NONE,
          page: 1,
        });
      }
    }
  };

  return (
    <div>
      <div className={"filter-section justify-content-start"}>
        <SearchBar4Table
          defaultValue={filters?.search}
          placeholder={"Search by invoice number, property company"}
          onChange={debounce_onChange}
        />
        <div className={"dropdown-filter d-flex w-100 flex-1"}>
          <SizeBox width={"16px"} />
          <DropdownCustom
            style={{ minWidth: "unset" }}
            className={"justify-content-center text-nowrap"}
            selectUI={true}
            options={FILTER_DATE_ARR}
            onChange={_onChangeDateType}
            value={filters.dateType}
          />
          {filters.dateType === FILTER_DATE_CUSTOM ? (
            <>
              <SizeBox width={"8px"} />
              <DateRangePickerV2
                format={"DD/MM/YYYY"}
                defaultValue={[
                  moment(
                    new Date(new Date().setDate(new Date().getDate() - 30))
                  ),
                  moment(new Date()),
                ]}
                onChange={(value: any) => {
                  if (!value) {
                    return;
                  }

                  const newFilters = {
                    ...filters,
                    page: 1,
                    dateRange: {
                      fromTime: moment(value[0]).startOf("day").utc().unix(),
                      toTime: moment(value[1]).endOf("day").utc().unix(),
                    },
                  };

                  setFilters(newFilters);
                }}
              />
            </>
          ) : null}

          <SizeBox width={"16px"} />
          <DropdownWithCheckboxMenu
            label={translations.filters}
            icon={
              <img
                alt={"Deskimo"}
                src={PUBLIC_URL + "/assets/icons/icon-filter.svg"}
              />
            }
            onChange={(event: any, data: any) => {
              let newState = JSON.parse(JSON.stringify(filters.statuses));
              if (data.checked) {
                newState.push(data.value);
              } else {
                newState = newState.filter(
                  (status: any) => status != data.value
                );
              }

              const newFilters = { ...filters, statuses: newState, page: 1 };

              setFilters(newFilters);
            }}
            listCheckBox={[
              { id: 0, name: "Draft", checked: filters.statuses.includes(0) },
              {
                id: 1,
                name: "Pending Payment",
                checked: filters.statuses.includes(1),
              },
              { id: 2, name: "Paid", checked: filters.statuses.includes(2) },
            ]}
          />

          <SizeBox width={"8px"} />

          <CompanyFilter
            companyId={filters.companyId}
            onChange={(value) =>
              setFilters({
                ...filters,
                companyId: value,
                page: 1,
              })
            }
            marketCodes={filters.country_code}
            type={1}
            showDeletedCompany
          />

          <ButtonCustom
            loading={generateLoading}
            className={"primary ml-auto"}
            style={{ width: "max-content", height: "36px" }}
            onClick={() => setModalGenerate(true)}
          >
            Generate Invoices +
          </ButtonCustom>
        </div>
      </div>
      <div className={"property-company-invoices"}>
        <TableCustom
          orderBy={filters?.orderBy}
          sortBy={filters?.sortBy}
          sortTable={true}
          onChangeSortCol={_onChangeSortCol}
          tableData={decorateTableData()}
          columns={columns}
          loading={loading}
          totalItems={
            listInvoiceData?.pagination?.total
              ? listInvoiceData?.pagination?.total
              : 0
          }
          currentPage={filters.page}
          showTotal={true}
          unit={"invoices"}
          onChangePagination={(e: any, { activePage }: any) => {
            setFilters({ ...filters, page: activePage });
          }}
          topRightComponent={
            <MarketFilter
              marketOptions={marketOptions}
              onChange={_onChangeMarketFilter}
              values={filters.country_code}
            />
          }
        />
      </div>
      <ModalGeneratePropertyCompanyInvoice
        modalGenerate={modalGenerate}
        setModalGenerate={setModalGenerate}
        generateInvoice={_generateInvoice}
        generateLoading={generateLoading}
      />
    </div>
  );
};

export default DeskimoPropertiesInvoices;
