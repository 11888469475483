import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { Formik, FormikValues } from "formik";
import * as yup from "yup";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import ModalCenterTemplate from "../../../../../App/views/ModalCenterTemplate";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalBookingRoomSelector } from "../../../../selectors/DashboardSelectors";
import { modalBookingRoomManualAction } from "../../../../actions/DashboardActions";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { partnerCreateMeetingBookingService } from "../../../../services/DashboardServices";
import Label from "../../../../../../components/Label";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import SizeBox from "../../../../../../components/SizeBox";
import DatePickerSingle from "../../../../../../components/DatePickerSingle";
import { dayOptions } from "../../../../../App/configs/DateTimeConfigs";
import InputCustom from "../../../../../../components/Input";
import DropdownProperty from "../DropdownProperty";
import DropdownMeetingRoom from "../DropdownMeetingRoom";
import moment from "moment-timezone";
import { userCompanyChooseSelector } from "../../../../../User/selectors/UserSelectors";
import { dashboardRoomBookingThunk } from "../../../../thunks/DashboardThunks";
import { PRODUCT_TYPE_PARTNER } from "../../../../configs/Constants";

const ModalBookingRoom = () => {
  const { translations } = useContext(LocalizationContext);
  const modalBookingRoom = useSelector(modalBookingRoomSelector);
  const dispatch = useDispatch();
  const userCompanyChoose = useSelector(userCompanyChooseSelector);

  const _onCloseModal = () => {
    dispatch(modalBookingRoomManualAction({ open: false }));
  };

  const _onClickSave = async (values: FormikValues) => {
    try {
      const startTime = values.startTime.split(":");
      const endTime = values.endTime.split(":");

      const payload = {
        company_id: userCompanyChoose.company_id,
        full_name: values.full_name,
        email: values.email,
        meeting_room_reference: values.meeting_room_reference,
        start_time: moment(values.dateSelected)
          .set({ hour: startTime[0], minute: startTime[1], second: 0 })
          .tz(values.timezone, true)
          .unix(),
        end_time: moment(values.dateSelected)
          .set({ hour: endTime[0], minute: endTime[1], second: 0 })
          .tz(values.timezone, true)
          .unix(),
      };

      await partnerCreateMeetingBookingService(payload);
      await dispatch<any>(dashboardRoomBookingThunk(PRODUCT_TYPE_PARTNER));
      _onCloseModal();
      toastSuccess("Created Booking Successfully!");
    } catch (error: any) {
      console.log(error);
      toastError(error?.status?.message);
    }
  };

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={_onCloseModal}
      modalClassName={"modal-general"}
      open={modalBookingRoom.open}
    >
      <Modal.Header>
        Create a Room Booking
        <ButtonCustom onClick={_onCloseModal} className={"modal-close-cta"}>
          <>
            <span>{translations.close}</span>
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Formik
        initialValues={{
          full_name: "",
          email: "",
          property_reference: "",
          property_name: "",
          meeting_room_reference: "",
          meeting_room_name: "",
          timezone: "",
          dateSelected: new Date(),
          startTime: "",
          endTime: "",
          minTime: "",
          maxTime: "",
        }}
        validationSchema={yup.object().shape({
          email: yup.string().email().label("Email"),
        })}
        onSubmit={_onClickSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <>
            <Modal.Content>
              <form className={"form-layout"}>
                <div className={"d-flex gap-24"}>
                  <div className={"flex-1"}>
                    <InputCustom
                      label={"User Name"}
                      value={values.full_name}
                      onChange={handleChange}
                      name={"full_name"}
                      error={errors.full_name}
                      touched={touched.full_name}
                    />
                  </div>
                  <div className={"flex-1"}>
                    <InputCustom
                      label={"Email"}
                      value={values.email}
                      onChange={handleChange}
                      name={"email"}
                      error={errors.email}
                      touched={touched.email}
                    />
                  </div>
                </div>
                <SizeBox height={"16px"} />
                <div>
                  <Label
                    fontSize={"12px"}
                    className={"sm"}
                    label={"Property"}
                  />
                  <DropdownProperty
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </div>
                <SizeBox height={"16px"} />
                <div>
                  <Label
                    fontSize={"12px"}
                    className={"sm"}
                    label={"Meeting Room"}
                  />
                  <DropdownMeetingRoom
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </div>
                <SizeBox height={"16px"} />
                <div>
                  <Label
                    fontSize={"12px"}
                    className={"sm"}
                    label={"Start Date"}
                  />
                  <DatePickerSingle
                    value={values.dateSelected}
                    inputStyle
                    oneTap
                    onChange={(date: Date) => {
                      setFieldValue("dateSelected", date);
                    }}
                  />
                </div>
                <SizeBox height={"16px"} />
                <div className={"d-flex"}>
                  <div style={{ flex: 1 }}>
                    <Label
                      fontSize={"12px"}
                      className={"sm"}
                      label={"Start Time"}
                    />
                    <DropdownCustom
                      selectUI
                      search
                      onChange={(event, data) => {
                        setFieldValue(
                          "startTime",
                          data.value ? data.value.toString() : ""
                        );
                        setFieldValue(
                          "minTime",
                          data.value ? data.value.toString() : ""
                        );
                      }}
                      options={dayOptions({
                        minTime: undefined,
                        maxTime: values.maxTime,
                      })}
                      className={"dropdown-fullwidth-common-style"}
                    />
                  </div>
                  <SizeBox width={"16px"} />
                  <div style={{ flex: 1 }}>
                    <Label
                      fontSize={"12px"}
                      className={"sm"}
                      label={"End Time"}
                    />
                    <DropdownCustom
                      selectUI
                      search
                      onChange={(event, data) => {
                        setFieldValue(
                          "endTime",
                          data.value ? data.value.toString() : ""
                        );
                        setFieldValue(
                          "maxTime",
                          data.value ? data.value.toString() : ""
                        );
                      }}
                      options={dayOptions({
                        minTime: values.minTime,
                        maxTime: undefined,
                      })}
                      className={"dropdown-fullwidth-common-style"}
                    />
                  </div>
                </div>
              </form>
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom
                disabled={isSubmitting}
                height={"47px"}
                onClick={_onCloseModal}
              >
                {translations.cancel}
              </ButtonCustom>
              <ButtonCustom
                disabled={!values.meeting_room_reference || !values.endTime}
                loading={isSubmitting}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {translations.create}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalCenterTemplate>
  );
};

export default ModalBookingRoom;
