import { Popup } from "semantic-ui-react";
import React, { useState } from "react";
import "./style.scss";

const CopyToClipboard = ({ text, copyText, trigger }: any) => {
  const [copy, setCopy] = useState(false);
  return (
    <Popup
      className={"copy-to-clipboard-wrapper " + (copy ? " copied " : "")}
      onClose={() => {
        setCopy(false);
      }}
      on={copy ? ["click"] : ["hover"]}
      hoverable={!copy}
      content={
        <div
          onClick={() => {
            navigator.clipboard.writeText(text);
            setCopy(true);
          }}
          className={"copy-to-clipboard "}
        >
          {copy ? (
            <>
              <i className="uil uil-clipboard-notes" />
              Copied
            </>
          ) : (
            <>
              <i className="uil uil-clipboard" />
              {copyText}
            </>
          )}
        </div>
      }
      trigger={trigger}
    />
  );
};

export default CopyToClipboard;
