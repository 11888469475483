import React, { useContext, useEffect, useState } from "react";
import TableCustom from "../../../../../components/Table";
import { LocalizationContext } from "../../../../../locales/Translation";
import {
  dashboardDeskUsageFilterSelector,
  dashboardDeskUsageSelector,
  dashboardFilterDateRangeSelector,
} from "../../../selectors/DashboardSelectors";
import { useDispatch, useSelector } from "react-redux";
import { DeskUsageDataType } from "../../../configs/DeclareTypes";
import { dashboardDeskUsageThunk } from "../../../thunks/DashboardThunks";
import { dashboardDeskUsageFilterAction } from "../../../actions/DashboardActions";
import { userCompanyChooseSelector } from "../../../../User/selectors/UserSelectors";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../../App/configs/Constants";
import { Label as LabelSemantic } from "semantic-ui-react";
import { BUSINESS, PRODUCT_TYPE_BUSINESS } from "../../../configs/Constants";

const TableDeskUsage = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const dashboardDeskUsage = useSelector(dashboardDeskUsageSelector);
  const dashboardDeskUsageFilter = useSelector(
    dashboardDeskUsageFilterSelector
  );
  const userCompanyChoose = useSelector(userCompanyChooseSelector);
  const dashboardFilterDateRange = useSelector(
    dashboardFilterDateRangeSelector
  );

  useEffect(() => {
    (async () => {
      try {
        if (dashboardFilterDateRange === null) {
          return;
        }
        setLoading(true);
        await dispatch<any>(
          dashboardDeskUsageThunk(BUSINESS, false, false, PRODUCT_TYPE_BUSINESS)
        );
      } catch (e) {
        console.log("e", e);
      } finally {
        setLoading(false);
      }
    })();
  }, [dashboardDeskUsageFilter, userCompanyChoose, dashboardFilterDateRange]);

  const UserName = ({ name, isGuest }: { name: string; isGuest: boolean }) => {
    return (
      <div>
        {name}
        {"  "}
        {isGuest && <LabelSemantic size={"mini"}>Guest</LabelSemantic>}
      </div>
    );
  };

  const _formatInputData = () => {
    return dashboardDeskUsage.items.map(
      ({
        transaction_id,
        employee_name,
        phone_number,
        property,
        check_in_time,
        check_out_time,
        usage,
        hourly_rate,
        cost,
        status,
        is_guest,
      }: DeskUsageDataType) => {
        return {
          transaction_id,
          employee_name: <UserName name={employee_name} isGuest={is_guest} />,
          phone_number,
          property,
          check_in_time: check_in_time ? check_in_time : "",
          check_out_time: check_out_time ? check_out_time : "",
          usage,
          hourly_rate,
          cost,
          status: translations.deskBookingStatus[status],
        };
      }
    );
  };

  const columns = [
    { key: "visit_by_transaction_id", col: translations.transactionID },
    { key: "visit_by_employee_name", col: translations.userName },
    { key: "visit_by_phone_number", col: translations.phoneNo },
    { key: "visit_by_property_name", col: translations.property },
    { key: "visit_by_check_in", col: translations.checkInTime },
    { key: "visit_by_check_out", col: translations.checkOutTime },
    { key: "visit_by_usage", col: translations.usageMin },
    { key: "visit_by_hourly_date", col: translations.hourlyRate },
    { key: "visit_by_cost", col: translations.cost },
    { key: "visit_by_status", col: translations.status },
  ];

  const _onChangeSortCol = (col: any) => {
    if (dashboardDeskUsageFilter.sort_by !== col.key) {
      dispatch(
        dashboardDeskUsageFilterAction({ key: "sort_by", value: col.key })
      );
      dispatch(
        dashboardDeskUsageFilterAction({ key: "order_by", value: ORDER_BY_ASC })
      );
    } else {
      dispatch(
        dashboardDeskUsageFilterAction({ key: "sort_by", value: col.key })
      );
      if (dashboardDeskUsageFilter.order_by === ORDER_BY_NONE) {
        dispatch(
          dashboardDeskUsageFilterAction({
            key: "order_by",
            value: ORDER_BY_ASC,
          })
        );
      }
      if (dashboardDeskUsageFilter.order_by === ORDER_BY_ASC) {
        dispatch(
          dashboardDeskUsageFilterAction({
            key: "order_by",
            value: ORDER_BY_DESC,
          })
        );
      }
      if (dashboardDeskUsageFilter.order_by === ORDER_BY_DESC) {
        dispatch(
          dashboardDeskUsageFilterAction({
            key: "order_by",
            value: ORDER_BY_NONE,
          })
        );
      }
    }
  };

  return (
    <TableCustom
      orderBy={dashboardDeskUsageFilter.order_by}
      sortBy={dashboardDeskUsageFilter.sort_by}
      sortTable={true}
      onChangeSortCol={_onChangeSortCol}
      tableData={dashboardDeskUsage ? _formatInputData() : []}
      columns={columns}
      loading={loading}
      totalItems={dashboardDeskUsage?.pagination?.total}
      currentPage={dashboardDeskUsage?.pagination?.page}
      showTotal
      unit={translations.transactions}
      itemPerPage={dashboardDeskUsage?.pagination?.limit}
      onChangePagination={(e: any, { activePage }: any) => {
        dispatch(
          dashboardDeskUsageFilterAction({ key: "page", value: activePage })
        );
      }}
    />
  );
};

export default TableDeskUsage;
