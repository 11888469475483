import React, { useEffect, useState } from "react";
import { PUBLIC_URL } from "../../../../configs/Constants";
import SizeBox from "../../../../../../components/SizeBox";
import "./style.scss";
import { Progress } from "semantic-ui-react";

const SwalInviting = ({ maxCountdown }: any) => {
  const [countDown, setCountDown] = useState(maxCountdown);

  useEffect(() => {
    const interval = setInterval(async () => {
      setCountDown((prevState: any) => {
        if (prevState > 1) {
          return prevState - 1;
        }
        return 0;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={"swal-inviting"}>
      <img src={PUBLIC_URL + "/assets/icons/icon-email-blue.svg"} />
      <SizeBox height={"22px"} />
      <div className={"swal-inviting__title"}>
        Inviting employees from CSV
        <SizeBox height={"16px"} />
        <div className={"w-100"}>
          <Progress
            percent={100 - (countDown / maxCountdown) * 100}
            indicating
          />
        </div>
        <SizeBox height={"8px"} />
        <div className={"swal-inviting__progress-text"}>
          Estimated time remaining: {countDown}s
        </div>
        <SizeBox height={"3px"} />
      </div>
    </div>
  );
};

export default SwalInviting;
