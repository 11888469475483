export const DASHBOARD_FILTER_DATE_ACTION = "DASHBOARD_FILTER_DATE_ACTION";
export const DASHBOARD_FILTER_DATE_RANGE_ACTION =
  "DASHBOARD_FILTER_DATE_RANGE_ACTION";
export const DASHBOARD_STATISTIC_ACTION = "DASHBOARD_STATISTIC_ACTION";
export const DASHBOARD_STATISTIC_DESKIMO_ACTION =
  "DASHBOARD_STATISTIC_DESKIMO_ACTION";
export const DASHBOARD_STATISTIC_PROPERTY_ACTION =
  "DASHBOARD_STATISTIC_PROPERTY_ACTION";
export const DASHBOARD_STATISTIC_BOOKING_PROPERTY_ACTION =
  "DASHBOARD_STATISTIC_BOOKING_PROPERTY_ACTION";
export const DASHBOARD_STATISTIC_OFFICE_PROPERTY_ACTION =
  "DASHBOARD_STATISTIC_OFFICE_PROPERTY_ACTION";
export const DASHBOARD_ACTIVITY_LOG_ACTION = "DASHBOARD_ACTIVITY_LOG_ACTION";
export const DASHBOARD_ACTIVITY_LOG_LOAD_MORE_ACTION =
  "DASHBOARD_ACTIVITY_LOG_LOAD_MORE_ACTION";
export const DASHBOARD_DESK_USAGE_ACTION = "DASHBOARD_DESK_USAGE_ACTION";
export const DASHBOARD_DESK_BOOKING_ACTION = "DASHBOARD_DESK_BOOKING_ACTION";
export const DASHBOARD_DESK_BOOKING_CHANGE_STATUS_ACTION =
  "DASHBOARD_DESK_BOOKING_CHANGE_STATUS_ACTION";
export const DASHBOARD_DESK_USAGE_FILTER_ACTION =
  "DASHBOARD_DESK_USAGE_FILTER_ACTION";

export const DASHBOARD_DESKIMO_DESK_USAGE_FILTER_ACTION =
  "DASHBOARD_DESKIMO_DESK_USAGE_FILTER_ACTION";
export const DASHBOARD_DESK_BOOKING_FILTER_ACTION =
  "DASHBOARD_DESK_BOOKING_FILTER_ACTION";

export const DASHBOARD_DESKIMO_DESK_BOOKING_FILTER_ACTION =
  "DASHBOARD_DESKIMO_DESK_BOOKING_FILTER_ACTION";
export const DASHBOARD_ROOM_BOOKING_ACTION = "DASHBOARD_ROOM_BOOKING_ACTION";
export const DASHBOARD_ROOM_BOOKING_CHANGE_STATUS_ACTION =
  "DASHBOARD_ROOM_BOOKING_CHANGE_STATUS_ACTION";
export const DASHBOARD_ROOM_BOOKING_FILTER_ACTION =
  "DASHBOARD_ROOM_BOOKING_FILTER_ACTION";

export const DASHBOARD_DESKIMO_ROOM_BOOKING_FILTER_ACTION =
  "DASHBOARD_DESKIMO_ROOM_BOOKING_FILTER_ACTION";

export const DASHBOARD_LEASE_FILTER_ACTION = "DASHBOARD_LEASE_FILTER_ACTION";
export const DASHBOARD_PROPERTY_LIST_ACTION = "DASHBOARD_PROPERTY_LIST_ACTION";
export const DASHBOARD_PROPERTY_CHOOSE_ACTION =
  "DASHBOARD_PROPERTY_CHOOSE_ACTION";
export const DASHBOARD_PROPERTY_PENDING_BOOKINGS_ACTION =
  "DASHBOARD_PROPERTY_PENDING_BOOKINGS_ACTION";
export const DASHBOARD_PROPERTY_PENDING_BOOKING_REMOVE_ACTION =
  "DASHBOARD_PROPERTY_PENDING_BOOKING_REMOVE_ACTION";
export const DASHBOARD_PROPERTY_STATUS_ACTION =
  "DASHBOARD_PROPERTY_STATUS_ACTION";
export const GET_DECLINE_REASONS_ACTION = "GET_DECLINE_REASONS_ACTION";
export const MODAL_DECLINE_REASONS_ACTION = "MODAL_DECLINE_REASONS_ACTION";

export const MODAL_CHECK_IN_MANUAL_ACTION = "MODAL_CHECK_IN_MANUAL_ACTION";
export const MODAL_BOOKING_DESK_MANUAL_ACTION =
  "MODAL_BOOKING_DESK_MANUAL_ACTION";
export const MODAL_BOOKING_ROOM_MANUAL_ACTION =
  "MODAL_BOOKING_ROOM_MANUAL_ACTION";
export const MODAL_CREATE_WALK_INS_MANUAL_ACTION =
  "MODAL_CREATE_WALK_INS_MANUAL_ACTION";
export const DASHBOARD_STATISTIC_FILTER_ACTION =
  "DASHBOARD_STATISTIC_FILTER_ACTION";

export const LIST_PROPERTY_FILTER_ACTION = "LIST_PROPERTY_FILTER_ACTION";
export const LIST_PROPERTY_SELECTED_FILTER_ACTION =
  "LIST_PROPERTY_SELECTED_FILTER_ACTION";

export const GET_FREE_CREDIT_ACTION = "GET_FREE_CREDIT_ACTION";
export const TRANSACTION_INFO_ACTION = "TRANSACTION_INFO_ACTION";
export const MODAL_PARTNER_CREATE_WALKINS_ACTIONS =
  "MODAL_PARTNER_CREATE_WALKINS_ACTIONS";

// Filter date
export const FILTER_DATE_TODAY = "FILTER_DATE_TODAY";
export const FILTER_DATE_YESTERDAY = "FILTER_DATE_YESTERDAY";
export const FILTER_DATE_THIS_WEEK = "FILTER_DATE_THIS_WEEK";
export const FILTER_DATE_LAST_WEEK = "FILTER_DATE_LAST_WEEK";
export const FILTER_DATE_LAST_MONTH = "FILTER_DATE_LAST_MONTH";
export const FILTER_DATE_LAST_14_DAYS = "FILTER_DATE_LAST_14_DAYS";
export const FILTER_DATE_LAST_30_DAYS = "FILTER_DATE_LAST_30_DAYS";
export const FILTER_DATE_LAST_90_DAYS = "FILTER_DATE_LAST_90_DAYS";
export const FILTER_DATE_ALL_TIME = "FILTER_DATE_ALL_TIME";
export const FILTER_DATE_CUSTOM = "FILTER_DATE_CUSTOM";
export const FILTER_DATE_LAST_7_DAYS = "FILTER_DATE_LAST_7_DAYS";
export const FILTER_DATE_THIS_MONTH = "FILTER_DATE_THIS_MONTH";

export const DESK_USAGE_ON_GOING_STATUS = 0;
export const DESK_USAGE_AWAITING_PAYMENT_STATUS = 1;
export const DESK_USAGE_COMPLETE_STATUS = 2;

export const ROOM_BOOKING_ALL_STATUS = "all_status";
export const ROOM_BOOKING_CONFIRMED_STATUS = "confirmed_status";
export const ROOM_BOOKING_PENDING_STATUS = "pending_status";
export const ROOM_BOOKING_DECLINED_STATUS = "declined_status";
export const ROOM_BOOKING_CANCELLED_STATUS = "cancelled_status";
export const ROOM_BOOKING_COMPLETED_STATUS = "completed_status";
export const ROOM_BOOKING_SCHEDULE_STATUS = "scheduled_status";
export const ROOM_BOOKING_APPROVED_SCHEDULE_STATUS = "approved_schedule_status";
export const ROOM_BOOKING_PAYMENT_FAILED_STATUS = "payment_failed_status";
export const ROOM_BOOKING_PENDING_PAYMENT_STATUS = "pending_payment_status";
export const ROOM_BOOKING_PENDING_REFUNDED_STATUS = "pending_refund_status";
export const ROOM_BOOKING_REFUNDED_STATUS = "refunded_status";
export const ROOM_BOOKING_NOT_REFUNDED_STATUS = "not_refunded_status";

export const DESK_BOOKING_CONFIRMED_STATUS = 1;
export const DESK_BOOKING_DECLINED_STATUS = 2;

export const ROOM_BOOKING_REFUND_FILTER = [
  "pending_refund_status",
  "refunded_status",
  "not_refunded_status",
];

export const TAB_DESK_USAGE_INDEX = 1;
export const TAB_DESK_BOOKINGS_INDEX = 2;
export const TAB_ROOM_BOOKINGS_INDEX = 3;
export const TAB_PRIVATE_OFFICES_INDEX = 4;

export const BUSINESS = "BUSINESS";
export const PROPERTY = "PROPERTY";

export const TYPE_ROOM = "room";
export const TYPE_DESK = "desk";

export const TOTAL_SLOT = (maxSlot: number) => {
  const slotArr = [];
  for (let i = 1; i <= maxSlot; i++) {
    slotArr.push({
      key: i,
      text: i,
      value: i.toString(),
    });
  }
  return slotArr;
};

export const AUTO_REFRESH_INTERVAL_TIME = 10000;

export const ROOM_BOOKING = "ROOM_BOOKING";
export const DESK_BOOKING = "DESK_BOOKING";

export const PAYMENT_FAIL = "PAYMENT_FAIL";
export const PAYMENT_SCHEDULED = "PAYMENT_SCHEDULED";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";

export const WALK_IN = "WALK_IN";
export const LEASE = "LEASE";
export const BUSINESS_INVOICE = "BUSINESS_INVOICE";

export const PRODUCT_TYPE_BUSINESS = 1;
export const PRODUCT_TYPE_PARTNER = 4;

export const BOOKING_APPROVED = 1;
export const BOOKING_REJECTED = 2;
export const BOOKING_CANCELED = 3;
