import React, { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDeskimoPrivateOfficeListThunk } from "../../thunks/PropertyThunks";
import {
  deskimoPrivateOfficeExportSelectedSelector,
  deskimoPrivateOfficeFiltersSelector,
  deskimoPrivateOfficeListSelector,
} from "../../selectors/PropertySelector";
import {
  deskimoPrivateOfficeFilterAction,
  deskimoPrivateOfficeExportAction,
} from "../../actions/PropertyActions";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
  PUBLIC_URL,
} from "../../../App/configs/Constants";
import ModalAddEditStaff from "./Components/ModalAddEditStaff/index";
import ButtonCustom from "../../../../components/Button";
import { useGetAllQuery, useSetQuery } from "../../../../utils/custom-hooks";
import SearchWithDebounce from "../../../../components/SearchBar4Table/SearchWithDebounce";
import PrivateOfficeList from "./Components/PrivateOfficeListTable/PrivateOfficeList";
import FilterPrivateOffice from "./Components/FilterPrivateOffice";
import PptxGenJS from "pptxgenjs";
import moment from "moment";
import { generateGoogleMapsLink } from "../../../../utils/commons";

const DeskimoPropertiesPrivateOfficeList = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const deskimoPrivateOfficeFilters = useSelector(
    deskimoPrivateOfficeFiltersSelector
  );
  const listPrivateOfficeExport = useSelector(
    deskimoPrivateOfficeExportSelectedSelector
  );
  const listPrivateOffice = useSelector(deskimoPrivateOfficeListSelector);

  const [addParamsAlready, setAddParamsAlready] = useState<boolean>(false);
  const getAllQuery = useGetAllQuery();
  const setQuery = useSetQuery();
  const [refreshList, setRefreshList] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    try {
      if (!Object.keys(getAllQuery).length) {
        return;
      }
    } finally {
      setAddParamsAlready(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      setQuery(deskimoPrivateOfficeFilters);
      await _getPrivateOfficeList();
    })();
  }, [addParamsAlready, deskimoPrivateOfficeFilters, refreshList]);

  const downloadPPTX = () => {
    setExportLoading(true);
    const pptx = new PptxGenJS();
    const slideIntro = pptx.addSlide();
    slideIntro.background = {
      path: PUBLIC_URL + "/assets/images/slide-intro-bg.jpg",
    };

    slideIntro.addImage({
      path: PUBLIC_URL + "/assets/images/logo-white.png",
      x: 5.64,
      y: 3.48,
      w: 3.27,
      h: 0.94,
      sizing: {
        type: "contain",
        w: 3.27,
        h: 0.94,
      },
    });

    slideIntro.addText(
      [
        {
          text: "Office Space Solution",
          options: {
            breakLine: true,
            fontFace: "Quattrocento Sans",
            fontSize: 15,
            color: "ffffff",
            italic: true,
          },
        },
        {
          text: "Drees & Sommer APAC",
          options: {
            breakLine: true,
            fontFace: "Quattrocento Sans",
            fontSize: 15,
            color: "ffffff",
            italic: true,
          },
        },
        {
          text: moment().format("MMM DD, YYYY"),
          options: {
            breakLine: true,
            fontFace: "Quattrocento Sans",
            fontSize: 15,
            color: "ffffff",
            italic: true,
          },
        },
      ],
      { x: 5.67, y: 4.42, w: 4.08, h: 0.4 }
    );

    //Slide Space requirements
    const slideRequirements = pptx.addSlide();
    slideRequirements.addText(
      [
        {
          text: `Office Space Requirements`,
          options: {
            fontSize: 26,
            color: "ffbe00",
            x: 1.125,
            y: 0.34,
          },
        },
      ],
      {
        x: 0,
        y: 0,
        inset: 1.125,
        w: "100%",
        h: 1.19,
        fill: { color: "003DBC" },
      }
    );
    slideRequirements.addImage({
      path: PUBLIC_URL + "/assets/images/slide-top-left-bg.png",
      x: 0,
      y: 0,
      w: 0.75,
      h: 1.125,
    });

    slideRequirements.addText(
      [
        {
          text: `1`,
          options: {
            fontSize: 11,
            color: "ffbe00",
            x: 9.47,
            y: 5.16,
            align: "center",
          },
        },
      ],
      {
        x: 9.47,
        y: 5.16,
        inset: 0.3,
        w: 0.31,
        h: 0.19,
        rectRadius: 0.05,
        shape: pptx.ShapeType.roundRect,
        fill: { color: "003DBC" },
      }
    );

    const requirementRow = [];
    requirementRow.push([
      {
        text: "No of Pax.",
        options: {
          color: "ffffff",
          fill: { color: "003DBC" },
          fontSize: 12,
          bold: true,
        },
      },
      {
        text: "",
        options: { color: "000000", fontSize: 12 },
      },
    ]);
    requirementRow.push([
      {
        text: "Workplace Arrangement",
        options: {
          color: "ffffff",
          fill: { color: "003DBC" },
          fontSize: 12,
          bold: true,
        },
      },
      {
        text: "",
        options: { color: "000000", fontSize: 12 },
      },
    ]);
    requirementRow.push([
      {
        text: "Preferred Locations",
        options: {
          color: "ffffff",
          fill: { color: "003DBC" },
          fontSize: 12,
          bold: true,
        },
      },
      {
        text: "",
        options: { color: "000000", fontSize: 12 },
      },
    ]);
    requirementRow.push([
      {
        text: "Monthly Budget",
        options: {
          color: "ffffff",
          fill: { color: "003DBC" },
          fontSize: 12,
          bold: true,
        },
      },
      {
        text: "",
        options: { color: "000000", fontSize: 12 },
      },
    ]);
    requirementRow.push([
      {
        text: "Starting Date",
        options: {
          color: "ffffff",
          fill: { color: "003DBC" },
          fontSize: 12,
          bold: true,
        },
      },
      {
        text: "",
        options: { color: "000000", fontSize: 12 },
      },
    ]);
    requirementRow.push([
      {
        text: "Contract Duration",
        options: {
          color: "ffffff",
          fill: { color: "003DBC" },
          fontSize: 12,
          bold: true,
        },
      },
      {
        text: "",
        options: { color: "000000", fontSize: 12 },
      },
    ]);
    slideRequirements.addTable(requirementRow, {
      x: 0.96,
      y: 1.79,
      w: 8.09,
      h: 2.68,
      border: { pt: 1, color: "d9d9d9" },
      valign: "middle",
      colW: [2, 6.09],
    });

    //Dynamic Slide

    const listExport = listPrivateOffice?.private_offices?.filter(
      (office: any) => listPrivateOfficeExport.includes(office?.id)
    );

    if (listExport?.length > 0) {
      listExport.forEach((office: any, index: number) => {
        const slide = pptx.addSlide();

        slide.addText(
          [
            {
              text: `Option ${index + 1}: `,
              options: {
                fontSize: 26,
                color: "ffbe00",
                x: 1.125,
                y: 0.34,
              },
            },
            {
              text: office?.name,
              options: { fontSize: 26, color: "ffffff" },
            },
          ],
          {
            x: 0,
            y: 0,
            inset: 1.125,
            w: "100%",
            h: 1.19,
            fill: { color: "003DBC" },
          }
        );

        slide.addImage({
          path: office?.image,
          x: 5.95,
          y: 1.39,
          sizing: {
            type: "cover",
            w: 3.83,
            h: 3.96,
          },
        });

        const rows = [];

        rows.push([
          {
            text: "Address",
            options: { color: "ffffff", fill: { color: "003DBC" } },
          },
          {
            text: [
              {
                text: office?.address,
                options: {
                  color: "003DBC",
                  breakLine: true,
                  hyperlink: { url: generateGoogleMapsLink(office?.address) },
                },
              },
            ],
            options: { color: "000000" },
          },
        ]);

        const estimatedRateText = [];
        if (office?.monthly_rate) {
          estimatedRateText.push({
            text: `${office?.currency} ${office?.monthly_rate} per month`,
            options: { color: "000000", breakLine: true, bold: true },
          });
        }

        estimatedRateText.push({
          text: `${office?.currency} ${office?.daily_rate} per day`,
          options: { color: "000000", breakLine: true, bold: true },
        });

        estimatedRateText.push({
          text: `(Zero agency, third-party fees)`,
          options: { color: "000000", breakLine: true, italic: true },
        });

        estimatedRateText.push({
          text: ``,
          options: { color: "000000", breakLine: true, italic: true },
        });

        estimatedRateText.push({
          text: `Immediate availability`,
          options: { color: "000000", breakLine: true },
        });

        estimatedRateText.push({
          text: `2 months deposit`,
          options: { color: "000000", breakLine: true },
        });

        estimatedRateText.push({
          text: ``,
          options: { color: "000000", breakLine: true, italic: true },
        });

        if (office?.area && office?.capacity) {
          estimatedRateText.push({
            text: `${office?.area} sqr. meter `,
            options: { color: "000000", bold: true },
          });
          estimatedRateText.push({
            text: `office size • `,
            options: { color: "000000" },
          });
          estimatedRateText.push({
            text: `${office?.capacity} `,
            options: { color: "000000", bold: true },
          });
          estimatedRateText.push({
            text: `pax. capacity`,
            options: { color: "000000" },
          });
        } else {
          if (office?.area) {
            estimatedRateText.push({
              text: `${office?.area} sqr. meter `,
              options: { color: "000000", bold: true },
            });
            estimatedRateText.push({
              text: `office size`,
              options: { color: "000000" },
            });
          }

          if (office?.capacity) {
            estimatedRateText.push({
              text: `${office?.capacity} `,
              options: { color: "000000", bold: true },
            });
            estimatedRateText.push({
              text: `pax. capacity`,
              options: { color: "000000" },
            });
          }
        }

        rows.push([
          {
            text: "Monthly Rate \n(Inc. GST)",
            options: { color: "ffffff", fill: { color: "003DBC" } },
          },
          {
            text: estimatedRateText,
            options: { color: "000000" },
          },
        ]);
        rows.push([
          {
            text: "What is Included\n",
            options: { color: "ffffff", fill: { color: "003DBC" } },
          },
          {
            text: [
              {
                text: office?.what_include,
                options: { bullet: false, color: "000000" },
              },
            ],
            options: { color: "000000" },
          },
        ]);

        slide.addTable(rows, {
          x: 0,
          y: 1.19,
          w: 5.53,
          h: 4.35,
          border: { pt: 1, color: "d9d9d9" },
          valign: "middle",
          colW: [1.83, 3.7],
          rowH: [0.5, 1.62, 2.23],
        });

        slide.addImage({
          path: PUBLIC_URL + "/assets/images/slide-top-left-bg.png",
          x: 0,
          y: 0,
          w: 0.75,
          h: 1.125,
        });

        slide.addText(
          [
            {
              text: `${index + 2}`,
              options: {
                fontSize: 11,
                color: "ffbe00",
                x: 9.46,
                y: 5.16,
                align: "center",
              },
            },
          ],
          {
            x: 9.46,
            y: 5.16,
            inset: 0.01,
            w: 0.32,
            h: 0.19,
            rectRadius: 0.05,
            shape: pptx.ShapeType.roundRect,
            fill: { color: "003DBC" },
          }
        );
      });
    } else {
      return;
    }

    //Slide market place info
    const slideMarketPlace = pptx.addSlide();

    slideMarketPlace.addText(
      [
        {
          text: `${listExport?.length + 2}`,
          options: {
            fontSize: 11,
            color: "ffbe00",
            x: 9.46,
            y: 5.16,
            align: "center",
          },
        },
      ],
      {
        x: 9.46,
        y: 5.16,
        inset: 0.01,
        w: 0.32,
        h: 0.19,
        rectRadius: 0.05,
        shape: pptx.ShapeType.roundRect,
        fill: { color: "003DBC" },
      }
    );
    slideMarketPlace.addText(
      [
        {
          text: `Option ${listExport?.length + 1}: Deskimo Marketplace`,
          options: {
            fontSize: 26,
            color: "ffbe00",
            x: 1.125,
            y: 0.34,
          },
        },
      ],
      {
        x: 0,
        y: 0,
        inset: 1.125,
        w: "100%",
        h: 1.19,
        fill: { color: "003DBC" },
      }
    );

    const rows1 = [];
    rows1.push([
      {
        text: "Address",
        options: { color: "ffffff", fill: { color: "003DBC" }, fontSize: 12 },
      },
      {
        text: "Hundreds of workspace locations all around Singapore, Hong Kong, Malaysia, Indonesia, Thailand and the UAE",
        options: { color: "000000", fontSize: 12 },
      },
    ]);
    rows1.push([
      {
        text: "Monthly Rate \n(Inc. GST)",
        options: { color: "ffffff", fill: { color: "003DBC" }, fontSize: 12 },
      },
      {
        text: "SGD 2,200 of Deskimo credits, pay only SGD 2,000",
        options: { color: "000000", bold: true, fontSize: 12 },
      },
    ]);
    rows1.push([
      {
        text: "What is Included",
        options: { color: "ffffff", fill: { color: "003DBC" }, fontSize: 12 },
      },
      {
        text: [
          {
            text: "On-demand hot desking in quality workspaces, pay per minute",
            options: { bullet: true, color: "000000", fontSize: 12 },
          },
          {
            text: "Meeting rooms bookable on an hourly basis",
            options: { bullet: true, color: "000000", fontSize: 12 },
          },
          {
            text: "Dashboard Access for easy allocation of budget and monthly invoicing of team usage",
            options: { bullet: true, color: "000000", fontSize: 12 },
          },
        ],
        options: { color: "000000" },
      },
    ]);
    slideMarketPlace.addTable(rows1, {
      x: 0,
      y: 1.19,
      w: 4.48,
      h: 3.8,
      border: { pt: 1, color: "d9d9d9" },
      valign: "middle",
      colW: [1.83, 2.65],
      rowH: [0.5, 1.2, 2.23],
    });
    slideMarketPlace.addImage({
      path: PUBLIC_URL + "/assets/images/slide-marketplace-1.png",
      x: 4.86,
      y: 1.28,
      w: 2.38,
      h: 3.94,
    });
    slideMarketPlace.addImage({
      path: PUBLIC_URL + "/assets/images/slide-marketplace-2.png",
      x: 7.14,
      y: 1.36,
      w: 2.86,
      h: 2.04,
    });

    slideMarketPlace.addText(
      [
        {
          text: `Click the link`,
          options: {
            fontSize: 17,
            color: "000000",
            x: 7.29,
            y: 3.63,
            w: 1.09,
            h: 1.06,
          },
        },
        {
          text: ` here `,
          options: {
            fontSize: 17,
            color: "0562C1",
            hyperlink: { url: "https://bo.deskimo.com/create-account-option" },
            x: 7.29,
            y: 3.63,
            w: 1.09,
            h: 1.06,
          },
        },
        {
          text: `to start`,
          options: {
            fontSize: 17,
            color: "000000",
            x: 7.29,
            y: 3.63,
            w: 1.09,
            h: 1.06,
          },
        },
      ],
      {
        fontSize: 17,
        color: "000000",
        x: 7.29,
        y: 3.63,
        w: 1.09,
        h: 1.06,
      }
    );

    slideMarketPlace.addImage({
      path: PUBLIC_URL + "/assets/images/slide-top-left-bg.png",
      x: 0,
      y: 0,
      w: 0.75,
      h: 1.125,
    });

    //Slide End
    const slideEnd = pptx.addSlide();
    slideEnd.background = {
      path: PUBLIC_URL + "/assets/images/slide-end-bg.jpg",
    };

    pptx.writeFile({ fileName: "Office-Space-Solutions" });
    setTimeout(() => {
      setExportLoading(false);
    }, 2000);
  };

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    setCheckAll(false);
    dispatch(deskimoPrivateOfficeExportAction([]));
    dispatch(
      deskimoPrivateOfficeFilterAction({
        ...deskimoPrivateOfficeFilters,
        page: activePage,
      })
    );
  };

  const _onChangeSortCol = (col: any) => {
    setCheckAll(false);
    dispatch(deskimoPrivateOfficeExportAction([]));
    if (deskimoPrivateOfficeFilters?.sort_by !== col.key) {
      dispatch(
        deskimoPrivateOfficeFilterAction({
          ...deskimoPrivateOfficeFilters,
          sort_by: col.key,
          order_by: ORDER_BY_DESC,
        })
      );
    } else {
      dispatch(
        deskimoPrivateOfficeFilterAction({
          ...deskimoPrivateOfficeFilters,
          sort_by: col.key,
        })
      );
      if (deskimoPrivateOfficeFilters?.order_by === ORDER_BY_ASC) {
        dispatch(
          deskimoPrivateOfficeFilterAction({
            ...deskimoPrivateOfficeFilters,
            order_by: ORDER_BY_NONE,
          })
        );
      } else if (deskimoPrivateOfficeFilters?.order_by === ORDER_BY_NONE) {
        dispatch(
          deskimoPrivateOfficeFilterAction({
            ...deskimoPrivateOfficeFilters,
            order_by: ORDER_BY_DESC,
          })
        );
      } else {
        dispatch(
          deskimoPrivateOfficeFilterAction({
            ...deskimoPrivateOfficeFilters,
            order_by: ORDER_BY_ASC,
          })
        );
      }
    }
  };

  const _getPrivateOfficeList = async () => {
    try {
      setLoading(true);
      await dispatch<any>(
        getDeskimoPrivateOfficeListThunk(deskimoPrivateOfficeFilters)
      );
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className={"filter-section"}>
        <div className={"filter-section-left"}>
          <SearchWithDebounce
            initialValue={deskimoPrivateOfficeFilters?.keyword}
            placeholder={"Search by private office name"}
            onChange={(value) =>
              dispatch(
                deskimoPrivateOfficeFilterAction({
                  ...deskimoPrivateOfficeFilters,
                  keyword: value,
                })
              )
            }
          />
          <FilterPrivateOffice />
        </div>
        <ButtonCustom
          loading={exportLoading}
          disabled={listPrivateOfficeExport?.length === 0 || exportLoading}
          className={"primary"}
          height={"36px"}
          onClick={downloadPPTX}
        >
          Export PowerPoint for the selected offices +
        </ButtonCustom>
      </div>
      <PrivateOfficeList
        checkAll={checkAll}
        setCheckAll={setCheckAll}
        refreshList={refreshList}
        setRefreshList={setRefreshList}
        loading={loading}
        onChangePagination={_onChangePagination}
        onChangeSortCol={_onChangeSortCol}
      />
      <ModalAddEditStaff />
    </div>
  );
};

export default DeskimoPropertiesPrivateOfficeList;
