import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import UtilProgress from "./UtilProgress";
import SizeBox from "../../../../../components/SizeBox";
import { DashboardStatisticType } from "../../../configs/DeclareTypes";
import { useSelector } from "react-redux";
import { dashboardStatisticSelector } from "../../../selectors/DashboardSelectors";

const NumberOfBooking = () => {
  const { translations } = useContext(LocalizationContext);
  const dashboardStatistic: DashboardStatisticType = useSelector(
    dashboardStatisticSelector
  );
  console.log("dashboardStatistic: ", dashboardStatistic);

  return (
    <div className={"dashboard_chart_box_wrapper"}>
      <div className={"dashboard_chart_box"}>
        <h2 className={"dashboard_chart_title"}>{translations.usage}</h2>
        <SizeBox height={"31px"} />
        <div className={"dashboard_chart_inner"}>
          <UtilProgress
            title={`${translations.numberOfVisits} (${dashboardStatistic.total_visits})`}
            color={"#003DBC"}
            percent={dashboardStatistic.total_visit_per}
          />
          <UtilProgress
            title={`${translations.dayPassesPurchased} (${dashboardStatistic.total_day_passes})`}
            color={"#16C79A"}
            percent={dashboardStatistic.total_day_pass_per}
          />
          <UtilProgress
            title={`${translations.numberOfRoomBookings} (${dashboardStatistic.total_bookings})`}
            color={"#FFBE00"}
            percent={dashboardStatistic.total_booking_per}
          />
        </div>
      </div>
    </div>
  );
};

export default NumberOfBooking;
