import NavBarBusiness from "../NavBar/BusinessAdmin";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { isAuthSelector } from "../../../Authentication/selectors/AuthenticationSelectors";
import { Navigate, useLocation } from "react-router-dom";
import { userCompanyChooseSelector } from "../../../User/selectors/UserSelectors";
import {
  MyProfileType,
  UserCompanyType,
} from "../../../User/configs/DeclareTypes";
import {
  ROLE_BUSINESS_ADMIN_ID,
  ROLE_BUSINESS_USER_ID,
  ROLE_DESKIMO_ADMIN_ID,
  ROLE_PARTNER_ID,
  ROLE_PROPERTY_ADMIN_ID,
  ROLE_PROPERTY_STAFF_ID,
} from "../../../User/configs/Constants";
import NavBarPropertyAdmin from "../NavBar/NavBarProperty";
import NavBarBusinessUser from "../NavBar/NavBarBusinessUser";
import NotificationGlobal from "../NotificationGlobal";
import {
  DASHBOARD_BUSINESS_LINK,
  DASHBOARD_PROPERTY_LINK,
  LOGIN_LINK,
  DESKIMO_USERS_LINK,
  BOOK_DESK_LINK,
  DASHBOARD_PARTNER_LINK,
} from "../../configs/ListRoutes";
import NavBarDeskimo from "../NavBar/NavBarDeskimo";
import { myProfileSelector } from "../../selectors/AppSelectors";
import classNames from "classnames";
import NotificationBelowNav from "../NotificationBelowNav";
import WhatsAppButton from "./WhatsAppButton";
import { ACCESS_TOKEN_CACHE } from "../../../../configs/Constants";
import NavBarPartner from "../NavBar/NavBarPartner";
import ModalPackageTrial from "../../../Onboard/views/PaidTrail/ModalPackageTrial";
import ModalTopProperties from "../../../Onboard/views/PaidTrail/ModalTopProperties";
import { PARTNER_PREFIX_URL } from "../../configs/Constants";
import ModalAddTeamMember from "../../../User/views/PartnerTeams/ModalAddTeamMember";
import { usePermissionCheck } from "../../../../utils/custom-hooks";

type Props = {
  children: JSX.Element | JSX.Element[];
  isCheckAuth?: boolean;
  containerClass?: string;
  wrapperClass?: string;
  roleAccept?: number[];
  noWrapper?: boolean;
  permission?: string;
};

const MasterTemplate = ({
  children,
  isCheckAuth = true,
  containerClass = "",
  roleAccept,
  noWrapper,
  wrapperClass,
  permission,
}: Props) => {
  const isAuth = useSelector(isAuthSelector);
  const myProfile: MyProfileType = useSelector(myProfileSelector);
  const userCompanyChoose: UserCompanyType = useSelector(
    userCompanyChooseSelector
  );

  const { pathname } = useLocation();
  const permissionAllow = usePermissionCheck(permission);

  useEffect(() => {
    window.addEventListener("storage", (event) => {
      if (event.key === ACCESS_TOKEN_CACHE) {
        window.location.reload();
      }
    });

    return window.removeEventListener("storage", (event) => {
      if (event.key === ACCESS_TOKEN_CACHE) {
        window.location.reload();
      }
    });
  }, []);

  // if (!permissionAllow) {
  //   return null;
  // }

  if (!isAuth && isCheckAuth) {
    return <Navigate to={LOGIN_LINK} />;
  }

  if (
    myProfile?.role_id === ROLE_DESKIMO_ADMIN_ID &&
    roleAccept?.includes(ROLE_DESKIMO_ADMIN_ID)
  ) {
    return (
      <>
        <NotificationGlobal />
        <NavBarDeskimo />

        <div className={"spa-main"}>
          <div
            className={classNames(containerClass, {
              container: !noWrapper,
            })}
          >
            {children}
          </div>
        </div>
      </>
    );
  }

  if (
    myProfile?.role_id === ROLE_DESKIMO_ADMIN_ID &&
    !roleAccept?.includes(ROLE_DESKIMO_ADMIN_ID)
  ) {
    return <Navigate to={DESKIMO_USERS_LINK} />;
  }

  if (
    pathname.includes(PARTNER_PREFIX_URL) &&
    userCompanyChoose?.role_id === ROLE_BUSINESS_ADMIN_ID &&
    !userCompanyChoose?.is_partner
  ) {
    return <Navigate to={DASHBOARD_BUSINESS_LINK} />;
  }

  if (!roleAccept || roleAccept?.includes(userCompanyChoose?.role_id)) {
    return (
      <>
        <NotificationGlobal />
        {userCompanyChoose?.role_id === ROLE_PROPERTY_ADMIN_ID ||
        userCompanyChoose?.role_id === ROLE_PROPERTY_STAFF_ID ? (
          <>
            <NavBarPropertyAdmin />
            <WhatsAppButton />
          </>
        ) : (pathname.includes(PARTNER_PREFIX_URL) &&
            userCompanyChoose?.role_id === ROLE_BUSINESS_ADMIN_ID) ||
          userCompanyChoose?.role_id === ROLE_PARTNER_ID ? (
          <>
            <NavBarPartner />
            <ModalAddTeamMember />
          </>
        ) : userCompanyChoose?.role_id === ROLE_BUSINESS_ADMIN_ID ? (
          <NavBarBusiness />
        ) : userCompanyChoose?.role_id === ROLE_BUSINESS_USER_ID ? (
          <NavBarBusinessUser />
        ) : (
          <NavBarDeskimo />
        )}

        <NotificationBelowNav />
        <ModalPackageTrial />
        <ModalTopProperties />

        <div className={classNames("spa-main", wrapperClass)}>
          <div
            className={classNames(containerClass, {
              container: !noWrapper,
            })}
          >
            {children}
          </div>
        </div>
      </>
    );
  }

  if (
    (userCompanyChoose?.is_partner &&
      userCompanyChoose?.role_id === ROLE_BUSINESS_ADMIN_ID) ||
    userCompanyChoose?.role_id === ROLE_PARTNER_ID
  ) {
    return <Navigate to={DASHBOARD_PARTNER_LINK} />;
  }

  if (userCompanyChoose?.role_id === ROLE_BUSINESS_ADMIN_ID) {
    return <Navigate to={DASHBOARD_BUSINESS_LINK} />;
  }

  if (userCompanyChoose?.role_id === ROLE_BUSINESS_USER_ID) {
    return <Navigate to={BOOK_DESK_LINK} />;
  }

  if (
    userCompanyChoose?.role_id === ROLE_PROPERTY_ADMIN_ID ||
    userCompanyChoose?.role_id === ROLE_PROPERTY_STAFF_ID
  ) {
    return <Navigate to={DASHBOARD_PROPERTY_LINK} />;
  }

  return null;
};

export default MasterTemplate;
