import React, { useContext, useEffect, useState } from "react";
import SizeBox from "../../../../../components/SizeBox";
import InputCustom from "../../../../../components/Input";
import ButtonCustom from "../../../../../components/Button";
import { PropertyDetailType } from "../../../configs/DeclareType";
import { useDispatch, useSelector } from "react-redux";
import {
  assignUserListSelector,
  propertyDetailViewSelector,
  propertyFilterSelector,
} from "../../../selectors/BookSelectors";
import {
  assignBookingReviewService,
  assignBookingService,
  checkAvailableDeskService,
  getPropertyAvailableTimeService,
} from "../../../services/BookServices";
import {
  calculateTotalDeskPrice,
  convertDate2StatEndTimeUsingMoment,
  getLocalDateFromAnotherTimezoneMoment,
  isHoliday,
  makeBookingDeskService,
} from "../../../services/Common";
import { userCompanyChooseSelector } from "../../../../User/selectors/UserSelectors";
import { toastError } from "../../../../../components/Toast";
import { LocalizationContext } from "../../../../../locales/Translation";
import {
  assignBookingIdAction,
  assignUserListAction,
  modalAssignBookingSubmittedAction,
  modalAssignUserAction,
} from "../../../actions/BookActions";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import BookingBoxAssignUserRow from "./BookingBoxAssignUserRow";
import { getMyProfileService } from "../../../../App/services/AppServices";
import { CURRENT_COMPANY_ID } from "../../../../../configs/Constants";
import moment from "moment";
import { useParams } from "react-router-dom";
import DatePickerSingleV2 from "../../../../../components/DatePickerSingleV2";
import { getCreditRemainingThunk } from "../../../../App/thunks/AppThunk";
import BoxAddVoucherForBooking from "../../../../App/views/BoxAddVoucherForBooking";
import Divide from "../../../../../components/Divide";

const BookingBox = () => {
  const { defaultDate } = useParams();

  const { translations } = useContext(LocalizationContext);
  const propertyDetail: PropertyDetailType = useSelector(
    propertyDetailViewSelector
  );
  const propertyFilter = useSelector(propertyFilterSelector);
  const userCompanyChoose = useSelector(userCompanyChooseSelector);
  const [bookingNo, setBookingNo] = useState<number>(1);
  const [dateChoose, setDateChoose] = useState<any>(
    getLocalDateFromAnotherTimezoneMoment(
      defaultDate ? defaultDate : propertyFilter?.start_time,
      propertyDetail.timezone,
      defaultDate ? false : true
    )
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [bookingReview, setBookingReview] = useState<null | string | any>(null);
  const dispatch = useDispatch();
  const assignUserList = useSelector(assignUserListSelector);
  const [totalPrice, setTotalPrice] = useState("");
  const [myProfile, setMyProfile] = useState<any>(null);
  const [listAvailableDayOfWeekISO, setListAvailableDayOfWeekIso] =
    useState<any>({
      available_day_of_week: [],
      holidays: [],
    });
  const [isRefresh, setIsRefresh] = useState(false);

  useEffect(() => {
    _getMyProfile();
  }, [userCompanyChoose]);

  useEffect(() => {
    (async () => {
      try {
        const { startTime, endTime } = convertDate2StatEndTimeUsingMoment(
          propertyDetail.timezone,
          dateChoose
        );

        const payload = {
          id: String(propertyDetail.id),
          end_time: startTime,
          start_time: endTime,
        };
        const { data } = await checkAvailableDeskService(payload);
        setBookingReview(data);
      } catch (error: any) {
        switch (error?.status?.code) {
          case 1004:
            setBookingReview(translations.errorMessage.paymentInValid);
            break;
          case 1250:
            setBookingReview(translations.errorMessage.hotDeskIsNotAvailable);
            break;
          case 2000:
            setBookingReview(
              translations.errorMessage.paymentBusinessRestricted
            );
            break;
          default:
            setBookingReview(translations.errorMessage.hotDeskIsNotAvailable);
        }
      }
    })();
  }, [dateChoose, isRefresh]);

  useEffect(() => {
    if (assignUserList?.length > 0) {
      if (myProfile) {
        onPreview();
      }
    } else {
      if (myProfile) {
        dispatch(
          assignUserListAction([
            { ...myProfile, id: myProfile?.entitlement_id, slot: 1 },
          ])
        );
      }

      setTotalPrice("");
    }
  }, [assignUserList]);

  useEffect(() => {
    dispatch(
      assignUserListAction([
        { ...myProfile, id: myProfile?.entitlement_id, slot: 1 },
      ])
    );
  }, [myProfile]);

  useEffect(() => {
    _fetchAvailableDayOfWeek();
  }, []);

  const _fetchAvailableDayOfWeek = async () => {
    try {
      const payload = {
        format_time: "g:i A",
        property_id: propertyDetail?.id,
      };

      const response = await getPropertyAvailableTimeService(payload);
      setListAvailableDayOfWeekIso({
        available_day_of_week: response?.data?.available_days,
        holidays: response?.data?.holidays,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const _getMyProfile = async () => {
    let companyId = localStorage.getItem(CURRENT_COMPANY_ID);

    if (!companyId) {
      companyId = "0";
    }

    const response = await getMyProfileService(companyId);
    setMyProfile(response?.data);
  };

  const _makeBooking = async () => {
    try {
      setLoading(true);
      if (assignUserList?.length > 0) {
        const { startTime, endTime } = convertDate2StatEndTimeUsingMoment(
          propertyDetail.timezone,
          dateChoose
        );

        const entitlements = assignUserList.map((user: any, index: number) => {
          return {
            id: user?.id,
            slot: user?.slot,
          };
        });

        const response = await assignBookingService({
          property_id: propertyDetail?.id,
          start_time: startTime,
          end_time: endTime,
          entitlements,
          from_source: "BO_APP",
        });

        if (response?.data?.group_booking_id) {
          dispatch(assignBookingIdAction(response?.data?.group_booking_id));
          dispatch(modalAssignBookingSubmittedAction({ open: true }));
          dispatch(assignUserListAction([]));
        }
      } else {
        await makeBookingDeskService({
          propertyId: propertyDetail?.id,
          paymentCompanyId: userCompanyChoose?.company_id,
          bookingNo,
          dateChoose,
          timezone: propertyDetail.timezone,
        });
      }
      dispatch<any>(getCreditRemainingThunk());
    } catch (error: any) {
      if (assignUserList?.length > 0) {
        switch (error?.status?.code) {
          case 1002:
          case 1012:
          case 1014:
            toastError(error?.status?.message);
            break;
          case 1005:
            toastError(translations.errorMessage.invalidEntitlement);
            break;
          case 1006:
            toastError(translations.errorMessage.exceedLimitBookingSlots);
            break;
          case 1007:
            toastError(translations.errorMessage.invalidDesk);
            break;
          default:
            toastError(error?.status?.message);
            break;
        }
      } else {
        switch (error?.status?.code) {
          case 1002:
          case 1012:
          case 1014:
            toastError(error?.status?.message);
            break;
          case 1004:
            toastError(translations.errorMessage.paymentInValid);
            break;
          case 1250:
            toastError(translations.errorMessage.hotDeskIsNotAvailable);
            break;
          case 2000:
            toastError(translations.errorMessage.paymentBusinessRestricted);
            break;
          default:
            toastError(error?.status?.message);
            break;
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const onPreview = async () => {
    try {
      const { startTime, endTime } = convertDate2StatEndTimeUsingMoment(
        propertyDetail.timezone,
        dateChoose
      );
      const entitlements = assignUserList.map((user: any, index: number) => {
        return {
          id: user?.id,
          slot: user?.slot,
        };
      });
      const response = await assignBookingReviewService({
        property_id: propertyDetail?.id,
        start_time: startTime,
        end_time: endTime,
        entitlements,
        from_source: "BO_APP",
      });

      if (response?.total_price) {
        setTotalPrice(response?.total_price);
      }
    } catch (error: any) {
      switch (error?.status?.code) {
        case 1005:
          toastError(translations.errorMessage.invalidEntitlement);
          break;
      }
    }
  };

  const renderAssignUserList = () => {
    if (assignUserList?.length > 0) {
      return assignUserList.map((user: any, index: number) => (
        <BookingBoxAssignUserRow
          onPreview={onPreview}
          user={user}
          key={index}
        />
      ));
    }
  };

  const checkDisabledDate = (date: any) => {
    const currentDayOfWeek = moment(date).day();
    const today = moment().set({ hours: 0 });
    if (moment(date).isBefore(today)) {
      return true;
    }

    const isAvailableDayOfWeek =
      listAvailableDayOfWeekISO.available_day_of_week.filter(
        (item: any) => item === currentDayOfWeek
      );
    if (isAvailableDayOfWeek?.length < 1) {
      return true;
    }

    if (isHoliday(listAvailableDayOfWeekISO?.holidays, date)) {
      return true;
    }
  };

  return (
    <div className={"book-detail__booking-wrapper"}>
      <div className={"book-detail__booking"}>
        <div className={"book-detail__booking-summary"}>
          <h3 className={"title"}>Booking Summary</h3>
          <h6 className={"description"}>
            Your day pass will be valid for the selected date
          </h6>
          <div className="assigned-wrapper">
            <table className={"assigned-list"}>
              <thead>
                <tr>
                  <th className={"text-left"}>User</th>
                  <th className={"text-right"}>No. of Desks</th>
                </tr>
              </thead>
              <tbody>{renderAssignUserList()}</tbody>
            </table>
          </div>

          <SizeBox height={"16px"} />
          <ButtonCustom
            onClick={() => {
              dispatch(modalAssignUserAction({ open: true }));
            }}
            height={"40px"}
            className={"outline-primary w-100 m-0"}
          >
            Assign Booking +
          </ButtonCustom>
        </div>
        <div className={"book-detail__booking-bottom"}>
          <div className={"booking-detail__info"}>
            <div className={"label"}>Date</div>
            <DatePickerSingleV2
              format={"DD/MM/YYYY"}
              allowClear={false}
              value={dateChoose}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              disabledDate={(date: any) => {
                return checkDisabledDate(date);
              }}
              showToday={false}
              className={"w-100"}
              onChange={(date: any) => {
                setDateChoose(date);
              }}
            />
            <SizeBox height={"16px"} />
          </div>
          <div className={"booking-detail__info"}>
            <div className={"label"}>Duration</div>
            <InputCustom value={"Full Day"} disabled height={"40px"} />
          </div>
          <BoxAddVoucherForBooking
            isRefresh={isRefresh}
            setIsRefresh={setIsRefresh}
          />

          {bookingReview === null ? null : typeof bookingReview === "string" ? (
            <div className="row">
              <div className={"label"}>{bookingReview}</div>
            </div>
          ) : (
            <>
              {bookingReview?.discount && bookingReview?.discount > 0 ? (
                <>
                  <>
                    <SizeBox height={"20px"} />
                    <div className="row">
                      <div className={"label-small"}>Subtotal</div>
                      <div className={"label-small text-right"}>
                        {bookingReview?.currency}{" "}
                        {bookingReview?.price_before_discount}
                      </div>
                    </div>
                    <SizeBox height={"16px"} />
                  </>

                  <>
                    <div className="row">
                      <div className={"label-small"}>Discount</div>
                      <div className={"label-small text-right"}>
                        - {bookingReview?.currency}{" "}
                        {bookingReview?.discount < 1
                          ? "0"
                          : bookingReview?.discount}
                      </div>
                    </div>
                  </>
                </>
              ) : (
                <SizeBox height={"4px"} />
              )}
              <Divide height={"1px"} type={"horizontal"} margin={"16px 0"} />
              <div className="row">
                <div className={"label"}>Total Amount Paid</div>
                <div className={"label"}>
                  {/*{totalPrice ? (*/}
                  {/*  totalPrice*/}
                  {/*) : (*/}
                  <>
                    {bookingReview?.currency}{" "}
                    {calculateTotalDeskPrice(
                      bookingNo,
                      bookingReview?.total_final_price
                    )}
                  </>
                  {/*)}*/}
                </div>
              </div>
            </>
          )}
          {typeof bookingReview === "string" ? null : (
            <>
              <SizeBox height={"16px"} />
              <ButtonCustom
                width={"100%"}
                height={"50px"}
                className={"primary"}
                onClick={_makeBooking}
                loading={loading}
                disabled={loading}
              >
                <b>
                  Purchase a day pass{assignUserList?.length > 1 ? "es" : ""}
                </b>
              </ButtonCustom>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingBox;
