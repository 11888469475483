import React, { useContext } from "react";
import { LocalizationContext } from "../../../../locales/Translation";
import { Link, Route, useLocation } from "react-router-dom";
import {
  BUSINESS_COMPANY_INVOICES_LINK,
  BUSINESS_COMPANY_LIST_LINK,
  BUSINESS_COMPANY_OFFICE_LEASE_LINK,
  BUSINESS_COMPANY_PREPAID_CREDIT_LINK,
  BUSINESS_COMPANY_PURCHASE_HISTORY_LINK,
  BUSINESS_COMPANY_STAFF_LINK,
  BUSINESS_CUSTOM_PACKAGES_LINK,
} from "../../../App/configs/ListRoutes";
import CompanyList from "../CompanyList";
import CompanyStaff from "../CompanyStaff";
import CompanyInvoices from "../CompanyInvoices";
import MasterTemplate from "../../../App/views/MasterTemplate";
import { ROLE_DESKIMO_ADMIN_ID } from "../../../User/configs/Constants";
import { Tab } from "semantic-ui-react";
import CompanyCustomPackages from "../CompanyCustomPackages";
import OfficeLease from "../OfficeLease";
import PrepaidCredit from "../PrepaidCredit/CreditsUsage";
import PurchaseHistoryTable from "../PrepaidCredit/PurchaseHistory";

type Props = {
  tabIndex?: number;
};

const BusinessTab = ({ tabIndex }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const { pathname } = useLocation();

  const panes = [
    {
      menuItem: {
        as: Link,
        id: "tab1",
        to: BUSINESS_COMPANY_LIST_LINK,
        content: "Companies",
        exact: true,
      },
      pane: <Route path={BUSINESS_COMPANY_LIST_LINK} />,
      render: () => <CompanyList />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab2",
        to: BUSINESS_COMPANY_STAFF_LINK,
        content: "Company Staff",
        exact: true,
        pane: <Route path={BUSINESS_COMPANY_STAFF_LINK} />,
      },
      render: () => <CompanyStaff />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab3",
        to: BUSINESS_COMPANY_INVOICES_LINK,
        content: "Company Invoices",
        exact: true,
        pane: <Route path={BUSINESS_COMPANY_INVOICES_LINK} />,
      },
      render: () => <CompanyInvoices />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab3",
        to: BUSINESS_COMPANY_PURCHASE_HISTORY_LINK,
        content: "Prepaid Credits",
        exact: true,
        className:
          pathname.includes(BUSINESS_COMPANY_PREPAID_CREDIT_LINK) && "d-none",
        pane: <Route path={BUSINESS_COMPANY_PURCHASE_HISTORY_LINK} />,
      },
      render: () => <PurchaseHistoryTable />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab5",
        to: BUSINESS_COMPANY_PURCHASE_HISTORY_LINK,
        content: "Prepaid Credits",
        exact: true,
        className:
          !pathname.includes(BUSINESS_COMPANY_PREPAID_CREDIT_LINK) && "d-none",
        pane: <Route path={BUSINESS_COMPANY_PREPAID_CREDIT_LINK} />,
      },
      render: () => <PrepaidCredit />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab6",
        to: BUSINESS_COMPANY_OFFICE_LEASE_LINK,
        content: "Office Leases",
        exact: true,
        pane: <Route path={BUSINESS_COMPANY_OFFICE_LEASE_LINK} />,
      },
      render: () => <OfficeLease />,
    },
    {
      menuItem: {
        as: Link,
        id: "tab7",
        to: BUSINESS_CUSTOM_PACKAGES_LINK,
        content: translations.customPackages.customPackagesHeaderTitle,
        exact: true,
        pane: <Route path={BUSINESS_CUSTOM_PACKAGES_LINK} />,
      },
      render: () => <CompanyCustomPackages />,
    },
  ];

  return (
    <MasterTemplate roleAccept={[ROLE_DESKIMO_ADMIN_ID]}>
      <Tab
        menu={{ text: true }}
        activeIndex={tabIndex}
        panes={panes}
        className={"tab-custom"}
      />
    </MasterTemplate>
  );
};

export default BusinessTab;
