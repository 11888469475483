import SizeBox from "../../../../../../../components/SizeBox";
import React, { useState, useEffect, useContext } from "react";
import EditActions from "./EditActions";
import { useDispatch, useSelector } from "react-redux";
import { propertyDetailSelector } from "../../../../../selectors/PropertySelector";
import Label from "../../../../../../../components/Label";
import InputCustom from "../../../../../../../components/Input";
import { Formik } from "formik";
import ButtonCustom from "../../../../../../../components/Button";
import { updatePropertyService } from "../../../../../services/PropertyServices";
import { getPropertyDetailThunk } from "../../../../../thunks/PropertyThunks";
import { toastError } from "../../../../../../../components/Toast";
import { useParams } from "react-router-dom";
import MapAutocompleted from "./MapAutocompleted";
import { LocalizationContext } from "../../../../../../../locales/Translation";
import ToggleSlider from "../../../../../../../components/ToggleSlider";

const PropertyParkingLocation = ({ setLoading, loading }: any) => {
  const { translations } = useContext(LocalizationContext);

  const { id } = useParams();
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const propertyDetail = useSelector(propertyDetailSelector);
  const [geo, setGeo] = useState({
    lat: "",
    lng: "",
  });
  const [address, setAddress] = useState("");
  const [geo2, setGeo2] = useState({
    lat: "",
    lng: "",
  });
  const [address2, setAddress2] = useState("");

  useEffect(() => {
    const parking = propertyDetail?.parking;
    if (parking && parking.length > 0) {
      setAddress(parking[0]?.address);
      setGeo({ lat: parking[0]?.lat, lng: parking[0]?.lng });

      if (parking.length > 1) {
        setAddress2(parking[1]?.address);
        setGeo2({ lat: parking[1]?.lat, lng: parking[1]?.lng });
      }
    }
  }, [propertyDetail]);

  const _handleSave = async (values: any) => {
    if (!values?.display_name1) {
      toastError("Enter the address of the first parking space");
      return;
    }

    if (address2 && !values?.display_name2) {
      toastError("Enter the address of the second parking space");
      return;
    }

    const payload = {
      property_id: id,
      parking: [
        {
          address,
          display_name: values?.display_name1,
          lat: geo?.lat,
          lng: geo?.lng,
        },
      ],
      allow_parking_request: values.allow_parking_request,
    };

    if (address2) {
      payload.parking.push({
        address: address2,
        display_name: values?.display_name2,
        lat: geo2?.lat,
        lng: geo2?.lng,
      });
    }

    setLoading(true);
    try {
      await updatePropertyService(payload);
      await dispatch<any>(getPropertyDetailThunk(id ? id : ""));
      setEditMode(false);
    } catch (response: any) {
      toastError(response?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={"section"}>
      <SizeBox height={"16px"} />
      <div
        className={
          "d-flex justify-content-between " +
          (editMode ? "" : "align-items-center")
        }
      >
        <div className={"pr-70 w-100"}>
          <div className={"section-title"}>{translations.parkingLocation}</div>
          <SizeBox height={"21px"} />
          <div>
            {editMode ? (
              <Formik
                initialValues={{
                  display_name1: propertyDetail?.parking[0]?.display_name || "",
                  display_name2: propertyDetail?.parking[1]?.display_name || "",
                  allow_parking_request:
                    propertyDetail?.allow_parking_request || false,
                }}
                onSubmit={_handleSave}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <>
                    <div>
                      <InputCustom
                        label={
                          translations.parking +
                          " 1 " +
                          translations.addressDisplayNameStreetNumberStreetName
                        }
                        value={values.display_name1}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.display_name1}
                        error={errors.display_name1}
                        name={"display_name1"}
                      />
                      <Label
                        fontSize={"12px"}
                        label={translations.addressForMapPin}
                        className={"sm"}
                      />
                      <MapAutocompleted
                        address={address}
                        setAddress={setAddress}
                        geo={geo}
                        setGeo={setGeo}
                      />
                    </div>
                    <SizeBox height={"32px"} />
                    <div>
                      <InputCustom
                        label={
                          translations.parking +
                          " 2 " +
                          translations.addressDisplayNameStreetNumberStreetName
                        }
                        value={values.display_name2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.display_name2}
                        error={errors.display_name2}
                        name={"display_name2"}
                      />
                      <Label
                        fontSize={"12px"}
                        label={translations.addressForMapPin}
                        className={"sm"}
                      />
                      <MapAutocompleted
                        address={address2}
                        setAddress={setAddress2}
                        geo={geo2}
                        setGeo={setGeo2}
                      />
                    </div>
                    <SizeBox height={18} />
                    <ToggleSlider
                      value={values.allow_parking_request}
                      content={
                        <Label
                          label={"Allow book for vehicle parking"}
                          labelGuide={
                            "Enable this option to allow free vehicle parking reservations for this location."
                          }
                        />
                      }
                      revertLabel
                      onChange={() =>
                        setFieldValue(
                          "allow_parking_request",
                          !values.allow_parking_request
                        )
                      }
                    />
                    <div className={"edit-cta-wrapper edit-mode"}>
                      <ButtonCustom
                        onClick={() => setEditMode(false)}
                        className={"outline-primary"}
                        fontSize={"12px"}
                        height={"37px"}
                      >
                        <>{translations.cancel}</>
                      </ButtonCustom>
                      <ButtonCustom
                        loading={loading}
                        onClick={handleSubmit}
                        className={"primary-alt"}
                        fontSize={"12px"}
                        height={"37px"}
                      >
                        <>{translations.save}</>
                      </ButtonCustom>
                    </div>
                  </>
                )}
              </Formik>
            ) : null}

            {propertyDetail?.parking?.length > 0 && !editMode
              ? propertyDetail?.parking.map((parking: any, index: number) => {
                  return (
                    <div key={index}>
                      <div className={"d-flex parking-location__item"}>
                        <div className={"parking-location__left"}>
                          <span className={"fw-600"}>Parking Address:</span>{" "}
                          {parking?.address}
                        </div>
                        <div className={"parking-location__right"}>
                          <span className={"fw-600"}>
                            Parking Display Name:
                          </span>{" "}
                          {parking?.display_name}
                        </div>
                      </div>
                      <SizeBox height={"18px"} />
                    </div>
                  );
                })
              : null}

            {!editMode ? (
              <div className={"d-flex parking-location__item"}>
                <div className={"parking-location__left"}>
                  <span className={"fw-600"}>
                    Allow book for vehicle parking:
                  </span>{" "}
                  {propertyDetail?.allow_parking_request ? "Yes" : "No"}
                </div>
              </div>
            ) : null}
          </div>
        </div>

        {editMode ? null : (
          <EditActions
            editMode={editMode}
            setEditMode={setEditMode}
            showSave={false}
          />
        )}
      </div>
      <SizeBox height={"36px"} />
    </div>
  );
};

export default PropertyParkingLocation;
