export const isModalPackageTopUpSelector = (state: any) =>
  state.AppReducer.isModalPackageTopUp;

export const isModalAddEmployeeSelector = (state: any) =>
  state.AppReducer.isModalAddEmployee;

export const isModalImportEmployeeSelector = (state: any) =>
  state.AppReducer.isModalImportEmployee;

export const isModalMyProfileSelector = (state: any) =>
  state.AppReducer.isModalMyProfile;

export const isModalUpdatePasswordSelector = (state: any) =>
  state.AppReducer.isModalUpdatePassword;

export const isModalAddVoucherSelector = (state: any) =>
  state.AppReducer.isModalAddVoucher;

export const modalAddVoucherForBookingSelector = (state: any) =>
  state.AppReducer.modalAddVoucherForBooking;

//Navbar
export const creditRemaining = (state: any) => state.AppReducer.creditRemaining;

export const isModalDownloadAppSelector = (state: any) =>
  state.AppReducer.isModalDownloadApp;

export const isModalNavbarMobileSelector = (state: any) =>
  state.AppReducer.isModalNavbarMobile;

export const myProfileSelector = (state: any) => state.AppReducer.myProfile;

export const userGuideSelector = (state: any) => state.AppReducer.userGuide;
export const userGuideSendBulkInviteSelector = (state: any) =>
  state.AppReducer.userGuideSendBulkInvite;

export const isGlobalIndicatorSelector = (state: any) =>
  state.AppReducer.isGlobalIndicator;

export const globalSettingSelector = (state: any) =>
  state.AppReducer.globalSetting;

export const headerPopupToppingUpSelector = (state: any) =>
  state.AppReducer.headerPopupToppingUp;

export const creditPackagesSelector = (state: any) =>
  state.AppReducer.creditPackages;

export const modalStartUserGuideSelector = (state: any) =>
  state.AppReducer.modalStartUserGuide;

export const onboardPromotionInfoSelector = (state: any) =>
  state.AppReducer.onboardPromotionInfo;

export const listVoucherForBookingSelector = (state: any) =>
  state.AppReducer.listVoucherForBooking;
