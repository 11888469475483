import React, { useContext, useState } from "react";
import ButtonCustom from "../../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { companyIntegrationService } from "../../../../services/SettingServices";
import { useDispatch } from "react-redux";
import { SWAL_DELETE_OPTION } from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import ModalIntegrationConfig from "./ModalIntegrationConfig";
import { useGoogleLogin } from "react-google-login";
import { inviteEmployeeAfterLoginGoogle } from "../../../../../User/services/EmployeeServices";
import { integrationUpdateAction } from "../../../../actions/IntegrationAction";
import { toastSuccess } from "../../../../../../components/Toast";
import { GATrackingEvent } from "../../../../../../services/GATrackingService";

type Props = {
  status: number;
  id: number;
  autoInvite: boolean;
};

const IntegrationAction = ({ status, id, autoInvite }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const [open, setOpen] = useState<boolean>(false);

  const { signIn } = useGoogleLogin({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || "",
    onSuccess: (response) =>
      inviteEmployeeAfterLoginGoogle({ translations, response }),
    cookiePolicy: "single_host_origin",
    uxMode: "popup",
    responseType: "code",
    accessType: "offline",
    prompt: "consent",
    scope: "https://www.googleapis.com/auth/admin.directory.user",
    isSignedIn: false,
  });

  const _onEdit = async () => {
    setOpen(true);
  };

  const _onStatus = async () => {
    try {
      if (status === 1) {
        const icon = null;
        const title = translations.areYouSureWantDeactivateIntegration;
        const confirmText = translations.deactivate;
        const confirmClass = "danger";
        const options = SWAL_DELETE_OPTION({
          title,
          icon,
          confirmText,
          confirmClass,
        });
        SweetAlertCommon({
          options,
          confirmCallback: async () => {
            const value = status ? 0 : 1;
            const payload = {
              status: value,
            };
            await companyIntegrationService(id, payload);
            dispatch(integrationUpdateAction({ id, key: "status", value }));
            toastSuccess(
              status ? "Successfully Deactivated" : "Successfully Activated"
            );
            GATrackingEvent("gg_integration_deactivate_action");
          },
          cancelCallback: () => null,
        });
        return;
      }

      signIn();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <DropdownCustom
        className={"table-action-dropdown"}
        direction={"left"}
        icon={null}
        trigger={
          <ButtonCustom
            style={{ width: "38px", height: "38px" }}
            className={"primary-alt"}
          >
            <i className="uil uil-setting" style={{ fontSize: "21px" }} />
          </ButtonCustom>
        }
      >
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={_onEdit}
            text={translations.edit}
            icon={<i className="uil uil-edit icon" />}
          />
          <Dropdown.Item
            onClick={_onStatus}
            text={status ? translations.deactivate : translations.activate}
            icon={
              <img src={PUBLIC_URL + "/assets/icons/icon-toggle-slider.svg"} />
            }
          />
        </Dropdown.Menu>
      </DropdownCustom>

      <ModalIntegrationConfig
        id={id}
        autoInviteDefault={autoInvite}
        isOpen={open}
        setIsOpen={setOpen}
      />
    </>
  );
};

export default IntegrationAction;
