import Label from "../../../../../components/Label";
import ToggleSlider from "../../../../../components/ToggleSlider";
import InputCustom from "../../../../../components/Input";
import React from "react";
import SizeBox from "../../../../../components/SizeBox";

const AffiliateSection = ({
  values,
  setFieldValue,
  errors,
  touched,
  handleChange,
  isSubmitting,
}: any) => {
  return (
    <>
      <Label
        label={"Affiliate"}
        fontSize={"24px"}
        className={"section-title"}
      />
      <div className={"detail_divide"}>
        <div className="left_section">
          <ToggleSlider
            checked={values.company_affiliate.is_active}
            onChange={(e: any, data: any) =>
              setFieldValue("company_affiliate.is_active", data.checked)
            }
            label={"Enable affiliate for this company"}
          />
          <SizeBox height={16} />
          {values.company_affiliate.is_active && (
            <div className={"d-flex flex-column"}>
              <InputCustom
                labelRequired
                type={"number"}
                max={100}
                min={0}
                inputLeftIcon={"%"}
                inputLeftIconBorder={true}
                label={"Commission"}
                value={values.company_affiliate.commission}
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                error={errors.company_affiliate?.commission}
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                touched={touched.company_affiliate?.commission}
                name={"company_affiliate.commission"}
                onChange={handleChange}
                disabled={isSubmitting}
              />
              <InputCustom
                labelRequired
                type={"number"}
                max={100}
                min={0}
                inputLeftIcon={"Months"}
                inputLeftIconBorder={true}
                label={"Duration"}
                value={values.company_affiliate.duration}
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                error={errors.company_affiliate?.duration}
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                touched={touched.company_affiliate?.duration}
                name={"company_affiliate.duration"}
                onChange={handleChange}
                disabled={isSubmitting}
              />
            </div>
          )}
        </div>
      </div>
      <SizeBox height={"12px"} />
      <div className={"property_company_detail_line"} />
    </>
  );
};

export default AffiliateSection;
