import { Link, NavLink, useLocation } from "react-router-dom";
import { PUBLIC_URL } from "../../configs/Constants";
import ButtonCustom from "../../../../components/Button";
import ProfileDropdown from "./ProfileDropdown";
import { useDispatch } from "react-redux";
import { modalNavbarMobileAction } from "../../actions/AppActions";
import {
  BOOK_DESK_DETAIL_LINK,
  BOOK_ROOM_DETAIL_LINK,
  BOOK_ROOM_LINK,
  DASHBOARD_PARTNER_LINK,
  INVOICE_PARTNER_LINK,
  PARTNER_INTEGRATION_LINK,
  PARTNER_LIMITATION_LINK,
  PARTNER_OPERATION_LOG_LINK,
  PARTNER_TEAM_LINK,
} from "../../configs/ListRoutes";
import React, { useEffect, useState } from "react";
import { isMobile } from "../../../../utils/commons";
import NavBarPartnerMobile from "../ModalNavBarMobile/NavBarPartnerMobile";
import "./styles.scss";
import { isModalAddTeamMemberAction } from "../../../User/actions/PartnerTeamActions";

const NavBarPartner = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [integrationNavAction, setIntegrationNavAction] = useState(false);

  useEffect(() => {
    if (
      pathname === PARTNER_OPERATION_LOG_LINK ||
      pathname === PARTNER_LIMITATION_LINK
    ) {
      setIntegrationNavAction(true);
    } else {
      setIntegrationNavAction(false);
    }
  }, [pathname]);

  return (
    <div className={"navbar"}>
      {isMobile() ? (
        <ButtonCustom
          onClick={() => dispatch(modalNavbarMobileAction(true))}
          className={"primary-alt navbar__toggle"}
          height={"37px"}
        >
          <i className="uil uil-bars" />
        </ButtonCustom>
      ) : (
        <Link to={DASHBOARD_PARTNER_LINK} className={"navbar__logo"}>
          <img src={PUBLIC_URL + "/assets/logo.svg"} alt="deskimo-logo" />
        </Link>
      )}

      <div className={"navbar__left"}>
        <NavLink
          className={({ isActive }) => (isActive ? "active" : "")}
          to={DASHBOARD_PARTNER_LINK}
        >
          <i className="uil uil-chart-line" />
          Overview
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "active" : "")}
          to={PARTNER_TEAM_LINK}
        >
          <i className="uil uil-users-alt" />
          Team
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "active" : "")}
          to={INVOICE_PARTNER_LINK}
        >
          <i className="uil uil-bill" />
          Billing
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive || integrationNavAction ? "active" : ""
          }
          to={PARTNER_INTEGRATION_LINK}
        >
          <i className="uil uil-setting" />
          Integration
        </NavLink>
      </div>
      <div className={"navbar__right"}>
        {isMobile() ? null : (
          <ButtonCustom
            height={"36px"}
            className={"primary"}
            onClick={() => dispatch(isModalAddTeamMemberAction(true))}
          >
            <>
              <i className="uil uil-user-plus mr-8px"></i>Add Team Members
            </>
          </ButtonCustom>
        )}

        <ProfileDropdown />

        {isMobile() ? <NavBarPartnerMobile /> : null}
      </div>
    </div>
  );
};

export default NavBarPartner;
