import React, { forwardRef, useState } from "react";
import { Input as InputSemantic, Popup, TextArea } from "semantic-ui-react";
import classnames from "classnames";
import classNames from "classnames";

type Props = {
  label?: string;
  labelGuide?: string;
  id?: string;
  placeholder?: string;
  touched?: string | boolean;
  error?: string;
  labelRequired?: boolean;
  containerClass?: string;
  name?: string;
  [x: string]: any;
};

// eslint-disable-next-line react/display-name
const TextAreaCustom = forwardRef(
  (
    {
      label,
      labelGuide,
      id,
      placeholder,
      touched,
      error,
      labelRequired = false,
      containerClass,
      name,
      ...props
    }: Props,
    ref
  ) => {
    return (
      <div className={classNames("input-component", containerClass)}>
        {label && (
          <div className={"label"}>
            <label htmlFor={id} className={labelRequired ? "required" : ""}>
              {label}
            </label>
            {labelGuide && (
              <Popup
                className={"input-label-text-guide"}
                content={labelGuide}
                trigger={<i className="uil uil-info-circle input-label-icon" />}
                position="top center"
              />
            )}
          </div>
        )}
        <TextArea
          name={name}
          id={id}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={ref}
          className={"text-area"}
          {...props}
          placeholder={placeholder}
        />
        {touched && error ? <span className={"error"}>{error}</span> : null}
      </div>
    );
  }
);

export default TextAreaCustom;
