import { LocalizationContext } from "../../../../../locales/Translation";
import ButtonCustom from "../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import { toastError, toastSuccess } from "../../../../../components/Toast";
import DropdownCustom from "../../../../../components/DropdownCustom";
import { SWAL_DELETE_OPTION } from "../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../components/SweetAlert";
import { useDispatch } from "react-redux";
import React, { useContext, useState } from "react";
import { DESKIMO_PROPERTY_SCANNER_EDIT_LINK } from "../../../../App/configs/ListRoutes";
import { useNavigate } from "react-router-dom";
import { changeStatusPropertyScannerService } from "../../../services/PropertyScannerServices";
import { changeStatusPropertyScannerAction } from "../../../actions/PropertyActions";

const Action = ({ scannerStatus, scannerToken }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { translations } = useContext(LocalizationContext);
  // const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);

  const isUnpublish = () => {
    return parseInt(scannerStatus) === 0;
  };

  const _onUnpublishScanner = async () => {
    try {
      const icon = null;
      const title = isUnpublish()
        ? translations.propertyScanners.scannerPublishMsg
        : translations.propertyScanners.scannerUnpublishMsg;
      const confirmText = isUnpublish()
        ? translations.propertyPage.publish
        : translations.propertyPage.unPublish;
      const confirmClass = "primary";
      const options = SWAL_DELETE_OPTION({
        title,
        icon,
        confirmText,
        confirmClass,
      });
      SweetAlertCommon({
        options,
        confirmCallback: async () => {
          setDisabled(true);
          try {
            await changeStatusPropertyScannerService(scannerToken);
            dispatch(changeStatusPropertyScannerAction(scannerToken));
            isUnpublish()
              ? toastSuccess(
                  translations.propertyScanners.scannerPublishSuccessPrompt
                )
              : toastSuccess(
                  translations.propertyScanners.scannerUnpublishSuccessPrompt
                );
          } catch (e) {
            isUnpublish()
              ? toastError(
                  translations.propertyScanners.scannerPublishFailPrompt
                )
              : toastError(
                  translations.propertyScanners.scannerUnpublishFailPrompt
                );

            console.error(e);
          } finally {
            setDisabled(false);
          }
        },
        cancelCallback: () => null,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const _onEditScanner = () => {
    try {
      navigate(
        DESKIMO_PROPERTY_SCANNER_EDIT_LINK.replaceAll(":token", scannerToken)
      );
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <DropdownCustom
      disabled={disabled}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={_onEditScanner}
          text={translations.propertyScanners.editScanner}
          icon={
            <i className="uil uil-edit icon" style={{ fontSize: "16px" }} />
          }
        />
        <Dropdown.Item
          onClick={_onUnpublishScanner}
          text={
            isUnpublish()
              ? translations.propertyPage.publish
              : translations.propertyPage.unPublish
          }
          icon={
            isUnpublish() ? (
              <i className="uil uil-toggle-on icon" />
            ) : (
              <i className="uil uil-toggle-off icon" />
            )
          }
        />
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default Action;
