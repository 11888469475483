import {
  IS_AUTH_ACTION,
  PERMISSION_ACTION,
  REFRESH_SESSION_ACTION,
} from "../configs/Constants";

export const isAuthAction = (data: boolean) => {
  return {
    type: IS_AUTH_ACTION,
    payload: data,
  };
};

export const permissionAction = (data: string[]) => {
  return {
    type: PERMISSION_ACTION,
    payload: data,
  };
};

export const refreshSessionAction = () => {
  return {
    type: REFRESH_SESSION_ACTION,
  };
};
