import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { toastError } from "../../../../../../components/Toast";
import ModalCenterTemplate from "../../../../../App/views/ModalCenterTemplate";
import { Formik } from "formik";
import * as yup from "yup";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import InputCustom from "../../../../../../components/Input";
import Label from "../../../../../../components/Label";
import SizeBox from "../../../../../../components/SizeBox";
import {
  propertyPromotionDetailService,
  propertyPromotionSetupService,
} from "../../../../services/DashboardServices";
import { useSelector } from "react-redux";
import { dashboardPropertyChooseSelector } from "../../../../selectors/DashboardSelectors";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import { SWAL_DELETE_OPTION } from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import DateRangePickerV2 from "../../../../../../components/DateRangePickerV2";
import moment from "moment-timezone";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  promotionApply: {
    id: number;
    value: number;
    start_time: number;
    end_time: number;
    timezone: string;
    rate_per_hour: string;
  } | null;
  setPromotionApply: Dispatch<
    SetStateAction<{
      id: number;
      value: number;
      start_time: number;
      end_time: number;
      timezone: string;
      rate_per_hour: string;
    } | null>
  >;
};

const ModalAddPromotion = ({
  open,
  setOpen,
  promotionApply,
  setPromotionApply,
}: Props) => {
  const { translations } = useContext(LocalizationContext);
  const dashboardPropertyChoose = useSelector(dashboardPropertyChooseSelector);

  const [loading, setLoading] = useState(false);

  const _handleSave = async (values: any) => {
    try {
      setLoading(true);
      const value = Number(values.value);
      const propertyId = dashboardPropertyChoose?.property_id;
      const payload = {
        property_id: propertyId,
        ...values,
        value,
      };
      await propertyPromotionSetupService(payload);
      const { data } = await propertyPromotionDetailService({
        property_id: propertyId,
      });

      const iconHtml = (
        <img
          src={PUBLIC_URL + "/assets/icons/icon-check-green.svg"}
          className={"swal2-icon__error"}
        />
      );
      const options = SWAL_DELETE_OPTION({
        title: value ? "Discount applied" : "Discount removed",
        text: value
          ? `The ${value}% discount on the standard rate has been applied. New hourly hot desk rate: ${data?.rate_per_hour}`
          : "",
        icon: null,
        iconHtml,
        confirmText: "Done",
        confirmClass: "primary",
        cancelClass: "d-none",
      });
      SweetAlertCommon({
        options,
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setPromotionApply(data);
      setOpen(false);
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  if (!promotionApply) {
    return null;
  }

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={() => setOpen(false)}
      modalClassName={"modal-general"}
      open={open}
    >
      <Formik
        enableReinitialize
        initialValues={{
          value: promotionApply?.value || 0,
          start_time:
            promotionApply?.start_time ||
            moment().tz(promotionApply?.timezone).startOf("day").unix(),
          end_time:
            promotionApply?.end_time ||
            moment().tz(promotionApply?.timezone).endOf("day").unix(),
        }}
        validationSchema={yup.object().shape({
          value: yup.number().max(100).min(0).nullable().label("Discount"),
          start_time: yup
            .string()
            .nullable()
            .required()
            .label("Start time / End time"),
          end_time: yup
            .string()
            .nullable()
            .required()
            .label("Start time / End time"),
        })}
        onSubmit={_handleSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            <Modal.Header>
              <span>{translations.hotDeskPromotion}</span>
              <ButtonCustom
                onClick={() => setOpen(false)}
                className={"modal-close-cta"}
              >
                <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
              </ButtonCustom>
            </Modal.Header>
            <Modal.Content>
              <form className={"form-layout"}>
                <Label
                  fontSize={"14px"}
                  className={"fw-500"}
                  label={translations.standardHotDeskRatePerHour}
                />
                <SizeBox height={"14px"} />
                <Label
                  fontSize={"14px"}
                  label={dashboardPropertyChoose?.rate_per_hour}
                />
                <SizeBox height={"38px"} />
                <InputCustom
                  label={translations.settings.discount}
                  value={values?.value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.value}
                  error={errors.value}
                  name={"value"}
                  inputLeftIcon={"%"}
                  inputLeftIconBorder
                />

                <Label label={"Start Time / End Time"} required />
                <DateRangePickerV2
                  className={"w-100"}
                  format={"DD/MM/YYYY"}
                  defaultValue={
                    !values.start_time || !values.end_time
                      ? []
                      : [
                          moment
                            .unix(values.start_time)
                            .tz(promotionApply?.timezone),
                          moment
                            .unix(values.end_time)
                            .tz(promotionApply?.timezone),
                        ]
                  }
                  onChange={(values: Date[]) => {
                    if (!values) {
                      setFieldValue("start_time", null);
                      setFieldValue("end_time", null);
                      return;
                    }

                    setFieldValue(
                      "start_time",
                      moment(values[0])
                        .tz(promotionApply?.timezone)
                        .startOf("day")
                        .unix()
                    );
                    setFieldValue(
                      "end_time",
                      moment(values[1])
                        .tz(promotionApply?.timezone)
                        .endOf("day")
                        .unix()
                    );
                  }}
                />
                {(touched.start_time && errors.start_time) ||
                (touched.end_time && errors.end_time) ? (
                  <span className={"error"}>
                    {errors.start_time || errors.end_time}
                  </span>
                ) : null}
              </form>
            </Modal.Content>
            <Modal.Actions className={"border-0"}>
              <ButtonCustom
                loading={loading}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {translations.save}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalCenterTemplate>
  );
};

export default ModalAddPromotion;
