import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../../../locales/Translation";

type Props = {
  pagination: any;
};
const TotalDisplay = ({ pagination }: Props) => {
  const { translations } = useContext(LocalizationContext);

  if (!pagination) {
    return <div />;
  }

  const firstCurrentItemCount =
    pagination?.page * pagination?.limit - pagination?.limit + 1;
  const lastCurrentItemCount = pagination?.page * pagination?.limit;

  return (
    <div>
      {firstCurrentItemCount} -{" "}
      {lastCurrentItemCount > pagination?.total
        ? pagination?.total
        : lastCurrentItemCount}{" "}
      {translations.of} {pagination?.total}
    </div>
  );
};

export default TotalDisplay;
