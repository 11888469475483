import { createReducer } from "@reduxjs/toolkit";
import {
  COMPANY_CUSTOM_PACKAGES_ACTION,
  COMPANY_DETAIL_ACTION,
  COMPANY_FILTER_ACTION,
  COMPANY_INVOICE_ACTION,
  COMPANY_INVOICE_FILTER_ACTION,
  COMPANY_LIST_ACTION,
  COMPANY_LIST_CAN_BUY_CREDIT_ACTION,
  COMPANY_LIST_DELETE_ACTION,
  COMPANY_LIST_PAY_POST_ACTION,
  COMPANY_PENDING_INVITATION_ACTION,
  COMPANY_STAFF_ACTION,
  COMPANY_STAFF_DEACTIVATE_ACTION,
  COMPANY_STAFF_DELETE_ACTION,
  COMPANY_STAFF_EDIT_ACTION,
  COMPANY_STAFF_FILTER_ACTION,
  COMPANY_STAFF_MAKE_ADMIN_ACTION,
  INVOICE_DRAFT_STATUS,
  INVOICE_PAID_STATUS,
  INVOICE_PENDING_PAYMENT_STATUS,
  MODAL_COMPANY_CUSTOM_PACKAGE_ACTION,
  MODAL_COMPANY_STAFF_EDIT_ACTION,
  MODAL_UPDATE_AUTO_TOPUP_ACTION,
  PREPAID_CREDIT_FILTERS_ACTION,
  PURCHASE_HISTORY_FILTERS_ACTION,
} from "../configs/Constants";
import { CREDIT_PAID_STATUS } from "../views/PrepaidCredit/constants";
import { ORDER_BY_DESC } from "../../App/configs/Constants";
import { FILTER_DATE_ALL_TIME } from "../../Dashboard/configs/Constants";

const initialState = {
  businessCompanyFilter: {
    keyword: "",
    sort_by: "",
    order_by: "",
    page: 1,
    market_code: [],
  },
  companyList: null,
  companyDetail: null,
  companyStaff: null,
  companyStaffFilter: {
    keyword: "",
    sort_by: "",
    order_by: "",
    page: 1,
    market_code: "",
    company_id: 0,
    statuses: [0, 1, 2],
  },
  companyPendingInvitation: null,
  companyStaffEditData: null,
  companyInvoice: null,
  companyInvoiceFilter: {
    company_id: 0,
    keyword: "",
    sort_by: "",
    order_by: "",
    page: 1,
    market_code: [],
    statuses: [
      INVOICE_DRAFT_STATUS,
      INVOICE_PENDING_PAYMENT_STATUS,
      INVOICE_PAID_STATUS,
    ],
    from_time: null,
    to_time: null,
    dateType: FILTER_DATE_ALL_TIME,
  },
  isModalCompanyStaffEdit: false,
  isModalBusinessCompanyCustomPackages: false,
  companyCustomPackage: null,
  modalUpdateAutoTopUp: {
    open: false,
    data: null,
  },
  purchaseHistoryFilters: {
    page: 1,
    keyword: "",
    statuses: CREDIT_PAID_STATUS,
    market_codes: [],
    company_id: 0,
    sort_by: "purchase_history_by_purchase_date",
    sort_type: ORDER_BY_DESC,
  },
  prepaidCreditFilters: {
    page: 1,
    keyword: "",
    statuses: [0, 1, 2, 3],
    market_codes: [],
    company_id: 0,
    sort_by: "purchase_history_by_price",
    sort_type: ORDER_BY_DESC,
  },
};
const BusinessReducer = createReducer(initialState, {
  [COMPANY_FILTER_ACTION]: (state, action) => {
    state.businessCompanyFilter = {
      ...state.businessCompanyFilter,
      ...action.payload,
    };
  },
  [COMPANY_LIST_ACTION]: (state, action) => {
    state.companyList = action.payload;
  },
  [COMPANY_LIST_PAY_POST_ACTION]: (state, action) => {
    if (!state.companyList || !action.payload) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.companyList.items = state.companyList.items.map((item) => {
      if (item?.company_id !== action.payload) {
        return item;
      }

      return { ...item, post_pay_verified: item.post_pay_verified ? 0 : 1 };
    });
  },

  [COMPANY_LIST_CAN_BUY_CREDIT_ACTION]: (state, action) => {
    if (!state.companyList || !action.payload) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.companyList.items = state.companyList.items.map((item) => {
      if (item?.company_id !== action.payload) {
        return item;
      }

      return { ...item, can_buy_credit: !item.can_buy_credit };
    });
  },

  [COMPANY_LIST_DELETE_ACTION]: (state, action) => {
    if (!state.companyList || !action.payload) {
      return;
    }

    const companyListTemp = {
      items: [],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      pagination: state.companyList.pagination,
    };

    companyListTemp.pagination.limit = companyListTemp.pagination.limit - 1;
    companyListTemp.pagination.total = companyListTemp.pagination.total - 1;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    companyListTemp.items = state.companyList.items.filter(
      ({ company_id }: { company_id: number }) => company_id !== action.payload
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.companyList = companyListTemp;
  },

  [COMPANY_DETAIL_ACTION]: (state, action) => {
    state.companyDetail = action.payload;
  },

  [COMPANY_STAFF_ACTION]: (state, action) => {
    state.companyStaff = action.payload;
  },

  [COMPANY_STAFF_FILTER_ACTION]: (state, action) => {
    state.companyStaffFilter = {
      ...state.companyStaffFilter,
      ...action.payload,
    };
  },

  [COMPANY_PENDING_INVITATION_ACTION]: (state, action) => {
    state.companyPendingInvitation = action.payload;
  },

  [MODAL_COMPANY_STAFF_EDIT_ACTION]: (state, action) => {
    state.isModalCompanyStaffEdit = action.payload;
  },

  [COMPANY_STAFF_EDIT_ACTION]: (state, action) => {
    state.companyStaffEditData = action.payload;
  },

  [COMPANY_STAFF_DELETE_ACTION]: (state, action) => {
    if (!state.companyStaff || !action.payload) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.companyStaff.data = state.companyStaff.data.filter(
      (staff: any) => staff.id !== action.payload
    );
  },

  [COMPANY_STAFF_MAKE_ADMIN_ACTION]: (state, action) => {
    if (!state.companyStaff) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.companyStaff.data = state.companyStaff.data.map((staff: any) => {
      if (staff?.id !== action.payload) {
        return staff;
      }
      return { ...staff, is_admin: true };
    });
  },

  [COMPANY_STAFF_DEACTIVATE_ACTION]: (state, action) => {
    if (!state.companyStaff) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.companyStaff.data = state.companyStaff.data.map((staff: any) => {
      if (staff?.id !== action.payload) {
        return staff;
      }
      return {
        ...staff,
        status: staff.status === 1 ? 2 : 1,
        status_name:
          staff.status_name.toLowerCase() === "suspended"
            ? "Accepted"
            : "Suspended",
      };
    });
  },

  [COMPANY_INVOICE_ACTION]: (state, action) => {
    state.companyInvoice = action.payload;
  },

  [COMPANY_INVOICE_FILTER_ACTION]: (state, action) => {
    state.companyInvoiceFilter = {
      ...state.companyInvoiceFilter,
      ...action.payload,
    };
  },

  [COMPANY_CUSTOM_PACKAGES_ACTION]: (state, action) => {
    state.companyCustomPackage = action.payload;
  },

  [MODAL_COMPANY_CUSTOM_PACKAGE_ACTION]: (state, action) => {
    state.isModalBusinessCompanyCustomPackages = action.payload;
  },

  [MODAL_UPDATE_AUTO_TOPUP_ACTION]: (state, action) => {
    state.modalUpdateAutoTopUp = action.payload;
  },

  [PURCHASE_HISTORY_FILTERS_ACTION]: (state, action) => {
    state.purchaseHistoryFilters = {
      ...state.purchaseHistoryFilters,
      ...action.payload,
    };
  },

  [PREPAID_CREDIT_FILTERS_ACTION]: (state, action) => {
    state.prepaidCreditFilters = {
      ...state.prepaidCreditFilters,
      ...action.payload,
    };
  },
});

export default BusinessReducer;
