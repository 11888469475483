import React, { SyntheticEvent, useContext, useState, useEffect } from "react";
import TableCustom from "../../../../../components/Table";
import { LocalizationContext } from "../../../../../locales/Translation";
import { businessCompanyInvoiceService } from "../../../services/BusinessServices";
import { BusinessCompanyDetailType } from "../../../configs/DeclareTypes";
import { useSelector } from "react-redux";
import { businessCompanyDetailSelector } from "../../../selectors/BusinessSelectors";
import moment from "moment/moment";
import { getValidTimeStamp } from "../../../../../utils/commons";
import { FORMAT_DATE_VARIANT_1 } from "../../../../App/configs/DateTimeConfigs";
import CircleStatus from "../../../../../components/CircleStatus";
import ButtonCustom from "../../../../../components/Button";
import { Link } from "react-router-dom";
import { INVOICE_DETAIL_DESKIMO_ADMIN_LINK } from "../../../../App/configs/ListRoutes";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../../App/configs/Constants";

const Revenue = () => {
  const { translations } = useContext(LocalizationContext);
  const businessCompanyDetail: BusinessCompanyDetailType = useSelector(
    businessCompanyDetailSelector
  );
  const [loadingTable, setLoadingTable] = useState(false);
  const [invoiceListData, setInvoiceListData] = useState<any>(null);
  const [tableFilter, setTableFilter] = useState({
    statuses: [],
    page: 1,
    order_by: "",
    sort_by: "",
    keyword: "",
  });

  useEffect(() => {
    if (businessCompanyDetail?.company_id) {
      fetchBusinessInvoices();
    }
  }, [businessCompanyDetail, tableFilter]);

  const fetchBusinessInvoices = async () => {
    setLoadingTable(true);
    try {
      const payload = {
        ...tableFilter,
        company_id: businessCompanyDetail?.company_id,
      };
      const response = await businessCompanyInvoiceService(payload);
      setInvoiceListData(response);
      console.log("response: ", response);
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setLoadingTable(false);
    }
  };

  const getInvoiceColor = (status: number) => {
    switch (status) {
      case 0:
        return "orange";
      case 1:
        return "yellow";
      case 2:
        return "green";
    }
  };

  const decorateData = (data: []) => {
    if (!data || data.length < 1) return [];
    return data.map((field: any) => {
      return {
        col1: <p className={"w-100"}>{field?.name}</p>,
        col2: (
          <p className={"text-nowrap"}>
            {moment(getValidTimeStamp(field?.due_date))
              .utc()
              .format(FORMAT_DATE_VARIANT_1)}
          </p>
        ),
        col3: <b className={"text-nowrap"}>{field?.total}</b>,
        col4: (
          <div className={"d-flex align-items-center"}>
            <CircleStatus color={getInvoiceColor(field?.status)} />
            {field?.status_name}
          </div>
        ),
        col5: (
          <ButtonCustom height={"48px"} className={"link p-0 invoice-link"}>
            <Link
              target="_blank"
              className={"text-nowrap"}
              to={INVOICE_DETAIL_DESKIMO_ADMIN_LINK.replaceAll(
                ":token",
                field?.token
              ).replaceAll(
                ":companyId",
                String(businessCompanyDetail?.company_id)
              )}
            >
              {translations.viewInvoice}
            </Link>
          </ButtonCustom>
        ),
      };
    });
  };

  const tableData = decorateData(invoiceListData?.data.items);

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    setTableFilter((prevState) => {
      return { ...prevState, page: activePage };
    });
  };

  const getNextOrderBy = () => {
    switch (tableFilter.order_by) {
      case ORDER_BY_ASC:
        return ORDER_BY_DESC;
      case ORDER_BY_DESC:
        return ORDER_BY_NONE;
      case ORDER_BY_NONE:
        return ORDER_BY_ASC;
    }
  };

  const _onChangeSortCol = (col: any) => {
    if (tableFilter?.sort_by !== col?.key) {
      setTableFilter((prevState) => {
        return { ...prevState, sort_by: col?.key, order_by: ORDER_BY_ASC };
      });
    } else {
      setTableFilter((prevState: any) => {
        return { ...prevState, sort_by: col?.key, order_by: getNextOrderBy() };
      });
    }
  };

  const columns = [
    { key: "", col: translations.invoice },
    { key: "date", col: translations.date },
    { key: "amount", col: translations.amount },
    { key: "status", col: translations.status },
    { key: "", col: translations.details },
  ];

  return (
    <>
      <div className={"company-detail__title"}>
        <div className="section-white-box_title">Revenue</div>
      </div>
      <TableCustom
        orderBy={tableFilter?.order_by}
        sortBy={tableFilter?.sort_by}
        onChangeSortCol={_onChangeSortCol}
        tableClass={"bordered table-detail-page"}
        sortTable
        loading={loadingTable}
        onChangePagination={_onChangePagination}
        showTotal={false}
        tableData={tableData}
        columns={columns}
        currentPage={invoiceListData?.data?.pagination?.page}
        totalItems={invoiceListData?.data?.pagination?.total}
        unit={translations.invoices}
      />
      <div className={"section-white-box_line"} />
    </>
  );
};

export default Revenue;
