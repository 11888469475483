import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../locales/Translation";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import DropdownWithCheckboxMenuWithTotal from "../../../../../components/DropdownWithCheckboxMenuWithTotal";

const FilterProperty = ({
  dropdownPropertyFilter,
  onChangeFilter,
  values,
}: any) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <div className={"dropdown-filter"}>
      <DropdownWithCheckboxMenuWithTotal
        values={values}
        onChange={onChangeFilter}
        listCheckBox={dropdownPropertyFilter}
        label={translations.filters}
        icon={
          <img
            alt={"Deskimo"}
            src={PUBLIC_URL + "/assets/icons/icon-filter.svg"}
          />
        }
      />
    </div>
  );
};

export default FilterProperty;
