import React, { useContext } from "react";
import ButtonCustom from "../../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import { deskimoModalPrivateOfficeAction } from "../../../../actions/PropertyActions";
import { SWAL_DELETE_OPTION } from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import {
  privateOfficeDeleteService,
  privateOfficeStatusService,
  privateOfficeVisibilityService,
} from "../../../../services/PropertyServices";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { EDIT_TYPE } from "../../../../configs/Constants";
import { useParams } from "react-router-dom";
import { MyProfileType } from "../../../../../User/configs/DeclareTypes";
import { myProfileSelector } from "../../../../../App/selectors/AppSelectors";
import { ROLE_DESKIMO_ADMIN_ID } from "../../../../../User/configs/Constants";

type Props = {
  id: string;
  status: number;
  onRefresh: () => void;
  companyId?: string;
  propertyId?: string;
  visibility?: boolean;
  visibilityAction?: string;
};

const Action = ({
  id,
  status,
  companyId,
  propertyId,
  visibility,
  visibilityAction,
  onRefresh,
}: Props) => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const myProfile: MyProfileType = useSelector(myProfileSelector);

  const _handlePublish = (id: any, isPublish: number) => {
    const icon = null;
    const title = isPublish
      ? "Are you sure you want to publish this private office?"
      : "Are you sure you want to un-publish this private office?";
    const confirmText =
      isPublish > 0
        ? translations.propertyPage.publish
        : translations.propertyPage.unPublish;
    const confirmClass = "primary";
    const options = SWAL_DELETE_OPTION({
      title,
      icon,
      confirmText,
      confirmClass,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          const payload = {
            private_office_id: id,
            status: isPublish,
            company_id: companyId,
          };
          await privateOfficeStatusService(payload);
          toastSuccess(
            isPublish ? "Published Successfully!" : "Un-published Successfully!"
          );
          onRefresh();
        } catch (error: any) {
          if (error?.status?.message) {
            toastError(error?.status?.message);
          }
        }
      },
      cancelCallback: () => null,
    });
  };

  const _handleDelete = (id: any) => {
    const icon = null;
    const iconHtml = (
      <img
        src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
        className={"swal2-icon__error"}
      />
    );
    const title = "Are you sure want to delete this private office?";
    const confirmText = translations.delete;
    const confirmClass = "danger";
    const options = SWAL_DELETE_OPTION({
      title,
      iconHtml,
      icon,
      confirmText,
      confirmClass,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          await privateOfficeDeleteService(id);
          toastSuccess("Deleted Successfully!");
          onRefresh();
        } catch (error: any) {
          if (error?.status?.message) {
            toastError(error?.status?.message);
          }
        }
      },
      cancelCallback: () => null,
    });
  };

  const _handleShowHidePrivateOffice = (visibility?: boolean) => {
    const icon = null;
    const title = visibility
      ? "Are you sure you want to hide this private office?"
      : "Are you sure you want to show this private office?";
    const confirmText = visibility ? "Hide" : "Show";
    const confirmClass = "primary";
    const options = SWAL_DELETE_OPTION({
      title,
      icon,
      confirmText,
      confirmClass,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          const payload = {
            visibility: visibility ? 0 : 1,
          };
          await privateOfficeVisibilityService(id, payload);
          toastSuccess(
            visibility
              ? "Action Hide Private Office Successfully!"
              : "Action Show Private Office Successfully!"
          );
          onRefresh();
        } catch (error: any) {
          if (error?.status?.message) {
            toastError(error?.status?.message);
          }
        }
      },
      cancelCallback: () => null,
    });
  };

  return (
    <DropdownCustom
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => {
            dispatch(
              deskimoModalPrivateOfficeAction({
                isOpen: true,
                type: EDIT_TYPE,
                privateOfficeId: id,
                companyId: companyId,
                propertyId: propertyId,
              })
            );
          }}
          text={"Edit Private Office"}
          icon={
            <i className="uil uil-edit icon" style={{ fontSize: "16px" }} />
          }
        />
        {status > 0 ? (
          <Dropdown.Item
            onClick={() => _handlePublish(id, 0)}
            text={"Un-publish"}
            icon={
              <img src={PUBLIC_URL + "/assets/icons/icon-toggle-slider.svg"} />
            }
          />
        ) : (
          <Dropdown.Item
            onClick={() => _handlePublish(id, 1)}
            text={"Publish"}
            icon={
              <img src={PUBLIC_URL + "/assets/icons/icon-toggle-slider.svg"} />
            }
          />
        )}

        {myProfile?.role_id === ROLE_DESKIMO_ADMIN_ID && status ? (
          <Dropdown.Item
            onClick={() => _handleShowHidePrivateOffice(visibility)}
            text={visibilityAction}
            icon={
              visibility ? (
                <i
                  className="uil uil-eye-slash icon"
                  style={{ fontSize: "16px" }}
                ></i>
              ) : (
                <i className="uil uil-eye icon" style={{ fontSize: "16px" }} />
              )
            }
          />
        ) : null}

        <Dropdown.Item
          onClick={() => _handleDelete(id)}
          text={"Delete Office"}
          icon={
            <i
              className="uil uil-trash-alt icon"
              style={{ fontSize: "16px" }}
            />
          }
        />
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default Action;
