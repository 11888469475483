import React, { useContext, useEffect, useState } from "react";
import OnboardTemplate from "../../../App/views/OnboardTemplate";
import "./styles.scss";
import CreateNewPassword from "./Components/CreateNewPassword";
import CreatePhoneNumber from "./Components/CreatePhoneNumber";
import VerifyOTP from "./Components/VerifyOTP";
import { useNavigate, useParams } from "react-router-dom";
import {
  entitlementSetPasswordService,
  entitlementValidatePhoneService,
  getOTPService,
  handleOTPCaptchaService,
  verifyTokenValidService,
} from "../../services/OnboardServices";
import { Dimmer, Loader } from "semantic-ui-react";
import { LOGIN_LINK } from "../../../App/configs/ListRoutes";
import {
  loginGeneralService,
  loginService,
} from "../../../Authentication/services/AuthenticationServices";
import { LocalizationContext } from "../../../../locales/Translation";
import { toastError } from "../../../../components/Toast";
import { GET_OTP_SMS } from "../../configs/Constants";
import { UserDataType } from "../../configs/DeclareType";
import { ACCESS_TOKEN_CACHE } from "../../../../configs/Constants";
import { isAuthAction } from "../../../Authentication/actions/AuthenticationActions";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { isAuthSelector } from "../../../Authentication/selectors/AuthenticationSelectors";
import SizeBox from "../../../../components/SizeBox";

const STEP_NEW_PASSWORD = 1;
const STEP_INPUT_PHONE = 2;
const STEP_VERIFY_OTP = 3;

const BusinessUserOnboard = () => {
  const { token } = useParams();

  const { translations } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAuth = useSelector(isAuthSelector);

  const [stepIndex, setStepIndex] = useState(STEP_NEW_PASSWORD);
  const [userData, setUserData] = useState<UserDataType>({});

  const [loading, setLoading] = useState(true);
  const [showCaptcha, setShowCaptcha] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (isAuth) {
          navigate("/", { replace: true });
          return;
        }

        if (!token) {
          navigate(LOGIN_LINK);
          return;
        }
        const { data } = await verifyTokenValidService({ token });

        if (!data) {
          navigate(LOGIN_LINK);
          return;
        }

        setUserData(data);
      } catch (e) {
        navigate(LOGIN_LINK);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const _handleSetPassword = async (values: any) => {
    const payload = {
      ...values,
      token,
    };
    const { data } = await entitlementSetPasswordService(payload);
    return data;
  };

  const _handleSetNewPassword = async (data: {
    password: string;
    repeat_password: string;
  }) => {
    try {
      if (!userData?.email) {
        return toastError("No data valid");
      }

      if (!userData?.has_phone_number) {
        setUserData((prevState) => ({ ...prevState, ...data }));
        setStepIndex(STEP_INPUT_PHONE);
        return;
      }

      const payload = {
        ...data,
        token,
      };
      await _handleSetPassword(payload);

      const payloadLogin = {
        email: userData?.email,
        password: data.password,
      };
      await loginGeneralService(payloadLogin, translations);
      navigate("/", { replace: true });
    } catch (e) {
      console.log(e);
    }
  };

  const _handleAddPhone = async (data: {
    dialing_code: string;
    phone: string;
  }) => {
    try {
      if (!data?.phone || !data?.dialing_code) {
        return toastError("No data valid");
      }

      setUserData((prevState: any) => ({ ...prevState, ...data }));
      const payload = {
        token,
        phone: data.phone,
        dialing_code: data.dialing_code,
      };
      await entitlementValidatePhoneService(payload);

      await _resendOTP({ phone: data.phone, dialing_code: data.dialing_code });
      setStepIndex(STEP_VERIFY_OTP);
    } catch (e: any) {
      if (!e?.status?.code) {
        return;
      }
      if (e?.status?.code === 1008) {
        toastError(translations.buOnboard.thePhoneNumberAlreadyExisted);
      } else {
        toastError(e?.status?.message);
      }
    }
  };

  const _resendOTP = async ({
    type = GET_OTP_SMS,
    phone,
    dialing_code,
    captcha_token,
  }: {
    type?: number;
    phone?: string;
    dialing_code?: string;
    captcha_token?: string;
  }) => {
    try {
      const payload = {
        phone_number: phone || userData.phone,
        country_code: dialing_code || userData.dialing_code,
        type,
        resend: 1,
      };

      if (captcha_token) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        payload.captcha_token = captcha_token;
      }

      await getOTPService(payload);
    } catch (e) {
      handleOTPCaptchaService(e, setShowCaptcha);
    }
  };

  const _loginWithOTP = async (otp: number) => {
    try {
      if (!userData?.phone || !userData?.dialing_code) {
        return toastError("No data valid");
      }

      const payload = {
        password: userData.password,
        repeat_password: userData.repeat_password,
        phone: userData.phone,
        dialing_code: userData.dialing_code,
        otp,
      };
      const { login_token } = await _handleSetPassword(payload);

      const { access_token } = await loginService({ token: login_token });

      localStorage.setItem(ACCESS_TOKEN_CACHE, access_token);
      dispatch(isAuthAction(true));
    } catch (e: any) {
      console.log("e", e);
      if (e?.status?.code === 1005) {
        toastError(translations.errorMessage.invalidOtp);
      } else {
        toastError(translations.errorMessage.contactAdmin);
      }
    }
  };

  const _onChangeCaptcha = async (value: string | null) => {
    try {
      if (!value) {
        return;
      }

      await _resendOTP({ captcha_token: value });
      setShowCaptcha(false);

      if (stepIndex === STEP_INPUT_PHONE) {
        setStepIndex(STEP_VERIFY_OTP);
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (loading) {
    return (
      <Dimmer active>
        <Loader />
      </Dimmer>
    );
  }

  return (
    <OnboardTemplate>
      {stepIndex === STEP_NEW_PASSWORD ? (
        <CreateNewPassword handleSetNewPassword={_handleSetNewPassword} />
      ) : stepIndex === STEP_INPUT_PHONE ? (
        <CreatePhoneNumber handleAddPhone={_handleAddPhone} />
      ) : (
        <VerifyOTP
          userData={userData}
          resendOTP={_resendOTP}
          loginWithOTP={_loginWithOTP}
        />
      )}
      {showCaptcha && process.env.REACT_APP_RECAPTCHA_KEY ? (
        <>
          <SizeBox height={"20px"} />
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={_onChangeCaptcha}
          />
        </>
      ) : (
        <div />
      )}
    </OnboardTemplate>
  );
};

export default BusinessUserOnboard;
