import React, { useEffect } from "react";
import BillingInfo from "../Setting/Components/BillingLeftSide/Components/BillingInfo";
import MasterTemplate from "../../../App/views/MasterTemplate";
import {
  ROLE_BUSINESS_ADMIN_ID,
  ROLE_PARTNER_ID,
} from "../../../User/configs/Constants";
import {
  getBillingContactThunk,
  getBillingInfoThunk,
} from "../../thunks/BillingThunk";
import { useDispatch, useSelector } from "react-redux";
import { userCompanyChooseSelector } from "../../../User/selectors/UserSelectors";
import {
  billingContactSelector,
  billingInfoSelector,
} from "../../selectors/BillingSelector";
import BillingContact from "../Setting/Components/BillingLeftSide/Components/BillingContact";
import SizeBox from "../../../../components/SizeBox";
import ModalBillingContact from "../Setting/Components/ModalBillingContact";
import ModalAddBillingContact from "../Setting/Components/ModalAddBillingContact";
import ModalBillingInformation from "../Setting/Components/ModalBillingInformation";
import {
  modalBillingContactAction,
  modalBillingInformationAction,
} from "../../actions/BiilingActions";
import BillingRightSide from "./Components/BillingRightSide";
import { UI_ACCESS_PARTNER_DASHBOARD } from "../../../../utils/PermissionConstant";

const PartnerInvoice = () => {
  const dispatch = useDispatch();
  const userCompanyChoose = useSelector(userCompanyChooseSelector);

  const billingInfo = useSelector(billingInfoSelector);
  const billingContact = useSelector(billingContactSelector);

  useEffect(() => {
    _getStaticContent();
  }, [userCompanyChoose]);

  const _getStaticContent = () => {
    dispatch<any>(getBillingInfoThunk());
    dispatch<any>(getBillingContactThunk());
  };

  const _openBillingInformation = () => {
    dispatch(modalBillingInformationAction(true));
  };

  const _openBillingContact = () => {
    dispatch(modalBillingContactAction(true));
  };

  return (
    <MasterTemplate
      roleAccept={[ROLE_BUSINESS_ADMIN_ID, ROLE_PARTNER_ID]}
      permission={UI_ACCESS_PARTNER_DASHBOARD}
    >
      <div className={"billing-tab"}>
        <div className={"billing-tab__left"}>
          <BillingInfo
            companyInfo={billingInfo}
            openBillingInformation={_openBillingInformation}
          />
          <BillingContact
            billingContact={billingContact}
            openBillingContact={_openBillingContact}
          />
        </div>
        <SizeBox width={"30px"} />
        <BillingRightSide />
      </div>
      <ModalBillingContact />
      <ModalAddBillingContact />
      <ModalBillingInformation />
    </MasterTemplate>
  );
};

export default PartnerInvoice;
