export const USER_COMPANY_ACTION = "USER_COMPANY_ACTION";
export const USER_COMPANY_CHOOSE_ACTION = "USER_COMPANY_CHOOSE_ACTION";
export const USER_COMPANY_CHOOSE_UPDATE_CREDIT_ACTION =
  "USER_COMPANY_CHOOSE_UPDATE_CREDIT_ACTION";

export const USERS_ACTION = "USERS_ACTION";
export const USERS_FILTERS_ACTION = "USERS_FILTERS_ACTION";
export const USERS_FILTERS_RESET_ACTION = "USERS_FILTERS_RESET_ACTION";

export const EMPLOYEES_ACTION = "EMPLOYEES_ACTION";
export const EMPLOYEES_FILTERS_ACTION = "EMPLOYEES_FILTERS_ACTION";
export const MODAL_EDIT_EMPLOYEE_ACTION = "MODAL_EDIT_EMPLOYEE_ACTION";
export const MODAL_DETAIL_EMPLOYEE_ACTION = "MODAL_DETAIL_EMPLOYEE_ACTION";
export const EMPLOYEE_DETAIL_ACTION = "EMPLOYEE_DETAIL_ACTION";
export const EMPLOYEE_GOOGLE_ACTION = "EMPLOYEE_GOOGLE_ACTION";
export const EMPLOYEE_GOOGLE_CHOOSE_ACTION = "EMPLOYEE_GOOGLE_CHOOSE_ACTION";
export const MODAL_IMPORT_EMPLOYEE_GOOGLE_ACTION =
  "MODAL_IMPORT_EMPLOYEE_GOOGLE_ACTION";
export const EMPLOYEE_EDIT_INLINE_ACTION = "EMPLOYEE_EDIT_INLINE_ACTION";
export const EMPLOYEE_REINVITE_ACTION = "EMPLOYEE_REINVITE_ACTION";
export const EMPLOYEE_DEACTIVE_ACTION = "EMPLOYEE_DEACTIVE_ACTION";
export const EMPLOYEE_DELETE_ACTION = "EMPLOYEE_DELETE_ACTION";
export const MODAL_CREATE_DESKIMO_ADMIN_ACTION =
  "MODAL_CREATE_DESKIMO_ADMIN_ACTION";

export const EMPLOYEE_PENDING_STATUS = 0;
export const EMPLOYEE_ACTIVE_STATUS = 1;
export const EMPLOYEE_DELETE_STATUS = 2;

export const ROLE_PROPERTY_STAFF_ID = 4;
export const ROLE_PROPERTY_ADMIN_ID = 5;
export const ROLE_BUSINESS_ADMIN_ID = 2;
export const ROLE_BUSINESS_USER_ID = 1;
export const ROLE_DESKIMO_ADMIN_ID = 7;
export const ROLE_PARTNER_ID = 11;
export const ROLE_WMS_USER_ID = 10;
export const USER_LIST_TYPE_EMPLOYEE = "EMPLOYEE";
export const USER_LIST_TYPE_PENDING_INVITATION =
  "USER_LIST_TYPE_PENDING_INVITATION";

export const IS_MODAL_ADD_TEAM_MEMBER_ACTION =
  "IS_MODAL_ADD_TEAM_MEMBER_ACTION";

export const TEAM_MEMBER_LIST_ACTION = "TEAM_MEMBER_LIST_ACTION";

export const TEAM_MEMBER_FILTER_ACTION = "TEAM_MEMBER_FILTER_ACTION";
export const MOVE_TO_BLACK_LIST = "MOVE_TO_BLACK_LIST";
export const MOVE_TO_WHITE_LIST = "MOVE_TO_WHITE_LIST";
