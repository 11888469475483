import React, { SyntheticEvent, useContext } from "react";
import TableCustom from "../../../../../../components/Table";
import MarketFilterMultiple from "../../../../../../components/FilterMarket/MarketFilterMultiple";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../../../App/configs/Constants";
import Action from "./Action";
import { LocalizationContext } from "../../../../../../locales/Translation";
import OfficeLeasesContext from "../../Context";
import {
  CONTRACT_DAILY_TYPE,
  CONTRACT_MONTHLY_TYPE,
  LEASE_CREATED_STATUS,
  LEASE_ONGOING_STATUS,
} from "../../../../configs/Constants";
import moment from "moment-timezone";
import { FORMAT_DATE_VARIANT_2 } from "../../../../../App/configs/DateTimeConfigs";
import { Popup } from "semantic-ui-react";

const Table = () => {
  const { translations } = useContext(LocalizationContext);
  const { tableData, loading, filters, setFilters }: any =
    useContext(OfficeLeasesContext);

  const _onChangeSortCol = (col: any) => {
    if (filters.sort_by !== col.key) {
      setFilters((prevState: any) => {
        return { ...prevState, sort_by: col.key, sort_type: ORDER_BY_ASC };
      });
    } else {
      if (filters.sort_type === ORDER_BY_NONE) {
        setFilters((prevState: any) => {
          return { ...prevState, sort_by: col.key, sort_type: ORDER_BY_ASC };
        });
      }
      if (filters.sort_type === ORDER_BY_DESC) {
        setFilters((prevState: any) => {
          return { ...prevState, sort_by: col.key, sort_type: ORDER_BY_NONE };
        });
      }
      if (filters.sort_type === ORDER_BY_ASC) {
        setFilters((prevState: any) => {
          return { ...prevState, sort_by: col.key, sort_type: ORDER_BY_DESC };
        });
      }
    }
  };

  const columns = [
    { key: "business_company_name", col: "Business Company" },
    { key: "property_name", col: "Property" },
    { key: "market_name", col: "Market" },
    {
      key: "start_date",
      col: "Start Date",
    },
    { key: "end_date", col: "End Date" },
    {
      key: "type",
      col: "Type of Contract",
    },
    {
      key: "amount",
      col: "Amount",
    },
    {
      key: "cogs",
      col: "COGS",
    },
    { key: "status", col: translations.status },
    { key: null, col: "Deposit" },
    { key: null, col: translations.action },
  ];

  const _formatInputData = () => {
    return tableData?.items?.map(
      ({
        id,
        company_id,
        company_name,
        property_id,
        property_name,
        start_date,
        is_no_end_date,
        pax_amount,
        end_date,
        contract_type,
        currency,
        price,
        cogs,
        status,
        note,
        is_pay_with_credit,
        usage_week_days,
        name,
        property_market_name,
        property_timezone,
        deposit,
      }: any) => {
        return {
          col1: <Popup content={name} trigger={<div>{company_name}</div>} />,
          col2: property_name,
          col3: property_market_name,
          col4: start_date
            ? moment
                .unix(start_date)
                .tz(property_timezone)
                .format(FORMAT_DATE_VARIANT_2)
            : "-",
          col5: end_date
            ? moment
                .unix(end_date)
                .tz(property_timezone)
                .format(FORMAT_DATE_VARIANT_2)
            : "-",
          col6:
            contract_type === CONTRACT_DAILY_TYPE
              ? "Daily"
              : contract_type === CONTRACT_MONTHLY_TYPE
              ? "Monthly"
              : "One time",
          col7: `${price} ${
            contract_type === CONTRACT_DAILY_TYPE
              ? "/ Day"
              : contract_type === CONTRACT_MONTHLY_TYPE
              ? "/ Month"
              : ""
          }`,
          col8: `${cogs} ${
            contract_type === CONTRACT_DAILY_TYPE
              ? "/ Day"
              : contract_type === CONTRACT_MONTHLY_TYPE
              ? "/ Month"
              : ""
          }`,
          col9:
            status === LEASE_CREATED_STATUS
              ? "Created"
              : status === LEASE_ONGOING_STATUS
              ? "Ongoing"
              : "Ended",
          col10: (
            <>
              <Popup
                disabled={!deposit?.business?.note}
                content={deposit?.business?.note}
                trigger={
                  <div>
                    Company Deposit:
                    <br />
                    {deposit?.business?.price || "-"}
                  </div>
                }
              />
              <br />
              <br />

              <Popup
                disabled={!deposit?.property?.note}
                content={deposit?.property?.note}
                trigger={
                  <div>
                    Property Deposit:
                    <br />
                    {deposit?.property?.price || "-"}
                  </div>
                }
              />
            </>
          ),
          col11: (
            <Action
              id={id}
              deposit={deposit}
              status={status}
              currency={currency}
            />
          ),
        };
      }
    );
  };

  const _onChangeMarketFilter = (values: string[]) => {
    setFilters((prevState: any) => {
      return { ...prevState, market_code: values, page: 1 };
    });
  };

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    setFilters((prevState: any) => {
      return { ...prevState, page: activePage };
    });
  };

  return (
    <div style={tableData?.items?.length < 3 ? { paddingBottom: 100 } : {}}>
      <TableCustom
        onChangeSortCol={_onChangeSortCol}
        sortTable
        loading={loading}
        showTotal
        tableData={tableData?.items ? _formatInputData() : []}
        columns={columns}
        orderBy={filters.sort_type}
        sortBy={filters.sort_by}
        itemPerPage={tableData?.pagination?.limit}
        currentPage={filters.page}
        totalItems={tableData?.pagination?.total}
        unit={"office leases"}
        onChangePagination={_onChangePagination}
        topRightComponent={
          <MarketFilterMultiple
            onChange={_onChangeMarketFilter}
            values={filters.market_code}
          />
        }
      />
    </div>
  );
};

export default Table;
