import { Link, Route, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../locales/Translation";
import {
  DESKIMO_PROPERTY_DETAIL_INTEGRATIONS_LINK,
  DESKIMO_PROPERTY_DETAIL_LINK,
  DESKIMO_PROPERTY_DETAIL_MEETING_ROOM_LINK,
  DESKIMO_PROPERTY_DETAIL_NOTES_LINK,
  DESKIMO_PROPERTY_DETAIL_PRIVATE_OFFICES_LINK,
  DESKIMO_PROPERTY_DETAIL_REWARD_LINK,
  DESKIMO_PROPERTY_DETAIL_USER_REVIEW_LINK,
  DESKIMO_PROPERTY_LINK,
} from "../../../App/configs/ListRoutes";
import DetailsTab from "../PropertyDetail/Components/DetailsTab";
import UserReviewTab from "../PropertyDetail/Components/UserReviewTab";
import MeetingRoomTab from "../PropertyDetail/Components/MeetingRoomTab";
import MasterTemplate from "../../../App/views/MasterTemplate";
import { ROLE_DESKIMO_ADMIN_ID } from "../../../User/configs/Constants";
import { Tab } from "semantic-ui-react";
import { CURRENT_COMPANY_ID } from "../../../../configs/Constants";
import ButtonGoBack from "../../../../components/ButtonGoBack";
import DeskimoNotes from "../PropertyDetail/Components/DeskimoNotes";
import IntegrationsTab from "../PropertyDetail/Components/IntegrationsTab";
import { propertyDetailSelector } from "../../selectors/PropertySelector";
import { getPropertyDetailThunk } from "../../thunks/PropertyThunks";
import PrivateOfficesTab from "../PropertyDetail/Components/PrivateOfficesTab";
import RewardsTab from "../PropertyDetail/Components/RewardsTab";
import { findTabIndexByName } from "../../../../utils/commons";

type Props = {
  tabName: string;
};
const DeskimoPropertyDetail = ({ tabName }: Props) => {
  const { companyId, id } = useParams();
  if (companyId) {
    localStorage.setItem(CURRENT_COMPANY_ID, companyId);
  }
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { translations } = useContext(LocalizationContext);
  const propertyDetail = useSelector(propertyDetailSelector);

  useEffect(() => {
    (async () => _getPropertyDetail())();
  }, []);

  const _getPropertyDetail = async () => {
    try {
      setLoading(true);
      await dispatch<any>(getPropertyDetailThunk(id ? id : ""));
      setLoading(false);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const panes = [
    {
      name: "BACK",
      menuItem: <ButtonGoBack route={DESKIMO_PROPERTY_LINK} className="mb-0" />,
    },
    {
      name: DESKIMO_PROPERTY_DETAIL_LINK,
      menuItem: {
        as: Link,
        id: "tab1",
        to: DESKIMO_PROPERTY_DETAIL_LINK.replaceAll(":id", id || "").replaceAll(
          ":companyId",
          companyId || ""
        ),
        content: translations.propertyPage.details,
        exact: true,
      },
      pane: <Route path={DESKIMO_PROPERTY_DETAIL_LINK} />,
      render: () => <DetailsTab loading={loading} setLoading={setLoading} />,
    },
    {
      name: DESKIMO_PROPERTY_DETAIL_USER_REVIEW_LINK,
      menuItem: {
        as: Link,
        id: "tab2",
        to: DESKIMO_PROPERTY_DETAIL_USER_REVIEW_LINK.replaceAll(
          ":id",
          id || ""
        ).replaceAll(":companyId", companyId || ""),
        content: translations.propertyPage.userReviews,
        exact: true,
      },
      pane: <Route path={DESKIMO_PROPERTY_DETAIL_USER_REVIEW_LINK} />,
      render: () => <UserReviewTab />,
    },
    {
      name: DESKIMO_PROPERTY_DETAIL_MEETING_ROOM_LINK,
      menuItem: {
        as: Link,
        id: "tab3",
        to: DESKIMO_PROPERTY_DETAIL_MEETING_ROOM_LINK.replaceAll(
          ":id",
          id || ""
        ).replaceAll(":companyId", companyId || ""),
        content: translations.meetingRooms,
        exact: true,
      },
      pane: <Route path={DESKIMO_PROPERTY_DETAIL_MEETING_ROOM_LINK} />,
      render: () => <MeetingRoomTab />,
    },
    {
      name: DESKIMO_PROPERTY_DETAIL_PRIVATE_OFFICES_LINK,
      menuItem: {
        as: Link,
        id: "privateOffice",
        to: DESKIMO_PROPERTY_DETAIL_PRIVATE_OFFICES_LINK.replaceAll(
          ":id",
          id || ""
        ).replaceAll(":companyId", companyId || ""),
        content: "Private Offices",
        exact: true,
      },
      pane: <Route path={DESKIMO_PROPERTY_DETAIL_PRIVATE_OFFICES_LINK} />,
      render: () => <PrivateOfficesTab />,
    },
    {
      name: DESKIMO_PROPERTY_DETAIL_NOTES_LINK,
      menuItem: {
        as: Link,
        id: "tab4",
        to: DESKIMO_PROPERTY_DETAIL_NOTES_LINK.replaceAll(
          ":id",
          id || ""
        ).replaceAll(":companyId", companyId || ""),
        content: "Deskimo Notes",
        exact: true,
      },
      pane: <Route path={DESKIMO_PROPERTY_DETAIL_NOTES_LINK} />,
      render: () => <DeskimoNotes />,
    },
  ];

  if (propertyDetail?.has_workspace_integration) {
    panes.push({
      name: DESKIMO_PROPERTY_DETAIL_INTEGRATIONS_LINK,
      menuItem: {
        as: Link,
        id: "tab5",
        to: DESKIMO_PROPERTY_DETAIL_INTEGRATIONS_LINK.replaceAll(
          ":id",
          id || ""
        ).replaceAll(":companyId", companyId || ""),
        content: "Integrations",
        exact: true,
      },
      pane: <Route path={DESKIMO_PROPERTY_DETAIL_INTEGRATIONS_LINK} />,
      render: () => <IntegrationsTab />,
    });
  }

  panes.push({
    name: DESKIMO_PROPERTY_DETAIL_REWARD_LINK,
    menuItem: {
      as: Link,
      id: "tab6",
      to: DESKIMO_PROPERTY_DETAIL_REWARD_LINK.replaceAll(
        ":id",
        id || ""
      ).replaceAll(":companyId", companyId || ""),
      content: "Rewards",
      exact: true,
    },
    pane: <Route path={DESKIMO_PROPERTY_DETAIL_REWARD_LINK} />,
    render: () => <RewardsTab />,
  });

  const defaultIndex = findTabIndexByName(panes, tabName);

  return (
    <MasterTemplate
      containerClass={"position-relative"}
      roleAccept={[ROLE_DESKIMO_ADMIN_ID]}
    >
      <Tab
        menu={{ text: true }}
        activeIndex={defaultIndex}
        panes={panes}
        className={"tab-custom property-tabs"}
      />
    </MasterTemplate>
  );
};

export default DeskimoPropertyDetail;
