import React from "react";
import { PUBLIC_URL } from "../../../App/configs/Constants";
import SizeBox from "../../../../components/SizeBox";

type Props = {
  onRegenerate: (text: string) => void;
  onExportExcel: (text: string) => void;
  reference: string;
};

const Action = ({ reference, onRegenerate, onExportExcel }: Props) => {
  return (
    <div className={"accounting-action-wrapper"}>
      <div
        className={"item"}
        onClick={() => {
          onRegenerate(reference);
        }}
      >
        <img
          src={PUBLIC_URL + "/assets/icons/icon-sync-outline.svg"}
          alt="icon-repeat"
        />
        <SizeBox width={"6px"} />
        Regenerate
      </div>
      <div
        onClick={() => {
          onExportExcel(reference);
        }}
        className={"item"}
      >
        Export Excel
      </div>
    </div>
  );
};

export default Action;
