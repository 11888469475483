import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardDeskimoDeskBookingFilterSelector,
  dashboardDeskBookingSelector,
  dashboardFilterDateRangeSelector,
  dashboardFilterDateTypeSelector,
  dashboardStatisticFilterSelector,
  filterListPropertySelectedSelector,
} from "../../../../selectors/DashboardSelectors";
import {
  dashboardDeskBookingThunk,
  transactionInfoThunk,
} from "../../../../thunks/DashboardThunks";
import {
  AUTO_REFRESH_INTERVAL_TIME,
  DESK_BOOKING,
  FILTER_DATE_TODAY,
  PROPERTY,
} from "../../../../configs/Constants";
import { DeskBookingDeskimoType } from "../../../../configs/DeclareTypes";
import { dashboardDeskimoDeskBookingFilterAction } from "../../../../actions/DashboardActions";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../../../App/configs/Constants";
import TableCustom from "../../../../../../components/Table";
import PaymentType from "./components/PaymentType";
import { Link } from "react-router-dom";
import { DESKIMO_PROPERTY_DETAIL_LINK } from "../../../../../App/configs/ListRoutes";
import SizeBox from "../../../../../../components/SizeBox";
import { Popup } from "semantic-ui-react";
import BookingActions from "./components/BookingActions";
import UserNameAndIndicator from "./components/UserNameAndIndicator";

const TableDeskBookings = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const dashboardDeskBooking = useSelector(dashboardDeskBookingSelector);
  const dashboardDeskBookingFilter = useSelector(
    dashboardDeskimoDeskBookingFilterSelector
  );
  const dashboardFilterDateRange = useSelector(
    dashboardFilterDateRangeSelector
  );
  const dashboardFilterMarket = useSelector(dashboardStatisticFilterSelector);
  const dashboardFilterDateType = useSelector(dashboardFilterDateTypeSelector);
  const filterListPropertySelected = useSelector(
    filterListPropertySelectedSelector
  );
  useEffect(() => {
    if (dashboardFilterDateRange === null) {
      return;
    }

    _init();
    const interval = setInterval(_autoRefresh, AUTO_REFRESH_INTERVAL_TIME);
    return () => {
      clearInterval(interval);
    };
  }, [
    dashboardDeskBookingFilter,
    dashboardFilterDateRange,
    dashboardFilterMarket,
    filterListPropertySelected,
  ]);

  const _init = async () => {
    try {
      setLoading(true);
      const data = await dispatch<any>(
        dashboardDeskBookingThunk(PROPERTY, false, true)
      );

      _getTransactionInfo(data?.items);
    } catch (e) {
      console.log("e", e);
    } finally {
      setLoading(false);
    }
  };

  const _autoRefresh = async () => {
    if (dashboardFilterDateType !== FILTER_DATE_TODAY) {
      return;
    }
    const data = await dispatch<any>(
      dashboardDeskBookingThunk(PROPERTY, false, true)
    );
    _getTransactionInfo(data?.items);
  };

  const _getTransactionInfo = (data: []) => {
    if (!data?.length) {
      return;
    }

    const payload = {
      transaction_ids: data?.map(({ id }: any) => id),
      type: DESK_BOOKING,
    };

    dispatch<any>(transactionInfoThunk(payload));
  };

  const statusField = ({
    status_id,
    refund_amount,
    refund_status_id,
    status_name,
    is_request_cancellation,
    cancellation_request,
  }: {
    status_id: number;
    refund_amount: string | null;
    refund_status_id: number | null;
    status_name: string;
    is_request_cancellation: number;
    cancellation_request?: string;
  }) => (
    <>
      <div>
        <b>Status: </b>
        {status_name}
      </div>
      <SizeBox height={"10px"} />
      <div>
        <b>Cancellation request: </b>
        {cancellation_request}
      </div>
    </>
  );

  const _formatInputData = () => {
    return dashboardDeskBooking.items.map(
      ({
        transaction_id,
        username,
        date,
        status_id,
        revenue,
        refund_status,
        refund_amount,
        actions,
        id,
        property,
        is_company_payment_method,
        company,
        from_source,
        user_id,
        created_time,
        status_name,
        is_request_cancellation,
        refund_type,
        price_after_discount,
        price_after_discount_amount,
        free_credit_amount,
        currency,
        property_company_name,
        property_id,
        user_cap_name,
        user_avatar,
        discount_price,
        property_company_id,
        can_pay_with_credit,
        cancellation_request,
        stripe_link,
      }: DeskBookingDeskimoType) => {
        return {
          transaction_id,
          userName: (
            <UserNameAndIndicator
              transactionId={id}
              userName={username}
              userId={user_id}
              showAvatar
              avatarUrl={user_avatar}
              capName={user_cap_name}
              isCompanyPaymentMethod={is_company_payment_method}
              companyName={company}
            />
          ),
          from_source,
          property: (
            <Link
              to={DESKIMO_PROPERTY_DETAIL_LINK.replaceAll(
                ":id",
                String(property_id)
              ).replaceAll(":companyId", String(property_company_id))}
            >
              <Popup
                content={property_company_name}
                trigger={<span>{property}</span>}
              />
            </Link>
          ),
          createdDate: created_time ? created_time : "",
          date: date ? date : "",
          paymentType: (
            <PaymentType
              isBusinessPayment={Boolean(is_company_payment_method)}
              companyName={company}
            />
          ),
          revenue: (
            <>
              {stripe_link ? (
                <>
                  <a
                    href={stripe_link}
                    target={"_blank"}
                    className={"text-nowrap"}
                    rel="noreferrer"
                  >
                    {revenue} <i className="uil uil-link-alt"></i>
                  </a>
                </>
              ) : (
                revenue
              )}
            </>
          ),
          discount_price,
          statusField: statusField({
            status_id,
            refund_amount,
            refund_status_id: refund_status,
            status_name,
            is_request_cancellation,
            cancellation_request,
          }),
          action: (
            <BookingActions
              statusId={status_id}
              bookingId={id}
              actions={actions}
              onCallback={_init}
              setRefresh={_init}
              refundType={refund_type}
              priceAfterDiscount={price_after_discount}
              priceAfterDiscountAmount={price_after_discount_amount}
              freeCreditAmount={free_credit_amount}
              currency={currency}
              canPayWithCredit={can_pay_with_credit}
            />
          ),
        };
      }
    );
  };

  const columns = [
    { key: "hot_desk_booking_by_transaction", col: translations.transactionID },
    { key: "hot_desk_booking_by_username", col: "User Name" },
    { key: "hot_desk_booking_by_form_source", col: "Source" },
    { key: "hot_desk_booking_by_property", col: translations.property },
    { key: "hot_desk_booking_by_created_time", col: "Created Date" },
    { key: "hot_desk_booking_by_date", col: "Booking Date" },
    { key: "hot_desk_booking_by_payment", col: "Payment Type" },
    { key: "hot_desk_booking_by_revenue", col: translations.revenue },
    {
      key: "hot_desk_booking_by_discount",
      col: translations.settings.discount,
    },
    { key: "hot_desk_booking_by_status", col: translations.status },
    { key: "hot_desk_booking_by_action", col: translations.action },
  ];

  const _onChangeSortCol = (col: any) => {
    if (dashboardDeskBookingFilter.sort_by !== col.key) {
      dispatch(
        dashboardDeskimoDeskBookingFilterAction({
          key: "sort_by",
          value: col.key,
        })
      );
      dispatch(
        dashboardDeskimoDeskBookingFilterAction({
          key: "order_by",
          value: ORDER_BY_ASC,
        })
      );
    } else {
      dispatch(
        dashboardDeskimoDeskBookingFilterAction({
          key: "sort_by",
          value: col.key,
        })
      );
      if (dashboardDeskBookingFilter.order_by === ORDER_BY_NONE) {
        dispatch(
          dashboardDeskimoDeskBookingFilterAction({
            key: "order_by",
            value: ORDER_BY_ASC,
          })
        );
      }
      if (dashboardDeskBookingFilter.order_by === ORDER_BY_ASC) {
        dispatch(
          dashboardDeskimoDeskBookingFilterAction({
            key: "order_by",
            value: ORDER_BY_DESC,
          })
        );
      }
      if (dashboardDeskBookingFilter.order_by === ORDER_BY_DESC) {
        dispatch(
          dashboardDeskimoDeskBookingFilterAction({
            key: "order_by",
            value: ORDER_BY_NONE,
          })
        );
      }
    }
  };

  return (
    <TableCustom
      orderBy={dashboardDeskBookingFilter.order_by}
      sortBy={dashboardDeskBookingFilter.sort_by}
      sortTable
      onChangeSortCol={_onChangeSortCol}
      tableData={dashboardDeskBooking ? _formatInputData() : []}
      columns={columns}
      loading={loading}
      totalItems={dashboardDeskBooking?.pagination?.total}
      currentPage={dashboardDeskBooking?.pagination?.page}
      showTotal
      unit={translations.transactions}
      itemPerPage={dashboardDeskBooking?.pagination?.limit}
      onChangePagination={(e: any, { activePage }: any) => {
        dispatch(
          dashboardDeskimoDeskBookingFilterAction({
            key: "page",
            value: activePage,
          })
        );
      }}
    />
  );
};

export default TableDeskBookings;
