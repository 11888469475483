import React, { useContext, useEffect, useRef, useState } from "react";
import ModalRightSideBarTemplate from "../../../../../App/views/ModalRightSideBarTemplate";
import { Formik, FormikValues } from "formik";
import * as yup from "yup";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import Label from "../../../../../../components/Label";
import SizeBox from "../../../../../../components/SizeBox";
import InputCustom from "../../../../../../components/Input";
import BoxToggleSlider from "../../../../../../components/BoxToggleSlider";
import { LocalizationContext } from "../../../../../../locales/Translation";
import DropdownCompany from "./DropdownCompany";
import DropdownProperty from "./DropdownProperty";
import DropdownContractType from "./DropdownContractType";
import Date from "./Date";
import UpdateContract from "./UpdateContract";
import TextAreaCustom from "../../../../../../components/Input/TextArea";
import DateOfWeek from "./DateOfWeek";
import {
  CONTRACT_DAILY_TYPE,
  CONTRACT_MONTHLY_TYPE,
  CONTRACT_ONETIME_TYPE,
  LEASE_CREATED_STATUS,
} from "../../../../configs/Constants";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import {
  officeLeasesContractService,
  officeLeaseDetailService,
  officeLeaseUpdateService,
  officeLeaseSummaryService,
} from "../../../../services/BusinessServices";
import moment from "moment-timezone";
import OfficeLeasesContext from "../../Context";
import { SWAL_DELETE_OPTION } from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import { validateNumber } from "../../../../../../services/FormatTextService";

const ModalAddEdit = ({ setLoading, loading }: any) => {
  const { translations } = useContext(LocalizationContext);
  const {
    modalOpen,
    setModalOpen,
    leaseChooseId,
    setLeaseChooseId,
    onSetRefreshing,
  } = useContext(OfficeLeasesContext);

  const [leaseData, setLeaseDate] = useState<any>(null);
  const [fieldDisabled, setFieldDisabled] = useState<boolean>(false);

  const formRef = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        if (!leaseChooseId) {
          return;
        }
        const { data } = await officeLeaseDetailService(leaseChooseId);
        setLeaseDate(data);

        if (data?.status !== LEASE_CREATED_STATUS) {
          setFieldDisabled(true);
        }
      } catch (e: any) {
        toastError(e?.status?.message);
      }
    })();
  }, [leaseChooseId]);

  useEffect(() => {
    if (!modalOpen) {
      setLeaseChooseId(null);
      setLeaseDate(null);
      setFieldDisabled(false);
    }
  }, [modalOpen]);

  const _onCloseModal = () => {
    setModalOpen(false);
  };

  const _handleSubmit = async (values: FormikValues) => {
    try {
      const timezone = values.timezone;
      let payload: any = {
        ...values,
        pax_amount: values.pax_amount || null,
        start_date: moment
          .unix(values.start_date)
          .tz(timezone, true)
          .startOf("day")
          .unix(),
      };

      if (values.end_time !== null) {
        payload = {
          ...payload,
          end_date: moment
            .unix(values.end_date)
            .tz(timezone, true)
            .startOf("day")
            .unix(),
        };
      }

      if (leaseChooseId) {
        payload = { ...payload, id: leaseChooseId };
        await officeLeaseUpdateService(payload);
        toastSuccess(translations.editSuccessPrompt);
        _onCloseModal();
        onSetRefreshing();
      } else {
        const response = await officeLeaseSummaryService(payload);
        const iconHtml = null;
        const title = `Payment Summary`;
        const confirmText = "Confirm";
        const confirmClass = "primary";
        let html = "<div>";

        if (response?.data?.length > 0) {
          response?.data.forEach((line: any) => (html += `${line}`));
        }

        html += "</div>";

        const options = SWAL_DELETE_OPTION({
          title,
          iconHtml,
          confirmText,
          confirmClass,
          html,
        });
        SweetAlertCommon({
          options,
          confirmCallback: async () => {
            setLoading(true);
            await officeLeasesContractService(payload);
            setLoading(false);
            toastSuccess(translations.createSuccessPrompt);
            _onCloseModal();
            onSetRefreshing();
          },
          cancelCallback: () => null,
        });
      }
    } catch (e: any) {
      console.log(e);
      toastError(e?.status?.message);
    }
  };

  return (
    <ModalRightSideBarTemplate
      open={modalOpen}
      modalClassName={"office-lease-modal"}
      onClose={_onCloseModal}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          name: leaseData?.name || "",
          company_id: leaseData?.company_id || "",
          property_id: leaseData?.property_id || "",
          property_name: leaseData?.property_name || "",
          pax_amount: leaseData?.pax_amount || "",
          type: leaseData?.contract_type || CONTRACT_DAILY_TYPE,
          price: leaseData?.price || "",
          start_date: leaseData?.start_date
            ? moment
                .unix(leaseData.start_date)
                .tz(leaseData.timezone)
                .local(true)
                .unix()
            : moment().startOf("day").unix(),
          end_date: leaseData?.end_date
            ? moment
                .unix(leaseData.end_date)
                .tz(leaseData.timezone)
                .local(true)
                .unix()
            : moment().startOf("day").unix(),
          is_no_end_date: leaseData?.is_no_end_date !== false,
          cogs: leaseData?.cogs || "",
          is_pay_with_credit: leaseData?.is_pay_with_credit !== false,
          usage_week_days: leaseData?.usage_week_days || [],
          note: leaseData?.note || "",
          file_key: leaseData?.contract_file?.file_key || "",
          file_url: leaseData?.contract_file?.file_url || "",
          timezone: leaseData?.timezone || "",
          currency: leaseData?.currency || "",
          force_post_pay: leaseData?.force_post_pay || false,
        }}
        validationSchema={yup.object().shape({
          name: yup.string().required().label("Lease name"),
          company_id: yup.string().required().label("Generate lease for"),
          property_id: yup.string().required().label("On which property"),
          pax_amount: yup.string().typeError("Must be a number").min(1),
          price: yup.string().required().label("Price per day"),
          cogs: yup.string().required().label("COGS"),
          usage_week_days: yup.array().when(["type"], {
            is: (value: string) => value === CONTRACT_DAILY_TYPE,
            then: yup
              .array()
              .min(1, "Days of the week used is a required field"),
          }),
          note: yup.string().required().label("Note"),
        })}
        onSubmit={_handleSubmit}
      >
        {({
          values,
          isSubmitting,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
          handleChange,
          resetForm,
        }) => (
          <>
            <Modal.Header>
              {leaseChooseId ? "Edit Lease" : "Add New Lease"}
              <ButtonCustom
                onClick={_onCloseModal}
                className="m-0 p-0 none-bg close-modal"
              >
                <>
                  {translations.close}
                  <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
                </>
              </ButtonCustom>
            </Modal.Header>
            <Modal.Content className={"scroll"}>
              <InputCustom
                disabled={fieldDisabled}
                label={"Lease name"}
                name={"name"}
                value={values.name}
                onChange={handleChange}
                touched={touched.name}
                error={errors.name}
              />
              <DropdownCompany
                disabled={fieldDisabled}
                value={values.company_id}
                onChange={(value) => setFieldValue("company_id", value)}
                error={Boolean(touched.property_id && errors.property_id)}
              />
              <DropdownProperty
                disabled={fieldDisabled}
                values={values}
                setFieldValue={setFieldValue}
                error={Boolean(touched.property_id && errors.property_id)}
              />
              <InputCustom
                disabled={fieldDisabled}
                label={"No. of pax"}
                name={"pax_amount"}
                value={values.pax_amount}
                onChange={(e: any, { value }: any) => {
                  const text = value.replace(/\D/gm, "");
                  setFieldValue("pax_amount", text);
                }}
                touched={touched.pax_amount}
                error={errors.pax_amount}
              />
              <DropdownContractType
                disabled={fieldDisabled}
                value={values.type}
                onChange={(value) => {
                  setFieldValue("type", value);
                  if (value === CONTRACT_ONETIME_TYPE) {
                    setFieldValue("is_no_end_date", false);
                  }
                }}
              />
              {values.type === CONTRACT_DAILY_TYPE ? (
                <DateOfWeek
                  error={
                    touched.usage_week_days && errors.usage_week_days
                      ? String(errors.usage_week_days)
                      : ""
                  }
                  disabled={fieldDisabled}
                  value={values.usage_week_days}
                  setFieldValue={setFieldValue}
                />
              ) : null}

              <InputCustom
                disabled={fieldDisabled}
                label={
                  values.type === CONTRACT_DAILY_TYPE
                    ? "Price per day"
                    : values.type === CONTRACT_MONTHLY_TYPE
                    ? "Price per month"
                    : "Price of the booking"
                }
                labelGuide={
                  "This is the price that Deskimo will receive as gross booking value."
                }
                inputLeftIcon={values?.currency || "$"}
                name={"price"}
                inputLeftIconBorder
                value={values.price}
                onChange={(e: any, { value }: any) => {
                  setFieldValue("price", validateNumber(value));
                }}
                touched={touched.price}
                error={errors.price}
              />
              <Date
                disabled={fieldDisabled}
                values={values}
                setFieldValue={setFieldValue}
              />
              <InputCustom
                disabled={fieldDisabled}
                label={
                  values.type === CONTRACT_DAILY_TYPE
                    ? "Cost paid to the property per day (COGS)"
                    : values.type === CONTRACT_MONTHLY_TYPE
                    ? "Cost paid to the property per month (COGS)"
                    : "Cost paid to the property for this booking (COGS)"
                }
                inputLeftIcon={values.currency || "$"}
                inputLeftIconBorder
                value={values.cogs}
                name={"cogs"}
                onChange={(e: any, { value }: any) => {
                  setFieldValue("cogs", validateNumber(value));
                }}
                touched={touched.cogs}
                error={errors.cogs}
              />
              <UpdateContract values={values} setFieldValue={setFieldValue} />
              <TextAreaCustom
                label={"Notes"}
                value={values.note}
                name={"note"}
                onChange={handleChange}
                error={errors.note}
                touched={touched.note}
              />
              <SizeBox height={"48px"} />
              <BoxToggleSlider
                disabled={fieldDisabled}
                value={values.is_pay_with_credit}
                onChange={(e: any, data: any) =>
                  setFieldValue("is_pay_with_credit", data.checked)
                }
                content={
                  <Label
                    fontSize={"14"}
                    label={"Allow to pay with business credit"}
                  />
                }
              />
              <SizeBox height={"44px"} />
              <BoxToggleSlider
                disabled={fieldDisabled}
                value={values.force_post_pay}
                onChange={(e: any, data: any) =>
                  setFieldValue("force_post_pay", data.checked)
                }
                content={
                  <Label fontSize={"14"} label={"Allow to pay with post-pay"} />
                }
              />
              <SizeBox height={"44px"} />
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom
                height={"47px"}
                onClick={() => {
                  resetForm();
                  _onCloseModal();
                }}
              >
                {translations.discard}
              </ButtonCustom>
              <ButtonCustom
                disabled={isSubmitting || loading}
                loading={isSubmitting || loading}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {leaseChooseId ? translations.save : translations.add}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalRightSideBarTemplate>
  );
};

export default ModalAddEdit;
