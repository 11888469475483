import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
  PUBLIC_URL,
} from "../../../../../App/configs/Constants";
import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import TableCustom from "../../../../../../components/Table";
import CircleStatus from "../../../../../../components/CircleStatus";
import ButtonCustom from "../../../../../../components/Button";
import SizeBox from "../../../../../../components/SizeBox";
import { getPaymentHistoryThunk } from "../../../../thunks/BillingThunk";
import { useDispatch, useSelector } from "react-redux";
import {
  paymentHistoryFilterSelector,
  paymentHistorySelector,
} from "../../../../selectors/BillingSelector";
import moment from "moment";
import { FORMAT_DATE_VARIANT_1 } from "../../../../../App/configs/DateTimeConfigs";
import { getValidTimeStamp } from "../../../../../../utils/commons";
import { Link, useLocation } from "react-router-dom";
import {
  INVOICE_DETAIL_LINK,
  INVOICE_PARTNER_LINK,
  PARTNER_INVOICE_DETAIL_LINK,
} from "../../../../../App/configs/ListRoutes";
import MonthlyUsage from "./Components/MonthlyUsage";
import { getInvoiceFiltersService } from "../../../../services/SettingServices";
import DropdownWithCheckboxMenuWithTotal from "../../../../../../components/DropdownWithCheckboxMenuWithTotal";
import { paymentHistoryFilterAction } from "../../../../actions/BiilingActions";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { PRODUCT_TYPE_PARTNER } from "../../../../../Dashboard/configs/Constants";

const BillingRightSide = () => {
  const { translations } = useContext(LocalizationContext);
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const paymentHistory = useSelector(paymentHistorySelector);
  const [tableFilter, setTableFilter] = useState({
    statuses: [],
    page: 1,
    order_by: "",
    sort_by: "",
  });
  const paymentHistoryFilters = useSelector(paymentHistoryFilterSelector);

  const [loadingTable, setLoadingTable] = useState(false);

  const _getInvoiceFilters = async () => {
    setLoadingTable(true);
    try {
      const response = await getInvoiceFiltersService();
      const filters = response?.data;
      if (filters.length > 0) {
        dispatch(paymentHistoryFilterAction(filters));

        setTableFilter((prevState) => {
          return {
            ...prevState,
            statuses: filters.map((filter: any) => filter?.status),
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    _getInvoiceFilters();
  }, []);

  //Handle for payment history table
  useEffect(() => {
    _getPaymentHistory(tableFilter);
  }, [tableFilter]);

  const _getPaymentHistory = async (tableFilter: any) => {
    setLoadingTable(true);

    try {
      await dispatch<any>(
        getPaymentHistoryThunk({
          ...tableFilter,
          products: [PRODUCT_TYPE_PARTNER],
        })
      );
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingTable(false);
    }
  };

  const decorateData = (data: []) => {
    if (!data || data.length < 1) return [];
    return data.map((field: any) => {
      return {
        col1: field?.name,
        col2: moment(getValidTimeStamp(field?.due_date))
          .utc()
          .format(FORMAT_DATE_VARIANT_1),
        col3: field?.total,
        time_period: field?.time_period,
        col4: (
          <div className={"d-flex align-items-center"}>
            <CircleStatus color={getInvoiceColor(field?.status)} />
            {field?.status_name}
          </div>
        ),
        col5: (
          <ButtonCustom className={"link p-0 invoice-link"}>
            <Link
              to={
                pathname.includes(INVOICE_PARTNER_LINK)
                  ? PARTNER_INVOICE_DETAIL_LINK.replaceAll(
                      ":token",
                      field?.token
                    )
                  : INVOICE_DETAIL_LINK.replaceAll(":token", field?.token)
              }
            >
              {translations.viewDetails}
            </Link>
          </ButtonCustom>
        ),
      };
    });
  };

  const getInvoiceColor = (status: number) => {
    switch (status) {
      case 0:
        return "orange";
      case 1:
        return "yellow";
      case 2:
        return "green";
    }
  };

  const tableData = decorateData(paymentHistory?.data?.items);

  const columns = [
    { key: "", col: translations.invoiceNo },
    { key: "date", col: translations.date },
    { key: "amount", col: translations.totalAmount },
    { key: "time-period", col: translations.timePeriod },
    { key: "status", col: translations.status },
    { key: "", col: translations.details },
  ];

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    setTableFilter((prevState) => {
      return { ...prevState, page: activePage };
    });
  };

  const _onChangeFilter = (e: SyntheticEvent, data: any) => {
    if (data.checked) {
      setTableFilter((prevState: any) => {
        return {
          ...prevState,
          statuses: [...tableFilter.statuses, data.value],
        };
      });
    } else {
      setTableFilter((prevState: any) => {
        return {
          ...prevState,
          statuses: tableFilter.statuses.filter(
            (status) => status !== data.value
          ),
        };
      });
    }
  };

  const _onChangeSortCol = (col: any) => {
    if (tableFilter?.sort_by !== col?.key) {
      setTableFilter((prevState) => {
        return { ...prevState, sort_by: col?.key, order_by: ORDER_BY_ASC };
      });
    } else {
      setTableFilter((prevState: any) => {
        return { ...prevState, sort_by: col?.key, order_by: getNextOrderBy() };
      });
    }
  };

  const getNextOrderBy = () => {
    switch (tableFilter.order_by) {
      case ORDER_BY_ASC:
        return ORDER_BY_DESC;
      case ORDER_BY_DESC:
        return ORDER_BY_NONE;
      case ORDER_BY_NONE:
        return ORDER_BY_ASC;
    }
  };

  const getTableFilter = () => {
    if (paymentHistoryFilters.length) {
      return paymentHistoryFilters.map((filter: any) => {
        return {
          id: filter?.status,
          name: `${filter?.status_name} (${filter?.total})`,
        };
      });
    }
  };

  return (
    <div className={"billing-tab__right"}>
      <MonthlyUsage />
      <SizeBox height={"24px"} />

      <div>
        <div className={"d-flex justify-content-between align-items-center"}>
          <div className={"billing-tab__right-title"}>
            {translations.paymentHistory}
          </div>
          <div className={"dropdown-filter"}>
            <DropdownWithCheckboxMenuWithTotal
              values={tableFilter.statuses}
              onChange={_onChangeFilter}
              listCheckBox={getTableFilter()}
              label={"Filters"}
              icon={<img src={PUBLIC_URL + "/assets/icons/icon-filter.svg"} />}
            />
          </div>
        </div>
        <div>
          <SizeBox height={"24px"} />
          <TableCustom
            orderBy={tableFilter?.order_by}
            sortBy={tableFilter?.sort_by}
            onChangeSortCol={_onChangeSortCol}
            sortTable
            loading={loadingTable}
            onChangePagination={_onChangePagination}
            showTotal={false}
            tableData={tableData}
            columns={columns}
            currentPage={paymentHistory?.data?.pagination?.page}
            totalItems={paymentHistory?.data?.pagination?.total}
          />
        </div>
      </div>
    </div>
  );
};

export default BillingRightSide;
