import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardDeskimoDeskUsageFilterSelector,
  dashboardDeskUsageSelector,
  dashboardFilterDateRangeSelector,
  dashboardFilterDateTypeSelector,
  dashboardStatisticFilterSelector,
  filterListPropertySelectedSelector,
  modalBookingDeskSelector,
  modalBookingRoomSelector,
} from "../../../../selectors/DashboardSelectors";
import {
  dashboardDeskUsageThunk,
  transactionInfoThunk,
} from "../../../../thunks/DashboardThunks";
import {
  AUTO_REFRESH_INTERVAL_TIME,
  FILTER_DATE_TODAY,
  PROPERTY,
  WALK_IN,
} from "../../../../configs/Constants";
import { Popup } from "semantic-ui-react";
import { DeskUsageDeskimoDataType } from "../../../../configs/DeclareTypes";
import { Link } from "react-router-dom";
import { DESKIMO_PROPERTY_DETAIL_LINK } from "../../../../../App/configs/ListRoutes";
import { dashboardDeskimoDeskUsageFilterAction } from "../../../../actions/DashboardActions";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
} from "../../../../../App/configs/Constants";
import TableCustom from "../../../../../../components/Table";
import DeskUsageAction from "./components/DeskUsageAction";
import UserNameAndIndicator from "./components/UserNameAndIndicator";
import SizeBox from "../../../../../../components/SizeBox";

const TableDeskUsage = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const dashboardDeskUsage = useSelector(dashboardDeskUsageSelector);
  const dashboardDeskUsageFilter = useSelector(
    dashboardDeskimoDeskUsageFilterSelector
  );

  const dashboardFilterDateRange = useSelector(
    dashboardFilterDateRangeSelector
  );
  const modalBookingRoom = useSelector(modalBookingRoomSelector);
  const modalBookingDesk = useSelector(modalBookingDeskSelector);
  const dashboardFilterMarket = useSelector(dashboardStatisticFilterSelector);
  const dashboardFilterDateType = useSelector(dashboardFilterDateTypeSelector);
  const filterListPropertySelected = useSelector(
    filterListPropertySelectedSelector
  );

  useEffect(() => {
    if (dashboardFilterDateRange === null) {
      return;
    }
    _init();
    const interval = setInterval(_autoRefresh, AUTO_REFRESH_INTERVAL_TIME);
    return () => {
      clearInterval(interval);
    };
  }, [
    dashboardDeskUsageFilter,
    dashboardFilterDateRange,
    dashboardFilterMarket,
    filterListPropertySelected,
  ]);

  const _init = async () => {
    try {
      setLoading(true);
      if (modalBookingRoom.open || modalBookingDesk.open) {
        return;
      } else {
        const data = await dispatch<any>(
          dashboardDeskUsageThunk(PROPERTY, false, true)
        );

        _getTransactionInfo(data?.items);
      }
    } catch (e) {
      console.log("e", e);
    } finally {
      setLoading(false);
    }
  };

  const _autoRefresh = async () => {
    if (dashboardFilterDateType !== FILTER_DATE_TODAY) {
      return;
    }
    const data = await dispatch<any>(
      dashboardDeskUsageThunk(PROPERTY, false, true)
    );
    _getTransactionInfo(data?.items);
  };

  const _getTransactionInfo = (data: []) => {
    if (!data?.length) {
      return;
    }

    const payload = {
      transaction_ids: data.map(({ visit_id }: any) => visit_id),
      type: WALK_IN,
    };

    dispatch<any>(transactionInfoThunk(payload));
  };

  const _formatInputData = () => {
    return dashboardDeskUsage.items.map(
      ({
        transaction_id,
        usage,
        status,
        check_out,
        property_name,
        revenue,
        username,
        check_in,
        visit_id,
        hourly_rate,
        property_id,
        is_company_payment_method,
        user_id,
        company_name,
        property_company_name,
        market_code,
        property_company_id,
        discount,
        can_pay_with_credit,
        is_guest,
        stripe_link,
        main_visitor_name,
        can_change_to_payg,
      }: DeskUsageDeskimoDataType) => {
        return {
          transaction_id,
          employee_name: (
            <UserNameAndIndicator
              mainVisitorName={main_visitor_name}
              transactionId={visit_id}
              userId={user_id}
              userName={username}
              isGuest={is_guest}
              isCompanyPaymentMethod={is_company_payment_method}
              companyName={company_name}
            />
          ),
          property_name: (
            <Link
              to={DESKIMO_PROPERTY_DETAIL_LINK.replaceAll(
                ":id",
                String(property_id)
              ).replaceAll(":companyId", String(property_company_id))}
            >
              <Popup
                content={property_company_name}
                trigger={<span>{property_name}</span>}
              />
            </Link>
          ),
          market_code,
          check_in_time: check_in ? check_in : "",
          check_out_time: check_out ? check_out : "",
          usage,
          hourly_rate,
          revenue: (
            <>
              {stripe_link ? (
                <>
                  <a
                    href={stripe_link}
                    target={"_blank"}
                    className={"text-nowrap"}
                    rel="noreferrer"
                  >
                    {revenue} <i className="uil uil-link-alt"></i>
                  </a>
                </>
              ) : (
                revenue
              )}
            </>
          ),
          discount,
          status: translations.deskBookingStatus[status],
          action: (
            <DeskUsageAction
              setLoading={setLoading}
              loading={loading}
              statusId={status}
              visitId={visit_id}
              canPayWithCredit={can_pay_with_credit}
              canChangeToPAYG={can_change_to_payg}
            />
          ),
        };
      }
    );
  };

  const columns = [
    { key: "visit_by_transaction_id", col: translations.transactionID },
    { key: "visit_by_employee_name", col: translations.userName },
    // { key: "visit_by_phone", col: translations.phoneNo },
    { key: "visit_by_property_name", col: translations.property },
    { key: "visit_by_market", col: translations.market },
    { key: "visit_by_check_in", col: translations.checkInTime },
    { key: "visit_by_check_out", col: translations.checkOutTime },
    // { key: "visit_by_company", col: translations.company },
    { key: "visit_by_usage", col: translations.usageMin },
    { key: "visit_by_hourly_rate", col: translations.hourlyRate },
    { key: "visit_by_cost", col: translations.revenue },
    { key: "visit_by_discount", col: translations.settings.discount },
    { key: "visit_by_status", col: translations.status },
    { key: "", col: translations.action },
  ];

  const _onChangeSortCol = (col: any) => {
    if (dashboardDeskUsageFilter.sort_by !== col.key) {
      dispatch(
        dashboardDeskimoDeskUsageFilterAction({
          key: "sort_by",
          value: col.key,
        })
      );
      dispatch(
        dashboardDeskimoDeskUsageFilterAction({
          key: "order_by",
          value: ORDER_BY_ASC,
        })
      );
    } else {
      dispatch(
        dashboardDeskimoDeskUsageFilterAction({
          key: "sort_by",
          value: col.key,
        })
      );
      if (dashboardDeskUsageFilter.order_by === ORDER_BY_NONE) {
        dispatch(
          dashboardDeskimoDeskUsageFilterAction({
            key: "order_by",
            value: ORDER_BY_ASC,
          })
        );
      }
      if (dashboardDeskUsageFilter.order_by === ORDER_BY_ASC) {
        dispatch(
          dashboardDeskimoDeskUsageFilterAction({
            key: "order_by",
            value: ORDER_BY_DESC,
          })
        );
      }
      if (dashboardDeskUsageFilter.order_by === ORDER_BY_DESC) {
        dispatch(
          dashboardDeskimoDeskUsageFilterAction({
            key: "order_by",
            value: ORDER_BY_NONE,
          })
        );
      }
    }
  };

  return (
    <TableCustom
      orderBy={dashboardDeskUsageFilter.order_by}
      sortBy={dashboardDeskUsageFilter.sort_by}
      sortTable={true}
      onChangeSortCol={_onChangeSortCol}
      tableData={dashboardDeskUsage ? _formatInputData() : []}
      columns={columns}
      loading={loading}
      totalItems={dashboardDeskUsage?.pagination?.total}
      currentPage={dashboardDeskUsage?.pagination?.page}
      showTotal
      unit={translations.transactions}
      itemPerPage={dashboardDeskUsage?.pagination?.limit}
      onChangePagination={(e: any, { activePage }: any) => {
        dispatch(
          dashboardDeskimoDeskUsageFilterAction({
            key: "page",
            value: activePage,
          })
        );
      }}
    />
  );
};

export default TableDeskUsage;
