import { Modal } from "semantic-ui-react";
import ModalRightSideBarTemplate from "../../../../../App/views/ModalRightSideBarTemplate";
import { useDispatch, useSelector } from "react-redux";
import {
  businessCompanyStaffEditDataSelector,
  isModalBusinessCompanyStaffEditSelector,
} from "../../../../selectors/BusinessSelectors";
import { modalBusinessCompanyStaffEditAction } from "../../../../actions/BusinessActions";
import ButtonCustom from "../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import React, { useContext, useRef, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import * as yup from "yup";
import SizeBox from "../../../../../../components/SizeBox";
import InputCustom from "../../../../../../components/Input";
import BoxToggleSlider from "../../../../../../components/BoxToggleSlider";
import Label from "../../../../../../components/Label";
import { Formik } from "formik";
import MakeCompanyAdminLabelGuide from "./MakeCompanyAdminLabelGuide";
import "./style.scss";
import ModalCropImage from "../../../../../App/views/ModalCropImage";
import { fileValidation } from "../../../../../../utils/commons";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { businessCompanyStaffUpdateService } from "../../../../services/BusinessServices";
import InputPhoneFlag from "../../../../../../components/InputPhoneFlag";
import { DIALING_CODE } from "../../../../../App/configs/PhonePrefix";
import { businessCompanyStaffThunk } from "../../../../thunks/BusinessThunks";

const PreviewImage = ({ uri }: any) => {
  return <img src={uri} alt="preview_image" />;
};

const ModalCompanyStaffEdit = ({ filter }: any) => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const uploadFileRef = useRef<any>();
  const [previewImage, setPreviewImage] = useState(null);
  const [openModalCrop, setOpenModalCrop] = useState(false);

  const USER_IS_BUSINESS_USER = 1;
  const USER_IS_BUSINESS_ADMIN = 2;

  const isModalCompanyStaffEdit = useSelector(
    isModalBusinessCompanyStaffEditSelector
  );
  const businessCompanyStaffEditData = useSelector(
    businessCompanyStaffEditDataSelector
  );
  const [imgSrc, setImgSrc] = useState(businessCompanyStaffEditData?.avatar);

  const handleUploadFile = async (e: any) => {
    const reader: any = new FileReader();
    reader.addEventListener("load", () =>
      setImgSrc(reader.result.toString() || "")
    );
    reader.readAsDataURL(e.target.files[0]);
    setOpenModalCrop(true);
  };

  const _onCloseModal = () => {
    dispatch(modalBusinessCompanyStaffEditAction(false));
  };

  const _handleSubmit = async (values: any) => {
    try {
      const payload = {
        invitation_id: businessCompanyStaffEditData?.invitation_id,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        monthly_budget: businessCompanyStaffEditData?.monthly_budget,
        monthly_pooled_budget:
          businessCompanyStaffEditData?.monthly_pooled_budget,
        make_admin: values.make_admin,
        avatar_uri: values.avatar_uri,
      };
      await businessCompanyStaffUpdateService(payload);
      await dispatch<any>(businessCompanyStaffThunk(filter));
      toastSuccess("Update successfully");
      setPreviewImage(null);
      dispatch(modalBusinessCompanyStaffEditAction(false));
    } catch (e) {
      toastError("Update unsuccessful");
      console.error(e);
    }
  };

  return (
    <ModalRightSideBarTemplate
      open={isModalCompanyStaffEdit}
      modalClassName="modal-right-sidebar-v2"
      onClose={_onCloseModal}
    >
      <Formik
        enableReinitialize
        initialValues={{
          avatar_uri: businessCompanyStaffEditData?.avatar,
          first_name: businessCompanyStaffEditData?.first_name,
          last_name: businessCompanyStaffEditData?.last_name,
          email: businessCompanyStaffEditData?.email,
          country_code: DIALING_CODE.filter((country) => {
            const countryCode = businessCompanyStaffEditData?.country_code
              ? businessCompanyStaffEditData?.country_code
              : "65";
            return country?.value?.replaceAll("+", "") === countryCode;
          })[0]?.key.toUpperCase(),
          phone_number: businessCompanyStaffEditData?.phone_number,
          role: businessCompanyStaffEditData?.role,
          company_name: businessCompanyStaffEditData?.company_name,
          make_admin:
            businessCompanyStaffEditData?.role === USER_IS_BUSINESS_ADMIN
              ? true
              : businessCompanyStaffEditData?.role === USER_IS_BUSINESS_USER
              ? false
              : null,
        }}
        validationSchema={yup.object().shape({
          email: yup.string().email().required().label("Email"),
        })}
        onSubmit={_handleSubmit}
      >
        {({
          values,
          isSubmitting,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
          handleChange,
          resetForm,
        }) => (
          <>
            <Modal.Header>
              {translations.editUser}
              <ButtonCustom
                onClick={_onCloseModal}
                className="m-0 p-0 none-bg close-modal"
              >
                <>
                  {translations.close}
                  <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
                </>
              </ButtonCustom>
            </Modal.Header>
            <Modal.Content className={"scroll"}>
              <Label
                label="Profile picture"
                className="label-phone-number"
                fontSize="12px"
                labelGuide="TBD"
              />
              <div className="mb-16px">
                <div className={"d-flex align-items-center mb-48px"}>
                  <div className={"my-profile__avatar"}>
                    {previewImage ? (
                      <PreviewImage uri={previewImage} />
                    ) : (
                      <img
                        src={
                          values?.avatar_uri
                            ? values?.avatar_uri
                            : PUBLIC_URL + "/assets/default-add-avatar.svg"
                        }
                        alt="avatar"
                      />
                    )}

                    <input
                      className={"d-none"}
                      ref={uploadFileRef}
                      type="file"
                      accept="image/*"
                      onChange={async (event: any) => {
                        const isFileValid = fileValidation(event.target.files);

                        if (isFileValid) {
                          const _URL = window.URL || window.webkitURL;
                          const img = new Image();
                          const objectUrl = _URL.createObjectURL(
                            event.target.files[0]
                          );

                          img.src = objectUrl;
                          img.onload = async () => {
                            _URL.revokeObjectURL(objectUrl);
                            await handleUploadFile(event);
                            event.target.value = null;
                          };
                          setOpenModalCrop(true);
                        } else {
                          toastError("The file must be smaller than 2MB");
                        }
                      }}
                    />
                  </div>

                  <SizeBox width={"32px"} />
                  <ButtonCustom
                    onClick={(e: any) => {
                      e.preventDefault();
                      uploadFileRef.current.click();
                    }}
                    className={"primary-alt"}
                    height={"36px"}
                  >
                    <>
                      {translations.uploadImage}
                      <SizeBox width={"14px"} />
                      <i className="uil uil-upload-alt" />
                    </>
                  </ButtonCustom>
                </div>
              </div>
              <div className="mb-16px">
                <Label
                  label="Property Company"
                  className="label-phone-number"
                  fontSize="12px"
                />
                <input
                  className={"input-property-company-user"}
                  value={values.company_name}
                  disabled
                />
              </div>
              <div className="section-name">
                <InputCustom
                  containerClass="width-section-name mr-12px"
                  label="First Name"
                  value={values.first_name}
                  onChange={handleChange}
                  name={"first_name"}
                />
                <InputCustom
                  containerClass="width-section-name"
                  label="Last Name"
                  value={values.last_name}
                  onChange={handleChange}
                  name={"last_name"}
                />
              </div>
              <InputCustom
                label="Email Address"
                value={values.email}
                onChange={handleChange}
                error={errors.email}
                touched={touched.email}
                name={"email"}
              />
              <div className="mb-60px">
                <InputPhoneFlag
                  label={translations.mobilePhoneNumber}
                  labelGuide={
                    "Add phone number incl. international prefix (+xx) and no gaps or special characters, e.g. +44887652323"
                  }
                  selectedFlag={values.country_code}
                  onSelectFlag={(code) => setFieldValue("country_code", code)}
                  error={errors.phone_number}
                  touched={touched.phone_number}
                  phoneChange={(value) => setFieldValue("phone_number", value)}
                  phoneValue={values.phone_number}
                  disabled
                />
              </div>
              <BoxToggleSlider
                content={
                  <Label
                    fontSize={"14px"}
                    size={"16px"}
                    label={"Make Company Admin"}
                    labelGuide={<MakeCompanyAdminLabelGuide />}
                  />
                }
                disabled={
                  businessCompanyStaffEditData?.status_name.toLowerCase() ===
                  "pending"
                }
                value={values.make_admin}
                onChange={() => {
                  setFieldValue("make_admin", !values.make_admin);
                }}
              />
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom
                height={"47px"}
                onClick={() =>
                  dispatch(modalBusinessCompanyStaffEditAction(false))
                }
              >
                {translations.cancel}
              </ButtonCustom>
              <ButtonCustom
                disabled={isSubmitting}
                loading={isSubmitting}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {translations.save}
              </ButtonCustom>
            </Modal.Actions>
            <ModalCropImage
              imgSrc={imgSrc}
              open={openModalCrop}
              setOpen={setOpenModalCrop}
              setFieldValue={setFieldValue}
              fieldKey={"avatar_uri"}
              setPreviewImage={setPreviewImage}
            />
          </>
        )}
      </Formik>
    </ModalRightSideBarTemplate>
  );
};

export default ModalCompanyStaffEdit;
