import React, { useContext, useEffect } from "react";
import { LocalizationContext } from "../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import { freeCreditSelector } from "../../../Dashboard/selectors/DashboardSelectors";
import { getFreeCreditThunk } from "../../../Dashboard/thunks/DashboardThunks";
import { UserCompanyType } from "../../../User/configs/DeclareTypes";
import { userCompanyChooseSelector } from "../../../User/selectors/UserSelectors";

const BusinessUserCreditRemain = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch<any>();

  const freeCredit = useSelector(freeCreditSelector);

  const userCompanyChoose: UserCompanyType = useSelector(
    userCompanyChooseSelector
  );

  useEffect(() => {
    if (!userCompanyChoose?.company_id) {
      return;
    }
    dispatch(getFreeCreditThunk(String(userCompanyChoose.company_id)));
  }, [userCompanyChoose]);
  if (!freeCredit || !freeCredit.length || !freeCredit[0]?.amount) {
    return null;
  }

  const { amount, remaining_days, currency }: any = freeCredit[0];

  return (
    <div className={"dashboard_notification"}>
      <span className={"dashboard_notification_text"}>
        {translations.youHave} {currency} {amount}{" "}
        {translations.inFreeCreditsValidFor} {remaining_days}{" "}
        {remaining_days > 1 ? translations.moreDays : translations.moreDay}
      </span>
    </div>
  );
};

export default BusinessUserCreditRemain;
