import React, { useContext } from "react";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import InputCustom from "../../../../../components/Input";
import Label from "../../../../../components/Label";
import SizeBox from "../../../../../components/SizeBox";
import { LocalizationContext } from "../../../../../locales/Translation";
import { usePlacesWidget } from "react-google-autocomplete";
import axios from "axios";
import { API_GET_ADDRESS_BY_LAT_LNG } from "../../../configs/Endpoints";
import Map from "../../../../../components/Map";

type Props = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
  handleChange: any;
  setFieldValue: any;
};

const Address = ({
  isSubmitting,
  setFieldValue,
  values,
  errors,
  touched,
  handleChange,
}: Props) => {
  const { translations } = useContext(LocalizationContext);

  const { ref }: any = usePlacesWidget({
    onPlaceSelected: (place) => {
      onPlaceChanged(place);
    },
    options: {
      types: ["establishment"],
      fields: ["place_id", "geometry", "name"],
    },
  });

  const onPlaceChanged = async (place: any) => {
    if (!place.geometry) {
      return;
    }
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    setFieldValue("lat", lat);
    setFieldValue("lng", lng);
    setFieldValue("address", ref?.current?.value);
  };

  const _markerOnDragEnd = async (e: any) => {
    try {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      axios.get(API_GET_ADDRESS_BY_LAT_LNG({ lat, lng })).then((response) => {
        const results = response?.data?.results;
        const address = results[0].formatted_address;
        setFieldValue("address", address);
        setFieldValue("lat", lat);
        setFieldValue("lng", lng);
      });
    } catch (e) {
      console.log("error: ", e);
    }
  };

  return (
    <>
      <InputCustom
        label={translations.addressDisplayNameStreetNumberStreetName}
        value={values.address_street_name}
        onChange={handleChange}
        touched={touched.address_street_name}
        error={errors.address_street_name}
        name={"address_street_name"}
        labelRequired
        disabled={isSubmitting}
      />
      <InputCustom
        label={translations.buildingName}
        labelRequired={true}
        value={values.address_building}
        onChange={handleChange}
        touched={touched.address_building}
        error={errors.address_building}
        name={"address_building"}
        disabled={isSubmitting}
      />
      <div className={"form-row"}>
        <InputCustom
          label={translations.floor}
          labelRequired={true}
          value={values.address_floor}
          onChange={handleChange}
          touched={touched.address_floor}
          error={errors.address_floor}
          name={"address_floor"}
          disabled={isSubmitting}
        />
        <InputCustom
          label={translations.unit}
          value={values.address_unit}
          onChange={handleChange}
          touched={touched.address_unit}
          error={errors.address_unit}
          name={"address_unit"}
          disabled={isSubmitting}
        />
      </div>
      <div className={"form-row"}>
        <InputCustom
          label={translations.settings.postalCode}
          value={values.address_postal_code}
          onChange={handleChange}
          touched={touched.address_postal_code}
          error={errors.address_postal_code}
          name={"address_postal_code"}
          disabled={isSubmitting}
        />
        <InputCustom
          label={translations.settings.city}
          value={values.address_city}
          onChange={handleChange}
          touched={touched.address_city}
          error={errors.address_city}
          name={"address_city"}
          disabled={isSubmitting}
        />
      </div>
      <Label
        fontSize={"12px"}
        label={translations.addressForMapPin}
        className={"sm"}
        required
      />

      <div className="input-component">
        <div className={"input-wrapper"} style={{ height: 44 }}>
          <div className={"ui input"} style={{ height: "100%" }}>
            <input
              placeholder={"Type office address"}
              ref={ref}
              value={values.address}
              onChange={({ target }) => {
                setFieldValue("address", target.value);
              }}
            />
          </div>
        </div>
        {touched.address && errors.address ? (
          <>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*@ts-ignore*/}
            <span className={"error"}>{errors.address}</span>
            <SizeBox height={"10px"} />
          </>
        ) : null}
      </div>
      <Label fontSize={"12px"} label={"Map"} className={"sm"} />
      <Map
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        /*// @ts-ignore*/
        lat={values.lat}
        lng={values.lng}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div className={"gg-map"} />}
        mapElement={<div style={{ height: `100%` }} />}
        draggable
        onDragEnd={_markerOnDragEnd}
      />
      <SizeBox height={"30px"} />
    </>
  );
};

export default Address;
