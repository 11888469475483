import React, { useState, useEffect } from "react";
import TableCustom from "../../../../../components/Table";
import PropertyRow from "./PropertyRow";
import { useSelector } from "react-redux";
import { propertyListSelector } from "../../../selectors/PropertySelector";

const PropertyListTable = ({
  page,
  onChangePagination,
  setRefreshList,
  refreshList,
}: any) => {
  const propertyList = useSelector(propertyListSelector);
  const [propertyTable, setPropertyTable] = useState([]);

  useEffect(() => {
    if (propertyList && propertyList.properties) {
      const data = propertyList.properties.map((property: any) => {
        return {
          col1: (
            <PropertyRow
              property={property}
              setRefreshList={setRefreshList}
              refreshList={refreshList}
            />
          ),
        };
      });
      setPropertyTable(data);
    } else {
      setPropertyTable([]);
    }
  }, [propertyList]);

  return (
    <div>
      <TableCustom
        noHeader={true}
        showTotal
        tableData={propertyTable}
        columns={[{ key: "name", col: "Property" }]}
        currentPage={page}
        totalItems={propertyList?.pagination?.total}
        unit={""}
        onChangePagination={onChangePagination}
      />
    </div>
  );
};

export default PropertyListTable;
