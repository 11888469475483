import React, { useContext, useState } from "react";
import Label from "../../../../../components/Label";
import { useSelector } from "react-redux";
import { businessCompanyDetailSelector } from "../../../selectors/BusinessSelectors";
import { BusinessCompanyDetailType } from "../../../configs/DeclareTypes";
import Information from "./Information";
import { LocalizationContext } from "../../../../../locales/Translation";
import { Link, useNavigate } from "react-router-dom";
import { BUSINESS_COMPANY_EDIT_LINK } from "../../../../App/configs/ListRoutes";
import SizeBox from "../../../../../components/SizeBox";
import ButtonCustom from "../../../../../components/Button";
import moment from "moment-timezone";

const GeneralInfo = () => {
  const { translations } = useContext(LocalizationContext);
  const businessCompanyDetail: BusinessCompanyDetailType = useSelector(
    businessCompanyDetailSelector
  );
  const navigate = useNavigate();

  return (
    <>
      <div className={"company-detail__title"}>
        <div className="section-white-box_title">
          {translations.companyDetails}
        </div>
        <ButtonCustom
          height={"30px"}
          onClick={() => {
            navigate(
              BUSINESS_COMPANY_EDIT_LINK.replace(
                ":id",
                String(businessCompanyDetail.company_id)
              ),
              { replace: true }
            );
          }}
          className={"edit-link reverse"}
          padding={"0"}
          fontSize={"16px"}
        >
          <>
            {translations.edit} <i className="uil uil-edit" />
          </>
        </ButtonCustom>
      </div>
      <div className="section-white-box-column">
        <div>
          <Label
            fontSize={"14px"}
            label={"Contact Info"}
            className={"company-detail__label"}
          />
          <Information
            label={translations.name}
            value={businessCompanyDetail.contact_name}
          />
          <Information
            label={translations.companyName}
            value={businessCompanyDetail.company_name}
          />
          <Information
            label={translations.email}
            value={businessCompanyDetail.contact_email}
          />
          <Information
            label={translations.settings.phone}
            value={businessCompanyDetail.contact_phone_with_dialing_code}
          />
          <Information
            label={translations.settings.address}
            value={businessCompanyDetail.contact_address}
          />

          {businessCompanyDetail.tag_ids ? (
            <>
              <SizeBox height={"22px"} />
              <Label
                fontSize={"14px"}
                label={translations?.companyCanAccess}
                className={"company-detail__label"}
              />
              <Information
                label={
                  businessCompanyDetail.tag_ids
                    ? businessCompanyDetail.tag_ids
                        .map(({ name }) => name)
                        .join(", ")
                    : ""
                }
                value={""}
              />
            </>
          ) : null}

          {businessCompanyDetail?.company_affiliate?.is_active ? (
            <Information
              label={"Affiliate Commission"}
              value={businessCompanyDetail?.company_affiliate?.commission + "%"}
            />
          ) : null}
        </div>
        <div>
          <Label
            fontSize={"14px"}
            label={translations.paymentTerms}
            className={"company-detail__label"}
          />
          <Information
            label={translations.paymentTerms}
            value={`${businessCompanyDetail.payment_term} ${
              businessCompanyDetail.payment_term > 1 ? " days" : " day"
            }`}
          />
          <Information
            label={translations.customPackages.currencyLabel}
            value={businessCompanyDetail.currency}
          />

          {businessCompanyDetail?.credit_expired_date ? (
            <>
              <Label
                fontSize={"14px"}
                label={`Credit balance for ${businessCompanyDetail?.company_name}`}
                className={"company-detail__label mb-8px"}
              />
              <div className={"company-credit-remaining-wrapper"}>
                <div className={"company-credit-remaining-row"}>
                  <span className={"middle-dot"}>·</span>
                  <span className={"company-credit-remaining-item__title"}>
                    Remaining credit:
                  </span>
                  <span className={"company-credit-remaining-item__value"}>
                    {businessCompanyDetail?.remaining_credit}
                  </span>
                </div>

                <div className={"company-credit-remaining-row"}>
                  <span className={"middle-dot"}>·</span>
                  <span className={"company-credit-remaining-item__title"}>
                    Credit expiry date:
                  </span>
                  <span className={"company-credit-remaining-item__value"}>
                    {moment(businessCompanyDetail?.credit_expired_date * 1000)
                      .tz(businessCompanyDetail?.company_timezone)
                      .format("DD/MM/YYYY")}
                  </span>
                </div>
              </div>
            </>
          ) : null}

          <Information
            label={translations.monthlySaaSFee}
            value={
              businessCompanyDetail?.has_saas_fee
                ? businessCompanyDetail?.total_saas_fee_format?.toString() || ""
                : " - "
            }
          />
        </div>
      </div>
      <div className={"section-white-box_line"} />
    </>
  );
};

export default GeneralInfo;
