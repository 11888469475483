import clientRequestService from "../../../services/ClientRequestService";
import {
  API_ADD_PAYMENT_VIA_CUSTOMER_REF,
  API_ALL_BUSINESS_COMPANY_LIST,
  API_AUTOCOMPLETE_AFFILIATE_COMPANY,
  API_BUSINESS_COMPANY,
  API_BUSINESS_COMPANY_AUTO_TOPUP_INFO,
  API_BUSINESS_COMPANY_CAN_BUY_CREDIT,
  API_BUSINESS_COMPANY_DELETE,
  API_BUSINESS_COMPANY_DETAIL,
  API_BUSINESS_COMPANY_LIST,
  API_BUSINESS_COMPANY_POST_PAY,
  API_CREDIT_PACKAGE_AMOUNT_INFO,
  API_GET_PAYMENT_VIA_CUSTOMER_REF,
  BUSINESS_COMPANY_CREDIT_PACKAGE_LIST_API,
  BUSINESS_CUSTOM_PACKAGE_DETAIL_API,
  BUSINESS_INVOICE_DELETE_API,
  BUSINESS_INVOICE_POST_PAID_API,
  BUSINESS_INVOICE_REPAY_API,
  CANCEL_CREDIT_PACKAGE_API,
  COMPANY_LIST_TYPE_BUSINESS,
  CREDIT_CHANGE_EXPIRY_DATE_API,
  CREDIT_HISTORY_API,
  CREDIT_PAY_CC_API,
  CREDIT_USAGE_API,
  DEACTIVATE_BUSINESS_COMPANY_USER_API,
  DELETE_BUSINESS_COMPANY_USER_API,
  DELETE_BUSINESS_PACKAGE_API,
  DELETE_INVITATION,
  DESKIMO_ADMIN_DELETE_INVITATION,
  DISABLE_PACKAGE_ROLL_OVER_API,
  EDIT_BUSINESS_COMPANY_STAFF_API,
  GET_BUSINESS_INVOICE_API,
  OFFICE_LEASES_CONTRACT_API,
  OFFICE_LEASES_CONTRACT_CHECK_API,
  OFFICE_LEASES_CONTRACT_LIST_API,
  OFFICE_LEASES_DEPOSIT_API,
  OFFICE_LEASES_DETAIL_API,
  OFFICE_LEASES_END_API,
  OFFICE_LEASES_REFUND_API,
  POST_BUSINESS_CUSTOM_PACKAGE_LIST_API,
  POST_CREATE_BUSINESS_PACKAGE_API,
  POST_DELETE_MODIFY_CUSTOM_FEE_API,
  POST_GENERATE_BUSINESS_INVOICE_API,
  PURCHASE_CUSTOM_PACKAGE_API,
  RECORD_CREDIT_PAYMENT_API,
  REINVITE_BUSINESS_COMPANY_USER_API,
  RESEND_BUSINESS_INVOICE_API,
  STOP_AUT_TOP_UP_API,
  UPDATE_BUSINESS_COMPANY_USER_API,
} from "../configs/Endpoints";
import {
  METHOD_DELETE,
  METHOD_POST,
  METHOD_PUT,
} from "../../../configs/Constants";
import {
  API_EMPLOYEE_EDIT_INLINE,
  API_EMPLOYEES,
  API_PENDING_INVITATION_DESKIMO_ADMIN,
} from "../../User/configs/Endpoints";
import { POST_GET_PAYMENT_HISTORY_API } from "../../Setting/configs/Endpoints";
import { API_UPLOAD_ASSET } from "../../../configs/Endpoints";

export const getBusinessCompanyListService = async (data: object) => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_BUSINESS_COMPANY_LIST,
    data,
  });
};

export const businessCompanyPostPayService = async (companyId: number) => {
  return clientRequestService({
    method: METHOD_POST,
    url: API_BUSINESS_COMPANY_POST_PAY.replaceAll(
      "%companyId%",
      String(companyId)
    ),
  });
};

export const businessCompanyCanBuyCreditService = async (
  companyId: number,
  data: object
) => {
  return clientRequestService({
    method: METHOD_PUT,
    url: API_BUSINESS_COMPANY_CAN_BUY_CREDIT.replaceAll(
      "%companyId%",
      String(companyId)
    ),
    data,
  });
};

export const businessCompanyDeleteService = async (
  companyId: number,
  data?: object
) => {
  return clientRequestService({
    method: METHOD_DELETE,
    url: API_BUSINESS_COMPANY_DELETE.replaceAll(
      "%companyId%",
      String(companyId)
    ),
    data,
  });
};

export const businessCompanyDetailService = async (companyId: string) => {
  return clientRequestService({
    url: API_BUSINESS_COMPANY_DETAIL.replaceAll("%companyId%", companyId),
  });
};

export const businessCompanyStaffService = async (data: object) => {
  return clientRequestService({
    method: "post",
    url: API_EMPLOYEES,
    data,
  });
};

export const businessCompanyPendingInvitationDeskimoAdminService = async (
  data: object
) => {
  return clientRequestService({
    method: "post",
    url: API_PENDING_INVITATION_DESKIMO_ADMIN,
    data,
  });
};

export const businessCompanyStaffEditService = async (payload: string) => {
  return clientRequestService({
    method: "POST",
    url: EDIT_BUSINESS_COMPANY_STAFF_API,
    data: payload,
  });
};

export const businessCompanyStaffDeleteService = async (
  invitationId: number
) => {
  return clientRequestService({
    method: "GET",
    url: DELETE_BUSINESS_COMPANY_USER_API.replaceAll(
      "%id%",
      String(invitationId)
    ),
  });
};

export const businessCompanyStaffReinviteService = async (
  invitationId: number
) => {
  return clientRequestService({
    method: "GET",
    url: REINVITE_BUSINESS_COMPANY_USER_API.replaceAll(
      "%id%",
      String(invitationId)
    ),
  });
};

export const businessCompanyStaffMakeAdminService = async (data: object) => {
  return clientRequestService({
    method: "POST",
    url: API_EMPLOYEE_EDIT_INLINE,
    data,
  });
};

export const businessCompanyStaffDeactivateService = async (
  invitationId: number
) => {
  return clientRequestService({
    method: "GET",
    url: DEACTIVATE_BUSINESS_COMPANY_USER_API.replaceAll(
      "%id%",
      String(invitationId)
    ),
  });
};

export const businessCompanyStaffUpdateService = async (payload: any) => {
  return clientRequestService({
    method: "POST",
    url: UPDATE_BUSINESS_COMPANY_USER_API,
    data: payload,
  });
};

export const businessCompanyInvoiceService = async (data: object) => {
  return clientRequestService({
    method: "post",
    url: POST_GET_PAYMENT_HISTORY_API,
    data,
  });
};

export const postPaidBusinessInvoiceService = async (
  token: string,
  data: object
) => {
  return await clientRequestService({
    method: "post",
    url: BUSINESS_INVOICE_POST_PAID_API.replaceAll("%token%", token),
    data,
  });
};

export const deleteBusinessInvoiceService = async (token: string) => {
  return await clientRequestService({
    method: "delete",
    url: BUSINESS_INVOICE_DELETE_API.replaceAll("%token%", token),
  });
};

export const businessCompanyCustomPackageService = async (data: object) => {
  return await clientRequestService({
    method: "POST",
    url: POST_BUSINESS_CUSTOM_PACKAGE_LIST_API,
    data,
  });
};

export const businessCompanyPackageCancelService = async (
  packageType: number,
  packageId: number
) => {
  return await clientRequestService({
    method: "DELETE",
    url: DELETE_BUSINESS_PACKAGE_API.replaceAll(
      ":packageType/:packageId",
      `${packageType}/${packageId}`
    ),
  });
};

export const businessCompanyPackageDetailService = async (
  packageId: string
) => {
  return await clientRequestService({
    url: BUSINESS_CUSTOM_PACKAGE_DETAIL_API.replaceAll(":packageId", packageId),
  });
};

export const businessCompanyPackageCreateService = async (payload: object) => {
  return await clientRequestService({
    method: "POST",
    url: POST_CREATE_BUSINESS_PACKAGE_API,
    data: payload,
  });
};

export const getBusinessInvoiceDetailService = async (
  companyId: any,
  token: any
) => {
  return await clientRequestService({
    method: "get",
    url: GET_BUSINESS_INVOICE_API.replaceAll(
      ":companyId",
      companyId
    ).replaceAll(":token", token),
  });
};

export const generateBusinessInvoiceService = async (data: object) => {
  return await clientRequestService({
    method: "POST",
    url: POST_GENERATE_BUSINESS_INVOICE_API,
    data,
  });
};

export const getBusinessCompaniesListService = async () => {
  return await clientRequestService({
    method: "GET",
    url: API_ALL_BUSINESS_COMPANY_LIST,
  });
};

export const addBusinessInvoiceCustomFeeService = async (
  data: any,
  token: string | undefined,
  companyId: string | undefined
) => {
  return await clientRequestService({
    method: "post",
    url: POST_DELETE_MODIFY_CUSTOM_FEE_API.replaceAll(
      "%token%",
      String(token)
    ).replaceAll("%companyId%", String(companyId)),
    data,
  });
};

export const deleteBusinessInvoiceCustomFeeService = async (
  data: any,
  token: string | undefined,
  companyId: string
) => {
  return await clientRequestService({
    method: "delete",
    url: POST_DELETE_MODIFY_CUSTOM_FEE_API.replaceAll(
      "%token%",
      String(token)
    ).replaceAll("%companyId%", companyId),
    data,
  });
};

export const companyUpdateCreateService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: API_BUSINESS_COMPANY,
    data,
  });
};

export const officeLeasesContractService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: OFFICE_LEASES_CONTRACT_API,
    data,
  });
};

export const officeLeaseUpdateService = async (data: object) => {
  return await clientRequestService({
    method: "put",
    url: OFFICE_LEASES_CONTRACT_LIST_API,
    data,
  });
};

export const officeLeasesContractListService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: OFFICE_LEASES_CONTRACT_LIST_API,
    data,
  });
};

export const officeLeaseDetailService = async (id: number) => {
  return await clientRequestService({
    url: OFFICE_LEASES_DETAIL_API.replaceAll("%id%", String(id)),
  });
};
export const officeLeaseDepositService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: OFFICE_LEASES_DEPOSIT_API,
    data,
  });
};

export const officeLeaseRefundService = async (data: object) => {
  return await clientRequestService({
    method: "put",
    url: OFFICE_LEASES_REFUND_API,
    data,
  });
};

export const officeLeaseEndService = async (id: number) => {
  return await clientRequestService({
    method: "put",
    url: OFFICE_LEASES_END_API.replaceAll("%id%", String(id)),
  });
};

export const officeLeaseSummaryService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: OFFICE_LEASES_CONTRACT_CHECK_API,
    data,
  });
};

export const uploadAssetService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    isFile: true,
    url: API_UPLOAD_ASSET,
    data,
  });
};

export const creditHistoryService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: CREDIT_HISTORY_API,
    data,
  });
};

export const creditUsageService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: CREDIT_USAGE_API,
    data,
  });
};

export const cancelCreditPackageService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: CANCEL_CREDIT_PACKAGE_API,
    data,
  });
};

export const disablePackageRollOverService = async (transactionId: string) => {
  return await clientRequestService({
    method: "post",
    url: DISABLE_PACKAGE_ROLL_OVER_API.replaceAll(
      "%transactionId%",
      transactionId
    ),
  });
};

export const stopAutoTopUpService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: STOP_AUT_TOP_UP_API,
    data,
  });
};
export const creditPayCCService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: CREDIT_PAY_CC_API,
    data,
  });
};

export const creditChangeExpiryDateService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: CREDIT_CHANGE_EXPIRY_DATE_API,
    data,
  });
};

export const creditRecordPaymentService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: RECORD_CREDIT_PAYMENT_API,
    data,
  });
};

export const businessCompanyCreditService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: BUSINESS_COMPANY_CREDIT_PACKAGE_LIST_API,
    data,
  });
};

export const purchaseCustomPackageService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: PURCHASE_CUSTOM_PACKAGE_API,
    data,
  });
};

export const businessInvoiceRepayService = async (token: string) => {
  return await clientRequestService({
    method: "post",
    url: BUSINESS_INVOICE_REPAY_API.replaceAll("%token%", token),
  });
};

export const resendBusinessInvoiceService = async (token: string) => {
  return await clientRequestService({
    method: "post",
    url: RESEND_BUSINESS_INVOICE_API.replaceAll("%token%", token),
  });
};

export const businessAutoTopUpInfoService = async (companyId: string) => {
  return await clientRequestService({
    method: "get",
    url: API_BUSINESS_COMPANY_AUTO_TOPUP_INFO.replaceAll(
      "%companyId%",
      companyId
    ),
  });
};

export const updateBusinessAutoTopUpInfoService = async (
  companyId: string,
  data: object
) => {
  return await clientRequestService({
    method: "put",
    url: API_BUSINESS_COMPANY_AUTO_TOPUP_INFO.replaceAll(
      "%companyId%",
      companyId
    ),
    data,
  });
};

export const creditPackageAmountInfoService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: API_CREDIT_PACKAGE_AMOUNT_INFO,
    data,
  });
};

export const getCompanyByKeywordService = async (companyFilter: any) => {
  let params = { ...companyFilter };
  params.show_deleted = params.show_deleted ? "1" : "0";
  delete params.keyword;
  params = new URLSearchParams(params).toString();

  return await clientRequestService({
    method: "get",
    url:
      COMPANY_LIST_TYPE_BUSINESS.replaceAll(
        "/%keyword%",
        companyFilter?.keyword?.trim().length > 0
          ? "/" + companyFilter?.keyword?.trim()
          : ""
      ) + `?${params}`,
  });
};

export const deskimoAdminDeleteInvitationService = async (
  invitationId: string
) => {
  return await clientRequestService({
    method: "delete",
    url: DESKIMO_ADMIN_DELETE_INVITATION.replaceAll(
      "%pendingInvitationId%",
      invitationId
    ),
  });
};

export const deleteInvitationService = async (
  invitationId: string,
  companyId: string
) => {
  return await clientRequestService({
    method: "delete",
    url: DELETE_INVITATION.replaceAll(
      "%pendingInvitationId%",
      invitationId
    ).replaceAll("%companyId%", companyId),
  });
};

export const getPaymentViaCustomerRefService = async (customerRef: string) => {
  return await clientRequestService({
    url: API_GET_PAYMENT_VIA_CUSTOMER_REF.replaceAll(
      "%customerRef%",
      customerRef
    ),
  });
};

export const addPaymentViaCustomerRefService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: API_ADD_PAYMENT_VIA_CUSTOMER_REF,
    data,
  });
};

export const autoCompleteAffiliateService = async (data: object) => {
  return await clientRequestService({
    method: "post",
    url: API_AUTOCOMPLETE_AFFILIATE_COMPANY,
    data,
  });
};
