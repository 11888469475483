import React from "react";
import "./style.scss";
import { Radio as RadioSemantic, RadioProps } from "semantic-ui-react";

interface Props extends RadioProps {
  currentValue?: any;
  value?: any;
  [x: string]: any;
}

const Radio = ({ currentValue, value, ...props }: Props) => {
  return (
    <RadioSemantic checked={currentValue === value} value={value} {...props} />
  );
};

export default Radio;
