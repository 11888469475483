import React from "react";
import Revenue from "./components/Revenure";
import Detail from "./components/Detail";
import ActivityLog from "./components/ActivityLog";
import { useSelector } from "react-redux";
import { dashboardStatisticBookingPropertySelector } from "../../../selectors/DashboardSelectors";

const TabMeetingRooms = () => {
  const dashboardStatisticProperty = useSelector(
    dashboardStatisticBookingPropertySelector
  );

  return (
    <div className={"dashboard_chart_wrapper"}>
      <Revenue data={dashboardStatisticProperty} />
      <Detail data={dashboardStatisticProperty} type={"meeting-room"} />
      <ActivityLog />
    </div>
  );
};

export default TabMeetingRooms;
