import React, { useContext } from "react";
import ModalRightSideBarTemplate from "../../../../App/views/ModalRightSideBarTemplate";
import { Formik, FormikValues } from "formik";
import * as yup from "yup";
import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../components/Button";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import InputCustom from "../../../../../components/Input";
import { LocalizationContext } from "../../../../../locales/Translation";
import { createTagService } from "../../../services/ConfigServices";
import { toastError, toastSuccess } from "../../../../../components/Toast";

type Props = {
  open: boolean;
  onClose: any;
  setOnRefresh: any;
};
const ModalAddTag = ({ open, onClose, setOnRefresh }: Props) => {
  const { translations } = useContext(LocalizationContext);

  const _handleSave = async (values: FormikValues) => {
    try {
      await createTagService(values);
      setOnRefresh();
      onClose();
      toastSuccess(translations.createSuccessPrompt);
    } catch (e: any) {
      if (e?.status?.message) {
        toastError(e?.status?.message);
      } else {
        toastError(translations.createFailPrompt);
      }
    }
  };

  return (
    <ModalRightSideBarTemplate open={open} onClose={onClose}>
      <Formik
        enableReinitialize
        initialValues={{
          name: "",
        }}
        validationSchema={yup.object().shape({
          name: yup.string().required(),
        })}
        onSubmit={_handleSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => (
          <>
            <Modal.Header>
              <div>{"Create Tag"}</div>
              <ButtonCustom
                onClick={onClose}
                className={"m-0 p-0 none-bg close-modal"}
              >
                <>
                  {translations.settings.close}
                  <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
                </>
              </ButtonCustom>
            </Modal.Header>
            <Modal.Content className={"scroll"}>
              <InputCustom
                label={"Tag name"}
                value={values.name}
                onChange={handleChange}
                touched={touched.name}
                error={errors.name}
                name={"name"}
              />
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom
                height={"47px"}
                onClick={() => {
                  resetForm();
                  onClose();
                }}
              >
                {translations.settings.cancel}
              </ButtonCustom>
              <ButtonCustom
                loading={isSubmitting}
                type={"submit"}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {translations.settings.save}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalRightSideBarTemplate>
  );
};

export default ModalAddTag;
