import TableCustom from "../../../../../../components/Table";
import React, { useContext } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import Action from "./Action";
import {
  campaignFilterSelector,
  campaignListSelector,
} from "../../../../selectors/MarketingSelectors";
import { CampaignItemType } from "../../../../configs/DeclareTypes";
import MarketFilterMultiple from "../../../../../../components/FilterMarket/MarketFilterMultiple";
import FilterStatus from "../FilterStatus";
import { campaignFilterAction } from "../../../../actions/MarketingActions";

type Props = {
  loading: boolean;
  onChangePagination: any;
};

const EmployeeTable = ({ loading, onChangePagination }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const campaignList = useSelector(campaignListSelector);
  const dispatch = useDispatch();
  const campaignFilter = useSelector(campaignFilterSelector);

  const columns = [
    "Campaign name",
    "Internal Description",
    "Displayed Name",
    translations.startTime,
    translations.endTime,
    translations.status,
    translations.action,
  ];

  const _formatInputData = () => {
    console.log("campaignList: ", campaignList);
    if (!campaignList?.items) {
      return [];
    }
    return campaignList?.items?.map(
      ({
        displayed_name,
        id,
        name,
        end_time,
        start_time,
        description,
        status,
        status_name,
      }: CampaignItemType) => {
        return {
          name,
          description,
          displayed_name,
          start_time,
          end_time,
          status: status_name,
          action: <Action status={status} id={id} />,
        };
      }
    );
  };

  const _onChangeMarketFilter = (values: string[]) => {
    dispatch(campaignFilterAction({ market_codes: values, page: 1 }));
  };

  const _onChangeStatusFilter = (value: string) => {
    dispatch(
      campaignFilterAction({
        filters: {
          ...campaignFilter.filters,
          status: value === "4" ? null : value,
        },
        page: 1,
      })
    );
  };

  return (
    <div
      className={"employee-table"}
      style={campaignList?.items?.length < 3 ? { paddingBottom: 100 } : {}}
    >
      <TableCustom
        loading={loading}
        showTotal
        tableData={campaignList ? _formatInputData() : []}
        columns={columns}
        currentPage={campaignList?.pagination?.page}
        totalItems={campaignList?.pagination?.total}
        onChangePagination={onChangePagination}
        topRightComponent={
          <>
            <FilterStatus
              status={campaignFilter.filters.status}
              onChange={_onChangeStatusFilter}
            />
            <MarketFilterMultiple
              isSubmitting={loading}
              onChange={_onChangeMarketFilter}
              values={campaignFilter.market_codes}
            />
          </>
        }
      />
    </div>
  );
};

export default EmployeeTable;
