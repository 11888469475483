import TableCustom from "../../../../../../../components/Table";
import Button from "../../../../../../../components/Button";
import React, { useState } from "react";
import QRCodeActions from "./QRCodeActions";
import { PUBLIC_URL } from "../../../../../../App/configs/Constants";
import ModalCreateUpdateQrCode from "./ModalCreateUpdateQrCode";
import { QRCodeCanvas } from "qrcode.react";
import { useDispatch, useSelector } from "react-redux";
import { propertyIntegrationsQRCodeListSelector } from "../../../../../selectors/PropertySelector";
import moment from "moment";
import QRCodeStatus from "./QRCodeStatus";
import {
  propertyIntegrationsChangeStatusQRCodeService,
  propertyIntegrationsRemoveQRCodeService,
} from "../../../../../services/PropertyIntegrationServices";
import { useParams } from "react-router-dom";
import { SWAL_DELETE_OPTION } from "../../../../../../../components/SweetAlert/configs/SwalConfigs";
import { SweetAlertCommon } from "../../../../../../../components/SweetAlert";
import { toastSuccess } from "../../../../../../../components/Toast";
import { getPropertyIntegrationsQRCodeListThunk } from "../../../../../thunks/PropertyThunks";

const QRCodeSection = () => {
  const listQRCode = useSelector(propertyIntegrationsQRCodeListSelector);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState<any>({
    open: false,
    data: null,
  });
  const [loading, setLoading] = useState(false);

  const _onChangeStatus = async (qrcodeId: any, status: any) => {
    try {
      if (!id) return;
      const icon = null;
      const title = `Are you sure you want to ${
        status > 0 ? "Activate" : "Deactivate"
      } this QR Code?`;
      const confirmText = "Confirm";
      const confirmClass = status > 0 ? "primary" : "danger";
      const options = SWAL_DELETE_OPTION({
        title,
        icon,
        confirmText,
        confirmClass,
      });
      SweetAlertCommon({
        options,
        confirmCallback: async () => {
          setLoading(true);
          await propertyIntegrationsChangeStatusQRCodeService(id, qrcodeId, {
            status,
          });

          await dispatch<any>(getPropertyIntegrationsQRCodeListThunk(id));
          setLoading(false);
          toastSuccess(
            status ? "Successfully Activated" : "Successfully Deactivated"
          );
        },
        cancelCallback: () => null,
      });
    } catch (error) {
      console.log("Error change status: ", error);
    }
  };

  const _onDelete = async (qrcodeId: any) => {
    try {
      if (!id) return;
      const iconHtml = (
        <img
          alt="error_icon"
          src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
          className={"swal2-icon__error"}
        />
      );
      const title = `Are you sure you want to delete this QR Code?`;
      const confirmText = "Delete";
      const confirmClass = "danger";
      const options = SWAL_DELETE_OPTION({
        title,
        iconHtml,
        confirmText,
        confirmClass,
      });
      SweetAlertCommon({
        options,
        confirmCallback: async () => {
          setLoading(true);
          await propertyIntegrationsRemoveQRCodeService(id, qrcodeId);
          await dispatch<any>(getPropertyIntegrationsQRCodeListThunk(id));
          setLoading(false);
          toastSuccess("Successfully Removed");
        },
        cancelCallback: () => null,
      });
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const decorateTableData = () => {
    if (listQRCode.length > 0) {
      return listQRCode.map((item: any, index: number) => {
        return {
          col1: (
            <div className={"qrcode-item-cell"}>
              {item?.qrcode_token ? (
                <QRCodeCanvas
                  size={100}
                  value={item?.qrcode_token}
                  imageSettings={{
                    src: PUBLIC_URL + "/assets/logo-square.svg",
                    x: undefined,
                    y: undefined,
                    height: 24,
                    width: 24,
                    excavate: true,
                  }}
                />
              ) : null}
            </div>
          ),
          col2: (
            <div className={"qrcode-item-cell__text"}>{item?.type_name}</div>
          ),
          col4: item?.expired_date
            ? moment(item?.expired_date * 1000).format("DD/MM/YYYY")
            : "Never expires",
          col3: (
            <QRCodeStatus
              item={item}
              onChangeStatus={_onChangeStatus}
              status={item?.status}
            />
          ),
          col5: (
            <QRCodeActions
              onDelete={_onDelete}
              item={item}
              edit={setOpenModal}
            />
          ),
        };
      });
    }
    return [];
  };

  return (
    <div className={"deskimo-tab-section"}>
      <div className="deskimo-tab-title">
        QR Code
        <Button
          onClick={() => setOpenModal({ open: true, data: null })}
          height={"36px"}
          className={"primary ml-auto mr-0"}
        >
          Create QR Code +
        </Button>
      </div>
      <TableCustom
        loading={loading}
        tableClass={"table-fixed table-border"}
        showTotal={false}
        tableData={decorateTableData()}
        columns={["QR Code", "Type", "Expire Date", "Activation", "Actions"]}
      />
      <ModalCreateUpdateQrCode open={openModal} setOpen={setOpenModal} />
    </div>
  );
};

export default QRCodeSection;
