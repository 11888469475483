import APIItem from "../../../../Property/views/PropertyDetail/Components/IntegrationsTab/Components/APIItem";
import { useSelector } from "react-redux";
import MiddleDot from "../../../../../components/MiddleDot";
import { partnerCredentialInfoSelector } from "../../../selectors/ConfigSelectors";

const APISection = () => {
  const apiData = useSelector(partnerCredentialInfoSelector);

  return (
    <div className={"deskimo-tab-section"}>
      <div className="deskimo-tab-title deskimo-tab-title-link">
        <span>API</span>
        <MiddleDot size={"4px"} />
        <a
          target={"_blank"}
          href={"https://www.deskimo.com/api-documentation/"}
          rel="noreferrer"
        >
          Check the documentation
        </a>
      </div>
      <div className={"api-section-wrapper"}>
        <APIItem
          text={apiData?.app_client}
          label={"Client App"}
          allowHide={false}
        />
        <APIItem text={apiData?.app_secret} label={"Client Secret"} />
      </div>
    </div>
  );
};

export default APISection;
