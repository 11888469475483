import React, { useContext, useState } from "react";
import SizeBox from "../../../../../components/SizeBox";
import ButtonCustom from "../../../../../components/Button";
import { PUBLIC_URL } from "../../../../App/configs/Constants";
import { useDispatch, useSelector } from "react-redux";
import { dashboardPropertyChooseSelector } from "../../../selectors/DashboardSelectors";
import { DASHBOARD_PROPERTY_TYPE } from "../../../configs/DeclareTypes";
import { propertyStatusService } from "../../../services/DashboardServices";
import { toastError } from "../../../../../components/Toast";
import { propertyStatusAction } from "../../../actions/DashboardActions";
import ModalCloseSchedule from "./Modal/ModalCloseSchedule";
import PropertyScanner from "./PropertyScanner";
import { LocalizationContext } from "../../../../../locales/Translation";

const PropertyStatus = () => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const dashboardPropertyChoose: DASHBOARD_PROPERTY_TYPE = useSelector(
    dashboardPropertyChooseSelector
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [openCloseScheduleModal, setOpenCloseScheduleModal] =
    useState<boolean>(false);

  const _onOpenCloseProperty = async () => {
    try {
      setLoading(true);
      const property_id = dashboardPropertyChoose?.property_id;
      const payload = {
        property_id,
        is_open: dashboardPropertyChoose?.is_open ? 0 : 1,
      };
      await propertyStatusService(payload);
      dispatch<any>(propertyStatusAction(property_id));
    } catch (e: any) {
      toastError(e?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <SizeBox height={"27px"} />
        <div className={"dashboard_property_status_box"}>
          <h2 className={"dashboard_chart_title"}>
            {translations.propertyStatus}
          </h2>
          <SizeBox height={"12px"} />
          <div
            className={
              dashboardPropertyChoose?.is_open
                ? "dashboard_property_status"
                : "dashboard_property_status_close"
            }
          >
            •{" "}
            {dashboardPropertyChoose?.is_open
              ? translations.open
              : translations.closed}
          </div>
          <SizeBox height={"12px"} />
          <ButtonCustom
            loading={loading}
            className={"dashboard_property_close_btn"}
            onClick={_onOpenCloseProperty}
          >
            {dashboardPropertyChoose?.is_open
              ? translations.closePropertyForTheDay
              : translations.openProperty}
          </ButtonCustom>
          <SizeBox height={"12px"} />
          <div style={{ textAlign: "center" }}>
            <ButtonCustom
              className={"dashboard_property_review_btn"}
              onClick={() => {
                setOpenCloseScheduleModal(true);
              }}
            >
              <img src={PUBLIC_URL + "/assets/icons/icon-carbon-time.svg"} />
              <span>{translations.scheduleFutureClosures}</span>
            </ButtonCustom>
          </div>
        </div>

        <SizeBox height={"12px"} />
        <PropertyScanner />
      </div>
      <ModalCloseSchedule
        open={openCloseScheduleModal}
        setOpen={setOpenCloseScheduleModal}
      />
    </>
  );
};

export default PropertyStatus;
