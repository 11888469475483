import React, { useContext } from "react";
import Label from "../../../../../components/Label";
import { LocalizationContext } from "../../../../../locales/Translation";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import InputCustom from "../../../../../components/Input";
import CheckboxCustom from "../../../../../components/Checkbox";
import AffiliateCompanySelect from "./AffiliateCompanySelect";
import SizeBox from "../../../../../components/SizeBox";
import { useParams } from "react-router-dom";

type Props = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
  handleChange: any;
  setFieldValue: any;
};

const PaymentTerm = ({
  values,
  errors,
  touched,
  isSubmitting,
  handleChange,
  setFieldValue,
}: Props) => {
  const { translations } = useContext(LocalizationContext);
  const { id } = useParams();

  return (
    <>
      <Label
        label={translations.paymentTerms}
        fontSize={"24px"}
        className={"section-title"}
      />
      <div className={"property_company_detail_highlight"}>
        Invoice is generated monthly (first day of the month)
      </div>
      <div className={"detail_divide"}>
        <div className={"left_section"}>
          <InputCustom
            labelClassName={"label-xl"}
            label={translations.percentageCommission}
            labelRequired
            value={values.percentage_commission}
            error={errors.percentage_commission}
            touched={touched.percentage_commission}
            name={"percentage_commission"}
            onChange={handleChange}
            disabled={isSubmitting}
          />

          <InputCustom
            labelClassName={"label-xl"}
            label={translations.paymentTermsInDays}
            labelRequired
            value={values.payment_term_in_days}
            error={errors.payment_term_in_days}
            touched={touched.payment_term_in_days}
            name={"payment_term_in_days"}
            onChange={handleChange}
            disabled={isSubmitting}
          />

          <InputCustom
            labelClassName={"label-xl"}
            label={translations.minimumDeskimoCommission}
            labelRequired
            value={values.minimum_des_commisson}
            error={errors.minimum_des_commisson}
            touched={touched.minimum_des_commisson}
            name={"minimum_des_commisson"}
            onChange={handleChange}
            disabled={isSubmitting}
            inputLeftIcon={values.currency}
            inputLeftIconBorder
          />

          {id ? null : (
            <>
              <Label label={"Affiliate company"} className={"label-xl"} />
              <AffiliateCompanySelect
                value={values.affiliate_reference}
                onChange={(value) =>
                  setFieldValue("affiliate_reference", value)
                }
              />
              <SizeBox height={40} />
            </>
          )}
        </div>
        <div className={"right_section"}>
          <InputCustom
            labelClassName={"label-xl"}
            label={translations.monthlyPaymentProcessingFee}
            labelRequired
            value={values.monthly_payment_processing_fee}
            error={errors.monthly_payment_processing_fee}
            touched={touched.monthly_payment_processing_fee}
            name={"monthly_payment_processing_fee"}
            onChange={handleChange}
            disabled={isSubmitting}
          />

          <InputCustom
            labelClassName={"label-xl"}
            label={translations.meetingRoomPercentageCommission}
            labelRequired
            value={values.room_percentage_commission}
            error={errors.room_percentage_commission}
            touched={touched.room_percentage_commission}
            name={"room_percentage_commission"}
            onChange={handleChange}
            disabled={isSubmitting}
          />

          <CheckboxCustom
            label={translations.VAT_GSTRegistered}
            checked={values.is_gst}
            onChange={() => {
              setFieldValue("is_gst", !values.is_gst);
            }}
          />
        </div>
      </div>

      <div className={"property_company_detail_line"} />
    </>
  );
};

export default PaymentTerm;
