import React, { useEffect, useState } from "react";
import MasterTemplate from "../../../App/views/MasterTemplate";
import { ROLE_DESKIMO_ADMIN_ID } from "../../../User/configs/Constants";
import SectionWhiteBox from "../../../../components/SectionWhiteBox";
import "./styles.scss";
import GeneralInfo from "./Components/GeneralInfo";
import { useDispatch } from "react-redux";
import { businessCompanyDetailThunk } from "../../thunks/BusinessThunks";
import { useParams } from "react-router-dom";
import LoaderCustom from "../../../../components/Loader";
import OnboardPromotion from "./Components/OnboardPromotion";
import WMSConfiguration from "./Components/WMSConfiguration";
import Contract from "./Components/Contract";
import CreditCard from "./Components/CreditCard";
import Revenue from "./Components/Revenue";
import BusinessPaymentMethodConfiguration from "./Components/BusinessPaymentMethodConfiguration";

const CompanyDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        if (!id) {
          return;
        }
        await dispatch<any>(businessCompanyDetailThunk(id));
        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <MasterTemplate roleAccept={[ROLE_DESKIMO_ADMIN_ID]}>
      {loading ? (
        <LoaderCustom />
      ) : (
        <SectionWhiteBox isShowBack>
          <GeneralInfo />
          <Revenue />
          <CreditCard />
          <OnboardPromotion />
          <BusinessPaymentMethodConfiguration />
          <WMSConfiguration />
          <Contract />
        </SectionWhiteBox>
      )}
    </MasterTemplate>
  );
};

export default CompanyDetail;
