export const API_LOG_OUT = "/api/v1/user/logout";
export const API_EMPLOYEES = "/api/v1/employees";
export const API_EMPLOYEES_PROPERTY = "/api/v1/property-com/employees";
export const API_EMPLOYEE_PROPERTY = "/api/v1/property-com/employee";
export const API_EMPLOYEE_PROFILE = "/api/v1/employee/profile";
export const API_EMPLOYEE_PROFILE_PROPERTY =
  "/api/v1/property-com/%companyId%/employee/%userId%/profile";
export const API_EMPLOYEE_IMPORT =
  "/api/v1/company/%companyId%/employee-file/import";
export const API_EMPLOYEE_PROPERTY_INVITE =
  "/api/v1/property-com/employee/invite";
export const API_EMPLOYEE_UPLOAD_AVATAR =
  "/api/v1/employee/update-employee_avatar";
export const API_EMPLOYEE_UPDATE = "/api/v1/employee/update-employee-profile";
export const API_EMPLOYEE_PROPERTY_UPDATE =
  "/api/v1/property-com/employee/update-employee-profile";
export const API_EMPLOYEE_EDIT_INLINE =
  "/api/v1/employee/update-employee-inline";
export const API_EMPLOYEE_REINVITE =
  "/api/v1/employee/%invitationId%/re-invite";
export const API_EMPLOYEE_PROPERTY_REINVITE =
  "/api/v1/property-com/employee/re-invite";
export const API_EMPLOYEE_DEACTIVE =
  "/api/v1/employee/%invitationId%/deactivate";
export const API_EMPLOYEE_PROPERTY_DEACTIVE =
  "/api/v1/property-com/%companyId%/employee/%userId%/deactivate";
export const API_EMPLOYEE_PROPERTY_ACTIVE =
  "/api/v1/property-com/%companyId%/employee/%userId%/activate";
export const API_EMPLOYEE_DELETE = "/api/v1/employee/%invitationId%/delete";
export const API_SEARCH_PROPERTY =
  "/api/v1/properties/autocomplete/%companyId%?q=%search%";

export const API_GET_EMPLOYEE_BY_GOOGLE =
  "/api/v1/external/company/%companyId%/users/google_workspace";

export const API_INVITE_EMPLOYEE_BY_GOOGLE =
  "/api/v1/external/company/%companyId%/users/google_workspace/import";

export const API_GET_FAKING_LOGIN = "/api/v1/user/faking-login";
export const API_GET_FAKING_COMEBACK = "/api/v1/user/faking-comeback";
export const API_DESKIMO_USERS = "/api/v1/users";
export const API_DESKIMO_USERS_STATUS = "/api/v1/des-admin/user/status";
export const API_DESKIMO_USERS_BLACK_LIST = "/api/v3/user/%id%/status/%action%";

export const API_DESKIMO_USER_DETAIL = "/api/v1/des-admin/user/%userId%";
export const API_DESKIMO_USER_UPDATE = "/api/v1/des-admin/user";
export const API_DESKIMO_USER_DETAIL_REVIEW =
  "/api/v1/des-admin/user/%userId%/review-property";
export const API_DESKIMO_USER_USAGE_HISTORY =
  "/api/v1/des-admin/user/%userId%/usage-history";

export const API_DESKIMO_USER_VOUCHER_USAGE_HISTORY =
  "/api/v3/voucher-usages/%userId%";
export const API_DESKIMO_USER_DELETE = "/api/v1/users/delete/%id%";

export const API_UPDATE_UPDATE_NEW_USER =
  "/api/v1/my-user-profile/update-new-user";

export const API_GET_ALL_COMPANIES = "/api/v1/des-admin/companies";
export const API_INVITE_WMS_USER =
  "/api/v1/company/%companyId%/employee-file/import";

export const API_EXTEND_CREDIT =
  "/api/v1/des-admin/user/%userId%/%companyId%/extend-credit";

export const API_PENDING_INVITATION_DESKIMO_ADMIN =
  "/api/v1/pending-request-invitations";

export const API_INVITE_TEAM_MEMBER = "/api/v3/business-partner/user";
export const API_GET_TEAM_MEMBER = "/api/v3/business-partner/users";
export const API_SEND_OTP_TO_SLACK =
  "/api/v1/des-admin/%userId%/send-otp-slack";
export const API_RESET_OTP_LIMIT = "api/v1/des-admin/%userId%/reset-otp-limit";
