import { Link, NavLink } from "react-router-dom";
import { PUBLIC_URL } from "../../configs/Constants";
import ButtonCustom from "../../../../components/Button";
import ProfileDropdown from "./ProfileDropdown";
import { useDispatch } from "react-redux";
import { modalNavbarMobileAction } from "../../actions/AppActions";
import {
  BUSINESS_COMPANY_LIST_LINK,
  DESKIMO_PROPERTY_COMPANY_CREATE_LINK,
  DESKIMO_PROPERTY_COMPANY_DETAIL_LINK,
  DESKIMO_PROPERTY_COMPANY_LINK,
  DESKIMO_PROPERTY_LINK,
  DESKIMO_PROPERTY_STAFF_LINK,
  DESKIMO_USERS_LINK,
  MARKETING_CAMPAIGN_CRATE_LINK,
  MARKETING_CAMPAIGN_DETAIL_LINK,
  MARKETING_CAMPAIGN_EDIT_LINK,
  MARKETING_CAMPAIGNS_LINK,
  DESKIMO_PROPERTY_DETAIL_LINK,
  CONFIG_MAIN_LINK,
  DESKIMO_USER_DETAIL_LINK,
  CONFIG_MARKET_EDIT_GENERAL_INFORMATION_LINK,
  CONFIG_MARKET_CREATE_LINK,
  DASHBOARD_DESKIMO_LINK,
  MEDIA_FILE_LINK,
} from "../../configs/ListRoutes";
import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "../../../../utils/commons";
import { LocalizationContext } from "../../../../locales/Translation";
import NavBarDeskimoMobile from "../ModalNavBarMobile/NavBarDeskimoMobile";
import { useLocation } from "react-router-dom";
import ConfigurationIcon from "./Components/ConfigurationIcon";
import "./styles.scss";
import WorkspacesIcon from "./Components/WorkspacesIcon";
import CompaniesIcon from "./Components/CompaniesIcon";
import MarketingIcon from "./Components/MarketingIcon";
import "./styles.scss";
import BellNotification from "./Components/BellNotification";
import MediaIcon from "./Components/MediaIcon";

const NavBarDeskimo = () => {
  const dispatch = useDispatch();
  const { translations } = useContext(LocalizationContext);
  const location = useLocation();

  const [userNavActive, setUserNavActive] = useState<boolean>(false);
  const [propertyNavActive, setPropertyNavActive] = useState<boolean>(false);
  const [marketingNavActive, setMarketingNavActive] = useState<boolean>(false);
  const [companyNavActive, setCompanyNavActive] = useState<boolean>(false);
  const [configNavActive, setConfigNavActive] = useState<boolean>(false);
  const [mediaNavActive, setMediaNavActive] = useState<boolean>(false);

  useEffect(() => {
    if (
      location.pathname === DESKIMO_USERS_LINK ||
      location.pathname.includes(DESKIMO_USER_DETAIL_LINK.replaceAll(":id", ""))
    ) {
      setUserNavActive(true);
    } else {
      setUserNavActive(false);
    }

    if (
      location.pathname === DESKIMO_PROPERTY_LINK ||
      location.pathname === DESKIMO_PROPERTY_COMPANY_LINK ||
      location.pathname === DESKIMO_PROPERTY_COMPANY_CREATE_LINK ||
      location.pathname === DESKIMO_PROPERTY_STAFF_LINK ||
      location.pathname.includes(
        DESKIMO_PROPERTY_DETAIL_LINK.replaceAll(":companyId/:id", "")
      ) ||
      location.pathname.includes(
        DESKIMO_PROPERTY_COMPANY_DETAIL_LINK.replaceAll(":id", "")
      )
    ) {
      setPropertyNavActive(true);
    } else {
      setPropertyNavActive(false);
    }

    if (location.pathname.includes(BUSINESS_COMPANY_LIST_LINK)) {
      setCompanyNavActive(true);
    } else {
      setCompanyNavActive(false);
    }

    if (
      location.pathname.includes(MARKETING_CAMPAIGNS_LINK) ||
      location.pathname.includes(
        MARKETING_CAMPAIGN_DETAIL_LINK.replaceAll(":id", "")
      ) ||
      location.pathname.includes(
        MARKETING_CAMPAIGN_EDIT_LINK.replaceAll(":id", "")
      ) ||
      location.pathname.includes(MARKETING_CAMPAIGN_CRATE_LINK)
    ) {
      setMarketingNavActive(true);
    } else {
      setMarketingNavActive(false);
    }

    if (
      location.pathname === CONFIG_MAIN_LINK ||
      location.pathname === CONFIG_MARKET_CREATE_LINK ||
      location.pathname.includes(
        CONFIG_MARKET_EDIT_GENERAL_INFORMATION_LINK.replaceAll(":id", "")
      )
    ) {
      setConfigNavActive(true);
    } else {
      setConfigNavActive(false);
    }

    if (location.pathname === MEDIA_FILE_LINK) {
      setMediaNavActive(true);
    } else {
      setMediaNavActive(false);
    }
  }, [location]);

  return (
    <div className={"navbar"}>
      {isMobile() ? (
        <ButtonCustom
          onClick={() => dispatch(modalNavbarMobileAction(true))}
          className={"primary-alt navbar__toggle"}
          height={"37px"}
        >
          <i className="uil uil-bars" />
        </ButtonCustom>
      ) : (
        <Link to={DASHBOARD_DESKIMO_LINK} className={"navbar__logo"}>
          <img src={PUBLIC_URL + "/assets/logo.svg"} alt="deskimo-logo" />
        </Link>
      )}

      <div className={"navbar__left"}>
        <NavLink
          className={({ isActive }) => (isActive ? "active" : "")}
          to={DASHBOARD_DESKIMO_LINK}
        >
          <i className="uil uil-chart" />
          {translations.navBar.dashboard}
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            userNavActive || isActive ? "active" : ""
          }
          to={DESKIMO_USERS_LINK}
        >
          <i className="uil uil-users-alt" />
          {translations.users}
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive || propertyNavActive ? "active" : ""
          }
          to={DESKIMO_PROPERTY_LINK}
        >
          <WorkspacesIcon isActive={propertyNavActive} />
          {translations.workspaces}
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "active" : "")}
          to={BUSINESS_COMPANY_LIST_LINK}
        >
          <CompaniesIcon isActive={companyNavActive} />
          {translations.navBar.companiesHeaderTitle}
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            marketingNavActive || isActive ? "active" : ""
          }
          to={MARKETING_CAMPAIGNS_LINK}
        >
          <MarketingIcon isActive={marketingNavActive} />
          {translations.navBar.marketingHeaderTitle}
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            configNavActive || isActive ? "active" : ""
          }
          to={CONFIG_MAIN_LINK}
        >
          <ConfigurationIcon isActive={configNavActive} />
          {translations.navBar.configurationHeaderTitle}
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            mediaNavActive || isActive ? "active" : ""
          }
          to={MEDIA_FILE_LINK}
        >
          <MediaIcon isActive={mediaNavActive} />
          {translations.navBar.mediaFileTitle}
        </NavLink>
      </div>
      <div className={"navbar__right"}>
        <BellNotification />
        <ProfileDropdown />

        {isMobile() ? <NavBarDeskimoMobile /> : null}
      </div>
    </div>
  );
};

export default NavBarDeskimo;
