import TableCustom from "../../../../../components/Table";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deskimoPropertyScannerListSelector } from "../../../selectors/PropertySelector";
import Button from "../../../../../components/Button";
import "./style.scss";
import {
  assignQrCodePropertyScannerAction,
  modalPropertyScannerQrCodeAction,
} from "../../../actions/PropertyActions";
import Action from "./Action";
import {
  STATUS_DISCONNECTED,
  STATUS_HEALTHY,
} from "../../../configs/Constants";
import { LocalizationContext } from "../../../../../locales/Translation";
import MarketFilterMultiple from "../../../../../components/FilterMarket/MarketFilterMultiple";
import FilterStatus from "./FilterStatus";

type Props = {
  loading: any;
  onChangeSortCol: any;
  onChangePagination: any;
  onChangeMarket: any;
  onChangeStatus: any;
  filter: any;
};

const DeskimoPropertiesScannerListTable = ({
  loading,
  filter,
  onChangeSortCol,
  onChangePagination,
  onChangeMarket,
  onChangeStatus,
}: Props) => {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [scannerTableData, setScannerTableData] = useState<any>([]);
  const propertyScannerListData = useSelector(
    deskimoPropertyScannerListSelector
  );

  const handleHeartbeatStatus = (value: string) => {
    if (value.toLowerCase() === STATUS_DISCONNECTED) {
      return (
        <div className="status-disconnected">{translations.disconnected}</div>
      );
    } else if (value.toLowerCase() === STATUS_HEALTHY) {
      return (
        <div className="status-connected">
          {translations.propertyScanners.statusConnected}
        </div>
      );
    }
    return "-";
  };

  const columns = [
    { key: "id", col: translations.scannerID },
    { key: "scanner_name", col: translations.propertyScanners.scannerName },
    { key: "property_name", col: translations.propertyName },
    { key: "market_name", col: translations.market },
    {
      key: "updated_date",
      col: translations.propertyAmenities.updatedDateColumnHeader,
    },
    { key: "heartbeat", col: translations.propertyScanners.heartbeat },
    { key: null, col: translations.propertyScanners.qrCode },
    { key: "status", col: translations.status },
    { key: null, col: translations.action },
  ];

  useEffect(() => {
    if (propertyScannerListData.scanners) {
      const data = propertyScannerListData.scanners.map((scanner: any) => {
        return {
          col1: scanner?.id,
          col2: scanner?.name,
          col3: scanner?.property_name,
          col4: scanner?.market,
          col5: scanner?.updated_date,
          col6: handleHeartbeatStatus(scanner?.heartbeat),
          col7: (
            <Button
              padding="0"
              height={"28px"}
              className="qr-code-button"
              onClick={() => {
                dispatch(modalPropertyScannerQrCodeAction(true));
                dispatch(assignQrCodePropertyScannerAction(scanner?.qr_code));
              }}
            >
              <i className="uil uil-qrcode-scan qr-code-label" />
            </Button>
          ),
          col8: scanner?.status_name,
          col9: (
            <Action
              scannerId={scanner?.id}
              scannerStatus={scanner?.status}
              scannerToken={scanner?.token}
            />
          ),
        };
      });
      setScannerTableData(data);
    } else {
      setScannerTableData([]);
    }
  }, [propertyScannerListData]);

  return (
    <div>
      <TableCustom
        sortTable={true}
        tableData={scannerTableData}
        loading={loading}
        columns={columns}
        unit={""}
        totalItems={propertyScannerListData?.pagination?.total}
        itemPerPage={propertyScannerListData?.pagination?.limit}
        currentPage={filter.page}
        orderBy={filter.order_by}
        sortBy={filter.sort_by}
        onChangeSortCol={onChangeSortCol}
        onChangePagination={onChangePagination}
        topRightComponent={
          <>
            <FilterStatus
              onChange={onChangeStatus}
              values={filter.status}
              isSubmitting={loading}
            />
            <MarketFilterMultiple
              onChange={onChangeMarket}
              values={filter.market_codes}
              isSubmitting={loading}
            />
          </>
        }
      />
    </div>
  );
};

export default DeskimoPropertiesScannerListTable;
