import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import ModalRightSideBarTemplate from "../../../../../App/views/ModalRightSideBarTemplate";
import React, { useContext, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  billingInfoSelector,
  isModalBillingInformationSelector,
} from "../../../../selectors/BillingSelector";
import { modalBillingInformationAction } from "../../../../actions/BiilingActions";
import SizeBox from "../../../../../../components/SizeBox";
import { DIALING_CODE } from "../../../../../App/configs/PhonePrefix";
import * as yup from "yup";
import Label from "../../../../../../components/Label";
import InputCustom from "../../../../../../components/Input";
import { Formik } from "formik";
import { COUNTRY_LIST } from "../../../../../App/configs/CountryList";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import { updateBillingInformationService } from "../../../../services/SettingServices";
import { getBillingInfoThunk } from "../../../../thunks/BillingThunk";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { fileValidation } from "../../../../../../utils/commons";
import { uploadImageService } from "../../../../../App/services/AppServices";
import { LocalizationContext } from "../../../../../../locales/Translation";
import InputPhoneFlag from "../../../../../../components/InputPhoneFlag";
import SimpleCollapseExpandBlank from "../../../../../../components/SimpleCollapseExpandBlank";
import { LOGIN_TIMES } from "../../../../../../configs/Constants";

const ModalBillingInformation = () => {
  const dispatch = useDispatch();
  const isModalBillingInformation = useSelector(
    isModalBillingInformationSelector
  );
  const billingInformationSelector = useSelector(billingInfoSelector);
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const uploadFileRef = useRef<any>();
  const [imgSrc, setImgSrc] = useState(billingInformationSelector?.logo);
  const { translations } = useContext(LocalizationContext);

  const _handleSave = async (values: any) => {
    setLoading(true);
    try {
      await updateBillingInformationService(values);
      toastSuccess(translations.settings.updateBillingSuccessMsg);
      await dispatch<any>(getBillingInfoThunk());
      dispatch(modalBillingInformationAction(false));
      localStorage.setItem(LOGIN_TIMES, String(1));
    } catch (response: any) {
      toastError(response?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const PreviewImage = ({ uri }: any) => {
    return <img src={uri} />;
  };

  const handleUploadFile = async (setFieldValue: any) => {
    const formData = new FormData();
    formData.append("avatar", uploadFileRef.current.files[0]);
    const response = await uploadImageService(formData);

    if (response?.data) {
      setPreviewImage(response?.data?.uri);
      setImgSrc(response?.data?.fileKey);
      setFieldValue("logo", response?.data?.uri);
    }
  };

  return (
    <ModalRightSideBarTemplate
      trigger={null}
      onClose={() => dispatch(modalBillingInformationAction(false))}
      modalClassName={"modal-edit-employee modal-billing-information"}
      open={isModalBillingInformation}
    >
      <Modal.Header>
        <div>{translations.settings.editBillingInformation}</div>
        <ButtonCustom
          onClick={() => dispatch(modalBillingInformationAction(false))}
          className={"m-0 p-0 none-bg close-modal"}
        >
          <>
            {translations.settings.close}
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>

      <Formik
        enableReinitialize
        initialValues={{
          name: billingInformationSelector?.name,
          email: billingInformationSelector?.email,
          phone_number: billingInformationSelector?.phone_number,
          dialing_code: DIALING_CODE.filter(
            (country) =>
              country?.value?.replaceAll("+", "") ===
              billingInformationSelector?.dialing_code
          )[0]?.key.toUpperCase(),
          address: billingInformationSelector?.address
            ? billingInformationSelector?.address
            : "",
          address_2: billingInformationSelector?.address_2,
          vat_number: billingInformationSelector?.vat_number,
          city: billingInformationSelector?.city
            ? billingInformationSelector?.city
            : "",
          zip_code: billingInformationSelector?.zip_code
            ? billingInformationSelector?.zip_code
            : "",
          country: billingInformationSelector?.country,
          logo: billingInformationSelector?.logo || "",
        }}
        validationSchema={yup.object().shape({
          name: yup
            .string()
            .required()
            .label(translations.settings.companyName),
          email: yup
            .string()
            .email()
            .required()
            .label(translations.settings.email),
          phone_number: yup
            .string()
            .required()
            .label(translations.settings.phone),
          address: yup.string().required().label(translations.settings.address),
          city: yup.string().required().label(translations.settings.city),
          zip_code: yup
            .string()
            .required()
            .label(translations.settings.postalCode),
        })}
        onSubmit={_handleSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <>
            <Modal.Content className={"scroll"}>
              <SizeBox height={"32px"} />
              <SimpleCollapseExpandBlank
                defaultOpen
                title={"Basic Information"}
                content={
                  <>
                    <div>
                      <Label
                        fontSize={"12px"}
                        className={"sm"}
                        label={"Company Logo"}
                        style={{ marginBottom: 16 }}
                      />
                      <div className={"d-flex align-items-center mb-48px"}>
                        <div className={"company-logo"}>
                          {previewImage ? (
                            <PreviewImage uri={previewImage} />
                          ) : (
                            <img
                              src={
                                values?.logo
                                  ? values?.logo
                                  : PUBLIC_URL +
                                    "/assets/default-add-avatar.svg"
                              }
                            />
                          )}

                          <input
                            className={"d-none"}
                            ref={uploadFileRef}
                            type="file"
                            accept="image/*"
                            onChange={async (event: any) => {
                              const isFileValid = fileValidation(
                                event.target.files
                              );

                              if (isFileValid) {
                                await handleUploadFile(setFieldValue);
                              } else {
                                toastError(
                                  translations.settings.file2MBValidationMsg
                                );
                              }
                            }}
                          />
                        </div>

                        <SizeBox width={"32px"} />
                        <ButtonCustom
                          onClick={(e: any) => {
                            e.preventDefault();
                            uploadFileRef.current.click();
                          }}
                          className={"primary-alt"}
                          height={"36px"}
                        >
                          <>
                            {translations.settings.uploadImage}
                            <SizeBox width={"14px"} />
                            <i className="uil uil-upload-alt" />
                          </>
                        </ButtonCustom>
                      </div>
                      {touched.logo && errors.logo ? (
                        <span className={"error"}>{String(errors.logo)}</span>
                      ) : null}
                    </div>
                    <SizeBox height={"32px"} />
                    <InputCustom
                      labelRequired={true}
                      label={translations.settings.companyName}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched.name}
                      error={errors.name}
                      name={"name"}
                    />
                    <InputCustom
                      labelRequired={true}
                      label={translations.settings.emailAddress}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched.email}
                      error={errors.email}
                      name={"email"}
                    />
                    <div className={"mb-16px"} />
                    <InputPhoneFlag
                      label={"Mobile Phone Number"}
                      selectedFlag={values.dialing_code}
                      onSelectFlag={(code) =>
                        setFieldValue("dialing_code", code)
                      }
                      error={errors.phone_number}
                      touched={touched.phone_number}
                      phoneChange={(value) =>
                        setFieldValue("phone_number", value)
                      }
                      phoneValue={values.phone_number}
                    />
                    <SizeBox height={"16px"} />
                    <InputCustom
                      label={translations.settings.GSTNumber}
                      value={values.vat_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched.vat_number}
                      error={errors.vat_number}
                      name={"vat_number"}
                    />
                  </>
                }
              />
              <SizeBox height={"32px"} />

              <SimpleCollapseExpandBlank
                title={translations.settings.address}
                content={
                  <>
                    <InputCustom
                      labelRequired={true}
                      label={translations.settings.address + " 1"}
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched.address}
                      error={errors.address}
                      name={"address"}
                    />
                    <InputCustom
                      label={translations.settings.address + " 2"}
                      value={values.address_2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched.address_2}
                      error={errors.address_2}
                      name={"address_2"}
                    />
                    <InputCustom
                      labelRequired={true}
                      label={translations.settings.city}
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched.city}
                      error={errors.city}
                      name={"city"}
                    />
                    <InputCustom
                      labelRequired={true}
                      label={translations.settings.postalCode}
                      value={values.zip_code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched.zip_code}
                      error={errors.zip_code}
                      name={"zip_code"}
                    />
                    <Label
                      fontSize={"12px"}
                      label={translations.settings.country}
                      className={"sm"}
                    />
                    <DropdownCustom
                      search
                      scrolling
                      className={"dropdown-select-country"}
                      icon={"chevron down"}
                      options={COUNTRY_LIST}
                      value={values.country}
                      onChange={(_, { value }) => {
                        setFieldValue("country", value);
                      }}
                    />
                  </>
                }
              />
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom
                height={"47px"}
                onClick={() => dispatch(modalBillingInformationAction(false))}
              >
                {translations.settings.cancel}
              </ButtonCustom>
              <ButtonCustom
                loading={loading}
                type={"submit"}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {translations.settings.save}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalRightSideBarTemplate>
  );
};

export default ModalBillingInformation;
