import React, { useContext, useState } from "react";
import ToggleSlider from "../../../../../../components/ToggleSlider";
import { Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { SETTING_LINK } from "../../../../../App/configs/ListRoutes";
import { LocalizationContext } from "../../../../../../locales/Translation";

type Props = {
  isCheck: boolean;
  onChange?: any;
  id?: any;
  hasMonthlyBudget: boolean;
};

const MonthlyPooledBudget = ({
  isCheck,
  onChange,
  id,
  hasMonthlyBudget,
}: Props) => {
  const { translations } = useContext(LocalizationContext);

  return (
    <Popup
      hoverable
      disabled={hasMonthlyBudget}
      trigger={
        <div>
          <ToggleSlider
            disabled={!hasMonthlyBudget}
            value={isCheck}
            onChange={(e: any, data: any) => {
              onChange(e, data, id);
            }}
          />
        </div>
      }
    >
      <div className={"employee-table__monthly_budget_popup"}>
        {translations.thePooledBudgetOptionIsCurrentlyDeactivated}
        <Link to={SETTING_LINK} className={"link"}>
          {translations.activateInSettings}
        </Link>
      </div>
    </Popup>
  );
};

export default MonthlyPooledBudget;
