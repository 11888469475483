import React from "react";
import DatePickerSingle from "../../../../../../components/DatePickerSingle";
import Label from "../../../../../../components/Label";
import moment from "moment/moment";
import { FORMAT_DATE_TIME_VARIANT_8 } from "../../../../../App/configs/DateTimeConfigs";

type Props = {
  value: Date;
  onChange: (value: Date | null) => void;
  label: string;
};

const Date = ({ label, value, onChange }: Props) => {
  return (
    <div>
      <Label label={label} />
      <DatePickerSingle
        value={value}
        onChange={onChange}
        renderValue={(value: any) =>
          moment(value).format(FORMAT_DATE_TIME_VARIANT_8)
        }
        squareUI
      />
    </div>
  );
};

export default Date;
