// Date
export const FORMAT_DATE_VARIANT_1 = "MMMM DD, YYYY";
export const FORMAT_DATE_VARIANT_2 = "DD/MM/yyyy";
export const FORMAT_DATE_VARIANT_3 = "DD MMMM YYYY";
export const FORMAT_DATE_FOR_DATE_RANGE = "dd/MM/yyyy";
export const FORMAT_DATE_FOR_DATE_PICKER_SINGLE = "dd/MM/yyyy";
// export const FORMAT_DATE_FOR_DATE_PICKER_SINGLE = "EEE, LLLL dd";

// Time
export const FORMAT_TIME_VARIANT_1 = "hh:mm A";

// Date time
export const FORMAT_DATE_TIME_VARIANT_1 = "MM/DD/YYYY - hh:mm A";
export const FORMAT_DATE_TIME_VARIANT_2 = "DD/MM/YYYY, hh:mm A";
export const FORMAT_DATE_TIME_VARIANT_3 = "DD/MM/yyyy hh:mm A";
export const FORMAT_DATE_TIME_VARIANT_4 = "dd/MM/yyyy hh:mm a";
export const FORMAT_DATE_TIME_VARIANT_5 = "ddd, MMMM DD";
export const FORMAT_DATE_TIME_VARIANT_6 = "MMMM DD, YYYY • hh:mm A";
export const FORMAT_DATE_TIME_VARIANT_7 = "DD MMMM YYYY hh:mm A";
export const FORMAT_DATE_TIME_VARIANT_8 = "ddd, MMM DD, YYYY";
export const FORMAT_DATE_TIME_VARIANT_9 = "ddd, MMM DD, YYYY • hh:mm A";
export const FORMAT_DATE_TIME_VARIANT_10 = "DD/MM/YYYY | HH:mm:ss ([GMT] Z) ";

type DayOptionsType = {
  minTime?: string;
  maxTime?: string;
};

export const dayOptions = (params?: DayOptionsType) => {
  let min = 0;
  let max = 47;

  if (params?.minTime) {
    const minTemp = params?.minTime.split(":");
    const hourIndex = Number(minTemp[0]) * 2;

    min = minTemp[1] === "00" ? hourIndex : hourIndex + 1;
  }

  if (params?.maxTime) {
    const maxTemp = params?.maxTime.split(":");
    const hourIndex = Number(maxTemp[0]) * 2;

    max = maxTemp[1] === "00" ? hourIndex : hourIndex + 1;
  }
  const dayArr = [];

  for (let i = min; i <= max; i++) {
    const temp = i % 2;
    const minute = temp === 0 ? "00" : "30";
    const hour = parseInt(String(i / 2));
    const value = hour < 10 ? `0${hour}:${minute}` : `${hour}:${minute}`;

    let text = "";

    if (hour === 12) {
      text = `12:${minute} PM`;
    } else if (hour < 10) {
      text = `0${hour}:${minute} AM`;
    } else if (hour < 12) {
      text = `${hour}:${minute} AM`;
    } else if (hour > 21) {
      text = `${hour - 12}:${minute} PM`;
    } else {
      text = `0${hour - 12}:${minute} PM`;
    }

    dayArr.push({
      key: value,
      text: text,
      value,
    });
  }
  return dayArr;
};

export const DAY_CUSTOM_TIME = "2";
export const DAY_CLOSED = "3";
export const DAY_24H = "1";
export const DAY_OF_WEEK = [
  {
    label: "Monday",
    name: "mondayGroup",
  },
  {
    label: "Tuesday",
    name: "tuesdayGroup",
  },
  {
    label: "Wednesday",
    name: "wednesdayGroup",
  },
  {
    label: "Thursday",
    name: "thursdayGroup",
  },
  {
    label: "Friday",
    name: "fridayGroup",
  },
  {
    label: "Saturday",
    name: "saturdayGroup",
  },
  {
    label: "Sunday",
    name: "sundayGroup",
  },
];
export const DAY_CLOSED_DISPLAY = "Closed";
export const DAY_24H_DISPLAY = "All day";
export const FROM_TIME = 1;
export const TO_TIME = 2;

//Schedule
export const OPERATION_HOUR_INIT = [
  {
    type: "openHour",
    typeName: "Operating Hours",
    week_days: [
      {
        from_time: "08:00",
        to_time: "18:00",
        week_day: 1,
        week_day_title: "Monday",
        open_type: 2,
      },
      {
        from_time: "08:00",
        to_time: "18:00",
        week_day: 2,
        week_day_title: "Tuesday",
        open_type: 2,
      },
      {
        from_time: "08:00",
        to_time: "18:00",
        week_day: 3,
        week_day_title: "Wednesday",
        open_type: 2,
      },
      {
        from_time: "08:00",
        to_time: "18:00",
        week_day: 4,
        week_day_title: "Thursday",
        open_type: 2,
      },
      {
        from_time: "08:00",
        to_time: "18:00",
        week_day: 5,
        week_day_title: "Friday",
        open_type: 2,
      },
      {
        from_time: "08:00",
        to_time: "18:00",
        week_day: 6,
        week_day_title: "Saturday",
        open_type: 2,
      },
      {
        from_time: "08:00",
        to_time: "18:00",
        week_day: 7,
        week_day_title: "Sunday",
        open_type: 2,
      },
    ],
  },
  {
    typeName: "Community Team Operating Hours",
    type: "community",
    week_days: [
      {
        from_time: "08:00",
        to_time: "18:00",
        week_day: 1,
        week_day_title: "Monday",
        open_type: 2,
      },
      {
        from_time: "08:00",
        to_time: "18:00",
        week_day: 2,
        week_day_title: "Tuesday",
        open_type: 2,
      },
      {
        from_time: "08:00",
        to_time: "18:00",
        week_day: 3,
        week_day_title: "Wednesday",
        open_type: 2,
      },
      {
        from_time: "08:00",
        to_time: "18:00",
        week_day: 4,
        week_day_title: "Thursday",
        open_type: 2,
      },
      {
        from_time: "08:00",
        to_time: "18:00",
        week_day: 5,
        week_day_title: "Friday",
        open_type: 2,
      },
      {
        from_time: "08:00",
        to_time: "18:00",
        week_day: 6,
        week_day_title: "Saturday",
        open_type: 2,
      },
      {
        from_time: "08:00",
        to_time: "18:00",
        week_day: 7,
        week_day_title: "Sunday",
        open_type: 2,
      },
    ],
  },
  {
    typeName: "Aircon Hours",
    type: "aircon",
    week_days: [
      {
        from_time: "08:00",
        to_time: "18:00",
        week_day: 1,
        week_day_title: "Monday",
        open_type: 2,
      },
      {
        from_time: "08:00",
        to_time: "18:00",
        week_day: 2,
        week_day_title: "Tuesday",
        open_type: 2,
      },
      {
        from_time: "08:00",
        to_time: "18:00",
        week_day: 3,
        week_day_title: "Wednesday",
        open_type: 2,
      },
      {
        from_time: "08:00",
        to_time: "18:00",
        week_day: 4,
        week_day_title: "Thursday",
        open_type: 2,
      },
      {
        from_time: "08:00",
        to_time: "18:00",
        week_day: 5,
        week_day_title: "Friday",
        open_type: 2,
      },
      {
        from_time: "08:00",
        to_time: "18:00",
        week_day: 6,
        week_day_title: "Saturday",
        open_type: 2,
      },
      {
        from_time: "08:00",
        to_time: "18:00",
        week_day: 7,
        week_day_title: "Sunday",
        open_type: 2,
      },
    ],
  },
];

export const LIST_MONTH = [
  {
    key: 1,
    text: "January",
    value: "1",
  },
  {
    key: 2,
    text: "February",
    value: "2",
  },
  {
    key: 3,
    text: "March",
    value: "3",
  },
  {
    key: 4,
    text: "April",
    value: "4",
  },
  {
    key: 5,
    text: "May",
    value: "5",
  },
  {
    key: 6,
    text: "June",
    value: "6",
  },
  {
    key: 7,
    text: "July",
    value: "7",
  },
  {
    key: 8,
    text: "August",
    value: "8",
  },
  {
    key: 9,
    text: "September",
    value: "9",
  },
  {
    key: 10,
    text: "October",
    value: "10",
  },
  {
    key: 11,
    text: "November",
    value: "11",
  },
  {
    key: 12,
    text: "December",
    value: "12",
  },
];

export const LIST_YEAR = (fromYear: number, toYear: number) => {
  const listYear = [];
  for (let i = fromYear; i <= toYear; i++) {
    listYear.push({
      key: i,
      text: String(i),
      value: String(i),
    });
  }
  return listYear;
};

export const DURATION_ARR = (from: number, to: number) => {
  const durationArr = [];
  for (let i = from; i < to; i++) {
    const hour = Math.floor(i / 2);
    const minutes = i % 2 !== 0;
    let hourText = "";
    if (hour > 0) {
      hourText = `${hour} hour${hour > 1 ? "s" : ""}`;
    }

    durationArr.push({
      key: i * 30,
      text: `${hourText} ${minutes ? "30 min" : ""}`,
      value: i * 30,
    });
  }

  return durationArr;
};
