import React, { Dispatch, SetStateAction, useContext } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useSelector } from "react-redux";
import Action from ".//Action";
import TableCustom from "../../../../../../components/Table";
import { businessCompanyInvoiceSelector } from "../../../../selectors/BusinessSelectors";
import { BusinessInvoicesType } from "../../../../configs/DeclareTypes";
import moment from "moment";
import { FORMAT_DATE_VARIANT_1 } from "../../../../../App/configs/DateTimeConfigs";
import MarketFilterMultiple from "../../../../../../components/FilterMarket/MarketFilterMultiple";

type Props = {
  loading: boolean;
  onChangePagination: any;
  onChangeMarketFilter: any;
  onChangeSortCol: any;
  filter: {
    keyword: string;
    sort_by: string;
    order_by: string;
    page: number;
    market_code: string[];
  };
  setRefreshList: Dispatch<SetStateAction<boolean>>;
  setLoading: any;
};

const CompanyInvoiceTable = ({
  loading,
  onChangePagination,
  onChangeMarketFilter,
  onChangeSortCol,
  filter,
  setRefreshList,
  setLoading,
}: Props) => {
  const { translations } = useContext(LocalizationContext);
  const businessCompanyInvoice = useSelector(businessCompanyInvoiceSelector);

  const columns = [
    { key: "invoice_no", col: translations.invoiceNo },
    {
      key: "company_name",
      col: translations.companyInvoices.businessCompanyTableLabel,
    },
    { key: "total", col: translations.totalAmount },
    { key: "time_period", col: translations.timePeriod },
    { key: "due_date", col: translations.settings.dueDate },
    { key: "status_name", col: translations.status },
    { key: null, col: translations.settings.paymentMethod },
    {
      key: "payment_date",
      col: translations.companyInvoices.paymentDateTableLabel,
    },
    { key: null, col: translations.action },
  ];

  const _formatInputData = () => {
    return businessCompanyInvoice?.items?.map(
      ({
        invoice_no,
        company_name,
        company_id,
        total,
        time_period,
        due_date,
        status_name,
        payment_method,
        payment_date,
        status,
        token,
        invoice_pdf_url,
        invoice_xlsx_url,
        can_pay_now,
        id,
        resend_able,
      }: BusinessInvoicesType) => {
        return {
          col1: invoice_no,
          col2: company_name,
          col3: total,
          col4: time_period,
          col5: moment.unix(due_date).utc().format(FORMAT_DATE_VARIANT_1),
          col6: status_name,
          col7: `${translations.companyInvoices.currentCreditCardTableLabel}:\n${payment_method.credit_card}`,
          col8: payment_date ? payment_date : "-",
          col10: (
            <Action
              id={id}
              loading={loading}
              setLoading={setLoading}
              token={token}
              invoicePdfUrl={invoice_pdf_url}
              invoiceXlsxUrl={invoice_xlsx_url}
              status={status}
              setRefreshList={setRefreshList}
              companyId={company_id}
              canPayNow={can_pay_now}
              resendAble={resend_able}
            />
          ),
        };
      }
    );
  };

  return (
    <div
      className={"employee-table"}
      style={
        businessCompanyInvoice?.items?.length < 3 ? { paddingBottom: 100 } : {}
      }
    >
      <TableCustom
        loading={loading}
        showTotal
        sortTable={true}
        tableData={businessCompanyInvoice ? _formatInputData() : []}
        columns={columns}
        itemPerPage={businessCompanyInvoice?.pagination?.limit}
        currentPage={filter.page}
        totalItems={businessCompanyInvoice?.pagination?.total}
        unit={translations.transactions}
        onChangePagination={onChangePagination}
        onChangeSortCol={onChangeSortCol}
        sortBy={filter.sort_by}
        orderBy={filter.order_by}
        topRightComponent={
          <MarketFilterMultiple
            isSubmitting={loading}
            onChange={onChangeMarketFilter}
            values={filter.market_code}
          />
        }
      />
    </div>
  );
};

export default CompanyInvoiceTable;
