import { Link, NavLink, useLocation } from "react-router-dom";
import { PUBLIC_URL } from "../../configs/Constants";
import ButtonCustom from "../../../../components/Button";
import ProfileDropdown from "./ProfileDropdown";
import { useDispatch } from "react-redux";
import { modalNavbarMobileAction } from "../../actions/AppActions";
import {
  BOOK_DESK_DETAIL_LINK,
  BOOK_DESK_LINK,
  BOOK_ROOM_DETAIL_LINK,
  BOOK_ROOM_LINK,
  DESK_BOOKING_LINK,
  ROOM_BOOKING_LINK,
} from "../../configs/ListRoutes";
import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "../../../../utils/commons";
import { LocalizationContext } from "../../../../locales/Translation";
import BookIcon from "./Components/BookIcon";
import NavBarBusinessUserMobile from "../ModalNavBarMobile/NavBarBusinessUserMobile";
import PendingInvitePopup from "../../../Dashboard/views/Components/PendingInvitePopup";

const NavBar = () => {
  const dispatch = useDispatch();
  const { translations } = useContext(LocalizationContext);
  const [bookNavActive, setBookNavActive] = useState<boolean>(false);
  const [bookingNavActive, setBookingNavActive] = useState<boolean>(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (
      pathname === BOOK_ROOM_LINK ||
      pathname.includes(BOOK_DESK_DETAIL_LINK.replaceAll(":id", "")) ||
      pathname.includes(BOOK_ROOM_DETAIL_LINK.replaceAll(":id", ""))
    ) {
      setBookNavActive(true);
    } else {
      setBookNavActive(false);
    }

    if (pathname === ROOM_BOOKING_LINK) {
      setBookingNavActive(true);
    } else {
      setBookingNavActive(false);
    }
  }, [location]);

  return (
    <div className={"navbar"}>
      {isMobile() ? (
        <ButtonCustom
          onClick={() => dispatch(modalNavbarMobileAction(true))}
          className={"primary-alt navbar__toggle"}
          height={"37px"}
        >
          <i className="uil uil-bars" />
        </ButtonCustom>
      ) : (
        <Link to={BOOK_DESK_LINK} className={"navbar__logo"}>
          <img src={PUBLIC_URL + "/assets/logo.svg"} alt="deskimo-logo" />
        </Link>
      )}

      <div className={"navbar__left"}>
        <NavLink
          className={({ isActive }) =>
            bookNavActive || isActive ? "active guide-book" : "guide-book"
          }
          to={BOOK_DESK_LINK}
        >
          <BookIcon />
          {translations.book}
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            bookingNavActive || isActive ? "active" : ""
          }
          to={DESK_BOOKING_LINK}
        >
          <i className="uil uil-list-ul" />
          {translations.myActivities}
        </NavLink>
      </div>
      <div className={"navbar__right"}>
        <ProfileDropdown />
        <PendingInvitePopup />

        {isMobile() ? <NavBarBusinessUserMobile /> : null}
      </div>
    </div>
  );
};

export default NavBar;
