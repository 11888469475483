import React, { useEffect, useState } from "react";
import Label from "../../../../../../components/Label";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import { manualBookingGetPropertiesService } from "../../../../../Dashboard/services/DashboardServices";
import DropdownSearchServer from "../../../../../../components/DropdownSearchServer";
import { FormikValues } from "formik";
import { debounce } from "lodash";
import DropdownSearchServerSingle from "../../../../../../components/DropdownSearchServerSingle";

type Props = {
  values: FormikValues;
  setFieldValue: any;
  disabled: boolean;
  error: boolean;
};

const DropdownProperty = ({
  values,
  setFieldValue,
  disabled,
  error,
}: Props) => {
  const [options, setOptions] = useState<
    {
      key: number;
      text: string;
      id: number;
      timezone: string;
      currency: string;
    }[]
  >([]);

  useEffect(() => {
    (async () => {
      await _initOptions();
    })();
  }, []);

  const _initOptions = async (keyword = "") => {
    try {
      const { data } = await manualBookingGetPropertiesService({ keyword });

      const temp = data.map(
        ({
          id,
          name,
          timezone,
          currency_code,
        }: {
          id: number;
          name: string;
          timezone: string;
          currency_code: string;
        }) => {
          return { key: id, text: name, id, timezone, currency_code };
        }
      );
      setOptions(temp);
    } catch (e) {
      console.log(e);
    }
  };

  const debounce_onChange = debounce(async (keyword: string) => {
    await _initOptions(keyword);
  }, 300);

  return (
    <>
      <Label label={"On which property"} />
      <DropdownSearchServerSingle
        value={{ text: values.property_name, id: values.property_id }}
        onSearch={(searchQuery) => debounce_onChange(searchQuery)}
        options={options}
        disabled={disabled}
        placeholder={"Select property"}
        onChange={(value) => {
          console.log(value);
          setFieldValue("property_name", value.text);
          setFieldValue("property_id", value.id);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setFieldValue("timezone", value.timezone);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setFieldValue("currency", value.currency_code);
        }}
      />
    </>
  );
};

export default DropdownProperty;
