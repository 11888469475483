import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardFilterDateRangeSelector,
  dashboardDeskimoRoomBookingFilterSelector,
  filterListPropertySelectedSelector,
} from "../../../../selectors/DashboardSelectors";
import { createRoomBookingStatusFilterService } from "../../../../services/DashboardServices";
import {
  PROPERTY,
  ROOM_BOOKING_ALL_STATUS,
  ROOM_BOOKING_CANCELLED_STATUS,
  ROOM_BOOKING_COMPLETED_STATUS,
  ROOM_BOOKING_CONFIRMED_STATUS,
  ROOM_BOOKING_DECLINED_STATUS,
  ROOM_BOOKING_NOT_REFUNDED_STATUS,
  ROOM_BOOKING_PENDING_PAYMENT_STATUS,
  ROOM_BOOKING_PENDING_REFUNDED_STATUS,
  ROOM_BOOKING_PENDING_STATUS,
  ROOM_BOOKING_REFUND_FILTER,
  ROOM_BOOKING_REFUNDED_STATUS,
} from "../../../../configs/Constants";
import { dashboardDeskimoRoomBookingFilterAction } from "../../../../actions/DashboardActions";
import DropdownWithCheckboxMenu from "../../../../../../components/DropdownWithCheckboxMenu";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";

const FilterRoomBooking = () => {
  const { translations } = useContext(LocalizationContext);

  const dispatch = useDispatch();
  const dashboardRoomBookingFilter = useSelector(
    dashboardDeskimoRoomBookingFilterSelector
  );
  const dashboardFilterDateRange = useSelector(
    dashboardFilterDateRangeSelector
  );
  const filterListPropertySelected = useSelector(
    filterListPropertySelectedSelector
  );
  const [roomBookingStatus, setRoomBookingStatus] = useState<
    { id: string; name: string }[]
  >([]);

  useEffect(() => {
    (async () => {
      try {
        await createRoomBookingStatusFilterService({
          translations,
          setStatus: setRoomBookingStatus,
          type: PROPERTY,
          deskimoAdmin: true,
        });
      } catch (e) {
        console.log("e", e);
      }
    })();
  }, [
    dashboardRoomBookingFilter?.keyword,
    dashboardFilterDateRange,
    filterListPropertySelected,
  ]);

  const _onChangeFilter = (e: SyntheticEvent, data: any) => {
    if (data.value === ROOM_BOOKING_ALL_STATUS) {
      if (data.checked) {
        dispatch(
          dashboardDeskimoRoomBookingFilterAction({
            key: "filter_status",
            value: [
              ROOM_BOOKING_ALL_STATUS,
              ROOM_BOOKING_CONFIRMED_STATUS,
              ROOM_BOOKING_PENDING_STATUS,
              ROOM_BOOKING_DECLINED_STATUS,
              ROOM_BOOKING_CANCELLED_STATUS,
              ROOM_BOOKING_COMPLETED_STATUS,
              ROOM_BOOKING_PENDING_PAYMENT_STATUS,
            ],
          })
        );
        dispatch(
          dashboardDeskimoRoomBookingFilterAction({
            key: "filter_refund_status",
            value: [
              ROOM_BOOKING_PENDING_REFUNDED_STATUS,
              ROOM_BOOKING_REFUNDED_STATUS,
              ROOM_BOOKING_NOT_REFUNDED_STATUS,
            ],
          })
        );
      } else {
        dispatch(
          dashboardDeskimoRoomBookingFilterAction({
            key: "filter_status",
            value: [],
          })
        );
        dispatch(
          dashboardDeskimoRoomBookingFilterAction({
            key: "filter_refund_status",
            value: [],
          })
        );
      }
      return;
    }

    if (data.checked) {
      if (ROOM_BOOKING_REFUND_FILTER.includes(data.value)) {
        const newValue = [
          ...dashboardRoomBookingFilter.filter_refund_status,
          data.value,
        ];
        dispatch(
          dashboardDeskimoRoomBookingFilterAction({
            key: "filter_refund_status",
            value: newValue,
          })
        );
      } else {
        const newValue = [
          ...dashboardRoomBookingFilter.filter_status,
          data.value,
        ];
        dispatch(
          dashboardDeskimoRoomBookingFilterAction({
            key: "filter_status",
            value: newValue,
          })
        );
      }
      return;
    }

    if (ROOM_BOOKING_REFUND_FILTER.includes(data.value)) {
      const newValue = dashboardRoomBookingFilter.filter_refund_status.filter(
        (status: string) =>
          status !== data.value && status !== ROOM_BOOKING_ALL_STATUS
      );
      dispatch(
        dashboardDeskimoRoomBookingFilterAction({
          key: "filter_refund_status",
          value: newValue,
        })
      );
    } else {
      const newValue = dashboardRoomBookingFilter.filter_status.filter(
        (status: string) =>
          status !== data.value && status !== ROOM_BOOKING_ALL_STATUS
      );
      dispatch(
        dashboardDeskimoRoomBookingFilterAction({
          key: "filter_status",
          value: newValue,
        })
      );
    }
  };

  return (
    <div className={"dropdown-filter"}>
      <DropdownWithCheckboxMenu
        onChange={_onChangeFilter}
        listCheckBox={roomBookingStatus.map((filter: any) => {
          if (ROOM_BOOKING_REFUND_FILTER.includes(filter.id)) {
            if (
              dashboardRoomBookingFilter.filter_refund_status.includes(
                filter.id
              )
            ) {
              filter.checked = true;
            } else {
              filter.checked = false;
            }

            return filter;
          }

          if (dashboardRoomBookingFilter.filter_status.includes(filter.id)) {
            filter.checked = true;
          } else {
            filter.checked = false;
          }

          return filter;
        })}
        label={translations.filters}
        icon={
          <img
            alt={"Deskimo"}
            src={PUBLIC_URL + "/assets/icons/icon-filter.svg"}
          />
        }
      />
    </div>
  );
};

export default FilterRoomBooking;
