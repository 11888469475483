import React, { useState } from "react";
import ToggleSlider from "../../../../../../components/ToggleSlider";
import { userReviewChangeStatusService } from "../../../../services/PropertyServices";
import { toastError } from "../../../../../../components/Toast";

type Props = {
  initialValue: boolean;
  reviewId: string;
  propertyId: string | undefined;
};

const Action = ({ initialValue, propertyId, reviewId }: Props) => {
  const [value, setValue] = useState(initialValue);

  const _onChange = async () => {
    try {
      setValue((prevState) => !prevState);
      const payload = { evaluation_id: reviewId, property_id: propertyId };
      await userReviewChangeStatusService(payload);
    } catch (e: any) {
      toastError(e?.status?.message);
    }
  };

  return <ToggleSlider value={value} onChange={_onChange} />;
};

export default Action;
