import clientRequestService from "../../../services/ClientRequestService";
import {
  GET_CHANGE_STATUS_PROPERTY_SCANNER_API,
  GET_PROPERTY_SCANNER_DETAILS_API,
  POST_ADD_PROPERTY_SCANNER_API,
  POST_EDIT_PROPERTY_SCANNER_API,
  POST_PROPERTY_SCANNER_LIST_API,
} from "../configs/Endpoints";

export const getDeskimoPropertyScannerListService = async (data: object) => {
  return await clientRequestService({
    method: "POST",
    url: POST_PROPERTY_SCANNER_LIST_API,
    data,
  });
};

export const getDeskimoPropertyScannerDetailService = async (token: string) => {
  return await clientRequestService({
    method: "GET",
    url: GET_PROPERTY_SCANNER_DETAILS_API.replaceAll("%token%", token),
  });
};

export const addDeskimoPropertyScannerService = async (data: object) => {
  return await clientRequestService({
    method: "POST",
    url: POST_ADD_PROPERTY_SCANNER_API,
    data,
  });
};

export const editDeskimoPropertyScannerService = async (
  data: object,
  token: string
) => {
  return await clientRequestService({
    method: "POST",
    url: POST_EDIT_PROPERTY_SCANNER_API.replaceAll("%token%", token),
    data,
  });
};

export const changeStatusPropertyScannerService = async (token: string) => {
  return await clientRequestService({
    method: "GET",
    url: GET_CHANGE_STATUS_PROPERTY_SCANNER_API.replaceAll("%token%", token),
  });
};
