import React from "react";
import BookingTable from "../BookingTable";

const CancelledTab = ({ loading }: any) => {
  const columns = [
    "Room Name",
    "Property Details",
    "Duration",
    "Booking Date",
    "Total Fee",
    "Cancelled On",
    "Action",
  ];

  return <BookingTable columns={columns} loading={loading} />;
};

export default CancelledTab;
