import React from "react";
import { Checkbox } from "semantic-ui-react";
import Avatar from "../../../../../components/Avatar";

const AssignUserItem = ({
  user,
  onChangeAssignUser,
  checked,
  disabled = false,
}: any) => {
  return (
    <div className="assign-user">
      <Checkbox
        disabled={disabled}
        checked={checked ? checked : undefined}
        onChange={(event, data) => onChangeAssignUser(user, data)}
        className={"assign-user-checkbox"}
      />
      <div className={"assign-user-info"}>
        <Avatar
          noTooltip={true}
          capName={user?.cap_name}
          imgUrl={user?.avatar}
        />
        <span>{user?.full_name}</span>
      </div>
    </div>
  );
};

export default AssignUserItem;
