import Label from "../../../../../../../components/Label";
import { useState } from "react";
import { Popup } from "semantic-ui-react";

const APIItem = ({ label, text, allowHide = true }: any) => {
  const [view, setView] = useState(false);
  const [copy, setCopy] = useState(false);

  return (
    <div className="api-item-box">
      <Label label={label} className={"custom-label"} />
      <div className={"api-item"}>
        <div className="api-item__icon">
          <i className="uil uil-keyhole-circle" />
        </div>
        <div className={"api-item__content " + (copy ? "text-copied" : "")}>
          <Popup
            onClose={() => {
              setCopy(false);
            }}
            on={copy ? ["click"] : ["hover"]}
            hoverable={copy ? false : true}
            className={"p-0" + (copy ? " copied" : "")}
            content={
              <div
                onClick={() => {
                  navigator.clipboard.writeText(text);
                  setCopy(true);
                }}
                className={"copy-popup "}
              >
                {copy ? (
                  <>
                    <i className="uil uil-clipboard-notes" />
                    Copied
                  </>
                ) : (
                  <>
                    <i className="uil uil-clipboard" />
                    Copy API String
                  </>
                )}
              </div>
            }
            trigger={
              allowHide ? (
                <span>
                  {view
                    ? text
                    : "*****************************************************************"}
                </span>
              ) : (
                <span>{text}</span>
              )
            }
          ></Popup>
        </div>
        {allowHide ? (
          <div
            onClick={() => {
              if (view) {
                setCopy(false);
              }
              setView(!view);
            }}
            className="api-item__action"
          >
            {view ? (
              <i className="uil uil-eye-slash" />
            ) : (
              <i className="uil uil-eye" />
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default APIItem;
