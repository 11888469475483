import React, { useContext } from "react";
import InputCustom from "../../../../../components/Input";
import Label from "../../../../../components/Label";
import MapAutocompleted from "../../PropertyDetail/Components/DetailsTab/Components/MapAutocompleted";
import SizeBox from "../../../../../components/SizeBox";
import { LocalizationContext } from "../../../../../locales/Translation";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import ToggleSlider from "../../../../../components/ToggleSlider";

type Props = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
  handleChange: any;
  setFieldValue: any;
};

const Parking = ({
  isSubmitting,
  setFieldValue,
  values,
  errors,
  touched,
  handleChange,
}: Props) => {
  const { translations } = useContext(LocalizationContext);

  // const;

  const _onChangeDisplayName = (value: string, index: number) => {
    setFieldValue(`parking[${index}].display_name`, value);
  };

  const _onChangeAddressName = (value: string, index: number) => {
    setFieldValue(`parking[${index}].address`, value);
  };

  const _onChangeGeo = (value: { lat: number; lng: number }, index: number) => {
    setFieldValue(`parking[${index}].lat`, value.lat);
    setFieldValue(`parking[${index}].lng`, value.lng);
  };

  return (
    <div className={"section"}>
      <SizeBox height={"16px"} />
      <div className={"section-title"}>{translations.parkingLocation}</div>
      <div>
        <InputCustom
          label={
            translations.parking +
            " 1 " +
            translations.addressDisplayNameStreetNumberStreetName
          }
          value={values?.parking[0]?.display_name}
          onChange={({ target }: any) => _onChangeDisplayName(target.value, 0)}
        />
        <Label
          fontSize={"12px"}
          label={translations.addressForMapPin}
          className={"sm"}
        />
        <MapAutocompleted
          address={values?.parking[0]?.address}
          setAddress={(value: string) => _onChangeAddressName(value, 0)}
          geo={{ lat: values?.parking[0]?.lat, lng: values?.parking[0]?.lng }}
          setGeo={(value: { lat: number; lng: number }) =>
            _onChangeGeo(value, 0)
          }
        />
      </div>
      <SizeBox height={"32px"} />
      <div>
        <InputCustom
          label={
            translations.parking +
            " 2 " +
            translations.addressDisplayNameStreetNumberStreetName
          }
          value={values?.parking[1]?.display_name}
          onChange={({ target }: any) => _onChangeDisplayName(target.value, 1)}
        />
        <Label
          fontSize={"12px"}
          label={translations.addressForMapPin}
          className={"sm"}
        />
        <MapAutocompleted
          address={values?.parking[1]?.address}
          setAddress={(value: string) => _onChangeAddressName(value, 1)}
          geo={{ lat: values?.parking[1]?.lat, lng: values?.parking[1]?.lng }}
          setGeo={(value: { lat: number; lng: number }) =>
            _onChangeGeo(value, 1)
          }
        />
      </div>
      <SizeBox height={"16px"} />
      <ToggleSlider
        value={values.allow_parking_request}
        content={
          <Label
            label={"Allow book for vehicle parking"}
            labelGuide={
              "Enable this option to allow free vehicle parking reservations for this location."
            }
          />
        }
        revertLabel
        onChange={() =>
          setFieldValue("allow_parking_request", !values.allow_parking_request)
        }
      />
      <SizeBox height={"16px"} />
    </div>
  );
};

export default Parking;
