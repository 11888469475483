import React, { useContext, useRef } from "react";
import ButtonCustom from "../../../../../../components/Button";
import { Dropdown } from "semantic-ui-react";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import {
  modalDetailEmployeeAction,
  modalEditEmployeeAction,
} from "../../../../actions/EmployeeActions";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import { useDispatch } from "react-redux";
import {
  employeeDetailThunk,
  employeesThunk,
} from "../../../../thunks/EmployeeThunks";
import {
  employeeDeActiveService,
  employeeDeleteService,
  employeeReInviteService,
} from "../../../../services/EmployeeServices";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import {
  SWAL_CUSTOM_CONTENT,
  SWAL_DELETE_OPTION,
} from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { toastError } from "../../../../../../components/Toast";
import SwalSuccess from "../../../../../../components/SweetAlert/Templates/SwalSuccess";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { EMPLOYEE_PENDING_STATUS } from "../../../../configs/Constants";

type Props = {
  invitationId: number;
  status?: any;
  setLoading?: any;
};

const Action = ({ invitationId, status, setLoading }: Props) => {
  const dispatch = useDispatch();
  const { translations } = useContext(LocalizationContext);

  const ref = useRef<any>(null);

  const _onEditUser = async () => {
    try {
      await dispatch<any>(employeeDetailThunk(invitationId));
      dispatch(modalEditEmployeeAction(true));
    } catch (e) {
      console.log(e);
    }
  };

  const _reload = async () => {
    try {
      setLoading(true);
      await dispatch<any>(employeesThunk());
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const _onDetail = async () => {
    try {
      await dispatch<any>(employeeDetailThunk(invitationId));
      dispatch(modalDetailEmployeeAction(true));
    } catch (e: any) {
      toastError(translations.errorMessage.contactAdmin);
      console.log(e);
    }
  };

  const _onReinvite = async () => {
    try {
      await employeeReInviteService(String(invitationId));
      SweetAlertCommon({
        options: SWAL_CUSTOM_CONTENT({
          content: <SwalSuccess title={translations.inviteResent} />,
          cancelButtonClass: "d-none",
          denyButtonClass: "d-none",
          confirmButtonText: (
            <ButtonCustom className={"primary w-100"} height={"36px"}>
              {translations.done}
            </ButtonCustom>
          ),
        }),
        confirmCallback() {
          console.log("Submit");
        },
        cancelCallback() {
          console.log("Cancel");
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const _onDeactive = async () => {
    const icon = null;
    const title = isDeactiveUser()
      ? translations.areYouSureYouWantToActivateThisUser
      : translations.areYouSureYouWantToDeactivateThisUser;
    const confirmText = isDeactiveUser()
      ? translations.activate
      : translations.deactivate;
    const cancelText = translations.cancel;
    const confirmClass = isDeactiveUser() ? "primary" : "danger";
    const options = SWAL_DELETE_OPTION({
      title,
      icon,
      confirmText,
      confirmClass,
      cancelText,
    });
    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          await employeeDeActiveService(String(invitationId));
          await _reload();
        } catch (response: any) {
          toastError(response?.status?.message);
        }
      },
      cancelCallback: () => null,
    });
  };

  const _onDelete = async () => {
    const iconHtml = (
      <img
        src={PUBLIC_URL + "/assets/icons/icon-error-red.svg"}
        className={"swal2-icon__error"}
      />
    );
    const title = translations.areYouSureWantToDeleteThisAccount;
    const confirmText = translations.delete;
    const cancelText = translations.cancel;
    const options = SWAL_DELETE_OPTION({
      title,
      iconHtml,
      confirmText,
      cancelText,
    });
    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          await employeeDeleteService(String(invitationId));
          await _reload();
        } catch (response: any) {
          toastError(response?.status?.message);
        }
      },
      cancelCallback: () => null,
    });
  };

  const isDeactiveUser = () => {
    return parseInt(status) === 2;
  };

  return (
    <div ref={ref} className={"dropdown-inside"}>
      <DropdownCustom
        direction={"left"}
        icon={null}
        trigger={
          <ButtonCustom
            style={{ width: "38px", height: "38px" }}
            className={"primary-alt"}
          >
            <i className="uil uil-setting" style={{ fontSize: "21px" }} />
          </ButtonCustom>
        }
        onOpen={() => {
          const offsetTop =
            ref.current.childNodes[0].getBoundingClientRect().top + 38;
          const offsetLeft =
            ref.current.childNodes[0].getBoundingClientRect().left - 106;

          const menuEl = ref.current.childNodes[0].querySelector(".menu");

          menuEl.style.setProperty("top", `${offsetTop}px`, "important");
          menuEl.style.setProperty("left", `${offsetLeft}px`, "important");
        }}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={_onDetail}
            text={translations.viewDetails}
            icon={<img src={PUBLIC_URL + "/assets/icons/icon-message.svg"} />}
          />
          <Dropdown.Item
            onClick={_onEditUser}
            text={translations.editUser}
            icon={
              <i className="uil uil-edit icon" style={{ fontSize: "16px" }} />
            }
          />
          <Dropdown.Item
            onClick={_onReinvite}
            text={translations.reInviteUser}
            icon={
              <img src={PUBLIC_URL + "/assets/icons/icon-plus-circle.svg"} />
            }
          />
          <Dropdown.Item
            onClick={_onDeactive}
            text={
              isDeactiveUser()
                ? translations.activateUser
                : translations.deactivateUser
            }
            icon={
              isDeactiveUser() ? (
                <i
                  className="uil uil-toggle-on icon"
                  style={{ fontSize: "16px" }}
                />
              ) : (
                <i
                  className="uil uil-toggle-off icon"
                  style={{ fontSize: "16px" }}
                />
              )
            }
          />
          <Dropdown.Item
            onClick={_onDelete}
            text={translations.deleteUser}
            icon={
              <i
                className="uil uil-trash-alt icon"
                style={{ fontSize: "16px" }}
              />
            }
          />
        </Dropdown.Menu>
      </DropdownCustom>
    </div>
  );
};

export default Action;
