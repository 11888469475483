import React, { useContext, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { LocalizationContext } from "../../../../../locales/Translation";
import DropdownCustom from "../../../../../components/DropdownCustom";
import ButtonCustom from "../../../../../components/Button";
import { removeTagService } from "../../../services/ConfigServices";
import { toastError, toastSuccess } from "../../../../../components/Toast";

type Props = {
  id: number;
  setOnRefresh: any;
};

const Action = ({ id, setOnRefresh }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const [disabled, setDisabled] = useState(false);

  const _onDelete = async () => {
    setDisabled(true);
    try {
      await removeTagService(String(id));
      setOnRefresh((prevState: any) => !prevState);
      toastSuccess(translations.deleteSuccessPrompt);
    } catch (e) {
      toastError(translations.deleteFailPrompt);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <DropdownCustom
      disabled={disabled}
      className={"table-action-dropdown"}
      direction={"left"}
      icon={null}
      trigger={
        <ButtonCustom
          style={{ width: "38px", height: "38px" }}
          className={"primary-alt"}
        >
          <i className="uil uil-setting" style={{ fontSize: "21px" }} />
        </ButtonCustom>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={_onDelete}
          text={translations.delete}
          icon={
            <i
              className="uil uil-trash-alt icon"
              style={{ fontSize: "16px" }}
            />
          }
        />
      </Dropdown.Menu>
    </DropdownCustom>
  );
};

export default Action;
