import { Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../../components/Button";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";
import { Formik } from "formik";
import * as yup from "yup";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import ModalCenterTemplate from "../../../../../App/views/ModalCenterTemplate";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalBookingDeskSelector } from "../../../../selectors/DashboardSelectors";
import { modalBookingDeskManualAction } from "../../../../actions/DashboardActions";
import { LocalizationContext } from "../../../../../../locales/Translation";
import {
  manualPartnerBookingGetPropertiesService,
  manualPartnerBookingSubmitDeskService,
} from "../../../../services/DashboardServices";
import Label from "../../../../../../components/Label";
import DropdownCustom from "../../../../../../components/DropdownCustom";
import { debounce } from "lodash";
import SizeBox from "../../../../../../components/SizeBox";
import DatePickerSingle from "../../../../../../components/DatePickerSingle";
import { convertDate2StatEndTime } from "../../../../../Book/services/Common";
import {
  BUSINESS,
  PRODUCT_TYPE_PARTNER,
  TOTAL_SLOT,
} from "../../../../configs/Constants";
import InputCustom from "../../../../../../components/Input";
import { userCompanyChooseSelector } from "../../../../../User/selectors/UserSelectors";
import { dashboardDeskBookingThunk } from "../../../../thunks/DashboardThunks";

type ManualBookingProperty = {
  reference: string;
  name: string;
  timezone: string;
};

type ManualBookingDataType = {
  propertySelected: string;
  dateSelected: Date;
  totalSlot: string;
  userName?: string;
  email?: string;
};

const ModalBookingDesk = ({ userDetail }: any) => {
  const { translations } = useContext(LocalizationContext);
  const modalBookingDesk = useSelector(modalBookingDeskSelector);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(userCompanyChooseSelector);

  const initData = {
    userName: "",
    email: "",
    propertySelected: "",
    dateSelected: new Date(),
    totalSlot: "1",
  };

  const [modalData, setModalData] = useState<ManualBookingDataType>(initData);
  const [propertyKeyword, setPropertyKeyword] = useState("");
  const [listProperty, setListProperty] = useState<any>([]);

  const _onCloseModal = () => {
    dispatch(modalBookingDeskManualAction({ open: false }));
    setModalData(initData);
  };

  useEffect(() => {
    if (modalBookingDesk.open) {
      _fetchListProperty();
    }
  }, [modalBookingDesk, propertyKeyword]);

  const _fetchListProperty = async () => {
    if (modalBookingDesk.open && currentUser) {
      try {
        const payload = {
          keyword: propertyKeyword,
          market_code: currentUser?.market_code,
          lat: null,
          lng: null,
          date: null,
          page: 1,
        };
        const response = await manualPartnerBookingGetPropertiesService(
          payload
        );
        setListProperty(response?.data?.properties);
      } catch (error: any) {
        toastError(error?.status?.message);
      }
    }
  };

  const getPropertyTimezone = () => {
    if (!modalData?.propertySelected) return;

    const selectedProperty = listProperty.find(
      (property: any) => property?.reference === modalData?.propertySelected
    );
    return selectedProperty?.timezone;
  };

  const _onClickSave = async () => {
    try {
      const timezone = getPropertyTimezone();
      if (!timezone) return;

      const { startTime } = convertDate2StatEndTime(
        timezone,
        modalData.dateSelected
      );

      const payload = {
        property_reference: modalData.propertySelected,
        booking_date: startTime,
        desk_no: modalData.totalSlot,
        full_name: modalData.userName,
        email: modalData.email,
        company_id: currentUser?.company_id,
      };

      await manualPartnerBookingSubmitDeskService(payload);
      await dispatch<any>(
        dashboardDeskBookingThunk(BUSINESS, false, false, PRODUCT_TYPE_PARTNER)
      );
      _onCloseModal();
      toastSuccess("Create booking successfully");
    } catch (error: any) {
      console.log("error: ", error);
      toastError(error?.status?.message);
    }
  };

  return (
    <ModalCenterTemplate
      size={"md"}
      trigger={null}
      onClose={_onCloseModal}
      modalClassName={"modal-general"}
      open={modalBookingDesk.open}
    >
      <Modal.Header>
        Create a Desk Booking
        <ButtonCustom onClick={_onCloseModal} className={"modal-close-cta"}>
          <>
            <span>{translations.close}</span>
            <img src={PUBLIC_URL + "/assets/icons/icon-close-grey.svg"} />
          </>
        </ButtonCustom>
      </Modal.Header>
      <Formik
        initialValues={{}}
        validationSchema={yup.object().shape({})}
        onSubmit={_onClickSave}
      >
        {({ handleSubmit, isSubmitting }) => (
          <>
            <Modal.Content>
              <form className={"form-layout"}>
                <div className={"d-flex gap-24"}>
                  <div className={"flex-1"}>
                    <div>
                      <Label
                        fontSize={"12px"}
                        className={"sm"}
                        label={"User Name"}
                      />
                      <InputCustom
                        value={modalData.userName}
                        onChange={(e: any, data: any) =>
                          setModalData({ ...modalData, userName: data.value })
                        }
                      />
                    </div>
                    <SizeBox height={"16px"} />
                  </div>
                  <div className={"flex-1"}>
                    <div>
                      <Label
                        fontSize={"12px"}
                        className={"sm"}
                        label={"Email"}
                      />
                      <InputCustom
                        type={"email"}
                        value={modalData.email}
                        onChange={(e: any, data: any) =>
                          setModalData({ ...modalData, email: data.value })
                        }
                      />
                    </div>
                    <SizeBox height={"16px"} />
                  </div>
                </div>

                <div>
                  <Label
                    fontSize={"12px"}
                    className={"sm"}
                    label={"Property"}
                  />
                  <DropdownCustom
                    selectUI
                    search
                    onSearchChange={debounce((event, data) => {
                      setPropertyKeyword(data.searchQuery);
                    }, 300)}
                    onChange={(event, data) => {
                      setModalData({
                        ...modalData,
                        propertySelected: data.value
                          ? data.value.toString()
                          : "",
                      });
                    }}
                    options={listProperty.map(
                      (property: ManualBookingProperty) => {
                        return {
                          key: property.reference,
                          text: property.name,
                          value: property.reference,
                        };
                      }
                    )}
                    className={"dropdown-fullwidth-common-style"}
                  />
                </div>
                <SizeBox height={"16px"} />
                <div>
                  <Label
                    fontSize={"12px"}
                    className={"sm"}
                    label={"Start Date"}
                  />
                  <DatePickerSingle
                    // disabledDate={beforeToday()}
                    value={modalData.dateSelected}
                    inputStyle
                    oneTap
                    onChange={(date: Date) => {
                      setModalData({ ...modalData, dateSelected: date });
                    }}
                  />
                </div>
                <SizeBox height={"16px"} />

                <div>
                  <Label
                    fontSize={"12px"}
                    className={"sm"}
                    label={"No. of Desks"}
                  />
                  <DropdownCustom
                    selectUI
                    search
                    value={modalData.totalSlot}
                    onChange={(event, data) => {
                      setModalData({
                        ...modalData,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        totalSlot: data.value ? data.value : "1",
                      });
                    }}
                    options={TOTAL_SLOT(10)}
                    className={"dropdown-fullwidth-common-style"}
                  />
                </div>
              </form>
            </Modal.Content>
            <Modal.Actions>
              <ButtonCustom height={"47px"} onClick={_onCloseModal}>
                {translations.cancel}
              </ButtonCustom>
              <ButtonCustom
                disabled={modalData.propertySelected.length < 1}
                loading={isSubmitting}
                className={"primary"}
                height={"47px"}
                onClick={handleSubmit}
              >
                {translations.create}
              </ButtonCustom>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </ModalCenterTemplate>
  );
};

export default ModalBookingDesk;
