import React from "react";
import { Provider } from "react-redux";
import { store } from "./configs/Store";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./configs/Routes";
import "semantic-ui-css/semantic.min.css";
import "./styles/app.scss";
import { handleClientRequestException } from "../../services/ClientRequestService";
import { LocalizationProvider } from "../../locales/Translation";
import { ToastContainer } from "react-toastify";
import { GoogleOAuthProvider } from "@react-oauth/google";
import useInitCustomerIOInAppMessage from "../../services/customer-io/useInitCustomerIOInAppMessage";

handleClientRequestException();

function App() {
  useInitCustomerIOInAppMessage();

  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
    >
      <Provider store={store}>
        <LocalizationProvider>
          <BrowserRouter>
            <AppRoutes />
            <ToastContainer />
          </BrowserRouter>
        </LocalizationProvider>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
