import React, { useContext, useEffect, useState } from "react";
import { Tab } from "semantic-ui-react";
import ToggleSlider from "../../../../../../components/ToggleSlider";
import Label from "../../../../../../components/Label";
import CheckboxCustom from "../../../../../../components/Checkbox";
import InputCustom from "../../../../../../components/Input";
import SizeBox from "../../../../../../components/SizeBox";
import ButtonCustom from "../../../../../../components/Button";
import "./style.scss";
import { SweetAlertCommon } from "../../../../../../components/SweetAlert";
import { SWAL_CONFIRM_OPTION } from "../../../../../../components/SweetAlert/configs/SwalConfigs";
import { getSettingThunk } from "../../../../thunks/BillingThunk";
import { settingSelector } from "../../../../selectors/BillingSelector";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import {
  requestPostPayService,
  saveSettingService,
} from "../../../../services/SettingServices";
import { toastSuccess } from "../../../../../../components/Toast";
import { addCommaToNumber } from "../../../../../../services/FormatTextService";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { userCompanyChooseSelector } from "../../../../../User/selectors/UserSelectors";
import TypeSelection from "./TypeSelection";
import { PUBLIC_URL } from "../../../../../App/configs/Constants";

const SettingTab = () => {
  const settings = useSelector(settingSelector);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { translations } = useContext(LocalizationContext);
  const userCompanyChoose = useSelector(userCompanyChooseSelector);

  useEffect(() => {
    fetchSetting();
  }, [userCompanyChoose]);

  const _requestPostPay = () => {
    const icon = null;
    const title = translations.settings.switchPostPaidMsgTitle;
    const text = translations.settings.switchPostPaidMsgText;
    const confirmText = translations.settings.submitRequest;
    const options = SWAL_CONFIRM_OPTION({
      title,
      text,
      icon,
      confirmText,
    });

    SweetAlertCommon({
      options,
      confirmCallback: async () => {
        try {
          await requestPostPayService();
          await fetchSetting();
        } catch (error) {
          console.log(error);
        }
      },
      cancelCallback: () => null,
    });
  };

  const fetchSetting = async () => {
    try {
      await dispatch<any>(getSettingThunk());
    } catch (error: any) {
      console.log(error);
    }
  };

  const _onClickSave = async (values: any) => {
    setLoading(true);
    try {
      const propertiesRestriction = values.properties_restriction;
      if (propertiesRestriction.length > 0) {
        values.properties_restriction = propertiesRestriction.map(
          (property: any) => {
            return {
              id: property,
            };
          }
        );
      }
      const payload = { ...values };
      if (values.monthly_company_budget) {
        payload.monthly_company_budget = values.monthly_company_budget
          .replaceAll(",", "")
          .replaceAll(".", "");
      }
      if (values.individual_monthly_budget) {
        payload.individual_monthly_budget = values.individual_monthly_budget
          .replaceAll(",", "")
          .replaceAll(".", "");
      }

      await saveSettingService(payload);
      await fetchSetting();
      toastSuccess(translations.settings.updatedMsg);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tab.Pane attached={false} className={"setting-tab"}>
      <Formik
        enableReinitialize
        initialValues={{
          enable_company_hot_desk_budget:
            settings?.enable_company_hot_desk_budget,
          enable_company_meeting_room_budget:
            settings?.enable_company_meeting_room_budget,
          enable_personal_hot_desk_budget:
            settings?.enable_personal_hot_desk_budget,
          enable_personal_meeting_room_budget:
            settings?.enable_personal_meeting_room_budget,
          monthly_company_budget: settings?.monthly_company_budget
            ? addCommaToNumber(parseInt(settings?.monthly_company_budget))
            : 0,
          payment_currency_method: settings?.payment_currency_method,
          payment_method: settings?.payment_method,
          properties_restriction: settings?.properties_restriction
            ? settings?.properties_restriction.map(
                (property: any) => property.key
              )
            : [],
          uncapped: settings?.uncapped,
          individual_monthly_budget: settings?.individual_monthly_budget
            ? addCommaToNumber(parseInt(settings?.individual_monthly_budget))
            : 0,
          has_request_post_payment: settings?.has_request_post_payment,
          is_personal_limit: settings?.is_personal_limit || false,
          apply_limit_for_current_user: false,
          is_apply_budget_current_employee:
            settings?.is_apply_budget_current_employee || false,
        }}
        onSubmit={_onClickSave}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          resetForm,
        }) => (
          <>
            <div className={"setting-title"}>Budget Restrictions</div>
            <SizeBox height={"16px"} />
            <div className={"setting-wrapper"}>
              <div className={"setting-left-side"}>
                <div className={"setting-item"}>
                  <ToggleSlider
                    value={
                      values.enable_company_meeting_room_budget ||
                      values.enable_company_hot_desk_budget
                    }
                    onChange={(e: any, data: any) => {
                      setFieldValue(
                        "enable_company_hot_desk_budget",
                        data.checked
                      );
                      setFieldValue(
                        "enable_company_meeting_room_budget",
                        data.checked
                      );
                    }}
                  />
                  <Label
                    className={"fw-500"}
                    fontSize={"14px"}
                    label={"Set a pooled budget"}
                    labelGuide={
                      translations.settings
                        .addMonthlyCompanyPooledBudgetCapTooltip
                    }
                  />
                </div>
                {values.enable_company_meeting_room_budget ||
                values.enable_company_hot_desk_budget ? (
                  <div className={"setting-collapse"}>
                    <div className={"setting-item"}>
                      <Label
                        label={translations.settings.monthlyPooledBudget}
                        className={"w-100 fw-400"}
                      />
                      <div className={"w-100"}>
                        <InputCustom
                          value={values.monthly_company_budget}
                          onChange={(event: any, { value }: any) =>
                            setFieldValue(
                              "monthly_company_budget",
                              addCommaToNumber(value)
                            )
                          }
                          onBlur={handleBlur}
                          touched={touched.monthly_company_budget}
                          error={errors.monthly_company_budget}
                          inputLeftIcon={settings?.currency}
                        />
                      </div>
                    </div>
                    <div className={"setting-text"}>
                      The remaining pooled budget is{" "}
                      <b>{settings.company_remaining_budget}</b>
                    </div>
                    <div className={"setting-item"}>
                      <Label
                        label={"Pooled budget can be used for"}
                        className={"fw-400"}
                      />
                      <div className={"w-100"}>
                        <div className={"type-wrapper"}>
                          <TypeSelection
                            type={"Meeting Rooms"}
                            icon={
                              PUBLIC_URL + "/assets/icons/icon-room-blue.png"
                            }
                            iconChecked={
                              PUBLIC_URL + "/assets/icons/icon-room-white.png"
                            }
                            isCheck={values.enable_company_meeting_room_budget}
                            onClick={() => {
                              setFieldValue(
                                "enable_company_meeting_room_budget",
                                !values.enable_company_meeting_room_budget
                              );
                            }}
                          />
                          <TypeSelection
                            type={"Hot Desks"}
                            icon={
                              PUBLIC_URL + "/assets/icons/icon-desk-blue.png"
                            }
                            iconChecked={
                              PUBLIC_URL + "/assets/icons/icon-desk-white.png"
                            }
                            isCheck={values.enable_company_hot_desk_budget}
                            onClick={() => {
                              setFieldValue(
                                "enable_company_hot_desk_budget",
                                !values.enable_company_hot_desk_budget
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className={"setting-divide"}></div>
              <div className={"setting-right-side"}>
                <div className={"setting-item"}>
                  <ToggleSlider
                    value={values.is_personal_limit}
                    onChange={(e: any, data: any) => {
                      setFieldValue("is_personal_limit", data.checked);
                    }}
                  />
                  <Label
                    className={"fw-500"}
                    fontSize={"14px"}
                    label={"Set budget limit for individual employee allowance"}
                    labelGuide={
                      translations.settings.setMonthlyEmployeeBudgetTooltip
                    }
                  />
                </div>

                {values.is_personal_limit ? (
                  <div className={"setting-collapse"}>
                    <div className={"setting-item"}>
                      <Label
                        label={"Default monthly individual employee budget to"}
                        className={"w-100"}
                      />
                      <div className={"w-100"}>
                        <InputCustom
                          value={values.individual_monthly_budget}
                          onChange={(event: any, { value }: any) => {
                            setFieldValue(
                              "individual_monthly_budget",
                              addCommaToNumber(value)
                            );
                          }}
                          onBlur={handleBlur}
                          touched={touched.individual_monthly_budget}
                          error={errors.individual_monthly_budget}
                          inputLeftIcon={settings?.currency}
                        />
                      </div>
                    </div>
                    <div className={"setting-item"} style={{ height: 24 }}>
                      <CheckboxCustom
                        onChange={(e: any, data: any) => {
                          setFieldValue(
                            "is_apply_budget_current_employee",
                            data.checked ? 1 : 0
                          );
                        }}
                        checked={values.is_apply_budget_current_employee}
                        label={`Apply the limit of ${
                          settings?.currency +
                          " " +
                          values.individual_monthly_budget
                        } for current employees`}
                      />
                    </div>
                    <div className={"setting-item"}>
                      <Label
                        label={"Individual allowance budget enabled for "}
                      />
                      <div className={"w-100"}>
                        <div className={"type-wrapper"}>
                          <TypeSelection
                            type={"Meeting Rooms"}
                            icon={
                              PUBLIC_URL + "/assets/icons/icon-room-blue.png"
                            }
                            iconChecked={
                              PUBLIC_URL + "/assets/icons/icon-room-white.png"
                            }
                            isCheck={values.enable_personal_meeting_room_budget}
                            onClick={() => {
                              setFieldValue(
                                "enable_personal_meeting_room_budget",
                                !values.enable_personal_meeting_room_budget
                              );
                            }}
                          />
                          <TypeSelection
                            type={"Hot Desks"}
                            icon={
                              PUBLIC_URL + "/assets/icons/icon-desk-blue.png"
                            }
                            iconChecked={
                              PUBLIC_URL + "/assets/icons/icon-desk-white.png"
                            }
                            isCheck={values.enable_personal_hot_desk_budget}
                            onClick={() => {
                              setFieldValue(
                                "enable_personal_hot_desk_budget",
                                !values.enable_personal_hot_desk_budget
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {/*<div className={"setting-item"}>*/}
                {/*  <Label*/}
                {/*    size={"16px"}*/}
                {/*    label={*/}
                {/*      translations.settings.currencyToBeUsedForTransactions*/}
                {/*    }*/}
                {/*    labelGuide={*/}
                {/*      values?.payment_currency_method === MARKET_CURRENCY*/}
                {/*        ? translations.settings*/}
                {/*            .currencyToBeUsedForTransactionsMarketCurrencyTooltip*/}
                {/*        : translations.settings*/}
                {/*            .currencyToBeUsedForTransactionsCompanyCurrencyTooltip*/}
                {/*    }*/}
                {/*  />*/}
                {/*  <SizeBox height={"18px"} />*/}
                {/*  <div className={"button-group yellow"}>*/}
                {/*    <ButtonCustom*/}
                {/*      onClick={() =>*/}
                {/*        setFieldValue(*/}
                {/*          "payment_currency_method",*/}
                {/*          MARKET_CURRENCY*/}
                {/*        )*/}
                {/*      }*/}
                {/*      height={"36px"}*/}
                {/*      className={*/}
                {/*        values.payment_currency_method === MARKET_CURRENCY*/}
                {/*          ? "active"*/}
                {/*          : ""*/}
                {/*      }*/}
                {/*    >*/}
                {/*      {translations.settings.marketCurrency}*/}
                {/*    </ButtonCustom>*/}
                {/*    <ButtonCustom*/}
                {/*      onClick={() =>*/}
                {/*        setFieldValue(*/}
                {/*          "payment_currency_method",*/}
                {/*          COMPANY_CURRENCY*/}
                {/*        )*/}
                {/*      }*/}
                {/*      height={"36px"}*/}
                {/*      className={*/}
                {/*        values.payment_currency_method === COMPANY_CURRENCY*/}
                {/*          ? "active"*/}
                {/*          : ""*/}
                {/*      }*/}
                {/*    >*/}
                {/*      {translations.settings.companyCurrency} (*/}
                {/*      {settings?.currency})*/}
                {/*    </ButtonCustom>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*<SizeBox height={"56px"} />*/}
                {/*<div className={"setting-item"}>*/}
                {/*  <Label*/}
                {/*    label={translations.settings.paymentMethod}*/}
                {/*    size={"16px"}*/}
                {/*  />*/}
                {/*  <SizeBox height={"18px"} />*/}
                {/*  <div className={"button-group yellow"}>*/}
                {/*    <ButtonCustom*/}
                {/*      onClick={() => setFieldValue("payment_method", 1)}*/}
                {/*      height={"36px"}*/}
                {/*      className={values.payment_method === 1 ? "active" : ""}*/}
                {/*    >*/}
                {/*      {translations.settings.payAsYouGo}*/}
                {/*    </ButtonCustom>*/}
                {/*    {settings?.has_post_pay ? (*/}
                {/*      <ButtonCustom*/}
                {/*        height={"36px"}*/}
                {/*        className={values.payment_method === 2 ? "active" : ""}*/}
                {/*        onClick={() => setFieldValue("payment_method", 2)}*/}
                {/*      >*/}
                {/*        <>{translations.settings.postPay}</>*/}
                {/*      </ButtonCustom>*/}
                {/*    ) : (*/}
                {/*      <ButtonCustom disabled={true} height={"36px"}>*/}
                {/*        <>*/}
                {/*          {translations.settings.postPay}*/}
                {/*          {values.has_request_post_payment ? null : (*/}
                {/*            <span className={"ml-4px"}>*/}
                {/*              ({translations.settings.requested})*/}
                {/*            </span>*/}
                {/*          )}*/}
                {/*        </>*/}
                {/*      </ButtonCustom>*/}
                {/*    )}*/}
                {/*  </div>*/}
                {/*  <SizeBox height={"9px"} />*/}
                {/*  {values.has_request_post_payment ? (*/}
                {/*    <ButtonCustom*/}
                {/*      onClick={_requestPostPay}*/}
                {/*      className={"link ml-auto px-0"}*/}
                {/*    >*/}
                {/*      {translations.settings.requestToEnablePostPay}*/}
                {/*    </ButtonCustom>*/}
                {/*  ) : null}*/}

                {/*  <SizeBox height={"18px"} />*/}
                {/*</div>*/}
                {/*<div className={"setting-item"}>*/}
                {/*  <Label*/}
                {/*    size={"16px"}*/}
                {/*    label={translations.excludedWorkspaces}*/}
                {/*    labelGuide={*/}
                {/*      translations.settings.restrictedPropertiesTooltip*/}
                {/*    }*/}
                {/*  />*/}
                {/*  <SizeBox height={"25px"} />*/}
                {/*  <Dropdown*/}
                {/*    onChange={(e: any, { value }: any) => {*/}
                {/*      setFieldValue("properties_restriction", value);*/}
                {/*    }}*/}
                {/*    icon={null}*/}
                {/*    value={values?.properties_restriction}*/}
                {/*    placeholder={translations.searchExcludedWorkspaces}*/}
                {/*    fluid*/}
                {/*    multiple*/}
                {/*    selection*/}
                {/*    search*/}
                {/*    options={properties}*/}
                {/*  />*/}
                {/*</div>*/}
              </div>
            </div>
            <div className={"setting-tab__actions"}>
              <ButtonCustom
                fontSize={"16px"}
                height={"47px"}
                className={"default"}
                onClick={() => {
                  resetForm();
                }}
              >
                {translations.settings.cancel}
              </ButtonCustom>
              <SizeBox width={"16px"} />
              <ButtonCustom
                loading={loading}
                fontSize={"16px"}
                height={"47px"}
                className={"primary"}
                onClick={handleSubmit}
              >
                {translations.settings.save}
              </ButtonCustom>
            </div>
          </>
        )}
      </Formik>
    </Tab.Pane>
  );
};

export default SettingTab;
