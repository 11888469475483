import { PUBLIC_URL } from "../../../../configs/Constants";
import "./style.scss";
import React, { useContext, useEffect, useState } from "react";
import { Loader, Modal } from "semantic-ui-react";
import ButtonCustom from "../../../../../../components/Button";
import {
  inviteEmployeeService,
  uploadEmployeeFileService,
} from "../../../../services/AppServices";
import { LocalizationContext } from "../../../../../../locales/Translation";

const VerifySection = ({
  fileUploaded,
  showErrorSwal,
  showSuccessSwal,
  showInvitingSwal,
  setFileUploaded,
  setTabIndex,
}: any) => {
  const { translations } = useContext(LocalizationContext);

  const [upload, setUpload] = useState(false);
  const [result, setResult] = useState({
    success: [],
    fail: [],
    warning: [],
  });
  const [invite, setInvite] = useState(false);

  useEffect(() => {
    importFile();
  }, []);

  const importFile = async () => {
    if (!fileUploaded) {
      setTabIndex(1);
      return;
    }
    setUpload(true);
    try {
      const formData = new FormData();
      formData.append("file", fileUploaded);
      const response: any = await uploadEmployeeFileService(formData);
      setResult({
        success: response?.data?.success,
        fail: response?.data?.fail,
        warning: response?.data?.warning,
      });

      if (response?.data?.fail && response?.data?.fail.length > 0) {
        showErrorSwal(response?.data?.fail);
        setFileUploaded(null);
      }
    } catch (error: any) {
      showErrorSwal([error?.status?.message]);
      setFileUploaded(null);
    } finally {
      setUpload(false);
    }
  };

  const inviteFile = async () => {
    setInvite(true);
    showInvitingSwal();
    try {
      const response = await inviteEmployeeService([
        ...result.success,
        ...result.warning,
      ]);
      showSuccessSwal(response?.data?.success.length, result.warning);
    } catch (error) {
      console.log(error);
    } finally {
      setInvite(false);
    }
  };

  const MileStone = () => {
    if (result.fail.length > 0) {
      return (
        <div className={"milestone-error"}>
          <i className="uil uil-times" />
        </div>
      );
    } else {
      return (
        <img src={PUBLIC_URL + "/assets/icons/icon-check-circle-blue.svg"} />
      );
    }
  };

  return (
    <>
      <Modal.Content>
        <div>
          <div className={"verify-section"}>
            <div className={"verify-section__steps"}>
              <div className={"verify-section__step"}>
                <div
                  className={
                    "verify-section__step-milestone " +
                    (upload ? "" : " active ")
                  }
                >
                  <img
                    src={
                      PUBLIC_URL + "/assets/icons/icon-check-circle-blue.svg"
                    }
                  />
                </div>
                <div className={"verify-section__step-content"}>
                  <div className={"content-title"}>
                    {translations.uploadedFiles}
                  </div>
                  <div className={"content-text file"}>
                    <img
                      src={PUBLIC_URL + "/assets/icons/icon-xlsx-file.svg"}
                      style={{ height: 32 }}
                    />
                    <div className={"file-name"}>{fileUploaded?.name}</div>
                  </div>
                </div>
              </div>
              <div className={"verify-section__step"}>
                <div className={"verify-section__step-milestone"}>
                  {upload ? (
                    <Loader size={"tiny"} className={"milestone"} />
                  ) : (
                    <MileStone />
                  )}
                </div>
                <div className={"verify-section__step-content"}>
                  <div className={"content-title"}>
                    {translations.fileVerified}
                  </div>
                  <div className={"content-text"}>
                    {upload ? "Verifying file format" : ""}
                  </div>
                </div>
              </div>
              <div className={"verify-section__step"}>
                <div className={"verify-section__step-milestone"}>
                  <img
                    src={
                      PUBLIC_URL + "/assets/icons/icon-check-circle-grey.svg"
                    }
                  />
                </div>
                <div className={"verify-section__step-content"}>
                  <div className={"content-title"}>
                    {translations.dataValidated}
                  </div>
                  <div className={"content-text"}>
                    {translations.verifyingFileFormat}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <ButtonCustom
          loading={invite}
          disabled={result.success.length < 1 && result.warning.length < 1}
          className={"primary"}
          height={"47px"}
          onClick={inviteFile}
        >
          {translations.invite}
        </ButtonCustom>
      </Modal.Actions>
    </>
  );
};

export default VerifySection;
