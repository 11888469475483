import React from "react";
import Tabs from "../Tabs";
import CreditsUsageTable from "./Table";
import SearchWithDebounce from "../../../../../components/SearchBar4Table/SearchWithDebounce";
import { prepaidCreditFiltersAction } from "../../../actions/BusinessActions";
import { useDispatch, useSelector } from "react-redux";
import { prepaidCreditFiltersSelector } from "../../../selectors/BusinessSelectors";

const PrepaidCredit = () => {
  const dispatch = useDispatch();
  const prepaidCreditFilters = useSelector(prepaidCreditFiltersSelector);

  return (
    <>
      <div className={"package-function-section"}>
        <SearchWithDebounce
          initialValue={prepaidCreditFilters?.keyword}
          placeholder={"Search by Transaction ID, Company Name"}
          onChange={(value) =>
            dispatch(
              prepaidCreditFiltersAction({
                page: 1,
                keyword: value,
              })
            )
          }
        />
        <Tabs />
        <div />
      </div>
      <CreditsUsageTable />
    </>
  );
};

export default PrepaidCredit;
