import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { businessCompanyInvoiceThunk } from "../../thunks/BusinessThunks";
import CompanyInvoiceTable from "./Components/CompanyInvoiceTable";
import ButtonCustom from "../../../../components/Button";
import ModalGenerateBusinessCompanyInvoice from "./Components/ModalGenerateInvoice";
import { LocalizationContext } from "../../../../locales/Translation";
import { toastError, toastSuccess } from "../../../../components/Toast";
import { generateBusinessInvoiceService } from "../../services/BusinessServices";
import SizeBox from "../../../../components/SizeBox";
import DropdownWithCheckboxMenu from "../../../../components/DropdownWithCheckboxMenu";
import {
  ORDER_BY_ASC,
  ORDER_BY_DESC,
  ORDER_BY_NONE,
  PUBLIC_URL,
} from "../../../App/configs/Constants";
import "./style.scss";
import { globalSettingSelector } from "../../../App/selectors/AppSelectors";
import {
  INVOICE_DRAFT_STATUS,
  INVOICE_PAID_STATUS,
  INVOICE_PENDING_PAYMENT_STATUS,
} from "../../configs/Constants";
import { businessCompanyInvoiceFilterSelector } from "../../selectors/BusinessSelectors";
import { businessCompanyInvoiceFilterAction } from "../../actions/BusinessActions";
import { useGetAllQuery, useSetQuery } from "../../../../utils/custom-hooks";
import SearchWithDebounce from "../../../../components/SearchBar4Table/SearchWithDebounce";
import {
  FILTER_DATE_ALL_TIME,
  FILTER_DATE_CUSTOM,
  FILTER_DATE_LAST_14_DAYS,
  FILTER_DATE_LAST_30_DAYS,
  FILTER_DATE_LAST_90_DAYS,
} from "../../../Dashboard/configs/Constants";
import DropdownCustom from "../../../../components/DropdownCustom";
import DateRangePickerV2 from "../../../../components/DateRangePickerV2";
import moment from "moment/moment";
import { DropdownProps } from "semantic-ui-react";
import { onChangeFilterDateTypeService } from "../../../Dashboard/services/DashboardServices";
import CompanyFilter from "../../../../components/CompanyFilter";

const CompanyInvoiceList = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [generateLoading, setGenerateLoading] = useState(false);
  const { translations } = useContext(LocalizationContext);
  const [refreshList, setRefreshList] = useState(false);
  const [modalGenerate, setModalGenerate] = useState(false);
  const globalSetting = useSelector(globalSettingSelector);
  const businessCompanyInvoiceFilter = useSelector(
    businessCompanyInvoiceFilterSelector
  );
  const [addParamsAlready, setAddParamsAlready] = useState<boolean>(false);
  const getAllQuery = useGetAllQuery();
  const setQuery = useSetQuery();

  useEffect(() => {
    try {
      if (!businessCompanyInvoiceFilter.market_code.length) {
        dispatch(
          businessCompanyInvoiceFilterAction({
            market_code: globalSetting?.location.map(
              ({ city_code }: { city_code: string }) => city_code
            ),
          })
        );
      }

      if (!Object.keys(getAllQuery).length) {
        return;
      }
      dispatch(businessCompanyInvoiceFilterAction(getAllQuery));
    } finally {
      setAddParamsAlready(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!addParamsAlready) {
          return;
        }

        setLoading(true);

        if (businessCompanyInvoiceFilter.market_code.length === 0) {
          return;
        }

        setQuery(businessCompanyInvoiceFilter);
        await dispatch<any>(
          businessCompanyInvoiceThunk(businessCompanyInvoiceFilter)
        );
      } finally {
        setLoading(false);
      }
    })();
  }, [businessCompanyInvoiceFilter, refreshList, addParamsAlready]);

  const _onChangePagination = (e: SyntheticEvent, { activePage }: any) => {
    dispatch(
      businessCompanyInvoiceFilterAction({
        page: activePage,
      })
    );
  };

  const _onChangeSortCol = (col: any) => {
    if (businessCompanyInvoiceFilter.sort_by !== col.key) {
      dispatch(
        businessCompanyInvoiceFilterAction({
          sort_by: col.key,
          order_by: ORDER_BY_ASC,
        })
      );
    } else {
      if (businessCompanyInvoiceFilter.order_by === ORDER_BY_NONE) {
        dispatch(
          businessCompanyInvoiceFilterAction({
            sort_by: col.key,
            order_by: ORDER_BY_ASC,
          })
        );
      }
      if (businessCompanyInvoiceFilter.order_by === ORDER_BY_DESC) {
        dispatch(
          businessCompanyInvoiceFilterAction({
            sort_by: col.key,
            order_by: ORDER_BY_NONE,
          })
        );
      }
      if (businessCompanyInvoiceFilter.order_by === ORDER_BY_ASC) {
        dispatch(
          businessCompanyInvoiceFilterAction({
            sort_by: col.key,
            order_by: ORDER_BY_DESC,
          })
        );
      }
    }
  };

  const _onSearch = (value: string) => {
    dispatch(
      businessCompanyInvoiceFilterAction({
        keyword: value,
        page: 1,
      })
    );
  };

  const _onChangeMarketFilter = (values: string[]) => {
    dispatch(
      businessCompanyInvoiceFilterAction({ market_code: values, page: 1 })
    );
  };

  const _generateInvoice = async (
    calendar: any,
    companyId: any,
    isBlankInvoice: any,
    isMonthlyInvoice: any,
    isSAASFeeInvoice: any
  ) => {
    if (!calendar) {
      return;
    }
    setGenerateLoading(true);
    try {
      const payload: any = {
        month: String(calendar?.month),
        year: String(calendar?.year),
        company: companyId,
      };

      if (companyId !== 0) {
        payload.is_blank_invoice = isBlankInvoice;
        payload.is_monthly_invoice = isMonthlyInvoice;
        payload.is_saas_fee_invoice = isSAASFeeInvoice;
      }

      await generateBusinessInvoiceService(payload);
      setModalGenerate(false);
      toastSuccess(translations.companyInvoices.generateInvoiceSuccessPrompt);
      setRefreshList(!refreshList);
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setGenerateLoading(false);
    }
  };

  const FILTER_DATE_ARR = [
    {
      key: FILTER_DATE_LAST_14_DAYS,
      value: FILTER_DATE_LAST_14_DAYS,
      text: translations.dateFilter.last14Days,
    },
    {
      key: FILTER_DATE_LAST_30_DAYS,
      value: FILTER_DATE_LAST_30_DAYS,
      text: translations.dateFilter.last30Days,
    },
    {
      key: FILTER_DATE_LAST_90_DAYS,
      value: FILTER_DATE_LAST_90_DAYS,
      text: translations.dateFilter.last90Days,
    },
    {
      key: FILTER_DATE_ALL_TIME,
      value: FILTER_DATE_ALL_TIME,
      text: translations.dateFilter.allTime,
    },
    {
      key: FILTER_DATE_CUSTOM,
      value: FILTER_DATE_CUSTOM,
      text: translations.dateFilter.custom,
    },
  ];

  const _onChangeDateType = (
    event: SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps
  ) => {
    if (!value) {
      return;
    }

    const dateRangeResult: any = onChangeFilterDateTypeService(value);
    dispatch(
      businessCompanyInvoiceFilterAction({
        dateType: value,
        from_time: dateRangeResult?.start_time,
        to_time: dateRangeResult?.end_time,
      })
    );
  };

  if (!addParamsAlready) {
    return null;
  }

  return (
    <>
      <div className="section-functions-company-invoices">
        <SearchWithDebounce
          initialValue={businessCompanyInvoiceFilter?.keyword}
          placeholder={
            translations.companyInvoices.companyInvoiceSearchPlaceholder
          }
          onChange={_onSearch}
        />
        <SizeBox width={"16px"} />
        <DropdownCustom
          style={{ minWidth: "unset" }}
          className={"justify-content-center text-nowrap"}
          selectUI
          options={FILTER_DATE_ARR}
          onChange={_onChangeDateType}
          value={businessCompanyInvoiceFilter.dateType}
        />
        {businessCompanyInvoiceFilter.dateType === FILTER_DATE_CUSTOM ? (
          <>
            <SizeBox width={"8px"} />
            <DateRangePickerV2
              format={"DD/MM/YYYY"}
              defaultValue={[
                moment(new Date(new Date().setDate(new Date().getDate() - 30))),
                moment(new Date()),
              ]}
              onChange={(value: any) => {
                if (!value) {
                  return;
                }

                dispatch(
                  businessCompanyInvoiceFilterAction({
                    from_time: moment(value[0]).startOf("day").utc().unix(),
                    to_time: moment(value[1]).endOf("day").utc().unix(),
                  })
                );
              }}
            />
          </>
        ) : null}

        <div className="section-generate-and-filter">
          <SizeBox width={"16px"} />
          <DropdownWithCheckboxMenu
            label={translations.filters}
            icon={
              <img
                alt={"Deskimo"}
                src={PUBLIC_URL + "/assets/icons/icon-filter.svg"}
              />
            }
            onChange={(event: any, data: any) => {
              let newState = JSON.parse(
                JSON.stringify(businessCompanyInvoiceFilter.statuses)
              );
              if (data.checked) {
                newState.push(data.value);
              } else {
                newState = newState.filter(
                  (status: any) => status != data.value
                );
              }
              dispatch(
                businessCompanyInvoiceFilterAction({
                  statuses: newState,
                  page: 1,
                })
              );
            }}
            listCheckBox={[
              {
                id: 0,
                name: translations.companyInvoices.draftStatus,
                checked:
                  businessCompanyInvoiceFilter.statuses.includes(
                    INVOICE_DRAFT_STATUS
                  ),
              },
              {
                id: 1,
                name: translations.roomBookingStatusFilter
                  .pending_payment_status,
                checked: businessCompanyInvoiceFilter.statuses.includes(
                  INVOICE_PENDING_PAYMENT_STATUS
                ),
              },
              {
                id: 2,
                name: translations.companyInvoices.paidStatus,
                checked:
                  businessCompanyInvoiceFilter.statuses.includes(
                    INVOICE_PAID_STATUS
                  ),
              },
            ]}
          />
          <SizeBox width="16px" />
          <div className="section-all-companies-and-generate">
            <CompanyFilter
              companyId={businessCompanyInvoiceFilter.company_id}
              onChange={(value) =>
                dispatch(
                  businessCompanyInvoiceFilterAction({
                    company_id: value,
                    page: 1,
                  })
                )
              }
              marketCodes={businessCompanyInvoiceFilter.market_code}
              type={2}
              showDeletedCompany
            />
            <ButtonCustom
              loading={generateLoading}
              className={"primary ml-auto"}
              style={{ width: "max-content", height: "36px" }}
              onClick={() => setModalGenerate(true)}
            >
              {`${translations.companyInvoices.generateInvoiceLabel} +`}
            </ButtonCustom>
          </div>
        </div>
      </div>
      <CompanyInvoiceTable
        setLoading={setLoading}
        onChangePagination={_onChangePagination}
        loading={loading}
        filter={businessCompanyInvoiceFilter}
        onChangeMarketFilter={_onChangeMarketFilter}
        onChangeSortCol={_onChangeSortCol}
        setRefreshList={setRefreshList}
      />
      <ModalGenerateBusinessCompanyInvoice
        modalGenerate={modalGenerate}
        setModalGenerate={setModalGenerate}
        generateInvoice={_generateInvoice}
        generateLoading={generateLoading}
      />
    </>
  );
};

export default CompanyInvoiceList;
