import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "../../../../../../locales/Translation";
import { useNavigate, useParams } from "react-router-dom";
import {
  getOnboardPromotionPaidTrialService,
  updateOnboardPromotionPaidTrialService,
} from "../../../../services/ConfigServices";
import { Formik, FormikValues } from "formik";
import { toastError, toastSuccess } from "../../../../../../components/Toast";
import { Dimmer, Loader } from "semantic-ui-react";
import * as yup from "yup";
import SizeBox from "../../../../../../components/SizeBox";
import InputCustom from "../../../../../../components/Input";
import ButtonCustom from "../../../../../../components/Button";
import { CONFIG_MAIN_LINK } from "../../../../../App/configs/ListRoutes";
import { validateNumber } from "../../../../../../services/FormatTextService";
import { ConfigMarketGeneralInformationDataType } from "../../../../configs/DeclareTypes";
import { useSelector } from "react-redux";
import { configMarketGeneralInformationSelector } from "../../../../selectors/ConfigSelectors";

const PaidTrial = () => {
  const { translations } = useContext(LocalizationContext);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const [onboardPromotionData, setOnboardPromotionData] = useState<any>(null);
  const navigate = useNavigate();
  const configMarketGeneralInformation: ConfigMarketGeneralInformationDataType =
    useSelector(configMarketGeneralInformationSelector);

  useEffect(() => {
    getOnboardPromotionData();
  }, []);

  const getOnboardPromotionData = async () => {
    try {
      const { data } = await getOnboardPromotionPaidTrialService(id);
      setOnboardPromotionData(data);
    } catch (error: any) {
      toastError(error?.status?.message);
    } finally {
      setLoading(false);
    }
  };

  const _onSubmit = async (values: FormikValues) => {
    try {
      await updateOnboardPromotionPaidTrialService(id, values);
      toastSuccess("Updated Successfully!");
    } catch (e: any) {
      toastError(e?.status?.message);
    }
  };

  return (
    <>
      {loading ? (
        <Dimmer active>
          <Loader />
        </Dimmer>
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            percentage_value:
              onboardPromotionData?.percentage_value === undefined
                ? ""
                : onboardPromotionData?.percentage_value,
            duration:
              onboardPromotionData?.duration === undefined
                ? ""
                : onboardPromotionData?.duration,
            valid_day:
              onboardPromotionData?.valid_day === undefined
                ? ""
                : onboardPromotionData?.valid_day,
            limitation:
              onboardPromotionData?.limitation === undefined
                ? ""
                : onboardPromotionData?.limitation,
            roll_over:
              onboardPromotionData?.roll_over === undefined
                ? ""
                : onboardPromotionData?.roll_over,
            amount:
              onboardPromotionData?.amount === undefined
                ? ""
                : onboardPromotionData?.amount,
            trigger_amount: 0,
          }}
          validationSchema={yup.object().shape({
            percentage_value: yup.string().required().label("Percentage value"),
            duration: yup.string().required().label("Credit duration"),
            valid_day: yup.string().required().label("Days of validity"),
            limitation: yup.string().required().label("Promotion Limit"),
            roll_over: yup.string().required().label("Rollover"),
            amount: yup
              .string()
              .required()
              .label("Default Trial Credit Amount"),
            trigger_amount: yup.string().required().label("Trigger amount"),
          })}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onSubmit={_onSubmit}
        >
          {({
            values,
            handleSubmit,
            isSubmitting,
            errors,
            touched,
            setFieldValue,
          }) => (
            <>
              <div className="detail_divide">
                <div className="left_section pr-8">
                  <InputCustom
                    label={"Percentage value (%)"}
                    labelRequired
                    labelGuide={
                      "How much value to be discount in credit package"
                    }
                    value={values.percentage_value}
                    error={errors.percentage_value}
                    touched={touched.percentage_value}
                    containerClass="market-input-style"
                    name={"percentage_value"}
                    onChange={(e: any, { value }: any) => {
                      let text = validateNumber(value);
                      if (Number(text) > 100) {
                        text = "100";
                      }
                      setFieldValue("percentage_value", text);
                    }}
                  />
                  <InputCustom
                    label={"Default trial credit amount"}
                    labelRequired
                    labelGuide={
                      "This is the amount that will be displayed on the banner during the onboarding process."
                    }
                    value={values.amount}
                    error={errors.amount}
                    touched={touched.amount}
                    containerClass="market-input-style"
                    name={"amount"}
                    onChange={(e: any, { value }: any) => {
                      const text = validateNumber(value);
                      setFieldValue("amount", text);
                    }}
                  />
                  <InputCustom
                    label={"Rollover"}
                    labelRequired
                    labelGuide={
                      "How many times will the trial credit roll over"
                    }
                    value={values.roll_over}
                    error={errors.roll_over}
                    touched={touched.roll_over}
                    containerClass="market-input-style"
                    name={"roll_over"}
                    onChange={(e: any, { value }: any) => {
                      const text = validateNumber(value);
                      setFieldValue("roll_over", text);
                    }}
                  />
                  {/*<InputCustom*/}
                  {/*  label={"Credit trigger amount"}*/}
                  {/*  labelRequired*/}
                  {/*  value={values.trigger_amount}*/}
                  {/*  error={errors.trigger_amount}*/}
                  {/*  touched={touched.trigger_amount}*/}
                  {/*  containerClass="market-input-style"*/}
                  {/*  name={"trigger_amount"}*/}
                  {/*  onChange={(e: any, { value }: any) => {*/}
                  {/*    const text = validateNumber(value);*/}
                  {/*    setFieldValue("trigger_amount", text);*/}
                  {/*  }}*/}
                  {/*/>*/}
                </div>
                <div className="right_section pl-8">
                  <InputCustom
                    label={`Promotion Limit (${configMarketGeneralInformation?.currency})`}
                    labelRequired
                    labelGuide={
                      "Apply promotion to credit packages below or equal a specified credit amount"
                    }
                    value={values.limitation}
                    error={errors.limitation}
                    touched={touched.limitation}
                    containerClass="market-input-style"
                    name={"limitation"}
                    onChange={(e: any, { value }: any) => {
                      const text = validateNumber(value);
                      setFieldValue("limitation", text);
                    }}
                  />
                  <InputCustom
                    label={"Credit duration"}
                    labelRequired
                    labelGuide={"How long will the credit available"}
                    value={values.duration}
                    error={errors.duration}
                    touched={touched.duration}
                    containerClass="market-input-style"
                    name={"duration"}
                    onChange={(e: any, { value }: any) => {
                      const text = validateNumber(value);
                      setFieldValue("duration", text);
                    }}
                  />
                  <InputCustom
                    label={"Days of validity after creation of company"}
                    labelRequired
                    labelGuide={
                      "How long the promotion will be available since the creation of the company"
                    }
                    value={values.valid_day}
                    error={errors.valid_day}
                    touched={touched.valid_day}
                    containerClass="market-input-style"
                    name={"valid_day"}
                    onChange={(e: any, { value }: any) => {
                      const text = validateNumber(value);
                      setFieldValue("valid_day", text);
                    }}
                  />
                </div>
              </div>

              <div className={"section-white-box-actions"}>
                <ButtonCustom
                  className={"mr-0"}
                  onClick={() => navigate(CONFIG_MAIN_LINK)}
                  disabled={isSubmitting}
                >
                  {translations.cancel}
                </ButtonCustom>
                <SizeBox width={"8px"} />
                <ButtonCustom
                  className={"primary mr-0"}
                  onClick={handleSubmit}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  {translations.save}
                </ButtonCustom>
              </div>
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default PaidTrial;
