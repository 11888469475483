import React, { useContext } from "react";
import { Popup } from "semantic-ui-react";
import { LocalizationContext } from "../../../../../locales/Translation";
import { useSelector } from "react-redux";
import { creditRemaining } from "../../../selectors/AppSelectors";

type Props = {
  isMobile?: boolean;
};
const CreditBalancePopup = ({ isMobile }: Props) => {
  const { translations } = useContext(LocalizationContext);
  const remainingCredit = useSelector(creditRemaining);

  return (
    <Popup
      content={
        remainingCredit?.auto_recharge ? (
          <div className={"credit-balance-popup"}>
            When your balance falls below{" "}
            <b>{remainingCredit?.trigger_amount}</b>, your credits will be
            topped up by <b>{remainingCredit?.recharge_amount}</b>. Opt for a
            bigger package for enhanced discounts.
          </div>
        ) : (
          translations.prepaidCreditBalanceLabelGuide
        )
      }
      trigger={
        <div className={"custom-label"}>
          <i className="uil uil-info-circle input-label-icon" />
        </div>
      }
      position={isMobile ? "bottom left" : "bottom center"}
      hoverable
    />
  );
};

export default CreditBalancePopup;
