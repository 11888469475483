import React from "react";
import classNames from "classnames";
import "./styles.scss";

type Props = {
  value: number;
  onPlusChange: () => void;
  onMinusChange: () => void;
  maxValue?: number;
  minValue?: number;
};

const NumberBox = ({
  onMinusChange,
  onPlusChange,
  value,
  minValue = 1,
  maxValue = 10,
}: Props) => {
  const _onMinusChange = () => {
    if (value <= minValue) {
      return;
    }

    onMinusChange();
  };

  const _onPlusChange = () => {
    if (value >= maxValue) {
      return;
    }
    onPlusChange();
  };

  return (
    <div className={"number-box"}>
      <div
        className={classNames("number-box__icon", {
          stop: value <= minValue,
        })}
        onClick={_onMinusChange}
      >
        <i className="uil uil-minus"></i>
      </div>
      <div className={"number-box__text"}>{value}</div>
      <div
        className={classNames("number-box__icon", {
          stop: value >= maxValue,
        })}
        onClick={_onPlusChange}
      >
        <i className="uil uil-plus"></i>
      </div>
    </div>
  );
};

export default NumberBox;
