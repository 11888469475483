import React from "react";

type Props = {
  title: string;
  content: string;
};

const Copy = ({ title, content }: Props) => {
  if (!content) {
    return null;
  }

  return (
    <div className={"book-detail__section"}>
      <div className={"book-detail__section-title"}>{title}</div>
      <div
        className={"book-detail__copy"}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};

export default Copy;
