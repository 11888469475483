import React from "react";
import "./style.scss";
import NoCreditCard from "./NoCreditCard";
import LoginOnBehalf from "./LoginOnBehalf";
import InvoiceUnpaid from "./InvoiceUnpaid";

const NotificationGlobal = () => {
  return (
    <>
      <LoginOnBehalf />
      <NoCreditCard />
      <InvoiceUnpaid />
    </>
  );
};

export default NotificationGlobal;
