import { Dropdown, DropdownItem, DropdownMenu } from "semantic-ui-react";
import { PUBLIC_URL } from "../../modules/App/configs/Constants";
import React, { useEffect, useState } from "react";
import "../DropdownSearchServer/styles.scss";
import classNames from "classnames";
import { useDetectClickOutside } from "react-detect-click-outside";
import { FormikErrors, FormikTouched } from "formik";

type ValueItemProps = { text: string; id: string | number };

type Props = {
  value: ValueItemProps;
  options: ValueItemProps[];
  onChange: (item: ValueItemProps) => void;
  onSearch: (keyword: string) => void;
  disabled?: boolean;
  placeholder?: string;
  isFullWidth?: boolean;
  error?:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
};

const DropdownSearchServerSingle = ({
  value,
  options,
  onChange,
  onSearch,
  disabled,
  placeholder,
  isFullWidth,
  error,
  touched,
}: Props) => {
  const [focus, setFocus] = useState<boolean>(false);
  const [optionsInit, setOptionsInit] = useState(options);

  const ref = useDetectClickOutside({ onTriggered: () => setFocus(false) });

  useEffect(() => {
    const temp = options.filter(({ id }) => {
      return value.id !== id;
    });
    setOptionsInit(temp);
  }, [value, options]);

  const _onSelect = (item: ValueItemProps) => {
    setFocus(false);
    onChange(item);
  };

  return (
    <>
      <div
        ref={ref}
        className={classNames(
          "ui dropdown dropdown-custom select-ui selection dropdown-search-server",
          {
            visible: focus,
            active: focus,
            disabled: disabled,
            "full-width": isFullWidth,
          }
        )}
        onClick={() => setFocus(true)}
      >
        <div className={"ui text"} key={value.id}>
          {value.text || placeholder}
        </div>
        <img
          className="dropdown icon"
          src={PUBLIC_URL + "/assets/icons/icon-caret-down.svg"}
          alt={"Deksimo"}
        />
        <Dropdown
          multiple
          search
          onSearchChange={(event, { searchQuery }) => {
            onSearch(searchQuery);
          }}
          icon={null}
          placeholder={"Search"}
        >
          <DropdownMenu>
            {optionsInit.map((item, index) => {
              return (
                <DropdownItem key={index} onClick={() => _onSelect(item)}>
                  {item.text}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </div>
      {error && touched ? (
        <span className="field-error">{String(error)}</span>
      ) : null}
    </>
  );
};

export default DropdownSearchServerSingle;
