//Actions
export const MAP_STATE_ACTION = "MAP_STATE_ACTION";
export const LIST_MEETING_ROOM_ACTION = "LIST_MEETING_ROOM_ACTION";
export const LIST_MEETING_ROOM_FILTER_ACTION =
  "LIST_MEETING_ROOM_FILTER_ACTION";
export const REFRESH_LIST_MEETING_ROOM_ACTION =
  "REFRESH_LIST_MEETING_ROOM_ACTION";
export const MODAL_CONFIRM_BOOKING_ACTION = "MODAL_CONFIRM_BOOKING_ACTION";
export const MODAL_BOOKING_SUCCESS_ACTION = "MODAL_BOOKING_SUCCESS_ACTION";
export const MODAL_CONFIRM_BOOKING_DESK_ACTION =
  "MODAL_CONFIRM_BOOKING_DESK_ACTION";
export const MODAL_CONFIRM_BOOKING_DESK_DATA_ACTION =
  "MODAL_CONFIRM_BOOKING_DESK_DATA_ACTION";
export const MODAL_CHECKIN_ACTION = "MODAL_CHECKIN_ACTION";
export const BOOKING_SUCCESS_DESK_ID_ACTION = "BOOKING_SUCCESS_DESK_ID_ACTION";
export const MODAL_ASSIGN_USER_ACTION = "MODAL_ASSIGN_USER_ACTION";
export const MEETING_ROOM_DATA_TMP_ACTION = "MEETING_ROOM_DATA_TMP_ACTION";
export const BOOK_PROPERTY_LIST_ACTION = "BOOK_PROPERTY_LIST_ACTION";
export const BOOK_PROPERTY_MAP_ACTION = "BOOK_PROPERTY_MAP_ACTION";
export const PROPERTY_FILTER_ACTION = "PROPERTY_FILTER_ACTION";
export const FILTER_MARKET_ACTION = "FILTER_MARKET_ACTION";
export const PROPERTY_FILTER_KEYWORD_ACTION = "PROPERTY_FILTER_KEYWORD_ACTION";
export const INFO_WINDOW_DATA_ACTION = "INFO_WINDOW_DATA_ACTION";
export const PROPERTY_FAVORITE_ACTION = "PROPERTY_FAVORITE_ACTION";
export const ROOM_FAVORITE_ACTION = "ROOM_FAVORITE_ACTION";
export const PROPERTY_DETAIL_ACTION = "PROPERTY_DETAIL_ACTION";
export const ROOM_DETAIL_ACTION = "ROOM_DETAIL_ACTION";
export const PROPERTY_MARKET_COUNT_ACTION = "PROPERTY_MARKET_COUNT_ACTION";
export const ROOM_MARKET_COUNT_ACTION = "ROOM_MARKET_COUNT_ACTION";
export const FILTER_MARKET_OPTIONS_ACTION = "FILTER_MARKET_OPTIONS_ACTION";
export const GET_USER_LIST_ACTION = "GET_USER_LIST_ACTION";
export const APPEND_USER_LIST_ACTION = "APPEND_USER_LIST_ACTION";
export const ASSIGN_USER_LIST_ACTION = "ASSIGN_USER_LIST_ACTION";
export const ASSIGN_BOOKING_ACTION = "ASSIGN_BOOKING_ACTION";
export const ASSIGN_BOOKING_ID_ACTION = "ASSIGN_BOOKING_ID_ACTION";
export const DESK_AMENITIES_ACTION = "DESK_AMENITIES_ACTION";
export const ROOM_AMENITIES_ACTION = "ROOM_AMENITIES_ACTION";
export const MODAL_ASSIGN_BOOKING_SUBMITTED_ACTION =
  "MODAL_ASSIGN_BOOKING_SUBMITTED_ACTION";
export const PIN_HIGHLIGHT_ACTION = "PIN_HIGHLIGHT_ACTION";
export const CIRCLE_INFO_ACTION = "CIRCLE_INFO_ACTION";
export const MODAL_PRIVATE_OFFICE_ACTION = "MODAL_PRIVATE_OFFICE_ACTION";
export const PRIVATE_OFFICE_LIST_ACTION = "PRIVATE_OFFICE_LIST_ACTION";
export const PRIVATE_OFFICE_FILTER_ACTION = "PRIVATE_OFFICE_FILTER_ACTION";
export const MODAL_REWARDS_ACTION = "MODAL_REWARDS_ACTION";
export const REWARD_LIST_ACTION = "REWARD_LIST_ACTION";
//Capacity
export const CAPACITY_2_4 = "2_4";
export const CAPACITY_5_7 = "5_7";
export const CAPACITY_8_9 = "8_10";
export const CAPACITY_10_19 = "11_19";
export const CAPACITY_20 = "20_";
export const CAPACITY_SINGLE = "0_1";
export const CAPACITY_ANY = "1_";
export const CAPACITY_ARR = [
  {
    key: CAPACITY_SINGLE,
    text: "1 Person",
    value: CAPACITY_SINGLE,
  },
  {
    key: CAPACITY_2_4,
    text: "2 - 4 People",
    value: CAPACITY_2_4,
  },
  {
    key: CAPACITY_5_7,
    text: "5 - 7 People",
    value: CAPACITY_5_7,
  },
  {
    key: CAPACITY_8_9,
    text: "8 - 10 People",
    value: CAPACITY_8_9,
  },
  {
    key: CAPACITY_10_19,
    text: "11 - 19 People",
    value: CAPACITY_10_19,
  },
  {
    key: CAPACITY_20,
    text: "20+ People",
    value: CAPACITY_20,
  },
  {
    key: CAPACITY_ANY,
    text: "Any",
    value: CAPACITY_ANY,
  },
];

export const CAPACITY_FILTER_ARR = [
  "0",
  "1",
  "2_4",
  "2_4",
  "2_4",
  "5_7",
  "5_7",
  "5_7",
  "8_10",
  "8_10",
  "8_10",
];

//Sort by
export const SORT_BY_DISTANCE = null;
export const SORT_BY_LOWEST_PRICE = 2;

//Duration
export const DEFAULT_DURATION = 180;

// Property filter
export const FILTER_SEARCH_KEY = "keyword";

export const FILTER_SORT_BY_KEY = "sort_by";
export const FILTER_SORT_BY_DEFAULT = 0;
export const FILTER_SORT_BY_NEAREST = 1;
export const FILTER_SORT_BY_LOWER_PRICE = 2;
export const FILTER_SORT_BY_LOWER_PRICE_HOT_DESK = 6;
export const FILTER_SORT_BY_BEST_RATED = 5;
export const FILTER_SORT_BY_PROMOTIONS = 4;

export const FILTER_START_TIME_KEY = "start_time";
export const FILTER_END_TIME_KEY = "end_time";

export const FILTER_OPEN_NOW_KEY = "is_open";
export const FILTER_OPEN_ALL = 0;
export const FILTER_OPEN_NOW = 1;

export const FILTER_DISTANCE_KEY = "distance";
export const FILTER_DISTANCE_ALL = 0;
export const FILTER_DISTANCE_MIN = 100;
export const FILTER_DISTANCE_MAX = 30000;

export const FILTER_WORKSPACE_KEY = "type";
export const FILTER_WORKSPACE_ALL = 0;
export const FILTER_WORKSPACE_COWORKING = 1;

export const FILTER_REGION_KEY = "city_code";
export const FILTER_AMENITY_KEY = "amenities";
export const FILTER_LATITUDE_KEY = "lat";
export const FILTER_LONGITUDE_KEY = "lng";
export const FILTER_PAGE_KEY = "page";
export const FILTER_NEXT_PAGE_KEY = "next_page";
export const FILTER_ITEMS_PER_PAGE_KEY = "items_per_page";
export const FILTER_POSITION_KEY = "position";
export const RATING_ITEM_PER_PAGE = 10;

// TAB
export const BOOK_DESK_TAB = 0;
export const BOOK_MEETING_ROOM_TAB = 1;

export const DESK_TYPE = "desk";
export const MEETING_ROOM_TYPE = "meeting-room";

// Favorite
export const FAVORITE_REMOVE = 0;
export const FAVORITE_ADD = 1;

export const FAVORITE_PROPERTY = 1;
export const FAVORITE_MEETING_ROOM = 2;

export const PAYMENT_METHOD_PERSONAL_TYPE = 0;
export const PAYMENT_METHOD_BUSINESS_TYPE = 1;

export const BO_SOURCE = "BO_APP";

// Assign Booking
export const LIMIT_TOTAL_BOOKING = 10;
export const DEFAULT_SLOT = 1;

export const FILTER_MARKET_SESSION = "FILTER_MARKET_SESSION";
export const BUFFER_DURATION = 15;

export const CARD_TYPE_PRIVATE_OFFICE = "CARD_TYPE_PRIVATE_OFFICE";
export const CARD_TYPE_DESK = "CARD_TYPE_DESK";
export const CARD_TYPE_ROOM = "CARD_TYPE_ROOM";

export const PARKING_REQUEST_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  CANCELLED: "CANCELLED",
};
