import { Tab } from "semantic-ui-react";
import BillingLeftSide from "../BillingLeftSide";
import BillingRightSide from "../BillingRightSide";
import "./style.scss";
import SizeBox from "../../../../../../components/SizeBox";
import {
  getBillingContactThunk,
  getBillingInfoThunk,
  getPaymentMethodThunk,
} from "../../../../thunks/BillingThunk";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalUpdatePaymentMethod from "../ModalUpdatePaymentMethod";
import ModalBillingContact from "../ModalBillingContact";
import ModalAddCreditCard from "../ModalAddCreditCard";
import ModalAddBillingContact from "../ModalAddBillingContact";
import ModalBillingInformation from "../ModalBillingInformation";
import React from "react";
import { userCompanyChooseSelector } from "../../../../../User/selectors/UserSelectors";
import { PRODUCT_TYPE_BUSINESS } from "../../../../../Dashboard/configs/Constants";

const BillingTab = () => {
  const dispatch = useDispatch();
  const userCompanyChoose = useSelector(userCompanyChooseSelector);

  //Handle for static content
  const _getStaticContent = () => {
    dispatch<any>(getBillingInfoThunk());
    dispatch<any>(getPaymentMethodThunk());
    dispatch<any>(getBillingContactThunk());
  };

  useEffect(() => {
    _getStaticContent();
  }, [userCompanyChoose]);

  return (
    <Tab.Pane attached={false} className={"billing-tab"}>
      <BillingLeftSide />
      <SizeBox width={"30px"} />
      <BillingRightSide productType={PRODUCT_TYPE_BUSINESS} />
      <ModalUpdatePaymentMethod />
      <ModalBillingContact />
      <ModalAddCreditCard />
      <ModalAddBillingContact />
      <ModalBillingInformation />
    </Tab.Pane>
  );
};

export default BillingTab;
